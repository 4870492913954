import React, { useState, useContext, Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import format from "date-fns/format"
import numeral from "numeral"
import { 
    Button, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    FormControl,
    IconButton,
    Select,
    MenuItem,
    Collapse,
    Box
} from '@mui/material';
//import UpdateOrder from './UpdateOrder';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { CANCEL_OFFLINE_ORDER, UPDATE_OFFLINE_ORDER_STATUS } from "../api/mutations"
import { useMutation } from "@apollo/client";
import StoreIcon from '@mui/icons-material/Store'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { getStatusLabel } from "../../../constants";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { AppContext } from "../../core/AppContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles({
    cancelled: {
        backgroundColor: "#f6f6f6",
        color:"#808080"
    },
    tableIndex:{
        fontWeight:'bold'
    },
    
})

const useRowStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    tableIndex: {
      fontWeight: "bold",
      textAlign: "center",
    },
    image: {
      width: 60,
      height: "auto",
    },
    typography: {
      padding: theme.spacing(2),
      whiteSpace: "pre-wrap",
    },
}));

const STATUSES = [
    {
        text: 'TO PAY',
        value: 'TO_PAY'
    },
    {
        text: 'CONFIRMED',
        value: 'CONFIRMED'
    },
    {
        text: 'TO RECEIVE',
        value: 'TO_RECEIVE'
    },
    {
        text: 'DELIVERED',
        value: 'DELIVERED'
    },
    {
        text: 'COMPLETED',
        value: 'COMPLETED'
    },
    {
        text: 'REJECTED',
        value: 'REJECTED'
    },
    {
        text: 'RETURN',
        value: 'RETURN'
    }
]

const Row = props => {
    let { offlineOrder, classes, i, enableCancel, doCancel, resetRegData } = props;
    const [isEditStatus, setIsEditStatus] = useState(false);
    const [doUpdateOfflineOrderStatus] = useMutation(UPDATE_OFFLINE_ORDER_STATUS);
    const { showSnackBar } = useContext(AppContext);
    const [open, setOpen] = useState(false);

    const doUpdateOrderStatus = async (offlineOrder, evt) => {
        const { loading } = await doUpdateOfflineOrderStatus({
          variables: {
            id: offlineOrder?._id,
            status: evt.target.value,
          },
        });
        if (!loading) {
            await resetRegData()
            await showSnackBar({
                open: true,
                message: "Status successfully updated!",
                severity: "info",
            });
        }
      };
    return (
        <>
        { offlineOrder ? (
            <Fragment>
                <TableRow key={i} > 
                    <TableCell align="center">
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="center" className={ offlineOrder.status === "CANCELLED" ? classes.cancelled : null }>
                        {offlineOrder.referenceNo}
                    </TableCell>
                    <TableCell align="center" className={ offlineOrder.status === "CANCELLED" ? classes.cancelled : null }>{format( new Date( offlineOrder.createdDate ), "MMM dd yyyy" )}</TableCell>
                    <TableCell align="center" className={ offlineOrder.status === "CANCELLED" ? classes.cancelled : null }>{offlineOrder.firstName || ""} {offlineOrder.lastName || ""}</TableCell>
                    <TableCell align="center" className={ offlineOrder.status === "CANCELLED" ? classes.cancelled : null }>{offlineOrder.isPickup ? <StoreIcon style={offlineOrder.status === "CANCELLED" ?  {color: "#808080" } : {color:"#FD5901"} } /> : 
                        <LocalShippingIcon style={offlineOrder.status === "CANCELLED" ?  {color: "#808080" } : {color:"#008080"} } />}</TableCell>
                    <TableCell align="center" className={ offlineOrder.status === "CANCELLED" ? classes.cancelled : null }>
                    {
                        isEditStatus ? 
                        <FormControl variant="outlined">
                        <Select
                            onChange={(evt) => { 
                                doUpdateOrderStatus(offlineOrder, evt)
                                setIsEditStatus(!isEditStatus)
                            }}
                            value={offlineOrder.status}
                        >
                            {STATUSES.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                                {status.text}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl> :
                        getStatusLabel(offlineOrder.status)
                    } 
            
                    { 
                        offlineOrder.status !== 'CANCELLED' ? 
                            !isEditStatus ? 
                            <IconButton aria-label="delete" onClick={() => setIsEditStatus(!isEditStatus)} >
                                <EditIcon /> 
                            </IconButton> :  
                            <IconButton aria-label="cancel" onClick={() => setIsEditStatus(!isEditStatus)}>
                                <CancelIcon /> 
                            </IconButton> : ''
                    } 
                    </TableCell>
                    <TableCell className={ offlineOrder.status === "CANCELLED" ? classes.cancelled : null } align="center">&#8369;{numeral(offlineOrder.total).format("0,000.00")}</TableCell>
                    <TableCell className={ offlineOrder.status === "CANCELLED" ? classes.cancelled : null } align="center">
                        { offlineOrder.status !== "CANCELLED" && <Button size="small" variant="contained" disabled={enableCancel} onClick={ () => doCancel( offlineOrder._id ) }
                            color="primary" startIcon={<CancelPresentationIcon />} >Cancel</Button> }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "rgb(232, 248, 255)" }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{}}>
                        <Box margin={1}>
                        <h3>Items</h3>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" width={5} />
                                    <TableCell align="center" className={classes.tableIndex}>Quantity</TableCell>
                                    <TableCell align="center" className={classes.tableIndex}>Product</TableCell>
                                    <TableCell align="center" className={classes.tableIndex}>Discount</TableCell>
                                    <TableCell align="center" className={classes.tableIndex}>Amount</TableCell>
                                    {/* <TableCell className={classes.tableIndex} width={5} /> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {offlineOrder.offlineItems.map((offlineItem, i) => (
                                <TableRow key={i}>
                                    <TableCell align="center">
                                        <img className={classes.image} src={offlineItem?.image || "/static/no-image.jpg"} />
                                    </TableCell>
                                    <TableCell align="center">{numeral(offlineItem?.quantity).format("0,000")}</TableCell>
                                    <TableCell align="center">
                                        {offlineItem?.productName} <br />
                                        {offlineItem?.skuCode}
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            offlineItem?.compareAtPrice > offlineItem?.currentPrice ? 
                                            numeral((offlineItem?.compareAtPrice - offlineItem?.currentPrice) * offlineItem?.quantity).format("0,000.00") : 
                                            numeral(offlineItem?.discount).format("0,000.00")
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        &#8369;
                                        {
                                            offlineItem?.compareAtPrice > offlineItem?.currentPrice ?  
                                            numeral(offlineItem?.compareAtPrice).format("0,000.00") * offlineItem?.quantity : 
                                            numeral(offlineItem?.currentPrice).format("0,000.00") * offlineItem?.quantity 
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            </Fragment>
        ) : null} 
        
        </>
    )
}

const OfflineOrders = props => {
    const { offlineOrders, resetRegData, enableCancel } = props 
    const classes = useRowStyles();
    const [doCancelOrder] = useMutation(CANCEL_OFFLINE_ORDER)
    const doCancel = async id => {
        const { loading } = await doCancelOrder({
            variables: {
                id
            }
        })

        if( !loading ){
            await resetRegData()
        }
    }

    return (
        <>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className={classes.tableIndex}></TableCell>
                        <TableCell align="center" className={classes.tableIndex}>Order No</TableCell>
                        <TableCell align="center" className={classes.tableIndex}>Date</TableCell>
                        <TableCell align="center" className={classes.tableIndex}>Customer</TableCell>
                        <TableCell align="center" className={classes.tableIndex}>Delivery/Pickup</TableCell>
                        <TableCell align="center" className={classes.tableIndex}>Status</TableCell>
                        <TableCell align="center" className={classes.tableIndex}>Total</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        offlineOrders && offlineOrders.map( ( offlineOrder, i ) => (
                            <Row
                                i={i}
                                key={i}
                                classes={classes}
                                offlineOrder={offlineOrder}
                                enableCancel={enableCancel}
                                doCancel={doCancel}
                                resetRegData={resetRegData}
                            />
                        ))
                    }
                </TableBody>
            </Table>    
       </TableContainer>
       </>
    )
}

export default OfflineOrders