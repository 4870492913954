import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useApolloClient } from "@apollo/client";
import { Button } from "@mui/material";
import { GET_BRANCHES } from "../../branch/api/queries";

const BranchSelection = (props) => {
  const { handleChange, defaultValue, setShowBranchSelect } = props;
  const [branchOptions, setBranchOptions] = useState(null);
  const client = useApolloClient();

  const getBranches = async () => {
    try {
      const { data, loading } = await client.query({
        query: GET_BRANCHES,
        variables: {
          limit: 25,
        },
      });

      if (!loading) {
        const { getBranches } = data;
        let branches = JSON.parse(getBranches);
        branches = branches.branches.filter((branch) => branch.isPickup);
        if (branches) {
          setBranchOptions(branches);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!branchOptions) {
      getBranches();
    }
  }, [getBranches, branchOptions]);

  return branchOptions ? (
    <>
      <Select fullWidth={true} id="branch" name="branch" onChange={(evt) => handleChange(evt)}>
        {branchOptions.map((branch) => (
          <MenuItem key={branch._id} value={{ id: branch._id, name: branch.name }} what={branch.name}>
            {branch.name.replace("The SM Store", "")}
          </MenuItem>
        ))}
      </Select>{" "}
      <Button size="small" color="secondary" onClick={() => setShowBranchSelect(false)}>
        Cancel
      </Button>
    </>
  ) : null;
};

export default BranchSelection;
