import { ALLOWED_ROLES } from "../../../constants";

const capitalize = (str) => {
  if (typeof str !== "string") return "";
  const splitStr = str
    .split("")
    .map((letter) => (letter === letter.toUpperCase() ? ` ${letter}` : letter))
    .join("");
  return splitStr.charAt(0).toUpperCase() + splitStr.slice(1);
};

const makeTableColumns = (fieldNames) =>
  fieldNames.reduce((acc, item) => {
    acc.push({ title: capitalize(item), field: item });
    return acc;
  }, []);

const convertToTableData = (fieldNames, sourceData, pageNum = 0) => {
  const tableColumns = makeTableColumns(fieldNames);

  const tableItems = {
    data: sourceData.items,
    page: pageNum,
    totalCount: sourceData.count,
  };

  const table = {
    tableColumns,
    tableItems,
  };

  return table;
};

const getRowsParams = ({ query, paginationCb, searchField, formFilter }) => {
  const sortField = (query && query.orderBy && query.orderBy.field) || "_id";
  const sortDir = query.orderDirection || "desc";

  const rowsParams = {
    pageNum: query.page,
    itemsPerPage: query.pageSize,
    search: query.search,
    sortField,
    sortDir,
    paginationCb,
    searchField,
    formFilter,
  };

  return rowsParams;
};

const getRows = async ({
  pageNum,
  itemsPerPage,
  search,
  sortField,
  sortDir,
  paginationCb,
  searchField,
  formFilter,
}) => {
  const sort = `${sortField.toUpperCase()}_${sortDir.toUpperCase()}`;

  const results = await paginationCb({
    variables: {
      // +1 pageNum from material-table data query
      page: pageNum + 1,
      perPage: itemsPerPage,
      filter: { formFilter },
      sort,
    },
  });

  return results;
};

const getTableProps = ({
  title,
  tableRef,
  fieldNames,
  paginationResult,
  formatColumns,
  editable,
  components,
  paginationCb,
  onRowClick,
  actions,
  localization,
  paginationName,
  searchField,
  options,
  formFilter,
}) => {
  const tableData = convertToTableData(fieldNames, paginationResult);
  const { tableColumns, tableItems } = tableData;
  const columns = formatColumns(tableColumns);

  const data = async (query) => {
    const rowsParams = getRowsParams({
      query,
      paginationCb,
      searchField,
      formFilter,
    });
    const results = await getRows(rowsParams);
    const reactiveResults = results.data[paginationName];

    const converted = convertToTableData(fieldNames, reactiveResults);

    let items = tableItems;
    if (converted && converted.tableItems) {
      items = converted.tableItems;
      items.page = query.page;
    }
    return items;
  };

  return {
    title,
    tableRef,
    columns,
    editable,
    data,
    components,
    onRowClick,
    actions,
    localization,
    options,
  };
};

const addHttps = (url) => {
  let withHttps;
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    withHttps = `https://${url}`;
  }
  return withHttps;
};

const getUserCompanies = (user) => {
  if (!user) {
    return [];
  }
  const companyArray = [];

  const userCompanies = user.companies || [];
  userCompanies.forEach((company) => {
    return user.authorizations
      .filter((auth) => auth.companyName === company && ALLOWED_ROLES.includes(auth.roleName.toLowerCase()))
      .forEach((auth) => {
        if (!companyArray.includes(auth.companyName)) {
          companyArray.push(auth.companyName);
        }
      });
  });

  return companyArray;
};

const loadModelSchema = (model) => {
  let modelSchema;
  try {
    modelSchema = require(`../../${model}/schemas/index`);
  } catch (e) {
    // TODO: create 404 page
    throw new Error("Schema does not exist.");
  }

  return modelSchema.default;
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const capitalizeEachWord = (words) => {
  var separateWord = (words ?? "").toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
};

export {
  capitalize,
  makeTableColumns,
  convertToTableData,
  getTableProps,
  addHttps,
  getUserCompanies,
  loadModelSchema,
  toBase64,
  capitalizeEachWord,
};
