import { gql } from "@apollo/client";

export const UPDATE_APP_REMOTE_CONFIG_MUTATION = gql`
  mutation updateAppRemoteConfigMutation(
    $version: String
    $versionCode: Int
    $force: Boolean
    $platform: String
    $isMaintenance: Boolean
  ) {
    updateAppRemoteConfig(
      version: $version
      versionCode: $versionCode
      force: $force
      platform: $platform
      isMaintenance: $isMaintenance
    ) {
      _id
      appId
      version
      versionCode
      force
      platform
      isMaintenance
    }
  }
`;
