export const PROVINCE_LIST = {
  PROVINCE_MAP: {
    ABRA: {
      name: "ABRA",
      region: "CAR",
      municipalities: {
        BANGUED: {
          name: "BANGUED",
          baranggays: [
            {
              name: "AGTANGAO",
            },
            {
              name: "ANGAD",
            },
            {
              name: "BAÑACAO",
            },
            {
              name: "BANGBANGAR",
            },
            {
              name: "BANGUED",
            },
            {
              name: "CABULOAN",
            },
            {
              name: "CALABA",
            },
            {
              name: "COSILI EAST (PROPER)",
            },
            {
              name: "COSILI WEST (BUAYA)",
            },
            {
              name: "DANGDANGLA",
            },
            {
              name: "LINGTAN",
            },
            {
              name: "LIPCAN",
            },
            {
              name: "LUBONG",
            },
            {
              name: "MACARCARMAY",
            },
            {
              name: "MACRAY",
            },
            {
              name: "MALITA",
            },
            {
              name: "MAOAY",
            },
            {
              name: "PALAO",
            },
            {
              name: "PATUCANNAY",
            },
            {
              name: "SAGAP",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SAO-ATAN",
            },
            {
              name: "SAPPAAC",
            },
            {
              name: "TABLAC (CALOT)",
            },
            {
              name: "ZONE 1 POB. (NALASIN)",
            },
            {
              name: "ZONE 2 POB. (CONSILIMAN)",
            },
            {
              name: "ZONE 3 POB. (LALAUD)",
            },
            {
              name: "ZONE 4 POB. (TOWN PROPER)",
            },
            {
              name: "ZONE 5 POB. (BO. BARIKIR)",
            },
            {
              name: "ZONE 6 POB. (SINAPANGAN)",
            },
            {
              name: "ZONE 7 POB. (BALILING)",
            },
          ],
        },
        BOLINEY: {
          name: "BOLINEY",
          baranggays: [
            {
              name: "AMTI",
            },
            {
              name: "BAO-YAN",
            },
            {
              name: "BOLINEY",
            },
            {
              name: "DANAC EAST",
            },
            {
              name: "DANAC WEST",
            },
            {
              name: "DAO-ANGAN",
            },
            {
              name: "DUMAGAS",
            },
            {
              name: "KILONG-OLAO",
            },
            {
              name: "POBLACION (BOLINEY)",
            },
          ],
        },
        BUCAY: {
          name: "BUCAY",
          baranggays: [
            {
              name: "ABANG",
            },
            {
              name: "BANGBANGCAG",
            },
            {
              name: "BANGCAGAN",
            },
            {
              name: "BANGLOLAO",
            },
            {
              name: "BUCAY",
            },
            {
              name: "BUGBOG",
            },
            {
              name: "CALAO",
            },
            {
              name: "DUGONG",
            },
            {
              name: "LABON",
            },
            {
              name: "LAYUGAN",
            },
            {
              name: "MADALIPAY",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "PAGALA",
            },
            {
              name: "PAKILING",
            },
            {
              name: "PALAQUIO",
            },
            {
              name: "PATOC",
            },
            {
              name: "QUIMLOONG",
            },
            {
              name: "SALNEC",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SIBLONG",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TABIOG",
            },
          ],
        },
        BUCLOC: {
          name: "BUCLOC",
          baranggays: [
            {
              name: "BUCLOC",
            },
            {
              name: "DUCLIGAN",
            },
            {
              name: "LABAAN",
            },
            {
              name: "LAMAO (POB.)",
            },
            {
              name: "LINGAY",
            },
          ],
        },
        DAGUIOMAN: {
          name: "DAGUIOMAN",
          baranggays: [
            {
              name: "ABLEG",
            },
            {
              name: "CABARUYAN",
            },
            {
              name: "DAGUIOMAN",
            },
            {
              name: "PIKEK",
            },
            {
              name: "TUI (POB.)",
            },
          ],
        },
        DANGLAS: {
          name: "DANGLAS",
          baranggays: [
            {
              name: "ABAQUID",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CAUPASAN (POB.)",
            },
            {
              name: "DANGLAS",
            },
            {
              name: "DANGLAS",
            },
            {
              name: "NAGAPARAN",
            },
            {
              name: "PADANGITAN",
            },
            {
              name: "PANGAL",
            },
          ],
        },
        DOLORES: {
          name: "DOLORES",
          baranggays: [
            {
              name: "BAYAAN",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "CALUMBAYA",
            },
            {
              name: "CARDONA",
            },
            {
              name: "DOLORES",
            },
            {
              name: "ISIT",
            },
            {
              name: "KIMMALABA",
            },
            {
              name: "LIBTEC",
            },
            {
              name: "LUB-LUBBA",
            },
            {
              name: "MUDIIT",
            },
            {
              name: "NAMIT-INGAN",
            },
            {
              name: "PACAC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALUCAG",
            },
            {
              name: "TALOGTOG",
            },
            {
              name: "TAPING",
            },
          ],
        },
        "LA PAZ": {
          name: "LA PAZ",
          baranggays: [
            {
              name: "BENBEN (BONBON)",
            },
            {
              name: "BULBULALA",
            },
            {
              name: "BULI",
            },
            {
              name: "CANAN (GAPAN)",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LIGUIS",
            },
            {
              name: "MALABBAGA",
            },
            {
              name: "MUDENG",
            },
            {
              name: "PIDIPID",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "TOON",
            },
            {
              name: "UDANGAN",
            },
          ],
        },
        LACUB: {
          name: "LACUB",
          baranggays: [
            {
              name: "BACAG",
            },
            {
              name: "BUNEG",
            },
            {
              name: "GUINGUINABANG",
            },
            {
              name: "LACUB",
            },
            {
              name: "LAN-AG",
            },
            {
              name: "PACOC",
            },
            {
              name: "POBLACION (TALAMPAC)",
            },
          ],
        },
        LAGANGILANG: {
          name: "LAGANGILANG",
          baranggays: [
            {
              name: "AGUET",
            },
            {
              name: "BACOOC",
            },
            {
              name: "BALAIS",
            },
            {
              name: "CAYAPA",
            },
            {
              name: "DALAGUISEN",
            },
            {
              name: "LAANG",
            },
            {
              name: "LAGANGILANG",
            },
            {
              name: "LAGBEN",
            },
            {
              name: "LAGUIBEN",
            },
            {
              name: "NAGTIPULAN",
            },
            {
              name: "NAGTUPACAN",
            },
            {
              name: "PAGANAO",
            },
            {
              name: "PAWA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PRESENTAR",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "TAGODTOD",
            },
            {
              name: "TAPING",
            },
          ],
        },
        LAGAYAN: {
          name: "LAGAYAN",
          baranggays: [
            {
              name: "BA-I",
            },
            {
              name: "COLLAGO",
            },
            {
              name: "LAGAYAN",
            },
            {
              name: "PANG-OT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULOT",
            },
          ],
        },
        LANGIDEN: {
          name: "LANGIDEN",
          baranggays: [
            {
              name: "BAAC",
            },
            {
              name: "DALAYAP (NALAAS)",
            },
            {
              name: "LANGIDEN",
            },
            {
              name: "MABUNGTOT",
            },
            {
              name: "MALAPAAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUILLAT",
            },
          ],
        },
        "LICUAN-BAAY (LICUAN)": {
          name: "LICUAN-BAAY (LICUAN)",
          baranggays: [
            {
              name: "BONGLO (PATAGUI)",
            },
            {
              name: "BULBULALA",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "DOMENGLAY",
            },
            {
              name: "LENNENG",
            },
            {
              name: "LICUAN-BAAY (LICUAN)",
            },
            {
              name: "MAPISLA",
            },
            {
              name: "MOGAO",
            },
            {
              name: "NALBUAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SUBAGAN",
            },
            {
              name: "TUMALIP",
            },
          ],
        },
        LUBA: {
          name: "LUBA",
          baranggays: [
            {
              name: "AMPALIOC",
            },
            {
              name: "BARIT",
            },
            {
              name: "GAYAMAN",
            },
            {
              name: "LUBA",
            },
            {
              name: "LUL-LUNO",
            },
            {
              name: "LUZONG",
            },
            {
              name: "NAGBUKEL-TUQUIPA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABNANGAN",
            },
          ],
        },
        MALIBCONG: {
          name: "MALIBCONG",
          baranggays: [
            {
              name: "BAYABAS",
            },
            {
              name: "BINASARAN",
            },
            {
              name: "BUANAO",
            },
            {
              name: "DULAO",
            },
            {
              name: "DULDULAO",
            },
            {
              name: "GACAB",
            },
            {
              name: "LAT-EY",
            },
            {
              name: "MALIBCONG",
            },
            {
              name: "MALIBCONG (POB.)",
            },
            {
              name: "MATARAGAN",
            },
            {
              name: "PACGUED",
            },
            {
              name: "TARIPAN",
            },
            {
              name: "UMNAP",
            },
          ],
        },
        MANABO: {
          name: "MANABO",
          baranggays: [
            {
              name: "AYYENG (POB.)",
            },
            {
              name: "CATACDEGAN NUEVO",
            },
            {
              name: "CATACDEGAN VIEJO",
            },
            {
              name: "LUZONG",
            },
            {
              name: "MANABO",
            },
            {
              name: "SAN JOSE NORTE",
            },
            {
              name: "SAN JOSE SUR",
            },
            {
              name: "SAN JUAN NORTE",
            },
            {
              name: "SAN JUAN SUR",
            },
            {
              name: "SAN RAMON EAST",
            },
            {
              name: "SAN RAMON WEST",
            },
            {
              name: "SANTO TOMAS",
            },
          ],
        },
        PEÑARRUBIA: {
          name: "PEÑARRUBIA",
          baranggays: [
            {
              name: "DUMAYCO",
            },
            {
              name: "LUSUAC",
            },
            {
              name: "MALAMSIT (PAU-MALAMSIT)",
            },
            {
              name: "NAMARABAR",
            },
            {
              name: "PATIAO",
            },
            {
              name: "PEÑARRUBIA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIANG (TIANG)",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "TATTAWA",
            },
          ],
        },
        PIDIGAN: {
          name: "PIDIGAN",
          baranggays: [
            {
              name: "ALINAYA",
            },
            {
              name: "ARAB",
            },
            {
              name: "GARRETA",
            },
            {
              name: "IMMULI",
            },
            {
              name: "LASKIG",
            },
            {
              name: "MONGGOC",
            },
            {
              name: "NAGUIRAYAN",
            },
            {
              name: "PAMUTIC",
            },
            {
              name: "PANGTUD",
            },
            {
              name: "PIDIGAN",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAN DIEGO",
            },
            {
              name: "SULBEC",
            },
            {
              name: "SUYO (MALIDONG)",
            },
            {
              name: "YUYENG",
            },
          ],
        },
        PILAR: {
          name: "PILAR",
          baranggays: [
            {
              name: "BOLBOLO",
            },
            {
              name: "BROOKSIDE",
            },
            {
              name: "DALIT",
            },
            {
              name: "DINTAN",
            },
            {
              name: "GAPANG",
            },
            {
              name: "KINABITI",
            },
            {
              name: "MALIPLIPIT",
            },
            {
              name: "NAGCANASAN",
            },
            {
              name: "NANANGDUAN",
            },
            {
              name: "NARNARA",
            },
            {
              name: "OCUP",
            },
            {
              name: "PANG-OT",
            },
            {
              name: "PATAD",
            },
            {
              name: "PILAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JUAN EAST",
            },
            {
              name: "SAN JUAN WEST",
            },
            {
              name: "SOUTH BALIOAG",
            },
            {
              name: "TIKITIK",
            },
            {
              name: "VILLAVIEJA",
            },
          ],
        },
        SALLAPADAN: {
          name: "SALLAPADAN",
          baranggays: [
            {
              name: "BAZAR",
            },
            {
              name: "BILABILA",
            },
            {
              name: "GANGAL (POB.)",
            },
            {
              name: "MAGUYEPYEP",
            },
            {
              name: "NAGUILIAN",
            },
            {
              name: "SACCAANG",
            },
            {
              name: "SALLAPADAN",
            },
            {
              name: "SALLAPADAN",
            },
            {
              name: "SUBUSOB",
            },
            {
              name: "UD-UDIAO",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "CABAYOGAN",
            },
            {
              name: "DALIMAG",
            },
            {
              name: "LANGBABAN",
            },
            {
              name: "MANAYDAY",
            },
            {
              name: "PANTOC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABTAN-OLO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MARCIAL",
            },
            {
              name: "TANGBAO",
            },
          ],
        },
        "SAN JUAN": {
          name: "SAN JUAN",
          baranggays: [
            {
              name: "ABUALAN",
            },
            {
              name: "BA-UG",
            },
            {
              name: "BADAS",
            },
            {
              name: "CABCABORAO",
            },
            {
              name: "COLABAOAN",
            },
            {
              name: "CULIONG",
            },
            {
              name: "DAOIDAO",
            },
            {
              name: "GUIMBA",
            },
            {
              name: "LAM-AG",
            },
            {
              name: "LUMOBANG",
            },
            {
              name: "NANGOBONGAN",
            },
            {
              name: "PATTAOIG",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "QUIDAOEN",
            },
            {
              name: "SABANGAN",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SILET",
            },
            {
              name: "SUPI-IL",
            },
            {
              name: "TAGAYTAY",
            },
          ],
        },
        "SAN QUINTIN": {
          name: "SAN QUINTIN",
          baranggays: [
            {
              name: "LABAAN",
            },
            {
              name: "PALANG",
            },
            {
              name: "PANTOC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN QUINTIN",
            },
            {
              name: "TANGADAN",
            },
            {
              name: "VILLA MERCEDES",
            },
          ],
        },
        TAYUM: {
          name: "TAYUM",
          baranggays: [
            {
              name: "BAGALAY",
            },
            {
              name: "BASBASA",
            },
            {
              name: "BUDAC",
            },
            {
              name: "BUMAGCAT",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "DEET",
            },
            {
              name: "GADDANI",
            },
            {
              name: "PATUCANNAY",
            },
            {
              name: "PIAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAYUM",
            },
            {
              name: "VELASCO",
            },
          ],
        },
        TINEG: {
          name: "TINEG",
          baranggays: [
            {
              name: "ALAOA",
            },
            {
              name: "ANAYAN",
            },
            {
              name: "APAO",
            },
            {
              name: "BELAAT",
            },
            {
              name: "CAGANAYAN",
            },
            {
              name: "COGON",
            },
            {
              name: "LANEC",
            },
            {
              name: "LAPAT-BALANTAY",
            },
            {
              name: "NAGLIBACAN",
            },
            {
              name: "POBLACION (AGSIMAO)",
            },
            {
              name: "TINEG",
            },
          ],
        },
        TUBO: {
          name: "TUBO",
          baranggays: [
            {
              name: "ALANGTIN",
            },
            {
              name: "AMTUAGAN",
            },
            {
              name: "DILONG",
            },
            {
              name: "KILI",
            },
            {
              name: "POBLACION (MAYABO)",
            },
            {
              name: "SUPO",
            },
            {
              name: "TABACDA",
            },
            {
              name: "TIEMPO",
            },
            {
              name: "TUBO",
            },
            {
              name: "TUBTUBA",
            },
            {
              name: "WAYANGAN",
            },
          ],
        },
        VILLAVICIOSA: {
          name: "VILLAVICIOSA",
          baranggays: [
            {
              name: "AP-APAYA",
            },
            {
              name: "BOL-LILISING",
            },
            {
              name: "CAL-LAO",
            },
            {
              name: "LAP-LAPOG",
            },
            {
              name: "LUMABA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAMAC",
            },
            {
              name: "TUQUIB",
            },
            {
              name: "VILLAVICIOSA",
            },
          ],
        },
      },
    },
    "AGUSAN DEL NORTE": {
      name: "AGUSAN DEL NORTE",
      region: "REGION 13",
      municipalities: {
        BUENAVISTA: {
          name: "BUENAVISTA",
          baranggays: [
            {
              name: "AGONG-ONG",
            },
            {
              name: "ALUBIJID",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "GUINABSAN",
            },
            {
              name: "LOWER OLAVE",
            },
            {
              name: "MACALANG",
            },
            {
              name: "MALAPONG",
            },
            {
              name: "MALPOC",
            },
            {
              name: "MANAPA",
            },
            {
              name: "MATABAO",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 10",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "POBLACION 4",
            },
            {
              name: "POBLACION 5",
            },
            {
              name: "POBLACION 6",
            },
            {
              name: "POBLACION 7",
            },
            {
              name: "POBLACION 8",
            },
            {
              name: "POBLACION 9",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SACOL",
            },
            {
              name: "SANGAY",
            },
            {
              name: "SIMBALAN",
            },
            {
              name: "TALO-AO",
            },
          ],
        },
        BUTUAN: {
          name: "BUTUAN",
          baranggays: [
            {
              name: "BUTUAN",
            },
          ],
        },
        "BUTUAN CITY": {
          name: "BUTUAN CITY",
          baranggays: [
            {
              name: "AGAO POB. (BGY. 3)",
            },
            {
              name: "AGUSAN PEQUEÑO",
            },
            {
              name: "AMBAGO",
            },
            {
              name: "AMPARO",
            },
            {
              name: "AMPAYON",
            },
            {
              name: "ANTICALA",
            },
            {
              name: "ANTONGALON",
            },
            {
              name: "AUPAGAN",
            },
            {
              name: "BAAN KM 3",
            },
            {
              name: "BAAN RIVERSIDE POB. (BGY. 20)",
            },
            {
              name: "BABAG",
            },
            {
              name: "BADING POB. (BGY. 22)",
            },
            {
              name: "BANCASI",
            },
            {
              name: "BANZA",
            },
            {
              name: "BAOBAOAN",
            },
            {
              name: "BASAG",
            },
            {
              name: "BAYANIHAN POB. (BGY. 27)",
            },
            {
              name: "BILAY",
            },
            {
              name: "BIT-OS",
            },
            {
              name: "BITAN-AGAN",
            },
            {
              name: "BOBON",
            },
            {
              name: "BONBON",
            },
            {
              name: "BUGABUS",
            },
            {
              name: "BUGSUKAN",
            },
            {
              name: "BUHANGIN POB. (BGY. 19)",
            },
            {
              name: "CABCABON",
            },
            {
              name: "CAMAYAHAN",
            },
            {
              name: "DAGOHOY POB. (BGY. 7)",
            },
            {
              name: "DANKIAS",
            },
            {
              name: "DE ORO",
            },
            {
              name: "DIEGO SILANG POB. (BGY. 6)",
            },
            {
              name: "DON FRANCISCO",
            },
            {
              name: "DOONGAN",
            },
            {
              name: "DULAG",
            },
            {
              name: "DUMALAGAN",
            },
            {
              name: "FLORIDA",
            },
            {
              name: "GOLDEN RIBBON POB. (BGY. 2)",
            },
            {
              name: "HOLY REDEEMER POB. (BGY. 23)",
            },
            {
              name: "HUMABON POB. (BGY. 11)",
            },
            {
              name: "IMADEJAS POB. (BGY. 24)",
            },
            {
              name: "JOSE RIZAL POB. (BGY. 25)",
            },
            {
              name: "KINAMLUTAN",
            },
            {
              name: "LAPU-LAPU POB. (BGY. 8)",
            },
            {
              name: "LEMON",
            },
            {
              name: "LEON KILAT POB. (BGY. 13)",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIMAHA POB. (BGY. 14)",
            },
            {
              name: "LOS ANGELES",
            },
            {
              name: "LUMBOCAN",
            },
            {
              name: "MAGUINDA",
            },
            {
              name: "MAHAY",
            },
            {
              name: "MAHOGANY POB. (BGY. 21)",
            },
            {
              name: "MAIBU",
            },
            {
              name: "MANDAMO",
            },
            {
              name: "MANILA DE BUGABUS",
            },
            {
              name: "MAON POB. (BGY. 1)",
            },
            {
              name: "MASAO",
            },
            {
              name: "MAUG",
            },
            {
              name: "NEW SOCIETY VILLAGE POB. (BGY. 26)",
            },
            {
              name: "NONG-NONG",
            },
            {
              name: "OBRERO POB. (BGY. 18)",
            },
            {
              name: "ONG YIU POB. (BGY. 16)",
            },
            {
              name: "PAGATPATAN",
            },
            {
              name: "PANGABUGAN",
            },
            {
              name: "PIANING",
            },
            {
              name: "PIGDAULAN",
            },
            {
              name: "PINAMANCULAN",
            },
            {
              name: "PORT POYOHON POB. (BGY. 17 - NEW ASIA)",
            },
            {
              name: "RAJAH SOLIMAN POB. (BGY. 4)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN IGNACIO POB. (BGY. 15)",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIKATUNA POB. (BGY. 10)",
            },
            {
              name: "SILONGAN POB. (BGY. 5)",
            },
            {
              name: "SUMILE",
            },
            {
              name: "SUMILIHON",
            },
            {
              name: "TAGABACA",
            },
            {
              name: "TAGUIBO",
            },
            {
              name: "TALIGAMAN",
            },
            {
              name: "TANDANG SORA POB. (BGY. 12)",
            },
            {
              name: "TINIWISAN",
            },
            {
              name: "TUNGAO",
            },
            {
              name: "URDUJA POB. (BGY. 9)",
            },
            {
              name: "VILLA KANANGA",
            },
          ],
        },
        "CABADBARAN CITY": {
          name: "CABADBARAN CITY",
          baranggays: [
            {
              name: "ANTONIO LUNA",
            },
            {
              name: "BAY-ANG",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "CAASINAN",
            },
            {
              name: "CABINET",
            },
            {
              name: "CALAMBA",
            },
            {
              name: "CALIBUNAN",
            },
            {
              name: "COMAGASCAS",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "KATUGASAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LA UNION",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAHABA",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 10",
            },
            {
              name: "POBLACION 11",
            },
            {
              name: "POBLACION 12",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "POBLACION 4",
            },
            {
              name: "POBLACION 5",
            },
            {
              name: "POBLACION 6",
            },
            {
              name: "POBLACION 7",
            },
            {
              name: "POBLACION 8",
            },
            {
              name: "POBLACION 9",
            },
            {
              name: "PUTING BATO",
            },
            {
              name: "SANGHAN",
            },
            {
              name: "SORIANO",
            },
            {
              name: "TOLOSA",
            },
          ],
        },
        CARMEN: {
          name: "CARMEN",
          baranggays: [
            {
              name: "CAHAYAGAN",
            },
            {
              name: "CARMEN",
            },
            {
              name: "GOSOON",
            },
            {
              name: "MANOLIGAO",
            },
            {
              name: "POBLACION (CARMEN)",
            },
            {
              name: "ROJALES",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "TAGCATONG",
            },
            {
              name: "VINAPOR",
            },
          ],
        },
        JABONGA: {
          name: "JABONGA",
          baranggays: [
            {
              name: "A. BELTRAN (CAMALIG)",
            },
            {
              name: "BALEGUIAN",
            },
            {
              name: "BANGONAY",
            },
            {
              name: "BUNGA",
            },
            {
              name: "COLORADO",
            },
            {
              name: "CUYAGO",
            },
            {
              name: "JABONGA",
            },
            {
              name: "LIBAS",
            },
            {
              name: "MAGDAGOOC",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARAIGING",
            },
            {
              name: "POBLACION (JABONGA)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        KITCHARAO: {
          name: "KITCHARAO",
          baranggays: [
            {
              name: "BANGAYAN",
            },
            {
              name: "CANAWAY",
            },
            {
              name: "CROSSING",
            },
            {
              name: "HINIMBANGAN",
            },
            {
              name: "JALIOBONG",
            },
            {
              name: "KITCHARAO",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANGAY",
            },
            {
              name: "SONGKOY",
            },
          ],
        },
        "LAS NIEVES": {
          name: "LAS NIEVES",
          baranggays: [
            {
              name: "AMBACON",
            },
            {
              name: "BALUNGAGAN",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "CASIKLAN",
            },
            {
              name: "CONSORCIA",
            },
            {
              name: "DURIAN",
            },
            {
              name: "EDUARDO G. MONTILLA (CAMBOAYON)",
            },
            {
              name: "IBUAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LAS NIEVES",
            },
            {
              name: "LINGAYAO",
            },
            {
              name: "MALICATO",
            },
            {
              name: "MANINGALAO",
            },
            {
              name: "MARCOS CALO",
            },
            {
              name: "MAT-I",
            },
            {
              name: "PINANA-AN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TINUCORAN",
            },
          ],
        },
        MAGALLANES: {
          name: "MAGALLANES",
          baranggays: [
            {
              name: "BUHANG",
            },
            {
              name: "CALOC-AN",
            },
            {
              name: "GUIASAN",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "MARCOS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TAOD-OY",
            },
          ],
        },
        NASIPIT: {
          name: "NASIPIT",
          baranggays: [
            {
              name: "ACLAN",
            },
            {
              name: "AMONTAY",
            },
            {
              name: "ATA-ATAHON",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "CAMAGONG",
            },
            {
              name: "CUBI-CUBI",
            },
            {
              name: "CULIT",
            },
            {
              name: "JAGUIMITAN",
            },
            {
              name: "KINABJANGAN",
            },
            {
              name: "NASIPIT",
            },
            {
              name: "PUNTA",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TRIANGULO",
            },
          ],
        },
        "R.T.R": {
          name: "R.T.R",
          baranggays: [
            {
              name: "R.T.R",
            },
          ],
        },
        "REMEDIOS T. ROMUALDEZ": {
          name: "REMEDIOS T. ROMUALDEZ",
          baranggays: [
            {
              name: "BALANGBALANG",
            },
            {
              name: "BASILISA",
            },
            {
              name: "HUMILOG",
            },
            {
              name: "PANAYTAYON",
            },
            {
              name: "POBLACION I (AGAY)",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "TAGBONGABONG",
            },
          ],
        },
        SANTIAGO: {
          name: "SANTIAGO",
          baranggays: [
            {
              name: "CURVA",
            },
            {
              name: "ESTANISLAO MORGADO",
            },
            {
              name: "JAGUPIT",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "PANGAYLAN-IP",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "TAGBUYACAN",
            },
          ],
        },
        TUBAY: {
          name: "TUBAY",
          baranggays: [
            {
              name: "BINUANGAN",
            },
            {
              name: "CABAYAWA",
            },
            {
              name: "DOÑA ROSARIO",
            },
            {
              name: "DOÑA TELESFORA",
            },
            {
              name: "LA FRATERNIDAD",
            },
            {
              name: "LAWIGAN",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "TAGMAMARKAY",
            },
            {
              name: "TAGPANGAHOY",
            },
            {
              name: "TINIGBASAN",
            },
            {
              name: "TUBAY",
            },
            {
              name: "VICTORY",
            },
          ],
        },
      },
    },
    "AGUSAN DEL SUR": {
      name: "AGUSAN DEL SUR",
      region: "REGION 13",
      municipalities: {
        BAYUGAN: {
          name: "BAYUGAN",
          baranggays: [
            {
              name: "BAYUGAN",
            },
            {
              name: "BERSEBA",
            },
            {
              name: "BUCAC",
            },
            {
              name: "CAGBAS",
            },
            {
              name: "CALAITAN",
            },
            {
              name: "CANAYUGAN",
            },
            {
              name: "CHARITO",
            },
            {
              name: "CLARO CORTEZ",
            },
            {
              name: "FILI",
            },
            {
              name: "GAMAO",
            },
            {
              name: "GETSEMANE",
            },
            {
              name: "GRACE ESTATE",
            },
            {
              name: "HAMOGAWAY",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGKIANGKANG",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MARCELINA",
            },
            {
              name: "MAYGATASAN",
            },
            {
              name: "MONTIVESTA",
            },
            {
              name: "MT. ARARAT",
            },
            {
              name: "MT. CARMEL",
            },
            {
              name: "MT. OLIVE",
            },
            {
              name: "NEW SALEM",
            },
            {
              name: "NOLI",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PANAYTAY",
            },
            {
              name: "PINAGALAAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGMONE",
            },
            {
              name: "SAGUMA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA IRENE",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGLATAWAN",
            },
            {
              name: "TAGLIBAS",
            },
            {
              name: "TAGUBAY",
            },
            {
              name: "VERDU",
            },
            {
              name: "VILLA UNDAYON",
            },
            {
              name: "WAWA",
            },
          ],
        },
        BUNAWAN: {
          name: "BUNAWAN",
          baranggays: [
            {
              name: "BUNAWAN",
            },
            {
              name: "BUNAWAN BROOK",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAMBALILI",
            },
            {
              name: "NUEVA ERA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SAN TEODORO",
            },
          ],
        },
        ESPERANZA: {
          name: "ESPERANZA",
          baranggays: [
            {
              name: "AGSABU",
            },
            {
              name: "AGUINALDO",
            },
            {
              name: "ANOLINGAN",
            },
            {
              name: "BAKINGKING",
            },
            {
              name: "BALUBO",
            },
            {
              name: "BENTAHON",
            },
            {
              name: "BUNAGUIT",
            },
            {
              name: "CATMONON",
            },
            {
              name: "CEBULAN",
            },
            {
              name: "CONCORDIA",
            },
            {
              name: "CROSSING LUNA",
            },
            {
              name: "CUBO",
            },
            {
              name: "DAKUTAN",
            },
            {
              name: "DUANGAN",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "GUIBONON",
            },
            {
              name: "HAWILIAN",
            },
            {
              name: "KALABUAN",
            },
            {
              name: "KINAMAYBAY",
            },
            {
              name: "LABAO",
            },
            {
              name: "LANGAG",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MAC-ARTHUR",
            },
            {
              name: "MAHAGCOT",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "MILAGROS",
            },
            {
              name: "NATO",
            },
            {
              name: "NEW GINGOOG",
            },
            {
              name: "ODIONG",
            },
            {
              name: "ORO",
            },
            {
              name: "PIGLAWIGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "REMEDIOS",
            },
            {
              name: "SALUG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN TORIBIO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SEGUNDA",
            },
            {
              name: "SINAKUNGAN",
            },
            {
              name: "TAGABASE",
            },
            {
              name: "TAGANAHAW",
            },
            {
              name: "TAGBALILI",
            },
            {
              name: "TAHINA",
            },
            {
              name: "TANDANG SORA",
            },
            {
              name: "VALENTINA",
            },
          ],
        },
        "LA PAZ": {
          name: "LA PAZ",
          baranggays: [
            {
              name: "ANGELES",
            },
            {
              name: "BATAAN",
            },
            {
              name: "COMOTA",
            },
            {
              name: "HALAPITAN",
            },
            {
              name: "KASAPA II",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LANGASIAN",
            },
            {
              name: "LYDIA",
            },
            {
              name: "OSMEÑA, SR.",
            },
            {
              name: "PANAGANGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABANG ADGAWAN",
            },
            {
              name: "SAGUNTO",
            },
            {
              name: "SAN PATRICIO",
            },
            {
              name: "VALENTINA",
            },
            {
              name: "VILLA PAZ",
            },
          ],
        },
        LORETO: {
          name: "LORETO",
          baranggays: [
            {
              name: "BINUCAYAN",
            },
            {
              name: "JOHNSON",
            },
            {
              name: "KASAPA",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LORETO",
            },
            {
              name: "MAGAUD",
            },
            {
              name: "NUEVA GRACIA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABUD",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA TERESA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "VIOLANTA",
            },
            {
              name: "WALOE",
            },
          ],
        },
        PROSPERIDAD: {
          name: "PROSPERIDAD",
          baranggays: [
            {
              name: "AURORA",
            },
            {
              name: "AWA",
            },
            {
              name: "AZPETIA",
            },
            {
              name: "LA CARIDAD",
            },
            {
              name: "LA PERIAN",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "LA SUERTE",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LAS NAVAS",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LOS ARCOS",
            },
            {
              name: "LUCENA",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAPAGA",
            },
            {
              name: "NAPO",
            },
            {
              name: "NEW MAUG",
            },
            {
              name: "PATIN-AY",
            },
            {
              name: "POBLACION (BAHBAH)",
            },
            {
              name: "PROSPERIDAD",
            },
            {
              name: "SALIMBOGAON",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA IRENE",
            },
            {
              name: "SANTA MARIA",
            },
          ],
        },
        ROSARIO: {
          name: "ROSARIO",
          baranggays: [
            {
              name: "BAYUGAN 3",
            },
            {
              name: "CABANTAO",
            },
            {
              name: "CABAWAN",
            },
            {
              name: "LIBUAC",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MARFIL",
            },
            {
              name: "NOVELE",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAGBAYAGAN",
            },
            {
              name: "WASI-AN",
            },
          ],
        },
        "SAN FRANCISCO": {
          name: "SAN FRANCISCO",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BAYUGAN 2",
            },
            {
              name: "BITAN-AGAN",
            },
            {
              name: "BORBON",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "CAIMPUGAN",
            },
            {
              name: "DAS-AGAN",
            },
            {
              name: "EBRO",
            },
            {
              name: "HUBANG",
            },
            {
              name: "KARAUS",
            },
            {
              name: "LADGADAN",
            },
            {
              name: "LAPINIGAN",
            },
            {
              name: "LUCAC",
            },
            {
              name: "MATE",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "ORMACA",
            },
            {
              name: "PASTA",
            },
            {
              name: "PISA-AN",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "TAGAPUA",
            },
          ],
        },
        "SAN LUIS": {
          name: "SAN LUIS",
          baranggays: [
            {
              name: "ANISLAGAN",
            },
            {
              name: "BALIT",
            },
            {
              name: "BAYLO",
            },
            {
              name: "BINICALAN",
            },
            {
              name: "CECILIA",
            },
            {
              name: "COALICION",
            },
            {
              name: "CULI",
            },
            {
              name: "DIMASALANG",
            },
            {
              name: "DON ALEJANDRO",
            },
            {
              name: "DON PEDRO",
            },
            {
              name: "DOÑA FLAVIA",
            },
            {
              name: "DOÑA MAXIMA",
            },
            {
              name: "MAHAGSAY",
            },
            {
              name: "MAHAPAG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MURITULA",
            },
            {
              name: "NUEVO TRABAJO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLICARPO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "WEGGUAM",
            },
          ],
        },
        "SANTA JOSEFA": {
          name: "SANTA JOSEFA",
          baranggays: [
            {
              name: "ANGAS",
            },
            {
              name: "AURORA",
            },
            {
              name: "AWAO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PATROCINIO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SAYON",
            },
            {
              name: "TAPAZ",
            },
          ],
        },
        SIBAGAT: {
          name: "SIBAGAT",
          baranggays: [
            {
              name: "AFGA",
            },
            {
              name: "ANAHAWAN",
            },
            {
              name: "BANAGBANAG",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "EL RIO",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KIOYA",
            },
            {
              name: "KOLAMBUGAN",
            },
            {
              name: "MAGKALAPE",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "NEW TUBIGON",
            },
            {
              name: "PADIAY",
            },
            {
              name: "PEREZ",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SIBAGAT",
            },
            {
              name: "SINAI",
            },
            {
              name: "TABON-TABON",
            },
            {
              name: "TAG-UYANGO",
            },
            {
              name: "VILLANGIT",
            },
          ],
        },
        "STA. JOSEFA": {
          name: "STA. JOSEFA",
          baranggays: [
            {
              name: "STA. JOSEFA",
            },
          ],
        },
        TALACOGON: {
          name: "TALACOGON",
          baranggays: [
            {
              name: "BATUCAN",
            },
            {
              name: "BUENAGRACIA",
            },
            {
              name: "CAUSWAGAN",
            },
            {
              name: "CULI",
            },
            {
              name: "DEL MONTE",
            },
            {
              name: "DESAMPARADOS",
            },
            {
              name: "LA FLORA",
            },
            {
              name: "LABNIG",
            },
            {
              name: "MAHARLIKA",
            },
            {
              name: "MARBON",
            },
            {
              name: "SABANG GIBUNG",
            },
            {
              name: "SAN AGUSTIN (POB.)",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "TALACOGON",
            },
            {
              name: "ZAMORA",
            },
            {
              name: "ZILLOVIA",
            },
          ],
        },
        TRENTO: {
          name: "TRENTO",
          baranggays: [
            {
              name: "BASA",
            },
            {
              name: "CEBOLIN",
            },
            {
              name: "CUEVAS",
            },
            {
              name: "KAPATUNGAN",
            },
            {
              name: "LANGKILA-AN",
            },
            {
              name: "MANAT",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "PANGYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULANG-LUPA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN IGNACIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "TRENTO",
            },
            {
              name: "TUDELA",
            },
          ],
        },
        VERUELA: {
          name: "VERUELA",
          baranggays: [
            {
              name: "ANITAP",
            },
            {
              name: "BACAY II",
            },
            {
              name: "BINONGAN",
            },
            {
              name: "CAIGANGAN",
            },
            {
              name: "CANDIIS",
            },
            {
              name: "DEL MONTE",
            },
            {
              name: "DON MATEO",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LA FORTUNA",
            },
            {
              name: "LIMOT",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MASAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA EMELIA",
            },
            {
              name: "SAWAGAN",
            },
            {
              name: "SINOBONG",
            },
            {
              name: "SISIMON",
            },
            {
              name: "VERUELA",
            },
          ],
        },
      },
    },
    AKLAN: {
      name: "AKLAN",
      region: "REGION 6",
      municipalities: {
        ALTAVAS: {
          name: "ALTAVAS",
          baranggays: [
            {
              name: "ALTAVAS",
            },
            {
              name: "CABANGILA",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CATMON",
            },
            {
              name: "DALIPDIP",
            },
            {
              name: "GINICTAN",
            },
            {
              name: "LINAYASAN",
            },
            {
              name: "LUMAYNAY",
            },
            {
              name: "LUPO",
            },
            {
              name: "MAN-UP",
            },
            {
              name: "ODIONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUINASAY-AN",
            },
            {
              name: "TALON",
            },
            {
              name: "TIBIAO",
            },
          ],
        },
        BALETE: {
          name: "BALETE",
          baranggays: [
            {
              name: "ARANAS",
            },
            {
              name: "ARCANGEL",
            },
            {
              name: "BALETE",
            },
            {
              name: "CALIZO",
            },
            {
              name: "CORTES",
            },
            {
              name: "FELICIANO",
            },
            {
              name: "FULGENCIO",
            },
            {
              name: "GUANKO",
            },
            {
              name: "MORALES",
            },
            {
              name: "OQUENDO",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        BANGA: {
          name: "BANGA",
          baranggays: [
            {
              name: "AGBANAWAN",
            },
            {
              name: "BACAN",
            },
            {
              name: "BADIANGAN",
            },
            {
              name: "BANGA",
            },
            {
              name: "CERRUDO",
            },
            {
              name: "CUPANG",
            },
            {
              name: "DAGUITAN",
            },
            {
              name: "DAJA NORTE",
            },
            {
              name: "DAJA SUR",
            },
            {
              name: "DINGLE",
            },
            {
              name: "JUMARAP",
            },
            {
              name: "LAPNAG",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LINABUAN SUR",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "MANGAN",
            },
            {
              name: "MUGUING",
            },
            {
              name: "PAGSANGHAN",
            },
            {
              name: "PALALE",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLO",
            },
            {
              name: "POLOCATE",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SIBALEW",
            },
            {
              name: "SIGCAY",
            },
            {
              name: "TABA-AO",
            },
            {
              name: "TABAYON",
            },
            {
              name: "TINAPUAY",
            },
            {
              name: "TORRALBA",
            },
            {
              name: "UGSOD",
            },
            {
              name: "VENTURANZA",
            },
          ],
        },
        BATAN: {
          name: "BATAN",
          baranggays: [
            {
              name: "AMBOLONG",
            },
            {
              name: "ANGAS",
            },
            {
              name: "BATAN",
            },
            {
              name: "BAY-ANG",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAIYANG",
            },
            {
              name: "CAMALIGAN",
            },
            {
              name: "CAMANCI",
            },
            {
              name: "IPIL",
            },
            {
              name: "LALAB",
            },
            {
              name: "LUPIT",
            },
            {
              name: "MAGPAG-ONG",
            },
            {
              name: "MAGUBAHAY",
            },
            {
              name: "MAMBUQUIAO",
            },
            {
              name: "MAN-UP",
            },
            {
              name: "MANDONG",
            },
            {
              name: "NAPTI",
            },
            {
              name: "PALAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SONGCOLAN",
            },
            {
              name: "TABON",
            },
          ],
        },
        BURUANGA: {
          name: "BURUANGA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BAGONGBAYAN",
            },
            {
              name: "BALUSBOS",
            },
            {
              name: "BEL-IS",
            },
            {
              name: "BURUANGA",
            },
            {
              name: "CABUGAN",
            },
            {
              name: "EL PROGRESO",
            },
            {
              name: "HABANA",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MAYAPAY",
            },
            {
              name: "NAZARETH",
            },
            {
              name: "PANILONGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTANDER",
            },
            {
              name: "TAG-OSIP",
            },
            {
              name: "TIGUM",
            },
          ],
        },
        IBAJAY: {
          name: "IBAJAY",
          baranggays: [
            {
              name: "AGBAGO",
            },
            {
              name: "AGDUGAYAN",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "APARICIO",
            },
            {
              name: "AQUINO",
            },
            {
              name: "ASLUM",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUGTONGBATO",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAPILIJAN",
            },
            {
              name: "COLONGCOLONG",
            },
            {
              name: "IBAJAY",
            },
            {
              name: "LAGUINBANUA",
            },
            {
              name: "MABUSAO",
            },
            {
              name: "MALINDOG",
            },
            {
              name: "MALOCO",
            },
            {
              name: "MINA-A",
            },
            {
              name: "MONLAQUE",
            },
            {
              name: "NAILE",
            },
            {
              name: "NAISUD",
            },
            {
              name: "NALIGUSAN",
            },
            {
              name: "ONDOY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLO",
            },
            {
              name: "REGADOR",
            },
            {
              name: "RIVERA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAGBAYA",
            },
            {
              name: "TUL-ANG",
            },
            {
              name: "UNAT",
            },
            {
              name: "YAWAN",
            },
          ],
        },
        KALIBO: {
          name: "KALIBO",
          baranggays: [
            {
              name: "ANDAGAW",
            },
            {
              name: "BACHAW NORTE",
            },
            {
              name: "BACHAW SUR",
            },
            {
              name: "BRIONES",
            },
            {
              name: "BUSWANG NEW",
            },
            {
              name: "BUSWANG OLD",
            },
            {
              name: "CAANO",
            },
            {
              name: "ESTANCIA",
            },
            {
              name: "KALIBO",
            },
            {
              name: "LINABUAN NORTE",
            },
            {
              name: "MABILO",
            },
            {
              name: "MOBO",
            },
            {
              name: "NALOOK",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POOK",
            },
            {
              name: "TIGAYON",
            },
            {
              name: "TINIGAW",
            },
          ],
        },
        LEZO: {
          name: "LEZO",
          baranggays: [
            {
              name: "AGCAWILAN",
            },
            {
              name: "BAGTO",
            },
            {
              name: "BUGASONGAN",
            },
            {
              name: "CARUGDOG",
            },
            {
              name: "COGON",
            },
            {
              name: "IBAO",
            },
            {
              name: "LEZO",
            },
            {
              name: "MINA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA CRUZ BIGAA",
            },
            {
              name: "SILAKAT-NONOK",
            },
            {
              name: "TAYHAWAN",
            },
          ],
        },
        LIBACAO: {
          name: "LIBACAO",
          baranggays: [
            {
              name: "AGMAILIG",
            },
            {
              name: "ALFONSO XII",
            },
            {
              name: "BATOBATO",
            },
            {
              name: "BONZA",
            },
            {
              name: "CALACABIAN",
            },
            {
              name: "CALAMCAN",
            },
            {
              name: "CAN-AWAN",
            },
            {
              name: "CASIT-AN",
            },
            {
              name: "DALAGSA-AN",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "JANLUD",
            },
            {
              name: "JULITA",
            },
            {
              name: "LIBACAO",
            },
            {
              name: "LUCTOGA",
            },
            {
              name: "MAGUGBA",
            },
            {
              name: "MANIKA",
            },
            {
              name: "OGSIP",
            },
            {
              name: "ORTEGA",
            },
            {
              name: "OYANG",
            },
            {
              name: "PAMPANGO",
            },
            {
              name: "PINONOY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIVERA",
            },
            {
              name: "ROSAL",
            },
            {
              name: "SIBALEW",
            },
          ],
        },
        MADALAG: {
          name: "MADALAG",
          baranggays: [
            {
              name: "ALAMINOS",
            },
            {
              name: "ALAS-AS",
            },
            {
              name: "BACYANG",
            },
            {
              name: "BALACTASAN",
            },
            {
              name: "CABANGAHAN",
            },
            {
              name: "CABILAWAN",
            },
            {
              name: "CATABANA",
            },
            {
              name: "DIT-ANA",
            },
            {
              name: "GALICIA",
            },
            {
              name: "GUINATU-AN",
            },
            {
              name: "LOGOHON",
            },
            {
              name: "MADALAG",
            },
            {
              name: "MAMBA",
            },
            {
              name: "MARIA CRISTINA",
            },
            {
              name: "MEDINA",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "NAPNOT",
            },
            {
              name: "PANG-ITAN",
            },
            {
              name: "PANINGAYAN",
            },
            {
              name: "PANIPIASON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SINGAY",
            },
            {
              name: "TALANGBAN",
            },
            {
              name: "TALIMAGAO",
            },
            {
              name: "TIGBAWAN",
            },
          ],
        },
        MAKATO: {
          name: "MAKATO",
          baranggays: [
            {
              name: "AGBALOGO",
            },
            {
              name: "AGLUCAY",
            },
            {
              name: "ALIBAGON",
            },
            {
              name: "BAGONG BARRIO",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "CABATANGA",
            },
            {
              name: "CAJILO",
            },
            {
              name: "CALANGCANG",
            },
            {
              name: "CALIMBAJAN",
            },
            {
              name: "CASTILLO",
            },
            {
              name: "CAYANGWAN",
            },
            {
              name: "DUMGA",
            },
            {
              name: "LIBANG",
            },
            {
              name: "MAKATO",
            },
            {
              name: "MANTIGUIB",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TIBIAWAN",
            },
            {
              name: "TINA",
            },
            {
              name: "TUGAS",
            },
          ],
        },
        MALAY: {
          name: "MALAY",
          baranggays: [
            {
              name: "ARGAO",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BALUSBUS",
            },
            {
              name: "CABULIHAN",
            },
            {
              name: "CATICLAN",
            },
            {
              name: "COGON",
            },
            {
              name: "CUBAY NORTE",
            },
            {
              name: "CUBAY SUR",
            },
            {
              name: "DUMLOG",
            },
            {
              name: "MALAY",
            },
            {
              name: "MANOC-MANOC",
            },
            {
              name: "MOTAG",
            },
            {
              name: "NAASUG",
            },
            {
              name: "NABAOY",
            },
            {
              name: "NAPAAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN VIRAY",
            },
            {
              name: "YAPAK",
            },
          ],
        },
        MALINAO: {
          name: "MALINAO",
          baranggays: [
            {
              name: "BANAYBANAY",
            },
            {
              name: "BIGA-A",
            },
            {
              name: "BULABUD",
            },
            {
              name: "CABAYUGAN",
            },
            {
              name: "CAPATAGA",
            },
            {
              name: "COGON",
            },
            {
              name: "DANGCALAN",
            },
            {
              name: "KINALANGAY NUEVO",
            },
            {
              name: "KINALANGAY VIEJO",
            },
            {
              name: "LILO-AN",
            },
            {
              name: "MALANDAYON",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MANHANIP",
            },
            {
              name: "NAVITAS",
            },
            {
              name: "OSMAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAN DIMAS",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SIPAC",
            },
            {
              name: "SUGNOD",
            },
            {
              name: "TAMBUAN",
            },
            {
              name: "TIGPALAS",
            },
          ],
        },
        NABAS: {
          name: "NABAS",
          baranggays: [
            {
              name: "ALIMBO-BAYBAY",
            },
            {
              name: "BUENAFORTUNA",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "GIBON",
            },
            {
              name: "HABANA",
            },
            {
              name: "LASERNA",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "MATABANA",
            },
            {
              name: "NABAS",
            },
            {
              name: "NAGUSTAN",
            },
            {
              name: "PAWA",
            },
            {
              name: "PINATUAD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SOLIDO",
            },
            {
              name: "TAGOROROC",
            },
            {
              name: "TOLEDO",
            },
            {
              name: "UNIDOS",
            },
            {
              name: "UNION",
            },
          ],
        },
        "NEW WASHINGTON": {
          name: "NEW WASHINGTON",
          baranggays: [
            {
              name: "CANDELARIA",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "DUMAGUIT",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GUINBALIWAN",
            },
            {
              name: "JALAS",
            },
            {
              name: "JUGAS",
            },
            {
              name: "LAWA-AN",
            },
            {
              name: "MABILO",
            },
            {
              name: "MATAPHAO",
            },
            {
              name: "NEW WASHINGTON",
            },
            {
              name: "OCHANDO",
            },
            {
              name: "PINAMUK-AN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLO",
            },
            {
              name: "PUIS",
            },
            {
              name: "TAMBAK",
            },
          ],
        },
        NUMANCIA: {
          name: "NUMANCIA",
          baranggays: [
            {
              name: "ALBASAN",
            },
            {
              name: "ALIPUTOS",
            },
            {
              name: "BADIO",
            },
            {
              name: "BUBOG",
            },
            {
              name: "BULWANG",
            },
            {
              name: "CAMANCI NORTE",
            },
            {
              name: "CAMANCI SUR",
            },
            {
              name: "DONGON EAST",
            },
            {
              name: "DONGON WEST",
            },
            {
              name: "JOYAO-JOYAO",
            },
            {
              name: "LAGUINBANUA EAST",
            },
            {
              name: "LAGUINBANUA WEST",
            },
            {
              name: "MARIANOS",
            },
            {
              name: "NAVITAS",
            },
            {
              name: "NUMANCIA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUSIW",
            },
            {
              name: "TABANGKA",
            },
          ],
        },
        TANGALAN: {
          name: "TANGALAN",
          baranggays: [
            {
              name: "AFGA",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DUMATAD",
            },
            {
              name: "JAWILI",
            },
            {
              name: "LANIPGA",
            },
            {
              name: "NAPATAG",
            },
            {
              name: "PANAYAKAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUDIOT",
            },
            {
              name: "TAGAS",
            },
            {
              name: "TAMALAGON",
            },
            {
              name: "TAMOKOE",
            },
            {
              name: "TANGALAN",
            },
            {
              name: "TONDOG",
            },
            {
              name: "VIVO",
            },
          ],
        },
      },
    },
    ALBAY: {
      name: "ALBAY",
      region: "REGION 5",
      municipalities: {
        BACACAY: {
          name: "BACACAY",
          baranggays: [
            {
              name: "BACACAY",
            },
            {
              name: "BACLAYON",
            },
            {
              name: "BANAO",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 14 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BARIW",
            },
            {
              name: "BASUD",
            },
            {
              name: "BAYANDONG",
            },
            {
              name: "BONGA (UPPER)",
            },
            {
              name: "BUANG",
            },
            {
              name: "BUSDAC (SAN JOSE)",
            },
            {
              name: "CABASAN",
            },
            {
              name: "CAGBULACAO",
            },
            {
              name: "CAGRARAY",
            },
            {
              name: "CAJOGUTAN",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "DAMACAN",
            },
            {
              name: "GUBAT ILAWOD",
            },
            {
              name: "GUBAT IRAYA",
            },
            {
              name: "HINDI",
            },
            {
              name: "IGANG",
            },
            {
              name: "LANGATON",
            },
            {
              name: "MANAET",
            },
            {
              name: "MAPULANG DAGA",
            },
            {
              name: "MATAAS",
            },
            {
              name: "MISIBIS",
            },
            {
              name: "NAHAPUNAN",
            },
            {
              name: "NAMANDAY",
            },
            {
              name: "NAMANTAO",
            },
            {
              name: "NAPAO",
            },
            {
              name: "PANARAYON",
            },
            {
              name: "PIGCOBOHAN",
            },
            {
              name: "PILI ILAWOD",
            },
            {
              name: "PILI IRAYA",
            },
            {
              name: "PONGCO (LOWER BONGA)",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SOGOD",
            },
            {
              name: "SULA",
            },
            {
              name: "TAMBILAGAO (TAMBOGNON)",
            },
            {
              name: "TAMBONGON (TAMBILAGAO)",
            },
            {
              name: "TANAGAN",
            },
            {
              name: "USON",
            },
            {
              name: "VINISITAHAN-BASUD (MAINLAND)",
            },
            {
              name: "VINISITAHAN-NAPAO (ISLAND)",
            },
          ],
        },
        CAMALIG: {
          name: "CAMALIG",
          baranggays: [
            {
              name: "ANOLING",
            },
            {
              name: "BALIGANG",
            },
            {
              name: "BANTONAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARIW",
            },
            {
              name: "BINANDERAHAN",
            },
            {
              name: "BINITAYAN",
            },
            {
              name: "BONGABONG",
            },
            {
              name: "CABAGÑAN",
            },
            {
              name: "CABRARAN PEQUEÑO",
            },
            {
              name: "CAGUIBA",
            },
            {
              name: "CALABIDONGAN",
            },
            {
              name: "CAMALIG",
            },
            {
              name: "COMUN",
            },
            {
              name: "COTMON",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "GAPO",
            },
            {
              name: "GOTOB",
            },
            {
              name: "ILAWOD",
            },
            {
              name: "ILULUAN",
            },
            {
              name: "LIBOD",
            },
            {
              name: "LIGBAN",
            },
            {
              name: "MABUNGA",
            },
            {
              name: "MAGOGON",
            },
            {
              name: "MANAWAN",
            },
            {
              name: "MANINILA",
            },
            {
              name: "MINA",
            },
            {
              name: "MITI",
            },
            {
              name: "PALANOG",
            },
            {
              name: "PANOYPOY",
            },
            {
              name: "PARIAAN",
            },
            {
              name: "QUINARTILAN",
            },
            {
              name: "QUIRANGAY",
            },
            {
              name: "QUITINDAY",
            },
            {
              name: "SALUGAN",
            },
            {
              name: "SOLONG",
            },
            {
              name: "SUA",
            },
            {
              name: "SUMLANG",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "TAGOYTOY",
            },
            {
              name: "TALADONG",
            },
            {
              name: "TALOTO",
            },
            {
              name: "TAPLACON",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TUMPA",
            },
          ],
        },
        "CITY OF LIGAO": {
          name: "CITY OF LIGAO",
          baranggays: [
            {
              name: "ABELLA",
            },
            {
              name: "ALLANG",
            },
            {
              name: "AMTIC",
            },
            {
              name: "BACONG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALANAC",
            },
            {
              name: "BALIGANG",
            },
            {
              name: "BARAYONG",
            },
            {
              name: "BASAG",
            },
            {
              name: "BATANG",
            },
            {
              name: "BAY",
            },
            {
              name: "BINANOWAN",
            },
            {
              name: "BINATAGAN (POB.)",
            },
            {
              name: "BOBONSURAN",
            },
            {
              name: "BONGA",
            },
            {
              name: "BUSAC",
            },
            {
              name: "BUSAY",
            },
            {
              name: "CABARIAN",
            },
            {
              name: "CALZADA (POB.)",
            },
            {
              name: "CATBURAWAN",
            },
            {
              name: "CAVASI",
            },
            {
              name: "CITY OF LIGAO",
            },
            {
              name: "CULLIAT",
            },
            {
              name: "DUNAO",
            },
            {
              name: "FRANCIA",
            },
            {
              name: "GUILID",
            },
            {
              name: "HERRERA",
            },
            {
              name: "LAYON",
            },
            {
              name: "MACALIDONG",
            },
            {
              name: "MAHABA",
            },
            {
              name: "MALAMA",
            },
            {
              name: "MAONON",
            },
            {
              name: "NABONTON",
            },
            {
              name: "NASISI",
            },
            {
              name: "OMA-OMA",
            },
            {
              name: "PALAPAS",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PAULBA",
            },
            {
              name: "PAULOG",
            },
            {
              name: "PINAMANIQUIAN",
            },
            {
              name: "PINIT",
            },
            {
              name: "RANAO-RANAO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "TAGPO",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TANDARURA",
            },
            {
              name: "TASTAS",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TINAMPO",
            },
            {
              name: "TIONGSON",
            },
            {
              name: "TOMOLIN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "TULA-TULA GRANDE",
            },
            {
              name: "TULA-TULA PEQUEÑO",
            },
            {
              name: "TUPAS",
            },
          ],
        },
        "DARAGA (LOCSIN)": {
          name: "DARAGA (LOCSIN)",
          baranggays: [
            {
              name: "ALCALA",
            },
            {
              name: "ALOBO",
            },
            {
              name: "ANISLAG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALINAD",
            },
            {
              name: "BAÑADERO",
            },
            {
              name: "BAÑAG",
            },
            {
              name: "BASCARAN",
            },
            {
              name: "BIGAO",
            },
            {
              name: "BINITAYAN",
            },
            {
              name: "BONGALON",
            },
            {
              name: "BUDIAO",
            },
            {
              name: "BURGOS",
            },
            {
              name: "BUSAY",
            },
            {
              name: "CANAROM",
            },
            {
              name: "CULLAT",
            },
            {
              name: "DARAGA (LOCSIN)",
            },
            {
              name: "DELA PAZ",
            },
            {
              name: "DINORONAN",
            },
            {
              name: "GABAWAN",
            },
            {
              name: "GAPO",
            },
            {
              name: "IBAUGAN",
            },
            {
              name: "ILAWOD AREA POB. (DIST. 2)",
            },
            {
              name: "INARADO",
            },
            {
              name: "KIDACO",
            },
            {
              name: "KILICAO",
            },
            {
              name: "KIMANTONG",
            },
            {
              name: "KINAWITAN",
            },
            {
              name: "KIWALO",
            },
            {
              name: "LACAG",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALABOG",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MAOPI",
            },
            {
              name: "MARKET AREA POB. (DIST. 1)",
            },
            {
              name: "MAROROY",
            },
            {
              name: "MATNOG",
            },
            {
              name: "MAYON",
            },
            {
              name: "MI-ISI",
            },
            {
              name: "NABASAN",
            },
            {
              name: "NAMANTAO",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PEÑAFRANCIA",
            },
            {
              name: "SAGPON",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE GRANDE",
            },
            {
              name: "SAN VICENTE PEQUEÑO",
            },
            {
              name: "SIPI",
            },
            {
              name: "TABON-TABON",
            },
            {
              name: "TAGAS",
            },
            {
              name: "TALAHIB",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        GUINOBATAN: {
          name: "GUINOBATAN",
          baranggays: [
            {
              name: "AGPAY",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANAO",
            },
            {
              name: "BATBAT",
            },
            {
              name: "BINOGSACAN LOWER",
            },
            {
              name: "BINOGSACAN UPPER",
            },
            {
              name: "BOLOLO",
            },
            {
              name: "BUBULUSAN",
            },
            {
              name: "CALZADA",
            },
            {
              name: "CATOMAG",
            },
            {
              name: "DOÑA MERCEDES",
            },
            {
              name: "DOÑA TOMASA (MAGATOL)",
            },
            {
              name: "GUINOBATAN",
            },
            {
              name: "ILAWOD",
            },
            {
              name: "INAMNAN GRANDE",
            },
            {
              name: "INAMNAN PEQUEÑO",
            },
            {
              name: "INASCAN",
            },
            {
              name: "IRAYA",
            },
            {
              name: "LOMACAO",
            },
            {
              name: "MAGUIRON",
            },
            {
              name: "MAIPON",
            },
            {
              name: "MALABNIG",
            },
            {
              name: "MALIPO",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MANINILA",
            },
            {
              name: "MAPACO",
            },
            {
              name: "MARCIAL O. RAÑOLA (CABALOAON)",
            },
            {
              name: "MASARAWAG",
            },
            {
              name: "MAURARO",
            },
            {
              name: "MINTO",
            },
            {
              name: "MORERA",
            },
            {
              name: "MULADBUCAD GRANDE",
            },
            {
              name: "MULADBUCAD PEQUEÑO",
            },
            {
              name: "ONGO",
            },
            {
              name: "PALANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POOD",
            },
            {
              name: "QUIBONGBONGAN",
            },
            {
              name: "QUITAGO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE (OGSONG)",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SINUNGTAN",
            },
            {
              name: "TANDARORA",
            },
            {
              name: "TRAVESIA",
            },
          ],
        },
        JOVELLAR: {
          name: "JOVELLAR",
          baranggays: [
            {
              name: "AURORA POB. (BGY. 6)",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAUTISTA",
            },
            {
              name: "CABRARAN",
            },
            {
              name: "CALZADA POB. (BGY. 7)",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "ESTRELLA",
            },
            {
              name: "FLORISTA",
            },
            {
              name: "JOVELLAR",
            },
            {
              name: "MABINI POB. (BGY. 2)",
            },
            {
              name: "MAGSAYSAY POB (BGY. 4)",
            },
            {
              name: "MAMLAD",
            },
            {
              name: "MAOGOG",
            },
            {
              name: "MERCADO POB. (BGY. 5)",
            },
            {
              name: "PLAZA POB. (BGY. 3)",
            },
            {
              name: "QUITINDAY POB. (BGY. 8)",
            },
            {
              name: "RIZAL POB. (BGY. 1)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SINAGARAN",
            },
            {
              name: "VILLA PAZ",
            },
            {
              name: "WHITE DEER POB. (BGY. 9)",
            },
          ],
        },
        "LEGAZPI CITY": {
          name: "LEGAZPI CITY",
          baranggays: [
            {
              name: "BGY. 1 - EM'S BARRIO (POB.)",
            },
            {
              name: "BGY. 10 - CABUGAO",
            },
            {
              name: "BGY. 11 - MAOYOD POB. (BGY. 10 AND 11)",
            },
            {
              name: "BGY. 12 - TULA-TULA (POB.)",
            },
            {
              name: "BGY. 13 - ILAWOD WEST POB. (ILAWOD 1)",
            },
            {
              name: "BGY. 14 - ILAWOD POB. (ILAWOD 2)",
            },
            {
              name: "BGY. 15 - ILAWOD EAST POB. (ILAWOD 3)",
            },
            {
              name: "BGY. 16 - KAWIT-EAST WASHINGTON DRIVE (POB.)",
            },
            {
              name: "BGY. 17 - RIZAL SREET., ILAWOD (POB.)",
            },
            {
              name: "BGY. 18 - CABAGÑAN WEST (POB.)",
            },
            {
              name: "BGY. 19 - CABAGÑAN",
            },
            {
              name: "BGY. 2 - EM'S BARRIO SOUTH (POB.)",
            },
            {
              name: "BGY. 20 - CABAGÑAN EAST (POB.)",
            },
            {
              name: "BGY. 21 - BINANUAHAN WEST (POB.)",
            },
            {
              name: "BGY. 22 - BINANUAHAN EAST (POB.)",
            },
            {
              name: "BGY. 23 - IMPERIAL COURT SUBD. (POB.)",
            },
            {
              name: "BGY. 24 - RIZAL STREET",
            },
            {
              name: "BGY. 25 - LAPU-LAPU (POB.)",
            },
            {
              name: "BGY. 26 - DINAGAAN (POB.)",
            },
            {
              name: "BGY. 27 - VICTORY VILLAGE SOUTH (POB.)",
            },
            {
              name: "BGY. 28 - VICTORY VILLAGE NORTH (POB.)",
            },
            {
              name: "BGY. 29 - SABANG (POB.)",
            },
            {
              name: "BGY. 3 - EM'S BARRIO EAST (POB.)",
            },
            {
              name: "BGY. 30 - PIGCALE (POB.)",
            },
            {
              name: "BGY. 31 - CENTRO-BAYBAY (POB.)",
            },
            {
              name: "BGY. 32 - SAN ROQUE (BGY. 66)",
            },
            {
              name: "BGY. 33 - PNR-PEÑARANDA ST.-IRAYA (POB.)",
            },
            {
              name: "BGY. 34 - ORO SITE-MAGALLANES ST. (POB.)",
            },
            {
              name: "BGY. 35 - TINAGO (POB.)",
            },
            {
              name: "BGY. 36 - KAPANTAWAN (POB.)",
            },
            {
              name: "BGY. 37 - BITANO (POB.)",
            },
            {
              name: "BGY. 38 - GOGON (BGY. 54)",
            },
            {
              name: "BGY. 39 - BONOT (POB.)",
            },
            {
              name: "BGY. 4 - SAGPON POB. (SAGPON 1)",
            },
            {
              name: "BGY. 40 - CRUZADA (BGY. 52)",
            },
            {
              name: "BGY. 41 - BOGTONG (BGY. 45)",
            },
            {
              name: "BGY. 42 - RAWIS (BGY. 65)",
            },
            {
              name: "BGY. 43 - TAMAOYAN (BGY. 67)",
            },
            {
              name: "BGY. 44 - PAWA (BGY. 61)",
            },
            {
              name: "BGY. 45 - DITA (BGY. 51)",
            },
            {
              name: "BGY. 46 - SAN JOAQUIN (BGY. 64)",
            },
            {
              name: "BGY. 47 - ARIMBAY",
            },
            {
              name: "BGY. 48 - BAGONG ABRE (BGY. 42)",
            },
            {
              name: "BGY. 49 - BIGAA (BGY. 44)",
            },
            {
              name: "BGY. 5 - SAGMIN POB. (SAGPON 2)",
            },
            {
              name: "BGY. 50 - PADANG (BGY. 60)",
            },
            {
              name: "BGY. 51 - BUYUAN (BGY. 49)",
            },
            {
              name: "BGY. 52 - MATANAG",
            },
            {
              name: "BGY. 53 - BONGA (BGY. 48)",
            },
            {
              name: "BGY. 54 - MABINIT (BGY. 59)",
            },
            {
              name: "BGY. 55 - ESTANZA (BGY. 53)",
            },
            {
              name: "BGY. 56 - TAYSAN (BGY. 68)",
            },
            {
              name: "BGY. 57 - DAP-DAP (BGY. 69)",
            },
            {
              name: "BGY. 58 - BURAGWIS",
            },
            {
              name: "BGY. 59 - PURO (BGY. 63)",
            },
            {
              name: "BGY. 6 - BAÑADERO POB. (SAGPON 3)",
            },
            {
              name: "BGY. 60 - LAMBA",
            },
            {
              name: "BGY. 61 - MASLOG (BGY. 58)",
            },
            {
              name: "BGY. 62 - HOMAPON (BGY. 55)",
            },
            {
              name: "BGY. 63 - MARIAWA (BGY. 56)",
            },
            {
              name: "BGY. 64 - BAGACAY (BGY. 41 BAGACAY)",
            },
            {
              name: "BGY. 65 - IMALNOD (BGY. 57)",
            },
            {
              name: "BGY. 66 - BANQUEROHAN (BGY. 43)",
            },
            {
              name: "BGY. 67 - BARIIS (BGY. 46)",
            },
            {
              name: "BGY. 68 - SAN FRANCISCO (BGY. 62)",
            },
            {
              name: "BGY. 69 - BUENAVISTA (BGY.47)",
            },
            {
              name: "BGY. 7 - BAÑO (POB.)",
            },
            {
              name: "BGY. 70 - CAGBACONG (BGY. 50)",
            },
            {
              name: "BGY. 8 - BAGUMBAYAN (POB.)",
            },
            {
              name: "BGY. 9 - PINARIC (POB.)",
            },
            {
              name: "LEGAZPI CITY",
            },
          ],
        },
        LIBON: {
          name: "LIBON",
          baranggays: [
            {
              name: "ALONGONG",
            },
            {
              name: "APUD",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BARIW",
            },
            {
              name: "BONBON",
            },
            {
              name: "BUGA",
            },
            {
              name: "BULUSAN",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CAGUSCOS",
            },
            {
              name: "EAST CARISAC",
            },
            {
              name: "HARIGUE",
            },
            {
              name: "LIBON",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "LINAO",
            },
            {
              name: "MABAYAWAS",
            },
            {
              name: "MACABUGOS",
            },
            {
              name: "MAGALLANG",
            },
            {
              name: "MALABIGA",
            },
            {
              name: "MARAYAG",
            },
            {
              name: "MATARA",
            },
            {
              name: "MOLOSBOLOS",
            },
            {
              name: "NATASAN",
            },
            {
              name: "NIÑO JESUS (SANTO NIÑO JESUS)",
            },
            {
              name: "NOGPO",
            },
            {
              name: "PANTAO",
            },
            {
              name: "RAWIS",
            },
            {
              name: "SAGRADA FAMILIA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAMPONGAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TALIN-TALIN",
            },
            {
              name: "TAMBO",
            },
            {
              name: "VILLA PETRONA",
            },
            {
              name: "WEST CARISAC",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
            {
              name: "ZONE V (POB.)",
            },
            {
              name: "ZONE VI (POB.)",
            },
            {
              name: "ZONE VII (POB.)",
            },
          ],
        },
        MALILIPOT: {
          name: "MALILIPOT",
          baranggays: [
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BINITAYAN",
            },
            {
              name: "CALBAYOG",
            },
            {
              name: "CANAWAY",
            },
            {
              name: "MALILIPOT",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO SANTICON (POB.)",
            },
            {
              name: "SAN ANTONIO SULONG",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO ILAWOD",
            },
            {
              name: "SAN ISIDRO IRAYA",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA TERESA",
            },
          ],
        },
        MALINAO: {
          name: "MALINAO",
          baranggays: [
            {
              name: "AWANG",
            },
            {
              name: "BAGATANGKI",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALADING",
            },
            {
              name: "BALZA",
            },
            {
              name: "BARIW",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BULANG",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CABUNTURAN",
            },
            {
              name: "COMUN",
            },
            {
              name: "DIARO",
            },
            {
              name: "ESTANCIA",
            },
            {
              name: "JONOP",
            },
            {
              name: "LABNIG",
            },
            {
              name: "LIBOD",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MALOLOS",
            },
            {
              name: "MATALIPNI",
            },
            {
              name: "OGOB",
            },
            {
              name: "PAWA",
            },
            {
              name: "PAYAHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUINARABASAHAN",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SOA",
            },
            {
              name: "SUGCAD",
            },
            {
              name: "TAGOYTOY",
            },
            {
              name: "TANAWAN",
            },
            {
              name: "TULIW",
            },
          ],
        },
        MANITO: {
          name: "MANITO",
          baranggays: [
            {
              name: "BALABAGON",
            },
            {
              name: "BALASBAS",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "BUYO",
            },
            {
              name: "CABACONGAN",
            },
            {
              name: "CABIT",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CAWIT",
            },
            {
              name: "HOLUGAN",
            },
            {
              name: "IT-BA (POB.)",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MANITO",
            },
            {
              name: "MANUMBALAY",
            },
            {
              name: "NAGOTGOT",
            },
            {
              name: "PAWA",
            },
            {
              name: "TINAPIAN",
            },
          ],
        },
        OAS: {
          name: "OAS",
          baranggays: [
            {
              name: "BADBAD",
            },
            {
              name: "BADIAN",
            },
            {
              name: "BAGSA",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BANAO",
            },
            {
              name: "BANGIAWON",
            },
            {
              name: "BOGTONG",
            },
            {
              name: "BONGORAN",
            },
            {
              name: "BUSAC",
            },
            {
              name: "CADAWAG",
            },
            {
              name: "CAGMANABA",
            },
            {
              name: "CALAGUIMIT",
            },
            {
              name: "CALPI",
            },
            {
              name: "CALZADA",
            },
            {
              name: "CAMAGONG",
            },
            {
              name: "CASINAGAN",
            },
            {
              name: "CENTRO POBLACION",
            },
            {
              name: "COLIAT",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "GUMABAO",
            },
            {
              name: "ILAOR NORTE",
            },
            {
              name: "ILAOR SUR",
            },
            {
              name: "IRAYA NORTE",
            },
            {
              name: "IRAYA SUR",
            },
            {
              name: "MANGA",
            },
            {
              name: "MAPORONG",
            },
            {
              name: "MARAMBA",
            },
            {
              name: "MATAMBO",
            },
            {
              name: "MAYAG",
            },
            {
              name: "MAYAO",
            },
            {
              name: "MOROPONROS",
            },
            {
              name: "NAGAS",
            },
            {
              name: "OAS",
            },
            {
              name: "OBALIW-RINAS",
            },
            {
              name: "PISTOLA",
            },
            {
              name: "RAMAY",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SABAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PASCUAL (NALE)",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE (SUCA)",
            },
            {
              name: "TABLON",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TALONGOG",
            },
            {
              name: "TAPEL",
            },
            {
              name: "TOBGON",
            },
            {
              name: "TOBOG",
            },
          ],
        },
        "PIO DURAN": {
          name: "PIO DURAN",
          baranggays: [
            {
              name: "AGOL",
            },
            {
              name: "ALABANGPURO",
            },
            {
              name: "BANAWAN (BINAWAN)",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BASICAO COASTAL",
            },
            {
              name: "BASICAO INTERIOR",
            },
            {
              name: "BINODEGAHAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUYO",
            },
            {
              name: "CARATAGAN",
            },
            {
              name: "CUYAOYAO",
            },
            {
              name: "FLORES",
            },
            {
              name: "LA MEDALLA",
            },
            {
              name: "LAWINON",
            },
            {
              name: "MACASITAS",
            },
            {
              name: "MALAPAY",
            },
            {
              name: "MALIDONG",
            },
            {
              name: "MAMLAD",
            },
            {
              name: "MARIGONDON",
            },
            {
              name: "MATANGLAD",
            },
            {
              name: "NABLANGBULOD",
            },
            {
              name: "ORINGON",
            },
            {
              name: "PALAPAS",
            },
            {
              name: "PANGANIRAN",
            },
            {
              name: "PIO DURAN",
            },
            {
              name: "RAWIS",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SUKIP",
            },
            {
              name: "TIBABO",
            },
          ],
        },
        POLANGUI: {
          name: "POLANGUI",
          baranggays: [
            {
              name: "AGOS",
            },
            {
              name: "ALNAY",
            },
            {
              name: "ALOMON",
            },
            {
              name: "AMOGUIS",
            },
            {
              name: "ANOPOL",
            },
            {
              name: "APAD",
            },
            {
              name: "BALABA",
            },
            {
              name: "BALANGIBANG",
            },
            {
              name: "BALINAD",
            },
            {
              name: "BASUD",
            },
            {
              name: "BINAGBANGAN (PINTOR)",
            },
            {
              name: "BUYO",
            },
            {
              name: "CENTRO OCCIDENTAL (POB.)",
            },
            {
              name: "CENTRO ORIENTAL (POB.)",
            },
            {
              name: "CEPRES",
            },
            {
              name: "COTMON",
            },
            {
              name: "COTNOGAN",
            },
            {
              name: "DANAO",
            },
            {
              name: "GABON",
            },
            {
              name: "GAMOT",
            },
            {
              name: "ITARAN",
            },
            {
              name: "KINALE",
            },
            {
              name: "KINUARTILAN",
            },
            {
              name: "LA MEDALLA",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "LANIGAY",
            },
            {
              name: "LIDONG",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MAGPANAMBO",
            },
            {
              name: "MAGURANG",
            },
            {
              name: "MATACON",
            },
            {
              name: "MAYNAGA",
            },
            {
              name: "MAYSUA",
            },
            {
              name: "MENDEZ",
            },
            {
              name: "NAPO",
            },
            {
              name: "PINAGDAPUGAN",
            },
            {
              name: "POLANGUI",
            },
            {
              name: "PONSO",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTICON",
            },
            {
              name: "SUGCAD",
            },
            {
              name: "UBALIW",
            },
          ],
        },
        "RAPU-RAPU": {
          name: "RAPU-RAPU",
          baranggays: [
            {
              name: "BAGAOBAWAN",
            },
            {
              name: "BATAN",
            },
            {
              name: "BILBAO",
            },
            {
              name: "BINOSAWAN",
            },
            {
              name: "BOGTONG",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUHATAN",
            },
            {
              name: "CALANAGA",
            },
            {
              name: "CARACARAN",
            },
            {
              name: "CAROGCOG",
            },
            {
              name: "DAP-DAP",
            },
            {
              name: "GABA",
            },
            {
              name: "GALICIA",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "HAMORAWON",
            },
            {
              name: "LAGUNDI",
            },
            {
              name: "LIGUAN",
            },
            {
              name: "LINAO",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MANANAO",
            },
            {
              name: "MANCAO",
            },
            {
              name: "MANILA",
            },
            {
              name: "MASAGA",
            },
            {
              name: "MOROCBOROCAN",
            },
            {
              name: "NAGCALSOT",
            },
            {
              name: "PAGCOLBON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RAPU-RAPU",
            },
            {
              name: "SAGRADA",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "TINOCAWAN",
            },
            {
              name: "TINOPAN",
            },
            {
              name: "VIGA",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        "SANTO DOMINGO (LIBOG)": {
          name: "SANTO DOMINGO (LIBOG)",
          baranggays: [
            {
              name: "ALIMSOG",
            },
            {
              name: "BAGONG SAN ROQUE",
            },
            {
              name: "BUHATAN",
            },
            {
              name: "CALAYUCAY",
            },
            {
              name: "DEL ROSARIO POB. (BGY. 3)",
            },
            {
              name: "FIDEL SURTIDA",
            },
            {
              name: "LIDONG",
            },
            {
              name: "MARKET SITE POB. (BGY. 9)",
            },
            {
              name: "NAGSIYA POB. (BGY. 8)",
            },
            {
              name: "PANDAYAN POB. (BGY. 10)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN FRANCISCO POB. (BGY. 1)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN POB. (BGY. 2)",
            },
            {
              name: "SAN PEDRO POB. (BGY. 5)",
            },
            {
              name: "SAN RAFAEL POB. (BGY. 7)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE POB. (BGY. 6.)",
            },
            {
              name: "SANTA MISERICORDIA",
            },
            {
              name: "SANTO DOMINGO (LIBOG)",
            },
            {
              name: "SANTO DOMINGO POB. (BGY. 4)",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        "TABACO CITY": {
          name: "TABACO CITY",
          baranggays: [
            {
              name: "AGNAS (SAN MIGUEL ISLAND)",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BANGKILINGAN",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BARANGHAWON",
            },
            {
              name: "BASAGAN",
            },
            {
              name: "BASUD (POB.)",
            },
            {
              name: "BOGÑABONG",
            },
            {
              name: "BOMBON (POB.)",
            },
            {
              name: "BONOT",
            },
            {
              name: "BUANG",
            },
            {
              name: "BUHIAN",
            },
            {
              name: "CABAGÑAN",
            },
            {
              name: "COBO",
            },
            {
              name: "COMON",
            },
            {
              name: "CORMIDAL",
            },
            {
              name: "DIVINO ROSTRO (POB.)",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GUINOBAT",
            },
            {
              name: "HACIENDA (SAN MIGUEL ISLAND)",
            },
            {
              name: "MAGAPO",
            },
            {
              name: "MARIROC",
            },
            {
              name: "MATAGBAC",
            },
            {
              name: "ORAS",
            },
            {
              name: "OSON",
            },
            {
              name: "PANAL",
            },
            {
              name: "PAWA",
            },
            {
              name: "PINAGBOBONG",
            },
            {
              name: "QUINALE CABASAN (POB.)",
            },
            {
              name: "QUINASTILLOJAN",
            },
            {
              name: "RAWIS (SAN MIGUEL ISLAND)",
            },
            {
              name: "SAGURONG (SAN MIGUEL ISLAND)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN ISIDRO (BORING)",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO CRISTO (POB.)",
            },
            {
              name: "SUA-IGOT",
            },
            {
              name: "TABACO CITY",
            },
            {
              name: "TABIGUIAN",
            },
            {
              name: "TAGAS",
            },
            {
              name: "TAYHI (POB.)",
            },
            {
              name: "VISITA (SAN MIGUEL ISLAND)",
            },
          ],
        },
        TIWI: {
          name: "TIWI",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BARIIS",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BELEN (MALABOG)",
            },
            {
              name: "BIYONG",
            },
            {
              name: "BOLO",
            },
            {
              name: "CALE",
            },
            {
              name: "CARARAYAN",
            },
            {
              name: "CORO-CORO",
            },
            {
              name: "DAP-DAP",
            },
            {
              name: "GAJO",
            },
            {
              name: "JOROAN",
            },
            {
              name: "LIBJO",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "MATALIBONG",
            },
            {
              name: "MAYNONONG",
            },
            {
              name: "MAYONG",
            },
            {
              name: "MISIBIS",
            },
            {
              name: "NAGA",
            },
            {
              name: "NAGAS",
            },
            {
              name: "OYAMA",
            },
            {
              name: "PUTSAN",
            },
            {
              name: "SAN BERNARDO",
            },
            {
              name: "SOGOD",
            },
            {
              name: "TIGBI (POB.)",
            },
            {
              name: "TIWI",
            },
          ],
        },
      },
    },
    ANTIQUE: {
      name: "ANTIQUE",
      region: "REGION 6",
      municipalities: {
        "ANINI-Y": {
          name: "ANINI-Y",
          baranggays: [
            {
              name: "ANINI-Y",
            },
            {
              name: "BAYO GRANDE",
            },
            {
              name: "BAYO PEQUEÑO",
            },
            {
              name: "BUTUAN",
            },
            {
              name: "CASAY",
            },
            {
              name: "CASAY VIEJO",
            },
            {
              name: "IBA",
            },
            {
              name: "IGBARABATUAN",
            },
            {
              name: "IGPALGE",
            },
            {
              name: "IGTUMAROM",
            },
            {
              name: "LISUB A",
            },
            {
              name: "LISUB B",
            },
            {
              name: "MABUYONG",
            },
            {
              name: "MAGDALENA",
            },
            {
              name: "NASULI C",
            },
            {
              name: "NATO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGUA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "TALISAYAN",
            },
          ],
        },
        BARBAZA: {
          name: "BARBAZA",
          baranggays: [
            {
              name: "BAGHARI",
            },
            {
              name: "BAHUYAN",
            },
            {
              name: "BARBAZA",
            },
            {
              name: "BERI",
            },
            {
              name: "BIGA-A",
            },
            {
              name: "BINANGBANG",
            },
            {
              name: "BINANGBANG CENTRO",
            },
            {
              name: "BINANU-AN",
            },
            {
              name: "CADIAO",
            },
            {
              name: "CALAPADAN",
            },
            {
              name: "CAPOYUAN",
            },
            {
              name: "CUBAY",
            },
            {
              name: "EMBRANGGA-AN",
            },
            {
              name: "ESPARAR",
            },
            {
              name: "GUA",
            },
            {
              name: "IDAO",
            },
            {
              name: "IGPALGE",
            },
            {
              name: "IGTUNARUM",
            },
            {
              name: "INTEGASAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "JINALINAN",
            },
            {
              name: "LANAS",
            },
            {
              name: "LANGCAON (EVELIO JAVIER)",
            },
            {
              name: "LISUB",
            },
            {
              name: "LOMBUYAN",
            },
            {
              name: "MABLAD",
            },
            {
              name: "MAGTULIS",
            },
            {
              name: "MARIGNE",
            },
            {
              name: "MAYABAY",
            },
            {
              name: "MAYOS",
            },
            {
              name: "NALUSDAN",
            },
            {
              name: "NARIRONG",
            },
            {
              name: "PALMA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SOLIGAO",
            },
            {
              name: "TABONGTABONG",
            },
            {
              name: "TIG-ALARAN",
            },
            {
              name: "YAPO",
            },
          ],
        },
        BELISON: {
          name: "BELISON",
          baranggays: [
            {
              name: "BELISON",
            },
            {
              name: "BOROCBOROC",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DELIMA",
            },
            {
              name: "IPIL",
            },
            {
              name: "MARADIONA",
            },
            {
              name: "MOJON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROMBANG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SINAJA",
            },
          ],
        },
        BUGASONG: {
          name: "BUGASONG",
          baranggays: [
            {
              name: "ANILAWAN",
            },
            {
              name: "ARANGOTE",
            },
            {
              name: "BAGTASON",
            },
            {
              name: "BUGASONG",
            },
            {
              name: "CAMANGAHAN",
            },
            {
              name: "CENTRO ILAWOD (POB.)",
            },
            {
              name: "CENTRO ILAYA (POB.)",
            },
            {
              name: "CENTRO POJO (POB.)",
            },
            {
              name: "CUBAY NORTH",
            },
            {
              name: "CUBAY SOUTH",
            },
            {
              name: "GUIJA",
            },
            {
              name: "IGBALANGAO",
            },
            {
              name: "IGSORO",
            },
            {
              name: "ILAURES",
            },
            {
              name: "JINALINAN",
            },
            {
              name: "LACAYON",
            },
            {
              name: "MARAY",
            },
            {
              name: "PALIWAN",
            },
            {
              name: "PANGALCAGAN",
            },
            {
              name: "SABANG EAST",
            },
            {
              name: "SABANG WEST",
            },
            {
              name: "TAGUDTUD NORTH",
            },
            {
              name: "TAGUDTUD SOUTH",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TICA",
            },
            {
              name: "TONO-AN",
            },
            {
              name: "YAPU",
            },
            {
              name: "ZARAGOZA",
            },
          ],
        },
        CALUYA: {
          name: "CALUYA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BACONG",
            },
            {
              name: "BANAGO",
            },
            {
              name: "BONBON",
            },
            {
              name: "CALUYA",
            },
            {
              name: "DAWIS",
            },
            {
              name: "DIONELA",
            },
            {
              name: "HARIGUE",
            },
            {
              name: "HININGA-AN",
            },
            {
              name: "IMBA",
            },
            {
              name: "MASANAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALAMENTO",
            },
            {
              name: "SEMIRARA",
            },
            {
              name: "SIBATO",
            },
            {
              name: "SIBAY",
            },
            {
              name: "SIBOLO",
            },
            {
              name: "TINOGBOC",
            },
          ],
        },
        CULASI: {
          name: "CULASI",
          baranggays: [
            {
              name: "ALOJIPAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BALAC-BALAC",
            },
            {
              name: "BATBATAN ISLAND",
            },
            {
              name: "BATONAN NORTE",
            },
            {
              name: "BATONAN SUR",
            },
            {
              name: "BITA",
            },
            {
              name: "BITADTON NORTE",
            },
            {
              name: "BITADTON SUR",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUHI",
            },
            {
              name: "CAMANCIJAN",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "CARIT-AN",
            },
            {
              name: "CENTRO NORTE (POB.)",
            },
            {
              name: "CENTRO POBLACION",
            },
            {
              name: "CENTRO SUR (POB.)",
            },
            {
              name: "CONDES",
            },
            {
              name: "CULASI",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "FE",
            },
            {
              name: "FLORES",
            },
            {
              name: "JALANDONI",
            },
            {
              name: "JANLAGASI",
            },
            {
              name: "LAMPUTONG",
            },
            {
              name: "LIPATA",
            },
            {
              name: "MAGSAYSAY (BALUA)",
            },
            {
              name: "MALACAÑANG",
            },
            {
              name: "MALALISON ISLAND",
            },
            {
              name: "MANIGUIN",
            },
            {
              name: "NABA",
            },
            {
              name: "OSORIO",
            },
            {
              name: "PANINGAYAN",
            },
            {
              name: "SALDE",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SIMBOLA",
            },
            {
              name: "TIGBOBOLO",
            },
            {
              name: "TINABUSAN",
            },
            {
              name: "TOMAO",
            },
            {
              name: "VALDERAMA",
            },
          ],
        },
        HAMTIC: {
          name: "HAMTIC",
          baranggays: [
            {
              name: "APDO",
            },
            {
              name: "ASLUMAN",
            },
            {
              name: "BANAWON",
            },
            {
              name: "BIA-AN",
            },
            {
              name: "BONGBONGAN I-II",
            },
            {
              name: "BONGBONGAN III",
            },
            {
              name: "BOTBOT",
            },
            {
              name: "BUDBUDAN",
            },
            {
              name: "BUHANG",
            },
            {
              name: "CALACJA I",
            },
            {
              name: "CALACJA II",
            },
            {
              name: "CALALA",
            },
            {
              name: "CANTULAN",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "CAROMANGAY",
            },
            {
              name: "CASALNGAN",
            },
            {
              name: "DANGCALAN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "FABRICA",
            },
            {
              name: "FUNDA",
            },
            {
              name: "GENERAL FULLON (TINA)",
            },
            {
              name: "GOV. EVELIO B. JAVIER (LANAG)",
            },
            {
              name: "GUINTAS",
            },
            {
              name: "HAMTIC",
            },
            {
              name: "IGBICAL",
            },
            {
              name: "IGBUCAGAY",
            },
            {
              name: "INABASAN",
            },
            {
              name: "INGWAN-BATANGAN",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LINABAN",
            },
            {
              name: "MALANDOG",
            },
            {
              name: "MAPATAG",
            },
            {
              name: "MASANAG",
            },
            {
              name: "NALIHAWAN",
            },
            {
              name: "PAMANDAYAN (BOTBOT)",
            },
            {
              name: "PASU-JUNGAO",
            },
            {
              name: "PIAPE I",
            },
            {
              name: "PIAPE II",
            },
            {
              name: "PIAPE III",
            },
            {
              name: "PILI 1, 2, 3",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "POBLACION 4",
            },
            {
              name: "POBLACION 5",
            },
            {
              name: "PU-AO",
            },
            {
              name: "SULOC",
            },
            {
              name: "VILLAVERT-JIMENEZ",
            },
          ],
        },
        "LAUA-AN": {
          name: "LAUA-AN",
          baranggays: [
            {
              name: "BAGONGBAYAN",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BONGBONGAN",
            },
            {
              name: "CABARIWAN",
            },
            {
              name: "CADAJUG",
            },
            {
              name: "CANITUAN",
            },
            {
              name: "CAPNAYAN",
            },
            {
              name: "CASIT-AN",
            },
            {
              name: "GUIAMON",
            },
            {
              name: "GUINBANGA-AN",
            },
            {
              name: "GUISIJAN",
            },
            {
              name: "IGTADIAO",
            },
            {
              name: "INTAO",
            },
            {
              name: "JAGUIKICAN",
            },
            {
              name: "JINALINAN",
            },
            {
              name: "LACTUDAN",
            },
            {
              name: "LATAZON",
            },
            {
              name: "LAUA-AN",
            },
            {
              name: "LAUA-AN",
            },
            {
              name: "LIBERATO",
            },
            {
              name: "LINDERO",
            },
            {
              name: "LIYA-LIYA",
            },
            {
              name: "LOON",
            },
            {
              name: "LUGTA",
            },
            {
              name: "LUPA-AN",
            },
            {
              name: "MAGYAPO",
            },
            {
              name: "MARIA",
            },
            {
              name: "MAUNO",
            },
            {
              name: "MAYBUNGA",
            },
            {
              name: "NECESITO (PANIATAN)",
            },
            {
              name: "OLOC",
            },
            {
              name: "OMLOT",
            },
            {
              name: "PANDANAN",
            },
            {
              name: "PANINGAYAN",
            },
            {
              name: "PASCUALA",
            },
            {
              name: "POBLACION (CENTRO)",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "TIBACAN",
            },
            {
              name: "TIGUNHAO",
            },
            {
              name: "VIRGINIA",
            },
          ],
        },
        LIBERTAD: {
          name: "LIBERTAD",
          baranggays: [
            {
              name: "BARUSBUS",
            },
            {
              name: "BULANAO",
            },
            {
              name: "CENTRO ESTE (POB.)",
            },
            {
              name: "CENTRO WESTE (POB.)",
            },
            {
              name: "CODIONG",
            },
            {
              name: "CUBAY",
            },
            {
              name: "IGCAGAY",
            },
            {
              name: "INYAWAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LINDERO",
            },
            {
              name: "MARAMIG",
            },
            {
              name: "PAJO",
            },
            {
              name: "PANANGKILON",
            },
            {
              name: "PAZ",
            },
            {
              name: "PUCIO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TABOC",
            },
            {
              name: "TINIGBAS",
            },
            {
              name: "TININDUGAN",
            },
            {
              name: "UNION",
            },
          ],
        },
        PANDAN: {
          name: "PANDAN",
          baranggays: [
            {
              name: "ARACAY",
            },
            {
              name: "BADIANGAN",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BOTBOT",
            },
            {
              name: "BUANG",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CANDARI",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CENTRO NORTE (POB.)",
            },
            {
              name: "CENTRO SUR (POB.)",
            },
            {
              name: "DIONELA",
            },
            {
              name: "DUMROG",
            },
            {
              name: "DUYONG",
            },
            {
              name: "FRAGANTE",
            },
            {
              name: "GUIA",
            },
            {
              name: "IDIACACAN",
            },
            {
              name: "JINALINAN",
            },
            {
              name: "LUHOD-BAYANG",
            },
            {
              name: "MAADIOS",
            },
            {
              name: "MAG-ABA",
            },
            {
              name: "NAPUID",
            },
            {
              name: "NAURING",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PATRIA",
            },
            {
              name: "PERFECTA",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TINGIB",
            },
            {
              name: "ZALDIVAR",
            },
          ],
        },
        PATNONGON: {
          name: "PATNONGON",
          baranggays: [
            {
              name: "ALVAÑIZ",
            },
            {
              name: "AMPARO",
            },
            {
              name: "APGAHAN",
            },
            {
              name: "AURELIANA",
            },
            {
              name: "BADIANGAN",
            },
            {
              name: "BERNALDO A. JULAGTING (BITAS)",
            },
            {
              name: "CARIT-AN",
            },
            {
              name: "CUYAPIAO",
            },
            {
              name: "GELLA",
            },
            {
              name: "IGBARAWAN",
            },
            {
              name: "IGBOBON",
            },
            {
              name: "IGBURI",
            },
            {
              name: "LA RIOJA",
            },
            {
              name: "MABASA",
            },
            {
              name: "MACARINA",
            },
            {
              name: "MAGARANG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "PADANG",
            },
            {
              name: "PANDANAN",
            },
            {
              name: "PATLABAWON",
            },
            {
              name: "PATNONGON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SALAGUIAWAN",
            },
            {
              name: "SAMALAGUE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "TAMAYOC",
            },
            {
              name: "TIGBALOGO",
            },
            {
              name: "TOBIAS FORNIER",
            },
            {
              name: "VILLA CRESPO",
            },
            {
              name: "VILLA CRUZ",
            },
            {
              name: "VILLA ELIO",
            },
            {
              name: "VILLA FLORES",
            },
            {
              name: "VILLA LAUA-AN",
            },
            {
              name: "VILLA SAL",
            },
            {
              name: "VILLA SALOMON",
            },
            {
              name: "VISTA ALEGRE",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "ATABAY",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARIRI",
            },
            {
              name: "BUGAROT (CATUNGAN-BUGAROT)",
            },
            {
              name: "CANSADAN (CANSADAN-TUBUDAN)",
            },
            {
              name: "DUROG",
            },
            {
              name: "FUNDA-DALIPE",
            },
            {
              name: "IGBONGLO",
            },
            {
              name: "INABASAN",
            },
            {
              name: "MADRANGCA",
            },
            {
              name: "MAGCALON",
            },
            {
              name: "MALAIBA",
            },
            {
              name: "MAYBATO NORTE",
            },
            {
              name: "MAYBATO SUR",
            },
            {
              name: "MOJON",
            },
            {
              name: "PANTAO",
            },
            {
              name: "SAN ANGEL",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SUPA",
            },
          ],
        },
        "SAN REMIGIO": {
          name: "SAN REMIGIO",
          baranggays: [
            {
              name: "AGRICULA",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "ANINGALAN",
            },
            {
              name: "ATABAY",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALADJAY",
            },
            {
              name: "BANBANAN",
            },
            {
              name: "BARANGBANG",
            },
            {
              name: "BAWANG",
            },
            {
              name: "BUGO",
            },
            {
              name: "BULAN-BULAN",
            },
            {
              name: "CABIAWAN",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CADOLONAN",
            },
            {
              name: "CARAWISAN I",
            },
            {
              name: "CARAWISAN II",
            },
            {
              name: "CARMELO I",
            },
            {
              name: "CARMELO II",
            },
            {
              name: "GENERAL FULLON",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "IGUIRINDON",
            },
            {
              name: "INSUBUAN",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LAPAK",
            },
            {
              name: "LUMPATAN",
            },
            {
              name: "MAGDALENA",
            },
            {
              name: "MARAGUBDUB",
            },
            {
              name: "NAGBANGI I (AMATONG)",
            },
            {
              name: "NAGBANGI II",
            },
            {
              name: "NASULI",
            },
            {
              name: "ORQUIA (IGCATUMBAL)",
            },
            {
              name: "OSORIO I",
            },
            {
              name: "OSORIO II",
            },
            {
              name: "PANPANAN I",
            },
            {
              name: "PANPANAN II",
            },
            {
              name: "POBLACION (CALAG-ITAN)",
            },
            {
              name: "RAMON MAGSAYSAY",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN REMIGIO",
            },
            {
              name: "SINUNDOLAN",
            },
            {
              name: "SUMARAY",
            },
            {
              name: "TRINIDAD",
            },
            {
              name: "TUBUDAN",
            },
            {
              name: "VILVAR",
            },
            {
              name: "WALKER",
            },
          ],
        },
        SEBASTE: {
          name: "SEBASTE",
          baranggays: [
            {
              name: "ABIERA",
            },
            {
              name: "AGUILA",
            },
            {
              name: "ALEGRE",
            },
            {
              name: "ARAS-ASAN",
            },
            {
              name: "BACALAN",
            },
            {
              name: "CALLAN",
            },
            {
              name: "IDIO",
            },
            {
              name: "NAUHON",
            },
            {
              name: "P. JAVIER",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SEBASTE",
            },
          ],
        },
        SIBALOM: {
          name: "SIBALOM",
          baranggays: [
            {
              name: "ALANGAN",
            },
            {
              name: "BARI",
            },
            {
              name: "BIGA-A",
            },
            {
              name: "BONGBONGAN I",
            },
            {
              name: "BONGBONGAN II",
            },
            {
              name: "BONGSOD",
            },
            {
              name: "BONTOL",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "BULALACAO",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CABARIUAN",
            },
            {
              name: "CABLADAN",
            },
            {
              name: "CADOLDOLAN",
            },
            {
              name: "CALO-OY",
            },
            {
              name: "CALOG",
            },
            {
              name: "CATMON",
            },
            {
              name: "CATUNGAN I",
            },
            {
              name: "CATUNGAN II",
            },
            {
              name: "CATUNGAN III",
            },
            {
              name: "CATUNGAN IV",
            },
            {
              name: "CUBAY-NAPULTAN",
            },
            {
              name: "CUBAY-SERMON",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "DISTRICT III (POB.)",
            },
            {
              name: "DISTRICT IV (POB.)",
            },
            {
              name: "EGAÑA",
            },
            {
              name: "ESPERANZA I",
            },
            {
              name: "ESPERANZA II",
            },
            {
              name: "ESPERANZA III",
            },
            {
              name: "IGCOCOC",
            },
            {
              name: "IGDAGMAY",
            },
            {
              name: "IGDALAQUIT",
            },
            {
              name: "IGLANOT",
            },
            {
              name: "IGPANOLONG",
            },
            {
              name: "IGPARAS",
            },
            {
              name: "IGSUMING",
            },
            {
              name: "ILABAS",
            },
            {
              name: "IMPARAYAN",
            },
            {
              name: "INABASAN",
            },
            {
              name: "INDAG-AN",
            },
            {
              name: "INITAN",
            },
            {
              name: "INSARAYAN",
            },
            {
              name: "LACARON",
            },
            {
              name: "LAGDO",
            },
            {
              name: "LAMBAYAGAN",
            },
            {
              name: "LUNA",
            },
            {
              name: "LUYANG",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MILLAMENA",
            },
            {
              name: "MOJON",
            },
            {
              name: "NAGDAYAO",
            },
            {
              name: "NAZARETH",
            },
            {
              name: "ODIONG",
            },
            {
              name: "OLAGA",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PANLAGANGAN",
            },
            {
              name: "PANTAO",
            },
            {
              name: "PASONG",
            },
            {
              name: "PIS-ANAN",
            },
            {
              name: "ROMBANG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SIBALOM",
            },
            {
              name: "SIDO",
            },
            {
              name: "SOLONG",
            },
            {
              name: "TABONGTABONG",
            },
            {
              name: "TIG-OHOT",
            },
            {
              name: "TIGBALUA I",
            },
            {
              name: "TIGBALUA II",
            },
            {
              name: "TORDESILLAS",
            },
            {
              name: "TULATULA",
            },
            {
              name: "VALENTIN GRASPARIL (BAD-AS)",
            },
            {
              name: "VILLAFONT",
            },
            {
              name: "VILLAHERMOSA",
            },
            {
              name: "VILLAR",
            },
          ],
        },
        TIBIAO: {
          name: "TIBIAO",
          baranggays: [
            {
              name: "ALEGRE",
            },
            {
              name: "AMAR",
            },
            {
              name: "BANDOJA (LUPA-AN)",
            },
            {
              name: "CASTILLO",
            },
            {
              name: "ESPARAGOZA",
            },
            {
              name: "IMPORTANTE",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "MALABOR",
            },
            {
              name: "MARTINEZ",
            },
            {
              name: "NATIVIDAD",
            },
            {
              name: "PITAC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAZAR",
            },
            {
              name: "SAN FRANCISCO NORTE",
            },
            {
              name: "SAN FRANCISCO SUR",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA JUSTA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TIBIAO",
            },
            {
              name: "TIGBABOY",
            },
            {
              name: "TUNO",
            },
          ],
        },
        "TOBIAS FORNIER (DAO)": {
          name: "TOBIAS FORNIER (DAO)",
          baranggays: [
            {
              name: "ABACA",
            },
            {
              name: "ARAS-ASAN",
            },
            {
              name: "AROBO",
            },
            {
              name: "ATABAY",
            },
            {
              name: "ATIOTES",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALLOSCAS",
            },
            {
              name: "BALUD",
            },
            {
              name: "BARASANAN A",
            },
            {
              name: "BARASANAN B",
            },
            {
              name: "BARASANAN C",
            },
            {
              name: "BARIRI",
            },
            {
              name: "CAMANDAGAN",
            },
            {
              name: "CATO-OGAN",
            },
            {
              name: "DANAWAN",
            },
            {
              name: "DICLUM",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GAMAD (IGDAMACIO)",
            },
            {
              name: "IGBALOGO",
            },
            {
              name: "IGBANGCAL-A",
            },
            {
              name: "IGBANGCAL-B",
            },
            {
              name: "IGBANGCAL-C",
            },
            {
              name: "IGCABUAD",
            },
            {
              name: "IGCADAC",
            },
            {
              name: "IGCADO",
            },
            {
              name: "IGCALAWAGAN",
            },
            {
              name: "IGCAPUYAS",
            },
            {
              name: "IGCASICAD (SAN PEDRO)",
            },
            {
              name: "IGDALAGUIT",
            },
            {
              name: "IGDANLOG",
            },
            {
              name: "IGDURAROG",
            },
            {
              name: "IGTUGAS",
            },
            {
              name: "LAWIGAN",
            },
            {
              name: "LINDERO",
            },
            {
              name: "MANALING (CATA-AN)",
            },
            {
              name: "MASAYO",
            },
            {
              name: "NAGSUBUAN",
            },
            {
              name: "NASULI-A (SAN RAMON)",
            },
            {
              name: "OPSAN (SAN JOSE)",
            },
            {
              name: "PACIENCIA",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "PORTILLO",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SALAMAGUE (SANTA MARIA)",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TACBUYAN",
            },
            {
              name: "TENE",
            },
            {
              name: "TOBIAS FORNIER (DAO)",
            },
            {
              name: "VILLAFLOR",
            },
            {
              name: "YSULAT",
            },
          ],
        },
        VALDERRAMA: {
          name: "VALDERRAMA",
          baranggays: [
            {
              name: "ALON",
            },
            {
              name: "BAKIANG",
            },
            {
              name: "BINANOGAN",
            },
            {
              name: "BOROCBOROC",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "BULUANGAN I",
            },
            {
              name: "BULUANGAN II",
            },
            {
              name: "BUNSOD",
            },
            {
              name: "BUSOG",
            },
            {
              name: "CANANGHAN",
            },
            {
              name: "CANIPAYAN",
            },
            {
              name: "CANSILAYAN",
            },
            {
              name: "CULYAT",
            },
            {
              name: "IGLINAB",
            },
            {
              name: "IGMASANDIG",
            },
            {
              name: "LUBLUB",
            },
            {
              name: "MANLACBO",
            },
            {
              name: "PANDANAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "TAKAS (POB.)",
            },
            {
              name: "TIGMAMALE",
            },
            {
              name: "UBOS (POB.)",
            },
            {
              name: "VALDERRAMA",
            },
          ],
        },
      },
    },
    APAYAO: {
      name: "APAYAO",
      region: "CAR",
      municipalities: {
        "CALANASAN (BAYAG)": {
          name: "CALANASAN (BAYAG)",
          baranggays: [
            {
              name: "BUTAO",
            },
            {
              name: "CADACLAN",
            },
            {
              name: "CALANASAN (BAYAG)",
            },
            {
              name: "DON ROQUE ABLAN SR.",
            },
            {
              name: "ELEAZAR",
            },
            {
              name: "EVA PUZON",
            },
            {
              name: "KABUGAWAN",
            },
            {
              name: "LANGNAO",
            },
            {
              name: "LUBONG",
            },
            {
              name: "MACALINO",
            },
            {
              name: "NAGUILIAN",
            },
            {
              name: "NAMALTUGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABANGAN",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "TANGLAGAN",
            },
            {
              name: "TUBANG",
            },
            {
              name: "TUBONGAN",
            },
          ],
        },
        CONNER: {
          name: "CONNER",
          baranggays: [
            {
              name: "BANBAN",
            },
            {
              name: "BULUAN",
            },
            {
              name: "CAGLAYAN (NEW POB.)",
            },
            {
              name: "CALAFUG",
            },
            {
              name: "CUPIS",
            },
            {
              name: "DAGA",
            },
            {
              name: "GUINAANG",
            },
            {
              name: "GUINAMGAMAN",
            },
            {
              name: "ILI",
            },
            {
              name: "KARIKITAN",
            },
            {
              name: "KATABLANGAN",
            },
            {
              name: "MALAMA",
            },
            {
              name: "MANAG",
            },
            {
              name: "MAWEGUI",
            },
            {
              name: "NABUANGAN",
            },
            {
              name: "PADDAOAN",
            },
            {
              name: "PUGUIN",
            },
            {
              name: "RIPANG (OLD POB.)",
            },
            {
              name: "SACPIL",
            },
            {
              name: "TALIFUGO",
            },
          ],
        },
        FLORA: {
          name: "FLORA",
          baranggays: [
            {
              name: "ALLIG",
            },
            {
              name: "ANNINIPAN",
            },
            {
              name: "ATOK",
            },
            {
              name: "BAGUTONG",
            },
            {
              name: "BALASI",
            },
            {
              name: "BALLUYAN",
            },
            {
              name: "FLORA",
            },
            {
              name: "MALAYUGAN",
            },
            {
              name: "MALLIG",
            },
            {
              name: "MALUBIBIT NORTE",
            },
            {
              name: "MALUBIBIT SUR",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "TAMALUNOG",
            },
            {
              name: "UPPER ATOK (COLIMAN)",
            },
          ],
        },
        KABUGAO: {
          name: "KABUGAO",
          baranggays: [
            {
              name: "BADDUAT",
            },
            {
              name: "BALIWANAN",
            },
            {
              name: "BULU",
            },
            {
              name: "CABETAYAN",
            },
            {
              name: "DAGARA",
            },
            {
              name: "DIBAGAT",
            },
            {
              name: "KABUGAO",
            },
            {
              name: "KARAGAWAN",
            },
            {
              name: "KUMAO",
            },
            {
              name: "LACO",
            },
            {
              name: "LENNENG (LIYYENG)",
            },
            {
              name: "LUCAB",
            },
            {
              name: "LUTTUACAN",
            },
            {
              name: "MADATAG",
            },
            {
              name: "MADDUANG",
            },
            {
              name: "MAGABTA",
            },
            {
              name: "MARAGAT",
            },
            {
              name: "MUSIMUT",
            },
            {
              name: "NAGBABALAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TUYANGAN",
            },
            {
              name: "WAGA",
            },
          ],
        },
        LUNA: {
          name: "LUNA",
          baranggays: [
            {
              name: "BACSAY",
            },
            {
              name: "CAGANDUNGAN",
            },
            {
              name: "CALABIGAN",
            },
            {
              name: "CANGISITAN",
            },
            {
              name: "CAPAGAYPAYAN",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "LAPPA",
            },
            {
              name: "LUNA",
            },
            {
              name: "LUYON",
            },
            {
              name: "MARAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUIRINO",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN ISIDRO NORTE",
            },
            {
              name: "SAN ISIDRO SUR",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SANTA LINA",
            },
            {
              name: "SHALOM",
            },
            {
              name: "TUMOG",
            },
            {
              name: "TUROD",
            },
            {
              name: "ZUMIGUI",
            },
          ],
        },
        PUDTOL: {
          name: "PUDTOL",
          baranggays: [
            {
              name: "AGA",
            },
            {
              name: "ALEM",
            },
            {
              name: "AMADO",
            },
            {
              name: "AURORA",
            },
            {
              name: "CABATACAN",
            },
            {
              name: "CACALAGGAN",
            },
            {
              name: "CAPANNIKIAN",
            },
            {
              name: "DOÑA LORETA",
            },
            {
              name: "EMILIA",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LOWER MATON",
            },
            {
              name: "LT. BILAG",
            },
            {
              name: "LYDIA",
            },
            {
              name: "MALIBANG",
            },
            {
              name: "MATAGUISI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUDTOL",
            },
            {
              name: "SAN ANTONIO (PUGO)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SWAN",
            },
            {
              name: "UPPER MATON",
            },
          ],
        },
        "SANTA MARCELA": {
          name: "SANTA MARCELA",
          baranggays: [
            {
              name: "BAROCBOC",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "EMILIANA",
            },
            {
              name: "IMELDA (SIPA ANNEX)",
            },
            {
              name: "MALEKKEG",
            },
            {
              name: "MARCELA (POB.)",
            },
            {
              name: "NUEVA",
            },
            {
              name: "PANAY",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SANTA MARCELA",
            },
            {
              name: "SIPA PROPER",
            },
          ],
        },
      },
    },
    AURORA: {
      name: "AURORA",
      region: "REGION 3",
      municipalities: {
        BALER: {
          name: "BALER",
          baranggays: [
            {
              name: "BALER",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BUHANGIN",
            },
            {
              name: "CALABUANAN",
            },
            {
              name: "OBLIGACION",
            },
            {
              name: "PINGIT",
            },
            {
              name: "RESERVA",
            },
            {
              name: "SABANG",
            },
            {
              name: "SUCLAYIN",
            },
            {
              name: "ZABALI",
            },
          ],
        },
        CASIGURAN: {
          name: "CASIGURAN",
          baranggays: [
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BIANUAN",
            },
            {
              name: "CALABGAN",
            },
            {
              name: "CALANGCUASAN",
            },
            {
              name: "CALANTAS",
            },
            {
              name: "CASIGURAN",
            },
            {
              name: "COZO",
            },
            {
              name: "CULAT",
            },
            {
              name: "DIBACONG",
            },
            {
              name: "DIBET",
            },
            {
              name: "DITINAGYAN",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "ESTEVES",
            },
            {
              name: "LUAL",
            },
            {
              name: "MARIKIT",
            },
            {
              name: "SAN ILDEFONSO",
            },
            {
              name: "TABAS",
            },
            {
              name: "TINIB",
            },
          ],
        },
        DILASAG: {
          name: "DILASAG",
          baranggays: [
            {
              name: "DIAGYAN",
            },
            {
              name: "DICABASAN",
            },
            {
              name: "DILAGUIDI",
            },
            {
              name: "DILASAG",
            },
            {
              name: "DIMASESET",
            },
            {
              name: "DINIOG",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "LAWANG",
            },
            {
              name: "MALIGAYA (POB.)",
            },
            {
              name: "MANGGITAHAN",
            },
            {
              name: "MASAGANA (POB.)",
            },
            {
              name: "URA",
            },
          ],
        },
        DINALUNGAN: {
          name: "DINALUNGAN",
          baranggays: [
            {
              name: "ABULEG",
            },
            {
              name: "DIBARAYBAY",
            },
            {
              name: "DINALUNGAN",
            },
            {
              name: "DITAWINI",
            },
            {
              name: "MAPALAD",
            },
            {
              name: "NIPOO (BULO)",
            },
            {
              name: "PALEG",
            },
            {
              name: "SIMBAHAN",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
          ],
        },
        DINGALAN: {
          name: "DINGALAN",
          baranggays: [
            {
              name: "APLAYA",
            },
            {
              name: "BUTAS NA BATO",
            },
            {
              name: "CABOG (MATAWE)",
            },
            {
              name: "CARAGSACAN",
            },
            {
              name: "DAVILDAVILAN",
            },
            {
              name: "DIKAPANIKIAN",
            },
            {
              name: "DINGALAN",
            },
            {
              name: "IBONA",
            },
            {
              name: "PALTIC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TANAWAN",
            },
            {
              name: "UMIRAY (MALAMIG)",
            },
          ],
        },
        DIPACULAO: {
          name: "DIPACULAO",
          baranggays: [
            {
              name: "BAYABAS",
            },
            {
              name: "BORLONGAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "DIAMANEN",
            },
            {
              name: "DIANED",
            },
            {
              name: "DIARABASIN",
            },
            {
              name: "DIBUTUNAN",
            },
            {
              name: "DIMABUNO",
            },
            {
              name: "DINADIAWAN",
            },
            {
              name: "DIPACULAO",
            },
            {
              name: "DITALE",
            },
            {
              name: "GUPA",
            },
            {
              name: "IPIL",
            },
            {
              name: "LABOY",
            },
            {
              name: "LIPIT",
            },
            {
              name: "LOBBOT",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MIJARES",
            },
            {
              name: "MUCDOL",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "PUANGI",
            },
            {
              name: "SALAY",
            },
            {
              name: "SAPANGKAWAYAN",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TOYTOYAN",
            },
          ],
        },
        "MARIA AURORA": {
          name: "MARIA AURORA",
          baranggays: [
            {
              name: "ALCALA",
            },
            {
              name: "BAGTU",
            },
            {
              name: "BANGCO",
            },
            {
              name: "BANNAWAG",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BAUBO",
            },
            {
              name: "BAYANIHAN",
            },
            {
              name: "BAZAL",
            },
            {
              name: "CABITUCULAN EAST",
            },
            {
              name: "CABITUCULAN WEST",
            },
            {
              name: "CADAYACAN",
            },
            {
              name: "DEBUCAO",
            },
            {
              name: "DECOLIAT",
            },
            {
              name: "DETAILEN",
            },
            {
              name: "DIAAT",
            },
            {
              name: "DIALATMAN",
            },
            {
              name: "DIAMAN",
            },
            {
              name: "DIANAWAN",
            },
            {
              name: "DIKILDIT",
            },
            {
              name: "DIMANPUDSO",
            },
            {
              name: "DIOME",
            },
            {
              name: "ESTONILO",
            },
            {
              name: "FLORIDA",
            },
            {
              name: "GALINTUJA",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MARIA AURORA",
            },
            {
              name: "PONGLO",
            },
            {
              name: "QUIRINO",
            },
            {
              name: "RAMADA",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LEONARDO",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SUGUIT",
            },
            {
              name: "VILLA AURORA",
            },
            {
              name: "WENCESLAO",
            },
          ],
        },
        "SAN LUIS": {
          name: "SAN LUIS",
          baranggays: [
            {
              name: "BACONG",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "DIBALO",
            },
            {
              name: "DIBAYABAY",
            },
            {
              name: "DIBUT",
            },
            {
              name: "DIKAPINISAN",
            },
            {
              name: "DIMANAYAT",
            },
            {
              name: "DITEKI",
            },
            {
              name: "DITUMABO",
            },
            {
              name: "L. PIMENTEL",
            },
            {
              name: "NONONG SENIOR",
            },
            {
              name: "REAL",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "ZARAH",
            },
          ],
        },
      },
    },
    BASILAN: {
      name: "BASILAN",
      region: "ARMM",
      municipalities: {
        AKBAR: {
          name: "AKBAR",
          baranggays: [
            {
              name: "CADDAYAN",
            },
            {
              name: "LINONGAN",
            },
            {
              name: "LOWER BATO-BATO",
            },
            {
              name: "MANGALUT",
            },
            {
              name: "MANGUSO",
            },
            {
              name: "PAGUENGAN",
            },
            {
              name: "SEMUT",
            },
            {
              name: "UPPER BATO-BATO",
            },
            {
              name: "UPPER SINANGKAPAN",
            },
          ],
        },
        "AL-BARKA": {
          name: "AL-BARKA",
          baranggays: [
            {
              name: "APIL-APIL",
            },
            {
              name: "BATO-BATO",
            },
            {
              name: "BOHE-PIANG",
            },
            {
              name: "BUCALAO",
            },
            {
              name: "CAMBUG",
            },
            {
              name: "DANAPAH",
            },
            {
              name: "GUINANTA",
            },
            {
              name: "KAILIH",
            },
            {
              name: "KINUKUTAN",
            },
            {
              name: "KUHON",
            },
            {
              name: "KUHON LENNUH",
            },
            {
              name: "LINUAN",
            },
            {
              name: "LOOKBISAYA (KAULUNGAN ISLAND)",
            },
            {
              name: "MACALANG",
            },
            {
              name: "MAGCAWA",
            },
            {
              name: "SANGKAHAN (KAULUNGAN ISLAND)",
            },
          ],
        },
        "HADJI MOHAMMAD AJUL": {
          name: "HADJI MOHAMMAD AJUL",
          baranggays: [
            {
              name: "BASAKAN",
            },
            {
              name: "BUTON",
            },
            {
              name: "CANDIIS",
            },
            {
              name: "LANGIL",
            },
            {
              name: "LANGONG",
            },
            {
              name: "LANGUYAN",
            },
            {
              name: "PINTASAN",
            },
            {
              name: "SERONGGON",
            },
            {
              name: "SIBAGO",
            },
            {
              name: "SULUTAN MATANGAL",
            },
            {
              name: "TUBURAN PROPER (POB.)",
            },
          ],
        },
        "HADJI MUHTAMAD": {
          name: "HADJI MUHTAMAD",
          baranggays: [
            {
              name: "BALUK-BALUK",
            },
            {
              name: "DASALAN",
            },
            {
              name: "LUBUKAN",
            },
            {
              name: "LUUKBONGSOD",
            },
            {
              name: "MANANGGAL",
            },
            {
              name: "PALAHANGAN",
            },
            {
              name: "PANDUCAN",
            },
            {
              name: "SANGBAY BIG",
            },
            {
              name: "SANGBAY SMALL",
            },
            {
              name: "TAUSAN",
            },
          ],
        },
        ISABELA: {
          name: "ISABELA",
          baranggays: [
            {
              name: "ISABELA",
            },
          ],
        },
        "ISABELA CITY": {
          name: "ISABELA CITY",
          baranggays: [
            {
              name: "AGUADA",
            },
            {
              name: "BALATANAY",
            },
            {
              name: "BALUNO",
            },
            {
              name: "BEGANG",
            },
            {
              name: "BINUANGAN",
            },
            {
              name: "BUSAY",
            },
            {
              name: "CABUNBATA",
            },
            {
              name: "CALVARIO",
            },
            {
              name: "CARBON",
            },
            {
              name: "DIKI",
            },
            {
              name: "DONA RAMONA T. ALANO (ISABELA WESTSITE)",
            },
            {
              name: "ISABELA EASTSIDE (POB.)",
            },
            {
              name: "ISABELA PROPER (POB.)",
            },
            {
              name: "KAPATAGAN GRANDE",
            },
            {
              name: "KAPAYAWAN",
            },
            {
              name: "KAUMPURNAH ZONE I",
            },
            {
              name: "KAUMPURNAH ZONE II",
            },
            {
              name: "KAUMPURNAH ZONE III",
            },
            {
              name: "KUMALARANG",
            },
            {
              name: "LA PIEDAD (POB.)",
            },
            {
              name: "LAMPINIGAN",
            },
            {
              name: "LANOTE",
            },
            {
              name: "LUKBUTON",
            },
            {
              name: "LUMBANG",
            },
            {
              name: "MAKIRI",
            },
            {
              name: "MALIGUE (LUNOT)",
            },
            {
              name: "MARANG-MARANG",
            },
            {
              name: "MARKETSITE (POB.)",
            },
            {
              name: "MASULA",
            },
            {
              name: "MENZI",
            },
            {
              name: "PANIGAYAN",
            },
            {
              name: "PANUNSULAN",
            },
            {
              name: "PORT AREA (POB.)",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SEASIDE (POB.)",
            },
            {
              name: "SMALL KAPATAGAN",
            },
            {
              name: "SUMAGDANG",
            },
            {
              name: "SUNRISE VILLAGE (POB.)",
            },
            {
              name: "TABIAWAN",
            },
            {
              name: "TABUK (POB.)",
            },
            {
              name: "TAMPALAN",
            },
            {
              name: "TIMPUL",
            },
          ],
        },
        LAMITAN: {
          name: "LAMITAN",
          baranggays: [
            {
              name: "LAMITAN",
            },
          ],
        },
        "LAMITAN CITY": {
          name: "LAMITAN CITY",
          baranggays: [
            {
              name: "ARCO",
            },
            {
              name: "BA-AS",
            },
            {
              name: "BAIMBING",
            },
            {
              name: "BALAGTASAN",
            },
            {
              name: "BALAS",
            },
            {
              name: "BALOBO",
            },
            {
              name: "BATO",
            },
            {
              name: "BAUNGOS",
            },
            {
              name: "BOHEBESSEY",
            },
            {
              name: "BOHEIBU",
            },
            {
              name: "BOHENANGE",
            },
            {
              name: "BOHESAPA",
            },
            {
              name: "BOHEYAKAN",
            },
            {
              name: "BOHEYAWAS",
            },
            {
              name: "BUAHAN",
            },
            {
              name: "BULANTING",
            },
            {
              name: "BULINGAN",
            },
            {
              name: "CABOBO",
            },
            {
              name: "CALUGUSAN",
            },
            {
              name: "CAMPO UNO",
            },
            {
              name: "COLONIA",
            },
            {
              name: "DANIT-PUNTOCAN",
            },
            {
              name: "KULAY BATO",
            },
            {
              name: "LEBBUH",
            },
            {
              name: "LIMO-OK",
            },
            {
              name: "LO-OK",
            },
            {
              name: "LUKSUMBANG",
            },
            {
              name: "LUMUTON",
            },
            {
              name: "MAGANDA",
            },
            {
              name: "MALAKAS",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MALINIS (POB.)",
            },
            {
              name: "MALO-ONG CANAL",
            },
            {
              name: "MALO-ONG SAN JOSE",
            },
            {
              name: "MATATAG",
            },
            {
              name: "MATIBAY",
            },
            {
              name: "PARANGBASAK",
            },
            {
              name: "SABONG",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SENGAL",
            },
            {
              name: "SIMBANGON",
            },
            {
              name: "TANDONG AHAS",
            },
            {
              name: "TUMAKID",
            },
            {
              name: "UBIT",
            },
            {
              name: "ULAME",
            },
          ],
        },
        LANTAWAN: {
          name: "LANTAWAN",
          baranggays: [
            {
              name: "ATONG-ATONG",
            },
            {
              name: "BAGBAGON",
            },
            {
              name: "BAUNGIS",
            },
            {
              name: "BULAN-BULAN",
            },
            {
              name: "BULANZA",
            },
            {
              name: "CALAYAN",
            },
            {
              name: "CALUGUSAN",
            },
            {
              name: "CANIBUNGAN",
            },
            {
              name: "LANDUGAN",
            },
            {
              name: "LANTAWAN",
            },
            {
              name: "LANTAWAN PROPER (POB.)",
            },
            {
              name: "LAWI-LAWI",
            },
            {
              name: "LAWILA",
            },
            {
              name: "LOWER BAÑAS",
            },
            {
              name: "LOWER MANGGAS",
            },
            {
              name: "LUUK-MALUHA",
            },
            {
              name: "MATARLING",
            },
            {
              name: "MATIKANG",
            },
            {
              name: "PAMUCALIN",
            },
            {
              name: "PANIONGAN",
            },
            {
              name: "PARIAN-BAUNOH",
            },
            {
              name: "SUBA-AN (PANGASAHAN)",
            },
            {
              name: "SWITCH YAKAL",
            },
            {
              name: "TAIRAN",
            },
            {
              name: "UPPER BAÑAS",
            },
            {
              name: "UPPER MANGGAS",
            },
          ],
        },
        MALUSO: {
          name: "MALUSO",
          baranggays: [
            {
              name: "ABONG-ABONG",
            },
            {
              name: "BATUNGAL",
            },
            {
              name: "CALANG CANAS",
            },
            {
              name: "FUENTE MALUSO",
            },
            {
              name: "GUANAN NORTH (ZONE I)",
            },
            {
              name: "GUANAN SOUTH (ZONE II)",
            },
            {
              name: "LIMBUBONG",
            },
            {
              name: "MAHAYAHAY LOWER (ZONE I)",
            },
            {
              name: "MAHAYAHAY UPPER (ZONE II)",
            },
            {
              name: "MALUSO",
            },
            {
              name: "MUSLIM AREA",
            },
            {
              name: "PORT HOLLAND ZONE I POB. (UPPER)",
            },
            {
              name: "PORT HOLLAND ZONE II POB. (SHIPYARD MAIN)",
            },
            {
              name: "PORT HOLLAND ZONE III POB. (SAMAL VILLAGE)",
            },
            {
              name: "PORT HOLLAND ZONE IV (LOWER)",
            },
            {
              name: "PORT HOLLAND ZONE V (SHIPYARD TABUK)",
            },
            {
              name: "TABERLONGAN",
            },
            {
              name: "TAMUK",
            },
            {
              name: "TOWNSITE (POB.)",
            },
            {
              name: "TUBIGAN",
            },
            {
              name: "UPPER GARLAYAN",
            },
          ],
        },
        SUMISIP: {
          name: "SUMISIP",
          baranggays: [
            {
              name: "BACUNG",
            },
            {
              name: "BAIWAS",
            },
            {
              name: "BASAK",
            },
            {
              name: "BENEMBENGAN LOWER",
            },
            {
              name: "BENEMBENGAN UPPER",
            },
            {
              name: "BOHE-LANGUYAN",
            },
            {
              name: "BULI-BULI",
            },
            {
              name: "CABCABAN",
            },
            {
              name: "CABENGBENG LOWER",
            },
            {
              name: "CABENGBENG UPPER",
            },
            {
              name: "ETTUB-ETTUB",
            },
            {
              name: "GUIONG",
            },
            {
              name: "KAUM-AIR",
            },
            {
              name: "KAUMPAMATSAKEM",
            },
            {
              name: "LIBUG",
            },
            {
              name: "LIMBOCANDIS",
            },
            {
              name: "LUKKETON",
            },
            {
              name: "LUUK-BAIT",
            },
            {
              name: "MAHATALANG",
            },
            {
              name: "MANAUL",
            },
            {
              name: "MANGAL (POB.)",
            },
            {
              name: "MARANG",
            },
            {
              name: "MEBAK",
            },
            {
              name: "SAHAYA BOHE BATO",
            },
            {
              name: "SAPAH BULAK",
            },
            {
              name: "SUMISIP",
            },
            {
              name: "SUMISIP CENTRAL",
            },
            {
              name: "TIKUS",
            },
            {
              name: "TONGSENGAL",
            },
            {
              name: "TUMAHUBONG",
            },
          ],
        },
        "TABUAN-LASA": {
          name: "TABUAN-LASA",
          baranggays: [
            {
              name: "BABAG (BABUAN ISLAND)",
            },
            {
              name: "BALANTING",
            },
            {
              name: "BOLOH-BOLOH",
            },
            {
              name: "BUKUT-UMUS",
            },
            {
              name: "KAUMPURNAH",
            },
            {
              name: "LANAWAN",
            },
            {
              name: "PISAK-PISAK",
            },
            {
              name: "SALUPING",
            },
            {
              name: "SULIGAN (BABUAN ISLAND)",
            },
            {
              name: "SULLOH (TAPIANTANA)",
            },
            {
              name: "TAMBULIG BUTON",
            },
            {
              name: "TONG-UMUS",
            },
          ],
        },
        "TIPO-TIPO": {
          name: "TIPO-TIPO",
          baranggays: [
            {
              name: "BADJA",
            },
            {
              name: "BAGUINDAN",
            },
            {
              name: "BANAH",
            },
            {
              name: "BANGCUANG",
            },
            {
              name: "BOHE-TAMBAK",
            },
            {
              name: "BOHEBACA",
            },
            {
              name: "BOHELEBUNG",
            },
            {
              name: "LAGAYAS",
            },
            {
              name: "LIMBO-UPAS",
            },
            {
              name: "SILANGKUM",
            },
            {
              name: "TIPO-TIPO",
            },
            {
              name: "TIPO-TIPO PROPER (POB.)",
            },
          ],
        },
        TUBURAN: {
          name: "TUBURAN",
          baranggays: [
            {
              name: "BOHETAMBIS",
            },
            {
              name: "CALUT",
            },
            {
              name: "DUGA-A",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LAHI-LAHI",
            },
            {
              name: "LOWER SINANGKAPAN",
            },
            {
              name: "LOWER TABLAS",
            },
            {
              name: "MAHAWID",
            },
            {
              name: "SINULATAN",
            },
            {
              name: "TABLAS USEW",
            },
            {
              name: "TUBURAN",
            },
          ],
        },
        "UNGKAYA PUKAN": {
          name: "UNGKAYA PUKAN",
          baranggays: [
            {
              name: "AMALOY",
            },
            {
              name: "BOHE-PAHUH",
            },
            {
              name: "BOHE-SUYAK",
            },
            {
              name: "CABANGALAN",
            },
            {
              name: "DANIT",
            },
            {
              name: "KAMAMBURINGAN",
            },
            {
              name: "MATATA",
            },
            {
              name: "MATERLING",
            },
            {
              name: "PIPIL",
            },
            {
              name: "SUNGKAYUT",
            },
            {
              name: "TONGBATO",
            },
            {
              name: "ULITAN",
            },
          ],
        },
      },
    },
    BATAAN: {
      name: "BATAAN",
      region: "REGION 3",
      municipalities: {
        ABUCAY: {
          name: "ABUCAY",
          baranggays: [
            {
              name: "ABUCAY",
            },
            {
              name: "BANGKAL",
            },
            {
              name: "CALAYLAYAN (POB.)",
            },
            {
              name: "CAPITANGAN",
            },
            {
              name: "GABON",
            },
            {
              name: "LAON (POB.)",
            },
            {
              name: "MABATANG",
            },
            {
              name: "OMBOY",
            },
            {
              name: "SALIAN",
            },
            {
              name: "WAWA (POB.)",
            },
          ],
        },
        BAGAC: {
          name: "BAGAC",
          baranggays: [
            {
              name: "ATILANO L. RICARDO",
            },
            {
              name: "BAGAC",
            },
            {
              name: "BAGUMBAYAN (POB.)",
            },
            {
              name: "BANAWANG",
            },
            {
              name: "BINUANGAN",
            },
            {
              name: "BINUKAWAN",
            },
            {
              name: "IBABA",
            },
            {
              name: "IBIS",
            },
            {
              name: "PAG-ASA (WAWA-SIBACAN)",
            },
            {
              name: "PARANG",
            },
            {
              name: "PAYSAWAN",
            },
            {
              name: "QUINAWAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAYSAIN",
            },
            {
              name: "TABING-ILOG (POB.)",
            },
          ],
        },
        "BALANGA CITY": {
          name: "BALANGA CITY",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALANGA CITY",
            },
            {
              name: "CABOG-CABOG",
            },
            {
              name: "CAMACHO",
            },
            {
              name: "CATANING",
            },
            {
              name: "CENTRAL",
            },
            {
              name: "CUPANG NORTH",
            },
            {
              name: "CUPANG PROPER",
            },
            {
              name: "CUPANG WEST",
            },
            {
              name: "DANGCOL (BERNABE)",
            },
            {
              name: "DOÑA FRANCISCA",
            },
            {
              name: "IBAYO",
            },
            {
              name: "LOTE",
            },
            {
              name: "MALABIA",
            },
            {
              name: "MUNTING BATANGAS (CADRE)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PTO. RIVAS IBABA",
            },
            {
              name: "PTO. RIVAS ITAAS",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SIBACAN",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TANATO",
            },
            {
              name: "TENEJERO",
            },
            {
              name: "TORTUGAS",
            },
            {
              name: "TUYO",
            },
          ],
        },
        DINALUPIHAN: {
          name: "DINALUPIHAN",
          baranggays: [
            {
              name: "AQUINO",
            },
            {
              name: "BANGAL",
            },
            {
              name: "BAYAN-BAYANAN",
            },
            {
              name: "BONIFACIO (POB.)",
            },
            {
              name: "BURGOS (POB.)",
            },
            {
              name: "COLO",
            },
            {
              name: "DAANG BAGO",
            },
            {
              name: "DALAO",
            },
            {
              name: "DEL PILAR (POB.)",
            },
            {
              name: "DINALUPIHAN",
            },
            {
              name: "GEN. LUNA (POB.)",
            },
            {
              name: "GOMEZ (POB.)",
            },
            {
              name: "HAPPY VALLEY",
            },
            {
              name: "JOSE C. PAYUMO, JR.",
            },
            {
              name: "KATAASAN",
            },
            {
              name: "LAYAC",
            },
            {
              name: "LUACAN",
            },
            {
              name: "MABINI EXT. (POB.)",
            },
            {
              name: "MABINI PROPER (POB.)",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "NAPARING",
            },
            {
              name: "NEW SAN JOSE",
            },
            {
              name: "OLD SAN JOSE",
            },
            {
              name: "PADRE DANDAN (POB.)",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PAGALANGGANG",
            },
            {
              name: "PAYANGAN",
            },
            {
              name: "PAYANGAN",
            },
            {
              name: "PENTOR",
            },
            {
              name: "PINULOT",
            },
            {
              name: "PITA",
            },
            {
              name: "ROOSEVELT",
            },
            {
              name: "ROXAS (POB.)",
            },
            {
              name: "SAGUING",
            },
            {
              name: "SAN BENITO",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN PABLO (BULATE)",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN SIMON",
            },
            {
              name: "SANTA ISABEL (TABACAN)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAPANG BALAS",
            },
            {
              name: "TORRES BUGAUEN (POB.)",
            },
            {
              name: "TUBO-TUBO",
            },
            {
              name: "TUCOP",
            },
            {
              name: "ZAMORA (POB.)",
            },
          ],
        },
        HERMOSA: {
          name: "HERMOSA",
          baranggays: [
            {
              name: "A. RIVERA (POB.)",
            },
            {
              name: "ALMACEN",
            },
            {
              name: "BACONG",
            },
            {
              name: "BALSIC",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "BURGOS-SOLIMAN (POB.)",
            },
            {
              name: "CATANING (POB.)",
            },
            {
              name: "CULIS",
            },
            {
              name: "DAUNGAN (POB.)",
            },
            {
              name: "HERMOSA",
            },
            {
              name: "JUDGE ROMAN CRUZ SR. (MANDAMA)",
            },
            {
              name: "MABIGA",
            },
            {
              name: "MABUCO",
            },
            {
              name: "MAITE",
            },
            {
              name: "MAMBOG - MANDAMA",
            },
            {
              name: "PALIHAN",
            },
            {
              name: "PANDATUNG",
            },
            {
              name: "SABA",
            },
            {
              name: "SACRIFICE VALLEY",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SANTO CRISTO (POB.)",
            },
            {
              name: "SUMALO",
            },
            {
              name: "TIPO",
            },
          ],
        },
        LIMAY: {
          name: "LIMAY",
          baranggays: [
            {
              name: "ALANGAN",
            },
            {
              name: "DUALE",
            },
            {
              name: "KITANG 2 AND LUZ",
            },
            {
              name: "KITANG I",
            },
            {
              name: "LAMAO",
            },
            {
              name: "LANDING",
            },
            {
              name: "LIMAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "REFORMISTA",
            },
            {
              name: "SAN FRANCISCO DE ASIS",
            },
            {
              name: "ST. FRANCIS II",
            },
            {
              name: "TOWNSITE",
            },
            {
              name: "WAWA",
            },
          ],
        },
        MARIVELES: {
          name: "MARIVELES",
          baranggays: [
            {
              name: "ALAS-ASIN",
            },
            {
              name: "ALION",
            },
            {
              name: "BALON-ANITO",
            },
            {
              name: "BASECO COUNTRY (NASSCO)",
            },
            {
              name: "BATANGAS II",
            },
            {
              name: "BIAAN",
            },
            {
              name: "CABCABEN",
            },
            {
              name: "CAMAYA",
            },
            {
              name: "IPAG",
            },
            {
              name: "LUCANIN",
            },
            {
              name: "MALAYA",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MARIVELES",
            },
            {
              name: "MT. VIEW",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SISIMAN",
            },
            {
              name: "TOWNSITE",
            },
          ],
        },
        MORONG: {
          name: "MORONG",
          baranggays: [
            {
              name: "BINARITAN",
            },
            {
              name: "MABAYO",
            },
            {
              name: "MORONG",
            },
            {
              name: "NAGBALAYONG",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        ORANI: {
          name: "ORANI",
          baranggays: [
            {
              name: "APOLLO",
            },
            {
              name: "BAGONG PARAISO (POB.)",
            },
            {
              name: "BALUT (POB.)",
            },
            {
              name: "BAYAN (POB.)",
            },
            {
              name: "CALERO (POB.)",
            },
            {
              name: "CENTRO I (POB.)",
            },
            {
              name: "CENTRO II (POB.)",
            },
            {
              name: "DONA",
            },
            {
              name: "KABALUTAN",
            },
            {
              name: "KAPARANGAN",
            },
            {
              name: "MARIA FE",
            },
            {
              name: "MASANTOL",
            },
            {
              name: "MULAWIN",
            },
            {
              name: "ORANI",
            },
            {
              name: "PAKING-CARBONERO (POB.)",
            },
            {
              name: "PALIHAN (POB.)",
            },
            {
              name: "PANTALAN BAGO (POB.)",
            },
            {
              name: "PANTALAN LUMA (POB.)",
            },
            {
              name: "PARANG PARANG (POB.)",
            },
            {
              name: "PUKSUAN",
            },
            {
              name: "SILAHIS",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TALA",
            },
            {
              name: "TALIMUNDOC",
            },
            {
              name: "TAPULAO",
            },
            {
              name: "TENEJERO (POB.)",
            },
            {
              name: "TUGATOG",
            },
            {
              name: "WAWA (POB.)",
            },
          ],
        },
        ORION: {
          name: "ORION",
          baranggays: [
            {
              name: "ARELLANO (POB.)",
            },
            {
              name: "BAGUMBAYAN (POB.)",
            },
            {
              name: "BALAGTAS (POB.)",
            },
            {
              name: "BALUT (POB.)",
            },
            {
              name: "BANTAN",
            },
            {
              name: "BILOLO",
            },
            {
              name: "CALUNGUSAN",
            },
            {
              name: "CAMACHILE",
            },
            {
              name: "DAANG BAGO (POB.)",
            },
            {
              name: "DAANG BILOLO (POB.)",
            },
            {
              name: "DAANG PARE",
            },
            {
              name: "GENERAL LIM (KAPUT)",
            },
            {
              name: "KAPUNITAN",
            },
            {
              name: "LATI (POB.)",
            },
            {
              name: "LUSUNGAN (POB.)",
            },
            {
              name: "ORION",
            },
            {
              name: "PUTING BUHANGIN",
            },
            {
              name: "SABATAN",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "VILLA ANGELES (POB.)",
            },
            {
              name: "WAKAS (POB.)",
            },
            {
              name: "WAWA (POB.)",
            },
          ],
        },
        PILAR: {
          name: "PILAR",
          baranggays: [
            {
              name: "ALA-ULI",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALUT I",
            },
            {
              name: "BALUT II",
            },
            {
              name: "BANTAN MUNTI",
            },
            {
              name: "BURGOS",
            },
            {
              name: "DEL ROSARIO (POB.)",
            },
            {
              name: "DIWA",
            },
            {
              name: "LANDING",
            },
            {
              name: "LIYANG",
            },
            {
              name: "NAGWALING",
            },
            {
              name: "PANILAO",
            },
            {
              name: "PANTINGAN",
            },
            {
              name: "PILAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "WAKAS NORTH",
            },
            {
              name: "WAKAS SOUTH",
            },
            {
              name: "WAWA",
            },
          ],
        },
        SAMAL: {
          name: "SAMAL",
          baranggays: [
            {
              name: "EAST CALAGUIMAN (POB.)",
            },
            {
              name: "EAST DAANG BAGO (POB.)",
            },
            {
              name: "GUGO",
            },
            {
              name: "IBABA (POB.)",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LALAWIGAN",
            },
            {
              name: "PALILI",
            },
            {
              name: "SAMAL",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SAPA",
            },
            {
              name: "TABING ILOG",
            },
            {
              name: "WEST CALAGUIMAN (POB.)",
            },
            {
              name: "WEST DAANG BAGO (POB.)",
            },
          ],
        },
      },
    },
    BATANES: {
      name: "BATANES",
      region: "REGION 2",
      municipalities: {
        BASCO: {
          name: "BASCO",
          baranggays: [
            {
              name: "BASCO",
            },
            {
              name: "CHANARIAN",
            },
            {
              name: "IHUBOK I (KAYCHANARIANAN)",
            },
            {
              name: "IHUBOK II (KAYVALUGANAN)",
            },
            {
              name: "KAYHUVOKAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOAQUIN",
            },
          ],
        },
        ITBAYAT: {
          name: "ITBAYAT",
          baranggays: [
            {
              name: "ITBAYAT",
            },
            {
              name: "RAELE",
            },
            {
              name: "SAN RAFAEL (IDIANG)",
            },
            {
              name: "SANTA LUCIA (KAUHAUHASAN)",
            },
            {
              name: "SANTA MARIA (MARAPUY)",
            },
            {
              name: "SANTA ROSA (KAYNATUAN)",
            },
          ],
        },
        IVANA: {
          name: "IVANA",
          baranggays: [
            {
              name: "IVANA",
            },
            {
              name: "RADIWAN",
            },
            {
              name: "SALAGAO",
            },
            {
              name: "SAN VICENTE (IGANG)",
            },
            {
              name: "TUHEL (POB.)",
            },
          ],
        },
        MAHATAO: {
          name: "MAHATAO",
          baranggays: [
            {
              name: "HANIB",
            },
            {
              name: "KAUMBAKAN",
            },
            {
              name: "MAHATAO",
            },
            {
              name: "PANATAYAN",
            },
            {
              name: "UVOY (POB.)",
            },
          ],
        },
        SABTANG: {
          name: "SABTANG",
          baranggays: [
            {
              name: "CHAVAYAN",
            },
            {
              name: "MALAKDANG (POB.)",
            },
            {
              name: "NAKANMUAN",
            },
            {
              name: "SABTANG",
            },
            {
              name: "SAVIDUG",
            },
            {
              name: "SINAKAN (POB.)",
            },
            {
              name: "SUMNANGA",
            },
          ],
        },
        UYUGAN: {
          name: "UYUGAN",
          baranggays: [
            {
              name: "IMNAJBU",
            },
            {
              name: "ITBUD",
            },
            {
              name: "KAYUGANAN (POB.)",
            },
            {
              name: "KAYVALUGANAN (POB.)",
            },
            {
              name: "UYUGAN",
            },
          ],
        },
      },
    },
    BATANGAS: {
      name: "BATANGAS",
      region: "REGION 4-A",
      municipalities: {
        AGONCILLO: {
          name: "AGONCILLO",
          baranggays: [
            {
              name: "ADIA",
            },
            {
              name: "AGONCILLO",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BALANGON",
            },
            {
              name: "BANGIN",
            },
            {
              name: "BANYAGA",
            },
            {
              name: "BARIGON",
            },
            {
              name: "BILIBINWANG",
            },
            {
              name: "CORAL NA MUNTI",
            },
            {
              name: "GUITNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "PAMIGA",
            },
            {
              name: "PANHULAN",
            },
            {
              name: "PANSIPIT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POOK",
            },
            {
              name: "SAN JACINTO",
            },
            {
              name: "SAN TEODORO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SUBIC IBABA",
            },
            {
              name: "SUBIC ILAYA",
            },
          ],
        },
        ALITAGTAG: {
          name: "ALITAGTAG",
          baranggays: [
            {
              name: "ALITAGTAG",
            },
            {
              name: "BALAGBAG",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CONCORDIA",
            },
            {
              name: "DALIPIT EAST",
            },
            {
              name: "DALIPIT WEST",
            },
            {
              name: "DOMINADOR EAST",
            },
            {
              name: "DOMINADOR WEST",
            },
            {
              name: "MUNLAWIN NORTE",
            },
            {
              name: "MUNLAWIN SUR",
            },
            {
              name: "MUZON PRIMERO",
            },
            {
              name: "MUZON SEGUNDO",
            },
            {
              name: "PINAGKURUSAN",
            },
            {
              name: "PING-AS",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TADLAC",
            },
          ],
        },
        BALAYAN: {
          name: "BALAYAN",
          baranggays: [
            {
              name: "BACLARAN",
            },
            {
              name: "BALAYAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "CALAN",
            },
            {
              name: "CALOOCAN",
            },
            {
              name: "CALZADA",
            },
            {
              name: "CANDA",
            },
            {
              name: "CARENAHAN",
            },
            {
              name: "CAYBUNGA",
            },
            {
              name: "CAYPONCE",
            },
            {
              name: "DALIG",
            },
            {
              name: "DAO",
            },
            {
              name: "DILAO",
            },
            {
              name: "DUHATAN",
            },
            {
              name: "DURUNGAO",
            },
            {
              name: "GIMALAS",
            },
            {
              name: "GUMAMELA",
            },
            {
              name: "LAGNAS",
            },
            {
              name: "LANATAN",
            },
            {
              name: "LANGGANGAN",
            },
            {
              name: "LUCBAN POOK",
            },
            {
              name: "LUCBAN PUTOL",
            },
            {
              name: "MAGABE",
            },
            {
              name: "MALALAY",
            },
            {
              name: "MUNTING TUBIG",
            },
            {
              name: "NAVOTAS",
            },
            {
              name: "PALIKPIKAN",
            },
            {
              name: "PATUGO",
            },
            {
              name: "POOC",
            },
            {
              name: "SAMBAT",
            },
            {
              name: "SAMPAGA",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PIRO",
            },
            {
              name: "SANTOL",
            },
            {
              name: "SUKOL",
            },
            {
              name: "TACTAC",
            },
            {
              name: "TALUDTUD",
            },
            {
              name: "TANGGOY",
            },
          ],
        },
        BALETE: {
          name: "BALETE",
          baranggays: [
            {
              name: "ALANGILAN",
            },
            {
              name: "BALETE",
            },
            {
              name: "CALAWIT",
            },
            {
              name: "LOOC",
            },
            {
              name: "MAGAPI",
            },
            {
              name: "MAKINA",
            },
            {
              name: "MALABANAN",
            },
            {
              name: "PALIGAWAN",
            },
            {
              name: "PALSARA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALA",
            },
            {
              name: "SAMPALOCAN",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SOLIS",
            },
          ],
        },
        "BATANGAS CITY": {
          name: "BATANGAS CITY",
          baranggays: [
            {
              name: "ALANGILAN",
            },
            {
              name: "BALAGTAS",
            },
            {
              name: "BALETE",
            },
            {
              name: "BANABA CENTER",
            },
            {
              name: "BANABA IBABA",
            },
            {
              name: "BANABA KANLURAN",
            },
            {
              name: "BANABA SILANGAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 14 (POB.)",
            },
            {
              name: "BARANGAY 15 (POB.)",
            },
            {
              name: "BARANGAY 16 (POB.)",
            },
            {
              name: "BARANGAY 17 (POB.)",
            },
            {
              name: "BARANGAY 18 (POB.)",
            },
            {
              name: "BARANGAY 19 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 20 (POB.)",
            },
            {
              name: "BARANGAY 21 (POB.)",
            },
            {
              name: "BARANGAY 22 (POB.)",
            },
            {
              name: "BARANGAY 23 (POB.)",
            },
            {
              name: "BARANGAY 24 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BATANGAS CITY",
            },
            {
              name: "BILOGO",
            },
            {
              name: "BOLBOK",
            },
            {
              name: "BUKAL",
            },
            {
              name: "CALICANTO",
            },
            {
              name: "CATANDALA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CONDE ITAAS",
            },
            {
              name: "CONDE LABAK",
            },
            {
              name: "CUTA",
            },
            {
              name: "DALIG",
            },
            {
              name: "DELA PAZ",
            },
            {
              name: "DELA PAZ PULOT APLAYA",
            },
            {
              name: "DELA PAZ PULOT ITAAS",
            },
            {
              name: "DOMOCLAY",
            },
            {
              name: "DUMANTAY",
            },
            {
              name: "GULOD ITAAS",
            },
            {
              name: "GULOD LABAK",
            },
            {
              name: "HALIGUE KANLURAN",
            },
            {
              name: "HALIGUE SILANGAN",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "KUMBA",
            },
            {
              name: "KUMINTANG IBABA",
            },
            {
              name: "KUMINTANG ILAYA",
            },
            {
              name: "LIBJO",
            },
            {
              name: "LIPONPON, ISLA VERDE",
            },
            {
              name: "MAAPAS",
            },
            {
              name: "MABACONG (MATOCO)",
            },
            {
              name: "MAHABANG DAHILIG",
            },
            {
              name: "MAHABANG PARANG",
            },
            {
              name: "MAHACOT KANLURAN",
            },
            {
              name: "MAHACOT SILANGAN",
            },
            {
              name: "MALALIM",
            },
            {
              name: "MALIBAYO",
            },
            {
              name: "MALITAM",
            },
            {
              name: "MARUCLAP",
            },
            {
              name: "PAGKILATAN",
            },
            {
              name: "PAHARANG KANLURAN",
            },
            {
              name: "PAHARANG SILANGAN",
            },
            {
              name: "PALLOCAN KANLURAN",
            },
            {
              name: "PALLOCAN SILANGAN",
            },
            {
              name: "PINAMUCAN",
            },
            {
              name: "PINAMUCAN IBABA",
            },
            {
              name: "PINAMUCAN SILANGAN",
            },
            {
              name: "SAMPAGA",
            },
            {
              name: "SAN AGAPITO, ISLA VERDE",
            },
            {
              name: "SAN AGUSTIN KANLURAN, ISLA VERDE",
            },
            {
              name: "SAN AGUSTIN SILANGAN, ISLA VERDE",
            },
            {
              name: "SAN ANDRES, ISLA VERDE",
            },
            {
              name: "SAN ANTONIO, ISLA VERDE",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE SICO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTA RITA APLAYA",
            },
            {
              name: "SANTA RITA KARSADA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIMLONG",
            },
            {
              name: "SIRANG LUPA",
            },
            {
              name: "SOROSORO IBABA",
            },
            {
              name: "SOROSORO ILAYA",
            },
            {
              name: "SOROSORO KARSADA",
            },
            {
              name: "TABANGAO AMBULONG",
            },
            {
              name: "TABANGAO APLAYA (TABANGAO PROPER)",
            },
            {
              name: "TABANGAO DAO",
            },
            {
              name: "TALAHIB PANDAYAN",
            },
            {
              name: "TALAHIB PAYAPA",
            },
            {
              name: "TALUMPOK KANLURAN",
            },
            {
              name: "TALUMPOK SILANGAN",
            },
            {
              name: "TINGA ITAAS",
            },
            {
              name: "TINGA LABAK",
            },
            {
              name: "TULO",
            },
            {
              name: "WAWA",
            },
          ],
        },
        BAUAN: {
          name: "BAUAN",
          baranggays: [
            {
              name: "ALAGAO",
            },
            {
              name: "APLAYA",
            },
            {
              name: "AS-IS",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAGUILAWA",
            },
            {
              name: "BALAYONG",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BAUAN",
            },
            {
              name: "BOLO",
            },
            {
              name: "COLVO",
            },
            {
              name: "CUPANG",
            },
            {
              name: "DURUNGAO",
            },
            {
              name: "GULIBAY",
            },
            {
              name: "INICBULAN",
            },
            {
              name: "LOCLOC",
            },
            {
              name: "MAGALANG-GALANG",
            },
            {
              name: "MALINDIG",
            },
            {
              name: "MANALUPONG",
            },
            {
              name: "MANGHINAO PROPER",
            },
            {
              name: "MANGHINAO UNO",
            },
            {
              name: "NEW DANGLAYAN",
            },
            {
              name: "ORENSE",
            },
            {
              name: "PITUGO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANDRES PROPER",
            },
            {
              name: "SAN ANDRES UNO",
            },
            {
              name: "SAN DIEGO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN TEODORO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SINALA",
            },
          ],
        },
        CALACA: {
          name: "CALACA",
          baranggays: [
            {
              name: "BACLAS",
            },
            {
              name: "BAGONG TUBIG",
            },
            {
              name: "BALIMBING",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BISAYA",
            },
            {
              name: "CAHIL",
            },
            {
              name: "CALACA",
            },
            {
              name: "CALANTAS",
            },
            {
              name: "CALUANGAN",
            },
            {
              name: "CAMASTILISAN",
            },
            {
              name: "CORAL NI BACAL",
            },
            {
              name: "CORAL NI LOPEZ (SUGOD)",
            },
            {
              name: "DACANLAO",
            },
            {
              name: "DILA",
            },
            {
              name: "LOMA",
            },
            {
              name: "LUMBANG CALZADA",
            },
            {
              name: "LUMBANG NA BATA",
            },
            {
              name: "LUMBANG NA MATANDA",
            },
            {
              name: "MADALUNOT",
            },
            {
              name: "MAKINA",
            },
            {
              name: "MATIPOK",
            },
            {
              name: "MUNTING CORAL",
            },
            {
              name: "NIYUGAN",
            },
            {
              name: "PANTAY",
            },
            {
              name: "PUTING BATO EAST",
            },
            {
              name: "PUTING BATO WEST",
            },
            {
              name: "PUTING KAHOY",
            },
            {
              name: "QUISUMBING",
            },
            {
              name: "SALONG",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SINISIAN",
            },
            {
              name: "TAKLANG ANAK",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAMAYO",
            },
            {
              name: "TIMBAIN",
            },
          ],
        },
        CALATAGAN: {
          name: "CALATAGAN",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAHA",
            },
            {
              name: "BALIBAGO",
            },
            {
              name: "BALITOC",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BIGA",
            },
            {
              name: "BUCAL",
            },
            {
              name: "CALATAGAN",
            },
            {
              name: "CARLOSA",
            },
            {
              name: "CARRETUNAN",
            },
            {
              name: "ENCARNACION",
            },
            {
              name: "GULOD",
            },
            {
              name: "HUKAY",
            },
            {
              name: "LUCSUHIN",
            },
            {
              name: "LUYA",
            },
            {
              name: "PARAISO",
            },
            {
              name: "QUILITISAN",
            },
            {
              name: "REAL",
            },
            {
              name: "SAMBUNGAN",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "TALIBAYOG",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TANAGAN",
            },
          ],
        },
        CUENCA: {
          name: "CUENCA",
          baranggays: [
            {
              name: "BALAGBAG",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BUNGAHAN",
            },
            {
              name: "CALUMAYIN",
            },
            {
              name: "CUENCA",
            },
            {
              name: "DALIPIT EAST",
            },
            {
              name: "DALIPIT WEST",
            },
            {
              name: "DITA",
            },
            {
              name: "DON JUAN",
            },
            {
              name: "EMMANUEL",
            },
            {
              name: "IBABAO",
            },
            {
              name: "LABAC",
            },
            {
              name: "PINAGKAISAHAN",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        IBAAN: {
          name: "IBAAN",
          baranggays: [
            {
              name: "BAGO",
            },
            {
              name: "BALANGA",
            },
            {
              name: "BUNGAHAN",
            },
            {
              name: "CALAMIAS",
            },
            {
              name: "CATANDALA",
            },
            {
              name: "COLIAT",
            },
            {
              name: "DAYAPAN",
            },
            {
              name: "IBAAN",
            },
            {
              name: "LAPU-LAPU",
            },
            {
              name: "LUCSUHIN",
            },
            {
              name: "MABALOR",
            },
            {
              name: "MALAININ",
            },
            {
              name: "MATALA",
            },
            {
              name: "MUNTING-TUBIG",
            },
            {
              name: "PALINDAN",
            },
            {
              name: "PANGAO",
            },
            {
              name: "PANGHAYAAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUILO",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALABAN I",
            },
            {
              name: "SALABAN II",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SANDALAN",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TALAIBON",
            },
            {
              name: "TULAY NA PATPAT",
            },
          ],
        },
        LAUREL: {
          name: "LAUREL",
          baranggays: [
            {
              name: "AS-IS",
            },
            {
              name: "BALAKILONG",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BERINAYAN",
            },
            {
              name: "BUGAAN EAST",
            },
            {
              name: "BUGAAN WEST",
            },
            {
              name: "BUSO-BUSO",
            },
            {
              name: "DAYAP ITAAS",
            },
            {
              name: "GULOD",
            },
            {
              name: "J. LEVISTE",
            },
            {
              name: "LAUREL",
            },
            {
              name: "MOLINETE",
            },
            {
              name: "NIYUGAN",
            },
            {
              name: "PALIPARAN",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "TICUB",
            },
          ],
        },
        LEMERY: {
          name: "LEMERY",
          baranggays: [
            {
              name: "ANAK-DAGAT",
            },
            {
              name: "ARUMAHAN",
            },
            {
              name: "AYAO-IYAO",
            },
            {
              name: "BAGONG POOK",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BALANGA",
            },
            {
              name: "BUKAL",
            },
            {
              name: "CAHILAN I",
            },
            {
              name: "CAHILAN II",
            },
            {
              name: "DAYAPAN",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "DISTRICT III (POB.)",
            },
            {
              name: "DISTRICT IV (POB.)",
            },
            {
              name: "DITA",
            },
            {
              name: "GULOD",
            },
            {
              name: "LEMERY",
            },
            {
              name: "LUCKY",
            },
            {
              name: "MAGUIHAN",
            },
            {
              name: "MAHABANG DAHILIG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MAIGSING DAHILIG",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MALINIS",
            },
            {
              name: "MASALISI",
            },
            {
              name: "MATAAS NA BAYAN",
            },
            {
              name: "MATINGAIN I",
            },
            {
              name: "MATINGAIN II",
            },
            {
              name: "MAYASANG",
            },
            {
              name: "NIUGAN",
            },
            {
              name: "NONONG CASTO",
            },
            {
              name: "PALANAS",
            },
            {
              name: "PAYAPA IBABA",
            },
            {
              name: "PAYAPA ILAYA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAMBAL IBABA",
            },
            {
              name: "SAMBAL ILAYA",
            },
            {
              name: "SAN ISIDRO IBABA",
            },
            {
              name: "SAN ISIDRO ITAAS",
            },
            {
              name: "SANGALANG",
            },
            {
              name: "SINISIAN EAST",
            },
            {
              name: "SINISIAN WEST",
            },
            {
              name: "TALAGA",
            },
            {
              name: "TUBIGAN",
            },
            {
              name: "TUBUAN",
            },
            {
              name: "WAWA IBABA",
            },
            {
              name: "WAWA ILAYA",
            },
          ],
        },
        LIAN: {
          name: "LIAN",
          baranggays: [
            {
              name: "BAGONG POOK",
            },
            {
              name: "BALIBAGO",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BINUBUSAN",
            },
            {
              name: "BUNGAHAN",
            },
            {
              name: "CUMBA",
            },
            {
              name: "HUMAYINGAN",
            },
            {
              name: "KAPITO",
            },
            {
              name: "LIAN",
            },
            {
              name: "LUMANIAG",
            },
            {
              name: "LUYAHAN",
            },
            {
              name: "MALARUHATAN",
            },
            {
              name: "MATABUNGKAY",
            },
            {
              name: "PRENZA",
            },
            {
              name: "PUTING-KAHOY",
            },
            {
              name: "SAN DIEGO",
            },
          ],
        },
        "LIPA CITY": {
          name: "LIPA CITY",
          baranggays: [
            {
              name: "ADYA",
            },
            {
              name: "ANILAO",
            },
            {
              name: "ANILAO-LABAC",
            },
            {
              name: "ANTIPOLO DEL NORTE",
            },
            {
              name: "ANTIPOLO DEL SUR",
            },
            {
              name: "BAGONG POOK",
            },
            {
              name: "BALINTAWAK",
            },
            {
              name: "BANAYBANAY",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BOLBOK",
            },
            {
              name: "BUGTONG NA PULO",
            },
            {
              name: "BULACNIN",
            },
            {
              name: "BULAKLAKAN",
            },
            {
              name: "CALAMIAS",
            },
            {
              name: "CUMBA",
            },
            {
              name: "DAGATAN",
            },
            {
              name: "DUHATAN",
            },
            {
              name: "HALANG",
            },
            {
              name: "INOSLOBAN",
            },
            {
              name: "KAYUMANGGI",
            },
            {
              name: "LATAG",
            },
            {
              name: "LIPA CITY",
            },
            {
              name: "LODLOD",
            },
            {
              name: "LUMBANG",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALAGONLONG",
            },
            {
              name: "MALITLIT",
            },
            {
              name: "MARAUOY",
            },
            {
              name: "MATAAS NA LUPA",
            },
            {
              name: "MUNTING PULO",
            },
            {
              name: "PAGOLINGIN BATA",
            },
            {
              name: "PAGOLINGIN EAST",
            },
            {
              name: "PAGOLINGIN WEST",
            },
            {
              name: "PANGAO",
            },
            {
              name: "PINAGKAWITAN",
            },
            {
              name: "PINAGTONGULAN",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POBLACION BARANGAY 1",
            },
            {
              name: "POBLACION BARANGAY 10",
            },
            {
              name: "POBLACION BARANGAY 11",
            },
            {
              name: "POBLACION BARANGAY 2",
            },
            {
              name: "POBLACION BARANGAY 3",
            },
            {
              name: "POBLACION BARANGAY 4",
            },
            {
              name: "POBLACION BARANGAY 5",
            },
            {
              name: "POBLACION BARANGAY 6",
            },
            {
              name: "POBLACION BARANGAY 7",
            },
            {
              name: "POBLACION BARANGAY 8",
            },
            {
              name: "POBLACION BARANGAY 9",
            },
            {
              name: "PUSIL",
            },
            {
              name: "QUEZON",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN BENITO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN CELESTINO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LUCAS",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "SAN SEBASTIAN (BALAGBAG)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO TORIBIO",
            },
            {
              name: "SAPAC",
            },
            {
              name: "SICO",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TANGOB",
            },
            {
              name: "TANGUAY",
            },
            {
              name: "TIBIG",
            },
            {
              name: "TIPACAN",
            },
          ],
        },
        LOBO: {
          name: "LOBO",
          baranggays: [
            {
              name: "APAR",
            },
            {
              name: "BALATBAT",
            },
            {
              name: "BALIBAGO",
            },
            {
              name: "BANALO",
            },
            {
              name: "BIGA",
            },
            {
              name: "BIGNAY",
            },
            {
              name: "CALO",
            },
            {
              name: "CALUMPIT",
            },
            {
              name: "FABRICA",
            },
            {
              name: "JAYBANGA",
            },
            {
              name: "LAGADLARIN",
            },
            {
              name: "LOBO",
            },
            {
              name: "MABILOG NA BUNDOK",
            },
            {
              name: "MALABRIGO",
            },
            {
              name: "MALALIM NA SANOG",
            },
            {
              name: "MALAPAD NA PARANG",
            },
            {
              name: "MASAGUITSIT",
            },
            {
              name: "NAGTALONGTONG",
            },
            {
              name: "NAGTOCTOC",
            },
            {
              name: "OLO-OLO",
            },
            {
              name: "PINAGHAWANAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAWANG",
            },
            {
              name: "SOLOC",
            },
            {
              name: "TAYUMAN",
            },
          ],
        },
        MABINI: {
          name: "MABINI",
          baranggays: [
            {
              name: "ANILAO EAST",
            },
            {
              name: "ANILAO PROPER",
            },
            {
              name: "BAGALANGIT",
            },
            {
              name: "BULACAN",
            },
            {
              name: "CALAMIAS",
            },
            {
              name: "ESTRELLA",
            },
            {
              name: "GASANG",
            },
            {
              name: "LAUREL",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAINAGA",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MAJUBEN",
            },
            {
              name: "MALIMATOC I",
            },
            {
              name: "MALIMATOC II",
            },
            {
              name: "NAG-IBA",
            },
            {
              name: "PILAHAN",
            },
            {
              name: "POBLCION",
            },
            {
              name: "PULANG LUPA",
            },
            {
              name: "PULONG ANAHAO",
            },
            {
              name: "PULONG BALIBAGUHAN",
            },
            {
              name: "PULONG NIOGAN",
            },
            {
              name: "SAGUING",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN TEODORO",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA MESA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SOLO",
            },
            {
              name: "TALAGA EAST",
            },
            {
              name: "TALAGA PROPER",
            },
          ],
        },
        MALVAR: {
          name: "MALVAR",
          baranggays: [
            {
              name: "BAGONG POOK",
            },
            {
              name: "BILUCAO (SAN ISIDRO WESTERN)",
            },
            {
              name: "BULIHAN",
            },
            {
              name: "LUTA DEL NORTE",
            },
            {
              name: "LUTA DEL SUR",
            },
            {
              name: "MALVAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN ISIDRO EAST",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO I (EASTERN)",
            },
            {
              name: "SAN PEDRO II (WESTERN)",
            },
            {
              name: "SAN PIOQUINTO",
            },
            {
              name: "SANTIAGO",
            },
          ],
        },
        "MATAAS NA KAHOY": {
          name: "MATAAS NA KAHOY",
          baranggays: [
            {
              name: "BARANGAY II-A (POB.)",
            },
            {
              name: "BAYORBOR",
            },
            {
              name: "BUBUYAN",
            },
            {
              name: "CALINGATAN",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "DISTRICT III (POB.)",
            },
            {
              name: "DISTRICT IV (POB.)",
            },
            {
              name: "KINALAGLAGAN",
            },
            {
              name: "LOOB",
            },
            {
              name: "LUMANG LIPA",
            },
            {
              name: "MANGGAHAN",
            },
            {
              name: "MATAAS NA KAHOY",
            },
            {
              name: "NANGKAAN",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SANTOL",
            },
            {
              name: "UPA",
            },
          ],
        },
        NASUGBU: {
          name: "NASUGBU",
          baranggays: [
            {
              name: "AGA",
            },
            {
              name: "BALAYTIGUI",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BILARAN",
            },
            {
              name: "BUCANA",
            },
            {
              name: "BULIHAN",
            },
            {
              name: "BUNDUCAN",
            },
            {
              name: "BUTUCAN",
            },
            {
              name: "CALAYO",
            },
            {
              name: "CATANDAAN",
            },
            {
              name: "COGUNAN",
            },
            {
              name: "DAYAP",
            },
            {
              name: "HAMILO",
            },
            {
              name: "KAYLAWAY",
            },
            {
              name: "KAYRILAW",
            },
            {
              name: "LATAG",
            },
            {
              name: "LOOC",
            },
            {
              name: "LUMBANGAN",
            },
            {
              name: "MALAPAD NA BATO",
            },
            {
              name: "MATAAS NA PULO",
            },
            {
              name: "MAUGAT",
            },
            {
              name: "MUNTING INDAN",
            },
            {
              name: "NASUGBU",
            },
            {
              name: "NATIPUAN",
            },
            {
              name: "PANTALAN",
            },
            {
              name: "PAPAYA",
            },
            {
              name: "PUTAT",
            },
            {
              name: "REPARO",
            },
            {
              name: "TUMALIM",
            },
            {
              name: "UTOD",
            },
            {
              name: "WAWA",
            },
          ],
        },
        "PADRE GARCIA": {
          name: "PADRE GARCIA",
          baranggays: [
            {
              name: "BANABA",
            },
            {
              name: "BANAYBANAY",
            },
            {
              name: "BAWI",
            },
            {
              name: "BUKAL",
            },
            {
              name: "CASTILLO",
            },
            {
              name: "CAWONGAN",
            },
            {
              name: "MANGGAS",
            },
            {
              name: "MAUGAT EAST",
            },
            {
              name: "MAUGAT WEST",
            },
            {
              name: "PADRE GARCIA",
            },
            {
              name: "PANSOL",
            },
            {
              name: "PAYAPA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUILO-QUILO NORTH",
            },
            {
              name: "QUILO-QUILO SOUTH",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "TAMAK",
            },
            {
              name: "TANGOB",
            },
          ],
        },
        ROSARIO: {
          name: "ROSARIO",
          baranggays: [
            {
              name: "ALUPAY",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "BAGONG POOK",
            },
            {
              name: "BALIBAGO",
            },
            {
              name: "BARANGAY A (POB.)",
            },
            {
              name: "BARANGAY B (POB.)",
            },
            {
              name: "BARANGAY C (POB.)",
            },
            {
              name: "BARANGAY D (POB.)",
            },
            {
              name: "BARANGAY E (POB.)",
            },
            {
              name: "BAYAWANG",
            },
            {
              name: "BAYBAYIN",
            },
            {
              name: "BULIHAN",
            },
            {
              name: "CAHIGAM",
            },
            {
              name: "CALANTAS",
            },
            {
              name: "COLONGAN",
            },
            {
              name: "ITLUGAN",
            },
            {
              name: "LEVISTE (TUBAHAN)",
            },
            {
              name: "LUMBANGAN",
            },
            {
              name: "MAALAS-AS",
            },
            {
              name: "MABATO",
            },
            {
              name: "MABUNGA",
            },
            {
              name: "MACALAMCAM A",
            },
            {
              name: "MACALAMCAM B",
            },
            {
              name: "MALAYA",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MARILAG",
            },
            {
              name: "MASAYA",
            },
            {
              name: "MATAMIS",
            },
            {
              name: "MAVALOR",
            },
            {
              name: "MAYURO",
            },
            {
              name: "NAMUCO",
            },
            {
              name: "NAMUNGA",
            },
            {
              name: "NASI",
            },
            {
              name: "NATU",
            },
            {
              name: "PALAKPAK",
            },
            {
              name: "PINAGSIBAAN",
            },
            {
              name: "PUTINGKAHOY",
            },
            {
              name: "QUILIB",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SALAO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN IGNACIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TIMBUGAN",
            },
            {
              name: "TIQUIWAN",
            },
            {
              name: "TULOS",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "AGUILA",
            },
            {
              name: "ANUS",
            },
            {
              name: "AYA",
            },
            {
              name: "BAGONG POOK",
            },
            {
              name: "BALAGTASIN",
            },
            {
              name: "BALAGTASIN I",
            },
            {
              name: "BANAYBANAY I",
            },
            {
              name: "BANAYBANAY II",
            },
            {
              name: "BIGAIN I",
            },
            {
              name: "BIGAIN II",
            },
            {
              name: "BIGAIN SOUTH",
            },
            {
              name: "CALANSAYAN",
            },
            {
              name: "DAGATAN",
            },
            {
              name: "DON LUIS",
            },
            {
              name: "GALAMAY-AMO",
            },
            {
              name: "LALAYAT",
            },
            {
              name: "LAPOLAPO I",
            },
            {
              name: "LAPOLAPO II",
            },
            {
              name: "LEPUTE",
            },
            {
              name: "LUMIL",
            },
            {
              name: "MOJON-TAMPOY",
            },
            {
              name: "NATUNUAN",
            },
            {
              name: "PALANCA",
            },
            {
              name: "PINAGTUNG-ULAN",
            },
            {
              name: "POBLACION BARANGAY I",
            },
            {
              name: "POBLACION BARANGAY II",
            },
            {
              name: "POBLACION BARANGAY III",
            },
            {
              name: "POBLACION BARANGAY IV",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALABAN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "TAYSAN",
            },
            {
              name: "TUGTUG",
            },
          ],
        },
        "SAN JUAN": {
          name: "SAN JUAN",
          baranggays: [
            {
              name: "ABUNG",
            },
            {
              name: "BALAGBAG",
            },
            {
              name: "BARUALTE",
            },
            {
              name: "BATAAN",
            },
            {
              name: "BUHAY NA SAPA",
            },
            {
              name: "BULSA",
            },
            {
              name: "CALICANTO",
            },
            {
              name: "CALITCALIT",
            },
            {
              name: "CALUBCUB I",
            },
            {
              name: "CALUBCUB II",
            },
            {
              name: "CATMON",
            },
            {
              name: "COLOCONTO",
            },
            {
              name: "ESCRIBANO",
            },
            {
              name: "HUGOM",
            },
            {
              name: "IMELDA (TUBOG)",
            },
            {
              name: "JANAOJANAO",
            },
            {
              name: "LAIYA-APLAYA",
            },
            {
              name: "LAIYA-IBABAO",
            },
            {
              name: "LIBATO",
            },
            {
              name: "LIPAHAN",
            },
            {
              name: "MABALANOY",
            },
            {
              name: "MARAYKIT",
            },
            {
              name: "MUZON",
            },
            {
              name: "NAGSAULAY",
            },
            {
              name: "PALAHANAN I",
            },
            {
              name: "PALAHANAN II",
            },
            {
              name: "PALINGOWAK",
            },
            {
              name: "PINAGBAYANAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POCTOL",
            },
            {
              name: "PULANGBATO",
            },
            {
              name: "PUTINGBUHANGIN",
            },
            {
              name: "QUIPOT",
            },
            {
              name: "SAMPIRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAPANGAN",
            },
            {
              name: "SICO I",
            },
            {
              name: "SICO II",
            },
            {
              name: "SUBUKIN",
            },
            {
              name: "TALAHIBAN I",
            },
            {
              name: "TALAHIBAN II",
            },
            {
              name: "TICALAN",
            },
            {
              name: "TIPAZ",
            },
          ],
        },
        "SAN LUIS": {
          name: "SAN LUIS",
          baranggays: [
            {
              name: "ABIACAO",
            },
            {
              name: "BAGONG TUBIG",
            },
            {
              name: "BALAGTASIN",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANOYO",
            },
            {
              name: "BOBOY",
            },
            {
              name: "BONLIW",
            },
            {
              name: "CALUMPANG EAST",
            },
            {
              name: "CALUMPANG WEST",
            },
            {
              name: "DULANGAN",
            },
            {
              name: "DURUNGAO",
            },
            {
              name: "LOCLOC",
            },
            {
              name: "LUYA",
            },
            {
              name: "MAHABANG PARANG",
            },
            {
              name: "MANGGAHAN",
            },
            {
              name: "MUZON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "TALIBA",
            },
            {
              name: "TALON",
            },
            {
              name: "TEJERO",
            },
            {
              name: "TUNGAL",
            },
          ],
        },
        "SAN NICOLAS": {
          name: "SAN NICOLAS",
          baranggays: [
            {
              name: "ABELO",
            },
            {
              name: "ALAS-AS",
            },
            {
              name: "BALETE",
            },
            {
              name: "BALUK-BALUK",
            },
            {
              name: "BANCORO",
            },
            {
              name: "BANGIN",
            },
            {
              name: "CALANGAY",
            },
            {
              name: "HIPIT",
            },
            {
              name: "MAABUD NORTH",
            },
            {
              name: "MAABUD SOUTH",
            },
            {
              name: "MUNLAWIN",
            },
            {
              name: "PANSIPIT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULANG-BATO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SINTURISAN",
            },
            {
              name: "TAGUDTOD",
            },
            {
              name: "TALANG",
            },
          ],
        },
        "SAN PASCUAL": {
          name: "SAN PASCUAL",
          baranggays: [
            {
              name: "ALALUM",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "BALIMBING",
            },
            {
              name: "BANABA",
            },
            {
              name: "BAYANAN",
            },
            {
              name: "DANGLAYAN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GELERANG KAWAYAN",
            },
            {
              name: "ILAT NORTH",
            },
            {
              name: "ILAT SOUTH",
            },
            {
              name: "KAINGIN",
            },
            {
              name: "LAUREL",
            },
            {
              name: "MALAKING POOK",
            },
            {
              name: "MATAAS NA LUPA",
            },
            {
              name: "NATUNUAN NORTH",
            },
            {
              name: "NATUNUAN SOUTH",
            },
            {
              name: "PADRE CASTILLO",
            },
            {
              name: "PALSAHINGIN",
            },
            {
              name: "PILA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POOK NI BANAL",
            },
            {
              name: "POOK NI KAPITAN",
            },
            {
              name: "RESPLANDOR",
            },
            {
              name: "SAMBAT",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        "SANTA TERESITA": {
          name: "SANTA TERESITA",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BIHIS",
            },
            {
              name: "BUROL",
            },
            {
              name: "CALAYAAN",
            },
            {
              name: "CALUMALA",
            },
            {
              name: "CUTA EAST",
            },
            {
              name: "CUTANG CAWAYAN",
            },
            {
              name: "IRUKAN",
            },
            {
              name: "PACIFICO",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "SAIMSIM",
            },
            {
              name: "SAMPA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SINIPIAN",
            },
            {
              name: "TAMBO IBABA",
            },
            {
              name: "TAMBO ILAYA",
            },
          ],
        },
        "SANTO TOMAS": {
          name: "SANTO TOMAS",
          baranggays: [
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN FELIX",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO NORTE",
            },
            {
              name: "SAN ISIDRO SUR",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA ANASTACIA",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO TOMAS",
            },
          ],
        },
        TAAL: {
          name: "TAAL",
          baranggays: [
            {
              name: "APACAY",
            },
            {
              name: "BALISONG",
            },
            {
              name: "BIHIS",
            },
            {
              name: "BOLBOK",
            },
            {
              name: "BULI",
            },
            {
              name: "BUTONG",
            },
            {
              name: "CARASUCHE",
            },
            {
              name: "CAWIT",
            },
            {
              name: "CAYSASAY",
            },
            {
              name: "CUBAMBA",
            },
            {
              name: "CULTIHAN",
            },
            {
              name: "GAHOL",
            },
            {
              name: "HALANG",
            },
            {
              name: "IBA",
            },
            {
              name: "ILOG",
            },
            {
              name: "IMAMAWO",
            },
            {
              name: "IPIL",
            },
            {
              name: "LAGUILE",
            },
            {
              name: "LATAG",
            },
            {
              name: "LUNTAL",
            },
            {
              name: "MAHABANG LODLOD",
            },
            {
              name: "NIOGAN",
            },
            {
              name: "PANSOL",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 10",
            },
            {
              name: "POBLACION 11",
            },
            {
              name: "POBLACION 12",
            },
            {
              name: "POBLACION 13",
            },
            {
              name: "POBLACION 14",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "POBLACION 4",
            },
            {
              name: "POBLACION 5",
            },
            {
              name: "POBLACION 6",
            },
            {
              name: "POBLACION 7",
            },
            {
              name: "POBLACION 8",
            },
            {
              name: "POBLACION 9",
            },
            {
              name: "POOK",
            },
            {
              name: "SEIRAN",
            },
            {
              name: "TAAL",
            },
            {
              name: "TATLONG MARIA",
            },
            {
              name: "TIERRA ALTA",
            },
            {
              name: "TULO",
            },
          ],
        },
        TALISAY: {
          name: "TALISAY",
          baranggays: [
            {
              name: "AYA",
            },
            {
              name: "BALAS",
            },
            {
              name: "BANGA",
            },
            {
              name: "BUCO",
            },
            {
              name: "CALOOCAN",
            },
            {
              name: "LEYNES",
            },
            {
              name: "MIRANDA",
            },
            {
              name: "POBLACION BARANGAY 1",
            },
            {
              name: "POBLACION BARANGAY 2",
            },
            {
              name: "POBLACION BARANGAY 3",
            },
            {
              name: "POBLACION BARANGAY 4",
            },
            {
              name: "POBLACION BARANGAY 5",
            },
            {
              name: "POBLACION BARANGAY 6",
            },
            {
              name: "POBLACION BARANGAY 7",
            },
            {
              name: "POBLACION BARANGAY 8",
            },
            {
              name: "QUILING",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TRANCA",
            },
            {
              name: "TUMAWAY",
            },
          ],
        },
        "TANAUAN CITY": {
          name: "TANAUAN CITY",
          baranggays: [
            {
              name: "ALTURA BATA",
            },
            {
              name: "ALTURA MATANDA",
            },
            {
              name: "ALTURA-SOUTH",
            },
            {
              name: "AMBULONG",
            },
            {
              name: "BAGBAG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALELE",
            },
            {
              name: "BANADERO",
            },
            {
              name: "BANJO EAST",
            },
            {
              name: "BANJO LAUREL (BANJO WEST)",
            },
            {
              name: "BILOG-BILOG",
            },
            {
              name: "BOOT",
            },
            {
              name: "CALE",
            },
            {
              name: "DARASA",
            },
            {
              name: "GONZALES",
            },
            {
              name: "HIDALGO",
            },
            {
              name: "JANOPOL",
            },
            {
              name: "JANOPOL ORIENTAL",
            },
            {
              name: "LAUREL",
            },
            {
              name: "LUYOS",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALAKING PULO",
            },
            {
              name: "MARIA PAZ",
            },
            {
              name: "MAUGAT",
            },
            {
              name: "MONTAÑA (IK-IK)",
            },
            {
              name: "NATATAS",
            },
            {
              name: "PAGASPAS",
            },
            {
              name: "PANTAY BATA",
            },
            {
              name: "PANTAY MATANDA",
            },
            {
              name: "POBLACION BARANGAY 1",
            },
            {
              name: "POBLACION BARANGAY 2",
            },
            {
              name: "POBLACION BARANGAY 3",
            },
            {
              name: "POBLACION BARANGAY 4",
            },
            {
              name: "POBLACION BARANGAY 5",
            },
            {
              name: "POBLACION BARANGAY 6",
            },
            {
              name: "POBLACION BARANGAY 7",
            },
            {
              name: "SALA",
            },
            {
              name: "SAMBAT",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTOL (DOÑA JACOBA GARCIA)",
            },
            {
              name: "SANTOR",
            },
            {
              name: "SULPOC",
            },
            {
              name: "SUPLANG",
            },
            {
              name: "TALAGA",
            },
            {
              name: "TANAUAN CITY",
            },
            {
              name: "TINURIK",
            },
            {
              name: "TRAPICHE",
            },
            {
              name: "ULANGO",
            },
            {
              name: "WAWA",
            },
          ],
        },
        TAYSAN: {
          name: "TAYSAN",
          baranggays: [
            {
              name: "BACAO",
            },
            {
              name: "BILOGO",
            },
            {
              name: "BUKAL",
            },
            {
              name: "DAGATAN",
            },
            {
              name: "GUINHAWA",
            },
            {
              name: "LAUREL",
            },
            {
              name: "MABAYABAS",
            },
            {
              name: "MAHANADIONG",
            },
            {
              name: "MAPULO",
            },
            {
              name: "MATAAS NA LUPA",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PANGHAYAAN",
            },
            {
              name: "PIÑA",
            },
            {
              name: "PINAGBAYANAN",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MARCELINO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAYSAN",
            },
            {
              name: "TILAMBO",
            },
          ],
        },
        TINGLOY: {
          name: "TINGLOY",
          baranggays: [
            {
              name: "BARANGAY 13 (POBLACION 1)",
            },
            {
              name: "BARANGAY 14 (POBLACION 2)",
            },
            {
              name: "BARANGAY 15 (POBLACION 3)",
            },
            {
              name: "CORONA",
            },
            {
              name: "GAMAO",
            },
            {
              name: "MAKAWAYAN",
            },
            {
              name: "MARIKABAN",
            },
            {
              name: "PAPAYA",
            },
            {
              name: "PISA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TALAHIB",
            },
            {
              name: "TINGLOY",
            },
          ],
        },
        TUY: {
          name: "TUY",
          baranggays: [
            {
              name: "ACLE",
            },
            {
              name: "BAYUDBUD",
            },
            {
              name: "BOLBOC",
            },
            {
              name: "BURGOS (POB.)",
            },
            {
              name: "DALIMA",
            },
            {
              name: "DAO",
            },
            {
              name: "GUINHAWA",
            },
            {
              name: "LUMBANGAN",
            },
            {
              name: "LUNA (POB.)",
            },
            {
              name: "LUNTAL",
            },
            {
              name: "MAGAHIS",
            },
            {
              name: "MALIBU",
            },
            {
              name: "MATAYWANAC-Kabitols",
            },
            {
              name: "PALINCARO",
            },
            {
              name: "PUTOL",
            },
            {
              name: "RILLO (POB.)",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TALON",
            },
            {
              name: "TOONG",
            },
            {
              name: "TUY",
            },
            {
              name: "TUYON-TUYON",
            },
          ],
        },
      },
    },
    BENGUET: {
      name: "BENGUET",
      region: "CAR",
      municipalities: {
        ATOK: {
          name: "ATOK",
          baranggays: [
            {
              name: "ABIANG",
            },
            {
              name: "ATOK",
            },
            {
              name: "CALIKING",
            },
            {
              name: "CATTUBO",
            },
            {
              name: "NAGUEY",
            },
            {
              name: "PAOAY",
            },
            {
              name: "PASDONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TOPDAC",
            },
          ],
        },
        "BAGUIO CITY": {
          name: "BAGUIO CITY",
          baranggays: [
            {
              name: "A. BONIFACIO-CAGUIOA-RIMANDO (ABCR)",
            },
            {
              name: "ABANAO-ZANDUETA-KAYONG-CHUGUM-OTEK (AZKCO)",
            },
            {
              name: "ALFONSO TABORA",
            },
            {
              name: "AMBIONG",
            },
            {
              name: "ANDRES BONIFACIO (LOWER BOKAWKAN)",
            },
            {
              name: "APUGAN-LOAKAN",
            },
            {
              name: "ASIN ROAD",
            },
            {
              name: "ATOK TRAIL",
            },
            {
              name: "AURORA HILL PROPER (MALVAR-SGT. FLORESCA)",
            },
            {
              name: "AURORA HILL, NORTH CENTRAL",
            },
            {
              name: "AURORA HILL, SOUTH CENTRAL",
            },
            {
              name: "BAGUIO CITY",
            },
            {
              name: "BAKAKENG CENTRAL",
            },
            {
              name: "BAKAKENG NORTH",
            },
            {
              name: "BAL-MARCOVILLE (MARCOVILLE)",
            },
            {
              name: "BALSIGAN",
            },
            {
              name: "BAYAN PARK EAST",
            },
            {
              name: "BAYAN PARK VILLAGE",
            },
            {
              name: "BAYAN PARK WEST (BAYAN PARK)",
            },
            {
              name: "BGH COMPOUND",
            },
            {
              name: "BROOKSIDE",
            },
            {
              name: "BROOKSPOINT",
            },
            {
              name: "CABINET HILL-TEACHER'S CAMP",
            },
            {
              name: "CAMDAS SUBDIVISION",
            },
            {
              name: "CAMP 7",
            },
            {
              name: "CAMP 8",
            },
            {
              name: "CAMP ALLEN",
            },
            {
              name: "CAMPO FILIPINO",
            },
            {
              name: "CITY CAMP CENTRAL",
            },
            {
              name: "CITY CAMP PROPER",
            },
            {
              name: "COUNTRY CLUB VILLAGE",
            },
            {
              name: "CRESENCIA VILLAGE",
            },
            {
              name: "DAGSIAN, LOWER",
            },
            {
              name: "DAGSIAN, UPPER",
            },
            {
              name: "DIZON SUBDIVISION",
            },
            {
              name: "DOMINICAN HILL-MIRADOR",
            },
            {
              name: "DONTOGAN",
            },
            {
              name: "DPS AREA",
            },
            {
              name: "ENGINEERS' HILL",
            },
            {
              name: "FAIRVIEW VILLAGE",
            },
            {
              name: "FERDINAND (HAPPY HOMES-CAMPO SIOCO)",
            },
            {
              name: "FORT DEL PILAR",
            },
            {
              name: "GABRIELA SILANG",
            },
            {
              name: "GENERAL EMILIO F. AGUINALDO (QUIRINO-MAGSAYSAY, LOWER)",
            },
            {
              name: "GENERAL LUNA, LOWER",
            },
            {
              name: "GENERAL LUNA, UPPER",
            },
            {
              name: "GIBRALTAR",
            },
            {
              name: "GREENWATER VILLAGE",
            },
            {
              name: "GUISAD CENTRAL",
            },
            {
              name: "GUISAD SORONG",
            },
            {
              name: "HAPPY HOLLOW",
            },
            {
              name: "HAPPY HOMES (HAPPY HOMES-LUCBAN)",
            },
            {
              name: "HARRISON-CLAUDIO CARANTES",
            },
            {
              name: "HILLSIDE",
            },
            {
              name: "HOLY GHOST EXTENSION",
            },
            {
              name: "HOLY GHOST PROPER",
            },
            {
              name: "HONEYMOON (HONEYMOON-HOLY GHOST)",
            },
            {
              name: "IMELDA R. MARCOS (LA SALLE)",
            },
            {
              name: "IMELDA VILLAGE",
            },
            {
              name: "IRISAN",
            },
            {
              name: "KABAYANIHAN",
            },
            {
              name: "KAGITINGAN",
            },
            {
              name: "KAYANG EXTENSION",
            },
            {
              name: "KAYANG-HILLTOP",
            },
            {
              name: "KIAS",
            },
            {
              name: "LEGARDA-BURNHAM-KISAD",
            },
            {
              name: "LIWANAG-LOAKAN",
            },
            {
              name: "LOAKAN PROPER",
            },
            {
              name: "LOPEZ JAENA",
            },
            {
              name: "LOURDES SUBDIVISION EXTENSION",
            },
            {
              name: "LOURDES SUBDIVISION, LOWER",
            },
            {
              name: "LOURDES SUBDIVISION, PROPER",
            },
            {
              name: "LUALHATI",
            },
            {
              name: "LUCNAB",
            },
            {
              name: "MAGSAYSAY PRIVATE ROAD",
            },
            {
              name: "MAGSAYSAY, LOWER",
            },
            {
              name: "MAGSAYSAY, UPPER",
            },
            {
              name: "MALCOLM SQUARE-PERFECTO (JOSE ABAD SANTOS)",
            },
            {
              name: "MANUEL A. ROXAS",
            },
            {
              name: "MARKET SUBDIVISION, UPPER",
            },
            {
              name: "MIDDLE QUEZON HILL SUBDIVISION(QUEZON HILL MIDDLE)",
            },
            {
              name: "MILITARY CUT-OFF",
            },
            {
              name: "MINES VIEW PARK",
            },
            {
              name: "MODERN SITE, EAST",
            },
            {
              name: "MODERN SITE, WEST",
            },
            {
              name: "MRR-QUEEN OF PEACE",
            },
            {
              name: "NEW LUCBAN",
            },
            {
              name: "OUTLOOK DRIVE",
            },
            {
              name: "PACDAL",
            },
            {
              name: "PADRE BURGOS",
            },
            {
              name: "PADRE ZAMORA",
            },
            {
              name: "PALMA-URBANO (CARIÑO-PALMA)",
            },
            {
              name: "PHIL-AM",
            },
            {
              name: "PINGET",
            },
            {
              name: "PINSAO PILOT PROJECT",
            },
            {
              name: "PINSAO PROPER",
            },
            {
              name: "POLIWES",
            },
            {
              name: "PUCSUSAN",
            },
            {
              name: "QUEZON HILL PROPER",
            },
            {
              name: "QUEZON HILL, UPPER",
            },
            {
              name: "QUIRINO HILL, EAST",
            },
            {
              name: "QUIRINO HILL, LOWER",
            },
            {
              name: "QUIRINO HILL, MIDDLE",
            },
            {
              name: "QUIRINO HILL, WEST",
            },
            {
              name: "QUIRINO-MAGSAYSAY, UPPER (UPPER QM)",
            },
            {
              name: "RIZAL MONUMENT AREA",
            },
            {
              name: "ROCK QUARRY, LOWER",
            },
            {
              name: "ROCK QUARRY, MIDDLE",
            },
            {
              name: "ROCK QUARRY, UPPER",
            },
            {
              name: "SAINT JOSEPH VILLAGE",
            },
            {
              name: "SALUD MITRA",
            },
            {
              name: "SAN ANTONIO VILLAGE",
            },
            {
              name: "SAN LUIS VILLAGE",
            },
            {
              name: "SAN ROQUE VILLAGE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANITARY CAMP, NORTH",
            },
            {
              name: "SANITARY CAMP, SOUTH",
            },
            {
              name: "SANTA ESCOLASTICA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SANTO TOMAS PROPER",
            },
            {
              name: "SANTO TOMAS SCHOOL AREA",
            },
            {
              name: "SCOUT BARRIO",
            },
            {
              name: "SESSION ROAD AREA",
            },
            {
              name: "SLAUGHTER HOUSE AREA (SANTO NIÑO SLAUGHTER)",
            },
            {
              name: "SLU-SVP HOUSING VILLAGE",
            },
            {
              name: "SOUTH DRIVE",
            },
            {
              name: "TEODORA ALONZO",
            },
            {
              name: "TRANCOVILLE",
            },
            {
              name: "VICTORIA VILLAGE",
            },
          ],
        },
        BAKUN: {
          name: "BAKUN",
          baranggays: [
            {
              name: "AMPUSONGAN",
            },
            {
              name: "BAGU",
            },
            {
              name: "BAKUN",
            },
            {
              name: "DALIPEY",
            },
            {
              name: "GAMBANG",
            },
            {
              name: "KAYAPA",
            },
            {
              name: "POBLACION (CENTRAL)",
            },
            {
              name: "SINACBAT",
            },
          ],
        },
        BOKOD: {
          name: "BOKOD",
          baranggays: [
            {
              name: "AMBUCLAO",
            },
            {
              name: "BILA",
            },
            {
              name: "BOBOK-BISAL",
            },
            {
              name: "BOKOD",
            },
            {
              name: "DACLAN",
            },
            {
              name: "EKIP",
            },
            {
              name: "KARAO",
            },
            {
              name: "NAWAL",
            },
            {
              name: "PITO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TIKEY",
            },
          ],
        },
        BUGUIAS: {
          name: "BUGUIAS",
          baranggays: [
            {
              name: "ABATAN",
            },
            {
              name: "AMGALEYGUEY",
            },
            {
              name: "AMLIMAY",
            },
            {
              name: "BACULONGAN NORTE",
            },
            {
              name: "BACULONGAN SUR",
            },
            {
              name: "BANGAO",
            },
            {
              name: "BUGUIAS",
            },
            {
              name: "BUYACAOAN",
            },
            {
              name: "CALAMAGAN",
            },
            {
              name: "CATLUBONG",
            },
            {
              name: "LENGAOAN",
            },
            {
              name: "LOO",
            },
            {
              name: "NATUBLENG",
            },
            {
              name: "POBLACION (CENTRAL)",
            },
            {
              name: "SEBANG",
            },
          ],
        },
        ITOGON: {
          name: "ITOGON",
          baranggays: [
            {
              name: "AMPUCAO",
            },
            {
              name: "DALUPIRIP",
            },
            {
              name: "GUMATDANG",
            },
            {
              name: "ITOGON",
            },
            {
              name: "LOACAN",
            },
            {
              name: "LOACAN",
            },
            {
              name: "POBLACION (CENTRAL)",
            },
            {
              name: "TINONGDAN",
            },
            {
              name: "TUDING",
            },
            {
              name: "UCAB",
            },
            {
              name: "VIRAC",
            },
          ],
        },
        KABAYAN: {
          name: "KABAYAN",
          baranggays: [
            {
              name: "ADAOAY",
            },
            {
              name: "ANCHUKEY",
            },
            {
              name: "BALLAY",
            },
            {
              name: "BASHOY",
            },
            {
              name: "BATAN",
            },
            {
              name: "DUACAN",
            },
            {
              name: "EDDET",
            },
            {
              name: "GUSARAN",
            },
            {
              name: "KABAYAN",
            },
            {
              name: "KABAYAN BARRIO",
            },
            {
              name: "LUSOD",
            },
            {
              name: "PACSO",
            },
            {
              name: "POBLACION (CENTRAL)",
            },
            {
              name: "TAWANGAN",
            },
          ],
        },
        KAPANGAN: {
          name: "KAPANGAN",
          baranggays: [
            {
              name: "BALAKBAK",
            },
            {
              name: "BELENG-BELIS",
            },
            {
              name: "BOKLAOAN",
            },
            {
              name: "CAYAPES",
            },
            {
              name: "CUBA",
            },
            {
              name: "DATAKAN",
            },
            {
              name: "GADANG",
            },
            {
              name: "GASWELING",
            },
            {
              name: "KAPANGAN",
            },
            {
              name: "LABUEG",
            },
            {
              name: "PAYKEK",
            },
            {
              name: "POBLACION CENTRAL",
            },
            {
              name: "PONGAYAN",
            },
            {
              name: "PUDONG",
            },
            {
              name: "SAGUBO",
            },
            {
              name: "TABA-AO",
            },
          ],
        },
        KIBUNGAN: {
          name: "KIBUNGAN",
          baranggays: [
            {
              name: "BADEO",
            },
            {
              name: "KIBUNGAN",
            },
            {
              name: "LUBO",
            },
            {
              name: "MADAYMEN",
            },
            {
              name: "PALINA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGPAT",
            },
            {
              name: "TACADANG",
            },
          ],
        },
        "LA TRINIDAD": {
          name: "LA TRINIDAD",
          baranggays: [
            {
              name: "ALAPANG",
            },
            {
              name: "ALNO",
            },
            {
              name: "AMBIONG",
            },
            {
              name: "BAHONG",
            },
            {
              name: "BALILI",
            },
            {
              name: "BECKEL",
            },
            {
              name: "BETAG",
            },
            {
              name: "BINENG",
            },
            {
              name: "CRUZ",
            },
            {
              name: "LA TRINIDAD",
            },
            {
              name: "LUBAS",
            },
            {
              name: "PICO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUGUIS",
            },
            {
              name: "SHILAN",
            },
            {
              name: "TAWANG",
            },
            {
              name: "WANGAL",
            },
          ],
        },
        MANKAYAN: {
          name: "MANKAYAN",
          baranggays: [
            {
              name: "BALILI",
            },
            {
              name: "BEDBED",
            },
            {
              name: "BULALACAO",
            },
            {
              name: "CABITEN",
            },
            {
              name: "COLALO",
            },
            {
              name: "GUINAOANG",
            },
            {
              name: "MANKAYAN",
            },
            {
              name: "PACO",
            },
            {
              name: "PALASAAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAPID",
            },
            {
              name: "TABIO",
            },
            {
              name: "TANEG",
            },
          ],
        },
        SABLAN: {
          name: "SABLAN",
          baranggays: [
            {
              name: "BAGONG",
            },
            {
              name: "BALLUAY",
            },
            {
              name: "BANANGAN",
            },
            {
              name: "BANENGBENG",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "KAMOG",
            },
            {
              name: "PAPPA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABLAN",
            },
          ],
        },
        TUBA: {
          name: "TUBA",
          baranggays: [
            {
              name: "ANSAGAN",
            },
            {
              name: "CAMP 3",
            },
            {
              name: "CAMP 4",
            },
            {
              name: "CAMP ONE",
            },
            {
              name: "NANGALISAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "TABAAN NORTE",
            },
            {
              name: "TABAAN SUR",
            },
            {
              name: "TADIANGAN",
            },
            {
              name: "TALOY NORTE",
            },
            {
              name: "TALOY SUR",
            },
            {
              name: "TUBA",
            },
            {
              name: "TWIN PEAKS",
            },
          ],
        },
        TUBLAY: {
          name: "TUBLAY",
          baranggays: [
            {
              name: "AMBASSADOR",
            },
            {
              name: "AMBONGDOLAN",
            },
            {
              name: "BA-AYAN",
            },
            {
              name: "BASIL",
            },
            {
              name: "CAPONGA (POB.)",
            },
            {
              name: "DACLAN",
            },
            {
              name: "TUBLAY",
            },
            {
              name: "TUBLAY CENTRAL",
            },
            {
              name: "TUEL",
            },
          ],
        },
      },
    },
    BILIRAN: {
      name: "BILIRAN",
      region: "REGION 8",
      municipalities: {
        ALMERIA: {
          name: "ALMERIA",
          baranggays: [
            {
              name: "ALMERIA",
            },
            {
              name: "CAUCAB",
            },
            {
              name: "IYOSAN",
            },
            {
              name: "JAMORAWON",
            },
            {
              name: "LO-OK",
            },
            {
              name: "MATANGA",
            },
            {
              name: "PILI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULANG BATO",
            },
            {
              name: "SALANGI",
            },
            {
              name: "SAMPAO",
            },
            {
              name: "TABUNAN",
            },
            {
              name: "TALAHID",
            },
            {
              name: "TAMARINDO",
            },
          ],
        },
        BILIRAN: {
          name: "BILIRAN",
          baranggays: [
            {
              name: "BATO",
            },
            {
              name: "BILIRAN",
            },
            {
              name: "BURABOD",
            },
            {
              name: "BUSALI",
            },
            {
              name: "CANILA",
            },
            {
              name: "HUGPA",
            },
            {
              name: "JULITA",
            },
            {
              name: "PINANGUMHAN",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANGGALANG",
            },
            {
              name: "VILLA ENAGE (BARAS)",
            },
          ],
        },
        CABUCGAYAN: {
          name: "CABUCGAYAN",
          baranggays: [
            {
              name: "BALAQUID",
            },
            {
              name: "BASO",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CAANIBONGAN",
            },
            {
              name: "CABUCGAYAN",
            },
            {
              name: "CASIAWAN",
            },
            {
              name: "ESPERANZA (POB.)",
            },
            {
              name: "LANGGAO",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LOOC",
            },
            {
              name: "MAGBANGON (POB.)",
            },
            {
              name: "PAWIKAN",
            },
            {
              name: "SALAWAD",
            },
            {
              name: "TALIBONG",
            },
          ],
        },
        CAIBIRAN: {
          name: "CAIBIRAN",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "ASUG",
            },
            {
              name: "BARI-IS",
            },
            {
              name: "BINOHANGAN",
            },
            {
              name: "CABIBIHAN",
            },
            {
              name: "CAIBIRAN",
            },
            {
              name: "CAULANGOHAN (MAREVIL)",
            },
            {
              name: "KAWAYANON",
            },
            {
              name: "LOOC",
            },
            {
              name: "MANLABANG",
            },
            {
              name: "MAURANG",
            },
            {
              name: "PALANAY (POB.)",
            },
            {
              name: "PALENGKE (POB.)",
            },
            {
              name: "TOMALISTIS",
            },
            {
              name: "UNION",
            },
            {
              name: "USON",
            },
            {
              name: "VICTORY (POB.)",
            },
            {
              name: "VILLA VICENTA (MAINIT)",
            },
          ],
        },
        CULABA: {
          name: "CULABA",
          baranggays: [
            {
              name: "ACABAN",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BINONGTOAN",
            },
            {
              name: "BOOL CENTRAL (POB.)",
            },
            {
              name: "BOOL EAST (POB.)",
            },
            {
              name: "BOOL WEST (POB.)",
            },
            {
              name: "CALIPAYAN",
            },
            {
              name: "CULABA",
            },
            {
              name: "CULABA CENTRAL (POB.)",
            },
            {
              name: "GUINDAPUNAN",
            },
            {
              name: "HABUHAB",
            },
            {
              name: "LOOC",
            },
            {
              name: "MARVEL (POB.)",
            },
            {
              name: "PATAG",
            },
            {
              name: "PINAMIHAGAN",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "VIRGINIA (POB.)",
            },
          ],
        },
        KAWAYAN: {
          name: "KAWAYAN",
          baranggays: [
            {
              name: "BAGANITO",
            },
            {
              name: "BALACSON",
            },
            {
              name: "BALITE",
            },
            {
              name: "BILWANG",
            },
            {
              name: "BULALACAO",
            },
            {
              name: "BURABOD",
            },
            {
              name: "BUYO",
            },
            {
              name: "INASUYAN",
            },
            {
              name: "KANSANOK",
            },
            {
              name: "KAWAYAN",
            },
            {
              name: "MADA-O",
            },
            {
              name: "MAPUYO",
            },
            {
              name: "MASAGAOSAO",
            },
            {
              name: "MASAGONGSONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "TABUNAN NORTH",
            },
            {
              name: "TUBIG GUINOO",
            },
            {
              name: "TUCDAO",
            },
            {
              name: "UNGALE",
            },
            {
              name: "VILLA CORNEJO (LOOC)",
            },
          ],
        },
        MARIPIPI: {
          name: "MARIPIPI",
          baranggays: [
            {
              name: "AGUTAY",
            },
            {
              name: "BANLAS",
            },
            {
              name: "BATO",
            },
            {
              name: "BINALAYAN EAST",
            },
            {
              name: "BINALAYAN WEST",
            },
            {
              name: "BINONGTO-AN (POBLACION NORTE)",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CALBANI",
            },
            {
              name: "CANDUHAO",
            },
            {
              name: "CASIBANG",
            },
            {
              name: "DANAO",
            },
            {
              name: "ERMITA (POBLACION SUR)",
            },
            {
              name: "MARIPIPI",
            },
            {
              name: "OL-OG",
            },
            {
              name: "TRABUGAN",
            },
            {
              name: "VIGA",
            },
          ],
        },
        NAVAL: {
          name: "NAVAL",
          baranggays: [
            {
              name: "AGPANGI",
            },
            {
              name: "ANISLAGAN",
            },
            {
              name: "ATIPOLO",
            },
            {
              name: "BORAC",
            },
            {
              name: "CABUNGAAN",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CAPIÑAHAN",
            },
            {
              name: "CARAYCARAY",
            },
            {
              name: "CATMON",
            },
            {
              name: "HAGUIKHIKAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LARRAZABAL",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "LICO",
            },
            {
              name: "LUCSOON",
            },
            {
              name: "MABINI",
            },
            {
              name: "NAVAL",
            },
            {
              name: "PADRE INOCENTES GARCIA (POB.)",
            },
            {
              name: "PADRE SERGIO EAMIGUEL",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SANTISSIMO ROSARIO POB. (SANTO ROSA)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TALUSTUSAN",
            },
            {
              name: "VILLA CANEJA",
            },
            {
              name: "VILLA CONSUELO",
            },
          ],
        },
      },
    },
    BOHOL: {
      name: "BOHOL",
      region: "REGION 7",
      municipalities: {
        ALBURQUERQUE: {
          name: "ALBURQUERQUE",
          baranggays: [
            {
              name: "ALBURQUERQUE",
            },
            {
              name: "BAHI",
            },
            {
              name: "BASACDACU",
            },
            {
              name: "CANTIGUIB",
            },
            {
              name: "DANGAY",
            },
            {
              name: "EAST POBLACION",
            },
            {
              name: "PONONG",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "TAGBUANE",
            },
            {
              name: "TORIL",
            },
            {
              name: "WEST POBLACION",
            },
          ],
        },
        ALICIA: {
          name: "ALICIA",
          baranggays: [
            {
              name: "ALICIA",
            },
            {
              name: "CABATANG",
            },
            {
              name: "CAGONGCAGONG",
            },
            {
              name: "CAMBAOL",
            },
            {
              name: "CAYACAY",
            },
            {
              name: "DEL MONTE",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LA HACIENDA",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "NAPO",
            },
            {
              name: "PAGAHAT",
            },
            {
              name: "POBLACION (CALINGGANAY)",
            },
            {
              name: "PROGRESO",
            },
            {
              name: "PUTLONGCAM",
            },
            {
              name: "SUDLON (OMHOR)",
            },
            {
              name: "UNTAGA",
            },
          ],
        },
        ANDA: {
          name: "ANDA",
          baranggays: [
            {
              name: "ALMARIA",
            },
            {
              name: "ANDA",
            },
            {
              name: "BACONG",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "CANDABONG",
            },
            {
              name: "CASICA",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LINAWAN",
            },
            {
              name: "LUNDAG",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SUBA",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TANOD",
            },
            {
              name: "TAWID",
            },
            {
              name: "VIRGEN",
            },
          ],
        },
        ANTEQUERA: {
          name: "ANTEQUERA",
          baranggays: [
            {
              name: "ANGILAN",
            },
            {
              name: "ANTEQUERA",
            },
            {
              name: "BANTOLINAO",
            },
            {
              name: "BICAHAN",
            },
            {
              name: "BITAUGAN",
            },
            {
              name: "BUNGAHAN",
            },
            {
              name: "CAN-OMAY",
            },
            {
              name: "CANLAAS",
            },
            {
              name: "CANSIBUAN",
            },
            {
              name: "CELING",
            },
            {
              name: "DANAO",
            },
            {
              name: "DANICOP",
            },
            {
              name: "MAG-ASO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUINAPON-AN",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TABUAN",
            },
            {
              name: "TAGUBAAS",
            },
            {
              name: "TUPAS",
            },
            {
              name: "UBOJAN",
            },
            {
              name: "VIGA",
            },
            {
              name: "VILLA AURORA (CANOC-OC)",
            },
          ],
        },
        BACLAYON: {
          name: "BACLAYON",
          baranggays: [
            {
              name: "BACLAYON",
            },
            {
              name: "BUENAVENTURA",
            },
            {
              name: "CAMBANAC",
            },
            {
              name: "DASITAM",
            },
            {
              name: "GUIWANON",
            },
            {
              name: "LANDICAN",
            },
            {
              name: "LAYA",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MONTANA",
            },
            {
              name: "PAMILACAN",
            },
            {
              name: "PAYAHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAGUIHON",
            },
            {
              name: "TANDAY",
            },
          ],
        },
        BALILIHAN: {
          name: "BALILIHAN",
          baranggays: [
            {
              name: "BALILIHAN",
            },
            {
              name: "BAUCAN NORTE",
            },
            {
              name: "BAUCAN SUR",
            },
            {
              name: "BOCTOL",
            },
            {
              name: "BOYOG NORTE",
            },
            {
              name: "BOYOG PROPER",
            },
            {
              name: "BOYOG SUR",
            },
            {
              name: "CABAD",
            },
            {
              name: "CANDASIG",
            },
            {
              name: "CANTALID",
            },
            {
              name: "CANTOMIMBO",
            },
            {
              name: "COGON",
            },
            {
              name: "DATAG NORTE",
            },
            {
              name: "DATAG SUR",
            },
            {
              name: "DEL CARMEN ESTE (POB.)",
            },
            {
              name: "DEL CARMEN NORTE (POB.)",
            },
            {
              name: "DEL CARMEN SUR (POB.)",
            },
            {
              name: "DEL CARMEN WESTE (POB.)",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "DOROL",
            },
            {
              name: "HAGUILANAN GRANDE",
            },
            {
              name: "HANOPOL ESTE",
            },
            {
              name: "HANOPOL NORTE",
            },
            {
              name: "HANOPOL WESTE",
            },
            {
              name: "MAGSIJA",
            },
            {
              name: "MASLOG",
            },
            {
              name: "SAGASA",
            },
            {
              name: "SAL-ING",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGUSTUSAN",
            },
          ],
        },
        BATUAN: {
          name: "BATUAN",
          baranggays: [
            {
              name: "ALOJA",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BEHIND THE CLOUDS (SAN JOSE)",
            },
            {
              name: "CABACNITAN",
            },
            {
              name: "CAMBACAY",
            },
            {
              name: "CANTIGDAS",
            },
            {
              name: "GARCIA",
            },
            {
              name: "JANLUD",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "POBLACION VIEJA (LONGSUDAAN)",
            },
            {
              name: "QUEZON",
            },
            {
              name: "QUIRINO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROSARIOHAN",
            },
            {
              name: "SANTA CRUZ",
            },
          ],
        },
        "BIEN UNIDO": {
          name: "BIEN UNIDO",
          baranggays: [
            {
              name: "BIEN UNIDO",
            },
            {
              name: "BILANGBILANGAN DAKO",
            },
            {
              name: "BILANGBILANGAN DIOT",
            },
            {
              name: "HINGOTANAN EAST",
            },
            {
              name: "HINGOTANAN WEST",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "MALINGIN",
            },
            {
              name: "MANDAWA",
            },
            {
              name: "MAOMAWAN",
            },
            {
              name: "NUEVA ESPERANZA",
            },
            {
              name: "NUEVA ESTRELLA",
            },
            {
              name: "PINAMGO",
            },
            {
              name: "POBLACION (BIEN UNIDO)",
            },
            {
              name: "PUERTO SAN PEDRO",
            },
            {
              name: "SAGASA",
            },
            {
              name: "TUBORAN",
            },
          ],
        },
        BILAR: {
          name: "BILAR",
          baranggays: [
            {
              name: "BILAR",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BUGANG NORTE",
            },
            {
              name: "BUGANG SUR",
            },
            {
              name: "CABACNITAN (MAGSAYSAY)",
            },
            {
              name: "CAMBIGSI",
            },
            {
              name: "CAMPAGAO",
            },
            {
              name: "CANSUMBOL",
            },
            {
              name: "DAGOHOY",
            },
            {
              name: "OWAC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUEZON",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SUBAYON",
            },
            {
              name: "VILLA AURORA",
            },
            {
              name: "VILLA SUERTE",
            },
            {
              name: "YANAYA",
            },
            {
              name: "ZAMORA",
            },
          ],
        },
        BUENAVISTA: {
          name: "BUENAVISTA",
          baranggays: [
            {
              name: "ANONANG",
            },
            {
              name: "ASINAN",
            },
            {
              name: "BAGO",
            },
            {
              name: "BALUARTE",
            },
            {
              name: "BANTUAN",
            },
            {
              name: "BATO",
            },
            {
              name: "BONOTBONOT",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUGAONG",
            },
            {
              name: "CAMBUHAT",
            },
            {
              name: "CAMBUS-OC",
            },
            {
              name: "CANGAWA",
            },
            {
              name: "CANTOMUGCAD",
            },
            {
              name: "CANTORES",
            },
            {
              name: "CANTUBA",
            },
            {
              name: "CATIGBIAN",
            },
            {
              name: "CAWAG",
            },
            {
              name: "CRUZ",
            },
            {
              name: "DAIT",
            },
            {
              name: "EASTERN CABUL-AN",
            },
            {
              name: "HUNAN",
            },
            {
              name: "LAPACAN NORTE",
            },
            {
              name: "LAPACAN SUR",
            },
            {
              name: "LUBANG",
            },
            {
              name: "LUSONG (PLATEAU)",
            },
            {
              name: "MAGKAYA",
            },
            {
              name: "MERRYLAND",
            },
            {
              name: "NUEVA GRANADA",
            },
            {
              name: "NUEVA MONTANA",
            },
            {
              name: "OVERLAND",
            },
            {
              name: "PANGHAGBAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUTING BATO",
            },
            {
              name: "RUFO HILL",
            },
            {
              name: "SWEETLAND",
            },
            {
              name: "WESTERN CABUL-AN",
            },
          ],
        },
        CALAPE: {
          name: "CALAPE",
          baranggays: [
            {
              name: "ABUCAYAN NORTE",
            },
            {
              name: "ABUCAYAN SUR",
            },
            {
              name: "BANLASAN",
            },
            {
              name: "BENTIG",
            },
            {
              name: "BINOGAWAN",
            },
            {
              name: "BONBON",
            },
            {
              name: "CABAYUGAN",
            },
            {
              name: "CABUDBURAN",
            },
            {
              name: "CALAPE",
            },
            {
              name: "CALUNASAN",
            },
            {
              name: "CAMIAS",
            },
            {
              name: "CANGUHA",
            },
            {
              name: "CATMONAN",
            },
            {
              name: "DESAMPARADOS (POB.)",
            },
            {
              name: "KAHAYAG",
            },
            {
              name: "KINABAG-AN",
            },
            {
              name: "LABUON",
            },
            {
              name: "LAWIS",
            },
            {
              name: "LIBORON",
            },
            {
              name: "LO-OC",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "LUCOB",
            },
            {
              name: "MADANGOG",
            },
            {
              name: "MAGTONGTONG",
            },
            {
              name: "MANDAUG",
            },
            {
              name: "MANTATAO",
            },
            {
              name: "SAMPOANGON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SOJOTON",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TINIBGAN",
            },
            {
              name: "TULTUGAN",
            },
            {
              name: "ULBUJAN",
            },
          ],
        },
        CANDIJAY: {
          name: "CANDIJAY",
          baranggays: [
            {
              name: "ABIHILAN",
            },
            {
              name: "ANOLING",
            },
            {
              name: "BOYO-AN",
            },
            {
              name: "CADAPDAPAN",
            },
            {
              name: "CAMBANE",
            },
            {
              name: "CAN-OLIN",
            },
            {
              name: "CANAWA",
            },
            {
              name: "CANDIJAY",
            },
            {
              name: "COGTONG",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LUAN",
            },
            {
              name: "LUNGSODA-AN",
            },
            {
              name: "MAHANGIN",
            },
            {
              name: "PAGAHAT",
            },
            {
              name: "PANADTARAN",
            },
            {
              name: "PANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "TAMBONGAN",
            },
            {
              name: "TAWID",
            },
            {
              name: "TUBOD (TRES ROSAS)",
            },
            {
              name: "TUGAS",
            },
          ],
        },
        CARMEN: {
          name: "CARMEN",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BICAO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUENOS AIRES",
            },
            {
              name: "CALATRAVA",
            },
            {
              name: "CARMEN",
            },
            {
              name: "EL PROGRESO",
            },
            {
              name: "EL SALVADOR",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LA LIBERTAD",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LA SALVACION",
            },
            {
              name: "LA VICTORIA",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "MONTEHERMOSO",
            },
            {
              name: "MONTESUERTE",
            },
            {
              name: "MONTESUNTING",
            },
            {
              name: "MONTEVIDEO",
            },
            {
              name: "NUEVA FUERZA",
            },
            {
              name: "NUEVA VIDA ESTE",
            },
            {
              name: "NUEVA VIDA NORTE",
            },
            {
              name: "NUEVA VIDA SUR",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "TAMBO-AN",
            },
            {
              name: "VALLEHERMOSO",
            },
            {
              name: "VILLAFLOR",
            },
            {
              name: "VILLAFUERTE",
            },
            {
              name: "VILLARCAYO",
            },
          ],
        },
        CATIGBIAN: {
          name: "CATIGBIAN",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "AMBUAN",
            },
            {
              name: "BAANG",
            },
            {
              name: "BAGTIC",
            },
            {
              name: "BONGBONG",
            },
            {
              name: "CAMBAILAN",
            },
            {
              name: "CANDUMAYAO",
            },
            {
              name: "CATIGBIAN",
            },
            {
              name: "CAUSWAGAN NORTE",
            },
            {
              name: "HAGBUAYA",
            },
            {
              name: "HAGUILANAN",
            },
            {
              name: "KANG-IRAS",
            },
            {
              name: "LIBERTAD SUR",
            },
            {
              name: "LIBORON",
            },
            {
              name: "MAHAYAG NORTE",
            },
            {
              name: "MAHAYAG SUR",
            },
            {
              name: "MAITUM",
            },
            {
              name: "MANTASIDA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION WESTE",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SINAKAYANAN",
            },
            {
              name: "TRIPLE UNION",
            },
          ],
        },
        CLARIN: {
          name: "CLARIN",
          baranggays: [
            {
              name: "BACANI",
            },
            {
              name: "BOGTONGBOD",
            },
            {
              name: "BONBON",
            },
            {
              name: "BONTUD",
            },
            {
              name: "BUACAO",
            },
            {
              name: "BUANGAN",
            },
            {
              name: "CABOG",
            },
            {
              name: "CABOY",
            },
            {
              name: "CALUWASAN",
            },
            {
              name: "CANDAJEC",
            },
            {
              name: "CANTOYOC",
            },
            {
              name: "CLARIN",
            },
            {
              name: "COMAANG",
            },
            {
              name: "DANAHAO",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LAJOG",
            },
            {
              name: "MATAUB",
            },
            {
              name: "NAHAWAN",
            },
            {
              name: "POBLACION CENTRO",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "TANGARAN",
            },
            {
              name: "TONTUNAN",
            },
            {
              name: "TUBOD",
            },
            {
              name: "VILLAFLOR",
            },
          ],
        },
        CORELLA: {
          name: "CORELLA",
          baranggays: [
            {
              name: "ANISLAG",
            },
            {
              name: "CANANGCA-AN",
            },
            {
              name: "CANAPNAPAN",
            },
            {
              name: "CANCATAC",
            },
            {
              name: "CORELLA",
            },
            {
              name: "PANDOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMBOG",
            },
            {
              name: "TANDAY",
            },
          ],
        },
        CORTES: {
          name: "CORTES",
          baranggays: [
            {
              name: "CORTES",
            },
            {
              name: "DE LA PAZ",
            },
            {
              name: "FATIMA",
            },
            {
              name: "LORETO",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MALAYO NORTE",
            },
            {
              name: "MALAYO SUR",
            },
            {
              name: "MONSERRAT",
            },
            {
              name: "NEW LOURDES",
            },
            {
              name: "PATROCINIO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SALVADOR",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "UPPER DE LA PAZ",
            },
          ],
        },
        DAGOHOY: {
          name: "DAGOHOY",
          baranggays: [
            {
              name: "BABAG",
            },
            {
              name: "CAGAWASAN",
            },
            {
              name: "CAGAWITAN",
            },
            {
              name: "CALUASAN",
            },
            {
              name: "CAN-OLING",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "DAGOHOY",
            },
            {
              name: "ESTACA",
            },
            {
              name: "LA ESPERANZA",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MALITBOG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "VILLA AURORA",
            },
          ],
        },
        DANAO: {
          name: "DANAO",
          baranggays: [
            {
              name: "CABATUAN",
            },
            {
              name: "CANTUBOD",
            },
            {
              name: "CARBON",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAGOHOY",
            },
            {
              name: "DANAO",
            },
            {
              name: "HIBALE",
            },
            {
              name: "MAGTANGTANG",
            },
            {
              name: "NAHUD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "REMEDIOS",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TABOK",
            },
            {
              name: "TAMING",
            },
            {
              name: "VILLA ANUNCIADO",
            },
          ],
        },
        DAUIS: {
          name: "DAUIS",
          baranggays: [
            {
              name: "BIKING",
            },
            {
              name: "BINGAG",
            },
            {
              name: "CATARMAN",
            },
            {
              name: "DAO",
            },
            {
              name: "DAUIS",
            },
            {
              name: "MARIVELES",
            },
            {
              name: "MAYACABAC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO (CANLONGON)",
            },
            {
              name: "SONGCULAN",
            },
            {
              name: "TABALONG",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TOTOLAN",
            },
          ],
        },
        DIMIAO: {
          name: "DIMIAO",
          baranggays: [
            {
              name: "ABIHID",
            },
            {
              name: "ALEMANIA",
            },
            {
              name: "BAGUHAN",
            },
            {
              name: "BAKILID",
            },
            {
              name: "BALBALAN",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BAUHUGAN",
            },
            {
              name: "BILISAN",
            },
            {
              name: "CABAGAKIAN",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CADAP-AGAN",
            },
            {
              name: "CAMBACOL",
            },
            {
              name: "CAMBAYAON",
            },
            {
              name: "CANHAYUPON",
            },
            {
              name: "CANLAMBONG",
            },
            {
              name: "CASINGAN",
            },
            {
              name: "CATUGASAN",
            },
            {
              name: "DATAG",
            },
            {
              name: "DIMIAO",
            },
            {
              name: "GUINDAGUITAN",
            },
            {
              name: "GUINGOYURAN",
            },
            {
              name: "ILE",
            },
            {
              name: "LAPSAON",
            },
            {
              name: "LIMOKON ILAOD",
            },
            {
              name: "LIMOKON ILAYA",
            },
            {
              name: "LUYO",
            },
            {
              name: "MALIJAO",
            },
            {
              name: "OAC",
            },
            {
              name: "PAGSA",
            },
            {
              name: "PANGIHAWAN",
            },
            {
              name: "PUANGYUTA",
            },
            {
              name: "SAWANG",
            },
            {
              name: "TANGOHAY",
            },
            {
              name: "TAONGON CABATUAN",
            },
            {
              name: "TAONGON CAN-ANDAM",
            },
            {
              name: "TAWID BITAOG",
            },
          ],
        },
        DUERO: {
          name: "DUERO",
          baranggays: [
            {
              name: "ALEJAWAN",
            },
            {
              name: "ANGILAN",
            },
            {
              name: "ANIBONGAN",
            },
            {
              name: "BANGWALOG",
            },
            {
              name: "CANSUHAY",
            },
            {
              name: "DANAO",
            },
            {
              name: "DUAY",
            },
            {
              name: "DUERO",
            },
            {
              name: "GUINSULARAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "ITUM",
            },
            {
              name: "LANGKIS",
            },
            {
              name: "LOBOGON",
            },
            {
              name: "MADUA NORTE",
            },
            {
              name: "MADUA SUR",
            },
            {
              name: "MAMBOOL",
            },
            {
              name: "MAWI",
            },
            {
              name: "PAYAO",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "TAYTAY",
            },
          ],
        },
        "GARCIA HERNANDEZ": {
          name: "GARCIA HERNANDEZ",
          baranggays: [
            {
              name: "ABIJILAN",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "BASIAO",
            },
            {
              name: "CAGWANG",
            },
            {
              name: "CALMA",
            },
            {
              name: "CAMBUYO",
            },
            {
              name: "CANAYAON EAST",
            },
            {
              name: "CANAYAON WEST",
            },
            {
              name: "CANDANAS",
            },
            {
              name: "CANDULAO",
            },
            {
              name: "CATMON",
            },
            {
              name: "CAYAM",
            },
            {
              name: "CUPA",
            },
            {
              name: "DATAG",
            },
            {
              name: "ESTACA",
            },
            {
              name: "GARCIA HERNANDEZ",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LUNGSODAAN EAST",
            },
            {
              name: "LUNGSODAAN WEST",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MANABA",
            },
            {
              name: "PASONG",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SACAON",
            },
            {
              name: "SAMPONG",
            },
            {
              name: "TABUAN",
            },
            {
              name: "TOGBONGON",
            },
            {
              name: "ULBUJAN EAST",
            },
            {
              name: "ULBUJAN WEST",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        GUINDULMAN: {
          name: "GUINDULMAN",
          baranggays: [
            {
              name: "BASDIO",
            },
            {
              name: "BATO",
            },
            {
              name: "BAYONG",
            },
            {
              name: "BIABAS",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CABANTIAN",
            },
            {
              name: "CANHAWAY",
            },
            {
              name: "CANSIWANG",
            },
            {
              name: "CASBU",
            },
            {
              name: "CATUNGAWAN NORTE",
            },
            {
              name: "CATUNGAWAN SUR",
            },
            {
              name: "GUINACOT",
            },
            {
              name: "GUINDULMAN",
            },
            {
              name: "GUIO-ANG",
            },
            {
              name: "LOMBOG",
            },
            {
              name: "MAYUGA",
            },
            {
              name: "SAWANG (POB.)",
            },
            {
              name: "TABAJAN (POB.)",
            },
            {
              name: "TABUNOK",
            },
            {
              name: "TRINIDAD",
            },
          ],
        },
        INABANGA: {
          name: "INABANGA",
          baranggays: [
            {
              name: "ANONANG",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BAGUHAN",
            },
            {
              name: "BAHAN",
            },
            {
              name: "BANAHAO",
            },
            {
              name: "BAOGO",
            },
            {
              name: "BUGANG",
            },
            {
              name: "CAGAWASAN",
            },
            {
              name: "CAGAYAN",
            },
            {
              name: "CAMBITOON",
            },
            {
              name: "CANLINTE",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "COGON",
            },
            {
              name: "CUAMING",
            },
            {
              name: "DAGNAWAN",
            },
            {
              name: "DAGOHOY",
            },
            {
              name: "DAIT SUR",
            },
            {
              name: "DATAG",
            },
            {
              name: "FATIMA",
            },
            {
              name: "HAMBONGAN",
            },
            {
              name: "ILAUD (POB.)",
            },
            {
              name: "ILAYA",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "INABANGA",
            },
            {
              name: "LAPACAN NORTE",
            },
            {
              name: "LAPACAN SUR",
            },
            {
              name: "LAWIS",
            },
            {
              name: "LILOAN NORTE",
            },
            {
              name: "LILOAN SUR",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "LONOY CAINSICAN",
            },
            {
              name: "LONOY ROMA",
            },
            {
              name: "LUTAO",
            },
            {
              name: "LUYO",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MARIA ROSARIO",
            },
            {
              name: "NABUAD",
            },
            {
              name: "NAPO",
            },
            {
              name: "ONDOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "SAA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SUA",
            },
            {
              name: "TAMBOOK",
            },
            {
              name: "TUNGOD",
            },
            {
              name: "U-OG",
            },
            {
              name: "UBUJAN",
            },
          ],
        },
        JAGNA: {
          name: "JAGNA",
          baranggays: [
            {
              name: "ALEJAWAN",
            },
            {
              name: "BALILI",
            },
            {
              name: "BOCTOL",
            },
            {
              name: "BUNGA ILAYA",
            },
            {
              name: "BUNGA MAR",
            },
            {
              name: "BUYOG",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CALABACITA",
            },
            {
              name: "CAMBUGASON",
            },
            {
              name: "CAN-IPOL",
            },
            {
              name: "CAN-UBA",
            },
            {
              name: "CAN-UPAO",
            },
            {
              name: "CANJULAO",
            },
            {
              name: "CANTAGAY",
            },
            {
              name: "CANTUYOC",
            },
            {
              name: "FARAON",
            },
            {
              name: "IPIL",
            },
            {
              name: "JAGNA",
            },
            {
              name: "KINAGBAAN",
            },
            {
              name: "LACA",
            },
            {
              name: "LARAPAN",
            },
            {
              name: "LONOY",
            },
            {
              name: "LOOC",
            },
            {
              name: "MALBOG",
            },
            {
              name: "MAYANA",
            },
            {
              name: "NAATANG",
            },
            {
              name: "NAUSOK",
            },
            {
              name: "ODIONG",
            },
            {
              name: "PAGINA",
            },
            {
              name: "PANGDAN",
            },
            {
              name: "POBLACION (PONDOL)",
            },
            {
              name: "TEJERO",
            },
            {
              name: "TUBOD MAR",
            },
            {
              name: "TUBOD MONTE",
            },
          ],
        },
        JETAFE: {
          name: "JETAFE",
          baranggays: [
            {
              name: "ALUMAR",
            },
            {
              name: "BANACON",
            },
            {
              name: "BUYOG",
            },
            {
              name: "CABASAKAN",
            },
            {
              name: "CAMPAO OCCIDENTAL",
            },
            {
              name: "CAMPAO ORIENTAL",
            },
            {
              name: "CANGMUNDO",
            },
            {
              name: "CARLOS P. GARCIA",
            },
            {
              name: "CORTE BAUD",
            },
            {
              name: "HANDUMON",
            },
            {
              name: "JAGOLIAO",
            },
            {
              name: "JANDAYAN NORTE",
            },
            {
              name: "JANDAYAN SUR",
            },
            {
              name: "JETAFE",
            },
            {
              name: "MAHANAY (MAHANAY ISLAND)",
            },
            {
              name: "NASINGIN",
            },
            {
              name: "PANDANON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGUISE",
            },
            {
              name: "SALOG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TUGAS",
            },
            {
              name: "TULANG",
            },
          ],
        },
        LILA: {
          name: "LILA",
          baranggays: [
            {
              name: "BANBAN",
            },
            {
              name: "BONKOKAN ILAYA",
            },
            {
              name: "BONKOKAN ILAYA",
            },
            {
              name: "BONKOKAN UBOS",
            },
            {
              name: "CALVARIO",
            },
            {
              name: "CALVARIO",
            },
            {
              name: "CANDULANG",
            },
            {
              name: "CANDULANG",
            },
            {
              name: "CATUGASAN",
            },
            {
              name: "CATUGASAN",
            },
            {
              name: "CAYUPO",
            },
            {
              name: "CAYUPO",
            },
            {
              name: "COGON",
            },
            {
              name: "COGON",
            },
            {
              name: "JAMBAWAN",
            },
            {
              name: "JAMBAWAN",
            },
            {
              name: "LA FORTUNA",
            },
            {
              name: "LILA",
            },
            {
              name: "LOMANOY",
            },
            {
              name: "LOMANOY",
            },
            {
              name: "MACALINGAN",
            },
            {
              name: "MACALINGAN",
            },
            {
              name: "MALINAO EAST",
            },
            {
              name: "MALINAO WEST",
            },
            {
              name: "MALINAO WEST",
            },
            {
              name: "NAGSULAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAUG",
            },
            {
              name: "TIGUIS",
            },
          ],
        },
        LOAY: {
          name: "LOAY",
          baranggays: [
            {
              name: "AGAPE",
            },
            {
              name: "ALEGRIA NORTE",
            },
            {
              name: "ALEGRIA SUR",
            },
            {
              name: "BONBON",
            },
            {
              name: "BOTOC OCCIDENTAL",
            },
            {
              name: "BOTOC ORIENTAL",
            },
            {
              name: "CALVARIO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "HINAWANAN",
            },
            {
              name: "LAS SALINAS NORTE",
            },
            {
              name: "LAS SALINAS SUR",
            },
            {
              name: "LOAY",
            },
            {
              name: "PALO",
            },
            {
              name: "POBLACION IBABAO",
            },
            {
              name: "POBLACION UBOS",
            },
            {
              name: "SAGNAP",
            },
            {
              name: "TAMBANGAN",
            },
            {
              name: "TANGCASAN NORTE",
            },
            {
              name: "TANGCASAN SUR",
            },
            {
              name: "TAYONG OCCIDENTAL",
            },
            {
              name: "TAYONG ORIENTAL",
            },
            {
              name: "TOCDOG DACU",
            },
            {
              name: "TOCDOG ILAYA",
            },
            {
              name: "VILLALIMPIA",
            },
            {
              name: "YANANGAN",
            },
          ],
        },
        LOBOC: {
          name: "LOBOC",
          baranggays: [
            {
              name: "AGAPE",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAHIAN",
            },
            {
              name: "BONBON LOWER",
            },
            {
              name: "BONBON UPPER",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUGHO",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CALUNASAN NORTE",
            },
            {
              name: "CALUNASAN SUR",
            },
            {
              name: "CAMAYAAN",
            },
            {
              name: "CAMBANCE",
            },
            {
              name: "CANDABONG",
            },
            {
              name: "CANDASAG",
            },
            {
              name: "CANLASID",
            },
            {
              name: "GON-OB",
            },
            {
              name: "GOTOZON",
            },
            {
              name: "JIMILIAN",
            },
            {
              name: "LOBOC",
            },
            {
              name: "OY",
            },
            {
              name: "POBLACION ONDOL",
            },
            {
              name: "POBLACION SAWANG",
            },
            {
              name: "QUINOGUITAN",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "UGPONG",
            },
            {
              name: "VALLADOLID",
            },
            {
              name: "VILLAFLOR",
            },
          ],
        },
        LOON: {
          name: "LOON",
          baranggays: [
            {
              name: "AGSOSO",
            },
            {
              name: "BADBAD OCCIDENTAL",
            },
            {
              name: "BADBAD ORIENTAL",
            },
            {
              name: "BAGACAY KATIPUNAN",
            },
            {
              name: "BAGACAY KAWAYAN",
            },
            {
              name: "BAGACAY SAONG",
            },
            {
              name: "BAHI",
            },
            {
              name: "BASAC",
            },
            {
              name: "BASDACU",
            },
            {
              name: "BASDIO",
            },
            {
              name: "BIASONG",
            },
            {
              name: "BONGCO",
            },
            {
              name: "BUGHO",
            },
            {
              name: "CABACONGAN",
            },
            {
              name: "CABADUG",
            },
            {
              name: "CABUG",
            },
            {
              name: "CALAYUGAN NORTE",
            },
            {
              name: "CALAYUGAN SUR",
            },
            {
              name: "CAMBAQUIZ",
            },
            {
              name: "CAMPATUD",
            },
            {
              name: "CANDAIGAN",
            },
            {
              name: "CANHANGDON OCCIDENTAL",
            },
            {
              name: "CANHANGDON ORIENTAL",
            },
            {
              name: "CANIGAAN",
            },
            {
              name: "CANMAAG",
            },
            {
              name: "CANMANOC",
            },
            {
              name: "CANSUAGWIT",
            },
            {
              name: "CANSUBAYON",
            },
            {
              name: "CANTAM-IS BAGO",
            },
            {
              name: "CANTAM-IS BASLAY",
            },
            {
              name: "CANTAONGON",
            },
            {
              name: "CANTUMOCAD",
            },
            {
              name: "CATAGBACAN HANDIG",
            },
            {
              name: "CATAGBACAN NORTE",
            },
            {
              name: "CATAGBACAN SUR",
            },
            {
              name: "COGON NORTE (POB.)",
            },
            {
              name: "COGON SUR",
            },
            {
              name: "CUASI",
            },
            {
              name: "GENOMOAN",
            },
            {
              name: "LINTUAN",
            },
            {
              name: "LOOC",
            },
            {
              name: "LOON",
            },
            {
              name: "MOCPOC NORTE",
            },
            {
              name: "MOCPOC SUR",
            },
            {
              name: "MOTO NORTE (POB.)",
            },
            {
              name: "MOTO SUR (POB.)",
            },
            {
              name: "NAGTUANG",
            },
            {
              name: "NAPO (POB.)",
            },
            {
              name: "NUEVA VIDA",
            },
            {
              name: "PANANGQUILON",
            },
            {
              name: "PANTUDLAN",
            },
            {
              name: "PIG-OT",
            },
            {
              name: "PONDOL",
            },
            {
              name: "QUINOBCOBAN",
            },
            {
              name: "SONDOL",
            },
            {
              name: "SONG-ON",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAN-AWAN",
            },
            {
              name: "TANGNAN",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TICUGAN",
            },
            {
              name: "TIWI",
            },
            {
              name: "TONTONAN",
            },
            {
              name: "TUBODACU",
            },
            {
              name: "TUBODIO",
            },
            {
              name: "TUBUAN",
            },
            {
              name: "UBAYON",
            },
            {
              name: "UBOJAN",
            },
          ],
        },
        MABINI: {
          name: "MABINI",
          baranggays: [
            {
              name: "ABACA",
            },
            {
              name: "ABAD SANTOS",
            },
            {
              name: "AGUIPO",
            },
            {
              name: "BAYBAYON",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CABIDIAN",
            },
            {
              name: "CAWAYANAN",
            },
            {
              name: "CONCEPCION (BANLAS)",
            },
            {
              name: "DEL MAR",
            },
            {
              name: "LUNGSODA-AN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MARCELO",
            },
            {
              name: "MINOL",
            },
            {
              name: "PARAISO",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE (CABULAO)",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TANGKIGAN",
            },
            {
              name: "VALAGA",
            },
          ],
        },
        MARIBOJOC: {
          name: "MARIBOJOC",
          baranggays: [
            {
              name: "AGAHAY",
            },
            {
              name: "ALIGUAY",
            },
            {
              name: "ANISLAG",
            },
            {
              name: "BAYACABAC",
            },
            {
              name: "BOOD",
            },
            {
              name: "BUSAO",
            },
            {
              name: "CABAWAN",
            },
            {
              name: "CANDAVID",
            },
            {
              name: "DIPATLONG",
            },
            {
              name: "GUIWANON",
            },
            {
              name: "JANDIG",
            },
            {
              name: "LAGTANGON",
            },
            {
              name: "LINCOD",
            },
            {
              name: "MARIBOJOC",
            },
            {
              name: "PAGNITOAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNSOD",
            },
            {
              name: "PUNTA CRUZ",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE (AGHAO)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TINIBGAN",
            },
            {
              name: "TORIL",
            },
          ],
        },
        PANGLAO: {
          name: "PANGLAO",
          baranggays: [
            {
              name: "BIL-ISAN",
            },
            {
              name: "BOLOD",
            },
            {
              name: "DANAO",
            },
            {
              name: "DOLJO",
            },
            {
              name: "LIBAONG",
            },
            {
              name: "LOOC",
            },
            {
              name: "LOURDES",
            },
            {
              name: "PANGLAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TANGNAN",
            },
            {
              name: "TAWALA",
            },
          ],
        },
        PILAR: {
          name: "PILAR",
          baranggays: [
            {
              name: "AURORA",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAYONG",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "CAGAWASAN",
            },
            {
              name: "CANSUNGAY",
            },
            {
              name: "CATAGDA-AN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "ESTACA",
            },
            {
              name: "ILAUD",
            },
            {
              name: "INAGHUBAN",
            },
            {
              name: "LA SUERTE",
            },
            {
              name: "LUMBAY",
            },
            {
              name: "LUNDAG",
            },
            {
              name: "PAMACSALAN",
            },
            {
              name: "PILAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
          ],
        },
        "PRES. CARLOS P. GARCIA (PITOGO)": {
          name: "PRES. CARLOS P. GARCIA (PITOGO)",
          baranggays: [
            {
              name: "AGUINING",
            },
            {
              name: "BASIAO",
            },
            {
              name: "BAUD",
            },
            {
              name: "BAYOG",
            },
            {
              name: "BOGO",
            },
            {
              name: "BONBONON",
            },
            {
              name: "BUTAN",
            },
            {
              name: "CAMPAMANOG",
            },
            {
              name: "CANMANGAO",
            },
            {
              name: "GAUS",
            },
            {
              name: "KABANGKALAN",
            },
            {
              name: "LAPINIG",
            },
            {
              name: "LIPATA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POPOO",
            },
            {
              name: "PRES. CARLOS P. GARCIA (PITOGO)",
            },
            {
              name: "SAGUISE",
            },
            {
              name: "SAN JOSE (TAWID)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TILMOBO",
            },
            {
              name: "TUGAS",
            },
            {
              name: "TUGNAO",
            },
            {
              name: "VILLA MILAGROSA",
            },
          ],
        },
        "SAGBAYAN (BORJA)": {
          name: "SAGBAYAN (BORJA)",
          baranggays: [
            {
              name: "CALANGAHAN",
            },
            {
              name: "CANMANO",
            },
            {
              name: "CANMAYA CENTRO",
            },
            {
              name: "CANMAYA DIOT",
            },
            {
              name: "DAGNAWAN",
            },
            {
              name: "KABASACAN",
            },
            {
              name: "KAGAWASAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LANGTAD",
            },
            {
              name: "LIBERTAD NORTE",
            },
            {
              name: "LIBERTAD SUR",
            },
            {
              name: "MANTALONGON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGBAYAN (BORJA)",
            },
            {
              name: "SAGBAYAN SUR",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE NORTE",
            },
            {
              name: "SAN VICENTE SUR",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "UBOJAN",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "ABEHILAN",
            },
            {
              name: "BARYONG DAAN",
            },
            {
              name: "BAUNOS",
            },
            {
              name: "CABANUGAN",
            },
            {
              name: "CAIMBANG",
            },
            {
              name: "CAMBANSAG",
            },
            {
              name: "CANDUNGAO",
            },
            {
              name: "CANSAGUE NORTE",
            },
            {
              name: "CANSAGUE SUR",
            },
            {
              name: "CAUSWAGAN SUR",
            },
            {
              name: "MASONOY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        "SAN MIGUEL": {
          name: "SAN MIGUEL",
          baranggays: [
            {
              name: "BAYONGAN",
            },
            {
              name: "BUGANG",
            },
            {
              name: "CABANGAHAN",
            },
            {
              name: "CALUASAN",
            },
            {
              name: "CAMANAGA",
            },
            {
              name: "CAMBANGAY NORTE",
            },
            {
              name: "CAPAYAS",
            },
            {
              name: "CORAZON",
            },
            {
              name: "GARCIA",
            },
            {
              name: "HAGBUYO",
            },
            {
              name: "KAGAWASAN",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TOMOC",
            },
          ],
        },
        SEVILLA: {
          name: "SEVILLA",
          baranggays: [
            {
              name: "BAYAWAHAN",
            },
            {
              name: "CABANCALAN",
            },
            {
              name: "CALINGA-AN",
            },
            {
              name: "CALINGINAN NORTE",
            },
            {
              name: "CALINGINAN SUR",
            },
            {
              name: "CAMBAGUI",
            },
            {
              name: "EWON",
            },
            {
              name: "GUINOB-AN",
            },
            {
              name: "LAGTANGAN",
            },
            {
              name: "LICOLICO",
            },
            {
              name: "LOBGOB",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SEVILLA",
            },
          ],
        },
        "SIERRA BULLONES": {
          name: "SIERRA BULLONES",
          baranggays: [
            {
              name: "ABACHANAN",
            },
            {
              name: "ANIBONGAN",
            },
            {
              name: "BUGSOC",
            },
            {
              name: "CAHAYAG",
            },
            {
              name: "CANLANGIT",
            },
            {
              name: "CANTA-UB",
            },
            {
              name: "CASILAY",
            },
            {
              name: "DANICOP",
            },
            {
              name: "DUSITA",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LATABAN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAN-OD",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVADOR",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SIERRA BULLONES",
            },
            {
              name: "VILLA GARCIA",
            },
          ],
        },
        SIKATUNA: {
          name: "SIKATUNA",
          baranggays: [
            {
              name: "ABUCAY NORTE",
            },
            {
              name: "ABUCAY SUR",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BAHAYBAHAY",
            },
            {
              name: "CAMBUAC NORTE",
            },
            {
              name: "CAMBUAC SUR",
            },
            {
              name: "CANAGONG",
            },
            {
              name: "LIBJO",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SIKATUNA",
            },
          ],
        },
        "TAGBILARAN CITY": {
          name: "TAGBILARAN CITY",
          baranggays: [
            {
              name: "BOOL",
            },
            {
              name: "BOOY",
            },
            {
              name: "CABAWAN",
            },
            {
              name: "COGON",
            },
            {
              name: "DAMPAS",
            },
            {
              name: "DAO",
            },
            {
              name: "MANGA",
            },
            {
              name: "MANSASA",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "TAGBILARAN CITY",
            },
            {
              name: "TALOTO",
            },
            {
              name: "TIPTIP",
            },
            {
              name: "UBUJAN",
            },
          ],
        },
        TALIBON: {
          name: "TALIBON",
          baranggays: [
            {
              name: "BAGACAY",
            },
            {
              name: "BALINTAWAK",
            },
            {
              name: "BURGOS",
            },
            {
              name: "BUSALIAN",
            },
            {
              name: "CALITUBAN",
            },
            {
              name: "CATABAN",
            },
            {
              name: "GUINDACPAN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAHANAY",
            },
            {
              name: "NOCNOCAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAG",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIKATUNA",
            },
            {
              name: "SUBA",
            },
            {
              name: "TALIBON",
            },
            {
              name: "TANGHALIGUE",
            },
            {
              name: "ZAMORA",
            },
          ],
        },
        TRINIDAD: {
          name: "TRINIDAD",
          baranggays: [
            {
              name: "BANLASAN",
            },
            {
              name: "BONGBONG",
            },
            {
              name: "CATOOGAN",
            },
            {
              name: "GUINOBATAN",
            },
            {
              name: "HINLAYAGAN ILAUD",
            },
            {
              name: "HINLAYAGAN ILAYA",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KINAN-OAN",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LA VICTORIA",
            },
            {
              name: "MABUHAY CABIGOHAN",
            },
            {
              name: "MAHAGBU",
            },
            {
              name: "MANUEL M. ROXAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SOOM",
            },
            {
              name: "TAGUM NORTE",
            },
            {
              name: "TAGUM SUR",
            },
            {
              name: "TRINIDAD",
            },
          ],
        },
        TUBIGON: {
          name: "TUBIGON",
          baranggays: [
            {
              name: "BAGONGBANWA",
            },
            {
              name: "BANLASAN",
            },
            {
              name: "BATASAN (BATASAN ISLAND)",
            },
            {
              name: "BILANGBILANGAN (BILANGBILANGAN ISLAND)",
            },
            {
              name: "BOSONGON",
            },
            {
              name: "BUENOS AIRES",
            },
            {
              name: "BUNACAN",
            },
            {
              name: "CABULIHAN",
            },
            {
              name: "CAHAYAG",
            },
            {
              name: "CAWAYANAN",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "GENONOCAN",
            },
            {
              name: "GUIWANON",
            },
            {
              name: "ILIHAN NORTE",
            },
            {
              name: "ILIHAN SUR",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MACAAS",
            },
            {
              name: "MATABAO",
            },
            {
              name: "MOCABOC ISLAND",
            },
            {
              name: "PANADTARAN",
            },
            {
              name: "PANAYTAYON",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PANGAPASAN (PANGAPASAN ISLAND)",
            },
            {
              name: "PINAYAGAN NORTE",
            },
            {
              name: "PINAYAGAN SUR",
            },
            {
              name: "POOC OCCIDENTAL (POB.)",
            },
            {
              name: "POOC ORIENTAL (POB.)",
            },
            {
              name: "POTOHAN",
            },
            {
              name: "TALENCERAS",
            },
            {
              name: "TAN-AWAN",
            },
            {
              name: "TINANGNAN",
            },
            {
              name: "TUBIGON",
            },
            {
              name: "UBAY ISLAND",
            },
            {
              name: "UBOJAN",
            },
            {
              name: "VILLANUEVA",
            },
          ],
        },
        UBAY: {
          name: "UBAY",
          baranggays: [
            {
              name: "ACHILA",
            },
            {
              name: "BAY-ANG",
            },
            {
              name: "BENLIW",
            },
            {
              name: "BIABAS",
            },
            {
              name: "BONGBONG",
            },
            {
              name: "BOOD",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULILIS",
            },
            {
              name: "CAGTING",
            },
            {
              name: "CALANGGAMAN",
            },
            {
              name: "CALIFORNIA",
            },
            {
              name: "CAMALI-AN",
            },
            {
              name: "CAMAMBUGAN",
            },
            {
              name: "CASATE",
            },
            {
              name: "CUYA",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GABI",
            },
            {
              name: "GOVERNOR BOYLES",
            },
            {
              name: "GUINTABO-AN",
            },
            {
              name: "HAMBABAURAN",
            },
            {
              name: "HUMAYHUMAY",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "JUAGDAN",
            },
            {
              name: "KATARUNGAN",
            },
            {
              name: "LOMANGOG",
            },
            {
              name: "LOS ANGELES",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SENTINILA",
            },
            {
              name: "SINANDIGAN",
            },
            {
              name: "TAPAL",
            },
            {
              name: "TAPON",
            },
            {
              name: "TINTINAN",
            },
            {
              name: "TIPOLO",
            },
            {
              name: "TUBOG",
            },
            {
              name: "TUBORAN",
            },
            {
              name: "UBAY",
            },
            {
              name: "UNION",
            },
            {
              name: "VILLA TERESITA",
            },
          ],
        },
        VALENCIA: {
          name: "VALENCIA",
          baranggays: [
            {
              name: "ADLAWAN",
            },
            {
              name: "ANAS",
            },
            {
              name: "ANONANG",
            },
            {
              name: "ANOYON",
            },
            {
              name: "BALINGASAO",
            },
            {
              name: "BANDERAHAN (UPPER GINOPOLAN)",
            },
            {
              name: "BOTONG",
            },
            {
              name: "BUYOG",
            },
            {
              name: "CANDUAO OCCIDENTAL",
            },
            {
              name: "CANDUAO ORIENTAL",
            },
            {
              name: "CANLUSONG",
            },
            {
              name: "CANMANICO",
            },
            {
              name: "CANSIBAO",
            },
            {
              name: "CATUG-A",
            },
            {
              name: "CUTCUTAN",
            },
            {
              name: "DANAO",
            },
            {
              name: "GENOVEVA",
            },
            {
              name: "GINOPOLAN (GINOPOLAN PROPER)",
            },
            {
              name: "LA VICTORIA",
            },
            {
              name: "LANTANG",
            },
            {
              name: "LIMOCON",
            },
            {
              name: "LOCTOB",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARAWIS",
            },
            {
              name: "MAUBO",
            },
            {
              name: "NAILO",
            },
            {
              name: "OMJON",
            },
            {
              name: "PANGI-AN",
            },
            {
              name: "POBLACION OCCIDENTAL",
            },
            {
              name: "POBLACION ORIENTAL",
            },
            {
              name: "SIMANG",
            },
            {
              name: "TAUG",
            },
            {
              name: "TAUSION",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TICUM",
            },
            {
              name: "VALENCIA",
            },
          ],
        },
      },
    },
    BUKIDNON: {
      name: "BUKIDNON",
      region: "REGION 10",
      municipalities: {
        BAUNGON: {
          name: "BAUNGON",
          baranggays: [
            {
              name: "BALINTAD",
            },
            {
              name: "BAUNGON",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "DANATAG",
            },
            {
              name: "IMBATUG (POB.)",
            },
            {
              name: "KALILANGAN",
            },
            {
              name: "LACOLAC",
            },
            {
              name: "LANGAON",
            },
            {
              name: "LIBORAN",
            },
            {
              name: "LINGATING",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MABUNGA",
            },
            {
              name: "NICDAO",
            },
            {
              name: "PUALAS",
            },
            {
              name: "SALIMBALAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN VICENTE",
            },
          ],
        },
        CABANGLASAN: {
          name: "CABANGLASAN",
          baranggays: [
            {
              name: "ANLOGAN",
            },
            {
              name: "CABANGLASAN",
            },
            {
              name: "CABULOHAN",
            },
            {
              name: "CANANGAAN",
            },
            {
              name: "CAPINONAN",
            },
            {
              name: "DALACUTAN",
            },
            {
              name: "FREEDOM",
            },
            {
              name: "IBA",
            },
            {
              name: "IMBATUG",
            },
            {
              name: "JASAAN",
            },
            {
              name: "LAMBANGAN",
            },
            {
              name: "MANDAHIKAN",
            },
            {
              name: "MANDAING",
            },
            {
              name: "MAUSWAGON",
            },
            {
              name: "PARADISE",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        "CITY OF MALAYBALAY": {
          name: "CITY OF MALAYBALAY",
          baranggays: [
            {
              name: "AGLAYAN",
            },
            {
              name: "APO MACOTE",
            },
            {
              name: "BANGCUD",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BUSDI",
            },
            {
              name: "CABANGAHAN",
            },
            {
              name: "CABURACANAN",
            },
            {
              name: "CANAYAN",
            },
            {
              name: "CAPITAN ANGEL",
            },
            {
              name: "CASISANG",
            },
            {
              name: "CITY OF MALAYBALAY",
            },
            {
              name: "DALWANGAN",
            },
            {
              name: "IMBAYAO",
            },
            {
              name: "INDALAZA",
            },
            {
              name: "KABALABAG",
            },
            {
              name: "KALASUNGAY",
            },
            {
              name: "KULAMAN",
            },
            {
              name: "LAGUITAS",
            },
            {
              name: "LINABO",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MANAGOK",
            },
            {
              name: "MANALOG",
            },
            {
              name: "MAPAYAG",
            },
            {
              name: "MAPULO",
            },
            {
              name: "MIGLAMIN",
            },
            {
              name: "PATPAT (LAPU-LAPU)",
            },
            {
              name: "SAINT PETER",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SILAE",
            },
            {
              name: "SIMAYA",
            },
            {
              name: "SINANGLANAN",
            },
            {
              name: "SUMPONG",
            },
            {
              name: "VIOLETA",
            },
            {
              name: "ZAMBOANGUITA",
            },
          ],
        },
        DAMULOG: {
          name: "DAMULOG",
          baranggays: [
            {
              name: "ALUDAS",
            },
            {
              name: "ANGGA-AN",
            },
            {
              name: "DAMULOG",
            },
            {
              name: "KINAPAT",
            },
            {
              name: "KIRAON",
            },
            {
              name: "KITINGTING",
            },
            {
              name: "LAGANDANG",
            },
            {
              name: "MACAPARI",
            },
            {
              name: "MAICAN",
            },
            {
              name: "MIGCAWAYAN",
            },
            {
              name: "NEW COMPOSTELA",
            },
            {
              name: "OLD DAMULOG",
            },
            {
              name: "OMONAY",
            },
            {
              name: "POBLACION (NEW DAMULOG)",
            },
            {
              name: "POCOPOCO",
            },
            {
              name: "SAMPAGAR",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "TANGKULAN (JOSE RIZAL)",
            },
          ],
        },
        DANGCAGAN: {
          name: "DANGCAGAN",
          baranggays: [
            {
              name: "BARONGCOT",
            },
            {
              name: "BUGWAK",
            },
            {
              name: "DOLOROSA",
            },
            {
              name: "KAPALARAN",
            },
            {
              name: "KIANGGAT",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MACARTHUR",
            },
            {
              name: "MIARAY",
            },
            {
              name: "MIGCUYA",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGBAYAN",
            },
            {
              name: "SAN VICENTE",
            },
          ],
        },
        "DON CARLOS": {
          name: "DON CARLOS",
          baranggays: [
            {
              name: "BISMARTZ",
            },
            {
              name: "BOCBOC",
            },
            {
              name: "BUYOT",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CALAOCALAO",
            },
            {
              name: "DON CARLOS",
            },
            {
              name: "DON CARLOS NORTE",
            },
            {
              name: "DON CARLOS SUR (POB.)",
            },
            {
              name: "EMBAYAO",
            },
            {
              name: "KALUBIHON",
            },
            {
              name: "KASIGKOT",
            },
            {
              name: "KAWILIHAN",
            },
            {
              name: "KIARA",
            },
            {
              name: "KIBATANG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MANLAMONAY",
            },
            {
              name: "MARAYMARAY",
            },
            {
              name: "MAUSWAGON",
            },
            {
              name: "MINSALAGAN",
            },
            {
              name: "NEW NONGNONGAN (MASIMAG)",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "OLD NONGNONGAN",
            },
            {
              name: "PINAMALOY",
            },
            {
              name: "PUALAS",
            },
            {
              name: "SAN ANTONIO EAST",
            },
            {
              name: "SAN ANTONIO WEST",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN NICOLAS (BANBAN)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SINANGGUYAN",
            },
          ],
        },
        "IMPASUG-ONG": {
          name: "IMPASUG-ONG",
          baranggays: [
            {
              name: "BONTONGON",
            },
            {
              name: "BULONAY",
            },
            {
              name: "CAPITAN BAYONG",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "DUMALAGUING",
            },
            {
              name: "GUIHEAN",
            },
            {
              name: "HAGPA",
            },
            {
              name: "IMPALUTAO",
            },
            {
              name: "IMPASUG-ONG",
            },
            {
              name: "KALABUGAO",
            },
            {
              name: "KIBENTON",
            },
            {
              name: "LA FORTUNA",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        KADINGILAN: {
          name: "KADINGILAN",
          baranggays: [
            {
              name: "BAGONGBAYAN",
            },
            {
              name: "BAGOR",
            },
            {
              name: "BALAORO",
            },
            {
              name: "BAROY",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "HUSAYAN",
            },
            {
              name: "KADINGILAN",
            },
            {
              name: "KIBALAGON",
            },
            {
              name: "KIBOGTOK",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "PAY-AS",
            },
            {
              name: "PINAMANGUHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SIBONGA",
            },
          ],
        },
        KALILANGAN: {
          name: "KALILANGAN",
          baranggays: [
            {
              name: "BABORAWON",
            },
            {
              name: "BANGBANG",
            },
            {
              name: "CANITUAN",
            },
            {
              name: "KALILANGAN",
            },
            {
              name: "KIBANING",
            },
            {
              name: "KINURA",
            },
            {
              name: "LAMPANUSAN",
            },
            {
              name: "MACA-OPAO",
            },
            {
              name: "MALINAO",
            },
            {
              name: "NINOY AQUINO",
            },
            {
              name: "PAMOTOLON (PAMOTDON)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUBLIC",
            },
            {
              name: "SAN VICENTE FERRER",
            },
            {
              name: "WEST POBLACION",
            },
          ],
        },
        KIBAWE: {
          name: "KIBAWE",
          baranggays: [
            {
              name: "BALINTAWAK",
            },
            {
              name: "BUKANG LIWAYWAY",
            },
            {
              name: "CAGAWASAN",
            },
            {
              name: "EAST KIBAWE (POB.)",
            },
            {
              name: "GUTAPOL",
            },
            {
              name: "KIORAO",
            },
            {
              name: "KISAWA",
            },
            {
              name: "LABUAGON",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARAPANGI",
            },
            {
              name: "MASCARIÑAS",
            },
            {
              name: "NATULONGAN",
            },
            {
              name: "NEW KIDAPAWAN",
            },
            {
              name: "OLD KIBAWE",
            },
            {
              name: "PALMA",
            },
            {
              name: "PINAMULA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROMAGOOC",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SANIPON",
            },
            {
              name: "SPRING",
            },
            {
              name: "TALAHIRON",
            },
            {
              name: "TUMARAS",
            },
            {
              name: "WEST KIBAWE (POB.)",
            },
          ],
        },
        KITAOTAO: {
          name: "KITAOTAO",
          baranggays: [
            {
              name: "BALANGIGAY",
            },
            {
              name: "BALUKBUKAN",
            },
            {
              name: "BERSHIBA",
            },
            {
              name: "BINOONGAN",
            },
            {
              name: "BOBONG",
            },
            {
              name: "BOLOCAON",
            },
            {
              name: "CABALANTIAN",
            },
            {
              name: "CALAPATON",
            },
            {
              name: "DIGONGAN",
            },
            {
              name: "EAST DALURONG",
            },
            {
              name: "KAHUSAYAN",
            },
            {
              name: "KALUMIHAN",
            },
            {
              name: "KAUYONAN",
            },
            {
              name: "KIMOLONG",
            },
            {
              name: "KIPILAS",
            },
            {
              name: "KITAIHON",
            },
            {
              name: "KITAOTAO",
            },
            {
              name: "KITOBO",
            },
            {
              name: "KIULOM",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALOBALO",
            },
            {
              name: "METEBAGAO",
            },
            {
              name: "NAPALICO",
            },
            {
              name: "PAGAN",
            },
            {
              name: "PANGANAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGUNDANON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SINAYSAYAN (DALURONG)",
            },
            {
              name: "SINUDA (SIMOD)",
            },
            {
              name: "TANDONG",
            },
            {
              name: "TAWAS",
            },
            {
              name: "WEST DALURONG",
            },
            {
              name: "WHITE KULAMAN",
            },
          ],
        },
        LANTAPAN: {
          name: "LANTAPAN",
          baranggays: [
            {
              name: "ALANIB",
            },
            {
              name: "BACLAYON",
            },
            {
              name: "BALILA",
            },
            {
              name: "BANTUANON",
            },
            {
              name: "BASAK",
            },
            {
              name: "BUGCAON",
            },
            {
              name: "CAPITAN JUAN",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "KA-ATOAN (KAATUAN)",
            },
            {
              name: "KIBANGAY",
            },
            {
              name: "KULASIHAN",
            },
            {
              name: "LANTAPAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SONGCO",
            },
            {
              name: "VICTORY",
            },
          ],
        },
        LIBONA: {
          name: "LIBONA",
          baranggays: [
            {
              name: "LATURAN",
            },
            {
              name: "LIBONA",
            },
            {
              name: "MAAMBONG",
            },
            {
              name: "NANGKA",
            },
            {
              name: "PALABUCAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PONGOL",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SIL-IPON",
            },
          ],
        },
        MALITBOG: {
          name: "MALITBOG",
          baranggays: [
            {
              name: "KALINGKING",
            },
            {
              name: "KIABO",
            },
            {
              name: "MALITBOG",
            },
            {
              name: "MINDAGAT",
            },
            {
              name: "OMAGLING",
            },
            {
              name: "PATPAT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMPIANO",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SILO-O",
            },
            {
              name: "SUMALSAG",
            },
          ],
        },
        "MANOLO FORTICH": {
          name: "MANOLO FORTICH",
          baranggays: [
            {
              name: "AGUSAN CANYON",
            },
            {
              name: "ALAE",
            },
            {
              name: "DAHILAYAN",
            },
            {
              name: "DALIRIG",
            },
            {
              name: "DAMILAG",
            },
            {
              name: "DICLUM",
            },
            {
              name: "GUILANG-GUILANG",
            },
            {
              name: "KALUGMANAN",
            },
            {
              name: "LINDABAN",
            },
            {
              name: "LINGION",
            },
            {
              name: "LUNOCAN",
            },
            {
              name: "MALUKO",
            },
            {
              name: "MAMBATANGAN",
            },
            {
              name: "MAMPAYAG",
            },
            {
              name: "MANOLO FORTICH",
            },
            {
              name: "MANTIBUGAO",
            },
            {
              name: "MINSURO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANKANAN",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TANKULAN (POB.)",
            },
            {
              name: "TICALA",
            },
          ],
        },
        MARAMAG: {
          name: "MARAMAG",
          baranggays: [
            {
              name: "ANAHAWON",
            },
            {
              name: "BAGONGSILANG",
            },
            {
              name: "BASE CAMP",
            },
            {
              name: "BAYABASON (SPRING)",
            },
            {
              name: "CAMP I",
            },
            {
              name: "COLAMBUGAN",
            },
            {
              name: "DAGUMBA-AN",
            },
            {
              name: "DANGGAWAN",
            },
            {
              name: "DOLOGON",
            },
            {
              name: "KIHARONG",
            },
            {
              name: "KISANDAY",
            },
            {
              name: "KUYA",
            },
            {
              name: "LA ROXAS",
            },
            {
              name: "MARAMAG",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "PANADTALAN",
            },
            {
              name: "PANALSALAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TUBIGON",
            },
          ],
        },
        PANGANTUCAN: {
          name: "PANGANTUCAN",
          baranggays: [
            {
              name: "ADTUYON",
            },
            {
              name: "BACUSANON",
            },
            {
              name: "BANGAHAN",
            },
            {
              name: "BARANDIAS",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "GANDINGAN",
            },
            {
              name: "KIMANAIT",
            },
            {
              name: "KIPADUKAN",
            },
            {
              name: "LANGCATAON",
            },
            {
              name: "LANTAY",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MALIPAYON",
            },
            {
              name: "MENDIS",
            },
            {
              name: "NABALIWA",
            },
            {
              name: "NEW EDEN",
            },
            {
              name: "PANGANTUCAN",
            },
            {
              name: "PAYAD",
            },
            {
              name: "PIGTAURANAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PORTULIN",
            },
          ],
        },
        QUEZON: {
          name: "QUEZON",
          baranggays: [
            {
              name: "BUTONG",
            },
            {
              name: "C-HANDUMANAN",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CEBOLE",
            },
            {
              name: "DELAPA",
            },
            {
              name: "DUMALAMA",
            },
            {
              name: "KIBURIAO",
            },
            {
              name: "KIPAYPAYON",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LINABO",
            },
            {
              name: "LIPA",
            },
            {
              name: "LUMINTAO",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MANUTO",
            },
            {
              name: "MERANGERANG",
            },
            {
              name: "MIBANTANG",
            },
            {
              name: "MINONGAN",
            },
            {
              name: "MINSALIRAK",
            },
            {
              name: "MINSAMONGAN",
            },
            {
              name: "PAITAN",
            },
            {
              name: "PALACAPAO",
            },
            {
              name: "PINILAYAN",
            },
            {
              name: "POBLACION (KIOKONG)",
            },
            {
              name: "PUNTIAN",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SALAWAGAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA FILOMENA",
            },
          ],
        },
        "SAN FERNANDO": {
          name: "SAN FERNANDO",
          baranggays: [
            {
              name: "BONACAO",
            },
            {
              name: "BULALANG",
            },
            {
              name: "CABULING",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "CAYAGA",
            },
            {
              name: "DAO",
            },
            {
              name: "DURIAN",
            },
            {
              name: "HALAPITAN (POB.)",
            },
            {
              name: "IGLUGSAD",
            },
            {
              name: "KALAGANGAN",
            },
            {
              name: "KAWAYAN",
            },
            {
              name: "KIBONGCOG",
            },
            {
              name: "LITTLE BAGUIO",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGKALUNGAY",
            },
            {
              name: "MALAYANAN",
            },
            {
              name: "MATUPE",
            },
            {
              name: "NACABUKLAD",
            },
            {
              name: "NAMNAM",
            },
            {
              name: "PALACPACAN",
            },
            {
              name: "SACRAMENTO VALLEY",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "TUGOP",
            },
          ],
        },
        SUMILAO: {
          name: "SUMILAO",
          baranggays: [
            {
              name: "CULASI",
            },
            {
              name: "KISOLON",
            },
            {
              name: "LICOAN",
            },
            {
              name: "LUPIAGAN",
            },
            {
              name: "OCASION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNTIAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SUMILAO",
            },
            {
              name: "VISTA VILLA",
            },
          ],
        },
        TALAKAG: {
          name: "TALAKAG",
          baranggays: [
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BASAK",
            },
            {
              name: "BAYLANAN",
            },
            {
              name: "CACAON",
            },
            {
              name: "COLAWINGON",
            },
            {
              name: "COSINA",
            },
            {
              name: "DAGUMBAAN",
            },
            {
              name: "DAGUNDALAHON",
            },
            {
              name: "DOMINOROG",
            },
            {
              name: "INDULANG",
            },
            {
              name: "LANTUD",
            },
            {
              name: "LAPOK",
            },
            {
              name: "LIGURON",
            },
            {
              name: "LINGI-ON",
            },
            {
              name: "LIRONGAN",
            },
            {
              name: "MIARAYON",
            },
            {
              name: "SAGARAN",
            },
            {
              name: "SALUCOT",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTO NIÑO (LUMBAYAWA)",
            },
            {
              name: "TAGBAK",
            },
            {
              name: "TALAKAG",
            },
            {
              name: "TIKALAAN",
            },
          ],
        },
        "VALENCIA CITY": {
          name: "VALENCIA CITY",
          baranggays: [
            {
              name: "BAGONTAAS",
            },
            {
              name: "BANLAG",
            },
            {
              name: "BAROBO",
            },
            {
              name: "BATANGAN",
            },
            {
              name: "CATUMBALON",
            },
            {
              name: "COLONIA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAGAT-KIDAVAO",
            },
            {
              name: "GUINOYURAN",
            },
            {
              name: "KAHAPUNAN",
            },
            {
              name: "LALIGAN",
            },
            {
              name: "LILINGAYON",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LUMBAYAO",
            },
            {
              name: "LUMBO",
            },
            {
              name: "LUROGAN",
            },
            {
              name: "MAAPAG",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAILAG",
            },
            {
              name: "MT. NEBO",
            },
            {
              name: "NABAGO",
            },
            {
              name: "PINATILAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SINABUAGAN",
            },
            {
              name: "SINAYAWAN",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TONGANTONGAN",
            },
            {
              name: "TUGAYA",
            },
            {
              name: "VALENCIA CITY",
            },
            {
              name: "VINTAR",
            },
          ],
        },
      },
    },
    BULACAN: {
      name: "BULACAN",
      region: "REGION 3",
      municipalities: {
        ANGAT: {
          name: "ANGAT",
          baranggays: [
            {
              name: "ANGAT",
            },
            {
              name: "BANABAN",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BINAGBAG",
            },
            {
              name: "DONACION",
            },
            {
              name: "ENCANTO",
            },
            {
              name: "LAOG",
            },
            {
              name: "MARUNGKO",
            },
            {
              name: "NIUGAN",
            },
            {
              name: "PALTOK",
            },
            {
              name: "PULONG YANTOK",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTO CRISTO (POB.)",
            },
            {
              name: "SULUCAN",
            },
            {
              name: "TABOC",
            },
          ],
        },
        "BALAGTAS (BIGAA)": {
          name: "BALAGTAS (BIGAA)",
          baranggays: [
            {
              name: "BALAGTAS (BIGAA)",
            },
            {
              name: "BOROL 1ST",
            },
            {
              name: "BOROL 2ND",
            },
            {
              name: "DALIG",
            },
            {
              name: "LONGOS",
            },
            {
              name: "PANGINAY",
            },
            {
              name: "PULONG GUBAT",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTOL",
            },
            {
              name: "WAWA (POB.)",
            },
          ],
        },
        BALIUAG: {
          name: "BALIUAG",
          baranggays: [
            {
              name: "BAGONG NAYON",
            },
            {
              name: "BALIUAG",
            },
            {
              name: "BARANGCA",
            },
            {
              name: "CALANTIPAY",
            },
            {
              name: "CATULINAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "HINUKAY",
            },
            {
              name: "MAKINABANG",
            },
            {
              name: "MATANGTUBIG",
            },
            {
              name: "PAGALA",
            },
            {
              name: "PAITAN",
            },
            {
              name: "PIEL",
            },
            {
              name: "PINAGBARILAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SUBIC",
            },
            {
              name: "SULIVAN",
            },
            {
              name: "TANGOS",
            },
            {
              name: "TARCAN",
            },
            {
              name: "TIAONG",
            },
            {
              name: "TIBAG",
            },
            {
              name: "TILAPAYONG",
            },
            {
              name: "VIRGEN DELAS FLORES",
            },
          ],
        },
        BOCAUE: {
          name: "BOCAUE",
          baranggays: [
            {
              name: "ANTIPONA",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "BATIA",
            },
            {
              name: "BIÑANG 1ST",
            },
            {
              name: "BIÑANG 2ND",
            },
            {
              name: "BOCAUE",
            },
            {
              name: "BOLACAN",
            },
            {
              name: "BUNDUKAN",
            },
            {
              name: "BUNLO",
            },
            {
              name: "CAINGIN",
            },
            {
              name: "DUHAT",
            },
            {
              name: "IGULOT",
            },
            {
              name: "LOLOMBOY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SULUCAN",
            },
            {
              name: "TAAL",
            },
            {
              name: "TAMBOBONG",
            },
            {
              name: "TURO",
            },
            {
              name: "WAKAS",
            },
          ],
        },
        BULACAN: {
          name: "BULACAN",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALUBAD",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "BULACAN",
            },
            {
              name: "MATUNGAO",
            },
            {
              name: "MAYSANTOL",
            },
            {
              name: "PEREZ",
            },
            {
              name: "PITPITAN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "TALIPTIP",
            },
            {
              name: "TIBIG",
            },
          ],
        },
        BUSTOS: {
          name: "BUSTOS",
          baranggays: [
            {
              name: "BONGA MAYOR",
            },
            {
              name: "BONGA MENOR",
            },
            {
              name: "BUISAN",
            },
            {
              name: "BUSTOS",
            },
            {
              name: "CAMACHILIHAN",
            },
            {
              name: "CAMBAOG",
            },
            {
              name: "CATACTE",
            },
            {
              name: "LICIADA",
            },
            {
              name: "MALAMIG",
            },
            {
              name: "MALAWAK",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "TALAMPAS",
            },
            {
              name: "TANAWAN",
            },
            {
              name: "TIBAGAN",
            },
          ],
        },
        CALUMPIT: {
          name: "CALUMPIT",
          baranggays: [
            {
              name: "BALITE",
            },
            {
              name: "BALUNGAO",
            },
            {
              name: "BUGUION",
            },
            {
              name: "BULUSAN",
            },
            {
              name: "CALIZON",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CALUMPIT",
            },
            {
              name: "CANIOGAN",
            },
            {
              name: "CORAZON",
            },
            {
              name: "FRANCES",
            },
            {
              name: "GATBUCA",
            },
            {
              name: "GUGO",
            },
            {
              name: "IBA ESTE",
            },
            {
              name: "IBA O'ESTE",
            },
            {
              name: "LONGOS",
            },
            {
              name: "MEYSULAO",
            },
            {
              name: "MEYTO",
            },
            {
              name: "PALIMBANG",
            },
            {
              name: "PANDUCOT",
            },
            {
              name: "PIO CRUZCOSA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNGO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAPANG BAYAN",
            },
            {
              name: "SERGIO BAYAN",
            },
            {
              name: "SUCOL",
            },
          ],
        },
        "DOÑA REMEDIOS TRINIDAD": {
          name: "DOÑA REMEDIOS TRINIDAD",
          baranggays: [
            {
              name: "BAYABAS",
            },
            {
              name: "CAMACHILE",
            },
            {
              name: "CAMACHIN",
            },
            {
              name: "DOÑA REMEDIOS TRINIDAD",
            },
            {
              name: "KABAYUNAN",
            },
            {
              name: "KALAWAKAN",
            },
            {
              name: "PULONG SAMPALOK",
            },
            {
              name: "SAPANG BULAK",
            },
            {
              name: "TALBAK",
            },
          ],
        },
        GUIGUINTO: {
          name: "GUIGUINTO",
          baranggays: [
            {
              name: "CUTCUT",
            },
            {
              name: "DAUNGAN",
            },
            {
              name: "GUIGUINTO",
            },
            {
              name: "ILANG-ILANG",
            },
            {
              name: "MALIS",
            },
            {
              name: "PANGINAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PRITIL",
            },
            {
              name: "PULONG GUBAT",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "TABANG",
            },
            {
              name: "TABE",
            },
            {
              name: "TIAONG",
            },
            {
              name: "TUKTUKAN",
            },
          ],
        },
        HAGONOY: {
          name: "HAGONOY",
          baranggays: [
            {
              name: "ABULALAS",
            },
            {
              name: "CARILLO",
            },
            {
              name: "HAGONOY",
            },
            {
              name: "IBA",
            },
            {
              name: "IBA-IBAYO",
            },
            {
              name: "MERCADO",
            },
            {
              name: "PALAPAT",
            },
            {
              name: "PUGAD",
            },
            {
              name: "SAGRADA FAMILIA",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TAMPOK",
            },
            {
              name: "TIBAGUIN",
            },
          ],
        },
        "MALOLOS CITY": {
          name: "MALOLOS CITY",
          baranggays: [
            {
              name: "ANILAO",
            },
            {
              name: "ATLAG",
            },
            {
              name: "BABATNIN",
            },
            {
              name: "BAGNA",
            },
            {
              name: "BAGONG BAYAN",
            },
            {
              name: "BALAYONG",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANGKAL",
            },
            {
              name: "BARIHAN",
            },
            {
              name: "BULIHAN",
            },
            {
              name: "BUNGAHAN",
            },
            {
              name: "CAINGIN",
            },
            {
              name: "CALERO",
            },
            {
              name: "CALILIGAWAN",
            },
            {
              name: "CANALATE",
            },
            {
              name: "CANIOGAN",
            },
            {
              name: "CATMON",
            },
            {
              name: "COFRADIA",
            },
            {
              name: "DAKILA",
            },
            {
              name: "GUINHAWA",
            },
            {
              name: "LIGAS",
            },
            {
              name: "LIYANG",
            },
            {
              name: "LONGOS",
            },
            {
              name: "LOOK 1ST",
            },
            {
              name: "LOOK 2ND",
            },
            {
              name: "LUGAM",
            },
            {
              name: "MABOLO",
            },
            {
              name: "MALOLOS CITY",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "MASILE",
            },
            {
              name: "MATIMBO",
            },
            {
              name: "MOJON",
            },
            {
              name: "NAMAYAN",
            },
            {
              name: "NIUGAN",
            },
            {
              name: "PAMARAWAN",
            },
            {
              name: "PANASAHAN",
            },
            {
              name: "PINAGBAKAHAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTISIMA TRINIDAD",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "SANTO ROSARIO (POB.)",
            },
            {
              name: "SANTOL",
            },
            {
              name: "SUMAPANG BATA",
            },
            {
              name: "SUMAPANG MATANDA",
            },
            {
              name: "TAAL",
            },
            {
              name: "TIKAY",
            },
          ],
        },
        MARILAO: {
          name: "MARILAO",
          baranggays: [
            {
              name: "ABANGAN NORTE",
            },
            {
              name: "ABANGAN SUR",
            },
            {
              name: "IBAYO",
            },
            {
              name: "LAMBAKIN",
            },
            {
              name: "LIAS",
            },
            {
              name: "LOMA DE GATO",
            },
            {
              name: "MARILAO",
            },
            {
              name: "NAGBALON",
            },
            {
              name: "PATUBIG",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "PRENZA I",
            },
            {
              name: "PRENZA II",
            },
            {
              name: "SANTA ROSA I",
            },
            {
              name: "SANTA ROSA II",
            },
            {
              name: "SAOG",
            },
            {
              name: "TABING ILOG",
            },
          ],
        },
        "MEYCAUAYAN CITY": {
          name: "MEYCAUAYAN CITY",
          baranggays: [
            {
              name: "BAGBAGUIN",
            },
            {
              name: "BAHAY PARE",
            },
            {
              name: "BANCAL",
            },
            {
              name: "BANGA",
            },
            {
              name: "BAYUGO",
            },
            {
              name: "CAINGIN",
            },
            {
              name: "CALVARIO",
            },
            {
              name: "CAMALIG",
            },
            {
              name: "GASAK (SAINT FRANCIS)",
            },
            {
              name: "HULO",
            },
            {
              name: "IBA",
            },
            {
              name: "LANGKA",
            },
            {
              name: "LAWA",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "LIPUTAN",
            },
            {
              name: "LONGOS",
            },
            {
              name: "MALHACAN",
            },
            {
              name: "MEYCAUAYAN CITY",
            },
            {
              name: "PAJO",
            },
            {
              name: "PANDAYAN",
            },
            {
              name: "PANTOC",
            },
            {
              name: "PEREZ",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALUYSOY",
            },
            {
              name: "TUGATOG",
            },
            {
              name: "UBIHAN",
            },
            {
              name: "ZAMORA",
            },
          ],
        },
        NORZAGARAY: {
          name: "NORZAGARAY",
          baranggays: [
            {
              name: "BARAKA",
            },
            {
              name: "BIGTE",
            },
            {
              name: "BITUNGOL",
            },
            {
              name: "FRIENDSHIP VILLAGE RESOURCES",
            },
            {
              name: "MATICTIC",
            },
            {
              name: "MINUYAN",
            },
            {
              name: "NORZAGARAY",
            },
            {
              name: "PARTIDA",
            },
            {
              name: "PINAGTULAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "TIGBE",
            },
          ],
        },
        OBANDO: {
          name: "OBANDO",
          baranggays: [
            {
              name: "CATANGHALAN",
            },
            {
              name: "HULO",
            },
            {
              name: "LAWA",
            },
            {
              name: "OBANDO",
            },
            {
              name: "PACO",
            },
            {
              name: "PAG-ASA (POB.)",
            },
            {
              name: "PALIWAS",
            },
            {
              name: "PANGHULO",
            },
            {
              name: "SALAMBAO",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "TAWIRAN",
            },
          ],
        },
        PANDI: {
          name: "PANDI",
          baranggays: [
            {
              name: "BAGBAGUIN",
            },
            {
              name: "BAGONG BARRIO",
            },
            {
              name: "BAKA-BAKAHAN",
            },
            {
              name: "BUNSURAN I",
            },
            {
              name: "BUNSURAN II",
            },
            {
              name: "BUNSURAN III",
            },
            {
              name: "CACARONG BATA",
            },
            {
              name: "CACARONG MATANDA",
            },
            {
              name: "CUPANG",
            },
            {
              name: "MALIBONG BATA",
            },
            {
              name: "MALIBONG MATANDA",
            },
            {
              name: "MANATAL",
            },
            {
              name: "MAPULANG LUPA",
            },
            {
              name: "MASAGANA",
            },
            {
              name: "MASUSO",
            },
            {
              name: "PANDI",
            },
            {
              name: "PINAGKUARTELAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "REAL DE CACARONG",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SILING BATA",
            },
            {
              name: "SILING MATANDA",
            },
          ],
        },
        PAOMBONG: {
          name: "PAOMBONG",
          baranggays: [
            {
              name: "BINAKOD",
            },
            {
              name: "KAPITANGAN",
            },
            {
              name: "MALUMOT",
            },
            {
              name: "MASUKOL",
            },
            {
              name: "PAOMBONG",
            },
            {
              name: "PINALAGDAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO I",
            },
            {
              name: "SAN ISIDRO II",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
          ],
        },
        PLARIDEL: {
          name: "PLARIDEL",
          baranggays: [
            {
              name: "AGNAYA",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BANGA I",
            },
            {
              name: "BANGA II",
            },
            {
              name: "BINTOG",
            },
            {
              name: "BULIHAN",
            },
            {
              name: "CULIANIN",
            },
            {
              name: "DAMPOL",
            },
            {
              name: "LAGUNDI",
            },
            {
              name: "LALANGAN",
            },
            {
              name: "LUMANG BAYAN",
            },
            {
              name: "PARULAN",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RUEDA",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIPAT",
            },
            {
              name: "TABANG",
            },
          ],
        },
        PULILAN: {
          name: "PULILAN",
          baranggays: [
            {
              name: "BALATONG A",
            },
            {
              name: "BALATONG B",
            },
            {
              name: "CUTCOT",
            },
            {
              name: "DAMPOL I",
            },
            {
              name: "DAMPOL II-A",
            },
            {
              name: "DAMPOL II-B",
            },
            {
              name: "DULONG MALABON",
            },
            {
              name: "INAON",
            },
            {
              name: "LONGOS",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "PALTAO",
            },
            {
              name: "PENABATAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULILAN",
            },
            {
              name: "SANTA PEREGRINA",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "TAAL",
            },
            {
              name: "TABON",
            },
            {
              name: "TIBAG",
            },
            {
              name: "TINEJERO",
            },
          ],
        },
        "SAN ILDEFONSO": {
          name: "SAN ILDEFONSO",
          baranggays: [
            {
              name: "AKLE",
            },
            {
              name: "ALAGAO",
            },
            {
              name: "ANYATAM",
            },
            {
              name: "BAGONG BARRIO",
            },
            {
              name: "BASUIT",
            },
            {
              name: "BUBULONG MALAKI",
            },
            {
              name: "BUBULONG MUNTI",
            },
            {
              name: "BUHOL NA MANGGA",
            },
            {
              name: "BULUSUKAN",
            },
            {
              name: "CALASAG",
            },
            {
              name: "CALAWITAN",
            },
            {
              name: "CASALAT",
            },
            {
              name: "GABIHAN",
            },
            {
              name: "GARLANG",
            },
            {
              name: "LAPNIT",
            },
            {
              name: "MAASIM",
            },
            {
              name: "MAKAPILAPIL",
            },
            {
              name: "MALIPAMPANG",
            },
            {
              name: "MATAAS NA PARANG",
            },
            {
              name: "MATIMBUBONG",
            },
            {
              name: "NABAONG GARLANG",
            },
            {
              name: "PALAPALA",
            },
            {
              name: "PASONG BANGKAL",
            },
            {
              name: "PINAOD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULONG TAMO",
            },
            {
              name: "SAN ILDEFONSO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CATALINA BATA",
            },
            {
              name: "SANTA CATALINA MATANDA",
            },
            {
              name: "SAPANG DAYAP",
            },
            {
              name: "SAPANG PUTIK",
            },
            {
              name: "SAPANG PUTOL",
            },
            {
              name: "SUMANDIG",
            },
            {
              name: "TELEPATIO",
            },
            {
              name: "UMPUCAN",
            },
            {
              name: "UPIG",
            },
          ],
        },
        "SAN JOSE DEL MONTE CITY": {
          name: "SAN JOSE DEL MONTE CITY",
          baranggays: [
            {
              name: "ASSUMPTION",
            },
            {
              name: "BAGONG BUHAY",
            },
            {
              name: "BAGONG BUHAY II",
            },
            {
              name: "BAGONG BUHAY III",
            },
            {
              name: "CITRUS",
            },
            {
              name: "CIUDAD REAL",
            },
            {
              name: "DULONG BAYAN",
            },
            {
              name: "FATIMA",
            },
            {
              name: "FATIMA II",
            },
            {
              name: "FATIMA III",
            },
            {
              name: "FATIMA IV",
            },
            {
              name: "FATIMA V",
            },
            {
              name: "FRANCISCO HOMES-GUIJO",
            },
            {
              name: "FRANCISCO HOMES-MULAWIN",
            },
            {
              name: "FRANCISCO HOMES-NARRA",
            },
            {
              name: "FRANCISCO HOMES-YAKAL",
            },
            {
              name: "GAYA-GAYA",
            },
            {
              name: "GRACEVILLE",
            },
            {
              name: "GUMAOC CENTRAL",
            },
            {
              name: "GUMAOC EAST",
            },
            {
              name: "GUMAOC WEST",
            },
            {
              name: "KAYBANBAN",
            },
            {
              name: "KAYPIAN",
            },
            {
              name: "LAWANG PARI",
            },
            {
              name: "MAHARLIKA",
            },
            {
              name: "MINUYAN",
            },
            {
              name: "MINUYAN II",
            },
            {
              name: "MINUYAN III",
            },
            {
              name: "MINUYAN IV",
            },
            {
              name: "MINUYAN PROPER",
            },
            {
              name: "MINUYAN V",
            },
            {
              name: "MUZON",
            },
            {
              name: "PARADISE III",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE DEL MONTE CITY",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SAN MARTIN II",
            },
            {
              name: "SAN MARTIN III",
            },
            {
              name: "SAN MARTIN IV",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAFAEL I",
            },
            {
              name: "SAN RAFAEL III",
            },
            {
              name: "SAN RAFAEL IV",
            },
            {
              name: "SAN RAFAEL V",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA CRUZ II",
            },
            {
              name: "SANTA CRUZ III",
            },
            {
              name: "SANTA CRUZ IV",
            },
            {
              name: "SANTA CRUZ V",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO NIÑO II",
            },
            {
              name: "SAPANG PALAY",
            },
            {
              name: "ST. MARTIN DE PORRES",
            },
            {
              name: "TUNGKONG MANGGA",
            },
          ],
        },
        "SAN MIGUEL": {
          name: "SAN MIGUEL",
          baranggays: [
            {
              name: "BAGONG PAG-ASA",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BALAONG",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANTOG",
            },
            {
              name: "BARDIAS",
            },
            {
              name: "BARITAN",
            },
            {
              name: "BATASAN BATA",
            },
            {
              name: "BATASAN MATANDA",
            },
            {
              name: "BIAK-NA-BATO",
            },
            {
              name: "BICLAT",
            },
            {
              name: "BUGA",
            },
            {
              name: "BULIRAN",
            },
            {
              name: "BULUALTO",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CAMBIO",
            },
            {
              name: "CAMIAS",
            },
            {
              name: "ILOG-BULO",
            },
            {
              name: "KING KABAYO",
            },
            {
              name: "LABNE",
            },
            {
              name: "LAMBAKIN",
            },
            {
              name: "MAGMARALE",
            },
            {
              name: "MALIBAY",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MANDILE",
            },
            {
              name: "MASALIPIT",
            },
            {
              name: "PACALAG",
            },
            {
              name: "PALIWASAN",
            },
            {
              name: "PARTIDA",
            },
            {
              name: "PINAMBARAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULONG BAYABAS",
            },
            {
              name: "PULONG DUHAT",
            },
            {
              name: "SACDALAN",
            },
            {
              name: "SALACOT",
            },
            {
              name: "SALANGAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA RITA BATA",
            },
            {
              name: "SANTA RITA MATANDA",
            },
            {
              name: "SAPANG",
            },
            {
              name: "SIBUL",
            },
            {
              name: "TARTARO",
            },
            {
              name: "TIBAGAN",
            },
            {
              name: "TIGPALAS",
            },
          ],
        },
        "SAN RAFAEL": {
          name: "SAN RAFAEL",
          baranggays: [
            {
              name: "BANCA-BANCA",
            },
            {
              name: "BMA-BALAGTAS",
            },
            {
              name: "CAINGIN",
            },
            {
              name: "CAPIHAN",
            },
            {
              name: "CORAL NA BATO",
            },
            {
              name: "CRUZ NA DAAN",
            },
            {
              name: "DAGAT-DAGATAN",
            },
            {
              name: "DILIMAN I",
            },
            {
              name: "DILIMAN II",
            },
            {
              name: "LIBIS",
            },
            {
              name: "LICO",
            },
            {
              name: "MAASIM",
            },
            {
              name: "MABALAS-BALAS",
            },
            {
              name: "MAGUINAO",
            },
            {
              name: "MARONGUILLO",
            },
            {
              name: "PACO",
            },
            {
              name: "PANSUMALOC",
            },
            {
              name: "PANTUBIG",
            },
            {
              name: "PASONG BANGKAL",
            },
            {
              name: "PASONG CALLOS",
            },
            {
              name: "PASONG INTSIK",
            },
            {
              name: "PINACPINACAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULO",
            },
            {
              name: "PULONG BAYABAS",
            },
            {
              name: "SALAPUNGAN",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAPANG PAHALANG",
            },
            {
              name: "TALACSAN",
            },
            {
              name: "TAMBUBONG",
            },
            {
              name: "TUKOD",
            },
            {
              name: "ULINGAO",
            },
          ],
        },
        "SANTA MARIA": {
          name: "SANTA MARIA",
          baranggays: [
            {
              name: "BAGBAGUIN",
            },
            {
              name: "BALASING",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULAC",
            },
            {
              name: "CAMANGYANAN",
            },
            {
              name: "CATMON",
            },
            {
              name: "CAY POMBO",
            },
            {
              name: "CAYSIO",
            },
            {
              name: "GUYONG",
            },
            {
              name: "LALAKHAN",
            },
            {
              name: "MAG-ASAWANG SAPA",
            },
            {
              name: "MAHABANG PARANG",
            },
            {
              name: "MANGGAHAN",
            },
            {
              name: "PARADA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULONG BUHANGIN",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN JOSE PATAG",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SILANGAN",
            },
            {
              name: "TABING BAKOD",
            },
            {
              name: "TUMANA",
            },
          ],
        },
      },
    },
    CAGAYAN: {
      name: "CAGAYAN",
      region: "REGION 2",
      municipalities: {
        ABULUG: {
          name: "ABULUG",
          baranggays: [
            {
              name: "ABULUG",
            },
            {
              name: "ALINUNU",
            },
            {
              name: "BAGU",
            },
            {
              name: "BANGUIAN",
            },
            {
              name: "CALOG NORTE",
            },
            {
              name: "CALOG SUR",
            },
            {
              name: "CANAYUN",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "DANA-ILI",
            },
            {
              name: "GUIDDAM",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LUCBAN",
            },
            {
              name: "PINILI",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SIGUIRAN",
            },
            {
              name: "SIMAYUNG",
            },
            {
              name: "SIRIT",
            },
          ],
        },
        ALCALA: {
          name: "ALCALA",
          baranggays: [
            {
              name: "ABBEG",
            },
            {
              name: "AFUSING BATO",
            },
            {
              name: "AFUSING DAGA",
            },
            {
              name: "AGANI",
            },
            {
              name: "ALCALA",
            },
            {
              name: "BACULOD",
            },
            {
              name: "BAYBAYOG",
            },
            {
              name: "CABULUAN",
            },
            {
              name: "CALANTAC",
            },
            {
              name: "CARALLANGAN",
            },
            {
              name: "CENTRO NORTE (POB.)",
            },
            {
              name: "CENTRO SUR (POB.)",
            },
            {
              name: "DALAOIG",
            },
            {
              name: "DAMUROG",
            },
            {
              name: "MALALATAN",
            },
            {
              name: "MARABURAB",
            },
            {
              name: "MASIN",
            },
            {
              name: "PAGBANGKERUAN",
            },
            {
              name: "PARED",
            },
            {
              name: "PIGGATAN",
            },
            {
              name: "PINOPOC",
            },
            {
              name: "PUSSIAN",
            },
            {
              name: "SAN ESTEBAN",
            },
            {
              name: "TAMBAN",
            },
            {
              name: "TUPANG",
            },
          ],
        },
        ALLACAPAN: {
          name: "ALLACAPAN",
          baranggays: [
            {
              name: "ALLACAPAN",
            },
            {
              name: "BESSANG",
            },
            {
              name: "BINOBONGAN",
            },
            {
              name: "BULO",
            },
            {
              name: "BUROT",
            },
            {
              name: "CAPAGARAN (BRIGIDA)",
            },
            {
              name: "CAPALUTAN",
            },
            {
              name: "CAPANICKIAN NORTE",
            },
            {
              name: "CAPANICKIAN SUR",
            },
            {
              name: "CATARATAN",
            },
            {
              name: "CENTRO EAST (POB.)",
            },
            {
              name: "CENTRO WEST (POB.)",
            },
            {
              name: "DAAN-ILI",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "DALAYAP",
            },
            {
              name: "GAGADDANGAN",
            },
            {
              name: "IRINGAN",
            },
            {
              name: "LABBEN",
            },
            {
              name: "MALUYO",
            },
            {
              name: "MAPURAO",
            },
            {
              name: "MATUCAY",
            },
            {
              name: "NAGATTATAN",
            },
            {
              name: "PACAC",
            },
            {
              name: "SAN JUAN (MAGUININANGO)",
            },
            {
              name: "SILANGAN",
            },
            {
              name: "TAMBOLI",
            },
            {
              name: "TUBEL",
            },
            {
              name: "UTAN",
            },
          ],
        },
        AMULUNG: {
          name: "AMULUNG",
          baranggays: [
            {
              name: "ABOLO",
            },
            {
              name: "AGGUIRIT",
            },
            {
              name: "ALITUNGTUNG",
            },
            {
              name: "AMULUNG",
            },
            {
              name: "ANNABUCULAN",
            },
            {
              name: "ANNAFATAN",
            },
            {
              name: "ANQUIRAY",
            },
            {
              name: "BABAYUAN",
            },
            {
              name: "BACCUIT",
            },
            {
              name: "BACRING",
            },
            {
              name: "BACULUD",
            },
            {
              name: "BALAUINI",
            },
            {
              name: "BAUAN",
            },
            {
              name: "BAYABAT",
            },
            {
              name: "CALAMAGUI",
            },
            {
              name: "CALINTAAN",
            },
            {
              name: "CARATACAT",
            },
            {
              name: "CASINGSINGAN NORTE",
            },
            {
              name: "CASINGSINGAN SUR",
            },
            {
              name: "CATARAUAN",
            },
            {
              name: "CENTRO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CORDOVA",
            },
            {
              name: "DADDA",
            },
            {
              name: "DAFUNGANAY",
            },
            {
              name: "DUGAYUNG",
            },
            {
              name: "ESTEFANIA",
            },
            {
              name: "GABUT",
            },
            {
              name: "GANGAUAN",
            },
            {
              name: "GORAN",
            },
            {
              name: "JURISDICCION",
            },
            {
              name: "LA SUERTE",
            },
            {
              name: "LOGUNG",
            },
            {
              name: "MAGOGOD",
            },
            {
              name: "MANALO",
            },
            {
              name: "MAROBBOB",
            },
            {
              name: "MASICAL",
            },
            {
              name: "MONTE ALEGRE",
            },
            {
              name: "NABBIALAN",
            },
            {
              name: "NAGSABARAN",
            },
            {
              name: "NANGALASAUAN",
            },
            {
              name: "NANUCCAUAN",
            },
            {
              name: "PACAC-GRANDE",
            },
            {
              name: "PACAC-PEQUEÑO",
            },
            {
              name: "PALACU",
            },
            {
              name: "PALAYAG",
            },
            {
              name: "TANA",
            },
            {
              name: "UNAG",
            },
          ],
        },
        APARRI: {
          name: "APARRI",
          baranggays: [
            {
              name: "APARRI",
            },
            {
              name: "BACKILING",
            },
            {
              name: "BANGAG",
            },
            {
              name: "BINALAN",
            },
            {
              name: "BISAGU",
            },
            {
              name: "BUKIG",
            },
            {
              name: "BULALA NORTE",
            },
            {
              name: "BULALA SUR",
            },
            {
              name: "CAAGAMAN",
            },
            {
              name: "CENTRO 1 (POB.)",
            },
            {
              name: "CENTRO 10 (POB.)",
            },
            {
              name: "CENTRO 11 (POB.)",
            },
            {
              name: "CENTRO 12 (POB.)",
            },
            {
              name: "CENTRO 13 (POB.)",
            },
            {
              name: "CENTRO 14 (POB.)",
            },
            {
              name: "CENTRO 15 (POB.)",
            },
            {
              name: "CENTRO 2 (POB.)",
            },
            {
              name: "CENTRO 3 (POB.)",
            },
            {
              name: "CENTRO 4 (POB.)",
            },
            {
              name: "CENTRO 5 (POB.)",
            },
            {
              name: "CENTRO 6 (POB.)",
            },
            {
              name: "CENTRO 7 (POB.)",
            },
            {
              name: "CENTRO 8 (POB.)",
            },
            {
              name: "CENTRO 9 (POB.)",
            },
            {
              name: "DODAN",
            },
            {
              name: "FUGA ISLAND",
            },
            {
              name: "GADDANG",
            },
            {
              name: "LINAO",
            },
            {
              name: "MABANGUC",
            },
            {
              name: "MACANAYA (PESCARIA)",
            },
            {
              name: "MAURA",
            },
            {
              name: "MINANGA",
            },
            {
              name: "NAVAGAN",
            },
            {
              name: "PADDAYA",
            },
            {
              name: "PARUDDUN NORTE",
            },
            {
              name: "PARUDDUN SUR",
            },
            {
              name: "PLAZA",
            },
            {
              name: "PUNTA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SANJA",
            },
            {
              name: "TALLUNGAN",
            },
            {
              name: "TORAN",
            },
            {
              name: "ZINARAG",
            },
          ],
        },
        BAGGAO: {
          name: "BAGGAO",
          baranggays: [
            {
              name: "ADAOAG",
            },
            {
              name: "AGAMAN (PROPER)",
            },
            {
              name: "AGAMAN NORTE",
            },
            {
              name: "AGAMAN SUR",
            },
            {
              name: "ALBA",
            },
            {
              name: "ANNAYATAN",
            },
            {
              name: "ASASSI",
            },
            {
              name: "ASINGA-VIA",
            },
            {
              name: "AWALLAN",
            },
            {
              name: "BACAGAN",
            },
            {
              name: "BAGGAO",
            },
            {
              name: "BAGUNOT",
            },
            {
              name: "BARSAT EAST",
            },
            {
              name: "BARSAT WEST",
            },
            {
              name: "BITAG GRANDE",
            },
            {
              name: "BITAG PEQUEÑO",
            },
            {
              name: "BUNUGAN",
            },
            {
              name: "C. VERZOSA (VALLEY COVE)",
            },
            {
              name: "CANAGATAN",
            },
            {
              name: "CARUPIAN",
            },
            {
              name: "CATUGAY",
            },
            {
              name: "DABBAC GRANDE",
            },
            {
              name: "DALIN",
            },
            {
              name: "DALLA",
            },
            {
              name: "HACIENDA INTAL",
            },
            {
              name: "IBULO",
            },
            {
              name: "IMURONG",
            },
            {
              name: "J. PALLAGAO",
            },
            {
              name: "LASILAT",
            },
            {
              name: "MABINI",
            },
            {
              name: "MASICAL",
            },
            {
              name: "MOCAG",
            },
            {
              name: "NANGALINAN",
            },
            {
              name: "POBLACION (CENTRO)",
            },
            {
              name: "REMUS",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MARGARITA",
            },
            {
              name: "SANTOR",
            },
            {
              name: "TAGUING",
            },
            {
              name: "TAGUNTUNGAN",
            },
            {
              name: "TALLANG",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TEMBLIQUE",
            },
            {
              name: "TUNGEL",
            },
          ],
        },
        BALLESTEROS: {
          name: "BALLESTEROS",
          baranggays: [
            {
              name: "AMMUBUAN",
            },
            {
              name: "BALLESTEROS",
            },
            {
              name: "BARAN",
            },
            {
              name: "CABARITAN EAST",
            },
            {
              name: "CABARITAN WEST",
            },
            {
              name: "CABAYU",
            },
            {
              name: "CABULUAN EAST",
            },
            {
              name: "CABULUAN WEST",
            },
            {
              name: "CENTRO EAST (POB.)",
            },
            {
              name: "CENTRO WEST (POB.)",
            },
            {
              name: "FUGU",
            },
            {
              name: "MABUTTAL EAST",
            },
            {
              name: "MABUTTAL WEST",
            },
            {
              name: "NARARAGAN",
            },
            {
              name: "PALLOC",
            },
            {
              name: "PAYAGAN EAST",
            },
            {
              name: "PAYAGAN WEST",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "ZITANGA",
            },
          ],
        },
        BUGUEY: {
          name: "BUGUEY",
          baranggays: [
            {
              name: "ALUCAO WESTE (SAN LORENZO*)",
            },
            {
              name: "ANTIPORDA",
            },
            {
              name: "BALLANG",
            },
            {
              name: "BALZA",
            },
            {
              name: "BUGUEY",
            },
            {
              name: "CABARITAN",
            },
            {
              name: "CALAMEGATAN",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "CENTRO WEST",
            },
            {
              name: "DALAYA",
            },
            {
              name: "FULA",
            },
            {
              name: "LERON",
            },
            {
              name: "MADDALERO",
            },
            {
              name: "MALA ESTE",
            },
            {
              name: "MALA WESTE",
            },
            {
              name: "MINANGA ESTE",
            },
            {
              name: "MINANGA WESTE",
            },
            {
              name: "PADDAYA ESTE",
            },
            {
              name: "PADDAYA WESTE",
            },
            {
              name: "PATTAO",
            },
            {
              name: "QUINAWEGAN",
            },
            {
              name: "REMEBELLA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "TABBAC",
            },
            {
              name: "VILLA CIELO",
            },
            {
              name: "VILLA GRACIA",
            },
            {
              name: "VILLA LEONORA",
            },
          ],
        },
        CALAYAN: {
          name: "CALAYAN",
          baranggays: [
            {
              name: "BABUYAN CLARO",
            },
            {
              name: "BALATUBAT",
            },
            {
              name: "CABUDADAN",
            },
            {
              name: "CALAYAN",
            },
            {
              name: "CENTRO II",
            },
            {
              name: "DADAO",
            },
            {
              name: "DALUPIRI",
            },
            {
              name: "DIBAY",
            },
            {
              name: "DILAM",
            },
            {
              name: "MAGSIDEL",
            },
            {
              name: "MINABEL",
            },
            {
              name: "NAGUILIAN",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        CAMALANIUGAN: {
          name: "CAMALANIUGAN",
          baranggays: [
            {
              name: "ABAGAO",
            },
            {
              name: "AFUNAN CABAYU",
            },
            {
              name: "AGUSI",
            },
            {
              name: "ALILINU",
            },
            {
              name: "BAGGAO",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BULALA",
            },
            {
              name: "CAMALANIUGAN",
            },
            {
              name: "CASILI NORTE",
            },
            {
              name: "CASILI SUR",
            },
            {
              name: "CATOTORAN NORTE",
            },
            {
              name: "CATOTORAN SUR",
            },
            {
              name: "CENTRO NORTE (POB.)",
            },
            {
              name: "CENTRO SUR (POB.)",
            },
            {
              name: "CULLIT",
            },
            {
              name: "DACAL-LAFUGU",
            },
            {
              name: "DAMMANG NORTE (JOAQUIN DE LA CRUZ*)",
            },
            {
              name: "DAMMANG SUR (FELIPE TUZON*)",
            },
            {
              name: "DUGO",
            },
            {
              name: "FUSINA",
            },
            {
              name: "GANG-NGO",
            },
            {
              name: "JURISDICTION",
            },
            {
              name: "LUEC",
            },
            {
              name: "MINANGA",
            },
            {
              name: "PARAGAT",
            },
            {
              name: "SAPPING",
            },
            {
              name: "TAGUM",
            },
            {
              name: "TULUTTUGING",
            },
            {
              name: "ZIMINILA",
            },
          ],
        },
        CLAVERIA: {
          name: "CLAVERIA",
          baranggays: [
            {
              name: "ALIMOAN",
            },
            {
              name: "BACSAY CATARAOAN NORTE",
            },
            {
              name: "BACSAY CATARAOAN SUR",
            },
            {
              name: "BACSAY MAPULAPULA",
            },
            {
              name: "BILIBIGAO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CADCADIR EAST",
            },
            {
              name: "CADCADIR WEST",
            },
            {
              name: "CAMALAGGOAN/D LEAÑO",
            },
            {
              name: "CAPANIKIAN",
            },
            {
              name: "CENTRO I (POB.)",
            },
            {
              name: "CENTRO II (POB.)",
            },
            {
              name: "CENTRO III",
            },
            {
              name: "CENTRO IV (NANGASANGAN)",
            },
            {
              name: "CENTRO V (MINA)",
            },
            {
              name: "CENTRO VI (MINANGA)",
            },
            {
              name: "CENTRO VII (MALASIN EAST)",
            },
            {
              name: "CENTRO VIII (MALASIN WEST)",
            },
            {
              name: "CLAVERIA",
            },
            {
              name: "CULAO",
            },
            {
              name: "DIBALIO",
            },
            {
              name: "KILKILING",
            },
            {
              name: "LABLABIG",
            },
            {
              name: "LUZON",
            },
            {
              name: "MABNANG",
            },
            {
              name: "MAGDALENA",
            },
            {
              name: "MALILITAO",
            },
            {
              name: "NAGSABARAN",
            },
            {
              name: "PATA EAST",
            },
            {
              name: "PATA WEST",
            },
            {
              name: "PINAS",
            },
            {
              name: "SAN ANTONIO (SAYAD*/BIMEKEL*)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MARIA (SURNGOT)",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO NIÑO (BARBARNIS)",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TABBUGAN",
            },
            {
              name: "TAGGAT NORTE",
            },
            {
              name: "TAGGAT SUR",
            },
            {
              name: "UNION",
            },
          ],
        },
        ENRILE: {
          name: "ENRILE",
          baranggays: [
            {
              name: "ALIBAGO (VILLA MARIA)",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (SAN ROQUE)",
            },
            {
              name: "BARANGAY III-A",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BATU",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "ENRILE",
            },
            {
              name: "INGA",
            },
            {
              name: "LANNA",
            },
            {
              name: "LEMU NORTE",
            },
            {
              name: "LEMU SUR",
            },
            {
              name: "LIWAN NORTE",
            },
            {
              name: "LIWAN SUR",
            },
            {
              name: "MADDARULUG NORTE",
            },
            {
              name: "MADDARULUG SUR",
            },
            {
              name: "MAGALALAG EAST",
            },
            {
              name: "MAGALALAG WEST (SAN NICOLAS*)",
            },
            {
              name: "MARACURU",
            },
            {
              name: "ROMA NORTE",
            },
            {
              name: "ROMA SUR",
            },
            {
              name: "SAN ANTONIO",
            },
          ],
        },
        GATTARAN: {
          name: "GATTARAN",
          baranggays: [
            {
              name: "ABRA",
            },
            {
              name: "AGUIGUICAN",
            },
            {
              name: "BANGATAN NGAGAN",
            },
            {
              name: "BARACAOIT",
            },
            {
              name: "BARAOIDAN",
            },
            {
              name: "BARBARIT",
            },
            {
              name: "BASAO",
            },
            {
              name: "BOLOS POINT",
            },
            {
              name: "CABAYU",
            },
            {
              name: "CALAOAGAN BASSIT",
            },
            {
              name: "CALAOAGAN DACKEL",
            },
            {
              name: "CAPIDDIGAN",
            },
            {
              name: "CAPISSAYAN NORTE",
            },
            {
              name: "CAPISSAYAN SUR",
            },
            {
              name: "CASICALLAN NORTE",
            },
            {
              name: "CASICALLAN SUR",
            },
            {
              name: "CENTRO NORTE (POB.)",
            },
            {
              name: "CENTRO SUR (POB.)",
            },
            {
              name: "CULLIT",
            },
            {
              name: "CUMAO",
            },
            {
              name: "CUNIG",
            },
            {
              name: "DUMMUN",
            },
            {
              name: "FUGU",
            },
            {
              name: "GANZANO",
            },
            {
              name: "GATTARAN",
            },
            {
              name: "GUISING",
            },
            {
              name: "L. ADVIENTO",
            },
            {
              name: "LANGGAN",
            },
            {
              name: "LAPOGAN",
            },
            {
              name: "MABUNO",
            },
            {
              name: "NABACCAYAN",
            },
            {
              name: "NADDUNGAN",
            },
            {
              name: "NAGATUTUAN",
            },
            {
              name: "NASSIPING",
            },
            {
              name: "NEWAGAC",
            },
            {
              name: "PALAGAO NORTE",
            },
            {
              name: "PALAGAO SUR",
            },
            {
              name: "PIÑA ESTE",
            },
            {
              name: "PIÑA WESTE",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SIDEM",
            },
            {
              name: "T. ELIZAGA (MABIRBIRA)",
            },
            {
              name: "TAGUMAY",
            },
            {
              name: "TAKIKI",
            },
            {
              name: "TALIGAN",
            },
            {
              name: "TANGLAGAN",
            },
            {
              name: "TUBUNGAN ESTE",
            },
            {
              name: "TUBUNGAN WESTE",
            },
          ],
        },
        GONZAGA: {
          name: "GONZAGA",
          baranggays: [
            {
              name: "AMUNITAN",
            },
            {
              name: "BATANGAN",
            },
            {
              name: "BAUA",
            },
            {
              name: "CABANBANAN NORTE",
            },
            {
              name: "CABANBANAN SUR",
            },
            {
              name: "CABIRAOAN",
            },
            {
              name: "CALAYAN",
            },
            {
              name: "CALLAO",
            },
            {
              name: "CAROAN",
            },
            {
              name: "CASITAN",
            },
            {
              name: "FLOURISHING (POB.)",
            },
            {
              name: "GONZAGA",
            },
            {
              name: "IPIL",
            },
            {
              name: "ISCA",
            },
            {
              name: "MAGRAFIL",
            },
            {
              name: "MINANGA",
            },
            {
              name: "PARADISE (POB.)",
            },
            {
              name: "PATENG",
            },
            {
              name: "PROGRESSIVE (POB.)",
            },
            {
              name: "REBECCA (NAGBABALACAN*)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SMART (POB.)",
            },
            {
              name: "TAPEL",
            },
          ],
        },
        IGUIG: {
          name: "IGUIG",
          baranggays: [
            {
              name: "AJAT (POB.)",
            },
            {
              name: "ATULU",
            },
            {
              name: "BACULUD",
            },
            {
              name: "BAYO",
            },
            {
              name: "CAMPO",
            },
            {
              name: "DUMPAO",
            },
            {
              name: "GAMMAD",
            },
            {
              name: "GARAB",
            },
            {
              name: "IGUIG",
            },
            {
              name: "MALABBAC",
            },
            {
              name: "MANAOAG (AQUILIQUILAO)",
            },
            {
              name: "MINANGA NORTE",
            },
            {
              name: "MINANGA SUR",
            },
            {
              name: "NATTANZAN (POB.)",
            },
            {
              name: "REDONDO",
            },
            {
              name: "SALAMAGUE",
            },
            {
              name: "SAN ESTEBAN (CAPITAN)",
            },
            {
              name: "SAN ISIDRO (UGAC WEST)",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN VICENTE (UGAC EAST)",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTA TERESA (GAMMAD SUR)",
            },
            {
              name: "SANTIAGO",
            },
          ],
        },
        "LAL-LO": {
          name: "LAL-LO",
          baranggays: [
            {
              name: "ABAGAO",
            },
            {
              name: "ALAGUIA",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BANGAG",
            },
            {
              name: "BICAL",
            },
            {
              name: "BICUD",
            },
            {
              name: "BINAG",
            },
            {
              name: "CABAYABASAN (CAPACUAN)",
            },
            {
              name: "CAGORAN",
            },
            {
              name: "CAMBONG",
            },
            {
              name: "CATAYAUAN",
            },
            {
              name: "CATUGAN",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "CULLIT",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "DALAYA",
            },
            {
              name: "FABRICA",
            },
            {
              name: "FUSINA",
            },
            {
              name: "JURISDICTION",
            },
            {
              name: "LAL-LO",
            },
            {
              name: "LALAFUGAN",
            },
            {
              name: "LOGAC",
            },
            {
              name: "MAGAPIT",
            },
            {
              name: "MALANAO",
            },
            {
              name: "MAXINGAL",
            },
            {
              name: "NAGUILIAN",
            },
            {
              name: "PARANUM",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAN ANTONIO (LAFU)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SANTA TERESA (MAGALLUNGON)",
            },
            {
              name: "TUCALANA",
            },
          ],
        },
        LASAM: {
          name: "LASAM",
          baranggays: [
            {
              name: "AGGUNETAN",
            },
            {
              name: "ALANNAY",
            },
            {
              name: "BATTALAN",
            },
            {
              name: "CABATACAN EAST (DULDUGAN*)",
            },
            {
              name: "CABATACAN WEST",
            },
            {
              name: "CALAPANGAN NORTE",
            },
            {
              name: "CALAPANGAN SUR",
            },
            {
              name: "CALLAO NORTE",
            },
            {
              name: "CALLAO SUR",
            },
            {
              name: "CATALIGANAN",
            },
            {
              name: "CENTRO I (POB.)",
            },
            {
              name: "CENTRO II (POB.)",
            },
            {
              name: "CENTRO III (POB.)",
            },
            {
              name: "FINUGO NORTE",
            },
            {
              name: "GABUN",
            },
            {
              name: "IGNACIO JURADO (FINUGU SUR)",
            },
            {
              name: "LASAM",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALINTA",
            },
            {
              name: "MINANGA NORTE",
            },
            {
              name: "MINANGA SUR",
            },
            {
              name: "NABANNAGAN EAST",
            },
            {
              name: "NABANNAGAN WEST",
            },
            {
              name: "NEW ORLINS",
            },
            {
              name: "NICOLAS AGATEP",
            },
            {
              name: "PERU",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SICALAO",
            },
            {
              name: "TAGAO",
            },
            {
              name: "TUCALAN PASSING",
            },
            {
              name: "VIGA",
            },
          ],
        },
        PAMPLONA: {
          name: "PAMPLONA",
          baranggays: [
            {
              name: "ABANQUERUAN",
            },
            {
              name: "ALLASITAN",
            },
            {
              name: "BAGU",
            },
            {
              name: "BALINGIT",
            },
            {
              name: "BIDDUANG",
            },
            {
              name: "CABAGGAN",
            },
            {
              name: "CAPALALIAN",
            },
            {
              name: "CASITAN",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "CURVA",
            },
            {
              name: "GATTU",
            },
            {
              name: "MASI",
            },
            {
              name: "NAGATTATAN",
            },
            {
              name: "NAGTUPACAN",
            },
            {
              name: "PAMPLONA",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TABBA",
            },
            {
              name: "TUPANNA",
            },
          ],
        },
        PEÑABLANCA: {
          name: "PEÑABLANCA",
          baranggays: [
            {
              name: "AGGUGADDAN",
            },
            {
              name: "ALIMANAO",
            },
            {
              name: "BALIUAG",
            },
            {
              name: "BICAL",
            },
            {
              name: "BUGATAY",
            },
            {
              name: "BUYUN",
            },
            {
              name: "CABASAN",
            },
            {
              name: "CABBO",
            },
            {
              name: "CALLAO",
            },
            {
              name: "CAMASI",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "DODAN",
            },
            {
              name: "LAPI",
            },
            {
              name: "MALIBABAG",
            },
            {
              name: "MANGA",
            },
            {
              name: "MINANGA",
            },
            {
              name: "NABBABALAYAN",
            },
            {
              name: "NANGUILATTAN",
            },
            {
              name: "NANNARIAN",
            },
            {
              name: "PARABBA",
            },
            {
              name: "PATAGUELEG",
            },
            {
              name: "PEÑABLANCA",
            },
            {
              name: "QUIBAL",
            },
            {
              name: "SAN ROQUE (LITTO)",
            },
            {
              name: "SISIM",
            },
          ],
        },
        PIAT: {
          name: "PIAT",
          baranggays: [
            {
              name: "APAYAO",
            },
            {
              name: "AQUIB",
            },
            {
              name: "BAUNG",
            },
            {
              name: "CALAOAGAN",
            },
            {
              name: "CATARAUAN",
            },
            {
              name: "DUGAYUNG",
            },
            {
              name: "GUMARUENG",
            },
            {
              name: "MACAPIL",
            },
            {
              name: "MAGUILLING",
            },
            {
              name: "MINANGA",
            },
            {
              name: "PIAT",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SICATNA",
            },
            {
              name: "VILLA REY (SAN GASPAR*)",
            },
            {
              name: "VILLA REYNO",
            },
            {
              name: "WARAT",
            },
          ],
        },
        RIZAL: {
          name: "RIZAL",
          baranggays: [
            {
              name: "ANAGGUAN",
            },
            {
              name: "ANUNGU",
            },
            {
              name: "ANURTURU",
            },
            {
              name: "BALUNGCANAG",
            },
            {
              name: "BATTUT",
            },
            {
              name: "BATU",
            },
            {
              name: "BURAL (ZINUNDUNGAN)",
            },
            {
              name: "CAMBABANGAN",
            },
            {
              name: "CAPACUAN",
            },
            {
              name: "DUNGGAN",
            },
            {
              name: "DUYUN",
            },
            {
              name: "GADDANGAO",
            },
            {
              name: "GAGGABUTAN EAST",
            },
            {
              name: "GAGGABUTAN WEST",
            },
            {
              name: "ILLURU NORTE",
            },
            {
              name: "ILLURU SUR",
            },
            {
              name: "LATTUT",
            },
            {
              name: "LINNO (VILLA CRUZ*)",
            },
            {
              name: "LIWAN",
            },
            {
              name: "MABBANG",
            },
            {
              name: "MASI (ZINUNDUNGAN)",
            },
            {
              name: "MAUANAN",
            },
            {
              name: "MINANGA",
            },
            {
              name: "NANAUATAN",
            },
            {
              name: "NANUNGARAN",
            },
            {
              name: "PASINGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN JUAN (ZINUNDUNGAN)",
            },
            {
              name: "SINICKING",
            },
          ],
        },
        "SANCHEZ-MIRA": {
          name: "SANCHEZ-MIRA",
          baranggays: [
            {
              name: "BANGAN",
            },
            {
              name: "CALLUNGAN",
            },
            {
              name: "CENTRO I (POB.)",
            },
            {
              name: "CENTRO II (POB.)",
            },
            {
              name: "DACAL",
            },
            {
              name: "DAGUERAY",
            },
            {
              name: "DAMMANG",
            },
            {
              name: "KITTAG",
            },
            {
              name: "LANGAGAN",
            },
            {
              name: "MAGACAN",
            },
            {
              name: "MARZAN",
            },
            {
              name: "MASISIT",
            },
            {
              name: "NAGRANGTAYAN",
            },
            {
              name: "NAMUAC",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SANCHEZ-MIRA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTOR",
            },
            {
              name: "TOKITOK",
            },
          ],
        },
        "SANTA ANA": {
          name: "SANTA ANA",
          baranggays: [
            {
              name: "BATU-PARADA",
            },
            {
              name: "CASAGAN",
            },
            {
              name: "CASAMBALANGAN (PORT IRENE)",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "DIORA-ZINUNGAN",
            },
            {
              name: "DUNGEG",
            },
            {
              name: "KAPANIKIAN",
            },
            {
              name: "MAREDE",
            },
            {
              name: "PALAWIG",
            },
            {
              name: "PATUNUNGAN",
            },
            {
              name: "RAPULI (PUNTI)",
            },
            {
              name: "SAN VICENTE (FORT)",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TANGATAN",
            },
            {
              name: "VISITACION (POB.)",
            },
          ],
        },
        "SANTA PRAXEDES": {
          name: "SANTA PRAXEDES",
          baranggays: [
            {
              name: "CADONGDONGAN",
            },
            {
              name: "CAPACUAN",
            },
            {
              name: "CENTRO I (POB.)",
            },
            {
              name: "CENTRO II (POB.)",
            },
            {
              name: "MACATEL",
            },
            {
              name: "PORTABAGA",
            },
            {
              name: "SALUNGSONG",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA PRAXEDES",
            },
            {
              name: "SICUL",
            },
          ],
        },
        "SANTA TERESITA": {
          name: "SANTA TERESITA",
          baranggays: [
            {
              name: "ALUCAO",
            },
            {
              name: "ARIDAWEN",
            },
            {
              name: "BUYUN",
            },
            {
              name: "CANIUGAN",
            },
            {
              name: "CENTRO EAST (POB.)",
            },
            {
              name: "CENTRO WEST",
            },
            {
              name: "DUNGEG",
            },
            {
              name: "LUGA",
            },
            {
              name: "MASI",
            },
            {
              name: "MISSION",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SIMBALUCA",
            },
            {
              name: "SIMPATUYO",
            },
            {
              name: "VILLA",
            },
          ],
        },
        "SANTO NIÑO (FAIRE)": {
          name: "SANTO NIÑO (FAIRE)",
          baranggays: [
            {
              name: "ABARIONGAN RUAR",
            },
            {
              name: "ABARIONGAN UNEG",
            },
            {
              name: "BALAGAN",
            },
            {
              name: "BALANNI",
            },
            {
              name: "CABAYO",
            },
            {
              name: "CALAPANGAN",
            },
            {
              name: "CALASSITAN",
            },
            {
              name: "CAMPO",
            },
            {
              name: "CENTRO NORTE (POB.)",
            },
            {
              name: "CENTRO SUR (POB.)",
            },
            {
              name: "DUNGAO",
            },
            {
              name: "LATTAC",
            },
            {
              name: "LIPATAN",
            },
            {
              name: "LUBO",
            },
            {
              name: "MABITBITNONG",
            },
            {
              name: "MAPITAC",
            },
            {
              name: "MASICAL",
            },
            {
              name: "MATALAO",
            },
            {
              name: "NAG-UMA",
            },
            {
              name: "NAMUCCAYAN",
            },
            {
              name: "NIUG NORTE",
            },
            {
              name: "NIUG SUR",
            },
            {
              name: "PALUSAO",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA FELICITAS",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO NIÑO (FAIRE)",
            },
            {
              name: "SIDIRAN",
            },
            {
              name: "TABANG",
            },
            {
              name: "TAMUCCO",
            },
            {
              name: "VIRGINIA",
            },
          ],
        },
        SOLANA: {
          name: "SOLANA",
          baranggays: [
            {
              name: "ANDARAYAN NORTH",
            },
            {
              name: "ANDARAYAN SOUTH",
            },
            {
              name: "BANGAG",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BASI EAST",
            },
            {
              name: "BASI WEST",
            },
            {
              name: "BAUAN EAST",
            },
            {
              name: "BAUAN WEST",
            },
            {
              name: "CADAANAN",
            },
            {
              name: "CALAMAGUI",
            },
            {
              name: "CALILLAUAN",
            },
            {
              name: "CARILUCUD",
            },
            {
              name: "CATTARAN",
            },
            {
              name: "CENTRO NORTHEAST (POB.)",
            },
            {
              name: "CENTRO NORTHWEST (POB.)",
            },
            {
              name: "CENTRO SOUTHEAST (POB.)",
            },
            {
              name: "CENTRO SOUTHWEST (POB.)",
            },
            {
              name: "DASSUN",
            },
            {
              name: "FURAGUI",
            },
            {
              name: "GADU",
            },
            {
              name: "GEN. EULOGIO BALAO",
            },
            {
              name: "IRAGA",
            },
            {
              name: "LANNA",
            },
            {
              name: "LANNIG",
            },
            {
              name: "LINGU",
            },
            {
              name: "MADDARULUG (SANTO DOMINGO)",
            },
            {
              name: "MAGUIRIG",
            },
            {
              name: "MALALAM-MALACABIBI",
            },
            {
              name: "NABBOTUAN",
            },
            {
              name: "NANGALISAN",
            },
            {
              name: "NATAPPIAN EAST",
            },
            {
              name: "NATAPPIAN WEST",
            },
            {
              name: "PADUL",
            },
            {
              name: "PALAO",
            },
            {
              name: "PARUG-PARUG",
            },
            {
              name: "PATAYA",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SOLANA",
            },
            {
              name: "UBONG",
            },
          ],
        },
        TUAO: {
          name: "TUAO",
          baranggays: [
            {
              name: "ACCUSILIAN",
            },
            {
              name: "ALABIAO",
            },
            {
              name: "ALABUG",
            },
            {
              name: "ANGANG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALAGAO",
            },
            {
              name: "BARANCUAG",
            },
            {
              name: "BATTUNG",
            },
            {
              name: "BICOK",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "CAGUMITAN",
            },
            {
              name: "CATO",
            },
            {
              name: "CULONG",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "FUGU",
            },
            {
              name: "LAKAMBINI",
            },
            {
              name: "LALLAYUG",
            },
            {
              name: "MALALINTA",
            },
            {
              name: "MALUMIN",
            },
            {
              name: "MAMBACAG",
            },
            {
              name: "MUNGO",
            },
            {
              name: "NARUANGAN",
            },
            {
              name: "PALCA",
            },
            {
              name: "PATA",
            },
            {
              name: "POBLACION I (WARD I CENTRO)",
            },
            {
              name: "POBLACION II (WARD II CENTRO)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS (GURENGAD)",
            },
            {
              name: "SAN VICENTE (MALEG)",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TARIBUBU",
            },
            {
              name: "TUAO",
            },
            {
              name: "VILLA LAIDA",
            },
          ],
        },
        "TUGUEGARAO CITY": {
          name: "TUGUEGARAO CITY",
          baranggays: [
            {
              name: "ANNAFUNAN EAST",
            },
            {
              name: "ANNAFUNAN WEST",
            },
            {
              name: "ATULAYAN NORTE",
            },
            {
              name: "ATULAYAN SUR",
            },
            {
              name: "BAGAY",
            },
            {
              name: "BUNTUN",
            },
            {
              name: "CAGGAY",
            },
            {
              name: "CAPATAN",
            },
            {
              name: "CARIG",
            },
            {
              name: "CARITAN CENTRO",
            },
            {
              name: "CARITAN NORTE",
            },
            {
              name: "CARITAN SUR",
            },
            {
              name: "CATAGGAMAN NUEVO",
            },
            {
              name: "CATAGGAMAN PARDO",
            },
            {
              name: "CATAGGAMAN VIEJO",
            },
            {
              name: "CENTRO 1 (POB.)",
            },
            {
              name: "CENTRO 10 (POB.)",
            },
            {
              name: "CENTRO 11 (POB.)",
            },
            {
              name: "CENTRO 12 (POB.)",
            },
            {
              name: "CENTRO 2 (POB.)",
            },
            {
              name: "CENTRO 3 (POB.)",
            },
            {
              name: "CENTRO 4 (POB.)",
            },
            {
              name: "CENTRO 5 (POB.)",
            },
            {
              name: "CENTRO 6 (POB.)",
            },
            {
              name: "CENTRO 7 (POB.)",
            },
            {
              name: "CENTRO 8 (POB.)",
            },
            {
              name: "CENTRO 9 (POB.)",
            },
            {
              name: "DADDA",
            },
            {
              name: "GOSI NORTE",
            },
            {
              name: "GOSI SUR",
            },
            {
              name: "LARION ALTO",
            },
            {
              name: "LARION BAJO",
            },
            {
              name: "LEONARDA",
            },
            {
              name: "LIBAG NORTE",
            },
            {
              name: "LIBAG SUR",
            },
            {
              name: "LINAO EAST",
            },
            {
              name: "LINAO NORTE",
            },
            {
              name: "LINAO WEST",
            },
            {
              name: "NAMBBALAN NORTE",
            },
            {
              name: "NAMBBALAN SUR",
            },
            {
              name: "PALLUA NORTE",
            },
            {
              name: "PALLUA SUR",
            },
            {
              name: "PENGUE (PENGUE-RUYU)",
            },
            {
              name: "REYES",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "TAGGA",
            },
            {
              name: "TANZA",
            },
            {
              name: "TUGUEGARAO CITY",
            },
            {
              name: "UGAC NORTE",
            },
            {
              name: "UGAC SUR",
            },
          ],
        },
      },
    },
    "CAMARINES NORTE": {
      name: "CAMARINES NORTE",
      region: "REGION 5",
      municipalities: {
        BASUD: {
          name: "BASUD",
          baranggays: [
            {
              name: "ANGAS",
            },
            {
              name: "BACTAS",
            },
            {
              name: "BINATAGAN",
            },
            {
              name: "CAAYUNAN",
            },
            {
              name: "GUINATUNGAN",
            },
            {
              name: "HINAMPACAN",
            },
            {
              name: "LANGA",
            },
            {
              name: "LANITON",
            },
            {
              name: "LIDONG",
            },
            {
              name: "MAMPILI",
            },
            {
              name: "MANDAZO",
            },
            {
              name: "MANGCAMAGONG",
            },
            {
              name: "MANMUNTAY",
            },
            {
              name: "MANTUGAWE",
            },
            {
              name: "MATNOG",
            },
            {
              name: "MOCONG",
            },
            {
              name: "OLIVA",
            },
            {
              name: "PAGSANGAHAN",
            },
            {
              name: "PINAGWARASAN",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "TABA-TABA",
            },
            {
              name: "TACAD",
            },
            {
              name: "TAISAN",
            },
            {
              name: "TUACA",
            },
          ],
        },
        CAPALONGA: {
          name: "CAPALONGA",
          baranggays: [
            {
              name: "ALAYAO",
            },
            {
              name: "BINAWANGAN",
            },
            {
              name: "CALABACA",
            },
            {
              name: "CAMAGSAAN",
            },
            {
              name: "CAPALONGA",
            },
            {
              name: "CATABAGUANGAN",
            },
            {
              name: "CATIOAN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "ITOK",
            },
            {
              name: "LUCBANAN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MACTANG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MATAQUE",
            },
            {
              name: "OLD CAMP",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TANAWAN",
            },
            {
              name: "UBANG",
            },
            {
              name: "VILLA AURORA",
            },
            {
              name: "VILLA BELEN",
            },
          ],
        },
        DAET: {
          name: "DAET",
          baranggays: [
            {
              name: "ALAWIHAO",
            },
            {
              name: "AWITAN",
            },
            {
              name: "BAGASBAS",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "BARANGAY VII (POB.)",
            },
            {
              name: "BARANGAY VIII (POB.)",
            },
            {
              name: "BIBIRAO",
            },
            {
              name: "BORABOD",
            },
            {
              name: "CALASGASAN",
            },
            {
              name: "CAMAMBUGAN",
            },
            {
              name: "COBANGBANG (CARUMPIT)",
            },
            {
              name: "DAET",
            },
            {
              name: "DOGONGAN",
            },
            {
              name: "GAHONON",
            },
            {
              name: "GUBAT",
            },
            {
              name: "LAG-ON",
            },
            {
              name: "MAGANG",
            },
            {
              name: "MAMBALITE",
            },
            {
              name: "MANCRUZ (MANGCRUZ)",
            },
            {
              name: "PAMORANGON",
            },
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        "JOSE PANGANIBAN": {
          name: "JOSE PANGANIBAN",
          baranggays: [
            {
              name: "BAGONG BAYAN",
            },
            {
              name: "CALERO",
            },
            {
              name: "DAHICAN",
            },
            {
              name: "DAYHAGAN",
            },
            {
              name: "JOSE PANGANIBAN",
            },
            {
              name: "LARAP",
            },
            {
              name: "LUKLUKAN NORTE",
            },
            {
              name: "LUKLUKAN SUR",
            },
            {
              name: "MOTHERLODE",
            },
            {
              name: "NAKALAYA",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA MILAGROSA",
            },
            {
              name: "SANTA ROSA NORTE",
            },
            {
              name: "SANTA ROSA SUR",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TAMISAN",
            },
          ],
        },
        LABO: {
          name: "LABO",
          baranggays: [
            {
              name: "ANAHAW (POB.)",
            },
            {
              name: "ANAMEAM",
            },
            {
              name: "AWITAN",
            },
            {
              name: "BAAY",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAGONG SILANG I",
            },
            {
              name: "BAGONG SILANG II",
            },
            {
              name: "BAGONG SILANG III (SILANG)",
            },
            {
              name: "BAKIAD",
            },
            {
              name: "BAUTISTA",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BAYAN-BAYAN",
            },
            {
              name: "BENIT",
            },
            {
              name: "BULHAO",
            },
            {
              name: "CABATUHAN",
            },
            {
              name: "CABUSAY",
            },
            {
              name: "CALABASA",
            },
            {
              name: "CANAPAWAN",
            },
            {
              name: "DAGUIT",
            },
            {
              name: "DALAS",
            },
            {
              name: "DUMAGMANG",
            },
            {
              name: "EXCIBAN",
            },
            {
              name: "FUNDADO",
            },
            {
              name: "GUINACUTAN",
            },
            {
              name: "GUISICAN",
            },
            {
              name: "GUMAMELA (POB.)",
            },
            {
              name: "IBERICA",
            },
            {
              name: "KALAMUNDING (POB.)",
            },
            {
              name: "LABO",
            },
            {
              name: "LUGUI",
            },
            {
              name: "MABILO I",
            },
            {
              name: "MABILO II",
            },
            {
              name: "MACOGON",
            },
            {
              name: "MAHAWAN-HAWAN",
            },
            {
              name: "MALANGCAO-BASUD",
            },
            {
              name: "MALASUGUI",
            },
            {
              name: "MALATAP",
            },
            {
              name: "MALAYA",
            },
            {
              name: "MALIBAGO",
            },
            {
              name: "MAOT",
            },
            {
              name: "MASALONG",
            },
            {
              name: "MATANLANG",
            },
            {
              name: "NAPAOD",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PINYA (POB.)",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SUBMAKIN",
            },
            {
              name: "TALOBATIB",
            },
            {
              name: "TIGBINAN",
            },
            {
              name: "TULAY NA LUPA",
            },
          ],
        },
        MERCEDES: {
          name: "MERCEDES",
          baranggays: [
            {
              name: "APUAO",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "BARANGAY VII (POB.)",
            },
            {
              name: "CARINGO",
            },
            {
              name: "CATANDUNGANON",
            },
            {
              name: "CAYUCYUCAN",
            },
            {
              name: "COLASI",
            },
            {
              name: "DEL ROSARIO (TAGONGTONG)",
            },
            {
              name: "GABOC",
            },
            {
              name: "HAMORAON",
            },
            {
              name: "HINIPAAN",
            },
            {
              name: "LALAWIGAN",
            },
            {
              name: "LANOT",
            },
            {
              name: "MAMBUNGALON",
            },
            {
              name: "MANGUISOC",
            },
            {
              name: "MASALONGSALONG",
            },
            {
              name: "MATOOGTOOG",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "PAMBUHAN",
            },
            {
              name: "QUINAPAGUIAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TARUM",
            },
          ],
        },
        PARACALE: {
          name: "PARACALE",
          baranggays: [
            {
              name: "AWITAN",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAKAL",
            },
            {
              name: "BATOBALANI",
            },
            {
              name: "CALABURNAY",
            },
            {
              name: "CAPACUAN",
            },
            {
              name: "CASALUGAN",
            },
            {
              name: "DAGANG",
            },
            {
              name: "DALNAC",
            },
            {
              name: "DANCALAN",
            },
            {
              name: "GUMAUS",
            },
            {
              name: "LABNIG",
            },
            {
              name: "MACOLABO ISLAND",
            },
            {
              name: "MALACBANG",
            },
            {
              name: "MALAGUIT",
            },
            {
              name: "MAMPUNGO",
            },
            {
              name: "MANGKASAY",
            },
            {
              name: "MAYBATO",
            },
            {
              name: "PALANAS",
            },
            {
              name: "PARACALE",
            },
            {
              name: "PINAGBIRAYAN MALAKI",
            },
            {
              name: "PINAGBIRAYAN MUNTI",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "TABAS",
            },
            {
              name: "TALUSAN",
            },
            {
              name: "TAWIG",
            },
            {
              name: "TUGOS",
            },
          ],
        },
        "SAN LORENZO RUIZ (IMELDA)": {
          name: "SAN LORENZO RUIZ (IMELDA)",
          baranggays: [
            {
              name: "DACULANG BOLO",
            },
            {
              name: "DAGOTDOTAN",
            },
            {
              name: "LANGGA",
            },
            {
              name: "LANITON",
            },
            {
              name: "MAISOG",
            },
            {
              name: "MAMPUROG",
            },
            {
              name: "MANLIMONSITO",
            },
            {
              name: "MATACONG (POB.)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN LORENZO RUIZ (IMELDA)",
            },
            {
              name: "SAN RAMON",
            },
          ],
        },
        "SAN VICENTE": {
          name: "SAN VICENTE",
          baranggays: [
            {
              name: "ASDUM",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CALABAGAS",
            },
            {
              name: "FABRICA",
            },
            {
              name: "IRAYA SUR",
            },
            {
              name: "MAN-OGOB",
            },
            {
              name: "POBLACION DISTRICT I (SILANGAN/BGY. 1)",
            },
            {
              name: "POBLACION DISTRICT II (KANLURAN/BGY. 2)",
            },
            {
              name: "SAN JOSE (IRAYA NORTE)",
            },
            {
              name: "SAN VICENTE",
            },
          ],
        },
        "SANTA ELENA": {
          name: "SANTA ELENA",
          baranggays: [
            {
              name: "BASIAD",
            },
            {
              name: "BULALA",
            },
            {
              name: "DON TOMAS",
            },
            {
              name: "GUITOL",
            },
            {
              name: "KABULUAN",
            },
            {
              name: "KAGTALABA",
            },
            {
              name: "MAULAWIN",
            },
            {
              name: "PATAG IBABA",
            },
            {
              name: "PATAG IRAYA",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POLUNGGUITGUIT",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA ELENA (POB.)",
            },
            {
              name: "TABUGON",
            },
            {
              name: "VILLA SAN ISIDRO",
            },
          ],
        },
        TALISAY: {
          name: "TALISAY",
          baranggays: [
            {
              name: "BINANUAAN",
            },
            {
              name: "CAAWIGAN",
            },
            {
              name: "CAHABAAN",
            },
            {
              name: "CALINTAAN",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "GABON",
            },
            {
              name: "ITOMANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TALISAY",
            },
          ],
        },
        VINZONS: {
          name: "VINZONS",
          baranggays: [
            {
              name: "AGUIT-IT",
            },
            {
              name: "BANOCBOC",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "CAGBALOGO",
            },
            {
              name: "CALANGCAWAN NORTE",
            },
            {
              name: "CALANGCAWAN SUR",
            },
            {
              name: "GUINACUTAN",
            },
            {
              name: "MANGCAWAYAN",
            },
            {
              name: "MANGCAYO",
            },
            {
              name: "MANLUCUGAN",
            },
            {
              name: "MATANGO",
            },
            {
              name: "NAPILIHAN",
            },
            {
              name: "PINAGTIGASAN",
            },
            {
              name: "SABANG",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SINGI",
            },
            {
              name: "SULA",
            },
            {
              name: "VINZONS",
            },
          ],
        },
      },
    },
    "CAMARINES SUR": {
      name: "CAMARINES SUR",
      region: "REGION 5",
      municipalities: {
        BAAO: {
          name: "BAAO",
          baranggays: [
            {
              name: "BAAO",
            },
            {
              name: "CARANDAY (LA PURISIMA)",
            },
            {
              name: "CRISTO REY",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DEL ROSARIO (POB.)",
            },
            {
              name: "IYAGAN",
            },
            {
              name: "LA MEDALLA",
            },
            {
              name: "LOURDES",
            },
            {
              name: "NABABARERA",
            },
            {
              name: "PUGAY (SAN RAFAEL)",
            },
            {
              name: "SAGRADA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SAN RAFAEL (IKPAN)",
            },
            {
              name: "SAN RAMON (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTA EULALIA",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA TERESA (VIGA)",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "TAPOL",
            },
          ],
        },
        BALATAN: {
          name: "BALATAN",
          baranggays: [
            {
              name: "BALATAN",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CABUNGAN",
            },
            {
              name: "CAMANGAHAN (CAORASAN)",
            },
            {
              name: "CAYOGCOG",
            },
            {
              name: "COGUIT",
            },
            {
              name: "DURAN",
            },
            {
              name: "LAGANAC",
            },
            {
              name: "LULUASAN",
            },
            {
              name: "MONTENEGRO (MAGUIRON)",
            },
            {
              name: "PARARAO",
            },
            {
              name: "PULANG DAGA",
            },
            {
              name: "SAGRADA NACACALE",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SANTIAGO NACACALE",
            },
            {
              name: "SIRAMAG (POB.)",
            },
            {
              name: "TAPAYAS",
            },
            {
              name: "TOMATARAYO",
            },
          ],
        },
        BATO: {
          name: "BATO",
          baranggays: [
            {
              name: "AGOS",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BATO",
            },
            {
              name: "BULUANG",
            },
            {
              name: "CARICOT",
            },
            {
              name: "CAWACAGAN",
            },
            {
              name: "COTMON",
            },
            {
              name: "CRISTO REY",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "DIVINA PASTORA (POB.)",
            },
            {
              name: "GOYUDAN",
            },
            {
              name: "LOBONG",
            },
            {
              name: "LUBIGAN",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MANGA (MANGGA)",
            },
            {
              name: "MASOLI",
            },
            {
              name: "NEIGHBORHOOD",
            },
            {
              name: "NIÑO JESUS",
            },
            {
              name: "PAGATPATAN",
            },
            {
              name: "PALO",
            },
            {
              name: "PAYAK",
            },
            {
              name: "SAGRADA (SAGRADA FAMILIA)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAFAEL (POB.)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTIAGO (POB.)",
            },
            {
              name: "SOOC",
            },
            {
              name: "TAGPOLO",
            },
            {
              name: "TRES REYES (POB.)",
            },
          ],
        },
        BOMBON: {
          name: "BOMBON",
          baranggays: [
            {
              name: "BOMBON",
            },
            {
              name: "PAGAO (SAN JUAN)",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SIEMBRE",
            },
          ],
        },
        BUHI: {
          name: "BUHI",
          baranggays: [
            {
              name: "AMLONGAN (DEL ROSARIO)",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "BUHI",
            },
            {
              name: "BUROCBUSOC",
            },
            {
              name: "CABATUAN",
            },
            {
              name: "CAGMASLOG",
            },
            {
              name: "DE LA FE",
            },
            {
              name: "DELOS ANGELES (LOS ANGELES)",
            },
            {
              name: "DIVINO ROSTRO",
            },
            {
              name: "GABAS",
            },
            {
              name: "IBAYUGAN",
            },
            {
              name: "IGBAC",
            },
            {
              name: "IPIL (FATIMA)",
            },
            {
              name: "IRAYA (DEL ROSARIO)",
            },
            {
              name: "LABAWON (SANTA TERESITA)",
            },
            {
              name: "LOURDES (SANTA LOURDES)",
            },
            {
              name: "MACAANGAY",
            },
            {
              name: "MONTE CALVARIO",
            },
            {
              name: "NAMURABOD",
            },
            {
              name: "SAGRADA FAMILIA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BUENAVENTURA (POB.)",
            },
            {
              name: "SAN FRANCISCO (PARADA)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE BAYBAYON",
            },
            {
              name: "SAN JOSE SALAY",
            },
            {
              name: "SAN PASCUAL (POB.)",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CLARA (POB.)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ELENA (POB.)",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA JUSTINA",
            },
            {
              name: "TAMBO",
            },
          ],
        },
        BULA: {
          name: "BULA",
          baranggays: [
            {
              name: "BAGOLADIO",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALAOGAN",
            },
            {
              name: "BULA",
            },
            {
              name: "CAORASAN",
            },
            {
              name: "CASUGAD",
            },
            {
              name: "CAUSIP",
            },
            {
              name: "FABRICA",
            },
            {
              name: "INOYONAN",
            },
            {
              name: "ITANGON",
            },
            {
              name: "KINALABASAHAN",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "LA VICTORIA",
            },
            {
              name: "LANIPGA",
            },
            {
              name: "LUBGAN",
            },
            {
              name: "OMBAO HEIGHTS",
            },
            {
              name: "OMBAO POLPOG",
            },
            {
              name: "PALSONG",
            },
            {
              name: "PANOYPOYAN",
            },
            {
              name: "PAWILI",
            },
            {
              name: "SAGRADA (SAGRADA FAMILIA)",
            },
            {
              name: "SALVACION (POB.)",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN ROQUE HEIGHTS",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAISAN",
            },
          ],
        },
        CABUSAO: {
          name: "CABUSAO",
          baranggays: [
            {
              name: "BARCELONITA",
            },
            {
              name: "BIONG",
            },
            {
              name: "CABUSAO",
            },
            {
              name: "CAMAGONG",
            },
            {
              name: "CASTILLO",
            },
            {
              name: "NEW POBLACION",
            },
            {
              name: "PANDAN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUTGARDA (POB.)",
            },
          ],
        },
        CALABANGA: {
          name: "CALABANGA",
          baranggays: [
            {
              name: "BALATASAN",
            },
            {
              name: "BALOMBON",
            },
            {
              name: "BALONGAY",
            },
            {
              name: "BELEN",
            },
            {
              name: "BIGAAS",
            },
            {
              name: "BINALIW",
            },
            {
              name: "BINANUAANAN GRANDE",
            },
            {
              name: "BINANUAANAN PEQUEÑO",
            },
            {
              name: "BONOT-SANTA ROSA",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CAGSAO",
            },
            {
              name: "CALABANGA",
            },
            {
              name: "CAMUNING",
            },
            {
              name: "COMAGUINGKING",
            },
            {
              name: "DEL CARMEN (POB.)",
            },
            {
              name: "DOMINOROG",
            },
            {
              name: "FABRICA",
            },
            {
              name: "HAROBAY",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "LUGSAD",
            },
            {
              name: "MANGUIRING",
            },
            {
              name: "PAGATPAT (SAN JOSE)",
            },
            {
              name: "PAOLBO",
            },
            {
              name: "PINADA",
            },
            {
              name: "PUNTA TARAWAL",
            },
            {
              name: "QUINALE",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALVACION-BAYBAY",
            },
            {
              name: "SAN ANTONIO (QUIPAYO)",
            },
            {
              name: "SAN ANTONIO POBLACION",
            },
            {
              name: "SAN BERNARDINO",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN LUCAS",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SAN PABLO (POB.)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA CRUZ POBLACION",
            },
            {
              name: "SANTA CRUZ RATAY",
            },
            {
              name: "SANTA ISABEL (POB.)",
            },
            {
              name: "SANTA SALUD (POB.)",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO (QUIPAYO)",
            },
            {
              name: "SIBA-O",
            },
            {
              name: "SIBOBO",
            },
            {
              name: "SOGOD",
            },
            {
              name: "TOMAGODTOD",
            },
          ],
        },
        CAMALIGAN: {
          name: "CAMALIGAN",
          baranggays: [
            {
              name: "CAMALIGAN",
            },
            {
              name: "DUGCAL",
            },
            {
              name: "MARUPIT",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE-SAN PABLO (POB.)",
            },
            {
              name: "SAN JUAN-SAN RAMON (POB.)",
            },
            {
              name: "SAN LUCAS (POB.)",
            },
            {
              name: "SAN MARCOS (POB.)",
            },
            {
              name: "SAN MATEO (POB.)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO DOMINGO (POB.)",
            },
            {
              name: "SANTO TOMAS (POB.)",
            },
            {
              name: "SUA",
            },
            {
              name: "TAROSANAN",
            },
          ],
        },
        CANAMAN: {
          name: "CANAMAN",
          baranggays: [
            {
              name: "BARAS (POB.)",
            },
            {
              name: "CANAMAN",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "DINAGA (POB.)",
            },
            {
              name: "FUNDADO",
            },
            {
              name: "HARING",
            },
            {
              name: "IQUIN",
            },
            {
              name: "LINAGA",
            },
            {
              name: "MANGAYAWAN",
            },
            {
              name: "PALO",
            },
            {
              name: "PANGPANG (POB.)",
            },
            {
              name: "PORO",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE EAST",
            },
            {
              name: "SAN JOSE WEST",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SUA",
            },
            {
              name: "TALIDTID",
            },
            {
              name: "TIBGAO (POB.)",
            },
          ],
        },
        CARAMOAN: {
          name: "CARAMOAN",
          baranggays: [
            {
              name: "AGAAS",
            },
            {
              name: "ANTOLON",
            },
            {
              name: "BACGONG",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BIKAL",
            },
            {
              name: "BINANUAHAN (POB.)",
            },
            {
              name: "CABACONGAN",
            },
            {
              name: "CADONG",
            },
            {
              name: "CANATUAN",
            },
            {
              name: "CAPUTATAN",
            },
            {
              name: "CARAMOAN",
            },
            {
              name: "COLONGCOGONG",
            },
            {
              name: "DARAGA",
            },
            {
              name: "GATA",
            },
            {
              name: "GIBGOS",
            },
            {
              name: "GOGON",
            },
            {
              name: "GUIJALO",
            },
            {
              name: "HANOPOL",
            },
            {
              name: "HANOY",
            },
            {
              name: "HAPONAN",
            },
            {
              name: "ILAWOD",
            },
            {
              name: "ILI-CENTRO (POB.)",
            },
            {
              name: "LIDONG",
            },
            {
              name: "LUBAS",
            },
            {
              name: "MALABOG",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MAMPIRAO",
            },
            {
              name: "MANDICLUM (MANDICLOM)",
            },
            {
              name: "MAQUEDA",
            },
            {
              name: "MINALABA",
            },
            {
              name: "ORING",
            },
            {
              name: "OROC-OSOC",
            },
            {
              name: "PAGOLINAN",
            },
            {
              name: "PANDANAN",
            },
            {
              name: "PANIMAN",
            },
            {
              name: "PATAG-BELEN",
            },
            {
              name: "PILI-CENTRO",
            },
            {
              name: "PILI-TABIGUIAN",
            },
            {
              name: "POLOAN",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SOLNOPAN",
            },
            {
              name: "TABGON",
            },
            {
              name: "TABIGUIAN",
            },
            {
              name: "TABOG",
            },
            {
              name: "TAWOG (POB.)",
            },
            {
              name: "TEROGO",
            },
            {
              name: "TOBOAN",
            },
          ],
        },
        "DEL GALLEGO": {
          name: "DEL GALLEGO",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BUCAL",
            },
            {
              name: "CABASAG",
            },
            {
              name: "COMADAYCADAY",
            },
            {
              name: "COMADOGCADOG",
            },
            {
              name: "DEL GALLEGO",
            },
            {
              name: "DOMAGONDONG",
            },
            {
              name: "KINALANGAN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGAIS I",
            },
            {
              name: "MAGAIS II",
            },
            {
              name: "MANSALAYA",
            },
            {
              name: "NAGKALIT",
            },
            {
              name: "PALASPAS",
            },
            {
              name: "PAMPLONA",
            },
            {
              name: "PASAY",
            },
            {
              name: "PINAGDAPIAN",
            },
            {
              name: "PINUGUSAN",
            },
            {
              name: "POBLACION ZONE III",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SANTA RITA I",
            },
            {
              name: "SANTA RITA II",
            },
            {
              name: "SINAGAWSAWAN",
            },
            {
              name: "SINUKNIPAN I",
            },
            {
              name: "SINUKNIPAN II",
            },
            {
              name: "SUGSUGIN",
            },
            {
              name: "TABION",
            },
            {
              name: "TOMAGOKTOK",
            },
            {
              name: "ZONE I FATIMA (POB.)",
            },
            {
              name: "ZONE II SAN ANTONIO (POB.)",
            },
          ],
        },
        GAINZA: {
          name: "GAINZA",
          baranggays: [
            {
              name: "CAGBUNGA",
            },
            {
              name: "DAHILIG",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "GAINZA",
            },
            {
              name: "LOOB",
            },
            {
              name: "MALBONG",
            },
            {
              name: "NAMUAT",
            },
            {
              name: "SAMPALOC",
            },
          ],
        },
        GARCHITORENA: {
          name: "GARCHITORENA",
          baranggays: [
            {
              name: "ASON (ANSON)",
            },
            {
              name: "BAHI",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BINAGASBASAN",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CAGAMUTAN",
            },
            {
              name: "CAGNIPA",
            },
            {
              name: "CANLONG",
            },
            {
              name: "DANGLA",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DENRICA",
            },
            {
              name: "GARCHITORENA",
            },
            {
              name: "HARRISON",
            },
            {
              name: "MANSANGAT",
            },
            {
              name: "PAMBUHAN",
            },
            {
              name: "SAGRADA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SUMAOY",
            },
            {
              name: "TAMIAWON",
            },
            {
              name: "TOYTOY",
            },
          ],
        },
        GOA: {
          name: "GOA",
          baranggays: [
            {
              name: "ABUCAYAN",
            },
            {
              name: "BAGUMBAYAN GRANDE (POB.)",
            },
            {
              name: "BAGUMBAYAN PEQUEÑO (POB.)",
            },
            {
              name: "BALAYNAN",
            },
            {
              name: "BELEN (POB.)",
            },
            {
              name: "BUYO",
            },
            {
              name: "CAGAYCAY",
            },
            {
              name: "CATAGBACAN",
            },
            {
              name: "DIGDIGON",
            },
            {
              name: "GIMAGA",
            },
            {
              name: "GOA",
            },
            {
              name: "HALAWIG-GOGON",
            },
            {
              name: "HIWACLOY",
            },
            {
              name: "LA PURISIMA (POB.)",
            },
            {
              name: "LAMON",
            },
            {
              name: "MATACLA",
            },
            {
              name: "MAYMATAN",
            },
            {
              name: "MAYSALAY",
            },
            {
              name: "NAPAWON (NAPUNUON)",
            },
            {
              name: "PANDAY (POB.)",
            },
            {
              name: "PAYATAN",
            },
            {
              name: "PINAGLABANAN",
            },
            {
              name: "SALOG",
            },
            {
              name: "SAN BENITO (POB.)",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN ISIDRO WEST",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN BAUTISTA (POB.)",
            },
            {
              name: "SAN JUAN EVANGELISTA (POB.)",
            },
            {
              name: "SAN PEDRO (ARORO)",
            },
            {
              name: "SCOUT FUENTEBELLA",
            },
            {
              name: "TABGON",
            },
            {
              name: "TAGONGTONG",
            },
            {
              name: "TAMBAN (MABINI)",
            },
            {
              name: "TAYTAY (HALGON EAST)",
            },
          ],
        },
        "IRIGA CITY": {
          name: "IRIGA CITY",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "CRISTO REY",
            },
            {
              name: "DEL ROSARIO (BANAO)",
            },
            {
              name: "FRANCIA",
            },
            {
              name: "IRIGA CITY",
            },
            {
              name: "LA ANUNCIACION",
            },
            {
              name: "LA MEDALLA",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "LA TRINIDAD",
            },
            {
              name: "NIÑO JESUS",
            },
            {
              name: "PERPETUAL HELP",
            },
            {
              name: "SAGRADA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN VICENTE NORTE",
            },
            {
              name: "SAN VICENTE SUR",
            },
            {
              name: "SANTA CRUZ NORTE",
            },
            {
              name: "SANTA CRUZ SUR",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        LAGONOY: {
          name: "LAGONOY",
          baranggays: [
            {
              name: "AGOSAIS",
            },
            {
              name: "AGPO-CAMAGONG-TABOG",
            },
            {
              name: "AMOGUIS",
            },
            {
              name: "BALATON",
            },
            {
              name: "BINANUAHAN",
            },
            {
              name: "BOCOGAN",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CABOTONAN",
            },
            {
              name: "DAHAT",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "GIMAGTOCON",
            },
            {
              name: "GINORANGAN (GENORANGAN)",
            },
            {
              name: "GUBAT",
            },
            {
              name: "GUIBAHOY",
            },
            {
              name: "HIMANAG",
            },
            {
              name: "KINAHOLOGAN",
            },
            {
              name: "LAGONOY",
            },
            {
              name: "LOHO",
            },
            {
              name: "MANAMOC",
            },
            {
              name: "MANGOGON",
            },
            {
              name: "MAPID",
            },
            {
              name: "OLAS",
            },
            {
              name: "OMALO",
            },
            {
              name: "PANAGAN",
            },
            {
              name: "PANICUAN",
            },
            {
              name: "PINAMIHAGAN",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ISIDRO NORTE (POB.)",
            },
            {
              name: "SAN ISIDRO SUR (POB.)",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTA MARIA (POB.)",
            },
            {
              name: "SARIPONGPONG (POB.)",
            },
            {
              name: "SIPACO",
            },
          ],
        },
        LIBMANAN: {
          name: "LIBMANAN",
          baranggays: [
            {
              name: "ASLONG",
            },
            {
              name: "AWAYAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAGADION",
            },
            {
              name: "BAGAMELON",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAHAO",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BEGAJO NORTE",
            },
            {
              name: "BEGAJO SUR",
            },
            {
              name: "BEGUITO NUEVO",
            },
            {
              name: "BEGUITO VIEJO",
            },
            {
              name: "BIKAL",
            },
            {
              name: "BUSAK",
            },
            {
              name: "CAIMA",
            },
            {
              name: "CALABNIGAN",
            },
            {
              name: "CAMBALIDIO",
            },
            {
              name: "CANDAMI",
            },
            {
              name: "CANDATO",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CUYAPI",
            },
            {
              name: "DANAWAN",
            },
            {
              name: "DUANG NIOG",
            },
            {
              name: "HANDONG",
            },
            {
              name: "IBID",
            },
            {
              name: "INALAHAN",
            },
            {
              name: "LABAO",
            },
            {
              name: "LIBMANAN",
            },
            {
              name: "LIBOD I",
            },
            {
              name: "LIBOD II",
            },
            {
              name: "LOBA-LOBA",
            },
            {
              name: "MALANSAD NUEVO",
            },
            {
              name: "MALANSAD VIEJO",
            },
            {
              name: "MALBOGON",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MAMBALITE",
            },
            {
              name: "MAMBAYAWAS",
            },
            {
              name: "MANCAWAYAN",
            },
            {
              name: "MANDACANAN",
            },
            {
              name: "MANTALISAY",
            },
            {
              name: "PADLOS",
            },
            {
              name: "PAG-ORING NUEVO",
            },
            {
              name: "PAG-ORING VIEJO",
            },
            {
              name: "PALANGON",
            },
            {
              name: "PALONG",
            },
            {
              name: "PATAG",
            },
            {
              name: "PLANZA",
            },
            {
              name: "PURO-BATIA",
            },
            {
              name: "RONGOS",
            },
            {
              name: "SIBUJO",
            },
            {
              name: "TAMPUHAN",
            },
            {
              name: "TANAG",
            },
            {
              name: "TARUM",
            },
            {
              name: "TINALMUD NUEVO",
            },
            {
              name: "TINALMUD VIEJO",
            },
            {
              name: "UDOC",
            },
            {
              name: "UMALO",
            },
            {
              name: "USON",
            },
            {
              name: "VILLADIMA (SANTA CRUZ)",
            },
            {
              name: "VILLASOCORRO",
            },
          ],
        },
        LUPI: {
          name: "LUPI",
          baranggays: [
            {
              name: "ALLEOMAR",
            },
            {
              name: "BAGANGAN SR.",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BANGON",
            },
            {
              name: "BARRERA JR.",
            },
            {
              name: "BARRERA SR.",
            },
            {
              name: "BEL-CRUZ",
            },
            {
              name: "BELWANG",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "BULAWAN JR.",
            },
            {
              name: "BULAWAN SR.",
            },
            {
              name: "CABUTAGAN",
            },
            {
              name: "CASAY",
            },
            {
              name: "COLACLING (DEL ROSARIO)",
            },
            {
              name: "CRISTO REY",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "HAGUIMIT",
            },
            {
              name: "HALUBAN (PIGBASAGAN)",
            },
            {
              name: "KAIBIGAN",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LUPI",
            },
            {
              name: "MANGCAWAYAN",
            },
            {
              name: "NAPOLIDAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLANTUNA",
            },
            {
              name: "SAGRADA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL NORTE",
            },
            {
              name: "SAN RAFAEL SUR",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SOOC",
            },
            {
              name: "TANAWAN",
            },
            {
              name: "TAPI (LUPI NUEVO)",
            },
            {
              name: "TIBLE",
            },
          ],
        },
        MAGARAO: {
          name: "MAGARAO",
          baranggays: [
            {
              name: "BAROBAYBAY",
            },
            {
              name: "BELL (POB.)",
            },
            {
              name: "CARANGCANG",
            },
            {
              name: "CARIGSA",
            },
            {
              name: "CASURAY",
            },
            {
              name: "MAGARAO",
            },
            {
              name: "MONSERRAT (POB.)",
            },
            {
              name: "PONONG",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PANTALEON (POB.)",
            },
            {
              name: "SANTA LUCIA (POB.)",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO TOMAS (POB.)",
            },
          ],
        },
        MILAOR: {
          name: "MILAOR",
          baranggays: [
            {
              name: "ALIMBUYOG",
            },
            {
              name: "AMPARADO (POB.)",
            },
            {
              name: "BALAGBAG",
            },
            {
              name: "BORONGBORONGAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAPUCNASAN",
            },
            {
              name: "DALIPAY",
            },
            {
              name: "DEL ROSARIO (POB.)",
            },
            {
              name: "FLORDELIZ",
            },
            {
              name: "LIPOT",
            },
            {
              name: "MAYAOPAYAWAN",
            },
            {
              name: "MAYCATMON",
            },
            {
              name: "MAYDASO",
            },
            {
              name: "MILAOR",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTO DOMINGO (POB.)",
            },
            {
              name: "TARUSANAN",
            },
          ],
        },
        MINALABAC: {
          name: "MINALABAC",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BAGOLATAO",
            },
            {
              name: "BAGONGBONG",
            },
            {
              name: "BALIUAG NUEVO",
            },
            {
              name: "BALIUAG VIEJO",
            },
            {
              name: "CATANUSAN",
            },
            {
              name: "DEL CARMEN-DEL ROSARIO (POB.)",
            },
            {
              name: "DEL SOCORRO",
            },
            {
              name: "HAMORAON",
            },
            {
              name: "HOBO",
            },
            {
              name: "IRAYANG SOLONG",
            },
            {
              name: "MAGADAP",
            },
            {
              name: "MALITBOG",
            },
            {
              name: "MANAPAO",
            },
            {
              name: "MATAOROC",
            },
            {
              name: "MINALABAC",
            },
            {
              name: "SAGRADA (SAGRADA FAMILIA)",
            },
            {
              name: "SALINGOGON",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FELIPE-SANTIAGO (POB.)",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN-SAN LORENZO (POB.)",
            },
            {
              name: "TABAN",
            },
            {
              name: "TARIRIC",
            },
            {
              name: "TIMBANG",
            },
          ],
        },
        NABUA: {
          name: "NABUA",
          baranggays: [
            {
              name: "ANGUSTIA (ANGUSTIA INAPATAN)",
            },
            {
              name: "ANTIPOLO OLD",
            },
            {
              name: "ANTIPOLO YOUNG",
            },
            {
              name: "ARO-ALDAO",
            },
            {
              name: "BUSTRAC",
            },
            {
              name: "DOLOROSA (DOLOROSA INAPATAN)",
            },
            {
              name: "DURAN (JESUS DURAN)",
            },
            {
              name: "INAPATAN (DEL ROSARIO INAPATAN)",
            },
            {
              name: "LA OPINION",
            },
            {
              name: "LA PURISIMA (LA PURISIMA AGUPIT)",
            },
            {
              name: "LOURDES OLD",
            },
            {
              name: "LOURDES YOUNG",
            },
            {
              name: "MALAWAG (SAN JOSE MALAWAG)",
            },
            {
              name: "NABUA",
            },
            {
              name: "PALOYON ORIENTAL",
            },
            {
              name: "PALOYON PROPER (SAGRADA PALOYON)",
            },
            {
              name: "SALVACION QUE GATOS",
            },
            {
              name: "SAN ANTONIO OGBON",
            },
            {
              name: "SAN ESTEBAN (POB.)",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN ISIDRO INAPATAN",
            },
            {
              name: "SAN JOSE (SAN JOSE PANGARAON)",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN LUIS (POB.)",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN ROQUE MADAWON",
            },
            {
              name: "SAN ROQUE SAGUMAY",
            },
            {
              name: "SAN VICENTE GORONG-GORONG",
            },
            {
              name: "SAN VICENTE OGBON",
            },
            {
              name: "SANTA BARBARA (MALIBAN)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ELENA BARAS",
            },
            {
              name: "SANTA LUCIA BARAS",
            },
            {
              name: "SANTIAGO OLD",
            },
            {
              name: "SANTIAGO YOUNG",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "TANDAAY",
            },
            {
              name: "TOPAS PROPER",
            },
            {
              name: "TOPAS SOGOD",
            },
          ],
        },
        "NAGA CITY": {
          name: "NAGA CITY",
          baranggays: [
            {
              name: "ABELLA",
            },
            {
              name: "BAGUMBAYAN NORTE",
            },
            {
              name: "BAGUMBAYAN SUR",
            },
            {
              name: "BALATAS",
            },
            {
              name: "CALAUAG",
            },
            {
              name: "CARARAYAN",
            },
            {
              name: "CAROLINA",
            },
            {
              name: "CONCEPCION GRANDE",
            },
            {
              name: "CONCEPCION PEQUEÑO",
            },
            {
              name: "DAYANGDANG",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "DINAGA",
            },
            {
              name: "IGUALDAD INTERIOR",
            },
            {
              name: "LERMA",
            },
            {
              name: "LIBOTON",
            },
            {
              name: "MABOLO",
            },
            {
              name: "NAGA CITY",
            },
            {
              name: "PACOL",
            },
            {
              name: "PANICUASON",
            },
            {
              name: "PEÑAFRANCIA",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TABUCO",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TRIANGULO",
            },
          ],
        },
        OCAMPO: {
          name: "OCAMPO",
          baranggays: [
            {
              name: "AYUGAN",
            },
            {
              name: "CABARIWAN",
            },
            {
              name: "CAGMANABA",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "GATBO",
            },
            {
              name: "GUINABAN",
            },
            {
              name: "HANAWAN",
            },
            {
              name: "HIBAGO",
            },
            {
              name: "LA PURISIMA NUEVO",
            },
            {
              name: "MAY-OGOB",
            },
            {
              name: "NEW MORIONES",
            },
            {
              name: "OCAMPO",
            },
            {
              name: "OLD MORIONES",
            },
            {
              name: "PINIT",
            },
            {
              name: "POBLACION CENTRAL",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE ORAS",
            },
            {
              name: "SAN ROQUE COMMONAL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "VILLAFLORIDA",
            },
          ],
        },
        PAMPLONA: {
          name: "PAMPLONA",
          baranggays: [
            {
              name: "BATANG",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CAGBIBI",
            },
            {
              name: "CAGBUNGA",
            },
            {
              name: "CALAWAT",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "PAMPLONA",
            },
            {
              name: "PATONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TAMPADONG",
            },
            {
              name: "VENERACION (TAGUILID)",
            },
          ],
        },
        PASACAO: {
          name: "PASACAO",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BALOGO",
            },
            {
              name: "CARANAN",
            },
            {
              name: "CUCO",
            },
            {
              name: "DALUPAON",
            },
            {
              name: "HUBO",
            },
            {
              name: "ITULAN",
            },
            {
              name: "MACAD (HEBRIO LOURDES)",
            },
            {
              name: "ODICON",
            },
            {
              name: "PASACAO",
            },
            {
              name: "QUITANG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CIRILO (POB.)",
            },
            {
              name: "SANTA ROSA DEL NORTE (POB.)",
            },
            {
              name: "SANTA ROSA DEL SUR (POB.)",
            },
            {
              name: "TILNAC",
            },
            {
              name: "TINALMUD",
            },
          ],
        },
        PILI: {
          name: "PILI",
          baranggays: [
            {
              name: "ANAYAN",
            },
            {
              name: "BAGONG SIRANG",
            },
            {
              name: "BINANWAANAN",
            },
            {
              name: "BINOBONG",
            },
            {
              name: "CADLAN",
            },
            {
              name: "CAROYROYAN",
            },
            {
              name: "CURRY",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "HIMAAO",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "NEW SAN ROQUE",
            },
            {
              name: "OLD SAN ROQUE (POB.)",
            },
            {
              name: "PALESTINA",
            },
            {
              name: "PAWILI",
            },
            {
              name: "PILI",
            },
            {
              name: "SAGRADA",
            },
            {
              name: "SAGURONG",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTIAGO (POB.)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGBONG",
            },
            {
              name: "TINANGIS",
            },
          ],
        },
        "PRESENTACION (PARUBCAN)": {
          name: "PRESENTACION (PARUBCAN)",
          baranggays: [
            {
              name: "AYUGAO",
            },
            {
              name: "BAGONG SIRANG",
            },
            {
              name: "BALIGUIAN",
            },
            {
              name: "BANTUGAN",
            },
            {
              name: "BICALEN",
            },
            {
              name: "BITAOGAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULALACAO",
            },
            {
              name: "CAGNIPA",
            },
            {
              name: "LAGHA",
            },
            {
              name: "LIDONG",
            },
            {
              name: "LIWACSA",
            },
            {
              name: "MAANGAS",
            },
            {
              name: "PAGSANGAHAN",
            },
            {
              name: "PATROCINIO",
            },
            {
              name: "PILI",
            },
            {
              name: "PRESENTACION (PARUBCAN)",
            },
            {
              name: "SANTA MARIA (POB.)",
            },
            {
              name: "TANAWAN",
            },
          ],
        },
        RAGAY: {
          name: "RAGAY",
          baranggays: [
            {
              name: "AGAO-AO",
            },
            {
              name: "AGRUPACION",
            },
            {
              name: "AMOMOKPOK",
            },
            {
              name: "APAD",
            },
            {
              name: "APALE",
            },
            {
              name: "BANGA CAVES",
            },
            {
              name: "BAYA",
            },
            {
              name: "BINAHAN PROPER",
            },
            {
              name: "BINAHAN UPPER",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "CABADISAN",
            },
            {
              name: "CABINITAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CADITAAN",
            },
            {
              name: "CALE",
            },
            {
              name: "CATABANGAN PROPER",
            },
            {
              name: "F. SIMEON (PUGOD)",
            },
            {
              name: "GODOFREDO REYES SR. (CATABANGAN CROSSING)",
            },
            {
              name: "INANDAWA",
            },
            {
              name: "LAGUIO",
            },
            {
              name: "LANIPGA-CAWAYAN",
            },
            {
              name: "LIBORO",
            },
            {
              name: "LOHONG",
            },
            {
              name: "LOWER OMON",
            },
            {
              name: "LOWER SANTA CRUZ",
            },
            {
              name: "PANAYTAYAN",
            },
            {
              name: "PANAYTAYAN NUEVO",
            },
            {
              name: "PATALUNAN",
            },
            {
              name: "POBLACION ILAOD",
            },
            {
              name: "POBLACION IRAYA",
            },
            {
              name: "PORT JUNCTION NORTE",
            },
            {
              name: "PORT JUNCTION SUR",
            },
            {
              name: "RAGAY",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAMAY",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "TAGBAC",
            },
            {
              name: "UPPER OMON",
            },
            {
              name: "UPPER SANTA CRUZ",
            },
          ],
        },
        SAGÑAY: {
          name: "SAGÑAY",
          baranggays: [
            {
              name: "ANIOG",
            },
            {
              name: "ATULAYAN",
            },
            {
              name: "BONGALON",
            },
            {
              name: "BURACAN",
            },
            {
              name: "CATALOTOAN",
            },
            {
              name: "DEL CARMEN (POB.)",
            },
            {
              name: "KILANTAAO",
            },
            {
              name: "KILOMAON",
            },
            {
              name: "MABCA",
            },
            {
              name: "MINADONGJOL",
            },
            {
              name: "NATO",
            },
            {
              name: "PATITINAN",
            },
            {
              name: "SAGÑAY",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIBAGUAN",
            },
            {
              name: "TINORONGAN",
            },
            {
              name: "TURAGUE",
            },
          ],
        },
        "SAN FERNANDO": {
          name: "SAN FERNANDO",
          baranggays: [
            {
              name: "ALIANZA",
            },
            {
              name: "BEBERON",
            },
            {
              name: "BICAL",
            },
            {
              name: "BOCAL",
            },
            {
              name: "BONIFACIO (POB.)",
            },
            {
              name: "BUENAVISTA (POB.)",
            },
            {
              name: "CALASCAGAS",
            },
            {
              name: "COTMO",
            },
            {
              name: "DACULANG TUBIG",
            },
            {
              name: "DEL PILAR (POB.)",
            },
            {
              name: "GÑARAN",
            },
            {
              name: "GRIJALVO",
            },
            {
              name: "LUPI",
            },
            {
              name: "MARAGÑI",
            },
            {
              name: "PAMUKID",
            },
            {
              name: "PINAMASAGAN",
            },
            {
              name: "PIPIAN",
            },
            {
              name: "PLANZA",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAGPOCOL",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "ADIANGAO",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BOCLOD",
            },
            {
              name: "CALALAHAN",
            },
            {
              name: "CALAWIT",
            },
            {
              name: "CAMAGONG",
            },
            {
              name: "CATALOTOAN",
            },
            {
              name: "DANLOG",
            },
            {
              name: "DEL CARMEN (POB.)",
            },
            {
              name: "DOLO",
            },
            {
              name: "KINALANSAN",
            },
            {
              name: "MAMPIRAO",
            },
            {
              name: "MANZANA",
            },
            {
              name: "MINORO",
            },
            {
              name: "PALALE",
            },
            {
              name: "PONGLON",
            },
            {
              name: "PUGAY",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALOGON",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SOLEDAD (POB.)",
            },
            {
              name: "TAGAS",
            },
            {
              name: "TAMBANGAN",
            },
            {
              name: "TELEGRAFO",
            },
            {
              name: "TOMINAWOG",
            },
          ],
        },
        SIPOCOT: {
          name: "SIPOCOT",
          baranggays: [
            {
              name: "ALDEZAR",
            },
            {
              name: "ALTEZA",
            },
            {
              name: "ANIB",
            },
            {
              name: "AWAYAN",
            },
            {
              name: "AZUCENA",
            },
            {
              name: "BAGONG SIRANG",
            },
            {
              name: "BINAHIAN",
            },
            {
              name: "BOLO NORTE",
            },
            {
              name: "BOLO SUR",
            },
            {
              name: "BULAN",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CABUYAO",
            },
            {
              name: "CAIMA",
            },
            {
              name: "CALAGBANGAN",
            },
            {
              name: "CALAMPINAY",
            },
            {
              name: "CARAYRAYAN",
            },
            {
              name: "COTMO",
            },
            {
              name: "GABI",
            },
            {
              name: "GAONGAN",
            },
            {
              name: "IMPIG",
            },
            {
              name: "LIPILIP",
            },
            {
              name: "LUBIGAN JR.",
            },
            {
              name: "LUBIGAN SR.",
            },
            {
              name: "MALAGUICO",
            },
            {
              name: "MALUBAGO",
            },
            {
              name: "MANANGLE",
            },
            {
              name: "MANGAPO",
            },
            {
              name: "MANGGA",
            },
            {
              name: "MANLUBANG",
            },
            {
              name: "MANTILA",
            },
            {
              name: "NORTH CENTRO (POB.)",
            },
            {
              name: "NORTH VILLAZAR",
            },
            {
              name: "SAGRADA FAMILIA",
            },
            {
              name: "SALANDA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SERRANZANA",
            },
            {
              name: "SIPOCOT",
            },
            {
              name: "SOUTH CENTRO (POB.)",
            },
            {
              name: "SOUTH VILLAZAR",
            },
            {
              name: "TAISAN",
            },
            {
              name: "TARA",
            },
            {
              name: "TIBLE",
            },
            {
              name: "TULA-TULA",
            },
            {
              name: "VIGAAN",
            },
            {
              name: "YABO",
            },
          ],
        },
        SIRUMA: {
          name: "SIRUMA",
          baranggays: [
            {
              name: "BAGONG SIRANG",
            },
            {
              name: "BAHAO",
            },
            {
              name: "BOBOAN",
            },
            {
              name: "BUTAWANAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "FUNDADO",
            },
            {
              name: "HOMESTEAD",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALACONINI",
            },
            {
              name: "MATANDANG SIRUMA",
            },
            {
              name: "NALAYAHAN",
            },
            {
              name: "PAMINTAN-BANTILAN",
            },
            {
              name: "PINITAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN RAMON (DALDAGON)",
            },
            {
              name: "SIRUMA",
            },
            {
              name: "SULPA",
            },
            {
              name: "TANDOC",
            },
            {
              name: "TONGO-BANTIGUE",
            },
            {
              name: "VITO",
            },
          ],
        },
        TIGAON: {
          name: "TIGAON",
          baranggays: [
            {
              name: "ABO",
            },
            {
              name: "CABALINADAN",
            },
            {
              name: "CARAYCAYON",
            },
            {
              name: "CASUNA",
            },
            {
              name: "CONSOCEP",
            },
            {
              name: "COYAOYAO",
            },
            {
              name: "GAAO",
            },
            {
              name: "GINGAROY",
            },
            {
              name: "GUBAT",
            },
            {
              name: "HUYONHUYON",
            },
            {
              name: "LIBOD",
            },
            {
              name: "MABALODBALOD",
            },
            {
              name: "MAY-ANAO",
            },
            {
              name: "PANAGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "TALOJONGON",
            },
            {
              name: "TIGAON",
            },
            {
              name: "TINAWAGAN",
            },
            {
              name: "VINAGRE",
            },
          ],
        },
        TINAMBAC: {
          name: "TINAMBAC",
          baranggays: [
            {
              name: "AGAY-AYAN",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BANGA",
            },
            {
              name: "BANI",
            },
            {
              name: "BATAAN",
            },
            {
              name: "BINALAY",
            },
            {
              name: "BOLAOBALITE",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUYO",
            },
            {
              name: "CAGLILIOG",
            },
            {
              name: "CALOCO",
            },
            {
              name: "CAMAGONG",
            },
            {
              name: "CANAYONAN",
            },
            {
              name: "CAWAYNAN",
            },
            {
              name: "DALIGAN",
            },
            {
              name: "FILARCA (POB.)",
            },
            {
              name: "LA MEDALLA (MILE 9)",
            },
            {
              name: "LA PURISIMA (POB.)",
            },
            {
              name: "LUPI",
            },
            {
              name: "MAGSAYSAY (CAMP 4)",
            },
            {
              name: "MAGTANG",
            },
            {
              name: "MANANAO",
            },
            {
              name: "NEW CAALUAN",
            },
            {
              name: "OLAG GRANDE",
            },
            {
              name: "OLAG PEQUEÑO",
            },
            {
              name: "OLD CAALUAN",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PANTAT",
            },
            {
              name: "SAGRADA (CAMP 6)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SALVACION POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN JOSE (TILTILAN)",
            },
            {
              name: "SAN PASCUAL (POB.)",
            },
            {
              name: "SAN RAMON (CAMP 7)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SOGOD",
            },
            {
              name: "TAMBANG",
            },
            {
              name: "TIERRA NEVADA",
            },
            {
              name: "TINAMBAC",
            },
            {
              name: "UNION",
            },
          ],
        },
      },
    },
    CAMIGUIN: {
      name: "CAMIGUIN",
      region: "REGION 10",
      municipalities: {
        CAMIGUIN: {
          name: "CAMIGUIN",
          baranggays: [
            {
              name: "CAMIGUIN",
            },
          ],
        },
        CATARMAN: {
          name: "CATARMAN",
          baranggays: [
            {
              name: "CATARMAN",
            },
          ],
        },
        GUINSILIBAN: {
          name: "GUINSILIBAN",
          baranggays: [
            {
              name: "GUINSILIBAN",
            },
          ],
        },
        MAHINOG: {
          name: "MAHINOG",
          baranggays: [
            {
              name: "MAHINOG",
            },
          ],
        },
        MAMBAJAO: {
          name: "MAMBAJAO",
          baranggays: [
            {
              name: "AGOHO",
            },
            {
              name: "ANITO",
            },
            {
              name: "BALBAGON",
            },
            {
              name: "BAYLAO",
            },
            {
              name: "BENHAAN",
            },
            {
              name: "BUG-ONG",
            },
            {
              name: "KUGUITA",
            },
            {
              name: "MAGTING",
            },
            {
              name: "MAMBAJAO",
            },
            {
              name: "NAASAG",
            },
            {
              name: "PANDAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SORO-SORO",
            },
            {
              name: "TAGDO",
            },
            {
              name: "TUPSAN",
            },
            {
              name: "YUMBING",
            },
          ],
        },
        SAGAY: {
          name: "SAGAY",
          baranggays: [
            {
              name: "ALANGILAN",
            },
            {
              name: "BACNIT",
            },
            {
              name: "BALITE",
            },
            {
              name: "BONBON",
            },
            {
              name: "BUGANG",
            },
            {
              name: "CUNA",
            },
            {
              name: "MANUYOG",
            },
            {
              name: "MAYANA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGAY",
            },
          ],
        },
      },
    },
    CAPIZ: {
      name: "CAPIZ",
      region: "REGION 6",
      municipalities: {
        CUARTERO: {
          name: "CUARTERO",
          baranggays: [
            {
              name: "AGCABUGAO",
            },
            {
              name: "AGDAHON",
            },
            {
              name: "AGNAGA",
            },
            {
              name: "ANGUB",
            },
            {
              name: "BALINGASAG",
            },
            {
              name: "BITO-ON ILAWOD",
            },
            {
              name: "BITO-ON ILAYA",
            },
            {
              name: "BUN-OD",
            },
            {
              name: "CARATAYA",
            },
            {
              name: "CUARTERO",
            },
            {
              name: "LUNAYAN",
            },
            {
              name: "MAHABANG SAPA",
            },
            {
              name: "MAHUNODHUNOD",
            },
            {
              name: "MAINDANG",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MALAGAB-I",
            },
            {
              name: "NAGBA",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "POBLACION TAKAS",
            },
            {
              name: "PUTI-AN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SINABSABAN",
            },
          ],
        },
        DAO: {
          name: "DAO",
          baranggays: [
            {
              name: "AGANAN",
            },
            {
              name: "AGTAMBI",
            },
            {
              name: "AGTANGUAY",
            },
            {
              name: "BALUCUAN",
            },
            {
              name: "BITA",
            },
            {
              name: "CENTRO",
            },
            {
              name: "DAO",
            },
            {
              name: "DAPLAS",
            },
            {
              name: "DUYOC",
            },
            {
              name: "ILAS SUR",
            },
            {
              name: "LACARON",
            },
            {
              name: "MALONOY",
            },
            {
              name: "MANHOY",
            },
            {
              name: "MAPULANG BATO",
            },
            {
              name: "MATAGNOP",
            },
            {
              name: "NASUNOGAN",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "QUINABCABAN",
            },
            {
              name: "QUINAYUYA",
            },
            {
              name: "SAN AGUSTIN (ILAS NORTE)",
            },
          ],
        },
        DUMALAG: {
          name: "DUMALAG",
          baranggays: [
            {
              name: "CONCEPCION",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "DOLORES",
            },
            {
              name: "DUMALAG",
            },
            {
              name: "DURAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CARMEN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTA TERESA",
            },
            {
              name: "SANTO ANGEL",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
          ],
        },
        DUMARAO: {
          name: "DUMARAO",
          baranggays: [
            {
              name: "AGBATUAN",
            },
            {
              name: "AGLALANA",
            },
            {
              name: "AGLANOT",
            },
            {
              name: "AGSIRAB",
            },
            {
              name: "ALIPASIAWAN",
            },
            {
              name: "ASTORGA",
            },
            {
              name: "BAYOG",
            },
            {
              name: "BUNGSUAN",
            },
            {
              name: "CALAPAWAN",
            },
            {
              name: "CODINGLE",
            },
            {
              name: "CUBI",
            },
            {
              name: "DACUTON",
            },
            {
              name: "DANGULA",
            },
            {
              name: "DUMARAO",
            },
            {
              name: "GIBATO",
            },
            {
              name: "GUINOTOS",
            },
            {
              name: "JAMBAD",
            },
            {
              name: "JANGUSLOB",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "MALONOY",
            },
            {
              name: "NAGSULANG",
            },
            {
              name: "ONGOL ILAWOD",
            },
            {
              name: "ONGOL ILAYA",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "SAGRADA FAMILIA",
            },
            {
              name: "SALCEDO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SIBARIWAN",
            },
            {
              name: "TAMULALOD",
            },
            {
              name: "TASLAN",
            },
            {
              name: "TINA",
            },
            {
              name: "TINAYTAYAN",
            },
            {
              name: "TRACIANO",
            },
          ],
        },
        IVISAN: {
          name: "IVISAN",
          baranggays: [
            {
              name: "AGMALOBO",
            },
            {
              name: "AGUSTIN NAVARRA",
            },
            {
              name: "BALARING",
            },
            {
              name: "BASIAO",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CUDIAN",
            },
            {
              name: "ILAYA-IVISAN",
            },
            {
              name: "IVISAN",
            },
            {
              name: "MALOCLOC NORTE",
            },
            {
              name: "MALOCLOC SUR",
            },
            {
              name: "MATNOG",
            },
            {
              name: "MIANAY",
            },
            {
              name: "ONDOY",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SANTA CRUZ",
            },
          ],
        },
        JAMINDAN: {
          name: "JAMINDAN",
          baranggays: [
            {
              name: "AGAMBULONG",
            },
            {
              name: "AGBUN-OD",
            },
            {
              name: "AGCAGAY",
            },
            {
              name: "AGLIBACAO",
            },
            {
              name: "AGLOLOWAY",
            },
            {
              name: "BAYEBAYE",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "FE",
            },
            {
              name: "GANZON",
            },
            {
              name: "GUINTAS",
            },
            {
              name: "IGANG",
            },
            {
              name: "JAENA NORTE",
            },
            {
              name: "JAENA SUR",
            },
            {
              name: "JAGNAYA",
            },
            {
              name: "JAMINDAN",
            },
            {
              name: "LAPAZ",
            },
            {
              name: "LINAMBASAN",
            },
            {
              name: "LUCERO",
            },
            {
              name: "MAANTOL",
            },
            {
              name: "MASGRAU",
            },
            {
              name: "MILAN",
            },
            {
              name: "MOLET",
            },
            {
              name: "PANGABAT",
            },
            {
              name: "PANGABUAN",
            },
            {
              name: "PASOL-O",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO ROSARIO",
            },
          ],
        },
        "MA-AYON": {
          name: "MA-AYON",
          baranggays: [
            {
              name: "AGLIMOCON",
            },
            {
              name: "ALASAGING",
            },
            {
              name: "ALAYUNAN",
            },
            {
              name: "BALIGHOT",
            },
            {
              name: "BATABAT",
            },
            {
              name: "BONGBONGAN",
            },
            {
              name: "CABUNGAHAN",
            },
            {
              name: "CANAPIAN",
            },
            {
              name: "CARATAYA",
            },
            {
              name: "DULUAN",
            },
            {
              name: "EAST VILLAFLORES",
            },
            {
              name: "FERNANDEZ",
            },
            {
              name: "GUINBI-ALAN",
            },
            {
              name: "INDAYAGAN",
            },
            {
              name: "JEBACA",
            },
            {
              name: "MA-AYON",
            },
            {
              name: "MAALAN",
            },
            {
              name: "MANAYUPIT",
            },
            {
              name: "NEW GUIA",
            },
            {
              name: "OLD GUIA",
            },
            {
              name: "PALAGUIAN",
            },
            {
              name: "PARALLAN",
            },
            {
              name: "PIÑA",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "POBLACION TABUC",
            },
            {
              name: "QUEVEDO (NGALAN)",
            },
            {
              name: "QUINABONGLAN",
            },
            {
              name: "QUINAT-UYAN",
            },
            {
              name: "SALGAN",
            },
            {
              name: "TAPULANG",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "WEST VILLAFLORES",
            },
          ],
        },
        MAMBUSAO: {
          name: "MAMBUSAO",
          baranggays: [
            {
              name: "ATIPLO",
            },
            {
              name: "BALAT-AN",
            },
            {
              name: "BALIT",
            },
            {
              name: "BATIANO",
            },
            {
              name: "BATING",
            },
            {
              name: "BATO BATO",
            },
            {
              name: "BAYE",
            },
            {
              name: "BERGANTE",
            },
            {
              name: "BULA",
            },
            {
              name: "BUNGA",
            },
            {
              name: "BUNGSI",
            },
            {
              name: "BURIAS",
            },
            {
              name: "CAIDQUID",
            },
            {
              name: "CALA-AGUS",
            },
            {
              name: "LIBO-O",
            },
            {
              name: "MAMBUSAO",
            },
            {
              name: "MANIBAD",
            },
            {
              name: "MARALAG",
            },
            {
              name: "NAJUS-AN",
            },
            {
              name: "PANGPANG NORTE",
            },
            {
              name: "PANGPANG SUR",
            },
            {
              name: "PINAY",
            },
            {
              name: "POBLACION PROPER",
            },
            {
              name: "POBLACION TABUC",
            },
            {
              name: "SINONDOJAN",
            },
            {
              name: "TUGAS",
            },
            {
              name: "TUMALALUD",
            },
          ],
        },
        PANAY: {
          name: "PANAY",
          baranggays: [
            {
              name: "AGBALO",
            },
            {
              name: "AGBANBAN",
            },
            {
              name: "AGOJO",
            },
            {
              name: "ANHAWON",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAGO CHIQUITO",
            },
            {
              name: "BAGO GRANDE",
            },
            {
              name: "BAHIT",
            },
            {
              name: "BANTIQUE",
            },
            {
              name: "BATO",
            },
            {
              name: "BINANGIG",
            },
            {
              name: "BINANTUAN",
            },
            {
              name: "BONGA",
            },
            {
              name: "BUNTOD",
            },
            {
              name: "BUTACAL",
            },
            {
              name: "CABUGAO ESTE",
            },
            {
              name: "CABUGAO OESTE",
            },
            {
              name: "CALAPAWAN",
            },
            {
              name: "CALITAN",
            },
            {
              name: "CANDUAL",
            },
            {
              name: "COGON",
            },
            {
              name: "DAGA",
            },
            {
              name: "ILAMNAY",
            },
            {
              name: "JAMUL-AWON",
            },
            {
              name: "LANIPGA",
            },
            {
              name: "LAT-ASAN",
            },
            {
              name: "LIBON",
            },
            {
              name: "LINAO",
            },
            {
              name: "LINATERAN",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "LUS-ONAN",
            },
            {
              name: "MAGUBILAN",
            },
            {
              name: "NAVITAS",
            },
            {
              name: "PANAY",
            },
            {
              name: "PAWA",
            },
            {
              name: "PILI",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "POBLACION TABUC",
            },
            {
              name: "TALASA",
            },
            {
              name: "TANZA NORTE",
            },
            {
              name: "TANZA SUR",
            },
            {
              name: "TICO",
            },
          ],
        },
        PANITAN: {
          name: "PANITAN",
          baranggays: [
            {
              name: "AGBABADIANG",
            },
            {
              name: "AGKILO",
            },
            {
              name: "AGLOWAY",
            },
            {
              name: "AMBILAY",
            },
            {
              name: "BAHIT",
            },
            {
              name: "BALATUCAN",
            },
            {
              name: "BANGA-AN",
            },
            {
              name: "CABANGAHAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CADIO",
            },
            {
              name: "CALA-AN",
            },
            {
              name: "CAPAGAO",
            },
            {
              name: "COGON",
            },
            {
              name: "CONCIENCIA",
            },
            {
              name: "ENSENAGAN",
            },
            {
              name: "INTAMPILAN",
            },
            {
              name: "PANITAN",
            },
            {
              name: "PASUGUE",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "QUIOS",
            },
            {
              name: "SALOCON",
            },
            {
              name: "TABUC NORTE",
            },
            {
              name: "TABUC SUR",
            },
            {
              name: "TIMPAS",
            },
            {
              name: "TINCUPON",
            },
            {
              name: "TINIGBAN",
            },
          ],
        },
        PILAR: {
          name: "PILAR",
          baranggays: [
            {
              name: "BALOGO",
            },
            {
              name: "BINAOBAWAN",
            },
            {
              name: "BLASCO",
            },
            {
              name: "CASANAYAN",
            },
            {
              name: "CAYUS",
            },
            {
              name: "DAYHAGAN",
            },
            {
              name: "DULANGAN",
            },
            {
              name: "MONTEFLOR",
            },
            {
              name: "NATIVIDAD",
            },
            {
              name: "OLALO",
            },
            {
              name: "PILAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BLAS",
            },
            {
              name: "SAN ESTEBAN",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN SILVESTRE",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SINAMONGAN",
            },
            {
              name: "TABUN-ACAN",
            },
            {
              name: "YATING",
            },
          ],
        },
        PONTEVEDRA: {
          name: "PONTEVEDRA",
          baranggays: [
            {
              name: "AGBANOG",
            },
            {
              name: "AGDALIPE",
            },
            {
              name: "AMELIGAN",
            },
            {
              name: "BAILAN",
            },
            {
              name: "BANATE",
            },
            {
              name: "BANTIGUE",
            },
            {
              name: "BINUNTUCAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "GABUC (CAUGIAT)",
            },
            {
              name: "GUBA",
            },
            {
              name: "HIPONA",
            },
            {
              name: "ILAWOD (POB.)",
            },
            {
              name: "ILAYA (POB.)",
            },
            {
              name: "INTUNGCAN",
            },
            {
              name: "JOLONGAJOG",
            },
            {
              name: "LANTANGAN",
            },
            {
              name: "LINAMPONGAN",
            },
            {
              name: "MALAG-IT",
            },
            {
              name: "MANAPAO",
            },
            {
              name: "PONTEVEDRA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SOLO",
            },
            {
              name: "SUBLANGON",
            },
            {
              name: "TABUC",
            },
            {
              name: "TACAS",
            },
            {
              name: "YATINGAN",
            },
          ],
        },
        "PRESIDENT ROXAS": {
          name: "PRESIDENT ROXAS",
          baranggays: [
            {
              name: "ARANGUEL",
            },
            {
              name: "BADIANGON",
            },
            {
              name: "BAYUYAN",
            },
            {
              name: "CABUGCABUG",
            },
            {
              name: "CARMENCITA",
            },
            {
              name: "CUBAY",
            },
            {
              name: "CULILANG",
            },
            {
              name: "GOCE",
            },
            {
              name: "HANGLID",
            },
            {
              name: "IBACA",
            },
            {
              name: "MADULANO",
            },
            {
              name: "MANOLING",
            },
            {
              name: "MARITA",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PANTALAN CABUGCABUG",
            },
            {
              name: "PINAMIHAGAN",
            },
            {
              name: "POBLACION (ELIZALDE)",
            },
            {
              name: "PONDOL",
            },
            {
              name: "PRESIDENT ROXAS",
            },
            {
              name: "QUIAJO",
            },
            {
              name: "SANGKAL",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "VIZCAYA",
            },
          ],
        },
        "ROXAS CITY": {
          name: "ROXAS CITY",
          baranggays: [
            {
              name: "ADLAWAN",
            },
            {
              name: "BAGO",
            },
            {
              name: "BALIJUAGAN",
            },
            {
              name: "BANICA",
            },
            {
              name: "BARRA",
            },
            {
              name: "BATO",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BOLO",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAGAY",
            },
            {
              name: "COGON",
            },
            {
              name: "CULAJAO",
            },
            {
              name: "CULASI",
            },
            {
              name: "DAYAO",
            },
            {
              name: "DINGINAN",
            },
            {
              name: "DUMOLOG",
            },
            {
              name: "GABU-AN",
            },
            {
              name: "INZO ARNALDO VILLAGE (CADIMAHAN)",
            },
            {
              name: "JUMAGUICJIC",
            },
            {
              name: "LANOT",
            },
            {
              name: "LAWA-AN",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LIONG",
            },
            {
              name: "LOCTUGAN",
            },
            {
              name: "LONOY",
            },
            {
              name: "MILIBILI",
            },
            {
              name: "MONGPONG",
            },
            {
              name: "OLOTAYAN",
            },
            {
              name: "POBLACION I (BARANGAY I)",
            },
            {
              name: "POBLACION II (BARANGAY II)",
            },
            {
              name: "POBLACION III (BARANGAY III)",
            },
            {
              name: "POBLACION IV (BARANGAY IV)",
            },
            {
              name: "POBLACION IX (BARANGAY IX)",
            },
            {
              name: "POBLACION V (BARANGAY V)",
            },
            {
              name: "POBLACION VI (BARANGAY VI)",
            },
            {
              name: "POBLACION VII (BARANGAY VII)",
            },
            {
              name: "POBLACION VIII (BARANGAY VIII)",
            },
            {
              name: "POBLACION X (BARANGAY X)",
            },
            {
              name: "POBLACION XI (BARANGAY XI)",
            },
            {
              name: "PUNTA COGON",
            },
            {
              name: "PUNTA TABUC",
            },
            {
              name: "ROXAS CITY",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SIBAGUAN",
            },
            {
              name: "TALON",
            },
            {
              name: "TANQUE",
            },
            {
              name: "TANZA",
            },
            {
              name: "TIZA",
            },
          ],
        },
        "SAPI-AN": {
          name: "SAPI-AN",
          baranggays: [
            {
              name: "AGSILAB",
            },
            {
              name: "AGTATACAY NORTE",
            },
            {
              name: "AGTATACAY SUR",
            },
            {
              name: "BILAO",
            },
            {
              name: "DAMAYAN",
            },
            {
              name: "DAPDAPAN",
            },
            {
              name: "LONOY",
            },
            {
              name: "MAJANLUD",
            },
            {
              name: "MANINANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAPI-AN",
            },
          ],
        },
        SIGMA: {
          name: "SIGMA",
          baranggays: [
            {
              name: "ACBO",
            },
            {
              name: "AMAGA",
            },
            {
              name: "BALUCUAN",
            },
            {
              name: "BANGONBANGON",
            },
            {
              name: "CAPUYHAN",
            },
            {
              name: "COGON",
            },
            {
              name: "DAYHAGON",
            },
            {
              name: "GUINTAS",
            },
            {
              name: "MALAPAD COGON",
            },
            {
              name: "MANGOSO",
            },
            {
              name: "MANSACUL",
            },
            {
              name: "MATANGCONG",
            },
            {
              name: "MATINABUS",
            },
            {
              name: "MIANAY",
            },
            {
              name: "OYONG",
            },
            {
              name: "PAGBUNITAN",
            },
            {
              name: "PARIAN",
            },
            {
              name: "PINAMALATICAN",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SIGMA",
            },
            {
              name: "TAWOG",
            },
          ],
        },
        TAPAZ: {
          name: "TAPAZ",
          baranggays: [
            {
              name: "ABANGAY",
            },
            {
              name: "ACUÑA",
            },
            {
              name: "AGCOCOC",
            },
            {
              name: "AGLINAB",
            },
            {
              name: "AGLUPACAN",
            },
            {
              name: "AGPALALI",
            },
            {
              name: "APERO",
            },
            {
              name: "ARTUZ",
            },
            {
              name: "BAG-ONG BARRIO",
            },
            {
              name: "BATO-BATO",
            },
            {
              name: "BURI",
            },
            {
              name: "CAMBURANAN",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "CARIDA",
            },
            {
              name: "CRISTINA",
            },
            {
              name: "DA-AN BANWA",
            },
            {
              name: "DA-AN NORTE",
            },
            {
              name: "DA-AN SUR",
            },
            {
              name: "GARCIA",
            },
            {
              name: "GEBIO-AN",
            },
            {
              name: "HILWAN",
            },
            {
              name: "INITAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LAGDUNGAN",
            },
            {
              name: "LAHUG",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALIAO",
            },
            {
              name: "MALITBOG",
            },
            {
              name: "MINAN",
            },
            {
              name: "NAYAWAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL NORTE",
            },
            {
              name: "RIZAL SUR",
            },
            {
              name: "ROOSEVELT",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SALONG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SAN MIGUEL ILAWOD",
            },
            {
              name: "SAN MIGUEL ILAYA",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA PETRONILA",
            },
            {
              name: "SENONOD",
            },
            {
              name: "SIYA",
            },
            {
              name: "SWITCH",
            },
            {
              name: "TABON",
            },
            {
              name: "TACAYAN",
            },
            {
              name: "TAFT",
            },
            {
              name: "TAGANGHIN",
            },
            {
              name: "TAPAZ",
            },
            {
              name: "TASLAN",
            },
            {
              name: "WRIGHT",
            },
          ],
        },
      },
    },
    CAVITE: {
      name: "CAVITE",
      region: "REGION 4-A",
      municipalities: {
        ALFONSO: {
          name: "ALFONSO",
          baranggays: [
            {
              name: "ALFONSO",
            },
            {
              name: "AMUYONG",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BILOG",
            },
            {
              name: "BUCK ESTATE",
            },
            {
              name: "ESPERANZA IBABA",
            },
            {
              name: "ESPERANZA ILAYA",
            },
            {
              name: "KAYSUYO",
            },
            {
              name: "KAYTITINGA I",
            },
            {
              name: "KAYTITINGA II",
            },
            {
              name: "KAYTITINGA III",
            },
            {
              name: "LUKSUHIN IBABA",
            },
            {
              name: "LUKSUHIN ILAYA",
            },
            {
              name: "MANGAS I",
            },
            {
              name: "MANGAS II",
            },
            {
              name: "MARAHAN I",
            },
            {
              name: "MARAHAN II",
            },
            {
              name: "MATAGBAK I",
            },
            {
              name: "MATAGBAK II",
            },
            {
              name: "PAJO",
            },
            {
              name: "PALUMLUM",
            },
            {
              name: "SANTA TERESA",
            },
            {
              name: "SIKAT",
            },
            {
              name: "SINALIW MALAKI",
            },
            {
              name: "SINALIW NA MUNTI",
            },
            {
              name: "SULSUGIN",
            },
            {
              name: "TAYWANAK IBABA",
            },
            {
              name: "TAYWANAK ILAYA",
            },
            {
              name: "UPLI",
            },
          ],
        },
        AMADEO: {
          name: "AMADEO",
          baranggays: [
            {
              name: "AMADEO",
            },
            {
              name: "BANAYBANAY",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY IX (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "BARANGAY VII (POB.)",
            },
            {
              name: "BARANGAY VIII (POB.)",
            },
            {
              name: "BARANGAY X (POB.)",
            },
            {
              name: "BARANGAY XI (POB.)",
            },
            {
              name: "BARANGAY XII (POB.)",
            },
            {
              name: "BUCAL",
            },
            {
              name: "BUHO",
            },
            {
              name: "DAGATAN",
            },
            {
              name: "HALANG",
            },
            {
              name: "LOMA",
            },
            {
              name: "MAITIM I",
            },
            {
              name: "MAYMANGGA",
            },
            {
              name: "MINANTOK KANLURAN",
            },
            {
              name: "MINANTOK SILANGAN",
            },
            {
              name: "PANGIL",
            },
            {
              name: "SALABAN",
            },
            {
              name: "TALON",
            },
            {
              name: "TAMACAN",
            },
          ],
        },
        "BACOOR CITY": {
          name: "BACOOR CITY",
          baranggays: [
            {
              name: "ALIMA",
            },
            {
              name: "ANIBAN I",
            },
            {
              name: "ANIBAN II",
            },
            {
              name: "ANIBAN III",
            },
            {
              name: "ANIBAN IV",
            },
            {
              name: "ANIBAN V",
            },
            {
              name: "BACOOR CITY",
            },
            {
              name: "BANALO",
            },
            {
              name: "BAYANAN",
            },
            {
              name: "CAMPO SANTO",
            },
            {
              name: "DAANG BUKID",
            },
            {
              name: "DIGMAN",
            },
            {
              name: "DULONG BAYAN",
            },
            {
              name: "HABAY I",
            },
            {
              name: "HABAY II",
            },
            {
              name: "KAINGIN (POB.)",
            },
            {
              name: "LIGAS I",
            },
            {
              name: "LIGAS II",
            },
            {
              name: "LIGAS III",
            },
            {
              name: "MABOLO I",
            },
            {
              name: "MABOLO II",
            },
            {
              name: "MABOLO III",
            },
            {
              name: "MALIKSI I",
            },
            {
              name: "MALIKSI II",
            },
            {
              name: "MALIKSI III",
            },
            {
              name: "MAMBOG I",
            },
            {
              name: "MAMBOG II",
            },
            {
              name: "MAMBOG III",
            },
            {
              name: "MAMBOG IV",
            },
            {
              name: "MAMBOG V",
            },
            {
              name: "MOLINO I",
            },
            {
              name: "MOLINO II",
            },
            {
              name: "MOLINO III",
            },
            {
              name: "MOLINO IV",
            },
            {
              name: "MOLINO V",
            },
            {
              name: "MOLINO VI",
            },
            {
              name: "MOLINO VII",
            },
            {
              name: "NIOG I",
            },
            {
              name: "NIOG II",
            },
            {
              name: "NIOG III",
            },
            {
              name: "P.F. ESPIRITU I (PANAPAAN)",
            },
            {
              name: "P.F. ESPIRITU II",
            },
            {
              name: "P.F. ESPIRITU III",
            },
            {
              name: "P.F. ESPIRITU IV",
            },
            {
              name: "P.F. ESPIRITU V",
            },
            {
              name: "P.F. ESPIRITU VI",
            },
            {
              name: "P.F. ESPIRITU VII",
            },
            {
              name: "P.F. ESPIRITU VIII",
            },
            {
              name: "QUEENS ROW CENTRAL",
            },
            {
              name: "QUEENS ROW EAST",
            },
            {
              name: "QUEENS ROW WEST",
            },
            {
              name: "REAL I",
            },
            {
              name: "REAL II",
            },
            {
              name: "SALINAS I",
            },
            {
              name: "SALINAS II",
            },
            {
              name: "SALINAS III",
            },
            {
              name: "SALINAS IV",
            },
            {
              name: "SAN NICOLAS I",
            },
            {
              name: "SAN NICOLAS II",
            },
            {
              name: "SAN NICOLAS III",
            },
            {
              name: "SINEGUELASAN",
            },
            {
              name: "TABING DAGAT",
            },
            {
              name: "TALABA I",
            },
            {
              name: "TALABA II",
            },
            {
              name: "TALABA III",
            },
            {
              name: "TALABA IV",
            },
            {
              name: "TALABA V",
            },
            {
              name: "TALABA VI",
            },
            {
              name: "TALABA VII",
            },
            {
              name: "ZAPOTE I",
            },
            {
              name: "ZAPOTE II",
            },
            {
              name: "ZAPOTE III",
            },
            {
              name: "ZAPOTE IV",
            },
            {
              name: "ZAPOTE V",
            },
          ],
        },
        CARMONA: {
          name: "CARMONA",
          baranggays: [
            {
              name: "BANCAL",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "CABILANG BAYBAY",
            },
            {
              name: "CARMONA",
            },
            {
              name: "LANTIC",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MADUYA",
            },
            {
              name: "MILAGROSA",
            },
          ],
        },
        "CAVITE CITY": {
          name: "CAVITE CITY",
          baranggays: [
            {
              name: "BARANGAY 1 (HEN. M. ALVAREZ)",
            },
            {
              name: "BARANGAY 10 (KINGFISHER)",
            },
            {
              name: "BARANGAY 10-A (KINGFISHER-A)",
            },
            {
              name: "BARANGAY 10-B (KINGFISHER-B)",
            },
            {
              name: "BARANGAY 11 (LAWIN)",
            },
            {
              name: "BARANGAY 12 (LOVE BIRD)",
            },
            {
              name: "BARANGAY 13 (AGUILA)",
            },
            {
              name: "BARANGAY 14 (LORO)",
            },
            {
              name: "BARANGAY 15 (KILYAWAN)",
            },
            {
              name: "BARANGAY 16 (MARTINES)",
            },
            {
              name: "BARANGAY 17 (KALAPATI)",
            },
            {
              name: "BARANGAY 18 (MAYA)",
            },
            {
              name: "BARANGAY 19 (GEMINI)",
            },
            {
              name: "BARANGAY 2 (C. TIRONA)",
            },
            {
              name: "BARANGAY 20 (VIRGO)",
            },
            {
              name: "BARANGAY 21 (SCORPIO)",
            },
            {
              name: "BARANGAY 22 (LEO)",
            },
            {
              name: "BARANGAY 22-A (LEO A)",
            },
            {
              name: "BARANGAY 23 (AQUARIUS)",
            },
            {
              name: "BARANGAY 24 (LIBRA)",
            },
            {
              name: "BARANGAY 25 (CAPRICORN)",
            },
            {
              name: "BARANGAY 26 (CANCER)",
            },
            {
              name: "BARANGAY 27 (SAGITARIUS)",
            },
            {
              name: "BARANGAY 28 (TAURUS)",
            },
            {
              name: "BARANGAY 29 (LAO-LAO)",
            },
            {
              name: "BARANGAY 29-A (LAO-LAO A)",
            },
            {
              name: "BARANGAY 3 (HEN. E. AGUINALDO)",
            },
            {
              name: "BARANGAY 30 (BID-BID)",
            },
            {
              name: "BARANGAY 31 (MAYA-MAYA)",
            },
            {
              name: "BARANGAY 32 (SALAY-SALAY)",
            },
            {
              name: "BARANGAY 33 (BUWAN-BUWAN)",
            },
            {
              name: "BARANGAY 34 (LAPU-LAPU)",
            },
            {
              name: "BARANGAY 35 (HASA-HASA)",
            },
            {
              name: "BARANGAY 36 (SAP-SAP)",
            },
            {
              name: "BARANGAY 36-A (SAP-SAP A)",
            },
            {
              name: "BARANGAY 37 (CADENA DE AMOR)",
            },
            {
              name: "BARANGAY 37-A (CADENA DE AMOR A)",
            },
            {
              name: "BARANGAY 38 (SAMPAGUITA)",
            },
            {
              name: "BARANGAY 38-A (SAMPAGUITA A)",
            },
            {
              name: "BARANGAY 39 (JASMIN)",
            },
            {
              name: "BARANGAY 4 (HEN. M. TRIAS)",
            },
            {
              name: "BARANGAY 40 (GUMAMELA)",
            },
            {
              name: "BARANGAY 41 (ROSAL)",
            },
            {
              name: "BARANGAY 42 (PINAGBUKLOD)",
            },
            {
              name: "BARANGAY 42-A (PINAGBUKLOD A)",
            },
            {
              name: "BARANGAY 42-B (PINAGBUKLOD B)",
            },
            {
              name: "BARANGAY 42-C (PINAGBUKLOD C)",
            },
            {
              name: "BARANGAY 43 (PINAGPALA)",
            },
            {
              name: "BARANGAY 44 (MALIGAYA)",
            },
            {
              name: "BARANGAY 45 (KAUNLARAN)",
            },
            {
              name: "BARANGAY 45-A (KAUNLARAN A)",
            },
            {
              name: "BARANGAY 46 (SINAGTALA)",
            },
            {
              name: "BARANGAY 47 (PAGKAKAISA)",
            },
            {
              name: "BARANGAY 47-A (PAGKAKAISA A)",
            },
            {
              name: "BARANGAY 47-B (PAGKAKAISA B)",
            },
            {
              name: "BARANGAY 48 (NARRA)",
            },
            {
              name: "BARANGAY 48-A (NARRA A)",
            },
            {
              name: "BARANGAY 49 (AKASYA)",
            },
            {
              name: "BARANGAY 49-A (AKASYA A)",
            },
            {
              name: "BARANGAY 5 (HEN. E. EVANGELISTA)",
            },
            {
              name: "BARANGAY 50 (KABALYERO)",
            },
            {
              name: "BARANGAY 51 (KAMAGONG)",
            },
            {
              name: "BARANGAY 52 (IPIL)",
            },
            {
              name: "BARANGAY 53 (YAKAL)",
            },
            {
              name: "BARANGAY 53-A (YAKAL A)",
            },
            {
              name: "BARANGAY 53-B (YAKAL B)",
            },
            {
              name: "BARANGAY 54 (PECHAY)",
            },
            {
              name: "BARANGAY 54-A (PECHAY A)",
            },
            {
              name: "BARANGAY 55 (AMPALAYA)",
            },
            {
              name: "BARANGAY 56 (LABANOS)",
            },
            {
              name: "BARANGAY 57 (REPOLYO)",
            },
            {
              name: "BARANGAY 58 (PATOLA)",
            },
            {
              name: "BARANGAY 58-A (PATOLA A)",
            },
            {
              name: "BARANGAY 59 (SITAW)",
            },
            {
              name: "BARANGAY 6 (DIEGO SILANG)",
            },
            {
              name: "BARANGAY 60 (LETSUGAS)",
            },
            {
              name: "BARANGAY 61 (TALONG)",
            },
            {
              name: "BARANGAY 61-A (TALONG A)",
            },
            {
              name: "BARANGAY 62 (KANGKONG)",
            },
            {
              name: "BARANGAY 62-A (KANGKONG A)",
            },
            {
              name: "BARANGAY 62-B (KANGKONG B)",
            },
            {
              name: "BARANGAY 7 (KAPITAN KONG)",
            },
            {
              name: "BARANGAY 8 (MANUEL S. ROJAS)",
            },
            {
              name: "BARANGAY 9 (KANAWAY)",
            },
            {
              name: "CAVITE CITY",
            },
          ],
        },
        "DASMARIÑAS CITY": {
          name: "DASMARIÑAS CITY",
          baranggays: [
            {
              name: "BUROL",
            },
            {
              name: "BUROL I",
            },
            {
              name: "BUROL II",
            },
            {
              name: "BUROL III",
            },
            {
              name: "DASMARIÑAS CITY",
            },
            {
              name: "DATU ESMAEL (BAGO-A-INGUD)",
            },
            {
              name: "EMMANUEL BERGADO I",
            },
            {
              name: "EMMANUEL BERGADO II",
            },
            {
              name: "FATIMA I",
            },
            {
              name: "FATIMA II",
            },
            {
              name: "FATIMA III",
            },
            {
              name: "H-2 (STA. MONICA)",
            },
            {
              name: "LANGKAAN I",
            },
            {
              name: "LANGKAAN II",
            },
            {
              name: "LUZVIMINDA I",
            },
            {
              name: "LUZVIMINDA II",
            },
            {
              name: "PALIPARAN I",
            },
            {
              name: "PALIPARAN II",
            },
            {
              name: "PALIPARAN III",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAINT PETER I",
            },
            {
              name: "SAINT PETER II",
            },
            {
              name: "SALAWAG",
            },
            {
              name: "SALITRAN I",
            },
            {
              name: "SALITRAN II",
            },
            {
              name: "SALITRAN III",
            },
            {
              name: "SALITRAN IV",
            },
            {
              name: "SAMPALOC I",
            },
            {
              name: "SAMPALOC II",
            },
            {
              name: "SAMPALOC III",
            },
            {
              name: "SAMPALOC IV",
            },
            {
              name: "SAMPALOC V",
            },
            {
              name: "SAN AGUSTIN I",
            },
            {
              name: "SAN AGUSTIN II",
            },
            {
              name: "SAN AGUSTIN III",
            },
            {
              name: "SAN ANDRES I",
            },
            {
              name: "SAN ANDRES II",
            },
            {
              name: "SAN ANTONIO DE PADUA I",
            },
            {
              name: "SAN ANTONIO DE PADUA II",
            },
            {
              name: "SAN DIONISIO (BARANGAY 1)",
            },
            {
              name: "SAN ESTEBAN (BARANGAY 4)",
            },
            {
              name: "SAN FRANCISCO I",
            },
            {
              name: "SAN FRANCISCO II",
            },
            {
              name: "SAN ISIDRO LABRADOR I",
            },
            {
              name: "SAN ISIDRO LABRADOR II",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN (SAN JUAN I)",
            },
            {
              name: "SAN LORENZO RUIZ I",
            },
            {
              name: "SAN LORENZO RUIZ II",
            },
            {
              name: "SAN LUIS I",
            },
            {
              name: "SAN LUIS II",
            },
            {
              name: "SAN MANUEL I",
            },
            {
              name: "SAN MANUEL II",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN MIGUEL II",
            },
            {
              name: "SAN NICOLAS I",
            },
            {
              name: "SAN NICOLAS II",
            },
            {
              name: "SAN ROQUE (STA. CRISTINA II)",
            },
            {
              name: "SAN SIMON (BARANGAY 7)",
            },
            {
              name: "SANTA CRISTINA I",
            },
            {
              name: "SANTA CRISTINA II",
            },
            {
              name: "SANTA CRUZ I",
            },
            {
              name: "SANTA CRUZ II",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTA LUCIA (SAN JUAN II)",
            },
            {
              name: "SANTA MARIA (BARANGAY 20)",
            },
            {
              name: "SANTO CRISTO (BARANGAY 3)",
            },
            {
              name: "SANTO NIÑO I",
            },
            {
              name: "SANTO NIÑO II",
            },
            {
              name: "VICTORIA REYES",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE I-B",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
          ],
        },
        "GEN. MARIANO ALVAREZ": {
          name: "GEN. MARIANO ALVAREZ",
          baranggays: [
            {
              name: "ALDIANO OLAES",
            },
            {
              name: "BARANGAY 1 POBLACION (AREA I)",
            },
            {
              name: "BARANGAY 2 POBLACION",
            },
            {
              name: "BARANGAY 3 POBLACION",
            },
            {
              name: "BARANGAY 4 POBLACION",
            },
            {
              name: "BARANGAY 5 POBLACION",
            },
            {
              name: "BENJAMIN TIRONA (AREA D)",
            },
            {
              name: "BERNARDO PULIDO (AREA H)",
            },
            {
              name: "EPIFANIO MALIA",
            },
            {
              name: "FIORELLO CARIMAG (AREA C)",
            },
            {
              name: "FRANCISCO DE CASTRO (SUNSHINE VILL.)",
            },
            {
              name: "FRANCISCO REYES",
            },
            {
              name: "GAVINO MADERAN",
            },
            {
              name: "GEN. MARIANO ALVAREZ",
            },
            {
              name: "GREGORIA DE JESUS",
            },
            {
              name: "INOCENCIO SALUD",
            },
            {
              name: "JACINTO LUMBRERAS",
            },
            {
              name: "KAPITAN KUA (AREA F)",
            },
            {
              name: "KORONEL JOSE P. ELISES (AREA E)",
            },
            {
              name: "MACARIO DACON",
            },
            {
              name: "MARCELINO MEMIJE",
            },
            {
              name: "NICOLASA VIRATA (SAN JOSE)",
            },
            {
              name: "PANTALEON GRANADOS (AREA G)",
            },
            {
              name: "RAMON CRUZ (AREA J)",
            },
            {
              name: "SAN GABRIEL (AREA K)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SEVERINO DE LAS ALAS",
            },
            {
              name: "TINIENTE TIAGO",
            },
          ],
        },
        "GENERAL EMILIO AGUINALDO": {
          name: "GENERAL EMILIO AGUINALDO",
          baranggays: [
            {
              name: "A. DALUSAG",
            },
            {
              name: "BATAS DAO",
            },
            {
              name: "CASTAÑOS CERCA",
            },
            {
              name: "CASTAÑOS LEJOS",
            },
            {
              name: "GENERAL EMILIO AGUINALDO",
            },
            {
              name: "KABULUSAN",
            },
            {
              name: "KAYMISAS",
            },
            {
              name: "KAYPAABA",
            },
            {
              name: "LUMIPA",
            },
            {
              name: "NARVAEZ",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "TABORA",
            },
          ],
        },
        "GENERAL TRIAS CITY": {
          name: "GENERAL TRIAS CITY",
          baranggays: [
            {
              name: "ALINGARO",
            },
            {
              name: "ARNALDO POB. (BGY. 7)",
            },
            {
              name: "BACAO I",
            },
            {
              name: "BACAO II",
            },
            {
              name: "BAGUMBAYAN POB. (BGY. 5)",
            },
            {
              name: "BICLATAN",
            },
            {
              name: "BUENAVISTA I",
            },
            {
              name: "BUENAVISTA II",
            },
            {
              name: "BUENAVISTA III",
            },
            {
              name: "CORREGIDOR POB. (BGY. 10)",
            },
            {
              name: "DULONG BAYAN POB. (BGY. 3)",
            },
            {
              name: "GENERAL TRIAS CITY",
            },
            {
              name: "GOV. FERRER POB. (BGY. 1)",
            },
            {
              name: "JAVALERA",
            },
            {
              name: "MANGGAHAN",
            },
            {
              name: "NAVARRO",
            },
            {
              name: "NINETY SIXTH POB. (BGY. 8)",
            },
            {
              name: "PANUNGYANAN",
            },
            {
              name: "PASONG CAMACHILE I",
            },
            {
              name: "PASONG CAMACHILE II",
            },
            {
              name: "PASONG KAWAYAN I",
            },
            {
              name: "PASONG KAWAYAN II",
            },
            {
              name: "PINAGTIPUNAN",
            },
            {
              name: "PRINZA POB. (BGY. 9)",
            },
            {
              name: "SAMPALUCAN POB. (BGY. 2)",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN GABRIEL POB. (BGY. 4)",
            },
            {
              name: "SAN JUAN I",
            },
            {
              name: "SAN JUAN II",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "TAPIA",
            },
            {
              name: "TEJERO",
            },
            {
              name: "VIBORA POB. (BGY. 6)",
            },
          ],
        },
        "IMUS CITY": {
          name: "IMUS CITY",
          baranggays: [
            {
              name: "ALAPAN I-A",
            },
            {
              name: "ALAPAN I-B",
            },
            {
              name: "ALAPAN I-C",
            },
            {
              name: "ALAPAN II-A",
            },
            {
              name: "ALAPAN II-B",
            },
            {
              name: "ANABU I-A",
            },
            {
              name: "ANABU I-B",
            },
            {
              name: "ANABU I-C",
            },
            {
              name: "ANABU I-D",
            },
            {
              name: "ANABU I-E",
            },
            {
              name: "ANABU I-F",
            },
            {
              name: "ANABU I-G",
            },
            {
              name: "ANABU II-A",
            },
            {
              name: "ANABU II-B",
            },
            {
              name: "ANABU II-C",
            },
            {
              name: "ANABU II-D",
            },
            {
              name: "ANABU II-E",
            },
            {
              name: "ANABU II-F",
            },
            {
              name: "BAGONG SILANG (BAHAYANG PAG-ASA)",
            },
            {
              name: "BAYAN LUMA I",
            },
            {
              name: "BAYAN LUMA II",
            },
            {
              name: "BAYAN LUMA III",
            },
            {
              name: "BAYAN LUMA IV",
            },
            {
              name: "BAYAN LUMA IX",
            },
            {
              name: "BAYAN LUMA V",
            },
            {
              name: "BAYAN LUMA VI",
            },
            {
              name: "BAYAN LUMA VII",
            },
            {
              name: "BAYAN LUMA VIII",
            },
            {
              name: "BUCANDALA I",
            },
            {
              name: "BUCANDALA II",
            },
            {
              name: "BUCANDALA III",
            },
            {
              name: "BUCANDALA IV",
            },
            {
              name: "BUCANDALA V",
            },
            {
              name: "BUHAY NA TUBIG",
            },
            {
              name: "CARSADANG BAGO I",
            },
            {
              name: "CARSADANG BAGO II",
            },
            {
              name: "IMUS CITY",
            },
            {
              name: "MAGDALO",
            },
            {
              name: "MAHARLIKA",
            },
            {
              name: "MALAGASANG I-A",
            },
            {
              name: "MALAGASANG I-B",
            },
            {
              name: "MALAGASANG I-C",
            },
            {
              name: "MALAGASANG I-D",
            },
            {
              name: "MALAGASANG I-E",
            },
            {
              name: "MALAGASANG I-F",
            },
            {
              name: "MALAGASANG I-G",
            },
            {
              name: "MALAGASANG II-A",
            },
            {
              name: "MALAGASANG II-B",
            },
            {
              name: "MALAGASANG II-C",
            },
            {
              name: "MALAGASANG II-D",
            },
            {
              name: "MALAGASANG II-E",
            },
            {
              name: "MALAGASANG II-F",
            },
            {
              name: "MALAGASANG II-G",
            },
            {
              name: "MARIANO ESPELETA I",
            },
            {
              name: "MARIANO ESPELETA II",
            },
            {
              name: "MARIANO ESPELETA III",
            },
            {
              name: "MEDICION I-A",
            },
            {
              name: "MEDICION I-B",
            },
            {
              name: "MEDICION I-C",
            },
            {
              name: "MEDICION I-D",
            },
            {
              name: "MEDICION II-A",
            },
            {
              name: "MEDICION II-B",
            },
            {
              name: "MEDICION II-C",
            },
            {
              name: "MEDICION II-D",
            },
            {
              name: "MEDICION II-E",
            },
            {
              name: "MEDICION II-F",
            },
            {
              name: "PAG-ASA I",
            },
            {
              name: "PAG-ASA II",
            },
            {
              name: "PAG-ASA III",
            },
            {
              name: "PALICO I",
            },
            {
              name: "PALICO II",
            },
            {
              name: "PALICO III",
            },
            {
              name: "PALICO IV",
            },
            {
              name: "PASONG BUAYA I",
            },
            {
              name: "PASONG BUAYA II",
            },
            {
              name: "PINAGBUKLOD",
            },
            {
              name: "POBLACION I-A (POB.)",
            },
            {
              name: "POBLACION I-B",
            },
            {
              name: "POBLACION I-C",
            },
            {
              name: "POBLACION II-A (POB.)",
            },
            {
              name: "POBLACION II-B",
            },
            {
              name: "POBLACION III-A (POB.)",
            },
            {
              name: "POBLACION III-B",
            },
            {
              name: "POBLACION IV-A (POB.)",
            },
            {
              name: "POBLACION IV-B",
            },
            {
              name: "POBLACION IV-C",
            },
            {
              name: "POBLACION IV-D",
            },
            {
              name: "TANZANG LUMA I",
            },
            {
              name: "TANZANG LUMA II",
            },
            {
              name: "TANZANG LUMA III",
            },
            {
              name: "TANZANG LUMA IV (SOUTHERN CITY)",
            },
            {
              name: "TANZANG LUMA V",
            },
            {
              name: "TANZANG LUMA VI",
            },
            {
              name: "TOCLONG I-A",
            },
            {
              name: "TOCLONG I-B",
            },
            {
              name: "TOCLONG I-C",
            },
            {
              name: "TOCLONG II-A",
            },
            {
              name: "TOCLONG II-B",
            },
          ],
        },
        INDANG: {
          name: "INDANG",
          baranggays: [
            {
              name: "AGUS-US",
            },
            {
              name: "ALULOD",
            },
            {
              name: "BANABA CERCA",
            },
            {
              name: "BANABA LEJOS",
            },
            {
              name: "BANCOD",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BUNA CERCA",
            },
            {
              name: "BUNA LEJOS I",
            },
            {
              name: "BUNA LEJOS II",
            },
            {
              name: "CALUMPANG CERCA",
            },
            {
              name: "CALUMPANG LEJOS I",
            },
            {
              name: "CARASUCHI",
            },
            {
              name: "DAINE I",
            },
            {
              name: "DAINE II",
            },
            {
              name: "GUYAM MALAKI",
            },
            {
              name: "GUYAM MUNTI",
            },
            {
              name: "HARASAN",
            },
            {
              name: "INDANG",
            },
            {
              name: "KAYQUIT I",
            },
            {
              name: "KAYQUIT II",
            },
            {
              name: "KAYQUIT III",
            },
            {
              name: "KAYTAMBOG",
            },
            {
              name: "KAYTAPOS",
            },
            {
              name: "LIMBON",
            },
            {
              name: "LUMAMPONG BALAGBAG",
            },
            {
              name: "LUMAMPONG HALAYHAY",
            },
            {
              name: "MAHABANGKAHOY CERCA",
            },
            {
              name: "MAHABANGKAHOY LEJOS",
            },
            {
              name: "MATAAS NA LUPA (CHECKPOINT)",
            },
            {
              name: "PULO",
            },
            {
              name: "TAMBO BALAGBAG",
            },
            {
              name: "TAMBO ILAYA",
            },
            {
              name: "TAMBO KULIT",
            },
            {
              name: "TAMBO MALAKI",
            },
          ],
        },
        KAWIT: {
          name: "KAWIT",
          baranggays: [
            {
              name: "BALSAHAN-BISITA",
            },
            {
              name: "BATONG DALIG",
            },
            {
              name: "BINAKAYAN-APLAYA",
            },
            {
              name: "BINAKAYAN-KANLURAN",
            },
            {
              name: "CONGBALAY-LEGASPI",
            },
            {
              name: "GAHAK",
            },
            {
              name: "KAINGEN",
            },
            {
              name: "KAWIT",
            },
            {
              name: "MAGDALO (PUTOL)",
            },
            {
              name: "MANGGAHAN-LAWIN",
            },
            {
              name: "MARULAS",
            },
            {
              name: "PANAMITAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULVORISTA",
            },
            {
              name: "SAMALA-MARQUEZ",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "TABON I",
            },
            {
              name: "TABON II",
            },
            {
              name: "TABON III",
            },
            {
              name: "TOCLONG",
            },
            {
              name: "TRAMO-BANTAYAN",
            },
            {
              name: "WAKAS I",
            },
            {
              name: "WAKAS II",
            },
          ],
        },
        MAGALLANES: {
          name: "MAGALLANES",
          baranggays: [
            {
              name: "BALIWAG",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BENDITA I",
            },
            {
              name: "BENDITA II",
            },
            {
              name: "CALUANGAN",
            },
            {
              name: "KABULUSAN",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "MEDINA",
            },
            {
              name: "PACHECO",
            },
            {
              name: "RAMIREZ",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "TUA",
            },
            {
              name: "URDANETA",
            },
          ],
        },
        MARAGONDON: {
          name: "MARAGONDON",
          baranggays: [
            {
              name: "BUCAL I",
            },
            {
              name: "BUCAL II",
            },
            {
              name: "BUCAL III A",
            },
            {
              name: "BUCAL III B",
            },
            {
              name: "BUCAL IV A",
            },
            {
              name: "BUCAL IV B",
            },
            {
              name: "CAINGIN POB. (BARANGAY 3)",
            },
            {
              name: "GARITA I A",
            },
            {
              name: "GARITA I B",
            },
            {
              name: "LAYONG MABILOG",
            },
            {
              name: "MABATO",
            },
            {
              name: "MARAGONDON",
            },
            {
              name: "PANTIHAN I (BALAYUNGAN)",
            },
            {
              name: "PANTIHAN II (SAGBAT)",
            },
            {
              name: "PANTIHAN III (POOK NA MUNTI)",
            },
            {
              name: "PANTIHAN IV (POOK NI SARA)",
            },
            {
              name: "PATUNGAN",
            },
            {
              name: "PINAGSANHAN I A",
            },
            {
              name: "PINAGSANHAN I B",
            },
            {
              name: "POBLACION I A",
            },
            {
              name: "POBLACION I B",
            },
            {
              name: "POBLACION II A",
            },
            {
              name: "POBLACION II B",
            },
            {
              name: "SAN MIGUEL I A (CAPUTATAN)",
            },
            {
              name: "SAN MIGUEL I B",
            },
            {
              name: "TALIPUSNGO",
            },
            {
              name: "TULAY KANLURAN",
            },
            {
              name: "TULAY SILANGAN",
            },
          ],
        },
        "MENDEZ (MENDEZ-NUÑEZ)": {
          name: "MENDEZ (MENDEZ-NUÑEZ)",
          baranggays: [
            {
              name: "ANULING CERCA I",
            },
            {
              name: "ANULING CERCA II",
            },
            {
              name: "ANULING LEJOS I (ANULING)",
            },
            {
              name: "ANULING LEJOS II",
            },
            {
              name: "ASIS I",
            },
            {
              name: "ASIS II",
            },
            {
              name: "ASIS III",
            },
            {
              name: "BANAYAD",
            },
            {
              name: "BUKAL",
            },
            {
              name: "GALICIA I",
            },
            {
              name: "GALICIA II",
            },
            {
              name: "GALICIA III",
            },
            {
              name: "MENDEZ (MENDEZ-NUÑEZ)",
            },
            {
              name: "MIGUEL MOJICA",
            },
            {
              name: "PALOCPOC I",
            },
            {
              name: "PALOCPOC II",
            },
            {
              name: "PANUNGYAN I",
            },
            {
              name: "PANUNGYAN II",
            },
            {
              name: "POBLACION I (BARANGAY I)",
            },
            {
              name: "POBLACION II (BARANGAY II)",
            },
            {
              name: "POBLACION III (BARANGAY III)",
            },
            {
              name: "POBLACION IV (BARANGAY IV)",
            },
            {
              name: "POBLACION V (BARANGAY V)",
            },
            {
              name: "POBLACION VI (BARANGAY VI)",
            },
            {
              name: "POBLACION VII (BARANGAY VII)",
            },
          ],
        },
        NAIC: {
          name: "NAIC",
          baranggays: [
            {
              name: "BAGONG KARSADA",
            },
            {
              name: "BALSAHAN",
            },
            {
              name: "BANCAAN",
            },
            {
              name: "BUCANA MALAKI",
            },
            {
              name: "BUCANA SASAHAN",
            },
            {
              name: "CALUBCOB",
            },
            {
              name: "CAPT. C. NAZARENO (POB.)",
            },
            {
              name: "GOMEZ-ZAMORA (POB.)",
            },
            {
              name: "HALANG",
            },
            {
              name: "HUMBAC",
            },
            {
              name: "IBAYO ESTACION",
            },
            {
              name: "IBAYO SILANGAN",
            },
            {
              name: "KANLURAN",
            },
            {
              name: "LABAC",
            },
            {
              name: "LATORIA",
            },
            {
              name: "MABOLO",
            },
            {
              name: "MAKINA",
            },
            {
              name: "MALAINEN BAGO",
            },
            {
              name: "MALAINEN LUMA",
            },
            {
              name: "MOLINO",
            },
            {
              name: "MUNTING MAPINO",
            },
            {
              name: "MUZON",
            },
            {
              name: "NAIC",
            },
            {
              name: "PALANGUE 1",
            },
            {
              name: "PALANGUE 2 AND 3",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTULAN",
            },
            {
              name: "SAPA",
            },
            {
              name: "TIMALAN BALSAHAN",
            },
            {
              name: "TIMALAN CONCEPCION",
            },
          ],
        },
        NOVELETA: {
          name: "NOVELETA",
          baranggays: [
            {
              name: "MAGDIWANG",
            },
            {
              name: "NOVELETA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALCEDO I",
            },
            {
              name: "SALCEDO II",
            },
            {
              name: "SAN ANTONIO I",
            },
            {
              name: "SAN ANTONIO II",
            },
            {
              name: "SAN JOSE I",
            },
            {
              name: "SAN JOSE II",
            },
            {
              name: "SAN JUAN I",
            },
            {
              name: "SAN JUAN II",
            },
            {
              name: "SAN RAFAEL I",
            },
            {
              name: "SAN RAFAEL II",
            },
            {
              name: "SAN RAFAEL III",
            },
            {
              name: "SAN RAFAEL IV",
            },
            {
              name: "SANTA ROSA I",
            },
            {
              name: "SANTA ROSA II",
            },
          ],
        },
        ROSARIO: {
          name: "ROSARIO",
          baranggays: [
            {
              name: "BAGBAG I",
            },
            {
              name: "BAGBAG II",
            },
            {
              name: "KANLURAN",
            },
            {
              name: "LIGTONG I",
            },
            {
              name: "LIGTONG II",
            },
            {
              name: "LIGTONG III",
            },
            {
              name: "LIGTONG IV",
            },
            {
              name: "MUZON I",
            },
            {
              name: "MUZON II",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAPA I",
            },
            {
              name: "SAPA II",
            },
            {
              name: "SAPA III",
            },
            {
              name: "SAPA IV",
            },
            {
              name: "SILANGAN I",
            },
            {
              name: "SILANGAN II",
            },
            {
              name: "TEJEROS CONVENTION",
            },
            {
              name: "WAWA I",
            },
            {
              name: "WAWA II",
            },
            {
              name: "WAWA III",
            },
          ],
        },
        SILANG: {
          name: "SILANG",
          baranggays: [
            {
              name: "ACACIA",
            },
            {
              name: "ADLAS",
            },
            {
              name: "ANAHAW I",
            },
            {
              name: "ANAHAW II",
            },
            {
              name: "BALITE I",
            },
            {
              name: "BALITE II",
            },
            {
              name: "BALUBAD",
            },
            {
              name: "BANABA",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BATAS",
            },
            {
              name: "BIGA I",
            },
            {
              name: "BIGA II",
            },
            {
              name: "BILUSO",
            },
            {
              name: "BUCAL",
            },
            {
              name: "BUHO",
            },
            {
              name: "BULIHAN",
            },
            {
              name: "CABANGAAN",
            },
            {
              name: "CARMEN",
            },
            {
              name: "HOYO",
            },
            {
              name: "HUKAY",
            },
            {
              name: "IBA",
            },
            {
              name: "INCHICAN",
            },
            {
              name: "IPIL I",
            },
            {
              name: "IPIL II",
            },
            {
              name: "KALUBKOB",
            },
            {
              name: "KAONG",
            },
            {
              name: "LALAAN I",
            },
            {
              name: "LALAAN II",
            },
            {
              name: "LITLIT",
            },
            {
              name: "LUCSUHIN",
            },
            {
              name: "LUMIL",
            },
            {
              name: "MAGUYAM",
            },
            {
              name: "MALABAG",
            },
            {
              name: "MALAKING TATYAO",
            },
            {
              name: "MATAAS NA BUROL",
            },
            {
              name: "MUNTING ILOG",
            },
            {
              name: "NARRA I",
            },
            {
              name: "NARRA II",
            },
            {
              name: "NARRA III",
            },
            {
              name: "PALIGAWAN",
            },
            {
              name: "PASONG LANGKA",
            },
            {
              name: "POOC I",
            },
            {
              name: "POOC II",
            },
            {
              name: "PULONG BUNGA",
            },
            {
              name: "PULONG SAGING",
            },
            {
              name: "PUTING KAHOY",
            },
            {
              name: "SABUTAN",
            },
            {
              name: "SAN MIGUEL I",
            },
            {
              name: "SAN MIGUEL II",
            },
            {
              name: "SAN VICENTE I",
            },
            {
              name: "SAN VICENTE II",
            },
            {
              name: "SANTOL",
            },
            {
              name: "SILANG",
            },
            {
              name: "TARTARIA",
            },
            {
              name: "TIBIG",
            },
            {
              name: "TOLEDO",
            },
            {
              name: "TUBUAN I",
            },
            {
              name: "TUBUAN II",
            },
            {
              name: "TUBUAN III",
            },
            {
              name: "ULAT",
            },
            {
              name: "YAKAL",
            },
          ],
        },
        "TAGAYTAY CITY": {
          name: "TAGAYTAY CITY",
          baranggays: [
            {
              name: "ASISAN",
            },
            {
              name: "BAGONG TUBIG",
            },
            {
              name: "CALABUSO",
            },
            {
              name: "DAPDAP EAST",
            },
            {
              name: "DAPDAP WEST",
            },
            {
              name: "FRANCISCO (SAN FRANCISCO)",
            },
            {
              name: "GUINHAWA NORTH",
            },
            {
              name: "GUINHAWA SOUTH",
            },
            {
              name: "IRUHIN EAST",
            },
            {
              name: "IRUHIN SOUTH",
            },
            {
              name: "IRUHIN WEST",
            },
            {
              name: "KAYBAGAL EAST",
            },
            {
              name: "KAYBAGAL NORTH",
            },
            {
              name: "KAYBAGAL SOUTH (POB.)",
            },
            {
              name: "MAG-ASAWANG ILAT",
            },
            {
              name: "MAHARLIKA EAST",
            },
            {
              name: "MAHARLIKA WEST",
            },
            {
              name: "MAITIM 2ND CENTRAL",
            },
            {
              name: "MAITIM 2ND EAST",
            },
            {
              name: "MAITIM 2ND WEST",
            },
            {
              name: "MENDEZ CROSSING EAST",
            },
            {
              name: "MENDEZ CROSSING WEST",
            },
            {
              name: "NEOGAN",
            },
            {
              name: "PATUTONG MALAKI NORTH",
            },
            {
              name: "PATUTONG MALAKI SOUTH",
            },
            {
              name: "SAMBONG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SILANG JUNCTION NORTH",
            },
            {
              name: "SILANG JUNCTION SOUTH",
            },
            {
              name: "SUNGAY NORTH",
            },
            {
              name: "SUNGAY SOUTH",
            },
            {
              name: "TAGAYTAY CITY",
            },
            {
              name: "TOLENTINO EAST",
            },
            {
              name: "TOLENTINO WEST",
            },
            {
              name: "ZAMBAL",
            },
          ],
        },
        TANZA: {
          name: "TANZA",
          baranggays: [
            {
              name: "AMAYA I",
            },
            {
              name: "AMAYA II",
            },
            {
              name: "AMAYA III",
            },
            {
              name: "AMAYA IV",
            },
            {
              name: "AMAYA V",
            },
            {
              name: "AMAYA VI",
            },
            {
              name: "AMAYA VII",
            },
            {
              name: "BAGTAS",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BIGA",
            },
            {
              name: "BIWAS",
            },
            {
              name: "BUCAL",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CALIBUYO",
            },
            {
              name: "CAPIPISA",
            },
            {
              name: "DAANG AMAYA I",
            },
            {
              name: "DAANG AMAYA II",
            },
            {
              name: "DAANG AMAYA III",
            },
            {
              name: "HALAYHAY",
            },
            {
              name: "JULUGAN I",
            },
            {
              name: "JULUGAN II",
            },
            {
              name: "JULUGAN III",
            },
            {
              name: "JULUGAN IV",
            },
            {
              name: "JULUGAN V",
            },
            {
              name: "JULUGAN VI",
            },
            {
              name: "JULUGAN VII",
            },
            {
              name: "JULUGAN VIII",
            },
            {
              name: "LAMBINGAN",
            },
            {
              name: "MULAWIN",
            },
            {
              name: "PARADAHAN I",
            },
            {
              name: "PARADAHAN II",
            },
            {
              name: "PUNTA I",
            },
            {
              name: "PUNTA II",
            },
            {
              name: "SAHUD ULAN",
            },
            {
              name: "SANJA MAYOR",
            },
            {
              name: "SANTOL",
            },
            {
              name: "TANAUAN",
            },
            {
              name: "TANZA",
            },
            {
              name: "TRES CRUSES",
            },
          ],
        },
        TERNATE: {
          name: "TERNATE",
          baranggays: [
            {
              name: "BUCANA",
            },
            {
              name: "MARINE BASE",
            },
            {
              name: "POBLACION I (BARANGAY I)",
            },
            {
              name: "POBLACION I A",
            },
            {
              name: "POBLACION II (BARANGAY II)",
            },
            {
              name: "POBLACION III (BARANGAY III)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN I",
            },
            {
              name: "SAN JUAN II",
            },
            {
              name: "SAPANG I",
            },
            {
              name: "SAPANG II",
            },
            {
              name: "TERNATE",
            },
          ],
        },
        "TRECE MARTIRES CITY": {
          name: "TRECE MARTIRES CITY",
          baranggays: [
            {
              name: "AGUADO (PISCAL MUNDO)",
            },
            {
              name: "CABEZAS",
            },
            {
              name: "CABUCO",
            },
            {
              name: "CONCHU (LAGUNDIAN)",
            },
            {
              name: "DE OCAMPO",
            },
            {
              name: "GREGORIO (ALIANG)",
            },
            {
              name: "INOCENCIO (B. POOK)",
            },
            {
              name: "LALLANA",
            },
            {
              name: "LAPIDARIO (BAYOG)",
            },
            {
              name: "LUCIANO (BITANGAN)",
            },
            {
              name: "OSORIO",
            },
            {
              name: "PEREZ (LUCBANAN)",
            },
            {
              name: "SAN AGUSTIN (POB.)",
            },
            {
              name: "TRECE MARTIRES CITY",
            },
          ],
        },
      },
    },
    CEBU: {
      name: "CEBU",
      region: "REGION 7",
      municipalities: {
        ALCANTARA: {
          name: "ALCANTARA",
          baranggays: [
            {
              name: "ALCANTARA",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CABIL-ISAN",
            },
            {
              name: "CANDABONG",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "MANGA",
            },
            {
              name: "PALANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLO",
            },
            {
              name: "SALAGMAYA",
            },
          ],
        },
        ALCOY: {
          name: "ALCOY",
          baranggays: [
            {
              name: "ALCOY",
            },
            {
              name: "ATABAY",
            },
            {
              name: "DAAN-LUNGSOD",
            },
            {
              name: "GUIWANG",
            },
            {
              name: "NUG-AS",
            },
            {
              name: "PASOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUGALO",
            },
            {
              name: "SAN AGUSTIN",
            },
          ],
        },
        ALEGRIA: {
          name: "ALEGRIA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "COMPOSTELA",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "LEGASPI",
            },
            {
              name: "LEPANTO",
            },
            {
              name: "MADRIDEJOS",
            },
            {
              name: "MONTPELLER",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "VALENCIA",
            },
          ],
        },
        ALOGUINSAN: {
          name: "ALOGUINSAN",
          baranggays: [
            {
              name: "ALOGUINSAN",
            },
            {
              name: "ANGILAN",
            },
            {
              name: "BOJO",
            },
            {
              name: "BONBON",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "KANDINGAN",
            },
            {
              name: "KANTABOGON",
            },
            {
              name: "KAWASAN",
            },
            {
              name: "OLANGO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNAY",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAKSAK",
            },
            {
              name: "TAMPA-AN",
            },
            {
              name: "TOYOKON",
            },
            {
              name: "ZARAGOSA",
            },
          ],
        },
        ARGAO: {
          name: "ARGAO",
          baranggays: [
            {
              name: "ALAMBIJUD",
            },
            {
              name: "ANAJAO",
            },
            {
              name: "APO",
            },
            {
              name: "ARGAO",
            },
            {
              name: "BALAAS",
            },
            {
              name: "BALISONG",
            },
            {
              name: "BINLOD",
            },
            {
              name: "BOGO",
            },
            {
              name: "BUG-OT",
            },
            {
              name: "BULASA",
            },
            {
              name: "BUTONG",
            },
            {
              name: "CALAGASAN",
            },
            {
              name: "CANBANTUG",
            },
            {
              name: "CANBANUA",
            },
            {
              name: "CANSUJE",
            },
            {
              name: "CAPIO-AN",
            },
            {
              name: "CASAY",
            },
            {
              name: "CATANG",
            },
            {
              name: "COLAWIN",
            },
            {
              name: "CONALUM",
            },
            {
              name: "GUIWANON",
            },
            {
              name: "GUTLANG",
            },
            {
              name: "JAMPANG",
            },
            {
              name: "JOMGAO",
            },
            {
              name: "LAMACAN",
            },
            {
              name: "LANGTAD",
            },
            {
              name: "LANGUB",
            },
            {
              name: "LAPAY",
            },
            {
              name: "LENGIGON",
            },
            {
              name: "LINUT-OD",
            },
            {
              name: "MABASA",
            },
            {
              name: "MANDILIKIT",
            },
            {
              name: "MOMPELLER",
            },
            {
              name: "PANADTARAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SUA",
            },
            {
              name: "SUMAGUAN",
            },
            {
              name: "TABAYAG",
            },
            {
              name: "TALAGA",
            },
            {
              name: "TALAYTAY",
            },
            {
              name: "TALO-OT",
            },
            {
              name: "TIGUIB",
            },
            {
              name: "TULANG",
            },
            {
              name: "TULIC",
            },
            {
              name: "UBAUB",
            },
            {
              name: "USMAD",
            },
          ],
        },
        ASTURIAS: {
          name: "ASTURIAS",
          baranggays: [
            {
              name: "AGBANGA",
            },
            {
              name: "AGTUGOP",
            },
            {
              name: "ASTURIAS",
            },
            {
              name: "BAGO",
            },
            {
              name: "BAIRAN",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BAYE",
            },
            {
              name: "BOG-O",
            },
            {
              name: "KALUANGAN",
            },
            {
              name: "LANAO",
            },
            {
              name: "LANGUB",
            },
            {
              name: "LOOC NORTE",
            },
            {
              name: "LUNAS",
            },
            {
              name: "MAGCALAPE",
            },
            {
              name: "MANGUIAO",
            },
            {
              name: "NEW BAGO",
            },
            {
              name: "OWAK",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAKSAK",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "TAG-AMAKAN",
            },
            {
              name: "TAGBUBONGA",
            },
            {
              name: "TUBIGAGMANOK",
            },
            {
              name: "TUBOD",
            },
            {
              name: "UBOGON",
            },
          ],
        },
        BADIAN: {
          name: "BADIAN",
          baranggays: [
            {
              name: "ALAWIJAO",
            },
            {
              name: "BADIAN",
            },
            {
              name: "BALHAAN",
            },
            {
              name: "BANHIGAN",
            },
            {
              name: "BASAK",
            },
            {
              name: "BASIAO",
            },
            {
              name: "BATO",
            },
            {
              name: "BUGAS",
            },
            {
              name: "CALANGCANG",
            },
            {
              name: "CANDIIS",
            },
            {
              name: "DAGATAN",
            },
            {
              name: "DOBDOB",
            },
            {
              name: "GINABLAN",
            },
            {
              name: "LAMBUG",
            },
            {
              name: "MALABAGO",
            },
            {
              name: "MALHIAO",
            },
            {
              name: "MANDUYONG",
            },
            {
              name: "MATUTINAO",
            },
            {
              name: "PATONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANLAGAN",
            },
            {
              name: "SANTICON",
            },
            {
              name: "SOHOTON",
            },
            {
              name: "SULSUGAN",
            },
            {
              name: "TALAYONG",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TIGUIB",
            },
            {
              name: "TUBOD",
            },
            {
              name: "ZARAGOSA",
            },
          ],
        },
        BALAMBAN: {
          name: "BALAMBAN",
          baranggays: [
            {
              name: "ABUCAYAN",
            },
            {
              name: "ALIWANAY",
            },
            {
              name: "ARPILI",
            },
            {
              name: "BALAMBAN",
            },
            {
              name: "BALIWAGAN (POB.)",
            },
            {
              name: "BAYONG",
            },
            {
              name: "BIASONG",
            },
            {
              name: "BUANOY",
            },
            {
              name: "CABAGDALAN",
            },
            {
              name: "CABASIANGAN",
            },
            {
              name: "CAMBUHAWE",
            },
            {
              name: "CANSOMOROY",
            },
            {
              name: "CANTIBAS",
            },
            {
              name: "CANTUOD",
            },
            {
              name: "DUANGAN",
            },
            {
              name: "GAAS",
            },
            {
              name: "GINATILAN",
            },
            {
              name: "HINGATMONAN",
            },
            {
              name: "LAMESA",
            },
            {
              name: "LIKI",
            },
            {
              name: "LUCA",
            },
            {
              name: "MATUN-OG",
            },
            {
              name: "NANGKA",
            },
            {
              name: "PONDOL",
            },
            {
              name: "PRENZA",
            },
            {
              name: "SANTA CRUZ-SANTO NIÑO (POB.)",
            },
            {
              name: "SINGSING",
            },
            {
              name: "SUNOG",
            },
            {
              name: "VITO",
            },
          ],
        },
        BANTAYAN: {
          name: "BANTAYAN",
          baranggays: [
            {
              name: "ATOP-ATOP",
            },
            {
              name: "BAIGAD",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BANTIGUE (POB.)",
            },
            {
              name: "BAOD",
            },
            {
              name: "BINAOBAO (POB.)",
            },
            {
              name: "BOTIGUES",
            },
            {
              name: "DOONG",
            },
            {
              name: "HILOTONGAN",
            },
            {
              name: "KABAC",
            },
            {
              name: "KABANGBANG",
            },
            {
              name: "KAMPINGGANON",
            },
            {
              name: "KANGKAIBE",
            },
            {
              name: "LIPAYRAN",
            },
            {
              name: "LUYONGBAYBAY",
            },
            {
              name: "MOJON",
            },
            {
              name: "OBO-OB",
            },
            {
              name: "PATAO",
            },
            {
              name: "PUTIAN",
            },
            {
              name: "SILLON",
            },
            {
              name: "SUBA (POB.)",
            },
            {
              name: "SULANGAN",
            },
            {
              name: "SUNGKO",
            },
            {
              name: "TICAD (POB.)",
            },
          ],
        },
        BARILI: {
          name: "BARILI",
          baranggays: [
            {
              name: "AZUCENA",
            },
            {
              name: "BAGAKAY",
            },
            {
              name: "BALAO",
            },
            {
              name: "BARILI",
            },
            {
              name: "BOLOCBOLOC",
            },
            {
              name: "BUDBUD",
            },
            {
              name: "BUGTONG KAWAYAN",
            },
            {
              name: "CABCABAN",
            },
            {
              name: "CAGAY",
            },
            {
              name: "CAMPANGGA",
            },
            {
              name: "CANDUGAY",
            },
            {
              name: "DAKIT",
            },
            {
              name: "GILOCTOG",
            },
            {
              name: "GIWANON",
            },
            {
              name: "GUIBUANGAN",
            },
            {
              name: "GUNTING",
            },
            {
              name: "HILASGASAN",
            },
            {
              name: "JAPITAN",
            },
            {
              name: "KALUBIHAN",
            },
            {
              name: "KANGDAMPAS",
            },
            {
              name: "LUHOD",
            },
            {
              name: "LUPO",
            },
            {
              name: "LUYO",
            },
            {
              name: "MAGHANOY",
            },
            {
              name: "MAIGANG",
            },
            {
              name: "MALOLOS",
            },
            {
              name: "MANTALONGON",
            },
            {
              name: "MANTAYUPAN",
            },
            {
              name: "MAYANA",
            },
            {
              name: "MINOLOS",
            },
            {
              name: "NABUNTURAN",
            },
            {
              name: "NASIPIT",
            },
            {
              name: "PANCIL",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PARIL",
            },
            {
              name: "PATUPAT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SAYAW",
            },
            {
              name: "TAL-OT",
            },
            {
              name: "TUBOD",
            },
            {
              name: "VITO",
            },
          ],
        },
        "BOGO CITY": {
          name: "BOGO CITY",
          baranggays: [
            {
              name: "ANONANG NORTE",
            },
            {
              name: "ANONANG SUR",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BINABAG",
            },
            {
              name: "BOGO CITY",
            },
            {
              name: "BUNGTOD (POB.)",
            },
            {
              name: "CARBON (POB.)",
            },
            {
              name: "CAYANG",
            },
            {
              name: "COGON (POB.)",
            },
            {
              name: "DAKIT",
            },
            {
              name: "DON PEDRO RODRIGUEZ",
            },
            {
              name: "GAIRAN",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LA PURISIMA CONCEPCION (POB.)",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LOURDES (POB.)",
            },
            {
              name: "MALINGIN",
            },
            {
              name: "MARANGOG",
            },
            {
              name: "NAILON",
            },
            {
              name: "ODLOT",
            },
            {
              name: "PANDAN (PANDAN HEIGHTS)",
            },
            {
              name: "POLAMBATO",
            },
            {
              name: "SAMBAG (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO (POB.)",
            },
            {
              name: "SIOCON",
            },
            {
              name: "SUDLONON",
            },
            {
              name: "TAYTAYAN",
            },
          ],
        },
        BOLJOON: {
          name: "BOLJOON",
          baranggays: [
            {
              name: "NANGKA",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        BORBON: {
          name: "BORBON",
          baranggays: [
            {
              name: "LUGO",
            },
          ],
        },
        "CARCAR CITY": {
          name: "CARCAR CITY",
          baranggays: [
            {
              name: "BOLINAWAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CALIDNGAN",
            },
            {
              name: "CAN-ASUJAN",
            },
            {
              name: "CARCAR CITY",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "LIBURON",
            },
            {
              name: "NAPO",
            },
            {
              name: "OCANA",
            },
            {
              name: "PERRELOS",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "TUYOM",
            },
            {
              name: "VALENCIA",
            },
            {
              name: "VALLADOLID",
            },
          ],
        },
        CARMEN: {
          name: "CARMEN",
          baranggays: [
            {
              name: "BARING",
            },
            {
              name: "CANTIPAY",
            },
            {
              name: "CANTUKONG",
            },
            {
              name: "CANTUMOG",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CAURASAN",
            },
            {
              name: "COGON EAST",
            },
            {
              name: "COGON WEST",
            },
            {
              name: "CORTE",
            },
            {
              name: "DAWIS NORTE",
            },
            {
              name: "DAWIS SUR",
            },
            {
              name: "HAGNAYA",
            },
            {
              name: "IPIL",
            },
            {
              name: "LANIPGA",
            },
            {
              name: "LIBORON",
            },
            {
              name: "LOWER NATIMAO-AN",
            },
            {
              name: "LUYANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUENTE",
            },
            {
              name: "SAC-ON",
            },
            {
              name: "TRIUMFO",
            },
            {
              name: "UPPER NATIMAO-AN",
            },
          ],
        },
        CATMON: {
          name: "CATMON",
          baranggays: [
            {
              name: "AGSUWAO",
            },
            {
              name: "AMANCION",
            },
            {
              name: "ANAPOG",
            },
            {
              name: "BACTAS",
            },
            {
              name: "BASAK",
            },
            {
              name: "BINONGKALAN",
            },
            {
              name: "BONGYAS",
            },
            {
              name: "CABUNGAAN",
            },
            {
              name: "CAMBANGKAYA",
            },
            {
              name: "CAN-IBUANG",
            },
            {
              name: "CATMON",
            },
            {
              name: "CATMONDAAN",
            },
            {
              name: "CORAZON (POB.)",
            },
            {
              name: "DUYAN",
            },
            {
              name: "FLORES (POB.)",
            },
            {
              name: "GINABUCAN",
            },
            {
              name: "MACAAS",
            },
            {
              name: "PANALIPAN",
            },
            {
              name: "SAN JOSE POB. (CATADMAN)",
            },
            {
              name: "TABILI",
            },
            {
              name: "TINABYONAN",
            },
          ],
        },
        "CEBU CITY": {
          name: "CEBU CITY",
          baranggays: [
            {
              name: "ADLAON",
            },
            {
              name: "AGSUNGOT",
            },
            {
              name: "APAS",
            },
            {
              name: "BABAG",
            },
            {
              name: "BACAYAN",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BASAK PARDO",
            },
            {
              name: "BASAK SAN NICOLAS",
            },
            {
              name: "BINALIW",
            },
            {
              name: "BONBON",
            },
            {
              name: "BRGY BARIO LUZ",
            },
            {
              name: "BUDLA-AN (POB.)",
            },
            {
              name: "BUHISAN",
            },
            {
              name: "BULACAO",
            },
            {
              name: "BUOT-TAUP PARDO",
            },
            {
              name: "BUSAY (POB.)",
            },
            {
              name: "CALAMBA",
            },
            {
              name: "CAMBINOCOT",
            },
            {
              name: "CAMPUTHAW (POB.)",
            },
            {
              name: "CAPITOL SITE (POB.)",
            },
            {
              name: "CARRETA",
            },
            {
              name: "CEBU CITY",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "COGON PARDO",
            },
            {
              name: "COGON RAMOS (POB.)",
            },
            {
              name: "DAY-AS",
            },
            {
              name: "DULJO (POB.)",
            },
            {
              name: "ERMITA (POB.)",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "GUBA",
            },
            {
              name: "HIPPODROMO",
            },
            {
              name: "INAYAWAN",
            },
            {
              name: "KALUBIHAN (POB.)",
            },
            {
              name: "KALUNASAN",
            },
            {
              name: "KAMAGAYAN (POB.)",
            },
            {
              name: "KASAMBAGAN",
            },
            {
              name: "KINASANG-AN PARDO",
            },
            {
              name: "LABANGON",
            },
            {
              name: "LAHUG (POB.)",
            },
            {
              name: "LOREGA (LOREGA SAN MIGUEL)",
            },
            {
              name: "LUSARAN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABOLO",
            },
            {
              name: "MALUBOG",
            },
            {
              name: "MAMBALING",
            },
            {
              name: "PAHINA CENTRAL (POB.)",
            },
            {
              name: "PAHINA SAN NICOLAS",
            },
            {
              name: "PAMUTAN",
            },
            {
              name: "PARDO (POB.)",
            },
            {
              name: "PARI-AN",
            },
            {
              name: "PARIL",
            },
            {
              name: "PASIL",
            },
            {
              name: "PIT-OS",
            },
            {
              name: "PULANGBATO",
            },
            {
              name: "PUNG-OL-SIBUGAY",
            },
            {
              name: "PUNTA PRINCESA",
            },
            {
              name: "QUIOT PARDO",
            },
            {
              name: "SAMBAG I (POB.)",
            },
            {
              name: "SAMBAG II (POB.)",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN NICOLAS CENTRAL",
            },
            {
              name: "SAN ROQUE (CIUDAD)",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SAPANGDAKU",
            },
            {
              name: "SAWANG CALERO (POB.)",
            },
            {
              name: "SINSIN",
            },
            {
              name: "SIRAO",
            },
            {
              name: "SUBA POB. (SUBA SAN NICOLAS)",
            },
            {
              name: "SUDLON I",
            },
            {
              name: "SUDLON II",
            },
            {
              name: "T. PADILLA",
            },
            {
              name: "TABUNAN",
            },
            {
              name: "TAGBAO",
            },
            {
              name: "TALAMBAN",
            },
            {
              name: "TAPTAP",
            },
            {
              name: "TEJERO (VILLA GONZALO)",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TISA",
            },
            {
              name: "TO-ONG PARDO",
            },
            {
              name: "ZAPATERA",
            },
          ],
        },
        COMPOSTELA: {
          name: "COMPOSTELA",
          baranggays: [
            {
              name: "BAGALNGA",
            },
            {
              name: "BASAK",
            },
            {
              name: "BULUANG",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CAMBAYOG",
            },
            {
              name: "CANAMUCAN",
            },
            {
              name: "COGON",
            },
            {
              name: "COMPOSTELA",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "ESTACA",
            },
            {
              name: "LUPA",
            },
            {
              name: "MAGAY",
            },
            {
              name: "MULAO",
            },
            {
              name: "PANANGBAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAG-UBE",
            },
            {
              name: "TAMIAO",
            },
            {
              name: "TUBIGAN",
            },
          ],
        },
        CONSOLACION: {
          name: "CONSOLACION",
          baranggays: [
            {
              name: "CABANGAHAN",
            },
            {
              name: "CANSAGA",
            },
            {
              name: "CASILI",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "DANGLAG",
            },
            {
              name: "GARING",
            },
            {
              name: "JUGAN",
            },
            {
              name: "LAMAC",
            },
            {
              name: "LANIPGA",
            },
            {
              name: "NANGKA",
            },
            {
              name: "PANAS",
            },
            {
              name: "PANOYPOY",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POBLACION OCCIDENTAL",
            },
            {
              name: "POBLACION ORIENTAL",
            },
            {
              name: "POLOG",
            },
            {
              name: "PULPOGAN",
            },
            {
              name: "SACSAC",
            },
            {
              name: "TAYUD",
            },
            {
              name: "TILHAONG",
            },
            {
              name: "TOLOTOLO",
            },
            {
              name: "TUGBONGAN",
            },
          ],
        },
        CORDOVA: {
          name: "CORDOVA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BANGBANG",
            },
            {
              name: "BUAGSONG",
            },
            {
              name: "CATARMAN",
            },
            {
              name: "COGON",
            },
            {
              name: "CORDOVA",
            },
            {
              name: "DAPITAN",
            },
            {
              name: "DAY-AS",
            },
            {
              name: "GABI",
            },
            {
              name: "GILUTONGAN",
            },
            {
              name: "IBABAO",
            },
            {
              name: "PILIPOG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN MIGUEL",
            },
          ],
        },
        DAANBANTAYAN: {
          name: "DAANBANTAYAN",
          baranggays: [
            {
              name: "AGUHO",
            },
            {
              name: "BAGAY",
            },
            {
              name: "BAKHAWAN",
            },
            {
              name: "BATERIA",
            },
            {
              name: "BITOON",
            },
            {
              name: "CALAPE",
            },
            {
              name: "CARNAZA",
            },
            {
              name: "DAANBANTAYAN",
            },
            {
              name: "DALINGDING",
            },
            {
              name: "LANAO",
            },
            {
              name: "LOGON",
            },
            {
              name: "MALBAGO",
            },
            {
              name: "MALINGIN",
            },
            {
              name: "MAYA",
            },
            {
              name: "PAJO",
            },
            {
              name: "PAYPAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAPILON",
            },
            {
              name: "TINUBDAN",
            },
            {
              name: "TOMINJAO",
            },
          ],
        },
        DALAGUETE: {
          name: "DALAGUETE",
          baranggays: [
            {
              name: "ABLAYAN",
            },
            {
              name: "BABAYONGAN",
            },
            {
              name: "BALUD",
            },
            {
              name: "BANHIGAN",
            },
            {
              name: "BULAK",
            },
            {
              name: "CALERIOHAN",
            },
            {
              name: "CALIONGAN",
            },
            {
              name: "CASAY",
            },
            {
              name: "CATOLOHAN",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "CORO",
            },
            {
              name: "DALAGUETE",
            },
            {
              name: "DUGYAN",
            },
            {
              name: "DUMALAN",
            },
            {
              name: "JOLOMAYNON",
            },
            {
              name: "LANAO",
            },
            {
              name: "LANGKAS",
            },
            {
              name: "LUMBANG",
            },
            {
              name: "MALONES",
            },
            {
              name: "MALORAY",
            },
            {
              name: "MANANGGAL",
            },
            {
              name: "MANLAPAY",
            },
            {
              name: "MANTALONGON",
            },
            {
              name: "NALHUB",
            },
            {
              name: "OBO",
            },
            {
              name: "OBONG",
            },
            {
              name: "PANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SACSAC",
            },
            {
              name: "SALUG",
            },
            {
              name: "TABON",
            },
            {
              name: "TAPUN",
            },
            {
              name: "TUBA",
            },
          ],
        },
        "DANAO CITY": {
          name: "DANAO CITY",
          baranggays: [
            {
              name: "BALIANG",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "CABUNGAHAN",
            },
            {
              name: "CAGAT-LAMAC",
            },
            {
              name: "CAHUMAYAN",
            },
            {
              name: "CAMBANAY",
            },
            {
              name: "CAMBUBHO",
            },
            {
              name: "COGON-CRUZ",
            },
            {
              name: "DANAO CITY",
            },
            {
              name: "DANASAN",
            },
            {
              name: "DUNGGA",
            },
            {
              name: "DUNGGOAN",
            },
            {
              name: "GUINACOT",
            },
            {
              name: "GUINSAY",
            },
            {
              name: "IBO",
            },
            {
              name: "LANGOSIG",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "LICOS",
            },
            {
              name: "LOOC",
            },
            {
              name: "MAGTAGOBTOB",
            },
            {
              name: "MALAPOC",
            },
            {
              name: "MANLAYAG",
            },
            {
              name: "MANTIJA",
            },
            {
              name: "MASABA",
            },
            {
              name: "MASLOG",
            },
            {
              name: "NANGKA",
            },
            {
              name: "OGUIS",
            },
            {
              name: "PILI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUISOL",
            },
            {
              name: "SABANG",
            },
            {
              name: "SACSAC",
            },
            {
              name: "SANDAYONG NORTE",
            },
            {
              name: "SANDAYONG SUR",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTICAN",
            },
            {
              name: "SIBACAN",
            },
            {
              name: "SUBA",
            },
            {
              name: "TABOC",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TOGONON",
            },
            {
              name: "TUBURAN SUR",
            },
          ],
        },
        DUMANJUG: {
          name: "DUMANJUG",
          baranggays: [
            {
              name: "BALAYGTIKI",
            },
            {
              name: "BITOON",
            },
            {
              name: "BULAK",
            },
            {
              name: "BULLOGAN",
            },
            {
              name: "CALABOON",
            },
            {
              name: "CAMBOANG",
            },
            {
              name: "CANDABONG",
            },
            {
              name: "COGON",
            },
            {
              name: "COTCOTON",
            },
            {
              name: "DOLDOL",
            },
            {
              name: "DUMANJUG",
            },
            {
              name: "ILAYA (POB.)",
            },
            {
              name: "KABALAASNAN",
            },
            {
              name: "KABATBATAN",
            },
            {
              name: "KAMBANOG",
            },
            {
              name: "KANG-ACTOL",
            },
            {
              name: "KANGHALO",
            },
            {
              name: "KANGHUMAOD",
            },
            {
              name: "KANGUHA",
            },
            {
              name: "KANTANGKAS",
            },
            {
              name: "KANYUKO",
            },
            {
              name: "KOLABTINGON",
            },
            {
              name: "LAMAK",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "LIONG",
            },
            {
              name: "MANLAPAY",
            },
            {
              name: "MASA",
            },
            {
              name: "MATALAO",
            },
            {
              name: "PACULOB",
            },
            {
              name: "PANLAAN",
            },
            {
              name: "PAWA",
            },
            {
              name: "POBLACION CENTRAL",
            },
            {
              name: "POBLACION LOOC",
            },
            {
              name: "POBLACION SIMA",
            },
            {
              name: "TANGIL",
            },
            {
              name: "TAPON",
            },
            {
              name: "TUBOD-BITOON",
            },
            {
              name: "TUBOD-DUGOAN",
            },
          ],
        },
        GINATILAN: {
          name: "GINATILAN",
          baranggays: [
            {
              name: "ANAO",
            },
            {
              name: "CAGSING",
            },
            {
              name: "CALABAWAN",
            },
            {
              name: "CAMBAGTE",
            },
            {
              name: "CAMPISONG",
            },
            {
              name: "CANORONG",
            },
            {
              name: "GINATILAN",
            },
            {
              name: "GUIWANON",
            },
            {
              name: "LOOC",
            },
            {
              name: "MALATBO",
            },
            {
              name: "MANGACO",
            },
            {
              name: "PALANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAMANCA",
            },
            {
              name: "SAN ROQUE",
            },
          ],
        },
        "LAPU-LAPU CITY (OPON)": {
          name: "LAPU-LAPU CITY (OPON)",
          baranggays: [
            {
              name: "AGUS",
            },
            {
              name: "BABAG",
            },
            {
              name: "BANKAL",
            },
            {
              name: "BARING",
            },
            {
              name: "BASAK",
            },
            {
              name: "BUAYA",
            },
            {
              name: "CALAWISAN",
            },
            {
              name: "CANJULAO",
            },
            {
              name: "CAUBIAN",
            },
            {
              name: "CAW-OY",
            },
            {
              name: "CAWHAGAN",
            },
            {
              name: "GUN-OB",
            },
            {
              name: "IBO",
            },
            {
              name: "LAPU-LAPU CITY (OPON)",
            },
            {
              name: "LOOC",
            },
            {
              name: "MACTAN",
            },
            {
              name: "MARIBAGO",
            },
            {
              name: "MARIGONDON",
            },
            {
              name: "PAJAC",
            },
            {
              name: "PAJO",
            },
            {
              name: "PANGAN-AN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNTA ENGAÑO",
            },
            {
              name: "PUSOK",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SUBABASBAS",
            },
            {
              name: "TALIMA",
            },
            {
              name: "TINGO",
            },
            {
              name: "TUNGASAN",
            },
          ],
        },
        LILOAN: {
          name: "LILOAN",
          baranggays: [
            {
              name: "CABADIANGAN",
            },
            {
              name: "CALERO",
            },
            {
              name: "CATARMAN",
            },
            {
              name: "COTCOT",
            },
            {
              name: "JUBAY",
            },
            {
              name: "LATABAN",
            },
            {
              name: "LILOAN",
            },
            {
              name: "MULAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TABLA",
            },
            {
              name: "TAYUD",
            },
            {
              name: "YATI",
            },
          ],
        },
        MADRIDEJOS: {
          name: "MADRIDEJOS",
          baranggays: [
            {
              name: "BUNAKAN",
            },
            {
              name: "KANGWAYAN",
            },
            {
              name: "KAONGKOD",
            },
            {
              name: "KODIA",
            },
            {
              name: "MAALAT",
            },
            {
              name: "MADRIDEJOS",
            },
            {
              name: "MALBAGO",
            },
            {
              name: "MANCILANG",
            },
            {
              name: "PILI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "TABAGAK",
            },
            {
              name: "TALANGNAN",
            },
            {
              name: "TARONG",
            },
            {
              name: "TUGAS",
            },
          ],
        },
        MALABUYOC: {
          name: "MALABUYOC",
          baranggays: [
            {
              name: "ARMEÑA (CANSILONGAN)",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "CERDEÑA (ANSAN)",
            },
            {
              name: "LABRADOR (BULOD)",
            },
            {
              name: "LOMBO",
            },
            {
              name: "LOOC",
            },
            {
              name: "MAHANLUD",
            },
            {
              name: "MALABUYOC",
            },
            {
              name: "MINDANAO (PAJO)",
            },
            {
              name: "MONTAÑEZA (INAMLANG)",
            },
            {
              name: "SALMERON (BULAK)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SORSOGON (BALIMAYA)",
            },
            {
              name: "TOLOSA (CALATAGAN)",
            },
          ],
        },
        "MANDAUE CITY": {
          name: "MANDAUE CITY",
          baranggays: [
            {
              name: "ALANG-ALANG",
            },
            {
              name: "BAKILID",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BASAK",
            },
            {
              name: "CAMBARO",
            },
            {
              name: "CANDUMAN",
            },
            {
              name: "CASILI",
            },
            {
              name: "CASUNTINGAN",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "CUBACUB",
            },
            {
              name: "GUIZO",
            },
            {
              name: "IBABAO-ESTANCIA",
            },
            {
              name: "JAGOBIAO",
            },
            {
              name: "LABOGON",
            },
            {
              name: "LOOC",
            },
            {
              name: "MAGUIKAY",
            },
            {
              name: "MANDAUE CITY",
            },
            {
              name: "MANTUYONG",
            },
            {
              name: "OPAO",
            },
            {
              name: "PAGSABUNGAN",
            },
            {
              name: "PAKNA-AN",
            },
            {
              name: "SUBANGDAKU",
            },
            {
              name: "TABOK",
            },
            {
              name: "TAWASON",
            },
            {
              name: "TINGUB",
            },
            {
              name: "UMAPAD",
            },
          ],
        },
        MEDELLIN: {
          name: "MEDELLIN",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "CANHABAGAT",
            },
            {
              name: "CAPUTATAN NORTE",
            },
            {
              name: "CAPUTATAN SUR",
            },
            {
              name: "CURVA",
            },
            {
              name: "DAANLUNGSOD",
            },
            {
              name: "DALINGDING SUR",
            },
            {
              name: "DAYHAGON",
            },
            {
              name: "DON VIRGILIO GONZALES",
            },
            {
              name: "GIBITNGIL",
            },
            {
              name: "KAWIT",
            },
            {
              name: "LAMINTAK NORTE",
            },
            {
              name: "LAMINTAK SUR",
            },
            {
              name: "LUY-A",
            },
            {
              name: "MAHARUHAY",
            },
            {
              name: "MAHAWAK",
            },
            {
              name: "MEDELLIN",
            },
            {
              name: "PANUGNAWAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TINDOG",
            },
          ],
        },
        MINGLANILLA: {
          name: "MINGLANILLA",
          baranggays: [
            {
              name: "CADULAWAN",
            },
            {
              name: "CALAJO-AN",
            },
            {
              name: "CAMP 7",
            },
            {
              name: "CAMP 8",
            },
            {
              name: "CUANOS",
            },
            {
              name: "GUINDARUHAN",
            },
            {
              name: "LINAO",
            },
            {
              name: "MANDUANG",
            },
            {
              name: "MINGLANILLA",
            },
            {
              name: "PAKIGNE",
            },
            {
              name: "POBLACION WARD I",
            },
            {
              name: "POBLACION WARD II",
            },
            {
              name: "POBLACION WARD III",
            },
            {
              name: "POBLACION WARD IV",
            },
            {
              name: "TUBOD",
            },
            {
              name: "TULAY",
            },
            {
              name: "TUNGHAAN",
            },
            {
              name: "TUNGKIL",
            },
            {
              name: "TUNGKOP",
            },
            {
              name: "VITO",
            },
          ],
        },
        MOALBOAL: {
          name: "MOALBOAL",
          baranggays: [
            {
              name: "AGBALANGA",
            },
            {
              name: "BALA",
            },
            {
              name: "BALABAGON",
            },
            {
              name: "BASDIOT",
            },
            {
              name: "BATADBATAD",
            },
            {
              name: "BUGHO",
            },
            {
              name: "BUGUIL",
            },
            {
              name: "BUSAY",
            },
            {
              name: "LANAO",
            },
            {
              name: "MOALBOAL",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAAVEDRA",
            },
            {
              name: "TOMONOY",
            },
            {
              name: "TUBLE",
            },
            {
              name: "TUNGA",
            },
          ],
        },
        "NAGA CITY": {
          name: "NAGA CITY",
          baranggays: [
            {
              name: "ALFACO",
            },
            {
              name: "BAIRAN",
            },
            {
              name: "BALIRONG",
            },
            {
              name: "CABUNGAHAN",
            },
            {
              name: "CANTAO-AN",
            },
            {
              name: "CENTRAL POBLACION",
            },
            {
              name: "COGON",
            },
            {
              name: "COLON",
            },
            {
              name: "EAST POBLACION",
            },
            {
              name: "INAYAGAN",
            },
            {
              name: "INOBURAN",
            },
            {
              name: "JAGUIMIT",
            },
            {
              name: "LANAS",
            },
            {
              name: "LANGTAD",
            },
            {
              name: "LUTAC",
            },
            {
              name: "MAINIT LOWER",
            },
            {
              name: "MAYANA",
            },
            {
              name: "NAALAD",
            },
            {
              name: "NAGA CITY",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "PANGDAN",
            },
            {
              name: "PATAG",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TAGJAGUIMIT",
            },
            {
              name: "TANGKE",
            },
            {
              name: "TINAAN",
            },
            {
              name: "TUYAN",
            },
            {
              name: "ULING",
            },
            {
              name: "WEST POBLACION",
            },
          ],
        },
        OSLOB: {
          name: "OSLOB",
          baranggays: [
            {
              name: "ALO",
            },
            {
              name: "BANGCOGON",
            },
            {
              name: "BONBON",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CAN-UKBAN",
            },
            {
              name: "CAÑANG",
            },
            {
              name: "CANANGCA-AN",
            },
            {
              name: "CANSALO-AY",
            },
            {
              name: "DAANLUNGSOD",
            },
            {
              name: "GAWI",
            },
            {
              name: "HAGDAN",
            },
            {
              name: "LAGUNDE",
            },
            {
              name: "LOOC",
            },
            {
              name: "LUKA",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MANLUM",
            },
            {
              name: "NUEVA CACERES",
            },
            {
              name: "OSLOB",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNGTOD",
            },
            {
              name: "TAN-AWAN",
            },
            {
              name: "TUMALOG",
            },
          ],
        },
        "PILAR - ISLAND": {
          name: "PILAR - ISLAND",
          baranggays: [
            {
              name: "BIASONG",
            },
            {
              name: "CAWIT",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LANAO",
            },
            {
              name: "LOWER POBLACION",
            },
            {
              name: "MOABOG",
            },
            {
              name: "MONTSERRAT",
            },
            {
              name: "PILAR - ISLAND",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "UPPER POBLACION",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        PINAMUNGAHAN: {
          name: "PINAMUNGAHAN",
          baranggays: [
            {
              name: "ANISLAG",
            },
            {
              name: "ANOPOG",
            },
            {
              name: "BINABAG",
            },
            {
              name: "BUHINGTUBIG",
            },
            {
              name: "BUSAY",
            },
            {
              name: "BUTONG",
            },
            {
              name: "CABIANGON",
            },
            {
              name: "CAMUGAO",
            },
            {
              name: "DUANGAN",
            },
            {
              name: "GUIMBAWIAN",
            },
            {
              name: "LAMAC",
            },
            {
              name: "LUT-OD",
            },
            {
              name: "MANGOTO",
            },
            {
              name: "OPAO",
            },
            {
              name: "PANDACAN",
            },
            {
              name: "PINAMUNGAHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNOD",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SACSAC",
            },
            {
              name: "SAMBAGON",
            },
            {
              name: "SIBAGO",
            },
            {
              name: "TAJAO",
            },
            {
              name: "TANGUB",
            },
            {
              name: "TANIBAG",
            },
            {
              name: "TUPAS",
            },
            {
              name: "TUTAY",
            },
          ],
        },
        "PORO- ISLAND": {
          name: "PORO- ISLAND",
          baranggays: [
            {
              name: "ADELA",
            },
            {
              name: "ALTAVISTA",
            },
            {
              name: "CAGCAGAN",
            },
            {
              name: "CANSABUSAB",
            },
            {
              name: "DAAN PAZ",
            },
            {
              name: "EASTERN POBLACION",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MABINI",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "PAGSA",
            },
            {
              name: "PAZ",
            },
            {
              name: "PORO- ISLAND",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "TEGUIS",
            },
            {
              name: "WESTERN POBLACION",
            },
          ],
        },
        RONDA: {
          name: "RONDA",
          baranggays: [
            {
              name: "BUTONG",
            },
            {
              name: "CAN-ABUHON",
            },
            {
              name: "CANDULING",
            },
            {
              name: "CANSALONOY",
            },
            {
              name: "CANSAYAHON",
            },
            {
              name: "ILAYA",
            },
            {
              name: "LANGIN",
            },
            {
              name: "LIBO-O",
            },
            {
              name: "MALALAY",
            },
            {
              name: "PALANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RONDA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TUPAS",
            },
            {
              name: "VIVE",
            },
          ],
        },
        SAMBOAN: {
          name: "SAMBOAN",
          baranggays: [
            {
              name: "BASAK",
            },
            {
              name: "BONBON",
            },
            {
              name: "BULANGSURAN",
            },
            {
              name: "CALATAGAN",
            },
            {
              name: "CAMBIGONG",
            },
            {
              name: "CAMBUROY",
            },
            {
              name: "CANORONG",
            },
            {
              name: "COLASE",
            },
            {
              name: "DALAHIKAN",
            },
            {
              name: "JUMANGPAS",
            },
            {
              name: "MONTEVERDE",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMBOAN",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SUBA",
            },
            {
              name: "TANGBO",
            },
          ],
        },
        "SAN FERNANDO": {
          name: "SAN FERNANDO",
          baranggays: [
            {
              name: "PANADTARAN",
            },
            {
              name: "PITALO",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        "SAN FRANCISCO": {
          name: "SAN FRANCISCO",
          baranggays: [
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        "SAN REMIGIO": {
          name: "SAN REMIGIO",
          baranggays: [
            {
              name: "ANAPOG",
            },
            {
              name: "ARGAWANON",
            },
            {
              name: "BAGTIC",
            },
            {
              name: "BANCASAN",
            },
            {
              name: "BATAD",
            },
            {
              name: "BUSOGON",
            },
            {
              name: "CALAMBUA",
            },
            {
              name: "CANAGAHAN",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "GAWAYGAWAY",
            },
            {
              name: "HAGNAYA",
            },
            {
              name: "KAYAM",
            },
            {
              name: "KINAWAHAN",
            },
            {
              name: "LAMBUSAN",
            },
            {
              name: "LAWIS",
            },
            {
              name: "LIBAONG",
            },
            {
              name: "LOOC",
            },
            {
              name: "LUYANG",
            },
            {
              name: "MANO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNTA",
            },
            {
              name: "SAB-A",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN REMIGIO",
            },
            {
              name: "TACUP",
            },
            {
              name: "TAMBONGON",
            },
            {
              name: "TO-ONG",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        "SANTA FE - ISLAND": {
          name: "SANTA FE - ISLAND",
          baranggays: [
            {
              name: "BALIDBID",
            },
            {
              name: "HAGDAN",
            },
            {
              name: "HILANTAGAAN",
            },
            {
              name: "KINATARKAN",
            },
            {
              name: "LANGUB",
            },
            {
              name: "MARICABAN",
            },
            {
              name: "OKOY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POOC",
            },
            {
              name: "SANTA FE - ISLAND",
            },
            {
              name: "TALISAY",
            },
          ],
        },
        SANTANDER: {
          name: "SANTANDER",
          baranggays: [
            {
              name: "BUNLAN",
            },
            {
              name: "CABUTONGAN",
            },
            {
              name: "CANDAMIANG",
            },
            {
              name: "CANLUMACAD",
            },
            {
              name: "LILOAN",
            },
            {
              name: "LIP-TONG",
            },
            {
              name: "LOOC",
            },
            {
              name: "PASIL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTANDER",
            },
            {
              name: "TALISAY",
            },
          ],
        },
        SIBONGA: {
          name: "SIBONGA",
          baranggays: [
            {
              name: "ABUGON",
            },
            {
              name: "BAE",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BANLOT",
            },
            {
              name: "BASAK",
            },
            {
              name: "BATO",
            },
            {
              name: "CAGAY",
            },
            {
              name: "CAN-AGA",
            },
            {
              name: "CANDAGUIT",
            },
            {
              name: "CANTOLAROY",
            },
            {
              name: "DUGOAN",
            },
            {
              name: "GUIMBANGCO-AN",
            },
            {
              name: "LAMACAN",
            },
            {
              name: "LIBO",
            },
            {
              name: "LINDOGON",
            },
            {
              name: "MAGCAGONG",
            },
            {
              name: "MANATAD",
            },
            {
              name: "MANGYAN",
            },
            {
              name: "PAPAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAYAO",
            },
            {
              name: "SIBONGA",
            },
            {
              name: "SIMALA",
            },
            {
              name: "TUBOD",
            },
          ],
        },
        SOGOD: {
          name: "SOGOD",
          baranggays: [
            {
              name: "AMPONGOL",
            },
            {
              name: "BAGAKAY",
            },
            {
              name: "BAGATAYAM",
            },
            {
              name: "BAWO",
            },
            {
              name: "CABALAWAN",
            },
            {
              name: "CABANGAHAN",
            },
            {
              name: "CALUMBOYAN",
            },
            {
              name: "DAKIT",
            },
            {
              name: "DAMOLOG",
            },
            {
              name: "IBABAO",
            },
            {
              name: "LIKI",
            },
            {
              name: "LOGO",
            },
            {
              name: "MOHON",
            },
            {
              name: "NAHUS-AN",
            },
            {
              name: "PANSOY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SOGOD",
            },
            {
              name: "TABUNOK",
            },
            {
              name: "TAKAY",
            },
          ],
        },
        TABOGON: {
          name: "TABOGON",
          baranggays: [
            {
              name: "ALANG-ALANG",
            },
            {
              name: "CADUAWAN",
            },
            {
              name: "CAMOBOAN",
            },
            {
              name: "CANAOCANAO",
            },
            {
              name: "COMBADO",
            },
            {
              name: "DAANTABOGON",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "KAL-ANAN",
            },
            {
              name: "LABANGON",
            },
            {
              name: "LIBJO",
            },
            {
              name: "LOONG",
            },
            {
              name: "MABULI",
            },
            {
              name: "MANAGASE",
            },
            {
              name: "MANLAGTANG",
            },
            {
              name: "MASLOG",
            },
            {
              name: "MUABOG",
            },
            {
              name: "PIO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAG",
            },
            {
              name: "SAMBAG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SOMOSA",
            },
            {
              name: "TABA-AO",
            },
            {
              name: "TABOGON",
            },
            {
              name: "TAPUL",
            },
          ],
        },
        TABUELAN: {
          name: "TABUELAN",
          baranggays: [
            {
              name: "BONGON",
            },
            {
              name: "DALID",
            },
            {
              name: "KANLIM-AO",
            },
            {
              name: "KANLUHANGON",
            },
            {
              name: "KANTUBAON",
            },
            {
              name: "MABUNAO",
            },
            {
              name: "MARAVILLA",
            },
            {
              name: "OLIVO",
            },
            {
              name: "TABUELAN",
            },
            {
              name: "TABUNOK",
            },
            {
              name: "TIGBAWAN",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        "TALISAY CITY": {
          name: "TALISAY CITY",
          baranggays: [
            {
              name: "BIASONG",
            },
            {
              name: "BULACAO",
            },
            {
              name: "CADULAWAN",
            },
            {
              name: "CAMP IV",
            },
            {
              name: "CANSOJONG",
            },
            {
              name: "DUMLOG",
            },
            {
              name: "JACLUPAN",
            },
            {
              name: "LAGTANG",
            },
            {
              name: "LAWAAN I",
            },
            {
              name: "LAWAAN II",
            },
            {
              name: "LAWAAN III",
            },
            {
              name: "LINAO",
            },
            {
              name: "MAGHAWAY",
            },
            {
              name: "MANIPIS",
            },
            {
              name: "MOHON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POOC",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TABUNOC",
            },
            {
              name: "TALISAY CITY",
            },
            {
              name: "TANGKE",
            },
            {
              name: "TAPUL",
            },
          ],
        },
        "TOLEDO CITY": {
          name: "TOLEDO CITY",
          baranggays: [
            {
              name: "AWIHAO",
            },
            {
              name: "BAGAKAY",
            },
            {
              name: "BATO",
            },
            {
              name: "BIGA",
            },
            {
              name: "BULONGAN",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CABITOONAN",
            },
            {
              name: "CALONGCALONG",
            },
            {
              name: "CAMBANG-UG",
            },
            {
              name: "CAMP 8",
            },
            {
              name: "CANLUMAMPAO",
            },
            {
              name: "CANTABACO",
            },
            {
              name: "CAPITAN CLAUDIO",
            },
            {
              name: "CARMEN",
            },
            {
              name: "DAANGLUNGSOD",
            },
            {
              name: "DON ANDRES SORIANO (LUTOPAN)",
            },
            {
              name: "DUMLOG",
            },
            {
              name: "GEN. CLIMACO (MALUBOG)",
            },
            {
              name: "IBO",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "JUAN CLIMACO, SR. (MAGDUGO)",
            },
            {
              name: "LANDAHAN",
            },
            {
              name: "LOAY",
            },
            {
              name: "LURAY II",
            },
            {
              name: "MATAB-ANG",
            },
            {
              name: "MEDIA ONCE",
            },
            {
              name: "PANGAMIHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POOG",
            },
            {
              name: "PUTINGBATO",
            },
            {
              name: "SAGAY",
            },
            {
              name: "SAM-ANG",
            },
            {
              name: "SANGI",
            },
            {
              name: "SANTO NIÑO (MAINGGIT)",
            },
            {
              name: "SUBAYON",
            },
            {
              name: "TALAVERA",
            },
            {
              name: "TOLEDO CITY",
            },
            {
              name: "TUBOD",
            },
            {
              name: "TUNGKAY",
            },
          ],
        },
        TUBURAN: {
          name: "TUBURAN",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "AMATUGAN",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "APALAN",
            },
            {
              name: "BAGASAWE",
            },
            {
              name: "BAKYAWAN",
            },
            {
              name: "BANGKITO",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "BARANGAY VII (POB.)",
            },
            {
              name: "BARANGAY VIII (POB.)",
            },
            {
              name: "BULWANG",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "CARMELO",
            },
            {
              name: "COGON",
            },
            {
              name: "COLONIA",
            },
            {
              name: "DAAN LUNGSOD",
            },
            {
              name: "FORTALIZA",
            },
            {
              name: "GA-ANG",
            },
            {
              name: "GIMAMA-A",
            },
            {
              name: "JAGBUAYA",
            },
            {
              name: "KABANGKALAN",
            },
            {
              name: "KABKABAN",
            },
            {
              name: "KAGBA-O",
            },
            {
              name: "KALANGAHAN",
            },
            {
              name: "KAMANSI",
            },
            {
              name: "KAMPOOT",
            },
            {
              name: "KAN-AN",
            },
            {
              name: "KANLUNSING",
            },
            {
              name: "KANSI",
            },
            {
              name: "KAORASAN",
            },
            {
              name: "LIBO",
            },
            {
              name: "LUSONG",
            },
            {
              name: "MACUPA",
            },
            {
              name: "MAG-ALWA",
            },
            {
              name: "MAG-ANTOY",
            },
            {
              name: "MAG-ATUBANG",
            },
            {
              name: "MAGHAN-AY",
            },
            {
              name: "MANGGA",
            },
            {
              name: "MARMOL",
            },
            {
              name: "MOLOBOLO",
            },
            {
              name: "MONTEALEGRE",
            },
            {
              name: "PUTAT",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANDAYONG",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIOTES",
            },
            {
              name: "SUMON",
            },
            {
              name: "TOMINJAO",
            },
            {
              name: "TOMUGPA",
            },
            {
              name: "TUBURAN",
            },
          ],
        },
        TUDELA: {
          name: "TUDELA",
          baranggays: [
            {
              name: "BUENAVISTA",
            },
            {
              name: "CALMANTE",
            },
            {
              name: "DAAN SECANTE",
            },
            {
              name: "GENERAL",
            },
            {
              name: "MCARTHUR",
            },
            {
              name: "NORTHERN POBLACION",
            },
            {
              name: "PUERTOBELLO",
            },
            {
              name: "SANTANDER",
            },
            {
              name: "SECANTE BAG-O",
            },
            {
              name: "SOUTHERN POBLACION",
            },
            {
              name: "TUDELA",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
      },
    },
    "COMPOSTELA VALLEY": {
      name: "COMPOSTELA VALLEY",
      region: "REGION 11",
      municipalities: {
        COMPOSTELA: {
          name: "COMPOSTELA",
          baranggays: [
            {
              name: "AURORA",
            },
            {
              name: "BAGONGON",
            },
            {
              name: "COMPOSTELA",
            },
            {
              name: "GABI",
            },
            {
              name: "LAGAB",
            },
            {
              name: "MANGAYON",
            },
            {
              name: "MAPACA",
            },
            {
              name: "MAPARAT",
            },
            {
              name: "NEW ALEGRIA",
            },
            {
              name: "NGAN",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PANANSALAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SIOCON",
            },
            {
              name: "TAMIA",
            },
          ],
        },
        "LAAK (SAN VICENTE)": {
          name: "LAAK (SAN VICENTE)",
          baranggays: [
            {
              name: "AGUINALDO",
            },
            {
              name: "AMOR CRUZ",
            },
            {
              name: "AMPAWID",
            },
            {
              name: "ANDAP",
            },
            {
              name: "ANITAP",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BANBANON",
            },
            {
              name: "BELMONTE",
            },
            {
              name: "BINASBAS",
            },
            {
              name: "BULLUCAN",
            },
            {
              name: "CEBULIDA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DATU AMPUNAN",
            },
            {
              name: "DATU DAVAO",
            },
            {
              name: "DOÑA JOSEFA",
            },
            {
              name: "EL KATIPUNAN",
            },
            {
              name: "IL PAPA",
            },
            {
              name: "IMELDA",
            },
            {
              name: "INACAYAN",
            },
            {
              name: "KALIGUTAN",
            },
            {
              name: "KAPATAGAN",
            },
            {
              name: "KIDAWA",
            },
            {
              name: "KILAGDING",
            },
            {
              name: "KIOKMAY",
            },
            {
              name: "LAAC (POB.)",
            },
            {
              name: "LANGTUD",
            },
            {
              name: "LONGANAPAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MACOPA",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MANGLOY",
            },
            {
              name: "MELALE",
            },
            {
              name: "NAGA",
            },
            {
              name: "NEW BETHLEHEM",
            },
            {
              name: "PANAMOREN",
            },
            {
              name: "SABUD",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SANTA EMILIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SISIMON",
            },
          ],
        },
        "MABINI (DOÑA ALICIA)": {
          name: "MABINI (DOÑA ALICIA)",
          baranggays: [
            {
              name: "ANITAPAN",
            },
            {
              name: "CABUYUAN",
            },
            {
              name: "CADUNAN",
            },
            {
              name: "CUAMBOG (POB.)",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GOLDEN VALLEY (MARAUT)",
            },
            {
              name: "LIBODON",
            },
            {
              name: "PANGIBIRAN",
            },
            {
              name: "PINDASAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "TAGNANAN (MAMPISING)",
            },
          ],
        },
        MACO: {
          name: "MACO",
          baranggays: [
            {
              name: "ANIBONGAN",
            },
            {
              name: "ANISLAGAN",
            },
            {
              name: "BINUANGAN",
            },
            {
              name: "BUCANA",
            },
            {
              name: "CALABCAB",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DUMLAN",
            },
            {
              name: "ELIZALDE (SOMIL)",
            },
            {
              name: "GUBATAN",
            },
            {
              name: "HIJO",
            },
            {
              name: "KINUBAN",
            },
            {
              name: "LANGGAM",
            },
            {
              name: "LAPU-LAPU",
            },
            {
              name: "LIBAY-LIBAY",
            },
            {
              name: "LIMBO",
            },
            {
              name: "LUMATAB",
            },
            {
              name: "MAGANGIT",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MALAMODAO",
            },
            {
              name: "MANIPONGOL",
            },
            {
              name: "MAPAANG",
            },
            {
              name: "MASARA",
            },
            {
              name: "NEW ASTURIAS",
            },
            {
              name: "NEW BARILI",
            },
            {
              name: "NEW LEYTE",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "PANANGAN",
            },
            {
              name: "PANGI (GAUDENCIO ANTONIO)",
            },
            {
              name: "PANIBASAN",
            },
            {
              name: "PANORAON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANGAB",
            },
            {
              name: "TAGBAROS",
            },
            {
              name: "TAGLAWIG",
            },
            {
              name: "TERESA",
            },
          ],
        },
        "MARAGUSAN (SAN MARIANO)": {
          name: "MARAGUSAN (SAN MARIANO)",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAHI",
            },
            {
              name: "CAMBAGANG",
            },
            {
              name: "CORONOBE",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LAHI",
            },
            {
              name: "LANGGAWISAN",
            },
            {
              name: "MABUGNAO",
            },
            {
              name: "MAGCAGONG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MAPAWA",
            },
            {
              name: "MARAGUSAN (POB.)",
            },
            {
              name: "MAUSWAGON",
            },
            {
              name: "NEW ALBAY",
            },
            {
              name: "NEW KATIPUNAN",
            },
            {
              name: "NEW MAN-AY",
            },
            {
              name: "NEW PANAY",
            },
            {
              name: "PALOC",
            },
            {
              name: "PAMINTARAN",
            },
            {
              name: "PARASANON",
            },
            {
              name: "TALIAN",
            },
            {
              name: "TANDIK",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TUPAZ",
            },
          ],
        },
        MAWAB: {
          name: "MAWAB",
          baranggays: [
            {
              name: "ANDILI",
            },
            {
              name: "BAWANI",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "MALINAWON",
            },
            {
              name: "NUEVA VISAYAS",
            },
            {
              name: "NUEVO ILOCO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAOSAO",
            },
            {
              name: "SAWANGAN",
            },
            {
              name: "TUBORAN",
            },
          ],
        },
        MONKAYO: {
          name: "MONKAYO",
          baranggays: [
            {
              name: "AWAO",
            },
            {
              name: "BABAG",
            },
            {
              name: "BANLAG",
            },
            {
              name: "BAYLO",
            },
            {
              name: "CASOON",
            },
            {
              name: "HAGUIMITAN",
            },
            {
              name: "INAMBATAN",
            },
            {
              name: "MACOPA",
            },
            {
              name: "MAMUNGA",
            },
            {
              name: "MOUNT DIWATA",
            },
            {
              name: "NABOC",
            },
            {
              name: "OLAYCON",
            },
            {
              name: "PASIAN (SANTA FILOMENA)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TUBO-TUBO (NEW DEL MONTE)",
            },
            {
              name: "UNION",
            },
            {
              name: "UPPER ULIP",
            },
          ],
        },
        MONTEVISTA: {
          name: "MONTEVISTA",
          baranggays: [
            {
              name: "BANAGBANAG",
            },
            {
              name: "BANGLASAN",
            },
            {
              name: "BANKEROHAN NORTE",
            },
            {
              name: "BANKEROHAN SUR",
            },
            {
              name: "CAMANSI",
            },
            {
              name: "CAMANTANGAN",
            },
            {
              name: "CANIDKID",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAUMAN",
            },
            {
              name: "LEBANON",
            },
            {
              name: "LINOAN",
            },
            {
              name: "MAYAON",
            },
            {
              name: "NEW CALAPE",
            },
            {
              name: "NEW CEBULAN (SAMBAYON)",
            },
            {
              name: "NEW DALAGUETE",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "PROSPERIDAD",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TAPIA",
            },
          ],
        },
        NABUNTURAN: {
          name: "NABUNTURAN",
          baranggays: [
            {
              name: "ANISLAGAN",
            },
            {
              name: "ANTIQUERA",
            },
            {
              name: "BASAK",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BUKAL",
            },
            {
              name: "CABACUNGAN",
            },
            {
              name: "CABIDIANAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LIBASAN",
            },
            {
              name: "LINDA",
            },
            {
              name: "MAGADING",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MANAT",
            },
            {
              name: "MATILO",
            },
            {
              name: "MIPANGI",
            },
            {
              name: "NEW DAUIS",
            },
            {
              name: "NEW SIBONGA",
            },
            {
              name: "OGAO",
            },
            {
              name: "PANGUTOSAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO NIÑO (KAO)",
            },
            {
              name: "SASA",
            },
            {
              name: "TAGNOCON",
            },
          ],
        },
        "NEW BATAAN": {
          name: "NEW BATAAN",
          baranggays: [
            {
              name: "ANDAP",
            },
            {
              name: "BANTACAN",
            },
            {
              name: "BATINAO",
            },
            {
              name: "CABINUANGAN (POB.)",
            },
            {
              name: "CAMANLANGAN",
            },
            {
              name: "COGONON",
            },
            {
              name: "FATIMA",
            },
            {
              name: "KAHAYAG",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MAGANGIT",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANURIGAO",
            },
            {
              name: "PAGSABANGAN",
            },
            {
              name: "PANAG",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TANDAWAN",
            },
          ],
        },
        PANTUKAN: {
          name: "PANTUKAN",
          baranggays: [
            {
              name: "ARAIBO",
            },
            {
              name: "BONGABONG",
            },
            {
              name: "BONGBONG",
            },
            {
              name: "KINGKING (POB.)",
            },
            {
              name: "LAS ARENAS",
            },
            {
              name: "MAGNAGA",
            },
            {
              name: "MATIAO",
            },
            {
              name: "NAPNAPAN",
            },
            {
              name: "P. FUENTES",
            },
            {
              name: "TAG-UGPO",
            },
            {
              name: "TAGDANGUA",
            },
            {
              name: "TAMBONGON",
            },
            {
              name: "TIBAGON",
            },
          ],
        },
      },
    },
    "COTABATO (NORTH COT.)": {
      name: "COTABATO (NORTH COT.)",
      region: "REGION 12",
      municipalities: {
        ALAMADA: {
          name: "ALAMADA",
          baranggays: [
            {
              name: "ALAMADA",
            },
            {
              name: "BAO",
            },
            {
              name: "BARANGIRAN",
            },
            {
              name: "CAMANSI",
            },
            {
              name: "DADO",
            },
            {
              name: "GUILING",
            },
            {
              name: "KITACUBONG (POB.)",
            },
            {
              name: "LOWER DADO",
            },
            {
              name: "MACABASA",
            },
            {
              name: "MALITUBOG",
            },
            {
              name: "MAPUROK",
            },
            {
              name: "MIRASOL",
            },
            {
              name: "PACAO",
            },
            {
              name: "PARUAYAN",
            },
            {
              name: "PIGCAWARAN",
            },
            {
              name: "POLAYAGAN",
            },
            {
              name: "RANGAYEN",
            },
            {
              name: "RARADANGAN",
            },
          ],
        },
        ALEOSAN: {
          name: "ALEOSAN",
          baranggays: [
            {
              name: "ALEOSAN",
            },
            {
              name: "BAGOLIBAS",
            },
            {
              name: "CAWILIHAN",
            },
            {
              name: "DUALING",
            },
            {
              name: "DUNGUAN",
            },
            {
              name: "KATALICANAN",
            },
            {
              name: "LAWILI",
            },
            {
              name: "LOWER MINGADING",
            },
            {
              name: "LUANAN",
            },
            {
              name: "MALAPANG",
            },
            {
              name: "NEW LEON",
            },
            {
              name: "NEW PANAY",
            },
            {
              name: "PAGANGAN",
            },
            {
              name: "PALACAT",
            },
            {
              name: "PENTIL",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAPODOC",
            },
            {
              name: "TOMADO",
            },
            {
              name: "UPPER MINGADING",
            },
          ],
        },
        AMAS: {
          name: "AMAS",
          baranggays: [
            {
              name: "AMAS",
            },
          ],
        },
        ANTIPAS: {
          name: "ANTIPAS",
          baranggays: [
            {
              name: "ANTIPAS",
            },
            {
              name: "B. CADUNGON",
            },
            {
              name: "CAMUTAN",
            },
            {
              name: "CANAAN",
            },
            {
              name: "DATU AGOD",
            },
            {
              name: "DOLORES",
            },
            {
              name: "KIYAAB",
            },
            {
              name: "LUHONG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALANGAG",
            },
            {
              name: "MALATAD",
            },
            {
              name: "MALIRE",
            },
            {
              name: "NEW PONTEVEDRA",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        ARAKAN: {
          name: "ARAKAN",
          baranggays: [
            {
              name: "ALLAB",
            },
            {
              name: "ANAPOLON",
            },
            {
              name: "ARAKAN",
            },
            {
              name: "BADIANGON",
            },
            {
              name: "BINOONGAN",
            },
            {
              name: "DALLAG",
            },
            {
              name: "DATU LADAYON",
            },
            {
              name: "DATU MATANGKIL",
            },
            {
              name: "DOROLUMAN",
            },
            {
              name: "GAMBODES",
            },
            {
              name: "GANATAN",
            },
            {
              name: "GREENFIELD",
            },
            {
              name: "KABALANTIAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KINAWAYAN",
            },
            {
              name: "KULAMAN VALLEY",
            },
            {
              name: "LANAO KURAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAKALANGOT",
            },
            {
              name: "MALIBATUAN",
            },
            {
              name: "MARIA CARIDAD",
            },
            {
              name: "MEOCAN",
            },
            {
              name: "NAJE",
            },
            {
              name: "NAPALICO",
            },
            {
              name: "SALASANG",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SUMALILI",
            },
            {
              name: "TUMANDING",
            },
          ],
        },
        BANISILAN: {
          name: "BANISILAN",
          baranggays: [
            {
              name: "BANISILAN POBLACION",
            },
            {
              name: "BUSAON",
            },
            {
              name: "CAPAYANGAN",
            },
            {
              name: "CARUGMANAN",
            },
            {
              name: "GASTAY",
            },
            {
              name: "KALAWAIG",
            },
            {
              name: "KIARING",
            },
            {
              name: "MALAGAP",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MIGUEL MACASARTE",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PARADISE",
            },
            {
              name: "PINAMULAAN",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "PUTING-BATO",
            },
            {
              name: "SOLAMA",
            },
            {
              name: "THAILAND",
            },
            {
              name: "TINIMBACAN",
            },
            {
              name: "TUMBAO-CAMALIG",
            },
            {
              name: "WADYA",
            },
          ],
        },
        CARMEN: {
          name: "CARMEN",
          baranggays: [
            {
              name: "AROMAN",
            },
            {
              name: "BENTANGAN",
            },
            {
              name: "CADIIS",
            },
            {
              name: "CARMEN",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "KATANAYANAN",
            },
            {
              name: "KIB-AYAO",
            },
            {
              name: "KIBENES",
            },
            {
              name: "KIBUGTONGAN",
            },
            {
              name: "KILALA",
            },
            {
              name: "KIMADZIL",
            },
            {
              name: "KITULAAN",
            },
            {
              name: "LANGOGAN",
            },
            {
              name: "LANOON",
            },
            {
              name: "LILIONGAN",
            },
            {
              name: "MACABENBAN",
            },
            {
              name: "MALAPAG",
            },
            {
              name: "MANARAPAN",
            },
            {
              name: "MANILI",
            },
            {
              name: "NASAPIAN",
            },
            {
              name: "PALANGGALAN",
            },
            {
              name: "PEBPOLOAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RANZO",
            },
            {
              name: "TACUPAN",
            },
            {
              name: "TAMBAD",
            },
            {
              name: "TONGANON",
            },
            {
              name: "TUPIG",
            },
            {
              name: "UGALINGAN (LUMAYONG)",
            },
          ],
        },
        "CITY OF KIDAPAWAN": {
          name: "CITY OF KIDAPAWAN",
          baranggays: [
            {
              name: "AMAS",
            },
            {
              name: "AMAZION",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BALINDOG",
            },
            {
              name: "BENOLIGAN",
            },
            {
              name: "BERADA",
            },
            {
              name: "CITY OF KIDAPAWAN",
            },
            {
              name: "GAYOLA",
            },
            {
              name: "GINATILAN",
            },
            {
              name: "ILOMAVIS",
            },
            {
              name: "INDANGAN",
            },
            {
              name: "JUNCTION",
            },
            {
              name: "KALAISAN",
            },
            {
              name: "KALASUYAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LANAO",
            },
            {
              name: "LINANGCOB",
            },
            {
              name: "LUVIMIN",
            },
            {
              name: "MACABOLIG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALINAN",
            },
            {
              name: "MANONGOL",
            },
            {
              name: "MARBEL (EMBAC)",
            },
            {
              name: "MATEO",
            },
            {
              name: "MEOCHAO",
            },
            {
              name: "MUA-AN",
            },
            {
              name: "NEW BOHOL",
            },
            {
              name: "NUANGAN",
            },
            {
              name: "ONICA",
            },
            {
              name: "PACO",
            },
            {
              name: "PATADON (PATADON EAST)",
            },
            {
              name: "PEREZ",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIBAWAN",
            },
            {
              name: "SIKITAN",
            },
            {
              name: "SINGAO",
            },
            {
              name: "SUDAPIN",
            },
            {
              name: "SUMBAO",
            },
          ],
        },
        KABACAN: {
          name: "KABACAN",
          baranggays: [
            {
              name: "ARINGAY",
            },
            {
              name: "BANGILAN",
            },
            {
              name: "BANNAWAG",
            },
            {
              name: "BULUAN",
            },
            {
              name: "CUYAPON",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "KABACAN",
            },
            {
              name: "KATIDTUAN",
            },
            {
              name: "KAYAGA",
            },
            {
              name: "KILAGASAN",
            },
            {
              name: "MAGATOS",
            },
            {
              name: "MALAMOTE",
            },
            {
              name: "MALANDUAGUE",
            },
            {
              name: "NANGA-AN",
            },
            {
              name: "OSIAS",
            },
            {
              name: "PAATAN LOWER",
            },
            {
              name: "PAATAN UPPER",
            },
            {
              name: "PEDTAD",
            },
            {
              name: "PISAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAPUNGAN",
            },
            {
              name: "SANGGADONG",
            },
            {
              name: "SIMBUHAY",
            },
            {
              name: "SIMONE",
            },
            {
              name: "TAMPED",
            },
          ],
        },
        LIBUNGAN: {
          name: "LIBUNGAN",
          baranggays: [
            {
              name: "ABAGA",
            },
            {
              name: "BAGUER",
            },
            {
              name: "BARONGIS",
            },
            {
              name: "BATIOCAN",
            },
            {
              name: "CABARUYAN",
            },
            {
              name: "CABPANGI",
            },
            {
              name: "DEMAPACO",
            },
            {
              name: "GREBONA",
            },
            {
              name: "GUMAGA",
            },
            {
              name: "KAPAYAWI",
            },
            {
              name: "KILOYAO",
            },
            {
              name: "KITUBOD",
            },
            {
              name: "LIBUNGAN",
            },
            {
              name: "MALENGEN",
            },
            {
              name: "MONTAY",
            },
            {
              name: "NICA-AN",
            },
            {
              name: "PALAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SINAPANGAN",
            },
            {
              name: "SINAWINGAN",
            },
            {
              name: "ULAMIAN",
            },
          ],
        },
        "M'LANG": {
          name: "M'LANG",
          baranggays: [
            {
              name: "BAGONTAPAY",
            },
            {
              name: "BIALONG",
            },
            {
              name: "BUAYAN",
            },
            {
              name: "CALUNASAN",
            },
            {
              name: "DAGONG",
            },
            {
              name: "DALIPE",
            },
            {
              name: "DUNGO-AN",
            },
            {
              name: "GAUNAN",
            },
            {
              name: "INAS",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LA FORTUNA",
            },
            {
              name: "LA SUERTE",
            },
            {
              name: "LANGKONG",
            },
            {
              name: "LEPAGA",
            },
            {
              name: "LIBOO",
            },
            {
              name: "LIKA",
            },
            {
              name: "LUZ VILLAGE",
            },
            {
              name: "MAGALLON",
            },
            {
              name: "MALAYAN",
            },
            {
              name: "NEW ANTIQUE",
            },
            {
              name: "NEW BARBAZA",
            },
            {
              name: "NEW CONSOLACION",
            },
            {
              name: "NEW ESPERANZA",
            },
            {
              name: "NEW JANIUAY",
            },
            {
              name: "NEW KALIBO",
            },
            {
              name: "NEW LAWA-AN",
            },
            {
              name: "NEW RIZAL",
            },
            {
              name: "NUEVA VIDA",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PALMA-PEREZ",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION B",
            },
            {
              name: "PULANG-LUPA",
            },
            {
              name: "SANGAT",
            },
            {
              name: "TAWANTAWAN",
            },
            {
              name: "TIBAO",
            },
            {
              name: "UGPAY",
            },
          ],
        },
        MAGPET: {
          name: "MAGPET",
          baranggays: [
            {
              name: "ALIBAYON",
            },
            {
              name: "AMABEL",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALETE",
            },
            {
              name: "BANGKAL",
            },
            {
              name: "BANTAC",
            },
            {
              name: "BASAK",
            },
            {
              name: "BINAY",
            },
            {
              name: "BONGOLANON",
            },
            {
              name: "DATU CELO",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DOLES",
            },
            {
              name: "DON PANACA",
            },
            {
              name: "GUBATAN",
            },
            {
              name: "ILIAN",
            },
            {
              name: "IMAMALING",
            },
            {
              name: "INAC",
            },
            {
              name: "KAMADA",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KINARUM",
            },
            {
              name: "KISANDAL",
            },
            {
              name: "MAGCAALAM",
            },
            {
              name: "MAGPET",
            },
            {
              name: "MAHONGCOG",
            },
            {
              name: "MANOBISA",
            },
            {
              name: "MANOBO",
            },
            {
              name: "NOA",
            },
            {
              name: "OWAS",
            },
            {
              name: "PANGAO-AN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALLAB",
            },
            {
              name: "TAGBAC",
            },
            {
              name: "TEMPORAN",
            },
          ],
        },
        MAKILALA: {
          name: "MAKILALA",
          baranggays: [
            {
              name: "BATASAN",
            },
            {
              name: "BATO",
            },
            {
              name: "BIANGAN",
            },
            {
              name: "BUENA VIDA",
            },
            {
              name: "BUHAY",
            },
            {
              name: "BULAKANON",
            },
            {
              name: "CABILAO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "GARSIKA",
            },
            {
              name: "GUANGAN",
            },
            {
              name: "INDANGAN",
            },
            {
              name: "JOSE RIZAL",
            },
            {
              name: "KATIPUNAN II",
            },
            {
              name: "KAWAYANON",
            },
            {
              name: "KISANTE",
            },
            {
              name: "LEBOCE",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LUAYON",
            },
            {
              name: "LUNA NORTE",
            },
            {
              name: "LUNA SUR",
            },
            {
              name: "MAKILALA",
            },
            {
              name: "MALABUAN",
            },
            {
              name: "MALASILA",
            },
            {
              name: "MALUNGON",
            },
            {
              name: "NEW BAGUIO",
            },
            {
              name: "NEW BULATUKAN",
            },
            {
              name: "NEW CEBU",
            },
            {
              name: "NEW ISRAEL",
            },
            {
              name: "OLD BULATUKAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RODERO",
            },
            {
              name: "SAGUING",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA FELOMINA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SINKATULAN",
            },
            {
              name: "TALUNTALUNAN",
            },
            {
              name: "VILLAFLORES",
            },
          ],
        },
        MATALAM: {
          name: "MATALAM",
          baranggays: [
            {
              name: "ARAKAN",
            },
            {
              name: "BANGBANG",
            },
            {
              name: "BATO",
            },
            {
              name: "CENTRAL MALAMOTE",
            },
            {
              name: "DALAPITAN",
            },
            {
              name: "ESTADO",
            },
            {
              name: "ILIAN",
            },
            {
              name: "KABULACAN",
            },
            {
              name: "KIBIA",
            },
            {
              name: "KIBUDOC",
            },
            {
              name: "KIDAMA",
            },
            {
              name: "KILADA",
            },
            {
              name: "LAMPAYAN",
            },
            {
              name: "LATAGAN",
            },
            {
              name: "LINAO",
            },
            {
              name: "LOWER MALAMOTE",
            },
            {
              name: "MANUBUAN",
            },
            {
              name: "MANUPAL",
            },
            {
              name: "MARBEL",
            },
            {
              name: "MATALAM",
            },
            {
              name: "MINAMAING",
            },
            {
              name: "NATUTUNGAN",
            },
            {
              name: "NEW ABRA",
            },
            {
              name: "NEW ALIMODIAN",
            },
            {
              name: "NEW BUGASONG",
            },
            {
              name: "NEW PANDAN",
            },
            {
              name: "PATADON WEST",
            },
            {
              name: "PINAMATON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SARAYAN",
            },
            {
              name: "TACULEN",
            },
            {
              name: "TAGURANAO",
            },
            {
              name: "TAMPED (TAMPAD)",
            },
          ],
        },
        MIDSAYAP: {
          name: "MIDSAYAP",
          baranggays: [
            {
              name: "AGRICULTURE",
            },
            {
              name: "ANONANG",
            },
            {
              name: "ARIZONA",
            },
            {
              name: "BAGUMBA",
            },
            {
              name: "BALIKI",
            },
            {
              name: "BARANGAY POBLACION 1",
            },
            {
              name: "BARANGAY POBLACION 2",
            },
            {
              name: "BARANGAY POBLACION 3",
            },
            {
              name: "BARANGAY POBLACION 4",
            },
            {
              name: "BARANGAY POBLACION 5",
            },
            {
              name: "BARANGAY POBLACION 6",
            },
            {
              name: "BARANGAY POBLACION 7",
            },
            {
              name: "BARANGAY POBLACION 8",
            },
            {
              name: "BITOKA",
            },
            {
              name: "BUAL NORTE",
            },
            {
              name: "BUAL SUR",
            },
            {
              name: "BULANAN UPPER",
            },
            {
              name: "CENTRAL BULANAN",
            },
            {
              name: "CENTRAL GLAD",
            },
            {
              name: "CENTRAL KATINGAWAN",
            },
            {
              name: "CENTRAL LABAS",
            },
            {
              name: "DAMATULAN",
            },
            {
              name: "ILBOCEAN",
            },
            {
              name: "KADIGASAN",
            },
            {
              name: "KADINGILAN",
            },
            {
              name: "KAPINPILAN",
            },
            {
              name: "KIMAGANGO",
            },
            {
              name: "KIWANAN",
            },
            {
              name: "KUDARANGAN",
            },
            {
              name: "LAGUMBINGAN",
            },
            {
              name: "LOMOPOG",
            },
            {
              name: "LOWER GLAD",
            },
            {
              name: "LOWER KATINGAWAN",
            },
            {
              name: "MACASENDEG",
            },
            {
              name: "MALAMOTE",
            },
            {
              name: "MALINGAO",
            },
            {
              name: "MIDSAYAP",
            },
            {
              name: "MILAYA",
            },
            {
              name: "MUDSENG",
            },
            {
              name: "NABALAWAG",
            },
            {
              name: "NALIN",
            },
            {
              name: "NES",
            },
            {
              name: "OLANDANG",
            },
            {
              name: "PALONGOGUEN",
            },
            {
              name: "PATINDEGUEN",
            },
            {
              name: "RANGABAN",
            },
            {
              name: "SADAAN",
            },
            {
              name: "SALUNAYAN",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TUGAL",
            },
            {
              name: "TUMBRAS",
            },
            {
              name: "UPPER GLAD I",
            },
            {
              name: "UPPER GLAD II",
            },
            {
              name: "UPPER LABAS",
            },
            {
              name: "VILLARICA",
            },
          ],
        },
        MLANG: {
          name: "MLANG",
          baranggays: [
            {
              name: "MLANG",
            },
          ],
        },
        PIGCAWAYAN: {
          name: "PIGCAWAYAN",
          baranggays: [
            {
              name: "PIGCAWAYAN",
            },
          ],
        },
        PIGKAWAYAN: {
          name: "PIGKAWAYAN",
          baranggays: [
            {
              name: "ANICK (UPPER BALOGO)",
            },
            {
              name: "BALACAYON",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BANUCAGON",
            },
            {
              name: "BULUAN",
            },
            {
              name: "BULUCAON",
            },
            {
              name: "BURICAIN",
            },
            {
              name: "CABPANGI",
            },
            {
              name: "CAPAYURAN",
            },
            {
              name: "CENTRAL PANATAN",
            },
            {
              name: "DATU BINASING",
            },
            {
              name: "DATU MANTIL",
            },
            {
              name: "KADINGILAN",
            },
            {
              name: "KIMARAYANG",
            },
            {
              name: "LIBUNGAN TORRETA",
            },
            {
              name: "LOWER BAGUER",
            },
            {
              name: "LOWER PANGANGKALAN",
            },
            {
              name: "MALAGAKIT",
            },
            {
              name: "MALUAO",
            },
            {
              name: "MATILAC",
            },
            {
              name: "MIDPAPAN I",
            },
            {
              name: "MIDPAPAN II",
            },
            {
              name: "MULOK",
            },
            {
              name: "NEW CULASI",
            },
            {
              name: "NEW IGBARAS",
            },
            {
              name: "NEW PANAY",
            },
            {
              name: "NORTH MANUANGAN",
            },
            {
              name: "PATOT",
            },
            {
              name: "PAYONG-PAYONG",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "PRESBITERO",
            },
            {
              name: "RENIBON",
            },
            {
              name: "SIMSIMAN",
            },
            {
              name: "SOUTH MANUANGAN",
            },
            {
              name: "TIGBAWAN",
            },
            {
              name: "TUBON",
            },
            {
              name: "UPPER BAGUER (BAGUER)",
            },
            {
              name: "UPPER PANGANGKALAN",
            },
          ],
        },
        PIKIT: {
          name: "PIKIT",
          baranggays: [
            {
              name: "BAGOAINGUD (BAGOINGED)",
            },
            {
              name: "BALABAK",
            },
            {
              name: "BALATICAN",
            },
            {
              name: "BALONG",
            },
            {
              name: "BALUNGIS",
            },
            {
              name: "BARUNGIS",
            },
            {
              name: "BATULAWAN",
            },
            {
              name: "BUALAN",
            },
            {
              name: "BULIOK",
            },
            {
              name: "BULOD",
            },
            {
              name: "BULOL",
            },
            {
              name: "CALAWAG",
            },
            {
              name: "DALINGAOEN (LALINGAON)",
            },
            {
              name: "DAMALASAK",
            },
            {
              name: "FORT PIKIT",
            },
            {
              name: "GINATILAN",
            },
            {
              name: "GLIGLI",
            },
            {
              name: "GOKOTON (GOKOTAN)",
            },
            {
              name: "INUG-UG",
            },
            {
              name: "KABASALAN",
            },
            {
              name: "KALACACAN",
            },
            {
              name: "KATILACAN",
            },
            {
              name: "KOLAMBOG",
            },
            {
              name: "LADTINGAN",
            },
            {
              name: "LAGUNDE",
            },
            {
              name: "LANGAYEN",
            },
            {
              name: "MACABUAL",
            },
            {
              name: "MACASENDEG",
            },
            {
              name: "MANAULANAN",
            },
            {
              name: "NABUNDAS",
            },
            {
              name: "NALAPAAN",
            },
            {
              name: "NUNGUAN",
            },
            {
              name: "PAIDU PULANGI",
            },
            {
              name: "PAMALIAN",
            },
            {
              name: "PANICUPAN",
            },
            {
              name: "PIKIT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNOL",
            },
            {
              name: "RAJAH MUDA",
            },
            {
              name: "SILIK",
            },
            {
              name: "TAKIPAN",
            },
            {
              name: "TALITAY",
            },
            {
              name: "TINUTULAN",
            },
          ],
        },
        "PRESIDENT ROXAS": {
          name: "PRESIDENT ROXAS",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BATO-BATO",
            },
            {
              name: "CABANGBANGAN",
            },
            {
              name: "CAMASI",
            },
            {
              name: "DATU INDANG",
            },
            {
              name: "DATU SANDONGAN",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "F. CAJELO (NEW MAASIN)",
            },
            {
              name: "GREENHILL",
            },
            {
              name: "IDAOMAN",
            },
            {
              name: "ILUSTRE",
            },
            {
              name: "KAMARAHAN",
            },
            {
              name: "KIMARUHING",
            },
            {
              name: "KISUPAAN",
            },
            {
              name: "LA ESPERANZA",
            },
            {
              name: "LABU-O",
            },
            {
              name: "LAMALAMA",
            },
            {
              name: "LOMONAY",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "NEW CEBU",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PRESIDENT ROXAS",
            },
            {
              name: "SAGCUNGAN",
            },
            {
              name: "SALAT",
            },
            {
              name: "SARAYAN",
            },
            {
              name: "TUAEL",
            },
          ],
        },
        TULUNAN: {
          name: "TULUNAN",
          baranggays: [
            {
              name: "BACONG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BANAYAL",
            },
            {
              name: "BATANG",
            },
            {
              name: "BITUAN",
            },
            {
              name: "BUAL",
            },
            {
              name: "BUNAWAN",
            },
            {
              name: "DAIG",
            },
            {
              name: "DAMAWATO",
            },
            {
              name: "DUNGOS",
            },
            {
              name: "F. CAJELO",
            },
            {
              name: "GALIDAN",
            },
            {
              name: "GENOVEVA BAYNOSA",
            },
            {
              name: "KANIBONG",
            },
            {
              name: "LA ESPERANZA",
            },
            {
              name: "LAMPAGANG",
            },
            {
              name: "MAGBOK",
            },
            {
              name: "MAYBULA",
            },
            {
              name: "MINAPAN",
            },
            {
              name: "NABUNDASAN",
            },
            {
              name: "NEW CARIDAD",
            },
            {
              name: "NEW CULASI",
            },
            {
              name: "NEW PANAY",
            },
            {
              name: "PARAISO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POPOYON",
            },
            {
              name: "SIBSIB",
            },
            {
              name: "TAMBAC",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "TULUNAN",
            },
          ],
        },
      },
    },
    "DAVAO (DAVAO DEL NORTE)": {
      name: "DAVAO (DAVAO DEL NORTE)",
      region: "REGION 11",
      municipalities: {
        ASUNCION: {
          name: "ASUNCION",
          baranggays: [
            {
              name: "ASUNCION",
            },
          ],
        },
        "ASUNCION (SAUG)": {
          name: "ASUNCION (SAUG)",
          baranggays: [
            {
              name: "BINANCIAN",
            },
            {
              name: "BUAN",
            },
            {
              name: "BUCLAD",
            },
            {
              name: "CABAYWA",
            },
            {
              name: "CAMANSA",
            },
            {
              name: "CAMBANOGOY (POB.)",
            },
            {
              name: "CAMONING",
            },
            {
              name: "CANATAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DOÑA ANDREA",
            },
            {
              name: "MAGATOS",
            },
            {
              name: "NAPUNGAS",
            },
            {
              name: "NEW BANTAYAN",
            },
            {
              name: "NEW LOON",
            },
            {
              name: "NEW SANTIAGO",
            },
            {
              name: "PAMACAUN",
            },
            {
              name: "SAGAYEN",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "SONLON",
            },
          ],
        },
        "BRAULIO E. DUJALI": {
          name: "BRAULIO E. DUJALI",
          baranggays: [
            {
              name: "CABAY-ANGAN",
            },
            {
              name: "DUJALI",
            },
            {
              name: "MAGUPISING",
            },
            {
              name: "NEW CASAY",
            },
            {
              name: "TANGLAW",
            },
          ],
        },
        CARMEN: {
          name: "CARMEN",
          baranggays: [
            {
              name: "ALEJAL",
            },
            {
              name: "ANIBONGAN",
            },
            {
              name: "ASUNCION (CUATRO-CUATRO)",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CEBULANO",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "ISING (POB.)",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "MABAUS",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANGALCAL",
            },
            {
              name: "MINDA",
            },
            {
              name: "NEW CAMILING",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TABA",
            },
            {
              name: "TIBULAO",
            },
            {
              name: "TUBOD",
            },
            {
              name: "TUGANAY",
            },
          ],
        },
        "CITY OF TAGUM": {
          name: "CITY OF TAGUM",
          baranggays: [
            {
              name: "APOKON",
            },
            {
              name: "BINCUNGAN",
            },
            {
              name: "BUSAON",
            },
            {
              name: "CANOCOTAN",
            },
            {
              name: "CUAMBOGAN",
            },
            {
              name: "LA FILIPINA",
            },
            {
              name: "LIBOGANON",
            },
            {
              name: "MADAUM",
            },
            {
              name: "MAGDUM",
            },
            {
              name: "MAGUGPO EAST",
            },
            {
              name: "MAGUGPO NORTH",
            },
            {
              name: "MAGUGPO POBLACION",
            },
            {
              name: "MAGUGPO SOUTH",
            },
            {
              name: "MAGUGPO WEST",
            },
            {
              name: "MANKILAM",
            },
            {
              name: "NEW BALAMBAN",
            },
            {
              name: "NUEVA FUERZA",
            },
            {
              name: "PAGSABANGAN",
            },
            {
              name: "PANDAPAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL (CAMP 4)",
            },
            {
              name: "VISAYAN VILLAGE",
            },
          ],
        },
        "ISLAND GARDEN CITY OF SAMAL": {
          name: "ISLAND GARDEN CITY OF SAMAL",
          baranggays: [
            {
              name: "ADECOR",
            },
            {
              name: "ANONANG",
            },
            {
              name: "AUMBAY",
            },
            {
              name: "AUNDANAO",
            },
            {
              name: "BALET",
            },
            {
              name: "BANDERA",
            },
            {
              name: "CALICLIC (DANGCA-AN)",
            },
            {
              name: "CAMUDMUD",
            },
            {
              name: "CATAGMAN",
            },
            {
              name: "CAWAG",
            },
            {
              name: "COGON",
            },
            {
              name: "COGON (TALICOD)",
            },
            {
              name: "DADATAN",
            },
            {
              name: "DEL MONTE",
            },
            {
              name: "GUILON",
            },
            {
              name: "KANAAN",
            },
            {
              name: "KINAWITNON",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIBUAK",
            },
            {
              name: "LICUP",
            },
            {
              name: "LIMAO",
            },
            {
              name: "LINOSUTAN",
            },
            {
              name: "MAMBAGO-A",
            },
            {
              name: "MAMBAGO-B",
            },
            {
              name: "MIRANDA (POB.)",
            },
            {
              name: "MONCADO (POB.)",
            },
            {
              name: "PANGUBATAN (TALICOD I)",
            },
            {
              name: "PEÑAPLATA (POB.)",
            },
            {
              name: "POBLACION (KAPUTIAN)",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO (BABAK)",
            },
            {
              name: "SAN ISIDRO (KAPUTIAN)",
            },
            {
              name: "SAN JOSE (SAN LAPUZ)",
            },
            {
              name: "SAN MIGUEL (MAGAMONO)",
            },
            {
              name: "SAN REMIGIO",
            },
            {
              name: "SANTA CRUZ (TALICOD II)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SION (ZION)",
            },
            {
              name: "TAGBAOBO",
            },
            {
              name: "TAGBAY",
            },
            {
              name: "TAGBITAN-AG",
            },
            {
              name: "TAGDALIAO",
            },
            {
              name: "TAGPOPONGAN",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TORIL",
            },
          ],
        },
        KAPALONG: {
          name: "KAPALONG",
          baranggays: [
            {
              name: "CAPUNGAGAN",
            },
            {
              name: "FLORIDA",
            },
            {
              name: "GABUYAN",
            },
            {
              name: "GUPITAN",
            },
            {
              name: "KAPALONG",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABANTAO",
            },
            {
              name: "MAMACAO",
            },
            {
              name: "MANIKI (POBLACION)",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "SAMPAO",
            },
            {
              name: "SEMONG",
            },
            {
              name: "SUA-ON",
            },
            {
              name: "TIBURCIA",
            },
          ],
        },
        "NEW CORELLA": {
          name: "NEW CORELLA",
          baranggays: [
            {
              name: "CABIDIANAN",
            },
            {
              name: "CARCOR",
            },
            {
              name: "DEL MONTE",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "EL SALVADOR",
            },
            {
              name: "LIMBA-AN",
            },
            {
              name: "MACGUM",
            },
            {
              name: "MAMBING",
            },
            {
              name: "MESAOY",
            },
            {
              name: "NEW BOHOL",
            },
            {
              name: "NEW CORELLA",
            },
            {
              name: "NEW CORTEZ",
            },
            {
              name: "NEW SAMBOG",
            },
            {
              name: "PATROCENIO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SUAWON",
            },
          ],
        },
        "PANABO CITY": {
          name: "PANABO CITY",
          baranggays: [
            {
              name: "A. O. FLOIRENDO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CACAO",
            },
            {
              name: "CAGANGOHAN",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "DAPCO",
            },
            {
              name: "DATU ABDUL DADIA",
            },
            {
              name: "GREDU (POB.)",
            },
            {
              name: "J.P. LAUREL",
            },
            {
              name: "KASILAK",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KATUALAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KIOTOY",
            },
            {
              name: "LITTLE PANAY",
            },
            {
              name: "LOWER PANAGA (ROXAS)",
            },
            {
              name: "MABUNAO",
            },
            {
              name: "MADUAO",
            },
            {
              name: "MALATIVAS",
            },
            {
              name: "MANAY",
            },
            {
              name: "NANYO",
            },
            {
              name: "NEW MALAGA (DALISAY)",
            },
            {
              name: "NEW MALITBOG",
            },
            {
              name: "NEW PANDAN (POB.)",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "PANABO CITY",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "SINDATON",
            },
            {
              name: "SOUTHERN DAVAO",
            },
            {
              name: "TAGPORE",
            },
            {
              name: "TIBUNGOL",
            },
            {
              name: "UPPER LICANAN",
            },
            {
              name: "WATERFALL",
            },
          ],
        },
        SAMAL: {
          name: "SAMAL",
          baranggays: [
            {
              name: "SAMAL",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "DACUDAO",
            },
            {
              name: "DATU BALONG",
            },
            {
              name: "IGANGON",
            },
            {
              name: "KIPALILI",
            },
            {
              name: "LIBUTON",
            },
            {
              name: "LINAO",
            },
            {
              name: "MAMANGAN",
            },
            {
              name: "MONTE DUJALI",
            },
            {
              name: "PINAMUNO",
            },
            {
              name: "SABANGAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAWATA",
            },
          ],
        },
        "SANTO TOMAS": {
          name: "SANTO TOMAS",
          baranggays: [
            {
              name: "BALAGUNAN",
            },
            {
              name: "BOBONGON",
            },
            {
              name: "CASIG-ANG",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "KIMAMON",
            },
            {
              name: "KINAMAYAN",
            },
            {
              name: "LA LIBERTAD",
            },
            {
              name: "LUNGAOG",
            },
            {
              name: "MAGWAWA",
            },
            {
              name: "NEW KATIPUNAN",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "PANTARON",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TALOMO",
            },
            {
              name: "TIBAL-OG (POB.)",
            },
            {
              name: "TULALIAN",
            },
          ],
        },
        TAGUM: {
          name: "TAGUM",
          baranggays: [
            {
              name: "TAGUM",
            },
          ],
        },
        TALAINGOD: {
          name: "TALAINGOD",
          baranggays: [
            {
              name: "DAGOHOY",
            },
            {
              name: "PALMA GIL",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
      },
    },
    "DAVAO DEL SUR": {
      name: "DAVAO DEL SUR",
      region: "REGION 11",
      municipalities: {
        BANSALAN: {
          name: "BANSALAN",
          baranggays: [
            {
              name: "ALEGRE",
            },
            {
              name: "ALTA VISTA",
            },
            {
              name: "ANONANG",
            },
            {
              name: "BANSALAN",
            },
            {
              name: "BITAUG",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "DARAPUAY",
            },
            {
              name: "DOLO",
            },
            {
              name: "EMAN",
            },
            {
              name: "KINUSKUSAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LINAWAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MABUNGA",
            },
            {
              name: "MANAGA",
            },
            {
              name: "MARBER",
            },
            {
              name: "NEW CLARIN (MIRAL)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION DOS",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIBAYAN",
            },
            {
              name: "TINONGTONGAN",
            },
            {
              name: "TUBOD",
            },
            {
              name: "UNION",
            },
          ],
        },
        "DAVAO CITY": {
          name: "DAVAO CITY",
          baranggays: [
            {
              name: "ACACIA",
            },
            {
              name: "AGDAO",
            },
            {
              name: "ALAMBRE",
            },
            {
              name: "ALEJANDRA NAVARRO (LASANG)",
            },
            {
              name: "ALFONSO ANGLIONGTO SR.",
            },
            {
              name: "ANGALAN",
            },
            {
              name: "ATAN-AWE",
            },
            {
              name: "BAGANIHAN",
            },
            {
              name: "BAGO APLAYA",
            },
            {
              name: "BAGO GALLERA",
            },
            {
              name: "BAGO OSHIRO",
            },
            {
              name: "BAGUIO (POB.)",
            },
            {
              name: "BALENGAENG",
            },
            {
              name: "BALIOK",
            },
            {
              name: "BANGKAS HEIGHTS",
            },
            {
              name: "BANTOL",
            },
            {
              name: "BARACATAN",
            },
            {
              name: "BARANGAY 1-A (POB.)",
            },
            {
              name: "BARANGAY 10-A (POB.)",
            },
            {
              name: "BARANGAY 11-B (POB.)",
            },
            {
              name: "BARANGAY 12-B (POB.)",
            },
            {
              name: "BARANGAY 13-B (POB.)",
            },
            {
              name: "BARANGAY 14-B (POB.)",
            },
            {
              name: "BARANGAY 15-B (POB.)",
            },
            {
              name: "BARANGAY 16-B (POB.)",
            },
            {
              name: "BARANGAY 17-B (POB.)",
            },
            {
              name: "BARANGAY 18-B (POB.)",
            },
            {
              name: "BARANGAY 19-B (POB.)",
            },
            {
              name: "BARANGAY 2-A (POB.)",
            },
            {
              name: "BARANGAY 20-B (POB.)",
            },
            {
              name: "BARANGAY 21-C (POB.)",
            },
            {
              name: "BARANGAY 22-C (POB.)",
            },
            {
              name: "BARANGAY 23-C (POB.)",
            },
            {
              name: "BARANGAY 24-C (POB.)",
            },
            {
              name: "BARANGAY 25-C (POB.)",
            },
            {
              name: "BARANGAY 26-C (POB.)",
            },
            {
              name: "BARANGAY 27-C (POB.)",
            },
            {
              name: "BARANGAY 28-C (POB.)",
            },
            {
              name: "BARANGAY 29-C (POB.)",
            },
            {
              name: "BARANGAY 3-A (POB.)",
            },
            {
              name: "BARANGAY 30-C (POB.)",
            },
            {
              name: "BARANGAY 31-D (POB.)",
            },
            {
              name: "BARANGAY 32-D (POB.)",
            },
            {
              name: "BARANGAY 33-D (POB.)",
            },
            {
              name: "BARANGAY 34-D (POB.)",
            },
            {
              name: "BARANGAY 35-D (POB.)",
            },
            {
              name: "BARANGAY 36-D (POB.)",
            },
            {
              name: "BARANGAY 37-D (POB.)",
            },
            {
              name: "BARANGAY 38-D (POB.)",
            },
            {
              name: "BARANGAY 39-D (POB.)",
            },
            {
              name: "BARANGAY 4-A (POB.)",
            },
            {
              name: "BARANGAY 40-D (POB.)",
            },
            {
              name: "BARANGAY 5-A (POB.)",
            },
            {
              name: "BARANGAY 6-A (POB.)",
            },
            {
              name: "BARANGAY 7-A (POB.)",
            },
            {
              name: "BARANGAY 8-A (POB.)",
            },
            {
              name: "BARANGAY 9-A (POB.)",
            },
            {
              name: "BATO",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BIAO ESCUELA",
            },
            {
              name: "BIAO GUIANGA",
            },
            {
              name: "BIAO JOAQUIN",
            },
            {
              name: "BINUGAO",
            },
            {
              name: "BUCANA",
            },
            {
              name: "BUDA",
            },
            {
              name: "BUHANGIN (POB.)",
            },
            {
              name: "BUNAWAN (POB.)",
            },
            {
              name: "CABANTIAN",
            },
            {
              name: "CADALIAN",
            },
            {
              name: "CALINAN (POB.)",
            },
            {
              name: "CALLAWA",
            },
            {
              name: "CAMANSI",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CATALUNAN GRANDE",
            },
            {
              name: "CATALUNAN PEQUEÑO",
            },
            {
              name: "CATIGAN",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CENTRO (SAN JUAN)",
            },
            {
              name: "COLOSAS",
            },
            {
              name: "COMMUNAL",
            },
            {
              name: "CROSSING BAYABAS",
            },
            {
              name: "DACUDAO",
            },
            {
              name: "DALAG",
            },
            {
              name: "DALAGDAG",
            },
            {
              name: "DALIAO",
            },
            {
              name: "DALIAON PLANTATION",
            },
            {
              name: "DATU SALUMAY",
            },
            {
              name: "DAVAO CITY",
            },
            {
              name: "DOMINGA",
            },
            {
              name: "DUMOY",
            },
            {
              name: "EDEN",
            },
            {
              name: "FATIMA (BENOWANG)",
            },
            {
              name: "GATUNGAN",
            },
            {
              name: "GOV. PACIANO BANGOY",
            },
            {
              name: "GOV. VICENTE DUTERTE",
            },
            {
              name: "GUMALANG",
            },
            {
              name: "GUMITAN",
            },
            {
              name: "ILANG",
            },
            {
              name: "INAYANGAN",
            },
            {
              name: "INDANGAN",
            },
            {
              name: "KAP. TOMAS MONTEVERDE, SR.",
            },
            {
              name: "KILATE",
            },
            {
              name: "LACSON",
            },
            {
              name: "LAMANAN",
            },
            {
              name: "LAMPIANAO",
            },
            {
              name: "LANGUB",
            },
            {
              name: "LAPU-LAPU",
            },
            {
              name: "LEON GARCIA, SR.",
            },
            {
              name: "LIZADA",
            },
            {
              name: "LOS AMIGOS",
            },
            {
              name: "LUBOGAN",
            },
            {
              name: "LUMIAD",
            },
            {
              name: "MA-A",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAGTUOD",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MALABOG",
            },
            {
              name: "MALAGOS",
            },
            {
              name: "MALAMBA",
            },
            {
              name: "MANAMBULAN",
            },
            {
              name: "MANDUG",
            },
            {
              name: "MANUEL GUIANGA",
            },
            {
              name: "MAPULA",
            },
            {
              name: "MARAPANGI",
            },
            {
              name: "MARILOG",
            },
            {
              name: "MATINA APLAYA",
            },
            {
              name: "MATINA BIAO",
            },
            {
              name: "MATINA CROSSING",
            },
            {
              name: "MATINA PANGI",
            },
            {
              name: "MEGKAWAYAN",
            },
            {
              name: "MINTAL",
            },
            {
              name: "MUDIANG",
            },
            {
              name: "MULIG",
            },
            {
              name: "NEW CARMEN",
            },
            {
              name: "NEW VALENCIA",
            },
            {
              name: "PAMPANGA",
            },
            {
              name: "PANACAN",
            },
            {
              name: "PANALUM",
            },
            {
              name: "PANDAITAN",
            },
            {
              name: "PANGYAN",
            },
            {
              name: "PAQUIBATO (POB.)",
            },
            {
              name: "PARADISE EMBAK",
            },
            {
              name: "RAFAEL CASTILLO",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "SALAPAWAN",
            },
            {
              name: "SALAYSAY",
            },
            {
              name: "SALOY",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO (LICANAN)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SASA",
            },
            {
              name: "SIBULAN",
            },
            {
              name: "SIRAWAN",
            },
            {
              name: "SIRIB",
            },
            {
              name: "SUAWAN (TULI)",
            },
            {
              name: "SUBASTA",
            },
            {
              name: "SUMIMAO",
            },
            {
              name: "TACUNAN",
            },
            {
              name: "TAGAKPAN",
            },
            {
              name: "TAGLUNO",
            },
            {
              name: "TAGURANO",
            },
            {
              name: "TALANDANG",
            },
            {
              name: "TALOMO (POB.)",
            },
            {
              name: "TALOMO RIVER",
            },
            {
              name: "TAMAYONG",
            },
            {
              name: "TAMBOBONG",
            },
            {
              name: "TAMUGAN",
            },
            {
              name: "TAPAK",
            },
            {
              name: "TAWAN-TAWAN",
            },
            {
              name: "TIBULOY",
            },
            {
              name: "TIBUNGCO",
            },
            {
              name: "TIGATTO",
            },
            {
              name: "TORIL (POB.)",
            },
            {
              name: "TUGBOK (POB.)",
            },
            {
              name: "TUNGAKALAN",
            },
            {
              name: "UBALDE",
            },
            {
              name: "ULA",
            },
            {
              name: "VICENTE HIZON SR.",
            },
            {
              name: "WAAN",
            },
            {
              name: "WANGAN",
            },
            {
              name: "WILFREDO AQUINO",
            },
            {
              name: "WINES",
            },
          ],
        },
        "DIGOS CITY": {
          name: "DIGOS CITY",
          baranggays: [
            {
              name: "APLAYA",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BINATON",
            },
            {
              name: "COGON",
            },
            {
              name: "COLORADO",
            },
            {
              name: "DAWIS",
            },
            {
              name: "DIGOS CITY",
            },
            {
              name: "DULANGAN",
            },
            {
              name: "GOMA",
            },
            {
              name: "IGPIT",
            },
            {
              name: "KAPATAGAN (RIZAL)",
            },
            {
              name: "KIAGOT",
            },
            {
              name: "LUNGAG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MATTI",
            },
            {
              name: "RUPARAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN JOSE (BALUTAKAY)",
            },
            {
              name: "SAN MIGUEL (ODACA)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SINAWILAN",
            },
            {
              name: "SOONG",
            },
            {
              name: "TIGUMAN",
            },
            {
              name: "TRES DE MAYO",
            },
            {
              name: "ZONE 1 (POB.)",
            },
            {
              name: "ZONE 2 (POB.)",
            },
            {
              name: "ZONE 3 (POB.)",
            },
          ],
        },
        HAGONOY: {
          name: "HAGONOY",
          baranggays: [
            {
              name: "BALUTAKAY",
            },
            {
              name: "CLIB",
            },
            {
              name: "GUIHING",
            },
            {
              name: "GUIHING APLAYA",
            },
            {
              name: "HAGONOY",
            },
            {
              name: "HAGONOY CROSSING",
            },
            {
              name: "KIBUAYA",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LANURO",
            },
            {
              name: "LAPULABAO",
            },
            {
              name: "LELING",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALABANG DAMSITE",
            },
            {
              name: "MALIIT DIGOS",
            },
            {
              name: "NEW QUEZON",
            },
            {
              name: "PALIGUE",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SACUB",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SINAYAWAN",
            },
            {
              name: "TOLOGAN",
            },
          ],
        },
        KIBLAWAN: {
          name: "KIBLAWAN",
          baranggays: [
            {
              name: "ABNATE",
            },
            {
              name: "BAGONG NEGROS",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALASIAO",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BULOL-SALO",
            },
            {
              name: "BUNOT",
            },
            {
              name: "COGON-BACACA",
            },
            {
              name: "DAPOK",
            },
            {
              name: "IHAN",
            },
            {
              name: "KIBLAWAN",
            },
            {
              name: "KIBONGBONG",
            },
            {
              name: "KIMLAWIS",
            },
            {
              name: "KISULAN",
            },
            {
              name: "LATI-AN",
            },
            {
              name: "MANUAL",
            },
            {
              name: "MARAGA-A",
            },
            {
              name: "MOLOPOLO",
            },
            {
              name: "NEW SIBONGA",
            },
            {
              name: "PANAGLIB",
            },
            {
              name: "PASIG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POCALEEL",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TACUB",
            },
            {
              name: "TACUL",
            },
            {
              name: "WATERFALL",
            },
          ],
        },
        MAGSAYSAY: {
          name: "MAGSAYSAY",
          baranggays: [
            {
              name: "BACUNGAN",
            },
            {
              name: "BALNATE",
            },
            {
              name: "BARAYONG",
            },
            {
              name: "BLOCON",
            },
            {
              name: "DALAWINON",
            },
            {
              name: "DALUMAY",
            },
            {
              name: "GLAMANG",
            },
            {
              name: "KANAPULO",
            },
            {
              name: "KASUGA",
            },
            {
              name: "LOWER BALA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAIBO",
            },
            {
              name: "MALAWANIT",
            },
            {
              name: "MALONGON",
            },
            {
              name: "NEW ILOCOS",
            },
            {
              name: "NEW OPON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "TACUL",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "UPPER BALA",
            },
          ],
        },
        MALALAG: {
          name: "MALALAG",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BOLTON",
            },
            {
              name: "BULACAN",
            },
            {
              name: "CAPUTIAN",
            },
            {
              name: "IBO",
            },
            {
              name: "KIBLAGON",
            },
            {
              name: "LAPLA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALALAG",
            },
            {
              name: "NEW BACLAYON",
            },
            {
              name: "PITU",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL (PARAME)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "TAGANSULE",
            },
          ],
        },
        MATANAO: {
          name: "MATANAO",
          baranggays: [
            {
              name: "ASBANG",
            },
            {
              name: "ASINAN",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BANGKAL",
            },
            {
              name: "BUAS",
            },
            {
              name: "BURI",
            },
            {
              name: "CABLIGAN (MANAGA)",
            },
            {
              name: "CAMANCHILES",
            },
            {
              name: "CEBOZA",
            },
            {
              name: "COLONSABAK",
            },
            {
              name: "DONGAN-PEKONG",
            },
            {
              name: "KABASAGAN",
            },
            {
              name: "KAPOK",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KIBAO",
            },
            {
              name: "LA SUERTE",
            },
            {
              name: "LANGA-AN",
            },
            {
              name: "LOWER MARBER",
            },
            {
              name: "MANGA",
            },
            {
              name: "MATANAO",
            },
            {
              name: "NEW KATIPUNAN",
            },
            {
              name: "NEW MURCIA",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABOY",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SAUB",
            },
            {
              name: "SINARAGAN",
            },
            {
              name: "SINAWILAN",
            },
            {
              name: "TAMLANGON",
            },
            {
              name: "TIBONGBONG",
            },
            {
              name: "TOWAK",
            },
          ],
        },
        PADADA: {
          name: "PADADA",
          baranggays: [
            {
              name: "ALMENDRAS (POB.)",
            },
            {
              name: "DON SERGIO OSMEÑA, SR.",
            },
            {
              name: "HARADA BUTAI",
            },
            {
              name: "LOWER KATIPUNAN",
            },
            {
              name: "LOWER LIMONZO",
            },
            {
              name: "LOWER MALINAO",
            },
            {
              name: "N C ORDANEZA DISTRICT (POB.)",
            },
            {
              name: "NORTHERN PALIGUE",
            },
            {
              name: "PADADA",
            },
            {
              name: "PALILI",
            },
            {
              name: "PIAPE",
            },
            {
              name: "PUNTA PIAPE",
            },
            {
              name: "QUIRINO DISTRICT (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SOUTHERN PALIGUE",
            },
            {
              name: "TULOGAN",
            },
            {
              name: "UPPER LIMONZO",
            },
            {
              name: "UPPER MALINAO",
            },
          ],
        },
        "SANTA CRUZ": {
          name: "SANTA CRUZ",
          baranggays: [
            {
              name: "ASTORGA",
            },
            {
              name: "BATO",
            },
            {
              name: "CORONON",
            },
            {
              name: "DARONG",
            },
            {
              name: "INAWAYAN",
            },
            {
              name: "JOSE RIZAL",
            },
            {
              name: "MATUTUNGAN",
            },
            {
              name: "MELILIA",
            },
            {
              name: "SALIDUCON",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SIBULAN",
            },
            {
              name: "SINORON",
            },
            {
              name: "TAGABULI",
            },
            {
              name: "TIBOLO",
            },
            {
              name: "TUBAN",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
          ],
        },
        SULOP: {
          name: "SULOP",
          baranggays: [
            {
              name: "BALASINON",
            },
            {
              name: "BUGUIS",
            },
            {
              name: "CARRE",
            },
            {
              name: "CLIB",
            },
            {
              name: "HARADA BUTAI",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KIBLAGON",
            },
            {
              name: "LABON",
            },
            {
              name: "LAPERAS",
            },
            {
              name: "LAPLA",
            },
            {
              name: "LITOS",
            },
            {
              name: "LUPARAN",
            },
            {
              name: "MCKINLEY",
            },
            {
              name: "NEW CEBU",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PALILI",
            },
            {
              name: "PARAME",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SOLONGVALE",
            },
            {
              name: "SULOP",
            },
            {
              name: "TAGOLILONG",
            },
            {
              name: "TALA-O",
            },
            {
              name: "TALAS",
            },
            {
              name: "TANWALANG",
            },
            {
              name: "WATERFALL",
            },
          ],
        },
      },
    },
    "DAVAO OCCIDENTAL": {
      name: "DAVAO OCCIDENTAL",
      region: "REGION 11",
      municipalities: {
        "DON MARCELINO": {
          name: "DON MARCELINO",
          baranggays: [
            {
              name: "BALUNTAYA",
            },
            {
              name: "CALIAN",
            },
            {
              name: "DALUPAN",
            },
            {
              name: "DON MARCELINO",
            },
            {
              name: "KINANGA",
            },
            {
              name: "KIOBOG",
            },
            {
              name: "LANAO",
            },
            {
              name: "LAPUAN",
            },
            {
              name: "LAWA (POB.)",
            },
            {
              name: "LINADASAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "NORTH LAMIDAN",
            },
            {
              name: "NUEVA VILLA",
            },
            {
              name: "SOUTH LAMIDAN",
            },
            {
              name: "TALAGUTONG (POB.)",
            },
            {
              name: "WEST LAMIDAN",
            },
          ],
        },
        "JOSE ABAD SANTOS": {
          name: "JOSE ABAD SANTOS",
          baranggays: [
            {
              name: "JOSE ABAD SANTOS",
            },
          ],
        },
        "JOSE ABAD SANTOS (TRINIDAD)": {
          name: "JOSE ABAD SANTOS (TRINIDAD)",
          baranggays: [
            {
              name: "BALANGONAN",
            },
            {
              name: "BUGUIS",
            },
            {
              name: "BUKID",
            },
            {
              name: "BUTUAN",
            },
            {
              name: "BUTULAN",
            },
            {
              name: "CABURAN BIG",
            },
            {
              name: "CABURAN SMALL (POB.)",
            },
            {
              name: "CAMALIAN",
            },
            {
              name: "CARAHAYAN",
            },
            {
              name: "CAYAPONGA",
            },
            {
              name: "CULAMAN",
            },
            {
              name: "KALBAY",
            },
            {
              name: "KITAYO",
            },
            {
              name: "MAGULIBAS",
            },
            {
              name: "MALALAN",
            },
            {
              name: "MANGILE",
            },
            {
              name: "MARABUTUAN",
            },
            {
              name: "MEYBIO",
            },
            {
              name: "MOLMOL",
            },
            {
              name: "NUING",
            },
            {
              name: "PATULANG",
            },
            {
              name: "QUIAPO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SUGAL",
            },
            {
              name: "TABAYON",
            },
            {
              name: "TANUMAN",
            },
          ],
        },
        MALITA: {
          name: "MALITA",
          baranggays: [
            {
              name: "BITO",
            },
            {
              name: "BOLILA",
            },
            {
              name: "BUHANGIN",
            },
            {
              name: "CULAMAN",
            },
            {
              name: "DATU DANWATA",
            },
            {
              name: "DEMOLOC",
            },
            {
              name: "FELIS",
            },
            {
              name: "FISHING VILLAGE (FISHERMAN'S VILLAGE)",
            },
            {
              name: "KIBALATONG",
            },
            {
              name: "KIDALAPONG",
            },
            {
              name: "KILALAG",
            },
            {
              name: "KINANGAN",
            },
            {
              name: "LACARON",
            },
            {
              name: "LAGUMIT",
            },
            {
              name: "LAIS",
            },
            {
              name: "LITTLE BAGUIO",
            },
            {
              name: "MACOL",
            },
            {
              name: "MALITA",
            },
            {
              name: "MANA",
            },
            {
              name: "MANUEL PERALTA",
            },
            {
              name: "NEW ARGAO",
            },
            {
              name: "PANGALEON",
            },
            {
              name: "PANGIAN",
            },
            {
              name: "PINALPALAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANGAY",
            },
            {
              name: "TALOGOY",
            },
            {
              name: "TICAL",
            },
            {
              name: "TICULON",
            },
            {
              name: "TINGOLO",
            },
            {
              name: "TUBALAN",
            },
          ],
        },
        "SANTA MARIA": {
          name: "SANTA MARIA",
          baranggays: [
            {
              name: "BASIAWAN",
            },
            {
              name: "BUCA",
            },
            {
              name: "CADAATAN",
            },
            {
              name: "DATU DALIGASAO",
            },
            {
              name: "DATU INTAN",
            },
            {
              name: "KIDADAN",
            },
            {
              name: "KINILIDAN",
            },
            {
              name: "KISULAD",
            },
            {
              name: "MALALAG TUBIG",
            },
            {
              name: "MAMACAO",
            },
            {
              name: "OGPAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PONGPONG",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TANGLAD",
            },
          ],
        },
        SARANGANI: {
          name: "SARANGANI",
          baranggays: [
            {
              name: "BATUGANDING",
            },
            {
              name: "CAMAHUAL",
            },
            {
              name: "CAMALIG",
            },
            {
              name: "GOMTAGO",
            },
            {
              name: "KONEL",
            },
            {
              name: "LAKER (SARANGANI SUR)",
            },
            {
              name: "LIPOL",
            },
            {
              name: "MABILA (POB.)",
            },
            {
              name: "PATUCO (SARANGANI NORTE)",
            },
            {
              name: "TAGEN",
            },
            {
              name: "TININA",
            },
            {
              name: "TUCAL",
            },
          ],
        },
      },
    },
    "DAVAO ORIENTAL": {
      name: "DAVAO ORIENTAL",
      region: "REGION 11",
      municipalities: {
        BAGANGA: {
          name: "BAGANGA",
          baranggays: [
            {
              name: "BACULIN",
            },
            {
              name: "BAGANGA",
            },
            {
              name: "BANAO",
            },
            {
              name: "BATAWAN",
            },
            {
              name: "BATIANO",
            },
            {
              name: "BINONDO",
            },
            {
              name: "BOBONAO",
            },
            {
              name: "CAMPAWAN",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "DAPNAN",
            },
            {
              name: "KINABLANGAN",
            },
            {
              name: "LAMBAJON",
            },
            {
              name: "LUCOD",
            },
            {
              name: "MAHANUB",
            },
            {
              name: "MIKIT",
            },
            {
              name: "SALINGCOMOT",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICTOR",
            },
            {
              name: "SAOQUEGUE",
            },
          ],
        },
        BANAYBANAY: {
          name: "BANAYBANAY",
          baranggays: [
            {
              name: "BANAYBANAY",
            },
            {
              name: "CABANGCALAN",
            },
            {
              name: "CAGANGANAN",
            },
            {
              name: "CALUBIHAN",
            },
            {
              name: "CAUSWAGAN",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MAPUTI",
            },
            {
              name: "MOGBONGCOGON",
            },
            {
              name: "PANIKIAN",
            },
            {
              name: "PINTATAGAN",
            },
            {
              name: "PISO PROPER",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNTA LINAO",
            },
            {
              name: "RANG-AY",
            },
            {
              name: "SAN VICENTE",
            },
          ],
        },
        BOSTON: {
          name: "BOSTON",
          baranggays: [
            {
              name: "BOSTON",
            },
            {
              name: "CAATIHAN",
            },
            {
              name: "CABASAGAN",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CAWAYANAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SIBAJAY",
            },
            {
              name: "SIMULAO",
            },
          ],
        },
        CARAGA: {
          name: "CARAGA",
          baranggays: [
            {
              name: "ALVAR",
            },
            {
              name: "CANINGAG",
            },
            {
              name: "CARAGA",
            },
            {
              name: "DON LEON BALANTE",
            },
            {
              name: "LAMIAWAN",
            },
            {
              name: "MANORIGAO",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "PALMA GIL",
            },
            {
              name: "PICHON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SOBRECAREY",
            },
          ],
        },
        CATEEL: {
          name: "CATEEL",
          baranggays: [
            {
              name: "ABIJOD",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "ALIWAGWAG",
            },
            {
              name: "ARAGON",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "CATEEL",
            },
            {
              name: "MAGLAHUS",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MALIBAGO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ALFONSO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "TAYTAYAN",
            },
          ],
        },
        "GOVERNOR GENEROSO": {
          name: "GOVERNOR GENEROSO",
          baranggays: [
            {
              name: "ANITAP",
            },
            {
              name: "CRISPIN DELA CRUZ",
            },
            {
              name: "DON AURELIO CHICOTE",
            },
            {
              name: "GOVERNOR GENEROSO",
            },
            {
              name: "LAVIGAN",
            },
            {
              name: "LUZON",
            },
            {
              name: "MAGDUG",
            },
            {
              name: "MANUEL ROXAS",
            },
            {
              name: "MONSERRAT",
            },
            {
              name: "NANGAN",
            },
            {
              name: "OREGON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNDAGUITAN",
            },
            {
              name: "SERGIO OSMEÑA",
            },
            {
              name: "SUROP",
            },
            {
              name: "TAGABEBE",
            },
            {
              name: "TAMBAN",
            },
            {
              name: "TANDANG SORA",
            },
            {
              name: "TIBANBAN",
            },
            {
              name: "TIBLAWAN",
            },
            {
              name: "UPPER TIBANBAN",
            },
          ],
        },
        LUPON: {
          name: "LUPON",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CALAPAGAN",
            },
            {
              name: "COCORNON",
            },
            {
              name: "CORPORACION",
            },
            {
              name: "DON MARIANO MARCOS",
            },
            {
              name: "ILANGAY",
            },
            {
              name: "LANGKA",
            },
            {
              name: "LANTAWAN",
            },
            {
              name: "LIMBAHAN",
            },
            {
              name: "LUPON",
            },
            {
              name: "MACANGAO",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MARAGATAS",
            },
            {
              name: "MARAYAG",
            },
            {
              name: "NEW VISAYAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TAGBOA",
            },
            {
              name: "TAGUGPO",
            },
          ],
        },
        MANAY: {
          name: "MANAY",
          baranggays: [
            {
              name: "CAPASNAN",
            },
            {
              name: "CAYAWAN",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GUZA",
            },
            {
              name: "HOLY CROSS",
            },
            {
              name: "LAMBOG",
            },
            {
              name: "MABINI",
            },
            {
              name: "MANAY",
            },
            {
              name: "MANREZA",
            },
            {
              name: "NEW TAOKANGA",
            },
            {
              name: "OLD MACOPA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN FERMIN",
            },
            {
              name: "SAN IGNACIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "ZARAGOSA",
            },
          ],
        },
        "MATI CITY": {
          name: "MATI CITY",
          baranggays: [
            {
              name: "BADAS",
            },
            {
              name: "BOBON",
            },
            {
              name: "BUSO",
            },
            {
              name: "CABUAYA",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "CULIAN",
            },
            {
              name: "DAHICAN",
            },
            {
              name: "DANAO",
            },
            {
              name: "DAWAN",
            },
            {
              name: "DON ENRIQUE LOPEZ",
            },
            {
              name: "DON MARTIN MARUNDAN",
            },
            {
              name: "DON SALVADOR LOPEZ, SR.",
            },
            {
              name: "LANGKA",
            },
            {
              name: "LAWIGAN",
            },
            {
              name: "LIBUDON",
            },
            {
              name: "LUBAN",
            },
            {
              name: "MACAMBOL",
            },
            {
              name: "MAMALI",
            },
            {
              name: "MATI CITY",
            },
            {
              name: "MATIAO",
            },
            {
              name: "MAYO",
            },
            {
              name: "SAINZ",
            },
            {
              name: "SANGHAY",
            },
            {
              name: "TAGABAKID",
            },
            {
              name: "TAGBINONGA",
            },
            {
              name: "TAGUIBO",
            },
            {
              name: "TAMISAN",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "BAON",
            },
            {
              name: "BATOBATO (POB.)",
            },
            {
              name: "BITAOGAN",
            },
            {
              name: "CAMBALEON",
            },
            {
              name: "DUGMANON",
            },
            {
              name: "IBA",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LAPU-LAPU",
            },
            {
              name: "MAAG",
            },
            {
              name: "MANIKLING",
            },
            {
              name: "MAPUTI",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SUDLON",
            },
            {
              name: "TALISAY",
            },
          ],
        },
        TARRAGONA: {
          name: "TARRAGONA",
          baranggays: [
            {
              name: "CABAGAYAN",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "DADONG",
            },
            {
              name: "JOVELLAR",
            },
            {
              name: "LIMOT",
            },
            {
              name: "LUCATAN",
            },
            {
              name: "MAGANDA",
            },
            {
              name: "OMPAO",
            },
            {
              name: "TARRAGONA",
            },
            {
              name: "TOMOAONG",
            },
            {
              name: "TUBAON",
            },
          ],
        },
      },
    },
    "DINAGAT ISLANDS": {
      name: "DINAGAT ISLANDS",
      region: "REGION 13",
      municipalities: {
        "BASILISA (RIZAL)": {
          name: "BASILISA (RIZAL)",
          baranggays: [
            {
              name: "BENGLEN",
            },
            {
              name: "CATADMAN",
            },
            {
              name: "COLUMBUS",
            },
            {
              name: "CORING",
            },
            {
              name: "CORTES",
            },
            {
              name: "DIEGAS",
            },
            {
              name: "DOÑA HELENE",
            },
            {
              name: "EDERA",
            },
            {
              name: "FERDINAND",
            },
            {
              name: "GEOTINA",
            },
            {
              name: "IMEE (BACTASAN)",
            },
            {
              name: "MELGAR",
            },
            {
              name: "MONTAG",
            },
            {
              name: "NAVARRO",
            },
            {
              name: "NEW NAZARETH",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUERTO PRINCESA",
            },
            {
              name: "RITA GLENDA",
            },
            {
              name: "ROMA",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SERING",
            },
            {
              name: "SOMBRADO",
            },
            {
              name: "TAG-ABACA",
            },
            {
              name: "VILLA ECLEO",
            },
            {
              name: "VILLA PANTINOPLE",
            },
          ],
        },
        CAGDIANAO: {
          name: "CAGDIANAO",
          baranggays: [
            {
              name: "BOA",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "LAGUNA",
            },
            {
              name: "LEGASPI",
            },
            {
              name: "MA-ATAS",
            },
            {
              name: "MABINI (BORJA)",
            },
            {
              name: "NUEVA ESTRELLA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "R. ECLEO, SR.",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "VALENCIA",
            },
          ],
        },
        DINAGAT: {
          name: "DINAGAT",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "CAB-ILAN",
            },
            {
              name: "CABAYAWAN",
            },
            {
              name: "CAYETANO",
            },
            {
              name: "ESCOLTA (POB.)",
            },
            {
              name: "GOMEZ",
            },
            {
              name: "JUSTINIANA EDERA",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAUSWAGON (POB.)",
            },
            {
              name: "NEW MABUHAY",
            },
            {
              name: "WADAS",
            },
            {
              name: "WHITE BEACH (POB.)",
            },
          ],
        },
        "LIBJO (ALBOR)": {
          name: "LIBJO (ALBOR)",
          baranggays: [
            {
              name: "ALBOR (POB.)",
            },
            {
              name: "ARELLANO",
            },
            {
              name: "BAYANIHAN",
            },
            {
              name: "DOÑA HELEN",
            },
            {
              name: "GARCIA",
            },
            {
              name: "GENERAL AGUINALDO (BOLOD-BOLOD)",
            },
            {
              name: "KANIHAAN",
            },
            {
              name: "LLAMERA",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "QUEZON",
            },
            {
              name: "ROSITA",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        LORETO: {
          name: "LORETO",
          baranggays: [
            {
              name: "CARMEN (POB.)",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "FERDINAND",
            },
            {
              name: "HELENE",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "PANAMAON",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTIAGO (POB.)",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "AURELIO",
            },
            {
              name: "CUARINTA",
            },
            {
              name: "DON RUBEN ECLEO (BALTAZAR)",
            },
            {
              name: "JACQUEZ",
            },
            {
              name: "JUSTINIANA EDERA",
            },
            {
              name: "LUNA",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MATINGBE",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "WILSON",
            },
          ],
        },
        TUBAJON: {
          name: "TUBAJON",
          baranggays: [
            {
              name: "DIAZ (ROMUALDEZ)",
            },
            {
              name: "IMELDA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALINAO",
            },
            {
              name: "NAVARRO",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
          ],
        },
      },
    },
    "EASTERN SAMAR": {
      name: "EASTERN SAMAR",
      region: "REGION 8",
      municipalities: {
        ARTECHE: {
          name: "ARTECHE",
          baranggays: [
            {
              name: "AGUINALDO",
            },
            {
              name: "ARTECHE",
            },
            {
              name: "BALUD (POB.)",
            },
            {
              name: "BATO (SAN LUIS)",
            },
            {
              name: "BERI",
            },
            {
              name: "BIGO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAGSALAY",
            },
            {
              name: "CAMPACION",
            },
            {
              name: "CARAPDAPAN",
            },
            {
              name: "CASIDMAN",
            },
            {
              name: "CATUMSAN",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "GARDEN (POB.)",
            },
            {
              name: "INAYAWAN",
            },
            {
              name: "MACARTHUR",
            },
            {
              name: "RAWIS (POB.)",
            },
            {
              name: "TANGBO",
            },
            {
              name: "TAWAGAN",
            },
            {
              name: "TEBALAWON",
            },
          ],
        },
        BALANGIGA: {
          name: "BALANGIGA",
          baranggays: [
            {
              name: "BACJAO",
            },
            {
              name: "BALANGIGA",
            },
            {
              name: "BARANGAY POBLACION I",
            },
            {
              name: "BARANGAY POBLACION II",
            },
            {
              name: "BARANGAY POBLACION III",
            },
            {
              name: "BARANGAY POBLACION IV",
            },
            {
              name: "BARANGAY POBLACION V",
            },
            {
              name: "BARANGAY POBLACION VI",
            },
            {
              name: "CAG-OLANGO",
            },
            {
              name: "CANSUMANGCAY",
            },
            {
              name: "GUINMAAYOHAN",
            },
            {
              name: "MAYBUNGA",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA ROSA",
            },
          ],
        },
        BALANGKAYAN: {
          name: "BALANGKAYAN",
          baranggays: [
            {
              name: "BALANGKAYAN",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BANGON",
            },
            {
              name: "CABAY",
            },
            {
              name: "CAISAWAN",
            },
            {
              name: "CANTUBI",
            },
            {
              name: "GENERAL MALVAR",
            },
            {
              name: "GUINPOLIRAN",
            },
            {
              name: "JULAG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARAMAG",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "POBLACION V",
            },
          ],
        },
        "BORONGAN CITY": {
          name: "BORONGAN CITY",
          baranggays: [
            {
              name: "ALANG-ALANG",
            },
            {
              name: "AMANTACOP",
            },
            {
              name: "ANDO",
            },
            {
              name: "BALACDAS",
            },
            {
              name: "BALUD",
            },
            {
              name: "BANUYO",
            },
            {
              name: "BARAS",
            },
            {
              name: "BATO",
            },
            {
              name: "BAYOBAY",
            },
            {
              name: "BENOWANGAN",
            },
            {
              name: "BORONGAN CITY",
            },
            {
              name: "BUGAS",
            },
            {
              name: "CABALAGNAN",
            },
            {
              name: "CABONG",
            },
            {
              name: "CAGBONGA",
            },
            {
              name: "CALICO-AN",
            },
            {
              name: "CALINGATNGAN",
            },
            {
              name: "CAMADA",
            },
            {
              name: "CAMPESAO",
            },
            {
              name: "CAN-ABONG",
            },
            {
              name: "CAN-AGA",
            },
            {
              name: "CANJAWAY",
            },
            {
              name: "CANLARAY",
            },
            {
              name: "CANYOPAY",
            },
            {
              name: "DIVINUBO",
            },
            {
              name: "HEBACONG",
            },
            {
              name: "HINDANG",
            },
            {
              name: "LALAWIGAN",
            },
            {
              name: "LIBUTON",
            },
            {
              name: "LOCSO-ON",
            },
            {
              name: "MAYBACONG",
            },
            {
              name: "MAYPANGDAN",
            },
            {
              name: "PEPELITAN",
            },
            {
              name: "PINANAG-AN",
            },
            {
              name: "PUNTA MARIA",
            },
            {
              name: "PUROK A (POB.)",
            },
            {
              name: "PUROK B (POB.)",
            },
            {
              name: "PUROK C (POB.)",
            },
            {
              name: "PUROK D1 (POB.)",
            },
            {
              name: "PUROK D2 (POB.)",
            },
            {
              name: "PUROK E (POB.)",
            },
            {
              name: "PUROK F (POB.)",
            },
            {
              name: "PUROK G (POB.)",
            },
            {
              name: "PUROK H (POB.)",
            },
            {
              name: "SABANG NORTH",
            },
            {
              name: "SABANG SOUTH",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN SATURNINO",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SIHA",
            },
            {
              name: "SOHUTAN",
            },
            {
              name: "SONGCO",
            },
            {
              name: "SURIBAO",
            },
            {
              name: "SUROK",
            },
            {
              name: "TABOC",
            },
            {
              name: "TABUNAN",
            },
            {
              name: "TAMOSO",
            },
          ],
        },
        "CAN-AVID": {
          name: "CAN-AVID",
          baranggays: [
            {
              name: "BALAGON",
            },
            {
              name: "BARANGAY 1 POBLACION",
            },
            {
              name: "BARANGAY 10 POBLACION",
            },
            {
              name: "BARANGAY 2 POBLACION",
            },
            {
              name: "BARANGAY 3 POBLACION",
            },
            {
              name: "BARANGAY 4 POBLACION",
            },
            {
              name: "BARANGAY 5 POBLACION",
            },
            {
              name: "BARANGAY 6 POBLACION",
            },
            {
              name: "BARANGAY 7 POBLACION",
            },
            {
              name: "BARANGAY 8 POBLACION",
            },
            {
              name: "BARANGAY 9 POBLACION",
            },
            {
              name: "BARUK",
            },
            {
              name: "BOCO",
            },
            {
              name: "CAGHALONG",
            },
            {
              name: "CAMANTANG",
            },
            {
              name: "CAN-AVID",
            },
            {
              name: "CAN-ILAY",
            },
            {
              name: "CANSANGAYA",
            },
            {
              name: "CANTEROS",
            },
            {
              name: "CAROLINA",
            },
            {
              name: "GUIBUANGAN",
            },
            {
              name: "JEPACO",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALOGO",
            },
            {
              name: "OBONG",
            },
            {
              name: "PANDOL",
            },
            {
              name: "RAWIS",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SOLONG",
            },
          ],
        },
        DOLORES: {
          name: "DOLORES",
          baranggays: [
            {
              name: "AROGANGA",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 14 (POB.)",
            },
            {
              name: "BARANGAY 15 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BONGHON",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABAGO-AN",
            },
            {
              name: "CAGLAO-AN",
            },
            {
              name: "CAGTABON",
            },
            {
              name: "DAMPIGAN",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DENIGPIAN",
            },
            {
              name: "DOLORES",
            },
            {
              name: "GAP-ANG",
            },
            {
              name: "HILABAAN",
            },
            {
              name: "HINOLASO",
            },
            {
              name: "JAPITAN",
            },
            {
              name: "JICONTOL",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAGONGBONG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALAINTOS",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ISIDRO (MALABAG)",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TANAUAN",
            },
            {
              name: "TIKLING",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        "GENERAL MACARTHUR": {
          name: "GENERAL MACARTHUR",
          baranggays: [
            {
              name: "AGUINALDO",
            },
            {
              name: "ALANG-ALANG",
            },
            {
              name: "BINALAY",
            },
            {
              name: "CALUTAN",
            },
            {
              name: "CAMCUEVAS",
            },
            {
              name: "DOMROG",
            },
            {
              name: "GENERAL MACARTHUR",
            },
            {
              name: "LAUREL",
            },
            {
              name: "LIMBUJAN",
            },
            {
              name: "MACAPAGAL",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PINGAN",
            },
            {
              name: "POBLACION BARANGAY 1",
            },
            {
              name: "POBLACION BARANGAY 2",
            },
            {
              name: "POBLACION BARANGAY 3",
            },
            {
              name: "POBLACION BARANGAY 4",
            },
            {
              name: "POBLACION BARANGAY 5",
            },
            {
              name: "POBLACION BARANGAY 6",
            },
            {
              name: "POBLACION BARANGAY 7",
            },
            {
              name: "POBLACION BARANGAY 8",
            },
            {
              name: "QUEZON",
            },
            {
              name: "QUIRINO",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ (OPONG)",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "TANDANG SORA",
            },
            {
              name: "TUGOP",
            },
            {
              name: "VIGAN",
            },
          ],
        },
        GIPORLOS: {
          name: "GIPORLOS",
          baranggays: [
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BIGA",
            },
            {
              name: "COTICOT",
            },
            {
              name: "GIGOSO",
            },
            {
              name: "GIPORLOS",
            },
            {
              name: "HUKNAN",
            },
            {
              name: "PARINA",
            },
            {
              name: "PAYA",
            },
            {
              name: "PRESIDENT ROXAS",
            },
            {
              name: "SAN ISIDRO (MALABAG)",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA CRUZ (CANSINGKOL)",
            },
          ],
        },
        GUIUAN: {
          name: "GUIUAN",
          baranggays: [
            {
              name: "ALINGAROG",
            },
            {
              name: "BAGUA",
            },
            {
              name: "BANAAG",
            },
            {
              name: "BANAHAO",
            },
            {
              name: "BARAS",
            },
            {
              name: "BARBO",
            },
            {
              name: "BITAUGAN",
            },
            {
              name: "BUCAO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUNGTOD",
            },
            {
              name: "CAGDARA-O",
            },
            {
              name: "CAGUSU-AN",
            },
            {
              name: "CAMPARANG",
            },
            {
              name: "CAMPOYONG",
            },
            {
              name: "CANAWAYON",
            },
            {
              name: "CANTAHAY",
            },
            {
              name: "CASUGURAN",
            },
            {
              name: "COGON",
            },
            {
              name: "CULASI",
            },
            {
              name: "DALARAGAN",
            },
            {
              name: "GAHOY",
            },
            {
              name: "GUIUAN",
            },
            {
              name: "HABAG",
            },
            {
              name: "HAGNA",
            },
            {
              name: "HAMORAWON",
            },
            {
              name: "HOLLYWOOD",
            },
            {
              name: "INAPULANGAN",
            },
            {
              name: "LUPOK (POB.)",
            },
            {
              name: "MAYANA",
            },
            {
              name: "NGOLOS",
            },
            {
              name: "PAGBABANGNAN",
            },
            {
              name: "PAGNAMITAN",
            },
            {
              name: "POBLACION WARD 1",
            },
            {
              name: "POBLACION WARD 10",
            },
            {
              name: "POBLACION WARD 11",
            },
            {
              name: "POBLACION WARD 12",
            },
            {
              name: "POBLACION WARD 2",
            },
            {
              name: "POBLACION WARD 3",
            },
            {
              name: "POBLACION WARD 4",
            },
            {
              name: "POBLACION WARD 4-A",
            },
            {
              name: "POBLACION WARD 5",
            },
            {
              name: "POBLACION WARD 6",
            },
            {
              name: "POBLACION WARD 7",
            },
            {
              name: "POBLACION WARD 8",
            },
            {
              name: "POBLACION WARD 9",
            },
            {
              name: "POBLACION WARD 9-A",
            },
            {
              name: "SALUG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAPAO",
            },
            {
              name: "SULANGAN",
            },
            {
              name: "SULUAN",
            },
            {
              name: "SUROK",
            },
            {
              name: "TAGPORO",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TIMALA",
            },
            {
              name: "TRINIDAD",
            },
            {
              name: "VICTORY ISLAND",
            },
          ],
        },
        HERNANI: {
          name: "HERNANI",
          baranggays: [
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BATANG",
            },
            {
              name: "CACATMONAN",
            },
            {
              name: "CANCILEDES",
            },
            {
              name: "CARMEN",
            },
            {
              name: "GARAWON",
            },
            {
              name: "HERNANI",
            },
            {
              name: "NAGAJA",
            },
            {
              name: "PADANG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
          ],
        },
        JIPAPAD: {
          name: "JIPAPAD",
          baranggays: [
            {
              name: "AGSAMAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "CAGMANABA",
            },
            {
              name: "DORILLO",
            },
            {
              name: "JEWARAN",
            },
            {
              name: "JIPAPAD",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "RECARE",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN ROQUE",
            },
          ],
        },
        LAWAAN: {
          name: "LAWAAN",
          baranggays: [
            {
              name: "BARANGAY POBLACION 1",
            },
            {
              name: "BARANGAY POBLACION 10",
            },
            {
              name: "BARANGAY POBLACION 2",
            },
            {
              name: "BARANGAY POBLACION 3",
            },
            {
              name: "BARANGAY POBLACION 4",
            },
            {
              name: "BARANGAY POBLACION 5",
            },
            {
              name: "BARANGAY POBLACION 6",
            },
            {
              name: "BARANGAY POBLACION 7",
            },
            {
              name: "BARANGAY POBLACION 8",
            },
            {
              name: "BARANGAY POBLACION 9",
            },
            {
              name: "BETAOG",
            },
            {
              name: "BOLUSAO",
            },
            {
              name: "GUINOB-AN",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "MASLOG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "TAGUITE",
            },
          ],
        },
        LLORENTE: {
          name: "LLORENTE",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BABANIKHON",
            },
            {
              name: "BACAYAWAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BAROBO",
            },
            {
              name: "BURAK",
            },
            {
              name: "CAN-ATO",
            },
            {
              name: "CANDOROS",
            },
            {
              name: "CANLIWAG",
            },
            {
              name: "CANTOMCO",
            },
            {
              name: "HUGPA",
            },
            {
              name: "LLORENTE",
            },
            {
              name: "MACA-ANGA",
            },
            {
              name: "MAGTINO",
            },
            {
              name: "MINA-ANOD",
            },
            {
              name: "NAUBAY",
            },
            {
              name: "PILIW",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SO-ONG",
            },
            {
              name: "TABOK",
            },
            {
              name: "WASO",
            },
          ],
        },
        MASLOG: {
          name: "MASLOG",
          baranggays: [
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CARAYACAY",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MAPUTI",
            },
            {
              name: "MASLOG",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TANGBO",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TUGAS",
            },
          ],
        },
        MAYDOLONG: {
          name: "MAYDOLONG",
          baranggays: [
            {
              name: "BARANGAY POBLACION 1",
            },
            {
              name: "BARANGAY POBLACION 2",
            },
            {
              name: "BARANGAY POBLACION 3",
            },
            {
              name: "BARANGAY POBLACION 4",
            },
            {
              name: "BARANGAY POBLACION 5",
            },
            {
              name: "BARANGAY POBLACION 6",
            },
            {
              name: "BARANGAY POBLACION 7",
            },
            {
              name: "CAMADA",
            },
            {
              name: "CAMPAKERIT (BOTAY)",
            },
            {
              name: "CANLOTERIO",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GUINDALITAN",
            },
            {
              name: "LAPGAP",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MAYBOCOG",
            },
            {
              name: "MAYDOLONG",
            },
            {
              name: "MAYTIGBAO",
            },
            {
              name: "OMAWAS",
            },
            {
              name: "PATAG",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "TAGASLIAN",
            },
          ],
        },
        MERCEDES: {
          name: "MERCEDES",
          baranggays: [
            {
              name: "ANURON",
            },
            {
              name: "BANUYO",
            },
            {
              name: "BARANGAY 1 POBLACION",
            },
            {
              name: "BARANGAY 2 POBLACION",
            },
            {
              name: "BARANGAY 3 POBLACION",
            },
            {
              name: "BARANGAY 4 POBLACION",
            },
            {
              name: "BOBON",
            },
            {
              name: "BUSAY",
            },
            {
              name: "BUYAYAWON",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CAMBANTE",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "PALAMRAG (CABILIRI-AN)",
            },
            {
              name: "PORT KENNEDY",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SUNG-AN",
            },
          ],
        },
        ORAS: {
          name: "ORAS",
          baranggays: [
            {
              name: "AGSAM",
            },
            {
              name: "ALANG-ALANG",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BALINGASAG",
            },
            {
              name: "BALOCAWE (POB.)",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BATANG",
            },
            {
              name: "BATO",
            },
            {
              name: "BINALAYAN",
            },
            {
              name: "BUNTAY",
            },
            {
              name: "BURAK",
            },
            {
              name: "BUTNGA (POB.)",
            },
            {
              name: "CADIAN",
            },
            {
              name: "CAGDINE",
            },
            {
              name: "CAGPILE",
            },
            {
              name: "CAGTOOG",
            },
            {
              name: "CAMANGA (POB.)",
            },
            {
              name: "DALID",
            },
            {
              name: "DAO",
            },
            {
              name: "FACTORIA",
            },
            {
              name: "GAMOT",
            },
            {
              name: "IWAYAN",
            },
            {
              name: "JAPAY",
            },
            {
              name: "KALAW",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALINGON",
            },
            {
              name: "MINAP-OS",
            },
            {
              name: "NADACPAN",
            },
            {
              name: "NAGA",
            },
            {
              name: "ORAS",
            },
            {
              name: "PANGUDTAN",
            },
            {
              name: "PAYPAYON (POB.)",
            },
            {
              name: "RIVERSIDE (POB.)",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN EDUARDO",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SAUGAN",
            },
            {
              name: "SAURONG",
            },
            {
              name: "TAWAGAN (POB.)",
            },
            {
              name: "TIGUIB (POB.)",
            },
            {
              name: "TRINIDAD (MAYCOROT)",
            },
          ],
        },
        QUINAPONDAN: {
          name: "QUINAPONDAN",
          baranggays: [
            {
              name: "ALANG-ALANG",
            },
            {
              name: "ANISLAG",
            },
            {
              name: "BAGTE",
            },
            {
              name: "BARANGAY NO. 1 (POB.)",
            },
            {
              name: "BARANGAY NO. 2 (POB.)",
            },
            {
              name: "BARANGAY NO. 3 (POB.)",
            },
            {
              name: "BARANGAY NO. 4 (POB.)",
            },
            {
              name: "BARANGAY NO. 5 (POB.)",
            },
            {
              name: "BARANGAY NO. 6 (POB.)",
            },
            {
              name: "BARANGAY NO. 7 (POB.)",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CACULANGAN",
            },
            {
              name: "CAGDAJA",
            },
            {
              name: "CAMBILLA",
            },
            {
              name: "CANTENIO",
            },
            {
              name: "NAGA",
            },
            {
              name: "PACO",
            },
            {
              name: "PALACTAD (VALLEY)",
            },
            {
              name: "QUINAPONDAN",
            },
            {
              name: "RIZAL (PANA-UGAN)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (LORO DIYO)",
            },
            {
              name: "SANTA MARGARITA",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        SALCEDO: {
          name: "SALCEDO",
          baranggays: [
            {
              name: "ABEJAO",
            },
            {
              name: "ALOG",
            },
            {
              name: "ASGAD",
            },
            {
              name: "BAGTONG",
            },
            {
              name: "BALUD",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BUABUA",
            },
            {
              name: "BURAK",
            },
            {
              name: "BUTIG",
            },
            {
              name: "CAGAUT",
            },
            {
              name: "CAMANGA",
            },
            {
              name: "CANTOMOJA",
            },
            {
              name: "CARAPDAPAN",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "CASILI-ON",
            },
            {
              name: "IBERAN",
            },
            {
              name: "JAGNAYA",
            },
            {
              name: "LUSOD",
            },
            {
              name: "MALBOG",
            },
            {
              name: "MALIWALIW",
            },
            {
              name: "MATARINAO",
            },
            {
              name: "NAPARAAN",
            },
            {
              name: "PALANAS",
            },
            {
              name: "SALCEDO",
            },
            {
              name: "SAN ROQUE (BUGAY)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SEGUINON",
            },
            {
              name: "TACLA-ON",
            },
            {
              name: "TAGBACAN",
            },
            {
              name: "TALANGDAWAN",
            },
          ],
        },
        "SAN JULIAN": {
          name: "SAN JULIAN",
          baranggays: [
            {
              name: "BARANGAY NO. 1 POBLACION",
            },
            {
              name: "BARANGAY NO. 2 POBLACION",
            },
            {
              name: "BARANGAY NO. 3 POBLACION",
            },
            {
              name: "BARANGAY NO. 4 POBLACION",
            },
            {
              name: "BARANGAY NO. 5 POBLACION",
            },
            {
              name: "BARANGAY NO. 6 POBLACION",
            },
            {
              name: "BUNACAN",
            },
            {
              name: "CAMPIDHAN",
            },
            {
              name: "CASOROY",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LUNANG",
            },
            {
              name: "NENA (LUNA)",
            },
            {
              name: "PAGBABANGNAN",
            },
            {
              name: "PUTONG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SAN MIGUEL",
            },
          ],
        },
        "SAN POLICARPO": {
          name: "SAN POLICARPO",
          baranggays: [
            {
              name: "ALUGAN",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BANGON",
            },
            {
              name: "BARANGAY NO. 1 (POB.)",
            },
            {
              name: "BARANGAY NO. 2 (POB.)",
            },
            {
              name: "BARANGAY NO. 3 (POB.)",
            },
            {
              name: "BARANGAY NO. 4 (POB.)",
            },
            {
              name: "BARANGAY NO. 5 (POB.)",
            },
            {
              name: "BARAS (LIPATA)",
            },
            {
              name: "BINOGAWAN",
            },
            {
              name: "CAJAGWAYAN",
            },
            {
              name: "JAPUNAN",
            },
            {
              name: "NATIVIDAD",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "SAN POLICARPO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TABO",
            },
            {
              name: "TAN-AWAN",
            },
          ],
        },
        SULAT: {
          name: "SULAT",
          baranggays: [
            {
              name: "A-ET",
            },
            {
              name: "ABUCAY (POB.)",
            },
            {
              name: "BAYBAY (POB.)",
            },
            {
              name: "DEL REMEDIO",
            },
            {
              name: "KANDALAKIT",
            },
            {
              name: "LOYOLA HEIGHTS (POB.)",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGLIPAY (POB.)",
            },
            {
              name: "MARAMARA (POB.)",
            },
            {
              name: "RIVERSIDE (POB.)",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SULAT",
            },
            {
              name: "TABI (POB.)",
            },
          ],
        },
        TAFT: {
          name: "TAFT",
          baranggays: [
            {
              name: "BATIAWAN",
            },
            {
              name: "BETO",
            },
            {
              name: "BINALOAN",
            },
            {
              name: "BONGDO",
            },
            {
              name: "DACUL",
            },
            {
              name: "DANAO",
            },
            {
              name: "DEL REMEDIOS",
            },
            {
              name: "GAYAM",
            },
            {
              name: "LOMATUD (BURAK)",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MANTANG",
            },
            {
              name: "NATO",
            },
            {
              name: "PANGABUTAN",
            },
            {
              name: "POBLACION BARANGAY 1",
            },
            {
              name: "POBLACION BARANGAY 2",
            },
            {
              name: "POBLACION BARANGAY 3",
            },
            {
              name: "POBLACION BARANGAY 4",
            },
            {
              name: "POBLACION BARANGAY 5",
            },
            {
              name: "POBLACION BARANGAY 6",
            },
            {
              name: "POLANGI",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "TAFT",
            },
          ],
        },
      },
    },
    GUIMARAS: {
      name: "GUIMARAS",
      region: "REGION 6",
      municipalities: {
        BUENAVISTA: {
          name: "BUENAVISTA",
          baranggays: [
            {
              name: "AGSANAYAN",
            },
            {
              name: "AVILA",
            },
            {
              name: "BACJAO (CALUMINGAN)",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CANSILAYAN",
            },
            {
              name: "DAGSA-AN",
            },
            {
              name: "DARAGAN",
            },
            {
              name: "EAST VALENCIA",
            },
            {
              name: "GETULIO",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MCLAIN",
            },
            {
              name: "MONTPILLER",
            },
            {
              name: "NAVALAS",
            },
            {
              name: "NAZARET",
            },
            {
              name: "NEW POBLACION (CALINGAO)",
            },
            {
              name: "OLD POBLACION",
            },
            {
              name: "PIÑA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SAWANG",
            },
            {
              name: "SUPANG",
            },
            {
              name: "TACAY",
            },
            {
              name: "TAMINLA",
            },
            {
              name: "TANAG",
            },
            {
              name: "TASTASAN",
            },
            {
              name: "TINADTARAN",
            },
            {
              name: "UMILIG",
            },
            {
              name: "ZALDIVAR",
            },
          ],
        },
        JORDAN: {
          name: "JORDAN",
          baranggays: [
            {
              name: "ALAGUISOC",
            },
            {
              name: "BALCON MARAVILLA",
            },
            {
              name: "BALCON MELLIZA",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "BULUANGAN",
            },
            {
              name: "ESPINOSA",
            },
            {
              name: "HOSKYN",
            },
            {
              name: "JORDAN",
            },
            {
              name: "LAWI",
            },
            {
              name: "MOROBUAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA TERESA",
            },
            {
              name: "SINAPSAPAN",
            },
          ],
        },
        "NUEVA VALENCIA": {
          name: "NUEVA VALENCIA",
          baranggays: [
            {
              name: "CABALAGNAN",
            },
            {
              name: "CALAYA",
            },
            {
              name: "CANHAWAN",
            },
            {
              name: "CONCORDIA SUR",
            },
            {
              name: "DOLORES",
            },
            {
              name: "GUIWANON",
            },
            {
              name: "IGANG",
            },
            {
              name: "IGDARAPDAP",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LANIPE",
            },
            {
              name: "LUCMAYAN",
            },
            {
              name: "MAGAMAY",
            },
            {
              name: "NAPANDONG",
            },
            {
              name: "NUEVA VALENCIA",
            },
            {
              name: "ORACON SUR",
            },
            {
              name: "PANDARAONAN",
            },
            {
              name: "PANOBOLON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "TANDO",
            },
          ],
        },
        "SAN LORENZO": {
          name: "SAN LORENZO",
          baranggays: [
            {
              name: "AGUILAR",
            },
            {
              name: "CABANO",
            },
            {
              name: "CABUNGAHAN",
            },
            {
              name: "CONSTANCIA",
            },
            {
              name: "GABAN",
            },
            {
              name: "IGCAWAYAN",
            },
            {
              name: "M. CHAVEZ",
            },
            {
              name: "SAN ENRIQUE (LEBAS)",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAPAL",
            },
            {
              name: "SEBARIO",
            },
            {
              name: "SUCLARAN",
            },
            {
              name: "TAMBORONG",
            },
          ],
        },
        SIBUNAG: {
          name: "SIBUNAG",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "AYANGAN",
            },
            {
              name: "BUBOG",
            },
            {
              name: "CONCORDIA",
            },
            {
              name: "DASAL",
            },
            {
              name: "INAMPOLOGAN",
            },
            {
              name: "MAABAY",
            },
            {
              name: "MILLAN",
            },
            {
              name: "ORACON",
            },
            {
              name: "RAVINA",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SEBASTE",
            },
            {
              name: "SIBUNAG",
            },
            {
              name: "TANGLAD",
            },
          ],
        },
      },
    },
    IFUGAO: {
      name: "IFUGAO",
      region: "CAR",
      municipalities: {
        AGUINALDO: {
          name: "AGUINALDO",
          baranggays: [
            {
              name: "AGUINALDO",
            },
            {
              name: "AWAYAN",
            },
            {
              name: "BUNHIAN",
            },
            {
              name: "BUTAC",
            },
            {
              name: "BUWAG",
            },
            {
              name: "CHALALO",
            },
            {
              name: "DAMAG",
            },
            {
              name: "GALONOGON",
            },
            {
              name: "HALAG",
            },
            {
              name: "ITAB",
            },
            {
              name: "JACMAL",
            },
            {
              name: "MAJLONG",
            },
            {
              name: "MONGAYANG",
            },
            {
              name: "POSNAAN",
            },
            {
              name: "TA-ANG",
            },
            {
              name: "TALITE",
            },
            {
              name: "UBAO",
            },
          ],
        },
        "ALFONSO LISTA (POTIA)": {
          name: "ALFONSO LISTA (POTIA)",
          baranggays: [
            {
              name: "ALFONSO LISTA (POTIA)",
            },
            {
              name: "BANGAR",
            },
            {
              name: "BUSILAC",
            },
            {
              name: "CALIMAG",
            },
            {
              name: "CALUPAAN",
            },
            {
              name: "CARAGASAN",
            },
            {
              name: "DOLOWOG",
            },
            {
              name: "KILING",
            },
            {
              name: "LAYA",
            },
            {
              name: "LITTLE TADIAN",
            },
            {
              name: "NAMILLANGAN",
            },
            {
              name: "NAMNAMA",
            },
            {
              name: "NGILEB",
            },
            {
              name: "PINTO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SAN QUINTIN",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO DOMINGO (CABICALAN)",
            },
          ],
        },
        ASIPULO: {
          name: "ASIPULO",
          baranggays: [
            {
              name: "AMDUNTOG",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "ASIPULO",
            },
            {
              name: "CAMANDAG",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "HALLAP",
            },
            {
              name: "NAMAL",
            },
            {
              name: "NUNGAWA",
            },
            {
              name: "PANUBTUBAN",
            },
            {
              name: "PULA",
            },
          ],
        },
        BANAUE: {
          name: "BANAUE",
          baranggays: [
            {
              name: "AMGANAD",
            },
            {
              name: "ANABA",
            },
            {
              name: "BALAWIS",
            },
            {
              name: "BANAO",
            },
            {
              name: "BANAUE",
            },
            {
              name: "BANGAAN",
            },
            {
              name: "BATAD",
            },
            {
              name: "BOCOS",
            },
            {
              name: "CAMBULO",
            },
            {
              name: "DUCLIGAN",
            },
            {
              name: "GOHANG",
            },
            {
              name: "KINAKIN",
            },
            {
              name: "OHAJ",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POITAN",
            },
            {
              name: "PULA",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "TAM-AN",
            },
            {
              name: "VIEW POINT",
            },
          ],
        },
        HINGYON: {
          name: "HINGYON",
          baranggays: [
            {
              name: "ANAO",
            },
            {
              name: "BANGTINON",
            },
            {
              name: "BITU",
            },
            {
              name: "CABABUYAN",
            },
            {
              name: "HINGYON",
            },
            {
              name: "MOMPOLIA",
            },
            {
              name: "NAMULDITAN",
            },
            {
              name: "NORTHERN CABABUYAN",
            },
            {
              name: "O-ONG",
            },
            {
              name: "PIWONG",
            },
            {
              name: "POBLACION (HINGYON)",
            },
            {
              name: "UBUAG",
            },
            {
              name: "UMALBONG",
            },
          ],
        },
        HUNGDUAN: {
          name: "HUNGDUAN",
          baranggays: [
            {
              name: "ABATAN",
            },
            {
              name: "BA-ANG",
            },
            {
              name: "BANGBANG",
            },
            {
              name: "BOKIAWAN",
            },
            {
              name: "HAPAO",
            },
            {
              name: "HUNGDUAN",
            },
            {
              name: "LUBO-ONG",
            },
            {
              name: "MAGGOK",
            },
            {
              name: "NUNGULUNAN",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        KIANGAN: {
          name: "KIANGAN",
          baranggays: [
            {
              name: "AMBABAG",
            },
            {
              name: "BAGUINGE",
            },
            {
              name: "BOKIAWAN",
            },
            {
              name: "BOLOG",
            },
            {
              name: "DALLIGAN",
            },
            {
              name: "DUIT",
            },
            {
              name: "HUCAB",
            },
            {
              name: "JULONGAN",
            },
            {
              name: "KIANGAN",
            },
            {
              name: "LINGAY",
            },
            {
              name: "MUNGAYANG",
            },
            {
              name: "NAGACADAN",
            },
            {
              name: "PINDONGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TUPLAC",
            },
          ],
        },
        LAGAWE: {
          name: "LAGAWE",
          baranggays: [
            {
              name: "ABINUAN",
            },
            {
              name: "BANGA",
            },
            {
              name: "BOLIWONG",
            },
            {
              name: "BURNAY",
            },
            {
              name: "BUYABUYAN",
            },
            {
              name: "CABA",
            },
            {
              name: "CUDOG",
            },
            {
              name: "DULAO",
            },
            {
              name: "JUCBONG",
            },
            {
              name: "LAGAWE",
            },
            {
              name: "LUTA",
            },
            {
              name: "MONTABIONG",
            },
            {
              name: "OLILICON",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PONGHAL",
            },
            {
              name: "PULLAAN",
            },
            {
              name: "TUNGNGOD",
            },
            {
              name: "TUPAYA",
            },
          ],
        },
        LAMUT: {
          name: "LAMUT",
          baranggays: [
            {
              name: "AMBASA",
            },
            {
              name: "BIMPAL",
            },
            {
              name: "HAPID",
            },
            {
              name: "HOLOWON",
            },
            {
              name: "LAMUT",
            },
            {
              name: "LAWIG",
            },
            {
              name: "LUCBAN",
            },
            {
              name: "MABATOBATO (LAMUT)",
            },
            {
              name: "MAGULON",
            },
            {
              name: "NAYON",
            },
            {
              name: "PANOPDOPAN",
            },
            {
              name: "PAYAWAN",
            },
            {
              name: "PIEZA",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PUGOL (IFUGAO RESERVATION)",
            },
            {
              name: "SALAMAGUE",
            },
            {
              name: "SANAFE",
            },
            {
              name: "UMILAG",
            },
          ],
        },
        MAYOYAO: {
          name: "MAYOYAO",
          baranggays: [
            {
              name: "ADUYONGAN",
            },
            {
              name: "ALIMIT",
            },
            {
              name: "AYANGAN",
            },
            {
              name: "BALANGBANG",
            },
            {
              name: "BANAO",
            },
            {
              name: "BANHAL",
            },
            {
              name: "BATO-ALATBANG",
            },
            {
              name: "BONGAN",
            },
            {
              name: "BUNINAN",
            },
            {
              name: "CHAYA",
            },
            {
              name: "CHUMANG",
            },
            {
              name: "EPENG",
            },
            {
              name: "GUINIHON",
            },
            {
              name: "INWALOY",
            },
            {
              name: "LANGAYAN",
            },
            {
              name: "LIWO",
            },
            {
              name: "MAGA",
            },
            {
              name: "MAGULON",
            },
            {
              name: "MAPAWOY",
            },
            {
              name: "MAYOYAO",
            },
            {
              name: "MAYOYAO PROPER",
            },
            {
              name: "MONGOL",
            },
            {
              name: "NALBU",
            },
            {
              name: "NATTUM",
            },
            {
              name: "PALAAD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TALBOC",
            },
            {
              name: "TULAED",
            },
          ],
        },
        TINOC: {
          name: "TINOC",
          baranggays: [
            {
              name: "AHIN",
            },
            {
              name: "AP-APID",
            },
            {
              name: "BINABLAYAN",
            },
            {
              name: "DANGGO",
            },
            {
              name: "EHEB",
            },
            {
              name: "GUMHANG",
            },
            {
              name: "IMPUGONG",
            },
            {
              name: "LUHONG",
            },
            {
              name: "TINOC",
            },
            {
              name: "TINOC",
            },
            {
              name: "TUKUCAN",
            },
            {
              name: "TULLUDAN",
            },
            {
              name: "WANGWANG",
            },
          ],
        },
      },
    },
    "ILOCOS NORTE": {
      name: "ILOCOS NORTE",
      region: "REGION 1",
      municipalities: {
        ADAMS: {
          name: "ADAMS",
          baranggays: [
            {
              name: "ADAMS (POB.)",
            },
          ],
        },
        BACARRA: {
          name: "BACARRA",
          baranggays: [
            {
              name: "BANI",
            },
            {
              name: "BUYON",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CABULALAAN",
            },
            {
              name: "CABUSLIGAN",
            },
            {
              name: "CADARATAN",
            },
            {
              name: "CALIOET-LIBONG",
            },
            {
              name: "CASILIAN",
            },
            {
              name: "COROCOR",
            },
            {
              name: "DURIPES",
            },
            {
              name: "GANAGAN",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "MACUPIT",
            },
            {
              name: "NAMBARAN",
            },
            {
              name: "NATBA",
            },
            {
              name: "PANINAAN",
            },
            {
              name: "PASIOCAN",
            },
            {
              name: "PASNGAL",
            },
            {
              name: "PIPIAS",
            },
            {
              name: "PULANGI",
            },
            {
              name: "PUNGTO",
            },
            {
              name: "SAN AGUSTIN I (POB.)",
            },
            {
              name: "SAN AGUSTIN II (POB.)",
            },
            {
              name: "SAN ANDRES I (POB.)",
            },
            {
              name: "SAN ANDRES II (POB.)",
            },
            {
              name: "SAN GABRIEL I (POB.)",
            },
            {
              name: "SAN GABRIEL II (POB.)",
            },
            {
              name: "SAN PEDRO I (POB.)",
            },
            {
              name: "SAN PEDRO II (POB.)",
            },
            {
              name: "SAN ROQUE I (POB.)",
            },
            {
              name: "SAN ROQUE II (POB.)",
            },
            {
              name: "SAN SIMON I (POB.)",
            },
            {
              name: "SAN SIMON II (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANGIL",
            },
            {
              name: "SANTA FILOMENA I (POB.)",
            },
            {
              name: "SANTA FILOMENA II (POB.)",
            },
            {
              name: "SANTA RITA (POB.)",
            },
            {
              name: "SANTO CRISTO I (POB.)",
            },
            {
              name: "SANTO CRISTO II (POB.)",
            },
            {
              name: "TAMBIDAO",
            },
            {
              name: "TEPPANG",
            },
            {
              name: "TUBBURAN",
            },
          ],
        },
        BADOC: {
          name: "BADOC",
          baranggays: [
            {
              name: "ALAY-NANGBABAAN (ALAY 15-B)",
            },
            {
              name: "ALOGOOG",
            },
            {
              name: "AR-ARUSIP",
            },
            {
              name: "ARING",
            },
            {
              name: "BALBALDEZ",
            },
            {
              name: "BATO",
            },
            {
              name: "CAMANGA",
            },
            {
              name: "CANAAN (POB.)",
            },
            {
              name: "CARAITAN",
            },
            {
              name: "GABUT NORTE",
            },
            {
              name: "GABUT SUR",
            },
            {
              name: "GARRETA (POB.)",
            },
            {
              name: "LA VIRGEN MILAGROSA (PAGUETPET)",
            },
            {
              name: "LABUT",
            },
            {
              name: "LACUBEN",
            },
            {
              name: "LUBIGAN",
            },
            {
              name: "MABUSAG NORTE",
            },
            {
              name: "MABUSAG SUR",
            },
            {
              name: "MADUPAYAS",
            },
            {
              name: "MORONG",
            },
            {
              name: "NAGREBCAN",
            },
            {
              name: "NAPU",
            },
            {
              name: "PAGSANAHAN NORTE",
            },
            {
              name: "PAGSANAHAN SUR",
            },
            {
              name: "PALTIT",
            },
            {
              name: "PARANG",
            },
            {
              name: "PASUC",
            },
            {
              name: "SANTA CRUZ NORTE",
            },
            {
              name: "SANTA CRUZ SUR",
            },
            {
              name: "SAUD",
            },
            {
              name: "TUROD",
            },
          ],
        },
        BANGUI: {
          name: "BANGUI",
          baranggays: [
            {
              name: "ABACA",
            },
            {
              name: "BACSIL",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BARUYEN",
            },
            {
              name: "DADAOR",
            },
            {
              name: "LANAO",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MANAYON",
            },
            {
              name: "MASIKIL",
            },
            {
              name: "NAGBALAGAN",
            },
            {
              name: "PAYAC",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN LORENZO (POB.)",
            },
            {
              name: "TAGUIPORO",
            },
            {
              name: "UTOL",
            },
          ],
        },
        "BANNA (ESPIRITU)": {
          name: "BANNA (ESPIRITU)",
          baranggays: [
            {
              name: "BALIOEG",
            },
            {
              name: "BANGSAR",
            },
            {
              name: "BARBARANGAY",
            },
            {
              name: "BINACAG",
            },
            {
              name: "BOMITOG",
            },
            {
              name: "BUGASI",
            },
            {
              name: "CAESTEBANAN",
            },
            {
              name: "CARIBQUIB",
            },
            {
              name: "CATAGTAGUEN",
            },
            {
              name: "CRISPINA",
            },
            {
              name: "HILARIO (POB.)",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LORENZO (POB.)",
            },
            {
              name: "MACAYEPYEP",
            },
            {
              name: "MARCOS (POB.)",
            },
            {
              name: "NAGPATAYAN",
            },
            {
              name: "SINAMAR",
            },
            {
              name: "TABTABAGAN",
            },
            {
              name: "VALDEZ",
            },
            {
              name: "VALENCIANO (POB.)",
            },
          ],
        },
        BATAC: {
          name: "BATAC",
          baranggays: [
            {
              name: "ABLAN POB. (LABUCAO)",
            },
            {
              name: "ACOSTA POB. (ILOILO)",
            },
            {
              name: "AGLIPAY (POB.)",
            },
            {
              name: "BAAY",
            },
            {
              name: "BALIGAT",
            },
            {
              name: "BAOA EAST",
            },
            {
              name: "BAOA WEST",
            },
            {
              name: "BARANI (POB.)",
            },
            {
              name: "BATAC",
            },
            {
              name: "BEN-AGAN (POB.)",
            },
            {
              name: "BIL-LOCA",
            },
            {
              name: "BININGAN",
            },
            {
              name: "BUNGON",
            },
            {
              name: "CALLAGUIP (POB.)",
            },
            {
              name: "CAMANDINGAN",
            },
            {
              name: "CAMGUIDAN",
            },
            {
              name: "CANGRUNAAN (POB.)",
            },
            {
              name: "CAPACUAN",
            },
            {
              name: "CAUNAYAN (POB.)",
            },
            {
              name: "COLO",
            },
            {
              name: "DARIWDIW",
            },
            {
              name: "LACUB (POB.)",
            },
            {
              name: "MABALENG",
            },
            {
              name: "MAGNUANG",
            },
            {
              name: "MAIPALIG",
            },
            {
              name: "NAGBACALAN",
            },
            {
              name: "NAGUIRANGAN",
            },
            {
              name: "PALONGPONG",
            },
            {
              name: "PALPALICONG (POB.)",
            },
            {
              name: "PARANGOPONG",
            },
            {
              name: "PAYAO",
            },
            {
              name: "PIMENTEL (CUBOL)",
            },
            {
              name: "QUILING NORTE",
            },
            {
              name: "QUILING SUR",
            },
            {
              name: "QUIOM",
            },
            {
              name: "RAYURAY",
            },
            {
              name: "RICARTE POB. (NALASIN)",
            },
            {
              name: "SAN JULIAN (POB.)",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SUABIT (POB.)",
            },
            {
              name: "SUMADER",
            },
            {
              name: "TABUG",
            },
            {
              name: "VALDEZ POB. (CAOAYAN)",
            },
          ],
        },
        BURGOS: {
          name: "BURGOS",
          baranggays: [
            {
              name: "ABLAN SARAT",
            },
            {
              name: "AGAGA",
            },
            {
              name: "BAYOG",
            },
            {
              name: "BOBON",
            },
            {
              name: "BUDUAN (MALITUEK)",
            },
            {
              name: "BURGOS",
            },
            {
              name: "NAGSUROT",
            },
            {
              name: "PAAYAS",
            },
            {
              name: "PAGALI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAOIT",
            },
            {
              name: "TANAP",
            },
          ],
        },
        CARASI: {
          name: "CARASI",
          baranggays: [
            {
              name: "ANGSET",
            },
            {
              name: "BARBAQUESO (POB.)",
            },
            {
              name: "CARASI",
            },
            {
              name: "VIRBIRA",
            },
          ],
        },
        CURRIMAO: {
          name: "CURRIMAO",
          baranggays: [
            {
              name: "ANGGAPANG NORTE",
            },
            {
              name: "ANGGAPANG SUR",
            },
            {
              name: "BIMMANGA",
            },
            {
              name: "CABUUSAN",
            },
            {
              name: "COMCOMLOONG",
            },
            {
              name: "CURRIMAO",
            },
            {
              name: "GAANG",
            },
            {
              name: "LANG-AYAN-BARAMBAN",
            },
            {
              name: "LIOES",
            },
            {
              name: "MAGLAOI CENTRO",
            },
            {
              name: "MAGLAOI NORTE",
            },
            {
              name: "MAGLAOI SUR",
            },
            {
              name: "PAGULUDAN-SALINDEG",
            },
            {
              name: "PANGIL",
            },
            {
              name: "PIAS NORTE",
            },
            {
              name: "PIAS SUR",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SALUGAN",
            },
            {
              name: "SAN SIMEON",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAPAO-TIGUE",
            },
            {
              name: "TORRE",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        DINGRAS: {
          name: "DINGRAS",
          baranggays: [
            {
              name: "ALBANO (POB.)",
            },
            {
              name: "BACSIL",
            },
            {
              name: "BAGUT",
            },
            {
              name: "BARESBES",
            },
            {
              name: "BARONG",
            },
            {
              name: "BUNGCAG",
            },
            {
              name: "CALI",
            },
            {
              name: "CAPASAN",
            },
            {
              name: "DANCEL (POB.)",
            },
            {
              name: "DINGRAS",
            },
            {
              name: "ELIZABETH",
            },
            {
              name: "ESPIRITU",
            },
            {
              name: "FOZ",
            },
            {
              name: "GUERRERO (POB.)",
            },
            {
              name: "LANAS",
            },
            {
              name: "LUMBAD",
            },
            {
              name: "MADAMBA (POB.)",
            },
            {
              name: "MANDALOQUE",
            },
            {
              name: "MEDINA",
            },
            {
              name: "PARADO (BANGAY)",
            },
            {
              name: "PERALTA (POB.)",
            },
            {
              name: "PURUGANAN (POB.)",
            },
            {
              name: "ROOT (BALDIAS)",
            },
            {
              name: "SAGPATAN",
            },
            {
              name: "SALUDARES",
            },
            {
              name: "SAN ESTEBAN",
            },
            {
              name: "SAN FRANCISCO (SURRATE)",
            },
            {
              name: "SAN MARCELINO (PADONG)",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SULQUIANO (SIDIRAN)",
            },
            {
              name: "SUYO",
            },
            {
              name: "VER (NAGLAYAAN)",
            },
          ],
        },
        DUMALNEG: {
          name: "DUMALNEG",
          baranggays: [
            {
              name: "DUMALNEG",
            },
            {
              name: "DUMALNEG",
            },
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        "LAOAG CITY": {
          name: "LAOAG CITY",
          baranggays: [
            {
              name: "BGY. NO. 1, SAN LORENZO (POB.)",
            },
            {
              name: "BGY. NO. 10, SAN JOSE (POB.)",
            },
            {
              name: "BGY. NO. 11, SANTA BALBINA (POB.)",
            },
            {
              name: "BGY. NO. 12, SAN ISIDRO (POB.)",
            },
            {
              name: "BGY. NO. 13, NSTRA. SRA. DE VISITACION (POB.)",
            },
            {
              name: "BGY. NO. 14, SANTO TOMAS (POB.)",
            },
            {
              name: "BGY. NO. 15, SAN GUILLERMO (POB.)",
            },
            {
              name: "BGY. NO. 16, SAN JACINTO (POB.)",
            },
            {
              name: "BGY. NO. 17, SAN FRANCISCO (POB.)",
            },
            {
              name: "BGY. NO. 19, SANTA MARCELA (POB.)",
            },
            {
              name: "BGY. NO. 2, SANTA JOAQUINA (POB.)",
            },
            {
              name: "BGY. NO. 20, SAN MIGUEL (POB.)",
            },
            {
              name: "BGY. NO. 21, SAN PEDRO (POB.)",
            },
            {
              name: "BGY. NO. 22, SAN ANDRES (POB.)",
            },
            {
              name: "BGY. NO. 23, SAN MATIAS (POB.)",
            },
            {
              name: "BGY. NO. 24, NSTRA. SRA. DE CONSOLACION (POB.)",
            },
            {
              name: "BGY. NO. 25, SANTA CAYETANA (POB.)",
            },
            {
              name: "BGY. NO. 26, SAN MARCELINO (POB.)",
            },
            {
              name: "BGY. NO. 27, NSTRA. SRA. DE SOLEDAD (POB.)",
            },
            {
              name: "BGY. NO. 28, SAN BERNARDO (POB.)",
            },
            {
              name: "BGY. NO. 29, SANTO TOMAS (POB.)",
            },
            {
              name: "BGY. NO. 3, NSTRA. SRA. DEL ROSARIO (POB.)",
            },
            {
              name: "BGY. NO. 30-A, SUYO",
            },
            {
              name: "BGY. NO. 30-B, SANTA MARIA",
            },
            {
              name: "BGY. NO. 31, TALINGAAN",
            },
            {
              name: "BGY. NO. 32-A, LA PAZ EAST",
            },
            {
              name: "BGY. NO. 32-B, LA PAZ WEST",
            },
            {
              name: "BGY. NO. 32-C LA PAZ EAST",
            },
            {
              name: "BGY. NO. 33-A, LA PAZ PROPER",
            },
            {
              name: "BGY. NO. 33-B, LA PAZ PROPER",
            },
            {
              name: "BGY. NO. 34-A, GABU NORTE WEST",
            },
            {
              name: "BGY. NO. 34-B, GABU NORTE EAST",
            },
            {
              name: "BGY. NO. 35, GABU SUR",
            },
            {
              name: "BGY. NO. 36, ARANIW",
            },
            {
              name: "BGY. NO. 37, CALAYAB",
            },
            {
              name: "BGY. NO. 38-A, MANGATO EAST",
            },
            {
              name: "BGY. NO. 38-B, MANGATO WEST",
            },
            {
              name: "BGY. NO. 39, SANTA ROSA",
            },
            {
              name: "BGY. NO. 4, SAN GUILLERMO (POB.)",
            },
            {
              name: "BGY. NO. 40, BALATONG",
            },
            {
              name: "BGY. NO. 41, BALACAD",
            },
            {
              name: "BGY. NO. 42, APAYA",
            },
            {
              name: "BGY. NO. 43, CAVIT",
            },
            {
              name: "BGY. NO. 44, ZAMBOANGA",
            },
            {
              name: "BGY. NO. 45, TANGID",
            },
            {
              name: "BGY. NO. 46, NALBO",
            },
            {
              name: "BGY. NO. 47, BENGCAG",
            },
            {
              name: "BGY. NO. 48-B, CABUNGAAN SOUTH",
            },
            {
              name: "BGY. NO. 49-A, DARAYDAY",
            },
            {
              name: "BGY. NO. 49-B, RARABURAN",
            },
            {
              name: "BGY. NO. 5, SAN PEDRO (POB.)",
            },
            {
              name: "BGY. NO. 50, BUTTONG",
            },
            {
              name: "BGY. NO. 51-A, NANGALISAN EAST",
            },
            {
              name: "BGY. NO. 51-B, NANGALISAN WEST",
            },
            {
              name: "BGY. NO. 52-A, SAN MATEO",
            },
            {
              name: "BGY. NO. 52-B, LATAAG",
            },
            {
              name: "BGY. NO. 53, RIOENG",
            },
            {
              name: "BGY. NO. 54-A, LAGUI-SAIL",
            },
            {
              name: "BGY. NO. 54-B, CAMANGAAN",
            },
            {
              name: "BGY. NO. 55-A, BARIT-PANDAN",
            },
            {
              name: "BGY. NO. 55-B, SALET-BULANGON",
            },
            {
              name: "BGY. NO. 55-C, VIRA",
            },
            {
              name: "BGY. NO. 56-A, BACSIL NORTH",
            },
            {
              name: "BGY. NO. 56-B, BACSIL SOUTH",
            },
            {
              name: "BGY. NO. 57, PILA",
            },
            {
              name: "BGY. NO. 58, CASILI",
            },
            {
              name: "BGY. NO. 59-A, DIBUA SOUTH",
            },
            {
              name: "BGY. NO. 59-B, DIBUA NORTH",
            },
            {
              name: "BGY. NO. 6, SAN AGUSTIN (POB.)",
            },
            {
              name: "BGY. NO. 60-A, CAAOACAN",
            },
            {
              name: "BGY. NO. 60-B, MADILADIG",
            },
            {
              name: "BGY. NO. 61, CATABAN",
            },
            {
              name: "BGY. NO. 62-A, NAVOTAS NORTH",
            },
            {
              name: "BGY. NO. 62-B, NAVOTAS SOUTH",
            },
            {
              name: "BGY. NO. 7-A, NSTRA. SRA. DE NATIVIDAD (POB.)",
            },
            {
              name: "BGY. NO. 7-B, NSTRA. SRA. DE NATIVIDAD (POB.)",
            },
            {
              name: "BGY. NO. 8, SAN VICENTE (POB.)",
            },
            {
              name: "BGY. NO. 9, SANTA ANGELA (POB.)",
            },
            {
              name: "BRY. NO. 18, SAN QUIRINO (POB.)",
            },
            {
              name: "BRY. NO. 48-A, CABUNGAAN NORTH",
            },
            {
              name: "LAOAG CITY",
            },
          ],
        },
        MARCOS: {
          name: "MARCOS",
          baranggays: [
            {
              name: "CACAFEAN",
            },
            {
              name: "DAQUIOAG",
            },
            {
              name: "ELIZABETH (CULAO)",
            },
            {
              name: "ESCODA",
            },
            {
              name: "FERDINAND",
            },
            {
              name: "FORTUNA",
            },
            {
              name: "IMELDA (CAPARIAAN)",
            },
            {
              name: "LYDIA (POB.)",
            },
            {
              name: "MABUTI",
            },
            {
              name: "MARCOS",
            },
            {
              name: "PACIFICO (AGUNIT)",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "TABUCBUC (RAGAS)",
            },
            {
              name: "VALDEZ (BIDING)",
            },
          ],
        },
        "NUEVA ERA": {
          name: "NUEVA ERA",
          baranggays: [
            {
              name: "ACNAM",
            },
            {
              name: "BARANGOBONG",
            },
            {
              name: "BARIKIR",
            },
            {
              name: "BUGAYONG",
            },
            {
              name: "CABITTAURAN",
            },
            {
              name: "CARAY",
            },
            {
              name: "GARNADEN",
            },
            {
              name: "NAGUILLAN (PAGPAG-ONG)",
            },
            {
              name: "NUEVA ERA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "UGUIS",
            },
          ],
        },
        PAGUDPUD: {
          name: "PAGUDPUD",
          baranggays: [
            {
              name: "AGGASI",
            },
            {
              name: "BADUANG",
            },
            {
              name: "BALAOI",
            },
            {
              name: "BURAYOC",
            },
            {
              name: "CAPARISPISAN",
            },
            {
              name: "CAUNAYAN",
            },
            {
              name: "DAMPIG",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "PAGUDPUD",
            },
            {
              name: "PANCIAN",
            },
            {
              name: "PASALENG",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "SAGUIGUI",
            },
            {
              name: "SAUD",
            },
            {
              name: "SUBEC",
            },
            {
              name: "TARRAG",
            },
          ],
        },
        PAOAY: {
          name: "PAOAY",
          baranggays: [
            {
              name: "BACSIL",
            },
            {
              name: "CABAGOAN",
            },
            {
              name: "CABANGARAN",
            },
            {
              name: "CALLAGUIP",
            },
            {
              name: "CAYUBOG",
            },
            {
              name: "DOLORES",
            },
            {
              name: "LAOA",
            },
            {
              name: "MASINTOC",
            },
            {
              name: "MONTE",
            },
            {
              name: "MUMULAAN",
            },
            {
              name: "NAGBACALAN",
            },
            {
              name: "NALASIN",
            },
            {
              name: "NANGUYUDAN",
            },
            {
              name: "OAIG-UPAY-ABULAO",
            },
            {
              name: "PAMBARAN",
            },
            {
              name: "PANNARATAN (POB.)",
            },
            {
              name: "PAOAY",
            },
            {
              name: "PARATONG",
            },
            {
              name: "PASIL",
            },
            {
              name: "SALBANG (POB.)",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN BLAS (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANGLADAN POB. (NALBUAN)",
            },
            {
              name: "SANTA RITA (POB.)",
            },
            {
              name: "SIDEG",
            },
            {
              name: "SUBA",
            },
            {
              name: "SUNGADAN",
            },
            {
              name: "SURGUI",
            },
            {
              name: "VERONICA",
            },
          ],
        },
        PASUQUIN: {
          name: "PASUQUIN",
          baranggays: [
            {
              name: "BATULI",
            },
            {
              name: "BINSANG",
            },
            {
              name: "CARUAN",
            },
            {
              name: "CARUSIKIS",
            },
            {
              name: "CARUSIPAN",
            },
            {
              name: "DADAEMAN",
            },
            {
              name: "DARUPIDIP",
            },
            {
              name: "DAVILA",
            },
            {
              name: "DILANIS",
            },
            {
              name: "DILAVO",
            },
            {
              name: "ESTANCIA",
            },
            {
              name: "NAGLICUAN",
            },
            {
              name: "NAGSANGA",
            },
            {
              name: "NALVO (CABABAAN-NALVO)",
            },
            {
              name: "NGABANGAB",
            },
            {
              name: "PANGIL",
            },
            {
              name: "PASUQUIN",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "POBLACION 4",
            },
            {
              name: "PRAGATA (PRAGATA-BUNGRO)",
            },
            {
              name: "PUYUPUYAN",
            },
            {
              name: "SALPAD",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA MATILDE",
            },
            {
              name: "SAPAT",
            },
            {
              name: "SULBEC",
            },
            {
              name: "SULONGAN",
            },
            {
              name: "SURONG",
            },
            {
              name: "SUSUGAEN",
            },
            {
              name: "TABUNGAO",
            },
            {
              name: "TADAO",
            },
          ],
        },
        PIDDIG: {
          name: "PIDDIG",
          baranggays: [
            {
              name: "AB-ABUT",
            },
            {
              name: "ABUCAY",
            },
            {
              name: "ANAO (POB.)",
            },
            {
              name: "ARUA-AY",
            },
            {
              name: "BIMMANGA",
            },
            {
              name: "BOYBOY",
            },
            {
              name: "CABAROAN (POB.)",
            },
            {
              name: "CALAMBEG",
            },
            {
              name: "CALLUSA",
            },
            {
              name: "DUPITAC",
            },
            {
              name: "ESTANCIA",
            },
            {
              name: "GAYAMAT",
            },
            {
              name: "LAGANDIT",
            },
            {
              name: "LIBNAOAN",
            },
            {
              name: "LOING (POB.)",
            },
            {
              name: "MAAB-ABACA",
            },
            {
              name: "MANGITAYAG",
            },
            {
              name: "MARUAYA",
            },
            {
              name: "PIDDIG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SUCSUQUEN",
            },
            {
              name: "TANGAOAN",
            },
            {
              name: "TONOTON",
            },
          ],
        },
        PINILI: {
          name: "PINILI",
          baranggays: [
            {
              name: "AGLIPAY",
            },
            {
              name: "APATUT-LUBONG",
            },
            {
              name: "BADIO",
            },
            {
              name: "BARBAR",
            },
            {
              name: "BUANGA",
            },
            {
              name: "BULBULALA",
            },
            {
              name: "BUNGRO",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "CAPANGDANAN",
            },
            {
              name: "DALAYAP",
            },
            {
              name: "DARAT",
            },
            {
              name: "GULPENG",
            },
            {
              name: "LILIPUTEN",
            },
            {
              name: "LUMBAAN-BICBICA",
            },
            {
              name: "NAGTRIGOAN",
            },
            {
              name: "PAGDILAO (POB.)",
            },
            {
              name: "PINILI",
            },
            {
              name: "PUGAOAN",
            },
            {
              name: "PURITAC",
            },
            {
              name: "PUZOL",
            },
            {
              name: "SACRITAN",
            },
            {
              name: "SALANAP",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TARTARABANG",
            },
            {
              name: "UPON",
            },
            {
              name: "VALBUENA (POB.)",
            },
          ],
        },
        "SAN NICOLAS": {
          name: "SAN NICOLAS",
          baranggays: [
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN BALTAZAR (POB.)",
            },
            {
              name: "SAN BARTOLOME (POB.)",
            },
            {
              name: "SAN CAYETANO (POB.)",
            },
            {
              name: "SAN EUGENIO (POB.)",
            },
            {
              name: "SAN FERNANDO (POB.)",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN GREGORIO (POB.)",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SAN ILDEFONSO (POB.)",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN BAUTISTA (POB.)",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN LUCAS (POB.)",
            },
            {
              name: "SAN MARCOS (PAYAS)",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PAULO (POB.)",
            },
            {
              name: "SAN PEDRO (BINGAO)",
            },
            {
              name: "SAN RUFINO (POB.)",
            },
            {
              name: "SAN SILVESTRE (POB.)",
            },
            {
              name: "SANTA ASUNCION (SAMAC)",
            },
            {
              name: "SANTA CECILIA (BARABAR)",
            },
            {
              name: "SANTA MONICA (NAGREBCAN)",
            },
          ],
        },
        SARRAT: {
          name: "SARRAT",
          baranggays: [
            {
              name: "SAN AGUSTIN (POB.)",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BERNABE",
            },
            {
              name: "SAN CRISTOBAL",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOAQUIN (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LEANDRO (POB.)",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA BARBARA (POB.)",
            },
            {
              name: "SANTA MAGDALENA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO SANTIAGO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SARRAT",
            },
          ],
        },
        SOLSONA: {
          name: "SOLSONA",
          baranggays: [
            {
              name: "AGUITAP",
            },
            {
              name: "BAGBAG",
            },
            {
              name: "BAGBAGO",
            },
            {
              name: "BARCELONA",
            },
            {
              name: "BUBUOS",
            },
            {
              name: "CAPURICTAN",
            },
            {
              name: "CATANGRARAN",
            },
            {
              name: "DARASDAS",
            },
            {
              name: "JUAN (POB.)",
            },
            {
              name: "LAURETA (POB.)",
            },
            {
              name: "LIPAY",
            },
            {
              name: "MAANANTENG",
            },
            {
              name: "MANALPAC",
            },
            {
              name: "MARIQUET",
            },
            {
              name: "NAGPATPATAN",
            },
            {
              name: "NALASIN",
            },
            {
              name: "PUTTAO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SOLSONA",
            },
            {
              name: "TALUGTOG",
            },
          ],
        },
        VINTAR: {
          name: "VINTAR",
          baranggays: [
            {
              name: "ABKIR",
            },
            {
              name: "ALEJO MALASIG",
            },
            {
              name: "ALSEM",
            },
            {
              name: "BAGO",
            },
            {
              name: "BULBULALA",
            },
            {
              name: "CABANGARAN",
            },
            {
              name: "CABAYO",
            },
            {
              name: "CABISOCOLAN",
            },
            {
              name: "CANAAM",
            },
            {
              name: "COLUMBIA",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "DIPILAT",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "ESTER",
            },
            {
              name: "ISIC ISIC",
            },
            {
              name: "MABANBANAG",
            },
            {
              name: "MALAMPA (PENINAAN-MALAMPA)",
            },
            {
              name: "MANARANG",
            },
            {
              name: "MARGAAY",
            },
            {
              name: "NAMOROC",
            },
            {
              name: "PARPAROROC",
            },
            {
              name: "PARUT",
            },
            {
              name: "PEDRO S. ALVIAR (DIATON)",
            },
            {
              name: "SALSALAMAGUI",
            },
            {
              name: "SAN JOSE (LIPAY)",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SAN RAMON (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANTA MARIA (POB.)",
            },
            {
              name: "TAMDAGAN",
            },
            {
              name: "VINTAR",
            },
            {
              name: "VISAYA",
            },
          ],
        },
      },
    },
    "ILOCOS SUR": {
      name: "ILOCOS SUR",
      region: "REGION 1",
      municipalities: {
        ALILEM: {
          name: "ALILEM",
          baranggays: [
            {
              name: "ALILEM",
            },
            {
              name: "ALILEM DAYA (POB.)",
            },
            {
              name: "AMILONGAN",
            },
            {
              name: "ANAAO",
            },
            {
              name: "APANG",
            },
            {
              name: "APAYA",
            },
            {
              name: "BATBATO",
            },
            {
              name: "DADDAAY",
            },
            {
              name: "DALAWA",
            },
            {
              name: "KIAT",
            },
          ],
        },
        BANAYOYO: {
          name: "BANAYOYO",
          baranggays: [
            {
              name: "BAGBAGOTOT",
            },
            {
              name: "BANAYOYO",
            },
            {
              name: "BANBANAAL",
            },
            {
              name: "BISANGOL",
            },
            {
              name: "CADANGLAAN",
            },
            {
              name: "CASILAGAN NORTE",
            },
            {
              name: "CASILAGAN SUR",
            },
            {
              name: "ELEFANTE",
            },
            {
              name: "GUARDIA",
            },
            {
              name: "LINTIC",
            },
            {
              name: "LOPEZ",
            },
            {
              name: "MONTERO",
            },
            {
              name: "NAGUIMBA",
            },
            {
              name: "PILA",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        BANTAY: {
          name: "BANTAY",
          baranggays: [
            {
              name: "AGGAY",
            },
            {
              name: "AN-ANNAM",
            },
            {
              name: "BALALENG",
            },
            {
              name: "BANAOANG",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BULAG",
            },
            {
              name: "BUQUIG",
            },
            {
              name: "CABALANGGAN",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "CABUSLIGAN",
            },
            {
              name: "CAPANGDANAN",
            },
            {
              name: "GUIMOD",
            },
            {
              name: "LINGSAT",
            },
            {
              name: "MALINGEB",
            },
            {
              name: "MIRA",
            },
            {
              name: "NAGUIDDAYAN",
            },
            {
              name: "ORA",
            },
            {
              name: "PAING",
            },
            {
              name: "PUSPUS",
            },
            {
              name: "QUIMMARAYAN",
            },
            {
              name: "SAGNEB",
            },
            {
              name: "SAGPAT",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SAN MARIANO (SALLACONG)",
            },
            {
              name: "SINABAAN",
            },
            {
              name: "TAGUIPORO",
            },
            {
              name: "TALEB",
            },
            {
              name: "TAY-AC",
            },
          ],
        },
        BURGOS: {
          name: "BURGOS",
          baranggays: [
            {
              name: "AMBUGAT",
            },
            {
              name: "BALUGANG",
            },
            {
              name: "BANGBANGAR",
            },
            {
              name: "BESSANG",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CABCABURAO",
            },
            {
              name: "CADACAD",
            },
            {
              name: "CALLITONG",
            },
            {
              name: "DAYANKI",
            },
            {
              name: "DIRDIRIG (DIRDIRIG-PADAY)",
            },
            {
              name: "LESSEB",
            },
            {
              name: "LUBING",
            },
            {
              name: "LUCABAN",
            },
            {
              name: "LUNA",
            },
            {
              name: "MACAOAYAN",
            },
            {
              name: "MAMBUG",
            },
            {
              name: "MANABOC",
            },
            {
              name: "MAPANIT",
            },
            {
              name: "NAGPANAOAN",
            },
            {
              name: "PADUROS",
            },
            {
              name: "PATAC",
            },
            {
              name: "POBLACION NORTE (BATO)",
            },
            {
              name: "POBLACION SUR (MASINGIT)",
            },
            {
              name: "SABANGAN PINGGAN",
            },
            {
              name: "SUBADI NORTE",
            },
            {
              name: "SUBADI SUR",
            },
            {
              name: "TALIAO",
            },
          ],
        },
        CABUGAO: {
          name: "CABUGAO",
          baranggays: [
            {
              name: "ALINAAY",
            },
            {
              name: "ARAGAN",
            },
            {
              name: "ARNAP",
            },
            {
              name: "BACLIG (POB.)",
            },
            {
              name: "BATO",
            },
            {
              name: "BONIFACIO (POB.)",
            },
            {
              name: "BUNGRO",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CACADIRAN",
            },
            {
              name: "CAELLAYAN",
            },
            {
              name: "CARUSIPAN",
            },
            {
              name: "CATUCDAAN",
            },
            {
              name: "CUANCABAL",
            },
            {
              name: "CUANTACLA",
            },
            {
              name: "DACLAPAN",
            },
            {
              name: "DARDARAT",
            },
            {
              name: "LIPIT",
            },
            {
              name: "MARADODON",
            },
            {
              name: "MARGAAY",
            },
            {
              name: "NAGSANTAAN",
            },
            {
              name: "NAGSINCAOAN",
            },
            {
              name: "NAMRUANGAN",
            },
            {
              name: "PILA",
            },
            {
              name: "PUG-OS",
            },
            {
              name: "QUEZON (POB.)",
            },
            {
              name: "REPPAAC",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAGAYADEN",
            },
            {
              name: "SALAPASAP",
            },
            {
              name: "SALOMAGUE",
            },
            {
              name: "SISIM",
            },
            {
              name: "TUROD",
            },
            {
              name: "TUROD-PATAC",
            },
          ],
        },
        "CANDON CITY": {
          name: "CANDON CITY",
          baranggays: [
            {
              name: "ALLANGIGAN PRIMERO",
            },
            {
              name: "ALLANGIGAN SEGUNDO",
            },
            {
              name: "AMGUID",
            },
            {
              name: "AYUDANTE",
            },
            {
              name: "BAGANI CAMPOSANTO",
            },
            {
              name: "BAGANI GABOR",
            },
            {
              name: "BAGANI TOCGO",
            },
            {
              name: "BAGANI UBBOG",
            },
            {
              name: "BAGAR",
            },
            {
              name: "BALINGAOAN",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "CALAOAAN",
            },
            {
              name: "CALONGBUYAN",
            },
            {
              name: "CANDON CITY",
            },
            {
              name: "CATERMAN",
            },
            {
              name: "CUBCUBBOOT",
            },
            {
              name: "DARAPIDAP",
            },
            {
              name: "LANGLANGCA PRIMERO",
            },
            {
              name: "LANGLANGCA SEGUNDO",
            },
            {
              name: "OAIG-DAYA",
            },
            {
              name: "PALACAPAC",
            },
            {
              name: "PARAS",
            },
            {
              name: "PARIOC PRIMERO",
            },
            {
              name: "PARIOC SEGUNDO",
            },
            {
              name: "PATPATA PRIMERO",
            },
            {
              name: "PATPATA SEGUNDO",
            },
            {
              name: "PAYPAYAD",
            },
            {
              name: "SALVADOR PRIMERO",
            },
            {
              name: "SALVADOR SEGUNDO",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TABLAC",
            },
            {
              name: "TALOGTOG",
            },
            {
              name: "TAMURONG PRIMERO",
            },
            {
              name: "TAMURONG SEGUNDO",
            },
            {
              name: "VILLARICA",
            },
          ],
        },
        CAOAYAN: {
          name: "CAOAYAN",
          baranggays: [
            {
              name: "ANONANG MAYOR",
            },
            {
              name: "ANONANG MENOR",
            },
            {
              name: "BAGGOC",
            },
            {
              name: "CALLAGUIP",
            },
            {
              name: "CAOAYAN",
            },
            {
              name: "CAPARACADAN",
            },
            {
              name: "DON ALEJANDRO QUIROLGICO (POB.)",
            },
            {
              name: "DON DIMAS QUERUBIN (POB.)",
            },
            {
              name: "DON LORENZO QUERUBIN (POB.)",
            },
            {
              name: "FUERTE",
            },
            {
              name: "MANANGAT",
            },
            {
              name: "NAGUILIAN",
            },
            {
              name: "NANSUAGAO",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PANTAY TAMURONG",
            },
            {
              name: "PANTAY-QUITIQUIT",
            },
            {
              name: "PURO",
            },
            {
              name: "VILLAMAR",
            },
          ],
        },
        CERVANTES: {
          name: "CERVANTES",
          baranggays: [
            {
              name: "ALULING",
            },
            {
              name: "CERVANTES",
            },
            {
              name: "COMILLAS NORTH",
            },
            {
              name: "COMILLAS SOUTH",
            },
            {
              name: "CONCEPCION (POB.)",
            },
            {
              name: "DINWEDE EAST",
            },
            {
              name: "DINWEDE WEST",
            },
            {
              name: "LIBANG",
            },
            {
              name: "MALAYA",
            },
            {
              name: "PILIPIL",
            },
            {
              name: "REMEDIOS",
            },
            {
              name: "ROSARIO (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS",
            },
          ],
        },
        GALIMUYOD: {
          name: "GALIMUYOD",
          baranggays: [
            {
              name: "ABAYA",
            },
            {
              name: "BARACBAC",
            },
            {
              name: "BIDBIDAY",
            },
            {
              name: "BITONG",
            },
            {
              name: "BOROBOR",
            },
            {
              name: "CALIMUGTONG",
            },
            {
              name: "CALONGBUYAN",
            },
            {
              name: "CALUMBAYA",
            },
            {
              name: "DALDAGAN",
            },
            {
              name: "GALIMUYOD",
            },
            {
              name: "KILANG",
            },
            {
              name: "LEGASPI",
            },
            {
              name: "MABAYAG",
            },
            {
              name: "MATANUBONG",
            },
            {
              name: "MCKINLEY",
            },
            {
              name: "NAGSINGCAOAN",
            },
            {
              name: "OAIG-DAYA",
            },
            {
              name: "PAGANGPANG",
            },
            {
              name: "PATAC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RUBIO",
            },
            {
              name: "SABANGAN-BATO",
            },
            {
              name: "SACAANG",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SAPANG",
            },
          ],
        },
        "GREGORIO DEL PILAR (CONCEPCION)": {
          name: "GREGORIO DEL PILAR (CONCEPCION)",
          baranggays: [
            {
              name: "ALFONSO (TANGAOAN)",
            },
            {
              name: "BUSSOT",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAPDAPPIG",
            },
            {
              name: "GREGORIO DEL PILAR (CONCEPCION)",
            },
            {
              name: "MATUE-BUTARAG",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
          ],
        },
        LIDLIDDA: {
          name: "LIDLIDDA",
          baranggays: [
            {
              name: "BANUCAL",
            },
            {
              name: "BEQUI-WALIN",
            },
            {
              name: "BUGUI",
            },
            {
              name: "CALUNGBUYAN",
            },
            {
              name: "CARCARABASA",
            },
            {
              name: "LABUT",
            },
            {
              name: "LIDLIDDA",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SUYSUYAN",
            },
            {
              name: "TAY-AC",
            },
          ],
        },
        MAGSINGAL: {
          name: "MAGSINGAL",
          baranggays: [
            {
              name: "ALANGAN",
            },
            {
              name: "BACAR",
            },
            {
              name: "BARBARIT",
            },
            {
              name: "BUNGRO",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "CADANGLAAN",
            },
            {
              name: "CARAISAN",
            },
            {
              name: "DACUTAN",
            },
            {
              name: "LABUT",
            },
            {
              name: "MAAS-ASIN",
            },
            {
              name: "MACATCATUD",
            },
            {
              name: "MAGSINGAL",
            },
            {
              name: "MANZANTE",
            },
            {
              name: "MARATUDO",
            },
            {
              name: "MIRAMAR",
            },
            {
              name: "NAMALPALAN",
            },
            {
              name: "NAPO",
            },
            {
              name: "PAGSANAAN NORTE",
            },
            {
              name: "PAGSANAAN SUR",
            },
            {
              name: "PANAY NORTE",
            },
            {
              name: "PANAY SUR",
            },
            {
              name: "PATONG",
            },
            {
              name: "PURO",
            },
            {
              name: "SAN BASILIO (POB.)",
            },
            {
              name: "SAN CLEMENTE (POB.)",
            },
            {
              name: "SAN JULIAN (POB.)",
            },
            {
              name: "SAN LUCAS (POB.)",
            },
            {
              name: "SAN RAMON (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SARSARACAT",
            },
          ],
        },
        NAGBUKEL: {
          name: "NAGBUKEL",
          baranggays: [
            {
              name: "BALAWEG",
            },
            {
              name: "BANDRIL",
            },
            {
              name: "BANTUGO",
            },
            {
              name: "CADACAD",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "CASOCOS",
            },
            {
              name: "LAPTING",
            },
            {
              name: "MAPISI",
            },
            {
              name: "MISSION",
            },
            {
              name: "NAGBUKEL",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "TALEB",
            },
          ],
        },
        NARVACAN: {
          name: "NARVACAN",
          baranggays: [
            {
              name: "ABUOR",
            },
            {
              name: "AMBULOGAN",
            },
            {
              name: "AQUIB",
            },
            {
              name: "BANGLAYAN",
            },
            {
              name: "BANTAY ABOT",
            },
            {
              name: "BULANOS",
            },
            {
              name: "CADACAD",
            },
            {
              name: "CAGAYUNGAN",
            },
            {
              name: "CAMARAO",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "CODOOG",
            },
            {
              name: "DASAY",
            },
            {
              name: "DINALAOAN",
            },
            {
              name: "ESTANCIA",
            },
            {
              name: "LANIPAO",
            },
            {
              name: "LUNGOG",
            },
            {
              name: "MARGAAY",
            },
            {
              name: "MAROZO",
            },
            {
              name: "NAGUNEG",
            },
            {
              name: "NARVACAN",
            },
            {
              name: "ORENCE",
            },
            {
              name: "PANTOC",
            },
            {
              name: "PARATONG",
            },
            {
              name: "PARPARIA",
            },
            {
              name: "QUINARAYAN",
            },
            {
              name: "RIVADAVIA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA LUCIA (POB.)",
            },
            {
              name: "SARMINGAN",
            },
            {
              name: "SUCOC",
            },
            {
              name: "SULVEC",
            },
            {
              name: "TUROD",
            },
          ],
        },
        "QUIRINO (ANGKAKI)": {
          name: "QUIRINO (ANGKAKI)",
          baranggays: [
            {
              name: "BANOEN",
            },
            {
              name: "CAYUS",
            },
            {
              name: "LAMAG (TUBTUBA)",
            },
            {
              name: "LEGLEG (POB.)",
            },
            {
              name: "MALIDEG",
            },
            {
              name: "NAMITPIT",
            },
            {
              name: "PATIACAN",
            },
            {
              name: "PATUNGCALEO (LAMAG)",
            },
            {
              name: "QUIRINO (ANGKAKI)",
            },
            {
              name: "SUAGAYAN",
            },
          ],
        },
        "SALCEDO (BAUGEN)": {
          name: "SALCEDO (BAUGEN)",
          baranggays: [
            {
              name: "ATABAY",
            },
            {
              name: "BALIDBID",
            },
            {
              name: "BALUARTE",
            },
            {
              name: "BAYBAYADING",
            },
            {
              name: "BOGUIBOG",
            },
            {
              name: "BULALA-LEGUEY",
            },
            {
              name: "CALANGCUASAN",
            },
            {
              name: "CULIONG",
            },
            {
              name: "DINARATAN",
            },
            {
              name: "KALIWAKIW",
            },
            {
              name: "KINMARIN",
            },
            {
              name: "LUCBUBAN",
            },
            {
              name: "MADARANG",
            },
            {
              name: "MALIGCONG",
            },
            {
              name: "PIAS",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SALCEDO (BAUGEN)",
            },
            {
              name: "SAN GASPAR",
            },
            {
              name: "SAN TIBURCIO",
            },
            {
              name: "SORIOAN",
            },
            {
              name: "UBBOG",
            },
          ],
        },
        "SAN EMILIO": {
          name: "SAN EMILIO",
          baranggays: [
            {
              name: "CABAROAN (POB.)",
            },
            {
              name: "KALUMSING",
            },
            {
              name: "LANCUAS",
            },
            {
              name: "MATIBUEY",
            },
            {
              name: "PALTOC",
            },
            {
              name: "SAN EMILIO",
            },
            {
              name: "SAN MILIANO",
            },
            {
              name: "SIBSIBBU",
            },
            {
              name: "TIAGAN",
            },
          ],
        },
        "SAN ESTEBAN": {
          name: "SAN ESTEBAN",
          baranggays: [
            {
              name: "ANSAD",
            },
            {
              name: "APATOT",
            },
            {
              name: "BATERIA",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "CAPPA-CAPPA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "VILLA QUIRINO",
            },
          ],
        },
        "SAN ESTEBAN CITY": {
          name: "SAN ESTEBAN CITY",
          baranggays: [
            {
              name: "SAN ESTEBAN CITY",
            },
          ],
        },
        "SAN ILDEFONSO": {
          name: "SAN ILDEFONSO",
          baranggays: [
            {
              name: "ARNAP",
            },
            {
              name: "BAHET",
            },
            {
              name: "BELEN",
            },
            {
              name: "BUNGRO",
            },
            {
              name: "BUSIING NORTE",
            },
            {
              name: "BUSIING SUR",
            },
            {
              name: "DONGALO",
            },
            {
              name: "GONGOGONG",
            },
            {
              name: "IBOY",
            },
            {
              name: "KINAMANTIRISAN",
            },
            {
              name: "OTOL-PATAC",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAGNEB",
            },
            {
              name: "SAGSAGAT",
            },
            {
              name: "SAN ILDEFONSO",
            },
          ],
        },
        "SAN JUAN (LAPOG)": {
          name: "SAN JUAN (LAPOG)",
          baranggays: [
            {
              name: "BACSIL",
            },
            {
              name: "BALIW",
            },
            {
              name: "BANNUAR (POB.)",
            },
            {
              name: "CABANGLOTAN",
            },
            {
              name: "CAMANGGAAN",
            },
            {
              name: "CAMINDOROAN",
            },
            {
              name: "CARONOAN",
            },
            {
              name: "DARAO",
            },
            {
              name: "DARDARAT",
            },
            {
              name: "GUIMOD NORTE",
            },
            {
              name: "GUIMOD SUR",
            },
            {
              name: "IMMAYOS NORTE",
            },
            {
              name: "IMMAYOS SUR",
            },
            {
              name: "LABNIG",
            },
            {
              name: "LAPTING",
            },
            {
              name: "LIRA (POB.)",
            },
            {
              name: "MURAYA",
            },
            {
              name: "NAGSABARAN",
            },
            {
              name: "NAGSUPOTAN",
            },
            {
              name: "PANDAYAN (POB.)",
            },
            {
              name: "REFARO",
            },
            {
              name: "RESURRECCION (POB.)",
            },
            {
              name: "SABANGAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN (LAPOG)",
            },
            {
              name: "SAOANG",
            },
            {
              name: "SOLOTSOLOT",
            },
            {
              name: "SUNGGIAM",
            },
            {
              name: "SURNGIT",
            },
          ],
        },
        "SAN VICENTE": {
          name: "SAN VICENTE",
          baranggays: [
            {
              name: "BANTAOAY",
            },
            {
              name: "BAYUBAY NORTE",
            },
            {
              name: "BAYUBAY SUR",
            },
            {
              name: "LUBONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUDOC",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SAN VICENTE",
            },
          ],
        },
        SANTA: {
          name: "SANTA",
          baranggays: [
            {
              name: "AMPANDULA",
            },
            {
              name: "BANAOANG",
            },
            {
              name: "BASUG",
            },
            {
              name: "BUCALAG",
            },
            {
              name: "CABANGARAN",
            },
            {
              name: "CALUNGBOYAN",
            },
            {
              name: "CASIBER",
            },
            {
              name: "DAMMAY",
            },
            {
              name: "LABUT NORTE",
            },
            {
              name: "LABUT SUR",
            },
            {
              name: "MABILBILA NORTE",
            },
            {
              name: "MABILBILA SUR",
            },
            {
              name: "MAGSAYSAY DISTRICT (POB.)",
            },
            {
              name: "MANUEVA",
            },
            {
              name: "MARCOS (POB.)",
            },
            {
              name: "NAGPANAOAN",
            },
            {
              name: "NAMALANGAN",
            },
            {
              name: "ORIBI",
            },
            {
              name: "PASUNGOL",
            },
            {
              name: "QUEZON (POB.)",
            },
            {
              name: "QUIRINO (POB.)",
            },
            {
              name: "RANCHO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SACUYYA NORTE",
            },
            {
              name: "SACUYYA SUR",
            },
            {
              name: "SANTA",
            },
            {
              name: "TABUCOLAN",
            },
          ],
        },
        "SANTA CATALINA": {
          name: "SANTA CATALINA",
          baranggays: [
            {
              name: "CABAROAN",
            },
            {
              name: "CABITTAOGAN",
            },
            {
              name: "CABULOAN",
            },
            {
              name: "PANGADA",
            },
            {
              name: "PARATONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SINABAAN",
            },
            {
              name: "SUBEC",
            },
            {
              name: "TAMORONG",
            },
          ],
        },
        "SANTA CRUZ": {
          name: "SANTA CRUZ",
          baranggays: [
            {
              name: "AMARAO",
            },
            {
              name: "BABAYOAN",
            },
            {
              name: "BACSAYAN",
            },
            {
              name: "BANAY",
            },
            {
              name: "BAYUGAO ESTE",
            },
            {
              name: "BAYUGAO OESTE",
            },
            {
              name: "BESALAN",
            },
            {
              name: "BUGBUGA",
            },
            {
              name: "CALAOAAN",
            },
            {
              name: "CAMANGGAAN",
            },
            {
              name: "CANDALICAN",
            },
            {
              name: "CAPARIAAN",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "COSCOSNONG",
            },
            {
              name: "DALIGAN",
            },
            {
              name: "DILI",
            },
            {
              name: "GABOR NORTE",
            },
            {
              name: "GABOR SUR",
            },
            {
              name: "LALONG",
            },
            {
              name: "LANTAG",
            },
            {
              name: "LAS-UD",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "MANTANAS",
            },
            {
              name: "NAGTENGNGA",
            },
            {
              name: "PADAOIL",
            },
            {
              name: "PARATONG",
            },
            {
              name: "PATTIQUI",
            },
            {
              name: "PIDPID",
            },
            {
              name: "PILAR",
            },
            {
              name: "PINIPIN",
            },
            {
              name: "POBLACION ESTE",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "POBLACION WESTE",
            },
            {
              name: "QUINFERMIN",
            },
            {
              name: "QUINSORIANO",
            },
            {
              name: "SAGAT",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SAOAT",
            },
            {
              name: "SEVILLA",
            },
            {
              name: "SIDAOEN",
            },
            {
              name: "SUYO",
            },
            {
              name: "TAMPUGO",
            },
            {
              name: "TUROD",
            },
            {
              name: "VILLA GARCIA",
            },
            {
              name: "VILLA HERMOSA",
            },
            {
              name: "VILLA LAURENCIA",
            },
          ],
        },
        "SANTA LUCIA": {
          name: "SANTA LUCIA",
          baranggays: [
            {
              name: "ALINCAOEG",
            },
            {
              name: "ANGKILENG",
            },
            {
              name: "ARANGIN",
            },
            {
              name: "AYUSAN (POB.)",
            },
            {
              name: "BANBANABA",
            },
            {
              name: "BANI",
            },
            {
              name: "BAO-AS",
            },
            {
              name: "BARANGOBONG (POB.)",
            },
            {
              name: "BULICLIC",
            },
            {
              name: "BURGOS (POB.)",
            },
            {
              name: "CABARITAN",
            },
            {
              name: "CATAYAGAN",
            },
            {
              name: "CONCONIG EAST",
            },
            {
              name: "CONCONIG WEST",
            },
            {
              name: "DAMACUAG",
            },
            {
              name: "LUBA",
            },
            {
              name: "LUBONG",
            },
            {
              name: "NAGREBCAN",
            },
            {
              name: "NAGTABLAAN",
            },
            {
              name: "NAMATICAN",
            },
            {
              name: "NANGALISAN",
            },
            {
              name: "PALALI NORTE",
            },
            {
              name: "PALALI SUR",
            },
            {
              name: "PAOC NORTE",
            },
            {
              name: "PAOC SUR",
            },
            {
              name: "PARATONG",
            },
            {
              name: "PILA EAST",
            },
            {
              name: "PILA WEST",
            },
            {
              name: "QUINABALAYANGAN",
            },
            {
              name: "RONDA",
            },
            {
              name: "SABUANAN",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SAPANG",
            },
            {
              name: "SUAGAYAN",
            },
            {
              name: "VICAL",
            },
          ],
        },
        "SANTA MARIA": {
          name: "SANTA MARIA",
          baranggays: [
            {
              name: "AG-AGRAO",
            },
            {
              name: "AMPUAGAN",
            },
            {
              name: "BABALLASIOAN",
            },
            {
              name: "BALIW DAYA",
            },
            {
              name: "BALIW LAUD",
            },
            {
              name: "BIA-O",
            },
            {
              name: "BUTIR",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "DANUMAN EAST",
            },
            {
              name: "DANUMAN WEST",
            },
            {
              name: "DUNGLAYAN",
            },
            {
              name: "GUSING",
            },
            {
              name: "LANGAOAN",
            },
            {
              name: "LASLASONG NORTE",
            },
            {
              name: "LASLASONG SUR",
            },
            {
              name: "LASLASONG WEST",
            },
            {
              name: "LESSEB",
            },
            {
              name: "LINGSAT",
            },
            {
              name: "LUBONG",
            },
            {
              name: "MAYNGANAY NORTE",
            },
            {
              name: "MAYNGANAY SUR",
            },
            {
              name: "NAGSAYAOAN",
            },
            {
              name: "NAGTUPACAN",
            },
            {
              name: "NALVO",
            },
            {
              name: "PACANG",
            },
            {
              name: "PENNED",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SILAG",
            },
            {
              name: "SUMAGUI",
            },
            {
              name: "SUSO",
            },
            {
              name: "TANGAOAN",
            },
            {
              name: "TINAAN",
            },
          ],
        },
        SANTIAGO: {
          name: "SANTIAGO",
          baranggays: [
            {
              name: "AL-ALUDIG",
            },
            {
              name: "AMBUCAO",
            },
            {
              name: "BAYBAYABAS",
            },
            {
              name: "BIGBIGA",
            },
            {
              name: "BULBULALA",
            },
            {
              name: "BUSEL-BUSEL",
            },
            {
              name: "BUTOL",
            },
            {
              name: "CABURAO",
            },
            {
              name: "DAN-AR",
            },
            {
              name: "GABAO",
            },
            {
              name: "GUINABANG",
            },
            {
              name: "IMUS",
            },
            {
              name: "LANG-AYAN",
            },
            {
              name: "MAMBUG",
            },
            {
              name: "NALASIN",
            },
            {
              name: "OLO-OLO NORTE",
            },
            {
              name: "OLO-OLO SUR",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SABANGAN",
            },
            {
              name: "SALINCUB",
            },
            {
              name: "SAN JOSE (BARAOAS)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "UBBOG",
            },
          ],
        },
        "SANTO DOMINGO": {
          name: "SANTO DOMINGO",
          baranggays: [
            {
              name: "BINALAYANGAN",
            },
            {
              name: "BINONGAN",
            },
            {
              name: "BOROBOR",
            },
            {
              name: "CABARITAN",
            },
            {
              name: "CABIGBIGAAN",
            },
            {
              name: "CALAUTIT",
            },
            {
              name: "CALAY-AB",
            },
            {
              name: "CAMESTIZOAN",
            },
            {
              name: "CASILI",
            },
            {
              name: "FLORA",
            },
            {
              name: "LAGATIT",
            },
            {
              name: "LAOINGEN",
            },
            {
              name: "LUSSOC",
            },
            {
              name: "NAGBETTEDAN",
            },
            {
              name: "NAGLAOA-AN",
            },
            {
              name: "NALASIN",
            },
            {
              name: "NAMBARAN",
            },
            {
              name: "NANERMAN",
            },
            {
              name: "NAPO",
            },
            {
              name: "PADU CHICO",
            },
            {
              name: "PADU GRANDE",
            },
            {
              name: "PAGURAPER",
            },
            {
              name: "PANAY",
            },
            {
              name: "PANGPANGDAN",
            },
            {
              name: "PARADA",
            },
            {
              name: "PARAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUERTA REAL",
            },
            {
              name: "PUSSUAC",
            },
            {
              name: "QUIMMARAYAN",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SIVED",
            },
            {
              name: "SUKSUKIT",
            },
            {
              name: "VACUNERO",
            },
          ],
        },
        SIGAY: {
          name: "SIGAY",
          baranggays: [
            {
              name: "ABACCAN",
            },
            {
              name: "MABILEG",
            },
            {
              name: "MATALLUCOD",
            },
            {
              name: "POBLACION (MADAYAW)",
            },
            {
              name: "SAN ELIAS",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SIGAY",
            },
          ],
        },
        SINAIT: {
          name: "SINAIT",
          baranggays: [
            {
              name: "AGUING",
            },
            {
              name: "BALIW",
            },
            {
              name: "BALLAIGUI (POB.)",
            },
            {
              name: "BARACBAC",
            },
            {
              name: "BARIKIR",
            },
            {
              name: "BATTOG",
            },
            {
              name: "BINACUD",
            },
            {
              name: "CABANGTALAN",
            },
            {
              name: "CABARAMBANAN",
            },
            {
              name: "CABULALAAN",
            },
            {
              name: "CADANGLAAN",
            },
            {
              name: "CALANUTIAN",
            },
            {
              name: "CALINGAYAN",
            },
            {
              name: "CURTIN",
            },
            {
              name: "DADALAQUITEN NORTE",
            },
            {
              name: "DADALAQUITEN SUR",
            },
            {
              name: "DEAN LEOPOLDO YABES (PUG-OS)",
            },
            {
              name: "DUYAYYAT",
            },
            {
              name: "JORDAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MACABIAG (POB.)",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARNAY",
            },
            {
              name: "MASADAG",
            },
            {
              name: "NAGBALIOARTIAN",
            },
            {
              name: "NAGCULLOOBAN",
            },
            {
              name: "NAGONGBURAN",
            },
            {
              name: "NAMNAMA (POB.)",
            },
            {
              name: "PACIS",
            },
            {
              name: "PARATONG",
            },
            {
              name: "PURAG",
            },
            {
              name: "QUIBIT-QUIBIT",
            },
            {
              name: "QUIMMALLOGONG",
            },
            {
              name: "RANG-AY (POB.)",
            },
            {
              name: "RICUDO",
            },
            {
              name: "SABAÑGAN (MARCOS)",
            },
            {
              name: "SALLACAPO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SAPRIANA",
            },
            {
              name: "SINAIT",
            },
            {
              name: "TAPAO",
            },
            {
              name: "TEPPENG",
            },
            {
              name: "TUBIGAY",
            },
            {
              name: "UBBOG",
            },
            {
              name: "ZAPAT",
            },
          ],
        },
        SUGPON: {
          name: "SUGPON",
          baranggays: [
            {
              name: "BALBALAYANG (POB.)",
            },
            {
              name: "BANGA",
            },
            {
              name: "CAOAYAN",
            },
            {
              name: "DANAC",
            },
            {
              name: "LICUNGAN (CULLANG)",
            },
            {
              name: "PANGOTAN",
            },
            {
              name: "SUGPON",
            },
          ],
        },
        SUYO: {
          name: "SUYO",
          baranggays: [
            {
              name: "BARINGCUCURONG",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "MAN-ATONG",
            },
            {
              name: "PATOC-AO",
            },
            {
              name: "POBLACION (KIMPUSA)",
            },
            {
              name: "SUYO",
            },
            {
              name: "SUYO PROPER",
            },
            {
              name: "URZADAN",
            },
            {
              name: "USO",
            },
          ],
        },
        TAGUDIN: {
          name: "TAGUDIN",
          baranggays: [
            {
              name: "AG-AGUMAN",
            },
            {
              name: "AMBALAYAT",
            },
            {
              name: "BARACBAC",
            },
            {
              name: "BARIO-AN",
            },
            {
              name: "BARITAO",
            },
            {
              name: "BECQUES",
            },
            {
              name: "BIMMANGA",
            },
            {
              name: "BIO",
            },
            {
              name: "BITALAG",
            },
            {
              name: "BORONO",
            },
            {
              name: "BUCAO EAST",
            },
            {
              name: "BUCAO WEST",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "CABUGBUGAN",
            },
            {
              name: "CABULANGLANGAN",
            },
            {
              name: "DACUTAN",
            },
            {
              name: "DARDARAT",
            },
            {
              name: "DEL PILAR (POB.)",
            },
            {
              name: "FAROLA",
            },
            {
              name: "GABUR",
            },
            {
              name: "GARITAN",
            },
            {
              name: "JARDIN",
            },
            {
              name: "LACONG",
            },
            {
              name: "LANTAG",
            },
            {
              name: "LAS-UD",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "LUBNAC",
            },
            {
              name: "MAGSAYSAY (POB.)",
            },
            {
              name: "MALACAÑANG",
            },
            {
              name: "PACAC",
            },
            {
              name: "PALLOGAN",
            },
            {
              name: "PUDOC EAST",
            },
            {
              name: "PUDOC WEST",
            },
            {
              name: "PULA",
            },
            {
              name: "QUIRINO (POB.)",
            },
            {
              name: "RANGET",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAWAT",
            },
            {
              name: "TAGUDIN",
            },
            {
              name: "TALLAOEN",
            },
            {
              name: "TAMPUGO",
            },
            {
              name: "TARANGOTONG",
            },
          ],
        },
        "VIGAN CITY": {
          name: "VIGAN CITY",
          baranggays: [
            {
              name: "AYUSAN NORTE",
            },
            {
              name: "AYUSAN SUR",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY IX",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "BARANGAY VII",
            },
            {
              name: "BARANGAY VIII",
            },
            {
              name: "BARRACA",
            },
            {
              name: "BEDDENG DAYA",
            },
            {
              name: "BEDDENG LAUD",
            },
            {
              name: "BONGTOLAN",
            },
            {
              name: "BULALA",
            },
            {
              name: "CABALANGEGAN",
            },
            {
              name: "CABAROAN DAYA",
            },
            {
              name: "CABAROAN LAUD",
            },
            {
              name: "CAMANGAAN",
            },
            {
              name: "CAPANGPANGAN",
            },
            {
              name: "MINDORO",
            },
            {
              name: "NAGSANGALAN",
            },
            {
              name: "PANTAY DAYA",
            },
            {
              name: "PANTAY FATIMA",
            },
            {
              name: "PANTAY LAUD",
            },
            {
              name: "PAOA",
            },
            {
              name: "PARATONG",
            },
            {
              name: "PONG-OL",
            },
            {
              name: "PUROK-A-BASSIT",
            },
            {
              name: "PUROK-A-DACKEL",
            },
            {
              name: "RAOIS",
            },
            {
              name: "RUGSUANAN",
            },
            {
              name: "SALINDEG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JULIAN NORTE",
            },
            {
              name: "SAN JULIAN SUR",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "TAMAG",
            },
            {
              name: "VIGAN CITY",
            },
          ],
        },
      },
    },
    ILOILO: {
      name: "ILOILO",
      region: "REGION 6",
      municipalities: {
        AJUY: {
          name: "AJUY",
          baranggays: [
            {
              name: "ADCADARAO",
            },
            {
              name: "AGBOBOLO",
            },
            {
              name: "AJUY",
            },
            {
              name: "BADIANGAN",
            },
            {
              name: "BARRIDO",
            },
            {
              name: "BATO BIASONG",
            },
            {
              name: "BAY-ANG",
            },
            {
              name: "BUCANA BUNGLAS",
            },
            {
              name: "CENTRAL",
            },
            {
              name: "CULASI",
            },
            {
              name: "LANJAGAN",
            },
            {
              name: "LUCA",
            },
            {
              name: "MALAYU-AN",
            },
            {
              name: "MANGOROCORO",
            },
            {
              name: "NASIDMAN",
            },
            {
              name: "PANTALAN NABAYE",
            },
            {
              name: "PANTALAN NAVARRO",
            },
            {
              name: "PEDADA",
            },
            {
              name: "PILI",
            },
            {
              name: "PINANTAN DIEL",
            },
            {
              name: "PINANTAN ELIZALDE",
            },
            {
              name: "PINAY ESPINOSA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PROGRESO",
            },
            {
              name: "PUENTE BUNGLAS",
            },
            {
              name: "PUNTA BURI",
            },
            {
              name: "ROJAS",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SILAGON",
            },
            {
              name: "TAGUBANHAN",
            },
            {
              name: "TAGUHANGIN",
            },
            {
              name: "TANDUYAN",
            },
            {
              name: "TIPACLA",
            },
            {
              name: "TUBOGAN",
            },
          ],
        },
        ALIMODIAN: {
          name: "ALIMODIAN",
          baranggays: [
            {
              name: "ABANG-ABANG",
            },
            {
              name: "AGSING",
            },
            {
              name: "ALIMODIAN",
            },
            {
              name: "ATABAY",
            },
            {
              name: "BA-ONG",
            },
            {
              name: "BAGSAKAN",
            },
            {
              name: "BAGUINGIN-LANOT",
            },
            {
              name: "BAGUMBAYAN-ILAJAS",
            },
            {
              name: "BALABAGO",
            },
            {
              name: "BAN-AG",
            },
            {
              name: "BANCAL",
            },
            {
              name: "BINALUD",
            },
            {
              name: "BUGANG",
            },
            {
              name: "BUHAY",
            },
            {
              name: "BULOD",
            },
            {
              name: "CABACANAN PROPER",
            },
            {
              name: "CABACANAN RIZAL",
            },
            {
              name: "CAGAY",
            },
            {
              name: "COLINE",
            },
            {
              name: "COLINE-DALAG",
            },
            {
              name: "CUNSAD",
            },
            {
              name: "CUYAD",
            },
            {
              name: "DALID",
            },
            {
              name: "DAO",
            },
            {
              name: "GINES",
            },
            {
              name: "GINOMOY",
            },
            {
              name: "INGWAN",
            },
            {
              name: "LAYLAYAN",
            },
            {
              name: "LICO",
            },
            {
              name: "LUAN-LUAN",
            },
            {
              name: "MALAMBOY-BONDOLAN",
            },
            {
              name: "MALAMHAY",
            },
            {
              name: "MAMBAWI",
            },
            {
              name: "MANASA",
            },
            {
              name: "MANDUYOG",
            },
            {
              name: "PAJO",
            },
            {
              name: "PIANDA-AN NORTE",
            },
            {
              name: "PIANDA-AN SUR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNONG",
            },
            {
              name: "QUINASPAN",
            },
            {
              name: "SINAMAY",
            },
            {
              name: "SULONG",
            },
            {
              name: "TABAN-MANGUINING",
            },
            {
              name: "TABUG",
            },
            {
              name: "TARUG",
            },
            {
              name: "TUGASLON",
            },
            {
              name: "UBODAN",
            },
            {
              name: "UGBO",
            },
            {
              name: "ULAY-BUGANG",
            },
            {
              name: "ULAY-HINABLAN",
            },
            {
              name: "UMINGAN",
            },
          ],
        },
        ANILAO: {
          name: "ANILAO",
          baranggays: [
            {
              name: "AGBATUAN",
            },
            {
              name: "ANILAO",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BALUNOS",
            },
            {
              name: "CAG-AN",
            },
            {
              name: "CAMIROS",
            },
            {
              name: "DANGULA-AN",
            },
            {
              name: "GUIPIS",
            },
            {
              name: "MANGANESE",
            },
            {
              name: "MEDINA",
            },
            {
              name: "MOSTRO",
            },
            {
              name: "PALAYPAY",
            },
            {
              name: "PANTALAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMBAG CULOB",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN JUAN CRISOSTOMO",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SERALLO",
            },
            {
              name: "VISTA ALEGRE",
            },
          ],
        },
        BADIANGAN: {
          name: "BADIANGAN",
          baranggays: [
            {
              name: "AGUSIPAN",
            },
            {
              name: "ASTORGA",
            },
            {
              name: "BADIANGAN",
            },
            {
              name: "BINGAUAN",
            },
            {
              name: "BITA-OYAN",
            },
            {
              name: "BOTONG",
            },
            {
              name: "BUDIAWE",
            },
            {
              name: "CABANGA-AN",
            },
            {
              name: "CABAYOGAN",
            },
            {
              name: "CALANSANAN",
            },
            {
              name: "CATUBIG",
            },
            {
              name: "GUINAWAHAN",
            },
            {
              name: "ILONGBUKID",
            },
            {
              name: "INDOROHAN",
            },
            {
              name: "INILIGAN",
            },
            {
              name: "LATAWAN",
            },
            {
              name: "LINAYUAN",
            },
            {
              name: "MAINGUIT",
            },
            {
              name: "MALUBLUB",
            },
            {
              name: "MANAOLAN",
            },
            {
              name: "MAPILI GRANDE",
            },
            {
              name: "MAPILI SANJO",
            },
            {
              name: "ODIONGAN",
            },
            {
              name: "POBLACION (BADIANGAN)",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SARIRI",
            },
            {
              name: "SIANON",
            },
            {
              name: "SINUAGAN",
            },
            {
              name: "TALABA",
            },
            {
              name: "TAMOCOL",
            },
            {
              name: "TENECLAN",
            },
            {
              name: "TINA",
            },
          ],
        },
        BALASAN: {
          name: "BALASAN",
          baranggays: [
            {
              name: "ARANJUEZ",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BALANTI-AN",
            },
            {
              name: "BALASAN",
            },
            {
              name: "BATUAN",
            },
            {
              name: "CABALIC",
            },
            {
              name: "CAMAMBUGAN",
            },
            {
              name: "DOLORES",
            },
            {
              name: "GIMAMANAY",
            },
            {
              name: "IPIL",
            },
            {
              name: "KINALKALAN",
            },
            {
              name: "LAWIS",
            },
            {
              name: "MALAPOC",
            },
            {
              name: "MAMHUT NORTE",
            },
            {
              name: "MAMHUT SUR",
            },
            {
              name: "MAYA",
            },
            {
              name: "PANI-AN",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "QUIASAN",
            },
            {
              name: "SALONG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "TINGUI-AN",
            },
            {
              name: "ZARAGOSA",
            },
          ],
        },
        BANATE: {
          name: "BANATE",
          baranggays: [
            {
              name: "ALACAYGAN",
            },
            {
              name: "BANATE",
            },
            {
              name: "BARIGA",
            },
            {
              name: "BELEN",
            },
            {
              name: "BOBON",
            },
            {
              name: "BULARAN",
            },
            {
              name: "CARMELO",
            },
            {
              name: "DE LA PAZ",
            },
            {
              name: "DUGWAKAN",
            },
            {
              name: "FUENTES*",
            },
            {
              name: "JUANICO",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAGDALO",
            },
            {
              name: "MANAGOPAYA",
            },
            {
              name: "MERCED",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "TALOKGANGAN",
            },
            {
              name: "ZONA SUR",
            },
          ],
        },
        "BAROTAC NUEVO": {
          name: "BAROTAC NUEVO",
          baranggays: [
            {
              name: "ACUIT",
            },
            {
              name: "AGCUYAWAN CALSADA",
            },
            {
              name: "AGCUYAWAN PULO",
            },
            {
              name: "BAGONGBONG",
            },
            {
              name: "BARAS",
            },
            {
              name: "BAROTAC NUEVO",
            },
            {
              name: "BUNGCA",
            },
            {
              name: "CABILAUAN",
            },
            {
              name: "CRUZ",
            },
            {
              name: "GUINTAS",
            },
            {
              name: "IGBONG",
            },
            {
              name: "ILAUD POBLACION",
            },
            {
              name: "ILAYA POBLACION",
            },
            {
              name: "JALAUD",
            },
            {
              name: "LAGUBANG",
            },
            {
              name: "LANAS",
            },
            {
              name: "LICO-AN",
            },
            {
              name: "LINAO",
            },
            {
              name: "MONPON",
            },
            {
              name: "PALACIAWAN",
            },
            {
              name: "PATAG",
            },
            {
              name: "SALIHID",
            },
            {
              name: "SO-OL",
            },
            {
              name: "SOHOTON",
            },
            {
              name: "TABUC-SUBA",
            },
            {
              name: "TABUCAN",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TINORIAN",
            },
            {
              name: "TIWI",
            },
            {
              name: "TUBUNGAN",
            },
          ],
        },
        "BAROTAC VIEJO": {
          name: "BAROTAC VIEJO",
          baranggays: [
            {
              name: "BAROTAC VIEJO",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "CALIFORNIA",
            },
            {
              name: "DE LA PEÑA",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "LA FORTUNA",
            },
            {
              name: "LIPATA",
            },
            {
              name: "NATIVIDAD",
            },
            {
              name: "NUEVA INVENCION",
            },
            {
              name: "NUEVA SEVILLA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUERTO PRINCESA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN GERONIMO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUCAS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "UGASAN",
            },
            {
              name: "VISTA ALEGRE",
            },
          ],
        },
        BATAD: {
          name: "BATAD",
          baranggays: [
            {
              name: "ALAPASCO",
            },
            {
              name: "ALINSOLONG",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BATAD",
            },
            {
              name: "BATAD VIEJO",
            },
            {
              name: "BINON-AN",
            },
            {
              name: "BOLHOG",
            },
            {
              name: "BULAK NORTE",
            },
            {
              name: "BULAK SUR",
            },
            {
              name: "CABAGOHAN",
            },
            {
              name: "CALANGAG",
            },
            {
              name: "CAW-I",
            },
            {
              name: "DRANCALAN",
            },
            {
              name: "EMBARCADERO",
            },
            {
              name: "HAMOD",
            },
            {
              name: "MALICO",
            },
            {
              name: "NANGKA",
            },
            {
              name: "PASAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUIAZAN FLORETE",
            },
            {
              name: "QUIAZAN LOPEZ",
            },
            {
              name: "SALONG",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "TANAO",
            },
            {
              name: "TAPI-AN",
            },
          ],
        },
        BINGAWAN: {
          name: "BINGAWAN",
          baranggays: [
            {
              name: "AGBA-O",
            },
            {
              name: "ALABIDHAN",
            },
            {
              name: "BINGAWAN",
            },
            {
              name: "BULABOG",
            },
            {
              name: "CAIROHAN",
            },
            {
              name: "GUINHULACAN",
            },
            {
              name: "INAMYUNGAN",
            },
            {
              name: "MALITBOG ILAWOD",
            },
            {
              name: "MALITBOG ILAYA",
            },
            {
              name: "NGINGI-AN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUINANGYANA",
            },
            {
              name: "QUINAR-UPAN",
            },
            {
              name: "TAPACON",
            },
            {
              name: "TUBOD",
            },
          ],
        },
        CABATUAN: {
          name: "CABATUAN",
          baranggays: [
            {
              name: "ACAO",
            },
            {
              name: "AMERANG",
            },
            {
              name: "AMURAO",
            },
            {
              name: "ANUANG",
            },
            {
              name: "AYAMAN",
            },
            {
              name: "AYONG",
            },
            {
              name: "BACAN",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BALUYAN",
            },
            {
              name: "BANGUIT",
            },
            {
              name: "BULAY",
            },
            {
              name: "CABATUAN",
            },
            {
              name: "CADOLDOLAN",
            },
            {
              name: "CAGBAN",
            },
            {
              name: "CALAWAGAN",
            },
            {
              name: "CALAYO",
            },
            {
              name: "DUYANDUYAN",
            },
            {
              name: "GAUB",
            },
            {
              name: "GINES INTERIOR",
            },
            {
              name: "GINES PATAG",
            },
            {
              name: "GUIBUANGAN TIGBAUAN",
            },
            {
              name: "INABASAN",
            },
            {
              name: "INACA",
            },
            {
              name: "INALADAN",
            },
            {
              name: "INGAS",
            },
            {
              name: "ITO NORTE",
            },
            {
              name: "ITO SUR",
            },
            {
              name: "JANIPAAN CENTRAL",
            },
            {
              name: "JANIPAAN ESTE",
            },
            {
              name: "JANIPAAN OESTE",
            },
            {
              name: "JANIPAAN OLO",
            },
            {
              name: "JELICUON LUSAYA",
            },
            {
              name: "JELICUON MONTINOLA",
            },
            {
              name: "LAG-AN",
            },
            {
              name: "LEONG",
            },
            {
              name: "LUTAC",
            },
            {
              name: "MANGUNA",
            },
            {
              name: "MARAGUIT",
            },
            {
              name: "MORUBUAN",
            },
            {
              name: "PACATIN",
            },
            {
              name: "PAGOTPOT",
            },
            {
              name: "PAMUL-OGAN",
            },
            {
              name: "PAMURINGAO GARRIDO",
            },
            {
              name: "PAMURINGAO PROPER",
            },
            {
              name: "PUNGTOD",
            },
            {
              name: "PUYAS",
            },
            {
              name: "SALACAY",
            },
            {
              name: "SULANGA",
            },
            {
              name: "TABUCAN",
            },
            {
              name: "TACDANGAN",
            },
            {
              name: "TALANGHAUAN",
            },
            {
              name: "TIGBAUAN ROAD",
            },
            {
              name: "TINIO-AN",
            },
            {
              name: "TIRING",
            },
            {
              name: "TUPOL CENTRAL",
            },
            {
              name: "TUPOL ESTE",
            },
            {
              name: "TUPOL OESTE",
            },
            {
              name: "TUY-AN",
            },
            {
              name: "ZONE I POB. (BARANGAY 1)",
            },
            {
              name: "ZONE II POB. (BARANGAY 2)",
            },
            {
              name: "ZONE III POB. (BARANGAY 3)",
            },
            {
              name: "ZONE IV POB. (BARANGAY 4)",
            },
            {
              name: "ZONE IX POB. (BARANGAY 9)",
            },
            {
              name: "ZONE V POB. (BARANGAY 5)",
            },
            {
              name: "ZONE VI POB. (BARANGAY 6 )",
            },
            {
              name: "ZONE VII POB. (BARANGAY 7)",
            },
            {
              name: "ZONE VIII POB. (BARANGAY 8)",
            },
            {
              name: "ZONE X POB. (BARANGAY 10)",
            },
            {
              name: "ZONE XI POB. (BARANGAY 11)",
            },
          ],
        },
        CALINOG: {
          name: "CALINOG",
          baranggays: [
            {
              name: "AGCALAGA",
            },
            {
              name: "AGLIBACAO",
            },
            {
              name: "AGLONOK",
            },
            {
              name: "ALIBUNAN",
            },
            {
              name: "BADLAN GRANDE",
            },
            {
              name: "BADLAN PEQUEÑO",
            },
            {
              name: "BADU",
            },
            {
              name: "BAJE SAN JULIAN",
            },
            {
              name: "BALATICON",
            },
            {
              name: "BANBAN GRANDE",
            },
            {
              name: "BANBAN PEQUEÑO",
            },
            {
              name: "BARRIO CALINOG",
            },
            {
              name: "BINOLOSAN GRANDE",
            },
            {
              name: "BINOLOSAN PEQUEÑO",
            },
            {
              name: "CABAGIAO",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAHIGON",
            },
            {
              name: "CALINOG",
            },
            {
              name: "CAMALONGO",
            },
            {
              name: "CANABAJAN",
            },
            {
              name: "CARATAGAN",
            },
            {
              name: "CARVASANA",
            },
            {
              name: "DALID",
            },
            {
              name: "DATAGAN",
            },
            {
              name: "GAMA GRANDE",
            },
            {
              name: "GAMA PEQUEÑO",
            },
            {
              name: "GARANGAN",
            },
            {
              name: "GUINBONYUGAN",
            },
            {
              name: "GUISO",
            },
            {
              name: "HILWAN",
            },
            {
              name: "IMPALIDAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "JAMIN-AY",
            },
            {
              name: "LAMPAYA",
            },
            {
              name: "LIBOT",
            },
            {
              name: "LONOY",
            },
            {
              name: "MALAG-IT",
            },
            {
              name: "MALAGUINABOT",
            },
            {
              name: "MALAPAWE",
            },
            {
              name: "MALITBOG CENTRO",
            },
            {
              name: "MAMBIRANAN",
            },
            {
              name: "MANARIPAY",
            },
            {
              name: "MARANDIG",
            },
            {
              name: "MASAROY",
            },
            {
              name: "MASPASAN",
            },
            {
              name: "NALBUGAN",
            },
            {
              name: "OWAK",
            },
            {
              name: "POBLACION CENTRO",
            },
            {
              name: "POBLACION DELGADO",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "POBLACION RIZAL ILAUD",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SIMSIMAN",
            },
            {
              name: "SUPANGA",
            },
            {
              name: "TABUCAN",
            },
            {
              name: "TAHING",
            },
            {
              name: "TIBIAO",
            },
            {
              name: "TIGBAYOG",
            },
            {
              name: "TOYUNGAN",
            },
            {
              name: "ULAYAN",
            },
          ],
        },
        CARLES: {
          name: "CARLES",
          baranggays: [
            {
              name: "ABONG",
            },
            {
              name: "ALIPATA",
            },
            {
              name: "ASLUMAN",
            },
            {
              name: "BANCAL",
            },
            {
              name: "BARANGCALAN",
            },
            {
              name: "BAROSBOS",
            },
            {
              name: "BINULUANGAN",
            },
            {
              name: "BITO-ON",
            },
            {
              name: "BOLO",
            },
            {
              name: "BUAYA",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABILAO GRANDE",
            },
            {
              name: "CABILAO PEQUEÑO",
            },
            {
              name: "CABUGUANA",
            },
            {
              name: "CARLES",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "DAYHAGAN",
            },
            {
              name: "GABI",
            },
            {
              name: "GRANADA",
            },
            {
              name: "GUINTICGAN",
            },
            {
              name: "ISLA DE CANA",
            },
            {
              name: "LANTANGAN",
            },
            {
              name: "MANLOT",
            },
            {
              name: "NALUMSAN",
            },
            {
              name: "PANTALAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNTA (BOLOCAWE)",
            },
            {
              name: "PUNTA BATUANAN",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "TABUGON",
            },
            {
              name: "TALINGTING",
            },
            {
              name: "TARONG",
            },
            {
              name: "TINIGBAN",
            },
            {
              name: "TUPAZ",
            },
          ],
        },
        "CITY OF PASSI": {
          name: "CITY OF PASSI",
          baranggays: [
            {
              name: "AGDAHON",
            },
            {
              name: "AGDAYAO",
            },
            {
              name: "AGLALANA",
            },
            {
              name: "AGTABO",
            },
            {
              name: "AGTAMBO",
            },
            {
              name: "ALIMONO",
            },
            {
              name: "ARAC",
            },
            {
              name: "AYUYAN",
            },
            {
              name: "BACURANAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BATU",
            },
            {
              name: "BAYAN",
            },
            {
              name: "BITAOGAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUYO",
            },
            {
              name: "CABUNGA",
            },
            {
              name: "CADILANG",
            },
            {
              name: "CAIROHAN",
            },
            {
              name: "CITY OF PASSI",
            },
            {
              name: "DALICANAN",
            },
            {
              name: "GEGACHAC",
            },
            {
              name: "GEMAT-Y",
            },
            {
              name: "GEMUMUA-AGAHON",
            },
            {
              name: "GINES VIEJO",
            },
            {
              name: "IMBANG GRANDE",
            },
            {
              name: "JAGUIMITAN",
            },
            {
              name: "LIBO-O",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MAGDUNGAO",
            },
            {
              name: "MALAG-IT GRANDE",
            },
            {
              name: "MALAG-IT PEQUEÑO",
            },
            {
              name: "MAMBIRANAN GRANDE",
            },
            {
              name: "MAMBIRANAN PEQUEÑO",
            },
            {
              name: "MAN-IT",
            },
            {
              name: "MANTULANG",
            },
            {
              name: "MULAPULA",
            },
            {
              name: "NUEVA UNION",
            },
            {
              name: "PAGAYPAY",
            },
            {
              name: "PANGI",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "PUNONG",
            },
            {
              name: "QUINAGARINGAN GRANDE",
            },
            {
              name: "QUINAGARINGAN PEQUEÑO",
            },
            {
              name: "SABLOGON",
            },
            {
              name: "SALNGAN",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SARAPAN",
            },
            {
              name: "TAGUBONG",
            },
            {
              name: "TALONGONAN",
            },
            {
              name: "TUBOD",
            },
            {
              name: "TUBURAN",
            },
          ],
        },
        CONCEPCION: {
          name: "CONCEPCION",
          baranggays: [
            {
              name: "AGLOSONG",
            },
            {
              name: "AGNAGA",
            },
            {
              name: "BACJAWAN NORTE",
            },
            {
              name: "BACJAWAN SUR",
            },
            {
              name: "BAGONGON",
            },
            {
              name: "BATITI",
            },
            {
              name: "BOTLOG",
            },
            {
              name: "CALAMIGAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DUNGON",
            },
            {
              name: "IGBON",
            },
            {
              name: "JAMUL-AWON",
            },
            {
              name: "LO-ONG",
            },
            {
              name: "MACALBANG",
            },
            {
              name: "MACATUNAO",
            },
            {
              name: "MALANGABANG",
            },
            {
              name: "MALIOGLIOG",
            },
            {
              name: "NIÑO",
            },
            {
              name: "NIPA",
            },
            {
              name: "PLANDICO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLOPINA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "TALOTU-AN",
            },
            {
              name: "TAMBALIZA",
            },
            {
              name: "TAMIS-AC",
            },
          ],
        },
        DINGLE: {
          name: "DINGLE",
          baranggays: [
            {
              name: "ABANGAY",
            },
            {
              name: "AGSALANAN",
            },
            {
              name: "AGTATACAY",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "BONGLOY",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAGUYUMAN",
            },
            {
              name: "CALICUANG",
            },
            {
              name: "CAMAMBUGAN",
            },
            {
              name: "DAWIS",
            },
            {
              name: "DINGLE",
            },
            {
              name: "GINALINAN NUEVO",
            },
            {
              name: "GINALINAN VIEJO",
            },
            {
              name: "GUTAO",
            },
            {
              name: "ILAJAS",
            },
            {
              name: "LIBO-O",
            },
            {
              name: "LICU-AN",
            },
            {
              name: "LINCUD",
            },
            {
              name: "MATANGHARON",
            },
            {
              name: "MOROBORO",
            },
            {
              name: "NAMATAY",
            },
            {
              name: "NAZUNI",
            },
            {
              name: "PANDAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POTOLAN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MATIAS",
            },
            {
              name: "SINIBA-AN",
            },
            {
              name: "TABUGON",
            },
            {
              name: "TAMBUNAC",
            },
            {
              name: "TANGHAWAN",
            },
            {
              name: "TIGUIB",
            },
            {
              name: "TINOCUAN",
            },
            {
              name: "TULATULA-AN",
            },
          ],
        },
        DUEÑAS: {
          name: "DUEÑAS",
          baranggays: [
            {
              name: "AGUTAYAN",
            },
            {
              name: "ANGARE",
            },
            {
              name: "ANJAWAN",
            },
            {
              name: "BAAC",
            },
            {
              name: "BAGONGBONG",
            },
            {
              name: "BALANGIGAN",
            },
            {
              name: "BALINGASAG",
            },
            {
              name: "BANUGAN",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BITA",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUGTONGAN",
            },
            {
              name: "CABUDIAN",
            },
            {
              name: "CALACA-AN",
            },
            {
              name: "CALANG",
            },
            {
              name: "CALAWINAN",
            },
            {
              name: "CAPAYCAPAY",
            },
            {
              name: "CAPULING",
            },
            {
              name: "CATIG",
            },
            {
              name: "DILA-AN",
            },
            {
              name: "DUEÑAS",
            },
            {
              name: "FUNDACION",
            },
            {
              name: "INADLAWAN",
            },
            {
              name: "JAGDONG",
            },
            {
              name: "JAGUIMIT",
            },
            {
              name: "LACADON",
            },
            {
              name: "LUAG",
            },
            {
              name: "MALUSGOD",
            },
            {
              name: "MARIBUYONG",
            },
            {
              name: "MINANGA",
            },
            {
              name: "MONPON",
            },
            {
              name: "NAVALAS",
            },
            {
              name: "PADER",
            },
            {
              name: "PANDAN",
            },
            {
              name: "POBLACION A",
            },
            {
              name: "POBLACION B",
            },
            {
              name: "POBLACION C",
            },
            {
              name: "POBLACION D",
            },
            {
              name: "PONONG GRANDE",
            },
            {
              name: "PONONG PEQUEÑO",
            },
            {
              name: "PUROG",
            },
            {
              name: "ROMBLON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAWE",
            },
            {
              name: "TAMINLA",
            },
            {
              name: "TINOCUAN",
            },
            {
              name: "TIPOLO",
            },
          ],
        },
        DUMANGAS: {
          name: "DUMANGAS",
          baranggays: [
            {
              name: "AURORA-DEL PILAR (POB.)",
            },
            {
              name: "BACAY",
            },
            {
              name: "BACONG",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BALUD",
            },
            {
              name: "BANTUD",
            },
            {
              name: "BANTUD FABRICA",
            },
            {
              name: "BARAS",
            },
            {
              name: "BARASAN",
            },
            {
              name: "BASA-MABINI BONIFACIO (POB.)",
            },
            {
              name: "BOLILAO",
            },
            {
              name: "BUENAFLOR EMBARKADERO (POB.)",
            },
            {
              name: "BURGOS-REGIDOR (POB.)",
            },
            {
              name: "CALAO",
            },
            {
              name: "CALI",
            },
            {
              name: "CANSILAYAN",
            },
            {
              name: "CAPALIZ",
            },
            {
              name: "CAYOS",
            },
            {
              name: "COMPAYAN",
            },
            {
              name: "DACUTAN",
            },
            {
              name: "DUMANGAS",
            },
            {
              name: "ERMITA",
            },
            {
              name: "ILAYA 1ST",
            },
            {
              name: "ILAYA 2ND",
            },
            {
              name: "ILAYA 3RD",
            },
            {
              name: "JARDIN",
            },
            {
              name: "LACTURAN",
            },
            {
              name: "LOPEZ JAENA - RIZAL (POB.)",
            },
            {
              name: "MANAGUIT",
            },
            {
              name: "MAQUINA",
            },
            {
              name: "NANDING LOPEZ",
            },
            {
              name: "PAGDUGUE",
            },
            {
              name: "PALOC BIGQUE",
            },
            {
              name: "PALOC SOOL",
            },
            {
              name: "PATLAD",
            },
            {
              name: "PD MONFORT NORTH (LUBLUB)",
            },
            {
              name: "PD MONFORT SOUTH (GUINSAMPANAN)",
            },
            {
              name: "PULAO",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAPAO",
            },
            {
              name: "SULANGAN",
            },
            {
              name: "TABUCAN",
            },
            {
              name: "TALUSAN",
            },
            {
              name: "TAMBOBO",
            },
            {
              name: "TAMBOILAN",
            },
            {
              name: "VICTORIAS",
            },
          ],
        },
        ESTANCIA: {
          name: "ESTANCIA",
          baranggays: [
            {
              name: "BAYAS (BAYAS ISLAND)",
            },
            {
              name: "BAYUYAN",
            },
            {
              name: "BOTONGON",
            },
            {
              name: "BULAQUEÑA",
            },
            {
              name: "CALAPDAN",
            },
            {
              name: "CANO-AN",
            },
            {
              name: "DAAN BANUA",
            },
            {
              name: "DACULAN",
            },
            {
              name: "ESTANCIA",
            },
            {
              name: "GOGO",
            },
            {
              name: "JOLOG",
            },
            {
              name: "LOGUINGOT (LOGUINGOT ISLAND)",
            },
            {
              name: "LONOY (ROMAN MOSQUEDA)",
            },
            {
              name: "LUMBIA (ANA CUENCA)",
            },
            {
              name: "MALBOG",
            },
            {
              name: "MANIPULON",
            },
            {
              name: "PA-ON",
            },
            {
              name: "POBLACION ZONE 1",
            },
            {
              name: "POBLACION ZONE II",
            },
            {
              name: "POBLACION ZONE III",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "TABU-AN",
            },
            {
              name: "TACBUYAN",
            },
            {
              name: "TANZA",
            },
            {
              name: "VILLA PANI-AN",
            },
          ],
        },
        GUIMBAL: {
          name: "GUIMBAL",
          baranggays: [
            {
              name: "ANONO-O",
            },
            {
              name: "BACONG",
            },
            {
              name: "BAGUMBAYAN POB. (BAGUMBAYAN-GRANADA)",
            },
            {
              name: "BALANTAD-CARLOS FRUTO (POB.)",
            },
            {
              name: "BARAS",
            },
            {
              name: "BINANUA-AN",
            },
            {
              name: "BONGOL SAN MIGUEL",
            },
            {
              name: "BONGOL SAN VICENTE",
            },
            {
              name: "BULAD",
            },
            {
              name: "BULUANGAN",
            },
            {
              name: "BURGOS-GENGOS (POB.)",
            },
            {
              name: "CABASI",
            },
            {
              name: "CABUBUGAN",
            },
            {
              name: "CALAMPITAO",
            },
            {
              name: "CAMANGAHAN",
            },
            {
              name: "GENEROSA-CRISTOBAL COLON (POB.)",
            },
            {
              name: "GERONA-GIMENO (POB.)",
            },
            {
              name: "GIRADO-MAGSAYSAY (POB.)",
            },
            {
              name: "GOTERA (POB.)",
            },
            {
              name: "GUIMBAL",
            },
            {
              name: "IGCOCOLO",
            },
            {
              name: "IYASAN",
            },
            {
              name: "LIBO-ON GONZALES (POB.)",
            },
            {
              name: "LUBACAN",
            },
            {
              name: "NAHAPAY",
            },
            {
              name: "NALUNDAN",
            },
            {
              name: "NANGA",
            },
            {
              name: "NITO-AN LUPSAG",
            },
            {
              name: "PARTICION",
            },
            {
              name: "PESCADORES (POB.)",
            },
            {
              name: "RIZAL-TUGUISAN (POB.)",
            },
            {
              name: "SANTA ROSA-LAGUNA",
            },
            {
              name: "SIPITAN-BADIANG",
            },
            {
              name: "TORREBLANCA-BLUMENTRITT (POB.)",
            },
          ],
        },
        IGBARAS: {
          name: "IGBARAS",
          baranggays: [
            {
              name: "ALAMEDA",
            },
            {
              name: "AMOROGTONG",
            },
            {
              name: "ANILAWAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAGACAYAN",
            },
            {
              name: "BAGAY",
            },
            {
              name: "BALIBAGAN",
            },
            {
              name: "BARANGAY 1 POBLACION",
            },
            {
              name: "BARANGAY 2 POBLACION",
            },
            {
              name: "BARANGAY 3 POBLACION",
            },
            {
              name: "BARANGAY 4 POBLACION",
            },
            {
              name: "BARANGAY 5 POBLACION",
            },
            {
              name: "BARANGAY 6 POBLACION",
            },
            {
              name: "BARASAN",
            },
            {
              name: "BINANUA-AN",
            },
            {
              name: "BOCLOD",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUGA",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "CALAMPITAO",
            },
            {
              name: "CALE",
            },
            {
              name: "CATIRINGAN",
            },
            {
              name: "CORUCUAN",
            },
            {
              name: "IGBARAS",
            },
            {
              name: "IGCABUGAO",
            },
            {
              name: "IGPIGUS",
            },
            {
              name: "IGTALONGON",
            },
            {
              name: "INDALUYON",
            },
            {
              name: "JOVELLAR",
            },
            {
              name: "KINAGDAN",
            },
            {
              name: "LAB-ON",
            },
            {
              name: "LACAY DOL-DOL",
            },
            {
              name: "LUMANGAN",
            },
            {
              name: "LUTUNGAN",
            },
            {
              name: "MANTANGON",
            },
            {
              name: "MULANGAN",
            },
            {
              name: "PASONG",
            },
            {
              name: "PASSI",
            },
            {
              name: "PINAOPAWAN",
            },
            {
              name: "RIRO-AN",
            },
            {
              name: "SAN AMBROSIO",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SIGNE",
            },
            {
              name: "TABIAC",
            },
            {
              name: "TALAYATAY",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TIGBANABA",
            },
          ],
        },
        "ILOILO CITY": {
          name: "ILOILO CITY",
          baranggays: [
            {
              name: "ABETO MIRASOL TAFT SOUTH (QUIRINO ABETO)",
            },
            {
              name: "AGUINALDO",
            },
            {
              name: "AIRPORT (TABUCAN AIRPORT)",
            },
            {
              name: "ALALASAN LAPUZ",
            },
            {
              name: "ARGUELLES",
            },
            {
              name: "ARSENAL ADUANA",
            },
            {
              name: "BAKHAW",
            },
            {
              name: "BALABAGO",
            },
            {
              name: "BALANTANG",
            },
            {
              name: "BALDOZA",
            },
            {
              name: "BANTUD",
            },
            {
              name: "BANUYAO",
            },
            {
              name: "BAYBAY TANZA",
            },
            {
              name: "BENEDICTO (JARO)",
            },
            {
              name: "BITO-ON",
            },
            {
              name: "BOLILAO",
            },
            {
              name: "BONIFACIO (AREVALO)",
            },
            {
              name: "BONIFACIO TANZA",
            },
            {
              name: "BUHANG",
            },
            {
              name: "BUHANG TAFT NORTH",
            },
            {
              name: "BUNTATALA",
            },
            {
              name: "BURGOS-MABINI-PLAZA",
            },
            {
              name: "CAINGIN",
            },
            {
              name: "CALAHUNAN",
            },
            {
              name: "CALAPARAN",
            },
            {
              name: "CALUBIHAN",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CAMALIG",
            },
            {
              name: "COCHERO",
            },
            {
              name: "COMPANIA",
            },
            {
              name: "CONCEPCION-MONTES",
            },
            {
              name: "CUARTERO",
            },
            {
              name: "CUBAY",
            },
            {
              name: "DANAO",
            },
            {
              name: "DELGADO-JALANDONI-BAGUMBAYAN",
            },
            {
              name: "DEMOCRACIA",
            },
            {
              name: "DESAMPARADOS",
            },
            {
              name: "DIVINAGRACIA",
            },
            {
              name: "DON ESTEBAN-LAPUZ",
            },
            {
              name: "DULONAN",
            },
            {
              name: "DUNGON",
            },
            {
              name: "DUNGON A",
            },
            {
              name: "DUNGON B",
            },
            {
              name: "EAST BALUARTE",
            },
            {
              name: "EAST TIMAWA",
            },
            {
              name: "EDGANZON",
            },
            {
              name: "EL 98 CASTILLA (CLAUDIO LOPEZ)",
            },
            {
              name: "FAJARDO",
            },
            {
              name: "FLORES",
            },
            {
              name: "GENERAL HUGHES-MONTES",
            },
            {
              name: "GLORIA",
            },
            {
              name: "GUSTILO",
            },
            {
              name: "GUZMAN-JESENA",
            },
            {
              name: "HABOG-HABOG SALVACION",
            },
            {
              name: "HIBAO-AN NORTE",
            },
            {
              name: "HIBAO-AN SUR",
            },
            {
              name: "HINACTACAN",
            },
            {
              name: "HIPODROMO",
            },
            {
              name: "ILOILO CITY",
            },
            {
              name: "INDAY",
            },
            {
              name: "INFANTE",
            },
            {
              name: "INGORE",
            },
            {
              name: "JALANDONI ESTATE-LAPUZ",
            },
            {
              name: "JALANDONI-WILSON",
            },
            {
              name: "JAVELLANA",
            },
            {
              name: "JEREOS",
            },
            {
              name: "KAHIRUPAN",
            },
            {
              name: "KASINGKASING",
            },
            {
              name: "KATILINGBAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LAGUDA",
            },
            {
              name: "LANIT",
            },
            {
              name: "LAPUZ NORTE",
            },
            {
              name: "LAPUZ SUR",
            },
            {
              name: "LEGASPI DELA RAMA",
            },
            {
              name: "LIBERATION",
            },
            {
              name: "LIBERTAD, SANTA ISABEL",
            },
            {
              name: "LIBERTAD-LAPUZ",
            },
            {
              name: "LOBOC-LAPUZ",
            },
            {
              name: "LOPEZ JAENA (JARO)",
            },
            {
              name: "LOPEZ JAENA NORTE",
            },
            {
              name: "LOPEZ JAENA SUR",
            },
            {
              name: "LUNA (JARO)",
            },
            {
              name: "LUNA (LA PAZ)",
            },
            {
              name: "M. V. HECHANOVA",
            },
            {
              name: "MABOLO-DELGADO",
            },
            {
              name: "MACARTHUR",
            },
            {
              name: "MAGDALO",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAGSAYSAY VILLAGE",
            },
            {
              name: "MALIPAYON-DELGADO",
            },
            {
              name: "MANSAYA-LAPUZ",
            },
            {
              name: "MARCELO H. DEL PILAR",
            },
            {
              name: "MARIA CLARA",
            },
            {
              name: "MARIA CRISTINA",
            },
            {
              name: "MOHON",
            },
            {
              name: "MOLO BOULEVARD",
            },
            {
              name: "MONICA BLUMENTRITT",
            },
            {
              name: "MONTINOLA",
            },
            {
              name: "MUELLE LONEY-MONTES",
            },
            {
              name: "NABITASAN",
            },
            {
              name: "NAVAIS",
            },
            {
              name: "NONOY",
            },
            {
              name: "NORTH AVANCEÑA",
            },
            {
              name: "NORTH BALUARTE",
            },
            {
              name: "NORTH FUNDIDOR",
            },
            {
              name: "NORTH SAN JOSE",
            },
            {
              name: "OBRERO-LAPUZ",
            },
            {
              name: "OÑATE DE LEON",
            },
            {
              name: "ORTIZ",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "OUR LADY OF FATIMA",
            },
            {
              name: "OUR LADY OF LOURDES",
            },
            {
              name: "PALE BENEDICTO RIZAL (MANDURRIAO)",
            },
            {
              name: "PHHC BLOCK 17",
            },
            {
              name: "PHHC BLOCK 22 NHA",
            },
            {
              name: "POBLACION MOLO",
            },
            {
              name: "PRESIDENT ROXAS",
            },
            {
              name: "PROGRESO-LAPUZ",
            },
            {
              name: "PUNONG-LAPUZ",
            },
            {
              name: "QUEZON",
            },
            {
              name: "QUINTIN SALAS",
            },
            {
              name: "RAILWAY",
            },
            {
              name: "RIMA-RIZAL",
            },
            {
              name: "RIZAL (LA PAZ)",
            },
            {
              name: "RIZAL ESTANZUELA",
            },
            {
              name: "RIZAL IBARRA",
            },
            {
              name: "RIZAL PALAPALA I",
            },
            {
              name: "RIZAL PALAPALA II",
            },
            {
              name: "ROXAS VILLAGE",
            },
            {
              name: "SAMBAG",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FELIX",
            },
            {
              name: "SAN ISIDRO (JARO)",
            },
            {
              name: "SAN ISIDRO (LA PAZ)",
            },
            {
              name: "SAN JOSE (AREVALO)",
            },
            {
              name: "SAN JOSE (CITY PROPER)",
            },
            {
              name: "SAN JOSE (JARO)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO (JARO)",
            },
            {
              name: "SAN PEDRO (MOLO)",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO NORTE",
            },
            {
              name: "SANTO NIÑO SUR",
            },
            {
              name: "SANTO ROSARIO-DURAN",
            },
            {
              name: "SEMINARIO (BURGOS JALANDONI)",
            },
            {
              name: "SIMON LEDESMA",
            },
            {
              name: "SINIKWAY (BANGKEROHAN LAPUZ)",
            },
            {
              name: "SO-OC",
            },
            {
              name: "SOUTH BALUARTE",
            },
            {
              name: "SOUTH FUNDIDOR",
            },
            {
              name: "SOUTH SAN JOSE",
            },
            {
              name: "TAAL",
            },
            {
              name: "TABUC SUBA (JARO)",
            },
            {
              name: "TABUC SUBA (LA PAZ)",
            },
            {
              name: "TABUCAN",
            },
            {
              name: "TACAS",
            },
            {
              name: "TAGBAC",
            },
            {
              name: "TANZA-ESPERANZA",
            },
            {
              name: "TAP-OC",
            },
            {
              name: "TAYTAY ZONE II",
            },
            {
              name: "TICUD (LA PAZ)",
            },
            {
              name: "TIMAWA TANZA I",
            },
            {
              name: "TIMAWA TANZA II",
            },
            {
              name: "UNGKA",
            },
            {
              name: "VETERANS VILLAGE",
            },
            {
              name: "VILLA ANITA",
            },
            {
              name: "WEST HABOG-HABOG",
            },
            {
              name: "WEST TIMAWA",
            },
            {
              name: "YULO DRIVE",
            },
            {
              name: "YULO-ARROYO",
            },
            {
              name: "ZAMORA-MELLIZA",
            },
          ],
        },
        JANIUAY: {
          name: "JANIUAY",
          baranggays: [
            {
              name: "ABANGAY",
            },
            {
              name: "AGCAROPE",
            },
            {
              name: "AGLOBONG",
            },
            {
              name: "AGUINGAY",
            },
            {
              name: "ANHAWAN",
            },
            {
              name: "AQUINO NOBLEZA EAST (POB.)",
            },
            {
              name: "AQUINO NOBLEZA WEST (POB.)",
            },
            {
              name: "ATIMONAN",
            },
            {
              name: "BALANAC",
            },
            {
              name: "BARASALON",
            },
            {
              name: "BONGOL",
            },
            {
              name: "CABANTOG",
            },
            {
              name: "CALMAY",
            },
            {
              name: "CANAWILI",
            },
            {
              name: "CANAWILLIAN",
            },
            {
              name: "CAPT. A. TIRADOR (POB.)",
            },
            {
              name: "CARANAS",
            },
            {
              name: "CARAUDAN",
            },
            {
              name: "CARIGANGAN",
            },
            {
              name: "CONCEPCION POB. (D.G. ABORDO)",
            },
            {
              name: "CRISPIN SALAZAR NORTH (POB.)",
            },
            {
              name: "CRISPIN SALAZAR SOUTH (POB.)",
            },
            {
              name: "CUNSAD",
            },
            {
              name: "DABONG",
            },
            {
              name: "DAMIRES",
            },
            {
              name: "DAMO-ONG",
            },
            {
              name: "DANAO",
            },
            {
              name: "DON T. LUTERO CENTER (POB.)",
            },
            {
              name: "DON T. LUTERO EAST (POB.)",
            },
            {
              name: "DON T. LUTERO WEST POB. (DON T. LUTERO NORTH)",
            },
            {
              name: "GINES",
            },
            {
              name: "GOLGOTA (POB.)",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "JANIUAY",
            },
            {
              name: "JIBOLO",
            },
            {
              name: "KUYOT",
            },
            {
              name: "LOCSIN (POB.)",
            },
            {
              name: "MADONG",
            },
            {
              name: "MANACABAC",
            },
            {
              name: "MANGIL",
            },
            {
              name: "MATAG-UB",
            },
            {
              name: "MONTE-MAGAPA",
            },
            {
              name: "PANGILIHAN",
            },
            {
              name: "PANURAN",
            },
            {
              name: "PARARINGA",
            },
            {
              name: "PATONG-PATONG",
            },
            {
              name: "QUIPOT",
            },
            {
              name: "R. ARMADA (POB.)",
            },
            {
              name: "S. M. VILLA (POB.)",
            },
            {
              name: "SAN JULIAN (POB.)",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SANTA RITA (POB.)",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SARAWAG",
            },
            {
              name: "TAMBAL",
            },
            {
              name: "TAMU-AN",
            },
            {
              name: "TIRINGANAN",
            },
            {
              name: "TOLARUCAN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "UBIAN",
            },
            {
              name: "YABON",
            },
          ],
        },
        LAMBUNAO: {
          name: "LAMBUNAO",
          baranggays: [
            {
              name: "AGSIRAB",
            },
            {
              name: "AGTUMAN",
            },
            {
              name: "ALUGMAWA",
            },
            {
              name: "BADIANGAN",
            },
            {
              name: "BALAGIAO",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BANSAG",
            },
            {
              name: "BAYUCO",
            },
            {
              name: "BINABA-AN ARMADA",
            },
            {
              name: "BINABA-AN LABAYNO",
            },
            {
              name: "BINABA-AN LIMOSO",
            },
            {
              name: "BINABA-AN PORTIGO",
            },
            {
              name: "BINABA-AN TIRADOR",
            },
            {
              name: "BOGONGBONG",
            },
            {
              name: "BONBON",
            },
            {
              name: "BONTOC",
            },
            {
              name: "BURI",
            },
            {
              name: "BURIRAO",
            },
            {
              name: "BUWANG",
            },
            {
              name: "CABATANGAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CABUNLAWAN",
            },
            {
              name: "CAGUISANAN",
            },
            {
              name: "CALOY-AHAN",
            },
            {
              name: "CANINGUAN",
            },
            {
              name: "CAPANGYAN",
            },
            {
              name: "CAYAN ESTE",
            },
            {
              name: "CAYAN OESTE",
            },
            {
              name: "COROT-ON",
            },
            {
              name: "COTO",
            },
            {
              name: "CUBAY",
            },
            {
              name: "CUNARUM",
            },
            {
              name: "DAANBANWA",
            },
            {
              name: "GINES",
            },
            {
              name: "HIPGOS",
            },
            {
              name: "JAYUBO",
            },
            {
              name: "JOROG",
            },
            {
              name: "LAMBUNAO",
            },
            {
              name: "LANOT GRANDE",
            },
            {
              name: "LANOT PEQUEÑO",
            },
            {
              name: "LEGAYADA",
            },
            {
              name: "LUMANAY",
            },
            {
              name: "MADARAG",
            },
            {
              name: "MAGBATO",
            },
            {
              name: "MAITE GRANDE",
            },
            {
              name: "MAITE PEQUEÑO",
            },
            {
              name: "MALAG-IT",
            },
            {
              name: "MANAULAN",
            },
            {
              name: "MARIBONG",
            },
            {
              name: "MARONG",
            },
            {
              name: "MISI",
            },
            {
              name: "NATIVIDAD",
            },
            {
              name: "PAJO",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PANURAN",
            },
            {
              name: "PASIG",
            },
            {
              name: "PATAG",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "POONG",
            },
            {
              name: "PUGHANAN",
            },
            {
              name: "PUNGSOD",
            },
            {
              name: "QUILING",
            },
            {
              name: "SAGCUP",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SIBACUNGAN",
            },
            {
              name: "SIBAGUAN",
            },
            {
              name: "SIMSIMAN",
            },
            {
              name: "SUPOC",
            },
            {
              name: "TAMPUCAO",
            },
            {
              name: "TRANGHAWAN",
            },
            {
              name: "TUBUNGAN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "WALANG",
            },
          ],
        },
        LEGANES: {
          name: "LEGANES",
          baranggays: [
            {
              name: "BIGKE",
            },
            {
              name: "BUNTATALA",
            },
            {
              name: "CAGAMUTAN NORTE",
            },
            {
              name: "CAGAMUTAN SUR",
            },
            {
              name: "CALABOA",
            },
            {
              name: "CAMANGAY",
            },
            {
              name: "CARI MAYOR",
            },
            {
              name: "CARI MINOR",
            },
            {
              name: "GUA-AN",
            },
            {
              name: "GUIHAMAN",
            },
            {
              name: "GUINOBATAN",
            },
            {
              name: "GUINTAS",
            },
            {
              name: "LAPAYON",
            },
            {
              name: "LEGANES",
            },
            {
              name: "M.V. HECHANOVA (BALAGON)",
            },
            {
              name: "NABITASAN",
            },
            {
              name: "NAPNUD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN VICENTE",
            },
          ],
        },
        LEMERY: {
          name: "LEMERY",
          baranggays: [
            {
              name: "AGPIPILI",
            },
            {
              name: "ALCANTARA",
            },
            {
              name: "ALMEÑANA",
            },
            {
              name: "ANABO",
            },
            {
              name: "BANKAL",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABANTOHAN",
            },
            {
              name: "CAPIÑAHAN",
            },
            {
              name: "DALIPE",
            },
            {
              name: "DAPDAPAN",
            },
            {
              name: "GERONGAN",
            },
            {
              name: "IMBAULAN",
            },
            {
              name: "LAYOGBATO",
            },
            {
              name: "LEMERY",
            },
            {
              name: "MARAPAL",
            },
            {
              name: "MILAN",
            },
            {
              name: "NAGSULANG",
            },
            {
              name: "NASAPAHAN",
            },
            {
              name: "OMIO",
            },
            {
              name: "PACUAN",
            },
            {
              name: "POBLACION NW ZONE",
            },
            {
              name: "POBLACION SE ZONE",
            },
            {
              name: "PONTOC",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN DIEGO",
            },
            {
              name: "SAN JOSE MOTO",
            },
            {
              name: "SEPANTON",
            },
            {
              name: "SINCUA",
            },
            {
              name: "TABUNAN",
            },
            {
              name: "TUGAS",
            },
            {
              name: "VELASCO",
            },
            {
              name: "YAWYAWAN",
            },
          ],
        },
        LEON: {
          name: "LEON",
          baranggays: [
            {
              name: "AGBOY NORTE",
            },
            {
              name: "AGBOY SUR",
            },
            {
              name: "AGTA",
            },
            {
              name: "AMBULONG",
            },
            {
              name: "ANONANG",
            },
            {
              name: "APIAN",
            },
            {
              name: "AVANZADA",
            },
            {
              name: "AWIS",
            },
            {
              name: "AYABANG",
            },
            {
              name: "AYUBO",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BAJE",
            },
            {
              name: "BANAGAN",
            },
            {
              name: "BARANGBANG",
            },
            {
              name: "BARASAN",
            },
            {
              name: "BAYAG NORTE",
            },
            {
              name: "BAYAG SUR",
            },
            {
              name: "BINOLBOG",
            },
            {
              name: "BIRI NORTE",
            },
            {
              name: "BIRI SUR",
            },
            {
              name: "BOBON",
            },
            {
              name: "BUCARI",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUGA",
            },
            {
              name: "BULAD",
            },
            {
              name: "BULWANG",
            },
            {
              name: "CABOLO-AN",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CABUTONGAN",
            },
            {
              name: "CAGAY",
            },
            {
              name: "CAMANDAG",
            },
            {
              name: "CAMANDO",
            },
            {
              name: "CANANAMAN",
            },
            {
              name: "CAPT. FERNANDO",
            },
            {
              name: "CARARA-AN",
            },
            {
              name: "CAROLINA",
            },
            {
              name: "CAWILIHAN",
            },
            {
              name: "COYUGAN NORTE",
            },
            {
              name: "COYUGAN SUR",
            },
            {
              name: "DANAO",
            },
            {
              name: "DOROG",
            },
            {
              name: "DUSACAN",
            },
            {
              name: "GINES",
            },
            {
              name: "GUMBOC",
            },
            {
              name: "IGCADIOS",
            },
            {
              name: "INGAY",
            },
            {
              name: "ISIAN NORTE",
            },
            {
              name: "ISIAN VICTORIA",
            },
            {
              name: "JAMOG GINES",
            },
            {
              name: "LAMPAYA",
            },
            {
              name: "LANAG",
            },
            {
              name: "LANG-OG",
            },
            {
              name: "LEON",
            },
            {
              name: "LIGTOS",
            },
            {
              name: "LONOC",
            },
            {
              name: "MAGCAPAY",
            },
            {
              name: "MALIAO",
            },
            {
              name: "MALUBLUB",
            },
            {
              name: "MANAMPUNAY",
            },
            {
              name: "MARIRONG",
            },
            {
              name: "MINA",
            },
            {
              name: "MOCOL",
            },
            {
              name: "NAGBANGI",
            },
            {
              name: "NALBANG",
            },
            {
              name: "ODONG-ODONG",
            },
            {
              name: "OLUANGAN",
            },
            {
              name: "OMAMBONG",
            },
            {
              name: "PAGA",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PANGINMAN",
            },
            {
              name: "PAOY",
            },
            {
              name: "PEPE",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALNGAN",
            },
            {
              name: "SAMLAGUE",
            },
            {
              name: "SIOL NORTE",
            },
            {
              name: "SIOL SUR",
            },
            {
              name: "TACUYONG NORTE",
            },
            {
              name: "TACUYONG SUR",
            },
            {
              name: "TAGSING",
            },
            {
              name: "TALACUAN",
            },
            {
              name: "TICUAN",
            },
            {
              name: "TINA-AN NORTE",
            },
            {
              name: "TINA-AN SUR",
            },
            {
              name: "TU-OG",
            },
            {
              name: "TUNGUAN",
            },
          ],
        },
        MAASIN: {
          name: "MAASIN",
          baranggays: [
            {
              name: "ABAY",
            },
            {
              name: "ABILAY",
            },
            {
              name: "AGROCEL POB. (AGUINALDO-ROXAS--CELSO MAYOR)",
            },
            {
              name: "AMERANG",
            },
            {
              name: "BAGACAY EAST",
            },
            {
              name: "BAGACAY WEST",
            },
            {
              name: "BOLO",
            },
            {
              name: "BUG-OT",
            },
            {
              name: "BULAY",
            },
            {
              name: "BUNTALAN",
            },
            {
              name: "BURAK",
            },
            {
              name: "CABANGCALAN",
            },
            {
              name: "CABATAC",
            },
            {
              name: "CAIGON",
            },
            {
              name: "CANANGHAN",
            },
            {
              name: "CANAWILI",
            },
            {
              name: "DAGAMI",
            },
            {
              name: "DAJA",
            },
            {
              name: "DALUSAN",
            },
            {
              name: "DELCAR POB. (DELGADO-CARTAGENA)",
            },
            {
              name: "INABASAN",
            },
            {
              name: "LAYOG",
            },
            {
              name: "LINAB",
            },
            {
              name: "LIÑAGAN CALSADA",
            },
            {
              name: "LIÑAGAN TACAS",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MABRIZ POB. (MABINI-RIZAL)",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANDOG",
            },
            {
              name: "MIAPA",
            },
            {
              name: "NAGBA",
            },
            {
              name: "NASAKA",
            },
            {
              name: "NASLO-BUCAO",
            },
            {
              name: "NASULI",
            },
            {
              name: "PANALIAN",
            },
            {
              name: "PIANDAAN EAST",
            },
            {
              name: "PIANDAAN WEST",
            },
            {
              name: "PISPIS",
            },
            {
              name: "PUNONG",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SINUBSUBAN",
            },
            {
              name: "SIWALO",
            },
            {
              name: "SUBOG",
            },
            {
              name: "THTP POB. (TAFT-THOMPSON--HUGHES-DEL PILAR)",
            },
            {
              name: "TIGBAUAN",
            },
            {
              name: "TRANGKA",
            },
            {
              name: "TUBANG",
            },
            {
              name: "TULAHONG",
            },
            {
              name: "TUY-AN EAST",
            },
            {
              name: "TUY-AN WEST",
            },
            {
              name: "UBIAN",
            },
          ],
        },
        MIAGAO: {
          name: "MIAGAO",
          baranggays: [
            {
              name: "AGDUM",
            },
            {
              name: "AGUIAUAN",
            },
            {
              name: "ALIMODIAS",
            },
            {
              name: "AWANG",
            },
            {
              name: "BACAUAN",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BANBANAN",
            },
            {
              name: "BANGA",
            },
            {
              name: "BANGLADAN",
            },
            {
              name: "BANUYAO",
            },
            {
              name: "BARACLAYAN",
            },
            {
              name: "BARIRI",
            },
            {
              name: "BAYBAY NORTE (POB.)",
            },
            {
              name: "BAYBAY SUR (POB.)",
            },
            {
              name: "BELEN",
            },
            {
              name: "BOLHO (POB.)",
            },
            {
              name: "BOLOCAUE",
            },
            {
              name: "BUENAVISTA NORTE",
            },
            {
              name: "BUENAVISTA SUR",
            },
            {
              name: "BUGTONG LUMANGAN",
            },
            {
              name: "BUGTONG NAULID",
            },
            {
              name: "CABALAUNAN",
            },
            {
              name: "CABANGCALAN",
            },
            {
              name: "CABUNOTAN",
            },
            {
              name: "CADOLDOLAN",
            },
            {
              name: "CAGBANG",
            },
            {
              name: "CAITIB",
            },
            {
              name: "CALAGTANGAN",
            },
            {
              name: "CALAMPITAO",
            },
            {
              name: "CAVITE",
            },
            {
              name: "CAWAYANAN",
            },
            {
              name: "CUBAY",
            },
            {
              name: "CUBAY UBOS",
            },
            {
              name: "DALIJE",
            },
            {
              name: "DAMILISAN",
            },
            {
              name: "DAWOG",
            },
            {
              name: "DIDAY",
            },
            {
              name: "DINGLE",
            },
            {
              name: "DUROG",
            },
            {
              name: "FRANTILLA",
            },
            {
              name: "FUNDACION",
            },
            {
              name: "GINES",
            },
            {
              name: "GUIBONGAN",
            },
            {
              name: "IGBITA",
            },
            {
              name: "IGBUGO",
            },
            {
              name: "IGCABIDIO",
            },
            {
              name: "IGCABITO-ON",
            },
            {
              name: "IGCATAMBOR",
            },
            {
              name: "IGDALAQUIT",
            },
            {
              name: "IGDULACA",
            },
            {
              name: "IGPAJO",
            },
            {
              name: "IGPANDAN",
            },
            {
              name: "IGPURO",
            },
            {
              name: "IGPURO-BARIRI",
            },
            {
              name: "IGSOLIGUE",
            },
            {
              name: "IGTUBA",
            },
            {
              name: "ILOG-ILOG",
            },
            {
              name: "INDAG-AN",
            },
            {
              name: "KIRAYAN NORTE",
            },
            {
              name: "KIRAYAN SUR",
            },
            {
              name: "KIRAYAN TACAS",
            },
            {
              name: "LA CONSOLACION",
            },
            {
              name: "LACADON",
            },
            {
              name: "LANUTAN",
            },
            {
              name: "LUMANGAN",
            },
            {
              name: "MABAYAN",
            },
            {
              name: "MADUYO",
            },
            {
              name: "MALAGYAN",
            },
            {
              name: "MAMBATAD",
            },
            {
              name: "MANINILA",
            },
            {
              name: "MARICOLCOL",
            },
            {
              name: "MARINGYAN",
            },
            {
              name: "MAT-Y (POB.)",
            },
            {
              name: "MATALNGON",
            },
            {
              name: "MIAGAO",
            },
            {
              name: "NACLUB",
            },
            {
              name: "NAM-O NORTE",
            },
            {
              name: "NAM-O SUR",
            },
            {
              name: "NARAT-AN",
            },
            {
              name: "NAROROGAN",
            },
            {
              name: "NAULID",
            },
            {
              name: "OLANGO",
            },
            {
              name: "ONGYOD",
            },
            {
              name: "ONOP",
            },
            {
              name: "OYA-OY",
            },
            {
              name: "OYUNGAN",
            },
            {
              name: "PALACA",
            },
            {
              name: "PARO-ON",
            },
            {
              name: "POTRIDO",
            },
            {
              name: "PUDPUD",
            },
            {
              name: "PUNGTOD MONTECLARO",
            },
            {
              name: "PUNGTOD NAULID",
            },
            {
              name: "SAG-ON",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAPA (MIAGAO)",
            },
            {
              name: "SARING",
            },
            {
              name: "SIBUCAO",
            },
            {
              name: "TAAL",
            },
            {
              name: "TABUNACAN",
            },
            {
              name: "TACAS (POB.)",
            },
            {
              name: "TAMBONG",
            },
            {
              name: "TAN-AGAN",
            },
            {
              name: "TATOY",
            },
            {
              name: "TICDALAN",
            },
            {
              name: "TIG-AMAGA",
            },
            {
              name: "TIG-APOG-APOG",
            },
            {
              name: "TIGBAGACAY",
            },
            {
              name: "TIGLAWA",
            },
            {
              name: "TIGMALAPAD",
            },
            {
              name: "TIGMARABO",
            },
            {
              name: "TO-OG",
            },
            {
              name: "TUGURA-AO",
            },
            {
              name: "TUMAGBOC",
            },
            {
              name: "UBOS ILAWOD (POB.)",
            },
            {
              name: "UBOS ILAYA (POB.)",
            },
            {
              name: "VALENCIA",
            },
            {
              name: "WAYANG",
            },
          ],
        },
        MINA: {
          name: "MINA",
          baranggays: [
            {
              name: "ABAT",
            },
            {
              name: "AGMANAPHAO",
            },
            {
              name: "AMIROY",
            },
            {
              name: "BADIANGAN",
            },
            {
              name: "BANGAC",
            },
            {
              name: "CABALABAGUAN",
            },
            {
              name: "CAPUL-AN",
            },
            {
              name: "DALA",
            },
            {
              name: "GUIBUANGAN",
            },
            {
              name: "JANIPA-AN EAST",
            },
            {
              name: "JANIPA-AN WEST",
            },
            {
              name: "MINA",
            },
            {
              name: "MINA EAST (POB.)",
            },
            {
              name: "MINA WEST (POB.)",
            },
            {
              name: "NASIRUM",
            },
            {
              name: "NAUMUAN",
            },
            {
              name: "SINGAY",
            },
            {
              name: "TALIBONG GRANDE",
            },
            {
              name: "TALIBONG PEQUEÑO",
            },
            {
              name: "TIPOLO",
            },
            {
              name: "TOLARUCAN",
            },
            {
              name: "TUMAY",
            },
            {
              name: "YUGOT",
            },
          ],
        },
        "NEW LUCENA": {
          name: "NEW LUCENA",
          baranggays: [
            {
              name: "BACLAYAN",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BILIDAN",
            },
            {
              name: "BITA-OG GAJA",
            },
            {
              name: "BOLOLACAO",
            },
            {
              name: "BUROT",
            },
            {
              name: "CABILAUAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAGBAN",
            },
            {
              name: "CALUMBUYAN",
            },
            {
              name: "DAMIRES",
            },
            {
              name: "DAWIS",
            },
            {
              name: "GENERAL DELGADO",
            },
            {
              name: "GUINOBATAN",
            },
            {
              name: "JANIPA-AN OESTE",
            },
            {
              name: "JELICUON ESTE",
            },
            {
              name: "JELICUON OESTE",
            },
            {
              name: "NEW LUCENA",
            },
            {
              name: "PASIL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "WARI-WARI",
            },
          ],
        },
        OTON: {
          name: "OTON",
          baranggays: [
            {
              name: "ABILAY NORTE",
            },
            {
              name: "ABILAY SUR",
            },
            {
              name: "ALEGRE",
            },
            {
              name: "BATUAN ILAUD",
            },
            {
              name: "BATUAN ILAYA",
            },
            {
              name: "BITA NORTE",
            },
            {
              name: "BITA SUR",
            },
            {
              name: "BOTONG",
            },
            {
              name: "BURAY",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CABOLOAN NORTE",
            },
            {
              name: "CABOLOAN SUR",
            },
            {
              name: "CADINGLIAN",
            },
            {
              name: "CAGBANG",
            },
            {
              name: "CALAM-ISAN",
            },
            {
              name: "GALANG",
            },
            {
              name: "LAMBUYAO",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "OTON",
            },
            {
              name: "PAKIAD",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "POLO MAESTRA BITA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALNGAN",
            },
            {
              name: "SAMBALUDAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "TAGBAC NORTE",
            },
            {
              name: "TAGBAC SUR",
            },
            {
              name: "TRAPICHE",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "TUROG-TUROG",
            },
          ],
        },
        PAVIA: {
          name: "PAVIA",
          baranggays: [
            {
              name: "AGANAN",
            },
            {
              name: "AMPARO",
            },
            {
              name: "ANILAO",
            },
            {
              name: "BALABAG",
            },
            {
              name: "CABUGAO NORTE",
            },
            {
              name: "CABUGAO SUR",
            },
            {
              name: "JIBAO-AN",
            },
            {
              name: "MALI-AO",
            },
            {
              name: "PAGSANGA-AN",
            },
            {
              name: "PAL-AGON",
            },
            {
              name: "PANDAC",
            },
            {
              name: "PAVIA",
            },
            {
              name: "PUROK I (POB.)",
            },
            {
              name: "PUROK II (POB.)",
            },
            {
              name: "PUROK III (POB.)",
            },
            {
              name: "PUROK IV (POB.)",
            },
            {
              name: "TIGUM",
            },
            {
              name: "UNGKA I",
            },
            {
              name: "UNGKA II",
            },
          ],
        },
        POTOTAN: {
          name: "POTOTAN",
          baranggays: [
            {
              name: "ABANGAY",
            },
            {
              name: "AMAMAROS",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BARASAN",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BONGCO",
            },
            {
              name: "CAHAGUICHICAN",
            },
            {
              name: "CALLAN",
            },
            {
              name: "CANSILAYAN",
            },
            {
              name: "CASALSAGAN",
            },
            {
              name: "CATO-OGAN",
            },
            {
              name: "CAU-AYAN",
            },
            {
              name: "CULOB",
            },
            {
              name: "DANAO",
            },
            {
              name: "DAPITAN",
            },
            {
              name: "DAWIS",
            },
            {
              name: "DONGSOL",
            },
            {
              name: "FERNANDO PARCON WARD (POB.)",
            },
            {
              name: "FUNDACION",
            },
            {
              name: "GUIBUANGAN",
            },
            {
              name: "GUINACAS",
            },
            {
              name: "IGANG",
            },
            {
              name: "INTALUAN",
            },
            {
              name: "IWA ILAUD",
            },
            {
              name: "IWA ILAYA",
            },
            {
              name: "JAMABALUD",
            },
            {
              name: "JEBIOC",
            },
            {
              name: "LAY-AHAN",
            },
            {
              name: "LOPEZ JAENA WARD (POB.)",
            },
            {
              name: "LUMBO",
            },
            {
              name: "MACATOL",
            },
            {
              name: "MALUSGOD",
            },
            {
              name: "NABITASAN",
            },
            {
              name: "NAGA",
            },
            {
              name: "NANGA",
            },
            {
              name: "NASLO",
            },
            {
              name: "PAJO",
            },
            {
              name: "PALANGUIA",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POLOT-AN",
            },
            {
              name: "POTOTAN",
            },
            {
              name: "PRIMITIVO LEDESMA WARD (POB.)",
            },
            {
              name: "PUROG",
            },
            {
              name: "RUMBANG",
            },
            {
              name: "SAN JOSE WARD (POB.)",
            },
            {
              name: "SINUAGAN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "TUMCON ILAUD",
            },
            {
              name: "TUMCON ILAYA",
            },
            {
              name: "UBANG",
            },
            {
              name: "ZARRAGUE",
            },
          ],
        },
        "SAN DIONISIO": {
          name: "SAN DIONISIO",
          baranggays: [
            {
              name: "AGDALIRAN",
            },
            {
              name: "AMAYONG",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BONDULAN",
            },
            {
              name: "BOROÑGON",
            },
            {
              name: "CANAS",
            },
            {
              name: "CAPINANG",
            },
            {
              name: "CUBAY",
            },
            {
              name: "CUDIONAN",
            },
            {
              name: "DUGMAN",
            },
            {
              name: "HACIENDA CONCHITA",
            },
            {
              name: "MADANLOG",
            },
            {
              name: "MANDU-AWAK",
            },
            {
              name: "MOTO",
            },
            {
              name: "NABOROT",
            },
            {
              name: "NIPA",
            },
            {
              name: "ODIONGAN",
            },
            {
              name: "PANGI",
            },
            {
              name: "PASE",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN DIONISIO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SANTOL",
            },
            {
              name: "SIEMPREVIVA",
            },
            {
              name: "SUA",
            },
            {
              name: "TALO-ATO",
            },
            {
              name: "TAMANGI",
            },
            {
              name: "TIABAS",
            },
            {
              name: "TUBLE",
            },
          ],
        },
        "SAN ENRIQUE": {
          name: "SAN ENRIQUE",
          baranggays: [
            {
              name: "ABACA",
            },
            {
              name: "ASISIG",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BRAULAN",
            },
            {
              name: "CABUGAO NUEVO",
            },
            {
              name: "CABUGAO VIEJO",
            },
            {
              name: "CAMIRI",
            },
            {
              name: "CATAN-AGAN",
            },
            {
              name: "COMPO",
            },
            {
              name: "CUBAY",
            },
            {
              name: "DACAL",
            },
            {
              name: "DUMILES",
            },
            {
              name: "GARITA",
            },
            {
              name: "GINES NUEVO",
            },
            {
              name: "IMBANG PEQUEÑO",
            },
            {
              name: "IMBESAD-AN",
            },
            {
              name: "IPROG",
            },
            {
              name: "LIP-AC",
            },
            {
              name: "MADARAG",
            },
            {
              name: "MAPILI",
            },
            {
              name: "PAGA",
            },
            {
              name: "PALJE",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "QUINOLPAN",
            },
            {
              name: "RUMAGAYRAY",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ENRIQUE",
            },
            {
              name: "TAMBUNAC",
            },
          ],
        },
        "SAN JOAQUIN": {
          name: "SAN JOAQUIN",
          baranggays: [
            {
              name: "AMBOYU-AN",
            },
            {
              name: "ANDRES BONIFACIO",
            },
            {
              name: "ANTALON",
            },
            {
              name: "BAD-AS",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALABAGO",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BAYUNAN (PANDAY ORO)",
            },
            {
              name: "BOLBOGAN",
            },
            {
              name: "BONGA",
            },
            {
              name: "BUCAYA",
            },
            {
              name: "BULHO",
            },
            {
              name: "CADLUMAN",
            },
            {
              name: "CADOLDOLAN",
            },
            {
              name: "CAMABA-AN",
            },
            {
              name: "CAMIA",
            },
            {
              name: "CATA-AN",
            },
            {
              name: "CROSSING DAPUYAN",
            },
            {
              name: "CUBAY",
            },
            {
              name: "CUMARASCAS",
            },
            {
              name: "DACDACANAN",
            },
            {
              name: "DANAWAN",
            },
            {
              name: "DOLDOL",
            },
            {
              name: "DONGOC",
            },
            {
              name: "ESCALANTERA",
            },
            {
              name: "GINOT-AN",
            },
            {
              name: "GUIBONGAN BAYUNAN",
            },
            {
              name: "HUNA",
            },
            {
              name: "IGBAJE",
            },
            {
              name: "IGBANGCAL",
            },
            {
              name: "IGBINANGON",
            },
            {
              name: "IGBURI",
            },
            {
              name: "IGCABUTONG",
            },
            {
              name: "IGCADLUM",
            },
            {
              name: "IGCAPHANG",
            },
            {
              name: "IGCARATONG",
            },
            {
              name: "IGCONDAO",
            },
            {
              name: "IGCORES",
            },
            {
              name: "IGDAGMAY",
            },
            {
              name: "IGDOMINGDING",
            },
            {
              name: "IGLILICO",
            },
            {
              name: "IGPAYONG",
            },
            {
              name: "JAWOD",
            },
            {
              name: "LANGCA",
            },
            {
              name: "LANGUANAN",
            },
            {
              name: "LAWIGAN",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "LOMBOYAN (SANTA ANA)",
            },
            {
              name: "LOPEZ VITO",
            },
            {
              name: "MABINI NORTE",
            },
            {
              name: "MABINI SUR",
            },
            {
              name: "MANHARA",
            },
            {
              name: "MANINILA",
            },
            {
              name: "MASAGUD",
            },
            {
              name: "MATAMBOG",
            },
            {
              name: "MAYUNOC",
            },
            {
              name: "MONTINOLA",
            },
            {
              name: "NADSADAN",
            },
            {
              name: "NAGQUIRISAN",
            },
            {
              name: "NAGSIPIT",
            },
            {
              name: "NEW GUMAWAN",
            },
            {
              name: "PANATAN",
            },
            {
              name: "PITOGO",
            },
            {
              name: "PUROK 1 (POB.)",
            },
            {
              name: "PUROK 2 (POB.)",
            },
            {
              name: "PUROK 3 (POB.)",
            },
            {
              name: "PUROK 4 (POB.)",
            },
            {
              name: "PUROK 5 (POB.)",
            },
            {
              name: "QUI-ANAN",
            },
            {
              name: "ROMA",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN MATEO NORTE",
            },
            {
              name: "SAN MATEO SUR",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SINOGBUHAN",
            },
            {
              name: "SIWARAGAN",
            },
            {
              name: "TALAGUTAC",
            },
            {
              name: "TAPIKAN",
            },
            {
              name: "TASLAN",
            },
            {
              name: "TIGLAWA",
            },
            {
              name: "TIOLAS",
            },
            {
              name: "TO-OG",
            },
            {
              name: "TOROCADAN",
            },
            {
              name: "ULAY",
            },
          ],
        },
        "SAN MIGUEL": {
          name: "SAN MIGUEL",
          baranggays: [
            {
              name: "BGY. 1 POB. (ROXAS ST.)",
            },
            {
              name: "BGY. 10 (POB. R.V.SANCHEZ ST. SOUTH)",
            },
            {
              name: "BGY. 11 POB. (R.V.SANCHEZ ST. NORTH)",
            },
            {
              name: "BGY. 12 POB. (SALES MALAGA SALIEDO)",
            },
            {
              name: "BGY. 13 POB. (STA.RITA-SACLAUSO ST.)",
            },
            {
              name: "BGY. 14 POB. (SAN MIGUEL NORTH)",
            },
            {
              name: "BGY. 15 POB. (SAN MIGUEL SOUTH)",
            },
            {
              name: "BGY. 16 POB. (SAN AGUSTIN ST.)",
            },
            {
              name: "BGY. 2 POB. (SAVILLA STO. ROSARIO)",
            },
            {
              name: "BGY. 3 POB. (A.S.SUAREZ ST. ZONE 1)",
            },
            {
              name: "BGY. 4 POB. (A.S.SUAREZ EAST)",
            },
            {
              name: "BGY. 5 POB. (SANTIAGO ST.NORTH)",
            },
            {
              name: "BGY. 6 POB. (SANTIAGO ST.SOUTH)",
            },
            {
              name: "BGY. 7 POB. (SAN ROQUE ST.SOUTH)",
            },
            {
              name: "BGY. 8 POB. (MONTANO - SAN ROQUE)",
            },
            {
              name: "BGY. 9 POB. (SALAZAR SAN JOSE)",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "IGTAMBO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA TERESA",
            },
            {
              name: "SANTO ANGEL",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        "SAN RAFAEL": {
          name: "SAN RAFAEL",
          baranggays: [
            {
              name: "ARIPDIP",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "CALAIGANG",
            },
            {
              name: "ILONGBUKID",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POSCOLON",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN DIONISIO",
            },
            {
              name: "SAN FLORENTINO",
            },
            {
              name: "SAN RAFAEL",
            },
          ],
        },
        "SANTA BARBARA": {
          name: "SANTA BARBARA",
          baranggays: [
            {
              name: "AGUSIPAN",
            },
            {
              name: "AGUTAYAN",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BALIBAGAN ESTE",
            },
            {
              name: "BALIBAGAN OESTE",
            },
            {
              name: "BAN-AG",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BARANGAY ZONE I (POB.)",
            },
            {
              name: "BARANGAY ZONE II (POB.)",
            },
            {
              name: "BARANGAY ZONE III (POB.)",
            },
            {
              name: "BARANGAY ZONE IV (POB.)",
            },
            {
              name: "BARANGAY ZONE V (POB.)",
            },
            {
              name: "BARANGAY ZONE VI (POB.)",
            },
            {
              name: "BARASAN ESTE",
            },
            {
              name: "BARASAN OESTE",
            },
            {
              name: "BINANGKILAN",
            },
            {
              name: "BITAOG-TAYTAY",
            },
            {
              name: "BOLONG ESTE",
            },
            {
              name: "BOLONG OESTE",
            },
            {
              name: "BUAYAHON",
            },
            {
              name: "BUYO",
            },
            {
              name: "CABUGAO NORTE",
            },
            {
              name: "CABUGAO SUR",
            },
            {
              name: "CADAGMAYAN NORTE",
            },
            {
              name: "CADAGMAYAN SUR",
            },
            {
              name: "CAFE",
            },
            {
              name: "CALABOA ESTE",
            },
            {
              name: "CALABOA OESTE",
            },
            {
              name: "CAMAMBUGAN",
            },
            {
              name: "CANIPAYAN",
            },
            {
              name: "CONAYNAY",
            },
            {
              name: "DAGA",
            },
            {
              name: "DALID",
            },
            {
              name: "DUYANDUYAN",
            },
            {
              name: "GEN. MARTIN T. DELGADO",
            },
            {
              name: "GUNO",
            },
            {
              name: "INANGAYAN",
            },
            {
              name: "JIBAO-AN",
            },
            {
              name: "LACADON",
            },
            {
              name: "LANAG",
            },
            {
              name: "LUPA",
            },
            {
              name: "MAGANCINA",
            },
            {
              name: "MALAWOG",
            },
            {
              name: "MAMBUYO",
            },
            {
              name: "MANHAYANG",
            },
            {
              name: "MIRAGA-GUIBUANGAN",
            },
            {
              name: "NASUGBAN",
            },
            {
              name: "OMAMBOG",
            },
            {
              name: "PAL-AGON",
            },
            {
              name: "PUNGSOD",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SANGCATE",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "TAGSING",
            },
            {
              name: "TALANGHAUAN",
            },
            {
              name: "TALONGADIAN",
            },
            {
              name: "TIGTIG",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "TUGAS",
            },
            {
              name: "TUNGAY",
            },
          ],
        },
        SARA: {
          name: "SARA",
          baranggays: [
            {
              name: "AGUIRRE",
            },
            {
              name: "ALDEGUER",
            },
            {
              name: "ALIBAYOG",
            },
            {
              name: "ANORING",
            },
            {
              name: "APELO",
            },
            {
              name: "APOLOGISTA",
            },
            {
              name: "APOSAGA",
            },
            {
              name: "ARANTE",
            },
            {
              name: "ARDEMIL",
            },
            {
              name: "ASPERA",
            },
            {
              name: "ASWE-PABRIAGA",
            },
            {
              name: "BAGAYGAY",
            },
            {
              name: "BAKABAK",
            },
            {
              name: "BATITAO",
            },
            {
              name: "BATO",
            },
            {
              name: "CASTOR",
            },
            {
              name: "CRESPO",
            },
            {
              name: "DEL CASTILLO",
            },
            {
              name: "DEVERA",
            },
            {
              name: "DOMINGO",
            },
            {
              name: "FERRARIS",
            },
            {
              name: "GILDORE",
            },
            {
              name: "IMPROGO",
            },
            {
              name: "JUANEZA",
            },
            {
              name: "LABIGAN",
            },
            {
              name: "LANCIOLA",
            },
            {
              name: "LATAWAN",
            },
            {
              name: "MALAPAYA",
            },
            {
              name: "MUYCO",
            },
            {
              name: "PADIOS",
            },
            {
              name: "PASIG",
            },
            {
              name: "POBLACION ILAWOD",
            },
            {
              name: "POBLACION ILAYA",
            },
            {
              name: "POBLACION MARKET",
            },
            {
              name: "POSADAS",
            },
            {
              name: "PRECIOSA",
            },
            {
              name: "SALCEDO",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SARA",
            },
            {
              name: "TADY",
            },
            {
              name: "TENTAY",
            },
            {
              name: "VILLAHERMOSA",
            },
            {
              name: "ZERRUDO",
            },
          ],
        },
        TIGBAUAN: {
          name: "TIGBAUAN",
          baranggays: [
            {
              name: "ALUPIDIAN",
            },
            {
              name: "ATABAYAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAGUINGIN",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BANGKAL",
            },
            {
              name: "BANTUD",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BAROSONG",
            },
            {
              name: "BARROC",
            },
            {
              name: "BAYUCO",
            },
            {
              name: "BINALIUAN MAYOR",
            },
            {
              name: "BINALIUAN MENOR",
            },
            {
              name: "BITAS",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUGASONGAN",
            },
            {
              name: "BUYU-AN",
            },
            {
              name: "CANABUAN",
            },
            {
              name: "CANSILAYAN",
            },
            {
              name: "CORDOVA NORTE",
            },
            {
              name: "CORDOVA SUR",
            },
            {
              name: "DANAO",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DORONG-AN",
            },
            {
              name: "GUISIAN",
            },
            {
              name: "ISAUAN",
            },
            {
              name: "ISIAN",
            },
            {
              name: "JAMOG",
            },
            {
              name: "LANAG",
            },
            {
              name: "LINOBAYAN",
            },
            {
              name: "LUBOG",
            },
            {
              name: "NAGBA",
            },
            {
              name: "NAMOCON",
            },
            {
              name: "NAPNAPAN NORTE",
            },
            {
              name: "NAPNAPAN SUR",
            },
            {
              name: "OLO BARROC",
            },
            {
              name: "PARARA NORTE",
            },
            {
              name: "PARARA SUR",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SERMON",
            },
            {
              name: "SIPITAN",
            },
            {
              name: "SUPA",
            },
            {
              name: "TAN PAEL",
            },
            {
              name: "TARO",
            },
            {
              name: "TIGBAUAN",
            },
          ],
        },
        TUBUNGAN: {
          name: "TUBUNGAN",
          baranggays: [
            {
              name: "ADGAO",
            },
            {
              name: "AGO",
            },
            {
              name: "AMBARIHON",
            },
            {
              name: "AYUBO",
            },
            {
              name: "BACAN",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BAGUNANAY",
            },
            {
              name: "BALICUA",
            },
            {
              name: "BANTAYANAN",
            },
            {
              name: "BATGA",
            },
            {
              name: "BATO",
            },
            {
              name: "BIKIL",
            },
            {
              name: "BOLOC",
            },
            {
              name: "BONDOC",
            },
            {
              name: "BORONG",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CADABDAB",
            },
            {
              name: "DAGA-AY",
            },
            {
              name: "DESPOSORIO",
            },
            {
              name: "IGDAMPOG NORTE",
            },
            {
              name: "IGDAMPOG SUR",
            },
            {
              name: "IGPAHO",
            },
            {
              name: "IGTUBLE",
            },
            {
              name: "INGAY",
            },
            {
              name: "ISAUAN",
            },
            {
              name: "JOLASON",
            },
            {
              name: "JONA",
            },
            {
              name: "LA-AG",
            },
            {
              name: "LANAG NORTE",
            },
            {
              name: "LANAG SUR",
            },
            {
              name: "MALE",
            },
            {
              name: "MAYANG",
            },
            {
              name: "MOLINA",
            },
            {
              name: "MORCILLAS",
            },
            {
              name: "NAGBA",
            },
            {
              name: "NAVILLAN",
            },
            {
              name: "PINAMACALAN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SIBUCAUAN",
            },
            {
              name: "SINGON",
            },
            {
              name: "TABAT",
            },
            {
              name: "TAGPU-AN",
            },
            {
              name: "TALENTO",
            },
            {
              name: "TENIENTE BENITO",
            },
            {
              name: "TUBUNGAN",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
          ],
        },
        ZARRAGA: {
          name: "ZARRAGA",
          baranggays: [
            {
              name: "BALUD I",
            },
            {
              name: "BALUD II",
            },
            {
              name: "BALUD LILO-AN",
            },
            {
              name: "DAWIS CENTRO",
            },
            {
              name: "DAWIS NORTE",
            },
            {
              name: "DAWIS SUR",
            },
            {
              name: "GINES",
            },
            {
              name: "ILAWOD POBLACION",
            },
            {
              name: "ILAYA POBLACION",
            },
            {
              name: "INAGDANGAN CENTRO",
            },
            {
              name: "INAGDANGAN NORTE",
            },
            {
              name: "INAGDANGAN SUR",
            },
            {
              name: "JALAUD NORTE",
            },
            {
              name: "JALAUD SUR",
            },
            {
              name: "LIBONGCOGON",
            },
            {
              name: "MALUNANG",
            },
            {
              name: "PAJO",
            },
            {
              name: "SAMBAG",
            },
            {
              name: "SIGANGAO",
            },
            {
              name: "TALAUGUIS",
            },
            {
              name: "TALIBONG",
            },
            {
              name: "TUBIGAN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "TUBURAN SULBOD",
            },
            {
              name: "ZARRAGA",
            },
          ],
        },
      },
    },
    ISABELA: {
      name: "ISABELA",
      region: "REGION 2",
      municipalities: {
        ALICIA: {
          name: "ALICIA",
          baranggays: [
            {
              name: "ALICIA",
            },
            {
              name: "AMISTAD",
            },
            {
              name: "ANTONINO (POB.)",
            },
            {
              name: "APANAY",
            },
            {
              name: "AURORA",
            },
            {
              name: "BAGNOS",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BANTUG-PETINES",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CALAOCAN (POB.)",
            },
            {
              name: "CALLAO",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "INANAMA",
            },
            {
              name: "LINGLINGAY",
            },
            {
              name: "M.H. DEL PILAR",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY (POB.)",
            },
            {
              name: "MATAAS NA KAHOY",
            },
            {
              name: "PADDAD",
            },
            {
              name: "RIZAL",
            },
            {
              name: "RIZALUNA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "ZAMORA",
            },
          ],
        },
        ANGADANAN: {
          name: "ANGADANAN",
          baranggays: [
            {
              name: "ALLANGIGAN",
            },
            {
              name: "ANGADANAN",
            },
            {
              name: "ANIOG",
            },
            {
              name: "BANIKET",
            },
            {
              name: "BANNAWAG",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BARANGCUAG",
            },
            {
              name: "BAUI",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUNNAY",
            },
            {
              name: "CALABAYAN-MINANGA",
            },
            {
              name: "CALACCAB",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "CAMPANARIO",
            },
            {
              name: "CANANGAN",
            },
            {
              name: "CENTRO I (POB.)",
            },
            {
              name: "CENTRO II (POB.)",
            },
            {
              name: "CENTRO III (POB.)",
            },
            {
              name: "CONSULAR",
            },
            {
              name: "CUMU",
            },
            {
              name: "DALAKIP",
            },
            {
              name: "DALENAT",
            },
            {
              name: "DIPALUDA",
            },
            {
              name: "DUROC",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "FUGARU",
            },
            {
              name: "INGUD NORTE",
            },
            {
              name: "INGUD SUR",
            },
            {
              name: "KALUSUTAN",
            },
            {
              name: "LA SUERTE",
            },
            {
              name: "LIWLIWA",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "LORIA",
            },
            {
              name: "LOURDES (EL ESCAÑO)",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MACALAUAT",
            },
            {
              name: "MACANIAO",
            },
            {
              name: "MALANNAO",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MANGANDINGAY",
            },
            {
              name: "MINANGA PROPER",
            },
            {
              name: "PAPPAT",
            },
            {
              name: "PISSAY",
            },
            {
              name: "RAMONA",
            },
            {
              name: "RANCHO BASSIT",
            },
            {
              name: "RANG-AYAN",
            },
            {
              name: "SALAY",
            },
            {
              name: "SAN AMBROCIO",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MARCELO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SARANAY",
            },
            {
              name: "SINABBARAN",
            },
            {
              name: "VICTORY",
            },
            {
              name: "VIGA",
            },
            {
              name: "VILLA DOMINGO",
            },
          ],
        },
        AURORA: {
          name: "AURORA",
          baranggays: [
            {
              name: "APIAT",
            },
            {
              name: "AURORA",
            },
            {
              name: "BAGNOS",
            },
            {
              name: "BAGONG TANZA",
            },
            {
              name: "BALLESTEROS",
            },
            {
              name: "BANNAGAO",
            },
            {
              name: "BANNAWAG",
            },
            {
              name: "BOLINAO",
            },
            {
              name: "CAIPILAN",
            },
            {
              name: "CAMARUNGGAYAN",
            },
            {
              name: "DALIG-KALINGA",
            },
            {
              name: "DIAMANTINA",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "ESPERANZA EAST",
            },
            {
              name: "ESPERANZA WEST",
            },
            {
              name: "KALABAZA",
            },
            {
              name: "MACATAL",
            },
            {
              name: "MALASIN",
            },
            {
              name: "NAMPICUAN",
            },
            {
              name: "PANECIEN",
            },
            {
              name: "RIZALUNA (LAPUZ)",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN PEDRO-SAN PABLO (POB.)",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SARANAY",
            },
            {
              name: "SILI",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "VILLA FUGU",
            },
            {
              name: "VILLA NUESA",
            },
          ],
        },
        "BENITO SOLIVEN": {
          name: "BENITO SOLIVEN",
          baranggays: [
            {
              name: "ANDABUEN",
            },
            {
              name: "ARA",
            },
            {
              name: "BALLIAO",
            },
            {
              name: "BENITO SOLIVEN",
            },
            {
              name: "BINOGTUNGAN",
            },
            {
              name: "CAPUSERAN (CAPUROCAN)",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "DANIPA",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "GOMEZ",
            },
            {
              name: "GUILINGAN",
            },
            {
              name: "LA SALETTE",
            },
            {
              name: "LUCBAN",
            },
            {
              name: "MAKINDOL",
            },
            {
              name: "MALUNO NORTE",
            },
            {
              name: "MALUNO SUR",
            },
            {
              name: "NACALMA",
            },
            {
              name: "NEW MAGSAYSAY",
            },
            {
              name: "PLACER",
            },
            {
              name: "PUNIT",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SEVILLANA",
            },
            {
              name: "SINIPIT",
            },
            {
              name: "VILLALUZ",
            },
            {
              name: "YEBAN NORTE",
            },
            {
              name: "YEBAN SUR",
            },
          ],
        },
        BURGOS: {
          name: "BURGOS",
          baranggays: [
            {
              name: "BACNOR EAST",
            },
            {
              name: "BACNOR WEST",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CALIGUIAN (POB.)",
            },
            {
              name: "CATABBAN",
            },
            {
              name: "CULLALABO DEL NORTE",
            },
            {
              name: "CULLALABO DEL SUR",
            },
            {
              name: "CULLALABO SAN ANTONIO",
            },
            {
              name: "DALIG",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MASIGUN",
            },
            {
              name: "RANIAG",
            },
            {
              name: "SAN BONIFACIO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
          ],
        },
        CABAGAN: {
          name: "CABAGAN",
          baranggays: [
            {
              name: "AGGUB",
            },
            {
              name: "ANAO",
            },
            {
              name: "ANGANCASILIAN",
            },
            {
              name: "BALASIG",
            },
            {
              name: "CABAGAN",
            },
            {
              name: "CANSAN",
            },
            {
              name: "CASIBARAG NORTE",
            },
            {
              name: "CASIBARAG SUR",
            },
            {
              name: "CATABAYUNGAN",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "CUBAG",
            },
            {
              name: "GARITA",
            },
            {
              name: "LUQUILU",
            },
            {
              name: "MABANGUG",
            },
            {
              name: "MAGASSI",
            },
            {
              name: "MASIPI EAST",
            },
            {
              name: "MASIPI WEST",
            },
            {
              name: "NGARAG",
            },
            {
              name: "PILIG ABAJO",
            },
            {
              name: "PILIG ALTO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BERNARDO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAUI",
            },
            {
              name: "TALLAG",
            },
            {
              name: "UGAD",
            },
            {
              name: "UNION",
            },
          ],
        },
        CABATUAN: {
          name: "CABATUAN",
          baranggays: [
            {
              name: "CABATUAN",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "CANAN",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "CULING CENTRO",
            },
            {
              name: "CULING EAST",
            },
            {
              name: "CULING WEST",
            },
            {
              name: "DEL CORPUZ",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DIAMANTINA",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LUZON",
            },
            {
              name: "MACALAOAT",
            },
            {
              name: "MAGDALENA",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "NAMNAMA",
            },
            {
              name: "NUEVA ERA",
            },
            {
              name: "PARAISO",
            },
            {
              name: "RANG-AY (CAGGONG)",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN ANDRES (TEODORO ABAD)",
            },
            {
              name: "SARANAY",
            },
            {
              name: "TANDUL",
            },
          ],
        },
        "CAUAYAN CITY": {
          name: "CAUAYAN CITY",
          baranggays: [
            {
              name: "ALICAOCAO",
            },
            {
              name: "ALINAM",
            },
            {
              name: "AMOBOCAN",
            },
            {
              name: "ANDARAYAN",
            },
            {
              name: "BACULOD",
            },
            {
              name: "BARINGIN NORTE",
            },
            {
              name: "BARINGIN SUR",
            },
            {
              name: "BUENA SUERTE",
            },
            {
              name: "BUGALLON",
            },
            {
              name: "BUYON",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CARABATAN BACARENO",
            },
            {
              name: "CARABATAN CHICA",
            },
            {
              name: "CARABATAN GRANDE",
            },
            {
              name: "CARABATAN PUNTA",
            },
            {
              name: "CASALATAN",
            },
            {
              name: "CASSAP FUERA",
            },
            {
              name: "CATALINA",
            },
            {
              name: "CAUAYAN CITY",
            },
            {
              name: "CULALABAT",
            },
            {
              name: "DABBURAB",
            },
            {
              name: "DE VERA",
            },
            {
              name: "DIANAO",
            },
            {
              name: "DISIMURAY",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "DISTRICT III (POB.)",
            },
            {
              name: "DUMINIT",
            },
            {
              name: "FAUSTINO (SIPAY)",
            },
            {
              name: "GAGABUTAN",
            },
            {
              name: "GAPPAL",
            },
            {
              name: "GUAYABAL",
            },
            {
              name: "LABINAB",
            },
            {
              name: "LINGLINGAY",
            },
            {
              name: "MABANTAD",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MANAOAG",
            },
            {
              name: "MARABULIG I",
            },
            {
              name: "MARABULIG II",
            },
            {
              name: "MINANTE I",
            },
            {
              name: "MINANTE II",
            },
            {
              name: "NAGANACAN",
            },
            {
              name: "NAGCAMPEGAN",
            },
            {
              name: "NAGRUMBUAN",
            },
            {
              name: "NUNGNUNGAN I",
            },
            {
              name: "NUNGNUNGAN II",
            },
            {
              name: "PINOMA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROGUS",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FERMIN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN PABLO (CASAP HACIENDA)",
            },
            {
              name: "SANTA LUCIANA (DABURAB 2)",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SILLAWIT",
            },
            {
              name: "SINIPPIL",
            },
            {
              name: "TAGARAN",
            },
            {
              name: "TURAYONG",
            },
            {
              name: "UNION",
            },
            {
              name: "VILLA CONCEPCION",
            },
            {
              name: "VILLA LUNA",
            },
            {
              name: "VILLAFLOR",
            },
          ],
        },
        "CITY OF SANTIAGO": {
          name: "CITY OF SANTIAGO",
          baranggays: [
            {
              name: "ABRA",
            },
            {
              name: "AMBALATUNGAN",
            },
            {
              name: "BALINTOCATOC",
            },
            {
              name: "BALUARTE",
            },
            {
              name: "BANNAWAG NORTE",
            },
            {
              name: "BATAL",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABULAY",
            },
            {
              name: "CALAO EAST (POB.)",
            },
            {
              name: "CALAO WEST (POB.)",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "CENTRO EAST (POB.)",
            },
            {
              name: "CENTRO WEST (POB.)",
            },
            {
              name: "CITY OF SANTIAGO",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "DUBINAN EAST",
            },
            {
              name: "DUBINAN WEST",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALVAR",
            },
            {
              name: "NABBUAN",
            },
            {
              name: "NAGGASICAN",
            },
            {
              name: "PATUL",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAGANA",
            },
            {
              name: "SALVADOR",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SINILI",
            },
            {
              name: "SINSAYON",
            },
            {
              name: "VICTORY NORTE",
            },
            {
              name: "VICTORY SUR",
            },
            {
              name: "VILLA GONZAGA",
            },
            {
              name: "VILLASIS",
            },
          ],
        },
        CORDON: {
          name: "CORDON",
          baranggays: [
            {
              name: "AGUINALDO (RIZALUNA ESTE)",
            },
            {
              name: "ANONANG (BALITOC*)",
            },
            {
              name: "CALIMATUROD",
            },
            {
              name: "CAMARAO",
            },
            {
              name: "CAPIRPIRIWAN",
            },
            {
              name: "CAQUILINGAN (SAN LUIS)",
            },
            {
              name: "CORDON",
            },
            {
              name: "DALLAO",
            },
            {
              name: "GAYONG",
            },
            {
              name: "LAUREL (CENTRO NORTE)",
            },
            {
              name: "MAGSAYSAY (CENTRO SUR OESTE)",
            },
            {
              name: "MALAPAT",
            },
            {
              name: "OSMENA (CENTRO SUR ESTE)",
            },
            {
              name: "QUEZON (CENTRO NORTE ESTE)",
            },
            {
              name: "QUIRINO (MANASIN)",
            },
            {
              name: "RIZALUNA (RIZALUNA OESTE)",
            },
            {
              name: "ROXAS POB. (CENTRO SUR)",
            },
            {
              name: "SAGAT",
            },
            {
              name: "SAN JUAN (SAN JUAN ESTE)",
            },
            {
              name: "TALIKTIK",
            },
            {
              name: "TANGGAL",
            },
            {
              name: "TARINSING",
            },
            {
              name: "TUROD NORTE",
            },
            {
              name: "TUROD SUR",
            },
            {
              name: "VILLAMARZO",
            },
            {
              name: "VILLAMIEMBAN",
            },
            {
              name: "WIGAN",
            },
          ],
        },
        "DELFIN ALBANO (MAGSAYSAY)": {
          name: "DELFIN ALBANO (MAGSAYSAY)",
          baranggays: [
            {
              name: "AGA",
            },
            {
              name: "ANDARAYAN",
            },
            {
              name: "ANEG",
            },
            {
              name: "BAYABO",
            },
            {
              name: "CALINAOAN SUR",
            },
            {
              name: "CALOOCAN",
            },
            {
              name: "CAPITOL",
            },
            {
              name: "CARMENCITA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DELFIN ALBANO (MAGSAYSAY)",
            },
            {
              name: "MAUI",
            },
            {
              name: "QUIBAL",
            },
            {
              name: "RAGAN ALMACEN",
            },
            {
              name: "RAGAN NORTE",
            },
            {
              name: "RAGAN SUR (POB.)",
            },
            {
              name: "RIZAL (RAGAN ALMACEN ALTO)",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MACARIO",
            },
            {
              name: "SAN NICOLAS (FUSI)",
            },
            {
              name: "SAN PATRICIO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SANTOR",
            },
            {
              name: "VILLA LUZ",
            },
            {
              name: "VILLA PEREDA",
            },
            {
              name: "VISITACION",
            },
          ],
        },
        DINAPIGUE: {
          name: "DINAPIGUE",
          baranggays: [
            {
              name: "BUCAL SUR",
            },
            {
              name: "DIBULO",
            },
            {
              name: "DIGUMASED (POB.)",
            },
            {
              name: "DIMALUADE",
            },
          ],
        },
        DIVILACAN: {
          name: "DIVILACAN",
          baranggays: [
            {
              name: "BICOBIAN",
            },
            {
              name: "DIBULOS",
            },
            {
              name: "DICAMBANGAN",
            },
            {
              name: "DICAROYAN",
            },
            {
              name: "DICATIAN",
            },
            {
              name: "DILAKIT",
            },
            {
              name: "DIMAPNAT",
            },
            {
              name: "DIMAPULA (POB.)",
            },
            {
              name: "DIMASALANSAN",
            },
            {
              name: "DIPUDO",
            },
            {
              name: "DITARUM",
            },
            {
              name: "DIVILACAN",
            },
            {
              name: "SAPINIT",
            },
          ],
        },
        ECHAGUE: {
          name: "ECHAGUE",
          baranggays: [
            {
              name: "ANGOLUAN",
            },
            {
              name: "ANNAFUNAN",
            },
            {
              name: "ARABIAT",
            },
            {
              name: "AROMIN",
            },
            {
              name: "BABARAN",
            },
            {
              name: "BACRADAL",
            },
            {
              name: "BENGUET",
            },
            {
              name: "BUNEG",
            },
            {
              name: "BUSILELAO",
            },
            {
              name: "CABUGAO (POB.)",
            },
            {
              name: "CANIGUING",
            },
            {
              name: "CARULAY",
            },
            {
              name: "CASTILLO",
            },
            {
              name: "DAMMANG EAST",
            },
            {
              name: "DAMMANG WEST",
            },
            {
              name: "DIASAN",
            },
            {
              name: "DICARAOYAN",
            },
            {
              name: "DUGAYONG",
            },
            {
              name: "ECHAGUE",
            },
            {
              name: "FUGU",
            },
            {
              name: "GARIT NORTE",
            },
            {
              name: "GARIT SUR",
            },
            {
              name: "GUCAB",
            },
            {
              name: "GUMBAUAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MABBAYAD",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MADADAMIAN",
            },
            {
              name: "MAGLETICIA",
            },
            {
              name: "MALIBAGO",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MALITAO",
            },
            {
              name: "NARRA",
            },
            {
              name: "NILUMISU",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PANGAL NORTE",
            },
            {
              name: "PANGAL SUR",
            },
            {
              name: "RUMANG-AY",
            },
            {
              name: "SALAY",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO MINIT",
            },
            {
              name: "SAN ANTONIO UGAD",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN FABIAN",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SILAUAN NORTE (POB.)",
            },
            {
              name: "SILAUAN SUR (POB.)",
            },
            {
              name: "SINABBARAN",
            },
            {
              name: "SOYUNG",
            },
            {
              name: "TAGGAPPAN",
            },
            {
              name: "TUGUEGARAO",
            },
            {
              name: "VILLA CAMPO",
            },
            {
              name: "VILLA FERMIN",
            },
            {
              name: "VILLA REY",
            },
            {
              name: "VILLA VICTORIA",
            },
          ],
        },
        GAMU: {
          name: "GAMU",
          baranggays: [
            {
              name: "BARCOLAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "DAMMAO",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "DISTRICT III (POB.)",
            },
            {
              name: "FURAO",
            },
            {
              name: "GAMU",
            },
            {
              name: "GUIBANG",
            },
            {
              name: "LENZON",
            },
            {
              name: "LINGLINGAY",
            },
            {
              name: "MABINI",
            },
            {
              name: "PINTOR",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SONGSONG",
            },
            {
              name: "UNION",
            },
            {
              name: "UPI",
            },
          ],
        },
        "ILAGAN CITY": {
          name: "ILAGAN CITY",
          baranggays: [
            {
              name: "AGGASIAN",
            },
            {
              name: "ALIBAGU",
            },
            {
              name: "ALLINGUIGAN 1ST",
            },
            {
              name: "ALLINGUIGAN 2ND",
            },
            {
              name: "ALLINGUIGAN 3RD",
            },
            {
              name: "ARUSIP",
            },
            {
              name: "BACULOD (POB.)",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAGUMBAYAN (POB.)",
            },
            {
              name: "BALIGATAN",
            },
            {
              name: "BALLACONG",
            },
            {
              name: "BANGAG",
            },
            {
              name: "BATONG-LABANG",
            },
            {
              name: "BIGAO",
            },
            {
              name: "CABANNUNGAN 1ST",
            },
            {
              name: "CABANNUNGAN 2ND",
            },
            {
              name: "CABESERIA 10 (LAPIGUI)",
            },
            {
              name: "CABESERIA 14 AND 16 (CASILAGAN)",
            },
            {
              name: "CABESERIA 17 AND 21 (SAN RAFAEL)",
            },
            {
              name: "CABESERIA 19 (VILLA SUERTE*)",
            },
            {
              name: "CABESERIA 2 (DAPPAT)",
            },
            {
              name: "CABESERIA 22 (SABLANG)",
            },
            {
              name: "CABESERIA 23 (SAN FRANCISCO*)",
            },
            {
              name: "CABESERIA 25 (STA. LUCIA*)",
            },
            {
              name: "CABESERIA 27 (ABUAN)",
            },
            {
              name: "CABESERIA 3 (SAN FERNANDO)",
            },
            {
              name: "CABESERIA 4 (SAN MANUEL)",
            },
            {
              name: "CABESERIA 5 (BARIBAD)",
            },
            {
              name: "CABESERIA 6 AND 24 (VILLA MARCOS*)",
            },
            {
              name: "CABESERIA 7 (NANGALISAN)",
            },
            {
              name: "CABESERIA 9 AND 11 (CAPOGOTAN)",
            },
            {
              name: "CADU",
            },
            {
              name: "CALAMAGUI 1ST",
            },
            {
              name: "CALAMAGUI 2ND",
            },
            {
              name: "CAMUNATAN",
            },
            {
              name: "CAPELLAN",
            },
            {
              name: "CAPO",
            },
            {
              name: "CARIKKIKAN NORTE",
            },
            {
              name: "CARIKKIKAN SUR",
            },
            {
              name: "CENTRO - SAN ANTONIO",
            },
            {
              name: "CENTRO POBLACION",
            },
            {
              name: "FUGU",
            },
            {
              name: "FUYO",
            },
            {
              name: "GAYONG-GAYONG NORTE",
            },
            {
              name: "GAYONG-GAYONG SUR",
            },
            {
              name: "GUINATAN",
            },
            {
              name: "ILAGAN CITY",
            },
            {
              name: "IMELDA BLISS VILLAGE",
            },
            {
              name: "LULLUTAN",
            },
            {
              name: "MALALAM",
            },
            {
              name: "MALASIN (ANGELES)",
            },
            {
              name: "MANARING",
            },
            {
              name: "MANGCURAM",
            },
            {
              name: "MARANA I",
            },
            {
              name: "MARANA II",
            },
            {
              name: "MARANA III",
            },
            {
              name: "MINABANG",
            },
            {
              name: "MORADO",
            },
            {
              name: "NAGUILIAN NORTE",
            },
            {
              name: "NAGUILIAN SUR",
            },
            {
              name: "NAMNAMA",
            },
            {
              name: "NANAGUAN",
            },
            {
              name: "OSMEÑA (SINIPPIL)",
            },
            {
              name: "PALIUEG",
            },
            {
              name: "PASA",
            },
            {
              name: "PILAR",
            },
            {
              name: "QUIMALABASA",
            },
            {
              name: "RANG-AYAN (BINTACAN)",
            },
            {
              name: "RUGAO",
            },
            {
              name: "SALINDINGAN",
            },
            {
              name: "SAN ANDRES (ANGARILLA)",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN IGNACIO (CANAPI)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN RODRIGO",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA BARBARA (POB.)",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA ISABEL NORTE",
            },
            {
              name: "SANTA ISABEL SUR",
            },
            {
              name: "SANTA MARIA (CABESERIA 8)",
            },
            {
              name: "SANTA VICTORIA",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SIFFU",
            },
            {
              name: "SINDON BAYABO",
            },
            {
              name: "SINDON MARIDE",
            },
            {
              name: "SIPAY",
            },
            {
              name: "TANGCUL",
            },
            {
              name: "VILLA IMELDA (MAPLAS)",
            },
          ],
        },
        JONES: {
          name: "JONES",
          baranggays: [
            {
              name: "ABULAN",
            },
            {
              name: "ADDALAM",
            },
            {
              name: "ARUBUB",
            },
            {
              name: "BANNAWAG",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGCUAG",
            },
            {
              name: "DALIBUBON",
            },
            {
              name: "DALIGAN",
            },
            {
              name: "DIARAO",
            },
            {
              name: "DIBULUAN",
            },
            {
              name: "DICAMAY I",
            },
            {
              name: "DICAMAY II",
            },
            {
              name: "DIPANGIT",
            },
            {
              name: "DISIMPIT",
            },
            {
              name: "DIVINAN",
            },
            {
              name: "DUMAWING",
            },
            {
              name: "FUGU",
            },
            {
              name: "JONES",
            },
            {
              name: "LACAB",
            },
            {
              name: "LINAMANAN",
            },
            {
              name: "LINOMOT",
            },
            {
              name: "MALANNIT",
            },
            {
              name: "MINURI",
            },
            {
              name: "NAMNAMA",
            },
            {
              name: "NAPALIONG",
            },
            {
              name: "PALAGAO",
            },
            {
              name: "PAPAN ESTE",
            },
            {
              name: "PAPAN WESTE",
            },
            {
              name: "PAYAC",
            },
            {
              name: "PONGPONGAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "TUPAX",
            },
            {
              name: "USOL",
            },
            {
              name: "VILLA BELLO",
            },
          ],
        },
        LUNA: {
          name: "LUNA",
          baranggays: [
            {
              name: "BUSTAMANTE",
            },
            {
              name: "CENTRO 1 (POB.)",
            },
            {
              name: "CENTRO 2 (POB.)",
            },
            {
              name: "CENTRO 3 (POB.)",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DADAP",
            },
            {
              name: "HARANA",
            },
            {
              name: "LALOG 1",
            },
            {
              name: "LALOG 2",
            },
            {
              name: "LUNA",
            },
            {
              name: "LUYAO",
            },
            {
              name: "MACAÑAO",
            },
            {
              name: "MACUGAY",
            },
            {
              name: "MAMBABANGA",
            },
            {
              name: "PULAY",
            },
            {
              name: "PUROC",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "UNION KALINGA",
            },
          ],
        },
        MACONACON: {
          name: "MACONACON",
          baranggays: [
            {
              name: "APLAYA",
            },
            {
              name: "CANADAM",
            },
            {
              name: "DIANA",
            },
            {
              name: "ELEONOR (POB.)",
            },
            {
              name: "FELY (POB.)",
            },
            {
              name: "LITA (POB.)",
            },
            {
              name: "MACONACON",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MINANGA",
            },
            {
              name: "REINA MERCEDES",
            },
            {
              name: "SANTA MARINA (DIANGGO)",
            },
          ],
        },
        MALLIG: {
          name: "MALLIG",
          baranggays: [
            {
              name: "BINMONTON",
            },
            {
              name: "CASILI",
            },
            {
              name: "CENTRO I (POB.)",
            },
            {
              name: "CENTRO II (POB.)",
            },
            {
              name: "HOLY FRIDAY",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MALLIG",
            },
            {
              name: "MANANO",
            },
            {
              name: "OLANGO",
            },
            {
              name: "RANG-AYAN",
            },
            {
              name: "SAN JOSE NORTE I",
            },
            {
              name: "SAN JOSE NORTE II",
            },
            {
              name: "SAN JOSE SUR",
            },
            {
              name: "SAN PEDRO (BARUCBUC SUR)",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SIEMPRE VIVA NORTE",
            },
            {
              name: "SIEMPRE VIVA SUR",
            },
            {
              name: "TRINIDAD",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        NAGUILIAN: {
          name: "NAGUILIAN",
          baranggays: [
            {
              name: "AGUINALDO",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "FLORES",
            },
            {
              name: "LA UNION",
            },
            {
              name: "MAGSAYSAY (POB.)",
            },
            {
              name: "MANARING",
            },
            {
              name: "MANSIBANG",
            },
            {
              name: "MINALLO",
            },
            {
              name: "MINANGA",
            },
            {
              name: "NAGUILIAN",
            },
            {
              name: "PALATTAO",
            },
            {
              name: "QUEZON (POB.)",
            },
            {
              name: "QUINALABASA",
            },
            {
              name: "QUIRINO (POB.)",
            },
            {
              name: "RANGAYAN",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROXAS (POB.)",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SANTA VICTORIA (VILLA CAPUCHINO)",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SUNLIFE",
            },
            {
              name: "SURCOC",
            },
            {
              name: "TOMINES",
            },
            {
              name: "VILLA PAZ",
            },
          ],
        },
        PALANAN: {
          name: "PALANAN",
          baranggays: [
            {
              name: "ALOMANAY",
            },
            {
              name: "BISAG",
            },
            {
              name: "CULASI",
            },
            {
              name: "DIALAOYAO",
            },
            {
              name: "DICABISAGAN EAST (POB.)",
            },
            {
              name: "DICABISAGAN WEST (POB.)",
            },
            {
              name: "DICADYUAN",
            },
            {
              name: "DIDDADUNGAN",
            },
            {
              name: "DIDIYAN",
            },
            {
              name: "DIMALICU-LICU",
            },
            {
              name: "DIMASARI",
            },
            {
              name: "DIMATICAN",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MARIKIT",
            },
            {
              name: "PALANAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA JACINTA",
            },
            {
              name: "VILLA ROBLES",
            },
          ],
        },
        QUEZON: {
          name: "QUEZON",
          baranggays: [
            {
              name: "QUEZON",
            },
          ],
        },
        QUIRINO: {
          name: "QUIRINO",
          baranggays: [
            {
              name: "QUIRINO",
            },
          ],
        },
        RAMON: {
          name: "RAMON",
          baranggays: [
            {
              name: "RAMON",
            },
          ],
        },
        "REINA MERCEDES": {
          name: "REINA MERCEDES",
          baranggays: [
            {
              name: "REINA MERCEDES",
            },
          ],
        },
        ROXAS: {
          name: "ROXAS",
          baranggays: [
            {
              name: "ANAO",
            },
            {
              name: "BANTUG (POB.)",
            },
            {
              name: "DOÑA CONCHA",
            },
            {
              name: "IMBIAO",
            },
            {
              name: "LANTING",
            },
            {
              name: "LUCBAN",
            },
            {
              name: "LUNA (POB.)",
            },
            {
              name: "MARCOS",
            },
            {
              name: "MASIGUN",
            },
            {
              name: "MATUSALEM",
            },
            {
              name: "MUÑOZ EAST",
            },
            {
              name: "MUÑOZ WEST",
            },
            {
              name: "QUILING",
            },
            {
              name: "RANG-AYAN",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN PLACIDO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SIMIMBAAN",
            },
            {
              name: "SINAMAR",
            },
            {
              name: "SOTERO NUESA",
            },
            {
              name: "VILLA CONCEPCION",
            },
            {
              name: "VIRA (POB.)",
            },
          ],
        },
        "SAN AGUSTIN": {
          name: "SAN AGUSTIN",
          baranggays: [
            {
              name: "BAUTISTA",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "DABUBU GRANDE",
            },
            {
              name: "DABUBU PEQUEÑO",
            },
            {
              name: "DAPPIG",
            },
            {
              name: "LAOAG",
            },
            {
              name: "MAPALAD",
            },
            {
              name: "MASAYA CENTRO (POB.)",
            },
            {
              name: "MASAYA NORTE",
            },
            {
              name: "MASAYA SUR",
            },
            {
              name: "NEMMATAN",
            },
            {
              name: "PALACIAN",
            },
            {
              name: "PANANG",
            },
            {
              name: "QUIMALABASA NORTE",
            },
            {
              name: "QUIMALABASA SUR",
            },
            {
              name: "RANG-AY",
            },
            {
              name: "SALAY",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTOS",
            },
            {
              name: "SINAOANGAN NORTE",
            },
            {
              name: "SINAOANGAN SUR",
            },
            {
              name: "VIRGONEZA",
            },
          ],
        },
        "SAN GUILLERMO": {
          name: "SAN GUILLERMO",
          baranggays: [
            {
              name: "ANONANG",
            },
            {
              name: "ARINGAY",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CALAOAGAN",
            },
            {
              name: "CENTRO 1 (POB.)",
            },
            {
              name: "CENTRO 2 (POB.)",
            },
            {
              name: "COLORADO",
            },
            {
              name: "DIETBAN",
            },
            {
              name: "DINGADING",
            },
            {
              name: "DIPACAMO",
            },
            {
              name: "ESTRELLA",
            },
            {
              name: "GUAM",
            },
            {
              name: "NAKAR",
            },
            {
              name: "PALAWAN",
            },
            {
              name: "PROGRESO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN FRANCISCO NORTE",
            },
            {
              name: "SAN FRANCISCO SUR",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SAN MARIANO NORTE",
            },
            {
              name: "SAN MARIANO SUR",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SINALUGAN",
            },
            {
              name: "VILLA REMEDIOS",
            },
            {
              name: "VILLA ROSE",
            },
            {
              name: "VILLA SANCHEZ",
            },
            {
              name: "VILLA TERESITA",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "CAMARAG",
            },
            {
              name: "CEBU",
            },
            {
              name: "GOMEZ",
            },
            {
              name: "GUD",
            },
            {
              name: "NAGBUKEL",
            },
            {
              name: "PATANAD",
            },
            {
              name: "QUEZON",
            },
            {
              name: "RAMOS EAST",
            },
            {
              name: "RAMOS WEST",
            },
            {
              name: "RIZAL EAST (POB.)",
            },
            {
              name: "RIZAL WEST (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "VILLAFLOR",
            },
          ],
        },
        "SAN MANUEL": {
          name: "SAN MANUEL",
          baranggays: [
            {
              name: "AGLIAM",
            },
            {
              name: "BABANUANG",
            },
            {
              name: "CABARITAN",
            },
            {
              name: "CARANIOGAN",
            },
            {
              name: "DISTRICT 1 (POB.)",
            },
            {
              name: "DISTRICT 2 (POB.)",
            },
            {
              name: "DISTRICT 3 (POB.)",
            },
            {
              name: "DISTRICT 4 (POB.)",
            },
            {
              name: "EDEN",
            },
            {
              name: "MALALINTA",
            },
            {
              name: "MARARIGUE",
            },
            {
              name: "NUEVA ERA",
            },
            {
              name: "PISANG",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SANDIAT CENTRO",
            },
            {
              name: "SANDIAT EAST",
            },
            {
              name: "SANDIAT WEST",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "VILLANUEVA",
            },
          ],
        },
        "SAN MARIANO": {
          name: "SAN MARIANO",
          baranggays: [
            {
              name: "ALIBADABAD",
            },
            {
              name: "BALAGAN",
            },
            {
              name: "BINATUG",
            },
            {
              name: "BITABIAN",
            },
            {
              name: "BUYASAN",
            },
            {
              name: "CADSALAN",
            },
            {
              name: "CASALA",
            },
            {
              name: "CATAGUING",
            },
            {
              name: "DARAGUTAN EAST",
            },
            {
              name: "DARAGUTAN WEST",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DIBULUAN",
            },
            {
              name: "DICAMAY",
            },
            {
              name: "DIPUSU",
            },
            {
              name: "DISULAP",
            },
            {
              name: "DISUSUAN",
            },
            {
              name: "GANGALAN",
            },
            {
              name: "IBUJAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MACAYUCAYU",
            },
            {
              name: "MALLABO",
            },
            {
              name: "MARANNAO",
            },
            {
              name: "MINANGA",
            },
            {
              name: "OLD SAN MARIANO",
            },
            {
              name: "PALUTAN",
            },
            {
              name: "PANNINAN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA FILOMINA",
            },
            {
              name: "TAPPA",
            },
            {
              name: "UEG",
            },
            {
              name: "ZAMORA",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
          ],
        },
        "SAN MATEO": {
          name: "SAN MATEO",
          baranggays: [
            {
              name: "BACAREÑA",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BELLA LUZ",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "DARAMUANGAN NORTE",
            },
            {
              name: "DARAMUANGAN SUR",
            },
            {
              name: "ESTRELLA",
            },
            {
              name: "GADDANAN",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MAPUROC",
            },
            {
              name: "MAPUROC",
            },
            {
              name: "MARASAT GRANDE",
            },
            {
              name: "MARASAT PEQUEÑO",
            },
            {
              name: "OLD CENTRO I",
            },
            {
              name: "OLD CENTRO II",
            },
            {
              name: "SALINUNGAN EAST",
            },
            {
              name: "SALINUNGAN WEST",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN IGNACIO",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SINAMAR NORTE",
            },
            {
              name: "SINAMAR SUR",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "VILLA CRUZ",
            },
            {
              name: "VILLA GAMIAO (BUYON)",
            },
            {
              name: "VILLA MAGAT",
            },
            {
              name: "VILLAFUERTE",
            },
          ],
        },
        "SAN PABLO": {
          name: "SAN PABLO",
          baranggays: [
            {
              name: "ANNANUMAN",
            },
            {
              name: "AUITAN",
            },
            {
              name: "BALLACAYU",
            },
            {
              name: "BINGUANG",
            },
            {
              name: "BUNGAD",
            },
            {
              name: "CADDANGAN/LIMBAUAN",
            },
            {
              name: "CALAMAGUI",
            },
            {
              name: "CARALUCUD",
            },
            {
              name: "DALENA",
            },
            {
              name: "GUMINGA",
            },
            {
              name: "MINANGA NORTE",
            },
            {
              name: "MINANGA SUR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SIMANU NORTE",
            },
            {
              name: "SIMANU SUR",
            },
            {
              name: "TUPA (SAN VICENTE)",
            },
          ],
        },
        "SANTA MARIA": {
          name: "SANTA MARIA",
          baranggays: [
            {
              name: "BANGAD",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CALAMAGUI EAST",
            },
            {
              name: "CALAMAGUI NORTH",
            },
            {
              name: "CALAMAGUI WEST",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "LINGALING",
            },
            {
              name: "MOZZOZZIN NORTH",
            },
            {
              name: "MOZZOZZIN SUR",
            },
            {
              name: "NAGANACAN",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "QUINAGABIAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO EAST",
            },
            {
              name: "SAN ISIDRO WEST",
            },
            {
              name: "SAN RAFAEL EAST",
            },
            {
              name: "SAN RAFAEL WEST",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "VILLABUENA",
            },
          ],
        },
        "SANTO TOMAS": {
          name: "SANTO TOMAS",
          baranggays: [
            {
              name: "AMMUGAUAN",
            },
            {
              name: "ANTAGAN",
            },
            {
              name: "BAGABAG",
            },
            {
              name: "BAGUTARI",
            },
            {
              name: "BALELLENG",
            },
            {
              name: "BARUMBONG",
            },
            {
              name: "BIGA OCCIDENTAL",
            },
            {
              name: "BIGA ORIENTAL",
            },
            {
              name: "BOLINAO-CULALABO",
            },
            {
              name: "BUBUG",
            },
            {
              name: "CALANIGAN NORTE",
            },
            {
              name: "CALANIGAN SUR",
            },
            {
              name: "CALINAOAN CENTRO",
            },
            {
              name: "CALINAOAN MALASIN",
            },
            {
              name: "CALINAOAN NORTE",
            },
            {
              name: "CAÑOGAN ABAJO NORTE",
            },
            {
              name: "CAÑOGAN ABAJO SUR",
            },
            {
              name: "CAÑOGAN ALTO",
            },
            {
              name: "CENTRO",
            },
            {
              name: "COLUNGUAN",
            },
            {
              name: "MALAPAGAY",
            },
            {
              name: "SAN RAFAEL ABAJO",
            },
            {
              name: "SAN RAFAEL ALTO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "UAUANG-GALICIA",
            },
            {
              name: "UAUANG-TULIAO",
            },
          ],
        },
        "STA. MARCELA": {
          name: "STA. MARCELA",
          baranggays: [
            {
              name: "STA. MARCELA",
            },
          ],
        },
        TUMAUINI: {
          name: "TUMAUINI",
          baranggays: [
            {
              name: "ANNAFUNAN",
            },
            {
              name: "ANTAGAN I",
            },
            {
              name: "ANTAGAN II",
            },
            {
              name: "ARCON",
            },
            {
              name: "BALUG",
            },
            {
              name: "BANIG",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BARANGAY DISTRICT 1 (POB.)",
            },
            {
              name: "BARANGAY DISTRICT 2 (POB.)",
            },
            {
              name: "BARANGAY DISTRICT 3 (POB.)",
            },
            {
              name: "BARANGAY DISTRICT 4 (POB.)",
            },
            {
              name: "BAYABO EAST",
            },
            {
              name: "CALIGAYAN",
            },
            {
              name: "CAMASI",
            },
            {
              name: "CARPENTERO",
            },
            {
              name: "COMPANIA",
            },
            {
              name: "CUMABAO",
            },
            {
              name: "FERMELDY (HCDA. SAN FRANCISCO)",
            },
            {
              name: "FUGU ABAJO",
            },
            {
              name: "FUGU NORTE",
            },
            {
              name: "FUGU SUR",
            },
            {
              name: "LALAUANAN",
            },
            {
              name: "LANNA",
            },
            {
              name: "LAPOGAN",
            },
            {
              name: "LINGALING",
            },
            {
              name: "LIWANAG",
            },
            {
              name: "MALAMAG EAST",
            },
            {
              name: "MALAMAG WEST",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MINANGA",
            },
            {
              name: "MOLDERO",
            },
            {
              name: "NAMNAMA",
            },
            {
              name: "PARAGU",
            },
            {
              name: "PILITAN",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA VISITACION (MAGGAYU)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SINIPPIL",
            },
            {
              name: "SISIM ABAJO",
            },
            {
              name: "SISIM ALTO",
            },
            {
              name: "TUMAUINI",
            },
            {
              name: "TUNGGUI",
            },
            {
              name: "UGAD",
            },
          ],
        },
      },
    },
    KALINGA: {
      name: "KALINGA",
      region: "CAR",
      municipalities: {
        BALBALAN: {
          name: "BALBALAN",
          baranggays: [
            {
              name: "ABABA-AN",
            },
            {
              name: "BALANTOY",
            },
            {
              name: "BALBALAN",
            },
            {
              name: "BALBALAN PROPER",
            },
            {
              name: "BALBALASANG",
            },
            {
              name: "BUAYA",
            },
            {
              name: "DAO-ANGAN",
            },
            {
              name: "GAWA-AN",
            },
            {
              name: "MABACA",
            },
            {
              name: "MALING (KABUGAO)",
            },
            {
              name: "PANTIKIAN",
            },
            {
              name: "POBLACION (SALEGSEG)",
            },
            {
              name: "POSWOY",
            },
            {
              name: "TALALANG",
            },
            {
              name: "TAWANG",
            },
          ],
        },
        BULANAO: {
          name: "BULANAO",
          baranggays: [
            {
              name: "BULANAO",
            },
          ],
        },
        CALANASAN: {
          name: "CALANASAN",
          baranggays: [
            {
              name: "CALANASAN",
            },
          ],
        },
        LUBUAGAN: {
          name: "LUBUAGAN",
          baranggays: [
            {
              name: "ANTONIO CANAO",
            },
            {
              name: "DANGOY",
            },
            {
              name: "LOWER UMA",
            },
            {
              name: "MABILONG",
            },
            {
              name: "MABONGTOT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TANGLAG",
            },
            {
              name: "UMA DEL NORTE (WESTERN LUNA UMA)",
            },
            {
              name: "UPPER UMA",
            },
          ],
        },
        PASIL: {
          name: "PASIL",
          baranggays: [
            {
              name: "ABLEG",
            },
            {
              name: "BAGTAYAN",
            },
            {
              name: "BALATOC",
            },
            {
              name: "BALENCIAGAO SUR",
            },
            {
              name: "BALINCIAGAO NORTE",
            },
            {
              name: "CAGALUAN",
            },
            {
              name: "COLAYO",
            },
            {
              name: "DALUPA",
            },
            {
              name: "DANGTALAN",
            },
            {
              name: "GALDANG (CASALOAN)",
            },
            {
              name: "GUINA-ANG (POB.)",
            },
            {
              name: "MAGSILAY",
            },
            {
              name: "MALUCSAD",
            },
            {
              name: "PASIL",
            },
            {
              name: "PUGONG",
            },
          ],
        },
        PINUKPUK: {
          name: "PINUKPUK",
          baranggays: [
            {
              name: "ACIGA",
            },
            {
              name: "ALLAGUIA",
            },
            {
              name: "AMMACIAN",
            },
            {
              name: "APATAN",
            },
            {
              name: "ASIBANGLAN",
            },
            {
              name: "BA-AY",
            },
            {
              name: "BALLAYANGON",
            },
            {
              name: "BAYAO",
            },
            {
              name: "CAMALOG",
            },
            {
              name: "CAWAGAYAN",
            },
            {
              name: "DUGPA",
            },
            {
              name: "KATABBOGAN",
            },
            {
              name: "LIMOS",
            },
            {
              name: "MAGAOGAO",
            },
            {
              name: "MALAGNAT",
            },
            {
              name: "MAPACO",
            },
            {
              name: "PAKAWIT",
            },
            {
              name: "PINOCOC",
            },
            {
              name: "PINUKPUK",
            },
            {
              name: "PINUKPUK JUNCTION",
            },
            {
              name: "SOCBOT",
            },
            {
              name: "TAGA (POB.)",
            },
            {
              name: "TAGGAY",
            },
            {
              name: "WAGUD",
            },
          ],
        },
        RIZAL: {
          name: "RIZAL",
          baranggays: [
            {
              name: "RIZAL",
            },
          ],
        },
        "RIZAL (LIWAN)": {
          name: "RIZAL (LIWAN)",
          baranggays: [
            {
              name: "BABALAG EAST (POB.)",
            },
            {
              name: "BABALAG WEST (POB.)",
            },
            {
              name: "BULBOL",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "KINAMA",
            },
            {
              name: "LIWAN EAST",
            },
            {
              name: "LIWAN WEST",
            },
            {
              name: "MACUTAY",
            },
            {
              name: "ROMUALDEZ",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN QUINTIN",
            },
            {
              name: "SANTOR",
            },
          ],
        },
        TABUK: {
          name: "TABUK",
          baranggays: [
            {
              name: "AGBANNAWAG",
            },
            {
              name: "AMLAO",
            },
            {
              name: "APPAS",
            },
            {
              name: "BADO DANGWA",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALAWAG",
            },
            {
              name: "BALONG",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BULANAO",
            },
            {
              name: "BULANAO NORTE",
            },
            {
              name: "BULO",
            },
            {
              name: "CABARITAN",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CALACCAD",
            },
            {
              name: "CALANAN",
            },
            {
              name: "CASIGAYAN",
            },
            {
              name: "CUDAL",
            },
            {
              name: "DAGUPAN CENTRO (POB.)",
            },
            {
              name: "DAGUPAN WESTE",
            },
            {
              name: "DILAG",
            },
            {
              name: "DUPAG",
            },
            {
              name: "GOBGOB",
            },
            {
              name: "GUILAYON",
            },
            {
              name: "IPIL",
            },
            {
              name: "LACNOG",
            },
            {
              name: "LANNA",
            },
            {
              name: "LAYA EAST",
            },
            {
              name: "LAYA WEST",
            },
            {
              name: "LUCOG",
            },
            {
              name: "MAGNAO",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALALAO",
            },
            {
              name: "MALIN-AWA",
            },
            {
              name: "MASABLANG",
            },
            {
              name: "NAMBARAN",
            },
            {
              name: "NAMBUCAYAN",
            },
            {
              name: "NANENG",
            },
            {
              name: "NEW TANGLAG",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SUYANG",
            },
            {
              name: "TABUK",
            },
            {
              name: "TUGA",
            },
          ],
        },
        TANUDAN: {
          name: "TANUDAN",
          baranggays: [
            {
              name: "ANGGACAN",
            },
            {
              name: "ANGGACAN SUR",
            },
            {
              name: "BABBANOY",
            },
            {
              name: "DACALAN",
            },
            {
              name: "DUPLIGAN",
            },
            {
              name: "GAANG",
            },
            {
              name: "LAY-ASAN",
            },
            {
              name: "LOWER LUBO",
            },
            {
              name: "LOWER MANGALI",
            },
            {
              name: "LOWER TALOCTOC",
            },
            {
              name: "MABACA",
            },
            {
              name: "MANGALI CENTRO",
            },
            {
              name: "PANGOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TANUDAN",
            },
            {
              name: "UPPER LUBO",
            },
            {
              name: "UPPER TALOCTOC",
            },
          ],
        },
        TINGLAYAN: {
          name: "TINGLAYAN",
          baranggays: [
            {
              name: "AMBATO LEGLEG",
            },
            {
              name: "BANGAD CENTRO",
            },
            {
              name: "BASAO",
            },
            {
              name: "BELONG MANUBAL",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "BUSCALAN (BUSCALAN-LOCONG)",
            },
            {
              name: "BUTBUT (BUTBUT-NGIBAT)",
            },
            {
              name: "DANANAO",
            },
            {
              name: "LOCCONG",
            },
            {
              name: "LOWER BANGAD",
            },
            {
              name: "LUPLUPA",
            },
            {
              name: "MALLANGO",
            },
            {
              name: "NGIBAT",
            },
            {
              name: "OLD TINGLAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SUMADEL 1",
            },
            {
              name: "SUMADEL 2",
            },
            {
              name: "TINGLAYAN",
            },
            {
              name: "TULGAO EAST",
            },
            {
              name: "TULGAO WEST",
            },
            {
              name: "UPPER BANGAD",
            },
          ],
        },
      },
    },
    "LA UNION": {
      name: "LA UNION",
      region: "REGION 1",
      municipalities: {
        AGOO: {
          name: "AGOO",
          baranggays: [
            {
              name: "AGOO",
            },
            {
              name: "AMBITACAY",
            },
            {
              name: "BALAWARTE",
            },
            {
              name: "CAPAS",
            },
            {
              name: "CONSOLACION (POB.)",
            },
            {
              name: "MACALVA CENTRAL",
            },
            {
              name: "MACALVA NORTE",
            },
            {
              name: "MACALVA SUR",
            },
            {
              name: "NAZARENO",
            },
            {
              name: "PUROK",
            },
            {
              name: "SAN AGUSTIN EAST",
            },
            {
              name: "SAN AGUSTIN NORTE",
            },
            {
              name: "SAN AGUSTIN SUR",
            },
            {
              name: "SAN ANTONINO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOAQUIN NORTE",
            },
            {
              name: "SAN JOAQUIN SUR",
            },
            {
              name: "SAN JOSE NORTE",
            },
            {
              name: "SAN JOSE SUR",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN JULIAN CENTRAL",
            },
            {
              name: "SAN JULIAN EAST",
            },
            {
              name: "SAN JULIAN NORTE",
            },
            {
              name: "SAN JULIAN WEST",
            },
            {
              name: "SAN MANUEL NORTE",
            },
            {
              name: "SAN MANUEL SUR",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS CENTRAL (POB.)",
            },
            {
              name: "SAN NICOLAS EAST",
            },
            {
              name: "SAN NICOLAS NORTE (POB.)",
            },
            {
              name: "SAN NICOLAS SUR (POB.)",
            },
            {
              name: "SAN NICOLAS WEST",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE EAST",
            },
            {
              name: "SAN ROQUE WEST",
            },
            {
              name: "SAN VICENTE NORTE",
            },
            {
              name: "SAN VICENTE SUR",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA BARBARA (POB.)",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA RITA (NALINAC)",
            },
            {
              name: "SANTA RITA EAST",
            },
            {
              name: "SANTA RITA NORTE",
            },
            {
              name: "SANTA RITA SUR",
            },
            {
              name: "SANTA RITA WEST",
            },
          ],
        },
        ARINGAY: {
          name: "ARINGAY",
          baranggays: [
            {
              name: "ALASKA",
            },
            {
              name: "ARINGAY",
            },
            {
              name: "BASCA",
            },
            {
              name: "DULAO",
            },
            {
              name: "GALLANO",
            },
            {
              name: "MACABATO",
            },
            {
              name: "MANGA",
            },
            {
              name: "PANGAO-AOAN EAST",
            },
            {
              name: "PANGAO-AOAN WEST",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMARA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BENITO NORTE",
            },
            {
              name: "SAN BENITO SUR",
            },
            {
              name: "SAN EUGENIO",
            },
            {
              name: "SAN JUAN EAST",
            },
            {
              name: "SAN JUAN WEST",
            },
            {
              name: "SAN SIMON EAST",
            },
            {
              name: "SAN SIMON WEST",
            },
            {
              name: "SANTA CECILIA",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA RITA EAST",
            },
            {
              name: "SANTA RITA WEST",
            },
            {
              name: "SANTO ROSARIO EAST",
            },
            {
              name: "SANTO ROSARIO WEST",
            },
          ],
        },
        BACNOTAN: {
          name: "BACNOTAN",
          baranggays: [
            {
              name: "AGTIPAL",
            },
            {
              name: "AROSIP",
            },
            {
              name: "BACNOTAN",
            },
            {
              name: "BACQUI",
            },
            {
              name: "BACSIL",
            },
            {
              name: "BAGUTOT",
            },
            {
              name: "BALLOGO",
            },
            {
              name: "BARORO",
            },
            {
              name: "BITALAG",
            },
            {
              name: "BULALA",
            },
            {
              name: "BURAYOC",
            },
            {
              name: "BUSSAOIT",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "CABARSICAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CALAUTIT",
            },
            {
              name: "CARCARMAY",
            },
            {
              name: "CASIAMAN",
            },
            {
              name: "GALONGEN",
            },
            {
              name: "GUINABANG",
            },
            {
              name: "LEGLEG",
            },
            {
              name: "LISQUEB",
            },
            {
              name: "MABANENGBENG 1ST",
            },
            {
              name: "MABANENGBENG 2ND",
            },
            {
              name: "MARAGAYAP",
            },
            {
              name: "NAGATIRAN",
            },
            {
              name: "NAGSARABOAN",
            },
            {
              name: "NAGSIMBAANAN",
            },
            {
              name: "NANGALISAN",
            },
            {
              name: "NARRA",
            },
            {
              name: "ORTEGA",
            },
            {
              name: "OYA-OY",
            },
            {
              name: "PAAGAN",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PANG-PANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUIRINO",
            },
            {
              name: "RAOIS",
            },
            {
              name: "SALINCOB",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SAPILANG",
            },
            {
              name: "SAYOAN",
            },
            {
              name: "SIPULO",
            },
            {
              name: "TAMMOCALAO",
            },
            {
              name: "UBBOG",
            },
            {
              name: "ZARAGOSA",
            },
          ],
        },
        BAGULIN: {
          name: "BAGULIN",
          baranggays: [
            {
              name: "ALIBANGSAY",
            },
            {
              name: "BAAY",
            },
            {
              name: "BAGULIN",
            },
            {
              name: "CAMBALY",
            },
            {
              name: "CARDIZ",
            },
            {
              name: "DAGUP",
            },
            {
              name: "LIBBO",
            },
            {
              name: "SUYO (POB.)",
            },
            {
              name: "TAGUDTUD",
            },
            {
              name: "TIO-ANGAN",
            },
            {
              name: "WALLAYAN",
            },
          ],
        },
        BALAOAN: {
          name: "BALAOAN",
          baranggays: [
            {
              name: "ALMIEDA",
            },
            {
              name: "ANTONINO",
            },
            {
              name: "APATUT",
            },
            {
              name: "AR-ARAMPANG",
            },
            {
              name: "BALAOAN",
            },
            {
              name: "BARACBAC ESTE",
            },
            {
              name: "BARACBAC OESTE",
            },
            {
              name: "BET-ANG",
            },
            {
              name: "BULBULALA",
            },
            {
              name: "BUNGOL",
            },
            {
              name: "BUTUBUT ESTE",
            },
            {
              name: "BUTUBUT NORTE",
            },
            {
              name: "BUTUBUT OESTE",
            },
            {
              name: "BUTUBUT SUR",
            },
            {
              name: "CABUAAN OESTE (POB.)",
            },
            {
              name: "CALLIAT",
            },
            {
              name: "CALUNGBUYAN",
            },
            {
              name: "CAMILING",
            },
            {
              name: "DR. CAMILO OSIAS POB. (CABUAAN ESTE)",
            },
            {
              name: "GUINABURAN",
            },
            {
              name: "MASUPE",
            },
            {
              name: "NAGSABARAN NORTE",
            },
            {
              name: "NAGSABARAN SUR",
            },
            {
              name: "NALASIN",
            },
            {
              name: "NAPASET",
            },
            {
              name: "PA-O",
            },
            {
              name: "PAGBENNECAN",
            },
            {
              name: "PAGLEDDEGAN",
            },
            {
              name: "PANTAR NORTE",
            },
            {
              name: "PANTAR SUR",
            },
            {
              name: "PARAOIR",
            },
            {
              name: "PATPATA",
            },
            {
              name: "SABLUT",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SINAPANGAN NORTE",
            },
            {
              name: "SINAPANGAN SUR",
            },
            {
              name: "TALLIPUGO",
            },
          ],
        },
        BANGAR: {
          name: "BANGAR",
          baranggays: [
            {
              name: "AGDEPPA",
            },
            {
              name: "ALZATE",
            },
            {
              name: "BANGAOILAN EAST",
            },
            {
              name: "BANGAOILAN WEST",
            },
            {
              name: "BANGAR",
            },
            {
              name: "BARRACA",
            },
            {
              name: "CADAPLI",
            },
            {
              name: "CAGGAO",
            },
            {
              name: "CENTRAL EAST NO. 1 (POB.)",
            },
            {
              name: "CENTRAL EAST NO. 2 (POB.)",
            },
            {
              name: "CENTRAL WEST NO. 1 (POB.)",
            },
            {
              name: "CENTRAL WEST NO. 2 (POB.)",
            },
            {
              name: "CENTRAL WEST NO. 3 (POB.)",
            },
            {
              name: "CONSUEGRA",
            },
            {
              name: "GENERAL PRIM EAST",
            },
            {
              name: "GENERAL PRIM WEST",
            },
            {
              name: "GENERAL TERRERO",
            },
            {
              name: "LUZONG NORTE",
            },
            {
              name: "LUZONG SUR",
            },
            {
              name: "MARIA CRISTINA EAST",
            },
            {
              name: "MARIA CRISTINA WEST",
            },
            {
              name: "MINDORO",
            },
            {
              name: "NAGSABARAN",
            },
            {
              name: "PARATONG NO. 3",
            },
            {
              name: "PARATONG NO. 4",
            },
            {
              name: "PARATONG NORTE",
            },
            {
              name: "QUINTARONG",
            },
            {
              name: "REYNA REGENTE",
            },
            {
              name: "RISSING",
            },
            {
              name: "SAN BLAS",
            },
            {
              name: "SAN CRISTOBAL",
            },
            {
              name: "SINAPANGAN NORTE",
            },
            {
              name: "SINAPANGAN SUR",
            },
            {
              name: "UBBOG",
            },
          ],
        },
        BAUANG: {
          name: "BAUANG",
          baranggays: [
            {
              name: "ACAO",
            },
            {
              name: "BACCUIT NORTE",
            },
            {
              name: "BACCUIT SUR",
            },
            {
              name: "BAGBAG",
            },
            {
              name: "BALLAY",
            },
            {
              name: "BAUANG",
            },
            {
              name: "BAWANTA",
            },
            {
              name: "BOY-UTAN",
            },
            {
              name: "BUCAYAB",
            },
            {
              name: "CABALAYANGAN",
            },
            {
              name: "CABISILAN",
            },
            {
              name: "CALUMBAYA",
            },
            {
              name: "CARMAY",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "CENTRAL EAST (POB.)",
            },
            {
              name: "CENTRAL WEST (POB.)",
            },
            {
              name: "DILI",
            },
            {
              name: "DISSO-OR",
            },
            {
              name: "GUERRERO",
            },
            {
              name: "LOWER SAN AGUSTIN",
            },
            {
              name: "NAGREBCAN",
            },
            {
              name: "PAGDALAGAN SUR",
            },
            {
              name: "PALINTUCANG",
            },
            {
              name: "PALUGSI-LIMMANSANGAN",
            },
            {
              name: "PARIAN ESTE",
            },
            {
              name: "PARIAN OESTE",
            },
            {
              name: "PARINGAO",
            },
            {
              name: "PAYOCPOC NORTE ESTE",
            },
            {
              name: "PAYOCPOC NORTE OESTE",
            },
            {
              name: "PAYOCPOC SUR",
            },
            {
              name: "PILAR",
            },
            {
              name: "POTTOT",
            },
            {
              name: "PUDOC",
            },
            {
              name: "PUGO",
            },
            {
              name: "QUINAVITE",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "TABERNA",
            },
            {
              name: "UPPER SAN AGUSTIN",
            },
            {
              name: "URAYONG",
            },
          ],
        },
        BURGOS: {
          name: "BURGOS",
          baranggays: [
            {
              name: "AGPAY",
            },
            {
              name: "BILIS",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CAOAYAN",
            },
            {
              name: "DALACDAC",
            },
            {
              name: "DELLES",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "LINUAN",
            },
            {
              name: "LOWER TUMAPOC",
            },
            {
              name: "NEW POBLACION",
            },
            {
              name: "OLD POBLACION",
            },
            {
              name: "UPPER TUMAPOC",
            },
          ],
        },
        CABA: {
          name: "CABA",
          baranggays: [
            {
              name: "BAUTISTA",
            },
            {
              name: "CABA",
            },
            {
              name: "GANA",
            },
            {
              name: "JUAN CARTAS",
            },
            {
              name: "LAS-UD",
            },
            {
              name: "LIQUICIA",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN CORNELIO",
            },
            {
              name: "SAN FERMIN",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTIAGO NORTE",
            },
            {
              name: "SANTIAGO SUR",
            },
            {
              name: "SOBREDILLO",
            },
            {
              name: "URAYONG",
            },
            {
              name: "WENCESLAO",
            },
          ],
        },
        "CITY OF SAN FERNANDO": {
          name: "CITY OF SAN FERNANDO",
          baranggays: [
            {
              name: "ABUT",
            },
            {
              name: "APALENG",
            },
            {
              name: "BACSIL",
            },
            {
              name: "BANGBANGOLAN",
            },
            {
              name: "BANGCUSAY",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARAOAS",
            },
            {
              name: "BATO",
            },
            {
              name: "BIDAY",
            },
            {
              name: "BIRUNGET",
            },
            {
              name: "BUNGRO",
            },
            {
              name: "CABAROAN (NEGRO)",
            },
            {
              name: "CABARSICAN",
            },
            {
              name: "CADACLAN",
            },
            {
              name: "CALABUGAO",
            },
            {
              name: "CAMANSI",
            },
            {
              name: "CANAOAY",
            },
            {
              name: "CARLATAN",
            },
            {
              name: "CATBANGEN",
            },
            {
              name: "CITY OF SAN FERNANDO",
            },
            {
              name: "DALLANGAYAN ESTE",
            },
            {
              name: "DALLANGAYAN OESTE",
            },
            {
              name: "DALUMPINAS ESTE",
            },
            {
              name: "DALUMPINAS OESTE",
            },
            {
              name: "ILOCANOS NORTE",
            },
            {
              name: "ILOCANOS SUR",
            },
            {
              name: "LANGCUAS",
            },
            {
              name: "LINGSAT",
            },
            {
              name: "MADAYEGDEG",
            },
            {
              name: "MAMELTAC",
            },
            {
              name: "MASICONG",
            },
            {
              name: "NAGYUBUYUBAN",
            },
            {
              name: "NAMTUTAN",
            },
            {
              name: "NARRA ESTE",
            },
            {
              name: "NARRA OESTE",
            },
            {
              name: "PACPACO",
            },
            {
              name: "PAGDALAGAN",
            },
            {
              name: "PAGDARAOAN",
            },
            {
              name: "PAGUDPUD",
            },
            {
              name: "PAO NORTE",
            },
            {
              name: "PAO SUR",
            },
            {
              name: "PARIAN",
            },
            {
              name: "PIAS",
            },
            {
              name: "PORO",
            },
            {
              name: "PUSPUS",
            },
            {
              name: "SACYUD",
            },
            {
              name: "SAGAYAD",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTIAGO NORTE",
            },
            {
              name: "SANTIAGO SUR",
            },
            {
              name: "SAOAY",
            },
            {
              name: "SEVILLA",
            },
            {
              name: "SIBOAN-OTONG",
            },
            {
              name: "TANQUI",
            },
            {
              name: "TANQUIGAN",
            },
          ],
        },
        LUNA: {
          name: "LUNA",
          baranggays: [
            {
              name: "ALCALA (POB.)",
            },
            {
              name: "AYAOAN",
            },
            {
              name: "BARANGOBONG",
            },
            {
              name: "BARRIENTOS",
            },
            {
              name: "BUNGRO",
            },
            {
              name: "BUSELBUSEL",
            },
            {
              name: "CABALITOCAN",
            },
            {
              name: "CANTORIA NO. 1",
            },
            {
              name: "CANTORIA NO. 2",
            },
            {
              name: "CANTORIA NO. 3",
            },
            {
              name: "CANTORIA NO. 4",
            },
            {
              name: "CARISQUIS",
            },
            {
              name: "DARIGAYOS",
            },
            {
              name: "LUNA",
            },
            {
              name: "MAGALLANES (POB.)",
            },
            {
              name: "MAGSIPING",
            },
            {
              name: "MAMAY",
            },
            {
              name: "NAGREBCAN",
            },
            {
              name: "NALVO NORTE",
            },
            {
              name: "NALVO SUR",
            },
            {
              name: "NAPASET",
            },
            {
              name: "OAQUI NO. 1",
            },
            {
              name: "OAQUI NO. 2",
            },
            {
              name: "OAQUI NO. 3",
            },
            {
              name: "OAQUI NO. 4",
            },
            {
              name: "PILA",
            },
            {
              name: "PITPITAC",
            },
            {
              name: "RIMOS NO. 1",
            },
            {
              name: "RIMOS NO. 2",
            },
            {
              name: "RIMOS NO. 3",
            },
            {
              name: "RIMOS NO. 4",
            },
            {
              name: "RIMOS NO. 5",
            },
            {
              name: "RISSING",
            },
            {
              name: "SALCEDO (POB.)",
            },
            {
              name: "SANTO DOMINGO NORTE",
            },
            {
              name: "SANTO DOMINGO SUR",
            },
            {
              name: "SUCOC NORTE",
            },
            {
              name: "SUCOC SUR",
            },
            {
              name: "SUYO",
            },
            {
              name: "TALLAOEN",
            },
            {
              name: "VICTORIA (POB.)",
            },
          ],
        },
        NAGUILIAN: {
          name: "NAGUILIAN",
          baranggays: [
            {
              name: "AGUIOAS",
            },
            {
              name: "AL-ALINAO NORTE",
            },
            {
              name: "AL-ALINAO SUR",
            },
            {
              name: "AMBARACAO NORTE",
            },
            {
              name: "AMBARACAO SUR",
            },
            {
              name: "ANGIN",
            },
            {
              name: "BALECBEC",
            },
            {
              name: "BANCAGAN",
            },
            {
              name: "BARAOAS NORTE",
            },
            {
              name: "BARAOAS SUR",
            },
            {
              name: "BARIQUIR",
            },
            {
              name: "BATO",
            },
            {
              name: "BIMMOTOBOT",
            },
            {
              name: "CABARITAN NORTE",
            },
            {
              name: "CABARITAN SUR",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "DAL-LIPAOEN",
            },
            {
              name: "DARAMUANGAN",
            },
            {
              name: "GUESSET",
            },
            {
              name: "GUSING NORTE",
            },
            {
              name: "GUSING SUR",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LIOAC NORTE",
            },
            {
              name: "LIOAC SUR",
            },
            {
              name: "MAGUNGUNAY",
            },
            {
              name: "MAMAT-ING NORTE",
            },
            {
              name: "MAMAT-ING SUR",
            },
            {
              name: "NAGSIDORISAN",
            },
            {
              name: "NAGUILIAN",
            },
            {
              name: "NATIVIDAD (POB.)",
            },
            {
              name: "ORTIZ (POB.)",
            },
            {
              name: "RIBSUAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SILI",
            },
            {
              name: "SUGUIDAN NORTE",
            },
            {
              name: "SUGUIDAN SUR",
            },
            {
              name: "TUDDINGAN",
            },
          ],
        },
        PUGO: {
          name: "PUGO",
          baranggays: [
            {
              name: "AMBALITE",
            },
            {
              name: "AMBANGONAN",
            },
            {
              name: "CARES",
            },
            {
              name: "CUENCA",
            },
            {
              name: "DUPLAS",
            },
            {
              name: "MAOASOAS NORTE",
            },
            {
              name: "MAOASOAS SUR",
            },
            {
              name: "PALINA",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PUGO",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAYTAN",
            },
            {
              name: "TAVORA EAST",
            },
            {
              name: "TAVORA PROPER",
            },
          ],
        },
        ROSARIO: {
          name: "ROSARIO",
          baranggays: [
            {
              name: "ALIPANG",
            },
            {
              name: "AMBANGONAN",
            },
            {
              name: "AMLANG",
            },
            {
              name: "BACANI",
            },
            {
              name: "BANGAR",
            },
            {
              name: "BANI",
            },
            {
              name: "BENTENG-SAPILANG",
            },
            {
              name: "CADUMANIAN",
            },
            {
              name: "CAMP ONE",
            },
            {
              name: "CARUNUAN EAST",
            },
            {
              name: "CARUNUAN WEST",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "CATAGUINGTINGAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAMORTIS",
            },
            {
              name: "GUMOT-NAGCOLARAN",
            },
            {
              name: "INABAAN NORTE",
            },
            {
              name: "INABAAN SUR",
            },
            {
              name: "MARCOS",
            },
            {
              name: "NAGTAGAAN",
            },
            {
              name: "NANGCAMOTIAN",
            },
            {
              name: "PARASAPAS",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PUZON",
            },
            {
              name: "RABON",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SUBUSUB",
            },
            {
              name: "TABTABUNGAO",
            },
            {
              name: "TANGLAG",
            },
            {
              name: "TAY-AC",
            },
            {
              name: "UDIAO",
            },
            {
              name: "VILA",
            },
          ],
        },
        "SAN GABRIEL": {
          name: "SAN GABRIEL",
          baranggays: [
            {
              name: "AMONTOC",
            },
            {
              name: "APAYAO",
            },
            {
              name: "BALBALAYANG",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BUCAO",
            },
            {
              name: "BUMBUNEG",
            },
            {
              name: "DAKING",
            },
            {
              name: "LACONG",
            },
            {
              name: "LIPAY ESTE",
            },
            {
              name: "LIPAY NORTE",
            },
            {
              name: "LIPAY PROPER",
            },
            {
              name: "LIPAY SUR",
            },
            {
              name: "LON-OY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLIPOL",
            },
            {
              name: "SAN GABRIEL",
            },
          ],
        },
        "SAN JUAN": {
          name: "SAN JUAN",
          baranggays: [
            {
              name: "ALLANGIGAN",
            },
            {
              name: "ALUDAID",
            },
            {
              name: "BACSAYAN",
            },
            {
              name: "BALBALLOSA",
            },
            {
              name: "BAMBANAY",
            },
            {
              name: "BUGBUGCAO",
            },
            {
              name: "CAARUSIPAN",
            },
            {
              name: "CABAROAN",
            },
            {
              name: "CABUGNAYAN",
            },
            {
              name: "CACAPIAN",
            },
            {
              name: "CACULANGAN",
            },
            {
              name: "CALINCAMASAN",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "CATDONGAN",
            },
            {
              name: "DANGDANGLA",
            },
            {
              name: "DASAY",
            },
            {
              name: "DINANUM",
            },
            {
              name: "DUPLAS",
            },
            {
              name: "GUINGUINABANG",
            },
            {
              name: "ILI NORTE (POB.)",
            },
            {
              name: "ILI SUR (POB.)",
            },
            {
              name: "LEGLEG",
            },
            {
              name: "LUBING",
            },
            {
              name: "NADSAAG",
            },
            {
              name: "NAGSABARAN",
            },
            {
              name: "NAGUIRANGAN",
            },
            {
              name: "NAGUITUBAN",
            },
            {
              name: "NAGYUBUYUBAN",
            },
            {
              name: "OAQUING",
            },
            {
              name: "PACPACAC",
            },
            {
              name: "PAGDILDILAN",
            },
            {
              name: "PANICSICAN",
            },
            {
              name: "QUIDEM",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SARACAT",
            },
            {
              name: "SINAPANGAN",
            },
            {
              name: "TABOC",
            },
            {
              name: "TALOGTOG",
            },
            {
              name: "URBIZTONDO",
            },
          ],
        },
        "SANTO TOMAS": {
          name: "SANTO TOMAS",
          baranggays: [
            {
              name: "AMBITACAY",
            },
            {
              name: "BAIL",
            },
            {
              name: "BALAOC",
            },
            {
              name: "BALSAAN",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CASANTAAN",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "CUPANG",
            },
            {
              name: "DAMORTIS",
            },
            {
              name: "FERNANDO",
            },
            {
              name: "LINONG",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "MALABAGO",
            },
            {
              name: "NAMBOONGAN",
            },
            {
              name: "NAMONITAN",
            },
            {
              name: "NARVACAN",
            },
            {
              name: "PATAC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PONGPONG",
            },
            {
              name: "RAOIS",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TOCOC",
            },
            {
              name: "TUBOD",
            },
            {
              name: "UBAGAN",
            },
          ],
        },
        SANTOL: {
          name: "SANTOL",
          baranggays: [
            {
              name: "CORROOY",
            },
            {
              name: "LETTAC NORTE",
            },
            {
              name: "LETTAC SUR",
            },
            {
              name: "MANGAAN",
            },
            {
              name: "PAAGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUGUIL",
            },
            {
              name: "RAMOT",
            },
            {
              name: "SANTOL",
            },
            {
              name: "SAPDAAN",
            },
            {
              name: "SASABA",
            },
            {
              name: "TUBADAY",
            },
          ],
        },
        SUDIPEN: {
          name: "SUDIPEN",
          baranggays: [
            {
              name: "BIGBIGA",
            },
            {
              name: "BULALAAN",
            },
            {
              name: "CASTRO",
            },
            {
              name: "DUPLAS",
            },
            {
              name: "ILOCANO",
            },
            {
              name: "IPET",
            },
            {
              name: "MALICLICO",
            },
            {
              name: "NAMALTUGAN",
            },
            {
              name: "OLD CENTRAL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PORPORIKET",
            },
            {
              name: "SAN FRANCISCO NORTE",
            },
            {
              name: "SAN FRANCISCO SUR",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SENGNGAT",
            },
            {
              name: "SUDIPEN",
            },
            {
              name: "TUROD",
            },
            {
              name: "UP-UPLAS",
            },
          ],
        },
        TUBAO: {
          name: "TUBAO",
          baranggays: [
            {
              name: "AMALLAPAY",
            },
            {
              name: "ANDUYAN",
            },
            {
              name: "CAOIGUE",
            },
            {
              name: "FRANCIA SUR",
            },
            {
              name: "FRANCIA WEST",
            },
            {
              name: "GARCIA",
            },
            {
              name: "GONZALES",
            },
            {
              name: "HALOG EAST",
            },
            {
              name: "HALOG WEST",
            },
            {
              name: "LEONES EAST",
            },
            {
              name: "LEONES WEST",
            },
            {
              name: "LINAPEW",
            },
            {
              name: "LLOREN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "PIDEG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SANTA TERESA",
            },
            {
              name: "TUBAO",
            },
          ],
        },
      },
    },
    LAGUNA: {
      name: "LAGUNA",
      region: "REGION 4-A",
      municipalities: {
        ALAMINOS: {
          name: "ALAMINOS",
          baranggays: [
            {
              name: "ALAMINOS",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "PALMA",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN BENITO",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN ILDEFONSO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ROSA",
            },
          ],
        },
        BAY: {
          name: "BAY",
          baranggays: [
            {
              name: "BAY",
            },
            {
              name: "BITIN",
            },
            {
              name: "CALO",
            },
            {
              name: "DILA",
            },
            {
              name: "MAITIM",
            },
            {
              name: "MASAYA",
            },
            {
              name: "PACIANO RIZAL",
            },
            {
              name: "PUYPUY",
            },
            {
              name: "SAN AGUSTIN (POB.)",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TRANCA",
            },
          ],
        },
        CABUYAO: {
          name: "CABUYAO",
          baranggays: [
            {
              name: "BACLARAN",
            },
            {
              name: "BANAYBANAY",
            },
            {
              name: "BANLIC",
            },
            {
              name: "BARANGAY DOS (POB.)",
            },
            {
              name: "BARANGAY TRES (POB.)",
            },
            {
              name: "BARANGAY UNO (POB.)",
            },
            {
              name: "BIGAA",
            },
            {
              name: "BUTONG",
            },
            {
              name: "CABUYAO",
            },
            {
              name: "CASILE",
            },
            {
              name: "DIEZMO",
            },
            {
              name: "GULOD",
            },
            {
              name: "MAMATID",
            },
            {
              name: "MARINIG",
            },
            {
              name: "NIUGAN",
            },
            {
              name: "PITTLAND",
            },
            {
              name: "PULO",
            },
            {
              name: "SALA",
            },
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        "CALAMBA CITY": {
          name: "CALAMBA CITY",
          baranggays: [
            {
              name: "BAGONG KALSADA",
            },
            {
              name: "BANADERO",
            },
            {
              name: "BANLIC",
            },
            {
              name: "BARANDAL",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BATINO",
            },
            {
              name: "BUBUYAN",
            },
            {
              name: "BUCAL",
            },
            {
              name: "BUNGGO",
            },
            {
              name: "BUROL",
            },
            {
              name: "CALAMBA CITY",
            },
            {
              name: "CAMALIGAN",
            },
            {
              name: "CANLUBANG",
            },
            {
              name: "HALANG",
            },
            {
              name: "HORNALAN",
            },
            {
              name: "KAY-ANLOG",
            },
            {
              name: "LA MESA",
            },
            {
              name: "LAGUERTA",
            },
            {
              name: "LAWA",
            },
            {
              name: "LECHERIA",
            },
            {
              name: "LINGGA",
            },
            {
              name: "LOOC",
            },
            {
              name: "MABATO",
            },
            {
              name: "MAJADA LABAS",
            },
            {
              name: "MAKILING",
            },
            {
              name: "MAPAGONG",
            },
            {
              name: "MASILI",
            },
            {
              name: "MAUNONG",
            },
            {
              name: "MAYAPA",
            },
            {
              name: "MILAGROSA (TULO)",
            },
            {
              name: "PACIANO RIZAL",
            },
            {
              name: "PALINGON",
            },
            {
              name: "PALO-ALTO",
            },
            {
              name: "PANSOL",
            },
            {
              name: "PARIAN",
            },
            {
              name: "PRINZA",
            },
            {
              name: "PUNTA",
            },
            {
              name: "PUTING LUPA",
            },
            {
              name: "REAL",
            },
            {
              name: "SAIMSIM",
            },
            {
              name: "SAMPIRUHAN",
            },
            {
              name: "SAN CRISTOBAL",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SIRANG LUPA",
            },
            {
              name: "SUCOL",
            },
            {
              name: "TURBINA",
            },
            {
              name: "ULANGO",
            },
            {
              name: "UWISAN",
            },
          ],
        },
        CALAUAN: {
          name: "CALAUAN",
          baranggays: [
            {
              name: "BALAYHANGIN",
            },
            {
              name: "BANGYAS",
            },
            {
              name: "CALAUAN",
            },
            {
              name: "DAYAP",
            },
            {
              name: "DAYAP 2 (NHA)",
            },
            {
              name: "HANGGAN",
            },
            {
              name: "IMOK",
            },
            {
              name: "KANLURAN (POB.)",
            },
            {
              name: "LAMOT 1",
            },
            {
              name: "LAMOT 2",
            },
            {
              name: "LIMAO",
            },
            {
              name: "MABACAN",
            },
            {
              name: "MASIIT",
            },
            {
              name: "PALIPARAN",
            },
            {
              name: "PEREZ",
            },
            {
              name: "PRINZA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SILANGAN (POB.)",
            },
          ],
        },
        CAVINTI: {
          name: "CAVINTI",
          baranggays: [
            {
              name: "ANGLAS",
            },
            {
              name: "BANGCO",
            },
            {
              name: "BUKAL",
            },
            {
              name: "BULAJO",
            },
            {
              name: "CANSUSO",
            },
            {
              name: "CAVINTI",
            },
            {
              name: "DUHAT",
            },
            {
              name: "INAO-AWAN",
            },
            {
              name: "KANLURAN TALAONGAN",
            },
            {
              name: "LABAYO",
            },
            {
              name: "LAYASIN",
            },
            {
              name: "LAYUG",
            },
            {
              name: "MAHIPON",
            },
            {
              name: "PAOWIN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SILANGAN TALAONGAN",
            },
            {
              name: "SISILMIN",
            },
            {
              name: "SUMUCAB",
            },
            {
              name: "TIBATIB",
            },
            {
              name: "UDIA",
            },
          ],
        },
        "CITY OF BIÑAN": {
          name: "CITY OF BIÑAN",
          baranggays: [
            {
              name: "BIÑAN (POBLACION)",
            },
            {
              name: "BUNGAHAN",
            },
            {
              name: "CANLALAY",
            },
            {
              name: "CASILE",
            },
            {
              name: "CITY OF BIÑAN",
            },
            {
              name: "DE LA PAZ",
            },
            {
              name: "GANADO",
            },
            {
              name: "LANGKIWA",
            },
            {
              name: "LOMA",
            },
            {
              name: "MALABAN",
            },
            {
              name: "MALAMIG",
            },
            {
              name: "MAMPALASAN",
            },
            {
              name: "PLATERO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO (HALANG)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO TOMAS (CALABUSO)",
            },
            {
              name: "SORO-SORO",
            },
            {
              name: "TIMBAO",
            },
            {
              name: "TUBIGAN",
            },
            {
              name: "ZAPOTE",
            },
          ],
        },
        FAMY: {
          name: "FAMY",
          baranggays: [
            {
              name: "ASANA (POB.)",
            },
            {
              name: "BACONG-SIGSIGAN",
            },
            {
              name: "BAGONG PAG-ASA (POB.)",
            },
            {
              name: "BALITOC",
            },
            {
              name: "BANABA (POB.)",
            },
            {
              name: "BATUHAN",
            },
            {
              name: "BULIHAN",
            },
            {
              name: "CABALLERO (POB.)",
            },
            {
              name: "CALUMPANG (POB.)",
            },
            {
              name: "CUEBANG BATO",
            },
            {
              name: "DAMAYAN (POB.)",
            },
            {
              name: "FAMY",
            },
            {
              name: "KAPATALAN",
            },
            {
              name: "KATAYPUANAN",
            },
            {
              name: "LIYANG",
            },
            {
              name: "MAATE",
            },
            {
              name: "MAGDALO (POB.)",
            },
            {
              name: "MAYATBA",
            },
            {
              name: "MINAYUTAN",
            },
            {
              name: "SALANGBATO",
            },
            {
              name: "TUNHAC",
            },
          ],
        },
        KALAYAAN: {
          name: "KALAYAAN",
          baranggays: [
            {
              name: "KALAYAAN",
            },
            {
              name: "LONGOS",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JUAN (POB.)",
            },
          ],
        },
        LILIW: {
          name: "LILIW",
          baranggays: [
            {
              name: "BAGONG ANYO (POB.)",
            },
            {
              name: "BAYATE",
            },
            {
              name: "BONGKOL",
            },
            {
              name: "BUBUKAL",
            },
            {
              name: "CABUYAO",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CULOY",
            },
            {
              name: "DAGATAN",
            },
            {
              name: "DANIW (DANLIW)",
            },
            {
              name: "DITA",
            },
            {
              name: "IBABANG PALINA",
            },
            {
              name: "IBABANG SAN ROQUE",
            },
            {
              name: "IBABANG SUNGI",
            },
            {
              name: "IBABANG TAYKIN",
            },
            {
              name: "ILAYANG PALINA",
            },
            {
              name: "ILAYANG SAN ROQUE",
            },
            {
              name: "ILAYANG SUNGI",
            },
            {
              name: "ILAYANG TAYKIN",
            },
            {
              name: "KANLURANG BUKAL",
            },
            {
              name: "LAGUAN",
            },
            {
              name: "LILIW",
            },
            {
              name: "LUQUIN",
            },
            {
              name: "MALABO-KALANTUKAN",
            },
            {
              name: "MASIKAP (POB.)",
            },
            {
              name: "MASLUN (POB.)",
            },
            {
              name: "MOJON",
            },
            {
              name: "NOVALICHES",
            },
            {
              name: "OPLES",
            },
            {
              name: "PAG-ASA (POB.)",
            },
            {
              name: "PALAYAN",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SILANGANG BUKAL",
            },
            {
              name: "TUY-BAANAN",
            },
          ],
        },
        "LOS BAÑOS": {
          name: "LOS BAÑOS",
          baranggays: [
            {
              name: "ANOS",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "BATONG MALAKE",
            },
            {
              name: "BAYBAYIN (POB.)",
            },
            {
              name: "BAYOG",
            },
            {
              name: "LALAKAY",
            },
            {
              name: "LOS BAÑOS",
            },
            {
              name: "MAAHAS",
            },
            {
              name: "MALINTA",
            },
            {
              name: "MAYONDON",
            },
            {
              name: "PUTHO TUNTUNGIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "TADLAK",
            },
            {
              name: "TIMUGAN (POB.)",
            },
          ],
        },
        LUISIANA: {
          name: "LUISIANA",
          baranggays: [
            {
              name: "BARANGAY ZONE I (POB.)",
            },
            {
              name: "BARANGAY ZONE II (POB.)",
            },
            {
              name: "BARANGAY ZONE III (POB.)",
            },
            {
              name: "BARANGAY ZONE IV (POB.)",
            },
            {
              name: "BARANGAY ZONE V (POB.)",
            },
            {
              name: "BARANGAY ZONE VI (POB.)",
            },
            {
              name: "BARANGAY ZONE VII (POB.)",
            },
            {
              name: "BARANGAY ZONE VIII (POB.)",
            },
            {
              name: "DE LA PAZ",
            },
            {
              name: "LUISIANA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BUENAVENTURA",
            },
            {
              name: "SAN DIEGO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO TOMAS",
            },
          ],
        },
        LUMBAN: {
          name: "LUMBAN",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BALIMBINGAN (POB.)",
            },
            {
              name: "BALUBAD",
            },
            {
              name: "CALIRAYA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "LEWIN",
            },
            {
              name: "LUMBAN",
            },
            {
              name: "MARACTA (POB.)",
            },
            {
              name: "MAYTALANG I",
            },
            {
              name: "MAYTALANG II",
            },
            {
              name: "PRIMERA PARANG (POB.)",
            },
            {
              name: "PRIMERA PULO (POB.)",
            },
            {
              name: "SALAC (POB.)",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "SEGUNDA PARANG (POB.)",
            },
            {
              name: "SEGUNDA PULO (POB.)",
            },
            {
              name: "WAWA",
            },
          ],
        },
        MABITAC: {
          name: "MABITAC",
          baranggays: [
            {
              name: "AMUYONG",
            },
            {
              name: "BAYANIHAN (POB.)",
            },
            {
              name: "LAMBAC (POB.)",
            },
            {
              name: "LIBIS NG NAYON (POB.)",
            },
            {
              name: "LUCONG",
            },
            {
              name: "MABITAC",
            },
            {
              name: "MALIGAYA (POB.)",
            },
            {
              name: "MASIKAP (POB.)",
            },
            {
              name: "MATALATALA",
            },
            {
              name: "NANGUMA",
            },
            {
              name: "NUMERO",
            },
            {
              name: "PAAGAHAN",
            },
            {
              name: "PAG-ASA (POB.)",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SINAGTALA (POB.)",
            },
          ],
        },
        MAGDALENA: {
          name: "MAGDALENA",
          baranggays: [
            {
              name: "ALIPIT",
            },
            {
              name: "BAANAN",
            },
            {
              name: "BALANAC",
            },
            {
              name: "BUCAL",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUNGKOL",
            },
            {
              name: "BUO",
            },
            {
              name: "BURLUNGAN",
            },
            {
              name: "CIGARAS",
            },
            {
              name: "HALAYHAYIN",
            },
            {
              name: "IBABANG ATINGAY",
            },
            {
              name: "IBABANG BUTNONG",
            },
            {
              name: "ILAYANG ATINGAY",
            },
            {
              name: "ILAYANG BUTNONG",
            },
            {
              name: "ILOG",
            },
            {
              name: "MAGDALENA",
            },
            {
              name: "MALAKING AMBLING",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MARAVILLA",
            },
            {
              name: "MUNTING AMBLING",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALASAD",
            },
            {
              name: "TANAWAN",
            },
            {
              name: "TIPUNAN",
            },
          ],
        },
        MAJAYJAY: {
          name: "MAJAYJAY",
          baranggays: [
            {
              name: "AMONOY",
            },
            {
              name: "BAKIA",
            },
            {
              name: "BALANAC",
            },
            {
              name: "BALAYONG",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BANTI",
            },
            {
              name: "BITAOY",
            },
            {
              name: "BOTOCAN",
            },
            {
              name: "BUKAL",
            },
            {
              name: "BURGOS",
            },
            {
              name: "BUROL",
            },
            {
              name: "CORALAO",
            },
            {
              name: "GAGALOT",
            },
            {
              name: "IBABANG BANGA",
            },
            {
              name: "IBABANG BAYUCAIN",
            },
            {
              name: "ILAYANG BANGA",
            },
            {
              name: "ILAYANG BAYUCAIN",
            },
            {
              name: "ISABANG",
            },
            {
              name: "MAJAYJAY",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MAY-IT",
            },
            {
              name: "MUNTING KAWAYAN",
            },
            {
              name: "OLLA",
            },
            {
              name: "OOBI",
            },
            {
              name: "ORIGUEL (POB.)",
            },
            {
              name: "PANALABAN",
            },
            {
              name: "PANGIL",
            },
            {
              name: "PANGLAN",
            },
            {
              name: "PIIT",
            },
            {
              name: "POOK",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CATALINA (POB.)",
            },
            {
              name: "SUBA",
            },
            {
              name: "TALORTOR",
            },
            {
              name: "TANAWAN",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "VILLA NOGALES",
            },
          ],
        },
        NAGCARLAN: {
          name: "NAGCARLAN",
          baranggays: [
            {
              name: "ABO",
            },
            {
              name: "ALIBUNGBUNGAN",
            },
            {
              name: "ALUMBRADO",
            },
            {
              name: "BALAYONG",
            },
            {
              name: "BALIMBING",
            },
            {
              name: "BALINACON",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "BANAGO",
            },
            {
              name: "BANCA-BANCA",
            },
            {
              name: "BANGCURO",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BAYAQUITOS",
            },
            {
              name: "BUBOY",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUHANGINAN",
            },
            {
              name: "BUKAL",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CABUYEW",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "KANLURAN KABUBUHAYAN",
            },
            {
              name: "KANLURAN LAZAAN",
            },
            {
              name: "LABANGAN",
            },
            {
              name: "LAGULO",
            },
            {
              name: "LAWAGUIN",
            },
            {
              name: "MAIIT",
            },
            {
              name: "MALAYA",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MANAOL",
            },
            {
              name: "MARAVILLA",
            },
            {
              name: "NAGCALBANG",
            },
            {
              name: "NAGCARLAN",
            },
            {
              name: "OPLES",
            },
            {
              name: "PALAYAN",
            },
            {
              name: "PALINA",
            },
            {
              name: "POBLACION I (POB.)",
            },
            {
              name: "POBLACION II (POB.)",
            },
            {
              name: "POBLACION III (POB.)",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SIBULAN",
            },
            {
              name: "SILANGAN ILAYA",
            },
            {
              name: "SILANGAN KABUBUHAYAN",
            },
            {
              name: "SILANGAN LAZAAN",
            },
            {
              name: "SILANGAN NAPAPATID",
            },
            {
              name: "SINIPIAN",
            },
            {
              name: "SULSUGUIN",
            },
            {
              name: "TALAHIB",
            },
            {
              name: "TALANGAN",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TIPACAN",
            },
            {
              name: "WAKAT",
            },
            {
              name: "YUKOS",
            },
          ],
        },
        PAETE: {
          name: "PAETE",
          baranggays: [
            {
              name: "BAGUMBAYAN (POB.)",
            },
            {
              name: "BANGKUSAY (POB.)",
            },
            {
              name: "ERMITA (POB.)",
            },
            {
              name: "IBABA DEL NORTE (POB.)",
            },
            {
              name: "IBABA DEL SUR (POB.)",
            },
            {
              name: "ILAYA DEL NORTE (POB.)",
            },
            {
              name: "ILAYA DEL SUR (POB.)",
            },
            {
              name: "MAYTOONG (POB.)",
            },
            {
              name: "PAETE",
            },
            {
              name: "QUINALE (POB.)",
            },
          ],
        },
        PAGSANJAN: {
          name: "PAGSANJAN",
          baranggays: [
            {
              name: "ANIBONG",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BIÑAN",
            },
            {
              name: "BUBOY",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CALUSICHE",
            },
            {
              name: "DINGIN",
            },
            {
              name: "LAMBAC",
            },
            {
              name: "LAYUGAN",
            },
            {
              name: "MAGDAPIO",
            },
            {
              name: "MAULAWIN",
            },
            {
              name: "PAGSANJAN",
            },
            {
              name: "PINAGSANJAN",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        PAKIL: {
          name: "PAKIL",
          baranggays: [
            {
              name: "BANILAN",
            },
            {
              name: "BAÑO (POB.)",
            },
            {
              name: "BURGOS (POB.)",
            },
            {
              name: "CASA REAL",
            },
            {
              name: "CASINSIN",
            },
            {
              name: "DORADO",
            },
            {
              name: "GONZALES (POB.)",
            },
            {
              name: "KABULUSAN",
            },
            {
              name: "MATIKIW",
            },
            {
              name: "PAKIL",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SARAY",
            },
            {
              name: "TAFT (POB.)",
            },
            {
              name: "TAVERA (POB.)",
            },
          ],
        },
        PANGIL: {
          name: "PANGIL",
          baranggays: [
            {
              name: "BALIAN",
            },
            {
              name: "DAMBO",
            },
            {
              name: "GALALAN",
            },
            {
              name: "ISLA (POB.)",
            },
            {
              name: "MABATO-AZUFRE",
            },
            {
              name: "NATIVIDAD (POB.)",
            },
            {
              name: "PANGIL",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SULIB",
            },
          ],
        },
        PILA: {
          name: "PILA",
          baranggays: [
            {
              name: "APLAYA",
            },
            {
              name: "BAGONG POOK",
            },
            {
              name: "BUKAL",
            },
            {
              name: "BULILAN NORTE (POB.)",
            },
            {
              name: "BULILAN SUR (POB.)",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "LABUIN",
            },
            {
              name: "LINGA",
            },
            {
              name: "MASICO",
            },
            {
              name: "MOJON",
            },
            {
              name: "PANSOL",
            },
            {
              name: "PILA",
            },
            {
              name: "PINAGBAYANAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA CLARA NORTE (POB.)",
            },
            {
              name: "SANTA CLARA SUR (POB.)",
            },
            {
              name: "TUBUAN",
            },
          ],
        },
        RIZAL: {
          name: "RIZAL",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "EAST POBLACION",
            },
            {
              name: "ENTABLADO",
            },
            {
              name: "LAGUAN",
            },
            {
              name: "PAULE 1",
            },
            {
              name: "PAULI 2",
            },
            {
              name: "POOK",
            },
            {
              name: "RIZAL",
            },
            {
              name: "TALA",
            },
            {
              name: "TALAGA",
            },
            {
              name: "TUY",
            },
            {
              name: "WEST POBLACION",
            },
          ],
        },
        "SAN PABLO CITY": {
          name: "SAN PABLO CITY",
          baranggays: [
            {
              name: "ATISAN",
            },
            {
              name: "BAGONG BAYAN II-A (POB.)",
            },
            {
              name: "BAGONG POOK VI-C (POB.)",
            },
            {
              name: "BARANGAY I-A (POB.)",
            },
            {
              name: "BARANGAY I-B (POB.)",
            },
            {
              name: "BARANGAY II-A (POB.)",
            },
            {
              name: "BARANGAY II-B (POB.)",
            },
            {
              name: "BARANGAY II-C (POB.)",
            },
            {
              name: "BARANGAY II-D (POB.)",
            },
            {
              name: "BARANGAY II-E (POB.)",
            },
            {
              name: "BARANGAY II-F (POB.)",
            },
            {
              name: "BARANGAY III-A (POB.)",
            },
            {
              name: "BARANGAY III-B (POB.)",
            },
            {
              name: "BARANGAY III-C (POB.)",
            },
            {
              name: "BARANGAY III-D (POB.)",
            },
            {
              name: "BARANGAY III-E (POB.)",
            },
            {
              name: "BARANGAY III-F (POB.)",
            },
            {
              name: "BARANGAY IV-A (POB.)",
            },
            {
              name: "BARANGAY IV-B (POB.)",
            },
            {
              name: "BARANGAY IV-C (POB.)",
            },
            {
              name: "BARANGAY V-A (POB.)",
            },
            {
              name: "BARANGAY V-B (POB.)",
            },
            {
              name: "BARANGAY V-C (POB.)",
            },
            {
              name: "BARANGAY V-D (POB.)",
            },
            {
              name: "BARANGAY VI-A (POB.)",
            },
            {
              name: "BARANGAY VI-B (POB.)",
            },
            {
              name: "BARANGAY VI-D (POB.)",
            },
            {
              name: "BARANGAY VI-E (POB.)",
            },
            {
              name: "BARANGAY VII-A (POB.)",
            },
            {
              name: "BARANGAY VII-B (POB.)",
            },
            {
              name: "BARANGAY VII-C (POB.)",
            },
            {
              name: "BARANGAY VII-D (POB.)",
            },
            {
              name: "BARANGAY VII-E (POB.)",
            },
            {
              name: "BAUTISTA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DEL REMEDIO",
            },
            {
              name: "DOLORES",
            },
            {
              name: "SAN ANTONIO 1",
            },
            {
              name: "SAN ANTONIO 2",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN BUENAVENTURA",
            },
            {
              name: "SAN CRISPIN",
            },
            {
              name: "SAN CRISTOBAL",
            },
            {
              name: "SAN DIEGO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN IGNACIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN LUCAS 1",
            },
            {
              name: "SAN LUCAS 2",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PABLO CITY",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA FELOMINA",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA MARIA MAGDALENA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA VERONICA",
            },
            {
              name: "SANTIAGO I",
            },
            {
              name: "SANTIAGO II",
            },
            {
              name: "SANTISIMO ROSARIO",
            },
            {
              name: "SANTO ANGEL",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SOLEDAD",
            },
          ],
        },
        "SAN PEDRO CITY": {
          name: "SAN PEDRO CITY",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "CALENDOLA",
            },
            {
              name: "CHRYSANTHEMUM",
            },
            {
              name: "CUYAB",
            },
            {
              name: "ESTRELLA",
            },
            {
              name: "FATIMA",
            },
            {
              name: "G.S.I.S.",
            },
            {
              name: "LANDAYAN",
            },
            {
              name: "LANGGAM",
            },
            {
              name: "LARAM",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAHARLIKA",
            },
            {
              name: "NARRA",
            },
            {
              name: "NUEVA",
            },
            {
              name: "PACITA 1",
            },
            {
              name: "PACITA 2",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAMPAGUITA VILLAGE",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN LORENZO RUIZ",
            },
            {
              name: "SAN PEDRO CITY",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "UNITED BAYANIHAN",
            },
            {
              name: "UNITED BETTER LIVING",
            },
          ],
        },
        "SANTA CRUZ": {
          name: "SANTA CRUZ",
          baranggays: [
            {
              name: "SANTO ANGEL NORTE",
            },
            {
              name: "SANTO ANGEL SUR",
            },
          ],
        },
        "SANTA MARIA": {
          name: "SANTA MARIA",
          baranggays: [
            {
              name: "ADIA",
            },
            {
              name: "BAGONG POOK",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BUBUKAL",
            },
            {
              name: "CABOOAN",
            },
            {
              name: "CALANGAY",
            },
            {
              name: "CAMBUJA",
            },
            {
              name: "CORALAN",
            },
            {
              name: "CUEVA",
            },
            {
              name: "INAYAPAN",
            },
            {
              name: "JOSE LAUREL, SR.",
            },
            {
              name: "JOSE RIZAL",
            },
            {
              name: "KAYHAKAT",
            },
            {
              name: "MACASIPAC",
            },
            {
              name: "MASINAO",
            },
            {
              name: "MATALING-TING",
            },
            {
              name: "PAO-O",
            },
            {
              name: "PARANG NG BUHO",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "TALANGKA",
            },
            {
              name: "TUNGKOD",
            },
          ],
        },
        "SANTA ROSA CITY": {
          name: "SANTA ROSA CITY",
          baranggays: [
            {
              name: "APLAYA",
            },
            {
              name: "BALIBAGO",
            },
            {
              name: "CAINGIN",
            },
            {
              name: "DILA",
            },
            {
              name: "DITA",
            },
            {
              name: "DON JOSE",
            },
            {
              name: "IBABA",
            },
            {
              name: "KANLURAN (POB.)",
            },
            {
              name: "LABAS",
            },
            {
              name: "MACABLING",
            },
            {
              name: "MALITLIT",
            },
            {
              name: "MALUSAK (POB.)",
            },
            {
              name: "MARKET AREA (POB.)",
            },
            {
              name: "POOK",
            },
            {
              name: "PULONG SANTA CRUZ",
            },
            {
              name: "SANTA ROSA CITY",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SINALHAN",
            },
            {
              name: "TAGAPO",
            },
          ],
        },
        SINILOAN: {
          name: "SINILOAN",
          baranggays: [
            {
              name: "ACEVIDA",
            },
            {
              name: "BAGONG PAG-ASA (POB.)",
            },
            {
              name: "BAGUMBARANGAY (POB.)",
            },
            {
              name: "BUHAY",
            },
            {
              name: "G. REDOR (POB.)",
            },
            {
              name: "GEN. LUNA",
            },
            {
              name: "HALAYHAYIN",
            },
            {
              name: "J. RIZAL (POB.)",
            },
            {
              name: "KAPATALAN",
            },
            {
              name: "LAGUIO",
            },
            {
              name: "LIYANG",
            },
            {
              name: "LLAVAC",
            },
            {
              name: "MACATAD",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAYATBA",
            },
            {
              name: "MENDIOLA",
            },
            {
              name: "P. BURGOS",
            },
            {
              name: "PANDENO",
            },
            {
              name: "SALUBUNGAN",
            },
            {
              name: "SINILOAN",
            },
            {
              name: "WAWA",
            },
          ],
        },
        VICTORIA: {
          name: "VICTORIA",
          baranggays: [
            {
              name: "BANCA-BANCA",
            },
            {
              name: "DANIW",
            },
            {
              name: "MASAPANG",
            },
            {
              name: "NANHAYA (POB.)",
            },
            {
              name: "PAGALANGAN",
            },
            {
              name: "SAN BENITO",
            },
            {
              name: "SAN FELIX",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
      },
    },
    "LANAO DEL NORTE": {
      name: "LANAO DEL NORTE",
      region: "REGION 10",
      municipalities: {
        BACOLOD: {
          name: "BACOLOD",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BABALAYA",
            },
            {
              name: "BABALAYAN TOWNSITE",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BINUNI",
            },
            {
              name: "DELABAYAN WEST",
            },
            {
              name: "DEMOLOGAN",
            },
            {
              name: "DIMARAO",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "KAHAYAG",
            },
            {
              name: "LIANGAN EAST",
            },
            {
              name: "MATI",
            },
            {
              name: "MINAULON",
            },
            {
              name: "PAGAYAWAN",
            },
            {
              name: "POBLACION BACOLOD",
            },
            {
              name: "PUNOD (MALIWANAG)",
            },
            {
              name: "RUPAGAN",
            },
          ],
        },
        BALOI: {
          name: "BALOI",
          baranggays: [
            {
              name: "ABAGA",
            },
            {
              name: "ADAPUN-ALI (DARIAT)",
            },
            {
              name: "ANGANDOG (BULAO)",
            },
            {
              name: "ANGAYEN (BALUT)",
            },
            {
              name: "BALOI",
            },
            {
              name: "BANGKO",
            },
            {
              name: "BATOLACONGAN (BASAGAD)",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "LANDA (GADONGAN)",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MAMAANUN",
            },
            {
              name: "MARIA-CRISTINA",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "NANGKA",
            },
            {
              name: "PACALUNDO",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SANDOR (DADUAN)",
            },
            {
              name: "SANGCAD (CORMATAN )",
            },
            {
              name: "SARIP-ALAWI (PAYAWAN)",
            },
            {
              name: "SIGAYAN",
            },
          ],
        },
        BAROY: {
          name: "BAROY",
          baranggays: [
            {
              name: "ANDIL",
            },
            {
              name: "BAGONG DAWIS",
            },
            {
              name: "BAROY",
            },
            {
              name: "BAROY DAKU",
            },
            {
              name: "BATO",
            },
            {
              name: "CABASAGAN",
            },
            {
              name: "DALAMA",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIMWAG",
            },
            {
              name: "LINDONGAN",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "MANAN-AO",
            },
            {
              name: "PANGE",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PRINCESA",
            },
            {
              name: "RAWAN POINT",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "SAGADAN (SAGADAN LOWER)",
            },
            {
              name: "SAGADAN UPPER",
            },
            {
              name: "SALONG",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "TINUBDAN",
            },
            {
              name: "VILLAGE",
            },
          ],
        },
        CAROMATAN: {
          name: "CAROMATAN",
          baranggays: [
            {
              name: "CAROMATAN",
            },
          ],
        },
        "ILIGAN CITY": {
          name: "ILIGAN CITY",
          baranggays: [
            {
              name: "ABUNO",
            },
            {
              name: "ACMAC",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BONBONON",
            },
            {
              name: "BUNAWAN",
            },
            {
              name: "BURU-UN",
            },
            {
              name: "CITY PROPER",
            },
            {
              name: "DALIPUGA",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "DIGKILAAN",
            },
            {
              name: "DITUCALAN",
            },
            {
              name: "DULAG",
            },
            {
              name: "HINAPLANON",
            },
            {
              name: "HINDANG",
            },
            {
              name: "KABACSANAN",
            },
            {
              name: "KALILANGAN",
            },
            {
              name: "KIWALAN",
            },
            {
              name: "LANIPAO",
            },
            {
              name: "LUINAB",
            },
            {
              name: "MAHAYHAY",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MANDULOG",
            },
            {
              name: "MARIA CRISTINA",
            },
            {
              name: "PALAO",
            },
            {
              name: "PANOROGANAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUGA-AN",
            },
            {
              name: "ROGONGON",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SARAY-TIBANGA",
            },
            {
              name: "SUAREZ",
            },
            {
              name: "TAMBACAN",
            },
            {
              name: "TIBANGA",
            },
            {
              name: "TIPANOY",
            },
            {
              name: "TOMINOBO PROPER",
            },
            {
              name: "TOMINOBO UPPER",
            },
            {
              name: "TUBOD",
            },
            {
              name: "UBALDO LAYA",
            },
            {
              name: "UPPER HINAPLANON",
            },
            {
              name: "VILLA VERDE",
            },
          ],
        },
        KAPATAGAN: {
          name: "KAPATAGAN",
          baranggays: [
            {
              name: "BAGONG BADIAN",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BALILI",
            },
            {
              name: "BANSARVIL",
            },
            {
              name: "BELIS",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUTADON",
            },
            {
              name: "CATHEDRAL FALLS",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CURVADA",
            },
            {
              name: "DE ASIS",
            },
            {
              name: "DONGGOAN",
            },
            {
              name: "DURANO",
            },
            {
              name: "KAHAYAGAN",
            },
            {
              name: "KAPATAGAN",
            },
            {
              name: "KIDALOS",
            },
            {
              name: "LA LIBERTAD",
            },
            {
              name: "LAPINIG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALINAS",
            },
            {
              name: "MARANDING",
            },
            {
              name: "MARGOS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULANG YUTA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SUSO",
            },
            {
              name: "TAGUITIC",
            },
            {
              name: "TIACONGAN",
            },
            {
              name: "TIPOLO",
            },
            {
              name: "TULATULAHAN",
            },
            {
              name: "WATERFALLS",
            },
          ],
        },
        KAUSWAGAN: {
          name: "KAUSWAGAN",
          baranggays: [
            {
              name: "BAGUMBAYAN (POB.)",
            },
            {
              name: "BARA-ASON",
            },
            {
              name: "CAYONTOR",
            },
            {
              name: "DELABAYAN",
            },
            {
              name: "INUDARAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KAWIT OCCIDENTAL",
            },
            {
              name: "KAWIT ORIENTAL",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "PAITON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TACUB",
            },
            {
              name: "TINGINTINGIN",
            },
            {
              name: "TUGAR",
            },
          ],
        },
        KOLAMBUGAN: {
          name: "KOLAMBUGAN",
          baranggays: [
            {
              name: "AUSTIN HEIGHTS",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BUBONG",
            },
            {
              name: "CAROMATAN",
            },
            {
              name: "INUDARAN",
            },
            {
              name: "KOLAMBUGAN",
            },
            {
              name: "KULASIHAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MANGA",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "MUKAS",
            },
            {
              name: "MUNTAY",
            },
            {
              name: "PAGALUNGAN",
            },
            {
              name: "PALAO",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PANTAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "REBUCON",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIMBUCO",
            },
            {
              name: "SMALL BANISILAN",
            },
            {
              name: "SUCODAN",
            },
            {
              name: "TABIGUE",
            },
            {
              name: "TITUNOD",
            },
          ],
        },
        LALA: {
          name: "LALA",
          baranggays: [
            {
              name: "ABAGA",
            },
            {
              name: "ANDIL",
            },
            {
              name: "CABASAGAN",
            },
            {
              name: "CAMALAN",
            },
            {
              name: "DARUMAWANG BUCANA",
            },
            {
              name: "DARUMAWANG ILAYA",
            },
            {
              name: "EL SALVADOR",
            },
            {
              name: "GUMAGAMOT",
            },
            {
              name: "LALA",
            },
            {
              name: "LALA PROPER (POB.)",
            },
            {
              name: "LANIPAO",
            },
            {
              name: "MAGPATAO",
            },
            {
              name: "MARANDING",
            },
            {
              name: "MATAMPAY BUCANA",
            },
            {
              name: "MATAMPAY ILAYA",
            },
            {
              name: "PACITA",
            },
            {
              name: "PENDOLONAN",
            },
            {
              name: "PINOYAK",
            },
            {
              name: "RAW-AN",
            },
            {
              name: "REBE",
            },
            {
              name: "SAN ISIDRO LOWER",
            },
            {
              name: "SAN ISIDRO UPPER",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SANTA CRUZ LOWER",
            },
            {
              name: "SANTA CRUZ UPPER",
            },
            {
              name: "SIMPAK",
            },
            {
              name: "TENAZAS",
            },
            {
              name: "TUNA-AN",
            },
          ],
        },
        LINAMON: {
          name: "LINAMON",
          baranggays: [
            {
              name: "BUSQUE",
            },
            {
              name: "LARAPAN",
            },
            {
              name: "LINAMON",
            },
            {
              name: "MAGOONG",
            },
            {
              name: "NAPO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PURAKAN",
            },
            {
              name: "ROBOCON",
            },
            {
              name: "SAMBURON",
            },
          ],
        },
        MAGSAYSAY: {
          name: "MAGSAYSAY",
          baranggays: [
            {
              name: "BABASALON",
            },
            {
              name: "BAGUIGUICON",
            },
            {
              name: "DAAN CAMPO",
            },
            {
              name: "DURIANON",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "LAMIGADATO",
            },
            {
              name: "LEMONCRET",
            },
            {
              name: "LOWER CANINGAG (PERIMBANGAN)",
            },
            {
              name: "LUBO",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALABAOGAN",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "OLANGO",
            },
            {
              name: "PANGAO",
            },
            {
              name: "PELINGKINGAN",
            },
            {
              name: "POBLACION (BAGO-A-INGUD)",
            },
            {
              name: "RARAB",
            },
            {
              name: "SOMIORANG",
            },
            {
              name: "TALAMBO",
            },
            {
              name: "TAMBACON",
            },
            {
              name: "TAWINIAN",
            },
            {
              name: "TIPAAN",
            },
            {
              name: "TOMBADOR",
            },
            {
              name: "UPPER CANINGAG (TAGUITINGAN)",
            },
          ],
        },
        MAIGO: {
          name: "MAIGO",
          baranggays: [
            {
              name: "BALAGATASA",
            },
            {
              name: "CAMP 1",
            },
            {
              name: "CLARO M. RECTO",
            },
            {
              name: "INOMA",
            },
            {
              name: "KULASIHAN (VILLANUEVA)",
            },
            {
              name: "LABUAY",
            },
            {
              name: "LIANGAN WEST",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MAIGO",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "MENTRING",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SOGAPOD",
            },
          ],
        },
        MARANDING: {
          name: "MARANDING",
          baranggays: [
            {
              name: "MARANDING",
            },
          ],
        },
        MATUNGAO: {
          name: "MATUNGAO",
          baranggays: [
            {
              name: "BANGCO",
            },
            {
              name: "BATAL",
            },
            {
              name: "BATANGAN",
            },
            {
              name: "BUBONG RADAPAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "MATUNGAO",
            },
            {
              name: "PANGI",
            },
            {
              name: "PASAYANON",
            },
            {
              name: "POBLACION (MATUNGAO)",
            },
            {
              name: "PUNTOD",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SOMIORANG",
            },
          ],
        },
        MUNAI: {
          name: "MUNAI",
          baranggays: [
            {
              name: "BACAYAWAN",
            },
            {
              name: "BALABACUN",
            },
            {
              name: "BALINTAD",
            },
            {
              name: "DALAMA",
            },
            {
              name: "KADAYONAN",
            },
            {
              name: "LINDONGAN",
            },
            {
              name: "LINGCO-AN",
            },
            {
              name: "LININDING",
            },
            {
              name: "LUMBA-BAYABAO",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MAGANDING",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "MUNAI",
            },
            {
              name: "NORTH CADULAWAN",
            },
            {
              name: "OLD POBLACION",
            },
            {
              name: "PANGGAO",
            },
            {
              name: "PANTAO",
            },
            {
              name: "PANTAO-A-MUNAI",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "PUNONG",
            },
            {
              name: "RAMAIN",
            },
            {
              name: "SANDIGAMUNAI",
            },
            {
              name: "TAGORANAO",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TAMPARAN (MANDAYA)",
            },
            {
              name: "TAPOROG",
            },
          ],
        },
        NUNUNGAN: {
          name: "NUNUNGAN",
          baranggays: [
            {
              name: "ABAGA",
            },
            {
              name: "BANGCO",
            },
            {
              name: "CABASARAN (LAYA)",
            },
            {
              name: "CANIBONGAN",
            },
            {
              name: "DIMAYON",
            },
            {
              name: "INAYAWAN",
            },
            {
              name: "KALUDAN",
            },
            {
              name: "KARCUM",
            },
            {
              name: "KATUBUAN",
            },
            {
              name: "LIANGAN",
            },
            {
              name: "LUPITAN",
            },
            {
              name: "MALAIG",
            },
            {
              name: "MANGAN",
            },
            {
              name: "MASIBAY",
            },
            {
              name: "NOTONGAN",
            },
            {
              name: "NUNUNGAN",
            },
            {
              name: "PANGANAPAN",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PARIDI",
            },
            {
              name: "PETADUN",
            },
            {
              name: "POBLACION (NUNUNGAN PROPER)",
            },
            {
              name: "RARAB",
            },
            {
              name: "RARABAN",
            },
            {
              name: "REBUCON",
            },
            {
              name: "SONGGOD",
            },
            {
              name: "TARAKA",
            },
          ],
        },
        "PANTAO RAGAT": {
          name: "PANTAO RAGAT",
          baranggays: [
            {
              name: "ALOON",
            },
            {
              name: "BANDAY",
            },
            {
              name: "BOBONGA PANTAO RAGAT",
            },
            {
              name: "BOBONGA RADAPAN",
            },
            {
              name: "CABASAGAN",
            },
            {
              name: "CALAWE",
            },
            {
              name: "CULUBUN",
            },
            {
              name: "DILIMBAYAN",
            },
            {
              name: "DIMAYON",
            },
            {
              name: "LOMIDONG",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "NATANGCOPAN",
            },
            {
              name: "PANSOR",
            },
            {
              name: "PANTAO MARUG",
            },
            {
              name: "PANTAO RAGAT",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "TANGCAL",
            },
            {
              name: "TONGCOPAN",
            },
          ],
        },
        PANTAR: {
          name: "PANTAR",
          baranggays: [
            {
              name: "BANGCAL",
            },
            {
              name: "BOWI",
            },
            {
              name: "BUBONG MADAYA",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "CAMPONG",
            },
            {
              name: "DIBAROSAN",
            },
            {
              name: "KALANGANAN EAST",
            },
            {
              name: "KALANGANAN LOWER",
            },
            {
              name: "KALILANGAN",
            },
            {
              name: "LUMBA-PUNOD",
            },
            {
              name: "PANTAO-MARUG",
            },
            {
              name: "PANTAO-RANAO",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PANTAR EAST",
            },
            {
              name: "PITUBO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POONA-PUNOD",
            },
            {
              name: "PUNOD",
            },
            {
              name: "SUNDIGA-PUNOD",
            },
            {
              name: "TAWANAN",
            },
            {
              name: "WEST PANTAR",
            },
          ],
        },
        "POONA PIAGAPO": {
          name: "POONA PIAGAPO",
          baranggays: [
            {
              name: "ALOWIN",
            },
            {
              name: "BUBONG-DINAIG",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "CAROMATAN",
            },
            {
              name: "DARAMBA",
            },
            {
              name: "DINAIG",
            },
            {
              name: "KABLANGAN",
            },
            {
              name: "LININDINGAN",
            },
            {
              name: "LUMBATAN",
            },
            {
              name: "LUPITAN",
            },
            {
              name: "MADAMBA",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "NUNANG",
            },
            {
              name: "NUNUNGAN",
            },
            {
              name: "PANTAO RAYA",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PENDOLONAN",
            },
            {
              name: "PENED",
            },
            {
              name: "PIANGAMANGAAN",
            },
            {
              name: "POBLACION (LUMBACAINGUD)",
            },
            {
              name: "POONA PIAGAPO",
            },
            {
              name: "SULO",
            },
            {
              name: "TAGORANAO",
            },
            {
              name: "TANGCLAO",
            },
            {
              name: "TIMBANGALAN",
            },
          ],
        },
        SALVADOR: {
          name: "SALVADOR",
          baranggays: [
            {
              name: "BARANDIA",
            },
            {
              name: "BULACON",
            },
            {
              name: "BUNTONG",
            },
            {
              name: "CALIMODAN",
            },
            {
              name: "CAMP III",
            },
            {
              name: "CURVA-MIAGAO",
            },
            {
              name: "DALIGDIGAN",
            },
            {
              name: "INASAGAN",
            },
            {
              name: "KILALA",
            },
            {
              name: "MABATAO",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MAMAANON",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "MINDALANO",
            },
            {
              name: "PADIANAN",
            },
            {
              name: "PAGALONGAN",
            },
            {
              name: "PAGAYAWAN",
            },
            {
              name: "PANALIWAD-ON",
            },
            {
              name: "PANGANTAPAN",
            },
            {
              name: "PANSOR",
            },
            {
              name: "PATIDON",
            },
            {
              name: "PAWAK",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVADOR",
            },
            {
              name: "SAUMAY",
            },
            {
              name: "SUDLON",
            },
          ],
        },
        SAPAD: {
          name: "SAPAD",
          baranggays: [
            {
              name: "BANING",
            },
            {
              name: "BURIASAN (POB.)",
            },
            {
              name: "DANSALAN",
            },
            {
              name: "GAMAL",
            },
            {
              name: "INUDARAN I",
            },
            {
              name: "INUDARAN II",
            },
            {
              name: "KARKUM",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MABUGNAO",
            },
            {
              name: "MAITO SALUG",
            },
            {
              name: "MALA SALUG",
            },
            {
              name: "MAMA-ANON",
            },
            {
              name: "MAPUROG",
            },
            {
              name: "PANCILAN",
            },
            {
              name: "PANOLOON",
            },
            {
              name: "PILI",
            },
            {
              name: "SAPAD",
            },
            {
              name: "SAPAD",
            },
          ],
        },
        "SULTAN NAGA DIMAPORO (KAROMATAN)": {
          name: "SULTAN NAGA DIMAPORO (KAROMATAN)",
          baranggays: [
            {
              name: "BANGAAN",
            },
            {
              name: "BANGCO",
            },
            {
              name: "BANSARVIL II",
            },
            {
              name: "BAUYAN",
            },
            {
              name: "CABONGBONGAN",
            },
            {
              name: "CALIBAO",
            },
            {
              name: "CALIPAPA",
            },
            {
              name: "CALUBE",
            },
            {
              name: "CAMPO ISLAM",
            },
            {
              name: "CAPOCAO",
            },
            {
              name: "DABLISTON",
            },
            {
              name: "DALAMA",
            },
            {
              name: "DANGULAAN",
            },
            {
              name: "DITAGO",
            },
            {
              name: "ILIAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KIRAPAN",
            },
            {
              name: "KOREO",
            },
            {
              name: "LANTAWAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGUINDANAO",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MAMAGUM",
            },
            {
              name: "MINA",
            },
            {
              name: "PANDANAN",
            },
            {
              name: "PAYONG",
            },
            {
              name: "PIKALAWAG",
            },
            {
              name: "PIKINIT",
            },
            {
              name: "PIRAKA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RAMAIN",
            },
            {
              name: "REBUCON",
            },
            {
              name: "SIGAYAN",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TAGULO",
            },
            {
              name: "TANTAON",
            },
            {
              name: "TOPOCON (CAPOCGO)",
            },
          ],
        },
        TAGOLOAN: {
          name: "TAGOLOAN",
          baranggays: [
            {
              name: "DALAMAS",
            },
            {
              name: "DARIMBANG",
            },
            {
              name: "DIMAYON",
            },
            {
              name: "INAGONGAN",
            },
            {
              name: "KIAZAR (POB.)",
            },
            {
              name: "MALIMBATO",
            },
            {
              name: "PANALAWAN",
            },
            {
              name: "TAGOLOAN",
            },
          ],
        },
        TANGCAL: {
          name: "TANGCAL",
          baranggays: [
            {
              name: "BAYABAO",
            },
            {
              name: "BERWAR",
            },
            {
              name: "BIG BANISILON",
            },
            {
              name: "BIG MELADOC",
            },
            {
              name: "BUBONG",
            },
            {
              name: "LAMAOSA",
            },
            {
              name: "LINAO",
            },
            {
              name: "LINDONGAN",
            },
            {
              name: "LINGCO-AN",
            },
            {
              name: "PAPAN",
            },
            {
              name: "PELINGKINGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POONA KAPATAGAN",
            },
            {
              name: "PUNOD",
            },
            {
              name: "SMALL BANISILON",
            },
            {
              name: "SMALL MELADOC",
            },
            {
              name: "SOMIORANG",
            },
            {
              name: "TANGCAL",
            },
            {
              name: "TANGCAL PROPER",
            },
          ],
        },
        TUBOD: {
          name: "TUBOD",
          baranggays: [
            {
              name: "BARAKANAS",
            },
            {
              name: "BARIS (LUMANGCULOB)",
            },
            {
              name: "BUALAN",
            },
            {
              name: "BULOD",
            },
            {
              name: "CAMP V",
            },
            {
              name: "CANDIS",
            },
            {
              name: "CANIOGAN",
            },
            {
              name: "DALAMA",
            },
            {
              name: "KAKAI RENABOR",
            },
            {
              name: "KALILANGAN",
            },
            {
              name: "LICAPAO",
            },
            {
              name: "MALINGAO",
            },
            {
              name: "PALAO",
            },
            {
              name: "PATUDAN",
            },
            {
              name: "PIGCARANGAN",
            },
            {
              name: "PINPIN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUALAS",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TADEN",
            },
            {
              name: "TAGURANAO",
            },
            {
              name: "TANGUEGUIRON",
            },
            {
              name: "TUBARAN",
            },
            {
              name: "TUBOD",
            },
          ],
        },
      },
    },
    "LANAO DEL SUR": {
      name: "LANAO DEL SUR",
      region: "ARMM",
      municipalities: {
        "BACOLOD GRANDE": {
          name: "BACOLOD GRANDE",
          baranggays: [
            {
              name: "BACOLOD GRANDE",
            },
          ],
        },
        "BACOLOD-KALAWI (BACOLOD GRANDE)": {
          name: "BACOLOD-KALAWI (BACOLOD GRANDE)",
          baranggays: [
            {
              name: "AMPAO",
            },
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BALUT",
            },
            {
              name: "BARUA",
            },
            {
              name: "BUADIAWANI",
            },
            {
              name: "BUBONG",
            },
            {
              name: "DARAMOYOD",
            },
            {
              name: "DILABAYAN",
            },
            {
              name: "DIPATUAN",
            },
            {
              name: "GANDAMATO",
            },
            {
              name: "GURAIN",
            },
            {
              name: "ILIAN",
            },
            {
              name: "LAMA",
            },
            {
              name: "LIAWAO",
            },
            {
              name: "LUMBACA-INGUD",
            },
            {
              name: "MADANDING",
            },
            {
              name: "ORONG",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "RAYA",
            },
            {
              name: "ROROWAN",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TUKA I",
            },
            {
              name: "TUKA II",
            },
          ],
        },
        BALABAGAN: {
          name: "BALABAGAN",
          baranggays: [
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BALABAGAN",
            },
            {
              name: "BANAGO",
            },
            {
              name: "BARORAO",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BUDAS",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUISAN (BENGABENG)",
            },
            {
              name: "CALILANGAN",
            },
            {
              name: "IGABAY",
            },
            {
              name: "ILIAN",
            },
            {
              name: "LALABUAN",
            },
            {
              name: "LORENZO",
            },
            {
              name: "LOWER ITIL",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MACAO",
            },
            {
              name: "MAGULALUNG OCCIDENTAL",
            },
            {
              name: "MAGULALUNG ORIENTAL",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "MATANOG",
            },
            {
              name: "MOLIMOC",
            },
            {
              name: "NARRA",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "PLASAN",
            },
            {
              name: "POBLACION (BALABAGAN)",
            },
            {
              name: "PURAKAN",
            },
            {
              name: "TATAYA",
            },
            {
              name: "UPPER ITIL",
            },
          ],
        },
        BALINDONG: {
          name: "BALINDONG",
          baranggays: [
            {
              name: "BALINDONG",
            },
          ],
        },
        "BALINDONG (WATU)": {
          name: "BALINDONG (WATU)",
          baranggays: [
            {
              name: "ABAGA",
            },
            {
              name: "BANTOGA WATO",
            },
            {
              name: "BARIT",
            },
            {
              name: "BOLINSONG",
            },
            {
              name: "BORAKIS",
            },
            {
              name: "BUALAN",
            },
            {
              name: "BUBONG",
            },
            {
              name: "BUBONG CADAPAAN",
            },
            {
              name: "CADAPAAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "DADAYAG",
            },
            {
              name: "DADO",
            },
            {
              name: "DIBARUSAN",
            },
            {
              name: "DILAUSAN",
            },
            {
              name: "DIMARAO",
            },
            {
              name: "INGUD",
            },
            {
              name: "KALUNTAY",
            },
            {
              name: "LALABUAN",
            },
            {
              name: "LATI",
            },
            {
              name: "LILOD",
            },
            {
              name: "LIMBO",
            },
            {
              name: "LUMBAC LALAN",
            },
            {
              name: "LUMBAC WATO",
            },
            {
              name: "LUMBAYAO",
            },
            {
              name: "MAGARANG",
            },
            {
              name: "MALAIG",
            },
            {
              name: "NUSA LUMBA RANAO",
            },
            {
              name: "PADILA",
            },
            {
              name: "PAGAYAWAN",
            },
            {
              name: "PAIGOAY",
            },
            {
              name: "PANTARAGOO",
            },
            {
              name: "POBLACION (BALINDONG)",
            },
            {
              name: "RAYA",
            },
            {
              name: "SALIPONGAN",
            },
            {
              name: "TALUB",
            },
            {
              name: "TANTUA RAYA",
            },
            {
              name: "TOMAROMPONG",
            },
            {
              name: "TUKA BUBONG",
            },
          ],
        },
        BAYANG: {
          name: "BAYANG",
          baranggays: [
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BAIRAN (POB.)",
            },
            {
              name: "BANDINGUN",
            },
            {
              name: "BAYANG",
            },
            {
              name: "BIABI",
            },
            {
              name: "BIALAAN",
            },
            {
              name: "BUBONG LILOD",
            },
            {
              name: "BUBONG RAYA",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "CADINGILAN OCCIDENTAL",
            },
            {
              name: "CADINGILAN ORIENTAL",
            },
            {
              name: "CONDARAAN POB. (CONDARAAN DIMADAP)",
            },
            {
              name: "CORMATAN",
            },
            {
              name: "GANDAMATO",
            },
            {
              name: "ILIAN",
            },
            {
              name: "LALAPUNG CENTRAL",
            },
            {
              name: "LALAPUNG PROPER (POB.)",
            },
            {
              name: "LALAPUNG UPPER",
            },
            {
              name: "LINAO",
            },
            {
              name: "LINUK (POB.)",
            },
            {
              name: "LIONG",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "LUMBAC CADAYONAN",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "MIMBALAWAG",
            },
            {
              name: "PALAO",
            },
            {
              name: "PAMA-AN",
            },
            {
              name: "PAMACOTAN",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PARAO",
            },
            {
              name: "PATONG",
            },
            {
              name: "POBLACION (BAYANG)",
            },
            {
              name: "POROTAN",
            },
            {
              name: "RANTIAN",
            },
            {
              name: "RAYA CADAYONAN",
            },
            {
              name: "RINABOR (POB.)",
            },
            {
              name: "SAMPORNA (POB.)",
            },
            {
              name: "SAPA",
            },
            {
              name: "SILID",
            },
            {
              name: "SUGOD",
            },
            {
              name: "SULTAN PANDAPATAN",
            },
            {
              name: "SUMBAG (POB.)",
            },
            {
              name: "TAGORANAO",
            },
            {
              name: "TANGCAL",
            },
            {
              name: "TANGCAL PROPER (POB.)",
            },
            {
              name: "TOMAROMPONG",
            },
            {
              name: "TOMONGCAL LIGI",
            },
            {
              name: "TOROGAN",
            },
            {
              name: "TUCA (POB.)",
            },
          ],
        },
        BINDAYAN: {
          name: "BINDAYAN",
          baranggays: [
            {
              name: "BINDAYAN",
            },
          ],
        },
        BINIDAYAN: {
          name: "BINIDAYAN",
          baranggays: [
            {
              name: "BADAK",
            },
            {
              name: "BAGUIANGUN",
            },
            {
              name: "BALUT MAITO",
            },
            {
              name: "BASAK",
            },
            {
              name: "BUBONG",
            },
            {
              name: "BUBONGA-RANAO",
            },
            {
              name: "DANSALAN DACSULA",
            },
            {
              name: "INGUD",
            },
            {
              name: "KIALILIDAN",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MACAGUILING",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MAGONAYA",
            },
            {
              name: "MAINDIG",
            },
            {
              name: "MASOLUN",
            },
            {
              name: "OLAMA",
            },
            {
              name: "PAGALAMATAN (POB.)",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PICALILANGAN",
            },
            {
              name: "PICOTAAN",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SOLDARORO",
            },
            {
              name: "TAMBAC",
            },
            {
              name: "TIMBANGAN",
            },
            {
              name: "TUCA",
            },
          ],
        },
        "BUADIPOSO-BUNTONG": {
          name: "BUADIPOSO-BUNTONG",
          baranggays: [
            {
              name: "BACOLOD",
            },
            {
              name: "BANGON",
            },
            {
              name: "BANGON PROPER",
            },
            {
              name: "BOTO RAGONDINGAN",
            },
            {
              name: "BUADIPOSO LILOD",
            },
            {
              name: "BUADIPOSO PROPER",
            },
            {
              name: "BUADIPOSO RAYA",
            },
            {
              name: "BUADIPOSO-BUNTONG",
            },
            {
              name: "BUBONG",
            },
            {
              name: "BUNTONG PROPER",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "DANSALAN",
            },
            {
              name: "DATU TAMBARA",
            },
            {
              name: "DIRISAN",
            },
            {
              name: "GATA",
            },
            {
              name: "KALAKALA",
            },
            {
              name: "KATOGONAN",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "LUMBATAN MANACAB",
            },
            {
              name: "LUMBATAN PATAINGUD",
            },
            {
              name: "LUNDUBAN (RAGONDINGAN)",
            },
            {
              name: "MANACAB (POB.)",
            },
            {
              name: "MINANGA (BUNTONG)",
            },
            {
              name: "PALING",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "PUALAS",
            },
            {
              name: "RAGONDINGAN EAST",
            },
            {
              name: "RAGONDINGAN PROPER",
            },
            {
              name: "RAGONDINGAN WEST",
            },
            {
              name: "RAYA BUNTONG (BUNTONG EAST)",
            },
            {
              name: "SAPOT",
            },
            {
              name: "TANGCAL",
            },
            {
              name: "TARIK",
            },
            {
              name: "TUKA",
            },
          ],
        },
        BUBONG: {
          name: "BUBONG",
          baranggays: [
            {
              name: "BACOLOD",
            },
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BANSAYAN",
            },
            {
              name: "BASINGAN",
            },
            {
              name: "BATANGAN",
            },
            {
              name: "BUALAN",
            },
            {
              name: "BUBONG",
            },
            {
              name: "BUBONGA DIDAGUN",
            },
            {
              name: "CARIGONGAN",
            },
            {
              name: "DALAON",
            },
            {
              name: "DIBAROSAN",
            },
            {
              name: "DILABAYAN",
            },
            {
              name: "DIMAPATOY",
            },
            {
              name: "DIMAYON",
            },
            {
              name: "DIMAYON PROPER",
            },
            {
              name: "DIOLANGAN",
            },
            {
              name: "GUIGUIKUN",
            },
            {
              name: "MADANDING",
            },
            {
              name: "MALUNGUN",
            },
            {
              name: "MASOROT",
            },
            {
              name: "MATAMPAY DIMARAO",
            },
            {
              name: "MIABALAWAG",
            },
            {
              name: "MONTIAAN",
            },
            {
              name: "PAGAYAWAN",
            },
            {
              name: "PALAO",
            },
            {
              name: "PANALAWAN",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PENDOGOAN",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POBLACION (BUBONG)",
            },
            {
              name: "POLAYAGAN",
            },
            {
              name: "PUNUD",
            },
            {
              name: "RAMAIN BUBONG",
            },
            {
              name: "ROGERO",
            },
            {
              name: "SALIPONGAN",
            },
            {
              name: "SUNGGOD",
            },
            {
              name: "TABORO",
            },
          ],
        },
        BUMBARAN: {
          name: "BUMBARAN",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BANDARA-INGUD",
            },
            {
              name: "BUMBARAN",
            },
            {
              name: "COMARA",
            },
            {
              name: "FRANKFORT",
            },
            {
              name: "LAMBANOGAN",
            },
            {
              name: "LICO",
            },
            {
              name: "MANSILANO",
            },
            {
              name: "NATANGCOPAN",
            },
            {
              name: "PAGALAMATAN",
            },
            {
              name: "PAGONAYAN",
            },
            {
              name: "PIAGMA",
            },
            {
              name: "POBLACION (APARTFORT)",
            },
            {
              name: "PUNUD",
            },
            {
              name: "RANAO-BANING",
            },
            {
              name: "SAGUA-AN",
            },
            {
              name: "SALAM",
            },
            {
              name: "SUMUGOT",
            },
          ],
        },
        BUTIG: {
          name: "BUTIG",
          baranggays: [
            {
              name: "BAYABAO POBLACION",
            },
            {
              name: "BUTIG",
            },
            {
              name: "BUTIG PROPER",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "COLOYAN TAMBO",
            },
            {
              name: "DILIMBAYAN",
            },
            {
              name: "DOLANGAN",
            },
            {
              name: "MALUNGUN",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POKTAN",
            },
            {
              name: "RAGAYAN",
            },
            {
              name: "RAYA",
            },
            {
              name: "SAMER",
            },
            {
              name: "SANDAB MADAYA",
            },
            {
              name: "SUNDIG",
            },
            {
              name: "TIMBAB",
            },
            {
              name: "TIOWI",
            },
          ],
        },
        CALANOGAS: {
          name: "CALANOGAS",
          baranggays: [
            {
              name: "BUBONGA RANAO",
            },
            {
              name: "CALALAOAN (POB.)",
            },
            {
              name: "CALANOGAS",
            },
            {
              name: "GAS",
            },
            {
              name: "INOMA",
            },
            {
              name: "INUDARAN",
            },
            {
              name: "LUGUNA",
            },
            {
              name: "MIMBALAWAG",
            },
            {
              name: "NGINGIR",
            },
            {
              name: "PAGALONGAN",
            },
            {
              name: "PANGGAWALUPA",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PIKSAN",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "PUNUD",
            },
            {
              name: "TAGORANAO",
            },
            {
              name: "TALIBOBOKA",
            },
            {
              name: "TAMBAC",
            },
          ],
        },
        "DITSAAN-RAMAIN": {
          name: "DITSAAN-RAMAIN",
          baranggays: [
            {
              name: "BACLAYAN LILOD",
            },
            {
              name: "BACLAYAN RAYA",
            },
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BARIMBINGAN",
            },
            {
              name: "BAYABAO",
            },
            {
              name: "BUADI ALAO",
            },
            {
              name: "BUADI BABAI",
            },
            {
              name: "BUADI OLOC",
            },
            {
              name: "BUAYAAN LILOD",
            },
            {
              name: "BUAYAAN MADANDING",
            },
            {
              name: "BUAYAAN RAYA",
            },
            {
              name: "BUBONG DANGIPRAMPIAI",
            },
            {
              name: "DADO",
            },
            {
              name: "DANGIMPRAMPIAI",
            },
            {
              name: "DARIMBANG",
            },
            {
              name: "DILAUSAN",
            },
            {
              name: "DITSAAN",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "LINAMON",
            },
            {
              name: "LUMBATAN RAMAIN",
            },
            {
              name: "MAINDIG DITSAAN",
            },
            {
              name: "MANDARA",
            },
            {
              name: "MARANAO TIMBER (DALAMA)",
            },
            {
              name: "PAGALONGAN BUADIADINGAN",
            },
            {
              name: "PAGALONGAN GINAOPAN",
            },
            {
              name: "PAGALONGAN MASIOON",
            },
            {
              name: "PAGALONGAN PROPER",
            },
            {
              name: "POLO",
            },
            {
              name: "RAMAIN POBLACION",
            },
            {
              name: "RAMAIN PROPER",
            },
            {
              name: "RANTIAN",
            },
            {
              name: "SULTAN PANGADAPUN",
            },
            {
              name: "SUNDIGA BAYABAO",
            },
            {
              name: "TALUB",
            },
            {
              name: "UPPER PUGAAN",
            },
          ],
        },
        GANASSI: {
          name: "GANASSI",
          baranggays: [
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BALINTAD",
            },
            {
              name: "BALINTAD A",
            },
            {
              name: "BARIT",
            },
            {
              name: "BARORAO",
            },
            {
              name: "BATO BATORAY",
            },
            {
              name: "BAYA",
            },
            {
              name: "CAMPONG A RAYA",
            },
            {
              name: "CAMPONG SABELA",
            },
            {
              name: "DAPAAN",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "GANASSI",
            },
            {
              name: "GUI",
            },
            {
              name: "LINUK",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "LUMBACAINGUD",
            },
            {
              name: "MACABAO",
            },
            {
              name: "MACAGUILING",
            },
            {
              name: "MASOLUN",
            },
            {
              name: "PAGALONGAN",
            },
            {
              name: "PAMALIAN",
            },
            {
              name: "PANGADAPUN",
            },
            {
              name: "PANGGAWALUPA",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PANTAON A",
            },
            {
              name: "PARA-ABA",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SEKUN MATAMPAY",
            },
            {
              name: "SOGOD MADAYA",
            },
            {
              name: "TABUAN",
            },
            {
              name: "TAGANONOK",
            },
            {
              name: "TALIOGON",
            },
          ],
        },
        KAPAI: {
          name: "KAPAI",
          baranggays: [
            {
              name: "BABAYOG",
            },
            {
              name: "CORMATAN",
            },
            {
              name: "DILABAYAN",
            },
            {
              name: "DILIMBAYAN",
            },
            {
              name: "DIMAGALING (DIMAGALIN PROPER)",
            },
            {
              name: "DIMUNDA",
            },
            {
              name: "DORONAN",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "KAPAI",
            },
            {
              name: "KASAYANAN",
            },
            {
              name: "KASAYANAN WEST",
            },
            {
              name: "KIBOLOS",
            },
            {
              name: "KINING",
            },
            {
              name: "LIDASAN",
            },
            {
              name: "MACADAR",
            },
            {
              name: "MALNA PROPER",
            },
            {
              name: "PAGALONGAN",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PARAO",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POBLACION (KAPAI PROPER)",
            },
          ],
        },
        KAPATAGAN: {
          name: "KAPATAGAN",
          baranggays: [
            {
              name: "BAKIKIS",
            },
            {
              name: "BARAO",
            },
            {
              name: "BONGABONG",
            },
            {
              name: "DAGUAN",
            },
            {
              name: "INUDARAN",
            },
            {
              name: "KABANIAKAWAN",
            },
            {
              name: "KAPATAGAN",
            },
            {
              name: "KAPATAGAN",
            },
            {
              name: "LUSAIN",
            },
            {
              name: "MATIMOS",
            },
            {
              name: "MINIMAO",
            },
            {
              name: "PINANTAO",
            },
            {
              name: "SALAMAN",
            },
            {
              name: "SIGAYAN",
            },
            {
              name: "TABUAN",
            },
            {
              name: "UPPER IGABAY",
            },
          ],
        },
        "LUMBA-A BAYABAO": {
          name: "LUMBA-A BAYABAO",
          baranggays: [
            {
              name: "LUMBA-A BAYABAO",
            },
          ],
        },
        "LUMBA-BAYABAO (MAGUING)": {
          name: "LUMBA-BAYABAO (MAGUING)",
          baranggays: [
            {
              name: "BACOLOD I",
            },
            {
              name: "BACOLOD II",
            },
            {
              name: "BANTAYAO",
            },
            {
              name: "BARIT",
            },
            {
              name: "BAUGAN",
            },
            {
              name: "BUAD LUMBAC",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "CALILANGAN",
            },
            {
              name: "CARANDANGAN-MIPAGA",
            },
            {
              name: "CORMATAN LANGBAN",
            },
            {
              name: "DIALONGANA",
            },
            {
              name: "DILINDONGAN-CADAYONAN",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "GALAWAN",
            },
            {
              name: "GAMBAI",
            },
            {
              name: "KASOLA",
            },
            {
              name: "LALANGITUN",
            },
            {
              name: "LAMA",
            },
            {
              name: "LINDONGAN DIALONGANA",
            },
            {
              name: "LOBO BASARA",
            },
            {
              name: "LUMBAC BACAYAWAN",
            },
            {
              name: "MACAGUILING",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "MAPOLING",
            },
            {
              name: "MARIBO (POB.)",
            },
            {
              name: "MINARING DILADIGAN",
            },
            {
              name: "PAGAYAWAN",
            },
            {
              name: "POSUDARAGAT",
            },
            {
              name: "RUMAYAS",
            },
            {
              name: "SABALA BANTAYAO",
            },
            {
              name: "SALAMAN",
            },
            {
              name: "SALOLODUN BERWAR",
            },
            {
              name: "SARIGIDAN MADIAR",
            },
            {
              name: "SUNGGOD",
            },
            {
              name: "TALUAN",
            },
            {
              name: "TAMLANG",
            },
            {
              name: "TONGCOPAN",
            },
            {
              name: "TUROGAN",
            },
          ],
        },
        "LUMBACA-UNAYAN": {
          name: "LUMBACA-UNAYAN",
          baranggays: [
            {
              name: "BANGON (DILAUSAN)",
            },
            {
              name: "BETA",
            },
            {
              name: "CALALON",
            },
            {
              name: "CALIPAPA",
            },
            {
              name: "DILAUSAN",
            },
            {
              name: "DIMAPAOK",
            },
            {
              name: "LUMBAK-DILAUSAN",
            },
            {
              name: "ORIENTAL BETA",
            },
            {
              name: "TRINGUN",
            },
          ],
        },
        LUMBATAN: {
          name: "LUMBATAN",
          baranggays: [
            {
              name: "ALOG",
            },
            {
              name: "BASAYUNGUN",
            },
            {
              name: "BUAD",
            },
            {
              name: "BUBONG MACADAR",
            },
            {
              name: "BUDI",
            },
            {
              name: "DAGO-OK",
            },
            {
              name: "DALAMA",
            },
            {
              name: "DALIPUGA",
            },
            {
              name: "LALAPUNG",
            },
            {
              name: "LIGUE",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "LUMBAC BACAYAWAN",
            },
            {
              name: "LUMBATAN",
            },
            {
              name: "LUNAY",
            },
            {
              name: "MACADAR",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MINANGA",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PENARING",
            },
            {
              name: "PICOTAAN",
            },
            {
              name: "POBLACION (LUMBATAN)",
            },
            {
              name: "TAMBAC",
            },
          ],
        },
        LUMBAYANAGUE: {
          name: "LUMBAYANAGUE",
          baranggays: [
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BALAIGAY",
            },
            {
              name: "BUALAN",
            },
            {
              name: "CABUNTUNGAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "CADINGILAN",
            },
            {
              name: "CADINGILAN A",
            },
            {
              name: "CASALAYAN",
            },
            {
              name: "DALA (DALAMA)",
            },
            {
              name: "DILIMBAYAN",
            },
            {
              name: "DIROMOYOD",
            },
            {
              name: "KABASARAN (POB.)",
            },
            {
              name: "LAMIN",
            },
            {
              name: "LUMBAYANAGUE",
            },
            {
              name: "MAPANTAO-BALANGAS",
            },
            {
              name: "MINIROS",
            },
            {
              name: "NANAGUN",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "PITATANGLAN",
            },
            {
              name: "POCTAN",
            },
            {
              name: "SINGCARA",
            },
            {
              name: "WAGO",
            },
          ],
        },
        MADALUM: {
          name: "MADALUM",
          baranggays: [
            {
              name: "ABAGA",
            },
            {
              name: "BACAYAWAN",
            },
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BASAK",
            },
            {
              name: "BATO",
            },
            {
              name: "BUBONG",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "DANDAMUN",
            },
            {
              name: "DELAUSAN",
            },
            {
              name: "DIAMPACA",
            },
            {
              name: "DIBAROSAN",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "GURAIN",
            },
            {
              name: "KORMATAN",
            },
            {
              name: "LIANGAN",
            },
            {
              name: "LIANGAN I",
            },
            {
              name: "LILITUN",
            },
            {
              name: "LINAO",
            },
            {
              name: "LINUK",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MADALUM",
            },
            {
              name: "MADAYA",
            },
            {
              name: "PADIAN TOROGAN I",
            },
            {
              name: "PAGAYAWAN",
            },
            {
              name: "PARIDI-KALIMODAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNUD",
            },
            {
              name: "RACOTAN",
            },
            {
              name: "RAYA",
            },
            {
              name: "RIRAY",
            },
            {
              name: "SABANDING",
            },
            {
              name: "SALONGABANDING",
            },
            {
              name: "SOGOD KALOY",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TAMPORONG",
            },
            {
              name: "TONGANTONGAN",
            },
            {
              name: "UDANGUN",
            },
          ],
        },
        MADAMBA: {
          name: "MADAMBA",
          baranggays: [
            {
              name: "BALAGUNUN",
            },
            {
              name: "BALINTAD",
            },
            {
              name: "BAWANG",
            },
            {
              name: "BIABE",
            },
            {
              name: "BUBONG UYAAN",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "DIBARUSAN",
            },
            {
              name: "ILIAN",
            },
            {
              name: "LAKITAN",
            },
            {
              name: "LIANGAN",
            },
            {
              name: "LINUK",
            },
            {
              name: "LUMBACA INGUD",
            },
            {
              name: "MADAMBA",
            },
            {
              name: "MADAMBA",
            },
            {
              name: "PAGAYONAN",
            },
            {
              name: "PALAO",
            },
            {
              name: "PANGADAPAN",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PUNUD",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TUBARAN",
            },
            {
              name: "TUCA",
            },
            {
              name: "TULAY",
            },
            {
              name: "UYAAN PROPER (POB.)",
            },
          ],
        },
        MAGUING: {
          name: "MAGUING",
          baranggays: [
            {
              name: "AGAGAN",
            },
            {
              name: "BALAGUNUN",
            },
            {
              name: "BALAWAG",
            },
            {
              name: "BALINTAO",
            },
            {
              name: "BATO-BATO",
            },
            {
              name: "BOLAO",
            },
            {
              name: "BOROCOT",
            },
            {
              name: "BORROWA",
            },
            {
              name: "BOTUD",
            },
            {
              name: "BUADIANGKAY",
            },
            {
              name: "BUBONG",
            },
            {
              name: "BUBONG BAYABAO",
            },
            {
              name: "CAMALIG",
            },
            {
              name: "CAMBONG",
            },
            {
              name: "DILAUSAN (POB.)",
            },
            {
              name: "DILIMBAYAN",
            },
            {
              name: "ILALAG",
            },
            {
              name: "KIANODAN",
            },
            {
              name: "LILOD BOROCOT",
            },
            {
              name: "LILOD MAGUING",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "LUMBAC-DIMARAO",
            },
            {
              name: "MADANDING",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MAGUING",
            },
            {
              name: "MAGUING PROPER",
            },
            {
              name: "MALUNGUN",
            },
            {
              name: "MALUNGUN BOROCOT",
            },
            {
              name: "MALUNGUN PAGALONGAN",
            },
            {
              name: "PAGALONGAN",
            },
            {
              name: "PANAYANGAN",
            },
            {
              name: "PILIMOKNAN",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "RAGAYAN (RUNGAYAN)",
            },
            {
              name: "SABALA DILAUSAN",
            },
          ],
        },
        MALABANG: {
          name: "MALABANG",
          baranggays: [
            {
              name: "BACAYAWAN",
            },
            {
              name: "BADAK LUMAO",
            },
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BANDAY",
            },
            {
              name: "BETAYAN",
            },
            {
              name: "BONIGA",
            },
            {
              name: "BPS VILLAGE",
            },
            {
              name: "BUNK HOUSE",
            },
            {
              name: "CABASARAN (SOUTH)",
            },
            {
              name: "CALIBAGAT",
            },
            {
              name: "CALUMBOG",
            },
            {
              name: "CAMPO MUSLIM",
            },
            {
              name: "CHINA TOWN (POB.)",
            },
            {
              name: "CORAHAB",
            },
            {
              name: "DIAMARO",
            },
            {
              name: "INANDAYAN",
            },
            {
              name: "JOSE ABAD SANTOS",
            },
            {
              name: "LAMIN",
            },
            {
              name: "MABLE",
            },
            {
              name: "MACURANDING",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MALABANG",
            },
            {
              name: "MANANAYO",
            },
            {
              name: "MANGGAHAN",
            },
            {
              name: "MASAO",
            },
            {
              name: "MATALIN",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "MATLING",
            },
            {
              name: "MONTAY",
            },
            {
              name: "PASIR",
            },
            {
              name: "PIALOT",
            },
            {
              name: "REBOCUN",
            },
            {
              name: "SARANG",
            },
            {
              name: "SUMBAGAROGONG",
            },
            {
              name: "TACUB",
            },
            {
              name: "TAMBARA",
            },
            {
              name: "TIONGCOP",
            },
            {
              name: "TUBOC",
            },
          ],
        },
        MARAGONG: {
          name: "MARAGONG",
          baranggays: [
            {
              name: "MARAGONG",
            },
          ],
        },
        MARANTAO: {
          name: "MARANTAO",
          baranggays: [
            {
              name: "BACAYAWAN",
            },
            {
              name: "BACONG",
            },
            {
              name: "BANGA-PANTAR",
            },
            {
              name: "BATAL-PUNUD",
            },
            {
              name: "BUBONG MADANDING (BUBONG)",
            },
            {
              name: "CAMALIG (POB.)",
            },
            {
              name: "CAMALIG BANDARA INGUD",
            },
            {
              name: "CAMALIG BUBONG",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CAWAYAN BACOLOD",
            },
            {
              name: "CAWAYAN KALAW",
            },
            {
              name: "CAWAYAN LINUK",
            },
            {
              name: "DAANAINGUD",
            },
            {
              name: "ILIAN",
            },
            {
              name: "INUDARAN CAMPONG",
            },
            {
              name: "INUDARAN LOWAY",
            },
            {
              name: "INUDARAN LUMBAC",
            },
            {
              name: "KIALDAN",
            },
            {
              name: "LUBO",
            },
            {
              name: "LUMBAC KIALDAN",
            },
            {
              name: "MANTAPOLI",
            },
            {
              name: "MARANTAO",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "MAUL",
            },
            {
              name: "MAUL ILIAN",
            },
            {
              name: "MAUL LUMBACA INGUD",
            },
            {
              name: "NATARON",
            },
            {
              name: "PAGALONGAN BACAYAWAN",
            },
            {
              name: "PALAO",
            },
            {
              name: "PATAIMAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POONA MARANTAO",
            },
            {
              name: "PUNUD PROPER",
            },
            {
              name: "TACUB",
            },
            {
              name: "TUCA KIALDAN",
            },
          ],
        },
        MARAWI: {
          name: "MARAWI",
          baranggays: [
            {
              name: "MARAWI",
            },
          ],
        },
        "MARAWI CITY": {
          name: "MARAWI CITY",
          baranggays: [
            {
              name: "AMBOLONG",
            },
            {
              name: "AMITO MARANTAO",
            },
            {
              name: "BACOLOD CHICO PROPER",
            },
            {
              name: "BANGA",
            },
            {
              name: "BANGCO",
            },
            {
              name: "BANGGOLO POBLACION",
            },
            {
              name: "BANGON",
            },
            {
              name: "BASAK MALUTLUT",
            },
            {
              name: "BEYABA-DAMAG",
            },
            {
              name: "BITO BUADI ITOWA",
            },
            {
              name: "BITO BUADI PARBA",
            },
            {
              name: "BOGANGA",
            },
            {
              name: "BOTO AMBOLONG",
            },
            {
              name: "BUADI SACAYO (GREEN)",
            },
            {
              name: "BUBONG LUMBAC",
            },
            {
              name: "BUBONGA CADAYONAN",
            },
            {
              name: "BUBONGA LILOD MADAYA",
            },
            {
              name: "BUBONGA MARAWI",
            },
            {
              name: "BUBONGA PAGALAMATAN",
            },
            {
              name: "BUBONGA PUNOD",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "CABINGAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "CADAYONAN I",
            },
            {
              name: "CALOCAN EAST",
            },
            {
              name: "CALOCAN WEST",
            },
            {
              name: "DAGUDUBAN",
            },
            {
              name: "DANSALAN",
            },
            {
              name: "DATU NAGA",
            },
            {
              name: "DATU SA DANSALAN",
            },
            {
              name: "DAYAWAN",
            },
            {
              name: "DIMALUNA",
            },
            {
              name: "DULAY",
            },
            {
              name: "DULAY WEST",
            },
            {
              name: "EAST BASAK",
            },
            {
              name: "EMIE PUNUD",
            },
            {
              name: "FORT",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "GADONGAN MAPANTAO",
            },
            {
              name: "GUIMBA (LILOD PROPER)",
            },
            {
              name: "KAPANTARAN",
            },
            {
              name: "KILALA",
            },
            {
              name: "KORMATAN MATAMPAY",
            },
            {
              name: "LILOD MADAYA (POB.)",
            },
            {
              name: "LILOD SADUC",
            },
            {
              name: "LOMIDONG",
            },
            {
              name: "LUMBAC MARINAUT",
            },
            {
              name: "LUMBACA MADAYA (POB.)",
            },
            {
              name: "LUMBACA TOROS",
            },
            {
              name: "MALIMONO",
            },
            {
              name: "MARAWI POBLACION",
            },
            {
              name: "MARINAUT (MARINAUT WEST)",
            },
            {
              name: "MARINAUT EAST",
            },
            {
              name: "MATAMPAY",
            },
            {
              name: "MIPAGA PROPER",
            },
            {
              name: "MONCADO COLONY",
            },
            {
              name: "MONCADO KADINGILAN",
            },
            {
              name: "MORIATAO LOKSADATO",
            },
            {
              name: "NAVARRO (DATU SABER)",
            },
            {
              name: "NORHAYA VILLAGE",
            },
            {
              name: "OLAWA AMBOLONG",
            },
            {
              name: "PAGALAMATAN GAMBAI",
            },
            {
              name: "PAGAYAWAN",
            },
            {
              name: "PANGGAO SADUC",
            },
            {
              name: "PANTAON (LANGCAF)",
            },
            {
              name: "PAPANDAYAN",
            },
            {
              name: "PAPANDAYAN CANIOGAN",
            },
            {
              name: "PARIDI",
            },
            {
              name: "PATANI",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POONA MARANTAO",
            },
            {
              name: "PUGAAN",
            },
            {
              name: "RAPASUN MSU",
            },
            {
              name: "RAYA MADAYA I",
            },
            {
              name: "RAYA MADAYA II",
            },
            {
              name: "RAYA SADUC",
            },
            {
              name: "ROROGAGUS EAST",
            },
            {
              name: "ROROGAGUS PROPER",
            },
            {
              name: "SABALA MANAO",
            },
            {
              name: "SABALA MANAO PROPER",
            },
            {
              name: "SADUC PROPER",
            },
            {
              name: "SAGONSONGAN",
            },
            {
              name: "SANGCAY DANSALAN",
            },
            {
              name: "SOMIORANG",
            },
            {
              name: "SOUTH MADAYA PROPER",
            },
            {
              name: "SUGOD PROPER",
            },
            {
              name: "TAMPILONG",
            },
            {
              name: "TIMBANGALAN",
            },
            {
              name: "TOLALI",
            },
            {
              name: "TONGANTONGAN-TUCA TIMBANGALAN",
            },
            {
              name: "TOROS",
            },
            {
              name: "TUCA",
            },
            {
              name: "TUCA AMBOLONG",
            },
            {
              name: "TUCA MARINAUT",
            },
            {
              name: "WAWALAYAN CALOCAN",
            },
            {
              name: "WAWALAYAN MARINAUT",
            },
          ],
        },
        MAROGONG: {
          name: "MAROGONG",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALUT",
            },
            {
              name: "BITAYAN",
            },
            {
              name: "BOLAWAN",
            },
            {
              name: "BONGA",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "CAHERA",
            },
            {
              name: "CAIRANTANG",
            },
            {
              name: "CALUMBOG",
            },
            {
              name: "CANIBONGAN",
            },
            {
              name: "DIRAGUN",
            },
            {
              name: "MANTAILOCO",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "MAROGONG EAST",
            },
            {
              name: "MAROGONG PROPER (POB.)",
            },
            {
              name: "MAYAMAN",
            },
            {
              name: "PABRICA",
            },
            {
              name: "PAIGOAY CODA",
            },
            {
              name: "PASAYANAN",
            },
            {
              name: "PIANGOLOGAN",
            },
            {
              name: "PURACAN",
            },
            {
              name: "ROMAGONDONG",
            },
            {
              name: "SARANG",
            },
          ],
        },
        MASIU: {
          name: "MASIU",
          baranggays: [
            {
              name: "ABDULLAH BUISAN",
            },
            {
              name: "ALIP LALABUAN",
            },
            {
              name: "ALUMPANG PAINO MIMBALAY",
            },
            {
              name: "BUADI AMLOY",
            },
            {
              name: "CARAMIAN ALIM RAYA",
            },
            {
              name: "DALOG BALUT",
            },
            {
              name: "GINDOLONGAN ALABAT",
            },
            {
              name: "GONDARANGIN ASA ADIGAO",
            },
            {
              name: "KALILANGAN",
            },
            {
              name: "LAILA LUMBAC BACON",
            },
            {
              name: "LAKADUN",
            },
            {
              name: "LANCO DIMAPATOY",
            },
            {
              name: "LOMIGIS SUCOD",
            },
            {
              name: "LUMBACA INGUD",
            },
            {
              name: "MACABANGAN IMBALA",
            },
            {
              name: "MACADAAG TALAGUIAN",
            },
            {
              name: "MACALUPANG LUMBAC CARAMIAN",
            },
            {
              name: "MACOMPARA APA MIMBALAY",
            },
            {
              name: "MAGAYO BAGOAINGUD",
            },
            {
              name: "MAI DITIMBANG BALINDONG",
            },
            {
              name: "MAI SINDAOLOAN DANSALAN",
            },
            {
              name: "MANALOCON TALUB",
            },
            {
              name: "MARANAT BONTALIS",
            },
            {
              name: "MASIU",
            },
            {
              name: "MATAO ARAZA",
            },
            {
              name: "MOCAMAD TANGUL",
            },
            {
              name: "MORIATAO-BAI LABAY",
            },
            {
              name: "PANTAO",
            },
            {
              name: "PUTAD MARANDANG BUISAN",
            },
            {
              name: "SAMBOWANG ATAWA",
            },
            {
              name: "SAWIR",
            },
            {
              name: "TALUB LANGI",
            },
            {
              name: "TAMBORO CORMATAN",
            },
            {
              name: "TOMAMBILING LUMBACA INGUD",
            },
            {
              name: "TOWANAO ARANGGA",
            },
            {
              name: "UNDA DAYAWAN",
            },
          ],
        },
        MOLUNDO: {
          name: "MOLUNDO",
          baranggays: [
            {
              name: "MOLUNDO",
            },
          ],
        },
        MULONDO: {
          name: "MULONDO",
          baranggays: [
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BANGON",
            },
            {
              name: "BUADI-ABALA",
            },
            {
              name: "BUADI-BAYAWA",
            },
            {
              name: "BUADI-INSUBA",
            },
            {
              name: "BUBONG",
            },
            {
              name: "BUBONGA GUILOPA",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "CAIRATAN",
            },
            {
              name: "CORMATAN",
            },
            {
              name: "DALAMA",
            },
            {
              name: "DANSALAN",
            },
            {
              name: "DIMARAO",
            },
            {
              name: "GUILOPA",
            },
            {
              name: "ILIAN",
            },
            {
              name: "KITAMBUGUN",
            },
            {
              name: "LAMA (BAGOAINGUD)",
            },
            {
              name: "LILOD",
            },
            {
              name: "LILOD RAYBALAI",
            },
            {
              name: "LUMBAC (LUMBAC BUBONG)",
            },
            {
              name: "LUMBACA INGUD",
            },
            {
              name: "MADAYA",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POBLACION (DADO)",
            },
            {
              name: "SALIPONGAN",
            },
            {
              name: "SUGAN",
            },
          ],
        },
        "PAGAYAWAN (TATARIKAN)": {
          name: "PAGAYAWAN (TATARIKAN)",
          baranggays: [
            {
              name: "AYONG",
            },
            {
              name: "BANDARA INGUD",
            },
            {
              name: "BANGON (POB.)",
            },
            {
              name: "BIALA-AN",
            },
            {
              name: "DIAMPACA",
            },
            {
              name: "GUIARONG",
            },
            {
              name: "ILIAN",
            },
            {
              name: "KALALUDAN",
            },
            {
              name: "LININDINGAN",
            },
            {
              name: "MADANG",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "NGINGIR (KABASARAN)",
            },
            {
              name: "PADAS",
            },
            {
              name: "PAIGOAY",
            },
            {
              name: "PINALANGCA",
            },
            {
              name: "POBLACION (LUMBAC)",
            },
            {
              name: "RANGIRAN",
            },
            {
              name: "RUBOKUN",
            },
          ],
        },
        PAGAYAWON: {
          name: "PAGAYAWON",
          baranggays: [
            {
              name: "PAGAYAWON",
            },
          ],
        },
        PIAGAPO: {
          name: "PIAGAPO",
          baranggays: [
            {
              name: "APOSONG",
            },
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BANGCO (POB.)",
            },
            {
              name: "BANSAYAN",
            },
            {
              name: "BASAK",
            },
            {
              name: "BOBO",
            },
            {
              name: "BUALAN",
            },
            {
              name: "BUBONG ILIAN",
            },
            {
              name: "BUBONG TAWA-AN",
            },
            {
              name: "BUBONGA MAMAANUN",
            },
            {
              name: "GACAP",
            },
            {
              name: "ILIAN",
            },
            {
              name: "ILIAN POBLACION",
            },
            {
              name: "KALANGANAN",
            },
            {
              name: "KATUMBACAN",
            },
            {
              name: "LININDING",
            },
            {
              name: "LUMBACA MAMAAN",
            },
            {
              name: "MAMAANUN",
            },
            {
              name: "MENTRING",
            },
            {
              name: "OLANGO",
            },
            {
              name: "PALACAT",
            },
            {
              name: "PALAO",
            },
            {
              name: "PALING",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PANTAR",
            },
            {
              name: "PARIDI",
            },
            {
              name: "PIAGAPO",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "RADAPAN",
            },
            {
              name: "RADAPAN POBLACION",
            },
            {
              name: "RANTIAN",
            },
            {
              name: "SAPINGIT",
            },
            {
              name: "TALAO",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TAPOCAN",
            },
            {
              name: "TAPORUG",
            },
            {
              name: "TAWAAN",
            },
            {
              name: "UDALO",
            },
          ],
        },
        "PICONG (SULTAN GUMANDER)": {
          name: "PICONG (SULTAN GUMANDER)",
          baranggays: [
            {
              name: "ANAS",
            },
            {
              name: "BARA-AS",
            },
            {
              name: "BIASONG",
            },
            {
              name: "BULANGOS",
            },
            {
              name: "DURIAN",
            },
            {
              name: "ILIAN",
            },
            {
              name: "LIANGAN (POB.)",
            },
            {
              name: "MAGANDING",
            },
            {
              name: "MALADI",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "MICALUBO",
            },
            {
              name: "MIMBALAWAG",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "PUNONG",
            },
            {
              name: "RAMITAN",
            },
            {
              name: "TOROGAN",
            },
            {
              name: "TUAL",
            },
            {
              name: "TUCA",
            },
            {
              name: "UBANOBAN",
            },
          ],
        },
        "POON-A BAYABAO": {
          name: "POON-A BAYABAO",
          baranggays: [
            {
              name: "POON-A BAYABAO",
            },
          ],
        },
        "POONA BAYABAO (GATA)": {
          name: "POONA BAYABAO (GATA)",
          baranggays: [
            {
              name: "ATARAGADONG",
            },
            {
              name: "BANGON",
            },
            {
              name: "BANSAYAN",
            },
            {
              name: "BUALAN",
            },
            {
              name: "BUBONG-DIMUNDA",
            },
            {
              name: "BUGARAN",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "CALILANGAN DICALA",
            },
            {
              name: "CALUPAAN",
            },
            {
              name: "DILAUSAN",
            },
            {
              name: "DIMAYON",
            },
            {
              name: "DONGCOAN",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "LIANGAN",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "LUMBACA INGUD",
            },
            {
              name: "MADANDING",
            },
            {
              name: "PANTAO",
            },
            {
              name: "POBLACION (GATA PROPER)",
            },
            {
              name: "PUNUD",
            },
            {
              name: "RAGAYAN",
            },
            {
              name: "ROGAN CAIRAN",
            },
            {
              name: "ROGAN TANDIONG DIMAYON",
            },
            {
              name: "TALAGUIAN",
            },
            {
              name: "TAPOROG",
            },
          ],
        },
        PUALAS: {
          name: "PUALAS",
          baranggays: [
            {
              name: "BADAK",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BASAGAD",
            },
            {
              name: "BOLINSONG",
            },
            {
              name: "BORING",
            },
            {
              name: "BUALAN",
            },
            {
              name: "DANUGAN",
            },
            {
              name: "DAPAO",
            },
            {
              name: "DIAMLA",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "INGUD",
            },
            {
              name: "LINUK",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MALIGO",
            },
            {
              name: "MASAO",
            },
            {
              name: "NOTONG",
            },
            {
              name: "PORUG",
            },
            {
              name: "PUALAS",
            },
            {
              name: "ROMAGONDONG",
            },
            {
              name: "TAMBO (POB.)",
            },
            {
              name: "TAMLANG",
            },
            {
              name: "TOMAROMPONG",
            },
            {
              name: "TUKA",
            },
            {
              name: "YARAN",
            },
          ],
        },
        SAGUIARAN: {
          name: "SAGUIARAN",
          baranggays: [
            {
              name: "ALINUN",
            },
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BASAK MAITO",
            },
            {
              name: "BATANGAN",
            },
            {
              name: "BUBONG",
            },
            {
              name: "CADAYON",
            },
            {
              name: "CADINGILAN",
            },
            {
              name: "COMONAL",
            },
            {
              name: "DILAUSAN",
            },
            {
              name: "DILIMBAYAN",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "LIMOGAO",
            },
            {
              name: "LINAO",
            },
            {
              name: "LUMBAC TOROS",
            },
            {
              name: "LUMBAYANAGUE",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "MIPAGA",
            },
            {
              name: "NATANGCOPAN",
            },
            {
              name: "PAGALAMATAN",
            },
            {
              name: "PAMACOTAN",
            },
            {
              name: "PANGGAO",
            },
            {
              name: "PANTAO RAYA",
            },
            {
              name: "PANTAON",
            },
            {
              name: "PATPANGKAT",
            },
            {
              name: "PAWAK",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGUIARAN",
            },
            {
              name: "SALOCAD",
            },
            {
              name: "SUNGCOD",
            },
          ],
        },
        "SULTAN DUMALONDONG": {
          name: "SULTAN DUMALONDONG",
          baranggays: [
            {
              name: "BACAYAWAN",
            },
            {
              name: "BUTA (SUMALINDAO)",
            },
            {
              name: "DINGANUN GUILOPA (DINGUNUN)",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MALALIS",
            },
            {
              name: "PAGALONGAN",
            },
            {
              name: "TAGORANAO",
            },
          ],
        },
        "SULTAN GUMANDER": {
          name: "SULTAN GUMANDER",
          baranggays: [
            {
              name: "SULTAN GUMANDER",
            },
          ],
        },
        TAGALOAN: {
          name: "TAGALOAN",
          baranggays: [
            {
              name: "TAGALOAN",
            },
          ],
        },
        TAGOLOAN: {
          name: "TAGOLOAN",
          baranggays: [
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BANTALAN",
            },
            {
              name: "BAYOG",
            },
            {
              name: "CADAYONAN",
            },
            {
              name: "DAGONALAN",
            },
            {
              name: "DIMALAMA",
            },
            {
              name: "GAYAKAY",
            },
            {
              name: "INODARAN",
            },
            {
              name: "KALILANGAN",
            },
            {
              name: "KIANIBONG",
            },
            {
              name: "KINGAN",
            },
            {
              name: "KITAON",
            },
            {
              name: "MAIMBAGUIANG",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MALINGON",
            },
            {
              name: "MAMA-AN PAGALONGAN",
            },
            {
              name: "MARAWI",
            },
            {
              name: "SIGAYAN",
            },
            {
              name: "TAGOLOAN POBLACION",
            },
          ],
        },
        TAMPARAN: {
          name: "TAMPARAN",
          baranggays: [
            {
              name: "BALUTMADIAR",
            },
            {
              name: "BANGON",
            },
            {
              name: "BERUAR",
            },
            {
              name: "BOCALAN",
            },
            {
              name: "CABASARAN",
            },
            {
              name: "DASOMALONG",
            },
            {
              name: "DILAUSAN",
            },
            {
              name: "GINAOPAN",
            },
            {
              name: "LALABUAN",
            },
            {
              name: "LILOD TAMPARAN",
            },
            {
              name: "LILOD TUBOK",
            },
            {
              name: "LINDONGAN",
            },
            {
              name: "LINUK",
            },
            {
              name: "LINUK ORIENTAL",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "LUMBACA INGUD",
            },
            {
              name: "LUMBACA LILOD",
            },
            {
              name: "LUMBACAINGUD SOUTH",
            },
            {
              name: "MAIDAN LINUK",
            },
            {
              name: "MALA-ABANGON",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "MARIATAO DATU",
            },
            {
              name: "MINANGA",
            },
            {
              name: "MIONDAS",
            },
            {
              name: "NEW LUMBACAINGUD",
            },
            {
              name: "OCCIDENTAL LINUK",
            },
            {
              name: "PAGALAMATAN LINUK",
            },
            {
              name: "PAGAYAWAN",
            },
            {
              name: "PICARABAWAN",
            },
            {
              name: "PIMBAGO-PAGALONGAN",
            },
            {
              name: "PINDOLONAN MARIATAO SARIP",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "RAYA BUADI BARAO",
            },
            {
              name: "RAYA NIONDAS",
            },
            {
              name: "RAYA TAMPARAN",
            },
            {
              name: "SALONGABANDING",
            },
            {
              name: "SAMINUNGGAY",
            },
            {
              name: "TALUB",
            },
            {
              name: "TAMPARAN",
            },
            {
              name: "TATAYAWAN NORTH",
            },
            {
              name: "TATAYAWAN SOUTH",
            },
            {
              name: "TUBOK",
            },
          ],
        },
        TARAKA: {
          name: "TARAKA",
          baranggays: [
            {
              name: "BANDERA BUISAN",
            },
            {
              name: "BORIONGAN",
            },
            {
              name: "BOROWA",
            },
            {
              name: "BUADI AMAO",
            },
            {
              name: "BUADI AMUNTA",
            },
            {
              name: "BUADI AMUNUD",
            },
            {
              name: "BUADI ARORAO",
            },
            {
              name: "BUADI ATOPA",
            },
            {
              name: "BUADI DAYOMANGGA",
            },
            {
              name: "BUADI DINGUN",
            },
            {
              name: "BUADI ONGCALO",
            },
            {
              name: "BUCALAN",
            },
            {
              name: "CADAYONAN BAGUMBAYAN",
            },
            {
              name: "CARAMAT",
            },
            {
              name: "CARANDANGAN CALOPAAN",
            },
            {
              name: "DATU MA-AS",
            },
            {
              name: "DILABAYAN",
            },
            {
              name: "DIMAYON",
            },
            {
              name: "GAPAO BALINDONG",
            },
            {
              name: "ILIAN",
            },
            {
              name: "LUMASA",
            },
            {
              name: "LUMASA PROPER (SALVADOR CONCHA)",
            },
            {
              name: "LUMBAC BAGOAINGUD",
            },
            {
              name: "LUMBAC BUBONG MAINDANG",
            },
            {
              name: "LUMBAC PITAKUS",
            },
            {
              name: "MALUNGUN",
            },
            {
              name: "MANGAYAO",
            },
            {
              name: "MARUHOM",
            },
            {
              name: "MASOLUN",
            },
            {
              name: "MORIATAO LOKSA DATU",
            },
            {
              name: "PAGALAMATAN",
            },
            {
              name: "PINDOLONAN",
            },
            {
              name: "PITAKUS",
            },
            {
              name: "RIRIK",
            },
            {
              name: "SALIPONGAN",
            },
            {
              name: "SAMBOLAWAN",
            },
            {
              name: "SAMPORNA SALAMATOLLAH",
            },
            {
              name: "SIGAYAN PROPER",
            },
            {
              name: "SOMIORANG BANDINGUN",
            },
            {
              name: "SUNDING",
            },
            {
              name: "SUNGGOD",
            },
            {
              name: "SUPANGAN",
            },
            {
              name: "TARAKA",
            },
            {
              name: "TUPA-AN BUADIATUPA",
            },
          ],
        },
        TUBARAN: {
          name: "TUBARAN",
          baranggays: [
            {
              name: "ALOG",
            },
            {
              name: "BAGIANGUN",
            },
            {
              name: "BETA",
            },
            {
              name: "CAMPO",
            },
            {
              name: "DATUMANONG",
            },
            {
              name: "DINAIGAN",
            },
            {
              name: "GADONGAN",
            },
            {
              name: "GAPUT",
            },
            {
              name: "GUIARONG",
            },
            {
              name: "MADAYA",
            },
            {
              name: "MALAGANDING",
            },
            {
              name: "METADICOP",
            },
            {
              name: "MINDAMUDAG",
            },
            {
              name: "PAGALAMATAN",
            },
            {
              name: "PAIGOAY-PIMBATAAN",
            },
            {
              name: "POBLACION (BURIBID)",
            },
            {
              name: "POLO",
            },
            {
              name: "RIANTARAN",
            },
            {
              name: "TANGCAL",
            },
            {
              name: "TUBARAN",
            },
            {
              name: "TUBARAN PROPER",
            },
            {
              name: "WAGO",
            },
          ],
        },
        TUGAYA: {
          name: "TUGAYA",
          baranggays: [
            {
              name: "BAGOAINGUD",
            },
            {
              name: "BUADI ALAWANG",
            },
            {
              name: "BUADI DICO",
            },
            {
              name: "BUBONG",
            },
            {
              name: "CAMPONG TALAO",
            },
            {
              name: "CAYAGAN",
            },
            {
              name: "DANDANUN",
            },
            {
              name: "DILIMBAYAN",
            },
            {
              name: "GURAIN",
            },
            {
              name: "LUMBAC",
            },
            {
              name: "MAIDAN",
            },
            {
              name: "MAPANTAO",
            },
            {
              name: "PAGALAMATAN",
            },
            {
              name: "PANDIARANAO",
            },
            {
              name: "PINDOLONAN I",
            },
            {
              name: "PINDOLONAN II",
            },
            {
              name: "POBLACION (INGUD)",
            },
            {
              name: "PUTAD",
            },
            {
              name: "RAYA",
            },
            {
              name: "SUGOD I",
            },
            {
              name: "SUGOD MAWATAN",
            },
            {
              name: "SUMBAGA ROGONG",
            },
            {
              name: "TANGCAL",
            },
            {
              name: "TUGAYA",
            },
          ],
        },
        WAO: {
          name: "WAO",
          baranggays: [
            {
              name: "AMOYONG",
            },
            {
              name: "BALATIN",
            },
            {
              name: "BANGA",
            },
            {
              name: "BO-OT",
            },
            {
              name: "BUNTONGUN",
            },
            {
              name: "CEBUANO GROUP",
            },
            {
              name: "CHRISTIAN VILLAGE",
            },
            {
              name: "EASTERN WAO",
            },
            {
              name: "EXTENSION POBLACION",
            },
            {
              name: "GATA",
            },
            {
              name: "KABATANGAN",
            },
            {
              name: "KADINGILAN",
            },
            {
              name: "KATUTUNGAN (POB.)",
            },
            {
              name: "KILIKILI EAST",
            },
            {
              name: "KILIKILI WEST",
            },
            {
              name: "MALAIGANG",
            },
            {
              name: "MANILA GROUP",
            },
            {
              name: "MILAYA",
            },
            {
              name: "MIMBUAYA",
            },
            {
              name: "MUSLIM VILLAGE",
            },
            {
              name: "PAGALONGAN",
            },
            {
              name: "PANANG",
            },
            {
              name: "PARK AREA (POB.)",
            },
            {
              name: "PILINTANGAN",
            },
            {
              name: "SERRAN VILLAGE",
            },
            {
              name: "WAO",
            },
            {
              name: "WESTERN WAO (POB.)",
            },
          ],
        },
      },
    },
    LEYTE: {
      name: "LEYTE",
      region: "REGION 8",
      municipalities: {
        ABUYOG: {
          name: "ABUYOG",
          baranggays: [
            {
              name: "ABUYOG",
            },
            {
              name: "ALANGILAN",
            },
            {
              name: "ANIBONGAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BALINSASAYAO",
            },
            {
              name: "BALOCAWE",
            },
            {
              name: "BALOCAWEHAY",
            },
            {
              name: "BARAYONG",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BITO (POB.)",
            },
            {
              name: "BUAYA",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULAK",
            },
            {
              name: "BUNGA",
            },
            {
              name: "BUNTAY (POB.)",
            },
            {
              name: "BURUBUD-AN",
            },
            {
              name: "CADAC-AN",
            },
            {
              name: "CAGBOLO",
            },
            {
              name: "CAN-APORONG",
            },
            {
              name: "CAN-UGUIB (POB.)",
            },
            {
              name: "CANMARATING",
            },
            {
              name: "CAPILIAN",
            },
            {
              name: "COMBIS",
            },
            {
              name: "DINGLE",
            },
            {
              name: "GUINTAGBUCAN (POB.)",
            },
            {
              name: "HAMPIPILA",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KIKILO",
            },
            {
              name: "LARAY",
            },
            {
              name: "LAWA-AN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LOYONSAWANG (POB.)",
            },
            {
              name: "MAG-ATUBANG",
            },
            {
              name: "MAHAGNA (NEW CAGBOLO)",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MAITUM",
            },
            {
              name: "MALAGUICAY",
            },
            {
              name: "MATAGNAO",
            },
            {
              name: "NALIBUNAN (POB.)",
            },
            {
              name: "NEBGA",
            },
            {
              name: "NEW TALIGUE",
            },
            {
              name: "ODIONGAN",
            },
            {
              name: "OLD TALIGUE",
            },
            {
              name: "PAGSANG-AN",
            },
            {
              name: "PAGUITE",
            },
            {
              name: "PARASANON",
            },
            {
              name: "PICAS SUR",
            },
            {
              name: "PILAR",
            },
            {
              name: "PINAMANAGAN",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA FE (POB.)",
            },
            {
              name: "SANTA LUCIA (POB.)",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "TABIGUE",
            },
            {
              name: "TADOC",
            },
            {
              name: "TIB-O",
            },
            {
              name: "TINALIAN",
            },
            {
              name: "TINOCOLAN",
            },
            {
              name: "TUY-A",
            },
            {
              name: "VICTORY (POB.)",
            },
          ],
        },
        ALANGALANG: {
          name: "ALANGALANG",
          baranggays: [
            {
              name: "ALANGALANG",
            },
            {
              name: "ASLUM",
            },
            {
              name: "ASTORGA (BURABOD)",
            },
            {
              name: "BATO",
            },
            {
              name: "BINONGTO-AN",
            },
            {
              name: "BINOTONG",
            },
            {
              name: "BLUMENTRITT (POB.)",
            },
            {
              name: "BOBONON",
            },
            {
              name: "BORSETH",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUGHO",
            },
            {
              name: "BURI",
            },
            {
              name: "CABADSAN",
            },
            {
              name: "CALAASAN",
            },
            {
              name: "CAMBAHANON",
            },
            {
              name: "CAMBOLAO",
            },
            {
              name: "CANVERTUDES",
            },
            {
              name: "CAPIZ",
            },
            {
              name: "CAVITE",
            },
            {
              name: "COGON",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "EKIRAN",
            },
            {
              name: "HINAPOLAN",
            },
            {
              name: "HOLY CHILD I (POB.)",
            },
            {
              name: "HOLY CHILD II (POB.)",
            },
            {
              name: "HUBANG",
            },
            {
              name: "HUPIT",
            },
            {
              name: "LANGIT",
            },
            {
              name: "LINGAYON",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LUKAY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MILAGROSA (POB.)",
            },
            {
              name: "MUDBORON",
            },
            {
              name: "P. BARRANTES",
            },
            {
              name: "PEÑALOSA",
            },
            {
              name: "PEPITA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SALVACION POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ANTONIO POB. (PATONG NORTE)",
            },
            {
              name: "SAN DIEGO",
            },
            {
              name: "SAN FRANCISCO EAST",
            },
            {
              name: "SAN FRANCISCO WEST",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "SANTOL",
            },
            {
              name: "TABANGOHAY",
            },
            {
              name: "TOMBO",
            },
            {
              name: "VETERANOS",
            },
          ],
        },
        ALBUERA: {
          name: "ALBUERA",
          baranggays: [
            {
              name: "ALBUERA",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "BALUGO",
            },
            {
              name: "BENOLHO",
            },
            {
              name: "CAMBALADING",
            },
            {
              name: "DAMULA-AN",
            },
            {
              name: "DOÑA MARIA (KANGKUIRINA)",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SEGUINON",
            },
            {
              name: "SHERWOOD",
            },
            {
              name: "TABGAS",
            },
            {
              name: "TALISAYAN",
            },
            {
              name: "TINAG-AN",
            },
          ],
        },
        BABATNGON: {
          name: "BABATNGON",
          baranggays: [
            {
              name: "BABATNGON",
            },
            {
              name: "BACONG",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BIASONG",
            },
            {
              name: "GOV. E. JARO (BAGAHUPI)",
            },
            {
              name: "GUINTIGUI-AN",
            },
            {
              name: "LUKAY",
            },
            {
              name: "MAGCASUANG",
            },
            {
              name: "MALIBAGO",
            },
            {
              name: "NAGA-ASAN",
            },
            {
              name: "PAGSULHUGON",
            },
            {
              name: "PLANZA",
            },
            {
              name: "POBLACION DISTRICT I",
            },
            {
              name: "POBLACION DISTRICT II",
            },
            {
              name: "POBLACION DISTRICT III",
            },
            {
              name: "POBLACION DISTRICT IV",
            },
            {
              name: "RIZAL I",
            },
            {
              name: "RIZAL II",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RICARDO",
            },
            {
              name: "SANGPUTAN",
            },
            {
              name: "TAGUITE",
            },
            {
              name: "UBAN",
            },
            {
              name: "VICTORY",
            },
            {
              name: "VILLA MAGSAYSAY",
            },
          ],
        },
        BARUGO: {
          name: "BARUGO",
          baranggays: [
            {
              name: "ABANGO",
            },
            {
              name: "AMAHIT",
            },
            {
              name: "BALIRE",
            },
            {
              name: "BALUD",
            },
            {
              name: "BARUGO",
            },
            {
              name: "BUKID",
            },
            {
              name: "BULOD",
            },
            {
              name: "BUSAY",
            },
            {
              name: "CABARASAN",
            },
            {
              name: "CABOLO-AN",
            },
            {
              name: "CALINGCAGUING",
            },
            {
              name: "CAN-ISAK",
            },
            {
              name: "CANOMANTAG",
            },
            {
              name: "CUTA",
            },
            {
              name: "DOMOGDOG",
            },
            {
              name: "DUKA",
            },
            {
              name: "GUINDAOHAN",
            },
            {
              name: "HIAGSAM",
            },
            {
              name: "HILABA",
            },
            {
              name: "HINUGAYAN",
            },
            {
              name: "IBAG",
            },
            {
              name: "MINUHANG",
            },
            {
              name: "MINUSWANG",
            },
            {
              name: "PIKAS",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POBLACION DIST. I",
            },
            {
              name: "POBLACION DIST. II",
            },
            {
              name: "POBLACION DIST. III",
            },
            {
              name: "POBLACION DIST. IV",
            },
            {
              name: "POBLACION DIST. V",
            },
            {
              name: "POBLACION DIST. VI (NEW ROAD)",
            },
            {
              name: "PONGSO",
            },
            {
              name: "ROOSEVELT",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTARIN",
            },
            {
              name: "TUTUG-AN",
            },
          ],
        },
        BATO: {
          name: "BATO",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "ALEJOS",
            },
            {
              name: "AMAGOS",
            },
            {
              name: "ANAHAWAN",
            },
            {
              name: "BAGO",
            },
            {
              name: "BAGONG BAYAN DISTRICT (POB.)",
            },
            {
              name: "BATO",
            },
            {
              name: "BULI",
            },
            {
              name: "CEBUANA",
            },
            {
              name: "DAAN LUNGSOD",
            },
            {
              name: "DAWAHON",
            },
            {
              name: "DOLHO",
            },
            {
              name: "DOMAGOCDOC",
            },
            {
              name: "GUERRERO DISTRICT (POB.)",
            },
            {
              name: "HIMAMAA",
            },
            {
              name: "IMELDA",
            },
            {
              name: "INIGUIHAN DISTRICT (POB.)",
            },
            {
              name: "KALANGGAMAN DISTRICT (POB.)",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LIBERTY (BINALIW)",
            },
            {
              name: "MABINI",
            },
            {
              name: "MARCELO",
            },
            {
              name: "NAGA",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "PONONG",
            },
            {
              name: "RIVILLA",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TABUNOK",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "TINAGO DISTRICT (POB.)",
            },
            {
              name: "TUGAS",
            },
          ],
        },
        "BAYBAY CITY": {
          name: "BAYBAY CITY",
          baranggays: [
            {
              name: "ALTAVISTA",
            },
            {
              name: "AMBACAN",
            },
            {
              name: "AMGUHAN",
            },
            {
              name: "AMPIHANON",
            },
            {
              name: "BALAO",
            },
            {
              name: "BANAHAO",
            },
            {
              name: "BAYBAY CITY",
            },
            {
              name: "BIASONG",
            },
            {
              name: "BIDLINAN",
            },
            {
              name: "BITANHUAN",
            },
            {
              name: "BUBON",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUNGA",
            },
            {
              name: "BUTIGAN",
            },
            {
              name: "CANDADAM",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "CIABO",
            },
            {
              name: "GA-AS",
            },
            {
              name: "GABAS",
            },
            {
              name: "GAKAT",
            },
            {
              name: "GUADALUPE (UTOD)",
            },
            {
              name: "GUBANG",
            },
            {
              name: "HIBUNAWAN",
            },
            {
              name: "HIGULOAN",
            },
            {
              name: "HILAPNITAN",
            },
            {
              name: "HIPUSNGO",
            },
            {
              name: "IGANG",
            },
            {
              name: "IMELDA",
            },
            {
              name: "JAENA",
            },
            {
              name: "KABALASAN",
            },
            {
              name: "KABATUAN",
            },
            {
              name: "KABUNGAAN",
            },
            {
              name: "KAGUMAY",
            },
            {
              name: "KAMBONGGAN",
            },
            {
              name: "KAN-IPA",
            },
            {
              name: "KANSUNGKA",
            },
            {
              name: "KANTAGNOS",
            },
            {
              name: "KILIM",
            },
            {
              name: "LINTAON",
            },
            {
              name: "MAGANHAN",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MAILHI",
            },
            {
              name: "MAITUM",
            },
            {
              name: "MAKINHAS",
            },
            {
              name: "MAPGAP",
            },
            {
              name: "MARCOS",
            },
            {
              name: "MASLUG",
            },
            {
              name: "MATAM-IS",
            },
            {
              name: "MAYBOG",
            },
            {
              name: "MAYPATAG",
            },
            {
              name: "MONTE VERDE",
            },
            {
              name: "MONTERICO",
            },
            {
              name: "PALHI",
            },
            {
              name: "PANGASUNGAN",
            },
            {
              name: "PANSAGAN",
            },
            {
              name: "PATAG",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POBLACION ZONE 1",
            },
            {
              name: "POBLACION ZONE 10",
            },
            {
              name: "POBLACION ZONE 11",
            },
            {
              name: "POBLACION ZONE 12",
            },
            {
              name: "POBLACION ZONE 13",
            },
            {
              name: "POBLACION ZONE 14",
            },
            {
              name: "POBLACION ZONE 15",
            },
            {
              name: "POBLACION ZONE 16",
            },
            {
              name: "POBLACION ZONE 17",
            },
            {
              name: "POBLACION ZONE 18",
            },
            {
              name: "POBLACION ZONE 19",
            },
            {
              name: "POBLACION ZONE 2",
            },
            {
              name: "POBLACION ZONE 20",
            },
            {
              name: "POBLACION ZONE 21",
            },
            {
              name: "POBLACION ZONE 22",
            },
            {
              name: "POBLACION ZONE 23",
            },
            {
              name: "POBLACION ZONE 3",
            },
            {
              name: "POBLACION ZONE 4",
            },
            {
              name: "POBLACION ZONE 5",
            },
            {
              name: "POBLACION ZONE 6",
            },
            {
              name: "POBLACION ZONE 7",
            },
            {
              name: "POBLACION ZONE 8",
            },
            {
              name: "POBLACION ZONE 9",
            },
            {
              name: "POMPONAN",
            },
            {
              name: "PUNTA",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SAPA",
            },
            {
              name: "VILLA MAG-ASO",
            },
            {
              name: "VILLA SOLIDARIDAD",
            },
            {
              name: "ZACARITO",
            },
          ],
        },
        BURAUEN: {
          name: "BURAUEN",
          baranggays: [
            {
              name: "ABUYOGON",
            },
            {
              name: "ANONANG",
            },
            {
              name: "ARADO",
            },
            {
              name: "BALAO",
            },
            {
              name: "BALATSON",
            },
            {
              name: "BALORINAY",
            },
            {
              name: "BOBON",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BURAUEN",
            },
            {
              name: "BURI",
            },
            {
              name: "CAANISLAGAN",
            },
            {
              name: "CADAHUNAN",
            },
            {
              name: "CAGANGON",
            },
            {
              name: "CALI",
            },
            {
              name: "CALSADAHAY",
            },
            {
              name: "CANDAG-ON",
            },
            {
              name: "CANSIBOY",
            },
            {
              name: "CATAGBACAN",
            },
            {
              name: "DAMULO-AN",
            },
            {
              name: "DINA-AYAN",
            },
            {
              name: "DUMALAG (PUSOD)",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GAMAY",
            },
            {
              name: "GITABLAN",
            },
            {
              name: "HAPUNAN",
            },
            {
              name: "HIBONAWAN",
            },
            {
              name: "HUGPA EAST",
            },
            {
              name: "HUGPA WEST",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "KAGBANA",
            },
            {
              name: "KALAO",
            },
            {
              name: "KALIPAYAN",
            },
            {
              name: "KAPARASANAN",
            },
            {
              name: "LAGUIWAN",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LIMBURAN",
            },
            {
              name: "LOGSONGAN",
            },
            {
              name: "MAABAB",
            },
            {
              name: "MAGHUBAS",
            },
            {
              name: "MAHAGNAO",
            },
            {
              name: "MALABCA",
            },
            {
              name: "MALAGUINABOT",
            },
            {
              name: "MALAIHAO (SAN RAMON)",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "MOGUING",
            },
            {
              name: "PAGHUDLAN",
            },
            {
              name: "PAITAN",
            },
            {
              name: "PANGDAN",
            },
            {
              name: "PATAG",
            },
            {
              name: "PATONG",
            },
            {
              name: "PAWA",
            },
            {
              name: "POBLACION DISTRICT I",
            },
            {
              name: "POBLACION DISTRICT II",
            },
            {
              name: "POBLACION DISTRICT III",
            },
            {
              name: "POBLACION DISTRICT IV",
            },
            {
              name: "POBLACION DISTRICT IX",
            },
            {
              name: "POBLACION DISTRICT V",
            },
            {
              name: "POBLACION DISTRICT VI",
            },
            {
              name: "POBLACION DISTRICT VII",
            },
            {
              name: "POBLACION DISTRICT VIII",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAMBEL",
            },
            {
              name: "SAN ESTEBAN",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN JOSE EAST",
            },
            {
              name: "SAN JOSE WEST",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "TABUANON",
            },
            {
              name: "TAGADTARAN",
            },
            {
              name: "TAGHUYAN",
            },
            {
              name: "TAKIN",
            },
            {
              name: "TAMBIS (NABOYA)",
            },
            {
              name: "TAMBUKO",
            },
            {
              name: "TOLOYAO",
            },
            {
              name: "VILLA AURORA",
            },
            {
              name: "VILLA CORAZON",
            },
            {
              name: "VILLA PATRIA",
            },
            {
              name: "VILLA ROSAS (CABANG)",
            },
          ],
        },
        CALUBIAN: {
          name: "CALUBIAN",
          baranggays: [
            {
              name: "ABANILLA",
            },
            {
              name: "AGAS",
            },
            {
              name: "ANISLAGAN",
            },
            {
              name: "BUNACAN",
            },
            {
              name: "CABALHIN",
            },
            {
              name: "CABALQUINTO",
            },
            {
              name: "CABRADILLA",
            },
            {
              name: "CALUBIAN",
            },
            {
              name: "CANEJA",
            },
            {
              name: "CANTONGHAO",
            },
            {
              name: "CAROYOCAN",
            },
            {
              name: "CASIONGAN",
            },
            {
              name: "CRISTINA",
            },
            {
              name: "DALUMPINES",
            },
            {
              name: "DON LUIS",
            },
            {
              name: "DULAO",
            },
            {
              name: "EFE",
            },
            {
              name: "ENAGE",
            },
            {
              name: "ESPINOSA",
            },
            {
              name: "FERDINAND E. MARCOS",
            },
            {
              name: "GARGANERA",
            },
            {
              name: "GARRIDO",
            },
            {
              name: "GUADALUPE (GUADALUPE MENDOZA)",
            },
            {
              name: "GUTOSAN",
            },
            {
              name: "IGANG",
            },
            {
              name: "INALAD",
            },
            {
              name: "JUBAY",
            },
            {
              name: "JUSON",
            },
            {
              name: "KAWAYAN BOGTONG",
            },
            {
              name: "KAWAYANAN",
            },
            {
              name: "KOKOY ROMUALDEZ",
            },
            {
              name: "LABTIC",
            },
            {
              name: "LARAY",
            },
            {
              name: "M. VELOSO",
            },
            {
              name: "MAHAIT",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MATAGOK",
            },
            {
              name: "NIPA",
            },
            {
              name: "OBISPO",
            },
            {
              name: "PADOGA",
            },
            {
              name: "PAGATPAT",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PATAG",
            },
            {
              name: "PATES",
            },
            {
              name: "PAULA",
            },
            {
              name: "PETROLIO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RAILES",
            },
            {
              name: "TABLA",
            },
            {
              name: "TAGHARIGUE",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "VILLAHERMOSA",
            },
            {
              name: "VILLALON",
            },
            {
              name: "VILLANUEVA",
            },
          ],
        },
        CAPOOCAN: {
          name: "CAPOOCAN",
          baranggays: [
            {
              name: "BALUCANAD",
            },
            {
              name: "BALUD",
            },
            {
              name: "BALUGO",
            },
            {
              name: "CABUL-AN",
            },
            {
              name: "CAPOOCAN",
            },
            {
              name: "CULASIAN",
            },
            {
              name: "GAYAD",
            },
            {
              name: "GUINADIONGAN",
            },
            {
              name: "LEMON",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MANLOY",
            },
            {
              name: "NAUGUISAN",
            },
            {
              name: "PINAMOPOAN",
            },
            {
              name: "POBLACION ZONE I",
            },
            {
              name: "POBLACION ZONE II",
            },
            {
              name: "POTOT",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TALAIRAN",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TOLIBAO",
            },
            {
              name: "VISARES",
            },
          ],
        },
        CARIGARA: {
          name: "CARIGARA",
          baranggays: [
            {
              name: "BAGONG LIPUNAN",
            },
            {
              name: "BALILIT",
            },
            {
              name: "BARAYONG",
            },
            {
              name: "BARUGOHAY CENTRAL",
            },
            {
              name: "BARUGOHAY NORTE",
            },
            {
              name: "BARUGOHAY SUR",
            },
            {
              name: "BAYBAY (POB.)",
            },
            {
              name: "BINIBIHAN",
            },
            {
              name: "BISLIG",
            },
            {
              name: "CAGHALO",
            },
            {
              name: "CANAL",
            },
            {
              name: "CANDIGAHUB",
            },
            {
              name: "CANFABI",
            },
            {
              name: "CANLAMPAY",
            },
            {
              name: "CARIGARA",
            },
            {
              name: "COGON",
            },
            {
              name: "CUTAY",
            },
            {
              name: "EAST VISORIA",
            },
            {
              name: "GUINDAPUNAN EAST",
            },
            {
              name: "GUINDAPUNAN WEST",
            },
            {
              name: "HILUCTOGAN",
            },
            {
              name: "JUGABAN (POB.)",
            },
            {
              name: "LIBO",
            },
            {
              name: "LOWER HIRAAN",
            },
            {
              name: "LOWER SOGOD",
            },
            {
              name: "MACALPI",
            },
            {
              name: "MANLOY",
            },
            {
              name: "NAUGUISAN",
            },
            {
              name: "PAGLAUM",
            },
            {
              name: "PANGNA",
            },
            {
              name: "PARAG-UM",
            },
            {
              name: "PARINA",
            },
            {
              name: "PILORO",
            },
            {
              name: "PONONG (POB.)",
            },
            {
              name: "RIZAL (TAGAK EAST)",
            },
            {
              name: "SAGKAHAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MATEO (POB.)",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SAWANG (POB.)",
            },
            {
              name: "TAGAK",
            },
            {
              name: "TANGNAN",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TINAGUBAN",
            },
            {
              name: "UPPER HIRAAN",
            },
            {
              name: "UPPER SOGOD",
            },
            {
              name: "UYAWAN",
            },
            {
              name: "WEST VISORIA",
            },
          ],
        },
        DAGAMI: {
          name: "DAGAMI",
          baranggays: [
            {
              name: "ABACA",
            },
            {
              name: "ABRE",
            },
            {
              name: "BALILIT",
            },
            {
              name: "BALUGO",
            },
            {
              name: "BANAYON",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BOLIRAO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUNTAY",
            },
            {
              name: "CAANISLAGAN",
            },
            {
              name: "CABARIWAN",
            },
            {
              name: "CABULORAN",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CALIPAYAN",
            },
            {
              name: "CALSADAHAY",
            },
            {
              name: "CALUCTOGAN",
            },
            {
              name: "CALUTAN",
            },
            {
              name: "CAMONO-AN",
            },
            {
              name: "CANDAGARA",
            },
            {
              name: "CANLINGGA",
            },
            {
              name: "CANSAMADA EAST",
            },
            {
              name: "CANSAMADA WEST",
            },
            {
              name: "CAPULHAN",
            },
            {
              name: "DAGAMI",
            },
            {
              name: "DIGAHONGAN",
            },
            {
              name: "GUINARONA",
            },
            {
              name: "HIABANGAN",
            },
            {
              name: "HILABAGO",
            },
            {
              name: "HINABUYAN",
            },
            {
              name: "HINOLOGAN",
            },
            {
              name: "HITUMNOG",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LAPU-LAPU POB. (DIST. 2)",
            },
            {
              name: "LOBE-LOBE",
            },
            {
              name: "LOBE-LOBE EAST",
            },
            {
              name: "LOS MARTIRES",
            },
            {
              name: "LUSAD POB. (DIST. 6)",
            },
            {
              name: "MACAALANG",
            },
            {
              name: "MALIWALIW",
            },
            {
              name: "MARAGONDONG",
            },
            {
              name: "ORMOCAY",
            },
            {
              name: "PALACIO",
            },
            {
              name: "PANDA",
            },
            {
              name: "PARAISO",
            },
            {
              name: "PATOC",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POPONTON",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAMPAO EAST POB. (DIST. 9)",
            },
            {
              name: "SAMPAO WEST POB. (DIST. 8)",
            },
            {
              name: "SAN ANTONIO POB. (DIST. 5)",
            },
            {
              name: "SAN BENITO",
            },
            {
              name: "SAN JOSE POB. (DIST. 1)",
            },
            {
              name: "SAN ROQUE POB. (DIST. 3)",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SAWAHON",
            },
            {
              name: "SIRAB",
            },
            {
              name: "STA. MESA POB. (DIST. 7)",
            },
            {
              name: "TAGKIP",
            },
            {
              name: "TALINHUGON",
            },
            {
              name: "TIN-AO",
            },
            {
              name: "TUNGA POB. (DIST. 4)",
            },
            {
              name: "TUYA",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        DULAG: {
          name: "DULAG",
          baranggays: [
            {
              name: "ALEGRE",
            },
            {
              name: "ARADO",
            },
            {
              name: "BARBO (POB.)",
            },
            {
              name: "BATUG",
            },
            {
              name: "BOLONGTOHAN",
            },
            {
              name: "BULOD",
            },
            {
              name: "BUNTAY (POB.)",
            },
            {
              name: "CABACUNGAN",
            },
            {
              name: "CABARASAN",
            },
            {
              name: "CABATO-AN",
            },
            {
              name: "CALIPAYAN",
            },
            {
              name: "CALUBIAN",
            },
            {
              name: "CAMBULA DISTRICT (POB.)",
            },
            {
              name: "CAMITOC",
            },
            {
              name: "CAMOTE",
            },
            {
              name: "CANDAO (POB.)",
            },
            {
              name: "CATMONAN (POB.)",
            },
            {
              name: "COMBIS (POB.)",
            },
            {
              name: "DACAY",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DULAG",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GENERAL ROXAS",
            },
            {
              name: "HIGHWAY (POB.)",
            },
            {
              name: "LUAN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARICUM",
            },
            {
              name: "MARKET SITE (POB.)",
            },
            {
              name: "RAWIS",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROMUALDEZ",
            },
            {
              name: "SABANG DAGUITAN",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SERRANO (POB.)",
            },
            {
              name: "SUNGI (POB.)",
            },
            {
              name: "TABU",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "VICTORY",
            },
          ],
        },
        HILONGOS: {
          name: "HILONGOS",
          baranggays: [
            {
              name: "AGUTAYAN",
            },
            {
              name: "ATABAY",
            },
            {
              name: "BAAS",
            },
            {
              name: "BAGONG LIPUNAN",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALIW",
            },
            {
              name: "BANTIGUE",
            },
            {
              name: "BON-OT",
            },
            {
              name: "BUNG-AW",
            },
            {
              name: "CACAO",
            },
            {
              name: "CAMPINA",
            },
            {
              name: "CATANDOG 1",
            },
            {
              name: "CATANDOG 2",
            },
            {
              name: "CENTRAL BARANGAY (POB.)",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "EASTERN BARANGAY (POB.)",
            },
            {
              name: "HAMPANGAN",
            },
            {
              name: "HILONGOS",
            },
            {
              name: "HIMO-AW",
            },
            {
              name: "HITUDPAN",
            },
            {
              name: "IMELDA MARCOS (PONG-ON)",
            },
            {
              name: "KANG-IRAS",
            },
            {
              name: "KANGHA-AS",
            },
            {
              name: "LAMAK",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "LUNANG",
            },
            {
              name: "MAGNANGOY",
            },
            {
              name: "MANAUL",
            },
            {
              name: "MARANGOG",
            },
            {
              name: "MATAPAY",
            },
            {
              name: "NAVAL",
            },
            {
              name: "OWAK",
            },
            {
              name: "PA-A",
            },
            {
              name: "PONTOD",
            },
            {
              name: "PROTECCION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARGARITA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TABUNOK",
            },
            {
              name: "TAGNATE",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAMBIS",
            },
            {
              name: "TEJERO",
            },
            {
              name: "TUGUIPA",
            },
            {
              name: "UTANAN",
            },
            {
              name: "WESTERN BARANGAY (POB.)",
            },
          ],
        },
        HINDANG: {
          name: "HINDANG",
          baranggays: [
            {
              name: "ANAHAW",
            },
            {
              name: "ANOLON",
            },
            {
              name: "BALDOZA",
            },
            {
              name: "BONTOC",
            },
            {
              name: "BULACAN",
            },
            {
              name: "CANHA-AYON",
            },
            {
              name: "CAPUDLOSAN",
            },
            {
              name: "DOOS DEL NORTE",
            },
            {
              name: "DOOS DEL SUR",
            },
            {
              name: "HIMACUGO",
            },
            {
              name: "HIMOKILAN ISLAND",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MABAGON",
            },
            {
              name: "MAHILUM",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TABOK",
            },
            {
              name: "TAGBIBI",
            },
          ],
        },
        INOPACAN: {
          name: "INOPACAN",
          baranggays: [
            {
              name: "APID",
            },
            {
              name: "CABULISAN",
            },
            {
              name: "CAMINTO",
            },
            {
              name: "CAN-ANGAY",
            },
            {
              name: "CAULISIHAN",
            },
            {
              name: "CONALUM",
            },
            {
              name: "DE LOS SANTOS (MAHILUM)",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "GUINSANGA-AN",
            },
            {
              name: "HINABAY",
            },
            {
              name: "INOPACAN",
            },
            {
              name: "JUBASAN",
            },
            {
              name: "LINAO",
            },
            {
              name: "MACAGOCO",
            },
            {
              name: "MALJO",
            },
            {
              name: "MARAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAHUD",
            },
            {
              name: "TAOTAON",
            },
            {
              name: "TINAGO",
            },
          ],
        },
        ISABEL: {
          name: "ISABEL",
          baranggays: [
            {
              name: "ANISLAG",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "APALE",
            },
            {
              name: "BANTIGUE",
            },
            {
              name: "BENOG",
            },
            {
              name: "BILWANG",
            },
            {
              name: "CAN-ANDAN",
            },
            {
              name: "CANGAG",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "HONAN",
            },
            {
              name: "ISABEL",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MARVEL (POB.)",
            },
            {
              name: "MATLANG",
            },
            {
              name: "MONTE ALEGRE",
            },
            {
              name: "PUTING BATO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TABUNOK",
            },
            {
              name: "TOLINGON",
            },
            {
              name: "TUBOD",
            },
          ],
        },
        JARO: {
          name: "JARO",
          baranggays: [
            {
              name: "ALAHAG",
            },
            {
              name: "ANIBONGAN",
            },
            {
              name: "ATIPOLO",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BATUG",
            },
            {
              name: "BIAS ZABALA",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUKID",
            },
            {
              name: "BURABOD",
            },
            {
              name: "BURI",
            },
            {
              name: "CANAPUAN",
            },
            {
              name: "CANHANDUGAN",
            },
            {
              name: "CROSSING RUBAS",
            },
            {
              name: "DARO",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "DISTRICT III (POB.)",
            },
            {
              name: "DISTRICT IV (POB.)",
            },
            {
              name: "HIAGSAM",
            },
            {
              name: "HIBUCAWAN",
            },
            {
              name: "HIBUNAWON",
            },
            {
              name: "JARO",
            },
            {
              name: "KAGLAWAAN",
            },
            {
              name: "KALINAWAN",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LIKOD",
            },
            {
              name: "MACANIP",
            },
            {
              name: "MACOPA",
            },
            {
              name: "MAG-ASO",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "OLOTAN",
            },
            {
              name: "PALANOG",
            },
            {
              name: "PANGE",
            },
            {
              name: "PARASAN",
            },
            {
              name: "PITOGO",
            },
            {
              name: "SAGKAHAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SARI-SARI",
            },
            {
              name: "TINAMBACAN",
            },
            {
              name: "TUBA",
            },
            {
              name: "UGUIAO",
            },
            {
              name: "VILLA PAZ",
            },
            {
              name: "VILLAGONZOILO (VILLA CONSUELO)",
            },
          ],
        },
        "JAVIER (BUGHO)": {
          name: "JAVIER (BUGHO)",
          baranggays: [
            {
              name: "ABUYOGAY",
            },
            {
              name: "ANDRES BONIFACIO",
            },
            {
              name: "BATUG",
            },
            {
              name: "BINULHO",
            },
            {
              name: "CALZADA",
            },
            {
              name: "CANCAYANG",
            },
            {
              name: "CARANHUG",
            },
            {
              name: "CARAYE",
            },
            {
              name: "CASALUNGAN",
            },
            {
              name: "COMATIN",
            },
            {
              name: "GUINDAPUNAN",
            },
            {
              name: "INAYUPAN",
            },
            {
              name: "JAVIER (BUGHO)",
            },
            {
              name: "LARAY",
            },
            {
              name: "MAGSAYSAY (RESPONDE)",
            },
            {
              name: "MALITBOGAY",
            },
            {
              name: "MANARUG",
            },
            {
              name: "MANLILISID",
            },
            {
              name: "NALIWATAN",
            },
            {
              name: "ODIONG",
            },
            {
              name: "PICAS NORTE",
            },
            {
              name: "PINOCAWAN",
            },
            {
              name: "POBLACION ZONE 1",
            },
            {
              name: "POBLACION ZONE 2",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN SOTERO (TAMBIS)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TALISAYAN",
            },
            {
              name: "ULHAY",
            },
          ],
        },
        JULITA: {
          name: "JULITA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "ANIBONG",
            },
            {
              name: "ASLUM",
            },
            {
              name: "BALANTE",
            },
            {
              name: "BONGDO",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BUGHO",
            },
            {
              name: "CALBASAG",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "CUYA-E",
            },
            {
              name: "DITA",
            },
            {
              name: "GITABLA",
            },
            {
              name: "HINDANG",
            },
            {
              name: "INAWANGAN",
            },
            {
              name: "JULITA",
            },
            {
              name: "JURAO",
            },
            {
              name: "POBLACION DISTRICT I",
            },
            {
              name: "POBLACION DISTRICT II",
            },
            {
              name: "POBLACION DISTRICT III",
            },
            {
              name: "POBLACION DISTRICT IV",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGKIP",
            },
            {
              name: "TOLOSAHAY",
            },
            {
              name: "VILLA HERMOSA",
            },
          ],
        },
        KANANGA: {
          name: "KANANGA",
          baranggays: [
            {
              name: "AGUITING",
            },
            {
              name: "CACAO",
            },
            {
              name: "HILUCTOGAN",
            },
            {
              name: "KANANGA",
            },
            {
              name: "KAWAYAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIBONGAO",
            },
            {
              name: "LIM-AO",
            },
            {
              name: "LONOY",
            },
            {
              name: "MAHAWAN",
            },
            {
              name: "MASARAYAO",
            },
            {
              name: "MONTE ALEGRE",
            },
            {
              name: "MONTE BELLO",
            },
            {
              name: "NAGHALIN",
            },
            {
              name: "NATUBGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN IGNACIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "TONGONAN",
            },
            {
              name: "TUGBONG",
            },
          ],
        },
        "LA PAZ": {
          name: "LA PAZ",
          baranggays: [
            {
              name: "BAGACAY EAST",
            },
            {
              name: "BAGACAY WEST",
            },
            {
              name: "BOCAWON",
            },
            {
              name: "BONGTOD",
            },
            {
              name: "BURACAN",
            },
            {
              name: "CAABANGAN",
            },
            {
              name: "CACAO",
            },
            {
              name: "CAGNGARAN",
            },
            {
              name: "CALABNIAN",
            },
            {
              name: "CALAGHUSAN",
            },
            {
              name: "CALTAYAN",
            },
            {
              name: "CANBAÑEZ",
            },
            {
              name: "COGON",
            },
            {
              name: "DUYOG",
            },
            {
              name: "GIMENARAT EAST",
            },
            {
              name: "GIMENARAT WEST",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LIMBA",
            },
            {
              name: "LUBI-LUBI",
            },
            {
              name: "LUNETA",
            },
            {
              name: "MAG-ASO",
            },
            {
              name: "MOROBORO",
            },
            {
              name: "PANSUD",
            },
            {
              name: "PAWA",
            },
            {
              name: "PILIWAY",
            },
            {
              name: "POBLACION DISTRICT 1",
            },
            {
              name: "POBLACION DISTRICT 2",
            },
            {
              name: "POBLACION DISTRICT 3",
            },
            {
              name: "POBLACION DISTRICT 4",
            },
            {
              name: "QUIONG",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN VICTORAY",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "TABANG",
            },
            {
              name: "TARUGAN",
            },
          ],
        },
        LEYTE: {
          name: "LEYTE",
          baranggays: [
            {
              name: "BACHAO",
            },
            {
              name: "BACO",
            },
            {
              name: "BAGABA-O",
            },
            {
              name: "BASUD",
            },
            {
              name: "BELEN",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CALAGUISE",
            },
            {
              name: "CONSUEGRA",
            },
            {
              name: "CULASI",
            },
            {
              name: "DANUS",
            },
            {
              name: "ELIZABETH",
            },
            {
              name: "KAWAYAN",
            },
            {
              name: "LEYTE",
            },
            {
              name: "LIBAS",
            },
            {
              name: "MAANDA",
            },
            {
              name: "MACUPA",
            },
            {
              name: "MATALOTO",
            },
            {
              name: "PALARAO",
            },
            {
              name: "PALID I (ILAWOD)",
            },
            {
              name: "PALID II (IRAYA)",
            },
            {
              name: "PARASAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALOG",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "TAG-ABACA",
            },
            {
              name: "TAPOL",
            },
            {
              name: "TIGBAWAN",
            },
            {
              name: "TINOCDUGAN",
            },
            {
              name: "TOCTOC",
            },
            {
              name: "UGBON",
            },
            {
              name: "WAGUE",
            },
          ],
        },
        MACARTHUR: {
          name: "MACARTHUR",
          baranggays: [
            {
              name: "BATUG",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CAPUDLOSAN",
            },
            {
              name: "CASUNTINGAN",
            },
            {
              name: "CAUSWAGAN",
            },
            {
              name: "DANAO",
            },
            {
              name: "DOÑA JOSEFA",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "KILING",
            },
            {
              name: "LANAWAN",
            },
            {
              name: "LIWAYWAY",
            },
            {
              name: "MACARTHUR",
            },
            {
              name: "MAYA",
            },
            {
              name: "OGUISAN",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PALALE 1",
            },
            {
              name: "PALALE 2",
            },
            {
              name: "POBLACION DISTRICT 1",
            },
            {
              name: "POBLACION DISTRICT 2",
            },
            {
              name: "POBLACION DISTRICT 3",
            },
            {
              name: "PONGON",
            },
            {
              name: "QUEZON",
            },
            {
              name: "ROMUALDEZ",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "TINAWAN",
            },
            {
              name: "TUYO",
            },
            {
              name: "VILLA IMELDA",
            },
          ],
        },
        MAHAPLAG: {
          name: "MAHAPLAG",
          baranggays: [
            {
              name: "CAMPIN",
            },
            {
              name: "CUATRO DE AGOSTO",
            },
            {
              name: "HILUCTOGAN",
            },
            {
              name: "HILUSIG",
            },
            {
              name: "HIMAMARA",
            },
            {
              name: "HINAGUIMITAN",
            },
            {
              name: "LIBERACION",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MABUNGA",
            },
            {
              name: "MAGSUGANAO",
            },
            {
              name: "MAHAPLAG",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MALIPOON",
            },
            {
              name: "PALAÑOGAN",
            },
            {
              name: "PARIL",
            },
            {
              name: "PINAMONOAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLAHONGON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "UGUIS",
            },
            {
              name: "UNION",
            },
            {
              name: "UPPER MAHAPLAG",
            },
          ],
        },
        "MATAG-OB": {
          name: "MATAG-OB",
          baranggays: [
            {
              name: "BALAGTAS",
            },
            {
              name: "BONOY (POB.)",
            },
            {
              name: "BULAK",
            },
            {
              name: "CAMBADBAD",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "CANSOSO",
            },
            {
              name: "IMELDA",
            },
            {
              name: "MALAZARTE",
            },
            {
              name: "MANSAHAON",
            },
            {
              name: "MANSALIP (POB.)",
            },
            {
              name: "MASABA",
            },
            {
              name: "MATAG-OB",
            },
            {
              name: "NAULAYAN",
            },
            {
              name: "RIVERSIDE (POB.)",
            },
            {
              name: "SAN DIONISIO",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SAN MARCELINO",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TALISAY (POB.)",
            },
          ],
        },
        MATALOM: {
          name: "MATALOM",
          baranggays: [
            {
              name: "AGBANGA",
            },
            {
              name: "ALTAVISTA",
            },
            {
              name: "BAGONG LIPUNAN",
            },
            {
              name: "CAHAGNAAN",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CANINGAG",
            },
            {
              name: "CARIDAD NORTE",
            },
            {
              name: "CARIDAD SUR",
            },
            {
              name: "ELEVADO",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "HITOOG",
            },
            {
              name: "ITUM",
            },
            {
              name: "LOWAN",
            },
            {
              name: "MATALOM",
            },
            {
              name: "MONTE ALEGRE",
            },
            {
              name: "PRESIDENT GARCIA",
            },
            {
              name: "PUNONG",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTA PAZ",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "TAG-OS",
            },
            {
              name: "TAGLIBAS IMELDA",
            },
            {
              name: "TEMPLANZA",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "WATERLOO",
            },
            {
              name: "ZARAGOZA",
            },
          ],
        },
        MAYORGA: {
          name: "MAYORGA",
          baranggays: [
            {
              name: "A. BONIFACIO",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CALIPAYAN",
            },
            {
              name: "CAMANSI",
            },
            {
              name: "GENERAL ANTONIO LUNA",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAYORGA",
            },
            {
              name: "ORMOCAY",
            },
            {
              name: "POBLACION ZONE 1",
            },
            {
              name: "POBLACION ZONE 2",
            },
            {
              name: "POBLACION ZONE 3",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TALISAY",
            },
            {
              name: "UNION",
            },
            {
              name: "WILSON",
            },
          ],
        },
        MERIDA: {
          name: "MERIDA",
          baranggays: [
            {
              name: "BINABAYE",
            },
            {
              name: "CABALIWAN",
            },
            {
              name: "CALUNANGAN",
            },
            {
              name: "CALUNASAN",
            },
            {
              name: "CAMBALONG",
            },
            {
              name: "CAN-UNZO",
            },
            {
              name: "CANBANTUG",
            },
            {
              name: "CASILDA",
            },
            {
              name: "LAMANOC",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LIBJO",
            },
            {
              name: "LUNDAG",
            },
            {
              name: "MACARIO",
            },
            {
              name: "MAHALIT",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MASUMBANG",
            },
            {
              name: "MAT-E",
            },
            {
              name: "MERIDA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUERTO BELLO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TUBOD",
            },
          ],
        },
        "ORMOC CITY": {
          name: "ORMOC CITY",
          baranggays: [
            {
              name: "AIRPORT",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "ALTA VISTA",
            },
            {
              name: "BAGONG",
            },
            {
              name: "BAGONG BUHAY",
            },
            {
              name: "BANTIGUE",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 14 (POB.)",
            },
            {
              name: "BARANGAY 15 (POB.)",
            },
            {
              name: "BARANGAY 16 (POB.)",
            },
            {
              name: "BARANGAY 17 (POB.)",
            },
            {
              name: "BARANGAY 18 (POB.)",
            },
            {
              name: "BARANGAY 19 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 20 (POB.)",
            },
            {
              name: "BARANGAY 21 (POB.)",
            },
            {
              name: "BARANGAY 22 (POB.)",
            },
            {
              name: "BARANGAY 23 (POB.)",
            },
            {
              name: "BARANGAY 24 (POB.)",
            },
            {
              name: "BARANGAY 25 (POB.)",
            },
            {
              name: "BARANGAY 26 (POB.)",
            },
            {
              name: "BARANGAY 27 (POB.)",
            },
            {
              name: "BARANGAY 28 (POB.)",
            },
            {
              name: "BARANGAY 29 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BAYOG",
            },
            {
              name: "BILIBOY",
            },
            {
              name: "BOROK",
            },
            {
              name: "CABAON-AN",
            },
            {
              name: "CABINTAN",
            },
            {
              name: "CABULIHAN",
            },
            {
              name: "CAGBUHANGIN",
            },
            {
              name: "CAMP DOWNES",
            },
            {
              name: "CAN-ADIENG",
            },
            {
              name: "CAN-UNTOG",
            },
            {
              name: "COGON COMBADO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CURVA",
            },
            {
              name: "DANAO",
            },
            {
              name: "DANHUG",
            },
            {
              name: "DAYHAGAN",
            },
            {
              name: "DOLORES",
            },
            {
              name: "DOMONAR",
            },
            {
              name: "DON FELIPE LARRAZABAL",
            },
            {
              name: "DON POTENCIANO LARRAZABAL",
            },
            {
              name: "DOÑA FELIZA Z. MEJIA",
            },
            {
              name: "DONGHOL",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GAAS",
            },
            {
              name: "GREEN VALLEY",
            },
            {
              name: "GUINTIGUI-AN",
            },
            {
              name: "HIBUNAWON",
            },
            {
              name: "HUGPA",
            },
            {
              name: "IPIL",
            },
            {
              name: "JUATON",
            },
            {
              name: "KADAOHAN",
            },
            {
              name: "LABRADOR (BALION)",
            },
            {
              name: "LAO",
            },
            {
              name: "LEONDONI",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LICUMA",
            },
            {
              name: "LILOAN",
            },
            {
              name: "LINAO",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABATO",
            },
            {
              name: "MACABUG",
            },
            {
              name: "MAGASWI",
            },
            {
              name: "MANLILINAO",
            },
            {
              name: "MARGEN",
            },
            {
              name: "MAS-IN",
            },
            {
              name: "MATICA-A",
            },
            {
              name: "MILAGRO",
            },
            {
              name: "NASUNOGAN",
            },
            {
              name: "NAUNGAN",
            },
            {
              name: "NUEVA SOCIEDAD",
            },
            {
              name: "NUEVA VISTA",
            },
            {
              name: "ORMOC CITY",
            },
            {
              name: "PUNTA",
            },
            {
              name: "QUEZON, JR.",
            },
            {
              name: "RUFINA M. TAN",
            },
            {
              name: "SABANG BAO",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PABLO (SIMANGAN)",
            },
            {
              name: "SUMANGGA",
            },
            {
              name: "TAMBULILID",
            },
            {
              name: "VALENCIA",
            },
          ],
        },
        PALO: {
          name: "PALO",
          baranggays: [
            {
              name: "ANAHAWAY",
            },
            {
              name: "ARADO",
            },
            {
              name: "BARAS",
            },
            {
              name: "BARAYONG",
            },
            {
              name: "BURI (POB.)",
            },
            {
              name: "CABARASAN DAKU",
            },
            {
              name: "CABARASAN GUTI",
            },
            {
              name: "CAMPETIK",
            },
            {
              name: "CANDAHUG",
            },
            {
              name: "CANGUMBANG",
            },
            {
              name: "CANHIDOC",
            },
            {
              name: "CAPIRAWAN",
            },
            {
              name: "CASTILLA",
            },
            {
              name: "CAVITE EAST (POB.)",
            },
            {
              name: "CAVITE WEST (POB.)",
            },
            {
              name: "COGON (COGON SAN JOAQUIN)",
            },
            {
              name: "GACAO",
            },
            {
              name: "GUINDAPUNAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LUNTAD (POB.)",
            },
            {
              name: "NAGA-NAGA",
            },
            {
              name: "PALO",
            },
            {
              name: "PAWING",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TACURANGA",
            },
            {
              name: "TERAZA",
            },
          ],
        },
        PALOMPON: {
          name: "PALOMPON",
          baranggays: [
            {
              name: "BELEN",
            },
            {
              name: "BITAOG POB. (YPIL III)",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CADUHAAN",
            },
            {
              name: "CAMBAKBAK",
            },
            {
              name: "CAMBINOY",
            },
            {
              name: "CANGCOSME",
            },
            {
              name: "CANGMUYA",
            },
            {
              name: "CANIPAAN",
            },
            {
              name: "CANTANDOY",
            },
            {
              name: "CANTUHAON",
            },
            {
              name: "CATIGAHAN",
            },
            {
              name: "CENTRAL 1 (POB.)",
            },
            {
              name: "CENTRAL 2 (POB.)",
            },
            {
              name: "CRUZ",
            },
            {
              name: "DULJUGAN",
            },
            {
              name: "GUIWAN 1 (POB.)",
            },
            {
              name: "GUIWAN 2 (POB.)",
            },
            {
              name: "HIMARCO",
            },
            {
              name: "HINABLAYAN POB. (CENTRAL 3)",
            },
            {
              name: "HINAGBUAN",
            },
            {
              name: "LAT-OSAN",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "LOMONON",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MASABA",
            },
            {
              name: "MAZAWALO POB. (LILI-ON)",
            },
            {
              name: "PALOMPON",
            },
            {
              name: "PARILLA",
            },
            {
              name: "PINAGDAIT POB. (YPIL I)",
            },
            {
              name: "PINAGHI-USA POB. (YPIL II)",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "TABERNA",
            },
            {
              name: "TABUNOK",
            },
            {
              name: "TAMBIS",
            },
            {
              name: "TINABILAN",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TINUBDAN",
            },
          ],
        },
        PASTRANA: {
          name: "PASTRANA",
          baranggays: [
            {
              name: "ARABUNOG",
            },
            {
              name: "ARINGIT",
            },
            {
              name: "BAHAY",
            },
            {
              name: "CABAOHAN",
            },
            {
              name: "CALSADAHAY",
            },
            {
              name: "CANCARAJA",
            },
            {
              name: "CANINOAN",
            },
            {
              name: "CAPILLA",
            },
            {
              name: "COLAWEN",
            },
            {
              name: "DISTRICT 1 (POB.)",
            },
            {
              name: "DISTRICT 2 (POB.)",
            },
            {
              name: "DISTRICT 3 (POB.)",
            },
            {
              name: "DISTRICT 4 (POB.)",
            },
            {
              name: "DUMARAG",
            },
            {
              name: "GUINDAPUNAN",
            },
            {
              name: "HALABA",
            },
            {
              name: "JONES",
            },
            {
              name: "LANAWAN",
            },
            {
              name: "LIMA",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MACALPIAY",
            },
            {
              name: "MALITBOGAY",
            },
            {
              name: "MANAYBANAY",
            },
            {
              name: "MARICUM",
            },
            {
              name: "PASTRANA",
            },
            {
              name: "PATONG",
            },
            {
              name: "SAPSAP",
            },
            {
              name: "SOCSOCON",
            },
            {
              name: "TINGIB",
            },
            {
              name: "YAPAD",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "BANAT-E",
            },
            {
              name: "BASUD",
            },
            {
              name: "BAWOD (POB.)",
            },
            {
              name: "BIASONG",
            },
            {
              name: "BUNACAN",
            },
            {
              name: "BUSAY",
            },
            {
              name: "CABUNGAAN",
            },
            {
              name: "CAPIÑAHAN (POB.)",
            },
            {
              name: "CROSSING (POB.)",
            },
            {
              name: "DAJA-DAKU",
            },
            {
              name: "DAJA-DIOT",
            },
            {
              name: "HACIENDA MARIA",
            },
            {
              name: "LINAO",
            },
            {
              name: "MATUNGAO",
            },
            {
              name: "PARIL",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TAGLAWIGAN",
            },
            {
              name: "TINAGO",
            },
          ],
        },
        "SAN MIGUEL": {
          name: "SAN MIGUEL",
          baranggays: [
            {
              name: "BAGACAY",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BAIRAN",
            },
            {
              name: "CABATIANUHAN",
            },
            {
              name: "CANAP",
            },
            {
              name: "CAPILIHAN",
            },
            {
              name: "CARAYCARAY",
            },
            {
              name: "CAYARE (WEST POBLACION)",
            },
            {
              name: "GUINCIAMAN",
            },
            {
              name: "IMPO",
            },
            {
              name: "KINALUMSAN",
            },
            {
              name: "LIBTONG (EAST POBLACION)",
            },
            {
              name: "LUKAY",
            },
            {
              name: "MALAGUINABOT",
            },
            {
              name: "MALPAG",
            },
            {
              name: "MAWODPAWOD",
            },
            {
              name: "PATONG",
            },
            {
              name: "PINARIGUSAN",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTOL",
            },
          ],
        },
        "SANTA FE": {
          name: "SANTA FE",
          baranggays: [
            {
              name: "BACULANAD",
            },
            {
              name: "BADIANGAY",
            },
            {
              name: "BULOD",
            },
            {
              name: "CATOOGAN",
            },
            {
              name: "CUTAY",
            },
            {
              name: "GAPAS",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MILAGROSA",
            },
            {
              name: "PILIT",
            },
            {
              name: "PITOGO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUELAY",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "TIBAK",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "ZONE 1 (POB.)",
            },
            {
              name: "ZONE 2 (POB.)",
            },
            {
              name: "ZONE 3 (POB.)",
            },
          ],
        },
        TABANGO: {
          name: "TABANGO",
          baranggays: [
            {
              name: "BUTASON I",
            },
            {
              name: "BUTASON II",
            },
            {
              name: "CAMPOKPOK",
            },
            {
              name: "CATMON",
            },
            {
              name: "GIBACUNGAN",
            },
            {
              name: "GIMARCO",
            },
            {
              name: "INANGATAN",
            },
            {
              name: "MANLAWAAN",
            },
            {
              name: "OMAGANHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "TABING",
            },
            {
              name: "TUGAS",
            },
          ],
        },
        TABONTABON: {
          name: "TABONTABON",
          baranggays: [
            {
              name: "AMANDANGAY",
            },
            {
              name: "ASLUM",
            },
            {
              name: "BALINGASAG",
            },
            {
              name: "BELISONG",
            },
            {
              name: "CAMBUCAO",
            },
            {
              name: "CAPAHUAN",
            },
            {
              name: "DISTRICT I POB. (QUEZON)",
            },
            {
              name: "DISTRICT II POB. (RIZAL)",
            },
            {
              name: "DISTRICT III POB. (BONIFACIO)",
            },
            {
              name: "DISTRICT IV POB. (MACARTHUR)",
            },
            {
              name: "GUINGAWAN",
            },
            {
              name: "JABONG",
            },
            {
              name: "MERCADOHAY",
            },
            {
              name: "MERING",
            },
            {
              name: "MOHON",
            },
            {
              name: "SAN PABLO (MOOC)",
            },
            {
              name: "TABONTABON",
            },
          ],
        },
        "TACLOBAN CITY": {
          name: "TACLOBAN CITY",
          baranggays: [
            {
              name: "BARANGAY 100 (SAN ROQUE)",
            },
            {
              name: "BARANGAY 101 (NEW KAWAYAN)",
            },
            {
              name: "BARANGAY 102 (OLD KAWAYAN)",
            },
            {
              name: "BARANGAY 103 (PALANOG)",
            },
            {
              name: "BARANGAY 103-A (SAN PAGLAUM)",
            },
            {
              name: "BARANGAY 104 (SALVACION)",
            },
            {
              name: "BARANGAY 105 (SUHI)",
            },
            {
              name: "BARANGAY 106 (SANTO NIÑO)",
            },
            {
              name: "BARANGAY 107 (SANTA ELENA)",
            },
            {
              name: "BARANGAY 108 (TAGAPURO)",
            },
            {
              name: "BARANGAY 109 (V AND G SUBD.)",
            },
            {
              name: "BARANGAY 109-A",
            },
            {
              name: "BARANGAY 110 (UTAP)",
            },
            {
              name: "BARANGAY 12 (PALANOG RESETTLEMENT)",
            },
            {
              name: "BARANGAY 13",
            },
            {
              name: "BARANGAY 14",
            },
            {
              name: "BARANGAY 15",
            },
            {
              name: "BARANGAY 16",
            },
            {
              name: "BARANGAY 17",
            },
            {
              name: "BARANGAY 18",
            },
            {
              name: "BARANGAY 19",
            },
            {
              name: "BARANGAY 2",
            },
            {
              name: "BARANGAY 20",
            },
            {
              name: "BARANGAY 21",
            },
            {
              name: "BARANGAY 21-A",
            },
            {
              name: "BARANGAY 22",
            },
            {
              name: "BARANGAY 23",
            },
            {
              name: "BARANGAY 23-A",
            },
            {
              name: "BARANGAY 24",
            },
            {
              name: "BARANGAY 25",
            },
            {
              name: "BARANGAY 26",
            },
            {
              name: "BARANGAY 27",
            },
            {
              name: "BARANGAY 28",
            },
            {
              name: "BARANGAY 29",
            },
            {
              name: "BARANGAY 30",
            },
            {
              name: "BARANGAY 31",
            },
            {
              name: "BARANGAY 32",
            },
            {
              name: "BARANGAY 33",
            },
            {
              name: "BARANGAY 34",
            },
            {
              name: "BARANGAY 35",
            },
            {
              name: "BARANGAY 35-A",
            },
            {
              name: "BARANGAY 36",
            },
            {
              name: "BARANGAY 36-A",
            },
            {
              name: "BARANGAY 37",
            },
            {
              name: "BARANGAY 37-A",
            },
            {
              name: "BARANGAY 38",
            },
            {
              name: "BARANGAY 39",
            },
            {
              name: "BARANGAY 40",
            },
            {
              name: "BARANGAY 41",
            },
            {
              name: "BARANGAY 42",
            },
            {
              name: "BARANGAY 42-A",
            },
            {
              name: "BARANGAY 43",
            },
            {
              name: "BARANGAY 43-A",
            },
            {
              name: "BARANGAY 43-B",
            },
            {
              name: "BARANGAY 44",
            },
            {
              name: "BARANGAY 44-A",
            },
            {
              name: "BARANGAY 45",
            },
            {
              name: "BARANGAY 46",
            },
            {
              name: "BARANGAY 47",
            },
            {
              name: "BARANGAY 48",
            },
            {
              name: "BARANGAY 48-A",
            },
            {
              name: "BARANGAY 48-B",
            },
            {
              name: "BARANGAY 49",
            },
            {
              name: "BARANGAY 5",
            },
            {
              name: "BARANGAY 5-A",
            },
            {
              name: "BARANGAY 50",
            },
            {
              name: "BARANGAY 50-A",
            },
            {
              name: "BARANGAY 50-B",
            },
            {
              name: "BARANGAY 51",
            },
            {
              name: "BARANGAY 51-A",
            },
            {
              name: "BARANGAY 52",
            },
            {
              name: "BARANGAY 53",
            },
            {
              name: "BARANGAY 54",
            },
            {
              name: "BARANGAY 54-A",
            },
            {
              name: "BARANGAY 56",
            },
            {
              name: "BARANGAY 56-A",
            },
            {
              name: "BARANGAY 57",
            },
            {
              name: "BARANGAY 58",
            },
            {
              name: "BARANGAY 59",
            },
            {
              name: "BARANGAY 59-A",
            },
            {
              name: "BARANGAY 59-B",
            },
            {
              name: "BARANGAY 6",
            },
            {
              name: "BARANGAY 6-A",
            },
            {
              name: "BARANGAY 60",
            },
            {
              name: "BARANGAY 60-A",
            },
            {
              name: "BARANGAY 61",
            },
            {
              name: "BARANGAY 62",
            },
            {
              name: "BARANGAY 62-A",
            },
            {
              name: "BARANGAY 62-B",
            },
            {
              name: "BARANGAY 63",
            },
            {
              name: "BARANGAY 64",
            },
            {
              name: "BARANGAY 65",
            },
            {
              name: "BARANGAY 66",
            },
            {
              name: "BARANGAY 66-A",
            },
            {
              name: "BARANGAY 67",
            },
            {
              name: "BARANGAY 68",
            },
            {
              name: "BARANGAY 69",
            },
            {
              name: "BARANGAY 7",
            },
            {
              name: "BARANGAY 70",
            },
            {
              name: "BARANGAY 71",
            },
            {
              name: "BARANGAY 72",
            },
            {
              name: "BARANGAY 73",
            },
            {
              name: "BARANGAY 74",
            },
            {
              name: "BARANGAY 75",
            },
            {
              name: "BARANGAY 76",
            },
            {
              name: "BARANGAY 77",
            },
            {
              name: "BARANGAY 78 (MARASBARAS)",
            },
            {
              name: "BARANGAY 79 (MARASBARAS)",
            },
            {
              name: "BARANGAY 8",
            },
            {
              name: "BARANGAY 8-A",
            },
            {
              name: "BARANGAY 80 (MARASBARAS)",
            },
            {
              name: "BARANGAY 81 (MARASBARAS)",
            },
            {
              name: "BARANGAY 82 (MARASBARAS)",
            },
            {
              name: "BARANGAY 83 (SAN JOSE)",
            },
            {
              name: "BARANGAY 83-A (SAN JOSE)",
            },
            {
              name: "BARANGAY 83-B",
            },
            {
              name: "BARANGAY 83-C (SAN JOSE)",
            },
            {
              name: "BARANGAY 84 (SAN JOSE)",
            },
            {
              name: "BARANGAY 85 (SAN JOSE)",
            },
            {
              name: "BARANGAY 86",
            },
            {
              name: "BARANGAY 87",
            },
            {
              name: "BARANGAY 88",
            },
            {
              name: "BARANGAY 89",
            },
            {
              name: "BARANGAY 90 (SAN JOSE)",
            },
            {
              name: "BARANGAY 91 (ABUCAY)",
            },
            {
              name: "BARANGAY 92 (APITONG)",
            },
            {
              name: "BARANGAY 93 (BAGACAY)",
            },
            {
              name: "BARANGAY 94 (TIGBAO)",
            },
            {
              name: "BARANGAY 94-A",
            },
            {
              name: "BARANGAY 95 (CAIBAAN)",
            },
            {
              name: "BARANGAY 95-A (CAIBAAN)",
            },
            {
              name: "BARANGAY 96 (CALANIPAWAN)",
            },
            {
              name: "BARANGAY 97 (CABALAWAN)",
            },
            {
              name: "BARANGAY 98 (CAMANSINAY)",
            },
            {
              name: "BARANGAY 99 (DIIT)",
            },
            {
              name: "EL REPOSO (BARANGAYS 55 AND 55A)",
            },
            {
              name: "LIBERTAD (BARANGAYS 1 AND 4)",
            },
            {
              name: "NULA-TULA (BGYS. 3 AND 3A)",
            },
            {
              name: "TACLOBAN CITY",
            },
          ],
        },
        TANAUAN: {
          name: "TANAUAN",
          baranggays: [
            {
              name: "ADA",
            },
            {
              name: "AMANLURAN",
            },
            {
              name: "ARADO",
            },
            {
              name: "ATIPOLO",
            },
            {
              name: "BALUD",
            },
            {
              name: "BANGON",
            },
            {
              name: "BANTAGAN",
            },
            {
              name: "BARAS",
            },
            {
              name: "BINOLO",
            },
            {
              name: "BINONGTO-AN",
            },
            {
              name: "BISLIG",
            },
            {
              name: "BUNTAY (POB.)",
            },
            {
              name: "CABALAGNAN",
            },
            {
              name: "CABARASAN GUTI",
            },
            {
              name: "CABONGA-AN",
            },
            {
              name: "CABUYNAN",
            },
            {
              name: "CAHUMAYHUMAYAN",
            },
            {
              name: "CALOGCOG",
            },
            {
              name: "CALSADAHAY",
            },
            {
              name: "CAMIRE",
            },
            {
              name: "CANBALISARA",
            },
            {
              name: "CANRAMOS (POB.)",
            },
            {
              name: "CATIGBIAN",
            },
            {
              name: "CATMON",
            },
            {
              name: "COGON",
            },
            {
              name: "GUINDAG-AN",
            },
            {
              name: "GUINGAWAN",
            },
            {
              name: "HILAGPAD",
            },
            {
              name: "KILING",
            },
            {
              name: "LAPAY",
            },
            {
              name: "LICOD (POB.)",
            },
            {
              name: "LIMBUHAN DAKU",
            },
            {
              name: "LIMBUHAN GUTI",
            },
            {
              name: "LINAO",
            },
            {
              name: "MAGAY",
            },
            {
              name: "MAGHULOD",
            },
            {
              name: "MALAGUICAY",
            },
            {
              name: "MARIBI",
            },
            {
              name: "MOHON",
            },
            {
              name: "PAGO",
            },
            {
              name: "PASIL",
            },
            {
              name: "PIKAS",
            },
            {
              name: "SACME",
            },
            {
              name: "SALVADOR",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN VICTOR",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTO NIÑO POB. (HACLAGAN)",
            },
            {
              name: "SOLANO",
            },
            {
              name: "TALOLORA",
            },
            {
              name: "TANAUAN",
            },
            {
              name: "TUGOP",
            },
          ],
        },
        TOLOSA: {
          name: "TOLOSA",
          baranggays: [
            {
              name: "BURAK",
            },
            {
              name: "CANMOGSAY",
            },
            {
              name: "CANTARIWIS",
            },
            {
              name: "CAPANGIHAN",
            },
            {
              name: "DOÑA BRIGIDA",
            },
            {
              name: "IMELDA",
            },
            {
              name: "MALBOG",
            },
            {
              name: "OLOT",
            },
            {
              name: "OPONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUILAO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TANGHAS",
            },
            {
              name: "TELEGRAFO",
            },
            {
              name: "TOLOSA",
            },
          ],
        },
        TUNGA: {
          name: "TUNGA",
          baranggays: [
            {
              name: "ASTORGA (UPART)",
            },
            {
              name: "BALIRE",
            },
            {
              name: "BANAWANG",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "TUNGA",
            },
          ],
        },
        VILLABA: {
          name: "VILLABA",
          baranggays: [
            {
              name: "ABIJAO",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANGCAL",
            },
            {
              name: "BUGABUGA",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CABUNGAHAN",
            },
            {
              name: "CAGNOCOT",
            },
            {
              name: "CAHIGAN",
            },
            {
              name: "CALBUGOS",
            },
            {
              name: "CAMPOROG",
            },
            {
              name: "CANQUIASON",
            },
            {
              name: "CAPINYAHAN",
            },
            {
              name: "CASILI-ON",
            },
            {
              name: "CATAGBACAN",
            },
            {
              name: "FATIMA (POB.)",
            },
            {
              name: "HIBULANGAN",
            },
            {
              name: "HINABUYAN",
            },
            {
              name: "ILIGAY",
            },
            {
              name: "JALAS",
            },
            {
              name: "JORDAN",
            },
            {
              name: "LIBAGONG",
            },
            {
              name: "NEW BALANAC",
            },
            {
              name: "PAYAO",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SILAD",
            },
            {
              name: "SUBA",
            },
            {
              name: "SULPA",
            },
            {
              name: "TABUNOK",
            },
            {
              name: "TAGBUBUNGA",
            },
            {
              name: "TINGHUB",
            },
            {
              name: "VILLABA",
            },
          ],
        },
      },
    },
    MAGUINDANAO: {
      name: "MAGUINDANAO",
      region: "ARMM",
      municipalities: {
        BULUAN: {
          name: "BULUAN",
          baranggays: [
            {
              name: "DIGAL",
            },
            {
              name: "LOWER SILING",
            },
            {
              name: "MASLABENG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POPOL",
            },
            {
              name: "TALITAY",
            },
            {
              name: "UPPER SILING",
            },
          ],
        },
        COTABATO: {
          name: "COTABATO",
          baranggays: [
            {
              name: "COTABATO",
            },
          ],
        },
        "COTABATO CITY": {
          name: "COTABATO CITY",
          baranggays: [
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "POBLACION IX",
            },
            {
              name: "POBLACION V",
            },
            {
              name: "POBLACION VI",
            },
            {
              name: "POBLACION VII",
            },
            {
              name: "ROSARY HEIGHTS",
            },
            {
              name: "ROSARY HEIGHTS I",
            },
            {
              name: "ROSARY HEIGHTS II",
            },
            {
              name: "ROSARY HEIGHTS III",
            },
            {
              name: "ROSARY HEIGHTS IV",
            },
            {
              name: "ROSARY HEIGHTS IX",
            },
            {
              name: "ROSARY HEIGHTS V",
            },
            {
              name: "ROSARY HEIGHTS VI",
            },
            {
              name: "ROSARY HEIGHTS VII",
            },
            {
              name: "ROSARY HEIGHTS VIII",
            },
            {
              name: "ROSARY HEIGHTS X",
            },
            {
              name: "ROSARY HEIGHTS XI",
            },
            {
              name: "ROSARY HEIGHTS XII",
            },
            {
              name: "ROSARY HEIGHTS XIII",
            },
            {
              name: "TAMONTAKA",
            },
            {
              name: "TAMONTAKA I",
            },
          ],
        },
        "DATU ODIN SINSUAT (DINAIG)": {
          name: "DATU ODIN SINSUAT (DINAIG)",
          baranggays: [
            {
              name: "AMBOLODTO",
            },
            {
              name: "AWANG",
            },
            {
              name: "BADAK",
            },
            {
              name: "BAGOENGED",
            },
            {
              name: "BAKA",
            },
            {
              name: "BENOLEN",
            },
            {
              name: "BITU",
            },
            {
              name: "BONGUED",
            },
            {
              name: "BUGAWAS",
            },
            {
              name: "CAPITON",
            },
            {
              name: "DADOS",
            },
            {
              name: "DALICAN POBLACION",
            },
            {
              name: "DINAIG PROPER",
            },
            {
              name: "DULANGAN",
            },
            {
              name: "KAKAR",
            },
            {
              name: "KENEBEKA",
            },
            {
              name: "KURINTEM",
            },
            {
              name: "KUSIONG",
            },
            {
              name: "LABUNGAN",
            },
            {
              name: "LINEK",
            },
            {
              name: "MAKIR",
            },
            {
              name: "MARGUES",
            },
            {
              name: "MOMPONG",
            },
            {
              name: "NEKITAN",
            },
            {
              name: "SAPALAN",
            },
            {
              name: "SEMBA",
            },
            {
              name: "SIBUTO",
            },
            {
              name: "SIFAREN (SIFARAN)",
            },
            {
              name: "TAMBAK",
            },
            {
              name: "TAMONTAKA",
            },
            {
              name: "TANUEL",
            },
            {
              name: "TAPIAN",
            },
            {
              name: "TAVIRAN",
            },
            {
              name: "TENONGGOS",
            },
          ],
        },
        "DATU PAGLAS": {
          name: "DATU PAGLAS",
          baranggays: [
            {
              name: "ALIP (POB.)",
            },
            {
              name: "BONAWAN",
            },
            {
              name: "BULOD",
            },
            {
              name: "DAMALUSAY",
            },
            {
              name: "DAMAWATO",
            },
            {
              name: "DATANG",
            },
            {
              name: "ELBEBE",
            },
            {
              name: "KALUMENGA (KALUMANGA)",
            },
            {
              name: "KATIL",
            },
            {
              name: "LIPAO",
            },
            {
              name: "LOMOYON",
            },
            {
              name: "MADIDIS",
            },
            {
              name: "MAKAT",
            },
            {
              name: "MALALA",
            },
            {
              name: "MANGADEG",
            },
            {
              name: "MANINDOLO",
            },
            {
              name: "MAO",
            },
            {
              name: "NAPOK",
            },
            {
              name: "PALAO SA BUTO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUYA",
            },
            {
              name: "SALENDAB",
            },
            {
              name: "SEPAKA",
            },
          ],
        },
        "DATU PIANG": {
          name: "DATU PIANG",
          baranggays: [
            {
              name: "ALONGANAN",
            },
            {
              name: "AMBADAO",
            },
            {
              name: "BALANAKAN",
            },
            {
              name: "BALONG",
            },
            {
              name: "BUAYAN",
            },
            {
              name: "DADO",
            },
            {
              name: "DAMABALAS",
            },
            {
              name: "DUAMINANGA",
            },
            {
              name: "KALIPAPA",
            },
            {
              name: "KANGUAN",
            },
            {
              name: "LIONG",
            },
            {
              name: "MAGASLONG",
            },
            {
              name: "MASIGAY",
            },
            {
              name: "MONTAY",
            },
            {
              name: "POBLACION (DULAWAN)",
            },
            {
              name: "REINA REGENTE",
            },
          ],
        },
        "DATU SALIBO": {
          name: "DATU SALIBO",
          baranggays: [
            {
              name: "ALONGANAN",
            },
            {
              name: "ANDAVIT",
            },
            {
              name: "BALANAKAN",
            },
            {
              name: "BUAYAN",
            },
            {
              name: "BUTILEN",
            },
            {
              name: "DADO",
            },
            {
              name: "DAMABALAS",
            },
            {
              name: "DUAMINANGA",
            },
            {
              name: "KALIPAPA",
            },
            {
              name: "LIONG",
            },
            {
              name: "MAGASLONG",
            },
            {
              name: "MASIGAY",
            },
            {
              name: "PAGATIN",
            },
            {
              name: "PANDI",
            },
            {
              name: "PENDITEN",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "TEE",
            },
          ],
        },
        "DATU SAUDI AMPATUAN": {
          name: "DATU SAUDI AMPATUAN",
          baranggays: [
            {
              name: "DAPIAWAN",
            },
            {
              name: "ELIAN",
            },
            {
              name: "GAWANG",
            },
            {
              name: "KABENGI",
            },
            {
              name: "KITANGO",
            },
            {
              name: "KITAPOK",
            },
            {
              name: "MADIA",
            },
            {
              name: "SALBU",
            },
          ],
        },
        "DATU UNSAY": {
          name: "DATU UNSAY",
          baranggays: [
            {
              name: "BULAYAN",
            },
            {
              name: "IGANAGAMPONG",
            },
            {
              name: "MACALAG",
            },
            {
              name: "MAITUMAIG",
            },
            {
              name: "MALANGOG",
            },
            {
              name: "META",
            },
            {
              name: "PANANGETI",
            },
            {
              name: "TUNTUNGAN",
            },
          ],
        },
        "GEN. S. K. PENDATUN": {
          name: "GEN. S. K. PENDATUN",
          baranggays: [
            {
              name: "BADAK",
            },
            {
              name: "BULOD",
            },
            {
              name: "KALADTURAN",
            },
            {
              name: "KULASI",
            },
            {
              name: "LAO-LAO",
            },
            {
              name: "LASANGAN",
            },
            {
              name: "LOWER IDTIG",
            },
            {
              name: "LUMABAO",
            },
            {
              name: "MAKAINIS",
            },
            {
              name: "MIDCONDING",
            },
            {
              name: "MIDPANDACAN",
            },
            {
              name: "PANOSOLEN",
            },
            {
              name: "PIDTIGUIAN",
            },
            {
              name: "QUIPOLOT",
            },
            {
              name: "RAMCOR",
            },
            {
              name: "SADANGEN",
            },
            {
              name: "SUMAKUBAY",
            },
            {
              name: "TONGGOL",
            },
            {
              name: "UPPER LASANGAN",
            },
          ],
        },
        GUINDULUNGAN: {
          name: "GUINDULUNGAN",
          baranggays: [
            {
              name: "AHAN",
            },
            {
              name: "BAGAN",
            },
            {
              name: "DATALPANDAN",
            },
            {
              name: "KALUMAMIS",
            },
            {
              name: "KATEMAN",
            },
            {
              name: "LAMBAYAO",
            },
            {
              name: "MACASAMPEN",
            },
            {
              name: "MUSLIM",
            },
            {
              name: "MUTI",
            },
            {
              name: "SAMPAO",
            },
            {
              name: "TAMBUNAN",
            },
          ],
        },
        "KABUNTALAN (TUMBAO)": {
          name: "KABUNTALAN (TUMBAO)",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BUTERIN",
            },
            {
              name: "DADTUMOG (DADTUMEG)",
            },
            {
              name: "GAMBAR",
            },
            {
              name: "GANTA",
            },
            {
              name: "KATIDTUAN",
            },
            {
              name: "LANGEBAN",
            },
            {
              name: "LIONG",
            },
            {
              name: "LOWER TAVIRAN",
            },
            {
              name: "MAITONG",
            },
            {
              name: "MATILAK",
            },
            {
              name: "PAGALUNGAN",
            },
            {
              name: "PAYAN",
            },
            {
              name: "PEDTAD",
            },
            {
              name: "PENED",
            },
            {
              name: "POBLACION",
            },
            {
              name: "UPPER TAVIRAN",
            },
          ],
        },
        MAMASAPANO: {
          name: "MAMASAPANO",
          baranggays: [
            {
              name: "BAGUMBONG",
            },
            {
              name: "DABENAYAN",
            },
            {
              name: "DALADAP",
            },
            {
              name: "DASIKIL",
            },
            {
              name: "LIAB",
            },
            {
              name: "LIBUTAN",
            },
            {
              name: "LUSAY",
            },
            {
              name: "MAMASAPANO",
            },
            {
              name: "MANONGKALING",
            },
            {
              name: "PIDSANDAWAN",
            },
            {
              name: "PIMBALAKAN",
            },
            {
              name: "SAPAKAN",
            },
            {
              name: "TUKA",
            },
            {
              name: "TUKANALIPAO",
            },
          ],
        },
        MANGUDADATU: {
          name: "MANGUDADATU",
          baranggays: [
            {
              name: "DALADAGAN",
            },
            {
              name: "KALIAN",
            },
            {
              name: "LUAYAN",
            },
            {
              name: "PAITAN",
            },
            {
              name: "PANAPAN",
            },
            {
              name: "TENOK",
            },
            {
              name: "TINAMBULAN",
            },
            {
              name: "TUMBAO",
            },
          ],
        },
        MATANOG: {
          name: "MATANOG",
          baranggays: [
            {
              name: "BAYANGA NORTE",
            },
            {
              name: "BAYANGA SUR",
            },
            {
              name: "BUGASAN NORTE",
            },
            {
              name: "BUGASAN SUR (POB.)",
            },
            {
              name: "KIDAMA",
            },
            {
              name: "LANGCO",
            },
            {
              name: "LANGKONG",
            },
            {
              name: "SAPAD",
            },
          ],
        },
        "NORTHERN KABUNTALAN": {
          name: "NORTHERN KABUNTALAN",
          baranggays: [
            {
              name: "BALONG",
            },
            {
              name: "DAMATOG",
            },
            {
              name: "GAYONGA",
            },
            {
              name: "GUIAWA",
            },
            {
              name: "INDATUAN",
            },
            {
              name: "KAPIMPILAN",
            },
            {
              name: "LIBUNGAN",
            },
            {
              name: "MONTAY",
            },
            {
              name: "PAULINO LABIO",
            },
            {
              name: "SABAKEN",
            },
            {
              name: "TUMAGUINTING",
            },
          ],
        },
        PAGAGAWAN: {
          name: "PAGAGAWAN",
          baranggays: [
            {
              name: "BALATUNGKAYO (BATUNGKAYO)",
            },
            {
              name: "BULIT",
            },
            {
              name: "BULOD",
            },
            {
              name: "DUNGGUAN",
            },
            {
              name: "LIMBALUD",
            },
            {
              name: "MARIDAGAO",
            },
            {
              name: "NABUNDAS",
            },
            {
              name: "PAGAGAWAN",
            },
            {
              name: "TALAPAS",
            },
            {
              name: "TALITAY",
            },
            {
              name: "TUNGGOL",
            },
          ],
        },
        PAGALUNGAN: {
          name: "PAGALUNGAN",
          baranggays: [
            {
              name: "BAGOENGED",
            },
            {
              name: "BULIOK",
            },
            {
              name: "DALGAN",
            },
            {
              name: "DAMALASAK",
            },
            {
              name: "GALAKIT",
            },
            {
              name: "INUG-UG",
            },
            {
              name: "KALBUGAN",
            },
            {
              name: "KILANGAN",
            },
            {
              name: "KUDAL",
            },
            {
              name: "LAYOG",
            },
            {
              name: "LINANDANGAN",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        PAGLAT: {
          name: "PAGLAT",
          baranggays: [
            {
              name: "CAMPO",
            },
            {
              name: "DAMAKLING",
            },
            {
              name: "DAMALUSAY",
            },
            {
              name: "KAKAL",
            },
            {
              name: "PAGLAT",
            },
            {
              name: "SALAM",
            },
            {
              name: "TUAL",
            },
            {
              name: "UPPER IDTIG",
            },
          ],
        },
        PANDAG: {
          name: "PANDAG",
          baranggays: [
            {
              name: "KABULING",
            },
            {
              name: "KAYAGA",
            },
            {
              name: "KAYUPO (CUYAPO)",
            },
            {
              name: "LEPAK",
            },
            {
              name: "LOWER D'LAG",
            },
            {
              name: "MALANGIT",
            },
            {
              name: "PANDAG",
            },
            {
              name: "UPPER D'LAG",
            },
          ],
        },
        PARANG: {
          name: "PARANG",
          baranggays: [
            {
              name: "BONGO ISLAND (LITAYEN)",
            },
            {
              name: "CAMPO ISLAM",
            },
            {
              name: "COTONGAN",
            },
            {
              name: "DATU MACARIMBANG BIRUAR",
            },
            {
              name: "GADUNGAN",
            },
            {
              name: "GADUNGANPEDPANDARAN",
            },
            {
              name: "GUIDAY T. BIRUAR",
            },
            {
              name: "GUMAGADONG CALAWAG",
            },
            {
              name: "KABUAN",
            },
            {
              name: "LANDASAN (SARMIENTO)",
            },
            {
              name: "LIMBAYAN",
            },
            {
              name: "MACASANDAG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAKING",
            },
            {
              name: "MANION",
            },
            {
              name: "MORO POINT",
            },
            {
              name: "NITUAN",
            },
            {
              name: "ORANDANG",
            },
            {
              name: "PARANG",
            },
            {
              name: "PINANTAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POLLOC",
            },
            {
              name: "SAMBEREN",
            },
            {
              name: "TAGUDTONGAN",
            },
            {
              name: "TUCA-MAROR",
            },
          ],
        },
        "SULTAN KUDARAT (NULING)": {
          name: "SULTAN KUDARAT (NULING)",
          baranggays: [
            {
              name: "REBUKEN",
            },
            {
              name: "SALIMBAO",
            },
            {
              name: "SAMBOLAWAN",
            },
            {
              name: "SENDITAN",
            },
            {
              name: "UNGAP",
            },
          ],
        },
        "SULTAN MASTURA": {
          name: "SULTAN MASTURA",
          baranggays: [
            {
              name: "BALUT",
            },
            {
              name: "BOLIOK",
            },
            {
              name: "BUNGABONG",
            },
            {
              name: "DAGURONGAN",
            },
            {
              name: "KIRKIR",
            },
            {
              name: "MACABICO (MACABISO)",
            },
            {
              name: "NAMUKEN",
            },
            {
              name: "SIMUAY/SEASHORE",
            },
            {
              name: "SOLON",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TAPAYAN",
            },
            {
              name: "TARIKEN",
            },
            {
              name: "TUKA",
            },
          ],
        },
        "SULTAN SA BARONGIS (LAMBAYONG)": {
          name: "SULTAN SA BARONGIS (LAMBAYONG)",
          baranggays: [
            {
              name: "ANGKAYAMAT",
            },
            {
              name: "BARURAO",
            },
            {
              name: "BULOD",
            },
            {
              name: "DARAMPUA",
            },
            {
              name: "GADUNGAN",
            },
            {
              name: "KULAMBOG",
            },
            {
              name: "LANGGAPANAN",
            },
            {
              name: "MASULOT",
            },
            {
              name: "PALDONG",
            },
            {
              name: "PAPAKAN",
            },
            {
              name: "TUGAL",
            },
            {
              name: "TUKANAKUDEN",
            },
          ],
        },
        TALAYAN: {
          name: "TALAYAN",
          baranggays: [
            {
              name: "BINANGGA NORTH",
            },
            {
              name: "BINANGGA SOUTH",
            },
            {
              name: "BOBOGUIRON",
            },
            {
              name: "DAMABLAC",
            },
            {
              name: "FUGOTAN",
            },
            {
              name: "FUKOL",
            },
            {
              name: "KATIBPUAN",
            },
            {
              name: "KEDATI",
            },
            {
              name: "LANTING",
            },
            {
              name: "LINAMUNAN",
            },
            {
              name: "MARADER",
            },
            {
              name: "TALAYAN",
            },
            {
              name: "TAMAR",
            },
            {
              name: "TAMBUNAN I",
            },
            {
              name: "TIMBALUAN",
            },
          ],
        },
        TALITAY: {
          name: "TALITAY",
          baranggays: [
            {
              name: "BINTAN (BENTAN)",
            },
            {
              name: "GADUNGAN",
            },
            {
              name: "KILADAP",
            },
            {
              name: "KILALAN",
            },
            {
              name: "KUDEN",
            },
            {
              name: "MAKADAYON",
            },
            {
              name: "MANGGAY",
            },
            {
              name: "PAGEDA",
            },
            {
              name: "TALITAY",
            },
          ],
        },
        UPI: {
          name: "UPI",
          baranggays: [
            {
              name: "BANTEK",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BLENSONG",
            },
            {
              name: "BORONGOTAN",
            },
            {
              name: "BUGABUNGAN",
            },
            {
              name: "BUNGCOG",
            },
            {
              name: "DARUGAO",
            },
            {
              name: "GANASI",
            },
            {
              name: "KABAKABA",
            },
            {
              name: "KIBLEG",
            },
            {
              name: "KIBUCAY",
            },
            {
              name: "KIGA",
            },
            {
              name: "KINITAN (KINITAAN)",
            },
            {
              name: "MIRAB",
            },
            {
              name: "NANGI",
            },
            {
              name: "NURO POBLACION",
            },
            {
              name: "RANAO PILAYAN",
            },
            {
              name: "REMPES",
            },
            {
              name: "RENEDE",
            },
            {
              name: "RENTI",
            },
            {
              name: "RIFAO",
            },
            {
              name: "SEFEGEFEN",
            },
            {
              name: "TINUNGKAAN",
            },
          ],
        },
      },
    },
    MARINDUQUE: {
      name: "MARINDUQUE",
      region: "REGION 4-B",
      municipalities: {
        BOAC: {
          name: "BOAC",
          baranggays: [
            {
              name: "AGOT",
            },
            {
              name: "AGUMAYMAYAN",
            },
            {
              name: "AMOINGON",
            },
            {
              name: "APITONG",
            },
            {
              name: "BALAGASAN",
            },
            {
              name: "BALARING",
            },
            {
              name: "BALIMBING",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "BANGBANGALON",
            },
            {
              name: "BANTAD",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BAYUTI",
            },
            {
              name: "BINUNGA",
            },
            {
              name: "BOAC",
            },
            {
              name: "BOI",
            },
            {
              name: "BOTON",
            },
            {
              name: "BULIASNIN",
            },
            {
              name: "BUNGANAY",
            },
            {
              name: "CAGANHAO",
            },
            {
              name: "CANAT",
            },
            {
              name: "CATUBUGAN",
            },
            {
              name: "CAWIT",
            },
            {
              name: "DAIG",
            },
            {
              name: "DAYPAY",
            },
            {
              name: "DUYAY",
            },
            {
              name: "HINAPULAN",
            },
            {
              name: "IHATUB",
            },
            {
              name: "ISOK I (POB.)",
            },
            {
              name: "ISOK II POB. (KALAMIAS)",
            },
            {
              name: "LAYLAY",
            },
            {
              name: "LUPAC",
            },
            {
              name: "MAHINHIN",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MALBOG",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MALUSAK (POB.)",
            },
            {
              name: "MANSIWAT",
            },
            {
              name: "MATAAS NA BAYAN (POB.)",
            },
            {
              name: "MAYBO",
            },
            {
              name: "MERCADO (POB.)",
            },
            {
              name: "MURALLON (POB.)",
            },
            {
              name: "OGBAC",
            },
            {
              name: "PAWA",
            },
            {
              name: "PILI",
            },
            {
              name: "POCTOY",
            },
            {
              name: "PORAS",
            },
            {
              name: "PUTING BUHANGIN",
            },
            {
              name: "PUYOG",
            },
            {
              name: "SABONG",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SANTOL",
            },
            {
              name: "SAWI",
            },
            {
              name: "TABI",
            },
            {
              name: "TABIGUE",
            },
            {
              name: "TAGWAK",
            },
            {
              name: "TAMBUNAN",
            },
            {
              name: "TAMPUS (POB.)",
            },
            {
              name: "TANZA",
            },
            {
              name: "TUGOS",
            },
            {
              name: "TUMAGABOK",
            },
            {
              name: "TUMAPON",
            },
          ],
        },
        BUENAVISTA: {
          name: "BUENAVISTA",
          baranggays: [
            {
              name: "BAGACAY",
            },
            {
              name: "BAGTINGON",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BICAS-BICAS",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAIGANGAN",
            },
            {
              name: "DAYKITIN",
            },
            {
              name: "LIBAS",
            },
            {
              name: "MALBOG",
            },
            {
              name: "SIHI",
            },
            {
              name: "TIMBO (SANGGULONG)",
            },
            {
              name: "TUNGIB-LIPATA",
            },
            {
              name: "YOOK",
            },
          ],
        },
        GASAN: {
          name: "GASAN",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BACHAO IBABA",
            },
            {
              name: "BACHAO ILAYA",
            },
            {
              name: "BACONGBACONG",
            },
            {
              name: "BAHI",
            },
            {
              name: "BANGBANG",
            },
            {
              name: "BANOT",
            },
            {
              name: "BANUYO",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BOGNUYAN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "DAWIS",
            },
            {
              name: "DILI",
            },
            {
              name: "GASAN",
            },
            {
              name: "LIBTANGIN",
            },
            {
              name: "MAHUNIG",
            },
            {
              name: "MANGILIOL",
            },
            {
              name: "MASIGA",
            },
            {
              name: "MATANDANG GASAN",
            },
            {
              name: "PANGI",
            },
            {
              name: "PINGAN",
            },
            {
              name: "TABIONAN",
            },
            {
              name: "TAPUYAN",
            },
            {
              name: "TIGUION",
            },
          ],
        },
        MOGPOG: {
          name: "MOGPOG",
          baranggays: [
            {
              name: "ANAPOG-SIBUCAO",
            },
            {
              name: "ARGAO",
            },
            {
              name: "BALANACAN",
            },
            {
              name: "BANTO",
            },
            {
              name: "BINTAKAY",
            },
            {
              name: "BOCBOC",
            },
            {
              name: "BUTANSAPA",
            },
            {
              name: "CANDAHON",
            },
            {
              name: "CAPAYANG",
            },
            {
              name: "DANAO",
            },
            {
              name: "DULONG BAYAN (POB.)",
            },
            {
              name: "GITNANG BAYAN (POB.)",
            },
            {
              name: "GUISIAN",
            },
            {
              name: "HINADHARAN",
            },
            {
              name: "HINANGGAYON",
            },
            {
              name: "INO",
            },
            {
              name: "JANAGDONG",
            },
            {
              name: "LAMESA",
            },
            {
              name: "LAON",
            },
            {
              name: "MAGAPUA",
            },
            {
              name: "MALAYAK",
            },
            {
              name: "MALUSAK",
            },
            {
              name: "MAMPAITAN",
            },
            {
              name: "MANGYAN-MABABAD",
            },
            {
              name: "MARKET SITE (POB.)",
            },
            {
              name: "MATAAS NA BAYAN",
            },
            {
              name: "MENDEZ",
            },
            {
              name: "MOGPOG",
            },
            {
              name: "NANGKA I",
            },
            {
              name: "NANGKA II",
            },
            {
              name: "PAYE",
            },
            {
              name: "PILI",
            },
            {
              name: "PUTING BUHANGIN",
            },
            {
              name: "SAYAO",
            },
            {
              name: "SILANGAN",
            },
            {
              name: "SUMANGGA",
            },
            {
              name: "TARUG",
            },
            {
              name: "VILLA MENDEZ (POB.)",
            },
          ],
        },
        "SANTA CRUZ": {
          name: "SANTA CRUZ",
          baranggays: [
            {
              name: "ALOBO",
            },
            {
              name: "ANGAS",
            },
            {
              name: "ATURAN",
            },
            {
              name: "BAGONG SILANG POB. (2ND ZONE)",
            },
            {
              name: "BAGUIDBIRIN",
            },
            {
              name: "BALIIS",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BANAHAW POB. (3RD ZONE)",
            },
            {
              name: "BANGCUANGAN",
            },
            {
              name: "BANOGBOG",
            },
            {
              name: "BIGA",
            },
            {
              name: "BOTILAO",
            },
            {
              name: "BUYABOD",
            },
            {
              name: "DATING BAYAN",
            },
            {
              name: "DEVILLA",
            },
            {
              name: "DOLORES",
            },
            {
              name: "HAGUIMIT",
            },
            {
              name: "HUPI",
            },
            {
              name: "IPIL",
            },
            {
              name: "JOLO",
            },
            {
              name: "KAGANHAO",
            },
            {
              name: "KALANGKANG",
            },
            {
              name: "KAMANDUGAN",
            },
            {
              name: "KASILY",
            },
            {
              name: "KILO-KILO",
            },
            {
              name: "KIÑAMAN",
            },
            {
              name: "LABO",
            },
            {
              name: "LAMESA",
            },
            {
              name: "LANDY",
            },
            {
              name: "LAPU-LAPU POB. (5TH ZONE)",
            },
            {
              name: "LIBJO",
            },
            {
              name: "LIPA",
            },
            {
              name: "LUSOK",
            },
            {
              name: "MAHARLIKA POB. (1ST ZONE)",
            },
            {
              name: "MAKULAPNIT",
            },
            {
              name: "MANIWAYA",
            },
            {
              name: "MANLIBUNAN",
            },
            {
              name: "MASAGUISI",
            },
            {
              name: "MASALUKOT",
            },
            {
              name: "MATALABA",
            },
            {
              name: "MONGPONG",
            },
            {
              name: "MORALES",
            },
            {
              name: "NAPO",
            },
            {
              name: "PAG-ASA POB. (4TH ZONE)",
            },
            {
              name: "PANTAYIN",
            },
            {
              name: "POLO",
            },
            {
              name: "PULONG-PARANG",
            },
            {
              name: "PUNONG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAGUM",
            },
            {
              name: "TAMAYO",
            },
            {
              name: "TAMBANGAN",
            },
            {
              name: "TAWIRAN",
            },
            {
              name: "TAYTAY",
            },
          ],
        },
        TORRIJOS: {
          name: "TORRIJOS",
          baranggays: [
            {
              name: "BANGWAYIN",
            },
            {
              name: "BAYAKBAKIN",
            },
            {
              name: "BOLO",
            },
            {
              name: "BONLIW",
            },
            {
              name: "BUANGAN",
            },
            {
              name: "CABUYO",
            },
            {
              name: "CAGPO",
            },
            {
              name: "DAMPULAN",
            },
            {
              name: "KAY DUKE",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAKAWAYAN",
            },
            {
              name: "MALIBAGO",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MARANLIG",
            },
            {
              name: "MARLANGGA",
            },
            {
              name: "MATUYATUYA",
            },
            {
              name: "NANGKA",
            },
            {
              name: "PAKASKASAN",
            },
            {
              name: "PAYANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POCTOY",
            },
            {
              name: "SIBUYAO",
            },
            {
              name: "SUHA",
            },
            {
              name: "TALAWAN",
            },
            {
              name: "TIGWI",
            },
            {
              name: "TORRIJOS",
            },
          ],
        },
      },
    },
    MASBATE: {
      name: "MASBATE",
      region: "REGION 5",
      municipalities: {
        AROROY: {
          name: "AROROY",
          baranggays: [
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAWANG",
            },
            {
              name: "SYNDICATE",
            },
            {
              name: "TALABAAN",
            },
            {
              name: "TALIB",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TINIGBAN",
            },
          ],
        },
        BALENO: {
          name: "BALENO",
          baranggays: [
            {
              name: "BAAO",
            },
            {
              name: "BALENO",
            },
            {
              name: "BANASE",
            },
            {
              name: "BATUILA",
            },
            {
              name: "CAGARA",
            },
            {
              name: "CAGPANDAN",
            },
            {
              name: "CANCAHORAO",
            },
            {
              name: "CANJUNDAY",
            },
            {
              name: "DOCOL",
            },
            {
              name: "EASTERN CAPSAY",
            },
            {
              name: "GABI",
            },
            {
              name: "GANGAO",
            },
            {
              name: "LAGTA",
            },
            {
              name: "LAHONG INTERIOR",
            },
            {
              name: "LAHONG PROPER",
            },
            {
              name: "LIPATA",
            },
            {
              name: "MADANGCALAN",
            },
            {
              name: "MAGDALENA",
            },
            {
              name: "MANOBOC",
            },
            {
              name: "OBONGON DIOT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLOT",
            },
            {
              name: "POTOSON",
            },
            {
              name: "SOG-ONG",
            },
            {
              name: "TINAPIAN",
            },
          ],
        },
        BALUD: {
          name: "BALUD",
          baranggays: [
            {
              name: "BALUD",
            },
            {
              name: "BAYBAY (LUMOCAB)",
            },
            {
              name: "BONGCANAWAY",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CANTIL",
            },
            {
              name: "CASAMONGAN",
            },
            {
              name: "DANAO",
            },
            {
              name: "DAO",
            },
            {
              name: "GUINBANWAHAN",
            },
            {
              name: "ILAYA",
            },
            {
              name: "JANGAN",
            },
            {
              name: "JINTOTOLO",
            },
            {
              name: "MABUHAY (BONGCANAWAY III)",
            },
            {
              name: "MAPILI",
            },
            {
              name: "MAPITOGO",
            },
            {
              name: "PAJO",
            },
            {
              name: "PALANE",
            },
            {
              name: "PANGUIRANAN",
            },
            {
              name: "PANUBIGAN",
            },
            {
              name: "POBLACION (BALUD)",
            },
            {
              name: "PULANDUTA",
            },
            {
              name: "QUINAYANGAN DIOTAY",
            },
            {
              name: "QUINAYANGAN TONGA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAMPAD",
            },
            {
              name: "SAN ANDRES (QUINAYANGAN DACU)",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAPATOS",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TONGA",
            },
            {
              name: "UBO",
            },
            {
              name: "VICTORY (VICTORIA)",
            },
            {
              name: "VILLA ALVAREZ",
            },
          ],
        },
        "BATUAN (ISLAND)": {
          name: "BATUAN (ISLAND)",
          baranggays: [
            {
              name: "BATUAN (ISLAND)",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CAMBAÑEZ",
            },
            {
              name: "CANARES",
            },
            {
              name: "COSTA RICA",
            },
            {
              name: "DANAO",
            },
            {
              name: "GIBRALTAR",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MATABAO",
            },
            {
              name: "NASANDIG",
            },
            {
              name: "PANISIHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROYROY",
            },
            {
              name: "SAWANG",
            },
          ],
        },
        CATAINGAN: {
          name: "CATAINGAN",
          baranggays: [
            {
              name: "ABACA",
            },
            {
              name: "AGUADA",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "CADULAWAN",
            },
            {
              name: "CAGBATANG",
            },
            {
              name: "CATAINGAN",
            },
            {
              name: "CHIMENEA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CURVADA",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "DOMOROG",
            },
            {
              name: "ESTAMPAR",
            },
            {
              name: "GAHIT",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "LIONG",
            },
            {
              name: "MAANAHAO",
            },
            {
              name: "MADAMBA",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MATAYUM",
            },
            {
              name: "MATUBINAO",
            },
            {
              name: "MINTAC",
            },
            {
              name: "NADAWISAN",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PAWICAN",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGBOAN",
            },
            {
              name: "TUYBO",
            },
            {
              name: "VILLA POGADO",
            },
          ],
        },
        CAWAYAN: {
          name: "CAWAYAN",
          baranggays: [
            {
              name: "BEGIA",
            },
            {
              name: "CABAYUGAN",
            },
            {
              name: "CABUNGAHAN",
            },
            {
              name: "CALAPAYAN",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CHICO ISLAND",
            },
            {
              name: "DALIPE",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "GILOTONGAN",
            },
            {
              name: "GUIOM",
            },
            {
              name: "IRAYA",
            },
            {
              name: "ITOMBATO",
            },
            {
              name: "LAGUE-LAGUE",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LOOC",
            },
            {
              name: "MACTAN",
            },
            {
              name: "MADBAD",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MAIHAO",
            },
            {
              name: "MALBUG",
            },
            {
              name: "NARO",
            },
            {
              name: "PALOBANDERA",
            },
            {
              name: "PANANAWAN",
            },
            {
              name: "PEÑA ISLAND",
            },
            {
              name: "PIN-AS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULOT",
            },
            {
              name: "PUNTA BATSAN",
            },
            {
              name: "R.M. MAGBALON (BEBINAN)",
            },
            {
              name: "RECODO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TABERNA",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TUBOG",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        "CLAVERIA (ISLAND)": {
          name: "CLAVERIA (ISLAND)",
          baranggays: [
            {
              name: "ALBASAN",
            },
            {
              name: "BOCA ENGAÑO",
            },
            {
              name: "BUYO",
            },
            {
              name: "CALPI",
            },
            {
              name: "CANOMAY",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CLAVERIA (ISLAND)",
            },
            {
              name: "IMELDA",
            },
            {
              name: "MABABANG BAYBAY",
            },
            {
              name: "MABITON",
            },
            {
              name: "MANAPAO",
            },
            {
              name: "NABASAGAN",
            },
            {
              name: "NONOC",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PASIG",
            },
            {
              name: "PEÑAFRANCIA",
            },
            {
              name: "POBLACION DISTRICT I (BGY. 1)",
            },
            {
              name: "POBLACION DISTRICT II (BGY. 2)",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TAGUILID",
            },
          ],
        },
        DIMASALANG: {
          name: "DIMASALANG",
          baranggays: [
            {
              name: "BALANTAY",
            },
            {
              name: "BALOCAWE",
            },
            {
              name: "BANAHAO",
            },
            {
              name: "BUENAFLOR",
            },
            {
              name: "BURACAN",
            },
            {
              name: "CABANOYOAN",
            },
            {
              name: "CABRERA",
            },
            {
              name: "CADULAN",
            },
            {
              name: "CALABAD",
            },
            {
              name: "CANOMAY",
            },
            {
              name: "DIMASALANG",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "GAID",
            },
            {
              name: "GREGORIO ALINO (PIA-ONG)",
            },
            {
              name: "MAGCARAGUIT",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SUBA",
            },
            {
              name: "T.R. YANGCO (YANCO)",
            },
          ],
        },
        ESPERANZA: {
          name: "ESPERANZA",
          baranggays: [
            {
              name: "AGOHO",
            },
            {
              name: "ALMERO",
            },
            {
              name: "BARAS",
            },
            {
              name: "DOMOROG",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "ILIGAN",
            },
            {
              name: "LABANGTAYTAY",
            },
            {
              name: "LABRADOR",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MASBARANON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POTINGBATO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SOROSIMBAJAN",
            },
            {
              name: "TAWAD",
            },
            {
              name: "TUNGA",
            },
            {
              name: "VILLA",
            },
          ],
        },
        MANDAON: {
          name: "MANDAON",
          baranggays: [
            {
              name: "ALAS",
            },
            {
              name: "AYAT",
            },
            {
              name: "BAT-ONGAN",
            },
            {
              name: "BUGTONG",
            },
            {
              name: "BURI",
            },
            {
              name: "CABITAN",
            },
            {
              name: "CAGMASOSO",
            },
            {
              name: "CANOMOY",
            },
            {
              name: "CENTRO",
            },
            {
              name: "DAYAO",
            },
            {
              name: "GUINCAIPTAN",
            },
            {
              name: "LAGUINBANWA",
            },
            {
              name: "LANTANGAN",
            },
            {
              name: "LOOC",
            },
            {
              name: "MABATOBATO",
            },
            {
              name: "MANDAON",
            },
            {
              name: "MAOLINGON",
            },
            {
              name: "NAILABAN",
            },
            {
              name: "NANIPSAN",
            },
            {
              name: "PINAMANGCAAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLO DACU",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "TAGPU",
            },
            {
              name: "TUMALAYTAY",
            },
          ],
        },
        "MASBATE CITY": {
          name: "MASBATE CITY",
          baranggays: [
            {
              name: "ANAS",
            },
            {
              name: "ASID",
            },
            {
              name: "B. TITONG",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BANTIGUE",
            },
            {
              name: "BAPOR (POB.)",
            },
            {
              name: "BATUHAN",
            },
            {
              name: "BAYOMBON",
            },
            {
              name: "BIYONG",
            },
            {
              name: "BOLO",
            },
            {
              name: "CAGAY",
            },
            {
              name: "CAWAYAN EXTERIOR",
            },
            {
              name: "CAWAYAN INTERIOR",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "ESPINOSA",
            },
            {
              name: "F. MAGALLANES",
            },
            {
              name: "IBINGAY",
            },
            {
              name: "IGANG",
            },
            {
              name: "KALIPAY (POB.)",
            },
            {
              name: "KINAMALIGAN",
            },
            {
              name: "MALINTA",
            },
            {
              name: "MAPIÑA",
            },
            {
              name: "MASBATE CITY",
            },
            {
              name: "MAYNGARAN",
            },
            {
              name: "NURSERY",
            },
            {
              name: "PATING (POB.)",
            },
            {
              name: "PAWA",
            },
            {
              name: "SINALONGAN",
            },
            {
              name: "TUGBO",
            },
            {
              name: "UBONGAN DACU",
            },
            {
              name: "USAB",
            },
          ],
        },
        MILAGROS: {
          name: "MILAGROS",
          baranggays: [
            {
              name: "BACOLOD",
            },
            {
              name: "BANGAD",
            },
            {
              name: "BARA",
            },
            {
              name: "BONBON",
            },
            {
              name: "CALASUCHE",
            },
            {
              name: "CALUMPANG (TAISAN)",
            },
            {
              name: "CAPACULAN",
            },
            {
              name: "CAYABON",
            },
            {
              name: "GUINLUTHANGAN",
            },
            {
              name: "JAMORAWON",
            },
            {
              name: "MAGSALANGI",
            },
            {
              name: "MATAGBAC",
            },
            {
              name: "MATANGLAD",
            },
            {
              name: "MATIPORON",
            },
            {
              name: "MILAGROS",
            },
            {
              name: "MOISES R. ESPINOSA",
            },
            {
              name: "NARANGASAN",
            },
            {
              name: "PAMANGPANGON",
            },
            {
              name: "PARAISO (POTOT)",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAWMILL",
            },
            {
              name: "TAGBON",
            },
            {
              name: "TAWAD",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TINACLIPAN (BATO)",
            },
          ],
        },
        MOBO: {
          name: "MOBO",
          baranggays: [
            {
              name: "BAANG",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BALATUCAN",
            },
            {
              name: "BARAG",
            },
            {
              name: "DACU",
            },
            {
              name: "FABRICA",
            },
            {
              name: "GUINTORELAN",
            },
            {
              name: "HOLJOGON",
            },
            {
              name: "LALAGUNA",
            },
            {
              name: "LOMOCLOC",
            },
            {
              name: "LUYONG CATUNGAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MANDALI",
            },
            {
              name: "MAPUYO",
            },
            {
              name: "MARINTOC",
            },
            {
              name: "MOBO",
            },
            {
              name: "NASUNDUAN",
            },
            {
              name: "PINAMALATICAN",
            },
            {
              name: "PINAMARBUHAN",
            },
            {
              name: "POBLACION DIST. I",
            },
            {
              name: "POBLACION DIST. II",
            },
            {
              name: "POLOT",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SAWMILL",
            },
            {
              name: "TABUC",
            },
            {
              name: "TUGAWE",
            },
            {
              name: "TUGBO",
            },
            {
              name: "UMABAY EXTERIOR",
            },
            {
              name: "UMABAY INTERIOR",
            },
          ],
        },
        MONREAL: {
          name: "MONREAL",
          baranggays: [
            {
              name: "CANTORNA",
            },
            {
              name: "FAMOSA",
            },
            {
              name: "GUINHADAP",
            },
            {
              name: "MACARTHUR",
            },
            {
              name: "MAGLAMBONG",
            },
            {
              name: "MONREAL",
            },
            {
              name: "MOROCBOROCAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "REAL",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TOGORON",
            },
          ],
        },
        PALANAS: {
          name: "PALANAS",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BANCO",
            },
            {
              name: "BIGA-A",
            },
            {
              name: "BONTOD",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "INTUSAN",
            },
            {
              name: "JOSE A. ABENIR SR. (LIBTONG)",
            },
            {
              name: "MAANAHAO",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALATAWAN",
            },
            {
              name: "MALIBAS",
            },
            {
              name: "MARAVILLA",
            },
            {
              name: "MATUGNAO",
            },
            {
              name: "MIABAS",
            },
            {
              name: "NABANGIG",
            },
            {
              name: "NIPA",
            },
            {
              name: "PALANAS",
            },
            {
              name: "PARINA",
            },
            {
              name: "PIÑA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA CRUZ",
            },
          ],
        },
        "PIO V. CORPUZ (LIMBUHAN)": {
          name: "PIO V. CORPUZ (LIMBUHAN)",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "BUGANG",
            },
            {
              name: "BUGTONG",
            },
            {
              name: "BUNDUCAN",
            },
            {
              name: "CABANGRAYAN",
            },
            {
              name: "CALONGONGAN",
            },
            {
              name: "CASABANGAN",
            },
            {
              name: "GUINDAWAHAN",
            },
            {
              name: "LABIGAN",
            },
            {
              name: "LAMPUYANG",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "PALHO",
            },
            {
              name: "PIO V. CORPUZ (LIMBUHAN)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "TANQUE",
            },
            {
              name: "TUBIGAN",
            },
            {
              name: "TUBOG",
            },
          ],
        },
        PLACER: {
          name: "PLACER",
          baranggays: [
            {
              name: "AGUADA",
            },
            {
              name: "BAN-AO",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CABANGCALAN",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CAMAYABSAN",
            },
            {
              name: "DAANLUNGSOD",
            },
            {
              name: "DANGPANAN",
            },
            {
              name: "DARAGA",
            },
            {
              name: "GUIN-AWAYAN",
            },
            {
              name: "GUINHAN-AYAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LOCSO-AN",
            },
            {
              name: "LUNA",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MANLUT-OD",
            },
            {
              name: "MATAGANTANG",
            },
            {
              name: "NABOCTOT",
            },
            {
              name: "NAGARAO",
            },
            {
              name: "NAINDAY",
            },
            {
              name: "NAOCONDIOT",
            },
            {
              name: "PASIAGON",
            },
            {
              name: "PILI",
            },
            {
              name: "PLACER",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PURO",
            },
            {
              name: "QUIBRADA",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TABOC",
            },
            {
              name: "TAN-AWAN",
            },
            {
              name: "TAVERNA",
            },
            {
              name: "TUBOD",
            },
            {
              name: "VILLA INOCENCIO",
            },
          ],
        },
        "SAN FERNANDO (ISLAND)": {
          name: "SAN FERNANDO (ISLAND)",
          baranggays: [
            {
              name: "ALTAVISTA",
            },
            {
              name: "BAYANIHAN POB. (DISTRICT 4)",
            },
            {
              name: "BAYBAYDAGAT POB. (DISTRICT 1)",
            },
            {
              name: "BENITINAN",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUENOS AIRES",
            },
            {
              name: "BUYO",
            },
            {
              name: "CAÑELAS",
            },
            {
              name: "CORBADA",
            },
            {
              name: "DAPLIAN",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "IPIL",
            },
            {
              name: "LAHONG",
            },
            {
              name: "LUMBIA",
            },
            {
              name: "MAGKAIPIT",
            },
            {
              name: "MAGSASAKA POB. (DISTRICT 3)",
            },
            {
              name: "MINIO",
            },
            {
              name: "PINAMOGHAAN",
            },
            {
              name: "PROGRESO",
            },
            {
              name: "RESURRECCION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN FERNANDO (ISLAND)",
            },
            {
              name: "SILANGAN POB. (DISTRICT 2)",
            },
            {
              name: "SOWA",
            },
            {
              name: "TALISAY",
            },
          ],
        },
        "SAN JACINTO (ISLAND)": {
          name: "SAN JACINTO (ISLAND)",
          baranggays: [
            {
              name: "ALMIÑE",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAGAHANGLAD",
            },
            {
              name: "BARTOLABAC",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CALIPAT-AN",
            },
            {
              name: "DANAO",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "DISTRICT III (POB.)",
            },
            {
              name: "DISTRICT IV (POB.)",
            },
            {
              name: "DORONG-AN DAPLIAN",
            },
            {
              name: "INTERIOR",
            },
            {
              name: "JAGNA-AN",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "PIÑA",
            },
            {
              name: "ROOSEVELT",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JACINTO (ISLAND)",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "WASHINGTON",
            },
          ],
        },
        "SAN PASCUAL (ISLAND)": {
          name: "SAN PASCUAL (ISLAND)",
          baranggays: [
            {
              name: "INIWARAN",
            },
            {
              name: "KI-BUAYA (RIZAL)",
            },
            {
              name: "KI-ROMERO (ROXAS)",
            },
            {
              name: "LAURENTE",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALAKING ILOG",
            },
            {
              name: "MAPANIQUE",
            },
            {
              name: "NAZARENO",
            },
            {
              name: "PINAMASINGAN",
            },
            {
              name: "QUINTINA",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TERRAPLIN (POB.)",
            },
          ],
        },
        USON: {
          name: "USON",
          baranggays: [
            {
              name: "ARADO",
            },
            {
              name: "ARMENIA",
            },
            {
              name: "AURORA",
            },
            {
              name: "BADLING",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAMPANA",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "CENTRO",
            },
            {
              name: "CROSSING",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MADAO",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARCELLA",
            },
            {
              name: "MIAGA",
            },
            {
              name: "MONGAHAY",
            },
            {
              name: "MOROCBOROCAN",
            },
            {
              name: "PAGUIHAMAN",
            },
            {
              name: "PANICIJAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SAWANG",
            },
            {
              name: "SIMAWA",
            },
            {
              name: "USON",
            },
          ],
        },
      },
    },
    "METRO MANILA": {
      name: "METRO MANILA",
      region: "NCR",
      municipalities: {
        "CALOOCAN CITY": {
          name: "CALOOCAN CITY",
          baranggays: [
            {
              name: "BARANGAY 100",
            },
            {
              name: "BARANGAY 101",
            },
            {
              name: "BARANGAY 102",
            },
            {
              name: "BARANGAY 103",
            },
            {
              name: "BARANGAY 104",
            },
            {
              name: "BARANGAY 105",
            },
            {
              name: "BARANGAY 106",
            },
            {
              name: "BARANGAY 107",
            },
            {
              name: "BARANGAY 108",
            },
            {
              name: "BARANGAY 109",
            },
            {
              name: "BARANGAY 11",
            },
            {
              name: "BARANGAY 110",
            },
            {
              name: "BARANGAY 111",
            },
            {
              name: "BARANGAY 112",
            },
            {
              name: "BARANGAY 113",
            },
            {
              name: "BARANGAY 114",
            },
            {
              name: "BARANGAY 115",
            },
            {
              name: "BARANGAY 116",
            },
            {
              name: "BARANGAY 117",
            },
            {
              name: "BARANGAY 118",
            },
            {
              name: "BARANGAY 119",
            },
            {
              name: "BARANGAY 12",
            },
            {
              name: "BARANGAY 120",
            },
            {
              name: "BARANGAY 121",
            },
            {
              name: "BARANGAY 122",
            },
            {
              name: "BARANGAY 123",
            },
            {
              name: "BARANGAY 124",
            },
            {
              name: "BARANGAY 125",
            },
            {
              name: "BARANGAY 126",
            },
            {
              name: "BARANGAY 127",
            },
            {
              name: "BARANGAY 128",
            },
            {
              name: "BARANGAY 129",
            },
            {
              name: "BARANGAY 13",
            },
            {
              name: "BARANGAY 130",
            },
            {
              name: "BARANGAY 131",
            },
            {
              name: "BARANGAY 132",
            },
            {
              name: "BARANGAY 133",
            },
            {
              name: "BARANGAY 134",
            },
            {
              name: "BARANGAY 135",
            },
            {
              name: "BARANGAY 136",
            },
            {
              name: "BARANGAY 137",
            },
            {
              name: "BARANGAY 138",
            },
            {
              name: "BARANGAY 139",
            },
            {
              name: "BARANGAY 14",
            },
            {
              name: "BARANGAY 140",
            },
            {
              name: "BARANGAY 141",
            },
            {
              name: "BARANGAY 142",
            },
            {
              name: "BARANGAY 143",
            },
            {
              name: "BARANGAY 144",
            },
            {
              name: "BARANGAY 145",
            },
            {
              name: "BARANGAY 146",
            },
            {
              name: "BARANGAY 147",
            },
            {
              name: "BARANGAY 148",
            },
            {
              name: "BARANGAY 149",
            },
            {
              name: "BARANGAY 15",
            },
            {
              name: "BARANGAY 150",
            },
            {
              name: "BARANGAY 151",
            },
            {
              name: "BARANGAY 152",
            },
            {
              name: "BARANGAY 153",
            },
            {
              name: "BARANGAY 154",
            },
            {
              name: "BARANGAY 155",
            },
            {
              name: "BARANGAY 156",
            },
            {
              name: "BARANGAY 157",
            },
            {
              name: "BARANGAY 158",
            },
            {
              name: "BARANGAY 159",
            },
            {
              name: "BARANGAY 16",
            },
            {
              name: "BARANGAY 160",
            },
            {
              name: "BARANGAY 161",
            },
            {
              name: "BARANGAY 162",
            },
            {
              name: "BARANGAY 163",
            },
            {
              name: "BARANGAY 164",
            },
            {
              name: "BARANGAY 165",
            },
            {
              name: "BARANGAY 166",
            },
            {
              name: "BARANGAY 167",
            },
            {
              name: "BARANGAY 168",
            },
            {
              name: "BARANGAY 169",
            },
            {
              name: "BARANGAY 17",
            },
            {
              name: "BARANGAY 170",
            },
            {
              name: "BARANGAY 171",
            },
            {
              name: "BARANGAY 172",
            },
            {
              name: "BARANGAY 173",
            },
            {
              name: "BARANGAY 174",
            },
            {
              name: "BARANGAY 175",
            },
            {
              name: "BARANGAY 176",
            },
            {
              name: "BARANGAY 177",
            },
            {
              name: "BARANGAY 178",
            },
            {
              name: "BARANGAY 179",
            },
            {
              name: "BARANGAY 18",
            },
            {
              name: "BARANGAY 180",
            },
            {
              name: "BARANGAY 181",
            },
            {
              name: "BARANGAY 182",
            },
            {
              name: "BARANGAY 183",
            },
            {
              name: "BARANGAY 184",
            },
            {
              name: "BARANGAY 185",
            },
            {
              name: "BARANGAY 186",
            },
            {
              name: "BARANGAY 187",
            },
            {
              name: "BARANGAY 188",
            },
            {
              name: "BARANGAY 19",
            },
            {
              name: "BARANGAY 2",
            },
            {
              name: "BARANGAY 20",
            },
            {
              name: "BARANGAY 21",
            },
            {
              name: "BARANGAY 22",
            },
            {
              name: "BARANGAY 23",
            },
            {
              name: "BARANGAY 24",
            },
            {
              name: "BARANGAY 25",
            },
            {
              name: "BARANGAY 26",
            },
            {
              name: "BARANGAY 27",
            },
            {
              name: "BARANGAY 28",
            },
            {
              name: "BARANGAY 29",
            },
            {
              name: "BARANGAY 3",
            },
            {
              name: "BARANGAY 30",
            },
            {
              name: "BARANGAY 31",
            },
            {
              name: "BARANGAY 32",
            },
            {
              name: "BARANGAY 33",
            },
            {
              name: "BARANGAY 34",
            },
            {
              name: "BARANGAY 35",
            },
            {
              name: "BARANGAY 36",
            },
            {
              name: "BARANGAY 37",
            },
            {
              name: "BARANGAY 38",
            },
            {
              name: "BARANGAY 39",
            },
            {
              name: "BARANGAY 4",
            },
            {
              name: "BARANGAY 40",
            },
            {
              name: "BARANGAY 41",
            },
            {
              name: "BARANGAY 42",
            },
            {
              name: "BARANGAY 43",
            },
            {
              name: "BARANGAY 44",
            },
            {
              name: "BARANGAY 45",
            },
            {
              name: "BARANGAY 46",
            },
            {
              name: "BARANGAY 47",
            },
            {
              name: "BARANGAY 48",
            },
            {
              name: "BARANGAY 49",
            },
            {
              name: "BARANGAY 5",
            },
            {
              name: "BARANGAY 50",
            },
            {
              name: "BARANGAY 51",
            },
            {
              name: "BARANGAY 52",
            },
            {
              name: "BARANGAY 53",
            },
            {
              name: "BARANGAY 54",
            },
            {
              name: "BARANGAY 55",
            },
            {
              name: "BARANGAY 56",
            },
            {
              name: "BARANGAY 57",
            },
            {
              name: "BARANGAY 58",
            },
            {
              name: "BARANGAY 59",
            },
            {
              name: "BARANGAY 6",
            },
            {
              name: "BARANGAY 60",
            },
            {
              name: "BARANGAY 61",
            },
            {
              name: "BARANGAY 62",
            },
            {
              name: "BARANGAY 63",
            },
            {
              name: "BARANGAY 64",
            },
            {
              name: "BARANGAY 65",
            },
            {
              name: "BARANGAY 66",
            },
            {
              name: "BARANGAY 67",
            },
            {
              name: "BARANGAY 68",
            },
            {
              name: "BARANGAY 69",
            },
            {
              name: "BARANGAY 7",
            },
            {
              name: "BARANGAY 70",
            },
            {
              name: "BARANGAY 71",
            },
            {
              name: "BARANGAY 72",
            },
            {
              name: "BARANGAY 73",
            },
            {
              name: "BARANGAY 74",
            },
            {
              name: "BARANGAY 75",
            },
            {
              name: "BARANGAY 76",
            },
            {
              name: "BARANGAY 77",
            },
            {
              name: "BARANGAY 78",
            },
            {
              name: "BARANGAY 79",
            },
            {
              name: "BARANGAY 8",
            },
            {
              name: "BARANGAY 80",
            },
            {
              name: "BARANGAY 81",
            },
            {
              name: "BARANGAY 82",
            },
            {
              name: "BARANGAY 83",
            },
            {
              name: "BARANGAY 84",
            },
            {
              name: "BARANGAY 85",
            },
            {
              name: "BARANGAY 86",
            },
            {
              name: "BARANGAY 87",
            },
            {
              name: "BARANGAY 88",
            },
            {
              name: "BARANGAY 89",
            },
            {
              name: "BARANGAY 9",
            },
            {
              name: "BARANGAY 90",
            },
            {
              name: "BARANGAY 91",
            },
            {
              name: "BARANGAY 92",
            },
            {
              name: "BARANGAY 93",
            },
            {
              name: "BARANGAY 94",
            },
            {
              name: "BARANGAY 95",
            },
            {
              name: "BARANGAY 96",
            },
            {
              name: "BARANGAY 97",
            },
            {
              name: "BARANGAY 98",
            },
            {
              name: "BARANGAY 99",
            },
          ],
        },
        "LAS PIÑAS CITY": {
          name: "LAS PIÑAS CITY",
          baranggays: [
            {
              name: "ALMANZA DOS",
            },
            {
              name: "ALMANZA UNO",
            },
            {
              name: "B. F. INTERNATIONAL VILLAGE",
            },
            {
              name: "DANIEL FAJARDO",
            },
            {
              name: "ELIAS ALDANA",
            },
            {
              name: "ILAYA",
            },
            {
              name: "MANUYO DOS",
            },
            {
              name: "MANUYO UNO",
            },
            {
              name: "PAMPLONA DOS",
            },
            {
              name: "PAMPLONA TRES",
            },
            {
              name: "PAMPLONA UNO",
            },
            {
              name: "PILAR",
            },
            {
              name: "PULANG LUPA DOS",
            },
            {
              name: "PULANG LUPA UNO",
            },
            {
              name: "TALON DOS",
            },
            {
              name: "TALON KUATRO",
            },
            {
              name: "TALON SINGKO",
            },
            {
              name: "TALON TRES",
            },
            {
              name: "TALON UNO",
            },
            {
              name: "ZAPOTE",
            },
          ],
        },
        "MAKATI CITY": {
          name: "MAKATI CITY",
          baranggays: [
            {
              name: "BANGKAL",
            },
            {
              name: "BEL-AIR",
            },
            {
              name: "CARMONA",
            },
            {
              name: "CEMBO",
            },
            {
              name: "COMEMBO",
            },
            {
              name: "DASMARINAS",
            },
            {
              name: "EAST REMBO",
            },
            {
              name: "FORBES PARK",
            },
            {
              name: "GUADALUPE NUEVO",
            },
            {
              name: "GUADALUPE VIEJO",
            },
            {
              name: "KASILAWAN",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "OLYMPIA",
            },
            {
              name: "PALANAN",
            },
            {
              name: "PEMBO",
            },
            {
              name: "PINAGKAISAHAN",
            },
            {
              name: "PIO DEL PILAR",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POST PROPER NORTHSIDE",
            },
            {
              name: "POST PROPER SOUTHSIDE",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SINGKAMAS",
            },
            {
              name: "SOUTH CEMBO",
            },
            {
              name: "TEJEROS",
            },
            {
              name: "URDANETA",
            },
            {
              name: "VALENZUELA",
            },
            {
              name: "WEST REMBO",
            },
          ],
        },
        "MALABON CITY": {
          name: "MALABON CITY",
          baranggays: [
            {
              name: "ACACIA",
            },
            {
              name: "BARITAN",
            },
            {
              name: "BAYAN-BAYANAN",
            },
            {
              name: "CATMON",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAMPALIT",
            },
            {
              name: "FLORES",
            },
            {
              name: "HULONG DUHAT",
            },
            {
              name: "IBABA",
            },
            {
              name: "LONGOS",
            },
            {
              name: "MAYSILO",
            },
            {
              name: "MUZON",
            },
            {
              name: "NIUGAN",
            },
            {
              name: "PANGHULO",
            },
            {
              name: "POTRERO",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SANTOLAN",
            },
            {
              name: "TANONG",
            },
            {
              name: "TINAJEROS",
            },
            {
              name: "TONSUYA",
            },
            {
              name: "TUGATOG",
            },
          ],
        },
        "MANDALUYONG CITY": {
          name: "MANDALUYONG CITY",
          baranggays: [
            {
              name: "ADDITION HILLS",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BARANGKA DRIVE",
            },
            {
              name: "BARANGKA IBABA",
            },
            {
              name: "BARANGKA ILAYA",
            },
            {
              name: "BARANGKA ITAAS",
            },
            {
              name: "BUAYANG BATO",
            },
            {
              name: "BUROL",
            },
            {
              name: "DAANG BAKAL",
            },
            {
              name: "HAGDANG BATO ITAAS",
            },
            {
              name: "HAGDANG BATO LIBIS",
            },
            {
              name: "HARAPIN ANG BUKAS",
            },
            {
              name: "HIGHWAY HILLS",
            },
            {
              name: "HULO",
            },
            {
              name: "MABINI-J. RIZAL",
            },
            {
              name: "MALAMIG",
            },
            {
              name: "MAUWAY",
            },
            {
              name: "NAMAYAN",
            },
            {
              name: "NEW ZANIGA",
            },
            {
              name: "OLD ZANIGA",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PLAINVIEW",
            },
            {
              name: "PLEASANT HILLS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "VERGARA",
            },
            {
              name: "WACK-WACK GREENHILLS",
            },
          ],
        },
        "MANILA - BINONDO": {
          name: "MANILA - BINONDO",
          baranggays: [
            {
              name: "BARANGAY 287",
            },
            {
              name: "BARANGAY 288",
            },
            {
              name: "BARANGAY 289",
            },
            {
              name: "BARANGAY 290",
            },
            {
              name: "BARANGAY 291",
            },
            {
              name: "BARANGAY 292",
            },
            {
              name: "BARANGAY 293",
            },
            {
              name: "BARANGAY 294",
            },
            {
              name: "BARANGAY 295",
            },
            {
              name: "BARANGAY 296",
            },
          ],
        },
        "MANILA - ERMITA": {
          name: "MANILA - ERMITA",
          baranggays: [
            {
              name: "BARANGAY 659",
            },
            {
              name: "BARANGAY 659-A",
            },
            {
              name: "BARANGAY 660",
            },
            {
              name: "BARANGAY 660-A",
            },
            {
              name: "BARANGAY 661",
            },
            {
              name: "BARANGAY 663",
            },
            {
              name: "BARANGAY 663-A",
            },
            {
              name: "BARANGAY 664",
            },
            {
              name: "BARANGAY 666",
            },
            {
              name: "BARANGAY 667",
            },
            {
              name: "BARANGAY 668",
            },
            {
              name: "BARANGAY 669",
            },
            {
              name: "BARANGAY 670",
            },
          ],
        },
        "MANILA - INTRAMUROS": {
          name: "MANILA - INTRAMUROS",
          baranggays: [
            {
              name: "BARANGAY 654",
            },
            {
              name: "BARANGAY 655",
            },
            {
              name: "BARANGAY 656",
            },
            {
              name: "BARANGAY 657",
            },
            {
              name: "BARANGAY 658",
            },
          ],
        },
        "MANILA - MALATE": {
          name: "MANILA - MALATE",
          baranggays: [
            {
              name: "BARANGAY 688",
            },
            {
              name: "BARANGAY 689",
            },
            {
              name: "BARANGAY 690",
            },
            {
              name: "BARANGAY 691",
            },
            {
              name: "BARANGAY 692",
            },
            {
              name: "BARANGAY 693",
            },
            {
              name: "BARANGAY 694",
            },
            {
              name: "BARANGAY 695",
            },
            {
              name: "BARANGAY 696",
            },
            {
              name: "BARANGAY 697",
            },
            {
              name: "BARANGAY 698",
            },
            {
              name: "BARANGAY 699",
            },
            {
              name: "BARANGAY 700",
            },
            {
              name: "BARANGAY 701",
            },
            {
              name: "BARANGAY 702",
            },
            {
              name: "BARANGAY 703",
            },
            {
              name: "BARANGAY 704",
            },
            {
              name: "BARANGAY 705",
            },
            {
              name: "BARANGAY 706",
            },
            {
              name: "BARANGAY 707",
            },
            {
              name: "BARANGAY 708",
            },
            {
              name: "BARANGAY 709",
            },
            {
              name: "BARANGAY 710",
            },
            {
              name: "BARANGAY 711",
            },
            {
              name: "BARANGAY 712",
            },
            {
              name: "BARANGAY 713",
            },
            {
              name: "BARANGAY 714",
            },
            {
              name: "BARANGAY 715",
            },
            {
              name: "BARANGAY 716",
            },
            {
              name: "BARANGAY 717",
            },
            {
              name: "BARANGAY 718",
            },
            {
              name: "BARANGAY 719",
            },
            {
              name: "BARANGAY 720",
            },
            {
              name: "BARANGAY 721",
            },
            {
              name: "BARANGAY 722",
            },
            {
              name: "BARANGAY 723",
            },
            {
              name: "BARANGAY 724",
            },
            {
              name: "BARANGAY 725",
            },
            {
              name: "BARANGAY 726",
            },
            {
              name: "BARANGAY 727",
            },
            {
              name: "BARANGAY 728",
            },
            {
              name: "BARANGAY 729",
            },
            {
              name: "BARANGAY 730",
            },
            {
              name: "BARANGAY 731",
            },
            {
              name: "BARANGAY 732",
            },
            {
              name: "BARANGAY 733",
            },
            {
              name: "BARANGAY 734",
            },
            {
              name: "BARANGAY 735",
            },
            {
              name: "BARANGAY 736",
            },
            {
              name: "BARANGAY 737",
            },
            {
              name: "BARANGAY 738",
            },
            {
              name: "BARANGAY 739",
            },
            {
              name: "BARANGAY 740",
            },
            {
              name: "BARANGAY 741",
            },
            {
              name: "BARANGAY 742",
            },
            {
              name: "BARANGAY 743",
            },
            {
              name: "BARANGAY 744",
            },
          ],
        },
        "MANILA - PACO": {
          name: "MANILA - PACO",
          baranggays: [
            {
              name: "BARANGAY 662",
            },
            {
              name: "BARANGAY 664-A",
            },
            {
              name: "BARANGAY 671",
            },
            {
              name: "BARANGAY 672",
            },
            {
              name: "BARANGAY 673",
            },
            {
              name: "BARANGAY 674",
            },
            {
              name: "BARANGAY 675",
            },
            {
              name: "BARANGAY 676",
            },
            {
              name: "BARANGAY 677",
            },
            {
              name: "BARANGAY 678",
            },
            {
              name: "BARANGAY 679",
            },
            {
              name: "BARANGAY 680",
            },
            {
              name: "BARANGAY 681",
            },
            {
              name: "BARANGAY 682",
            },
            {
              name: "BARANGAY 683",
            },
            {
              name: "BARANGAY 684",
            },
            {
              name: "BARANGAY 685",
            },
            {
              name: "BARANGAY 686",
            },
            {
              name: "BARANGAY 687",
            },
            {
              name: "BARANGAY 809",
            },
            {
              name: "BARANGAY 810",
            },
            {
              name: "BARANGAY 811",
            },
            {
              name: "BARANGAY 812",
            },
            {
              name: "BARANGAY 813",
            },
            {
              name: "BARANGAY 814",
            },
            {
              name: "BARANGAY 815",
            },
            {
              name: "BARANGAY 816",
            },
            {
              name: "BARANGAY 817",
            },
            {
              name: "BARANGAY 818",
            },
            {
              name: "BARANGAY 819",
            },
            {
              name: "BARANGAY 820",
            },
            {
              name: "BARANGAY 821",
            },
            {
              name: "BARANGAY 822",
            },
            {
              name: "BARANGAY 823",
            },
            {
              name: "BARANGAY 824",
            },
            {
              name: "BARANGAY 825",
            },
            {
              name: "BARANGAY 826",
            },
            {
              name: "BARANGAY 827",
            },
            {
              name: "BARANGAY 828",
            },
            {
              name: "BARANGAY 829",
            },
            {
              name: "BARANGAY 830",
            },
            {
              name: "BARANGAY 831",
            },
            {
              name: "BARANGAY 832",
            },
          ],
        },
        "MANILA - PANDACAN": {
          name: "MANILA - PANDACAN",
          baranggays: [
            {
              name: "BARANGAY 833",
            },
            {
              name: "BARANGAY 834",
            },
            {
              name: "BARANGAY 835",
            },
            {
              name: "BARANGAY 836",
            },
            {
              name: "BARANGAY 837",
            },
            {
              name: "BARANGAY 838",
            },
            {
              name: "BARANGAY 839",
            },
            {
              name: "BARANGAY 840",
            },
            {
              name: "BARANGAY 841",
            },
            {
              name: "BARANGAY 842",
            },
            {
              name: "BARANGAY 843",
            },
            {
              name: "BARANGAY 844",
            },
            {
              name: "BARANGAY 845",
            },
            {
              name: "BARANGAY 846",
            },
            {
              name: "BARANGAY 847",
            },
            {
              name: "BARANGAY 848",
            },
            {
              name: "BARANGAY 849",
            },
            {
              name: "BARANGAY 850",
            },
            {
              name: "BARANGAY 851",
            },
            {
              name: "BARANGAY 852",
            },
            {
              name: "BARANGAY 853",
            },
            {
              name: "BARANGAY 855",
            },
            {
              name: "BARANGAY 856",
            },
            {
              name: "BARANGAY 857",
            },
            {
              name: "BARANGAY 858",
            },
            {
              name: "BARANGAY 859",
            },
            {
              name: "BARANGAY 860",
            },
            {
              name: "BARANGAY 861",
            },
            {
              name: "BARANGAY 862",
            },
            {
              name: "BARANGAY 863",
            },
            {
              name: "BARANGAY 864",
            },
            {
              name: "BARANGAY 865",
            },
            {
              name: "BARANGAY 867",
            },
            {
              name: "BARANGAY 868",
            },
            {
              name: "BARANGAY 869",
            },
            {
              name: "BARANGAY 870",
            },
            {
              name: "BARANGAY 871",
            },
            {
              name: "BARANGAY 872",
            },
          ],
        },
        "MANILA - PORT AREA": {
          name: "MANILA - PORT AREA",
          baranggays: [
            {
              name: "BARANGAY 649",
            },
            {
              name: "BARANGAY 650",
            },
            {
              name: "BARANGAY 651",
            },
            {
              name: "BARANGAY 652",
            },
            {
              name: "BARANGAY 653",
            },
          ],
        },
        "MANILA - QUIAPO": {
          name: "MANILA - QUIAPO",
          baranggays: [
            {
              name: "BARANGAY 306",
            },
            {
              name: "BARANGAY 307",
            },
            {
              name: "BARANGAY 308",
            },
            {
              name: "BARANGAY 309",
            },
            {
              name: "BARANGAY 383",
            },
            {
              name: "BARANGAY 384",
            },
            {
              name: "BARANGAY 385",
            },
            {
              name: "BARANGAY 386",
            },
            {
              name: "BARANGAY 387",
            },
            {
              name: "BARANGAY 388",
            },
            {
              name: "BARANGAY 389",
            },
            {
              name: "BARANGAY 390",
            },
            {
              name: "BARANGAY 391",
            },
            {
              name: "BARANGAY 392",
            },
            {
              name: "BARANGAY 393",
            },
            {
              name: "BARANGAY 394",
            },
          ],
        },
        "MANILA - SAMPALOC": {
          name: "MANILA - SAMPALOC",
          baranggays: [
            {
              name: "BARANGAY 395",
            },
            {
              name: "BARANGAY 396",
            },
            {
              name: "BARANGAY 397",
            },
            {
              name: "BARANGAY 398",
            },
            {
              name: "BARANGAY 399",
            },
            {
              name: "BARANGAY 400",
            },
            {
              name: "BARANGAY 401",
            },
            {
              name: "BARANGAY 402",
            },
            {
              name: "BARANGAY 403",
            },
            {
              name: "BARANGAY 404",
            },
            {
              name: "BARANGAY 405",
            },
            {
              name: "BARANGAY 406",
            },
            {
              name: "BARANGAY 407",
            },
            {
              name: "BARANGAY 408",
            },
            {
              name: "BARANGAY 409",
            },
            {
              name: "BARANGAY 410",
            },
            {
              name: "BARANGAY 411",
            },
            {
              name: "BARANGAY 412",
            },
            {
              name: "BARANGAY 413",
            },
            {
              name: "BARANGAY 414",
            },
            {
              name: "BARANGAY 415",
            },
            {
              name: "BARANGAY 416",
            },
            {
              name: "BARANGAY 417",
            },
            {
              name: "BARANGAY 418",
            },
            {
              name: "BARANGAY 419",
            },
            {
              name: "BARANGAY 420",
            },
            {
              name: "BARANGAY 421",
            },
            {
              name: "BARANGAY 422",
            },
            {
              name: "BARANGAY 423",
            },
            {
              name: "BARANGAY 424",
            },
            {
              name: "BARANGAY 425",
            },
            {
              name: "BARANGAY 426",
            },
            {
              name: "BARANGAY 427",
            },
            {
              name: "BARANGAY 428",
            },
            {
              name: "BARANGAY 429",
            },
            {
              name: "BARANGAY 430",
            },
            {
              name: "BARANGAY 431",
            },
            {
              name: "BARANGAY 432",
            },
            {
              name: "BARANGAY 433",
            },
            {
              name: "BARANGAY 434",
            },
            {
              name: "BARANGAY 435",
            },
            {
              name: "BARANGAY 436",
            },
            {
              name: "BARANGAY 437",
            },
            {
              name: "BARANGAY 438",
            },
            {
              name: "BARANGAY 439",
            },
            {
              name: "BARANGAY 440",
            },
            {
              name: "BARANGAY 441",
            },
            {
              name: "BARANGAY 442",
            },
            {
              name: "BARANGAY 443",
            },
            {
              name: "BARANGAY 444",
            },
            {
              name: "BARANGAY 445",
            },
            {
              name: "BARANGAY 446",
            },
            {
              name: "BARANGAY 447",
            },
            {
              name: "BARANGAY 448",
            },
            {
              name: "BARANGAY 449",
            },
            {
              name: "BARANGAY 450",
            },
            {
              name: "BARANGAY 451",
            },
            {
              name: "BARANGAY 452",
            },
            {
              name: "BARANGAY 453",
            },
            {
              name: "BARANGAY 454",
            },
            {
              name: "BARANGAY 455",
            },
            {
              name: "BARANGAY 456",
            },
            {
              name: "BARANGAY 457",
            },
            {
              name: "BARANGAY 458",
            },
            {
              name: "BARANGAY 459",
            },
            {
              name: "BARANGAY 460",
            },
            {
              name: "BARANGAY 461",
            },
            {
              name: "BARANGAY 462",
            },
            {
              name: "BARANGAY 463",
            },
            {
              name: "BARANGAY 464",
            },
            {
              name: "BARANGAY 465",
            },
            {
              name: "BARANGAY 466",
            },
            {
              name: "BARANGAY 467",
            },
            {
              name: "BARANGAY 468",
            },
            {
              name: "BARANGAY 469",
            },
            {
              name: "BARANGAY 470",
            },
            {
              name: "BARANGAY 471",
            },
            {
              name: "BARANGAY 472",
            },
            {
              name: "BARANGAY 473",
            },
            {
              name: "BARANGAY 474",
            },
            {
              name: "BARANGAY 475",
            },
            {
              name: "BARANGAY 476",
            },
            {
              name: "BARANGAY 477",
            },
            {
              name: "BARANGAY 478",
            },
            {
              name: "BARANGAY 479",
            },
            {
              name: "BARANGAY 480",
            },
            {
              name: "BARANGAY 481",
            },
            {
              name: "BARANGAY 482",
            },
            {
              name: "BARANGAY 483",
            },
            {
              name: "BARANGAY 484",
            },
            {
              name: "BARANGAY 485",
            },
            {
              name: "BARANGAY 486",
            },
            {
              name: "BARANGAY 487",
            },
            {
              name: "BARANGAY 488",
            },
            {
              name: "BARANGAY 489",
            },
            {
              name: "BARANGAY 490",
            },
            {
              name: "BARANGAY 491",
            },
            {
              name: "BARANGAY 492",
            },
            {
              name: "BARANGAY 493",
            },
            {
              name: "BARANGAY 494",
            },
            {
              name: "BARANGAY 495",
            },
            {
              name: "BARANGAY 496",
            },
            {
              name: "BARANGAY 497",
            },
            {
              name: "BARANGAY 498",
            },
            {
              name: "BARANGAY 499",
            },
            {
              name: "BARANGAY 500",
            },
            {
              name: "BARANGAY 501",
            },
            {
              name: "BARANGAY 502",
            },
            {
              name: "BARANGAY 503",
            },
            {
              name: "BARANGAY 504",
            },
            {
              name: "BARANGAY 505",
            },
            {
              name: "BARANGAY 506",
            },
            {
              name: "BARANGAY 507",
            },
            {
              name: "BARANGAY 508",
            },
            {
              name: "BARANGAY 509",
            },
            {
              name: "BARANGAY 510",
            },
            {
              name: "BARANGAY 511",
            },
            {
              name: "BARANGAY 512",
            },
            {
              name: "BARANGAY 513",
            },
            {
              name: "BARANGAY 514",
            },
            {
              name: "BARANGAY 515",
            },
            {
              name: "BARANGAY 516",
            },
            {
              name: "BARANGAY 517",
            },
            {
              name: "BARANGAY 518",
            },
            {
              name: "BARANGAY 519",
            },
            {
              name: "BARANGAY 520",
            },
            {
              name: "BARANGAY 521",
            },
            {
              name: "BARANGAY 522",
            },
            {
              name: "BARANGAY 523",
            },
            {
              name: "BARANGAY 524",
            },
            {
              name: "BARANGAY 525",
            },
            {
              name: "BARANGAY 526",
            },
            {
              name: "BARANGAY 527",
            },
            {
              name: "BARANGAY 528",
            },
            {
              name: "BARANGAY 529",
            },
            {
              name: "BARANGAY 530",
            },
            {
              name: "BARANGAY 531",
            },
            {
              name: "BARANGAY 532",
            },
            {
              name: "BARANGAY 533",
            },
            {
              name: "BARANGAY 534",
            },
            {
              name: "BARANGAY 535",
            },
            {
              name: "BARANGAY 536",
            },
            {
              name: "BARANGAY 537",
            },
            {
              name: "BARANGAY 538",
            },
            {
              name: "BARANGAY 539",
            },
            {
              name: "BARANGAY 540",
            },
            {
              name: "BARANGAY 541",
            },
            {
              name: "BARANGAY 542",
            },
            {
              name: "BARANGAY 543",
            },
            {
              name: "BARANGAY 544",
            },
            {
              name: "BARANGAY 545",
            },
            {
              name: "BARANGAY 546",
            },
            {
              name: "BARANGAY 547",
            },
            {
              name: "BARANGAY 548",
            },
            {
              name: "BARANGAY 549",
            },
            {
              name: "BARANGAY 550",
            },
            {
              name: "BARANGAY 551",
            },
            {
              name: "BARANGAY 552",
            },
            {
              name: "BARANGAY 553",
            },
            {
              name: "BARANGAY 554",
            },
            {
              name: "BARANGAY 555",
            },
            {
              name: "BARANGAY 556",
            },
            {
              name: "BARANGAY 557",
            },
            {
              name: "BARANGAY 558",
            },
            {
              name: "BARANGAY 559",
            },
            {
              name: "BARANGAY 560",
            },
            {
              name: "BARANGAY 561",
            },
            {
              name: "BARANGAY 562",
            },
            {
              name: "BARANGAY 563",
            },
            {
              name: "BARANGAY 564",
            },
            {
              name: "BARANGAY 565",
            },
            {
              name: "BARANGAY 566",
            },
            {
              name: "BARANGAY 567",
            },
            {
              name: "BARANGAY 568",
            },
            {
              name: "BARANGAY 569",
            },
            {
              name: "BARANGAY 570",
            },
            {
              name: "BARANGAY 571",
            },
            {
              name: "BARANGAY 572",
            },
            {
              name: "BARANGAY 573",
            },
            {
              name: "BARANGAY 574",
            },
            {
              name: "BARANGAY 575",
            },
            {
              name: "BARANGAY 576",
            },
            {
              name: "BARANGAY 577",
            },
            {
              name: "BARANGAY 578",
            },
            {
              name: "BARANGAY 579",
            },
            {
              name: "BARANGAY 580",
            },
            {
              name: "BARANGAY 581",
            },
            {
              name: "BARANGAY 582",
            },
            {
              name: "BARANGAY 583",
            },
            {
              name: "BARANGAY 584",
            },
            {
              name: "BARANGAY 585",
            },
            {
              name: "BARANGAY 586",
            },
            {
              name: "BARANGAY 587",
            },
            {
              name: "BARANGAY 587-A",
            },
            {
              name: "BARANGAY 588",
            },
            {
              name: "BARANGAY 589",
            },
            {
              name: "BARANGAY 590",
            },
            {
              name: "BARANGAY 591",
            },
            {
              name: "BARANGAY 592",
            },
            {
              name: "BARANGAY 593",
            },
            {
              name: "BARANGAY 594",
            },
            {
              name: "BARANGAY 595",
            },
            {
              name: "BARANGAY 596",
            },
            {
              name: "BARANGAY 597",
            },
            {
              name: "BARANGAY 598",
            },
            {
              name: "BARANGAY 599",
            },
            {
              name: "BARANGAY 600",
            },
            {
              name: "BARANGAY 601",
            },
            {
              name: "BARANGAY 602",
            },
            {
              name: "BARANGAY 603",
            },
            {
              name: "BARANGAY 604",
            },
            {
              name: "BARANGAY 605",
            },
            {
              name: "BARANGAY 606",
            },
            {
              name: "BARANGAY 607",
            },
            {
              name: "BARANGAY 608",
            },
            {
              name: "BARANGAY 609",
            },
            {
              name: "BARANGAY 610",
            },
            {
              name: "BARANGAY 611",
            },
            {
              name: "BARANGAY 612",
            },
            {
              name: "BARANGAY 613",
            },
            {
              name: "BARANGAY 614",
            },
            {
              name: "BARANGAY 615",
            },
            {
              name: "BARANGAY 616",
            },
            {
              name: "BARANGAY 617",
            },
            {
              name: "BARANGAY 618",
            },
            {
              name: "BARANGAY 619",
            },
            {
              name: "BARANGAY 620",
            },
            {
              name: "BARANGAY 621",
            },
            {
              name: "BARANGAY 622",
            },
            {
              name: "BARANGAY 623",
            },
            {
              name: "BARANGAY 624",
            },
            {
              name: "BARANGAY 625",
            },
            {
              name: "BARANGAY 626",
            },
            {
              name: "BARANGAY 627",
            },
            {
              name: "BARANGAY 628",
            },
            {
              name: "BARANGAY 629",
            },
            {
              name: "BARANGAY 630",
            },
            {
              name: "BARANGAY 631",
            },
            {
              name: "BARANGAY 632",
            },
            {
              name: "BARANGAY 633",
            },
            {
              name: "BARANGAY 634",
            },
            {
              name: "BARANGAY 635",
            },
            {
              name: "BARANGAY 636",
            },
          ],
        },
        "MANILA - SAN MIGUEL": {
          name: "MANILA - SAN MIGUEL",
          baranggays: [
            {
              name: "BARANGAY 637",
            },
            {
              name: "BARANGAY 638",
            },
            {
              name: "BARANGAY 639",
            },
            {
              name: "BARANGAY 640",
            },
            {
              name: "BARANGAY 641",
            },
            {
              name: "BARANGAY 642",
            },
            {
              name: "BARANGAY 643",
            },
            {
              name: "BARANGAY 644",
            },
            {
              name: "BARANGAY 645",
            },
            {
              name: "BARANGAY 646",
            },
            {
              name: "BARANGAY 647",
            },
            {
              name: "BARANGAY 648",
            },
          ],
        },
        "MANILA - SAN NICOLAS": {
          name: "MANILA - SAN NICOLAS",
          baranggays: [
            {
              name: "BARANGAY 268",
            },
            {
              name: "BARANGAY 269",
            },
            {
              name: "BARANGAY 270",
            },
            {
              name: "BARANGAY 271",
            },
            {
              name: "BARANGAY 272",
            },
            {
              name: "BARANGAY 273",
            },
            {
              name: "BARANGAY 274",
            },
            {
              name: "BARANGAY 275",
            },
            {
              name: "BARANGAY 276",
            },
            {
              name: "BARANGAY 281",
            },
            {
              name: "BARANGAY 282",
            },
            {
              name: "BARANGAY 283",
            },
            {
              name: "BARANGAY 284",
            },
            {
              name: "BARANGAY 285",
            },
            {
              name: "BARANGAY 286",
            },
          ],
        },
        "MANILA - SANTA ANA": {
          name: "MANILA - SANTA ANA",
          baranggays: [
            {
              name: "BARANGAY 745",
            },
            {
              name: "BARANGAY 746",
            },
            {
              name: "BARANGAY 747",
            },
            {
              name: "BARANGAY 748",
            },
            {
              name: "BARANGAY 749",
            },
            {
              name: "BARANGAY 750",
            },
            {
              name: "BARANGAY 751",
            },
            {
              name: "BARANGAY 752",
            },
            {
              name: "BARANGAY 753",
            },
            {
              name: "BARANGAY 754",
            },
            {
              name: "BARANGAY 755",
            },
            {
              name: "BARANGAY 756",
            },
            {
              name: "BARANGAY 757",
            },
            {
              name: "BARANGAY 758",
            },
            {
              name: "BARANGAY 759",
            },
            {
              name: "BARANGAY 760",
            },
            {
              name: "BARANGAY 761",
            },
            {
              name: "BARANGAY 762",
            },
            {
              name: "BARANGAY 763",
            },
            {
              name: "BARANGAY 764",
            },
            {
              name: "BARANGAY 765",
            },
            {
              name: "BARANGAY 766",
            },
            {
              name: "BARANGAY 767",
            },
            {
              name: "BARANGAY 768",
            },
            {
              name: "BARANGAY 769",
            },
            {
              name: "BARANGAY 770",
            },
            {
              name: "BARANGAY 771",
            },
            {
              name: "BARANGAY 772",
            },
            {
              name: "BARANGAY 773",
            },
            {
              name: "BARANGAY 774",
            },
            {
              name: "BARANGAY 775",
            },
            {
              name: "BARANGAY 776",
            },
            {
              name: "BARANGAY 777",
            },
            {
              name: "BARANGAY 778",
            },
            {
              name: "BARANGAY 779",
            },
            {
              name: "BARANGAY 780",
            },
            {
              name: "BARANGAY 781",
            },
            {
              name: "BARANGAY 782",
            },
            {
              name: "BARANGAY 783",
            },
            {
              name: "BARANGAY 784",
            },
            {
              name: "BARANGAY 785",
            },
            {
              name: "BARANGAY 786",
            },
            {
              name: "BARANGAY 787",
            },
            {
              name: "BARANGAY 788",
            },
            {
              name: "BARANGAY 789",
            },
            {
              name: "BARANGAY 790",
            },
            {
              name: "BARANGAY 791",
            },
            {
              name: "BARANGAY 792",
            },
            {
              name: "BARANGAY 793",
            },
            {
              name: "BARANGAY 794",
            },
            {
              name: "BARANGAY 795",
            },
            {
              name: "BARANGAY 796",
            },
            {
              name: "BARANGAY 797",
            },
            {
              name: "BARANGAY 798",
            },
            {
              name: "BARANGAY 799",
            },
            {
              name: "BARANGAY 800",
            },
            {
              name: "BARANGAY 801",
            },
            {
              name: "BARANGAY 802",
            },
            {
              name: "BARANGAY 803",
            },
            {
              name: "BARANGAY 804",
            },
            {
              name: "BARANGAY 805",
            },
            {
              name: "BARANGAY 806",
            },
            {
              name: "BARANGAY 807",
            },
            {
              name: "BARANGAY 808",
            },
            {
              name: "BARANGAY 818-A",
            },
            {
              name: "BARANGAY 866",
            },
            {
              name: "BARANGAY 873",
            },
            {
              name: "BARANGAY 874",
            },
            {
              name: "BARANGAY 875",
            },
            {
              name: "BARANGAY 876",
            },
            {
              name: "BARANGAY 877",
            },
            {
              name: "BARANGAY 878",
            },
            {
              name: "BARANGAY 879",
            },
            {
              name: "BARANGAY 880",
            },
            {
              name: "BARANGAY 881",
            },
            {
              name: "BARANGAY 882",
            },
            {
              name: "BARANGAY 883",
            },
            {
              name: "BARANGAY 884",
            },
            {
              name: "BARANGAY 885",
            },
            {
              name: "BARANGAY 886",
            },
            {
              name: "BARANGAY 887",
            },
            {
              name: "BARANGAY 888",
            },
            {
              name: "BARANGAY 889",
            },
            {
              name: "BARANGAY 890",
            },
            {
              name: "BARANGAY 891",
            },
            {
              name: "BARANGAY 892",
            },
            {
              name: "BARANGAY 893",
            },
            {
              name: "BARANGAY 894",
            },
            {
              name: "BARANGAY 895",
            },
            {
              name: "BARANGAY 896",
            },
            {
              name: "BARANGAY 897",
            },
            {
              name: "BARANGAY 898",
            },
            {
              name: "BARANGAY 899",
            },
            {
              name: "BARANGAY 900",
            },
            {
              name: "BARANGAY 901",
            },
            {
              name: "BARANGAY 902",
            },
            {
              name: "BARANGAY 903",
            },
            {
              name: "BARANGAY 904",
            },
            {
              name: "BARANGAY 905",
            },
          ],
        },
        "MANILA - SANTA CRUZ": {
          name: "MANILA - SANTA CRUZ",
          baranggays: [
            {
              name: "BARANGAY 297",
            },
            {
              name: "BARANGAY 298",
            },
            {
              name: "BARANGAY 299",
            },
            {
              name: "BARANGAY 300",
            },
            {
              name: "BARANGAY 301",
            },
            {
              name: "BARANGAY 302",
            },
            {
              name: "BARANGAY 303",
            },
            {
              name: "BARANGAY 304",
            },
            {
              name: "BARANGAY 305",
            },
            {
              name: "BARANGAY 310",
            },
            {
              name: "BARANGAY 311",
            },
            {
              name: "BARANGAY 312",
            },
            {
              name: "BARANGAY 313",
            },
            {
              name: "BARANGAY 314",
            },
            {
              name: "BARANGAY 315",
            },
            {
              name: "BARANGAY 316",
            },
            {
              name: "BARANGAY 317",
            },
            {
              name: "BARANGAY 318",
            },
            {
              name: "BARANGAY 319",
            },
            {
              name: "BARANGAY 320",
            },
            {
              name: "BARANGAY 321",
            },
            {
              name: "BARANGAY 322",
            },
            {
              name: "BARANGAY 323",
            },
            {
              name: "BARANGAY 324",
            },
            {
              name: "BARANGAY 325",
            },
            {
              name: "BARANGAY 326",
            },
            {
              name: "BARANGAY 327",
            },
            {
              name: "BARANGAY 328",
            },
            {
              name: "BARANGAY 329",
            },
            {
              name: "BARANGAY 330",
            },
            {
              name: "BARANGAY 331",
            },
            {
              name: "BARANGAY 332",
            },
            {
              name: "BARANGAY 333",
            },
            {
              name: "BARANGAY 334",
            },
            {
              name: "BARANGAY 335",
            },
            {
              name: "BARANGAY 336",
            },
            {
              name: "BARANGAY 337",
            },
            {
              name: "BARANGAY 338",
            },
            {
              name: "BARANGAY 339",
            },
            {
              name: "BARANGAY 340",
            },
            {
              name: "BARANGAY 341",
            },
            {
              name: "BARANGAY 342",
            },
            {
              name: "BARANGAY 343",
            },
            {
              name: "BARANGAY 344",
            },
            {
              name: "BARANGAY 345",
            },
            {
              name: "BARANGAY 346",
            },
            {
              name: "BARANGAY 347",
            },
            {
              name: "BARANGAY 348",
            },
            {
              name: "BARANGAY 349",
            },
            {
              name: "BARANGAY 350",
            },
            {
              name: "BARANGAY 351",
            },
            {
              name: "BARANGAY 352",
            },
            {
              name: "BARANGAY 353",
            },
            {
              name: "BARANGAY 354",
            },
            {
              name: "BARANGAY 355",
            },
            {
              name: "BARANGAY 356",
            },
            {
              name: "BARANGAY 357",
            },
            {
              name: "BARANGAY 358",
            },
            {
              name: "BARANGAY 359",
            },
            {
              name: "BARANGAY 360",
            },
            {
              name: "BARANGAY 361",
            },
            {
              name: "BARANGAY 362",
            },
            {
              name: "BARANGAY 363",
            },
            {
              name: "BARANGAY 364",
            },
            {
              name: "BARANGAY 365",
            },
            {
              name: "BARANGAY 366",
            },
            {
              name: "BARANGAY 367",
            },
            {
              name: "BARANGAY 368",
            },
            {
              name: "BARANGAY 369",
            },
            {
              name: "BARANGAY 370",
            },
            {
              name: "BARANGAY 371",
            },
            {
              name: "BARANGAY 372",
            },
            {
              name: "BARANGAY 373",
            },
            {
              name: "BARANGAY 374",
            },
            {
              name: "BARANGAY 375",
            },
            {
              name: "BARANGAY 376",
            },
            {
              name: "BARANGAY 377",
            },
            {
              name: "BARANGAY 378",
            },
            {
              name: "BARANGAY 379",
            },
            {
              name: "BARANGAY 380",
            },
            {
              name: "BARANGAY 381",
            },
            {
              name: "BARANGAY 382",
            },
          ],
        },
        "MANILA - TONDO": {
          name: "MANILA - TONDO",
          baranggays: [
            {
              name: "BARANGAY 1",
            },
            {
              name: "BARANGAY 10",
            },
            {
              name: "BARANGAY 100",
            },
            {
              name: "BARANGAY 101",
            },
            {
              name: "BARANGAY 102",
            },
            {
              name: "BARANGAY 103",
            },
            {
              name: "BARANGAY 104",
            },
            {
              name: "BARANGAY 105",
            },
            {
              name: "BARANGAY 106",
            },
            {
              name: "BARANGAY 107",
            },
            {
              name: "BARANGAY 108",
            },
            {
              name: "BARANGAY 109",
            },
            {
              name: "BARANGAY 11",
            },
            {
              name: "BARANGAY 110",
            },
            {
              name: "BARANGAY 111",
            },
            {
              name: "BARANGAY 112",
            },
            {
              name: "BARANGAY 116",
            },
            {
              name: "BARANGAY 117",
            },
            {
              name: "BARANGAY 118",
            },
            {
              name: "BARANGAY 119",
            },
            {
              name: "BARANGAY 12",
            },
            {
              name: "BARANGAY 120",
            },
            {
              name: "BARANGAY 121",
            },
            {
              name: "BARANGAY 122",
            },
            {
              name: "BARANGAY 123",
            },
            {
              name: "BARANGAY 124",
            },
            {
              name: "BARANGAY 125",
            },
            {
              name: "BARANGAY 126",
            },
            {
              name: "BARANGAY 127",
            },
            {
              name: "BARANGAY 128",
            },
            {
              name: "BARANGAY 129",
            },
            {
              name: "BARANGAY 13",
            },
            {
              name: "BARANGAY 130",
            },
            {
              name: "BARANGAY 131",
            },
            {
              name: "BARANGAY 132",
            },
            {
              name: "BARANGAY 133",
            },
            {
              name: "BARANGAY 134",
            },
            {
              name: "BARANGAY 135",
            },
            {
              name: "BARANGAY 136",
            },
            {
              name: "BARANGAY 137",
            },
            {
              name: "BARANGAY 138",
            },
            {
              name: "BARANGAY 139",
            },
            {
              name: "BARANGAY 14",
            },
            {
              name: "BARANGAY 140",
            },
            {
              name: "BARANGAY 141",
            },
            {
              name: "BARANGAY 142",
            },
            {
              name: "BARANGAY 143",
            },
            {
              name: "BARANGAY 144",
            },
            {
              name: "BARANGAY 145",
            },
            {
              name: "BARANGAY 146",
            },
            {
              name: "BARANGAY 147",
            },
            {
              name: "BARANGAY 148",
            },
            {
              name: "BARANGAY 149",
            },
            {
              name: "BARANGAY 15",
            },
            {
              name: "BARANGAY 150",
            },
            {
              name: "BARANGAY 151",
            },
            {
              name: "BARANGAY 152",
            },
            {
              name: "BARANGAY 153",
            },
            {
              name: "BARANGAY 154",
            },
            {
              name: "BARANGAY 155",
            },
            {
              name: "BARANGAY 156",
            },
            {
              name: "BARANGAY 157",
            },
            {
              name: "BARANGAY 158",
            },
            {
              name: "BARANGAY 159",
            },
            {
              name: "BARANGAY 16",
            },
            {
              name: "BARANGAY 160",
            },
            {
              name: "BARANGAY 161",
            },
            {
              name: "BARANGAY 162",
            },
            {
              name: "BARANGAY 163",
            },
            {
              name: "BARANGAY 164",
            },
            {
              name: "BARANGAY 165",
            },
            {
              name: "BARANGAY 166",
            },
            {
              name: "BARANGAY 167",
            },
            {
              name: "BARANGAY 168",
            },
            {
              name: "BARANGAY 169",
            },
            {
              name: "BARANGAY 17",
            },
            {
              name: "BARANGAY 170",
            },
            {
              name: "BARANGAY 171",
            },
            {
              name: "BARANGAY 172",
            },
            {
              name: "BARANGAY 173",
            },
            {
              name: "BARANGAY 174",
            },
            {
              name: "BARANGAY 175",
            },
            {
              name: "BARANGAY 176",
            },
            {
              name: "BARANGAY 177",
            },
            {
              name: "BARANGAY 178",
            },
            {
              name: "BARANGAY 179",
            },
            {
              name: "BARANGAY 18",
            },
            {
              name: "BARANGAY 180",
            },
            {
              name: "BARANGAY 181",
            },
            {
              name: "BARANGAY 182",
            },
            {
              name: "BARANGAY 183",
            },
            {
              name: "BARANGAY 184",
            },
            {
              name: "BARANGAY 185",
            },
            {
              name: "BARANGAY 186",
            },
            {
              name: "BARANGAY 187",
            },
            {
              name: "BARANGAY 188",
            },
            {
              name: "BARANGAY 189",
            },
            {
              name: "BARANGAY 19",
            },
            {
              name: "BARANGAY 190",
            },
            {
              name: "BARANGAY 191",
            },
            {
              name: "BARANGAY 192",
            },
            {
              name: "BARANGAY 193",
            },
            {
              name: "BARANGAY 194",
            },
            {
              name: "BARANGAY 195",
            },
            {
              name: "BARANGAY 196",
            },
            {
              name: "BARANGAY 197",
            },
            {
              name: "BARANGAY 198",
            },
            {
              name: "BARANGAY 199",
            },
            {
              name: "BARANGAY 2",
            },
            {
              name: "BARANGAY 20",
            },
            {
              name: "BARANGAY 200",
            },
            {
              name: "BARANGAY 201",
            },
            {
              name: "BARANGAY 202",
            },
            {
              name: "BARANGAY 202-A",
            },
            {
              name: "BARANGAY 203",
            },
            {
              name: "BARANGAY 204",
            },
            {
              name: "BARANGAY 205",
            },
            {
              name: "BARANGAY 206",
            },
            {
              name: "BARANGAY 207",
            },
            {
              name: "BARANGAY 208",
            },
            {
              name: "BARANGAY 209",
            },
            {
              name: "BARANGAY 210",
            },
            {
              name: "BARANGAY 211",
            },
            {
              name: "BARANGAY 212",
            },
            {
              name: "BARANGAY 213",
            },
            {
              name: "BARANGAY 214",
            },
            {
              name: "BARANGAY 215",
            },
            {
              name: "BARANGAY 216",
            },
            {
              name: "BARANGAY 217",
            },
            {
              name: "BARANGAY 218",
            },
            {
              name: "BARANGAY 219",
            },
            {
              name: "BARANGAY 220",
            },
            {
              name: "BARANGAY 221",
            },
            {
              name: "BARANGAY 222",
            },
            {
              name: "BARANGAY 223",
            },
            {
              name: "BARANGAY 224",
            },
            {
              name: "BARANGAY 225",
            },
            {
              name: "BARANGAY 226",
            },
            {
              name: "BARANGAY 227",
            },
            {
              name: "BARANGAY 228",
            },
            {
              name: "BARANGAY 229",
            },
            {
              name: "BARANGAY 230",
            },
            {
              name: "BARANGAY 231",
            },
            {
              name: "BARANGAY 232",
            },
            {
              name: "BARANGAY 233",
            },
            {
              name: "BARANGAY 234",
            },
            {
              name: "BARANGAY 235",
            },
            {
              name: "BARANGAY 236",
            },
            {
              name: "BARANGAY 237",
            },
            {
              name: "BARANGAY 238",
            },
            {
              name: "BARANGAY 239",
            },
            {
              name: "BARANGAY 240",
            },
            {
              name: "BARANGAY 241",
            },
            {
              name: "BARANGAY 242",
            },
            {
              name: "BARANGAY 243",
            },
            {
              name: "BARANGAY 244",
            },
            {
              name: "BARANGAY 245",
            },
            {
              name: "BARANGAY 246",
            },
            {
              name: "BARANGAY 247",
            },
            {
              name: "BARANGAY 248",
            },
            {
              name: "BARANGAY 249",
            },
            {
              name: "BARANGAY 25",
            },
            {
              name: "BARANGAY 250",
            },
            {
              name: "BARANGAY 251",
            },
            {
              name: "BARANGAY 252",
            },
            {
              name: "BARANGAY 253",
            },
            {
              name: "BARANGAY 254",
            },
            {
              name: "BARANGAY 255",
            },
            {
              name: "BARANGAY 256",
            },
            {
              name: "BARANGAY 257",
            },
            {
              name: "BARANGAY 258",
            },
            {
              name: "BARANGAY 259",
            },
            {
              name: "BARANGAY 26",
            },
            {
              name: "BARANGAY 260",
            },
            {
              name: "BARANGAY 261",
            },
            {
              name: "BARANGAY 262",
            },
            {
              name: "BARANGAY 263",
            },
            {
              name: "BARANGAY 264",
            },
            {
              name: "BARANGAY 265",
            },
            {
              name: "BARANGAY 266",
            },
            {
              name: "BARANGAY 267",
            },
            {
              name: "BARANGAY 28",
            },
            {
              name: "BARANGAY 29",
            },
            {
              name: "BARANGAY 3",
            },
            {
              name: "BARANGAY 30",
            },
            {
              name: "BARANGAY 31",
            },
            {
              name: "BARANGAY 32",
            },
            {
              name: "BARANGAY 33",
            },
            {
              name: "BARANGAY 34",
            },
            {
              name: "BARANGAY 35",
            },
            {
              name: "BARANGAY 36",
            },
            {
              name: "BARANGAY 37",
            },
            {
              name: "BARANGAY 38",
            },
            {
              name: "BARANGAY 39",
            },
            {
              name: "BARANGAY 4",
            },
            {
              name: "BARANGAY 41",
            },
            {
              name: "BARANGAY 42",
            },
            {
              name: "BARANGAY 43",
            },
            {
              name: "BARANGAY 44",
            },
            {
              name: "BARANGAY 45",
            },
            {
              name: "BARANGAY 46",
            },
            {
              name: "BARANGAY 47",
            },
            {
              name: "BARANGAY 48",
            },
            {
              name: "BARANGAY 49",
            },
            {
              name: "BARANGAY 5",
            },
            {
              name: "BARANGAY 50",
            },
            {
              name: "BARANGAY 51",
            },
            {
              name: "BARANGAY 52",
            },
            {
              name: "BARANGAY 53",
            },
            {
              name: "BARANGAY 54",
            },
            {
              name: "BARANGAY 55",
            },
            {
              name: "BARANGAY 56",
            },
            {
              name: "BARANGAY 57",
            },
            {
              name: "BARANGAY 58",
            },
            {
              name: "BARANGAY 59",
            },
            {
              name: "BARANGAY 6",
            },
            {
              name: "BARANGAY 60",
            },
            {
              name: "BARANGAY 61",
            },
            {
              name: "BARANGAY 62",
            },
            {
              name: "BARANGAY 63",
            },
            {
              name: "BARANGAY 64",
            },
            {
              name: "BARANGAY 65",
            },
            {
              name: "BARANGAY 66",
            },
            {
              name: "BARANGAY 67",
            },
            {
              name: "BARANGAY 68",
            },
            {
              name: "BARANGAY 69",
            },
            {
              name: "BARANGAY 7",
            },
            {
              name: "BARANGAY 70",
            },
            {
              name: "BARANGAY 71",
            },
            {
              name: "BARANGAY 72",
            },
            {
              name: "BARANGAY 73",
            },
            {
              name: "BARANGAY 74",
            },
            {
              name: "BARANGAY 75",
            },
            {
              name: "BARANGAY 76",
            },
            {
              name: "BARANGAY 77",
            },
            {
              name: "BARANGAY 78",
            },
            {
              name: "BARANGAY 79",
            },
            {
              name: "BARANGAY 8",
            },
            {
              name: "BARANGAY 80",
            },
            {
              name: "BARANGAY 81",
            },
            {
              name: "BARANGAY 82",
            },
            {
              name: "BARANGAY 83",
            },
            {
              name: "BARANGAY 84",
            },
            {
              name: "BARANGAY 85",
            },
            {
              name: "BARANGAY 86",
            },
            {
              name: "BARANGAY 87",
            },
            {
              name: "BARANGAY 88",
            },
            {
              name: "BARANGAY 89",
            },
            {
              name: "BARANGAY 9",
            },
            {
              name: "BARANGAY 90",
            },
            {
              name: "BARANGAY 91",
            },
            {
              name: "BARANGAY 92",
            },
            {
              name: "BARANGAY 93",
            },
            {
              name: "BARANGAY 94",
            },
            {
              name: "BARANGAY 95",
            },
            {
              name: "BARANGAY 96",
            },
            {
              name: "BARANGAY 97",
            },
            {
              name: "BARANGAY 98",
            },
            {
              name: "BARANGAY 99",
            },
          ],
        },
        "MARIKINA CITY": {
          name: "MARIKINA CITY",
          baranggays: [
            {
              name: "BARANGKA",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CONCEPCION DOS",
            },
            {
              name: "CONCEPCION UNO",
            },
            {
              name: "FORTUNE",
            },
            {
              name: "INDUSTRIAL VALLEY",
            },
            {
              name: "JESUS DE LA PENA",
            },
            {
              name: "MALANDAY",
            },
            {
              name: "MARIKINA HEIGHTS (CONCEPCION)",
            },
            {
              name: "NANGKA",
            },
            {
              name: "PARANG",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTO NINO",
            },
            {
              name: "TANONG",
            },
            {
              name: "TUMANA",
            },
          ],
        },
        "MUNTINLUPA CITY": {
          name: "MUNTINLUPA CITY",
          baranggays: [
            {
              name: "ALABANG",
            },
            {
              name: "AYALA ALABANG",
            },
            {
              name: "BAYANAN",
            },
            {
              name: "BULI",
            },
            {
              name: "CUPANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUTATAN",
            },
            {
              name: "SUCAT",
            },
            {
              name: "TUNASAN",
            },
          ],
        },
        "NAVOTAS CITY": {
          name: "NAVOTAS CITY",
          baranggays: [
            {
              name: "BAGUMBAYAN NORTH",
            },
            {
              name: "BAGUMBAYAN SOUTH",
            },
            {
              name: "BANGCULASI",
            },
            {
              name: "DAANGHARI",
            },
            {
              name: "NAVOTAS EAST",
            },
            {
              name: "NAVOTAS WEST",
            },
            {
              name: "NORTH BAY BLVD.",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL VILLAGE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SIPAC-ALMACEN",
            },
            {
              name: "TANGOS",
            },
            {
              name: "TANZA",
            },
          ],
        },
        "PARAÑAQUE CITY": {
          name: "PARAÑAQUE CITY",
          baranggays: [
            {
              name: "B. F. HOMES",
            },
            {
              name: "BACLARAN",
            },
            {
              name: "DON BOSCO",
            },
            {
              name: "DON GALO",
            },
            {
              name: "LA HUERTA",
            },
            {
              name: "MARCELO GREEN VILLAGE",
            },
            {
              name: "MERVILLE",
            },
            {
              name: "MOONWALK",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN DIONISIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MARTIN DE PORRES",
            },
            {
              name: "SANTO NINO",
            },
            {
              name: "SUN VALLEY",
            },
            {
              name: "TAMBO",
            },
            {
              name: "VITALEZ",
            },
          ],
        },
        "PASAY CITY": {
          name: "PASAY CITY",
          baranggays: [
            {
              name: "BARANGAY 1",
            },
            {
              name: "BARANGAY 10",
            },
            {
              name: "BARANGAY 100",
            },
            {
              name: "BARANGAY 101",
            },
            {
              name: "BARANGAY 102",
            },
            {
              name: "BARANGAY 103",
            },
            {
              name: "BARANGAY 104",
            },
            {
              name: "BARANGAY 105",
            },
            {
              name: "BARANGAY 106",
            },
            {
              name: "BARANGAY 107",
            },
            {
              name: "BARANGAY 108",
            },
            {
              name: "BARANGAY 109",
            },
            {
              name: "BARANGAY 11",
            },
            {
              name: "BARANGAY 110",
            },
            {
              name: "BARANGAY 111",
            },
            {
              name: "BARANGAY 112",
            },
            {
              name: "BARANGAY 113",
            },
            {
              name: "BARANGAY 114",
            },
            {
              name: "BARANGAY 115",
            },
            {
              name: "BARANGAY 116",
            },
            {
              name: "BARANGAY 117",
            },
            {
              name: "BARANGAY 118",
            },
            {
              name: "BARANGAY 119",
            },
            {
              name: "BARANGAY 12",
            },
            {
              name: "BARANGAY 120",
            },
            {
              name: "BARANGAY 121",
            },
            {
              name: "BARANGAY 122",
            },
            {
              name: "BARANGAY 123",
            },
            {
              name: "BARANGAY 124",
            },
            {
              name: "BARANGAY 125",
            },
            {
              name: "BARANGAY 126",
            },
            {
              name: "BARANGAY 127",
            },
            {
              name: "BARANGAY 128",
            },
            {
              name: "BARANGAY 129",
            },
            {
              name: "BARANGAY 13",
            },
            {
              name: "BARANGAY 130",
            },
            {
              name: "BARANGAY 131",
            },
            {
              name: "BARANGAY 132",
            },
            {
              name: "BARANGAY 133",
            },
            {
              name: "BARANGAY 134",
            },
            {
              name: "BARANGAY 135",
            },
            {
              name: "BARANGAY 136",
            },
            {
              name: "BARANGAY 137",
            },
            {
              name: "BARANGAY 138",
            },
            {
              name: "BARANGAY 139",
            },
            {
              name: "BARANGAY 14",
            },
            {
              name: "BARANGAY 140",
            },
            {
              name: "BARANGAY 141",
            },
            {
              name: "BARANGAY 142",
            },
            {
              name: "BARANGAY 143",
            },
            {
              name: "BARANGAY 144",
            },
            {
              name: "BARANGAY 145",
            },
            {
              name: "BARANGAY 146",
            },
            {
              name: "BARANGAY 147",
            },
            {
              name: "BARANGAY 148",
            },
            {
              name: "BARANGAY 149",
            },
            {
              name: "BARANGAY 15",
            },
            {
              name: "BARANGAY 150",
            },
            {
              name: "BARANGAY 151",
            },
            {
              name: "BARANGAY 152",
            },
            {
              name: "BARANGAY 153",
            },
            {
              name: "BARANGAY 154",
            },
            {
              name: "BARANGAY 155",
            },
            {
              name: "BARANGAY 156",
            },
            {
              name: "BARANGAY 157",
            },
            {
              name: "BARANGAY 158",
            },
            {
              name: "BARANGAY 159",
            },
            {
              name: "BARANGAY 16",
            },
            {
              name: "BARANGAY 160",
            },
            {
              name: "BARANGAY 161",
            },
            {
              name: "BARANGAY 162",
            },
            {
              name: "BARANGAY 163",
            },
            {
              name: "BARANGAY 164",
            },
            {
              name: "BARANGAY 165",
            },
            {
              name: "BARANGAY 166",
            },
            {
              name: "BARANGAY 167",
            },
            {
              name: "BARANGAY 168",
            },
            {
              name: "BARANGAY 169",
            },
            {
              name: "BARANGAY 17",
            },
            {
              name: "BARANGAY 170",
            },
            {
              name: "BARANGAY 171",
            },
            {
              name: "BARANGAY 172",
            },
            {
              name: "BARANGAY 173",
            },
            {
              name: "BARANGAY 174",
            },
            {
              name: "BARANGAY 175",
            },
            {
              name: "BARANGAY 176",
            },
            {
              name: "BARANGAY 177",
            },
            {
              name: "BARANGAY 178",
            },
            {
              name: "BARANGAY 179",
            },
            {
              name: "BARANGAY 18",
            },
            {
              name: "BARANGAY 180",
            },
            {
              name: "BARANGAY 181",
            },
            {
              name: "BARANGAY 182",
            },
            {
              name: "BARANGAY 183",
            },
            {
              name: "BARANGAY 184",
            },
            {
              name: "BARANGAY 185",
            },
            {
              name: "BARANGAY 186",
            },
            {
              name: "BARANGAY 187",
            },
            {
              name: "BARANGAY 188",
            },
            {
              name: "BARANGAY 189",
            },
            {
              name: "BARANGAY 19",
            },
            {
              name: "BARANGAY 190",
            },
            {
              name: "BARANGAY 191",
            },
            {
              name: "BARANGAY 192",
            },
            {
              name: "BARANGAY 193",
            },
            {
              name: "BARANGAY 194",
            },
            {
              name: "BARANGAY 195",
            },
            {
              name: "BARANGAY 196",
            },
            {
              name: "BARANGAY 197",
            },
            {
              name: "BARANGAY 198",
            },
            {
              name: "BARANGAY 199",
            },
            {
              name: "BARANGAY 2",
            },
            {
              name: "BARANGAY 20",
            },
            {
              name: "BARANGAY 200",
            },
            {
              name: "BARANGAY 201",
            },
            {
              name: "BARANGAY 21",
            },
            {
              name: "BARANGAY 22",
            },
            {
              name: "BARANGAY 23",
            },
            {
              name: "BARANGAY 24",
            },
            {
              name: "BARANGAY 25",
            },
            {
              name: "BARANGAY 26",
            },
            {
              name: "BARANGAY 27",
            },
            {
              name: "BARANGAY 28",
            },
            {
              name: "BARANGAY 29",
            },
            {
              name: "BARANGAY 3",
            },
            {
              name: "BARANGAY 30",
            },
            {
              name: "BARANGAY 31",
            },
            {
              name: "BARANGAY 32",
            },
            {
              name: "BARANGAY 33",
            },
            {
              name: "BARANGAY 34",
            },
            {
              name: "BARANGAY 35",
            },
            {
              name: "BARANGAY 36",
            },
            {
              name: "BARANGAY 37",
            },
            {
              name: "BARANGAY 38",
            },
            {
              name: "BARANGAY 39",
            },
            {
              name: "BARANGAY 4",
            },
            {
              name: "BARANGAY 40",
            },
            {
              name: "BARANGAY 41",
            },
            {
              name: "BARANGAY 42",
            },
            {
              name: "BARANGAY 43",
            },
            {
              name: "BARANGAY 44",
            },
            {
              name: "BARANGAY 45",
            },
            {
              name: "BARANGAY 46",
            },
            {
              name: "BARANGAY 47",
            },
            {
              name: "BARANGAY 48",
            },
            {
              name: "BARANGAY 49",
            },
            {
              name: "BARANGAY 5",
            },
            {
              name: "BARANGAY 50",
            },
            {
              name: "BARANGAY 51",
            },
            {
              name: "BARANGAY 52",
            },
            {
              name: "BARANGAY 53",
            },
            {
              name: "BARANGAY 54",
            },
            {
              name: "BARANGAY 55",
            },
            {
              name: "BARANGAY 56",
            },
            {
              name: "BARANGAY 57",
            },
            {
              name: "BARANGAY 58",
            },
            {
              name: "BARANGAY 59",
            },
            {
              name: "BARANGAY 6",
            },
            {
              name: "BARANGAY 60",
            },
            {
              name: "BARANGAY 61",
            },
            {
              name: "BARANGAY 62",
            },
            {
              name: "BARANGAY 63",
            },
            {
              name: "BARANGAY 64",
            },
            {
              name: "BARANGAY 65",
            },
            {
              name: "BARANGAY 66",
            },
            {
              name: "BARANGAY 67",
            },
            {
              name: "BARANGAY 68",
            },
            {
              name: "BARANGAY 69",
            },
            {
              name: "BARANGAY 7",
            },
            {
              name: "BARANGAY 70",
            },
            {
              name: "BARANGAY 71",
            },
            {
              name: "BARANGAY 72",
            },
            {
              name: "BARANGAY 73",
            },
            {
              name: "BARANGAY 74",
            },
            {
              name: "BARANGAY 75",
            },
            {
              name: "BARANGAY 76",
            },
            {
              name: "BARANGAY 77",
            },
            {
              name: "BARANGAY 78",
            },
            {
              name: "BARANGAY 79",
            },
            {
              name: "BARANGAY 8",
            },
            {
              name: "BARANGAY 80",
            },
            {
              name: "BARANGAY 81",
            },
            {
              name: "BARANGAY 82",
            },
            {
              name: "BARANGAY 83",
            },
            {
              name: "BARANGAY 84",
            },
            {
              name: "BARANGAY 85",
            },
            {
              name: "BARANGAY 86",
            },
            {
              name: "BARANGAY 87",
            },
            {
              name: "BARANGAY 88",
            },
            {
              name: "BARANGAY 89",
            },
            {
              name: "BARANGAY 9",
            },
            {
              name: "BARANGAY 90",
            },
            {
              name: "BARANGAY 91",
            },
            {
              name: "BARANGAY 92",
            },
            {
              name: "BARANGAY 93",
            },
            {
              name: "BARANGAY 94",
            },
            {
              name: "BARANGAY 95",
            },
            {
              name: "BARANGAY 96",
            },
            {
              name: "BARANGAY 97",
            },
            {
              name: "BARANGAY 98",
            },
            {
              name: "BARANGAY 99",
            },
          ],
        },
        "PASIG CITY": {
          name: "PASIG CITY",
          baranggays: [
            {
              name: "BAGONG ILOG",
            },
            {
              name: "BAGONG KATIPUNAN",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "BUTING",
            },
            {
              name: "CANIOGAN",
            },
            {
              name: "DELA PAZ",
            },
            {
              name: "KALAWAAN",
            },
            {
              name: "KAPASIGAN",
            },
            {
              name: "KAPITOLYO",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MANGGAHAN",
            },
            {
              name: "MAYBUNGA",
            },
            {
              name: "ORANBO",
            },
            {
              name: "PALATIW",
            },
            {
              name: "PINAGBUHATAN",
            },
            {
              name: "PINEDA",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAGAD",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SANTOLAN",
            },
            {
              name: "SUMILANG",
            },
            {
              name: "UGONG",
            },
          ],
        },
        PATEROS: {
          name: "PATEROS",
          baranggays: [
            {
              name: "AGUHO",
            },
            {
              name: "MAGTANGGOL",
            },
            {
              name: "MARTIRES DEL 96",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTO ROSARIO-KANLURAN",
            },
            {
              name: "SANTO ROSARIO-SILANGAN",
            },
            {
              name: "TABACALERA",
            },
          ],
        },
        "QUEZON CITY": {
          name: "QUEZON CITY",
          baranggays: [
            {
              name: "ALICIA",
            },
            {
              name: "AMIHAN",
            },
            {
              name: "APOLONIO SAMSON",
            },
            {
              name: "AURORA",
            },
            {
              name: "BAESA",
            },
            {
              name: "BAGBAG",
            },
            {
              name: "BAGONG LIPUNAN NG CRAME",
            },
            {
              name: "BAGONG PAG-ASA",
            },
            {
              name: "BAGONG SILANGAN",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAGUMBUHAY",
            },
            {
              name: "BAHAY TORO",
            },
            {
              name: "BALINGASA",
            },
            {
              name: "BALONG BATO",
            },
            {
              name: "BATASAN HILLS",
            },
            {
              name: "BAYANIHAN",
            },
            {
              name: "BLUE RIDGE A",
            },
            {
              name: "BLUE RIDGE B",
            },
            {
              name: "BOTOCAN",
            },
            {
              name: "BUNGAD",
            },
            {
              name: "CAMP AGUINALDO",
            },
            {
              name: "CAPRI",
            },
            {
              name: "CENTRAL",
            },
            {
              name: "CLARO",
            },
            {
              name: "COMMONWEALTH",
            },
            {
              name: "CULIAT",
            },
            {
              name: "DAMAR",
            },
            {
              name: "DAMAYAN",
            },
            {
              name: "DAMAYANG LAGI",
            },
            {
              name: "DEL MONTE",
            },
            {
              name: "DIOQUINO ZOBEL",
            },
            {
              name: "DON MANUEL",
            },
            {
              name: "DONA IMELDA",
            },
            {
              name: "DONA JOSEFA",
            },
            {
              name: "DUYAN-DUYAN",
            },
            {
              name: "E. RODRIGUEZ",
            },
            {
              name: "EAST KAMIAS",
            },
            {
              name: "ESCOPA I",
            },
            {
              name: "ESCOPA II",
            },
            {
              name: "ESCOPA III",
            },
            {
              name: "ESCOPA IV",
            },
            {
              name: "FAIRVIEW",
            },
            {
              name: "GREATER LAGRO",
            },
            {
              name: "GULOD",
            },
            {
              name: "HOLY SPIRIT",
            },
            {
              name: "HORSESHOE",
            },
            {
              name: "IMMACULATE CONCEPCION",
            },
            {
              name: "KALIGAYAHAN",
            },
            {
              name: "KALUSUGAN",
            },
            {
              name: "KAMUNING",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KAUNLARAN",
            },
            {
              name: "KRISTONG HARI",
            },
            {
              name: "KRUS NA LIGAS",
            },
            {
              name: "LAGING HANDA",
            },
            {
              name: "LIBIS",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LOYOLA HEIGHTS",
            },
            {
              name: "MAHARLIKA",
            },
            {
              name: "MALAYA",
            },
            {
              name: "MANGGA",
            },
            {
              name: "MANRESA",
            },
            {
              name: "MARIANA",
            },
            {
              name: "MARIBLO",
            },
            {
              name: "MARILAG",
            },
            {
              name: "MASAGANA",
            },
            {
              name: "MASAMBONG",
            },
            {
              name: "MATANDANG BALARA",
            },
            {
              name: "MILAGROSA",
            },
            {
              name: "N.S. AMORANTO (GINTONG SILAHIS)",
            },
            {
              name: "NAGKAISANG NAYON",
            },
            {
              name: "NAYONG KANLURAN",
            },
            {
              name: "NEW ERA (CONSTITUTION HILLS)",
            },
            {
              name: "NORTH FAIRVIEW",
            },
            {
              name: "NOVALICHES PROPER",
            },
            {
              name: "OBRERO",
            },
            {
              name: "OLD CAPITOL SITE",
            },
            {
              name: "PAANG BUNDOK",
            },
            {
              name: "PAG-IBIG SA NAYON",
            },
            {
              name: "PALIGSAHAN",
            },
            {
              name: "PALTOK",
            },
            {
              name: "PANSOL",
            },
            {
              name: "PARAISO",
            },
            {
              name: "PASONG PUTIK PROPER",
            },
            {
              name: "PASONG TAMO",
            },
            {
              name: "PAYATAS",
            },
            {
              name: "PHIL-AM",
            },
            {
              name: "PINAGKAISAHAN",
            },
            {
              name: "PINYAHAN",
            },
            {
              name: "PROJECT 6",
            },
            {
              name: "QUIRINO 2A",
            },
            {
              name: "QUIRINO 2B",
            },
            {
              name: "QUIRINO 2C",
            },
            {
              name: "QUIRINO 3A",
            },
            {
              name: "RAMON MAGSAYSAY",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SACRED HEART",
            },
            {
              name: "SAINT IGNATIUS",
            },
            {
              name: "SAINT PETER",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ISIDRO LABRADOR",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MARTIN DE PORRES",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANGANDAAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO DOMINGO (MATALAHIB)",
            },
            {
              name: "SANTO NINO",
            },
            {
              name: "SANTOL",
            },
            {
              name: "SAUYO",
            },
            {
              name: "SIENNA",
            },
            {
              name: "SIKATUNA VILLAGE",
            },
            {
              name: "SILANGAN",
            },
            {
              name: "SOCORRO",
            },
            {
              name: "SOUTH TRIANGLE",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TALAYAN",
            },
            {
              name: "TALIPAPA",
            },
            {
              name: "TANDANG SORA",
            },
            {
              name: "TATALON",
            },
            {
              name: "TEACHERS VILLAGE EAST",
            },
            {
              name: "TEACHERS VILLAGE WEST",
            },
            {
              name: "U.P. CAMPUS",
            },
            {
              name: "U.P. VILLAGE",
            },
            {
              name: "UGONG NORTE",
            },
            {
              name: "UNANG SIGAW",
            },
            {
              name: "VALENCIA",
            },
            {
              name: "VASRA",
            },
            {
              name: "VETERANS VILLAGE",
            },
            {
              name: "VILLA MARIA CLARA",
            },
            {
              name: "WEST KAMIAS",
            },
            {
              name: "WEST TRIANGLE",
            },
            {
              name: "WHITE PLAINS",
            },
          ],
        },
        "SAN JUAN CITY": {
          name: "SAN JUAN CITY",
          baranggays: [
            {
              name: "ADDITION HILLS",
            },
            {
              name: "BALONG-BATO",
            },
            {
              name: "BATIS",
            },
            {
              name: "CORAZON DE JESUS",
            },
            {
              name: "ERMITANO",
            },
            {
              name: "GREENHILLS",
            },
            {
              name: "HALO-HALO (ST. JOSEPH)",
            },
            {
              name: "ISABELITA",
            },
            {
              name: "KABAYANAN",
            },
            {
              name: "LITTLE BAGUIO",
            },
            {
              name: "MAYTUNAS",
            },
            {
              name: "ONSE",
            },
            {
              name: "PASADENA",
            },
            {
              name: "PEDRO CRUZ",
            },
            {
              name: "PROGRESO",
            },
            {
              name: "RIVERA",
            },
            {
              name: "SALAPAN",
            },
            {
              name: "SAN PERFECTO",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "TIBAGAN",
            },
            {
              name: "WEST CRAME",
            },
          ],
        },
        "TAGUIG CITY": {
          name: "TAGUIG CITY",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "CALZADA",
            },
            {
              name: "CENTRAL BICUTAN",
            },
            {
              name: "CENTRAL SIGNAL VILLAGE",
            },
            {
              name: "FORT BONIFACIO",
            },
            {
              name: "HAGONOY",
            },
            {
              name: "IBAYO TIPAS",
            },
            {
              name: "KATUPARAN",
            },
            {
              name: "LIGID-TIPAS",
            },
            {
              name: "LOWER BICUTAN",
            },
            {
              name: "MAHARLIKA VILLAGE",
            },
            {
              name: "NAPINDAN",
            },
            {
              name: "NEW LOWER BICUTAN",
            },
            {
              name: "NORTH DAANG HARI",
            },
            {
              name: "NORTH SIGNAL VILLAGE",
            },
            {
              name: "PALINGON",
            },
            {
              name: "PINAGSAMA",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SOUTH DAAN HARI",
            },
            {
              name: "SOUTH SIGNAL VILLAGE",
            },
            {
              name: "TANYAG",
            },
            {
              name: "TUKTUKAN",
            },
            {
              name: "UPPER BICUTAN",
            },
            {
              name: "USUSAN",
            },
            {
              name: "WAWA",
            },
            {
              name: "WESTERN BICUTAN",
            },
          ],
        },
        "VALENZUELA CITY": {
          name: "VALENZUELA CITY",
          baranggays: [
            {
              name: "ARKONG BATO",
            },
            {
              name: "BAGBAGUIN",
            },
            {
              name: "BALANGKAS",
            },
            {
              name: "BIGNAY",
            },
            {
              name: "BISIG",
            },
            {
              name: "CANUMAY EAST",
            },
            {
              name: "CANUMAY WEST",
            },
            {
              name: "COLOONG",
            },
            {
              name: "DALANDANAN",
            },
            {
              name: "HEN. T. DE LEON",
            },
            {
              name: "ISLA",
            },
            {
              name: "KARUHATAN",
            },
            {
              name: "LAWANG BATO",
            },
            {
              name: "LINGUNAN",
            },
            {
              name: "MABOLO",
            },
            {
              name: "MALANDAY",
            },
            {
              name: "MALINTA",
            },
            {
              name: "MAPULANG LUPA",
            },
            {
              name: "MARULAS",
            },
            {
              name: "MAYSAN",
            },
            {
              name: "PALASAN",
            },
            {
              name: "PARADA",
            },
            {
              name: "PARIANCILLO VILLA",
            },
            {
              name: "PASO DE BLAS",
            },
            {
              name: "PASOLO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULO",
            },
            {
              name: "PUNTURIN",
            },
            {
              name: "RINCON",
            },
            {
              name: "TAGALAG",
            },
            {
              name: "UGONG",
            },
            {
              name: "VIENTE REALES",
            },
            {
              name: "WAWANG PULO",
            },
          ],
        },
      },
    },
    "MISAMIS OCCIDENTAL": {
      name: "MISAMIS OCCIDENTAL",
      region: "REGION 10",
      municipalities: {
        ALORAN: {
          name: "ALORAN",
          baranggays: [
            {
              name: "ALORAN",
            },
            {
              name: "BANISILON",
            },
            {
              name: "CASUSAN",
            },
            {
              name: "CONAT",
            },
            {
              name: "DALISAY",
            },
            {
              name: "DULLAN",
            },
            {
              name: "IBABAO",
            },
            {
              name: "LABO",
            },
            {
              name: "LAWA-AN",
            },
            {
              name: "LOBOGON",
            },
            {
              name: "LUMBAYAO",
            },
            {
              name: "MAKAWA",
            },
            {
              name: "MATIPAZ",
            },
            {
              name: "MAULAR",
            },
            {
              name: "MOHON",
            },
            {
              name: "OSPITAL (POB.)",
            },
            {
              name: "PALAYAN",
            },
            {
              name: "PELONG",
            },
            {
              name: "TAWI-TAWI",
            },
          ],
        },
        BALIANGAO: {
          name: "BALIANGAO",
          baranggays: [
            {
              name: "BALIANGAO",
            },
          ],
        },
        BONIFACIO: {
          name: "BONIFACIO",
          baranggays: [
            {
              name: "BONIFACIO",
            },
            {
              name: "POBLACION (CENTRO)",
            },
          ],
        },
        CALAMBA: {
          name: "CALAMBA",
          baranggays: [
            {
              name: "CALAMBA",
            },
            {
              name: "CALARAN",
            },
            {
              name: "DON BERNARDO NERI POB. (TRES DE MAYO)",
            },
            {
              name: "NORTHERN POBLACION",
            },
            {
              name: "SOUTHWESTERN POBLACION",
            },
          ],
        },
        CLARIN: {
          name: "CLARIN",
          baranggays: [
            {
              name: "BERNAD",
            },
            {
              name: "BITO-ON",
            },
            {
              name: "CLARIN",
            },
            {
              name: "GATA DAKU",
            },
            {
              name: "GATA DIOT",
            },
            {
              name: "LAPASAN",
            },
            {
              name: "LUPAGAN",
            },
            {
              name: "MIALEN",
            },
            {
              name: "PAN-AY",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "TINACLA-AN",
            },
          ],
        },
        CONCEPCION: {
          name: "CONCEPCION",
          baranggays: [
            {
              name: "CONCEPCION",
            },
          ],
        },
        JIMENEZ: {
          name: "JIMENEZ",
          baranggays: [
            {
              name: "BUTUAY",
            },
            {
              name: "CORRALES",
            },
            {
              name: "DICOLOC",
            },
            {
              name: "GATA",
            },
            {
              name: "JIMENEZ",
            },
            {
              name: "MACABAYAO",
            },
            {
              name: "NACIONAL (POB.)",
            },
            {
              name: "NAGA (POB.)",
            },
            {
              name: "PALILAN",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "TABO-O",
            },
            {
              name: "TARAKA (POB.)",
            },
          ],
        },
        "LOPEZ JAENA": {
          name: "LOPEZ JAENA",
          baranggays: [
            {
              name: "BIASONG",
            },
            {
              name: "EASTERN POBLACION",
            },
            {
              name: "KATIPA",
            },
            {
              name: "LOPEZ JAENA",
            },
            {
              name: "LUZARAN",
            },
            {
              name: "MANSABAY ALTO",
            },
            {
              name: "MANSABAY BAJO",
            },
            {
              name: "MOLATUHAN ALTO",
            },
            {
              name: "MOLATUHAN BAJO",
            },
            {
              name: "PUNTOD",
            },
            {
              name: "SIBUGON",
            },
            {
              name: "SIBULA",
            },
            {
              name: "WESTERN POBLACION",
            },
          ],
        },
        "OROQUIETA CITY": {
          name: "OROQUIETA CITY",
          baranggays: [
            {
              name: "CANUBAY",
            },
            {
              name: "CIRIACO C. PASTRANO (NILABO)",
            },
            {
              name: "LAMAC LOWER",
            },
            {
              name: "LAMAC UPPER",
            },
            {
              name: "LANGCANGAN LOWER",
            },
            {
              name: "LANGCANGAN PROPER",
            },
            {
              name: "LANGCANGAN UPPER",
            },
            {
              name: "LAYAWAN",
            },
            {
              name: "LOBOC LOWER",
            },
            {
              name: "LOBOC UPPER",
            },
            {
              name: "MOBOD",
            },
            {
              name: "OROQUIETA CITY",
            },
            {
              name: "PAYPAYAN",
            },
            {
              name: "PINES",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "TALAIRON",
            },
            {
              name: "TALIC",
            },
            {
              name: "TIPAN",
            },
            {
              name: "VILLAFLOR",
            },
          ],
        },
        "OZAMIS CITY": {
          name: "OZAMIS CITY",
          baranggays: [
            {
              name: "50TH DISTRICT (POB.)",
            },
            {
              name: "AGUADA (POB.)",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BAGAKAY",
            },
            {
              name: "BANADERO (POB.)",
            },
            {
              name: "BAYBAY SAN ROQUE",
            },
            {
              name: "BAYBAY SANTA CRUZ",
            },
            {
              name: "BAYBAY TRIUNFO",
            },
            {
              name: "BONGBONG",
            },
            {
              name: "CALABAYAN",
            },
            {
              name: "CARANGAN",
            },
            {
              name: "CARMEN (MISAMIS ANNEX)",
            },
            {
              name: "CATADMAN-MANABAY",
            },
            {
              name: "DIGUAN",
            },
            {
              name: "DIMALUNA",
            },
            {
              name: "DOÑA CONSUELO",
            },
            {
              name: "GANGO",
            },
            {
              name: "LABINAY",
            },
            {
              name: "LABO",
            },
            {
              name: "LAM-AN",
            },
            {
              name: "MALAUBANG",
            },
            {
              name: "MANINGCOL",
            },
            {
              name: "MOLICAY",
            },
            {
              name: "OZAMIS CITY",
            },
            {
              name: "PULOT",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "TABID",
            },
            {
              name: "TINAGO",
            },
          ],
        },
        PANAON: {
          name: "PANAON",
          baranggays: [
            {
              name: "DELA PAZ",
            },
            {
              name: "LUTAO",
            },
            {
              name: "MOHON",
            },
            {
              name: "PANAON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "VILLALIN",
            },
          ],
        },
        PLARIDEL: {
          name: "PLARIDEL",
          baranggays: [
            {
              name: "CALACA-AN",
            },
            {
              name: "CLARIN",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "EASTERN LOOC",
            },
            {
              name: "LAO PROPER",
            },
            {
              name: "LAO SANTA CRUZ",
            },
            {
              name: "LOOC PROPER",
            },
            {
              name: "MAMANGA DAKU",
            },
            {
              name: "NORTHERN POBLACION",
            },
            {
              name: "PANALSALAN",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "PUNTOD",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SOUTHERN LOOC",
            },
            {
              name: "SOUTHERN POBLACION",
            },
            {
              name: "TIPOLO",
            },
          ],
        },
        "SAPANG DALAGA": {
          name: "SAPANG DALAGA",
          baranggays: [
            {
              name: "POBLACION",
            },
            {
              name: "SAPANG DALAGA",
            },
          ],
        },
        SINACABAN: {
          name: "SINACABAN",
          baranggays: [
            {
              name: "CAGAY-ANON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SINACABAN",
            },
            {
              name: "SINONOC",
            },
          ],
        },
        "TANGUB CITY": {
          name: "TANGUB CITY",
          baranggays: [
            {
              name: "BALATACAN",
            },
            {
              name: "BARANGAY I - CITY HALL (POB.)",
            },
            {
              name: "BARANGAY II - MARILOU ANNEX (POB.)",
            },
            {
              name: "BARANGAY III- MARKET KALUBIAN (POB.)",
            },
            {
              name: "BARANGAY IV - ST. MICHAEL (POB.)",
            },
            {
              name: "BARANGAY V - MALUBOG (POB.)",
            },
            {
              name: "BARANGAY VI - LOWER POLAO (POB.)",
            },
            {
              name: "BARANGAY VII - UPPER POLAO (POB.)",
            },
            {
              name: "LORENZO TAN",
            },
            {
              name: "MAQUILAO",
            },
            {
              name: "PANGABUAN",
            },
            {
              name: "TANGUB CITY",
            },
          ],
        },
        TUDELA: {
          name: "TUDELA",
          baranggays: [
            {
              name: "CENTRO HULPA (POB.)",
            },
            {
              name: "CENTRO NAPU (POB.)",
            },
            {
              name: "CENTRO UPPER (POB.)",
            },
            {
              name: "TUDELA",
            },
          ],
        },
      },
    },
    "MISAMIS ORIENTAL": {
      name: "MISAMIS ORIENTAL",
      region: "REGION 10",
      municipalities: {
        ALUBIJID: {
          name: "ALUBIJID",
          baranggays: [
            {
              name: "ALUBIJID",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BENIGWAYAN",
            },
            {
              name: "CALATCAT",
            },
            {
              name: "LAGTANG",
            },
            {
              name: "LANAO",
            },
            {
              name: "LOGUILO",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LUMBO",
            },
            {
              name: "MOLOCBOLOC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMPATULOG",
            },
            {
              name: "SUNGAY",
            },
            {
              name: "TALABA",
            },
            {
              name: "TAPARAK",
            },
            {
              name: "TUGASNON",
            },
            {
              name: "TULA",
            },
          ],
        },
        BALINGASAG: {
          name: "BALINGASAG",
          baranggays: [
            {
              name: "BALAGNAN",
            },
            {
              name: "BALINGASAG",
            },
            {
              name: "BALIWAGAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BINITINAN",
            },
            {
              name: "BLANCO",
            },
            {
              name: "CALAWAG",
            },
            {
              name: "CAMUAYAN",
            },
            {
              name: "COGON",
            },
            {
              name: "DANSULI",
            },
            {
              name: "DUMARAIT",
            },
            {
              name: "HERMANO",
            },
            {
              name: "KIBANBAN",
            },
            {
              name: "LINABU",
            },
            {
              name: "LINGGANGAO",
            },
            {
              name: "MAMBAYAAN",
            },
            {
              name: "MANDANGOA",
            },
            {
              name: "NAPALIRAN",
            },
            {
              name: "QUEZON",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAMAY",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "TALUSAN",
            },
            {
              name: "WATERFALL",
            },
          ],
        },
        BALINGOAN: {
          name: "BALINGOAN",
          baranggays: [
            {
              name: "BALINGOAN",
            },
            {
              name: "BAUKBAUK POB. (G.E. ANTONINO)",
            },
            {
              name: "DAHILIG",
            },
            {
              name: "KABANGASAN",
            },
            {
              name: "KABULAKAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LAPINIG (POB.)",
            },
            {
              name: "MANTANGALE",
            },
            {
              name: "MAPUA",
            },
            {
              name: "SAN ALONZO",
            },
          ],
        },
        BINUANGAN: {
          name: "BINUANGAN",
          baranggays: [
            {
              name: "BINUANGAN",
            },
            {
              name: "DAMPIAS",
            },
            {
              name: "KITAMBAN",
            },
            {
              name: "KITAMBIS",
            },
            {
              name: "MABINI",
            },
            {
              name: "MOSANGOT",
            },
            {
              name: "NABATAAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "VALDECONCHA",
            },
          ],
        },
        "CAGAYAN DE ORO CITY": {
          name: "CAGAYAN DE ORO CITY",
          baranggays: [
            {
              name: "AGUSAN",
            },
            {
              name: "BAIKINGON",
            },
            {
              name: "BALUBAL",
            },
            {
              name: "BALULANG",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 14 (POB.)",
            },
            {
              name: "BARANGAY 15 (POB.)",
            },
            {
              name: "BARANGAY 16 (POB.)",
            },
            {
              name: "BARANGAY 17 (POB.)",
            },
            {
              name: "BARANGAY 18 (POB.)",
            },
            {
              name: "BARANGAY 19 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 20 (POB.)",
            },
            {
              name: "BARANGAY 21 (POB.)",
            },
            {
              name: "BARANGAY 22 (POB.)",
            },
            {
              name: "BARANGAY 23 (POB.)",
            },
            {
              name: "BARANGAY 24 (POB.)",
            },
            {
              name: "BARANGAY 25 (POB.)",
            },
            {
              name: "BARANGAY 26 (POB.)",
            },
            {
              name: "BARANGAY 27 (POB.)",
            },
            {
              name: "BARANGAY 28 (POB.)",
            },
            {
              name: "BARANGAY 29 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 30 (POB.)",
            },
            {
              name: "BARANGAY 31 (POB.)",
            },
            {
              name: "BARANGAY 32 (POB.)",
            },
            {
              name: "BARANGAY 33 (POB.)",
            },
            {
              name: "BARANGAY 34 (POB.)",
            },
            {
              name: "BARANGAY 35 (POB.)",
            },
            {
              name: "BARANGAY 36 (POB.)",
            },
            {
              name: "BARANGAY 37 (POB.)",
            },
            {
              name: "BARANGAY 38 (POB.)",
            },
            {
              name: "BARANGAY 39 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 40 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BAYANGA",
            },
            {
              name: "BESIGAN",
            },
            {
              name: "BONBON",
            },
            {
              name: "BUGO",
            },
            {
              name: "BULUA",
            },
            {
              name: "CAGAYAN DE ORO CITY",
            },
            {
              name: "CAMAMAN-AN",
            },
            {
              name: "CANITO-AN",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "CUGMAN",
            },
            {
              name: "DANSOLIHON",
            },
            {
              name: "F. S. CATANICO",
            },
            {
              name: "GUSA",
            },
            {
              name: "INDAHAG",
            },
            {
              name: "IPONAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LAPASAN",
            },
            {
              name: "LUMBIA",
            },
            {
              name: "MACABALAN",
            },
            {
              name: "MACASANDIG",
            },
            {
              name: "MAMBUAYA",
            },
            {
              name: "NAZARETH",
            },
            {
              name: "PAGALUNGAN",
            },
            {
              name: "PAGATPAT",
            },
            {
              name: "PATAG",
            },
            {
              name: "PIGSAG-AN",
            },
            {
              name: "PUERTO LOWER",
            },
            {
              name: "PUERTO UPPER",
            },
            {
              name: "PUNTOD",
            },
            {
              name: "SAN SIMON",
            },
            {
              name: "TABLON",
            },
            {
              name: "TAGLIMAO",
            },
            {
              name: "TAGPANGI",
            },
            {
              name: "TIGNAPOLOAN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "TUMPAGON",
            },
          ],
        },
        CLAVERIA: {
          name: "CLAVERIA",
          baranggays: [
            {
              name: "ANI-E",
            },
            {
              name: "APOSKAHOY",
            },
            {
              name: "BULAHAN",
            },
            {
              name: "CABACUNGAN",
            },
            {
              name: "CLAVERIA",
            },
            {
              name: "GUMAOD",
            },
            {
              name: "HINAPLANAN",
            },
            {
              name: "KALAWITAN",
            },
            {
              name: "LANISE",
            },
            {
              name: "LUNA",
            },
            {
              name: "MADAGUING",
            },
            {
              name: "MALAGANA",
            },
            {
              name: "MAT-I",
            },
            {
              name: "MINALWANG",
            },
            {
              name: "PAMBUGAS",
            },
            {
              name: "PANAMPAWAN",
            },
            {
              name: "PATROCENIO",
            },
            {
              name: "PELAEZ (DON GREGORIO PELAEZ)",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNONG",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAMBOBOAN",
            },
            {
              name: "TIPOLOHON",
            },
          ],
        },
        "EL SALVADOR CITY": {
          name: "EL SALVADOR CITY",
          baranggays: [
            {
              name: "AMOROS",
            },
            {
              name: "BOLISONG",
            },
            {
              name: "BOLOBOLO",
            },
            {
              name: "CALONGONAN",
            },
            {
              name: "COGON",
            },
            {
              name: "EL SALVADOR CITY",
            },
            {
              name: "HIMAYA",
            },
            {
              name: "HINIGDAAN",
            },
            {
              name: "KALABAYLABAY",
            },
            {
              name: "KIBONBON",
            },
            {
              name: "MOLUGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "SINALOC",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "ULALIMAN",
            },
          ],
        },
        "GINGOOG CITY": {
          name: "GINGOOG CITY",
          baranggays: [
            {
              name: "AGAY-AYAN",
            },
            {
              name: "ALAGATAN",
            },
            {
              name: "ANAKAN",
            },
            {
              name: "BAGUBAD",
            },
            {
              name: "BAKIDBAKID",
            },
            {
              name: "BAL-ASON",
            },
            {
              name: "BANTAAWAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 14 (POB.)",
            },
            {
              name: "BARANGAY 15 (POB.)",
            },
            {
              name: "BARANGAY 16 (POB.)",
            },
            {
              name: "BARANGAY 17 (POB.)",
            },
            {
              name: "BARANGAY 18 (POB.)",
            },
            {
              name: "BARANGAY 18-A (POB.)",
            },
            {
              name: "BARANGAY 19 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 20 (POB.)",
            },
            {
              name: "BARANGAY 21 (POB.)",
            },
            {
              name: "BARANGAY 22 (POB.)",
            },
            {
              name: "BARANGAY 22-A (POB.)",
            },
            {
              name: "BARANGAY 23 (POB.)",
            },
            {
              name: "BARANGAY 24 (POB.)",
            },
            {
              name: "BARANGAY 24-A (POB.)",
            },
            {
              name: "BARANGAY 25 (POB.)",
            },
            {
              name: "BARANGAY 26 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BINAKALAN",
            },
            {
              name: "CAPITULANGAN",
            },
            {
              name: "DAAN-LUNGSOD",
            },
            {
              name: "DINAWEHAN",
            },
            {
              name: "EUREKA",
            },
            {
              name: "GINGOOG CITY",
            },
            {
              name: "HINDANGON",
            },
            {
              name: "KALAGONOY",
            },
            {
              name: "KALIPAY",
            },
            {
              name: "KAMANIKAN",
            },
            {
              name: "KIANLAGAN",
            },
            {
              name: "KIBUGING",
            },
            {
              name: "KIPUNTOS",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "LAWIT",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIBON",
            },
            {
              name: "LUNAO",
            },
            {
              name: "LUNOTAN",
            },
            {
              name: "MALIBUD",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MARIBUCAO",
            },
            {
              name: "MIMBALAGON",
            },
            {
              name: "MIMBUNGA",
            },
            {
              name: "MIMBUNTONG",
            },
            {
              name: "MINSAPINIT",
            },
            {
              name: "MURALLON",
            },
            {
              name: "ODIONGAN",
            },
            {
              name: "PANGASIHAN",
            },
            {
              name: "PUNONG",
            },
            {
              name: "RICORO",
            },
            {
              name: "SAMAY",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANGALAN",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "TAGPAKO",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TALON",
            },
            {
              name: "TINABALAN",
            },
            {
              name: "TINULONGAN",
            },
          ],
        },
        GITAGUM: {
          name: "GITAGUM",
          baranggays: [
            {
              name: "BURNAY",
            },
            {
              name: "CARLOS P. GARCIA",
            },
            {
              name: "COGON",
            },
            {
              name: "GITAGUM",
            },
            {
              name: "GREGORIO PELAEZ (LAGUTAY)",
            },
            {
              name: "KILANGIT",
            },
            {
              name: "MATANGAD",
            },
            {
              name: "PANGAYAWAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUEZON",
            },
            {
              name: "TALA-O",
            },
            {
              name: "ULAB",
            },
          ],
        },
        INITAO: {
          name: "INITAO",
          baranggays: [
            {
              name: "ALUNA",
            },
            {
              name: "ANDALES",
            },
            {
              name: "APAS",
            },
            {
              name: "CALACAPAN",
            },
            {
              name: "GIMANGPANG",
            },
            {
              name: "INITAO",
            },
            {
              name: "JAMPASON",
            },
            {
              name: "JAMPASON",
            },
            {
              name: "KAMELON",
            },
            {
              name: "KANITOAN",
            },
            {
              name: "OGUIS",
            },
            {
              name: "PAGAHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PONTACON",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SINALAC",
            },
            {
              name: "TAWANTAWAN",
            },
            {
              name: "TUBIGAN",
            },
          ],
        },
        JASAAN: {
          name: "JASAAN",
          baranggays: [
            {
              name: "APLAYA",
            },
            {
              name: "BOBONTUGAN",
            },
            {
              name: "CORRALES",
            },
            {
              name: "DANAO",
            },
            {
              name: "I. S. CRUZ",
            },
            {
              name: "JASAAN",
            },
            {
              name: "KIMAYA",
            },
            {
              name: "LOWER JASAAN (POB.)",
            },
            {
              name: "LUZ BANZON",
            },
            {
              name: "NATUBO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SOLANA",
            },
            {
              name: "UPPER JASAAN (POB.)",
            },
          ],
        },
        KINOGUITAN: {
          name: "KINOGUITAN",
          baranggays: [
            {
              name: "BERAY",
            },
            {
              name: "BOLISONG",
            },
            {
              name: "BUKO",
            },
            {
              name: "CALUBO",
            },
            {
              name: "CAMPO",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "KAGUMAHAN",
            },
            {
              name: "KALITIAN",
            },
            {
              name: "KINOGUITAN",
            },
            {
              name: "KITOTOK",
            },
            {
              name: "PANABOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALICAPAWAN",
            },
            {
              name: "SALUBSOB",
            },
            {
              name: "SUAREZ",
            },
            {
              name: "SUMALAG",
            },
          ],
        },
        LAGONGLONG: {
          name: "LAGONGLONG",
          baranggays: [
            {
              name: "BANGLAY",
            },
            {
              name: "DAMPIL",
            },
            {
              name: "GASTON",
            },
            {
              name: "KABULAWAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LAGONGLONG",
            },
            {
              name: "LUMBO",
            },
            {
              name: "MANAOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TABOK",
            },
            {
              name: "UMAGOS",
            },
          ],
        },
        LAGUINDINGAN: {
          name: "LAGUINDINGAN",
          baranggays: [
            {
              name: "AROMAHON",
            },
            {
              name: "GASI",
            },
            {
              name: "KIBAGHOT",
            },
            {
              name: "LAGUINDINGAN",
            },
            {
              name: "LAPAD",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "MAUSWAGON",
            },
            {
              name: "MOOG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "SINAI",
            },
            {
              name: "TUBAJON",
            },
          ],
        },
        LIBERTAD: {
          name: "LIBERTAD",
          baranggays: [
            {
              name: "DULONG",
            },
            {
              name: "GIMAYLAN",
            },
            {
              name: "KIMALOK",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LUBLUBAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RETABLO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TANGCUB",
            },
            {
              name: "TAYTAYAN",
            },
          ],
        },
        LUGAIT: {
          name: "LUGAIT",
          baranggays: [
            {
              name: "AYA-AYA",
            },
            {
              name: "BETAHON",
            },
            {
              name: "BIGA",
            },
            {
              name: "CALANGAHAN",
            },
            {
              name: "KALUKNAYAN",
            },
            {
              name: "LOWER TALACOGON",
            },
            {
              name: "LUGAIT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "UPPER TALACOGON",
            },
          ],
        },
        MAGSAYSAY: {
          name: "MAGSAYSAY",
          baranggays: [
            {
              name: "MAGSAYSAY",
            },
          ],
        },
        "MAGSAYSAY (LINUGOS)": {
          name: "MAGSAYSAY (LINUGOS)",
          baranggays: [
            {
              name: "ABUNDA",
            },
            {
              name: "ARTADI",
            },
            {
              name: "BONIFACIO AQUINO",
            },
            {
              name: "CABALAWAN",
            },
            {
              name: "CABANTIAN",
            },
            {
              name: "CABUBUHAN",
            },
            {
              name: "CANDIIS",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "DAMAYUHAN",
            },
            {
              name: "GUMABON",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KIBUNGSOD",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MINDULAO",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAMA",
            },
            {
              name: "TIBON-TIBON",
            },
            {
              name: "TINAAN",
            },
            {
              name: "TULANG (CADENA DE AMOR)",
            },
            {
              name: "VILLA FELIPA",
            },
          ],
        },
        MANTICAO: {
          name: "MANTICAO",
          baranggays: [
            {
              name: "ARGAYOSO",
            },
            {
              name: "BALINTAD",
            },
            {
              name: "CABALANTIAN",
            },
            {
              name: "CAMANGA",
            },
            {
              name: "DIGKILAAN",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MANTICAO",
            },
            {
              name: "PAGAWAN",
            },
            {
              name: "PANIANGAN",
            },
            {
              name: "PATAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNTA SILUM",
            },
            {
              name: "TUOD",
            },
            {
              name: "UPPER MALUBOG",
            },
          ],
        },
        MEDINA: {
          name: "MEDINA",
          baranggays: [
            {
              name: "BANGBANG",
            },
            {
              name: "BULWA",
            },
            {
              name: "CABUG",
            },
            {
              name: "DIG-AGUYAN",
            },
            {
              name: "DUKA",
            },
            {
              name: "GASA",
            },
            {
              name: "MAANAS",
            },
            {
              name: "MANANUM BAG-O",
            },
            {
              name: "MANANUM DAAN",
            },
            {
              name: "MEDINA",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "PAHINDONG",
            },
            {
              name: "PORTULIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TAMBAGAN",
            },
            {
              name: "TUP-ON",
            },
          ],
        },
        NAAWAN: {
          name: "NAAWAN",
          baranggays: [
            {
              name: "DON PEDRO",
            },
            {
              name: "LINANGKAYAN",
            },
            {
              name: "LUBILAN",
            },
            {
              name: "MAPULOG",
            },
            {
              name: "MAPUTI",
            },
            {
              name: "MAT-I",
            },
            {
              name: "NAAWAN",
            },
            {
              name: "PATAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAGBALOGO",
            },
            {
              name: "TUBORAN",
            },
          ],
        },
        OPOL: {
          name: "OPOL",
          baranggays: [
            {
              name: "AWANG",
            },
            {
              name: "BAGOCBOC",
            },
            {
              name: "BARRA",
            },
            {
              name: "BONBON",
            },
            {
              name: "CAUYONAN",
            },
            {
              name: "IGPIT",
            },
            {
              name: "LIMONDA",
            },
            {
              name: "LUYONGBONBON",
            },
            {
              name: "MALANANG",
            },
            {
              name: "NANGCAON",
            },
            {
              name: "OPOL",
            },
            {
              name: "PATAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TABOC",
            },
            {
              name: "TINGALAN",
            },
          ],
        },
        SALAY: {
          name: "SALAY",
          baranggays: [
            {
              name: "ALIPUATON",
            },
            {
              name: "AMPENICAN",
            },
            {
              name: "BUNAL",
            },
            {
              name: "CASULOG",
            },
            {
              name: "DINAGSAAN",
            },
            {
              name: "GUINALABAN",
            },
            {
              name: "ILI-ILIHON",
            },
            {
              name: "INOBULAN",
            },
            {
              name: "LOOC",
            },
            {
              name: "MATAMPA",
            },
            {
              name: "MEMBULI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAGSAG",
            },
            {
              name: "SALAY",
            },
            {
              name: "SALAY RIVER I",
            },
            {
              name: "SALAY RIVER II",
            },
            {
              name: "SARAY",
            },
            {
              name: "TINAGAAN",
            },
            {
              name: "YUNGOD",
            },
          ],
        },
        SUGBONGCOGON: {
          name: "SUGBONGCOGON",
          baranggays: [
            {
              name: "ALICOMOHAN",
            },
            {
              name: "AMPIANGA",
            },
            {
              name: "KAULAYANAN",
            },
            {
              name: "KIDAMPAS",
            },
            {
              name: "KIRAGING",
            },
            {
              name: "MANGGA",
            },
            {
              name: "MIMBUAHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA CRUZ (MABINI)",
            },
            {
              name: "SILAD",
            },
            {
              name: "SUGBONGCOGON",
            },
          ],
        },
        TAGOLOAN: {
          name: "TAGOLOAN",
          baranggays: [
            {
              name: "BALUARTE",
            },
            {
              name: "CASINGLOT",
            },
            {
              name: "GRACIA",
            },
            {
              name: "MOHON",
            },
            {
              name: "NATUMOLAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SUGBONGCOGON",
            },
            {
              name: "TAGOLOAN",
            },
          ],
        },
        TALISAYAN: {
          name: "TALISAYAN",
          baranggays: [
            {
              name: "BUGDANG",
            },
            {
              name: "CALAMCAM",
            },
            {
              name: "CASIBOLE",
            },
            {
              name: "MACOPA",
            },
            {
              name: "MAGKARILA",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MANDAHILAG",
            },
            {
              name: "MINTABON",
            },
            {
              name: "PANGPANGON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POOK",
            },
            {
              name: "PUNTA SANTIAGO",
            },
            {
              name: "PUTING BALAS",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SIBANTANG",
            },
            {
              name: "SINDANGAN",
            },
            {
              name: "TAGBOCBOC",
            },
            {
              name: "TALISAYAN",
            },
          ],
        },
        VILLANUEVA: {
          name: "VILLANUEVA",
          baranggays: [
            {
              name: "BALACANAS",
            },
            {
              name: "DAYAWAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KIMAYA",
            },
            {
              name: "LOOC",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "TAMBOBONG",
            },
            {
              name: "VILLANUEVA",
            },
          ],
        },
      },
    },
    "MOUNTAIN PROVINCE": {
      name: "MOUNTAIN PROVINCE",
      region: "CAR",
      municipalities: {
        BARLIG: {
          name: "BARLIG",
          baranggays: [
            {
              name: "BARLIG",
            },
            {
              name: "CHUPAC",
            },
            {
              name: "FIANGTIN",
            },
            {
              name: "GAWANA (POB.)",
            },
            {
              name: "KALEO",
            },
            {
              name: "LATANG",
            },
            {
              name: "LIAS KANLURAN",
            },
            {
              name: "LIAS SILANGAN",
            },
            {
              name: "LINGOY",
            },
            {
              name: "LUNAS",
            },
            {
              name: "MACALANA",
            },
            {
              name: "OGOOG",
            },
          ],
        },
        BAUKO: {
          name: "BAUKO",
          baranggays: [
            {
              name: "ABATAN",
            },
            {
              name: "BAGNEN ORIENTE",
            },
            {
              name: "BAGNEN PROPER",
            },
            {
              name: "BALINTAUGAN",
            },
            {
              name: "BANAO",
            },
            {
              name: "BAUKO",
            },
            {
              name: "BILA (BUA)",
            },
            {
              name: "GUINZADAN CENTRAL",
            },
            {
              name: "GUINZADAN NORTE",
            },
            {
              name: "GUINZADAN SUR",
            },
            {
              name: "LAGAWA",
            },
            {
              name: "LESEB",
            },
            {
              name: "MABAAY",
            },
            {
              name: "MAYAG",
            },
            {
              name: "MONAMON NORTE",
            },
            {
              name: "MONAMON SUR",
            },
            {
              name: "MOUNT DATA",
            },
            {
              name: "OTUCAN NORTE",
            },
            {
              name: "OTUCAN SUR",
            },
            {
              name: "POBLACION (BAUKO)",
            },
            {
              name: "SADSADAN",
            },
            {
              name: "SINTO",
            },
            {
              name: "TAPAPAN",
            },
          ],
        },
        BESAO: {
          name: "BESAO",
          baranggays: [
            {
              name: "AGAWA",
            },
            {
              name: "AMBAGUIO",
            },
            {
              name: "BANGUITAN",
            },
            {
              name: "BESAO EAST (BESAO PROPER)",
            },
            {
              name: "BESAO WEST",
            },
            {
              name: "CATENGAN",
            },
            {
              name: "GUEDAY",
            },
            {
              name: "KIN-IWAY (POB.)",
            },
            {
              name: "LACMAAN",
            },
            {
              name: "LAYLAYA",
            },
            {
              name: "PANDANGAN",
            },
            {
              name: "PAYEO",
            },
            {
              name: "SUQUIB",
            },
            {
              name: "TAMBOAN",
            },
          ],
        },
        BONTOC: {
          name: "BONTOC",
          baranggays: [
            {
              name: "ALAB ORIENTE",
            },
            {
              name: "ALAB PROPER",
            },
            {
              name: "BALILI",
            },
            {
              name: "BAYYO",
            },
            {
              name: "BONTOC",
            },
            {
              name: "BONTOC ILI",
            },
            {
              name: "CALUTIT",
            },
            {
              name: "CANEO",
            },
            {
              name: "DALICAN",
            },
            {
              name: "GONOGON",
            },
            {
              name: "GUINAANG",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MALIGCONG",
            },
            {
              name: "POBLACION (BONTOC)",
            },
            {
              name: "SAMOKI",
            },
            {
              name: "TALUBIN",
            },
            {
              name: "TOCUCAN",
            },
          ],
        },
        NATONIN: {
          name: "NATONIN",
          baranggays: [
            {
              name: "ALUNOGAN",
            },
            {
              name: "BALANGAO",
            },
            {
              name: "BANAO",
            },
            {
              name: "BANAWAL",
            },
            {
              name: "BUTAC",
            },
            {
              name: "MADUCAYAN",
            },
            {
              name: "NATONIN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUDO",
            },
            {
              name: "SALIOK",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "TONGLAYAN",
            },
          ],
        },
        PARACELIS: {
          name: "PARACELIS",
          baranggays: [
            {
              name: "ANONAT",
            },
            {
              name: "BACARNI",
            },
            {
              name: "BANANAO",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BUNOT",
            },
            {
              name: "BURINGAL",
            },
            {
              name: "BUTIGUE",
            },
            {
              name: "PALITOD",
            },
            {
              name: "PARACELIS",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        SABANGAN: {
          name: "SABANGAN",
          baranggays: [
            {
              name: "BAO-ANGAN",
            },
            {
              name: "BUN-AYAN",
            },
            {
              name: "BUSA",
            },
            {
              name: "CAMATAGAN",
            },
            {
              name: "CAPINITAN",
            },
            {
              name: "DATA",
            },
            {
              name: "GAYANG",
            },
            {
              name: "LAGAN",
            },
            {
              name: "LOSAD",
            },
            {
              name: "NAMATEC",
            },
            {
              name: "NAPUA",
            },
            {
              name: "PINGAD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABANGAN",
            },
            {
              name: "SUPANG",
            },
            {
              name: "TAMBINGAN",
            },
          ],
        },
        SADANGA: {
          name: "SADANGA",
          baranggays: [
            {
              name: "ANABEL",
            },
            {
              name: "BEKIGAN",
            },
            {
              name: "BELWANG",
            },
            {
              name: "BETWAGAN",
            },
            {
              name: "DEMANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SACASACAN",
            },
            {
              name: "SACLIT",
            },
          ],
        },
        SAGADA: {
          name: "SAGADA",
          baranggays: [
            {
              name: "AGUID",
            },
            {
              name: "AMBASING",
            },
            {
              name: "ANGKELING",
            },
            {
              name: "ANTADAO",
            },
            {
              name: "BALUGAN",
            },
            {
              name: "BANGAAN",
            },
            {
              name: "DAGDAG (POB.)",
            },
            {
              name: "DEMANG (POB.)",
            },
            {
              name: "FIDELISAN",
            },
            {
              name: "KILONG",
            },
            {
              name: "MADONGO",
            },
            {
              name: "NACAGANG",
            },
            {
              name: "PIDE",
            },
            {
              name: "POBLACION (PATAY)",
            },
            {
              name: "SAGADA",
            },
            {
              name: "SUYO",
            },
            {
              name: "TACCONG",
            },
            {
              name: "TANULONG",
            },
            {
              name: "TETEPAN NORTE",
            },
            {
              name: "TETEPAN SUR",
            },
          ],
        },
        TADIAN: {
          name: "TADIAN",
          baranggays: [
            {
              name: "BALAOA",
            },
            {
              name: "BANAAO",
            },
            {
              name: "BANTEY",
            },
            {
              name: "BATAYAN",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CADAD-ANAN",
            },
            {
              name: "CAGUBATAN",
            },
            {
              name: "DACUDAC",
            },
            {
              name: "DUAGAN",
            },
            {
              name: "KAYAN EAST",
            },
            {
              name: "KAYAN WEST",
            },
            {
              name: "LENGA",
            },
            {
              name: "LUBON",
            },
            {
              name: "MABALITE",
            },
            {
              name: "MASLA",
            },
            {
              name: "PANDAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SUMADEL",
            },
            {
              name: "TUE",
            },
          ],
        },
      },
    },
    "NEGROS OCCIDENTAL": {
      name: "NEGROS OCCIDENTAL",
      region: "NIR REGION",
      municipalities: {
        "BACOLOD CITY": {
          name: "BACOLOD CITY",
          baranggays: [
            {
              name: "ALANGILAN",
            },
            {
              name: "ALIJIS",
            },
            {
              name: "BACOLOD CITY",
            },
            {
              name: "BANAGO",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 14 (POB.)",
            },
            {
              name: "BARANGAY 15 (POB.)",
            },
            {
              name: "BARANGAY 16 (POB.)",
            },
            {
              name: "BARANGAY 17 (POB.)",
            },
            {
              name: "BARANGAY 18 (POB.)",
            },
            {
              name: "BARANGAY 19 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 20 (POB.)",
            },
            {
              name: "BARANGAY 21 (POB.)",
            },
            {
              name: "BARANGAY 22 (POB.)",
            },
            {
              name: "BARANGAY 23 (POB.)",
            },
            {
              name: "BARANGAY 24 (POB.)",
            },
            {
              name: "BARANGAY 25 (POB.)",
            },
            {
              name: "BARANGAY 26 (POB.)",
            },
            {
              name: "BARANGAY 27 (POB.)",
            },
            {
              name: "BARANGAY 28 (POB.)",
            },
            {
              name: "BARANGAY 29 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 30 (POB.)",
            },
            {
              name: "BARANGAY 31 (POB.)",
            },
            {
              name: "BARANGAY 32 (POB.)",
            },
            {
              name: "BARANGAY 33 (POB.)",
            },
            {
              name: "BARANGAY 34 (POB.)",
            },
            {
              name: "BARANGAY 35 (POB.)",
            },
            {
              name: "BARANGAY 36 (POB.)",
            },
            {
              name: "BARANGAY 37 (POB.)",
            },
            {
              name: "BARANGAY 38 (POB.)",
            },
            {
              name: "BARANGAY 39 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 40 (POB.)",
            },
            {
              name: "BARANGAY 41 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BATA",
            },
            {
              name: "CABUG",
            },
            {
              name: "ESTEFANIA",
            },
            {
              name: "FELISA(proper only)",
            },
            {
              name: "GRANADA",
            },
            {
              name: "HANDUMANAN",
            },
            {
              name: "MANDALAGAN",
            },
            {
              name: "MANSILINGAN",
            },
            {
              name: "MONTEVISTA",
            },
            {
              name: "PAHANOCOY",
            },
            {
              name: "PUNTA TAYTAY",
            },
            {
              name: "SINGCANG-AIRPORT",
            },
            {
              name: "SUM-AG",
            },
            {
              name: "TACULING",
            },
            {
              name: "TANGUB",
            },
            {
              name: "VILLAMONTE",
            },
            {
              name: "VISTA ALEGRE",
            },
          ],
        },
        "BAGO CITY": {
          name: "BAGO CITY",
          baranggays: [
            {
              name: "ABUANAN",
            },
            {
              name: "ALIANZA",
            },
            {
              name: "ATIPULUAN",
            },
            {
              name: "BACONG-MONTILLA",
            },
            {
              name: "BAGO CITY",
            },
            {
              name: "BAGROY",
            },
            {
              name: "BALINGASAG",
            },
            {
              name: "BINUBUHAN",
            },
            {
              name: "BUSAY",
            },
            {
              name: "CALUMANGAN",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "DULAO",
            },
            {
              name: "ILIJAN",
            },
            {
              name: "JORGE L. ARANETA (MA-AO CENTRAL)",
            },
            {
              name: "LAG-ASAN",
            },
            {
              name: "MA-AO BARRIO",
            },
            {
              name: "MAILUM",
            },
            {
              name: "MALINGIN",
            },
            {
              name: "NAPOLES",
            },
            {
              name: "PACOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGASA",
            },
            {
              name: "SAMPINIT",
            },
            {
              name: "TABUNAN",
            },
            {
              name: "TALOC",
            },
          ],
        },
        BINALBAGAN: {
          name: "BINALBAGAN",
          baranggays: [
            {
              name: "AMONTAY",
            },
            {
              name: "BAGROY (PROPER ONLY)",
            },
            {
              name: "BI-AO",
            },
            {
              name: "BINALBAGAN",
            },
            {
              name: "CANMOROS (POB.)",
            },
            {
              name: "ENCLARO",
            },
            {
              name: "MARINA (POB.)",
            },
            {
              name: "PAGLAUM (POB.)",
            },
            {
              name: "PAYAO",
            },
            {
              name: "PROGRESO (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SAN TEODORO (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTO ROSARIO (POB.)",
            },
            {
              name: "SANTOL",
            },
          ],
        },
        "CADIZ CITY": {
          name: "CADIZ CITY",
          baranggays: [
            {
              name: "ANDRES BONIFACIO",
            },
            {
              name: "BANQUEROHAN",
            },
            {
              name: "BARANGAY 1 POB. (ZONE 1)",
            },
            {
              name: "BARANGAY 2 POB. (ZONE 2)",
            },
            {
              name: "BARANGAY 3 POB. (ZONE 3)",
            },
            {
              name: "BARANGAY 4 POB. (ZONE 4)",
            },
            {
              name: "BARANGAY 5 POB. (ZONE 5)",
            },
            {
              name: "BARANGAY 6 POB. (ZONE 6)",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CABAHUG",
            },
            {
              name: "CADIZ CITY",
            },
            {
              name: "CADIZ VIEJO",
            },
            {
              name: "CADUHA-AN",
            },
            {
              name: "CELESTINO VILLACIN",
            },
            {
              name: "DAGA",
            },
            {
              name: "JERUSALEM",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "SICABA",
            },
            {
              name: "TIGLAWIGAN",
            },
            {
              name: "TINAMPA-AN",
            },
          ],
        },
        CALATRAVA: {
          name: "CALATRAVA",
          baranggays: [
            {
              name: "AGPANGI",
            },
            {
              name: "ANI-E",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BANTAYANON",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABUNGAHAN",
            },
            {
              name: "CALAMPISAWAN",
            },
            {
              name: "CALATRAVA",
            },
            {
              name: "CAMBAYOBO",
            },
            {
              name: "CASTELLANO",
            },
            {
              name: "CRUZ",
            },
            {
              name: "DOLIS",
            },
            {
              name: "HILUB-ANG",
            },
            {
              name: "HINAB-ONGAN",
            },
            {
              name: "ILAYA",
            },
            {
              name: "LAGA-AN",
            },
            {
              name: "LALONG",
            },
            {
              name: "LEMERY",
            },
            {
              name: "LIPAT-ON",
            },
            {
              name: "LO-OK (POB.)",
            },
            {
              name: "MA-ASLOB",
            },
            {
              name: "MACASILAO",
            },
            {
              name: "MAHILUM",
            },
            {
              name: "MALANOG",
            },
            {
              name: "MALATAS",
            },
            {
              name: "MARCELO",
            },
            {
              name: "MENCHACA",
            },
            {
              name: "MINA-UTOK",
            },
            {
              name: "MINAPASUK",
            },
            {
              name: "PAGHUMAYAN",
            },
            {
              name: "PANTAO",
            },
            {
              name: "PATUN-AN",
            },
            {
              name: "PINOCUTAN",
            },
            {
              name: "REFUGIO",
            },
            {
              name: "SAN BENITO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SUBA (POB.)",
            },
            {
              name: "TELIM",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TIGBON",
            },
            {
              name: "WINASWASAN",
            },
          ],
        },
        CANDONI: {
          name: "CANDONI",
          baranggays: [
            {
              name: "AGBOY",
            },
            {
              name: "BANGA",
            },
            {
              name: "CABIA-AN",
            },
            {
              name: "CANDONI",
            },
            {
              name: "CANINGAY",
            },
            {
              name: "GATUSLAO",
            },
            {
              name: "HABA",
            },
            {
              name: "PAYAUAN",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
          ],
        },
        CAUAYAN: {
          name: "CAUAYAN",
          baranggays: [
            {
              name: "ABACA",
            },
            {
              name: "BACLAO",
            },
            {
              name: "BASAK",
            },
            {
              name: "BULATA",
            },
            {
              name: "CALILING",
            },
            {
              name: "CAMALANDA-AN",
            },
            {
              name: "CAMINDANGAN",
            },
            {
              name: "CAUAYAN",
            },
            {
              name: "ELIHAN",
            },
            {
              name: "GUILJUNGAN",
            },
            {
              name: "INAYAWAN",
            },
            {
              name: "ISIO",
            },
            {
              name: "LINAON",
            },
            {
              name: "LUMBIA",
            },
            {
              name: "MAMBUGSAY",
            },
            {
              name: "MAN-ULING",
            },
            {
              name: "MASALING",
            },
            {
              name: "MOLOBOLO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SURA",
            },
            {
              name: "TALACDAN",
            },
            {
              name: "TAMBAD",
            },
            {
              name: "TILING",
            },
            {
              name: "TOMINA",
            },
            {
              name: "TUYOM",
            },
            {
              name: "YAO-YAO",
            },
          ],
        },
        "ENRIQUE B. MAGALONA (SARAVIA)": {
          name: "ENRIQUE B. MAGALONA (SARAVIA)",
          baranggays: [
            {
              name: "ALACAYGAN",
            },
            {
              name: "ALICANTE",
            },
            {
              name: "BATEA",
            },
            {
              name: "CANLUSONG",
            },
            {
              name: "CONSING",
            },
            {
              name: "CUDANGDANG",
            },
            {
              name: "DAMGO",
            },
            {
              name: "ENRIQUE B. MAGALONA (SARAVIA)",
            },
            {
              name: "GAHIT",
            },
            {
              name: "LATASAN",
            },
            {
              name: "MADALAG",
            },
            {
              name: "MANTA-ANGAN",
            },
            {
              name: "NANCA",
            },
            {
              name: "PASIL",
            },
            {
              name: "POBLACION I (BARANGAY 1)",
            },
            {
              name: "POBLACION II (BARANGAY 2)",
            },
            {
              name: "POBLACION III (BARANGAY 3)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TABIGUE",
            },
            {
              name: "TANZA",
            },
            {
              name: "TOMONGTONG",
            },
            {
              name: "TUBURAN",
            },
          ],
        },
        "ESCALANTE CITY": {
          name: "ESCALANTE CITY",
          baranggays: [
            {
              name: "ALIMANGO",
            },
            {
              name: "BALINTAWAK (POB.)",
            },
            {
              name: "BINAGUIOHAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CERVANTES",
            },
            {
              name: "DIAN-AY",
            },
            {
              name: "ESCALANTE CITY",
            },
            {
              name: "HACIENDA FE",
            },
            {
              name: "JAPITAN",
            },
            {
              name: "JONOBJONOB",
            },
            {
              name: "LANGUB",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY (BINABONGOL)",
            },
            {
              name: "MALASIBOG",
            },
            {
              name: "OLD POBLACION",
            },
            {
              name: "PAITAN",
            },
            {
              name: "PINAPUGASAN",
            },
            {
              name: "RIZAL",
            },
            {
              name: "TAMLANG",
            },
            {
              name: "UDTONGAN",
            },
            {
              name: "WASHINGTON",
            },
          ],
        },
        "HIMAMAYLAN CITY": {
          name: "HIMAMAYLAN CITY",
          baranggays: [
            {
              name: "AGUISAN",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CARABALAN",
            },
            {
              name: "CARADIO-AN",
            },
            {
              name: "HIMAMAYLAN CITY",
            },
            {
              name: "LIBACAO",
            },
            {
              name: "MAHALANG",
            },
            {
              name: "MAMBAGATON",
            },
            {
              name: "NABALI-AN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SARA-ET",
            },
            {
              name: "SU-AY",
            },
            {
              name: "TALABAN",
            },
            {
              name: "TO-OY",
            },
          ],
        },
        HINIGARAN: {
          name: "HINIGARAN",
          baranggays: [
            {
              name: "ANAHAW",
            },
            {
              name: "ARANDA",
            },
            {
              name: "BAGA-AS",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BATO",
            },
            {
              name: "CALAPI",
            },
            {
              name: "CAMALOBALO",
            },
            {
              name: "CAMBA-OG",
            },
            {
              name: "CAMBUGSA",
            },
            {
              name: "CANDUMARAO",
            },
            {
              name: "GARGATO",
            },
            {
              name: "HIMAYA",
            },
            {
              name: "HINIGARAN",
            },
            {
              name: "MIRANDA",
            },
            {
              name: "NANUNGA",
            },
            {
              name: "NARAUIS",
            },
            {
              name: "PALAYOG",
            },
            {
              name: "PATICUI",
            },
            {
              name: "PILAR",
            },
            {
              name: "QUIWI",
            },
            {
              name: "TAGDA",
            },
            {
              name: "TUGUIS",
            },
          ],
        },
        "HINOBA-AN (ASIA)": {
          name: "HINOBA-AN (ASIA)",
          baranggays: [
            {
              name: "ALIM",
            },
            {
              name: "ASIA",
            },
            {
              name: "BACUYANGAN",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BULWANGAN",
            },
            {
              name: "CULIPAPA",
            },
            {
              name: "DAMUTAN",
            },
            {
              name: "DAUG",
            },
            {
              name: "HINOBA-AN (ASIA)",
            },
            {
              name: "PO-OK",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANGKE",
            },
            {
              name: "TALACAGAY",
            },
          ],
        },
        ILOG: {
          name: "ILOG",
          baranggays: [
            {
              name: "ANDULAUAN",
            },
            {
              name: "BALICOTOC",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BOCANA",
            },
            {
              name: "CALUBANG",
            },
            {
              name: "CANLAMAY",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "DANCALAN",
            },
            {
              name: "DELICIOSO",
            },
            {
              name: "GALICIA",
            },
            {
              name: "ILOG",
            },
            {
              name: "MANALAD",
            },
            {
              name: "PINGGOT",
            },
            {
              name: "TABU",
            },
            {
              name: "VISTA ALEGRE",
            },
          ],
        },
        ISABELA: {
          name: "ISABELA",
          baranggays: [
            {
              name: "AMIN",
            },
            {
              name: "BANOGBANOG",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BULAD",
            },
            {
              name: "BUNGAHIN",
            },
            {
              name: "CABCAB",
            },
            {
              name: "CAMANGCAMANG",
            },
            {
              name: "CAMP CLARK",
            },
            {
              name: "CANSALONGON",
            },
            {
              name: "GUINTUBHAN",
            },
            {
              name: "ISABELA",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LIMALIMA",
            },
            {
              name: "MAKILIGNIT",
            },
            {
              name: "MANSABLAY",
            },
            {
              name: "MAYTUBIG",
            },
            {
              name: "PANAQUIAO",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "RUMIRANG",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SEBUCAWAN",
            },
            {
              name: "SIKATUNA",
            },
            {
              name: "TINONGAN",
            },
          ],
        },
        "KABANKALAN CITY": {
          name: "KABANKALAN CITY",
          baranggays: [
            {
              name: "BANTAYAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BINICUIL",
            },
            {
              name: "CAMANSI",
            },
            {
              name: "CAMINGAWAN",
            },
            {
              name: "CAMUGAO",
            },
            {
              name: "CAROL-AN",
            },
            {
              name: "DAAN BANUA",
            },
            {
              name: "HILAMONAN",
            },
            {
              name: "INAPOY",
            },
            {
              name: "KABANKALAN CITY",
            },
            {
              name: "LINAO",
            },
            {
              name: "LOCOTAN",
            },
            {
              name: "MAGBALLO",
            },
            {
              name: "ORINGAO",
            },
            {
              name: "ORONG",
            },
            {
              name: "PINAGUINPINAN",
            },
            {
              name: "SALONG",
            },
            {
              name: "TABUGON",
            },
            {
              name: "TAGOC",
            },
            {
              name: "TAGUKON",
            },
            {
              name: "TALUBANGI",
            },
            {
              name: "TAMPALON",
            },
            {
              name: "TAN-AWAN",
            },
            {
              name: "TAPI",
            },
          ],
        },
        "LA CARLOTA CITY": {
          name: "LA CARLOTA CITY",
          baranggays: [
            {
              name: "ARA-AL",
            },
            {
              name: "AYUNGON",
            },
            {
              name: "BALABAG",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BATUAN",
            },
            {
              name: "CUBAY",
            },
            {
              name: "HAGUIMIT",
            },
            {
              name: "LA CARLOTA CITY",
            },
            {
              name: "LA GRANJA",
            },
            {
              name: "NAGASI",
            },
            {
              name: "ROBERTO S. BENEDICTO (CONSUELO)",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "YUBO",
            },
          ],
        },
        "LA CASTELLANA": {
          name: "LA CASTELLANA",
          baranggays: [
            {
              name: "BIAKNABATO",
            },
            {
              name: "CABACUNGAN",
            },
            {
              name: "CABAGNAAN",
            },
            {
              name: "CAMANDAG",
            },
            {
              name: "LA CASTELLANA",
            },
            {
              name: "LALAGSAN",
            },
            {
              name: "MANGHANOY",
            },
            {
              name: "MANSALANAO",
            },
            {
              name: "MASULOG",
            },
            {
              name: "NATO",
            },
            {
              name: "PUSO",
            },
            {
              name: "ROBLES (POB.)",
            },
            {
              name: "SAG-ANG",
            },
            {
              name: "TALAPTAP",
            },
          ],
        },
        MANAPLA: {
          name: "MANAPLA",
          baranggays: [
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY I-A (POB.)",
            },
            {
              name: "BARANGAY I-B (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY II-A (POB.)",
            },
            {
              name: "CHAMBERY",
            },
            {
              name: "MANAPLA",
            },
            {
              name: "PUNTA MESA",
            },
            {
              name: "PUNTA SALONG",
            },
            {
              name: "PURISIMA",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SANTA TERESA",
            },
            {
              name: "TORTOSA",
            },
          ],
        },
        "MOISES PADILLA (MAGALLON)": {
          name: "MOISES PADILLA (MAGALLON)",
          baranggays: [
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "CROSSING MAGALLON",
            },
            {
              name: "GUINPANA-AN",
            },
            {
              name: "INOLINGAN",
            },
            {
              name: "MACAGAHAY",
            },
            {
              name: "MAGALLON CADRE",
            },
            {
              name: "MOISES PADILLA (MAGALLON)",
            },
            {
              name: "MONTILLA",
            },
            {
              name: "ODIONG",
            },
            {
              name: "QUINTIN REMO",
            },
          ],
        },
        MURCIA: {
          name: "MURCIA",
          baranggays: [
            {
              name: "ABO-ABO",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "AMAYCO",
            },
            {
              name: "BLUMENTRITT",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CALIBAN",
            },
            {
              name: "CANLANDOG",
            },
            {
              name: "CANSILAYAN",
            },
            {
              name: "DAMSITE",
            },
            {
              name: "IGLAU-AN",
            },
            {
              name: "LOPEZ JAENA",
            },
            {
              name: "MINOYAN",
            },
            {
              name: "MURCIA",
            },
            {
              name: "PANDANON",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "TALOTOG",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
            {
              name: "ZONE V (POB.)",
            },
          ],
        },
        PONTEVEDRA: {
          name: "PONTEVEDRA",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BUENAVISTA GIBONG",
            },
            {
              name: "BUENAVISTA RIZAL",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CAMBARUS",
            },
            {
              name: "CANROMA",
            },
            {
              name: "DON SALVADOR BENEDICTO (CARMEN)",
            },
            {
              name: "GENERAL MALVAR",
            },
            {
              name: "GOMEZ",
            },
            {
              name: "M. H. DEL PILAR",
            },
            {
              name: "MABINI",
            },
            {
              name: "MIRANDA",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PONTEVEDRA",
            },
            {
              name: "RECREO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "ZAMORA",
            },
          ],
        },
        PULUPANDAN: {
          name: "PULUPANDAN",
          baranggays: [
            {
              name: "BARANGAY ZONE 1 (POB.)",
            },
            {
              name: "BARANGAY ZONE 1-A (POB.)",
            },
            {
              name: "BARANGAY ZONE 2 (POB.)",
            },
            {
              name: "BARANGAY ZONE 3 (POB.)",
            },
            {
              name: "BARANGAY ZONE 4 (POB.)",
            },
            {
              name: "BARANGAY ZONE 4-A (POB.)",
            },
            {
              name: "BARANGAY ZONE 5 (POB.)",
            },
            {
              name: "BARANGAY ZONE 6 (POB.)",
            },
            {
              name: "BARANGAY ZONE 7 (POB.)",
            },
            {
              name: "CANJUSA",
            },
            {
              name: "CROSSING PULUPANDAN",
            },
            {
              name: "CULO",
            },
            {
              name: "MABINI",
            },
            {
              name: "PAG-AYON",
            },
            {
              name: "PALAKA NORTE",
            },
            {
              name: "PALAKA SUR",
            },
            {
              name: "PATIC",
            },
            {
              name: "PULUPANDAN",
            },
            {
              name: "TAPONG",
            },
            {
              name: "UBAY",
            },
            {
              name: "UTOD",
            },
          ],
        },
        "SAGAY CITY": {
          name: "SAGAY CITY",
          baranggays: [
            {
              name: "ANDRES BONIFACIO",
            },
            {
              name: "BATO",
            },
            {
              name: "BAVIERA",
            },
            {
              name: "BULANON",
            },
            {
              name: "CAMPO HIMOGA-AN",
            },
            {
              name: "CAMPO SANTIAGO",
            },
            {
              name: "COLONIA DIVINA",
            },
            {
              name: "FABRICA",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "HIMOGA-AN BAYBAY",
            },
            {
              name: "LOPEZ JAENA",
            },
            {
              name: "MAKILING",
            },
            {
              name: "MALUBON",
            },
            {
              name: "MOLOCABOC",
            },
            {
              name: "OLD SAGAY",
            },
            {
              name: "PARAISO",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POBLACION I (BARANGAY 1)",
            },
            {
              name: "POBLACION II (BARANGAY 2)",
            },
            {
              name: "PUEY",
            },
            {
              name: "RAFAELA BARRERA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAGAY CITY",
            },
            {
              name: "TABA-AO",
            },
            {
              name: "TADLONG",
            },
            {
              name: "VITO",
            },
          ],
        },
        "SALVADOR BENEDICTO": {
          name: "SALVADOR BENEDICTO",
          baranggays: [
            {
              name: "BAGO (LALUNG)",
            },
            {
              name: "BAGONG SILANG (MARCELO)",
            },
            {
              name: "BUNGA",
            },
            {
              name: "IGMAYA-AN",
            },
            {
              name: "KUMALISKIS",
            },
            {
              name: "PANDANON",
            },
            {
              name: "PINOWAYAN (PROSPERIDAD)",
            },
            {
              name: "SALVADOR BENEDICTO",
            },
          ],
        },
        "SAN CARLOS CITY": {
          name: "SAN CARLOS CITY",
          baranggays: [
            {
              name: "BAGONBON",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "BULUANGAN",
            },
            {
              name: "CODCOD",
            },
            {
              name: "ERMITA",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "NATABAN",
            },
            {
              name: "PALAMPAS",
            },
            {
              name: "PROSPERIDAD",
            },
            {
              name: "PUNAO",
            },
            {
              name: "QUEZON",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN CARLOS CITY",
            },
            {
              name: "SAN JUAN",
            },
          ],
        },
        "SAN ENRIQUE": {
          name: "SAN ENRIQUE",
          baranggays: [
            {
              name: "BAGONAWA",
            },
            {
              name: "BALIWAGAN",
            },
            {
              name: "BATUAN",
            },
            {
              name: "GUINTORILAN",
            },
            {
              name: "NAYON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ENRIQUE",
            },
            {
              name: "SIBUCAO",
            },
            {
              name: "TABAO BAYBAY",
            },
            {
              name: "TABAO RIZAL",
            },
            {
              name: "TIBSOC",
            },
          ],
        },
        "SILAY CITY": {
          name: "SILAY CITY",
          baranggays: [
            {
              name: "BAGTIC",
            },
            {
              name: "BALARING",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI POB. (HAWAIIAN)",
            },
            {
              name: "EUSTAQUIO LOPEZ",
            },
            {
              name: "GUIMBALA-ON",
            },
            {
              name: "GUINHALARAN",
            },
            {
              name: "KAPITAN RAMON",
            },
            {
              name: "LANTAD",
            },
            {
              name: "MAMBULAC",
            },
            {
              name: "PATAG",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SILAY CITY",
            },
          ],
        },
        "SIPALAY CITY": {
          name: "SIPALAY CITY",
          baranggays: [
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CAMINDANGAN",
            },
            {
              name: "CANTURAY",
            },
            {
              name: "CARTAGENA",
            },
            {
              name: "CAYHAGAN",
            },
            {
              name: "GIL MONTILLA",
            },
            {
              name: "MAMBAROTO",
            },
            {
              name: "MANLUCAHOC",
            },
            {
              name: "MARICALUM",
            },
            {
              name: "NABULAO",
            },
            {
              name: "NAUHANG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SIPALAY CITY",
            },
          ],
        },
        "TALISAY CITY": {
          name: "TALISAY CITY",
          baranggays: [
            {
              name: "BUBOG",
            },
            {
              name: "CABATANGAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DOS HERMANAS",
            },
            {
              name: "EFIGENIO LIZARES",
            },
            {
              name: "KATILINGBAN",
            },
            {
              name: "MATAB-ANG",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "TALISAY CITY",
            },
            {
              name: "ZONE 1 (POB.)",
            },
            {
              name: "ZONE 10 (POB.)",
            },
            {
              name: "ZONE 11 (POB.)",
            },
            {
              name: "ZONE 12 (POB.)",
            },
            {
              name: "ZONE 12-A (POB.)",
            },
            {
              name: "ZONE 14 (POB.)",
            },
            {
              name: "ZONE 14-A (POB.)",
            },
            {
              name: "ZONE 14-B (POB.)",
            },
            {
              name: "ZONE 15 (POB.)",
            },
            {
              name: "ZONE 16 (POB.)",
            },
            {
              name: "ZONE 2 (POB.)",
            },
            {
              name: "ZONE 3 (POB.)",
            },
            {
              name: "ZONE 4 (POB.)",
            },
            {
              name: "ZONE 4-A (POB.)",
            },
            {
              name: "ZONE 5 (POB.)",
            },
            {
              name: "ZONE 6 (POB.)",
            },
            {
              name: "ZONE 7 (POB.)",
            },
            {
              name: "ZONE 8 (POB.)",
            },
            {
              name: "ZONE 9 (POB.)",
            },
          ],
        },
        TOBOSO: {
          name: "TOBOSO",
          baranggays: [
            {
              name: "BANDILA",
            },
            {
              name: "BUG-ANG",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "MAGTICOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAMANCA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TABUN-AC",
            },
            {
              name: "TOBOSO",
            },
          ],
        },
        VALLADOLID: {
          name: "VALLADOLID",
          baranggays: [
            {
              name: "ALIJIS",
            },
            {
              name: "AYUNGON",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "CENTRAL TABAO",
            },
            {
              name: "DOLDOL",
            },
            {
              name: "GUINTORILAN",
            },
            {
              name: "LACARON",
            },
            {
              name: "MABINI",
            },
            {
              name: "PACOL",
            },
            {
              name: "PALAKA",
            },
            {
              name: "PALOMA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGUA BANUA",
            },
            {
              name: "TABAO PROPER",
            },
            {
              name: "VALLADOLID",
            },
          ],
        },
        "VICTORIAS CITY": {
          name: "VICTORIAS CITY",
          baranggays: [
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY IX (DAAN BANWA)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "BARANGAY VI-A",
            },
            {
              name: "BARANGAY VII (POB.)",
            },
            {
              name: "BARANGAY VIII (POB.)",
            },
            {
              name: "BARANGAY X (ESTADO)",
            },
            {
              name: "BARANGAY XI (GAWAHON)",
            },
            {
              name: "BARANGAY XII",
            },
            {
              name: "BARANGAY XIII",
            },
            {
              name: "BARANGAY XIV",
            },
            {
              name: "BARANGAY XIX",
            },
            {
              name: "BARANGAY XIX-A",
            },
            {
              name: "BARANGAY XV",
            },
            {
              name: "BARANGAY XV-A",
            },
            {
              name: "BARANGAY XVI",
            },
            {
              name: "BARANGAY XVI-A",
            },
            {
              name: "BARANGAY XVII",
            },
            {
              name: "BARANGAY XVIII",
            },
            {
              name: "BARANGAY XVIII-A",
            },
            {
              name: "BARANGAY XX",
            },
            {
              name: "BARANGAY XXI",
            },
            {
              name: "VICTORIAS CITY",
            },
          ],
        },
      },
    },
    "NEGROS ORIENTAL": {
      name: "NEGROS ORIENTAL",
      region: "NIR REGION",
      municipalities: {
        "AMLAN (AYUQUITAN)": {
          name: "AMLAN (AYUQUITAN)",
          baranggays: [
            {
              name: "AMLAN (AYUQUITAN)",
            },
            {
              name: "BIO-OS",
            },
            {
              name: "JANTIANON",
            },
            {
              name: "JUGNO",
            },
            {
              name: "MAG-ABO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SILAB",
            },
            {
              name: "TAMBOJANGIN",
            },
            {
              name: "TANDAYAG",
            },
          ],
        },
        AYUNGON: {
          name: "AYUNGON",
          baranggays: [
            {
              name: "AMDUS",
            },
            {
              name: "ANIBONG",
            },
            {
              name: "ATABAY",
            },
            {
              name: "AWA-AN",
            },
            {
              name: "AYUNGON",
            },
            {
              name: "BAN-BAN",
            },
            {
              name: "CALAGCALAG",
            },
            {
              name: "CANDANA-AY",
            },
            {
              name: "CAROL-AN",
            },
            {
              name: "GOMENTOC",
            },
            {
              name: "INACBAN",
            },
            {
              name: "INIBAN",
            },
            {
              name: "JANDALAMANON",
            },
            {
              name: "KILABAN",
            },
            {
              name: "LAMIGAN",
            },
            {
              name: "MAASLUM",
            },
            {
              name: "MABATO",
            },
            {
              name: "MANOGTONG",
            },
            {
              name: "NABHANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAMBO",
            },
            {
              name: "TAMPOCON I",
            },
            {
              name: "TAMPOCON II",
            },
            {
              name: "TIBYAWAN",
            },
            {
              name: "TIGUIB",
            },
          ],
        },
        BACONG: {
          name: "BACONG",
          baranggays: [
            {
              name: "BACONG",
            },
            {
              name: "BALAYAGMANOK",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BUNTIS",
            },
            {
              name: "BUNTOD",
            },
            {
              name: "CALANGAG",
            },
            {
              name: "COMBADO",
            },
            {
              name: "DOLDOL",
            },
            {
              name: "ISUGAN",
            },
            {
              name: "LIPTONG",
            },
            {
              name: "LUTAO",
            },
            {
              name: "MAGSUHOT",
            },
            {
              name: "MALABAGO",
            },
            {
              name: "MAMPAS",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "SACSAC",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "SULODPAN",
            },
            {
              name: "TIMBANGA",
            },
            {
              name: "TIMBAO",
            },
            {
              name: "TUBOD",
            },
            {
              name: "WEST POBLACION",
            },
          ],
        },
        "BAIS CITY": {
          name: "BAIS CITY",
          baranggays: [
            {
              name: "BAIS CITY",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BASAK",
            },
            {
              name: "BIÑOHON",
            },
            {
              name: "CABANLUTAN",
            },
            {
              name: "CALASGA-AN",
            },
            {
              name: "CAMBAGAHAN",
            },
            {
              name: "CAMBAGUIO",
            },
            {
              name: "CAMBANJAO",
            },
            {
              name: "CAMBUILAO",
            },
            {
              name: "CANLARGO",
            },
            {
              name: "CAPIÑAHAN",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "DANSULAN",
            },
            {
              name: "HANGYAD",
            },
            {
              name: "KATACGAHAN (TACGAHAN)",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LO-OC",
            },
            {
              name: "LONOY",
            },
            {
              name: "MABUNAO",
            },
            {
              name: "MANLIPAC",
            },
            {
              name: "MANSANGABAN",
            },
            {
              name: "OKIOT",
            },
            {
              name: "OLYMPIA",
            },
            {
              name: "PANALA-AN",
            },
            {
              name: "PANAM-ANGAN",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAB-AHAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "TAGPO",
            },
            {
              name: "TALUNGON",
            },
            {
              name: "TAMISU",
            },
            {
              name: "TAMOGONG",
            },
            {
              name: "TANGCULOGAN",
            },
            {
              name: "VALENCIA",
            },
          ],
        },
        BASAY: {
          name: "BASAY",
          baranggays: [
            {
              name: "ACTIN",
            },
            {
              name: "BAL-OS",
            },
            {
              name: "BASAY",
            },
            {
              name: "BONGALONAN",
            },
            {
              name: "CABALAYONGAN",
            },
            {
              name: "CABATUANAN",
            },
            {
              name: "LINANTAYAN",
            },
            {
              name: "MAGLINAO",
            },
            {
              name: "NAGBO-ALAO",
            },
            {
              name: "OLANDAO",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        "BAYAWAN CITY (TULONG)": {
          name: "BAYAWAN CITY (TULONG)",
          baranggays: [
            {
              name: "ALI-IS",
            },
            {
              name: "BANAYBANAY",
            },
            {
              name: "BANGA",
            },
            {
              name: "BAYAWAN CITY (TULONG)",
            },
            {
              name: "BOYCO",
            },
            {
              name: "BUGAY",
            },
            {
              name: "CANSUMALIG",
            },
            {
              name: "DAWIS",
            },
            {
              name: "KALAMTUKAN",
            },
            {
              name: "KALUMBOYAN",
            },
            {
              name: "MALABUGAS",
            },
            {
              name: "MANDU-AO",
            },
            {
              name: "MANINIHON",
            },
            {
              name: "MINABA",
            },
            {
              name: "NANGKA",
            },
            {
              name: "NARRA",
            },
            {
              name: "PAGATBAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SUBA (POB.)",
            },
            {
              name: "TABUAN",
            },
            {
              name: "TAYAWAN",
            },
            {
              name: "TINAGO (POB.)",
            },
            {
              name: "UBOS (POB.)",
            },
            {
              name: "VILLAREAL",
            },
            {
              name: "VILLASOL (BATO)",
            },
          ],
        },
        "BINDOY (PAYABON)": {
          name: "BINDOY (PAYABON)",
          baranggays: [
            {
              name: "ATOTES",
            },
            {
              name: "BATANGAN",
            },
            {
              name: "BINDOY (PAYABON)",
            },
            {
              name: "BULOD",
            },
            {
              name: "CABCABAN",
            },
            {
              name: "CABUGAN",
            },
            {
              name: "CAMUDLAS",
            },
            {
              name: "CANLUTO",
            },
            {
              name: "DANAO",
            },
            {
              name: "DANAWAN",
            },
            {
              name: "DOMOLOG",
            },
            {
              name: "MALAGA",
            },
            {
              name: "MANSEJE",
            },
            {
              name: "MATOBATO",
            },
            {
              name: "NAGCASUNOG",
            },
            {
              name: "NALUNDAN",
            },
            {
              name: "PANGALAYCAYAN",
            },
            {
              name: "PEÑAHAN",
            },
            {
              name: "POBLACION (PAYABON)",
            },
            {
              name: "SALONG",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "TINAOGAN",
            },
            {
              name: "TUBOD",
            },
          ],
        },
        "CANLAON CITY": {
          name: "CANLAON CITY",
          baranggays: [
            {
              name: "BAYOG",
            },
            {
              name: "BINALBAGAN",
            },
            {
              name: "BUCALAN (EAST BUDSALAN)",
            },
            {
              name: "BUDLASAN (WEST BUDLASAN)",
            },
            {
              name: "CANLAON CITY",
            },
            {
              name: "LINOTHANGAN",
            },
            {
              name: "LUMAPAO",
            },
            {
              name: "MABIGO (POB.)",
            },
            {
              name: "MALAIBA",
            },
            {
              name: "MASULOG",
            },
            {
              name: "NINOY AQUINO",
            },
            {
              name: "PANUBIGAN",
            },
            {
              name: "PULA",
            },
          ],
        },
        DAUIN: {
          name: "DAUIN",
          baranggays: [
            {
              name: "ANAHAWAN",
            },
            {
              name: "APO ISLAND",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BASLAY",
            },
            {
              name: "BATUHON DACU",
            },
            {
              name: "BOLOC-BOLOC",
            },
            {
              name: "BULAK",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CASILE",
            },
            {
              name: "DAUIN",
            },
            {
              name: "LIBJO",
            },
            {
              name: "LIPAYO",
            },
            {
              name: "MAAYONGTUBIG",
            },
            {
              name: "MAG-ASO",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALONGCAY DACU",
            },
            {
              name: "MASAPLOD NORTE",
            },
            {
              name: "MASAPLOD SUR",
            },
            {
              name: "PANUBTUBAN",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "TUGAWE",
            },
            {
              name: "TUNGA-TUNGA",
            },
          ],
        },
        "DUMAGUETE CITY": {
          name: "DUMAGUETE CITY",
          baranggays: [
            {
              name: "BAGACAY",
            },
            {
              name: "BAJUMPANDAN",
            },
            {
              name: "BALUGO",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BATINGUEL",
            },
            {
              name: "BUNAO",
            },
            {
              name: "CADAWINONAN",
            },
            {
              name: "CALINDAGAN",
            },
            {
              name: "CAMANJAC",
            },
            {
              name: "CANDAU-AY",
            },
            {
              name: "CANTIL-E",
            },
            {
              name: "DARO",
            },
            {
              name: "DUMAGUETE CITY",
            },
            {
              name: "JUNOB",
            },
            {
              name: "LOOC",
            },
            {
              name: "MANGNAO-CANAL",
            },
            {
              name: "MOTONG",
            },
            {
              name: "PIAPI",
            },
            {
              name: "POBLACION NO. 1 (BARANGAY 1)",
            },
            {
              name: "POBLACION NO. 2 (BARANGAY 2)",
            },
            {
              name: "POBLACION NO. 3 (BARANGAY 3)",
            },
            {
              name: "POBLACION NO. 4 (BARANGAY 4)",
            },
            {
              name: "POBLACION NO. 5 (BARANGAY 5)",
            },
            {
              name: "POBLACION NO. 6 (BARANGAY 6)",
            },
            {
              name: "POBLACION NO. 7 (BARANGAY 7)",
            },
            {
              name: "POBLACION NO. 8 (BARANGAY 8)",
            },
            {
              name: "PULANTUBIG",
            },
            {
              name: "TABUCTUBIG",
            },
            {
              name: "TACLOBO",
            },
            {
              name: "TALAY",
            },
          ],
        },
        "GUIHULNGAN CITY": {
          name: "GUIHULNGAN CITY",
          baranggays: [
            {
              name: "BAKID",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BANWAQUE",
            },
            {
              name: "BASAK",
            },
            {
              name: "BINOBOHAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULADO",
            },
            {
              name: "CALAMBA",
            },
            {
              name: "CALUPA-AN",
            },
            {
              name: "GUIHULNGAN CITY",
            },
            {
              name: "HIBAIYO",
            },
            {
              name: "HILAITAN",
            },
            {
              name: "HINAKPAN",
            },
            {
              name: "HUMAYHUMAY",
            },
            {
              name: "IMELDA",
            },
            {
              name: "KAGAWASAN",
            },
            {
              name: "LINANTUYAN",
            },
            {
              name: "LUZ",
            },
            {
              name: "MABUNGA",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALUSAY",
            },
            {
              name: "MANIAK",
            },
            {
              name: "MCKINLEY",
            },
            {
              name: "NAGSAHA",
            },
            {
              name: "PADRE ZAMORA",
            },
            {
              name: "PLAGATASANON",
            },
            {
              name: "PLANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANDAYAO",
            },
            {
              name: "TACPAO",
            },
            {
              name: "TINAYUNAN BEACH",
            },
            {
              name: "TINAYUNAN HILL",
            },
            {
              name: "TRINIDAD",
            },
            {
              name: "VILLEGAS",
            },
          ],
        },
        JIMALALUD: {
          name: "JIMALALUD",
          baranggays: [
            {
              name: "AGLAHUG",
            },
            {
              name: "AGUTAYON",
            },
            {
              name: "APANANGON",
            },
            {
              name: "BAE",
            },
            {
              name: "BALA-AS",
            },
            {
              name: "BANGCAL",
            },
            {
              name: "BANOG",
            },
            {
              name: "BUTO",
            },
            {
              name: "CABANG",
            },
            {
              name: "CAMANDAYON",
            },
            {
              name: "CANGHARAY",
            },
            {
              name: "CANLAHAO",
            },
            {
              name: "DAYOYO",
            },
            {
              name: "ELI",
            },
            {
              name: "JIMALALUD",
            },
            {
              name: "LACAON",
            },
            {
              name: "MAHANLUD",
            },
            {
              name: "MALABAGO",
            },
            {
              name: "MAMBAID",
            },
            {
              name: "MONGPONG",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "OWACAN",
            },
            {
              name: "PACUAN",
            },
            {
              name: "PANGLAYA-AN",
            },
            {
              name: "POLOPANTAO",
            },
            {
              name: "SAMPINITON",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TALAMBAN",
            },
            {
              name: "TAMAO",
            },
          ],
        },
        "LA LIBERTAD": {
          name: "LA LIBERTAD",
          baranggays: [
            {
              name: "ANINIAW",
            },
            {
              name: "AYA",
            },
            {
              name: "BAGTIC",
            },
            {
              name: "BIGA-A",
            },
            {
              name: "BUSILAK",
            },
            {
              name: "CANGABO",
            },
            {
              name: "CANTUPA",
            },
            {
              name: "ELECIA (TALOSTOS)",
            },
            {
              name: "ELI",
            },
            {
              name: "GUIHOB",
            },
            {
              name: "KANSUMANDIG",
            },
            {
              name: "LA LIBERTAD",
            },
            {
              name: "MAMBULOD",
            },
            {
              name: "MANDAPATON",
            },
            {
              name: "MANGHULYAWON",
            },
            {
              name: "MANLUMINSAG",
            },
            {
              name: "MAPALASAN",
            },
            {
              name: "MARAGONDONG",
            },
            {
              name: "MARTILO",
            },
            {
              name: "NASUNGAN",
            },
            {
              name: "PACUAN",
            },
            {
              name: "PANGCA",
            },
            {
              name: "PISONG",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SOLONGON",
            },
            {
              name: "TALA-ON",
            },
            {
              name: "TALAYONG",
            },
          ],
        },
        MABINAY: {
          name: "MABINAY",
          baranggays: [
            {
              name: "ABIS",
            },
            {
              name: "AREBASORE",
            },
            {
              name: "BAGTIC",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BARRAS",
            },
            {
              name: "BATO",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "BULIBULIHAN",
            },
            {
              name: "BULWANG",
            },
            {
              name: "CAMPANUN-AN",
            },
            {
              name: "CANGGOHOB",
            },
            {
              name: "CANSAL-ING",
            },
            {
              name: "DAGBASAN",
            },
            {
              name: "DAHILE",
            },
            {
              name: "HAGTU",
            },
            {
              name: "HIMOCDONGON",
            },
            {
              name: "INAPOY",
            },
            {
              name: "LAMDAS",
            },
            {
              name: "LUMBANGAN",
            },
            {
              name: "LUYANG",
            },
            {
              name: "MABINAY",
            },
            {
              name: "MANLINGAY",
            },
            {
              name: "MAYAPOSI",
            },
            {
              name: "NAPASU-AN",
            },
            {
              name: "NEW NAMANGKA",
            },
            {
              name: "OLD NAMANGKA",
            },
            {
              name: "PANDANON",
            },
            {
              name: "PANIABONAN",
            },
            {
              name: "PANTAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMAC",
            },
            {
              name: "TADLONG",
            },
            {
              name: "TARA",
            },
          ],
        },
        MANJUYOD: {
          name: "MANJUYOD",
          baranggays: [
            {
              name: "ALANGILANAN",
            },
            {
              name: "BAGTIC",
            },
            {
              name: "BALAAS",
            },
            {
              name: "BANTOLINAO",
            },
            {
              name: "BOLISONG",
            },
            {
              name: "BUTONG",
            },
            {
              name: "CAMPUYO",
            },
            {
              name: "CANDABONG",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DUNGO-AN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LAMOGONG",
            },
            {
              name: "LIBJO",
            },
            {
              name: "MAASLUM",
            },
            {
              name: "MANDALUPANG",
            },
            {
              name: "MANJUYOD",
            },
            {
              name: "PANCIAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAC-SAC",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SUBA",
            },
            {
              name: "SUNDO-AN",
            },
            {
              name: "TANGLAD",
            },
            {
              name: "TUBOD",
            },
            {
              name: "TUPAS",
            },
          ],
        },
        PAMPLONA: {
          name: "PAMPLONA",
          baranggays: [
            {
              name: "ABANTE",
            },
            {
              name: "BALAYONG",
            },
            {
              name: "BANAWE",
            },
            {
              name: "CALICANAN",
            },
            {
              name: "DATAGON",
            },
            {
              name: "FATIMA",
            },
            {
              name: "INAWASAN",
            },
            {
              name: "MAGSUSUNOG",
            },
            {
              name: "MALALANGSI",
            },
            {
              name: "MAMBURAO",
            },
            {
              name: "MANGOTO",
            },
            {
              name: "PAMPLONA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA AGUEDA",
            },
            {
              name: "SIMBORIO",
            },
            {
              name: "YUPISAN",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "BASAK",
            },
            {
              name: "BASIAO",
            },
            {
              name: "CAMBALOCTOT",
            },
            {
              name: "CANCAWAS",
            },
            {
              name: "JANAYJANAY",
            },
            {
              name: "JILOCON",
            },
            {
              name: "NAIBA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SEÑORA ASCION (CALO)",
            },
            {
              name: "SIAPO",
            },
            {
              name: "TAMPI",
            },
            {
              name: "TAPON NORTE",
            },
          ],
        },
        "SANTA CATALINA": {
          name: "SANTA CATALINA",
          baranggays: [
            {
              name: "ALANGILAN",
            },
            {
              name: "AMIO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAIGANGAN",
            },
            {
              name: "CARANOCHE",
            },
            {
              name: "CAWITAN",
            },
            {
              name: "FATIMA",
            },
            {
              name: "KABULACAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MANALONGON",
            },
            {
              name: "MANSAGOMAYON",
            },
            {
              name: "MILAGROSA",
            },
            {
              name: "NAGBALAYE",
            },
            {
              name: "NAGBINLOD",
            },
            {
              name: "OBAT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TALALAK",
            },
          ],
        },
        SIATON: {
          name: "SIATON",
          baranggays: [
            {
              name: "ALBIGA",
            },
            {
              name: "APOLOY",
            },
            {
              name: "BONAWON",
            },
            {
              name: "BONBONON",
            },
            {
              name: "CABANGAHAN",
            },
            {
              name: "CANAWAY",
            },
            {
              name: "CASALA-AN",
            },
            {
              name: "CATICUGAN",
            },
            {
              name: "DATAG",
            },
            {
              name: "GILIGA-ON",
            },
            {
              name: "INALAD",
            },
            {
              name: "MALABUHAN",
            },
            {
              name: "MALOH",
            },
            {
              name: "MANTIQUIL",
            },
            {
              name: "MANTUYOP",
            },
            {
              name: "NAPACAO",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "SALAG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANDULOT",
            },
            {
              name: "SI-IT",
            },
            {
              name: "SIATON",
            },
            {
              name: "SUMALIRING",
            },
            {
              name: "TAYAK",
            },
          ],
        },
        SIBULAN: {
          name: "SIBULAN",
          baranggays: [
            {
              name: "AGAN-AN",
            },
            {
              name: "AJONG",
            },
            {
              name: "BALUGO",
            },
            {
              name: "BOLOCBOLOC",
            },
            {
              name: "CALABNUGAN",
            },
            {
              name: "CANGMATING",
            },
            {
              name: "ENRIQUE VILLANUEVA",
            },
            {
              name: "LOOC",
            },
            {
              name: "MAGATAS",
            },
            {
              name: "MANINGCAO",
            },
            {
              name: "MASLOG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SIBULAN",
            },
            {
              name: "TUBIGON",
            },
            {
              name: "TUBTUBON",
            },
          ],
        },
        "TANJAY CITY": {
          name: "TANJAY CITY",
          baranggays: [
            {
              name: "AZAGRA",
            },
            {
              name: "BAHI-AN",
            },
            {
              name: "LUCA",
            },
            {
              name: "MANIPIS",
            },
            {
              name: "NOVALLAS",
            },
            {
              name: "OBOGON",
            },
            {
              name: "PAL-EW",
            },
            {
              name: "POBLACION I (BARANGAY 1)",
            },
            {
              name: "POBLACION II (BARANGAY 2)",
            },
            {
              name: "POBLACION III (BARANGAY 3)",
            },
            {
              name: "POBLACION IV (BARANGAY 4)",
            },
            {
              name: "POBLACION IX (BARANGAY 9)",
            },
            {
              name: "POBLACION V (BARANGAY 5)",
            },
            {
              name: "POBLACION VI (BARANGAY 6)",
            },
            {
              name: "POBLACION VII (BARANGAY 7)",
            },
            {
              name: "POBLACION VIII (BARANGAY 8)",
            },
            {
              name: "POLO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA CRUZ NUEVO",
            },
            {
              name: "SANTA CRUZ VIEJO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TANJAY CITY",
            },
            {
              name: "TUGAS",
            },
          ],
        },
        TAYASAN: {
          name: "TAYASAN",
          baranggays: [
            {
              name: "BACONG",
            },
            {
              name: "BAGO",
            },
            {
              name: "BANGA",
            },
            {
              name: "CABULOTAN",
            },
            {
              name: "CAMBAYE",
            },
            {
              name: "DALAUPON",
            },
            {
              name: "GUINCALABAN",
            },
            {
              name: "ILAYA-TAYASAN",
            },
            {
              name: "JILABANGAN",
            },
            {
              name: "LAG-IT",
            },
            {
              name: "LINAO",
            },
            {
              name: "LUTAY",
            },
            {
              name: "MAGLIHE",
            },
            {
              name: "MAGTUHAO",
            },
            {
              name: "MATAUTA",
            },
            {
              name: "MATUOG",
            },
            {
              name: "NUMNUM",
            },
            {
              name: "PALASLAN",
            },
            {
              name: "PINALUBNGAN",
            },
            {
              name: "PINDAHAN",
            },
            {
              name: "PINOCAWAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SAYING",
            },
            {
              name: "SUQUIB",
            },
            {
              name: "TAMAO",
            },
            {
              name: "TAMBULAN",
            },
            {
              name: "TANLAD",
            },
            {
              name: "TAYASAN",
            },
          ],
        },
        "VALENCIA (LUZURRIAGA)": {
          name: "VALENCIA (LUZURRIAGA)",
          baranggays: [
            {
              name: "APOLONG",
            },
            {
              name: "BALABAG EAST",
            },
            {
              name: "BALABAG WEST",
            },
            {
              name: "BALAYAGMANOK",
            },
            {
              name: "BALILI",
            },
            {
              name: "BALUGO",
            },
            {
              name: "BONG-AO",
            },
            {
              name: "BONGBONG",
            },
            {
              name: "CAIDIOCAN",
            },
            {
              name: "CALAYUGAN",
            },
            {
              name: "CAMBUCAD",
            },
            {
              name: "DOBDOB",
            },
            {
              name: "JAWA",
            },
            {
              name: "LIPTONG",
            },
            {
              name: "LUNGA",
            },
            {
              name: "MALABO",
            },
            {
              name: "MALAUNAY",
            },
            {
              name: "MAMPAS",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "PALINPINON",
            },
            {
              name: "PUHAGAN",
            },
            {
              name: "PULANGBATO",
            },
            {
              name: "SAGBANG",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "VALENCIA (LUZURRIAGA)",
            },
          ],
        },
        VALLEHERMOSO: {
          name: "VALLEHERMOSO",
          baranggays: [
            {
              name: "BAGAWINES",
            },
            {
              name: "BAIRAN",
            },
            {
              name: "CABULIHAN",
            },
            {
              name: "DON ESPIRIDION VILLEGAS",
            },
            {
              name: "GUBA",
            },
            {
              name: "MACAPSO",
            },
            {
              name: "MAGLAHOS",
            },
            {
              name: "MALANGSA",
            },
            {
              name: "MOLOBOLO",
            },
            {
              name: "PINOCAWAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUAN",
            },
            {
              name: "TABON",
            },
            {
              name: "TAGBINO",
            },
            {
              name: "ULAY",
            },
            {
              name: "VALLEHERMOSO",
            },
          ],
        },
        ZAMBOANGUITA: {
          name: "ZAMBOANGUITA",
          baranggays: [
            {
              name: "BASAC",
            },
            {
              name: "CALANGO",
            },
            {
              name: "LOTUBAN",
            },
            {
              name: "MALONGCAY DIOT",
            },
            {
              name: "MALUAY",
            },
            {
              name: "MAYABON",
            },
            {
              name: "NABAGO",
            },
            {
              name: "NAJANDIG",
            },
            {
              name: "NASIG-ID",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ZAMBOANGUITA",
            },
          ],
        },
      },
    },
    "NORTHERN SAMAR": {
      name: "NORTHERN SAMAR",
      region: "REGION 8",
      municipalities: {
        ALLEN: {
          name: "ALLEN",
          baranggays: [
            {
              name: "ALEJANDRO VILLAGE (SANTIAGO)",
            },
            {
              name: "ALLEN",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "CABACUNGAN",
            },
            {
              name: "CALARAYAN",
            },
            {
              name: "FREDERIC",
            },
            {
              name: "GUIN-ARAWAYAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "JUBASAN",
            },
            {
              name: "KINABRANAN ZONE I (POB.)",
            },
            {
              name: "KINABRANAN ZONE II (POB.)",
            },
            {
              name: "KINAGUITMAN",
            },
            {
              name: "LO-OC",
            },
            {
              name: "LONDRES",
            },
            {
              name: "SABANG ZONE I (POB.)",
            },
            {
              name: "SABANG ZONE II (POB.)",
            },
            {
              name: "TASVILLA",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        BIRI: {
          name: "BIRI",
          baranggays: [
            {
              name: "BIRI",
            },
            {
              name: "KAUSWAGAN (BASUD)",
            },
            {
              name: "MACARTHUR",
            },
            {
              name: "PIO DEL PILAR",
            },
            {
              name: "POBLACION (BIRI)",
            },
            {
              name: "PROGRESSO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        BOBON: {
          name: "BOBON",
          baranggays: [
            {
              name: "ACERIDA",
            },
            {
              name: "ARELLANO",
            },
            {
              name: "BALAT-BALUD",
            },
            {
              name: "BOBON",
            },
            {
              name: "CALANTIAO (PANGOBI-AN)",
            },
            {
              name: "DANCALAN",
            },
            {
              name: "E. DURAN",
            },
            {
              name: "GEN. LUCBAN (POB.)",
            },
            {
              name: "JOSE ABAD SANTOS",
            },
            {
              name: "JOSE P. LAUREL (CASULGAN)",
            },
            {
              name: "MAGSAYSAY (DOCE)",
            },
            {
              name: "QUEZON (PANICAYAN)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SANTA CLARA (POB.)",
            },
            {
              name: "SANTANDER",
            },
            {
              name: "TROJELLO",
            },
          ],
        },
        CAPUL: {
          name: "CAPUL",
          baranggays: [
            {
              name: "AGUIN",
            },
            {
              name: "CAPUL",
            },
            {
              name: "JUBANG",
            },
            {
              name: "LANDUSAN",
            },
            {
              name: "OSON",
            },
            {
              name: "POBLACION BARANGAY 1",
            },
            {
              name: "POBLACION BARANGAY 2",
            },
            {
              name: "POBLACION BARANGAY 3",
            },
            {
              name: "POBLACION BARANGAY 4",
            },
            {
              name: "POBLACION BARANGAY 5",
            },
            {
              name: "SAGAOSAWAN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAWANG",
            },
          ],
        },
        CATARMAN: {
          name: "CATARMAN",
          baranggays: [
            {
              name: "ACACIA (POB.)",
            },
            {
              name: "AGUINALDO",
            },
            {
              name: "AIRPORT VILLAGE",
            },
            {
              name: "BANGKEROHAN",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BOCSOL",
            },
            {
              name: "CABAYHAN",
            },
            {
              name: "CAG-ABACA",
            },
            {
              name: "CAL-IGANG",
            },
            {
              name: "CALACHUCHI (POB.)",
            },
            {
              name: "CATARMAN",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CERVANTES",
            },
            {
              name: "CULARIMA",
            },
            {
              name: "DAGANAS",
            },
            {
              name: "DALAKIT (POB.)",
            },
            {
              name: "DOÑA PULQUERIA (HIMBANG)",
            },
            {
              name: "GALUTAN",
            },
            {
              name: "GEBALAGNAN (HIBALAGNAN)",
            },
            {
              name: "GEBULWANGAN",
            },
            {
              name: "GENERAL MALVAR",
            },
            {
              name: "GUBA",
            },
            {
              name: "HINATAD",
            },
            {
              name: "IMELDA (ELIMBO)",
            },
            {
              name: "IPIL-IPIL (POB.)",
            },
            {
              name: "JOSE ABAD SANTOS (POB.)",
            },
            {
              name: "JOSE P. RIZAL (POB.)",
            },
            {
              name: "KASOY (POB.)",
            },
            {
              name: "LAPU-LAPU (POB.)",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "LIBJO (BINOG)",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABOLO (POB.)",
            },
            {
              name: "MACAGTAS",
            },
            {
              name: "MCKINLEY",
            },
            {
              name: "MOLAVE (POB.)",
            },
            {
              name: "NARRA (POB.)",
            },
            {
              name: "NEW RIZAL",
            },
            {
              name: "OLD RIZAL",
            },
            {
              name: "PATICUA",
            },
            {
              name: "POLANGI",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAMPAGUITA (POB.)",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SANTOL (POB.)",
            },
            {
              name: "SOMOGE",
            },
            {
              name: "TALISAY (POB.)",
            },
            {
              name: "TINOWARAN",
            },
            {
              name: "TRANGUE",
            },
            {
              name: "UEP I",
            },
            {
              name: "UEP II",
            },
            {
              name: "UEP III",
            },
            {
              name: "WASHINGTON",
            },
            {
              name: "YAKAL (POB.)",
            },
          ],
        },
        CATUBIG: {
          name: "CATUBIG",
          baranggays: [
            {
              name: "ANONGO",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BORING",
            },
            {
              name: "CAGBUGNA",
            },
            {
              name: "CAGMANABA",
            },
            {
              name: "CAGOGOBNGAN",
            },
            {
              name: "CALINGNAN",
            },
            {
              name: "CANUCTAN",
            },
            {
              name: "CATUBIG",
            },
            {
              name: "CLARO M. RECTO (LOBEDICO)",
            },
            {
              name: "D. MERCADER (BONGOG)",
            },
            {
              name: "GUIBWANGAN",
            },
            {
              name: "HINAGONOYAN",
            },
            {
              name: "HIPARAYAN",
            },
            {
              name: "HITAPI-AN",
            },
            {
              name: "INOBURAN",
            },
            {
              name: "IRAWAHAN",
            },
            {
              name: "LENOYAHAN",
            },
            {
              name: "LIBON",
            },
            {
              name: "MAGONGON",
            },
            {
              name: "MAGTUAD",
            },
            {
              name: "MANERING",
            },
            {
              name: "NABULO",
            },
            {
              name: "NAGOOCAN",
            },
            {
              name: "NAHULID",
            },
            {
              name: "OPONG",
            },
            {
              name: "OSANG",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "P. REBADULLA",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAGUDSURON",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE (HEBOBOLLAO)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SULITAN",
            },
            {
              name: "TANGBO",
            },
            {
              name: "TUNGODNON",
            },
            {
              name: "VIENNA MARIA",
            },
          ],
        },
        GAMAY: {
          name: "GAMAY",
          baranggays: [
            {
              name: "ANITO",
            },
            {
              name: "BANGON",
            },
            {
              name: "BATO",
            },
            {
              name: "BAYBAY DISTRICT (POB.)",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BURABOD (POB.)",
            },
            {
              name: "CABARASAN",
            },
            {
              name: "CADAC-AN (CALINGNAN)",
            },
            {
              name: "CADE-AN",
            },
            {
              name: "CAGAMUTAN DEL NORTE",
            },
            {
              name: "CAGAMUTAN DEL SUR",
            },
            {
              name: "DAO",
            },
            {
              name: "G. M. OSIAS",
            },
            {
              name: "GAMAY",
            },
            {
              name: "GAMAY CENTRAL (POB.)",
            },
            {
              name: "GAMAY OCCIDENTAL I (POB.)",
            },
            {
              name: "GAMAY ORIENTAL I (POB.)",
            },
            {
              name: "GUIBUANGAN",
            },
            {
              name: "HENOGAWE",
            },
            {
              name: "LIBERTAD (POB.)",
            },
            {
              name: "LONOY",
            },
            {
              name: "LUNETA",
            },
            {
              name: "MALIDONG",
            },
            {
              name: "OCCIDENTAL II (POB.)",
            },
            {
              name: "ORIENTAL II (POB.)",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANTONIO",
            },
          ],
        },
        LAOANG: {
          name: "LAOANG",
          baranggays: [
            {
              name: "ABATON",
            },
            {
              name: "AGUADAHAN",
            },
            {
              name: "AROGANGA",
            },
            {
              name: "ATIPOLO",
            },
            {
              name: "BAWANG",
            },
            {
              name: "BAYBAY (POB.)",
            },
            {
              name: "BINATIKLAN",
            },
            {
              name: "BOBOLOSAN",
            },
            {
              name: "BONGLIW",
            },
            {
              name: "BURABUD",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CABAGNGAN",
            },
            {
              name: "CABAGO-AN",
            },
            {
              name: "CABULALOAN",
            },
            {
              name: "CAGAASAN",
            },
            {
              name: "CAGDARA-O",
            },
            {
              name: "CAHAYAGAN",
            },
            {
              name: "CALINTAAN POB. (STO. NIÑO)",
            },
            {
              name: "CALOMOTAN",
            },
            {
              name: "CANDAWID",
            },
            {
              name: "CANGCAHIPOS",
            },
            {
              name: "CANYOMANAO",
            },
            {
              name: "CATIGBIAN",
            },
            {
              name: "E. J. DULAY",
            },
            {
              name: "G. B. TAN",
            },
            {
              name: "GIBATANGAN",
            },
            {
              name: "GUILAOANGI (POB.)",
            },
            {
              name: "INAMLAN",
            },
            {
              name: "LA PERLA",
            },
            {
              name: "LANGOB",
            },
            {
              name: "LAOANG",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "LITTLE VENICE (POB.)",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARUBAY",
            },
            {
              name: "MUALBUAL",
            },
            {
              name: "NAPOTIOCAN (SALVACION)",
            },
            {
              name: "OLERAS",
            },
            {
              name: "ONAY (DOÑA LUISA)",
            },
            {
              name: "PALMERA",
            },
            {
              name: "PANGDAN",
            },
            {
              name: "RAWIS",
            },
            {
              name: "ROMBANG",
            },
            {
              name: "SAN ANTONIO (SON-OG)",
            },
            {
              name: "SAN MIGUEL HEIGHTS (POB.)",
            },
            {
              name: "SANGCOL",
            },
            {
              name: "SIBUNOT",
            },
            {
              name: "SIMORA",
            },
            {
              name: "SUBA",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAN-AWAN",
            },
            {
              name: "TARUSAN",
            },
            {
              name: "TINOBLAN",
            },
            {
              name: "TUMAGUINGTING (POB.)",
            },
            {
              name: "VIGO",
            },
            {
              name: "YABYABAN (SAN VICENTE)",
            },
            {
              name: "YAPAS",
            },
          ],
        },
        LAPINIG: {
          name: "LAPINIG",
          baranggays: [
            {
              name: "ALANG-ALANG",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "CAHAGWAYAN",
            },
            {
              name: "CAN MARIA",
            },
            {
              name: "CAN OMANIO",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LAPINIG",
            },
            {
              name: "LAPINIG DEL NORTE (POB.)",
            },
            {
              name: "LAPINIG DEL SUR (POB.)",
            },
            {
              name: "LO-OK",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAY-IGOT",
            },
            {
              name: "PALANAS",
            },
            {
              name: "PIO DEL PILAR",
            },
            {
              name: "POTONG",
            },
            {
              name: "POTONG DEL SUR",
            },
          ],
        },
        "LAS NAVAS": {
          name: "LAS NAVAS",
          baranggays: [
            {
              name: "BALUGO",
            },
            {
              name: "BUGAY",
            },
            {
              name: "BUGTOSAN",
            },
            {
              name: "BUKID",
            },
            {
              name: "BULAO",
            },
            {
              name: "CAPUTOAN",
            },
            {
              name: "CATOTO-OGAN",
            },
            {
              name: "CUENCO",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DOLORES",
            },
            {
              name: "EPAW",
            },
            {
              name: "GEGUINTA",
            },
            {
              name: "GERACDO",
            },
            {
              name: "GUYO",
            },
            {
              name: "H. JOLEJOLE",
            },
            {
              name: "H. JOLEJOLE DISTRICT (POB.)",
            },
            {
              name: "HANGI",
            },
            {
              name: "IMELDA",
            },
            {
              name: "L. EMPON",
            },
            {
              name: "LAKANDULA",
            },
            {
              name: "LAS NAVAS",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LUMALA-OG",
            },
            {
              name: "MABINI",
            },
            {
              name: "MACARTHUR",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MATELARAG",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PACO",
            },
            {
              name: "PALANAS",
            },
            {
              name: "PEREZ",
            },
            {
              name: "POPONTON",
            },
            {
              name: "QUEZON",
            },
            {
              name: "QUIRINO",
            },
            {
              name: "QUIRINO DISTRICT (POB.)",
            },
            {
              name: "REBONG",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROXAS",
            },
            {
              name: "RUFINO",
            },
            {
              name: "SAG-OD",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JORGE",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TAGAB-IRAN",
            },
            {
              name: "TAGAN-AYAN",
            },
            {
              name: "TAYLOR",
            },
            {
              name: "VICTORY",
            },
          ],
        },
        LAVEZARES: {
          name: "LAVEZARES",
          baranggays: [
            {
              name: "BALICUATRO",
            },
            {
              name: "BANI",
            },
            {
              name: "BAROBAYBAY",
            },
            {
              name: "CABURIHAN (POB.)",
            },
            {
              name: "CARAGAS (POB.)",
            },
            {
              name: "CATAOGAN (POB.)",
            },
            {
              name: "CHANSVILLA",
            },
            {
              name: "DATAG",
            },
            {
              name: "ENRIQUETA",
            },
            {
              name: "LAVEZARES",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MACARTHUR",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARAVILLA",
            },
            {
              name: "OCAD (POB.)",
            },
            {
              name: "SABONG-TABOK",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "TO-OG",
            },
            {
              name: "URDANETA",
            },
            {
              name: "VILLA",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        "LOPE DE VEGA": {
          name: "LOPE DE VEGA",
          baranggays: [
            {
              name: "BAYHO",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "CAG-AGUINGAY",
            },
            {
              name: "CAGAMESARAG",
            },
            {
              name: "CURRY",
            },
            {
              name: "GEBONAWAN",
            },
            {
              name: "GEN. LUNA",
            },
            {
              name: "GETIGO",
            },
            {
              name: "HENARONAGAN",
            },
            {
              name: "LOPE DE VEGA",
            },
            {
              name: "LOPE DE VEGA (POB.)",
            },
            {
              name: "LOWER CAYNAGA",
            },
            {
              name: "MAGHIPID",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PAGUITE",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SOMOROY",
            },
            {
              name: "UPPER CAYNAGA",
            },
          ],
        },
        MAPANAS: {
          name: "MAPANAS",
          baranggays: [
            {
              name: "BURGOS",
            },
            {
              name: "DEL NORTE (POB.)",
            },
            {
              name: "DEL SUR (POB.)",
            },
            {
              name: "E. LAODENIO",
            },
            {
              name: "JUBASAN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAGTAON",
            },
            {
              name: "MANAYBANAY",
            },
            {
              name: "MAPANAS",
            },
            {
              name: "NAPARASAN",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA POTENCIANA (POB.)",
            },
            {
              name: "SILJAGON",
            },
          ],
        },
        MONDRAGON: {
          name: "MONDRAGON",
          baranggays: [
            {
              name: "BAGASBAS",
            },
            {
              name: "BUGKO",
            },
            {
              name: "CABLANGAN",
            },
            {
              name: "CAGMANABA",
            },
            {
              name: "CAHICSAN",
            },
            {
              name: "CHITONGCO (POB.)",
            },
            {
              name: "DE MARIA",
            },
            {
              name: "DOÑA LUCIA",
            },
            {
              name: "ECO (POB.)",
            },
            {
              name: "FLORMINA",
            },
            {
              name: "HINABANGAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "LA TRINIDAD",
            },
            {
              name: "MAKIWALO",
            },
            {
              name: "MIRADOR",
            },
            {
              name: "MONDRAGON",
            },
            {
              name: "NENITA",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "TALOLORA",
            },
          ],
        },
        PALAPAG: {
          name: "PALAPAG",
          baranggays: [
            {
              name: "ASUM (POB.)",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BANGON",
            },
            {
              name: "BENIGNO S. AQUINO, JR.",
            },
            {
              name: "BINAY",
            },
            {
              name: "CABARIWAN",
            },
            {
              name: "CABATUAN",
            },
            {
              name: "CAMPEDICO",
            },
            {
              name: "CAPACUJAN",
            },
            {
              name: "JANGTUD",
            },
            {
              name: "LANIWAN (POB.)",
            },
            {
              name: "MABARAS",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANAJAO",
            },
            {
              name: "MAPNO",
            },
            {
              name: "MARAGANO",
            },
            {
              name: "MATAMBAG",
            },
            {
              name: "MONBON",
            },
            {
              name: "NAGBOBTAC",
            },
            {
              name: "NAPO",
            },
            {
              name: "NATAWO",
            },
            {
              name: "NIPA",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PALAPAG",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PAYSUD",
            },
            {
              name: "SANGAY",
            },
            {
              name: "SIMORA",
            },
            {
              name: "SINALARAN",
            },
            {
              name: "SUMOROY",
            },
            {
              name: "TALOLORA",
            },
            {
              name: "TAMBANGAN (POB.)",
            },
            {
              name: "TINAMPO (POB.)",
            },
          ],
        },
        PAMBUJAN: {
          name: "PAMBUJAN",
          baranggays: [
            {
              name: "CABABTO-AN",
            },
            {
              name: "CABARI-AN",
            },
            {
              name: "CAGBIGAJO",
            },
            {
              name: "CAMPARANGA",
            },
            {
              name: "CANJUMADAL",
            },
            {
              name: "DOÑA ANECITA",
            },
            {
              name: "GE-ADGAWAN",
            },
            {
              name: "GEPARAYAN",
            },
            {
              name: "GINULGAN",
            },
            {
              name: "IGOT",
            },
            {
              name: "INANAHAWAN",
            },
            {
              name: "MANAHAO",
            },
            {
              name: "PAMBUJAN",
            },
            {
              name: "PANINIRONGAN",
            },
            {
              name: "POBLACION DISTRICT 1",
            },
            {
              name: "POBLACION DISTRICT 2",
            },
            {
              name: "POBLACION DISTRICT 3",
            },
            {
              name: "POBLACION DISTRICT 4",
            },
            {
              name: "POBLACION DISTRICT 5",
            },
            {
              name: "POBLACION DISTRICT 6",
            },
            {
              name: "POBLACION DISTRICT 7",
            },
            {
              name: "POBLACION DISTRICT 8",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SENONOGAN",
            },
            {
              name: "SIXTO T. BALANGUIT, SR.",
            },
            {
              name: "TULA",
            },
            {
              name: "YNAGUINGAYAN",
            },
          ],
        },
        ROSARIO: {
          name: "ROSARIO",
          baranggays: [
            {
              name: "AGUADA",
            },
            {
              name: "BANTOLINAO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "COMMONWEALTH",
            },
            {
              name: "GUINDAULAN",
            },
            {
              name: "JAMOOG",
            },
            {
              name: "KAILINGAN",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SALHAG",
            },
            {
              name: "SAN LORENZO",
            },
          ],
        },
        "SAN ANTONIO": {
          name: "SAN ANTONIO",
          baranggays: [
            {
              name: "BURABOD",
            },
            {
              name: "DALUPIRIT",
            },
            {
              name: "MANRAYA",
            },
            {
              name: "PILAR",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "VINISITAHAN",
            },
            {
              name: "WARD I (POB.)",
            },
            {
              name: "WARD II (POB.)",
            },
            {
              name: "WARD III (POB.)",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BALITE",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAGLANIPAO",
            },
            {
              name: "HAPPY VALLEY",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "PALANIT",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SEVEN HILLS",
            },
            {
              name: "VERIATO",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "AGUADAHAN",
            },
            {
              name: "BAGONG SABANG",
            },
            {
              name: "BALITE",
            },
            {
              name: "BARANGAY EAST (POB.)",
            },
            {
              name: "BARANGAY NORTH (POB.)",
            },
            {
              name: "BARANGAY SOUTH (POB.)",
            },
            {
              name: "BARANGAY WEST (POB.)",
            },
            {
              name: "BONGLAS",
            },
            {
              name: "DA-O",
            },
            {
              name: "GENGAROG",
            },
            {
              name: "GERATAG",
            },
            {
              name: "LAYUHAN",
            },
            {
              name: "MANDUGANG",
            },
            {
              name: "P. TINGZON",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "TUBIGDANAO",
            },
          ],
        },
        "SAN ROQUE": {
          name: "SAN ROQUE",
          baranggays: [
            {
              name: "BALNASAN",
            },
            {
              name: "COROCONOG",
            },
            {
              name: "DALE",
            },
            {
              name: "GINAGDANAN",
            },
            {
              name: "LAO-ANGAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "ZONE 1 (POB.)",
            },
            {
              name: "ZONE 2 (POB.)",
            },
            {
              name: "ZONE 3 (POB.)",
            },
            {
              name: "ZONE 4 (POB.)",
            },
            {
              name: "ZONE 5 (POB.)",
            },
            {
              name: "ZONE 6 (POB.)",
            },
          ],
        },
        "SAN VICENTE": {
          name: "SAN VICENTE",
          baranggays: [
            {
              name: "DESTACADO POB. (BGY.2)",
            },
            {
              name: "MARAGAT",
            },
            {
              name: "MONGOL BONGOL POB. (BGY.1)",
            },
            {
              name: "PUNTA POB. (BGY.3)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANGPUTAN",
            },
            {
              name: "SILA",
            },
            {
              name: "TARNATE",
            },
          ],
        },
        "SILVINO LOBOS": {
          name: "SILVINO LOBOS",
          baranggays: [
            {
              name: "BALUD",
            },
            {
              name: "CABABAYOGAN",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CAGDA-O",
            },
            {
              name: "CAGHILOT",
            },
            {
              name: "CAMANGGARAN",
            },
            {
              name: "CAMAYA-AN",
            },
            {
              name: "DEIT DE SUBA",
            },
            {
              name: "DEIT DE TURAG",
            },
            {
              name: "GEBOLWANGAN",
            },
            {
              name: "GEBONAWAN",
            },
            {
              name: "GECBO-AN",
            },
            {
              name: "GENAGASAN",
            },
            {
              name: "GEPARAYAN DE TURAG",
            },
            {
              name: "GIGUIMITAN",
            },
            {
              name: "GUSARAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "MONTALBAN",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SENONOGAN DE TUBANG",
            },
            {
              name: "SILVINO LOBOS",
            },
            {
              name: "SUBA (POB.)",
            },
            {
              name: "TOBGON",
            },
            {
              name: "VICTORY",
            },
          ],
        },
        VICTORIA: {
          name: "VICTORIA",
          baranggays: [
            {
              name: "ACEDILLO",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "BUENOS AIRES",
            },
            {
              name: "COLAB-OG",
            },
            {
              name: "ERENAS",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LUISITA",
            },
            {
              name: "LUNGIB",
            },
            {
              name: "MAXVILLA",
            },
            {
              name: "PASABUENA",
            },
            {
              name: "SAN LAZARO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROMAN",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
          ],
        },
      },
    },
    "NUEVA ECIJA": {
      name: "NUEVA ECIJA",
      region: "REGION 3",
      municipalities: {
        ALIAGA: {
          name: "ALIAGA",
          baranggays: [
            {
              name: "ALIAGA",
            },
            {
              name: "BETES",
            },
            {
              name: "BIBICLAT",
            },
            {
              name: "BUCOT",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "MACABUCOD",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "PANTOC",
            },
            {
              name: "POBLACION CENTRO",
            },
            {
              name: "POBLACION EAST I",
            },
            {
              name: "POBLACION EAST II",
            },
            {
              name: "POBLACION WEST III",
            },
            {
              name: "POBLACION WEST IV",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN EMILIANO",
            },
            {
              name: "SAN EUSTACIO",
            },
            {
              name: "SAN FELIPE BATA",
            },
            {
              name: "SAN FELIPE MATANDA",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PABLO BATA",
            },
            {
              name: "SAN PABLO MATANDA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SUNSON",
            },
            {
              name: "UMANGAN",
            },
          ],
        },
        BONGABON: {
          name: "BONGABON",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "ARIENDO",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BONGABON",
            },
            {
              name: "CALAANAN",
            },
            {
              name: "COMMERCIAL (POB.)",
            },
            {
              name: "CRUZ",
            },
            {
              name: "CURVA (J. TOMACRUZ)",
            },
            {
              name: "DIGMALA",
            },
            {
              name: "KAINGIN (POB.)",
            },
            {
              name: "LABI",
            },
            {
              name: "LARCON",
            },
            {
              name: "LUSOK",
            },
            {
              name: "MACABACLAY",
            },
            {
              name: "MAGTANGGOL (POB.)",
            },
            {
              name: "MANTILE (POB.)",
            },
            {
              name: "OLIVETE",
            },
            {
              name: "PALO MARIA (POB.)",
            },
            {
              name: "PESA",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SAMPALUCAN (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANTOR",
            },
            {
              name: "SINIPIT (POB.)",
            },
            {
              name: "SISILANG NA LIGAYA (POB.)",
            },
            {
              name: "SOCIAL (POB.)",
            },
            {
              name: "TUGATUG",
            },
            {
              name: "TULAY NA BATO (POB.)",
            },
            {
              name: "VEGA",
            },
          ],
        },
        "CABANATUAN CITY": {
          name: "CABANATUAN CITY",
          baranggays: [
            {
              name: "ADUAS CENTRO (ADUAS)",
            },
            {
              name: "ADUAS NORTE",
            },
            {
              name: "ADUAS SUR",
            },
            {
              name: "BAGONG BUHAY",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BAKERO",
            },
            {
              name: "BAKOD BAYAN",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANGAD",
            },
            {
              name: "BANTUG BULALO",
            },
            {
              name: "BANTUG NORTE",
            },
            {
              name: "BARLIS",
            },
            {
              name: "BARRERA DISTRICT (POB.)",
            },
            {
              name: "BERNARDO DISTRICT (POB.)",
            },
            {
              name: "BITAS",
            },
            {
              name: "BONIFACIO DISTRICT (POB.)",
            },
            {
              name: "BULIRAN",
            },
            {
              name: "CAALIBANGBANGAN",
            },
            {
              name: "CABANATUAN CITY",
            },
            {
              name: "CABU",
            },
            {
              name: "CALAWAGAN (KALAWAGAN)",
            },
            {
              name: "CAMPO TINIO",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "CAUDILLO",
            },
            {
              name: "CINCO-CINCO",
            },
            {
              name: "CITY SUPERMARKET (POB.)",
            },
            {
              name: "COMMUNAL",
            },
            {
              name: "CRUZ ROJA",
            },
            {
              name: "DAANG SARILE",
            },
            {
              name: "DALAMPANG",
            },
            {
              name: "DICARMA (POB.)",
            },
            {
              name: "DIMASALANG (POB.)",
            },
            {
              name: "DIONISIO S. GARCIA",
            },
            {
              name: "FATIMA (POB.)",
            },
            {
              name: "GENERAL LUNA (POB.)",
            },
            {
              name: "HERMOGENES C. CONCEPCION, SR.",
            },
            {
              name: "IBABAO BANA",
            },
            {
              name: "IMELDA DISTRICT",
            },
            {
              name: "ISLA (POB.)",
            },
            {
              name: "KALIKID NORTE",
            },
            {
              name: "KALIKID SUR",
            },
            {
              name: "KAPITAN PEPE (POB.)",
            },
            {
              name: "LAGARE",
            },
            {
              name: "LOURDES (MATUNGAL-TUNGAL)",
            },
            {
              name: "M. S. GARCIA",
            },
            {
              name: "MABINI EXTENSION",
            },
            {
              name: "MABINI HOMESITE",
            },
            {
              name: "MACATBONG",
            },
            {
              name: "MAGSAYSAY DISTRICT",
            },
            {
              name: "MAGSAYSAY SOUTH",
            },
            {
              name: "MARIA THERESA",
            },
            {
              name: "MATADERO (POB.)",
            },
            {
              name: "MAYAPYAP NORTE",
            },
            {
              name: "MAYAPYAP SUR",
            },
            {
              name: "MELOJAVILLA (POB.)",
            },
            {
              name: "NABAO (POB.)",
            },
            {
              name: "OBRERO",
            },
            {
              name: "PADRE BURGOS (POB.)",
            },
            {
              name: "PADRE CRISOSTOMO",
            },
            {
              name: "PAGAS",
            },
            {
              name: "PALAGAY",
            },
            {
              name: "PAMALDAN",
            },
            {
              name: "PANGATIAN",
            },
            {
              name: "PATALAC",
            },
            {
              name: "POLILIO",
            },
            {
              name: "PULA",
            },
            {
              name: "QUEZON DISTRICT (POB.)",
            },
            {
              name: "RIZDELIS (POB.)",
            },
            {
              name: "SAMON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSEF NORTE",
            },
            {
              name: "SAN JOSEF SUR",
            },
            {
              name: "SAN JUAN POB. (ACOFA)",
            },
            {
              name: "SAN ROQUE NORTE",
            },
            {
              name: "SAN ROQUE SUR",
            },
            {
              name: "SANBERMICRISTI (POB.)",
            },
            {
              name: "SANGITAN",
            },
            {
              name: "SANGITAN EAST",
            },
            {
              name: "SANTA ARCADIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAPANG",
            },
            {
              name: "SUMACAB ESTE",
            },
            {
              name: "SUMACAB NORTE",
            },
            {
              name: "SUMACAB SOUTH",
            },
            {
              name: "TALIPAPA",
            },
            {
              name: "VALDEFUENTE",
            },
            {
              name: "VALLE CRUZ",
            },
            {
              name: "VIJANDRE DISTRICT (POB.)",
            },
            {
              name: "VILLA OFELIA-CARIDAD",
            },
            {
              name: "ZULUETA DISTRICT (POB.)",
            },
          ],
        },
        CABIAO: {
          name: "CABIAO",
          baranggays: [
            {
              name: "BAGONG BUHAY",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "CABIAO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "ENTABLADO",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "NATIVIDAD NORTH (POB.)",
            },
            {
              name: "NATIVIDAD SOUTH (POB.)",
            },
            {
              name: "PALASINAN",
            },
            {
              name: "POLILIO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN FERNANDO NORTE",
            },
            {
              name: "SAN FERNANDO SUR",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN JUAN NORTH (POB.)",
            },
            {
              name: "SAN JUAN SOUTH (POB.)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SINIPIT",
            },
          ],
        },
        CARRANGLAN: {
          name: "CARRANGLAN",
          baranggays: [
            {
              name: "BANTUG",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CAPINTALAN",
            },
            {
              name: "CARRANGLAN",
            },
            {
              name: "D. L. MAGLANOC POB. (BGY.III)",
            },
            {
              name: "F. C. OTIC POB. (BGY. II)",
            },
            {
              name: "G. S. ROSARIO POB. (BGY. IV)",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "JOSON (DIGIDIG)",
            },
            {
              name: "MINULI",
            },
            {
              name: "PIUT",
            },
            {
              name: "PUNCAN",
            },
            {
              name: "PUTLAN",
            },
            {
              name: "R.A.PADILLA (BALUARTE)",
            },
            {
              name: "SALAZAR",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "T. L. PADILLA POB. (BGY. I)",
            },
          ],
        },
        CUYAPO: {
          name: "CUYAPO",
          baranggays: [
            {
              name: "BALOY",
            },
            {
              name: "BAMBANABA",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BENTIGAN",
            },
            {
              name: "BIBICLAT",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BUED",
            },
            {
              name: "BULALA",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CABATUAN",
            },
            {
              name: "CABILEO",
            },
            {
              name: "CACAPASAN",
            },
            {
              name: "CALANCUASAN NORTE",
            },
            {
              name: "CALANCUASAN SUR",
            },
            {
              name: "COLOSBOA",
            },
            {
              name: "COLUMBITIN",
            },
            {
              name: "CURVA",
            },
            {
              name: "CUYAPO",
            },
            {
              name: "DISTRICT I (POB. I)",
            },
            {
              name: "DISTRICT II (POB. II)",
            },
            {
              name: "DISTRICT IV (POB. IV)",
            },
            {
              name: "DISTRICT V (POB. V)",
            },
            {
              name: "DISTRICT VI (POB. VI)",
            },
            {
              name: "DISTRICT VII (POB. VII)",
            },
            {
              name: "DISTRICT VIII (POB. VIII)",
            },
            {
              name: "LANDIG",
            },
            {
              name: "LATAP",
            },
            {
              name: "LOOB",
            },
            {
              name: "LUNA",
            },
            {
              name: "MALBEG-PATALAN",
            },
            {
              name: "MALINENG",
            },
            {
              name: "MATINDEG",
            },
            {
              name: "MAYCABAN",
            },
            {
              name: "NACURALAN",
            },
            {
              name: "NAGMISAHAN",
            },
            {
              name: "PAITAN NORTE",
            },
            {
              name: "PAITAN SUR",
            },
            {
              name: "PIGLISAN",
            },
            {
              name: "PUGO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SABIT",
            },
            {
              name: "SALAGUSOG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SINIMBAAN",
            },
            {
              name: "TAGTAGUMBAO",
            },
            {
              name: "TUTULOY",
            },
            {
              name: "UNGAB",
            },
            {
              name: "VILLAFLORES",
            },
          ],
        },
        "GABALDON (BITULOK AND SABANI)": {
          name: "GABALDON (BITULOK AND SABANI)",
          baranggays: [
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BAGTING",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BITULOK (NORTH POB.)",
            },
            {
              name: "BUGNAN",
            },
            {
              name: "CALABASA",
            },
            {
              name: "CAMACHILE",
            },
            {
              name: "CUYAPA",
            },
            {
              name: "GABALDON (BITULOK AND SABANI)",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "MACASANDAL",
            },
            {
              name: "MALINAO",
            },
            {
              name: "PANTOC",
            },
            {
              name: "PINAMALISAN",
            },
            {
              name: "SAWMILL",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TAGUMPAY",
            },
          ],
        },
        "GAPAN CITY": {
          name: "GAPAN CITY",
          baranggays: [
            {
              name: "BALANTE",
            },
            {
              name: "BAYANIHAN",
            },
            {
              name: "BULAK",
            },
            {
              name: "BUNGO",
            },
            {
              name: "GAPAN CITY",
            },
            {
              name: "KAPALANGAN",
            },
            {
              name: "MABUNGA",
            },
            {
              name: "MABURAK",
            },
            {
              name: "MAHIPON",
            },
            {
              name: "MAKABACLAY",
            },
            {
              name: "MALIMBA",
            },
            {
              name: "MANGINO",
            },
            {
              name: "MARELO",
            },
            {
              name: "PAMBUAN",
            },
            {
              name: "PARCUTELA",
            },
            {
              name: "PUTING TUBIG",
            },
            {
              name: "SAN LORENZO (POB.)",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO CRISTO NORTE",
            },
            {
              name: "SANTO CRISTO SUR",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        "GENERAL MAMERTO NATIVIDAD": {
          name: "GENERAL MAMERTO NATIVIDAD",
          baranggays: [
            {
              name: "BALANGKARE NORTE",
            },
            {
              name: "BALANGKARE SUR",
            },
            {
              name: "BALARING",
            },
            {
              name: "BELEN",
            },
            {
              name: "BRAVO",
            },
            {
              name: "BUROL",
            },
            {
              name: "GENERAL MAMERTO NATIVIDAD",
            },
            {
              name: "KABULIHAN",
            },
            {
              name: "MAG-ASAWANG SAMPALOC",
            },
            {
              name: "MANAROG",
            },
            {
              name: "MATAAS NA KAHOY",
            },
            {
              name: "PANACSAC",
            },
            {
              name: "PICALEON",
            },
            {
              name: "PINAHAN",
            },
            {
              name: "PLATERO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULA",
            },
            {
              name: "PULONG SINGKAMAS",
            },
            {
              name: "SAPANG BATO",
            },
            {
              name: "TALABUTAB NORTE",
            },
            {
              name: "TALABUTAB SUR",
            },
          ],
        },
        "GENERAL TINIO (PAPAYA)": {
          name: "GENERAL TINIO (PAPAYA)",
          baranggays: [
            {
              name: "BAGO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "GENERAL TINIO (PAPAYA)",
            },
            {
              name: "NAZARETH",
            },
            {
              name: "PADOLINA",
            },
            {
              name: "PALALE",
            },
            {
              name: "PIAS",
            },
            {
              name: "POBLACION CENTRAL",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PULONG MATONG",
            },
            {
              name: "RIO CHICO",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
          ],
        },
        GUIMBA: {
          name: "GUIMBA",
          baranggays: [
            {
              name: "AYOS LOMBOY",
            },
            {
              name: "BACAYAO",
            },
            {
              name: "BAGONG BARRIO",
            },
            {
              name: "BALBALINO",
            },
            {
              name: "BALINGOG EAST",
            },
            {
              name: "BALINGOG WEST",
            },
            {
              name: "BANITAN",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BULAKID",
            },
            {
              name: "BUNOL",
            },
            {
              name: "CABALLERO",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CAINGIN TABING ILOG",
            },
            {
              name: "CALEM",
            },
            {
              name: "CAMILING",
            },
            {
              name: "CARDINAL",
            },
            {
              name: "CASONGSONG",
            },
            {
              name: "CATIMON",
            },
            {
              name: "CAVITE",
            },
            {
              name: "CAWAYAN BUGTONG",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "CULONG",
            },
            {
              name: "ESCANO",
            },
            {
              name: "FAIGAL",
            },
            {
              name: "GALVAN",
            },
            {
              name: "GUIMBA",
            },
            {
              name: "GUISET",
            },
            {
              name: "LAMORITO",
            },
            {
              name: "LENNEC",
            },
            {
              name: "MACAMIAS",
            },
            {
              name: "MACAPABELLAG",
            },
            {
              name: "MACATCATUIT",
            },
            {
              name: "MANACSAC",
            },
            {
              name: "MANGGANG MARIKIT",
            },
            {
              name: "MATURANOC",
            },
            {
              name: "MAYBUBON",
            },
            {
              name: "NAGLABRAHAN",
            },
            {
              name: "NAGPANDAYAN",
            },
            {
              name: "NARVACAN I",
            },
            {
              name: "NARVACAN II",
            },
            {
              name: "PACAC",
            },
            {
              name: "PARTIDA I",
            },
            {
              name: "PARTIDA II",
            },
            {
              name: "PASONG INCHIC",
            },
            {
              name: "SAINT JOHN DISTRICT (POB.)",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN BERNARDINO",
            },
            {
              name: "SAN MARCELINO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA VERONICA DISTRICT (POB.)",
            },
            {
              name: "SANTO CRISTO DISTRICT (POB.)",
            },
            {
              name: "SARANAY DISTRICT (POB.)",
            },
            {
              name: "SINULATAN",
            },
            {
              name: "SUBOL",
            },
            {
              name: "TAMPAC I",
            },
            {
              name: "TAMPAC II AND III",
            },
            {
              name: "TRIALA",
            },
            {
              name: "YUSON",
            },
          ],
        },
        JAEN: {
          name: "JAEN",
          baranggays: [
            {
              name: "CALABASA",
            },
            {
              name: "DAMPULAN (POB.)",
            },
            {
              name: "DON MARIANO MARCOS (POB.)",
            },
            {
              name: "HILERA",
            },
            {
              name: "IMBUNIA",
            },
            {
              name: "IMELDA POB. (DOÑA AURORA)",
            },
            {
              name: "JAEN",
            },
            {
              name: "LAMBAKIN",
            },
            {
              name: "LANGLA",
            },
            {
              name: "MAGSALISI",
            },
            {
              name: "MALABON-KAINGIN",
            },
            {
              name: "MARAWA",
            },
            {
              name: "NIYUGAN",
            },
            {
              name: "OCAMPO-RIVERA DISTRICT (POB.)",
            },
            {
              name: "PAKOL",
            },
            {
              name: "PAMACPACAN",
            },
            {
              name: "PINANGGAAN",
            },
            {
              name: "PUTLOD",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JOSEF (NABAO)",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO TOMAS NORTH",
            },
            {
              name: "SANTO TOMAS SOUTH",
            },
            {
              name: "SAPANG",
            },
            {
              name: "ULANIN-PITAK",
            },
          ],
        },
        LAUR: {
          name: "LAUR",
          baranggays: [
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BETANIA",
            },
            {
              name: "CANANTONG",
            },
            {
              name: "LAUR",
            },
            {
              name: "NAUZON",
            },
            {
              name: "PANGARULONG",
            },
            {
              name: "PINAGBAYANAN",
            },
            {
              name: "SAGANA",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSEF",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SICLONG",
            },
          ],
        },
        LICAB: {
          name: "LICAB",
          baranggays: [
            {
              name: "LICAB",
            },
            {
              name: "LINAO",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SAN CASIMIRO",
            },
            {
              name: "SAN CRISTOBAL",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "TABING ILOG",
            },
            {
              name: "VILLAROSA",
            },
          ],
        },
        LLANERA: {
          name: "LLANERA",
          baranggays: [
            {
              name: "A. BONIFACIO",
            },
            {
              name: "BAGUMBAYAN (POB.)",
            },
            {
              name: "BOSQUE",
            },
            {
              name: "CARIDAD NORTE",
            },
            {
              name: "CARIDAD SUR",
            },
            {
              name: "CASILE",
            },
            {
              name: "FLORIDA BLANCA",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "GENERAL RICARTE",
            },
            {
              name: "GOMEZ",
            },
            {
              name: "INANAMA",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "LLANERA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MURCON",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "VILLA VINIEGAS",
            },
          ],
        },
        LUPAO: {
          name: "LUPAO",
          baranggays: [
            {
              name: "AGUPALO ESTE",
            },
            {
              name: "AGUPALO WESTE",
            },
            {
              name: "ALALAY CHICA",
            },
            {
              name: "ALALAY GRANDE",
            },
            {
              name: "BAGONG FLORES",
            },
            {
              name: "BALBALUNGAO",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CORDERO",
            },
            {
              name: "J. U. TIENZO (ARIMAL)",
            },
            {
              name: "LUPAO",
            },
            {
              name: "MAPANGPANG",
            },
            {
              name: "NAMULANDAYAN",
            },
            {
              name: "PARISTA",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SALVACION I",
            },
            {
              name: "SALVACION II",
            },
            {
              name: "SAN ANTONIO ESTE",
            },
            {
              name: "SAN ANTONIO WESTE",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO DOMINGO",
            },
          ],
        },
        NAMPICUAN: {
          name: "NAMPICUAN",
          baranggays: [
            {
              name: "ALEMANIA",
            },
            {
              name: "AMBASADOR ALZATE VILLAGE",
            },
            {
              name: "CABADUCAN EAST (POB.)",
            },
            {
              name: "CABADUCAN WEST (POB.)",
            },
            {
              name: "CABAWANGAN",
            },
            {
              name: "EAST CENTRAL POBLACION",
            },
            {
              name: "EDY",
            },
            {
              name: "ESTACION (POB.)",
            },
            {
              name: "MAELING",
            },
            {
              name: "MAYANTOC",
            },
            {
              name: "MEDICO",
            },
            {
              name: "MONIC",
            },
            {
              name: "NAMPICUAN",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "NORTHWEST POBLACION",
            },
            {
              name: "RECUERDO",
            },
            {
              name: "SOUTH CENTRAL POBLACION",
            },
            {
              name: "SOUTHEAST POBLACION",
            },
            {
              name: "SOUTHWEST POBLACION",
            },
            {
              name: "TONY",
            },
            {
              name: "WEST CENTRAL POBLACION",
            },
            {
              name: "WEST POBLACION",
            },
          ],
        },
        "PALAYAN CITY": {
          name: "PALAYAN CITY",
          baranggays: [
            {
              name: "ATATE",
            },
            {
              name: "AULO",
            },
            {
              name: "BAGONG BUHAY",
            },
            {
              name: "BO. MILITAR (FORT MAGSAYSAY)",
            },
            {
              name: "CABALLERO",
            },
            {
              name: "CAIMITO",
            },
            {
              name: "DOÑA JOSEFA",
            },
            {
              name: "GANADERIA",
            },
            {
              name: "IMELDA VALLEY",
            },
            {
              name: "LANGKA",
            },
            {
              name: "MALATE (POB.)",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MANACNAC",
            },
            {
              name: "MAPAIT",
            },
            {
              name: "MARCOS VILLAGE",
            },
            {
              name: "PALAYAN CITY",
            },
            {
              name: "POPOLON PAGAS",
            },
            {
              name: "SANTOLAN",
            },
            {
              name: "SAPANG BUHO",
            },
            {
              name: "SINGALAT",
            },
          ],
        },
        PANTABANGAN: {
          name: "PANTABANGAN",
          baranggays: [
            {
              name: "CADACLAN",
            },
            {
              name: "CAMBITALA",
            },
            {
              name: "CONVERSION",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GANDUZ",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "MALBANG",
            },
            {
              name: "NAPON-NAPON",
            },
            {
              name: "PANTABANGAN",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "VILLARICA",
            },
          ],
        },
        PEÑARANDA: {
          name: "PEÑARANDA",
          baranggays: [
            {
              name: "CALLOS",
            },
            {
              name: "LAS PIÑAS",
            },
            {
              name: "PEÑARANDA",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "SAN JOSEF",
            },
            {
              name: "SAN MARIANO (MAUGAT)",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SINASAJAN",
            },
          ],
        },
        QUEZON: {
          name: "QUEZON",
          baranggays: [
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BERTESE",
            },
            {
              name: "DOÑA LUCIA",
            },
            {
              name: "DULONG BAYAN",
            },
            {
              name: "ILOG BALIWAG",
            },
            {
              name: "PULONG BAHAY",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SAN ALEJANDRO",
            },
            {
              name: "SAN ANDRES I",
            },
            {
              name: "SAN ANDRES II",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO TOMAS FERIA",
            },
          ],
        },
        RIZAL: {
          name: "RIZAL",
          baranggays: [
            {
              name: "AGBANNAWAG",
            },
            {
              name: "AGLIPAY (CURVA)",
            },
            {
              name: "BICOS",
            },
            {
              name: "CABUCBUCAN",
            },
            {
              name: "CALAOCAN DISTRICT",
            },
            {
              name: "CANAAN EAST",
            },
            {
              name: "CANAAN WEST",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "ESTRELLA",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "MACAPSING",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "PACO ROMAN",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "POBLACION CENTRAL",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PORTAL",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ESTEBAN",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "VILLA LABRADOR",
            },
            {
              name: "VILLA PARAISO",
            },
          ],
        },
        "SAN ANTONIO": {
          name: "SAN ANTONIO",
          baranggays: [
            {
              name: "BULIRAN",
            },
            {
              name: "CAMA JUAN",
            },
            {
              name: "JULO",
            },
            {
              name: "LAWANG KUPANG",
            },
            {
              name: "LUYOS",
            },
            {
              name: "MAUGAT",
            },
            {
              name: "PANABINGAN",
            },
            {
              name: "PAPAYA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "TIKIW",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "ALUA",
            },
            {
              name: "CALABA",
            },
            {
              name: "MALAPIT",
            },
            {
              name: "MANGGA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "TABON",
            },
          ],
        },
        "SAN JOSE CITY": {
          name: "SAN JOSE CITY",
          baranggays: [
            {
              name: "A. PASCUAL",
            },
            {
              name: "ABAR 2ND",
            },
            {
              name: "ABAR IST",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "CAANAWAN",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "CAMANACSACAN",
            },
            {
              name: "CANUTO RAMOS POB. (DISTRICT III)",
            },
            {
              name: "CRISANTO SANCHEZ POB. (DISTRICT V)",
            },
            {
              name: "CULAYLAY",
            },
            {
              name: "DIZOL",
            },
            {
              name: "FERDINAND E. MARCOS POB. (DISTRICT II)",
            },
            {
              name: "KALIWANAGAN",
            },
            {
              name: "KITA-KITA",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MANICLA",
            },
            {
              name: "PALESTINA",
            },
            {
              name: "PARANG MANGGA",
            },
            {
              name: "PINILI",
            },
            {
              name: "PORAIS",
            },
            {
              name: "RAFAEL RUEDA, SR. POB. (DISTRICT I)",
            },
            {
              name: "RAYMUNDO EUGENIO POB. (DISTRICT IV)",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN JOSE CITY",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MAURICIO",
            },
            {
              name: "SANTO NIÑO 1ST",
            },
            {
              name: "SANTO NIÑO 2ND",
            },
            {
              name: "SANTO NIÑO 3RD",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SIBUT",
            },
            {
              name: "SINIPIT BUBON",
            },
            {
              name: "TABULAC",
            },
            {
              name: "TAYABO",
            },
            {
              name: "TONDOD",
            },
            {
              name: "TULAT",
            },
            {
              name: "VILLA FLORESCA",
            },
            {
              name: "VILLA JOSON (PARILLA)",
            },
            {
              name: "VILLA MARINA",
            },
          ],
        },
        "SAN LEONARDO": {
          name: "SAN LEONARDO",
          baranggays: [
            {
              name: "BONIFACIO DISTRICT (POB.)",
            },
            {
              name: "BURGOS DISTRICT (POB.)",
            },
            {
              name: "CASTELLANO",
            },
            {
              name: "DIVERSION",
            },
            {
              name: "MAGPAPALAYOC",
            },
            {
              name: "MALLORCA",
            },
            {
              name: "MAMBANGNAN",
            },
            {
              name: "NIEVES",
            },
            {
              name: "RIZAL DISTRICT (POB.)",
            },
            {
              name: "SAN ANTON",
            },
            {
              name: "SAN BARTOLOME (POB.)",
            },
            {
              name: "SAN LEONARDO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TABUATING",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TAMBO ADORABLE",
            },
          ],
        },
        "SANTA ROSA": {
          name: "SANTA ROSA",
          baranggays: [
            {
              name: "AGUINALDO",
            },
            {
              name: "BERANG",
            },
            {
              name: "BURGOS",
            },
            {
              name: "COJUANGCO (POB.)",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GOMEZ",
            },
            {
              name: "INSPECTOR",
            },
            {
              name: "ISLA",
            },
            {
              name: "LA FUENTE",
            },
            {
              name: "LIWAYWAY",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALACAÑANG",
            },
            {
              name: "MALIOLIO",
            },
            {
              name: "MAPALAD",
            },
            {
              name: "RAJAL CENTRO",
            },
            {
              name: "RAJAL NORTE",
            },
            {
              name: "RAJAL SUR",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SAN GREGORIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSEP",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SAPSAP",
            },
            {
              name: "SOLEDAD",
            },
            {
              name: "TAGPOS",
            },
            {
              name: "TRAMO",
            },
            {
              name: "VALENZUELA (POB.)",
            },
            {
              name: "ZAMORA (POB.)",
            },
          ],
        },
        "SANTO DOMINGO": {
          name: "SANTO DOMINGO",
          baranggays: [
            {
              name: "BALOC",
            },
            {
              name: "BUASAO",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CASULUCAN",
            },
            {
              name: "COMITANG",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DOLORES",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "HULO",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MALAYA (POOK MALAYA)",
            },
            {
              name: "MALAYANTOC",
            },
            {
              name: "MAMBARAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULONG BULI",
            },
            {
              name: "SAGABA",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN FABIAN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO ROSARIO",
            },
          ],
        },
        "SCIENCE CITY OF MUÑOZ": {
          name: "SCIENCE CITY OF MUÑOZ",
          baranggays: [
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BALANTE",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BICAL",
            },
            {
              name: "CABISUCULAN",
            },
            {
              name: "CALABALABAAN",
            },
            {
              name: "CALISITAN",
            },
            {
              name: "CATALANACAN",
            },
            {
              name: "CURVA",
            },
            {
              name: "FRANZA",
            },
            {
              name: "GABALDON",
            },
            {
              name: "LABNEY",
            },
            {
              name: "LICAONG",
            },
            {
              name: "LINGLINGAY",
            },
            {
              name: "MAGTANGGOL",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MANGANDINGAY",
            },
            {
              name: "MAPANGPANG",
            },
            {
              name: "MARAGOL",
            },
            {
              name: "MATINGKIS",
            },
            {
              name: "NAGLABRAHAN",
            },
            {
              name: "PALUSAPIS",
            },
            {
              name: "PANDALLA",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "RANG-AYAN",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAPANG CAWAYAN",
            },
            {
              name: "SCIENCE CITY OF MUÑOZ",
            },
            {
              name: "VILLA CUIZON",
            },
            {
              name: "VILLA ISLA",
            },
            {
              name: "VILLA NATI",
            },
            {
              name: "VILLA SANTOS",
            },
          ],
        },
        TALAVERA: {
          name: "TALAVERA",
          baranggays: [
            {
              name: "ANDAL ALINO (POB.)",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAKAL I",
            },
            {
              name: "BAKAL II",
            },
            {
              name: "BAKAL III",
            },
            {
              name: "BALUGA",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BANTUG HACIENDA",
            },
            {
              name: "BANTUG HAMOG (BASANG HAMOG)",
            },
            {
              name: "BUGTONG NA BULI",
            },
            {
              name: "BULAC",
            },
            {
              name: "BURNAY",
            },
            {
              name: "CAANIPLAHAN",
            },
            {
              name: "CABUBULAONAN",
            },
            {
              name: "CALIPAHAN",
            },
            {
              name: "CAMPOS",
            },
            {
              name: "CAPUTICAN",
            },
            {
              name: "CASULUCAN ESTE",
            },
            {
              name: "COLLADO",
            },
            {
              name: "DIMASALANG NORTE",
            },
            {
              name: "DIMASALANG SUR",
            },
            {
              name: "DINARAYAT",
            },
            {
              name: "ESGUERRA DISTRICT (POB.)",
            },
            {
              name: "GULOD",
            },
            {
              name: "HOMESTEAD I",
            },
            {
              name: "HOMESTEAD II",
            },
            {
              name: "KINALANGUYAN",
            },
            {
              name: "LA TORRE",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAESTRANG KIKAY (POB.)",
            },
            {
              name: "MAMANDIL",
            },
            {
              name: "MARCOS DISTRICT (POB.)",
            },
            {
              name: "MATINGKIS",
            },
            {
              name: "MINABUYOC",
            },
            {
              name: "PAG-ASA (POB.)",
            },
            {
              name: "PALUDPOD",
            },
            {
              name: "PANTOC BULAC",
            },
            {
              name: "PINAGPANAAN",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "PULA",
            },
            {
              name: "PULONG SAN MIGUEL (POB.)",
            },
            {
              name: "PUROK MATIAS (POB.)",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN MIGUEL NA MUNTI",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN RICARDO",
            },
            {
              name: "SIBUL",
            },
            {
              name: "SICSICAN MATANDA",
            },
            {
              name: "TABACAO",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "TALAVERA",
            },
            {
              name: "VALLE",
            },
          ],
        },
        TALUGTUG: {
          name: "TALUGTUG",
          baranggays: [
            {
              name: "ALULA",
            },
            {
              name: "BAYBAYABAS",
            },
            {
              name: "BUTED",
            },
            {
              name: "CABIANGAN",
            },
            {
              name: "CALISITAN",
            },
            {
              name: "CINENSE",
            },
            {
              name: "CULIAT",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MAGSAYSAY (POB.)",
            },
            {
              name: "MAYAMOT I",
            },
            {
              name: "MAYAMOT II",
            },
            {
              name: "NANGABULAN",
            },
            {
              name: "OSMEÑA (POB.)",
            },
            {
              name: "PANGIT",
            },
            {
              name: "PATOLA",
            },
            {
              name: "QUEZON (POB.)",
            },
            {
              name: "QUIRINO (POB.)",
            },
            {
              name: "ROXAS (POB.)",
            },
            {
              name: "SAGUING",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SARINGAYA",
            },
            {
              name: "SAVERONA",
            },
            {
              name: "TALUGTUG",
            },
            {
              name: "TANDOC",
            },
            {
              name: "VILLA BOADO",
            },
            {
              name: "VILLA ROSARIO",
            },
          ],
        },
        ZARAGOZA: {
          name: "ZARAGOZA",
          baranggays: [
            {
              name: "BATITANG",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "H. ROMERO",
            },
            {
              name: "MACARSE",
            },
            {
              name: "MANAUL",
            },
            {
              name: "MAYAMOT",
            },
            {
              name: "PANTOC",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUCIA OLD",
            },
            {
              name: "SANTA LUCIA YOUNG",
            },
            {
              name: "SANTO ROSARIO OLD",
            },
            {
              name: "SANTO ROSARIO YOUNG",
            },
            {
              name: "VALERIANA",
            },
            {
              name: "ZARAGOZA",
            },
          ],
        },
      },
    },
    "NUEVA VIZCAYA": {
      name: "NUEVA VIZCAYA",
      region: "REGION 2",
      municipalities: {
        "A. CASTAÑEDA": {
          name: "A. CASTAÑEDA",
          baranggays: [
            {
              name: "A. CASTAÑEDA",
            },
          ],
        },
        "ALFONSO CASTANEDA": {
          name: "ALFONSO CASTANEDA",
          baranggays: [
            {
              name: "ABUYO",
            },
            {
              name: "CAUAYAN",
            },
            {
              name: "GALINTUJA",
            },
            {
              name: "LIPUGA",
            },
            {
              name: "LUBLUB (POB.)",
            },
            {
              name: "PELAWAY",
            },
          ],
        },
        AMBAGUIO: {
          name: "AMBAGUIO",
          baranggays: [
            {
              name: "AMBAGUIO",
            },
            {
              name: "AMMUEG",
            },
            {
              name: "CAMANDAG",
            },
            {
              name: "DULLI",
            },
            {
              name: "LABANG",
            },
            {
              name: "NAPO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALINGSINGAN",
            },
            {
              name: "TIBLAC",
            },
          ],
        },
        ARITAO: {
          name: "ARITAO",
          baranggays: [
            {
              name: "ANAYO",
            },
            {
              name: "ARITAO",
            },
            {
              name: "BAAN",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANGANAN",
            },
            {
              name: "BETI",
            },
            {
              name: "BONE NORTH",
            },
            {
              name: "BONE SOUTH",
            },
            {
              name: "CALITLITAN",
            },
            {
              name: "CANABUAN",
            },
            {
              name: "CANAREM",
            },
            {
              name: "COMON",
            },
            {
              name: "CUTAR",
            },
            {
              name: "DARAPIDAP",
            },
            {
              name: "KIRANG",
            },
            {
              name: "LATAR-NOCNOC-SAN FRANCISCO",
            },
            {
              name: "NAGCUARTELAN",
            },
            {
              name: "OCAO-CAPINIAAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "TABUENG",
            },
            {
              name: "TUCANON",
            },
            {
              name: "YAWAY",
            },
          ],
        },
        BAGABAG: {
          name: "BAGABAG",
          baranggays: [
            {
              name: "BAGABAG",
            },
            {
              name: "BAKIR",
            },
            {
              name: "BARETBET",
            },
            {
              name: "CAREB",
            },
            {
              name: "LANTAP",
            },
            {
              name: "MURONG",
            },
            {
              name: "NANGALISAN",
            },
            {
              name: "PANIKI",
            },
            {
              name: "POGONSINO",
            },
            {
              name: "QUIRINO (POB.)",
            },
            {
              name: "SAN GERONIMO (POB.)",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "TUAO NORTH",
            },
            {
              name: "TUAO SOUTH",
            },
            {
              name: "VILLA COLOMA (POB.)",
            },
            {
              name: "VILLAROS",
            },
          ],
        },
        BAMBANG: {
          name: "BAMBANG",
          baranggays: [
            {
              name: "ABIAN",
            },
            {
              name: "ABINGANAN",
            },
            {
              name: "ALIAGA",
            },
            {
              name: "ALMAGUER NORTH",
            },
            {
              name: "ALMAGUER SOUTH",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "BANGGOT (POB.)",
            },
            {
              name: "BARAT",
            },
            {
              name: "BUAG (POB.)",
            },
            {
              name: "CALAOCAN (POB.)",
            },
            {
              name: "DULLAO",
            },
            {
              name: "HOMESTEAD",
            },
            {
              name: "INDIANA",
            },
            {
              name: "MABUSLO",
            },
            {
              name: "MACATE",
            },
            {
              name: "MAGSAYSAY HILLS",
            },
            {
              name: "MANAMTAM",
            },
            {
              name: "MAUAN",
            },
            {
              name: "PALLAS",
            },
            {
              name: "SALINAS",
            },
            {
              name: "SAN ANTONIO NORTH",
            },
            {
              name: "SAN ANTONIO SOUTH",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN LEONARDO",
            },
            {
              name: "SANTO DOMINGO (TABANGAN)",
            },
            {
              name: "SANTO DOMINGO WEST",
            },
          ],
        },
        BAYOMBONG: {
          name: "BAYOMBONG",
          baranggays: [
            {
              name: "BANSING",
            },
            {
              name: "BAYOMBONG",
            },
            {
              name: "BONFAL EAST",
            },
            {
              name: "BONFAL PROPER",
            },
            {
              name: "BONFAL WEST",
            },
            {
              name: "BUENAVISTA (VISTA HILLS)",
            },
            {
              name: "BUSILAC",
            },
            {
              name: "CABUAAN",
            },
            {
              name: "CASAT",
            },
            {
              name: "DISTRICT III POB. (DON M. PEREZ)",
            },
            {
              name: "DISTRICT IV (POB.)",
            },
            {
              name: "DON DOMINGO MADDELA POB. (DISTRICT I)",
            },
            {
              name: "DON MARIANO MARCOS",
            },
            {
              name: "DON TOMAS MADDELA POB. (DISTRICT II)",
            },
            {
              name: "IPIL-CUNEG",
            },
            {
              name: "LA TORRE NORTH",
            },
            {
              name: "LA TORRE SOUTH",
            },
            {
              name: "LUYANG",
            },
            {
              name: "MAGAPUY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MASOC",
            },
            {
              name: "PAITAN",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN NICOLAS NORTH (LUYANG)",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "VISTA ALEGRE (B. BARINGIN)",
            },
          ],
        },
        DIADI: {
          name: "DIADI",
          baranggays: [
            {
              name: "AMPAKLING",
            },
            {
              name: "ARWAS",
            },
            {
              name: "BALETE",
            },
            {
              name: "BUGNAY",
            },
            {
              name: "BUTAO",
            },
            {
              name: "DECABACAN",
            },
            {
              name: "DIADI",
            },
            {
              name: "DURUAROG",
            },
            {
              name: "ESCOTING",
            },
            {
              name: "LANGCA",
            },
            {
              name: "LURAD",
            },
            {
              name: "NAGSABARAN",
            },
            {
              name: "NAMAMPARAN",
            },
            {
              name: "PINYA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "VILLA AURORA",
            },
            {
              name: "VILLA FLORENTINO",
            },
          ],
        },
        "DUPAX DEL NORTE": {
          name: "DUPAX DEL NORTE",
          baranggays: [
            {
              name: "BELANCE",
            },
            {
              name: "BINNUANGAN",
            },
            {
              name: "BITNONG",
            },
            {
              name: "BULALA",
            },
            {
              name: "DUPAX DEL NORTE",
            },
            {
              name: "INABAN",
            },
            {
              name: "INEANGAN",
            },
            {
              name: "LAMO",
            },
            {
              name: "MABASA",
            },
            {
              name: "MACABENGA",
            },
            {
              name: "MALASIN (POB.)",
            },
            {
              name: "MUNGUIA",
            },
            {
              name: "NEW GUMIAD",
            },
            {
              name: "OYAO",
            },
            {
              name: "PARAI",
            },
            {
              name: "YABBI",
            },
          ],
        },
        "DUPAX DEL SUR": {
          name: "DUPAX DEL SUR",
          baranggays: [
            {
              name: "ABACA",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BALSAIN",
            },
            {
              name: "BANILA",
            },
            {
              name: "BIRUK",
            },
            {
              name: "CANABAY",
            },
            {
              name: "CAROLOTAN",
            },
            {
              name: "DOMANG",
            },
            {
              name: "DOPAJ",
            },
            {
              name: "DUPAX DEL SUR",
            },
            {
              name: "GABUT",
            },
            {
              name: "GANAO (LINGAD)",
            },
            {
              name: "KIMBUTAN",
            },
            {
              name: "KINABUAN",
            },
            {
              name: "LUKIDNON",
            },
            {
              name: "MANGAYANG",
            },
            {
              name: "PALABOTAN",
            },
            {
              name: "SANGUIT",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "TALBEK",
            },
          ],
        },
        KASIBU: {
          name: "KASIBU",
          baranggays: [
            {
              name: "ALIMIT",
            },
            {
              name: "ALLOY",
            },
            {
              name: "ANTUTOT",
            },
            {
              name: "BILET",
            },
            {
              name: "BINOGAWAN",
            },
            {
              name: "BIYOY",
            },
            {
              name: "BUA",
            },
            {
              name: "CAMAMASI",
            },
            {
              name: "CAPISAAN",
            },
            {
              name: "CATARAWAN",
            },
            {
              name: "CORDON",
            },
            {
              name: "DIDIPIO",
            },
            {
              name: "DINE",
            },
            {
              name: "KAKIDUGUEN",
            },
            {
              name: "KASIBU",
            },
            {
              name: "KONGKONG",
            },
            {
              name: "LUPA",
            },
            {
              name: "MACALONG",
            },
            {
              name: "MALABING",
            },
            {
              name: "MUTA",
            },
            {
              name: "NANTAWACAN",
            },
            {
              name: "PACQUET (ILONGOT RES.)",
            },
            {
              name: "PAO",
            },
            {
              name: "PAPAYA",
            },
            {
              name: "POBLACION (ALLOY)",
            },
            {
              name: "PUDI",
            },
            {
              name: "SEGUEM",
            },
            {
              name: "TADJI",
            },
            {
              name: "TOKOD",
            },
            {
              name: "WANGAL",
            },
            {
              name: "WATWAT",
            },
          ],
        },
        KAYAPA: {
          name: "KAYAPA",
          baranggays: [
            {
              name: "ACACIA",
            },
            {
              name: "ALANG-SALACSAC",
            },
            {
              name: "AMILONG LABENG",
            },
            {
              name: "ANSIPSIP",
            },
            {
              name: "BAAN",
            },
            {
              name: "BABADI",
            },
            {
              name: "BALANGABANG",
            },
            {
              name: "BALETE",
            },
            {
              name: "BANAO",
            },
            {
              name: "BESONG",
            },
            {
              name: "BINALIAN",
            },
            {
              name: "BUYASYAS",
            },
            {
              name: "CABALATAN-ALANG",
            },
            {
              name: "CABANGLASAN",
            },
            {
              name: "CABAYO",
            },
            {
              name: "CASTILLO VILLAGE",
            },
            {
              name: "KAYAPA",
            },
            {
              name: "KAYAPA PROPER EAST",
            },
            {
              name: "KAYAPA PROPER WEST",
            },
            {
              name: "LATBANG",
            },
            {
              name: "LAWIGAN",
            },
            {
              name: "MAPAYAO",
            },
            {
              name: "NANSIAKAN",
            },
            {
              name: "PAMPANG (POB.)",
            },
            {
              name: "PANGAWAN",
            },
            {
              name: "PINAYAG",
            },
            {
              name: "PINGKIAN",
            },
            {
              name: "SAN FABIAN",
            },
            {
              name: "TALECABCAB",
            },
            {
              name: "TIDANG VILLAGE",
            },
            {
              name: "TUBONGAN",
            },
          ],
        },
        QUEZON: {
          name: "QUEZON",
          baranggays: [
            {
              name: "AURORA",
            },
            {
              name: "BARESBES",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BULIWAO",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "CALIAT (POB.)",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "DARUBBA",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MADDIANGAT",
            },
            {
              name: "NALUBBUNAN",
            },
            {
              name: "QUEZON",
            },
            {
              name: "RUNRUNO",
            },
          ],
        },
        "SANTA FE": {
          name: "SANTA FE",
          baranggays: [
            {
              name: "ATBU",
            },
            {
              name: "BACNENG",
            },
            {
              name: "BALETE",
            },
            {
              name: "BALILING",
            },
            {
              name: "BANTINAN",
            },
            {
              name: "BARACBAC",
            },
            {
              name: "BUYASYAS",
            },
            {
              name: "CANABUAN",
            },
            {
              name: "IMUGAN",
            },
            {
              name: "MALICO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SINAPAOAN",
            },
            {
              name: "TACTAC",
            },
            {
              name: "UNIB",
            },
            {
              name: "VILLA FLORES",
            },
          ],
        },
        SOLANO: {
          name: "SOLANO",
          baranggays: [
            {
              name: "AGGUB",
            },
            {
              name: "BAGAHABAG",
            },
            {
              name: "BANGAAN",
            },
            {
              name: "BANGAR",
            },
            {
              name: "BASCARAN",
            },
            {
              name: "COMMUNAL",
            },
            {
              name: "CONCEPCION (CALALABANGAN)",
            },
            {
              name: "CURIFANG",
            },
            {
              name: "DADAP",
            },
            {
              name: "LACTAWAN",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PILAR D. GALIMA",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "QUEZON",
            },
            {
              name: "QUIRINO",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SOLANO",
            },
            {
              name: "TUCAL",
            },
            {
              name: "UDDIAWAN",
            },
            {
              name: "WACAL",
            },
          ],
        },
        VILLAVERDE: {
          name: "VILLAVERDE",
          baranggays: [
            {
              name: "BINTAWAN NORTE",
            },
            {
              name: "BINTAWAN SUR",
            },
            {
              name: "CABULUAN",
            },
            {
              name: "IBUNG",
            },
            {
              name: "NAGBITIN",
            },
            {
              name: "OCAPON",
            },
            {
              name: "PIEZA",
            },
            {
              name: "POBLACION (TUROD)",
            },
            {
              name: "SAWMILL",
            },
            {
              name: "VILLAVERDE",
            },
          ],
        },
      },
    },
    "OCCIDENTAL MINDORO": {
      name: "OCCIDENTAL MINDORO",
      region: "REGION 4-B",
      municipalities: {
        "ABRA DE ILOG": {
          name: "ABRA DE ILOG",
          baranggays: [
            {
              name: "ABRA DE ILOG",
            },
            {
              name: "ARMADO",
            },
            {
              name: "BALAO",
            },
            {
              name: "CABACAO",
            },
            {
              name: "LUMANGBAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "STA. MARIA",
            },
            {
              name: "TIBAG",
            },
            {
              name: "UDALO (CAMURONG)",
            },
            {
              name: "WAWA",
            },
          ],
        },
        CALINTAAN: {
          name: "CALINTAAN",
          baranggays: [
            {
              name: "CALINTAAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "IRIRON",
            },
            {
              name: "MALPALON",
            },
            {
              name: "NEW DAGUPAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POYPOY",
            },
            {
              name: "TANYAG",
            },
          ],
        },
        LOOC: {
          name: "LOOC",
          baranggays: [
            {
              name: "AGKAWAYAN",
            },
            {
              name: "AMBIL",
            },
            {
              name: "BALIKYAS",
            },
            {
              name: "BONBON (POB.)",
            },
            {
              name: "BULACAN",
            },
            {
              name: "BUROL",
            },
            {
              name: "GUITNA (POB.)",
            },
            {
              name: "KANLURAN (POB.)",
            },
            {
              name: "LOOC",
            },
            {
              name: "TALAOTAO",
            },
          ],
        },
        LUBANG: {
          name: "LUBANG",
          baranggays: [
            {
              name: "ARAW AT BITUIN (POB.)",
            },
            {
              name: "BAGONG SIKAT (POB.)",
            },
            {
              name: "BANAAG AT PAG-ASA (POB.)",
            },
            {
              name: "BINAKAS",
            },
            {
              name: "CABRA",
            },
            {
              name: "LIKAS NG SILANGAN (POB.)",
            },
            {
              name: "LUBANG",
            },
            {
              name: "MAGINHAWA (POB.)",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MALIIG",
            },
            {
              name: "NINIKAT NG PAG-ASA (POB.)",
            },
            {
              name: "PARAISO (POB.)",
            },
            {
              name: "SURVILLE (POB.)",
            },
            {
              name: "TAGBAC",
            },
            {
              name: "TANGAL",
            },
            {
              name: "TILIK",
            },
            {
              name: "VIGO",
            },
          ],
        },
        MAGSAYSAY: {
          name: "MAGSAYSAY",
          baranggays: [
            {
              name: "ALIBOG",
            },
            {
              name: "CAGURAY",
            },
            {
              name: "CALAWAG",
            },
            {
              name: "GAPASAN",
            },
            {
              name: "LASTE",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "NICOLAS (BULO)",
            },
            {
              name: "PACLOLO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PURNAGA",
            },
            {
              name: "SANTA TERESA",
            },
            {
              name: "SIBALAT",
            },
          ],
        },
        MAMBURAO: {
          name: "MAMBURAO",
          baranggays: [
            {
              name: "BALANSAY",
            },
            {
              name: "FATIMA (TII)",
            },
            {
              name: "MAMBURAO",
            },
            {
              name: "PAYOMPON",
            },
            {
              name: "POBLACION 1 (BARANGAY 1)",
            },
            {
              name: "POBLACION 2 (BARANGAY 2)",
            },
            {
              name: "POBLACION 3 (BARANGAY 3)",
            },
            {
              name: "POBLACION 4 (BARANGAY 4)",
            },
            {
              name: "POBLACION 5 (BARANGAY 5)",
            },
            {
              name: "POBLACION 6 (BARANGAY 6)",
            },
            {
              name: "POBLACION 7 (BARANGAY 7)",
            },
            {
              name: "POBLACION 8 (BARANGAY 8)",
            },
            {
              name: "SAN LUIS (LIGANG)",
            },
            {
              name: "TALABAAN",
            },
            {
              name: "TANGKALAN",
            },
            {
              name: "TAYAMAAN",
            },
          ],
        },
        PALUAN: {
          name: "PALUAN",
          baranggays: [
            {
              name: "ALIPAOY",
            },
            {
              name: "BAGONG SILANG POB. (BGY 5)",
            },
            {
              name: "HANDANG TUMULONG POB. (BGY 2)",
            },
            {
              name: "HARRISON",
            },
            {
              name: "LUMANGBAYAN",
            },
            {
              name: "MANANAO",
            },
            {
              name: "MAPALAD POB. (BGY 1)",
            },
            {
              name: "MARIKIT",
            },
            {
              name: "PAG-ASA NG BAYAN POB. (BGY 4)",
            },
            {
              name: "PALUAN",
            },
            {
              name: "SAN JOSE POB. (BGY 6)",
            },
            {
              name: "SILAHIS NG PAG-ASA POB. (BGY 3)",
            },
            {
              name: "TUBILI",
            },
          ],
        },
        RIZAL: {
          name: "RIZAL",
          baranggays: [
            {
              name: "ADELA",
            },
            {
              name: "AGUAS",
            },
            {
              name: "MAGSIKAP",
            },
            {
              name: "MALAWAAN",
            },
            {
              name: "MANOOT",
            },
            {
              name: "PITOGO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "RIZAL",
            },
            {
              name: "RUMBANG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        SABLAYAN: {
          name: "SABLAYAN",
          baranggays: [
            {
              name: "BATONG BUHAY",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CLAUDIO SALGADO",
            },
            {
              name: "GENERAL EMILIO AGUINALDO",
            },
            {
              name: "IBUD",
            },
            {
              name: "ILVITA",
            },
            {
              name: "LAGNAS",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "MALISBONG",
            },
            {
              name: "PAETAN",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "POBLACION (LUMANGBAYAN)",
            },
            {
              name: "SABLAYAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TUBAN",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "AMBULONG",
            },
            {
              name: "ANSIRAY",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BANGKAL",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BATASAN",
            },
            {
              name: "BAYOTBOT",
            },
            {
              name: "BUBOG",
            },
            {
              name: "BURI",
            },
            {
              name: "CAMBURAY",
            },
            {
              name: "CAMINAWIT",
            },
            {
              name: "CATAYUNGAN",
            },
            {
              name: "CENTRAL",
            },
            {
              name: "ILING PROPER",
            },
            {
              name: "INASAKAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "LA CURVA",
            },
            {
              name: "LABANGAN ILING",
            },
            {
              name: "LABANGAN POBLACION",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGBAY",
            },
            {
              name: "MANGARIN",
            },
            {
              name: "MAPAYA",
            },
            {
              name: "MONTE CLARO",
            },
            {
              name: "MURTHA",
            },
            {
              name: "NATANDOL",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PAWICAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
          ],
        },
        "SANTA CRUZ": {
          name: "SANTA CRUZ",
          baranggays: [
            {
              name: "ALACAAK",
            },
            {
              name: "BARAHAN",
            },
            {
              name: "CASAGUE",
            },
            {
              name: "DAYAP",
            },
            {
              name: "KURTINGANAN",
            },
            {
              name: "LUMANGBAYAN",
            },
            {
              name: "MULAWIN",
            },
            {
              name: "PINAGTURILAN (SAN PEDRO)",
            },
            {
              name: "POBLACION I (BARANGAY 1)",
            },
            {
              name: "POBLACION II (BARANGAY 2)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
          ],
        },
      },
    },
    "ORIENTAL MINDORO": {
      name: "ORIENTAL MINDORO",
      region: "REGION 4-B",
      municipalities: {
        BACO: {
          name: "BACO",
          baranggays: [
            {
              name: "ALAG",
            },
            {
              name: "BACO",
            },
            {
              name: "BANGKATAN",
            },
            {
              name: "BARAS (MANGYAN MINORITY)",
            },
            {
              name: "BAYANAN",
            },
            {
              name: "BURBULI",
            },
            {
              name: "CATWIRAN I",
            },
            {
              name: "CATWIRAN II",
            },
            {
              name: "DULANGAN I",
            },
            {
              name: "DULANGAN II",
            },
            {
              name: "LANTUYANG (MANGYAN MINORITY)",
            },
            {
              name: "LUMANG BAYAN",
            },
            {
              name: "MALAPAD",
            },
            {
              name: "MANGANGAN I",
            },
            {
              name: "MANGANGAN II",
            },
            {
              name: "MAYABIG",
            },
            {
              name: "PAMBISAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULANG-TUBIG",
            },
            {
              name: "PUTICAN-CABULO",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN IGNACIO (DULANGAN III)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ROSA I",
            },
            {
              name: "SANTA ROSA II",
            },
            {
              name: "TABON-TABON",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "WATER",
            },
          ],
        },
        BANSUD: {
          name: "BANSUD",
          baranggays: [
            {
              name: "ALCADESMA",
            },
            {
              name: "BANSUD",
            },
            {
              name: "BATO",
            },
            {
              name: "CONRAZON",
            },
            {
              name: "MALO",
            },
            {
              name: "MANIHALA",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PROPER BANSUD",
            },
            {
              name: "PROPER TIGUISAN",
            },
            {
              name: "ROSACARA",
            },
            {
              name: "SALCEDO",
            },
            {
              name: "SUMAGUI",
            },
            {
              name: "VILLA PAG-ASA",
            },
          ],
        },
        BONGABONG: {
          name: "BONGABONG",
          baranggays: [
            {
              name: "ANILAO",
            },
            {
              name: "APLAYA",
            },
            {
              name: "BAGUMBAYAN I",
            },
            {
              name: "BAGUMBAYAN II",
            },
            {
              name: "BATANGAN",
            },
            {
              name: "BONGABONG",
            },
            {
              name: "BUKAL",
            },
            {
              name: "CAMANTIGUE",
            },
            {
              name: "CARMUNDO",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "DAYHAGAN",
            },
            {
              name: "FORMON",
            },
            {
              name: "HAGAN",
            },
            {
              name: "HAGUPIT",
            },
            {
              name: "IPIL",
            },
            {
              name: "KALIGTASAN",
            },
            {
              name: "LABASAN",
            },
            {
              name: "LABONAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LISAP",
            },
            {
              name: "LUNA",
            },
            {
              name: "MALITBOG",
            },
            {
              name: "MAPANG",
            },
            {
              name: "MASAGUISI",
            },
            {
              name: "MINA DE ORO",
            },
            {
              name: "MORENTE",
            },
            {
              name: "OGBOT",
            },
            {
              name: "ORCONUMA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLUSAHI",
            },
            {
              name: "SAGANA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SIGANGE",
            },
            {
              name: "TAWAS",
            },
          ],
        },
        "BULALACAO (SAN PEDRO)": {
          name: "BULALACAO (SAN PEDRO)",
          baranggays: [
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BALATASAN",
            },
            {
              name: "BENLI (MANGYAN SETTLEMENT)",
            },
            {
              name: "BULALACAO (SAN PEDRO)",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAMBUNANG (POB.)",
            },
            {
              name: "CAMPAASAN (POB.)",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MAUJAO",
            },
            {
              name: "MILAGROSA (GUIOB)",
            },
            {
              name: "NASUKOB (POB.)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FRANCISCO (ALIMAWAN)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE (BUYAYAO)",
            },
          ],
        },
        "CITY OF CALAPAN": {
          name: "CITY OF CALAPAN",
          baranggays: [
            {
              name: "BALINGAYAN",
            },
            {
              name: "BALITE",
            },
            {
              name: "BARUYAN",
            },
            {
              name: "BATINO",
            },
            {
              name: "BAYANAN I",
            },
            {
              name: "BAYANAN II",
            },
            {
              name: "BIGA",
            },
            {
              name: "BONDOC",
            },
            {
              name: "BUCAYAO",
            },
            {
              name: "BUHUAN",
            },
            {
              name: "BULUSAN",
            },
            {
              name: "CALERO (POB.)",
            },
            {
              name: "CAMANSIHAN",
            },
            {
              name: "CAMILMIL",
            },
            {
              name: "CANUBING I",
            },
            {
              name: "CANUBING II",
            },
            {
              name: "CITY OF CALAPAN",
            },
            {
              name: "COMUNAL",
            },
            {
              name: "GUINOBATAN",
            },
            {
              name: "GULOD",
            },
            {
              name: "GUTAD",
            },
            {
              name: "IBABA EAST (POB.)",
            },
            {
              name: "IBABA WEST (POB.)",
            },
            {
              name: "ILAYA (POB.)",
            },
            {
              name: "LALUD",
            },
            {
              name: "LAZARETO",
            },
            {
              name: "LIBIS (POB.)",
            },
            {
              name: "LUMANG BAYAN",
            },
            {
              name: "MAHAL NA PANGALAN",
            },
            {
              name: "MAIDLANG",
            },
            {
              name: "MALAD",
            },
            {
              name: "MALAMIG",
            },
            {
              name: "MANAGPI",
            },
            {
              name: "MASIPIT",
            },
            {
              name: "NAG-IBA I",
            },
            {
              name: "NAG-IBA II",
            },
            {
              name: "NAVOTAS",
            },
            {
              name: "PACHOCA",
            },
            {
              name: "PALHI",
            },
            {
              name: "PANGGALAAN",
            },
            {
              name: "PARANG",
            },
            {
              name: "PATAS",
            },
            {
              name: "PERSONAS",
            },
            {
              name: "PUTINGTUBIG",
            },
            {
              name: "SALONG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN VICENTE CENTRAL (POB.)",
            },
            {
              name: "SAN VICENTE EAST (POB.)",
            },
            {
              name: "SAN VICENTE NORTH (POB.)",
            },
            {
              name: "SAN VICENTE SOUTH (POB.)",
            },
            {
              name: "SAN VICENTE WEST (POB.)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA MARIA VILLAGE",
            },
            {
              name: "SANTA RITA (BUNGAHAN)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAPUL",
            },
            {
              name: "SILONAY",
            },
            {
              name: "SUQUI",
            },
            {
              name: "TAWAGAN",
            },
            {
              name: "TAWIRAN",
            },
            {
              name: "TIBAG",
            },
            {
              name: "WAWA",
            },
          ],
        },
        GLORIA: {
          name: "GLORIA",
          baranggays: [
            {
              name: "AGOS",
            },
            {
              name: "AGSALIN",
            },
            {
              name: "ALMA VILLA",
            },
            {
              name: "ANDRES BONIFACIO",
            },
            {
              name: "BALETE",
            },
            {
              name: "BANUS",
            },
            {
              name: "BANUTAN",
            },
            {
              name: "BULAKLAKAN",
            },
            {
              name: "BUONG LUPA",
            },
            {
              name: "GAUDENCIO ANTONINO",
            },
            {
              name: "GLORIA",
            },
            {
              name: "GUIMBONAN",
            },
            {
              name: "KAWIT",
            },
            {
              name: "LUCIO LAUREL",
            },
            {
              name: "MACARIO ADRIATICO",
            },
            {
              name: "MALAMIG",
            },
            {
              name: "MALAYONG",
            },
            {
              name: "MALIGAYA POBLACION",
            },
            {
              name: "MALUBAY",
            },
            {
              name: "MANGUYANG",
            },
            {
              name: "MARAGOOC",
            },
            {
              name: "MIRAYAN",
            },
            {
              name: "NARRA",
            },
            {
              name: "PAPANDUNGIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA THERESA",
            },
            {
              name: "TAMBONG",
            },
          ],
        },
        MANSALAY: {
          name: "MANSALAY",
          baranggays: [
            {
              name: "B. DEL MUNDO",
            },
            {
              name: "BALUGO",
            },
            {
              name: "BONBON",
            },
            {
              name: "BUDBURAN",
            },
            {
              name: "CABALWA",
            },
            {
              name: "DON PEDRO",
            },
            {
              name: "MALIWANAG",
            },
            {
              name: "MANAUL",
            },
            {
              name: "MANSALAY",
            },
            {
              name: "PANAYTAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROMA",
            },
            {
              name: "SANTA BRIGIDA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "VILLA CELESTIAL",
            },
            {
              name: "WASIG",
            },
            {
              name: "WAYGAN",
            },
          ],
        },
        NAUJAN: {
          name: "NAUJAN",
          baranggays: [
            {
              name: "ADRIALUNA",
            },
            {
              name: "ANDRES ILAGAN (MAG-ASAWANG TUBIG)",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "APITONG",
            },
            {
              name: "ARANGIN",
            },
            {
              name: "AURORA",
            },
            {
              name: "BACUNGAN",
            },
            {
              name: "BAGONG BUHAY",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANCURO",
            },
            {
              name: "BANUTON",
            },
            {
              name: "BARCENAGA",
            },
            {
              name: "BAYANI",
            },
            {
              name: "BUHANGIN",
            },
            {
              name: "CABURO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAO",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "ESTRELLA",
            },
            {
              name: "EVANGELISTA",
            },
            {
              name: "GAMAO",
            },
            {
              name: "GENERAL ESCO",
            },
            {
              name: "HERRERA",
            },
            {
              name: "INARAWAN",
            },
            {
              name: "KALINISAN",
            },
            {
              name: "LAGUNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGTIBAY",
            },
            {
              name: "MAHABANG PARANG",
            },
            {
              name: "MALAYA",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MALVAR",
            },
            {
              name: "MASAGANA",
            },
            {
              name: "MASAGUING",
            },
            {
              name: "MELGAR A",
            },
            {
              name: "MELGAR B",
            },
            {
              name: "METOLZA",
            },
            {
              name: "MONTELAGO",
            },
            {
              name: "MONTEMAYOR",
            },
            {
              name: "MOTODERAZO",
            },
            {
              name: "MULAWIN",
            },
            {
              name: "NAG-IBA I",
            },
            {
              name: "NAG-IBA II",
            },
            {
              name: "NAUJAN",
            },
            {
              name: "PAGKAKAISA",
            },
            {
              name: "PAITAN",
            },
            {
              name: "PANIQUIAN",
            },
            {
              name: "PINAGSABANGAN I",
            },
            {
              name: "PINAGSABANGAN II",
            },
            {
              name: "PIÑAHAN",
            },
            {
              name: "POBLACION I (BARANGAY I)",
            },
            {
              name: "POBLACION II (BARANGAY II)",
            },
            {
              name: "POBLACION III (BARANGAY III)",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN AGUSTIN I",
            },
            {
              name: "SAN AGUSTIN II",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TIGKAN",
            },
          ],
        },
        PINAMALAYAN: {
          name: "PINAMALAYAN",
          baranggays: [
            {
              name: "ANOLING",
            },
            {
              name: "BACUNGAN",
            },
            {
              name: "BANGBANG",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BULI",
            },
            {
              name: "CACAWAN",
            },
            {
              name: "CALINGAG",
            },
            {
              name: "DEL RAZON",
            },
            {
              name: "GUINHAWA",
            },
            {
              name: "INCLANAY",
            },
            {
              name: "LUMANGBAYAN",
            },
            {
              name: "MALAYA",
            },
            {
              name: "MALIANGCOG",
            },
            {
              name: "MANINGCOL",
            },
            {
              name: "MARAYOS",
            },
            {
              name: "MARFRANCISCO",
            },
            {
              name: "NABUSLOT",
            },
            {
              name: "PAGALAGALA",
            },
            {
              name: "PALAYAN",
            },
            {
              name: "PAMBISAN MALAKI",
            },
            {
              name: "PAMBISAN MUNTI",
            },
            {
              name: "PANGGULAYAN",
            },
            {
              name: "PAPANDAYAN",
            },
            {
              name: "PILI",
            },
            {
              name: "PINAMALAYAN",
            },
            {
              name: "QUINABIGAN",
            },
            {
              name: "RANZO",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SABANG",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "WAWA",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
          ],
        },
        POLA: {
          name: "POLA",
          baranggays: [
            {
              name: "BACAWAN",
            },
            {
              name: "BACUNGAN",
            },
            {
              name: "BATUHAN",
            },
            {
              name: "BAYANAN",
            },
            {
              name: "BIGA",
            },
            {
              name: "BUHAY NA TUBIG",
            },
            {
              name: "CALIMA",
            },
            {
              name: "CALUBASANHON",
            },
            {
              name: "CAMPAMENTO",
            },
            {
              name: "CASILIGAN",
            },
            {
              name: "MALIBAGO",
            },
            {
              name: "MALUANLUAN",
            },
            {
              name: "MATULATULA",
            },
            {
              name: "MISONG",
            },
            {
              name: "PAHILAHAN",
            },
            {
              name: "PANIKIHAN",
            },
            {
              name: "POLA",
            },
            {
              name: "PULA",
            },
            {
              name: "PUTING CACAO",
            },
            {
              name: "TAGBAKIN",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TIGUIHAN",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
          ],
        },
        "PUERTO GALERA": {
          name: "PUERTO GALERA",
          baranggays: [
            {
              name: "ANINUAN",
            },
            {
              name: "BACLAYAN",
            },
            {
              name: "BALATERO",
            },
            {
              name: "DULANGAN",
            },
            {
              name: "PALANGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUERTO GALERA",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTO NINO",
            },
            {
              name: "SINANDIGAN",
            },
            {
              name: "TABINAY",
            },
            {
              name: "VILLAFLOR",
            },
          ],
        },
        ROXAS: {
          name: "ROXAS",
          baranggays: [
            {
              name: "BAGUMBAYAN (POB.)",
            },
            {
              name: "CANTIL",
            },
            {
              name: "HAPPY VALLEY",
            },
            {
              name: "HAPPY VALLEY",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "LITTLE TANAUAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MARASKA",
            },
            {
              name: "ODIONG",
            },
            {
              name: "PACLASAN (POB.)",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN AQUILINO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "UYAO",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        "SAN TEODORO": {
          name: "SAN TEODORO",
          baranggays: [
            {
              name: "BIGAAN",
            },
            {
              name: "CAAGUTAYAN",
            },
            {
              name: "CALANGATAN",
            },
            {
              name: "CALSAPA",
            },
            {
              name: "ILAG",
            },
            {
              name: "LUMANGBAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN TEODORO",
            },
            {
              name: "TACLIGAN",
            },
          ],
        },
        SOCORRO: {
          name: "SOCORRO",
          baranggays: [
            {
              name: "BAGSOK",
            },
            {
              name: "BATONG DALIG",
            },
            {
              name: "BAYUIN",
            },
            {
              name: "BUGTONG NA TUOG",
            },
            {
              name: "CALOCMOY",
            },
            {
              name: "CALUBAYAN",
            },
            {
              name: "CATININGAN",
            },
            {
              name: "FORTUNA",
            },
            {
              name: "HAPPY VALLEY",
            },
            {
              name: "LEUTEBORO I",
            },
            {
              name: "LEUTEBORO II",
            },
            {
              name: "MA. CONCEPCION",
            },
            {
              name: "MABUHAY I",
            },
            {
              name: "MABUHAY II",
            },
            {
              name: "MALUGAY",
            },
            {
              name: "MATUNGAO",
            },
            {
              name: "MONTEVERDE",
            },
            {
              name: "PASI I",
            },
            {
              name: "PASI II",
            },
            {
              name: "SANTO DOMINGO (LAPOG)",
            },
            {
              name: "SOCORRO",
            },
            {
              name: "SUBAAN",
            },
            {
              name: "VILLAREAL (DAAN)",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
          ],
        },
        VICTORIA: {
          name: "VICTORIA",
          baranggays: [
            {
              name: "ALCATE",
            },
            {
              name: "ANTONINO",
            },
            {
              name: "BABANGONAN",
            },
            {
              name: "BAGONG BUHAY",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAMBANIN",
            },
            {
              name: "BETHEL",
            },
            {
              name: "CANAAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DUONGAN",
            },
            {
              name: "JOSE LEIDO JR.",
            },
            {
              name: "LOYAL",
            },
            {
              name: "MABINI",
            },
            {
              name: "MACATOC",
            },
            {
              name: "MALABO",
            },
            {
              name: "MERIT",
            },
            {
              name: "ORDOVILLA",
            },
            {
              name: "PAKYAS",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CRISTOBAL",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN GELACIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN NARCISO",
            },
            {
              name: "URDANETA",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "VILLA CERVEZA",
            },
          ],
        },
      },
    },
    PALAWAN: {
      name: "PALAWAN",
      region: "REGION 4-B",
      municipalities: {
        ABORLAN: {
          name: "ABORLAN",
          baranggays: [
            {
              name: "ABORLAN",
            },
            {
              name: "APO-APORAWAN",
            },
            {
              name: "APOC-APOC",
            },
            {
              name: "APORAWAN",
            },
            {
              name: "BARAKE",
            },
            {
              name: "CABIGAAN",
            },
            {
              name: "CULANDANUM",
            },
            {
              name: "GOGOGNAN",
            },
            {
              name: "IRAAN",
            },
            {
              name: "ISAUB",
            },
            {
              name: "JOSE RIZAL",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGBABADIL",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RAMON MAGSAYSAY",
            },
            {
              name: "SAGPANGAN",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "TAGPAIT",
            },
            {
              name: "TIGMAN",
            },
          ],
        },
        AGUTAYA: {
          name: "AGUTAYA",
          baranggays: [
            {
              name: "ABAGAT (POB.)",
            },
            {
              name: "AGUTAYA",
            },
            {
              name: "ALGECIRAS",
            },
            {
              name: "BANGCAL (POB.)",
            },
            {
              name: "CAMBIAN (POB.)",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DIIT",
            },
            {
              name: "MARACANAO",
            },
            {
              name: "MATARAWIS",
            },
            {
              name: "VILLAFRIA",
            },
            {
              name: "VILLASOL",
            },
          ],
        },
        ARACELI: {
          name: "ARACELI",
          baranggays: [
            {
              name: "ARACELI",
            },
            {
              name: "BALOGO",
            },
            {
              name: "DAGMAN",
            },
            {
              name: "DALAYAWON",
            },
            {
              name: "LUMACAD",
            },
            {
              name: "MADOLDOLON",
            },
            {
              name: "MAURINGUEN",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "POBLACION (CENTRO)",
            },
            {
              name: "SAN JOSE DE ORO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TALOTO",
            },
            {
              name: "TININTINAN",
            },
            {
              name: "TUDELA (CALANDAGAN)",
            },
          ],
        },
        BALABAC: {
          name: "BALABAC",
          baranggays: [
            {
              name: "AGUTAYAN",
            },
            {
              name: "BALABAC",
            },
            {
              name: "BANCALAAN",
            },
            {
              name: "BUGSUK (NEW CAGAYANCILLO)",
            },
            {
              name: "CATAGUPAN",
            },
            {
              name: "INDALAWAN",
            },
            {
              name: "MALAKING ILOG",
            },
            {
              name: "MANGSEE",
            },
            {
              name: "MELVILLE",
            },
            {
              name: "PANDANAN",
            },
            {
              name: "PASIG",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "POBLACION V",
            },
            {
              name: "POBLACION VI",
            },
            {
              name: "RABOR",
            },
            {
              name: "RAMOS",
            },
            {
              name: "SALANG",
            },
            {
              name: "SEBARING",
            },
          ],
        },
        BATARAZA: {
          name: "BATARAZA",
          baranggays: [
            {
              name: "BATARAZA",
            },
            {
              name: "BONO-BONO",
            },
            {
              name: "BULALACAO",
            },
            {
              name: "BULILUYAN",
            },
            {
              name: "CULANDANUM",
            },
            {
              name: "IGANG-IGANG",
            },
            {
              name: "INOGBONG",
            },
            {
              name: "IWAHIG",
            },
            {
              name: "MALIHUD",
            },
            {
              name: "MALITUB",
            },
            {
              name: "MARANGAS (POB.)",
            },
            {
              name: "OCAYAN",
            },
            {
              name: "PURING",
            },
            {
              name: "RIO TUBA",
            },
            {
              name: "SANDOVAL",
            },
            {
              name: "SAPA",
            },
            {
              name: "SARONG",
            },
            {
              name: "SUMBILING",
            },
            {
              name: "TABUD",
            },
            {
              name: "TAGNATO",
            },
            {
              name: "TAGOLANGO",
            },
            {
              name: "TARATAK",
            },
            {
              name: "TARUSAN",
            },
          ],
        },
        "BROOKE'S POINT": {
          name: "BROOKE'S POINT",
          baranggays: [
            {
              name: "AMAS",
            },
            {
              name: "ARIBUNGOS",
            },
            {
              name: "BARONG-BARONG",
            },
            {
              name: "BROOKE'S POINT",
            },
            {
              name: "BROOKE'S POINT",
            },
            {
              name: "CALASAGUEN",
            },
            {
              name: "IMULNOD",
            },
            {
              name: "IPILAN",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MALIS",
            },
            {
              name: "ORING-ORING",
            },
            {
              name: "PANGOBILIAN",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SALOGON",
            },
            {
              name: "SAMAREÑANA",
            },
            {
              name: "SARAZA",
            },
            {
              name: "TUBTUB",
            },
          ],
        },
        BUSUANGA: {
          name: "BUSUANGA",
          baranggays: [
            {
              name: "BOGTONG",
            },
            {
              name: "BULUANG",
            },
            {
              name: "BUSUANGA",
            },
            {
              name: "CHEEY",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "MAGLALAMBAY",
            },
            {
              name: "NEW BUSUANGA (POB.)",
            },
            {
              name: "OLD BUSUANGA",
            },
            {
              name: "PANLAITAN",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SAGRADA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        CAGAYANCILLO: {
          name: "CAGAYANCILLO",
          baranggays: [
            {
              name: "BANTAYAN (POB.)",
            },
            {
              name: "CAGAYANCILLO",
            },
            {
              name: "CALSADA (POB.)",
            },
            {
              name: "CONVENTO (POB.)",
            },
            {
              name: "LIPOT NORTH (POB.)",
            },
            {
              name: "LIPOT SOUTH (POB.)",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAMPIO",
            },
            {
              name: "NUSA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TACAS (POB.)",
            },
            {
              name: "TALAGA",
            },
            {
              name: "WAHIG (POB.)",
            },
          ],
        },
        CORON: {
          name: "CORON",
          baranggays: [
            {
              name: "BANUANG DAAN",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "BINTUAN",
            },
            {
              name: "BORAC",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULALACAO",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CORON",
            },
            {
              name: "DECABOBO",
            },
            {
              name: "DECALACHAO",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "LAJALA",
            },
            {
              name: "MALAWIG",
            },
            {
              name: "MARCILLA",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TARA",
            },
            {
              name: "TURDA",
            },
          ],
        },
        CULION: {
          name: "CULION",
          baranggays: [
            {
              name: "BALALA",
            },
            {
              name: "BALDAT",
            },
            {
              name: "BINUDAC",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CULANGO",
            },
            {
              name: "CULION",
            },
            {
              name: "DE CARABAO",
            },
            {
              name: "GALOC",
            },
            {
              name: "HALSEY",
            },
            {
              name: "JARDIN",
            },
            {
              name: "LIBIS",
            },
            {
              name: "LUAC",
            },
            {
              name: "MALAKING PATAG",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "TIZA",
            },
          ],
        },
        CUYO: {
          name: "CUYO",
          baranggays: [
            {
              name: "BALADING",
            },
            {
              name: "BANGCAL (POB.)",
            },
            {
              name: "CABIGSING (POB.)",
            },
            {
              name: "CABURIAN",
            },
            {
              name: "CAPONAYAN",
            },
            {
              name: "CATADMAN (POB.)",
            },
            {
              name: "CUYO",
            },
            {
              name: "FUNDA",
            },
            {
              name: "LAGAORIAO (POB.)",
            },
            {
              name: "LUBID",
            },
            {
              name: "LUNGSOD (POB.)",
            },
            {
              name: "MANAMOC",
            },
            {
              name: "MARINGIAN",
            },
            {
              name: "PAWA",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SUBA",
            },
            {
              name: "TENGA-TENGA (POB.)",
            },
            {
              name: "TOCADAN (POB.)",
            },
          ],
        },
        DUMARAN: {
          name: "DUMARAN",
          baranggays: [
            {
              name: "BACAO",
            },
            {
              name: "BOHOL",
            },
            {
              name: "CALASAG",
            },
            {
              name: "CAPAYAS",
            },
            {
              name: "CATEP",
            },
            {
              name: "CULASIAN",
            },
            {
              name: "DANLEG",
            },
            {
              name: "DUMARAN",
            },
            {
              name: "DUMARAN (POB.)",
            },
            {
              name: "ILIAN",
            },
            {
              name: "ITANGIL",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TANATANAON",
            },
          ],
        },
        "EL NIDO (BACUIT)": {
          name: "EL NIDO (BACUIT)",
          baranggays: [
            {
              name: "ABERAWAN",
            },
            {
              name: "BAGONG BAYAN",
            },
            {
              name: "BAROTUAN",
            },
            {
              name: "BEBELADAN",
            },
            {
              name: "BUENA SUERTE POB. (BARANGAY 2)",
            },
            {
              name: "CORONG-CORONG POB. (BARANGAY 4)",
            },
            {
              name: "EL NIDO (BACUIT)",
            },
            {
              name: "EL NIDO (BACUIT)",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALIGAYA POB. (BARANGAY 1)",
            },
            {
              name: "MANLAG",
            },
            {
              name: "MASAGANA POB. (BARANGAY 3)",
            },
            {
              name: "NEW IBAJAY",
            },
            {
              name: "PASADEÑA",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SIBALTAN",
            },
            {
              name: "TENEGUIBAN",
            },
            {
              name: "VILLA LIBERTAD",
            },
            {
              name: "VILLA PAZ",
            },
          ],
        },
        KALAYAAN: {
          name: "KALAYAAN",
          baranggays: [
            {
              name: "KALAYAAN",
            },
            {
              name: "PAG-ASA (POB.)",
            },
          ],
        },
        LINAPACAN: {
          name: "LINAPACAN",
          baranggays: [
            {
              name: "BARANGONAN (ILOC)",
            },
            {
              name: "CABUNLAWAN",
            },
            {
              name: "CALIBANGBANGAN",
            },
            {
              name: "DECABAITOT",
            },
            {
              name: "LINAPACAN",
            },
            {
              name: "MAROYOGROYOG",
            },
            {
              name: "NANGALAO",
            },
            {
              name: "NEW CULAYLAYAN",
            },
            {
              name: "PICAL",
            },
            {
              name: "SAN MIGUEL (POB.)",
            },
            {
              name: "SAN NICOLAS",
            },
          ],
        },
        MAGSAYSAY: {
          name: "MAGSAYSAY",
          baranggays: [
            {
              name: "ALCOBA",
            },
            {
              name: "BALAGUEN",
            },
            {
              name: "CANIPO",
            },
            {
              name: "COCORO",
            },
            {
              name: "DANAWAN (POB.)",
            },
            {
              name: "EMILOD",
            },
            {
              name: "IGABAS",
            },
            {
              name: "LACAREN",
            },
            {
              name: "LOS ANGELES",
            },
            {
              name: "LUCBUAN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "RIZAL",
            },
          ],
        },
        NARRA: {
          name: "NARRA",
          baranggays: [
            {
              name: "ANTIPULUAN",
            },
            {
              name: "ARAMAYWAN",
            },
            {
              name: "BAGONG SIKAT",
            },
            {
              name: "BATANG-BATANG",
            },
            {
              name: "BURIRAO",
            },
            {
              name: "CAGUISAN",
            },
            {
              name: "CALATEGAS",
            },
            {
              name: "DUMAGUEÑA",
            },
            {
              name: "ELVITA",
            },
            {
              name: "ESTRELLA VILLAGE",
            },
            {
              name: "IPILAN",
            },
            {
              name: "MALATGAO",
            },
            {
              name: "MALINAO",
            },
            {
              name: "NARRA",
            },
            {
              name: "NARRA (POB.)",
            },
            {
              name: "PANACAN",
            },
            {
              name: "PANACAN 2",
            },
            {
              name: "PRINCESS URDUJA",
            },
            {
              name: "SANDOVAL",
            },
            {
              name: "TACRAS",
            },
            {
              name: "TARITIEN",
            },
            {
              name: "TERESA",
            },
            {
              name: "TINAGONG DAGAT",
            },
          ],
        },
        "PUERTO PRINCESA CITY": {
          name: "PUERTO PRINCESA CITY",
          baranggays: [
            {
              name: "BABUYAN",
            },
            {
              name: "BACUNGAN",
            },
            {
              name: "BAGONG BAYAN",
            },
            {
              name: "BAGONG PAG-ASA (POB.)",
            },
            {
              name: "BAGONG SIKAT (POB.)",
            },
            {
              name: "BAGONG SILANG (POB.)",
            },
            {
              name: "BAHILE",
            },
            {
              name: "BANCAO-BANCAO",
            },
            {
              name: "BARANGAY NG MGA MANGINGISDA",
            },
            {
              name: "BINDUYAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABAYUGAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "INAGAWAN",
            },
            {
              name: "INAGAWAN SUB-COLONY",
            },
            {
              name: "IRAWAN",
            },
            {
              name: "IWAHIG (POB.)",
            },
            {
              name: "KALIPAY (POB.)",
            },
            {
              name: "KAMUNING",
            },
            {
              name: "LANGOGAN",
            },
            {
              name: "LIWANAG (POB.)",
            },
            {
              name: "LUCBUAN",
            },
            {
              name: "LUZVIMINDA",
            },
            {
              name: "MABUHAY (POB.)",
            },
            {
              name: "MACARASCAS",
            },
            {
              name: "MAGKAKAIBIGAN (POB.)",
            },
            {
              name: "MALIGAYA (POB.)",
            },
            {
              name: "MANALO",
            },
            {
              name: "MANDARAGAT",
            },
            {
              name: "MANGGAHAN (POB.)",
            },
            {
              name: "MANINGNING (POB.)",
            },
            {
              name: "MAOYON",
            },
            {
              name: "MARUFINAS",
            },
            {
              name: "MARUYOGON",
            },
            {
              name: "MASIGLA (POB.)",
            },
            {
              name: "MASIKAP (POB.)",
            },
            {
              name: "MASIPAG (POB.)",
            },
            {
              name: "MATAHIMIK (POB.)",
            },
            {
              name: "MATIYAGA (POB.)",
            },
            {
              name: "MAUNLAD (POB.)",
            },
            {
              name: "MILAGROSA (POB.)",
            },
            {
              name: "MODEL (POB.)",
            },
            {
              name: "MONTIBLE (POB.)",
            },
            {
              name: "NAPSAN",
            },
            {
              name: "NEW PANGGANGAN",
            },
            {
              name: "PAGKAKAISA (POB.)",
            },
            {
              name: "PRINCESA (POB.)",
            },
            {
              name: "PUERTO PRINCESA CITY",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LOURDES",
            },
            {
              name: "SANTA LUCIA (POB.)",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SEASIDE (POB.)",
            },
            {
              name: "SICSICAN",
            },
            {
              name: "SIMPOCAN",
            },
            {
              name: "TAGABINIT",
            },
            {
              name: "TAGBUROS",
            },
            {
              name: "TAGUMPAY (POB.)",
            },
            {
              name: "TANABAG",
            },
            {
              name: "TANGLAW (POB.)",
            },
            {
              name: "TINIGUIBAN",
            },
          ],
        },
        QUEZON: {
          name: "QUEZON",
          baranggays: [
            {
              name: "ALFONSO XIII (POB.)",
            },
            {
              name: "ARAMAYWAN",
            },
            {
              name: "BERONG",
            },
            {
              name: "CALATAGBAK",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "ISUGOD",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MALATGAO",
            },
            {
              name: "PANITIAN",
            },
            {
              name: "PINAGLABANAN",
            },
            {
              name: "QUEZON",
            },
            {
              name: "QUINLOGAN",
            },
            {
              name: "SOWANGAN",
            },
            {
              name: "TABON",
            },
            {
              name: "TAGUSAO",
            },
          ],
        },
        "RIZAL (MARCOS)": {
          name: "RIZAL (MARCOS)",
          baranggays: [
            {
              name: "BUNOG",
            },
            {
              name: "CAMPONG ULAY",
            },
            {
              name: "CANDAWAGA",
            },
            {
              name: "CANIPAAN",
            },
            {
              name: "CULASIAN",
            },
            {
              name: "IRAAN",
            },
            {
              name: "LATUD",
            },
            {
              name: "PANALINGAAN",
            },
            {
              name: "PUNTA BAJA",
            },
            {
              name: "RANSANG",
            },
            {
              name: "RIZAL (MARCOS)",
            },
            {
              name: "TABURI",
            },
          ],
        },
        ROXAS: {
          name: "ROXAS",
          baranggays: [
            {
              name: "ABAROAN",
            },
            {
              name: "ANTONINO",
            },
            {
              name: "BAGONG BAYAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V POB. (PORAO ISLAND)",
            },
            {
              name: "BARANGAY VI POB. (JOHNSON ISLAND)",
            },
            {
              name: "CARAMAY",
            },
            {
              name: "DUMARAO",
            },
            {
              name: "IRAAN",
            },
            {
              name: "JOLO",
            },
            {
              name: "MAGARA (ARASAN)",
            },
            {
              name: "MALCAMPO",
            },
            {
              name: "MENDOZA",
            },
            {
              name: "NARRA (MINARA)",
            },
            {
              name: "NEW BARBACAN (RETAC)",
            },
            {
              name: "NEW CUYO",
            },
            {
              name: "NICANOR ZABALA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SANDOVAL",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TARADUNGAN",
            },
            {
              name: "TINITIAN",
            },
            {
              name: "TUMARBONG",
            },
          ],
        },
        "SAN VICENTE": {
          name: "SAN VICENTE",
          baranggays: [
            {
              name: "ALIMANGUAN",
            },
            {
              name: "BINGA",
            },
            {
              name: "CARURAY",
            },
            {
              name: "KEMDENG",
            },
            {
              name: "NEW AGUTAYA",
            },
            {
              name: "NEW CANIPO",
            },
            {
              name: "POBLACION (SAN VICENTE)",
            },
            {
              name: "PORT BARTON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        "SOFRONIO ESPAÑOLA": {
          name: "SOFRONIO ESPAÑOLA",
          baranggays: [
            {
              name: "ABO-ABO",
            },
            {
              name: "IRARAY",
            },
            {
              name: "ISUMBO",
            },
            {
              name: "LABOG",
            },
            {
              name: "PANITIAN",
            },
            {
              name: "PULOT CENTER",
            },
            {
              name: "PULOT SHORE (PULOT I)",
            },
            {
              name: "SOFRONIO ESPAÑOLA",
            },
          ],
        },
        TAYTAY: {
          name: "TAYTAY",
          baranggays: [
            {
              name: "ABONGAN",
            },
            {
              name: "ALACALIAN",
            },
            {
              name: "BANBANAN",
            },
            {
              name: "BANTULAN",
            },
            {
              name: "BARAS (PANGPANG)",
            },
            {
              name: "BATAS",
            },
            {
              name: "BATO",
            },
            {
              name: "BETON",
            },
            {
              name: "BUSY BEES",
            },
            {
              name: "CALAWAG",
            },
            {
              name: "CASIAN",
            },
            {
              name: "CATABAN",
            },
            {
              name: "DEBANGAN",
            },
            {
              name: "DEPLA",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIMINANGCONG",
            },
            {
              name: "MEYTEGUED",
            },
            {
              name: "MINAPLA",
            },
            {
              name: "NEW GUINLO",
            },
            {
              name: "OLD GUINLO",
            },
            {
              name: "PAGLAUM",
            },
            {
              name: "PALY (PALY ISLAND)",
            },
            {
              name: "PAMANTOLON",
            },
            {
              name: "PANCOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULARAQUEN (CANIQUE)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANDOVAL",
            },
            {
              name: "SILANGA",
            },
            {
              name: "TALOG",
            },
            {
              name: "TAYTAY",
            },
            {
              name: "TUMBOD",
            },
          ],
        },
      },
    },
    PAMPANGA: {
      name: "PAMPANGA",
      region: "REGION 3",
      municipalities: {
        "ANGELES CITY": {
          name: "ANGELES CITY",
          baranggays: [
            {
              name: "AGAPITO DEL ROSARIO",
            },
            {
              name: "AMSIC",
            },
            {
              name: "ANGELES CITY",
            },
            {
              name: "ANUNAS",
            },
            {
              name: "BALIBAGO",
            },
            {
              name: "CAPAYA",
            },
            {
              name: "CLARO M. RECTO",
            },
            {
              name: "CUAYAN",
            },
            {
              name: "CUTCUT",
            },
            {
              name: "CUTUD",
            },
            {
              name: "LOURDES NORTH WEST",
            },
            {
              name: "LOURDES SUR",
            },
            {
              name: "LOURDES SUR EAST",
            },
            {
              name: "MALABANIAS",
            },
            {
              name: "MARGOT",
            },
            {
              name: "MINING",
            },
            {
              name: "NINOY AQUINO (MARISOL)",
            },
            {
              name: "PAMPANG",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PULUNG CACUTUD",
            },
            {
              name: "PULUNG MARAGUL",
            },
            {
              name: "PULUNGBULU",
            },
            {
              name: "SALAPUNGAN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTA TRINIDAD",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO ROSARIO (POB.)",
            },
            {
              name: "SAPALIBUTAD",
            },
            {
              name: "SAPANGBATO",
            },
            {
              name: "TABUN",
            },
            {
              name: "VIRGEN DELOS REMEDIOS",
            },
          ],
        },
        APALIT: {
          name: "APALIT",
          baranggays: [
            {
              name: "APALIT",
            },
            {
              name: "BALUCUC",
            },
            {
              name: "CALANTIPE",
            },
            {
              name: "CANSINALA",
            },
            {
              name: "CAPALANGAN",
            },
            {
              name: "COLGANTE",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SUCAD",
            },
            {
              name: "SULIPAN (IBABA)",
            },
            {
              name: "SULIPAN (ITAAS)",
            },
            {
              name: "TABUYUC (SANTO ROSARIO)",
            },
          ],
        },
        ARAYAT: {
          name: "ARAYAT",
          baranggays: [
            {
              name: "ARAYAT",
            },
            {
              name: "ARENAS",
            },
            {
              name: "BALITI",
            },
            {
              name: "BATASAN",
            },
            {
              name: "BUENSUCESO",
            },
            {
              name: "CANDATING",
            },
            {
              name: "CUPANG (SANTA LUCIA)",
            },
            {
              name: "GATIAWIN",
            },
            {
              name: "GUEMASAN",
            },
            {
              name: "KALEDIAN (CAMBA)",
            },
            {
              name: "LA PAZ (TURU)",
            },
            {
              name: "LACMIT",
            },
            {
              name: "LACQUIOS",
            },
            {
              name: "MANGGA-CACUTUD",
            },
            {
              name: "MAPALAD",
            },
            {
              name: "MATAMO (SANTA LUCIA)",
            },
            {
              name: "PANLINLANG",
            },
            {
              name: "PARALAYA",
            },
            {
              name: "PLAZANG LUMA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN AGUSTIN NORTE",
            },
            {
              name: "SAN AGUSTIN SUR",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE MESULO",
            },
            {
              name: "SAN JUAN BANO",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN ROQUE BITAS",
            },
            {
              name: "SANTO NIÑO TABUAN",
            },
            {
              name: "SUCLAYIN",
            },
            {
              name: "TELAPAYONG",
            },
          ],
        },
        BACOLOR: {
          name: "BACOLOR",
          baranggays: [
            {
              name: "BACOLOR",
            },
            {
              name: "BALAS",
            },
            {
              name: "CABALANTIAN",
            },
            {
              name: "CABAMBANGAN (POB.)",
            },
            {
              name: "CABETICAN",
            },
            {
              name: "CALIBUTBUT",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DOLORES",
            },
            {
              name: "DUAT",
            },
            {
              name: "MACABACLE",
            },
            {
              name: "MAGLIMAN",
            },
            {
              name: "MALIWALU",
            },
            {
              name: "MESALIPIT",
            },
            {
              name: "PARULOG",
            },
            {
              name: "POTRERO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "TALBA",
            },
            {
              name: "TINAJERO",
            },
          ],
        },
        CANDABA: {
          name: "CANDABA",
          baranggays: [
            {
              name: "BAHAY PARE",
            },
            {
              name: "BAMBANG",
            },
            {
              name: "BARANGCA",
            },
            {
              name: "BARIT",
            },
            {
              name: "BUAS (POB.)",
            },
            {
              name: "CANDABA",
            },
            {
              name: "CUAYANG BUGTONG",
            },
            {
              name: "DALAYAP",
            },
            {
              name: "DULONG ILOG",
            },
            {
              name: "GULAP",
            },
            {
              name: "LANANG",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MAGUMBALI",
            },
            {
              name: "MANDASIG",
            },
            {
              name: "MANDILI",
            },
            {
              name: "MANGGA",
            },
            {
              name: "MAPANIQUI",
            },
            {
              name: "PALIGUI",
            },
            {
              name: "PANGCLARA",
            },
            {
              name: "PANSINAO",
            },
            {
              name: "PARALAYA (POB.)",
            },
            {
              name: "PASIG",
            },
            {
              name: "PESCADORES (POB.)",
            },
            {
              name: "PULONG GUBAT",
            },
            {
              name: "PULONG PALAZAN",
            },
            {
              name: "SALAPUNGAN",
            },
            {
              name: "SAN AGUSTIN (POB.)",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TAGULOD",
            },
            {
              name: "TALANG",
            },
            {
              name: "TENEJERO",
            },
            {
              name: "VIZAL SAN PABLO",
            },
            {
              name: "VIZAL SANTO CRISTO",
            },
            {
              name: "VIZAL SANTO NIÑO",
            },
          ],
        },
        FLORIDABLANCA: {
          name: "FLORIDABLANCA",
          baranggays: [
            {
              name: "ANON",
            },
            {
              name: "APALIT",
            },
            {
              name: "BASA AIR BASE",
            },
            {
              name: "BENEDICTO",
            },
            {
              name: "BODEGA",
            },
            {
              name: "CABANGCALAN",
            },
            {
              name: "CALANTAS",
            },
            {
              name: "CARMENCITA",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "DAMPE",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "FLORIDABLANCA",
            },
            {
              name: "FORTUNA",
            },
            {
              name: "GUTAD",
            },
            {
              name: "MABICAL",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MAWACAT",
            },
            {
              name: "NABUCLOD",
            },
            {
              name: "PABANLAG",
            },
            {
              name: "PAGUIRUAN",
            },
            {
              name: "PALMAYO",
            },
            {
              name: "PANDAGUIRIG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTO ROSARIO (MALABO)",
            },
            {
              name: "SOLIB",
            },
            {
              name: "VALDEZ",
            },
          ],
        },
        GUAGUA: {
          name: "GUAGUA",
          baranggays: [
            {
              name: "ASCOMO",
            },
            {
              name: "BANCAL",
            },
            {
              name: "GUAGUA",
            },
            {
              name: "JOSE ABAD SANTOS (SIRAN)",
            },
            {
              name: "LAMBAC",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAQUIAPO",
            },
            {
              name: "NATIVIDAD",
            },
            {
              name: "PLAZA BURGOS (POB.)",
            },
            {
              name: "PULUNGMASLE",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN JUAN BAUTISTA",
            },
            {
              name: "SAN JUAN NEPOMUCENO",
            },
            {
              name: "SAN MATIAS",
            },
            {
              name: "SAN MIGUEL (BETIS)",
            },
            {
              name: "SAN NICOLAS 1ST",
            },
            {
              name: "SAN NICOLAS 2ND",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL (DUCK ISLAND)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE (IBUS)",
            },
            {
              name: "SANTA FILOMENA (POB.)",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SANTA URSULA",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
          ],
        },
        LUBAO: {
          name: "LUBAO",
          baranggays: [
            {
              name: "BALANTACAN",
            },
            {
              name: "BANCAL PUGAD",
            },
            {
              name: "BANCAL SINUBLI",
            },
            {
              name: "BARUYA (SAN RAFAEL)",
            },
            {
              name: "CALANGAIN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DE LA PAZ",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "DON IGNACIO DIMSON",
            },
            {
              name: "LOURDES (LAUC PAU)",
            },
            {
              name: "LUBAO",
            },
            {
              name: "PRADO SIONGCO",
            },
            {
              name: "REMEDIOS",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE APUNAN",
            },
            {
              name: "SAN JOSE GUMI",
            },
            {
              name: "SAN MATIAS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS 1ST (POB.)",
            },
            {
              name: "SAN NICOLAS 2ND",
            },
            {
              name: "SAN PABLO 1ST",
            },
            {
              name: "SAN PABLO 2ND",
            },
            {
              name: "SAN PEDRO PALCARANGAN",
            },
            {
              name: "SAN PEDRO SAUG",
            },
            {
              name: "SAN ROQUE ARBOL",
            },
            {
              name: "SAN ROQUE DAU",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUCIA (POB.)",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTA TERESA 1ST",
            },
            {
              name: "SANTA TERESA 2ND",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO (PRADO ARUBA)",
            },
            {
              name: "SANTO TOMAS (POB.)",
            },
          ],
        },
        MABALACAT: {
          name: "MABALACAT",
          baranggays: [
            {
              name: "ATLU-BOLA",
            },
            {
              name: "BICAL",
            },
            {
              name: "BUNDAGUL",
            },
            {
              name: "CACUTUD",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CAMACHILES",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DAU",
            },
            {
              name: "DOLORES",
            },
            {
              name: "DUQUIT",
            },
            {
              name: "LAKANDULA",
            },
            {
              name: "MABALACAT",
            },
            {
              name: "MABIGA",
            },
            {
              name: "MACAPAGAL VILLAGE",
            },
            {
              name: "MAMATITANG",
            },
            {
              name: "MANGALIT",
            },
            {
              name: "MARCOS VILLAGE",
            },
            {
              name: "MAWAQUE",
            },
            {
              name: "PARALAYUNAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SAPANG BALEN",
            },
            {
              name: "SAPANG BIABAS",
            },
            {
              name: "TABUN",
            },
          ],
        },
        MACABEBE: {
          name: "MACABEBE",
          baranggays: [
            {
              name: "BATASAN",
            },
            {
              name: "CADUANG TETE",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "CASTULI",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "DALAYAP",
            },
            {
              name: "MACABEBE",
            },
            {
              name: "MATAGUITI",
            },
            {
              name: "SAN ESTEBAN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN GABRIEL (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTA LUTGARDA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA RITA (POB.)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO (POB.)",
            },
            {
              name: "SAPLAD DAVID",
            },
            {
              name: "TACASAN",
            },
            {
              name: "TELACSAN",
            },
          ],
        },
        MAGALANG: {
          name: "MAGALANG",
          baranggays: [
            {
              name: "AYALA",
            },
            {
              name: "BUCANAN",
            },
            {
              name: "CAMIAS",
            },
            {
              name: "DOLORES",
            },
            {
              name: "ESCALER",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "MAGALANG",
            },
            {
              name: "NAVALING",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISO",
            },
            {
              name: "SAN ILDEFONSO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS 1ST (POB.)",
            },
            {
              name: "SAN NICOLAS 2ND",
            },
            {
              name: "SAN PABLO (POB.)",
            },
            {
              name: "SAN PEDRO I",
            },
            {
              name: "SAN PEDRO II",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TURU",
            },
          ],
        },
        MASANTOL: {
          name: "MASANTOL",
          baranggays: [
            {
              name: "ALAULI",
            },
            {
              name: "BAGANG",
            },
            {
              name: "BALIBAGO",
            },
            {
              name: "BEBE ANAC",
            },
            {
              name: "BEBE MATUA",
            },
            {
              name: "BULACUS",
            },
            {
              name: "CAMBASI",
            },
            {
              name: "MALAULI",
            },
            {
              name: "MASANTOL",
            },
            {
              name: "NIGUI",
            },
            {
              name: "PALIMPE",
            },
            {
              name: "PUTI",
            },
            {
              name: "SAGRADA (TIBAGIN)",
            },
            {
              name: "SAN AGUSTIN (CAINGIN)",
            },
            {
              name: "SAN ISIDRO ANAC",
            },
            {
              name: "SAN ISIDRO MATUA (POB.)",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUCIA ANAC (POB.)",
            },
            {
              name: "SANTA LUCIA MATUA",
            },
            {
              name: "SANTA LUCIA PAGUIBA",
            },
            {
              name: "SANTA LUCIA WAKAS",
            },
            {
              name: "SANTA MONICA (CAINGIN)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAPANG KAWAYAN",
            },
            {
              name: "SUA",
            },
          ],
        },
        MEXICO: {
          name: "MEXICO",
          baranggays: [
            {
              name: "ACLI",
            },
            {
              name: "ANAO",
            },
            {
              name: "BALAS",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAMUNING",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CULUBASA",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "DOLORES (PIRING)",
            },
            {
              name: "EDEN",
            },
            {
              name: "GANDUS",
            },
            {
              name: "LAGUNDI",
            },
            {
              name: "LAPUT",
            },
            {
              name: "LAUG",
            },
            {
              name: "MASAMAT",
            },
            {
              name: "MASANGSANG",
            },
            {
              name: "MEXICO",
            },
            {
              name: "NUEVA VICTORIA",
            },
            {
              name: "PANDACAQUI",
            },
            {
              name: "PANGATLAN",
            },
            {
              name: "PANIPUAN",
            },
            {
              name: "PARIAN (POB.)",
            },
            {
              name: "SABANILLA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN JOSE MALINO",
            },
            {
              name: "SAN JOSE MATULID",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PATRICIO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SAPANG MAISAC",
            },
            {
              name: "SUCLABAN",
            },
            {
              name: "TANGLE (TANGLAY)",
            },
          ],
        },
        MINALIN: {
          name: "MINALIN",
          baranggays: [
            {
              name: "BULAC",
            },
            {
              name: "DAWE",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MANIANGO",
            },
            {
              name: "MINALIN",
            },
            {
              name: "SAN FRANCISCO 1ST",
            },
            {
              name: "SAN FRANCISCO 2ND",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO ROSARIO (POB.)",
            },
            {
              name: "SAPLAD",
            },
          ],
        },
        PORAC: {
          name: "PORAC",
          baranggays: [
            {
              name: "BABO PANGULO",
            },
            {
              name: "BABO SACAN",
            },
            {
              name: "BALUBAD",
            },
            {
              name: "CALZADANG BAYU",
            },
            {
              name: "CAMIAS",
            },
            {
              name: "CANGATBA",
            },
            {
              name: "DIAZ",
            },
            {
              name: "DOLORES (HACIENDA DOLORES)",
            },
            {
              name: "INARARO (AETAS)",
            },
            {
              name: "JALUNG",
            },
            {
              name: "MANCATIAN",
            },
            {
              name: "MANIBAUG LIBUTAD",
            },
            {
              name: "MANIBAUG PARALAYA",
            },
            {
              name: "MANIBAUG PASIG",
            },
            {
              name: "MANUALI",
            },
            {
              name: "MITLA PROPER",
            },
            {
              name: "PALAT",
            },
            {
              name: "PIAS",
            },
            {
              name: "PIO",
            },
            {
              name: "PLANAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PORAC",
            },
            {
              name: "PULONG SANTOL",
            },
            {
              name: "SALU",
            },
            {
              name: "SAN JOSE MITLA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SAPANG UWAK (AETAS)",
            },
            {
              name: "SEPUNG BULAUN",
            },
            {
              name: "SINURA",
            },
            {
              name: "VILLA MARIA (AETAS)",
            },
          ],
        },
        "SAN FERNANDO CITY": {
          name: "SAN FERNANDO CITY",
          baranggays: [
            {
              name: "ALASAS",
            },
            {
              name: "BALITI",
            },
            {
              name: "BULAON",
            },
            {
              name: "CALULUT",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "DELA PAZ NORTE",
            },
            {
              name: "DELA PAZ SUR",
            },
            {
              name: "DOLORES",
            },
            {
              name: "JULIANA",
            },
            {
              name: "LARA",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MAGLIMAN",
            },
            {
              name: "MAIMPIS",
            },
            {
              name: "MALINO",
            },
            {
              name: "MALPITIC",
            },
            {
              name: "PANDARAS",
            },
            {
              name: "PANIPUAN",
            },
            {
              name: "PULUNG BULU",
            },
            {
              name: "QUEBIAUAN",
            },
            {
              name: "SAGUIN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN FERNANDO CITY",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO (POB.)",
            },
            {
              name: "SINDALAN",
            },
            {
              name: "TELABASTAGAN",
            },
          ],
        },
        "SAN LUIS": {
          name: "SAN LUIS",
          baranggays: [
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA CRUZ PAMBILOG",
            },
            {
              name: "SANTA CRUZ POBLACION",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SANTO TOMAS",
            },
          ],
        },
        "SAN SIMON": {
          name: "SAN SIMON",
          baranggays: [
            {
              name: "CONCEPCION",
            },
            {
              name: "DE LA PAZ",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PABLO LIBUTAD",
            },
            {
              name: "SAN PABLO PROPER",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN SIMON",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        "SANTA ANA": {
          name: "SANTA ANA",
          baranggays: [
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOAQUIN (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO ROSARIO",
            },
          ],
        },
        "SANTA RITA": {
          name: "SANTA RITA",
          baranggays: [
            {
              name: "BECURAN",
            },
            {
              name: "DILA-DILA",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN BASILIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MATIAS",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA RITA",
            },
          ],
        },
        "SANTO TOMAS": {
          name: "SANTO TOMAS",
          baranggays: [
            {
              name: "MORAS DE LA PAZ",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN MATIAS",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO ROSARIO (PAU)",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SAPA (SANTO NIÑO)",
            },
          ],
        },
        "SASMUAN (SEXMOAN)": {
          name: "SASMUAN (SEXMOAN)",
          baranggays: [
            {
              name: "BATANG 1ST",
            },
            {
              name: "BATANG 2ND",
            },
            {
              name: "MABUANBUAN",
            },
            {
              name: "MALUSAC",
            },
            {
              name: "SABITANAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN NICOLAS 1ST",
            },
            {
              name: "SAN NICOLAS 2ND",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA LUCIA (POB.)",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SASMUAN (SEXMOAN)",
            },
          ],
        },
      },
    },
    PANGASINAN: {
      name: "PANGASINAN",
      region: "REGION 1",
      municipalities: {
        AGNO: {
          name: "AGNO",
          baranggays: [
            {
              name: "AGNO",
            },
            {
              name: "ALLABON",
            },
            {
              name: "ALOLENG",
            },
            {
              name: "BANGAN-ODA",
            },
            {
              name: "BARUAN",
            },
            {
              name: "BOBOY",
            },
            {
              name: "CAYUNGNAN",
            },
            {
              name: "DANGLEY",
            },
            {
              name: "GAYUSAN",
            },
            {
              name: "MACABOBONI",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "NAMATUCAN",
            },
            {
              name: "PATAR",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "TUPA",
            },
            {
              name: "VIGA",
            },
          ],
        },
        AGUILAR: {
          name: "AGUILAR",
          baranggays: [
            {
              name: "AGUILAR",
            },
            {
              name: "BAYAOAS",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BOCACLIW",
            },
            {
              name: "BOCBOC EAST",
            },
            {
              name: "BOCBOC WEST",
            },
            {
              name: "BUER",
            },
            {
              name: "CALSIB",
            },
            {
              name: "LAOAG",
            },
            {
              name: "MANLOCBOC",
            },
            {
              name: "NINOY",
            },
            {
              name: "PANACOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POGOMBOA",
            },
            {
              name: "POGONSILI",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TAMPAC",
            },
          ],
        },
        "ALAMINOS CITY": {
          name: "ALAMINOS CITY",
          baranggays: [
            {
              name: "ALAMINOS CITY",
            },
            {
              name: "ALOS",
            },
            {
              name: "AMANDIEGO",
            },
            {
              name: "AMANGBANGAN",
            },
            {
              name: "BALANGOBONG",
            },
            {
              name: "BALAYANG",
            },
            {
              name: "BALEYADAAN",
            },
            {
              name: "BISOCOL",
            },
            {
              name: "BOLANEY",
            },
            {
              name: "BUED",
            },
            {
              name: "CABATUAN",
            },
            {
              name: "CAYUCAY",
            },
            {
              name: "DULACAC",
            },
            {
              name: "INERANGAN",
            },
            {
              name: "LANDOC",
            },
            {
              name: "LINMANSANGAN",
            },
            {
              name: "LUCAP",
            },
            {
              name: "LUCAP INANSUANA",
            },
            {
              name: "LUCAP NATIONAL ROAD TO WARF",
            },
            {
              name: "LUCAP RECUDO",
            },
            {
              name: "LUCAP SITIO GALIZ",
            },
            {
              name: "MAAWI",
            },
            {
              name: "MACATIW",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MONA",
            },
            {
              name: "PALAMIS",
            },
            {
              name: "PANGAPISAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POCALPOCAL",
            },
            {
              name: "QUIBUAR",
            },
            {
              name: "SAN ANTONIO (R. MAGSAYSAY)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TANAYTAY",
            },
            {
              name: "TANGCARANG",
            },
            {
              name: "TAWINTAWIN",
            },
          ],
        },
        ALCALA: {
          name: "ALCALA",
          baranggays: [
            {
              name: "ANULID",
            },
            {
              name: "ATAINAN",
            },
            {
              name: "BERSAMIN",
            },
            {
              name: "CANARVACANAN",
            },
            {
              name: "CARANGLAAN",
            },
            {
              name: "CURARENG",
            },
            {
              name: "GUALSIC",
            },
            {
              name: "KASIKIS",
            },
            {
              name: "LAOAC",
            },
            {
              name: "MACAYO",
            },
            {
              name: "PINDANGAN CENTRO",
            },
            {
              name: "PINDANGAN EAST",
            },
            {
              name: "PINDANGAN WEST",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO APARTADO",
            },
            {
              name: "SAN PEDRO ILI",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "VACANTE",
            },
          ],
        },
        ANDA: {
          name: "ANDA",
          baranggays: [
            {
              name: "ANDA",
            },
            {
              name: "AWAG",
            },
            {
              name: "AWILE",
            },
            {
              name: "BATIARAO",
            },
            {
              name: "CABUNGAN",
            },
            {
              name: "CAROT",
            },
            {
              name: "DOLAOAN",
            },
            {
              name: "IMBO",
            },
            {
              name: "MACALEENG",
            },
            {
              name: "MACANDOCANDONG",
            },
            {
              name: "MAL-ONG",
            },
            {
              name: "NAMAGBAGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SABLIG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SIAPAR",
            },
            {
              name: "TONDOL",
            },
            {
              name: "TORITORI",
            },
          ],
        },
        ASINGAN: {
          name: "ASINGAN",
          baranggays: [
            {
              name: "ARISTON ESTE",
            },
            {
              name: "ARISTON WESTE",
            },
            {
              name: "ASINGAN",
            },
            {
              name: "BANTOG",
            },
            {
              name: "BARO",
            },
            {
              name: "BOBONAN",
            },
            {
              name: "CABALITIAN",
            },
            {
              name: "CALEPAAN",
            },
            {
              name: "CAROSUCAN NORTE",
            },
            {
              name: "CAROSUCAN SUR",
            },
            {
              name: "COLDIT",
            },
            {
              name: "DOMANPOT",
            },
            {
              name: "DUPAC",
            },
            {
              name: "MACALONG",
            },
            {
              name: "PALARIS",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SAN VICENTE ESTE",
            },
            {
              name: "SAN VICENTE WESTE",
            },
            {
              name: "SANCHEZ",
            },
            {
              name: "SOBOL",
            },
            {
              name: "TOBOY",
            },
          ],
        },
        BALUNGAO: {
          name: "BALUNGAO",
          baranggays: [
            {
              name: "ANGAYAN NORTE",
            },
            {
              name: "ANGAYAN SUR",
            },
            {
              name: "BALUNGAO",
            },
            {
              name: "CAPULAAN",
            },
            {
              name: "ESMERALDA",
            },
            {
              name: "KITA-KITA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAUBAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUGARO",
            },
            {
              name: "RAJAL",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN AURELIO 1ST",
            },
            {
              name: "SAN AURELIO 2ND",
            },
            {
              name: "SAN AURELIO 3RD",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SAN LEON",
            },
            {
              name: "SAN MARCELINO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAYMUNDO",
            },
          ],
        },
        BANI: {
          name: "BANI",
          baranggays: [
            {
              name: "AMBABAAY",
            },
            {
              name: "APORAO",
            },
            {
              name: "ARWAS",
            },
            {
              name: "BALLAG",
            },
            {
              name: "BANI",
            },
            {
              name: "BANOG NORTE",
            },
            {
              name: "BANOG SUR",
            },
            {
              name: "CALABENG",
            },
            {
              name: "CENTRO TOMA",
            },
            {
              name: "COLAYO",
            },
            {
              name: "DACAP NORTE",
            },
            {
              name: "DACAP SUR",
            },
            {
              name: "GARRITA",
            },
            {
              name: "LUAC",
            },
            {
              name: "MACABIT",
            },
            {
              name: "MASIDEM",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUINAOAYANAN",
            },
            {
              name: "RANAO",
            },
            {
              name: "RANOM ILOCO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN SIMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TIEP",
            },
            {
              name: "TIPOR",
            },
            {
              name: "TUGUI GRANDE",
            },
            {
              name: "TUGUI NORTE",
            },
          ],
        },
        BASISTA: {
          name: "BASISTA",
          baranggays: [
            {
              name: "ANAMBONGAN",
            },
            {
              name: "BASISTA",
            },
            {
              name: "BAYOYONG",
            },
            {
              name: "CABELDATAN",
            },
            {
              name: "DUMPAY",
            },
            {
              name: "MALIMPEC EAST",
            },
            {
              name: "MAPOLOPOLO",
            },
            {
              name: "NALNERAN",
            },
            {
              name: "NAVATAT",
            },
            {
              name: "OBONG",
            },
            {
              name: "OSMENA SR.",
            },
            {
              name: "PALMA",
            },
            {
              name: "PATACBO",
            },
            {
              name: "POBLACION",
            },
          ],
        },
        BAUTISTA: {
          name: "BAUTISTA",
          baranggays: [
            {
              name: "ARTACHO",
            },
            {
              name: "BALUYOT",
            },
            {
              name: "BAUTISTA",
            },
            {
              name: "CABUAAN",
            },
            {
              name: "CACANDONGAN",
            },
            {
              name: "DIAZ",
            },
            {
              name: "KETEGAN",
            },
            {
              name: "NANDACAN",
            },
            {
              name: "NIBALIW NORTE",
            },
            {
              name: "NIBALIW SUR",
            },
            {
              name: "PALISOC",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "POGO",
            },
            {
              name: "POPONTO",
            },
            {
              name: "PRIMICIAS",
            },
            {
              name: "SINABAAN",
            },
            {
              name: "VACANTE",
            },
            {
              name: "VILLANUEVA",
            },
          ],
        },
        BAYAMBANG: {
          name: "BAYAMBANG",
          baranggays: [
            {
              name: "ALINGGAN",
            },
            {
              name: "AMAMPEREZ",
            },
            {
              name: "AMANCOSILING NORTE",
            },
            {
              name: "AMANCOSILING SUR",
            },
            {
              name: "AMBAYAT I",
            },
            {
              name: "AMBAYAT II",
            },
            {
              name: "APALEN",
            },
            {
              name: "ASIN",
            },
            {
              name: "ATAYNAN",
            },
            {
              name: "BACNONO",
            },
            {
              name: "BALAYBUAYA",
            },
            {
              name: "BANABAN",
            },
            {
              name: "BANI",
            },
            {
              name: "BATANGCAWA",
            },
            {
              name: "BAYAMBANG",
            },
            {
              name: "BELENG",
            },
            {
              name: "BICAL NORTE",
            },
            {
              name: "BICAL SUR",
            },
            {
              name: "BONGATO EAST",
            },
            {
              name: "BONGATO WEST",
            },
            {
              name: "BUAYAEN",
            },
            {
              name: "BUENLAG 1ST",
            },
            {
              name: "BUENLAG 2ND",
            },
            {
              name: "CADRE SITE",
            },
            {
              name: "CARUNGAY",
            },
            {
              name: "CATURAY",
            },
            {
              name: "DARAWEY (TANGAL)",
            },
            {
              name: "DUERA",
            },
            {
              name: "DUSOC",
            },
            {
              name: "HERMOZA",
            },
            {
              name: "IDONG",
            },
            {
              name: "INANLORENZANA",
            },
            {
              name: "INIRANGAN",
            },
            {
              name: "ITON",
            },
            {
              name: "LANGIRAN",
            },
            {
              name: "LIGUE",
            },
            {
              name: "M. H. DEL PILAR",
            },
            {
              name: "MACAYOCAYO",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAIGPA",
            },
            {
              name: "MALIMPEC",
            },
            {
              name: "MALIOER",
            },
            {
              name: "MANAGOS",
            },
            {
              name: "MANAMBONG NORTE",
            },
            {
              name: "MANAMBONG PARTE",
            },
            {
              name: "MANAMBONG SUR",
            },
            {
              name: "MANGAYAO",
            },
            {
              name: "NALSIAN NORTE",
            },
            {
              name: "NALSIAN SUR",
            },
            {
              name: "PANGDEL",
            },
            {
              name: "PANTOL",
            },
            {
              name: "PARAGOS",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "PUGO",
            },
            {
              name: "REYNADO",
            },
            {
              name: "SAN GABRIEL 1ST",
            },
            {
              name: "SAN GABRIEL 2ND",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANGCAGULIS",
            },
            {
              name: "SANLIBO",
            },
            {
              name: "SAPANG",
            },
            {
              name: "TAMARO",
            },
            {
              name: "TAMBAC",
            },
            {
              name: "TAMPOG",
            },
            {
              name: "TANOLONG",
            },
            {
              name: "TATARAO",
            },
            {
              name: "TELBANG",
            },
            {
              name: "TOCOC EAST",
            },
            {
              name: "TOCOC WEST",
            },
            {
              name: "WARDING",
            },
            {
              name: "WAWA",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
            {
              name: "ZONE V (POB.)",
            },
            {
              name: "ZONE VI (POB.)",
            },
            {
              name: "ZONE VII (POB.)",
            },
          ],
        },
        BINALONAN: {
          name: "BINALONAN",
          baranggays: [
            {
              name: "BALANGOBONG",
            },
            {
              name: "BINALONAN",
            },
            {
              name: "BUED",
            },
            {
              name: "BUGAYONG",
            },
            {
              name: "CAMANGAAN",
            },
            {
              name: "CANARVACANAN",
            },
            {
              name: "CAPAS",
            },
            {
              name: "CILI",
            },
            {
              name: "DUMAYAT",
            },
            {
              name: "LINMANSANGAN",
            },
            {
              name: "MANGCASUY",
            },
            {
              name: "MORENO",
            },
            {
              name: "PASILENG NORTE",
            },
            {
              name: "PASILENG SUR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FELIPE CENTRAL",
            },
            {
              name: "SAN FELIPE SUR",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA MARIA NORTE",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SUMABNIT",
            },
            {
              name: "TABUYOC",
            },
            {
              name: "VACANTE",
            },
          ],
        },
        BINMALEY: {
          name: "BINMALEY",
          baranggays: [
            {
              name: "AMANCORO",
            },
            {
              name: "BALAGAN",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BASING",
            },
            {
              name: "BAYBAY LOPEZ",
            },
            {
              name: "BAYBAY POLONG",
            },
            {
              name: "BIEC",
            },
            {
              name: "BINMALEY",
            },
            {
              name: "BUENLAG",
            },
            {
              name: "CALIT",
            },
            {
              name: "CALOOCAN DUPO",
            },
            {
              name: "CALOOCAN NORTE",
            },
            {
              name: "CALOOCAN SUR",
            },
            {
              name: "CAMALEY",
            },
            {
              name: "CANAOALAN",
            },
            {
              name: "DULAG",
            },
            {
              name: "GAYAMAN",
            },
            {
              name: "LINOC",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "MALINDONG",
            },
            {
              name: "MANAT",
            },
            {
              name: "NAGPALANGAN",
            },
            {
              name: "NAGUILAYAN",
            },
            {
              name: "PALLAS",
            },
            {
              name: "PAPAGUEYAN",
            },
            {
              name: "PARAYAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POTOTAN",
            },
            {
              name: "SABANGAN",
            },
            {
              name: "SALAPINGAO",
            },
            {
              name: "SAN ISIDRO NORTE",
            },
            {
              name: "SAN ISIDRO SUR",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "TOMBOR",
            },
          ],
        },
        BOLINAO: {
          name: "BOLINAO",
          baranggays: [
            {
              name: "ARNEDO",
            },
            {
              name: "ARNEDO",
            },
            {
              name: "BALINGASAY",
            },
            {
              name: "BINABALIAN",
            },
            {
              name: "BOLINAO",
            },
            {
              name: "CABUYAO",
            },
            {
              name: "CATUDAY",
            },
            {
              name: "CATUNGI",
            },
            {
              name: "CONCORDIA (POB.)",
            },
            {
              name: "CULANG",
            },
            {
              name: "DEWEY",
            },
            {
              name: "ESTANZA",
            },
            {
              name: "GERMINAL (POB.)",
            },
            {
              name: "GOYODEN",
            },
            {
              name: "ILOGMALINO",
            },
            {
              name: "LAMBES (LAMES)",
            },
            {
              name: "LIWA-LIWA",
            },
            {
              name: "LUCERO",
            },
            {
              name: "LUCIENTE 1.0",
            },
            {
              name: "LUCIENTE 2.0",
            },
            {
              name: "LUNA",
            },
            {
              name: "PATAR",
            },
            {
              name: "PILAR",
            },
            {
              name: "SALUD",
            },
            {
              name: "SAMANG NORTE",
            },
            {
              name: "SAMANG SUR",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "TARA",
            },
            {
              name: "TUPA",
            },
            {
              name: "VICTORY",
            },
            {
              name: "ZARAGOZA",
            },
          ],
        },
        BUGALLON: {
          name: "BUGALLON",
          baranggays: [
            {
              name: "ANGARIAN",
            },
            {
              name: "ASINAN",
            },
            {
              name: "BACABAC",
            },
            {
              name: "BANAGA",
            },
            {
              name: "BOLAOEN",
            },
            {
              name: "BUENLAG",
            },
            {
              name: "BUGALLON",
            },
            {
              name: "CABAYAOASAN",
            },
            {
              name: "CAYANGA",
            },
            {
              name: "GUESET",
            },
            {
              name: "HACIENDA",
            },
            {
              name: "LAGUIT CENTRO",
            },
            {
              name: "LAGUIT PADILLA",
            },
            {
              name: "MAGTAKING",
            },
            {
              name: "PANGASCASAN",
            },
            {
              name: "PANTAL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLONG",
            },
            {
              name: "PORTIC",
            },
            {
              name: "SALASA",
            },
            {
              name: "SALOMAGUE NORTE",
            },
            {
              name: "SALOMAGUE SUR",
            },
            {
              name: "SAMAT",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "UMANDAY",
            },
          ],
        },
        BURGOS: {
          name: "BURGOS",
          baranggays: [
            {
              name: "ANAPAO (BUR ANAPAC)",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CACAYASEN",
            },
            {
              name: "CONCORDIA",
            },
            {
              name: "DON MATIAS",
            },
            {
              name: "ILIO-ILIO (ILIW-ILIW)",
            },
            {
              name: "PAPALLASEN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POGORUAC",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SAPA GRANDE",
            },
            {
              name: "SAPA PEQUEÑA",
            },
            {
              name: "TAMBACAN",
            },
          ],
        },
        CALASIAO: {
          name: "CALASIAO",
          baranggays: [
            {
              name: "AMBONAO",
            },
            {
              name: "AMBUETEL",
            },
            {
              name: "BANAOANG",
            },
            {
              name: "BUED",
            },
            {
              name: "BUENLAG",
            },
            {
              name: "CABILOCAAN",
            },
            {
              name: "CALASIAO",
            },
            {
              name: "DINALAOAN",
            },
            {
              name: "DOYONG",
            },
            {
              name: "GABON",
            },
            {
              name: "LASIP",
            },
            {
              name: "LONGOS",
            },
            {
              name: "LUMBANG",
            },
            {
              name: "MACABITO",
            },
            {
              name: "MALABAGO",
            },
            {
              name: "MANCUP",
            },
            {
              name: "NAGSAING",
            },
            {
              name: "NALSIAN",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "QUESBAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SONGKOY",
            },
            {
              name: "TALIBAEW",
            },
          ],
        },
        DAGUPAN: {
          name: "DAGUPAN",
          baranggays: [
            {
              name: "BACAYAO NORTE",
            },
            {
              name: "BACAYAO SUR",
            },
            {
              name: "BARANGAY I (T. BUGALLON)",
            },
            {
              name: "BARANGAY II (NUEVA)",
            },
            {
              name: "BARANGAY IV (ZAMORA)",
            },
            {
              name: "BOLOSAN",
            },
            {
              name: "BONUAN BINLOC",
            },
            {
              name: "BONUAN BOQUIG",
            },
            {
              name: "BONUAN GUESET",
            },
            {
              name: "CALMAY",
            },
            {
              name: "CARAEL",
            },
            {
              name: "CARANGLAAN",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "HERRERO",
            },
            {
              name: "LASIP CHICO",
            },
            {
              name: "LASIP GRANDE",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "LUCAO",
            },
            {
              name: "MALUED",
            },
            {
              name: "MAMALINGLING",
            },
            {
              name: "MANGIN",
            },
            {
              name: "MAYOMBO",
            },
            {
              name: "PANTAL",
            },
            {
              name: "POBLACION OESTE",
            },
            {
              name: "POGO CHICO",
            },
            {
              name: "POGO GRANDE",
            },
            {
              name: "PUGARO SUIT",
            },
            {
              name: "SALAPINGAO",
            },
            {
              name: "SALISAY",
            },
            {
              name: "TAMBAC",
            },
            {
              name: "TAPUAC",
            },
            {
              name: "TEBENG",
            },
          ],
        },
        DASOL: {
          name: "DASOL",
          baranggays: [
            {
              name: "ALILAO",
            },
            {
              name: "AMALBALAN",
            },
            {
              name: "BOBONOT",
            },
            {
              name: "DASOL",
            },
            {
              name: "EGUIA",
            },
            {
              name: "GAIS-GUIPE",
            },
            {
              name: "HERMOSA",
            },
            {
              name: "MACALANG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALACAPAS",
            },
            {
              name: "MALIMPIN",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PETAL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TAMBAC",
            },
            {
              name: "TAMBOBONG",
            },
            {
              name: "ULI",
            },
            {
              name: "VIGA",
            },
          ],
        },
        INFANTA: {
          name: "INFANTA",
          baranggays: [
            {
              name: "BABUYAN",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "BATANG",
            },
            {
              name: "BAYAMBANG",
            },
            {
              name: "CATO",
            },
            {
              name: "DOLIMAN",
            },
            {
              name: "FATIMA",
            },
            {
              name: "INFANTA",
            },
            {
              name: "MAYA",
            },
            {
              name: "NANGALISAN",
            },
            {
              name: "NAYOM",
            },
            {
              name: "PITA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POTOL",
            },
          ],
        },
        LABRADOR: {
          name: "LABRADOR",
          baranggays: [
            {
              name: "BOLO",
            },
            {
              name: "BONGALON",
            },
            {
              name: "DULIG",
            },
            {
              name: "LABRADOR",
            },
            {
              name: "LAOIS",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN GONZALO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TOBUAN",
            },
            {
              name: "UYONG",
            },
          ],
        },
        LAOAC: {
          name: "LAOAC",
          baranggays: [
            {
              name: "ANIS",
            },
            {
              name: "BALLIGI",
            },
            {
              name: "BANUAR",
            },
            {
              name: "BOTIQUE",
            },
            {
              name: "CAARINGAYAN",
            },
            {
              name: "CABILAOAN WEST",
            },
            {
              name: "CABULALAAN",
            },
            {
              name: "CALAOAGAN",
            },
            {
              name: "CALMAY",
            },
            {
              name: "CASAMPAGAAN",
            },
            {
              name: "CASANESTEBANAN",
            },
            {
              name: "CASANTIAGOAN",
            },
            {
              name: "DOMINGO ALARCIO (CABILAOAN EAST)",
            },
            {
              name: "INMANDUYAN",
            },
            {
              name: "LAOAC",
            },
            {
              name: "LEBUEG",
            },
            {
              name: "MARABOC",
            },
            {
              name: "NANBAGATAN",
            },
            {
              name: "PANAGA",
            },
            {
              name: "POBLACION (LAOAC)",
            },
            {
              name: "TALOGTOG",
            },
            {
              name: "TURKO",
            },
            {
              name: "YATYAT",
            },
          ],
        },
        LINGAYEN: {
          name: "LINGAYEN",
          baranggays: [
            {
              name: "ALIWEKWEK",
            },
            {
              name: "BAAY",
            },
            {
              name: "BALANGOBONG",
            },
            {
              name: "BALOCOC",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BASING",
            },
            {
              name: "CAPANDANAN",
            },
            {
              name: "DOMALANDAN CENTER",
            },
            {
              name: "DOMALANDAN EAST",
            },
            {
              name: "DOMALANDAN WEST",
            },
            {
              name: "DORONGAN",
            },
            {
              name: "DULAG",
            },
            {
              name: "ESTANZA",
            },
            {
              name: "LASIP",
            },
            {
              name: "LIBSONG EAST",
            },
            {
              name: "LIBSONG WEST",
            },
            {
              name: "LINGAYEN",
            },
            {
              name: "MALAWAN",
            },
            {
              name: "MALIMPUEC",
            },
            {
              name: "MANIBOC",
            },
            {
              name: "MATALAVA",
            },
            {
              name: "NAGUELGUEL",
            },
            {
              name: "NAMOLAN",
            },
            {
              name: "PANGAPISAN NORTH",
            },
            {
              name: "PANGAPISAN SUR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUIBAOL",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SABANGAN",
            },
            {
              name: "TALOGTOG",
            },
            {
              name: "TONTON",
            },
            {
              name: "TUMBAR",
            },
            {
              name: "WAWA",
            },
          ],
        },
        MABINI: {
          name: "MABINI",
          baranggays: [
            {
              name: "BACNIT",
            },
            {
              name: "BARLO",
            },
            {
              name: "CAABIANGAAN",
            },
            {
              name: "CABANAETAN",
            },
            {
              name: "CABINUANGAN",
            },
            {
              name: "CALZADA",
            },
            {
              name: "CARANGLAAN",
            },
            {
              name: "DE GUZMAN",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGALONG",
            },
            {
              name: "NIBALIW",
            },
            {
              name: "PATAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "TAGUDIN",
            },
            {
              name: "VILLACORTA",
            },
          ],
        },
        MALASIQUI: {
          name: "MALASIQUI",
          baranggays: [
            {
              name: "ABONAGAN",
            },
            {
              name: "AGDAO",
            },
            {
              name: "ALACAN",
            },
            {
              name: "ALIAGA",
            },
            {
              name: "AMACALAN",
            },
            {
              name: "ANOLID",
            },
            {
              name: "APAYA",
            },
            {
              name: "ASIN ESTE",
            },
            {
              name: "ASIN WESTE",
            },
            {
              name: "BACUNDAO ESTE",
            },
            {
              name: "BACUNDAO WESTE",
            },
            {
              name: "BAKITIW",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANAWANG",
            },
            {
              name: "BARANG",
            },
            {
              name: "BAWER",
            },
            {
              name: "BINALAY",
            },
            {
              name: "BOBON",
            },
            {
              name: "BOLAOIT",
            },
            {
              name: "BONGAR",
            },
            {
              name: "BUTAO",
            },
            {
              name: "CABATLING",
            },
            {
              name: "CABUELDATAN",
            },
            {
              name: "CALBUEG",
            },
            {
              name: "CANAN NORTE",
            },
            {
              name: "CANAN SUR",
            },
            {
              name: "CAWAYAN BOGTONG",
            },
            {
              name: "DON PEDRO",
            },
            {
              name: "GATANG",
            },
            {
              name: "GOLIMAN",
            },
            {
              name: "GOMEZ",
            },
            {
              name: "GUILIG",
            },
            {
              name: "ICAN",
            },
            {
              name: "INGALAGALA",
            },
            {
              name: "LAREG-LAREG",
            },
            {
              name: "LASIP",
            },
            {
              name: "LEPA",
            },
            {
              name: "LOQUEB ESTE",
            },
            {
              name: "LOQUEB NORTE",
            },
            {
              name: "LOQUEB SUR",
            },
            {
              name: "LUNEC",
            },
            {
              name: "MABULITEC",
            },
            {
              name: "MALASIQUI",
            },
            {
              name: "MALIMPEC",
            },
            {
              name: "MANGGAN-DAMPAY",
            },
            {
              name: "NALSIAN NORTE",
            },
            {
              name: "NALSIAN SUR",
            },
            {
              name: "NANCAPIAN",
            },
            {
              name: "NANSANGAAN",
            },
            {
              name: "OLEA",
            },
            {
              name: "PACUAN",
            },
            {
              name: "PALAPAR NORTE",
            },
            {
              name: "PALAPAR SUR",
            },
            {
              name: "PALONG",
            },
            {
              name: "PAMARANUM",
            },
            {
              name: "PASIMA",
            },
            {
              name: "PAYAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLONG NORTE",
            },
            {
              name: "POLONG SUR",
            },
            {
              name: "POTIOCAN",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "TABO-SILI",
            },
            {
              name: "TALOSPATANG",
            },
            {
              name: "TALOY",
            },
            {
              name: "TALOYAN",
            },
            {
              name: "TAMBAC",
            },
            {
              name: "TOBOR",
            },
            {
              name: "TOLONGUAT",
            },
            {
              name: "TOMLING",
            },
            {
              name: "UMANDO",
            },
            {
              name: "VIADO",
            },
            {
              name: "WAIG",
            },
            {
              name: "WAREY",
            },
          ],
        },
        MANAOAG: {
          name: "MANAOAG",
          baranggays: [
            {
              name: "BABASIT",
            },
            {
              name: "BAGUINAY",
            },
            {
              name: "BARITAO",
            },
            {
              name: "BISAL",
            },
            {
              name: "BUCAO",
            },
            {
              name: "CABANBANAN",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "INAMOTAN",
            },
            {
              name: "LELEMAAN",
            },
            {
              name: "LICSI",
            },
            {
              name: "LIPIT NORTE",
            },
            {
              name: "LIPIT SUR",
            },
            {
              name: "MANAOAG",
            },
            {
              name: "MATOLONG",
            },
            {
              name: "MERMER",
            },
            {
              name: "NALSIAN",
            },
            {
              name: "ORAAN EAST",
            },
            {
              name: "ORAAN WEST",
            },
            {
              name: "PANTAL",
            },
            {
              name: "PAO",
            },
            {
              name: "PARIAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUGARO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SAPANG",
            },
            {
              name: "TEBUEL",
            },
          ],
        },
        MANGALDAN: {
          name: "MANGALDAN",
          baranggays: [
            {
              name: "ALITAYA",
            },
            {
              name: "AMANSABINA",
            },
            {
              name: "ANOLID",
            },
            {
              name: "BANAOANG",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BARI",
            },
            {
              name: "BATENG",
            },
            {
              name: "BUENLAG",
            },
            {
              name: "DAVID",
            },
            {
              name: "EMBARCADERO",
            },
            {
              name: "GUEGUESANGEN",
            },
            {
              name: "GUESANG",
            },
            {
              name: "GUIGUILONEN",
            },
            {
              name: "GUILIG",
            },
            {
              name: "INLAMBO",
            },
            {
              name: "LANAS",
            },
            {
              name: "LANDAS",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MACAYUG",
            },
            {
              name: "MALABAGO",
            },
            {
              name: "MANGALDAN",
            },
            {
              name: "NAVALUAN",
            },
            {
              name: "NIBALIW",
            },
            {
              name: "OSIEM",
            },
            {
              name: "PALUA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POGO",
            },
            {
              name: "SALAAN",
            },
            {
              name: "SALAY",
            },
            {
              name: "TALOGTOG",
            },
            {
              name: "TEBAG",
            },
          ],
        },
        MANGATAREM: {
          name: "MANGATAREM",
          baranggays: [
            {
              name: "ANDANGIN",
            },
            {
              name: "ARELLANO STREET (POB.)",
            },
            {
              name: "BANTAY",
            },
            {
              name: "BANTOCALING",
            },
            {
              name: "BARACBAC",
            },
            {
              name: "BOGTONG BOLO",
            },
            {
              name: "BOGTONG BUNAO",
            },
            {
              name: "BOGTONG CENTRO",
            },
            {
              name: "BOGTONG NIOG",
            },
            {
              name: "BOGTONG SILAG",
            },
            {
              name: "BUAYA",
            },
            {
              name: "BUENLAG",
            },
            {
              name: "BUENO",
            },
            {
              name: "BUNAGAN",
            },
            {
              name: "BUNLALACAO",
            },
            {
              name: "BURGOS STREET (POB.)",
            },
            {
              name: "CABALUYAN 1ST",
            },
            {
              name: "CABALUYAN 2ND",
            },
            {
              name: "CABARABUAN",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CABAYAOASAN",
            },
            {
              name: "CABAYUGAN",
            },
            {
              name: "CACAOITEN",
            },
            {
              name: "CALUMBOYAN NORTE",
            },
            {
              name: "CALUMBOYAN SUR",
            },
            {
              name: "CALVO (POB.)",
            },
            {
              name: "CASILAGAN",
            },
            {
              name: "CATARATARAAN",
            },
            {
              name: "CATURAY NORTE",
            },
            {
              name: "CATURAY SUR",
            },
            {
              name: "CAVIERNESAN",
            },
            {
              name: "DORONGAN KETAKET",
            },
            {
              name: "DORONGAN LINMANSANGAN",
            },
            {
              name: "DORONGAN PUNTA",
            },
            {
              name: "DORONGAN SAWAT",
            },
            {
              name: "DORONGAN VALERIO",
            },
            {
              name: "GENERAL LUNA (POB.)",
            },
            {
              name: "HISTORIA",
            },
            {
              name: "LAWAK LANGKA",
            },
            {
              name: "LINMANSANGAN",
            },
            {
              name: "LOPEZ (POB.)",
            },
            {
              name: "MABINI (POB.)",
            },
            {
              name: "MACARANG",
            },
            {
              name: "MALABOBO",
            },
            {
              name: "MALIBONG",
            },
            {
              name: "MALUNEC",
            },
            {
              name: "MANGATAREM",
            },
            {
              name: "MARAVILLA (POB.)",
            },
            {
              name: "MARAVILLA-ARELLANO EXT. (POB.)",
            },
            {
              name: "MUELANG",
            },
            {
              name: "NAGUILAYAN EAST",
            },
            {
              name: "NAGUILAYAN WEST",
            },
            {
              name: "NANCASALAN",
            },
            {
              name: "NIOG-CABISON-BULANEY",
            },
            {
              name: "OLEGARIO-CAOILE (POB.)",
            },
            {
              name: "OLO CACAMPOSAN",
            },
            {
              name: "OLO CAFABROSAN",
            },
            {
              name: "OLO CAGARLITAN",
            },
            {
              name: "OSMEÑA (POB.)",
            },
            {
              name: "PACALAT",
            },
            {
              name: "PAMPANO",
            },
            {
              name: "PARIAN",
            },
            {
              name: "PAUL",
            },
            {
              name: "PEANIA PEDANIA (BEDANIA)",
            },
            {
              name: "POGON-ANIAT",
            },
            {
              name: "POGON-LOMBOY (POB.)",
            },
            {
              name: "PONGLO-BALEG",
            },
            {
              name: "PONGLO-MUELAG",
            },
            {
              name: "QUETEGAN (POGON-BALEG)",
            },
            {
              name: "QUEZON (POB.)",
            },
            {
              name: "SALAVANTE",
            },
            {
              name: "SAPANG",
            },
            {
              name: "SONSON ONGKIT",
            },
            {
              name: "SUACO",
            },
            {
              name: "TAGAC",
            },
            {
              name: "TAKIPAN",
            },
            {
              name: "TALOGTOG",
            },
            {
              name: "TOCOC BARIKIR",
            },
            {
              name: "TORRE 1ST",
            },
            {
              name: "TORRE 2ND",
            },
            {
              name: "TORRES BUGALLON (POB.)",
            },
            {
              name: "TORRES BUGALLON (POB.)",
            },
            {
              name: "UMANGAN",
            },
            {
              name: "ZAMORA (POB.)",
            },
          ],
        },
        MAPANDAN: {
          name: "MAPANDAN",
          baranggays: [
            {
              name: "AMANOAOAC",
            },
            {
              name: "APAYA",
            },
            {
              name: "ASERDA",
            },
            {
              name: "BALOLING",
            },
            {
              name: "CORAL",
            },
            {
              name: "GOLDEN",
            },
            {
              name: "JIMENEZ",
            },
            {
              name: "LAMBAYAN",
            },
            {
              name: "LUYAN (LUYAN SOUTH)",
            },
            {
              name: "MAPANDAN",
            },
            {
              name: "NILOMBOT",
            },
            {
              name: "PIAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PRIMICIAS",
            },
            {
              name: "SANTA MARIA (LUYAN NORTH)",
            },
            {
              name: "TORRES",
            },
          ],
        },
        NATIVIDAD: {
          name: "NATIVIDAD",
          baranggays: [
            {
              name: "BARANGOBONG",
            },
            {
              name: "BATCHELOR EAST",
            },
            {
              name: "BATCHELOR WEST",
            },
            {
              name: "BURGOS (SAN NARCISO)",
            },
            {
              name: "CACANDUNGAN",
            },
            {
              name: "CALAPUGAN",
            },
            {
              name: "CANAREM",
            },
            {
              name: "LUNA",
            },
            {
              name: "NATIVIDAD",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALUD",
            },
            {
              name: "SAN EUGENIO",
            },
            {
              name: "SAN MACARIO NORTE",
            },
            {
              name: "SAN MACARIO SUR",
            },
            {
              name: "SAN MAXIMO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SILAG",
            },
          ],
        },
        POZORRUBIO: {
          name: "POZORRUBIO",
          baranggays: [
            {
              name: "ALIPANGPANG",
            },
            {
              name: "AMAGBAGAN",
            },
            {
              name: "BALACAG",
            },
            {
              name: "BANDING",
            },
            {
              name: "BANTUGAN",
            },
            {
              name: "BATAKIL",
            },
            {
              name: "BOBONAN",
            },
            {
              name: "BUNEG",
            },
            {
              name: "CABLONG",
            },
            {
              name: "CASANFERNANDOAN",
            },
            {
              name: "CASTAÑO",
            },
            {
              name: "DILAN",
            },
            {
              name: "DON BENITO",
            },
            {
              name: "HAWAY",
            },
            {
              name: "IMBALBALATONG",
            },
            {
              name: "INOMAN",
            },
            {
              name: "LAOAC",
            },
            {
              name: "MAAMBAL",
            },
            {
              name: "MALASIN",
            },
            {
              name: "MALOKIAT",
            },
            {
              name: "MANAOL",
            },
            {
              name: "NAMA",
            },
            {
              name: "NANTANGALAN",
            },
            {
              name: "PALACPALAC",
            },
            {
              name: "PALGUYOD",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POBLACION IV",
            },
            {
              name: "POZORRUBIO",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SUGCONG",
            },
            {
              name: "TALOGTOG",
            },
            {
              name: "TULNAC",
            },
            {
              name: "VILLEGAS",
            },
          ],
        },
        ROSALES: {
          name: "ROSALES",
          baranggays: [
            {
              name: "ACOP",
            },
            {
              name: "BAKITBAKIT",
            },
            {
              name: "BALINGCANAWAY",
            },
            {
              name: "CABALAOANGAN NORTE",
            },
            {
              name: "CABALAOANGAN SUR",
            },
            {
              name: "CALANUTAN (DON FELIX COLOMA)",
            },
            {
              name: "CAMANGAAN",
            },
            {
              name: "CAPITAN TOMAS",
            },
            {
              name: "CARMAY EAST",
            },
            {
              name: "CARMAY WEST",
            },
            {
              name: "CARMEN EAST",
            },
            {
              name: "CARMEN WEST",
            },
            {
              name: "CASANICOLASAN",
            },
            {
              name: "COLILING",
            },
            {
              name: "DON ANTONIO VILLAGE",
            },
            {
              name: "GUILING",
            },
            {
              name: "PALAKIPAK",
            },
            {
              name: "PANGAOAN",
            },
            {
              name: "RABAGO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "ROSALES",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANGEL",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN PEDRO EAST",
            },
            {
              name: "SAN PEDRO WEST",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "STATION DISTRICT",
            },
            {
              name: "TOMANA EAST",
            },
            {
              name: "TOMANA WEST",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
            {
              name: "ZONE V (POB.)",
            },
          ],
        },
        "SAN CARLOS": {
          name: "SAN CARLOS",
          baranggays: [
            {
              name: "ABANON",
            },
            {
              name: "AGDAO",
            },
            {
              name: "ANANDO",
            },
            {
              name: "ANO",
            },
            {
              name: "ANTIPANGOL",
            },
            {
              name: "APONIT",
            },
            {
              name: "BACNAR",
            },
            {
              name: "BALAYA",
            },
            {
              name: "BALAYONG",
            },
            {
              name: "BALDOG",
            },
            {
              name: "BALITE SUR",
            },
            {
              name: "BALOCOC",
            },
            {
              name: "BANI",
            },
            {
              name: "BEGA",
            },
            {
              name: "BOCBOC",
            },
            {
              name: "BOGAOAN",
            },
            {
              name: "BOLINGIT",
            },
            {
              name: "BOLOSAN",
            },
            {
              name: "BONIFACIO (POB.)",
            },
            {
              name: "BUENGLAT",
            },
            {
              name: "BUGALLON-POSADAS STREET (POB.)",
            },
            {
              name: "BURGOS PADLAN (POB.)",
            },
            {
              name: "CACARITAN",
            },
            {
              name: "CAINGAL",
            },
            {
              name: "CALOBAOAN",
            },
            {
              name: "CALOMBOYAN",
            },
            {
              name: "CAOAYAN-KILING",
            },
            {
              name: "CAPATAAN",
            },
            {
              name: "COBOL",
            },
            {
              name: "COLILING",
            },
            {
              name: "CRUZ",
            },
            {
              name: "DOYONG",
            },
            {
              name: "GAMATA",
            },
            {
              name: "GUELEW",
            },
            {
              name: "ILANG",
            },
            {
              name: "INERANGAN",
            },
            {
              name: "ISLA",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LILIMASAN",
            },
            {
              name: "LONGOS",
            },
            {
              name: "LUCBAN (POB.)",
            },
            {
              name: "M. SORIANO",
            },
            {
              name: "MABALBALINO",
            },
            {
              name: "MABINI (POB.)",
            },
            {
              name: "MAGTAKING",
            },
            {
              name: "MALACAÑANG",
            },
            {
              name: "MALIWARA",
            },
            {
              name: "MAMARLAO",
            },
            {
              name: "MANZON",
            },
            {
              name: "MATAGDEM",
            },
            {
              name: "MESTIZO NORTE",
            },
            {
              name: "NAGUILAYAN",
            },
            {
              name: "NILENTAP",
            },
            {
              name: "PADILLA-GOMEZ",
            },
            {
              name: "PAGAL",
            },
            {
              name: "PAITAN-PANOYPOY",
            },
            {
              name: "PALAMING",
            },
            {
              name: "PALARIS (POB.)",
            },
            {
              name: "PALOSPOS",
            },
            {
              name: "PANGALANGAN",
            },
            {
              name: "PANGOLOAN",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PARAYAO",
            },
            {
              name: "PAYAPA",
            },
            {
              name: "PAYAR",
            },
            {
              name: "PEREZ BOULEVARD (POB.)",
            },
            {
              name: "PNR STATION SITE",
            },
            {
              name: "POLO",
            },
            {
              name: "QUEZON BOULEVARD (POB.)",
            },
            {
              name: "QUINTONG",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "ROXAS BOULEVARD (POB.)",
            },
            {
              name: "SALINAP",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO-TALOY",
            },
            {
              name: "SAPINIT",
            },
            {
              name: "SUPO",
            },
            {
              name: "TALANG",
            },
            {
              name: "TAMAYO",
            },
            {
              name: "TANDANG SORA",
            },
            {
              name: "TANDOC",
            },
            {
              name: "TARECE",
            },
            {
              name: "TARECTEC",
            },
            {
              name: "TAYAMBANI",
            },
            {
              name: "TEBAG",
            },
            {
              name: "TURAC",
            },
          ],
        },
        "SAN FABIAN": {
          name: "SAN FABIAN",
          baranggays: [
            {
              name: "ALACAN",
            },
            {
              name: "AMBALANGAN-DALIN",
            },
            {
              name: "ANGIO",
            },
            {
              name: "ANONANG",
            },
            {
              name: "ARAMAL",
            },
            {
              name: "BIGBIGA",
            },
            {
              name: "BINDAY",
            },
            {
              name: "BOLAOEN",
            },
            {
              name: "BOLASI",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CAYANGA",
            },
            {
              name: "COLISAO",
            },
            {
              name: "GOMOT",
            },
            {
              name: "INMALOG",
            },
            {
              name: "INMALOG NORTE",
            },
            {
              name: "LEKEP-BUTAO",
            },
            {
              name: "LIPIT-TOMEENG",
            },
            {
              name: "LONGOS",
            },
            {
              name: "LONGOS PROPER",
            },
            {
              name: "LONGOS-AMANGONAN-PARAC-PARAC FABRICA",
            },
            {
              name: "MABILAO",
            },
            {
              name: "NIBALIW CENTRAL",
            },
            {
              name: "NIBALIW EAST",
            },
            {
              name: "NIBALIW MAGLIBA",
            },
            {
              name: "NIBALIW NARVARTE (NIBALIW WEST COMPOUND)",
            },
            {
              name: "NIBALIW VIDAL (NIBALIW WEST PROPER)",
            },
            {
              name: "PALAPAD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RABON",
            },
            {
              name: "SAGUD-BAHLEY",
            },
            {
              name: "SAN FABIAN",
            },
            {
              name: "SOBOL",
            },
            {
              name: "TEMPRA-GUILIG",
            },
            {
              name: "TIBLONG",
            },
            {
              name: "TOCOK",
            },
          ],
        },
        "SAN JACINTO": {
          name: "SAN JACINTO",
          baranggays: [
            {
              name: "AWAI",
            },
            {
              name: "BAGONG PAG-ASA (POBLACION EAST)",
            },
            {
              name: "BOLO",
            },
            {
              name: "CAPAOAY (POB.)",
            },
            {
              name: "CASIBONG",
            },
            {
              name: "GUIBEL",
            },
            {
              name: "IMELDA (DECRITO)",
            },
            {
              name: "LABNEY",
            },
            {
              name: "LOBONG",
            },
            {
              name: "MACAYUG",
            },
            {
              name: "MAGSAYSAY (CAPAY)",
            },
            {
              name: "SAN GUILLERMO (POBLACION WEST)",
            },
            {
              name: "SAN JACINTO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO TOMAS",
            },
          ],
        },
        "SAN MANUEL": {
          name: "SAN MANUEL",
          baranggays: [
            {
              name: "CABACARAAN",
            },
            {
              name: "CABARITAN",
            },
            {
              name: "FLORES",
            },
            {
              name: "GUISET NORTE (POB.)",
            },
            {
              name: "GUISET SUR (POB.)",
            },
            {
              name: "LAPALO",
            },
            {
              name: "NAGSAAG",
            },
            {
              name: "NARRA",
            },
            {
              name: "SAN ANTONIO-ARZADON",
            },
            {
              name: "SAN BONIFACIO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO DOMINGO",
            },
          ],
        },
        "SAN NICOLAS": {
          name: "SAN NICOLAS",
          baranggays: [
            {
              name: "BENSICAN",
            },
            {
              name: "CABITNONGAN",
            },
            {
              name: "CABOLOAN",
            },
            {
              name: "CACABUGAOAN",
            },
            {
              name: "CALANUTIAN",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "CAMANGGAAN",
            },
            {
              name: "CAMINDOROAN",
            },
            {
              name: "CASARATAN",
            },
            {
              name: "DALUMPINAS",
            },
            {
              name: "FIANZA",
            },
            {
              name: "LUNGAO",
            },
            {
              name: "MALICO",
            },
            {
              name: "MALILION",
            },
            {
              name: "NAGKAYSA",
            },
            {
              name: "NINING",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SALINGCOB",
            },
            {
              name: "SALPAD",
            },
            {
              name: "SAN FELIPE EAST",
            },
            {
              name: "SAN FELIPE WEST",
            },
            {
              name: "SAN ISIDRO (STA. CRUZAN)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN RAFAEL CENTRO",
            },
            {
              name: "SAN RAFAEL EAST",
            },
            {
              name: "SAN RAFAEL WEST",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA MARIA EAST",
            },
            {
              name: "SANTA MARIA WEST",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SIBLOT",
            },
            {
              name: "SOBOL",
            },
          ],
        },
        "SAN QUINTIN": {
          name: "SAN QUINTIN",
          baranggays: [
            {
              name: "ALAC",
            },
            {
              name: "BALIGAYAN",
            },
            {
              name: "BANTOG",
            },
            {
              name: "BOLINTAGUEN",
            },
            {
              name: "CABALAOANGAN",
            },
            {
              name: "CABANGARAN",
            },
            {
              name: "CALOMBOYAN",
            },
            {
              name: "CARAYACAN",
            },
            {
              name: "CASANTAMARIAN",
            },
            {
              name: "GONZALO",
            },
            {
              name: "LABUAN",
            },
            {
              name: "LAGASIT",
            },
            {
              name: "LUMAYAO",
            },
            {
              name: "MABINI",
            },
            {
              name: "MANTACDANG",
            },
            {
              name: "NANGAPUGAN",
            },
            {
              name: "POBLACION ZONE I",
            },
            {
              name: "POBLACION ZONE II",
            },
            {
              name: "POBLACION ZONE III",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN QUINTIN",
            },
            {
              name: "UNGIB",
            },
          ],
        },
        "SANTA BARBARA": {
          name: "SANTA BARBARA",
          baranggays: [
            {
              name: "ALIBAGO",
            },
            {
              name: "BALINGUEO",
            },
            {
              name: "BANAOANG",
            },
            {
              name: "BANZAL",
            },
            {
              name: "BOTAO",
            },
            {
              name: "CABLONG",
            },
            {
              name: "CARUSOCAN",
            },
            {
              name: "DALONGUE",
            },
            {
              name: "ERFE",
            },
            {
              name: "GUEGUESANGEN",
            },
            {
              name: "LEET",
            },
            {
              name: "MALANAY",
            },
            {
              name: "MANINGDING",
            },
            {
              name: "MARONONG",
            },
            {
              name: "MATICMATIC",
            },
            {
              name: "MINIEN EAST",
            },
            {
              name: "MINIEN WEST",
            },
            {
              name: "NILOMBOT",
            },
            {
              name: "PATAYAC",
            },
            {
              name: "PAYAS",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "PRIMICIAS (VENTINILLA WEST)",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SAPANG",
            },
            {
              name: "SONQUIL",
            },
            {
              name: "TEBAG EAST",
            },
            {
              name: "TEBAG WEST",
            },
            {
              name: "TULIAO",
            },
            {
              name: "VENTINILLA (VENTINILLA EAST)",
            },
          ],
        },
        "SANTA MARIA": {
          name: "SANTA MARIA",
          baranggays: [
            {
              name: "BAL-LOY",
            },
            {
              name: "BANTOG",
            },
            {
              name: "CABOLUAN",
            },
            {
              name: "CAL-LITANG",
            },
            {
              name: "CAPANDANAN",
            },
            {
              name: "CAUPLASAN",
            },
            {
              name: "DALAYAP",
            },
            {
              name: "LIBSONG",
            },
            {
              name: "NAMAGBAGAN",
            },
            {
              name: "PAITAN",
            },
            {
              name: "PATAQUID",
            },
            {
              name: "PILAR",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PUGOT",
            },
            {
              name: "SAMON",
            },
            {
              name: "SAN ALEJANDRO",
            },
            {
              name: "SAN MARIANO",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PATRICIO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA ROSA",
            },
          ],
        },
        "SANTO TOMAS": {
          name: "SANTO TOMAS",
          baranggays: [
            {
              name: "LA LUNA",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO TOMAS",
            },
          ],
        },
        SISON: {
          name: "SISON",
          baranggays: [
            {
              name: "AGAT",
            },
            {
              name: "ALIBENG",
            },
            {
              name: "AMAGBAGAN",
            },
            {
              name: "ARTACHO",
            },
            {
              name: "ASAN NORTE",
            },
            {
              name: "ASAN SUR",
            },
            {
              name: "BANTAY INSIK",
            },
            {
              name: "BILA",
            },
            {
              name: "BINMECKEG",
            },
            {
              name: "BULAOEN EAST",
            },
            {
              name: "BULAOEN WEST",
            },
            {
              name: "CABARITAN",
            },
            {
              name: "CALUNETAN",
            },
            {
              name: "CAMANGAAN",
            },
            {
              name: "CAURINGAN",
            },
            {
              name: "DUNGON",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "INMALOG",
            },
            {
              name: "KILLO",
            },
            {
              name: "LABAYUG",
            },
            {
              name: "PALDIT",
            },
            {
              name: "PINDANGAN",
            },
            {
              name: "PINMILAPIL",
            },
            {
              name: "POBLACION CENTRAL",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SAGUNTO",
            },
            {
              name: "SISON",
            },
            {
              name: "TARA-TARA",
            },
          ],
        },
        SUAL: {
          name: "SUAL",
          baranggays: [
            {
              name: "BAQUIOEN",
            },
            {
              name: "BAYBAY NORTE",
            },
            {
              name: "BAYBAY SUR",
            },
            {
              name: "BOLAOEN",
            },
            {
              name: "CABALITIAN",
            },
            {
              name: "CALUMBUYAN",
            },
            {
              name: "CAMAGSINGALAN",
            },
            {
              name: "CAOAYAN",
            },
            {
              name: "CAPANTOLAN",
            },
            {
              name: "MACAYCAYAWAN",
            },
            {
              name: "PAITAN EAST",
            },
            {
              name: "PAITAN WEST",
            },
            {
              name: "PANGASCASAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SESELANGEN",
            },
            {
              name: "SIOASIO EAST",
            },
            {
              name: "SIOASIO WEST",
            },
            {
              name: "SUAL",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        TAYUG: {
          name: "TAYUG",
          baranggays: [
            {
              name: "AGNO",
            },
            {
              name: "AMISTAD",
            },
            {
              name: "BARANGAY A (POB.)",
            },
            {
              name: "BARANGAY B (POB.)",
            },
            {
              name: "BARANGAY C (POB.)",
            },
            {
              name: "BARANGAY D (POB.)",
            },
            {
              name: "BARANGOBONG",
            },
            {
              name: "C. LICHAUCO",
            },
            {
              name: "CARRIEDO",
            },
            {
              name: "EVANGELISTA",
            },
            {
              name: "GUZON",
            },
            {
              name: "LAWAK",
            },
            {
              name: "LEGASPI",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "PANGANIBAN",
            },
            {
              name: "SALENG",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "TAYUG",
            },
            {
              name: "TOKETEC",
            },
            {
              name: "TRENCHERA",
            },
            {
              name: "ZAMORA",
            },
          ],
        },
        UMINGAN: {
          name: "UMINGAN",
          baranggays: [
            {
              name: "ABOT MOLINA",
            },
            {
              name: "ALO-O",
            },
            {
              name: "AMARONAN",
            },
            {
              name: "ANNAM",
            },
            {
              name: "BANTUG",
            },
            {
              name: "BARACBAC",
            },
            {
              name: "BARAT",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABALITIAN",
            },
            {
              name: "CABANGARAN",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CABATUAN",
            },
            {
              name: "CADIZ",
            },
            {
              name: "CALITLITAN",
            },
            {
              name: "CAPAS",
            },
            {
              name: "CARAYUNGAN SUR",
            },
            {
              name: "CAROSALESAN",
            },
            {
              name: "CASILAN",
            },
            {
              name: "CAURDANETAAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DECRETO",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "DIAZ",
            },
            {
              name: "DIKET",
            },
            {
              name: "DON JUSTO ABALOS (CAROAN)",
            },
            {
              name: "DON MONTANO",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "EVANGELISTA",
            },
            {
              name: "FLORES",
            },
            {
              name: "FULGOSINO",
            },
            {
              name: "GONZALES",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LABUAN",
            },
            {
              name: "LAUREN",
            },
            {
              name: "LUBONG",
            },
            {
              name: "LUNA ESTE",
            },
            {
              name: "LUNA WESTE",
            },
            {
              name: "MANTACDANG",
            },
            {
              name: "MASEIL-SEIL",
            },
            {
              name: "NAMPALCAN",
            },
            {
              name: "NANCALABASAAN",
            },
            {
              name: "PANGANGAAN",
            },
            {
              name: "PAPALLASEN",
            },
            {
              name: "PEMIENTA",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PRADO",
            },
            {
              name: "RESURRECCION",
            },
            {
              name: "RICOS",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LEON",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SINABAAN",
            },
            {
              name: "TANGGAL SAWANG",
            },
            {
              name: "UMINGAN",
            },
          ],
        },
        URBIZTONDO: {
          name: "URBIZTONDO",
          baranggays: [
            {
              name: "ANGATEL",
            },
            {
              name: "BALANGAY",
            },
            {
              name: "BATANGCAOA",
            },
            {
              name: "BAUG",
            },
            {
              name: "BAYAOAS",
            },
            {
              name: "BITUAG",
            },
            {
              name: "CAMAMBUGAN",
            },
            {
              name: "DALANGIRING",
            },
            {
              name: "DUPLAC",
            },
            {
              name: "GALARIN",
            },
            {
              name: "GUETEB",
            },
            {
              name: "MALACA",
            },
            {
              name: "MALAYO",
            },
            {
              name: "MALIBONG",
            },
            {
              name: "PASIBI EAST",
            },
            {
              name: "PASIBI WEST",
            },
            {
              name: "PISUAC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "REAL",
            },
            {
              name: "SALAVANTE",
            },
            {
              name: "SAWAT",
            },
            {
              name: "URBIZTONDO",
            },
          ],
        },
        "URDANETA CITY": {
          name: "URDANETA CITY",
          baranggays: [
            {
              name: "ANONAS",
            },
            {
              name: "BACTAD EAST",
            },
            {
              name: "BAYAOAS",
            },
            {
              name: "BOLAOEN",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CABULOAN",
            },
            {
              name: "CAMANANG",
            },
            {
              name: "CAMANTILES",
            },
            {
              name: "CASANTAAN",
            },
            {
              name: "CATABLAN",
            },
            {
              name: "CAYAMBANAN",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "DILAN PAURIDO",
            },
            {
              name: "DR. PEDRO T. ORATA (BACTAD PROPER)",
            },
            {
              name: "LABIT PROPER",
            },
            {
              name: "LABIT WEST",
            },
            {
              name: "MABANOGBOG",
            },
            {
              name: "MACALONG",
            },
            {
              name: "NANCALOBASAAN",
            },
            {
              name: "NANCAMALIRAN EAST",
            },
            {
              name: "NANCAMALIRAN WEST",
            },
            {
              name: "NANCAYASAN",
            },
            {
              name: "OLTAMA",
            },
            {
              name: "PALINA EAST",
            },
            {
              name: "PALINA WEST",
            },
            {
              name: "PINMALUDPOD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SUGCONG",
            },
            {
              name: "TIPUSO",
            },
            {
              name: "TULONG",
            },
            {
              name: "URDANETA CITY",
            },
          ],
        },
        VILLASIS: {
          name: "VILLASIS",
          baranggays: [
            {
              name: "AMAMPEREZ",
            },
            {
              name: "BACAG",
            },
            {
              name: "BARANGOBONG",
            },
            {
              name: "BARRACA",
            },
            {
              name: "CAPULAAN",
            },
            {
              name: "CARAMUTAN",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LABIT",
            },
            {
              name: "LIPAY",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "PIAZ (PLAZA)",
            },
            {
              name: "PUELAY",
            },
            {
              name: "SAN BLAS",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "TOMBOD",
            },
            {
              name: "UNZAD",
            },
            {
              name: "VILLASIS",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
            {
              name: "ZONE V (POB.)",
            },
          ],
        },
      },
    },
    QUEZON: {
      name: "QUEZON",
      region: "REGION 4-A",
      municipalities: {
        AGDANGAN: {
          name: "AGDANGAN",
          baranggays: [
            {
              name: "AGDANGAN",
            },
            {
              name: "BINAGBAG",
            },
            {
              name: "DAYAP",
            },
            {
              name: "IBABANG KINAGUNAN",
            },
            {
              name: "ILAYANG KINAGUNAN",
            },
            {
              name: "KANLURANG CALUTAN",
            },
            {
              name: "KANLURANG MALIGAYA",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION II",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SILANGANG CALUTAN",
            },
            {
              name: "SILANGANG MALIGAYA",
            },
            {
              name: "SILDORA",
            },
          ],
        },
        "ALABAT ( ISLAND)": {
          name: "ALABAT ( ISLAND)",
          baranggays: [
            {
              name: "ALABAT ( ISLAND)",
            },
            {
              name: "ANGELES",
            },
            {
              name: "BACONG",
            },
            {
              name: "BALUNGAY",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAGLATE",
            },
            {
              name: "CAMAGONG",
            },
            {
              name: "GORDON",
            },
            {
              name: "PAMBILAN NORTE",
            },
            {
              name: "PAMBILAN SUR",
            },
            {
              name: "VILLA ESPERANZA",
            },
            {
              name: "VILLA JESUS ESTE",
            },
            {
              name: "VILLA JESUS WESTE",
            },
            {
              name: "VILLA NORTE",
            },
            {
              name: "VILLA VICTORIA",
            },
          ],
        },
        ATIMONAN: {
          name: "ATIMONAN",
          baranggays: [
            {
              name: "ANGELES",
            },
            {
              name: "ATIMONAN",
            },
            {
              name: "BALUBAD",
            },
            {
              name: "BALUGOHIN",
            },
            {
              name: "BARANGAY ZONE 1 (POB.)",
            },
            {
              name: "BARANGAY ZONE 2 (POB.)",
            },
            {
              name: "BARANGAY ZONE 3 (POB.)",
            },
            {
              name: "BARANGAY ZONE 4 (POB.)",
            },
            {
              name: "BUHANGIN",
            },
            {
              name: "CARIDAD IBABA",
            },
            {
              name: "CARIDAD ILAYA",
            },
            {
              name: "HABINGAN",
            },
            {
              name: "INACLAGAN",
            },
            {
              name: "INALIG",
            },
            {
              name: "KILAIT",
            },
            {
              name: "KULAWIT",
            },
            {
              name: "LAKIP",
            },
            {
              name: "LUBI",
            },
            {
              name: "LUMUTAN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALINAO IBABA",
            },
            {
              name: "MALINAO ILAYA",
            },
            {
              name: "MALUSAK",
            },
            {
              name: "MANGGALAYAN BUNDOK",
            },
            {
              name: "MANGGALAYAN LABAK",
            },
            {
              name: "MATANAG",
            },
            {
              name: "MONTES BALAON",
            },
            {
              name: "MONTES KALLAGAN",
            },
            {
              name: "PONON",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANDRES BUNDOK",
            },
            {
              name: "SAN ANDRES LABAK",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE BALATOK",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SAPAAN",
            },
            {
              name: "SOKOL",
            },
            {
              name: "TAGBAKIN",
            },
            {
              name: "TALABA",
            },
            {
              name: "TINANDOG",
            },
            {
              name: "VILLA IBABA",
            },
            {
              name: "VILLA ILAYA",
            },
          ],
        },
        BUENAVISTA: {
          name: "BUENAVISTA",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BATABAT NORTE",
            },
            {
              name: "BATABAT SUR",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUKAL",
            },
            {
              name: "BULO",
            },
            {
              name: "CABONG",
            },
            {
              name: "CADLIT",
            },
            {
              name: "CATULIN",
            },
            {
              name: "CAWA",
            },
            {
              name: "DE LA PAZ",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "HAGONGHONG",
            },
            {
              name: "IBABANG WASAY",
            },
            {
              name: "ILAYANG WASAY",
            },
            {
              name: "LILUKIN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABUTAG",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "MALIGAYA (ESPERANZA)",
            },
            {
              name: "MANLANA",
            },
            {
              name: "MASAYA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SABANG PINAMASAGAN",
            },
            {
              name: "SABANG PIRIS",
            },
            {
              name: "SAN DIEGO",
            },
            {
              name: "SAN ISIDRO IBABA",
            },
            {
              name: "SAN ISIDRO ILAYA",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PEDRO (VILLA RODRIGO)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SIAIN",
            },
            {
              name: "VILLA AURORA",
            },
            {
              name: "VILLA BATABAT",
            },
            {
              name: "VILLA MAGSAYSAY",
            },
            {
              name: "VILLA VERONICA",
            },
          ],
        },
        "BURDEOS ( ISLAND )": {
          name: "BURDEOS ( ISLAND )",
          baranggays: [
            {
              name: "ALUYON",
            },
            {
              name: "AMOT",
            },
            {
              name: "ANIBAWAN",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BURDEOS ( ISLAND )",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CABUNGALUNAN",
            },
            {
              name: "CALUTCOT",
            },
            {
              name: "CANIWAN",
            },
            {
              name: "CARLAGAN",
            },
            {
              name: "MABINI",
            },
            {
              name: "PALASAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN RAFAEL",
            },
          ],
        },
        CALAUAG: {
          name: "CALAUAG",
          baranggays: [
            {
              name: "AGOHO",
            },
            {
              name: "ANAHAWAN",
            },
            {
              name: "ANAS",
            },
            {
              name: "APAD LUTAO",
            },
            {
              name: "APAD QUEZON",
            },
            {
              name: "APAD TAISAN",
            },
            {
              name: "ATULAYAN",
            },
            {
              name: "BACLARAN",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BALIBAGO",
            },
            {
              name: "BANGKURUHAN",
            },
            {
              name: "BANTOLINAO",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BIGAAN",
            },
            {
              name: "BINUTAS",
            },
            {
              name: "BIYAN",
            },
            {
              name: "BUKAL",
            },
            {
              name: "BULI",
            },
            {
              name: "CALAUAG",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DOMINLOG",
            },
            {
              name: "DOÑA AURORA",
            },
            {
              name: "GUINOSAYAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "KALIBO (SANTA CRUZ)",
            },
            {
              name: "KAPALUHAN",
            },
            {
              name: "KATANGTANG",
            },
            {
              name: "KIGTAN",
            },
            {
              name: "KINALIN IBABA",
            },
            {
              name: "KINALIN ILAYA",
            },
            {
              name: "KINAMALIGAN",
            },
            {
              name: "KUMALUDKUD",
            },
            {
              name: "KUNALUM",
            },
            {
              name: "KUYAOYAO",
            },
            {
              name: "LAGAY",
            },
            {
              name: "LAINGLAINGAN",
            },
            {
              name: "LUNGIB",
            },
            {
              name: "MABINI",
            },
            {
              name: "MADLANGDUNGAN",
            },
            {
              name: "MAGLIPAD (ROSARIO)",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MAMBALING",
            },
            {
              name: "MANHULUGIN",
            },
            {
              name: "MARILAG (PUNAYA)",
            },
            {
              name: "MULAY",
            },
            {
              name: "PANDANAN",
            },
            {
              name: "PANSOL",
            },
            {
              name: "PATIHAN",
            },
            {
              name: "PINAGBAYANAN",
            },
            {
              name: "PINAGKAMALIGAN",
            },
            {
              name: "PINAGSAKAHAN",
            },
            {
              name: "PINAGTALLERAN",
            },
            {
              name: "RIZAL IBABA",
            },
            {
              name: "RIZAL ILAYA",
            },
            {
              name: "SABANG I",
            },
            {
              name: "SABANG II",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN QUINTIN",
            },
            {
              name: "SAN ROQUE IBABA",
            },
            {
              name: "SAN ROQUE ILAYA",
            },
            {
              name: "SANTA CECILIA",
            },
            {
              name: "SANTA MILAGROSA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO ANGEL (PANGAHOY)",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SINAG",
            },
            {
              name: "SUMILANG",
            },
            {
              name: "SUMULONG",
            },
            {
              name: "TABANSAK",
            },
            {
              name: "TALINGTING",
            },
            {
              name: "TAMIS",
            },
            {
              name: "TIKIWAN",
            },
            {
              name: "TINIGUIBAN",
            },
            {
              name: "VILLA MAGSINO",
            },
            {
              name: "VILLA SAN ISIDRO",
            },
            {
              name: "VIÑAS",
            },
            {
              name: "YAGANAK",
            },
          ],
        },
        CANDELARIA: {
          name: "CANDELARIA",
          baranggays: [
            {
              name: "BUENAVISTA EAST",
            },
            {
              name: "BUENAVISTA WEST",
            },
            {
              name: "BUKAL NORTE",
            },
            {
              name: "BUKAL SUR",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "KINATIHAN I",
            },
            {
              name: "KINATIHAN II",
            },
            {
              name: "MALABANBAN NORTE",
            },
            {
              name: "MALABANBAN SUR",
            },
            {
              name: "MANGILAG NORTE",
            },
            {
              name: "MANGILAG SUR",
            },
            {
              name: "MASALUKOT I",
            },
            {
              name: "MASALUKOT II",
            },
            {
              name: "MASALUKOT III",
            },
            {
              name: "MASALUKOT IV",
            },
            {
              name: "MASALUKOT V",
            },
            {
              name: "MASIN NORTE",
            },
            {
              name: "MASIN SUR",
            },
            {
              name: "MAYABOBO",
            },
            {
              name: "PAHINGA NORTE",
            },
            {
              name: "PAHINGA SUR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA CATALINA NORTE",
            },
            {
              name: "SANTA CATALINA SUR",
            },
          ],
        },
        CATANAUAN: {
          name: "CATANAUAN",
          baranggays: [
            {
              name: "AJOS",
            },
            {
              name: "ANUSAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BOLO",
            },
            {
              name: "BULAGSONG",
            },
            {
              name: "CAMANDIISON",
            },
            {
              name: "CANCULAJAO",
            },
            {
              name: "CATANAUAN",
            },
            {
              name: "CATUMBO",
            },
            {
              name: "CAWAYANIN IBABA",
            },
            {
              name: "CAWAYANIN ILAYA",
            },
            {
              name: "CUTCUTAN",
            },
            {
              name: "DAHICAN",
            },
            {
              name: "DOONGAN IBABA",
            },
            {
              name: "DOONGAN ILAYA",
            },
            {
              name: "GATASAN",
            },
            {
              name: "MACPAC",
            },
            {
              name: "MADULAO",
            },
            {
              name: "MATANDANG SABANG KANLURAN",
            },
            {
              name: "MATANDANG SABANG SILANGAN",
            },
            {
              name: "MILAGROSA",
            },
            {
              name: "NAVITAS",
            },
            {
              name: "PACABIT",
            },
            {
              name: "SAN ANTONIO MAGKUPA",
            },
            {
              name: "SAN ANTONIO PALA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE (ANYAO)",
            },
            {
              name: "SAN PABLO (SUHA)",
            },
            {
              name: "SAN ROQUE (DOYON)",
            },
            {
              name: "SAN VICENTE KANLURAN",
            },
            {
              name: "SAN VICENTE SILANGAN",
            },
            {
              name: "SANTA MARIA (DAO)",
            },
            {
              name: "TAGABAS IBABA",
            },
            {
              name: "TAGABAS ILAYA",
            },
            {
              name: "TAGBACAN IBABA",
            },
            {
              name: "TAGBACAN ILAYA",
            },
            {
              name: "TAGBACAN SILANGAN",
            },
            {
              name: "TUHIAN",
            },
          ],
        },
        DOLORES: {
          name: "DOLORES",
          baranggays: [
            {
              name: "ANTONINO (AYUSAN)",
            },
            {
              name: "BAGONG ANYO (POB.)",
            },
            {
              name: "BAYANIHAN (POB.)",
            },
            {
              name: "BULAKIN I",
            },
            {
              name: "BULAKIN II",
            },
            {
              name: "BUNGOY",
            },
            {
              name: "CABATANG",
            },
            {
              name: "DAGATAN",
            },
            {
              name: "DOLORES",
            },
            {
              name: "KINABUHAYAN",
            },
            {
              name: "MALIGAYA (POB.)",
            },
            {
              name: "MANGGAHAN",
            },
            {
              name: "PINAGDANLAYAN",
            },
            {
              name: "PUTOL",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SILANGANAN (POB.)",
            },
          ],
        },
        "GENERAL LUNA": {
          name: "GENERAL LUNA",
          baranggays: [
            {
              name: "BACONG IBABA",
            },
            {
              name: "BACONG ILAYA",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "LAVIDES",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALAYA",
            },
            {
              name: "NIEVA",
            },
            {
              name: "RECTO",
            },
            {
              name: "SAN IGNACIO IBABA",
            },
            {
              name: "SAN IGNACIO ILAYA",
            },
            {
              name: "SAN ISIDRO IBABA",
            },
            {
              name: "SAN ISIDRO ILAYA",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MARIA IBABA",
            },
            {
              name: "SANTA MARIA ILAYA",
            },
            {
              name: "SUMILANG",
            },
            {
              name: "VILLARICA",
            },
          ],
        },
        "GENERAL NAKAR": {
          name: "GENERAL NAKAR",
          baranggays: [
            {
              name: "ANOLING",
            },
            {
              name: "BANGLOS",
            },
            {
              name: "BATANGAN",
            },
            {
              name: "CANAWAY",
            },
            {
              name: "CATABLINGAN",
            },
            {
              name: "GENERAL NAKAR",
            },
            {
              name: "LUMUTAN",
            },
            {
              name: "MAGSIKAP",
            },
            {
              name: "MAHABANG LALIM",
            },
            {
              name: "MAIGANG",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MINAHAN NORTE",
            },
            {
              name: "MINAHAN SUR",
            },
            {
              name: "PAGSANGAHAN",
            },
            {
              name: "PAMPLONA",
            },
            {
              name: "PISA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABLANG",
            },
            {
              name: "SAN MARCELINO",
            },
            {
              name: "UMIRAY",
            },
          ],
        },
        GUINAYANGAN: {
          name: "GUINAYANGAN",
          baranggays: [
            {
              name: "A. MABINI",
            },
            {
              name: "ALONEROS",
            },
            {
              name: "ARBISMEN",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BALINARIN",
            },
            {
              name: "BUKAL MALIGAYA",
            },
            {
              name: "CABIBIHAN",
            },
            {
              name: "CABONG NORTE",
            },
            {
              name: "CABONG SUR",
            },
            {
              name: "CALIMPAK",
            },
            {
              name: "CAPULUAN CENTRAL",
            },
            {
              name: "CAPULUAN TULON",
            },
            {
              name: "DANCALAN CAIMAWAN",
            },
            {
              name: "DANCALAN CENTRAL",
            },
            {
              name: "DANLAGAN BATIS",
            },
            {
              name: "DANLAGAN CABAYAO",
            },
            {
              name: "DANLAGAN CENTRAL",
            },
            {
              name: "DANLAGAN RESERVA",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "DUNGAWAN CENTRAL",
            },
            {
              name: "DUNGAWAN PAALYUNAN",
            },
            {
              name: "DUNGAWAN PANTAY",
            },
            {
              name: "ERMITA",
            },
            {
              name: "GAPAS",
            },
            {
              name: "GUINAYANGAN",
            },
            {
              name: "HIMBUBULO ESTE",
            },
            {
              name: "HIMBUBULO WESTE",
            },
            {
              name: "HINABAAN",
            },
            {
              name: "LIGPIT BANTAYAN",
            },
            {
              name: "LUBIGAN",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANGGAGAWA",
            },
            {
              name: "MANGGALANG",
            },
            {
              name: "MANLAYO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAKAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN LUIS I",
            },
            {
              name: "SAN LUIS II",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PEDRO I",
            },
            {
              name: "SAN PEDRO II",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SINTONES",
            },
            {
              name: "SISI",
            },
            {
              name: "TIKAY",
            },
            {
              name: "TRIUMPO",
            },
            {
              name: "VILLA HIWASAYAN",
            },
          ],
        },
        GUMACA: {
          name: "GUMACA",
          baranggays: [
            {
              name: "ADIA BITAOG",
            },
            {
              name: "ANONANGIN",
            },
            {
              name: "BAGONG BUHAY (POB.)",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "BANTAD",
            },
            {
              name: "BATONG DALIG",
            },
            {
              name: "BIGA",
            },
            {
              name: "BINAMBANG",
            },
            {
              name: "BUENSUCESO",
            },
            {
              name: "BUNGAHAN",
            },
            {
              name: "BUTAGUIN",
            },
            {
              name: "CALUMANGIN",
            },
            {
              name: "CAMOHAGUIN",
            },
            {
              name: "CASASAHAN IBABA",
            },
            {
              name: "CASASAHAN ILAYA",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "GAYAGAYAAN",
            },
            {
              name: "GITNANG BARRIO",
            },
            {
              name: "GUMACA",
            },
            {
              name: "HAGAKHAKIN",
            },
            {
              name: "HARDINAN",
            },
            {
              name: "INACLAGAN",
            },
            {
              name: "INAGBUHAN ILAYA",
            },
            {
              name: "LABNIG",
            },
            {
              name: "LAGUNA",
            },
            {
              name: "LAGYO",
            },
            {
              name: "MABINI (POB.)",
            },
            {
              name: "MABUNGA",
            },
            {
              name: "MALABTOG",
            },
            {
              name: "MANLAYAAN",
            },
            {
              name: "MARCELO H. DEL PILAR",
            },
            {
              name: "MATAAS NA BUNDOK",
            },
            {
              name: "MAUNLAD (POB.)",
            },
            {
              name: "PAGSABANGAN",
            },
            {
              name: "PANIKIHAN",
            },
            {
              name: "PEÑAFRANCIA (POB.)",
            },
            {
              name: "PIPISIK (POB.)",
            },
            {
              name: "PROGRESO",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN DIEGO POBLACION",
            },
            {
              name: "SAN ISIDRO KANLURAN",
            },
            {
              name: "SAN ISIDRO SILANGAN",
            },
            {
              name: "SAN JUAN DE JESUS",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SASTRE",
            },
            {
              name: "TABING DAGAT (POB.)",
            },
            {
              name: "TUMAYAN",
            },
            {
              name: "VILLA ARCAYA",
            },
            {
              name: "VILLA BOTA",
            },
            {
              name: "VILLA FUERTE",
            },
            {
              name: "VILLA M. PRINCIPE",
            },
            {
              name: "VILLA MENDOZA",
            },
            {
              name: "VILLA NAVA",
            },
            {
              name: "VILLA PADUA",
            },
            {
              name: "VILLA PEREZ",
            },
            {
              name: "VILLA TAÑADA",
            },
            {
              name: "VILLA VICTORIA",
            },
          ],
        },
        INFANTA: {
          name: "INFANTA",
          baranggays: [
            {
              name: "ABIAWIN",
            },
            {
              name: "AGOS-AGOS",
            },
            {
              name: "ALITAS",
            },
            {
              name: "AMOLONGIN",
            },
            {
              name: "ANIBONG",
            },
            {
              name: "ANTIKIN",
            },
            {
              name: "BACONG",
            },
            {
              name: "BALOBO",
            },
            {
              name: "BANTILAN",
            },
            {
              name: "BANUGAO",
            },
            {
              name: "BATICAN",
            },
            {
              name: "BINONOAN",
            },
            {
              name: "BINULASAN",
            },
            {
              name: "BOBOIN",
            },
            {
              name: "CATAMBUNGAN",
            },
            {
              name: "CAWAYNIN",
            },
            {
              name: "COMON",
            },
            {
              name: "DINAHICAN",
            },
            {
              name: "GUMIAN",
            },
            {
              name: "ILOG",
            },
            {
              name: "INFANTA",
            },
            {
              name: "INGAS",
            },
            {
              name: "LANGGAS",
            },
            {
              name: "LIBJO",
            },
            {
              name: "LUAL",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAYPULOT",
            },
            {
              name: "MISWA",
            },
            {
              name: "PILAWAY",
            },
            {
              name: "PINAGLAPATAN",
            },
            {
              name: "POBLACION 1 (BARANGAY 1)",
            },
            {
              name: "POBLACION 38 (POBLACION BARANGAY 2)",
            },
            {
              name: "POBLACION 39 (POBLACION BARANGAY 3)",
            },
            {
              name: "PULO",
            },
            {
              name: "SILANGAN",
            },
            {
              name: "TONGOHIN",
            },
            {
              name: "TUDTURAN",
            },
          ],
        },
        "JOMALIG (ISLAND)": {
          name: "JOMALIG (ISLAND)",
          baranggays: [
            {
              name: "APAD",
            },
            {
              name: "BUKAL",
            },
            {
              name: "CASUGURAN",
            },
            {
              name: "GANGO",
            },
            {
              name: "JOMALIG (ISLAND)",
            },
            {
              name: "TALISOY (POB.)",
            },
          ],
        },
        LOPEZ: {
          name: "LOPEZ",
          baranggays: [
            {
              name: "BACUNGAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BANABAHIN IBABA",
            },
            {
              name: "BANABAHIN ILAYA",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BEBITO",
            },
            {
              name: "BIGAJO",
            },
            {
              name: "BINAHIAN A",
            },
            {
              name: "BINAHIAN B",
            },
            {
              name: "BINAHIAN C",
            },
            {
              name: "BOCBOC",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BURGOS (POB.)",
            },
            {
              name: "BUYACANIN",
            },
            {
              name: "CAGACAG",
            },
            {
              name: "CALANTIPAYAN",
            },
            {
              name: "CANDA IBABA",
            },
            {
              name: "CANDA ILAYA",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CAWAYANIN",
            },
            {
              name: "COGORIN IBABA",
            },
            {
              name: "COGORIN ILAYA",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DANLAGAN",
            },
            {
              name: "DE LA PAZ",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "ESPERANZA IBABA",
            },
            {
              name: "ESPERANZA ILAYA",
            },
            {
              name: "GOMEZ (POB.)",
            },
            {
              name: "GUIHAY",
            },
            {
              name: "GUINUANGAN",
            },
            {
              name: "GUITES",
            },
            {
              name: "HONDAGUA",
            },
            {
              name: "ILAYANG ILOG A",
            },
            {
              name: "ILAYANG ILOG B",
            },
            {
              name: "INALUSAN",
            },
            {
              name: "JONGO",
            },
            {
              name: "LALAGUNA",
            },
            {
              name: "LOPEZ",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MABANBAN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "MAGSAYSAY (POB.)",
            },
            {
              name: "MAGUILAYAN",
            },
            {
              name: "MAHAYOD-HAYOD",
            },
            {
              name: "MAL-AY",
            },
            {
              name: "MANDOOG",
            },
            {
              name: "MANGUISIAN",
            },
            {
              name: "MATINIK",
            },
            {
              name: "MONTECLARO",
            },
            {
              name: "PAMAMPANGIN",
            },
            {
              name: "PANSOL",
            },
            {
              name: "PEÑAFRANCIA",
            },
            {
              name: "PISIPIS",
            },
            {
              name: "RIZAL (POBLACION)",
            },
            {
              name: "RIZAL (RURAL)",
            },
            {
              name: "ROMA",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAMAT",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO A",
            },
            {
              name: "SAN FRANCISCO B",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL (DAO)",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA JACOBE",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTA TERESA (MAYUPAG)",
            },
            {
              name: "SANTO NIÑO IBABA",
            },
            {
              name: "SANTO NIÑO ILAYA",
            },
            {
              name: "SILANG",
            },
            {
              name: "SUGOD",
            },
            {
              name: "SUMALANG",
            },
            {
              name: "TALOLONG (POB.)",
            },
            {
              name: "TAN-AG IBABA",
            },
            {
              name: "TAN-AG ILAYA",
            },
            {
              name: "TOCALIN",
            },
            {
              name: "VEGAFLOR",
            },
            {
              name: "VERGAÑA",
            },
            {
              name: "VERONICA",
            },
            {
              name: "VILLA AURORA",
            },
            {
              name: "VILLA ESPINA",
            },
            {
              name: "VILLA GEDA",
            },
            {
              name: "VILLA HERMOSA",
            },
            {
              name: "VILLAMONTE",
            },
            {
              name: "VILLANACAOB",
            },
          ],
        },
        LUCBAN: {
          name: "LUCBAN",
          baranggays: [
            {
              name: "ABANG",
            },
            {
              name: "ALILIW",
            },
            {
              name: "ATULINAO",
            },
            {
              name: "AYUTI",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "IGANG",
            },
            {
              name: "KABATETE",
            },
            {
              name: "KAKAWIT",
            },
            {
              name: "KALANGAY",
            },
            {
              name: "KALYAAT",
            },
            {
              name: "KILIB",
            },
            {
              name: "KULAPI",
            },
            {
              name: "LUCBAN",
            },
            {
              name: "MAHABANG PARANG",
            },
            {
              name: "MALUPAK",
            },
            {
              name: "MANASA",
            },
            {
              name: "MAY-IT",
            },
            {
              name: "NAGSINAMO",
            },
            {
              name: "NALUNAO",
            },
            {
              name: "PALOLA",
            },
            {
              name: "PIIS",
            },
            {
              name: "SAMIL",
            },
            {
              name: "TIAWE",
            },
            {
              name: "TINAMNAN",
            },
          ],
        },
        "LUCENA CITY": {
          name: "LUCENA CITY",
          baranggays: [
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BARRA",
            },
            {
              name: "BOCOHAN",
            },
            {
              name: "COTTA",
            },
            {
              name: "DALAHICAN",
            },
            {
              name: "DOMOIT",
            },
            {
              name: "GULANG-GULANG",
            },
            {
              name: "IBABANG DUPAY",
            },
            {
              name: "IBABANG IYAM",
            },
            {
              name: "IBABANG TALIM",
            },
            {
              name: "ILAYANG DUPAY",
            },
            {
              name: "ILAYANG IYAM",
            },
            {
              name: "ILAYANG TALIM",
            },
            {
              name: "ISABANG",
            },
            {
              name: "LUCENA CITY",
            },
            {
              name: "MARKET VIEW",
            },
            {
              name: "MAYAO CASTILLO",
            },
            {
              name: "MAYAO CROSSING",
            },
            {
              name: "MAYAO KANLURAN",
            },
            {
              name: "MAYAO PARADA",
            },
            {
              name: "MAYAO SILANGAN",
            },
            {
              name: "RANSOHAN",
            },
            {
              name: "SALINAS",
            },
            {
              name: "TALAO-TALAO",
            },
          ],
        },
        MACALELON: {
          name: "MACALELON",
          baranggays: [
            {
              name: "AMONTAY",
            },
            {
              name: "ANOS",
            },
            {
              name: "BUYAO",
            },
            {
              name: "CALANTAS",
            },
            {
              name: "CANDANGAL",
            },
            {
              name: "CASTILLO (POB.)",
            },
            {
              name: "DAMAYAN (POB.)",
            },
            {
              name: "LAHING",
            },
            {
              name: "LUCTOB",
            },
            {
              name: "MABINI IBABA",
            },
            {
              name: "MABINI ILAYA",
            },
            {
              name: "MACALELON",
            },
            {
              name: "MALABAHAY",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "MASIPAG (POB.)",
            },
            {
              name: "OLONGTAO IBABA",
            },
            {
              name: "OLONGTAO ILAYA",
            },
            {
              name: "PADRE HERRERA",
            },
            {
              name: "PAG-ASA (POB.)",
            },
            {
              name: "PAJARILLO",
            },
            {
              name: "PINAGBAYANAN",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "RODRIQUEZ (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TAGUIN",
            },
            {
              name: "TUBIGAN IBABA",
            },
            {
              name: "TUBIGAN ILAYA",
            },
            {
              name: "VISTA HERMOSA",
            },
          ],
        },
        MAUBAN: {
          name: "MAUBAN",
          baranggays: [
            {
              name: "ABO-ABO",
            },
            {
              name: "ALITAP",
            },
            {
              name: "BAAO",
            },
            {
              name: "BAGONG BAYAN (POB.)",
            },
            {
              name: "BALAYBALAY",
            },
            {
              name: "BATO",
            },
            {
              name: "CAGBALETE I",
            },
            {
              name: "CAGBALETE II",
            },
            {
              name: "CAGSIAY I",
            },
            {
              name: "CAGSIAY II",
            },
            {
              name: "CAGSIAY III",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAUNGAN (POB.)",
            },
            {
              name: "LIWAYWAY",
            },
            {
              name: "LUAL (POB.)",
            },
            {
              name: "LUAL RURAL",
            },
            {
              name: "LUCUTAN",
            },
            {
              name: "LUYA-LUYA",
            },
            {
              name: "MABATO (POB.)",
            },
            {
              name: "MACASIN",
            },
            {
              name: "MAUBAN",
            },
            {
              name: "POLO",
            },
            {
              name: "REMEDIOS I",
            },
            {
              name: "REMEDIOS II",
            },
            {
              name: "RIZALIANA (POB.)",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SADSARAN (POB.)",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTO ANGEL",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTOL",
            },
            {
              name: "SOLEDAD",
            },
            {
              name: "TAPUCAN",
            },
          ],
        },
        MULANAY: {
          name: "MULANAY",
          baranggays: [
            {
              name: "AJOS",
            },
            {
              name: "AMUGUIS",
            },
            {
              name: "ANONANG",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAGUPAYE",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BOLO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BURGOS",
            },
            {
              name: "BUTANYOG",
            },
            {
              name: "CANUYEP",
            },
            {
              name: "F. NANADIEGO",
            },
            {
              name: "IBABANG CAMBUGA",
            },
            {
              name: "IBABANG YUNI",
            },
            {
              name: "ILAYANG CAMBUGA (MABINI)",
            },
            {
              name: "ILAYANG YUNI",
            },
            {
              name: "LATANGAN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MATATAJA",
            },
            {
              name: "MULANAY",
            },
            {
              name: "PAKIING",
            },
            {
              name: "PATABOG",
            },
            {
              name: "SAGONGON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA ROSA",
            },
          ],
        },
        "PADRE BURGOS": {
          name: "PADRE BURGOS",
          baranggays: [
            {
              name: "BASIAO (POB.)",
            },
            {
              name: "BURGOS (POB.)",
            },
            {
              name: "CABUYAO NORTE",
            },
            {
              name: "CABUYAO SUR",
            },
            {
              name: "CAMPO (POB.)",
            },
            {
              name: "DANLAGAN",
            },
            {
              name: "DUHAT",
            },
            {
              name: "HINGUIWIN",
            },
            {
              name: "KINAGUNAN IBABA",
            },
            {
              name: "KINAGUNAN ILAYA",
            },
            {
              name: "LIPATA",
            },
            {
              name: "MARAO",
            },
            {
              name: "MARQUEZ",
            },
            {
              name: "PADRE BURGOS",
            },
            {
              name: "PUNTA (POB.)",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SIPA",
            },
            {
              name: "TULAY BUHANGIN",
            },
            {
              name: "VILLAPAZ",
            },
            {
              name: "WALAY",
            },
            {
              name: "YAWE",
            },
          ],
        },
        PAGBILAO: {
          name: "PAGBILAO",
          baranggays: [
            {
              name: "ALUPAYE",
            },
            {
              name: "AÑATO",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "BANTIGUE",
            },
            {
              name: "BARANGAY 1 CASTILLO (POB.)",
            },
            {
              name: "BARANGAY 2 DAUNGAN (POB.)",
            },
            {
              name: "BARANGAY 3 DEL CARMEN (POB.)",
            },
            {
              name: "BARANGAY 4 PARANG (POB.)",
            },
            {
              name: "BARANGAY 5 SANTA CATALINA (POB.)",
            },
            {
              name: "BARANGAY 6 TAMBAK (POB.)",
            },
            {
              name: "BIGO",
            },
            {
              name: "BINAHAAN",
            },
            {
              name: "BUKAL",
            },
            {
              name: "IBABANG BAGUMBUNGAN",
            },
            {
              name: "IBABANG PALSABANGON",
            },
            {
              name: "IBABANG POLO",
            },
            {
              name: "IKIRIN",
            },
            {
              name: "ILAYANG BAGUMBUNGAN",
            },
            {
              name: "ILAYANG PALSABANGON",
            },
            {
              name: "ILAYANG POLO",
            },
            {
              name: "KANLURAN MALICBOY",
            },
            {
              name: "MAPAGONG",
            },
            {
              name: "MAYHAY",
            },
            {
              name: "PAGBILAO",
            },
            {
              name: "PINAGBAYANAN",
            },
            {
              name: "SILANGAN MALICBOY",
            },
            {
              name: "TALIPAN",
            },
            {
              name: "TUKALAN",
            },
          ],
        },
        "PANUKULAN (ISLAND)": {
          name: "PANUKULAN (ISLAND)",
          baranggays: [
            {
              name: "BALUNGAY",
            },
            {
              name: "BATO",
            },
            {
              name: "BONBON",
            },
            {
              name: "CALASUMANGA",
            },
            {
              name: "KINALAGTI",
            },
            {
              name: "LIBO",
            },
            {
              name: "LIPATA",
            },
            {
              name: "MATANGKAP",
            },
            {
              name: "MILAWID",
            },
            {
              name: "PAGITAN",
            },
            {
              name: "PANDAN",
            },
            {
              name: "PANUKULAN (ISLAND)",
            },
            {
              name: "SAN JUAN (POB.)",
            },
          ],
        },
        "PATNANUNGAN (ISLAND)": {
          name: "PATNANUNGAN (ISLAND)",
          baranggays: [
            {
              name: "AMAGA",
            },
            {
              name: "BUSDAK",
            },
            {
              name: "KILOGAN",
            },
            {
              name: "LUOD",
            },
            {
              name: "PATNANUNGAN (ISLAND)",
            },
            {
              name: "PATNANUNGAN NORTE",
            },
            {
              name: "PATNANUNGAN SUR (POB.)",
            },
          ],
        },
        "PEREZ (ISLAND)": {
          name: "PEREZ (ISLAND)",
          baranggays: [
            {
              name: "BAGONG PAG-ASA POB. (BARANGAY 3)",
            },
            {
              name: "BAGONG SILANG POB. (BARANGAY 4)",
            },
            {
              name: "MAABOT",
            },
            {
              name: "MAINIT NORTE",
            },
            {
              name: "MAINIT SUR",
            },
            {
              name: "MAPAGMAHAL POB. (BARANGAY 2)",
            },
            {
              name: "PAGKAKAISA POB. (BARANGAY 1)",
            },
            {
              name: "PAMBUHAN",
            },
            {
              name: "PEREZ (ISLAND)",
            },
            {
              name: "PINAGTUBIGAN ESTE",
            },
            {
              name: "PINAGTUBIGAN WESTE",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SANGIRIN",
            },
            {
              name: "VILLAMANZANO NORTE",
            },
            {
              name: "VILLAMANZANO SUR",
            },
          ],
        },
        PITOGO: {
          name: "PITOGO",
          baranggays: [
            {
              name: "AMONTAY",
            },
            {
              name: "BIGA",
            },
            {
              name: "BILUCAO",
            },
            {
              name: "CABULIHAN",
            },
            {
              name: "CASTILLO (POB.)",
            },
            {
              name: "CAWAYANIN",
            },
            {
              name: "COMETA",
            },
            {
              name: "DALAMPASIGAN (POB.)",
            },
            {
              name: "DULONG BAYAN (POB.)",
            },
            {
              name: "GANGAHIN",
            },
            {
              name: "IBABANG BURGOS",
            },
            {
              name: "IBABANG PACATIN",
            },
            {
              name: "IBABANG PIÑA",
            },
            {
              name: "IBABANG SOLIYAO",
            },
            {
              name: "ILAYANG BURGOS",
            },
            {
              name: "ILAYANG PACATIN",
            },
            {
              name: "ILAYANG PIÑA",
            },
            {
              name: "ILAYANG SOLIYAO",
            },
            {
              name: "MAALIW (POB.)",
            },
            {
              name: "MANGGAHAN (POB.)",
            },
            {
              name: "MASAYA (POB.)",
            },
            {
              name: "MAYUBOK (POB.)",
            },
            {
              name: "NAG-CRUZ",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PAG-ASA (POB.)",
            },
            {
              name: "PAMILIHAN (POB.)",
            },
            {
              name: "PAYTE",
            },
            {
              name: "PINAGBAYANAN",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POCTOL",
            },
            {
              name: "QUEZON",
            },
            {
              name: "QUINAGASAN",
            },
            {
              name: "RIZALINO",
            },
            {
              name: "SAGUINSINAN",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SISIRIN",
            },
            {
              name: "SUMAG ESTE",
            },
            {
              name: "SUMAG NORTE",
            },
            {
              name: "SUMAG WESTE",
            },
          ],
        },
        PLARIDEL: {
          name: "PLARIDEL",
          baranggays: [
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DUHAT",
            },
            {
              name: "ILAYA",
            },
            {
              name: "ILOSONG",
            },
            {
              name: "M. L. TUMAGAY POB. (REMAS MABUTON)",
            },
            {
              name: "PAANG BUNDOK (POB.)",
            },
            {
              name: "PAMPAARALAN (POB.)",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "TANAUAN",
            },
          ],
        },
        "POLILLO (ISLAND)": {
          name: "POLILLO (ISLAND)",
          baranggays: [
            {
              name: "ANAWAN",
            },
            {
              name: "ATULAYAN",
            },
            {
              name: "BALESIN",
            },
            {
              name: "BAÑADERO",
            },
            {
              name: "BINIBITINAN",
            },
            {
              name: "BISLIAN",
            },
            {
              name: "BUCAO",
            },
            {
              name: "CANICANIAN",
            },
            {
              name: "KALUBAKIS",
            },
            {
              name: "LANGUYIN",
            },
            {
              name: "LIBJO",
            },
            {
              name: "PAMATDAN",
            },
            {
              name: "PILION",
            },
            {
              name: "PINAGLUBAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLILLO (ISLAND)",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALIPSIP",
            },
            {
              name: "SIBULAN",
            },
            {
              name: "TALUONG",
            },
            {
              name: "TAMULAYA-ANITONG",
            },
          ],
        },
        "QUEZON (ISLAND)": {
          name: "QUEZON (ISLAND)",
          baranggays: [
            {
              name: "APAD",
            },
            {
              name: "ARGOSINO",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BARANGAY V (POB.)",
            },
            {
              name: "BARANGAY VI (POB.)",
            },
            {
              name: "CAGBALOGO",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "COMETA",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GUINHAWA",
            },
            {
              name: "GUMUBAT",
            },
            {
              name: "MAGSINO",
            },
            {
              name: "MASCARIÑA",
            },
            {
              name: "MONTAÑA",
            },
            {
              name: "QUEZON (ISLAND)",
            },
            {
              name: "SABANG",
            },
            {
              name: "SILANGAN",
            },
            {
              name: "TAGKAWA",
            },
            {
              name: "VILLA BELEN",
            },
            {
              name: "VILLA FRANCIA",
            },
            {
              name: "VILLA GOMEZ",
            },
            {
              name: "VILLA MERCEDES",
            },
          ],
        },
        REAL: {
          name: "REAL",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "CAPALONG",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "KILOLORAN",
            },
            {
              name: "LLAVAC",
            },
            {
              name: "LUBAYAT",
            },
            {
              name: "MALAPAD",
            },
            {
              name: "MARAGONDON",
            },
            {
              name: "MASIKAP",
            },
            {
              name: "MAUNLAD",
            },
            {
              name: "PANDAN",
            },
            {
              name: "POBLACION 61 (BARANGAY 2)",
            },
            {
              name: "POBLACION I (BARANGAY 1)",
            },
            {
              name: "REAL",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TANAUAN",
            },
            {
              name: "TIGNOAN",
            },
            {
              name: "UNGOS",
            },
          ],
        },
        SAMPALOC: {
          name: "SAMPALOC",
          baranggays: [
            {
              name: "ALUPAY",
            },
            {
              name: "APASAN",
            },
            {
              name: "BANOT",
            },
            {
              name: "BATAAN",
            },
            {
              name: "BAYONGON",
            },
            {
              name: "BILUCAO",
            },
            {
              name: "CALDONG",
            },
            {
              name: "IBABANG OWAIN",
            },
            {
              name: "ILAYANG OWAIN",
            },
            {
              name: "MAMALA",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN BUENO",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "TAQUICO",
            },
          ],
        },
        "SAN ANDRES": {
          name: "SAN ANDRES",
          baranggays: [
            {
              name: "ALIBIHABAN",
            },
            {
              name: "CAMFLORA",
            },
            {
              name: "MANGERO",
            },
            {
              name: "PANSOY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "TALA",
            },
            {
              name: "TALISAY",
            },
          ],
        },
        "SAN ANTONIO": {
          name: "SAN ANTONIO",
          baranggays: [
            {
              name: "ARAWAN",
            },
            {
              name: "BAGONG NIING",
            },
            {
              name: "BALAT ATIS",
            },
            {
              name: "BRIONES",
            },
            {
              name: "BULIHAN",
            },
            {
              name: "BULIRAN",
            },
            {
              name: "CALLEJON",
            },
            {
              name: "CORAZON",
            },
            {
              name: "LOOB",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANUEL DEL VALLE, SR.",
            },
            {
              name: "MATIPUNSO",
            },
            {
              name: "NIING",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULO",
            },
            {
              name: "PURY",
            },
            {
              name: "SAMPAGA",
            },
            {
              name: "SAMPAGUITA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SINTURISAN",
            },
          ],
        },
        "SAN FRANCISCO (AURORA)": {
          name: "SAN FRANCISCO (AURORA)",
          baranggays: [
            {
              name: "BUTANGUIAD",
            },
            {
              name: "CASAY",
            },
            {
              name: "CAWAYAN I",
            },
            {
              name: "CAWAYAN II",
            },
            {
              name: "DON JUAN VERCELOS",
            },
            {
              name: "HUYON-UYON",
            },
            {
              name: "IBABANG TAYUMAN",
            },
            {
              name: "ILAYANG TAYUMAN",
            },
            {
              name: "INABUAN",
            },
            {
              name: "MABUÑGA",
            },
            {
              name: "NASALAAN",
            },
            {
              name: "PAGSANGAHAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUGON",
            },
            {
              name: "SAN FRANCISCO (AURORA)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SILONGIN",
            },
          ],
        },
        "SAN NARCISO": {
          name: "SAN NARCISO",
          baranggays: [
            {
              name: "ABUYON",
            },
            {
              name: "ANDRES BONIFACIO",
            },
            {
              name: "BANI",
            },
            {
              name: "BAYANIHAN (POB.)",
            },
            {
              name: "BINAY",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUSOKBUSOKAN",
            },
            {
              name: "CALWIT",
            },
            {
              name: "GUINHALINAN",
            },
            {
              name: "LACDAYAN",
            },
            {
              name: "MAGUITING",
            },
            {
              name: "MALIGAYA (POB.)",
            },
            {
              name: "MANLAMPONG",
            },
            {
              name: "PAGDADAMAYAN (POB.)",
            },
            {
              name: "PAGKAKAISA (POB.)",
            },
            {
              name: "PUNTA",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN NARCISO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "VIGO CENTRAL",
            },
            {
              name: "VILLA AURIN (PINAGSAMA)",
            },
            {
              name: "VILLA REYES",
            },
            {
              name: "WHITE CLIFF",
            },
          ],
        },
        SARIAYA: {
          name: "SARIAYA",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BALUBAL",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BIGNAY 1",
            },
            {
              name: "BIGNAY 2",
            },
            {
              name: "BUCAL",
            },
            {
              name: "CANDA",
            },
            {
              name: "CASTAÑAS",
            },
            {
              name: "CONCEPCION BANAHAW",
            },
            {
              name: "CONCEPCION NO. 1",
            },
            {
              name: "CONCEPCION PALASAN",
            },
            {
              name: "CONCEPCION PINAGBAKURAN",
            },
            {
              name: "GIBANGA",
            },
            {
              name: "GUISGUIS-SAN ROQUE",
            },
            {
              name: "GUISGUIS-TALON",
            },
            {
              name: "JANAGDONG 1",
            },
            {
              name: "JANAGDONG 2",
            },
            {
              name: "LIMBON",
            },
            {
              name: "LUTUCAN 1",
            },
            {
              name: "LUTUCAN BATA",
            },
            {
              name: "LUTUCAN MALABAG",
            },
            {
              name: "MAMALA I",
            },
            {
              name: "MAMALA II",
            },
            {
              name: "MANGGALANG 1",
            },
            {
              name: "MANGGALANG TULO-TULO",
            },
            {
              name: "MANGGALANG-BANTILAN",
            },
            {
              name: "MANGGALANG-KILING",
            },
            {
              name: "MONTECILLO",
            },
            {
              name: "MORONG",
            },
            {
              name: "PILI",
            },
            {
              name: "SAMPALOC 1",
            },
            {
              name: "SAMPALOC 2",
            },
            {
              name: "SAMPALOC BOGON",
            },
            {
              name: "SAMPALOC SANTO CRISTO",
            },
            {
              name: "SARIAYA",
            },
            {
              name: "TALAAN APLAYA",
            },
            {
              name: "TALAANPANTOC",
            },
            {
              name: "TUMBAGA 1",
            },
            {
              name: "TUMBAGA 2",
            },
          ],
        },
        TAGKAWAYAN: {
          name: "TAGKAWAYAN",
          baranggays: [
            {
              name: "ALDAVOC",
            },
            {
              name: "ALIJI",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "BOSIGON",
            },
            {
              name: "BUKAL",
            },
            {
              name: "CABIBIHAN",
            },
            {
              name: "CABUGWANG",
            },
            {
              name: "CAGASCAS",
            },
            {
              name: "CANDALAPDAP",
            },
            {
              name: "CASISPALAN",
            },
            {
              name: "COLONG-COLONG",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "KATIMO",
            },
            {
              name: "KINATAKUTAN",
            },
            {
              name: "LANDING",
            },
            {
              name: "LAUREL",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAGUIBUAY",
            },
            {
              name: "MAHINTA",
            },
            {
              name: "MALBOG",
            },
            {
              name: "MANATO CENTRAL",
            },
            {
              name: "MANATO STATION",
            },
            {
              name: "MANGAYAO",
            },
            {
              name: "MANSILAY",
            },
            {
              name: "MAPULOT",
            },
            {
              name: "MUNTING PARANG",
            },
            {
              name: "PAYAPA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN DIEGO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CECILIA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTO NIÑO I",
            },
            {
              name: "SANTO NIÑO II",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SEGUIWAN",
            },
            {
              name: "TABASON",
            },
            {
              name: "TAGKAWAYAN",
            },
            {
              name: "TUNTON",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        "TAYABAS CITY": {
          name: "TAYABAS CITY",
          baranggays: [
            {
              name: "ALITAO",
            },
            {
              name: "ALSAM IBABA",
            },
            {
              name: "ALSAM ILAYA",
            },
            {
              name: "ALUPAY",
            },
            {
              name: "ANGELES ZONE I (POB.)",
            },
            {
              name: "ANGELES ZONE II",
            },
            {
              name: "ANGELES ZONE III",
            },
            {
              name: "ANGELES ZONE IV",
            },
            {
              name: "ANGUSTIAS ZONE I (POB.)",
            },
            {
              name: "ANGUSTIAS ZONE II",
            },
            {
              name: "ANGUSTIAS ZONE III",
            },
            {
              name: "ANGUSTIAS ZONE IV",
            },
            {
              name: "ANOS",
            },
            {
              name: "AYAAS",
            },
            {
              name: "BAGUIO",
            },
            {
              name: "BANILAD",
            },
            {
              name: "BUKAL IBABA",
            },
            {
              name: "BUKAL ILAYA",
            },
            {
              name: "CALANTAS",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CAMAYSA",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DOMOIT KANLURAN",
            },
            {
              name: "DOMOIT SILANGAN",
            },
            {
              name: "GIBANGA",
            },
            {
              name: "IBAS",
            },
            {
              name: "ILASAN IBABA",
            },
            {
              name: "ILASAN ILAYA",
            },
            {
              name: "IPILAN",
            },
            {
              name: "ISABANG",
            },
            {
              name: "KATIGAN KANLURAN",
            },
            {
              name: "KATIGAN SILANGAN",
            },
            {
              name: "LAKAWAN",
            },
            {
              name: "LALO",
            },
            {
              name: "LAWIGUE",
            },
            {
              name: "LITA (POB.)",
            },
            {
              name: "MALAOA",
            },
            {
              name: "MASIN",
            },
            {
              name: "MATE",
            },
            {
              name: "MATEUNA",
            },
            {
              name: "MAYOWE",
            },
            {
              name: "NANGKA IBABA",
            },
            {
              name: "NANGKA ILAYA",
            },
            {
              name: "OPIAS",
            },
            {
              name: "PALALE IBABA",
            },
            {
              name: "PALALE ILAYA",
            },
            {
              name: "PALALE KANLURAN",
            },
            {
              name: "PALALE SILANGAN",
            },
            {
              name: "PANDAKAKI",
            },
            {
              name: "POOK",
            },
            {
              name: "POTOL",
            },
            {
              name: "SAN DIEGO ZONE I (POB.)",
            },
            {
              name: "SAN DIEGO ZONE II",
            },
            {
              name: "SAN DIEGO ZONE III",
            },
            {
              name: "SAN DIEGO ZONE IV",
            },
            {
              name: "SAN ISIDRO ZONE I (POB.)",
            },
            {
              name: "SAN ISIDRO ZONE II",
            },
            {
              name: "SAN ISIDRO ZONE III",
            },
            {
              name: "SAN ISIDRO ZONE IV",
            },
            {
              name: "SAN ROQUE ZONE I (POB.)",
            },
            {
              name: "SAN ROQUE ZONE II",
            },
            {
              name: "TALOLONG",
            },
            {
              name: "TAMLONG",
            },
            {
              name: "TAYABAS CITY",
            },
            {
              name: "TONGKO",
            },
            {
              name: "VALENCIA",
            },
            {
              name: "WAKAS",
            },
          ],
        },
        TIAONG: {
          name: "TIAONG",
          baranggays: [
            {
              name: "ANASTACIA (TAGBAK)",
            },
            {
              name: "AQUINO",
            },
            {
              name: "AYUSAN I",
            },
            {
              name: "AYUSAN II",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "BEHIA",
            },
            {
              name: "BUKAL",
            },
            {
              name: "BULA",
            },
            {
              name: "BULAKIN",
            },
            {
              name: "CABATANG",
            },
            {
              name: "CABAY",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "LAGALAG",
            },
            {
              name: "LALIG",
            },
            {
              name: "LUMINGON",
            },
            {
              name: "LUSACAN",
            },
            {
              name: "PAIISA",
            },
            {
              name: "PALAGARAN",
            },
            {
              name: "QUIPOT",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "TAGBAKIN",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAMISIAN",
            },
            {
              name: "TIAONG",
            },
          ],
        },
        UNISAN: {
          name: "UNISAN",
          baranggays: [
            {
              name: "ALMACEN",
            },
            {
              name: "BALAGTAS",
            },
            {
              name: "BALANACAN",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BULO IBABA",
            },
            {
              name: "BULO ILAYA",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CABULIHAN IBABA",
            },
            {
              name: "CABULIHAN ILAYA",
            },
            {
              name: "CAIGDAL",
            },
            {
              name: "F. DE JESUS (POB.)",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "KALILAYAN IBABA",
            },
            {
              name: "KALILAYAN ILAYA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAIROK IBABA",
            },
            {
              name: "MAIROK ILAYA",
            },
            {
              name: "MALVAR",
            },
            {
              name: "MAPUTAT",
            },
            {
              name: "MULIGUIN",
            },
            {
              name: "PAGAGUASAN",
            },
            {
              name: "PANAON IBABA",
            },
            {
              name: "PANAON ILAYA",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POCTOL",
            },
            {
              name: "PUNTA",
            },
            {
              name: "R. LAPU-LAPU (POB.)",
            },
            {
              name: "R. MAGSAYSAY (POB.)",
            },
            {
              name: "RAJA SOLIMAN (POB.)",
            },
            {
              name: "RIZAL IBABA",
            },
            {
              name: "RIZAL ILAYA",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SOCORRO",
            },
            {
              name: "TAGUMPAY",
            },
            {
              name: "TUBAS",
            },
            {
              name: "TUBIGAN",
            },
            {
              name: "UNISAN",
            },
          ],
        },
      },
    },
    QUIRINO: {
      name: "QUIRINO",
      region: "REGION 2",
      municipalities: {
        AGLIPAY: {
          name: "AGLIPAY",
          baranggays: [
            {
              name: "AGLIPAY",
            },
            {
              name: "ALICIA",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "DAGUPAN",
            },
            {
              name: "DIODOL",
            },
            {
              name: "DUMABEL",
            },
            {
              name: "DUNGO (OSMEÑA)",
            },
            {
              name: "GUINALBIN",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "NAGABGABAN",
            },
            {
              name: "PALACIAN",
            },
            {
              name: "PINARIPAD NORTE",
            },
            {
              name: "PINARIPAD SUR",
            },
            {
              name: "PROGRESO (POB.)",
            },
            {
              name: "RAMOS",
            },
            {
              name: "RANG-AYAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BENIGNO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN LEONARDO (CABARROGUIS)",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "VILLA PAGADUAN",
            },
            {
              name: "VILLA SANTIAGO",
            },
            {
              name: "VILLA VENTURA",
            },
          ],
        },
        CABARROGUIS: {
          name: "CABARROGUIS",
          baranggays: [
            {
              name: "BANUAR",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CABARROGUIS",
            },
            {
              name: "CALAOCAN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DIBIBI",
            },
            {
              name: "DINGASAN",
            },
            {
              name: "EDEN",
            },
            {
              name: "GOMEZ",
            },
            {
              name: "GUNDAWAY (POB.)",
            },
            {
              name: "MANGANDINGAY (POB.)",
            },
            {
              name: "SAN MARCOS",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "TUCOD",
            },
            {
              name: "VILLA PEÑA (CAPELLANGAN)",
            },
            {
              name: "VILLAMOR",
            },
            {
              name: "VILLAROSE",
            },
            {
              name: "ZAMORA",
            },
          ],
        },
        DIFFUN: {
          name: "DIFFUN",
          baranggays: [
            {
              name: "AKLAN VILLAGE",
            },
            {
              name: "ANDRES BONIFACIO (POB.)",
            },
            {
              name: "AURORA EAST (POB.)",
            },
            {
              name: "AURORA WEST (POB.)",
            },
            {
              name: "BAGUIO VILLAGE",
            },
            {
              name: "BALAGBAG",
            },
            {
              name: "BANNAWAG",
            },
            {
              name: "CAJEL",
            },
            {
              name: "CAMPAMENTO",
            },
            {
              name: "DIEGO SILANG",
            },
            {
              name: "DIFFUN",
            },
            {
              name: "DON FAUSTINO PAGADUAN",
            },
            {
              name: "DON MARIANO PEREZ, SR.",
            },
            {
              name: "DOÑA IMELDA",
            },
            {
              name: "DUMANISI",
            },
            {
              name: "GABRIELA SILANG",
            },
            {
              name: "GREGORIO PIMENTEL",
            },
            {
              name: "GULAC",
            },
            {
              name: "GURIBANG",
            },
            {
              name: "IFUGAO VILLAGE",
            },
            {
              name: "ISIDRO PAREDES",
            },
            {
              name: "LIWAYWAY",
            },
            {
              name: "LUTTUAD",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAKATE",
            },
            {
              name: "MARIA CLARA",
            },
            {
              name: "RAFAEL PALMA (DON SERGIO OSMEÑA)",
            },
            {
              name: "RICARTE NORTE",
            },
            {
              name: "RICARTE SUR",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "VILLA PASCUA",
            },
          ],
        },
        MADDELA: {
          name: "MADDELA",
          baranggays: [
            {
              name: "ABBAG",
            },
            {
              name: "BALLIGUI",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CABUA-AN",
            },
            {
              name: "COFCAVILLE",
            },
            {
              name: "DIDUYON",
            },
            {
              name: "DIPINTIN",
            },
            {
              name: "DIVISORIA NORTE",
            },
            {
              name: "DIVISORIA SUR (BISANGAL)",
            },
            {
              name: "DUMABATO NORTE",
            },
            {
              name: "DUMABATO SUR",
            },
            {
              name: "JOSE ANCHETA",
            },
            {
              name: "LUSOD",
            },
            {
              name: "MADDELA",
            },
            {
              name: "MANGLAD",
            },
            {
              name: "PEDLISAN",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SAN BERNABE",
            },
            {
              name: "SAN DIONISIO I",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "VILLA AGULLANA",
            },
            {
              name: "VILLA GRACIA",
            },
            {
              name: "VILLA HERMOSA NORTE",
            },
            {
              name: "VILLA HERMOSA SUR",
            },
            {
              name: "VILLA JOSE V YLANAN",
            },
            {
              name: "YSMAEL",
            },
          ],
        },
        NAGTIPUNAN: {
          name: "NAGTIPUNAN",
          baranggays: [
            {
              name: "ANAK",
            },
            {
              name: "ASAKLAT",
            },
            {
              name: "DIPANTAN",
            },
            {
              name: "DISSIMUNGAL",
            },
            {
              name: "GUINO (GIAYAN)",
            },
            {
              name: "LA CONWAP (GUINGIN)",
            },
            {
              name: "LANDINGAN",
            },
            {
              name: "MATADDI",
            },
            {
              name: "MATMAD",
            },
            {
              name: "NAGTIPUNAN",
            },
            {
              name: "OLD GUMIAD",
            },
            {
              name: "PONGGO",
            },
            {
              name: "SAN DIONISIO II",
            },
            {
              name: "SAN PUGO",
            },
            {
              name: "SAN RAMOS",
            },
            {
              name: "SANGBAY",
            },
            {
              name: "WASID",
            },
          ],
        },
        SAGUDAY: {
          name: "SAGUDAY",
          baranggays: [
            {
              name: "CARDENAS",
            },
            {
              name: "DIBUL",
            },
            {
              name: "GAMIS",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "MAGSAYSAY (POB.)",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SAGUDAY",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TRES REYES",
            },
          ],
        },
      },
    },
    RIZAL: {
      name: "RIZAL",
      region: "REGION 4-A",
      municipalities: {
        ANGONO: {
          name: "ANGONO",
          baranggays: [
            {
              name: "ANGONO",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "KALAYAAN",
            },
            {
              name: "MAHABANG PARANG",
            },
            {
              name: "POBLACION IBABA",
            },
            {
              name: "POBLACION ITAAS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        BARAS: {
          name: "BARAS",
          baranggays: [
            {
              name: "BARAS",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "EVANGELISTA",
            },
            {
              name: "MABINI",
            },
            {
              name: "PINUGAY",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "SANTIAGO",
            },
          ],
        },
        BINANGONAN: {
          name: "BINANGONAN",
          baranggays: [
            {
              name: "BANGAD",
            },
            {
              name: "BATINGAN",
            },
            {
              name: "BILIBIRAN",
            },
            {
              name: "BINANGONAN",
            },
            {
              name: "BINITAGAN",
            },
            {
              name: "BOMBONG",
            },
            {
              name: "BUHANGIN",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "GINOONG SANAY",
            },
            {
              name: "GULOD",
            },
            {
              name: "HABAGATAN",
            },
            {
              name: "ITHAN",
            },
            {
              name: "JANOSA",
            },
            {
              name: "KALAWAAN",
            },
            {
              name: "KALINAWAN",
            },
            {
              name: "KASILE",
            },
            {
              name: "KAYTOME",
            },
            {
              name: "KINABOOGAN",
            },
            {
              name: "KINAGATAN",
            },
            {
              name: "LAYUNAN (POB.)",
            },
            {
              name: "LIBID (POB.)",
            },
            {
              name: "LIBIS (POB.)",
            },
            {
              name: "LIMBON-LIMBON",
            },
            {
              name: "LUNSAD",
            },
            {
              name: "MACAMOT",
            },
            {
              name: "MAHABANG PARANG",
            },
            {
              name: "MALAKABAN",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PALANGOY",
            },
            {
              name: "PANTOK",
            },
            {
              name: "PILA PILA",
            },
            {
              name: "PINAGDILAWAN",
            },
            {
              name: "PIPINDAN",
            },
            {
              name: "RAYAP",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAPANG",
            },
            {
              name: "TABON",
            },
            {
              name: "TAGPOS",
            },
            {
              name: "TATALA",
            },
            {
              name: "TAYUMAN",
            },
          ],
        },
        CAINTA: {
          name: "CAINTA",
          baranggays: [
            {
              name: "CAINTA",
            },
            {
              name: "SAN ANDRES (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        CARDONA: {
          name: "CARDONA",
          baranggays: [
            {
              name: "BALIBAGO",
            },
            {
              name: "BOOR",
            },
            {
              name: "CALAHAN",
            },
            {
              name: "CARDONA",
            },
            {
              name: "DALIG",
            },
            {
              name: "DEL REMEDIO (POB.)",
            },
            {
              name: "IGLESIA (POB.)",
            },
            {
              name: "LAMBAC",
            },
            {
              name: "LOOC",
            },
            {
              name: "MALANGGAM-CALUBACAN",
            },
            {
              name: "NAGSULO",
            },
            {
              name: "NAVOTAS",
            },
            {
              name: "PATUNHAY",
            },
            {
              name: "REAL (POB.)",
            },
            {
              name: "SAMPAD",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SUBAY",
            },
            {
              name: "TICULIO",
            },
            {
              name: "TUNA",
            },
          ],
        },
        "CITY OF ANTIPOLO": {
          name: "CITY OF ANTIPOLO",
          baranggays: [
            {
              name: "BAGONG NAYON",
            },
            {
              name: "BEVERLY HILLS",
            },
            {
              name: "CALAWIS",
            },
            {
              name: "CITY OF ANTIPOLO",
            },
            {
              name: "COGIO",
            },
            {
              name: "CUPANG",
            },
            {
              name: "DALIG",
            },
            {
              name: "DELA PAZ (POB.)",
            },
            {
              name: "INARAWAN",
            },
            {
              name: "MAMBUGAN",
            },
            {
              name: "MAYAMOT",
            },
            {
              name: "MUNTINGDILAW",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SANTA CRUZ",
            },
          ],
        },
        "JALA-JALA": {
          name: "JALA-JALA",
          baranggays: [
            {
              name: "BAGUMBONG",
            },
            {
              name: "BAYUGO",
            },
            {
              name: "JALA-JALA",
            },
            {
              name: "LUBO",
            },
            {
              name: "PAALAMAN",
            },
            {
              name: "PAGKALINAWAN",
            },
            {
              name: "PALAYPALAY",
            },
            {
              name: "PUNTA",
            },
            {
              name: "SECOND DISTRICT (POB.)",
            },
            {
              name: "SIPSIPIN",
            },
            {
              name: "SPECIAL DISTRICT (POB.)",
            },
            {
              name: "THIRD DISTRICT (POB.)",
            },
          ],
        },
        MORONG: {
          name: "MORONG",
          baranggays: [
            {
              name: "BOMBONGAN",
            },
            {
              name: "CAN-CAL-LAN (CANIOGAN-CALERO-LANANG)",
            },
            {
              name: "LAGUNDI",
            },
            {
              name: "MAYBANCAL",
            },
            {
              name: "MORONG",
            },
            {
              name: "SAN GUILLERMO",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
          ],
        },
        PILILLA: {
          name: "PILILLA",
          baranggays: [
            {
              name: "BAGUMBAYAN (POB.)",
            },
            {
              name: "HALAYHAYIN",
            },
            {
              name: "HULO (POB.)",
            },
            {
              name: "IMATONG (POB.)",
            },
            {
              name: "MALAYA",
            },
            {
              name: "NIOGAN",
            },
            {
              name: "PILILLA",
            },
            {
              name: "QUISAO",
            },
            {
              name: "TAKUNGAN (POB.)",
            },
            {
              name: "WAWA (POB.)",
            },
          ],
        },
        "RODRIGUEZ (MONTALBAN)": {
          name: "RODRIGUEZ (MONTALBAN)",
          baranggays: [
            {
              name: "BALITE (POB.)",
            },
            {
              name: "BURGOS",
            },
            {
              name: "GERONIMO",
            },
            {
              name: "MACABUD",
            },
            {
              name: "MANGGAHAN",
            },
            {
              name: "MASCAP",
            },
            {
              name: "PURAY",
            },
            {
              name: "RODRIGUEZ (MONTALBAN)",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL",
            },
          ],
        },
        "SAN MATEO": {
          name: "SAN MATEO",
          baranggays: [
            {
              name: "AMPID I",
            },
            {
              name: "AMPID II",
            },
            {
              name: "BANABA",
            },
            {
              name: "DULONG BAYAN 1",
            },
            {
              name: "DULONG BAYAN 2",
            },
            {
              name: "GUINAYANG",
            },
            {
              name: "GUITNANG BAYAN I (POB.)",
            },
            {
              name: "GUITNANG BAYAN II (POB.)",
            },
            {
              name: "GULOD MALAYA",
            },
            {
              name: "MALANDAY",
            },
            {
              name: "MALY",
            },
            {
              name: "PINTONG BOCAWE",
            },
            {
              name: "SAN MATEO",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SILANGAN",
            },
          ],
        },
        TANAY: {
          name: "TANAY",
          baranggays: [
            {
              name: "CAYABU",
            },
            {
              name: "CUYAMBAY",
            },
            {
              name: "DARAITAN",
            },
            {
              name: "KATIPUNAN-BAYAN (POB.)",
            },
            {
              name: "KAYBUTO (POB.)",
            },
            {
              name: "LAIBAN",
            },
            {
              name: "MAG-AMPON (POB.)",
            },
            {
              name: "MAMUYAO",
            },
            {
              name: "PINAGKAMALIGAN (POB.)",
            },
            {
              name: "PLAZA ALDEA (POB.)",
            },
            {
              name: "SAMPALOC",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SANTA INEZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TABING ILOG (POB.)",
            },
            {
              name: "TANAY",
            },
            {
              name: "TANDANG KUTYO (POB.)",
            },
            {
              name: "TINUCAN",
            },
            {
              name: "WAWA (POB.)",
            },
          ],
        },
        TAYTAY: {
          name: "TAYTAY",
          baranggays: [
            {
              name: "DOLORES (POB.)",
            },
            {
              name: "MUZON",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA ANA",
            },
          ],
        },
        TERESA: {
          name: "TERESA",
          baranggays: [
            {
              name: "DALIG",
            },
            {
              name: "DULUMBAYAN",
            },
            {
              name: "MAY-IBA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PRINZA",
            },
            {
              name: "SAN GABRIEL",
            },
            {
              name: "SAN ROQUE",
            },
          ],
        },
      },
    },
    ROMBLON: {
      name: "ROMBLON",
      region: "REGION 4-B",
      municipalities: {
        ALCANTARA: {
          name: "ALCANTARA",
          baranggays: [
            {
              name: "ALCANTARA",
            },
            {
              name: "BAGSIK",
            },
            {
              name: "BONLAO",
            },
            {
              name: "CALAGONSAO",
            },
            {
              name: "CAMILI",
            },
            {
              name: "CAMOD-OM",
            },
            {
              name: "GUI-OB",
            },
            {
              name: "LAWAN",
            },
            {
              name: "MADALAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TUGDAN",
            },
          ],
        },
        BANTON: {
          name: "BANTON",
          baranggays: [
            {
              name: "BALOGO",
            },
            {
              name: "BANICE",
            },
            {
              name: "BANTON",
            },
            {
              name: "HAMBI-AN",
            },
            {
              name: "LAGANG",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "MAINIT",
            },
            {
              name: "NABALAY",
            },
            {
              name: "NASUNOGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SIBAY",
            },
            {
              name: "TAN-AG",
            },
            {
              name: "TOCTOC",
            },
            {
              name: "TOGBONGAN",
            },
            {
              name: "TOGONG",
            },
            {
              name: "TUMALUM",
            },
            {
              name: "TUNGONAN",
            },
            {
              name: "YABAWON",
            },
          ],
        },
        CAJIDIOCAN: {
          name: "CAJIDIOCAN",
          baranggays: [
            {
              name: "ALIBAGON",
            },
            {
              name: "CAJIDIOCAN",
            },
            {
              name: "CAMBAJAO",
            },
            {
              name: "CAMBALO",
            },
            {
              name: "CAMBIJANG",
            },
            {
              name: "CANTAGDA",
            },
            {
              name: "DANAO",
            },
            {
              name: "GUTIVAN",
            },
            {
              name: "LICO",
            },
            {
              name: "LUMBANG ESTE",
            },
            {
              name: "LUMBANG WESTE",
            },
            {
              name: "MARIGONDON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TAGUILOS",
            },
          ],
        },
        CALATRAVA: {
          name: "CALATRAVA",
          baranggays: [
            {
              name: "BALOGO",
            },
            {
              name: "CALATRAVA",
            },
            {
              name: "LINAO",
            },
            {
              name: "PAGSANGAHAN",
            },
            {
              name: "PANGULO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TALISAY",
            },
          ],
        },
        CONCEPCION: {
          name: "CONCEPCION",
          baranggays: [
            {
              name: "BACHAWAN",
            },
            {
              name: "CALABASAHAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DALAJICAN",
            },
            {
              name: "MASADYA",
            },
            {
              name: "MASUDSUD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAMPONG",
            },
            {
              name: "SAN PEDRO (AGBATANG)",
            },
            {
              name: "SAN VICENTE",
            },
          ],
        },
        CORCUERA: {
          name: "CORCUERA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "AMBULONG",
            },
            {
              name: "COLONGCOLONG",
            },
            {
              name: "CORCUERA",
            },
            {
              name: "GOBON",
            },
            {
              name: "GUINTIGUIBAN",
            },
            {
              name: "ILIJAN",
            },
            {
              name: "LABNIG",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAHABA",
            },
            {
              name: "MANGANSAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TACASAN",
            },
          ],
        },
        FERROL: {
          name: "FERROL",
          baranggays: [
            {
              name: "AGNONOC",
            },
            {
              name: "BUNSORAN",
            },
            {
              name: "CLARO M. RECTO",
            },
            {
              name: "FERROL",
            },
            {
              name: "HINAGUMAN",
            },
            {
              name: "POBLACION (FERROL)",
            },
            {
              name: "TUBIGON",
            },
          ],
        },
        LOOC: {
          name: "LOOC",
          baranggays: [
            {
              name: "AGOJO",
            },
            {
              name: "BALATUCAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CAMANDAO",
            },
            {
              name: "GUINHAYAAN",
            },
            {
              name: "LIMON NORTE",
            },
            {
              name: "LIMON SUR",
            },
            {
              name: "LOOC",
            },
            {
              name: "MANHAC",
            },
            {
              name: "PILI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNTA",
            },
            {
              name: "TUGUIS",
            },
          ],
        },
        MAGDIWANG: {
          name: "MAGDIWANG",
          baranggays: [
            {
              name: "AGSAO",
            },
            {
              name: "AGUTAY",
            },
            {
              name: "AMBULONG",
            },
            {
              name: "DULANGAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "JAO-ASAN",
            },
            {
              name: "MAGDIWANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SILUM",
            },
            {
              name: "TAMPAYAN",
            },
          ],
        },
        ODIONGAN: {
          name: "ODIONGAN",
          baranggays: [
            {
              name: "AMATONG",
            },
            {
              name: "ANAHAO",
            },
            {
              name: "BANGON",
            },
            {
              name: "BATIANO",
            },
            {
              name: "BUDIONG",
            },
            {
              name: "CANDUYONG",
            },
            {
              name: "DAPAWAN",
            },
            {
              name: "GABAWAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIGAYA (POB.)",
            },
            {
              name: "LIWANAG (POB.)",
            },
            {
              name: "LIWAYWAY (POB.)",
            },
            {
              name: "MALILICO",
            },
            {
              name: "MAYHA",
            },
            {
              name: "ODIONGAN",
            },
            {
              name: "PANIQUE",
            },
            {
              name: "PATO-O",
            },
            {
              name: "POCTOY",
            },
            {
              name: "PROGRESO ESTE",
            },
            {
              name: "PROGRESO WESTE",
            },
            {
              name: "RIZAL",
            },
            {
              name: "TABING DAGAT (POB.)",
            },
            {
              name: "TABOBO-AN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "TULAY",
            },
            {
              name: "TUMINGAD",
            },
          ],
        },
        ROMBLON: {
          name: "ROMBLON",
          baranggays: [
            {
              name: "AGBALUTO",
            },
            {
              name: "AGBUDIA",
            },
            {
              name: "AGNAGA",
            },
            {
              name: "AGNAY",
            },
            {
              name: "AGNIPA",
            },
            {
              name: "AGPANABAT",
            },
            {
              name: "AGTONGO",
            },
            {
              name: "ALAD",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BARANGAY III (POB.)",
            },
            {
              name: "BARANGAY IV (POB.)",
            },
            {
              name: "CAJIMOS",
            },
            {
              name: "CALABOGO",
            },
            {
              name: "CAPACLAN",
            },
            {
              name: "COBRADOR (NAGUSO)",
            },
            {
              name: "GINABLAN",
            },
            {
              name: "GUIMPINGAN",
            },
            {
              name: "ILAURAN",
            },
            {
              name: "LAMAO",
            },
            {
              name: "LI-O",
            },
            {
              name: "LOGBON",
            },
            {
              name: "LONOS",
            },
            {
              name: "LUNAS",
            },
            {
              name: "MACALAS",
            },
            {
              name: "MAPULA",
            },
            {
              name: "PALJE",
            },
            {
              name: "ROMBLON",
            },
            {
              name: "SABLAYAN",
            },
            {
              name: "SAWANG",
            },
            {
              name: "TAMBAC",
            },
          ],
        },
        "SAN AGUSTIN": {
          name: "SAN AGUSTIN",
          baranggays: [
            {
              name: "BACHAWAN",
            },
            {
              name: "BINONGAHAN",
            },
            {
              name: "BINUGUSAN",
            },
            {
              name: "BULI",
            },
            {
              name: "CABOLUTAN",
            },
            {
              name: "CAGBUAYA",
            },
            {
              name: "CAMANTAYA",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "DOÑA JUANA",
            },
            {
              name: "DUBDUBAN",
            },
            {
              name: "LUSONG",
            },
            {
              name: "MAHABANG BAYBAY (LONG BEACH)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SUGOD",
            },
          ],
        },
        "SAN ANDRES": {
          name: "SAN ANDRES",
          baranggays: [
            {
              name: "AGPUDLOS",
            },
            {
              name: "CALUNACON",
            },
            {
              name: "DOÑA TRINIDAD",
            },
            {
              name: "JUNCARLO",
            },
            {
              name: "LINAWAN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MARIGONDON NORTE",
            },
            {
              name: "MARIGONDON SUR",
            },
            {
              name: "MATUTUNA",
            },
            {
              name: "PAG-ALAD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "TAN-AGAN",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        "SAN FERNANDO": {
          name: "SAN FERNANDO",
          baranggays: [
            {
              name: "AGTIWA",
            },
            {
              name: "AZARGA",
            },
            {
              name: "CAMPALINGO",
            },
            {
              name: "CANJALON",
            },
            {
              name: "ESPAÑA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABULO",
            },
            {
              name: "OTOD",
            },
            {
              name: "PANANGCALAN",
            },
            {
              name: "PILI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "TACLOBO",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "BUSAY",
            },
            {
              name: "COMBOT",
            },
            {
              name: "LANAS",
            },
            {
              name: "PINAMIHAGAN",
            },
            {
              name: "POBLACION (AGCOGON)",
            },
            {
              name: "SAN JOSE",
            },
          ],
        },
        "SANTA FE": {
          name: "SANTA FE",
          baranggays: [
            {
              name: "AGMANIC",
            },
            {
              name: "CANYAYO",
            },
            {
              name: "DANAO NORTE",
            },
            {
              name: "DANAO SUR",
            },
            {
              name: "GUINBIRAYAN",
            },
            {
              name: "GUINTIGBASAN",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAT-I",
            },
            {
              name: "PANDAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "TABUGON",
            },
          ],
        },
        "SANTA MARIA (IMELDA)": {
          name: "SANTA MARIA (IMELDA)",
          baranggays: [
            {
              name: "BONGA",
            },
            {
              name: "CONCEPCION NORTE (POB.)",
            },
            {
              name: "CONCEPCION SUR",
            },
            {
              name: "PAROYHOG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA MARIA (IMELDA)",
            },
            {
              name: "SANTO NIÑO",
            },
          ],
        },
      },
    },
    "SAMAR (WESTERN SAMAR)": {
      name: "SAMAR (WESTERN SAMAR)",
      region: "REGION 8",
      municipalities: {
        ALMAGRO: {
          name: "ALMAGRO",
          baranggays: [
            {
              name: "ALMAGRO",
            },
            {
              name: "BACJAO",
            },
            {
              name: "BIASONG I",
            },
            {
              name: "BIASONG II",
            },
            {
              name: "COSTA RICA",
            },
            {
              name: "COSTA RICA II",
            },
            {
              name: "GUIN-ANSAN",
            },
            {
              name: "IMELDA (BADJANG)",
            },
            {
              name: "KERIKITE",
            },
            {
              name: "LUNANG I (LOOK)",
            },
            {
              name: "LUNANG II",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MARASBARAS",
            },
            {
              name: "PANJOBJOBAN I",
            },
            {
              name: "PANJOBJOBAN II",
            },
            {
              name: "POBLACION",
            },
            {
              name: "ROÑO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TALAHID",
            },
            {
              name: "TONGA-TONGA",
            },
            {
              name: "VELOSO",
            },
          ],
        },
        BASEY: {
          name: "BASEY",
          baranggays: [
            {
              name: "AMANDAYEHAN",
            },
            {
              name: "ANGLIT",
            },
            {
              name: "BACUBAC",
            },
            {
              name: "BALANTE",
            },
            {
              name: "BALOOG",
            },
            {
              name: "BASEY",
            },
            {
              name: "BASIAO",
            },
            {
              name: "BAYBAY (POB.)",
            },
            {
              name: "BINONGTU-AN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULAO",
            },
            {
              name: "BURGOS",
            },
            {
              name: "BUSCADA (POB.)",
            },
            {
              name: "CAMBAYAN",
            },
            {
              name: "CAN-ABAY",
            },
            {
              name: "CANCAIYAS",
            },
            {
              name: "CANMANILA",
            },
            {
              name: "CATADMAN",
            },
            {
              name: "COGON",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DOLONGAN",
            },
            {
              name: "GUINTIGUI-AN",
            },
            {
              name: "GUIRANG",
            },
            {
              name: "IBA",
            },
            {
              name: "INUNTAN",
            },
            {
              name: "LAWA-AN (POB.)",
            },
            {
              name: "LOOG",
            },
            {
              name: "LOYO (POB.)",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "MANLILINAB",
            },
            {
              name: "MAY-IT",
            },
            {
              name: "MERCADO (POB.)",
            },
            {
              name: "MONGABONG",
            },
            {
              name: "NEW SAN AGUSTIN",
            },
            {
              name: "NOUVELAS OCCIDENTAL",
            },
            {
              name: "OLD SAN AGUSTIN",
            },
            {
              name: "PALAYPAY (POB.)",
            },
            {
              name: "PANUGMONON",
            },
            {
              name: "PELIT",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FERNANDO (NOUVELAS ORIENTAL)",
            },
            {
              name: "SAWA",
            },
            {
              name: "SERUM",
            },
            {
              name: "SUGCA",
            },
            {
              name: "SUGPONON",
            },
            {
              name: "SULOD (POB.)",
            },
            {
              name: "TINAOGAN",
            },
            {
              name: "TINGIB",
            },
            {
              name: "VILLA AURORA",
            },
          ],
        },
        "CALBAYOG CITY": {
          name: "CALBAYOG CITY",
          baranggays: [
            {
              name: "ACEDILLO",
            },
            {
              name: "AGUIT-ITAN (POB.)",
            },
            {
              name: "AMAMPACANG",
            },
            {
              name: "ANISLAG",
            },
            {
              name: "AWANG EAST (POB.)",
            },
            {
              name: "AWANG WEST (POB.)",
            },
            {
              name: "BA-AY",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAJA",
            },
            {
              name: "BALUD (POB.)",
            },
            {
              name: "BANTE",
            },
            {
              name: "BANTIAN",
            },
            {
              name: "BASUD",
            },
            {
              name: "BAYO",
            },
            {
              name: "BEGAHO",
            },
            {
              name: "BINALIW",
            },
            {
              name: "BONTAY",
            },
            {
              name: "BUGTONG",
            },
            {
              name: "CABICAHAN",
            },
            {
              name: "CACARANSAN",
            },
            {
              name: "CAG-ANAHAW",
            },
            {
              name: "CAG-ANIBONG",
            },
            {
              name: "CAG-OLANGO",
            },
            {
              name: "CAGBANAYACAO",
            },
            {
              name: "CAGBAYANG",
            },
            {
              name: "CAGBILWANG",
            },
            {
              name: "CAGBOBORAC",
            },
            {
              name: "CAGLANIPAO SUR",
            },
            {
              name: "CAGMANIPES NORTE",
            },
            {
              name: "CAGMANIPES SUR",
            },
            {
              name: "CAGNIPA",
            },
            {
              name: "CAGSALAOSAO",
            },
            {
              name: "CAHUMPAN",
            },
            {
              name: "CALBAYOG CITY",
            },
            {
              name: "CALBAYOG CITY",
            },
            {
              name: "CALOCNAYAN",
            },
            {
              name: "CANGOMAOD",
            },
            {
              name: "CANHUMADAC",
            },
            {
              name: "CAPOOCAN",
            },
            {
              name: "CARAYMAN",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CATABUNAN",
            },
            {
              name: "CAYBAGO",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "COGON",
            },
            {
              name: "DAGUM",
            },
            {
              name: "DANAO I",
            },
            {
              name: "DANAO II",
            },
            {
              name: "DAWO",
            },
            {
              name: "DE VICTORIA",
            },
            {
              name: "DINABONGAN",
            },
            {
              name: "DINAGAN",
            },
            {
              name: "DINAWACAN",
            },
            {
              name: "GABAY",
            },
            {
              name: "GADGARAN",
            },
            {
              name: "GASDO",
            },
            {
              name: "GERAGA-AN",
            },
            {
              name: "GUIMBAOYAN NORTE",
            },
            {
              name: "GUIMBAOYAN SUR",
            },
            {
              name: "GUIN-ON",
            },
            {
              name: "HAMORAWON",
            },
            {
              name: "HELINO",
            },
            {
              name: "HIBABNGAN",
            },
            {
              name: "HIBATANG",
            },
            {
              name: "HIGASAAN",
            },
            {
              name: "HIMALANDROG",
            },
            {
              name: "HUGON ROSALES",
            },
            {
              name: "JACINTO",
            },
            {
              name: "JIMAUTAN",
            },
            {
              name: "JOSE A. ROÑO",
            },
            {
              name: "KALILIHAN",
            },
            {
              name: "KILIKILI",
            },
            {
              name: "LANGOYON",
            },
            {
              name: "LAPAAN",
            },
            {
              name: "LIMARAYON",
            },
            {
              name: "LONGSOB",
            },
            {
              name: "LONOY",
            },
            {
              name: "MABINI I (CALBAYOG DISTRICT)",
            },
            {
              name: "MABINI II (OQUENDO DISTRICT)",
            },
            {
              name: "MACATINGOG",
            },
            {
              name: "MAG-UBAY",
            },
            {
              name: "MAGUINO-O",
            },
            {
              name: "MALAGA",
            },
            {
              name: "MALAJOG",
            },
            {
              name: "MALAYOG",
            },
            {
              name: "MALOPALO",
            },
            {
              name: "MANCOL",
            },
            {
              name: "MANTAONG (OQUENDO DISTRICT)",
            },
            {
              name: "MANUEL BARRAL, SR.",
            },
            {
              name: "MARCATUBIG",
            },
            {
              name: "MATOBATO",
            },
            {
              name: "MAWACAT",
            },
            {
              name: "MAYSALONG",
            },
            {
              name: "MIGARA",
            },
            {
              name: "NABANG",
            },
            {
              name: "NAGA",
            },
            {
              name: "NAGUMA",
            },
            {
              name: "NAVARRO",
            },
            {
              name: "NIJAGA",
            },
            {
              name: "OBOOB",
            },
            {
              name: "OBRERO",
            },
            {
              name: "OLERA",
            },
            {
              name: "OQUENDO (POB.)",
            },
            {
              name: "PAGBALICAN",
            },
            {
              name: "PALANAS",
            },
            {
              name: "PALANOGAN",
            },
            {
              name: "PANLAYAHAN",
            },
            {
              name: "PANONONGAN",
            },
            {
              name: "PANOYPOY",
            },
            {
              name: "PAYAHAN",
            },
            {
              name: "PEÑA",
            },
            {
              name: "PINAMOROTAN",
            },
            {
              name: "RAWIS",
            },
            {
              name: "RIZAL I (CALBAYOG DISTRICT)",
            },
            {
              name: "RIZAL II (OQUENDO DISTRICT)",
            },
            {
              name: "ROXAS I (CALBAYOG DISTRICT)",
            },
            {
              name: "ROXAS II (OQUENDO DISTRICT)",
            },
            {
              name: "SALJAG (BAUT)",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN POLICARPIO",
            },
            {
              name: "SAN RUFINO",
            },
            {
              name: "SAPUTAN",
            },
            {
              name: "SIGO",
            },
            {
              name: "SINANTAN",
            },
            {
              name: "SINIDMAN OCCIDENTAL",
            },
            {
              name: "SINIDMAN ORIENTAL",
            },
            {
              name: "TABAWAN",
            },
            {
              name: "TALAHIBAN",
            },
            {
              name: "TANVAL",
            },
            {
              name: "TAPA-E",
            },
            {
              name: "TARABUCAN",
            },
            {
              name: "TIGBE",
            },
            {
              name: "TINAMBACAN NORTE",
            },
            {
              name: "TINAPLACAN",
            },
            {
              name: "TOMALIGUEZ",
            },
            {
              name: "TRINIDAD (SABANG)",
            },
          ],
        },
        CALBIGA: {
          name: "CALBIGA",
          baranggays: [
            {
              name: "ANTOL",
            },
            {
              name: "BACYARAN",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BAROBAYBAY",
            },
            {
              name: "BERI",
            },
            {
              name: "BINANGGARAN",
            },
            {
              name: "BORONG",
            },
            {
              name: "BULAO",
            },
            {
              name: "BULUAN",
            },
            {
              name: "CAAMLONGAN",
            },
            {
              name: "CALAYAAN",
            },
            {
              name: "CALBIGA",
            },
            {
              name: "CALINGONAN",
            },
            {
              name: "CANBAGTIC",
            },
            {
              name: "CANTICUM",
            },
            {
              name: "DALIGAN",
            },
            {
              name: "GUINBANGA",
            },
            {
              name: "HINDANG",
            },
            {
              name: "HUBASAN",
            },
            {
              name: "LITERON",
            },
            {
              name: "LUBANG",
            },
            {
              name: "MACAALAN",
            },
            {
              name: "MAHANGCAO",
            },
            {
              name: "MALABAL",
            },
            {
              name: "MINATA",
            },
            {
              name: "OTOC",
            },
            {
              name: "PANAYURAN",
            },
            {
              name: "PASIGAY",
            },
            {
              name: "PATONG",
            },
            {
              name: "POLANGI",
            },
            {
              name: "RAWIS",
            },
            {
              name: "SAN IGNACIO",
            },
            {
              name: "SAN MAURICIO",
            },
            {
              name: "SINALANGTAN",
            },
            {
              name: "TIMBANGAN",
            },
            {
              name: "TINAGO",
            },
          ],
        },
        "CATBALOGAN CITY": {
          name: "CATBALOGAN CITY",
          baranggays: [
            {
              name: "ALBALATE",
            },
            {
              name: "BAGONGON",
            },
            {
              name: "BANGON",
            },
            {
              name: "BASIAO",
            },
            {
              name: "BULUAN",
            },
            {
              name: "BUNUANAN",
            },
            {
              name: "CABUGAWAN",
            },
            {
              name: "CAGUDALO",
            },
            {
              name: "CAGUSIPAN",
            },
            {
              name: "CAGUTIAN",
            },
            {
              name: "CAGUTSAN",
            },
            {
              name: "CANHAWAN GOTE",
            },
            {
              name: "CANLAPWAS (POB.)",
            },
            {
              name: "CATBALOGAN CITY",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CINCO",
            },
            {
              name: "DARAHUWAY DACO",
            },
            {
              name: "DARAHUWAY GOTE",
            },
            {
              name: "ESTAKA",
            },
            {
              name: "GUINDAPONAN",
            },
            {
              name: "GUINSORONGAN",
            },
            {
              name: "IBOL",
            },
            {
              name: "IGUID",
            },
            {
              name: "LAGUNDI",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LOBO",
            },
            {
              name: "MANGUEHAY",
            },
            {
              name: "MAULONG",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "MOMBON",
            },
            {
              name: "MUÑOZ (POBLACION 14)",
            },
            {
              name: "NEW MAHAYAG",
            },
            {
              name: "OLD MAHAYAG",
            },
            {
              name: "PALANYOGON",
            },
            {
              name: "PANGDAN",
            },
            {
              name: "PAYAO",
            },
            {
              name: "POBLACION 1 (BARANGAY 1)",
            },
            {
              name: "POBLACION 10 (BARANGAY 10)",
            },
            {
              name: "POBLACION 11 (BARANGAY 11)",
            },
            {
              name: "POBLACION 12 (BARANGAY 12)",
            },
            {
              name: "POBLACION 13 (BARANGAY 13)",
            },
            {
              name: "POBLACION 2 (BARANGAY 2)",
            },
            {
              name: "POBLACION 3 (BARANGAY 3)",
            },
            {
              name: "POBLACION 4 (BARANGAY 4)",
            },
            {
              name: "POBLACION 5 (BARANGAY 5)",
            },
            {
              name: "POBLACION 6 (BARANGAY 6)",
            },
            {
              name: "POBLACION 7 (BARANGAY 7)",
            },
            {
              name: "POBLACION 8 (BARANGAY 8)",
            },
            {
              name: "POBLACION 9 (BARANGAY 9)",
            },
            {
              name: "PUPUA",
            },
            {
              name: "RAMA",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SILANGA",
            },
            {
              name: "SOCORRO",
            },
            {
              name: "TOTORINGON",
            },
          ],
        },
        DARAM: {
          name: "DARAM",
          baranggays: [
            {
              name: "ARAWANE",
            },
            {
              name: "ASTORGA",
            },
            {
              name: "BACHAO",
            },
            {
              name: "BACLAYAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BAYOG",
            },
            {
              name: "BETAUG",
            },
            {
              name: "BIRAWAN",
            },
            {
              name: "BONO-ANON",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CABAC",
            },
            {
              name: "CABIL-ISAN",
            },
            {
              name: "CABITON-AN",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAGBOBOTO",
            },
            {
              name: "CALAWAN-AN",
            },
            {
              name: "CAMBUHAY",
            },
            {
              name: "CAMPELIPA",
            },
            {
              name: "CANDUGUE",
            },
            {
              name: "CANLOLOY",
            },
            {
              name: "CANSAGANAY",
            },
            {
              name: "CASAB-AHAN",
            },
            {
              name: "DARAM",
            },
            {
              name: "GUINDAPUNAN",
            },
            {
              name: "GUINTAMPILAN",
            },
            {
              name: "IQUIRAN",
            },
            {
              name: "JACOPON",
            },
            {
              name: "LOSA",
            },
            {
              name: "LUCOB-LUCOB",
            },
            {
              name: "MABINI",
            },
            {
              name: "MACALPE",
            },
            {
              name: "MANDOYUCAN",
            },
            {
              name: "MARUPANGDAN",
            },
            {
              name: "MAYABAY",
            },
            {
              name: "MONGOLBONGOL",
            },
            {
              name: "NIPA",
            },
            {
              name: "PARASAN",
            },
            {
              name: "POBLACION 1 (HILABA)",
            },
            {
              name: "POBLACION 2 (MALINGON)",
            },
            {
              name: "POBLACION 3 (CANTI-IL)",
            },
            {
              name: "PONDANG",
            },
            {
              name: "POSO",
            },
            {
              name: "REAL",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SAUGAN",
            },
            {
              name: "SO-ONG",
            },
            {
              name: "SUA",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TUGAS",
            },
            {
              name: "UBO",
            },
            {
              name: "VALLES-BELLO",
            },
            {
              name: "YANGTA",
            },
          ],
        },
        GANDARA: {
          name: "GANDARA",
          baranggays: [
            {
              name: "ADELA HEIGHTS (POB.)",
            },
            {
              name: "ARONG",
            },
            {
              name: "BALOCAWE",
            },
            {
              name: "BANGAHON",
            },
            {
              name: "BESLIG",
            },
            {
              name: "BUAO",
            },
            {
              name: "BUNYAGAN",
            },
            {
              name: "BURABOD I (POB.)",
            },
            {
              name: "BURABOD II (POB.)",
            },
            {
              name: "CALIROCAN",
            },
            {
              name: "CANHUMAWID",
            },
            {
              name: "CAPARANGASAN",
            },
            {
              name: "CARANAS",
            },
            {
              name: "CARMONA",
            },
            {
              name: "CASAB-AHAN",
            },
            {
              name: "CASANDIG",
            },
            {
              name: "CATORSE DE AGOSTO",
            },
            {
              name: "CAUGBUSAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DIAZ",
            },
            {
              name: "DUMALO-ONG (POB.)",
            },
            {
              name: "ELCANO",
            },
            {
              name: "GANDARA",
            },
            {
              name: "GERALI",
            },
            {
              name: "GEREGANAN",
            },
            {
              name: "GIABOC",
            },
            {
              name: "HAMPTON",
            },
            {
              name: "HETEBAC",
            },
            {
              name: "HIMAMALOTO",
            },
            {
              name: "HINAYAGAN",
            },
            {
              name: "HINUGACAN",
            },
            {
              name: "HIPARAYAN (KINONONGAN)",
            },
            {
              name: "JASMINEZ",
            },
            {
              name: "LUNGIB",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MACUGO",
            },
            {
              name: "MALAYOG",
            },
            {
              name: "MARCOS",
            },
            {
              name: "MINDA (POB.)",
            },
            {
              name: "NACUBE",
            },
            {
              name: "NALIHUGAN",
            },
            {
              name: "NAPALISAN",
            },
            {
              name: "NATIMONAN",
            },
            {
              name: "NGOSO",
            },
            {
              name: "PALAMBRAG",
            },
            {
              name: "PALANAS",
            },
            {
              name: "PIÑAPLATA",
            },
            {
              name: "PIZARRO",
            },
            {
              name: "POLOGON",
            },
            {
              name: "PUROG",
            },
            {
              name: "RAWIS",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAMOYAO",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ENRIQUE",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO (DAO)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PELAYO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SENIBARAN (VILLA LEONA)",
            },
            {
              name: "SIDMON",
            },
            {
              name: "TAGNAO",
            },
            {
              name: "TAMBONGAN",
            },
            {
              name: "TAWIRAN",
            },
            {
              name: "TIGBAWON",
            },
          ],
        },
        HINABANGAN: {
          name: "HINABANGAN",
          baranggays: [
            {
              name: "BAGACAY",
            },
            {
              name: "BINOBUCALAN",
            },
            {
              name: "BUCALAN",
            },
            {
              name: "CABALAGNAN",
            },
            {
              name: "CABANG",
            },
            {
              name: "CANANO",
            },
            {
              name: "CONCORD",
            },
            {
              name: "CONSOLABAO",
            },
            {
              name: "DALOSDOSON",
            },
            {
              name: "FATIMA",
            },
            {
              name: "HINABANGAN",
            },
            {
              name: "LIM-AO",
            },
            {
              name: "MALIHAO",
            },
            {
              name: "MUGDO",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "POBLACION 1 (BARANGAY 1)",
            },
            {
              name: "POBLACION 2 (BARANGAY 2)",
            },
            {
              name: "RAWIS",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "TABAY",
            },
            {
              name: "YABON",
            },
          ],
        },
        JIABONG: {
          name: "JIABONG",
          baranggays: [
            {
              name: "BARANGAY NO. 1 (POB.)",
            },
            {
              name: "BARANGAY NO. 2 (POB.)",
            },
            {
              name: "BARANGAY NO. 3 (POB.)",
            },
            {
              name: "BARANGAY NO. 4 (POB.)",
            },
            {
              name: "BARANGAY NO. 5 (POB.)",
            },
            {
              name: "BARANGAY NO. 6 (POB.)",
            },
            {
              name: "BARANGAY NO. 7 (POB.)",
            },
            {
              name: "BARANGAY NO. 8 (POB.)",
            },
            {
              name: "BAWANG",
            },
            {
              name: "BUGHO",
            },
            {
              name: "CAMAROBO-AN",
            },
            {
              name: "CANDAYAO",
            },
            {
              name: "CANTONGTONG",
            },
            {
              name: "CASAPA",
            },
            {
              name: "CATALINA",
            },
            {
              name: "CRISTINA",
            },
            {
              name: "DOGONGAN",
            },
            {
              name: "GARCIA",
            },
            {
              name: "HINAGA",
            },
            {
              name: "JIA-AN",
            },
            {
              name: "JIDANAO",
            },
            {
              name: "LULUGAYAN",
            },
            {
              name: "MACABETAS",
            },
            {
              name: "MALINO",
            },
            {
              name: "MALOBAGO (VILLALINDA)",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "NAGBAC",
            },
            {
              name: "PARINA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "TAGBAYAON",
            },
            {
              name: "VICTORY",
            },
          ],
        },
        MARABUT: {
          name: "MARABUT",
          baranggays: [
            {
              name: "AMAMBUCALE",
            },
            {
              name: "AMANTILLO",
            },
            {
              name: "BINUKYAHAN",
            },
            {
              name: "CALUWAYAN",
            },
            {
              name: "CANYOYO",
            },
            {
              name: "CATATO POB. (DIST. I)",
            },
            {
              name: "FERRERAS",
            },
            {
              name: "LEGASPI",
            },
            {
              name: "LIPATA",
            },
            {
              name: "LOGERO",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MARABUT",
            },
            {
              name: "ODOC",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "PANAN-AWAN",
            },
            {
              name: "PINALANGA",
            },
            {
              name: "PINAMITINAN (POB.)",
            },
            {
              name: "ROÑO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO NIÑO POB. (DIST. II)",
            },
            {
              name: "TAGALAG",
            },
            {
              name: "TINABANAN",
            },
            {
              name: "VELOSO",
            },
          ],
        },
        MATUGUINAO: {
          name: "MATUGUINAO",
          baranggays: [
            {
              name: "ANGYAP",
            },
            {
              name: "BAG-OTAN",
            },
            {
              name: "BARRUZ (BARANGAY NO. 1)",
            },
            {
              name: "CAMONOAN",
            },
            {
              name: "CAROLINA",
            },
            {
              name: "DEIT",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "INUBOD",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "MABULIGON POB. (BARANGAY NO. 2)",
            },
            {
              name: "MADUROTO POB. (BARANGAY NO. 3)",
            },
            {
              name: "MAHANUD",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MATUGUINAO",
            },
            {
              name: "NAGPAPACAO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE (MABUHAY)",
            },
            {
              name: "SANTA CRUZ",
            },
          ],
        },
        MOTIONG: {
          name: "MOTIONG",
          baranggays: [
            {
              name: "ANGYAP",
            },
            {
              name: "BARAYONG",
            },
            {
              name: "BAYOG",
            },
            {
              name: "BERI",
            },
            {
              name: "BONGA",
            },
            {
              name: "CALANTAWAN",
            },
            {
              name: "CALAPI",
            },
            {
              name: "CALUYAHAN",
            },
            {
              name: "CANATUAN",
            },
            {
              name: "CANDOMACOL",
            },
            {
              name: "CANVAIS",
            },
            {
              name: "CAPAYSAGAN",
            },
            {
              name: "CARANAS",
            },
            {
              name: "CAULAYANAN",
            },
            {
              name: "HINICA-AN",
            },
            {
              name: "INALAD",
            },
            {
              name: "LINONOBAN",
            },
            {
              name: "MALOBAGO",
            },
            {
              name: "MALONOY",
            },
            {
              name: "MARARANGSI",
            },
            {
              name: "MAYPANGE",
            },
            {
              name: "MOTIONG",
            },
            {
              name: "NEW MINAROG",
            },
            {
              name: "OYANDIC",
            },
            {
              name: "PAMAMASAN",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION I-A",
            },
            {
              name: "PUSONGAN",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SARAO",
            },
          ],
        },
        PAGSANGHAN: {
          name: "PAGSANGHAN",
          baranggays: [
            {
              name: "BANGON",
            },
            {
              name: "BUENOS AIRES",
            },
            {
              name: "CALANYUGAN",
            },
            {
              name: "CALOLOMA",
            },
            {
              name: "CAMBAYE",
            },
            {
              name: "CANLAPWAS (POB.)",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "PAGSANGHAN",
            },
            {
              name: "PAÑGE",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "VIEJO",
            },
            {
              name: "VILLAHERMOSA OCCIDENTAL",
            },
            {
              name: "VILLAHERMOSA ORIENTAL",
            },
          ],
        },
        "PARANAS (WRIGHT)": {
          name: "PARANAS (WRIGHT)",
          baranggays: [
            {
              name: "ANAGASI",
            },
            {
              name: "APOLONIA",
            },
            {
              name: "BAGSA",
            },
            {
              name: "BALBAGAN",
            },
            {
              name: "BATO",
            },
            {
              name: "BURAY (BINOGHO)",
            },
            {
              name: "CANTAGUIC",
            },
            {
              name: "CANTAO-AN",
            },
            {
              name: "CANTATO (CANTURAB)",
            },
            {
              name: "CASANDIG I",
            },
            {
              name: "CASANDIG II",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "JOSE ROÑO",
            },
            {
              name: "LAWAAN I",
            },
            {
              name: "LAWAAN II",
            },
            {
              name: "LIPATA",
            },
            {
              name: "LOKILOKON",
            },
            {
              name: "MANGCAL",
            },
            {
              name: "MAYLOBE",
            },
            {
              name: "MINAROG",
            },
            {
              name: "NAWI",
            },
            {
              name: "PABANOG",
            },
            {
              name: "PACO",
            },
            {
              name: "PAGSA-OGAN",
            },
            {
              name: "PAGSANJAN (PAGSAN-AN)",
            },
            {
              name: "PARANAS (WRIGHT)",
            },
            {
              name: "PATAG",
            },
            {
              name: "PEQUIT",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "POBLACION 4",
            },
            {
              name: "POBLACION 5",
            },
            {
              name: "POBLACION 6",
            },
            {
              name: "SALAY",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SULOPAN",
            },
            {
              name: "TABUCAN",
            },
            {
              name: "TAPUL",
            },
            {
              name: "TENANI",
            },
            {
              name: "TIGBAWON",
            },
            {
              name: "TULA",
            },
            {
              name: "TUTUBIGAN",
            },
          ],
        },
        PINABACDAO: {
          name: "PINABACDAO",
          baranggays: [
            {
              name: "BANGON",
            },
            {
              name: "BARANGAY I (POB.)",
            },
            {
              name: "BARANGAY II (POB.)",
            },
            {
              name: "BOTOC",
            },
            {
              name: "BUGHO",
            },
            {
              name: "CALAMPONG",
            },
            {
              name: "CANLOBO",
            },
            {
              name: "CATIGAWAN",
            },
            {
              name: "DOLORES",
            },
            {
              name: "LALE",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "LAYGAYON",
            },
            {
              name: "LAYO",
            },
            {
              name: "LOCTOB",
            },
            {
              name: "MADALUNOT",
            },
            {
              name: "MAGDAWAT",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "MANAING",
            },
            {
              name: "NABONG",
            },
            {
              name: "OBAYAN",
            },
            {
              name: "PAHUG",
            },
            {
              name: "PARASANON",
            },
            {
              name: "PELAON",
            },
            {
              name: "PINABACDAO",
            },
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        "SAN JORGE": {
          name: "SAN JORGE",
          baranggays: [
            {
              name: "ANQUIANA (ANGKIANA)",
            },
            {
              name: "AURORA",
            },
            {
              name: "BAY-ANG",
            },
            {
              name: "BLANCA AURORA",
            },
            {
              name: "BUENAVISTA I",
            },
            {
              name: "BUENAVISTA II",
            },
            {
              name: "BULAO",
            },
            {
              name: "BUNGLIW",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAG-OLO-OLO",
            },
            {
              name: "CALUNDAN",
            },
            {
              name: "CANTAGUIC",
            },
            {
              name: "CANYAKI",
            },
            {
              name: "COGTOTO-OG",
            },
            {
              name: "ERENAS",
            },
            {
              name: "GAYONDATO",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "GUINDAPUNAN",
            },
            {
              name: "HERNANDEZ",
            },
            {
              name: "HIMAY",
            },
            {
              name: "JANIPON",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LINCORO",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MANCOL (POB.)",
            },
            {
              name: "MATALUD",
            },
            {
              name: "MOBO-OB",
            },
            {
              name: "MOMBON",
            },
            {
              name: "PUHAGAN",
            },
            {
              name: "QUEZON",
            },
            {
              name: "RANERA",
            },
            {
              name: "RAWIS",
            },
            {
              name: "ROSALIM",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JORGE",
            },
            {
              name: "SAN JORGE I (POB.)",
            },
            {
              name: "SAN JORGE II (POB.)",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAPINIT",
            },
            {
              name: "SINIT-AN",
            },
            {
              name: "TOMOGBONG",
            },
          ],
        },
        "SAN JOSE DE BUAN": {
          name: "SAN JOSE DE BUAN",
          baranggays: [
            {
              name: "AGUINGAYAN",
            },
            {
              name: "BABACLAYON",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "CAN-APONTE",
            },
            {
              name: "CATAYDONGAN",
            },
            {
              name: "GUSA",
            },
            {
              name: "HAGBAY",
            },
            {
              name: "HIBACA-AN",
            },
            {
              name: "HIDUROMA",
            },
            {
              name: "HILUMOT",
            },
            {
              name: "SAN JOSE DE BUAN",
            },
            {
              name: "SAN NICOLAS",
            },
          ],
        },
        "SAN SEBASTIAN": {
          name: "SAN SEBASTIAN",
          baranggays: [
            {
              name: "BALOGO",
            },
            {
              name: "BONTOD",
            },
            {
              name: "CABAYWA",
            },
            {
              name: "CAMANHAGAY",
            },
            {
              name: "CAMPIYAK",
            },
            {
              name: "CANDUYUCAN",
            },
            {
              name: "DOLORES",
            },
            {
              name: "HITA-ASAN I",
            },
            {
              name: "HITA-ASAN II",
            },
            {
              name: "INOBONGAN",
            },
            {
              name: "POBLACION BARANGAY 1",
            },
            {
              name: "POBLACION BARANGAY 2",
            },
            {
              name: "POBLACION BARANGAY 3",
            },
            {
              name: "POBLACION BARANGAY 4",
            },
            {
              name: "SAN SEBASTIAN",
            },
          ],
        },
        "SANTA MARGARITA": {
          name: "SANTA MARGARITA",
          baranggays: [
            {
              name: "AGRUPACION",
            },
            {
              name: "ARAPISON",
            },
            {
              name: "AVELINO",
            },
            {
              name: "BALUD",
            },
            {
              name: "BANA-AO",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CAGSUMJI",
            },
            {
              name: "CAMPEIG",
            },
            {
              name: "CAMPERITO",
            },
            {
              name: "CAN-IPULAN",
            },
            {
              name: "CANMOROS",
            },
            {
              name: "CAUTOD (POB.)",
            },
            {
              name: "GAJO",
            },
            {
              name: "ILO",
            },
            {
              name: "INORAGUIAO",
            },
            {
              name: "JOLACAO",
            },
            {
              name: "LAMBAO",
            },
            {
              name: "MATAYONAS",
            },
            {
              name: "MONBON (POB.)",
            },
            {
              name: "NAPURO",
            },
            {
              name: "NAPURO II",
            },
            {
              name: "PALALE",
            },
            {
              name: "PANABATAN",
            },
            {
              name: "PANARUAN",
            },
            {
              name: "SANTA MARGARITA",
            },
            {
              name: "SOLSOGON",
            },
            {
              name: "SUNDARA",
            },
          ],
        },
        "SANTA RITA": {
          name: "SANTA RITA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "ANIBONGAN",
            },
            {
              name: "ASLUM",
            },
            {
              name: "BAGOLIBAS",
            },
            {
              name: "BINANALAN",
            },
            {
              name: "BOKINGGAN POB. (ZONE I)",
            },
            {
              name: "BOUGAINVILLA POB. (ZONE II)",
            },
            {
              name: "CABACUNGAN",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CAMAYSE",
            },
            {
              name: "CANSADONG",
            },
            {
              name: "CATICUGAN",
            },
            {
              name: "DAMPIGAN",
            },
            {
              name: "GUINBALOT-AN",
            },
            {
              name: "GUMAMELA POB. (ZONE III)",
            },
            {
              name: "HINANGUDTAN",
            },
            {
              name: "IGANG-IGANG",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LUPIG",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "NEW MANUNCA",
            },
            {
              name: "OLD MANUNCA",
            },
            {
              name: "PAGSULHOGON",
            },
            {
              name: "ROSAL POB. (ZONE IV)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN EDUARDO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PASCUAL (CROSSING)",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTAN POB. (ZONE V)",
            },
            {
              name: "TAGACAY",
            },
            {
              name: "TOMINAMOS",
            },
            {
              name: "TULAY",
            },
            {
              name: "UNION",
            },
          ],
        },
        "SANTO NIÑO": {
          name: "SANTO NIÑO",
          baranggays: [
            {
              name: "BALATGUTI",
            },
            {
              name: "BARAS",
            },
            {
              name: "BASUD (POB.)",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "COROCAWAYAN",
            },
            {
              name: "ILIJAN",
            },
            {
              name: "ILO (POB.)",
            },
            {
              name: "LOBELOBE",
            },
            {
              name: "PINANANGNAN",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SEVILLA",
            },
            {
              name: "TAKUT",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        "TAGAPUL-AN": {
          name: "TAGAPUL-AN",
          baranggays: [
            {
              name: "BAGUIW",
            },
            {
              name: "BALOCAWE",
            },
            {
              name: "GUINBARUCAN",
            },
            {
              name: "LABANGBAYBAY",
            },
            {
              name: "LUNA",
            },
            {
              name: "MATALUTO",
            },
            {
              name: "NIPA",
            },
            {
              name: "PANTALAN",
            },
            {
              name: "PULANGBATO",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SUAREZ (MANLANGIT)",
            },
            {
              name: "SUGOD (POB.)",
            },
            {
              name: "TAGAPUL-AN",
            },
            {
              name: "TRINIDAD",
            },
          ],
        },
        TALALORA: {
          name: "TALALORA",
          baranggays: [
            {
              name: "BO. INDEPENDENCIA",
            },
            {
              name: "MALAGUINING",
            },
            {
              name: "MALLORGA",
            },
            {
              name: "NAVATAS DAKU",
            },
            {
              name: "NAVATAS GUTI",
            },
            {
              name: "PLACER",
            },
            {
              name: "POBLACION BARANGAY 1",
            },
            {
              name: "POBLACION BARANGAY 2",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "TALALORA",
            },
            {
              name: "TATABUNAN",
            },
            {
              name: "VICTORY",
            },
          ],
        },
        TARANGNAN: {
          name: "TARANGNAN",
          baranggays: [
            {
              name: "ALCAZAR",
            },
            {
              name: "AWANG",
            },
            {
              name: "BAHAY",
            },
            {
              name: "BALONGA-AS",
            },
            {
              name: "BALUGO",
            },
            {
              name: "BANGON GOTE",
            },
            {
              name: "BARAS",
            },
            {
              name: "BINALAYAN",
            },
            {
              name: "BISITAHAN",
            },
            {
              name: "BONGA",
            },
            {
              name: "CABUNGA-AN",
            },
            {
              name: "CAGTUTULO",
            },
            {
              name: "CAMBATUTAY NUEVO",
            },
            {
              name: "CAMBATUTAY VIEJO",
            },
            {
              name: "CANUNGHAN",
            },
            {
              name: "CATAN-AGAN",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "GALLEGO",
            },
            {
              name: "IMELDA POB. (POSGO)",
            },
            {
              name: "LAHONG",
            },
            {
              name: "LIBUCAN DACU",
            },
            {
              name: "LIBUCAN GOTE",
            },
            {
              name: "LUCERDONI (IRONG-IRONG)",
            },
            {
              name: "MAJACOB",
            },
            {
              name: "MANCARES",
            },
            {
              name: "MARABUT",
            },
            {
              name: "OESTE - A",
            },
            {
              name: "OESTE - B",
            },
            {
              name: "PAJO",
            },
            {
              name: "PALENCIA",
            },
            {
              name: "POBLACION A",
            },
            {
              name: "POBLACION B",
            },
            {
              name: "POBLACION C",
            },
            {
              name: "POBLACION D",
            },
            {
              name: "POBLACION E",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TALINGA",
            },
            {
              name: "TARANGNAN",
            },
            {
              name: "TIGDARANAO",
            },
            {
              name: "TIZON",
            },
          ],
        },
        VILLAREAL: {
          name: "VILLAREAL",
          baranggays: [
            {
              name: "BANQUIL",
            },
            {
              name: "BINO-ONGAN",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CAMBAGUIO",
            },
            {
              name: "CANMUCAT",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "CONANT",
            },
            {
              name: "GUINTARCAN",
            },
            {
              name: "HIMYANGAN",
            },
            {
              name: "IGOT",
            },
            {
              name: "INARUMBACAN",
            },
            {
              name: "INASUDLAN",
            },
            {
              name: "LAM-AWAN",
            },
            {
              name: "LAMINGAO",
            },
            {
              name: "LAWA-AN",
            },
            {
              name: "MACOPA",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MALONOY",
            },
            {
              name: "MERCADO (POB.)",
            },
            {
              name: "MIRAMAR (POB.)",
            },
            {
              name: "NAGCADUHA",
            },
            {
              name: "PACAO",
            },
            {
              name: "PACOYOY",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PATAG",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "POLANGI",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN RAFAEL (BUAYA)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SOLEDAD (POB.)",
            },
            {
              name: "TAYUD (POB.)",
            },
            {
              name: "TOMABE",
            },
            {
              name: "ULAYAN",
            },
            {
              name: "VILLAREAL",
            },
            {
              name: "VILLAROSA POB. (CAMPIATOT)",
            },
          ],
        },
        ZUMARRAGA: {
          name: "ZUMARRAGA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "ARTECHE",
            },
            {
              name: "BIOSO",
            },
            {
              name: "BOBLARAN",
            },
            {
              name: "BOTAERA",
            },
            {
              name: "BUNTAY",
            },
            {
              name: "CAMAYSE",
            },
            {
              name: "CANWARAK",
            },
            {
              name: "IBARRA",
            },
            {
              name: "LUMALANTANG",
            },
            {
              name: "MACALUNOD",
            },
            {
              name: "MAGA-AN",
            },
            {
              name: "MAPUTI",
            },
            {
              name: "MARAPILIT",
            },
            {
              name: "MONBON",
            },
            {
              name: "MUALBUAL",
            },
            {
              name: "PANGDAN",
            },
            {
              name: "POBLACION 1 (BARANGAY 1)",
            },
            {
              name: "POBLACION 2 (BARANGAY 2)",
            },
            {
              name: "PORO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TALIB",
            },
            {
              name: "TINAUGAN",
            },
            {
              name: "TUBIGAN",
            },
            {
              name: "ZUMARRAGA",
            },
          ],
        },
      },
    },
    SARANGANI: {
      name: "SARANGANI",
      region: "REGION 12",
      municipalities: {
        ALABEL: {
          name: "ALABEL",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BALUNTAY",
            },
            {
              name: "DATAL ANGGAS",
            },
            {
              name: "DOMOLOK",
            },
            {
              name: "KAWAS",
            },
            {
              name: "LADOL",
            },
            {
              name: "MARIBULAN",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PARAISO",
            },
            {
              name: "POBLACION (ALABEL)",
            },
            {
              name: "POBLACION (ALABEL)",
            },
            {
              name: "SPRING",
            },
            {
              name: "TOKAWAL",
            },
          ],
        },
        GLAN: {
          name: "GLAN",
          baranggays: [
            {
              name: "BALITON",
            },
            {
              name: "BATOTULING",
            },
            {
              name: "BATULAKI",
            },
            {
              name: "BIG MARGUS",
            },
            {
              name: "BURIAS",
            },
            {
              name: "CABLALAN",
            },
            {
              name: "CALABANIT",
            },
            {
              name: "CALPIDONG",
            },
            {
              name: "CONGAN",
            },
            {
              name: "CROSS",
            },
            {
              name: "DATALBUKAY",
            },
            {
              name: "E. ALEGADO",
            },
            {
              name: "GLAN PADIDU",
            },
            {
              name: "GUMASA",
            },
            {
              name: "ILAYA",
            },
            {
              name: "KALTUAD",
            },
            {
              name: "KAPATAN",
            },
            {
              name: "LAGO",
            },
            {
              name: "LAGUIMIT",
            },
            {
              name: "MUDAN",
            },
            {
              name: "NEW AKLAN",
            },
            {
              name: "PANGYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIO DEL PILAR",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SMALL MARGUS",
            },
            {
              name: "SUFATUBO",
            },
            {
              name: "TALUYA",
            },
            {
              name: "TANGO",
            },
            {
              name: "TAPON",
            },
          ],
        },
        KIAMBA: {
          name: "KIAMBA",
          baranggays: [
            {
              name: "BADTASAN",
            },
            {
              name: "DATU DANI",
            },
            {
              name: "GASI",
            },
            {
              name: "KAPATE",
            },
            {
              name: "KATUBAO",
            },
            {
              name: "KAYUPO",
            },
            {
              name: "KLING (LUMIT)",
            },
            {
              name: "LAGUNDI",
            },
            {
              name: "LEBE",
            },
            {
              name: "LOMUYON",
            },
            {
              name: "LUMA",
            },
            {
              name: "MALIGANG",
            },
            {
              name: "NALUS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAKIT",
            },
            {
              name: "SULI",
            },
            {
              name: "TABLAO",
            },
            {
              name: "TAMADANG",
            },
            {
              name: "TAMBILIL",
            },
          ],
        },
        MAASIM: {
          name: "MAASIM",
          baranggays: [
            {
              name: "AMSIPIT",
            },
            {
              name: "BALES",
            },
            {
              name: "COLON",
            },
            {
              name: "DALIAO",
            },
            {
              name: "KABATIOL",
            },
            {
              name: "KABLACAN",
            },
            {
              name: "KAMANGA",
            },
            {
              name: "KANALO",
            },
            {
              name: "LUMASAL",
            },
            {
              name: "LUMATIL",
            },
            {
              name: "MALBANG",
            },
            {
              name: "NOMOH",
            },
            {
              name: "PANANAG",
            },
            {
              name: "POBLACION (MAASIM)",
            },
            {
              name: "POBLACION (MAASIM)",
            },
            {
              name: "SEVEN HILLS",
            },
            {
              name: "TINOTO",
            },
          ],
        },
        MAITUM: {
          name: "MAITUM",
          baranggays: [
            {
              name: "BATI-AN",
            },
            {
              name: "KALANEG",
            },
            {
              name: "KALAONG",
            },
            {
              name: "KIAMBING",
            },
            {
              name: "KIAYAP",
            },
            {
              name: "MABAY",
            },
            {
              name: "MAGULING",
            },
            {
              name: "MALALAG (POB.)",
            },
            {
              name: "MINDUPOK",
            },
            {
              name: "NEW LA UNION",
            },
            {
              name: "OLD POBLACION (MAITUM)",
            },
            {
              name: "OLD POBLACION (MAITUM)",
            },
            {
              name: "PANGI (LINAO)",
            },
            {
              name: "PINOL",
            },
            {
              name: "SISON (EDENTON)",
            },
            {
              name: "TICULAB",
            },
            {
              name: "TUANADATU",
            },
            {
              name: "UPO (LANAO)",
            },
            {
              name: "WALI (KAMBUHAN)",
            },
            {
              name: "ZION",
            },
          ],
        },
        MALAPATAN: {
          name: "MALAPATAN",
          baranggays: [
            {
              name: "DAAN SUYAN",
            },
            {
              name: "KIHAN",
            },
            {
              name: "KINAM",
            },
            {
              name: "LIBI",
            },
            {
              name: "LUN MASLA",
            },
            {
              name: "LUN PADIDU",
            },
            {
              name: "PATAG",
            },
            {
              name: "POBLACION (MALAPATAN)",
            },
            {
              name: "POBLACION (MALAPATAN)",
            },
            {
              name: "SAPU MASLA",
            },
            {
              name: "SAPU PADIDU",
            },
            {
              name: "TUYAN",
            },
            {
              name: "UPPER SUYAN",
            },
          ],
        },
        MALUNGON: {
          name: "MALUNGON",
          baranggays: [
            {
              name: "ALKIKAN",
            },
            {
              name: "AMPON",
            },
            {
              name: "ATLAE",
            },
            {
              name: "B'LAAN",
            },
            {
              name: "BANAHAW",
            },
            {
              name: "BANATE",
            },
            {
              name: "DATAL BATONG",
            },
            {
              name: "DATAL BILA",
            },
            {
              name: "DATAL TAMPAL",
            },
            {
              name: "J.P. LAUREL",
            },
            {
              name: "KAWAYAN",
            },
            {
              name: "KIBALA",
            },
            {
              name: "KIBLAT",
            },
            {
              name: "KINABALAN",
            },
            {
              name: "LOWER MAINIT",
            },
            {
              name: "LUTAY",
            },
            {
              name: "MALABOD",
            },
            {
              name: "MALALAG COGON",
            },
            {
              name: "MALANDAG",
            },
            {
              name: "MALUNGON GAMAY",
            },
            {
              name: "NAGPAN",
            },
            {
              name: "PANAMIN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TALUS",
            },
            {
              name: "TAMBAN",
            },
            {
              name: "UPPER BIANGAN",
            },
            {
              name: "UPPER LUMABAT",
            },
            {
              name: "UPPER MAINIT",
            },
          ],
        },
      },
    },
    SIQUIJOR: {
      name: "SIQUIJOR",
      region: "REGION 7",
      municipalities: {
        "ENRIQUE VILLANUEVA": {
          name: "ENRIQUE VILLANUEVA",
          baranggays: [
            {
              name: "BALOLONG",
            },
            {
              name: "BINO-ONGAN",
            },
            {
              name: "BITAUG",
            },
            {
              name: "BOLOT",
            },
            {
              name: "CAMOGAO",
            },
            {
              name: "CANGMANGKI",
            },
            {
              name: "ENRIQUE VILLANUEVA",
            },
            {
              name: "LIBO",
            },
            {
              name: "LOMANGCAPAN",
            },
            {
              name: "LOTLOTON",
            },
            {
              name: "MANAN-AO",
            },
            {
              name: "OLAVE",
            },
            {
              name: "PARIAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TULAPOS",
            },
          ],
        },
        LARENA: {
          name: "LARENA",
          baranggays: [
            {
              name: "BAGACAY",
            },
            {
              name: "BALOLANG",
            },
            {
              name: "BASAC",
            },
            {
              name: "BINTANGAN",
            },
            {
              name: "BONTOD",
            },
            {
              name: "CABULIHAN",
            },
            {
              name: "CALUNASAN",
            },
            {
              name: "CANDIGUM",
            },
            {
              name: "CANG-ALLAS",
            },
            {
              name: "CANG-APA",
            },
            {
              name: "CANGBAGSA",
            },
            {
              name: "CANGMALALAG",
            },
            {
              name: "CANLAMBO",
            },
            {
              name: "CANLASOG",
            },
            {
              name: "CATAMBOAN",
            },
            {
              name: "HELEN (DATAG)",
            },
            {
              name: "LARENA",
            },
            {
              name: "NONOC",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "PONONG",
            },
            {
              name: "SABANG",
            },
            {
              name: "SANDUGAN",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TACULING",
            },
          ],
        },
        LAZI: {
          name: "LAZI",
          baranggays: [
            {
              name: "CAMPALANAS",
            },
            {
              name: "CANGCLARAN",
            },
            {
              name: "CANGOMANTONG",
            },
            {
              name: "CAPALASANAN",
            },
            {
              name: "CATAMBOAN (POB.)",
            },
            {
              name: "GABAYAN",
            },
            {
              name: "KIMBA",
            },
            {
              name: "KINAMANDAGAN",
            },
            {
              name: "LAZI",
            },
            {
              name: "LOWER CABANGCALAN",
            },
            {
              name: "NAGERONG",
            },
            {
              name: "PO-O",
            },
            {
              name: "SIMACOLONG",
            },
            {
              name: "TAGMANOCAN",
            },
            {
              name: "TALAYONG",
            },
            {
              name: "TIGBAWAN (POB.)",
            },
            {
              name: "TIGNAO",
            },
            {
              name: "UPPER CABANGCALAN",
            },
            {
              name: "YTAYA",
            },
          ],
        },
        MARIA: {
          name: "MARIA",
          baranggays: [
            {
              name: "BOGO",
            },
            {
              name: "BONGA",
            },
            {
              name: "CABAL-ASAN",
            },
            {
              name: "CALUNASAN",
            },
            {
              name: "CANDAPING A",
            },
            {
              name: "CANDAPING B",
            },
            {
              name: "CANTAROC A",
            },
            {
              name: "CANTAROC B",
            },
            {
              name: "CANTUGBAS",
            },
            {
              name: "LICO-AN",
            },
            {
              name: "LILO-AN",
            },
            {
              name: "LOGUCAN",
            },
            {
              name: "LOOC",
            },
            {
              name: "MARIA",
            },
            {
              name: "MINALULAN",
            },
            {
              name: "NABUTAY",
            },
            {
              name: "OLANG",
            },
            {
              name: "PISONG A",
            },
            {
              name: "PISONG B",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SAGUING",
            },
            {
              name: "SAWANG",
            },
          ],
        },
        "SAN JUAN": {
          name: "SAN JUAN",
          baranggays: [
            {
              name: "CANASAGAN",
            },
            {
              name: "CANDURA",
            },
            {
              name: "CANGMUNAG",
            },
            {
              name: "CANSAYANG",
            },
            {
              name: "CATULAYAN",
            },
            {
              name: "LALA-O",
            },
            {
              name: "MAITE",
            },
            {
              name: "NAPO",
            },
            {
              name: "PALITON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SOLANGON",
            },
            {
              name: "TAG-IBO",
            },
            {
              name: "TAMBISAN",
            },
            {
              name: "TIMBAON",
            },
            {
              name: "TUBOD",
            },
          ],
        },
        SIQUIJOR: {
          name: "SIQUIJOR",
          baranggays: [
            {
              name: "BANBAN",
            },
            {
              name: "BOLOS",
            },
            {
              name: "CAIPILAN",
            },
            {
              name: "CAITICAN",
            },
            {
              name: "CALALINAN",
            },
            {
              name: "CANAL",
            },
            {
              name: "CANDANAY NORTE",
            },
            {
              name: "CANDANAY SUR",
            },
            {
              name: "CANG-ADIENG",
            },
            {
              name: "CANG-AGONG",
            },
            {
              name: "CANG-ALWANG",
            },
            {
              name: "CANG-ASA",
            },
            {
              name: "CANG-ATUYOM",
            },
            {
              name: "CANG-INTE",
            },
            {
              name: "CANG-ISAD",
            },
            {
              name: "CANGHUNOGHUNOG",
            },
            {
              name: "CANGMATNOG",
            },
            {
              name: "CANGMOHAO",
            },
            {
              name: "CANTABON",
            },
            {
              name: "CATICUGAN",
            },
            {
              name: "DUMANHOG",
            },
            {
              name: "IBABAO",
            },
            {
              name: "LAMBOJON",
            },
            {
              name: "LUYANG",
            },
            {
              name: "LUZONG",
            },
            {
              name: "OLO",
            },
            {
              name: "PANGI",
            },
            {
              name: "PANLAUTAN",
            },
            {
              name: "PASIHAGON",
            },
            {
              name: "PILI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLANGYUTA",
            },
            {
              name: "PONONG",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SIQUIJOR",
            },
            {
              name: "SONGCULAN",
            },
            {
              name: "TACDOG",
            },
            {
              name: "TACLOBAN",
            },
            {
              name: "TAMBISAN",
            },
            {
              name: "TEBJONG",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TONGO",
            },
          ],
        },
      },
    },
    SORSOGON: {
      name: "SORSOGON",
      region: "REGION 5",
      municipalities: {
        BARCELONA: {
          name: "BARCELONA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BANGATE",
            },
            {
              name: "BARCELONA",
            },
            {
              name: "BUGTONG",
            },
            {
              name: "CAGANG",
            },
            {
              name: "FABRICA",
            },
            {
              name: "JIBONG",
            },
            {
              name: "LAGO",
            },
            {
              name: "LAYOG",
            },
            {
              name: "LUNETA",
            },
            {
              name: "MACABARI",
            },
            {
              name: "MAPAPAC",
            },
            {
              name: "OLANDIA",
            },
            {
              name: "PAGHALUBAN",
            },
            {
              name: "POBLACION CENTRAL",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "PUTIAO",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LOURDES",
            },
            {
              name: "TAGDON",
            },
          ],
        },
        BULAN: {
          name: "BULAN",
          baranggays: [
            {
              name: "A. BONIFACIO",
            },
            {
              name: "ABAD SANTOS",
            },
            {
              name: "AGUINALDO",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "BEGUIN",
            },
            {
              name: "BENIGNO S. AQUINO ( IMELDA)",
            },
            {
              name: "BICAL",
            },
            {
              name: "BONGA",
            },
            {
              name: "BULAN",
            },
            {
              name: "BUTAG",
            },
            {
              name: "CADANDANAN",
            },
            {
              name: "CALOMAGON",
            },
            {
              name: "CALPI",
            },
            {
              name: "COCOK-CABITAN",
            },
            {
              name: "DAGANAS",
            },
            {
              name: "DANAO",
            },
            {
              name: "DOLOS",
            },
            {
              name: "E. QUIRINO",
            },
            {
              name: "FABRICA",
            },
            {
              name: "G. DEL PILAR (TANGA)",
            },
            {
              name: "GATE",
            },
            {
              name: "INARARAN",
            },
            {
              name: "J. GERONA",
            },
            {
              name: "J.P. LAUREL (PON-OD)",
            },
            {
              name: "JAMORAWON",
            },
            {
              name: "LAJONG",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "M. ROXAS",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANAGANAGA",
            },
            {
              name: "MARINAB",
            },
            {
              name: "MONTECALVARIO",
            },
            {
              name: "N. ROQUE (RIZAL)",
            },
            {
              name: "NAMO",
            },
            {
              name: "NASUJE",
            },
            {
              name: "OBRERO",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "OTAVI",
            },
            {
              name: "PADRE DIAZ",
            },
            {
              name: "PALALE",
            },
            {
              name: "QUEZON",
            },
            {
              name: "R. GERONA",
            },
            {
              name: "RECTO",
            },
            {
              name: "SAGRADA",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN BAG-O",
            },
            {
              name: "SAN JUAN DAAN",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA REMEDIOS",
            },
            {
              name: "SANTA TERESITA",
            },
            {
              name: "SIGAD",
            },
            {
              name: "SOMAGONGSONG",
            },
            {
              name: "TAROMATA",
            },
            {
              name: "ZONE I POB. (BGY. 1- SOUTH ILAWOD)",
            },
            {
              name: "ZONE II POB. (BGY. 2- WEST ILAWOD)",
            },
            {
              name: "ZONE III POB. (BGY. 3- EAST ILAWOD)",
            },
            {
              name: "ZONE IV POB. (BGY. 4- WEST CENTRAL)",
            },
            {
              name: "ZONE V POB. (BGY. 5-LANIPAN)",
            },
            {
              name: "ZONE VI POB. (BGY. 6- BAYBAY)",
            },
            {
              name: "ZONE VII POB. (BGY. 7- IRAYA)",
            },
            {
              name: "ZONE VIII POB. (BGY. 8- LOYO)",
            },
          ],
        },
        BULUSAN: {
          name: "BULUSAN",
          baranggays: [
            {
              name: "BAGACAY",
            },
            {
              name: "BULUSAN",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "COGON",
            },
            {
              name: "DANCALAN",
            },
            {
              name: "DAPDAP (POB.)",
            },
            {
              name: "LALUD",
            },
            {
              name: "LOOBAN (POB.)",
            },
            {
              name: "MABUHAY (POB.)",
            },
            {
              name: "MADLAWON (POB.)",
            },
            {
              name: "POCTOL (POB.)",
            },
            {
              name: "POROG",
            },
            {
              name: "SABANG (POB.)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BERNARDO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE (BUHANG)",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SAPNGAN (POB.)",
            },
            {
              name: "TINAMPO",
            },
          ],
        },
        CASIGURAN: {
          name: "CASIGURAN",
          baranggays: [
            {
              name: "ADOVIS (POB.)",
            },
            {
              name: "BOTON",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CASAY",
            },
            {
              name: "CASIGURAN",
            },
            {
              name: "CAWIT",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "COGON",
            },
            {
              name: "COLAMBIS",
            },
            {
              name: "ESCUALA",
            },
            {
              name: "INLAGADIAN",
            },
            {
              name: "LUNGIB",
            },
            {
              name: "MABINI",
            },
            {
              name: "PONONG",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANTONIO (BOHO)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SOMAL-OT (POB.)",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TIMBAYOG (POB.)",
            },
            {
              name: "TIRIS",
            },
            {
              name: "TRECE MARTIREZ",
            },
            {
              name: "TULAY",
            },
          ],
        },
        CASTILLA: {
          name: "CASTILLA",
          baranggays: [
            {
              name: "AMOMONTING",
            },
            {
              name: "BAGALAYAG",
            },
            {
              name: "BAGONG SIRANG",
            },
            {
              name: "BONGA",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BURABOD",
            },
            {
              name: "CABURACAN",
            },
            {
              name: "CANJELA",
            },
            {
              name: "CASTILLA",
            },
            {
              name: "COGON",
            },
            {
              name: "CUMADCAD",
            },
            {
              name: "DANGCALAN",
            },
            {
              name: "DINAPA",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LIBTONG",
            },
            {
              name: "LORETO",
            },
            {
              name: "MACALAYA",
            },
            {
              name: "MARACABAC",
            },
            {
              name: "MAYON",
            },
            {
              name: "MAYPANGI",
            },
            {
              name: "MILAGROSA",
            },
            {
              name: "MILUYA",
            },
            {
              name: "MONTE CARMELO",
            },
            {
              name: "ORAS",
            },
            {
              name: "PANDAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUIRAPI",
            },
            {
              name: "SACLAYAN",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SOGOY",
            },
            {
              name: "TOMALAYTAY",
            },
          ],
        },
        DONSOL: {
          name: "DONSOL",
          baranggays: [
            {
              name: "ALIN",
            },
            {
              name: "AWAI",
            },
            {
              name: "BANBAN",
            },
            {
              name: "BANDI",
            },
            {
              name: "BANUANG GURANG",
            },
            {
              name: "BARAS",
            },
            {
              name: "BAYAWAS",
            },
            {
              name: "BORORAN BARANGAY 1 (POB.)",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CENTRAL BARANGAY 2 (POB.)",
            },
            {
              name: "CRISTO",
            },
            {
              name: "DANCALAN",
            },
            {
              name: "DE VERA",
            },
            {
              name: "DONSOL",
            },
            {
              name: "GIMAGAAN",
            },
            {
              name: "GIRAWAN",
            },
            {
              name: "GOGON",
            },
            {
              name: "GURA",
            },
            {
              name: "JUAN ADRE",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALAPOC",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MARKET SITE BARANGAY 3 (POB.)",
            },
            {
              name: "NEW MAGUISA",
            },
            {
              name: "OGOD (CROSSING)",
            },
            {
              name: "OLD MAGUISA",
            },
            {
              name: "ORANGE",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PARINA",
            },
            {
              name: "PAWALA",
            },
            {
              name: "PINAMANAAN",
            },
            {
              name: "POSO POB. (BARANGAY 5)",
            },
            {
              name: "PUNTA WALING-WALING POB. (BARANGAY 4)",
            },
            {
              name: "RAWIS",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SEVILLA",
            },
            {
              name: "SIBAGO",
            },
            {
              name: "SUGUIAN",
            },
            {
              name: "TAGBAC",
            },
            {
              name: "TINANOGAN",
            },
            {
              name: "TONGDOL",
            },
            {
              name: "TRES MARIAS",
            },
            {
              name: "TUBA",
            },
            {
              name: "TUPAS",
            },
            {
              name: "VINISITAHAN",
            },
          ],
        },
        GUBAT: {
          name: "GUBAT",
          baranggays: [
            {
              name: "ARIMAN",
            },
            {
              name: "BAGACAY",
            },
            {
              name: "BALUD DEL NORTE (POB.)",
            },
            {
              name: "BALUD DEL SUR (POB.)",
            },
            {
              name: "BENGUET",
            },
            {
              name: "BENTUCO",
            },
            {
              name: "BERIRAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULACAO",
            },
            {
              name: "CABIGAAN",
            },
            {
              name: "CABIGUHAN",
            },
            {
              name: "CARRIEDO",
            },
            {
              name: "CASILI",
            },
            {
              name: "COGON",
            },
            {
              name: "COTA NA DACO (POB.)",
            },
            {
              name: "DITA",
            },
            {
              name: "GUBAT",
            },
            {
              name: "JUPI",
            },
            {
              name: "LAPINIG",
            },
            {
              name: "LUNA-CANDOL (POB.)",
            },
            {
              name: "MANAPAO",
            },
            {
              name: "MANOOK (POB.)",
            },
            {
              name: "NAAGTAN",
            },
            {
              name: "NATO",
            },
            {
              name: "NAZARENO",
            },
            {
              name: "OGAO",
            },
            {
              name: "PACO",
            },
            {
              name: "PANGANIBAN (POB.)",
            },
            {
              name: "PARADIJON (POB.)",
            },
            {
              name: "PATAG",
            },
            {
              name: "PAYAWIN",
            },
            {
              name: "PINONTINGAN (POB.)",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN IGNACIO",
            },
            {
              name: "SANGAT",
            },
            {
              name: "SANTA ANA",
            },
            {
              name: "TABI",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "TIGKIW",
            },
            {
              name: "TIRIS",
            },
            {
              name: "TOGAWE",
            },
            {
              name: "UNION",
            },
            {
              name: "VILLAREAL",
            },
          ],
        },
        IROSIN: {
          name: "IROSIN",
          baranggays: [
            {
              name: "BACOLOD (POB.)",
            },
            {
              name: "BAGSANGAN",
            },
            {
              name: "BATANG",
            },
            {
              name: "BOLOS",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CARRIEDO",
            },
            {
              name: "CASINI",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "COGON",
            },
            {
              name: "GABAO",
            },
            {
              name: "GULANG-GULANG",
            },
            {
              name: "GUMAPIA",
            },
            {
              name: "IROSIN",
            },
            {
              name: "LIANG",
            },
            {
              name: "MACAWAYAN",
            },
            {
              name: "MAPASO",
            },
            {
              name: "MONBON",
            },
            {
              name: "PATAG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN JULIAN (POB.)",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTO DOMINGO (LAMBOON)",
            },
            {
              name: "TABON-TABON",
            },
            {
              name: "TINAMPO",
            },
            {
              name: "TONGDOL",
            },
          ],
        },
        JUBAN: {
          name: "JUBAN",
          baranggays: [
            {
              name: "ANOG",
            },
            {
              name: "AROROY",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BINANUAHAN",
            },
            {
              name: "BIRIRAN",
            },
            {
              name: "BURABURAN",
            },
            {
              name: "CALATEO",
            },
            {
              name: "CALMAYON",
            },
            {
              name: "CAROHAYON",
            },
            {
              name: "CATANAGAN",
            },
            {
              name: "CATANUSAN",
            },
            {
              name: "COGON",
            },
            {
              name: "EMBARCADERO",
            },
            {
              name: "GURUYAN",
            },
            {
              name: "JUBAN",
            },
            {
              name: "LAJONG",
            },
            {
              name: "MAALO",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "PUTING SAPA",
            },
            {
              name: "RANGAS",
            },
            {
              name: "SABLAYAN",
            },
            {
              name: "SIPAYA",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TABOC",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TUGHAN",
            },
          ],
        },
        MAGALLANES: {
          name: "MAGALLANES",
          baranggays: [
            {
              name: "AGUADA NORTE",
            },
            {
              name: "AGUADA SUR",
            },
            {
              name: "ANIBONG",
            },
            {
              name: "BACALON",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BANACUD",
            },
            {
              name: "BEHIA",
            },
            {
              name: "BIGA",
            },
            {
              name: "BINISITAHAN DEL NORTE",
            },
            {
              name: "BINISITAHAN DEL SUR",
            },
            {
              name: "BITON",
            },
            {
              name: "BULALA",
            },
            {
              name: "BUSAY",
            },
            {
              name: "CADITAAN",
            },
            {
              name: "CAGBOLO",
            },
            {
              name: "CAGTALABA",
            },
            {
              name: "CAWIT EXTENSION",
            },
            {
              name: "CAWIT PROPER",
            },
            {
              name: "GINANGRA",
            },
            {
              name: "HUBO",
            },
            {
              name: "INCARIZAN",
            },
            {
              name: "LAPINIG",
            },
            {
              name: "MAGALLANES",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALBOG",
            },
            {
              name: "PANTALAN",
            },
            {
              name: "PAWIK",
            },
            {
              name: "PILI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SANTA ELENA",
            },
            {
              name: "SIUTON",
            },
            {
              name: "TAGAS",
            },
            {
              name: "TULATULA NORTE",
            },
            {
              name: "TULATULA SUR",
            },
          ],
        },
        MATNOG: {
          name: "MATNOG",
          baranggays: [
            {
              name: "BALOCAWE",
            },
            {
              name: "BANOGAO",
            },
            {
              name: "BANUANGDAAN",
            },
            {
              name: "BARIIS",
            },
            {
              name: "BOLO",
            },
            {
              name: "BON-OT BIG",
            },
            {
              name: "BON-OT SMALL",
            },
            {
              name: "CABAGAHAN",
            },
            {
              name: "CALAYUAN",
            },
            {
              name: "CALINTAAN",
            },
            {
              name: "CALOOCAN (POB.)",
            },
            {
              name: "CALPI",
            },
            {
              name: "CAMACHILES (POB.)",
            },
            {
              name: "CAMCAMAN (POB.)",
            },
            {
              name: "CORON-CORON",
            },
            {
              name: "CULASI",
            },
            {
              name: "GADGARON",
            },
            {
              name: "GENABLAN OCCIDENTAL",
            },
            {
              name: "GENABLAN ORIENTAL",
            },
            {
              name: "HIDHID",
            },
            {
              name: "LABOY",
            },
            {
              name: "LAJONG",
            },
            {
              name: "MAMBAJOG",
            },
            {
              name: "MANJUNLAD",
            },
            {
              name: "MANURABI",
            },
            {
              name: "MATNOG",
            },
            {
              name: "NABURACAN",
            },
            {
              name: "PAGHULIRAN",
            },
            {
              name: "PANGI",
            },
            {
              name: "PAWA",
            },
            {
              name: "POROPANDAN",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SINALMACAN",
            },
            {
              name: "SINANG-ATAN",
            },
            {
              name: "SINIBARAN",
            },
            {
              name: "SISIGON",
            },
            {
              name: "SUA",
            },
            {
              name: "SULANGAN",
            },
            {
              name: "TABLAC (POB.)",
            },
            {
              name: "TABUNAN (POB.)",
            },
            {
              name: "TUGAS",
            },
          ],
        },
        PILAR: {
          name: "PILAR",
          baranggays: [
            {
              name: "ABAS",
            },
            {
              name: "ABUCAY",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BANUYO (POB.)",
            },
            {
              name: "BAYASONG",
            },
            {
              name: "BAYAWAS",
            },
            {
              name: "BINANUAHAN (POB.)",
            },
            {
              name: "CABIGUAN",
            },
            {
              name: "CAGDONGON",
            },
            {
              name: "CALONGAY",
            },
            {
              name: "CALPI",
            },
            {
              name: "CATAMLANGAN",
            },
            {
              name: "COMAPO-CAPO",
            },
            {
              name: "DANLOG",
            },
            {
              name: "DAO (POB.)",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DEL ROSARIO (BUAL)",
            },
            {
              name: "ESMERADA",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GINABLAN",
            },
            {
              name: "GUIRON",
            },
            {
              name: "INANG",
            },
            {
              name: "INAPUGAN",
            },
            {
              name: "LEONA",
            },
            {
              name: "LIPASON",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LUBIANO",
            },
            {
              name: "LUMBANG",
            },
            {
              name: "LUNGIB",
            },
            {
              name: "MABANATE",
            },
            {
              name: "MALBOG",
            },
            {
              name: "MARIFOSQUE (POB.)",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "MIGABOD",
            },
            {
              name: "NASPI",
            },
            {
              name: "PALANAS",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PILAR",
            },
            {
              name: "PINAGSALOG",
            },
            {
              name: "PINEDA",
            },
            {
              name: "POCTOL",
            },
            {
              name: "PUDO",
            },
            {
              name: "PUTIAO",
            },
            {
              name: "SACNANGAN",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO (MILLABAS)",
            },
            {
              name: "SAN ANTONIO (SAPA)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA FE",
            },
          ],
        },
        "PRIETO DIAZ": {
          name: "PRIETO DIAZ",
          baranggays: [
            {
              name: "BRILLANTE (POB.)",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CALAO",
            },
            {
              name: "CARAYAT",
            },
            {
              name: "DIAMANTE",
            },
            {
              name: "GOGON",
            },
            {
              name: "LUPI",
            },
            {
              name: "MANINGCAY DE ORO",
            },
            {
              name: "MANLABONG",
            },
            {
              name: "PERLAS",
            },
            {
              name: "PRIETO DIAZ",
            },
            {
              name: "QUIDOLOG",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SANTA LOURDES",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "TALISAYAN",
            },
            {
              name: "TUPAZ",
            },
            {
              name: "ULAG",
            },
          ],
        },
        "SANTA MAGDALENA": {
          name: "SANTA MAGDALENA",
          baranggays: [
            {
              name: "BARANGAY POBLACION I",
            },
            {
              name: "BARANGAY POBLACION II",
            },
            {
              name: "BARANGAY POBLACION III",
            },
            {
              name: "BARANGAY POBLACION IV",
            },
            {
              name: "LA ESPERANZA",
            },
            {
              name: "PEÑAFRANCIA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BARTOLOME (TALAONGAN)",
            },
            {
              name: "SAN EUGENIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SANTA MAGDALENA",
            },
          ],
        },
        "SORSOGON CITY": {
          name: "SORSOGON CITY",
          baranggays: [
            {
              name: "ABUYOG",
            },
            {
              name: "ALMENDRAS-COGON (POB.)",
            },
            {
              name: "BALETE",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BALOGO ( FR. THE MUN. OF BACON)",
            },
            {
              name: "BARAYONG",
            },
            {
              name: "BASUD",
            },
            {
              name: "BATO",
            },
            {
              name: "BIBINCAHAN",
            },
            {
              name: "BITAN-O/DALIPAY (POB.)",
            },
            {
              name: "BOGÑA",
            },
            {
              name: "BON-OT",
            },
            {
              name: "BUCALBUCALAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUENAVISTA ( FR. THE MUN. OF BACON)",
            },
            {
              name: "BUHATAN",
            },
            {
              name: "BULABOG",
            },
            {
              name: "BURABOD (POB.)",
            },
            {
              name: "CABARBUHAN",
            },
            {
              name: "CABID-AN",
            },
            {
              name: "CAMBULAGA",
            },
            {
              name: "CAPUY",
            },
            {
              name: "CARICARAN",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "GATBO",
            },
            {
              name: "GIMALOTO",
            },
            {
              name: "GUINLAJON",
            },
            {
              name: "JAMISLAGAN",
            },
            {
              name: "MACABOG",
            },
            {
              name: "MARICRUM",
            },
            {
              name: "MARINAS",
            },
            {
              name: "OSIAO",
            },
            {
              name: "PAMURAYAN",
            },
            {
              name: "PANGPANG",
            },
            {
              name: "PANLAYAAN",
            },
            {
              name: "PEÑAFRANCIA",
            },
            {
              name: "PIOT (POB.)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLVORISTA (POB.)",
            },
            {
              name: "RAWIS",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALOG (POB.)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SALVACION ( FR. THE MUN. OF BACON)",
            },
            {
              name: "SAMPALOC (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ISIDRO ( FR. THE MUN. OF BACON)",
            },
            {
              name: "SAN JUAN ( FR. THE MUN. OF BACON)",
            },
            {
              name: "SAN JUAN (RORO)",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAWANGA",
            },
            {
              name: "SIRANGAN (POB.)",
            },
            {
              name: "SORSOGON CITY",
            },
            {
              name: "SUGOD",
            },
            {
              name: "SULUCAN (POB.)",
            },
            {
              name: "TALISAY (POB.)",
            },
            {
              name: "TICOL",
            },
            {
              name: "TUGOS",
            },
          ],
        },
      },
    },
    "SOUTH COTABATO": {
      name: "SOUTH COTABATO",
      region: "REGION 12",
      municipalities: {
        ALABEL: {
          name: "ALABEL",
          baranggays: [
            {
              name: "ALABEL",
            },
          ],
        },
        APOPONG: {
          name: "APOPONG",
          baranggays: [
            {
              name: "APOPONG",
            },
          ],
        },
        BALACAYO: {
          name: "BALACAYO",
          baranggays: [
            {
              name: "BALACAYO",
            },
          ],
        },
        BANGA: {
          name: "BANGA",
          baranggays: [
            {
              name: "BANGA",
            },
            {
              name: "BENITEZ (POB.)",
            },
            {
              name: "CABUDIAN",
            },
            {
              name: "CABULING",
            },
            {
              name: "CINCO (BARRIO 5)",
            },
            {
              name: "DERILON",
            },
            {
              name: "EL NONOK",
            },
            {
              name: "IMPROGO VILLAGE (POB.)",
            },
            {
              name: "KUSAN (BARRIO 8)",
            },
            {
              name: "LAM-APOS",
            },
            {
              name: "LAMBA",
            },
            {
              name: "LAMBINGI",
            },
            {
              name: "LAMPARI",
            },
            {
              name: "LIWANAY (BARRIO 1)",
            },
            {
              name: "MALAYA (BARRIO 9)",
            },
            {
              name: "PUNONG GRANDE (BARRIO 2)",
            },
            {
              name: "RANG-AY (BARRIO 4)",
            },
            {
              name: "REYES (POB.)",
            },
            {
              name: "RIZAL (BARRIO 3)",
            },
            {
              name: "RIZAL POBLACION",
            },
            {
              name: "SAN JOSE (BARRIO 7)",
            },
            {
              name: "SAN VICENTE (BARRIO 6)",
            },
            {
              name: "YANGCO POBLACION",
            },
          ],
        },
        BLAGAN: {
          name: "BLAGAN",
          baranggays: [
            {
              name: "BLAGAN",
            },
          ],
        },
        BLIANOK: {
          name: "BLIANOK",
          baranggays: [
            {
              name: "BLIANOK",
            },
          ],
        },
        "BUDA TALAM": {
          name: "BUDA TALAM",
          baranggays: [
            {
              name: "BUDA TALAM",
            },
          ],
        },
        BULA: {
          name: "BULA",
          baranggays: [
            {
              name: "BULA",
            },
          ],
        },
        CALUMPANG: {
          name: "CALUMPANG",
          baranggays: [
            {
              name: "CALUMPANG",
            },
          ],
        },
        "DADIANGAS HTS": {
          name: "DADIANGAS HTS",
          baranggays: [
            {
              name: "DADIANGAS HTS",
            },
          ],
        },
        "DATAL KASILA": {
          name: "DATAL KASILA",
          baranggays: [
            {
              name: "DATAL KASILA",
            },
          ],
        },
        "GENERAL SANTOS": {
          name: "GENERAL SANTOS",
          baranggays: [
            {
              name: "GENERAL SANTOS",
            },
          ],
        },
        "GENERAL SANTOS CITY (DADIANGAS)": {
          name: "GENERAL SANTOS CITY (DADIANGAS)",
          baranggays: [
            {
              name: "APOPONG",
            },
            {
              name: "BALUAN",
            },
            {
              name: "BATOMELONG",
            },
            {
              name: "BUAYAN",
            },
            {
              name: "BULA",
            },
            {
              name: "CALUMPANG",
            },
            {
              name: "CITY HEIGHTS",
            },
            {
              name: "CONEL",
            },
            {
              name: "DADIANGAS EAST (POB.)",
            },
            {
              name: "DADIANGAS NORTH",
            },
            {
              name: "DADIANGAS SOUTH",
            },
            {
              name: "DADIANGAS WEST",
            },
            {
              name: "FATIMA",
            },
            {
              name: "KATANGAWAN",
            },
            {
              name: "LABANGAL",
            },
            {
              name: "LAGAO (1ST AND 3RD)",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "OLYMPOG",
            },
            {
              name: "SAN ISIDRO (LAGAO 2ND)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SIGUEL",
            },
            {
              name: "SINAWAL",
            },
            {
              name: "TAMBLER",
            },
            {
              name: "TINAGACAN",
            },
            {
              name: "UPPER LABAY",
            },
          ],
        },
        GLAN: {
          name: "GLAN",
          baranggays: [
            {
              name: "GLAN",
            },
          ],
        },
        KATANGAWAN: {
          name: "KATANGAWAN",
          baranggays: [
            {
              name: "KATANGAWAN",
            },
          ],
        },
        KIAMBA: {
          name: "KIAMBA",
          baranggays: [
            {
              name: "KIAMBA",
            },
          ],
        },
        KLINAN: {
          name: "KLINAN",
          baranggays: [
            {
              name: "KLINAN",
            },
          ],
        },
        "KORONADAL CITY": {
          name: "KORONADAL CITY",
          baranggays: [
            {
              name: "ASSUMPTION (BULOL)",
            },
            {
              name: "AVANCEÑA (BO. 3)",
            },
            {
              name: "CACUB",
            },
            {
              name: "CALOOCAN",
            },
            {
              name: "CARPENTER HILL",
            },
            {
              name: "CONCEPCION (BO. 6)",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GENERAL PAULINO SANTOS (BO. 1)",
            },
            {
              name: "KORONADAL CITY",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAMBUCAL",
            },
            {
              name: "MORALES",
            },
            {
              name: "NAMNAMA",
            },
            {
              name: "NEW PANGASINAN (BO. 4)",
            },
            {
              name: "PARAISO",
            },
            {
              name: "ROTONDA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE (BO. 5)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO (BO. 2)",
            },
            {
              name: "SARABIA (BO. 8)",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
            {
              name: "ZULUETA (BO. 7)",
            },
          ],
        },
        LABANGAL: {
          name: "LABANGAL",
          baranggays: [
            {
              name: "LABANGAL",
            },
          ],
        },
        LABAY: {
          name: "LABAY",
          baranggays: [
            {
              name: "LABAY",
            },
          ],
        },
        LAGAO: {
          name: "LAGAO",
          baranggays: [
            {
              name: "LAGAO",
            },
          ],
        },
        "LAKE SEBU": {
          name: "LAKE SEBU",
          baranggays: [
            {
              name: "BACDULONG",
            },
            {
              name: "DENLAG",
            },
            {
              name: "HALILAN",
            },
            {
              name: "HANOON",
            },
            {
              name: "KLUBI",
            },
            {
              name: "LAKE LAHIT",
            },
            {
              name: "LAMCADE",
            },
            {
              name: "LAMDALAG",
            },
            {
              name: "LAMFUGON",
            },
            {
              name: "LAMLAHAK",
            },
            {
              name: "LOWER MACULAN",
            },
            {
              name: "LUHIB",
            },
            {
              name: "NED",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SILUTON",
            },
            {
              name: "TAKUNEL",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TASIMAN",
            },
            {
              name: "UPPER MACULAN",
            },
          ],
        },
        LAMBAYONG: {
          name: "LAMBAYONG",
          baranggays: [
            {
              name: "LAMBAYONG",
            },
          ],
        },
        LIGAYA: {
          name: "LIGAYA",
          baranggays: [
            {
              name: "LIGAYA",
            },
          ],
        },
        MAASIM: {
          name: "MAASIM",
          baranggays: [
            {
              name: "MAASIM",
            },
          ],
        },
        MABUHAY: {
          name: "MABUHAY",
          baranggays: [
            {
              name: "MABUHAY",
            },
          ],
        },
        MAITUM: {
          name: "MAITUM",
          baranggays: [
            {
              name: "MAITUM",
            },
          ],
        },
        MAKAR: {
          name: "MAKAR",
          baranggays: [
            {
              name: "MAKAR",
            },
          ],
        },
        MALANDAG: {
          name: "MALANDAG",
          baranggays: [
            {
              name: "MALANDAG",
            },
          ],
        },
        MALAPATAN: {
          name: "MALAPATAN",
          baranggays: [
            {
              name: "MALAPATAN",
            },
          ],
        },
        MALUNGON: {
          name: "MALUNGON",
          baranggays: [
            {
              name: "MALUNGON",
            },
          ],
        },
        MARBEL: {
          name: "MARBEL",
          baranggays: [
            {
              name: "MARBEL",
            },
          ],
        },
        MARIBULAN: {
          name: "MARIBULAN",
          baranggays: [
            {
              name: "MARIBULAN",
            },
          ],
        },
        NORALA: {
          name: "NORALA",
          baranggays: [
            {
              name: "BENIGNO AQUINO, JR.",
            },
            {
              name: "DUMAGUIL",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "KIBID",
            },
            {
              name: "LAPUZ",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "LOPEZ JAENA",
            },
            {
              name: "MATAPOL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUTI",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SIMSIMAN",
            },
            {
              name: "TINAGO",
            },
          ],
        },
        OLIMPOG: {
          name: "OLIMPOG",
          baranggays: [
            {
              name: "OLIMPOG",
            },
          ],
        },
        POLOMOLOK: {
          name: "POLOMOLOK",
          baranggays: [
            {
              name: "BENTUNG",
            },
            {
              name: "CANNERY SITE",
            },
            {
              name: "CROSSING PALKAN",
            },
            {
              name: "GLAMANG",
            },
            {
              name: "KINILIS",
            },
            {
              name: "KLINAN 6",
            },
            {
              name: "KORONADAL PROPER",
            },
            {
              name: "LAM-CALIAF",
            },
            {
              name: "LANDAN",
            },
            {
              name: "LAPU",
            },
            {
              name: "LUMAKIL",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALIGO",
            },
            {
              name: "PAGALUNGAN",
            },
            {
              name: "PALKAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLO",
            },
            {
              name: "RUBBER",
            },
            {
              name: "SILWAY 7",
            },
            {
              name: "SILWAY 8",
            },
            {
              name: "SULIT",
            },
            {
              name: "SUMBAKIL",
            },
            {
              name: "UPPER KLINAN",
            },
          ],
        },
        "PUTTING BATO": {
          name: "PUTTING BATO",
          baranggays: [
            {
              name: "PUTTING BATO",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "SAN ISIDRO",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "SAN JOSE",
            },
          ],
        },
        "SANTO NIÑO": {
          name: "SANTO NIÑO",
          baranggays: [
            {
              name: "AMBALGAN",
            },
            {
              name: "GUINSANG-AN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MANUEL ROXAS",
            },
            {
              name: "PANAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAJANEBA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TERESITA",
            },
          ],
        },
        SINAWAL: {
          name: "SINAWAL",
          baranggays: [
            {
              name: "SINAWAL",
            },
          ],
        },
        SURALLAH: {
          name: "SURALLAH",
          baranggays: [
            {
              name: "BUENAVISTA",
            },
            {
              name: "CANAHAY (GODWINO)",
            },
            {
              name: "CENTRALA",
            },
            {
              name: "COLONGULO",
            },
            {
              name: "DAJAY",
            },
            {
              name: "DUENGAS",
            },
            {
              name: "LAMBONTONG",
            },
            {
              name: "LAMIAN",
            },
            {
              name: "LAMSUGOD",
            },
            {
              name: "LIBERTAD (POB.)",
            },
            {
              name: "LITTLE BAGUIO",
            },
            {
              name: "MOLOY",
            },
            {
              name: "NACI (DOCE)",
            },
            {
              name: "TALAHIK",
            },
            {
              name: "TUBIALA",
            },
            {
              name: "UPPER SEPAKA",
            },
            {
              name: "VETERANS",
            },
          ],
        },
        TAMBLER: {
          name: "TAMBLER",
          baranggays: [
            {
              name: "TAMBLER",
            },
          ],
        },
        TAMPAKAN: {
          name: "TAMPAKAN",
          baranggays: [
            {
              name: "ALBAGAN",
            },
            {
              name: "BUTO",
            },
            {
              name: "DANLAG",
            },
            {
              name: "KIPALBIG",
            },
            {
              name: "LAMBAYONG",
            },
            {
              name: "LAMPITAK",
            },
            {
              name: "LIBERTY",
            },
            {
              name: "MALTANA",
            },
            {
              name: "PALO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULA-BATO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TABLU",
            },
            {
              name: "TAMPAKAN",
            },
          ],
        },
        TANTANGAN: {
          name: "TANTANGAN",
          baranggays: [
            {
              name: "BUKAY PAIT",
            },
            {
              name: "CABULING",
            },
            {
              name: "DUMADALIG",
            },
            {
              name: "LIBAS",
            },
            {
              name: "MAGON",
            },
            {
              name: "MAIBO",
            },
            {
              name: "MANGILALA",
            },
            {
              name: "NEW CUYAPO",
            },
            {
              name: "NEW ILOILO",
            },
            {
              name: "NEW LAMBUNAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "TANTANGAN",
            },
            {
              name: "TINONGCOP",
            },
          ],
        },
        TINAGACAN: {
          name: "TINAGACAN",
          baranggays: [
            {
              name: "TINAGACAN",
            },
          ],
        },
        TUPI: {
          name: "TUPI",
          baranggays: [
            {
              name: "ACMONAN",
            },
            {
              name: "BOLOLMALA",
            },
            {
              name: "BUNAO",
            },
            {
              name: "CEBUANO",
            },
            {
              name: "CROSSING RUBBER",
            },
            {
              name: "KABLON",
            },
            {
              name: "KALKAM",
            },
            {
              name: "LINAN",
            },
            {
              name: "LUNEN",
            },
            {
              name: "MIASONG",
            },
            {
              name: "PALIAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLONULING",
            },
            {
              name: "SIMBO",
            },
            {
              name: "TUBENG",
            },
          ],
        },
        "T`BOLI": {
          name: "T`BOLI",
          baranggays: [
            {
              name: "AFLEK",
            },
            {
              name: "AFUS",
            },
            {
              name: "BASAG",
            },
            {
              name: "DATAL BOB",
            },
            {
              name: "DESAWO",
            },
            {
              name: "DLANAG",
            },
            {
              name: "EDWARDS (POB.)",
            },
            {
              name: "KEMATU",
            },
            {
              name: "LACONON",
            },
            {
              name: "LAMBANGAN",
            },
            {
              name: "LAMBULING",
            },
            {
              name: "LAMHAKO",
            },
            {
              name: "LAMSALOME",
            },
            {
              name: "LEMSNOLON",
            },
            {
              name: "MAAN",
            },
            {
              name: "MALUGONG",
            },
            {
              name: "MONGOCAYO",
            },
            {
              name: "NEW DUMANGAS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALACAFE",
            },
            {
              name: "SINOLON",
            },
            {
              name: "T'BOLOK",
            },
            {
              name: "TALCON",
            },
            {
              name: "TALUFO",
            },
            {
              name: "TUDOK",
            },
          ],
        },
        "UPPER LABAY": {
          name: "UPPER LABAY",
          baranggays: [
            {
              name: "UPPER LABAY",
            },
          ],
        },
      },
    },
    "SOUTHERN LEYTE": {
      name: "SOUTHERN LEYTE",
      region: "REGION 8",
      municipalities: {
        ANAHAWAN: {
          name: "ANAHAWAN",
          baranggays: [
            {
              name: "AMAGUSAN",
            },
            {
              name: "ANAHAWAN",
            },
            {
              name: "CALINTAAN",
            },
            {
              name: "CANLABIAN",
            },
            {
              name: "CAPACUHAN",
            },
            {
              name: "COGON",
            },
            {
              name: "KAGINGKINGAN",
            },
            {
              name: "LEWING",
            },
            {
              name: "LO-OK",
            },
            {
              name: "MAHALO",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MANIGAWONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "TAGUP-ON",
            },
          ],
        },
        BONTOC: {
          name: "BONTOC",
          baranggays: [
            {
              name: "ANAHAO",
            },
            {
              name: "BANAHAO",
            },
            {
              name: "BAUGO",
            },
            {
              name: "BENITON",
            },
            {
              name: "BONTOC",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CASAO",
            },
            {
              name: "CATMON",
            },
            {
              name: "CATOOGAN",
            },
            {
              name: "CAWAYANAN",
            },
            {
              name: "DAO",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GUINSANGAAN",
            },
            {
              name: "HIBAGWAN",
            },
            {
              name: "HILAAN",
            },
            {
              name: "HIMAKILO",
            },
            {
              name: "HITAWOS",
            },
            {
              name: "LANAO",
            },
            {
              name: "LAWGAWAN",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALBAGO",
            },
            {
              name: "MAUYLAB",
            },
            {
              name: "OLISIHAN",
            },
            {
              name: "PAKU",
            },
            {
              name: "PAMAHAWAN",
            },
            {
              name: "PAMIGSIAN",
            },
            {
              name: "PANGI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PONG-ON",
            },
            {
              name: "SAMPONGON",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAA",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAYTAGAN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "UNION",
            },
          ],
        },
        HINUNANGAN: {
          name: "HINUNANGAN",
          baranggays: [
            {
              name: "AMBACON",
            },
            {
              name: "BADIANGON",
            },
            {
              name: "BANGCAS A",
            },
            {
              name: "BANGCAS B",
            },
            {
              name: "BIASONG",
            },
            {
              name: "BUGHO",
            },
            {
              name: "CALAG-ITAN",
            },
            {
              name: "CALAYUGAN",
            },
            {
              name: "CALINAO",
            },
            {
              name: "CANIPAAN",
            },
            {
              name: "CATUBLIAN",
            },
            {
              name: "HINUNANGAN",
            },
            {
              name: "ILAYA",
            },
            {
              name: "INGAN",
            },
            {
              name: "LABRADOR",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LUMBOG",
            },
            {
              name: "MANALOG",
            },
            {
              name: "MANLICO",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "NAVA",
            },
            {
              name: "NUEVA ESPERANZA",
            },
            {
              name: "OTAMA",
            },
            {
              name: "PALONGPONG",
            },
            {
              name: "PANALARON",
            },
            {
              name: "PATONG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PONDOL",
            },
            {
              name: "SALOG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN PABLO ISLAND",
            },
            {
              name: "SAN PEDRO ISLAND",
            },
            {
              name: "SANTO NIÑO I",
            },
            {
              name: "SANTO NIÑO II",
            },
            {
              name: "TAHUSAN",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAWOG",
            },
            {
              name: "TOPTOP",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "UNION",
            },
            {
              name: "UPPER BANTAWON",
            },
          ],
        },
        HINUNDAYAN: {
          name: "HINUNDAYAN",
          baranggays: [
            {
              name: "AMAGA",
            },
            {
              name: "AMBAO",
            },
            {
              name: "AN-AN",
            },
            {
              name: "BACULOD",
            },
            {
              name: "BUGHO",
            },
            {
              name: "CABULISAN",
            },
            {
              name: "CAT-IWING",
            },
            {
              name: "DISTRICT I (POB.)",
            },
            {
              name: "DISTRICT II (POB.)",
            },
            {
              name: "DISTRICT III (POB.)",
            },
            {
              name: "HUBASAN",
            },
            {
              name: "LUNGSODAAN",
            },
            {
              name: "NAVALITA",
            },
            {
              name: "PLARIDEL",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAGBOK",
            },
          ],
        },
        LIBAGON: {
          name: "LIBAGON",
          baranggays: [
            {
              name: "BIASONG",
            },
            {
              name: "BOGASONG",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "GAKAT",
            },
            {
              name: "JUBAS (POB.)",
            },
            {
              name: "LIBAGON",
            },
            {
              name: "MAGKASAG",
            },
            {
              name: "MAYUGA",
            },
            {
              name: "NAHAONG",
            },
            {
              name: "NAHULID",
            },
            {
              name: "OTIKON",
            },
            {
              name: "PANGI",
            },
            {
              name: "PUNTA",
            },
            {
              name: "TALISAY (POB.)",
            },
            {
              name: "TIGBAO",
            },
          ],
        },
        LILOAN: {
          name: "LILOAN",
          baranggays: [
            {
              name: "AMAGA",
            },
            {
              name: "ANILAO",
            },
            {
              name: "BAHAY",
            },
            {
              name: "CAGBUNGALON",
            },
            {
              name: "CALIAN",
            },
            {
              name: "CALIGANGAN",
            },
            {
              name: "CANDAYUMAN",
            },
            {
              name: "CATIG",
            },
            {
              name: "ESTELA",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GUD-AN",
            },
            {
              name: "GUINTOYLAN",
            },
            {
              name: "HIMAYANGAN",
            },
            {
              name: "ILAG",
            },
            {
              name: "LILOAN",
            },
            {
              name: "MAGAUPAS",
            },
            {
              name: "MALANGSA",
            },
            {
              name: "MOLOPOLO",
            },
            {
              name: "PANDAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PRES. QUEZON (MAUGOC)",
            },
            {
              name: "PRESIDENT ROXAS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TABUGON",
            },
          ],
        },
        LIMASAWA: {
          name: "LIMASAWA",
          baranggays: [
            {
              name: "CABULIHAN",
            },
            {
              name: "LIMASAWA",
            },
            {
              name: "LUGSONGAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN BERNARDO",
            },
            {
              name: "TRIANA",
            },
          ],
        },
        "MAASIN CITY": {
          name: "MAASIN CITY",
          baranggays: [
            {
              name: "ABGAO (POB.)",
            },
            {
              name: "ACASIA",
            },
            {
              name: "ASUNCION",
            },
            {
              name: "BACTUL I",
            },
            {
              name: "BACTUL II",
            },
            {
              name: "BADIANG",
            },
            {
              name: "BAGTICAN",
            },
            {
              name: "BASAK",
            },
            {
              name: "BATO I",
            },
            {
              name: "BATO II",
            },
            {
              name: "BATUAN",
            },
            {
              name: "BAUGO",
            },
            {
              name: "BILIBOL",
            },
            {
              name: "BOGO",
            },
            {
              name: "CABADIANGAN",
            },
            {
              name: "CABULIHAN",
            },
            {
              name: "CAGNITUAN",
            },
            {
              name: "CAMBOOC",
            },
            {
              name: "CANSIRONG",
            },
            {
              name: "CANTURING",
            },
            {
              name: "CANYUOM",
            },
            {
              name: "COMBADO",
            },
            {
              name: "DONGON",
            },
            {
              name: "GAWISAN",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "HANGINAN",
            },
            {
              name: "HANTAG",
            },
            {
              name: "HINAPU DAKU",
            },
            {
              name: "HINAPU GAMAY",
            },
            {
              name: "IBARRA",
            },
            {
              name: "ISAGANI",
            },
            {
              name: "LABOON",
            },
            {
              name: "LANAO",
            },
            {
              name: "LIB-OG",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LIBHU",
            },
            {
              name: "LONOY",
            },
            {
              name: "LUNAS",
            },
            {
              name: "MAASIN CITY",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALAPOC NORTE",
            },
            {
              name: "MALAPOC SUR",
            },
            {
              name: "MAMBAJAO (POB.)",
            },
            {
              name: "MANHILO",
            },
            {
              name: "MANTAHAN (POB.)",
            },
            {
              name: "MARIA CLARA",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "NASAUG",
            },
            {
              name: "NATI",
            },
            {
              name: "NONOK NORTE",
            },
            {
              name: "NONOK SUR",
            },
            {
              name: "PANAN-AWAN",
            },
            {
              name: "PANSAAN",
            },
            {
              name: "PASAY",
            },
            {
              name: "PINASCOHAN",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SORO-SORO",
            },
            {
              name: "TAGNIPA (POB.)",
            },
            {
              name: "TAM-IS",
            },
            {
              name: "TAWID",
            },
            {
              name: "TIGBAWAN",
            },
            {
              name: "TOMOY-TOMOY",
            },
            {
              name: "TUNGA-TUNGA (POB.)",
            },
          ],
        },
        MACROHON: {
          name: "MACROHON",
          baranggays: [
            {
              name: "AGUINALDO",
            },
            {
              name: "AMPARO",
            },
            {
              name: "ASUNCION",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BUSCAYAN",
            },
            {
              name: "CAMBARO",
            },
            {
              name: "CANLUSAY",
            },
            {
              name: "DANAO",
            },
            {
              name: "FLORDELIZ",
            },
            {
              name: "GUADALUPE",
            },
            {
              name: "ICHON",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "LARAY",
            },
            {
              name: "LOWER VILLA JACINTA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MACROHON",
            },
            {
              name: "MOHON",
            },
            {
              name: "MOLOPOLO",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALVADOR (MANGYANG)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOAQUIN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE (UPPER SAN ROQUE)",
            },
            {
              name: "SAN VICENTE POBLACION",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO (POB.)",
            },
            {
              name: "SINDANGAN",
            },
            {
              name: "UPPER ICHON",
            },
            {
              name: "UPPER VILLA JACINTA",
            },
          ],
        },
        MALITBOG: {
          name: "MALITBOG",
          baranggays: [
            {
              name: "ABGAO",
            },
            {
              name: "ASUNCION",
            },
            {
              name: "AURORA",
            },
            {
              name: "BENIT",
            },
            {
              name: "CAAGA",
            },
            {
              name: "CABUL-ANONAN (POB.)",
            },
            {
              name: "CADARUHAN",
            },
            {
              name: "CADARUHAN SUR",
            },
            {
              name: "CANDATAG",
            },
            {
              name: "CANTAMUAC",
            },
            {
              name: "CARAATAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GUINABONAN",
            },
            {
              name: "IBA",
            },
            {
              name: "JUANGON",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LAMBONAO",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALITBOG",
            },
            {
              name: "MANINGNING",
            },
            {
              name: "MAUJO",
            },
            {
              name: "NEW KATIPUNAN",
            },
            {
              name: "PANCIL",
            },
            {
              name: "PASIL (POB.)",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN ANTONIO (POB.)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANGAHON",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TALIWA (POB.)",
            },
            {
              name: "TIGBAWAN I",
            },
            {
              name: "TIGBAWAN II",
            },
            {
              name: "TIMBA",
            },
          ],
        },
        "PADRE BURGOS": {
          name: "PADRE BURGOS",
          baranggays: [
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUNGA",
            },
            {
              name: "CANTUTANG",
            },
            {
              name: "DINAHUGAN",
            },
            {
              name: "LACA",
            },
            {
              name: "LUNGSODAAN",
            },
            {
              name: "PADRE BURGOS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA SOFIA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TANGKAAN",
            },
          ],
        },
        PINTUYAN: {
          name: "PINTUYAN",
          baranggays: [
            {
              name: "BADIANG",
            },
            {
              name: "BALONGBALONG",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CANLAWIS",
            },
            {
              name: "CATBAWAN",
            },
            {
              name: "CAUBANG",
            },
            {
              name: "COGON",
            },
            {
              name: "DAN-AN",
            },
            {
              name: "LOBO",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MANGLIT",
            },
            {
              name: "NUEVA ESTRELLA NORTE",
            },
            {
              name: "NUEVA ESTRELLA SUR",
            },
            {
              name: "PINTUYAN",
            },
            {
              name: "POBLACION IBABAO",
            },
            {
              name: "POBLACION UBOS",
            },
            {
              name: "POCIANO D. EQUIPILAG",
            },
            {
              name: "PONOD",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SON-OK I",
            },
            {
              name: "SON-OK II",
            },
            {
              name: "TAUTAG",
            },
          ],
        },
        "SAINT BERNARD": {
          name: "SAINT BERNARD",
          baranggays: [
            {
              name: "ATUYAN",
            },
            {
              name: "AYAHAG",
            },
            {
              name: "BANTAWON",
            },
            {
              name: "BOLODBOLOD",
            },
            {
              name: "CABAGAWAN",
            },
            {
              name: "CARNAGA",
            },
            {
              name: "CATMON",
            },
            {
              name: "GUINSAUGON",
            },
            {
              name: "HIMATAGON (POB.)",
            },
            {
              name: "HIMBANGAN",
            },
            {
              name: "HIMOS-ONAN",
            },
            {
              name: "HINABIAN",
            },
            {
              name: "HINDAG-AN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LIPANTO",
            },
            {
              name: "MAGBAGACAY",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALIBAGO",
            },
            {
              name: "MALINAO",
            },
            {
              name: "NUEVA ESPERANZA (CABAC-AN)",
            },
            {
              name: "PANIAN",
            },
            {
              name: "SAINT BERNARD",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SUG-ANGON",
            },
            {
              name: "TABONTABON",
            },
            {
              name: "TAMBIS I",
            },
            {
              name: "TAMBIS II",
            },
          ],
        },
        "SAN FRANCISCO": {
          name: "SAN FRANCISCO",
          baranggays: [
            {
              name: "ANISLAGON",
            },
            {
              name: "BONGAWISAN",
            },
            {
              name: "BONGBONG",
            },
            {
              name: "CAHAYAG",
            },
            {
              name: "CAUSI",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "DAKIT (POB.)",
            },
            {
              name: "GABI",
            },
            {
              name: "HABAY",
            },
            {
              name: "MALICO",
            },
            {
              name: "MARAYAG",
            },
            {
              name: "NAPANTAO",
            },
            {
              name: "PASANON",
            },
            {
              name: "PINAMUDLAN",
            },
            {
              name: "PUNTA",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA PAZ NORTE",
            },
            {
              name: "SANTA PAZ SUR",
            },
            {
              name: "SUDMON",
            },
            {
              name: "TINAAN",
            },
            {
              name: "TUNO",
            },
            {
              name: "UBOS (POB.)",
            },
          ],
        },
        "SAN JUAN (CABALIAN)": {
          name: "SAN JUAN (CABALIAN)",
          baranggays: [
            {
              name: "AGAY-AY",
            },
            {
              name: "BASAK",
            },
            {
              name: "BOBON A",
            },
            {
              name: "BOBON B",
            },
            {
              name: "DAYANOG",
            },
            {
              name: "GARRIDO",
            },
            {
              name: "MINOYHO",
            },
            {
              name: "OSAO",
            },
            {
              name: "PONG-OY",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN (CABALIAN)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "SOMOJE",
            },
            {
              name: "SUA",
            },
            {
              name: "TIMBA",
            },
          ],
        },
        "SAN RICARDO": {
          name: "SAN RICARDO",
          baranggays: [
            {
              name: "BENIT",
            },
            {
              name: "BITOON",
            },
            {
              name: "CABUTAN",
            },
            {
              name: "CAMANG",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "ESPERANZA DOS",
            },
            {
              name: "INOLINAN",
            },
            {
              name: "KINACHAWA",
            },
            {
              name: "LOOC",
            },
            {
              name: "PINUT-AN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO (ALANGALANG)",
            },
            {
              name: "SAN RAMON",
            },
            {
              name: "SAN RICARDO",
            },
            {
              name: "SAUB",
            },
            {
              name: "TIMBA",
            },
          ],
        },
        SILAGO: {
          name: "SILAGO",
          baranggays: [
            {
              name: "BALAGAWAN",
            },
            {
              name: "CATMON",
            },
            {
              name: "HINGATUNGAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LAGUMA",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "POB. DISTRICT I",
            },
            {
              name: "POB. DISTRICT II",
            },
            {
              name: "PUNTANA",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAP-ANG",
            },
            {
              name: "SILAGO",
            },
            {
              name: "SUDMON",
            },
            {
              name: "TUBA-ON",
            },
            {
              name: "TUBOD",
            },
          ],
        },
        SOGOD: {
          name: "SOGOD",
          baranggays: [
            {
              name: "BENIT",
            },
            {
              name: "BUAC DAKU",
            },
            {
              name: "BUAC GAMAY",
            },
            {
              name: "CABADBARAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "DAGSA",
            },
            {
              name: "HIBOD-HIBOD",
            },
            {
              name: "HINDANGAN",
            },
            {
              name: "HIPANTAG",
            },
            {
              name: "JAVIER",
            },
            {
              name: "KAHUPIAN",
            },
            {
              name: "KANANGKAAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LA PURISIMA CONCEPCION",
            },
            {
              name: "LIBAS",
            },
            {
              name: "LUM-AN",
            },
            {
              name: "MABICAY",
            },
            {
              name: "MAC",
            },
            {
              name: "MAGATAS",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MARIA PLANA",
            },
            {
              name: "MILAGROSO",
            },
            {
              name: "OLISIHAN",
            },
            {
              name: "PANCHO VILLA",
            },
            {
              name: "PANDAN",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN FRANCISCO MABUHAY",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN (AGATA)",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SOGOD",
            },
            {
              name: "SUBA",
            },
            {
              name: "TAMPOONG",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
            {
              name: "ZONE V (POB.)",
            },
          ],
        },
        "TOMAS OPPUS": {
          name: "TOMAS OPPUS",
          baranggays: [
            {
              name: "ANAHAWAN",
            },
            {
              name: "BANDAY (POB.)",
            },
            {
              name: "BIASONG",
            },
            {
              name: "BOGO (POB.)",
            },
            {
              name: "CABASCAN",
            },
            {
              name: "CAMANSI",
            },
            {
              name: "CAMBITE (POB.)",
            },
            {
              name: "CANLUPAO",
            },
            {
              name: "CARNAGA",
            },
            {
              name: "CAWAYAN",
            },
            {
              name: "HIGOSOAN",
            },
            {
              name: "HINAGTIKAN",
            },
            {
              name: "HINAPO",
            },
            {
              name: "HUGPA",
            },
            {
              name: "INIGUIHAN POB. (SALVACION)",
            },
            {
              name: "LOOC",
            },
            {
              name: "LUAN",
            },
            {
              name: "MAANYAG",
            },
            {
              name: "MAG-ATA",
            },
            {
              name: "MAPGAP",
            },
            {
              name: "MASLOG",
            },
            {
              name: "PONONG",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TOMAS OPPUS",
            },
          ],
        },
      },
    },
    "SULTAN KUDARAT": {
      name: "SULTAN KUDARAT",
      region: "REGION 12",
      municipalities: {
        BAGUMBAYAN: {
          name: "BAGUMBAYAN",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BAI SARIFINANG",
            },
            {
              name: "BIWANG",
            },
            {
              name: "BUSOK",
            },
            {
              name: "CHUA",
            },
            {
              name: "DAGUMA",
            },
            {
              name: "DALUGA",
            },
            {
              name: "KABULANAN",
            },
            {
              name: "KANULAY",
            },
            {
              name: "KAPAYA",
            },
            {
              name: "KINAYAO",
            },
            {
              name: "MASIAG",
            },
            {
              name: "MONTEVERDE",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SISON",
            },
            {
              name: "SOUTH SEPAKA",
            },
            {
              name: "SUMILIL",
            },
            {
              name: "TITULOK",
            },
            {
              name: "TUKA",
            },
          ],
        },
        BULUAN: {
          name: "BULUAN",
          baranggays: [
            {
              name: "BULUAN",
            },
          ],
        },
        COLUMBIO: {
          name: "COLUMBIO",
          baranggays: [
            {
              name: "BANTANGAN (LASAK)",
            },
            {
              name: "COLUMBIO",
            },
            {
              name: "DATABLAO",
            },
            {
              name: "EDAY",
            },
            {
              name: "ELBEBE",
            },
            {
              name: "LASAK",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LOMOYON",
            },
            {
              name: "MAKAT (SUMALI PAS)",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MAYO",
            },
            {
              name: "NATIVIDAD",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POLOMOLOK",
            },
            {
              name: "SINAPULAN",
            },
            {
              name: "SUCOB",
            },
            {
              name: "TELAFAS",
            },
          ],
        },
        ESPERANZA: {
          name: "ESPERANZA",
          baranggays: [
            {
              name: "ALA",
            },
            {
              name: "DALADAP",
            },
            {
              name: "DUKAY",
            },
            {
              name: "GUIAMALIA",
            },
            {
              name: "ILIAN",
            },
            {
              name: "KANGKONG",
            },
            {
              name: "LAGUINDING",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARGUES",
            },
            {
              name: "NEW PANAY",
            },
            {
              name: "NUMO",
            },
            {
              name: "PAITAN",
            },
            {
              name: "PAMANTINGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGASA",
            },
            {
              name: "SALABACA",
            },
            {
              name: "SALIAO",
            },
            {
              name: "SALUMPING",
            },
            {
              name: "VILLAMOR",
            },
          ],
        },
        ISULAN: {
          name: "ISULAN",
          baranggays: [
            {
              name: "BAMBAD",
            },
            {
              name: "BUAL",
            },
            {
              name: "D'LOTILLA",
            },
            {
              name: "DANSULI",
            },
            {
              name: "IMPAO",
            },
            {
              name: "KALAWAG I (POB.)",
            },
            {
              name: "KALAWAG II (POB.)",
            },
            {
              name: "KALAWAG III (POB.)",
            },
            {
              name: "KENRAM",
            },
            {
              name: "KOLAMBOG",
            },
            {
              name: "KUDANDING",
            },
            {
              name: "LAGANDANG",
            },
            {
              name: "LAGUILAYAN",
            },
            {
              name: "MAPANTIG",
            },
            {
              name: "NEW PANGASINAN",
            },
            {
              name: "SAMPAO",
            },
            {
              name: "TAYUGO",
            },
          ],
        },
        KALAMANSIG: {
          name: "KALAMANSIG",
          baranggays: [
            {
              name: "BANTOGON (SANTA CLARA)",
            },
            {
              name: "CADIZ",
            },
            {
              name: "DATU ITO ANDONG",
            },
            {
              name: "DATU WASAY",
            },
            {
              name: "DUMANGAS NUEVO",
            },
            {
              name: "HINALAAN",
            },
            {
              name: "KALAMANSIG",
            },
            {
              name: "LIMULAN",
            },
            {
              name: "NALILIDAN",
            },
            {
              name: "OBIAL",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PARIL",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SABANAL",
            },
            {
              name: "SANGAY",
            },
            {
              name: "SANTA MARIA",
            },
          ],
        },
        "LAMBAYONG (MARIANO MARCOS)": {
          name: "LAMBAYONG (MARIANO MARCOS)",
          baranggays: [
            {
              name: "CARIDAD (CUYAPON)",
            },
            {
              name: "DIDTARAS",
            },
            {
              name: "GANSING (BILUMEN)",
            },
            {
              name: "KABULAKAN",
            },
            {
              name: "KAPINGKONG",
            },
            {
              name: "KATITISAN",
            },
            {
              name: "LAGAO",
            },
            {
              name: "LAMBAYONG (MARIANO MARCOS)",
            },
            {
              name: "LILIT",
            },
            {
              name: "MADANDING",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MAMALI",
            },
            {
              name: "MATIOMPONG",
            },
            {
              name: "MIDTAPOK",
            },
            {
              name: "NEW CEBU",
            },
            {
              name: "PALUMBI",
            },
            {
              name: "PIDTIGUIAN",
            },
            {
              name: "PIMBALAYAN",
            },
            {
              name: "PINGUIAMAN",
            },
            {
              name: "POBLACION (LAMBAYONG)",
            },
            {
              name: "SADSALAN",
            },
            {
              name: "SENEBEN",
            },
            {
              name: "SIGAYAN",
            },
            {
              name: "TAMBAK",
            },
            {
              name: "TINUMIGUES",
            },
            {
              name: "TUMIAO (TINAGA)",
            },
            {
              name: "UDTONG",
            },
          ],
        },
        LEBAK: {
          name: "LEBAK",
          baranggays: [
            {
              name: "AURELIO F. FREIRES SR. ( POBLACION II)",
            },
            {
              name: "BARURAO",
            },
            {
              name: "BARURAO II",
            },
            {
              name: "BASAK",
            },
            {
              name: "BOLEBOK",
            },
            {
              name: "BULULAWAN",
            },
            {
              name: "CAPILAN",
            },
            {
              name: "CHRISTIANNUEVO",
            },
            {
              name: "DATU KARON",
            },
            {
              name: "KALAMONGOG",
            },
            {
              name: "KEYTODAC",
            },
            {
              name: "KINODALAN",
            },
            {
              name: "LEBAK",
            },
            {
              name: "NEW CALINOG",
            },
            {
              name: "NULING",
            },
            {
              name: "PANSUD",
            },
            {
              name: "PASANDALAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "POBLACION III",
            },
            {
              name: "POLOY-POLOY",
            },
            {
              name: "PURIKAY",
            },
            {
              name: "RAGANDANG",
            },
            {
              name: "SALAMAN",
            },
            {
              name: "SALANGSANG",
            },
            {
              name: "TAGUISA",
            },
            {
              name: "TIBPUAN",
            },
            {
              name: "TRAN",
            },
            {
              name: "VILLAMONTE",
            },
          ],
        },
        LUTAYAN: {
          name: "LUTAYAN",
          baranggays: [
            {
              name: "ANTONG",
            },
            {
              name: "BAYASONG",
            },
            {
              name: "BLINGKONG",
            },
            {
              name: "LUTAYAN",
            },
            {
              name: "LUTAYAN PROPER",
            },
            {
              name: "MAINDANG",
            },
            {
              name: "MAMALI",
            },
            {
              name: "MANILI",
            },
            {
              name: "PALAVILLA",
            },
            {
              name: "SAMPAO",
            },
            {
              name: "SISIMAN",
            },
            {
              name: "TAMNAG (POB.)",
            },
          ],
        },
        MALABANG: {
          name: "MALABANG",
          baranggays: [
            {
              name: "MALABANG",
            },
          ],
        },
        "MARIANO MARCOS": {
          name: "MARIANO MARCOS",
          baranggays: [
            {
              name: "MARIANO MARCOS",
            },
          ],
        },
        PALEMBANG: {
          name: "PALEMBANG",
          baranggays: [
            {
              name: "PALEMBANG",
            },
          ],
        },
        PALIMBANG: {
          name: "PALIMBANG",
          baranggays: [
            {
              name: "AKOL",
            },
            {
              name: "BADIANGON",
            },
            {
              name: "BALIANGO",
            },
            {
              name: "BALWAN (BULAN)",
            },
            {
              name: "BAMBANEN",
            },
            {
              name: "BARANAYAN",
            },
            {
              name: "BARONGIS",
            },
            {
              name: "BATANG-BAGLAS",
            },
            {
              name: "BUTRIL",
            },
            {
              name: "COLOBE (TAGADTAL)",
            },
            {
              name: "DATU MAGUIALES",
            },
            {
              name: "DOMOLOL",
            },
            {
              name: "KABULING",
            },
            {
              name: "KALIBUHAN",
            },
            {
              name: "KANIPAAN",
            },
            {
              name: "KIDAYAN",
            },
            {
              name: "KIPONGET",
            },
            {
              name: "KISEK",
            },
            {
              name: "KRAAN",
            },
            {
              name: "KULONG-KULONG",
            },
            {
              name: "LANGALI",
            },
            {
              name: "LIBUA",
            },
            {
              name: "LIGAO",
            },
            {
              name: "LOPOKEN (LEPOLON)",
            },
            {
              name: "LUMITAN",
            },
            {
              name: "MAGANAO",
            },
            {
              name: "MAGUID",
            },
            {
              name: "MALATUNENG (MALATUNOL)",
            },
            {
              name: "MALISBONG",
            },
            {
              name: "MEDOL",
            },
            {
              name: "MILBUK",
            },
            {
              name: "MINA",
            },
            {
              name: "MOLON",
            },
            {
              name: "NAMAT MASLA",
            },
            {
              name: "NAPNAPON",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TIBUHOL (EAST BADIANGON)",
            },
            {
              name: "WAL",
            },
            {
              name: "WASAG",
            },
          ],
        },
        "PRES. QUIRINO": {
          name: "PRES. QUIRINO",
          baranggays: [
            {
              name: "PRES. QUIRINO",
            },
          ],
        },
        "PRESIDENT QUIRINO": {
          name: "PRESIDENT QUIRINO",
          baranggays: [
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BANNAWAG",
            },
            {
              name: "BAYAWA",
            },
            {
              name: "C. MANGILALA",
            },
            {
              name: "ESTRELLA",
            },
            {
              name: "KALANAWE I",
            },
            {
              name: "KALANAWE II",
            },
            {
              name: "KATICO",
            },
            {
              name: "MALINGON",
            },
            {
              name: "MANGALEN",
            },
            {
              name: "PEDTUBO",
            },
            {
              name: "POBLACION (SAMBULAWAN)",
            },
            {
              name: "ROMUALDEZ",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN PEDRO (TUATO)",
            },
            {
              name: "SINAKULAY",
            },
            {
              name: "SUBEN",
            },
            {
              name: "TINAUNGAN",
            },
            {
              name: "TUAL (LIGUASAN)",
            },
          ],
        },
        "SEN. NINOY AQUINO": {
          name: "SEN. NINOY AQUINO",
          baranggays: [
            {
              name: "BANALI",
            },
            {
              name: "BASAG",
            },
            {
              name: "BUENAFLORES",
            },
            {
              name: "BUGSO",
            },
            {
              name: "BUKLOD",
            },
            {
              name: "GAPOK",
            },
            {
              name: "KADI",
            },
            {
              name: "KAPATAGAN",
            },
            {
              name: "KIADSAM",
            },
            {
              name: "KUDEN",
            },
            {
              name: "KULAMAN",
            },
            {
              name: "LAGUBANG",
            },
            {
              name: "LANGGAL",
            },
            {
              name: "LIMUHAY",
            },
            {
              name: "MALEGDEG",
            },
            {
              name: "MIDTUNGOK",
            },
            {
              name: "NATI",
            },
            {
              name: "SEWOD",
            },
            {
              name: "TACUPIS",
            },
            {
              name: "TINALON",
            },
          ],
        },
        "TACURONG CITY": {
          name: "TACURONG CITY",
          baranggays: [
            {
              name: "BARAS",
            },
            {
              name: "BUENAFLOR",
            },
            {
              name: "CALEAN",
            },
            {
              name: "CARMEN",
            },
            {
              name: "D'LEDESMA",
            },
            {
              name: "GANSING",
            },
            {
              name: "KALANDAGAN",
            },
            {
              name: "LANCHETA",
            },
            {
              name: "LOWER KATUNGAL",
            },
            {
              name: "NEW ISABELA",
            },
            {
              name: "NEW LAGAO",
            },
            {
              name: "NEW PASSI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RAJAH NUDA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN EMMANUEL",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "TACURONG CITY",
            },
            {
              name: "TINA",
            },
            {
              name: "UPPER KATUNGAL",
            },
          ],
        },
      },
    },
    SULU: {
      name: "SULU",
      region: "ARMM",
      municipalities: {
        "HADJI PANGLIMA TAHIL (MARUNGGAS)": {
          name: "HADJI PANGLIMA TAHIL (MARUNGGAS)",
          baranggays: [
            {
              name: "BANGAS (POB.)",
            },
            {
              name: "BUBUAN",
            },
            {
              name: "KABUUKAN",
            },
            {
              name: "PAG-ASINAN",
            },
            {
              name: "TEOMABAL",
            },
          ],
        },
        INDANAN: {
          name: "INDANAN",
          baranggays: [
            {
              name: "ADJID",
            },
            {
              name: "BANGALAN",
            },
            {
              name: "BATO-BATO",
            },
            {
              name: "BUANZA",
            },
            {
              name: "BUD-TARAN",
            },
            {
              name: "BUNUT",
            },
            {
              name: "INDANAN",
            },
            {
              name: "JATI-TUNGGAL",
            },
            {
              name: "KABBON MAAS",
            },
            {
              name: "KAGAY",
            },
            {
              name: "KAJATIAN",
            },
            {
              name: "KAN ISLAM",
            },
            {
              name: "KANDANG TUKAY",
            },
            {
              name: "KARAWAN",
            },
            {
              name: "KATIAN",
            },
            {
              name: "KUPPONG",
            },
            {
              name: "LAMBAYONG",
            },
            {
              name: "LANGPAS",
            },
            {
              name: "LICUP",
            },
            {
              name: "MALIMBAYA",
            },
            {
              name: "MANGGIS",
            },
            {
              name: "MANILOP",
            },
            {
              name: "PALIGUE",
            },
            {
              name: "PANABUAN",
            },
            {
              name: "PANGLIMA MISUARI (SASAK)",
            },
            {
              name: "PASIL",
            },
            {
              name: "POBLACION (INDANAN)",
            },
            {
              name: "SAPAH MALAUM",
            },
            {
              name: "SAWAKI",
            },
            {
              name: "SIONOGAN",
            },
            {
              name: "TAGBAK",
            },
            {
              name: "TIMBANGAN",
            },
            {
              name: "TUBIG DAKULAH",
            },
            {
              name: "TUBIG PARANG",
            },
            {
              name: "TUMANTANGIS",
            },
          ],
        },
        JOLO: {
          name: "JOLO",
          baranggays: [
            {
              name: "ALAT",
            },
            {
              name: "ASTURIAS",
            },
            {
              name: "BUS-BUS",
            },
            {
              name: "CHINESE PIER",
            },
            {
              name: "JOLO",
            },
            {
              name: "SAN RAYMUNDO",
            },
            {
              name: "TAKUT-TAKUT",
            },
            {
              name: "TULAY",
            },
            {
              name: "WALLED CITY (POB.)",
            },
          ],
        },
        "KALINGALAN CALUANG": {
          name: "KALINGALAN CALUANG",
          baranggays: [
            {
              name: "KALINGALAN CALUANG",
            },
            {
              name: "KAMBING",
            },
            {
              name: "KANLAGAY",
            },
            {
              name: "KARUNGDONG (POB.)",
            },
            {
              name: "MASJID BAYLE",
            },
            {
              name: "MASJID PUNJUNGAN",
            },
            {
              name: "PANG",
            },
            {
              name: "PANGDAN PANGDAN",
            },
            {
              name: "PITOGO",
            },
            {
              name: "TUNGGOL",
            },
          ],
        },
        LUGUS: {
          name: "LUGUS",
          baranggays: [
            {
              name: "ALU BUS-BUS",
            },
            {
              name: "ALU-DUYONG",
            },
            {
              name: "BAS LUGUS",
            },
            {
              name: "GAPAS RUGASAN",
            },
            {
              name: "GAPAS TUBIG TUWAK",
            },
            {
              name: "HUWIT-HUWIT BAS NONOK",
            },
            {
              name: "HUWIT-HUWIT PROPER",
            },
            {
              name: "KUTAH PARANG",
            },
            {
              name: "LAHA",
            },
            {
              name: "LARAP",
            },
            {
              name: "LUGUS PROPER",
            },
            {
              name: "MANGKALLAY",
            },
            {
              name: "MANTAN",
            },
            {
              name: "PAIT",
            },
            {
              name: "PARIAN KAYAWAN",
            },
            {
              name: "SIBUL",
            },
            {
              name: "TINGKANGAN",
            },
          ],
        },
        LUNGUS: {
          name: "LUNGUS",
          baranggays: [
            {
              name: "LUNGUS",
            },
          ],
        },
        LUUK: {
          name: "LUUK",
          baranggays: [
            {
              name: "BUAL",
            },
            {
              name: "GUIMBAUN",
            },
            {
              name: "KAN-BULAK",
            },
            {
              name: "KAN-MINDUS",
            },
            {
              name: "LAMBAGO",
            },
            {
              name: "LIANUTAN",
            },
            {
              name: "LINGAH",
            },
            {
              name: "LUUK",
            },
            {
              name: "MANANTI",
            },
            {
              name: "NIOG-NIOG",
            },
            {
              name: "TANDU-BATO",
            },
            {
              name: "TUBIG-PUTI (POB.)",
            },
            {
              name: "TULAYAN ISLAND",
            },
          ],
        },
        MAIMBUNG: {
          name: "MAIMBUNG",
          baranggays: [
            {
              name: "ANAK JATI",
            },
            {
              name: "BATO UGIS",
            },
            {
              name: "BUALO LAHI",
            },
            {
              name: "BUALO LIPID",
            },
            {
              name: "BULABOG",
            },
            {
              name: "DUHOL KABBON",
            },
            {
              name: "GULANGAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "KANDANG",
            },
            {
              name: "KAPOK-PUNGGOL",
            },
            {
              name: "KULASI",
            },
            {
              name: "LABAH",
            },
            {
              name: "LAGASAN ASIBIH",
            },
            {
              name: "LANTONG",
            },
            {
              name: "LAPA",
            },
            {
              name: "LAUD KULASI",
            },
            {
              name: "LAUM MAIMBUNG",
            },
            {
              name: "LOWER TAMBAKING",
            },
            {
              name: "LUNGGANG",
            },
            {
              name: "MAIMBUNG",
            },
            {
              name: "MATATAL",
            },
            {
              name: "PATAO",
            },
            {
              name: "POBLACION (MAIMBUNG)",
            },
            {
              name: "RATAG LIMBON",
            },
            {
              name: "TABU-BATO",
            },
            {
              name: "TANDU PATONG",
            },
            {
              name: "TUBIG-SAMIN",
            },
            {
              name: "UPPER TAMBAKING",
            },
          ],
        },
        MARUNGAS: {
          name: "MARUNGAS",
          baranggays: [
            {
              name: "MARUNGAS",
            },
          ],
        },
        "NEW PANAMAO": {
          name: "NEW PANAMAO",
          baranggays: [
            {
              name: "NEW PANAMAO",
            },
          ],
        },
        "OLD PANAMAO": {
          name: "OLD PANAMAO",
          baranggays: [
            {
              name: "ASIN",
            },
            {
              name: "BAKUD",
            },
            {
              name: "BANGDAY",
            },
            {
              name: "BAUNOH",
            },
            {
              name: "BITANAG",
            },
            {
              name: "BUD SEIT",
            },
            {
              name: "BULANGSI",
            },
            {
              name: "DATAG",
            },
            {
              name: "KAMALIG",
            },
            {
              name: "KAN ASAALI",
            },
            {
              name: "KAN UKOL",
            },
            {
              name: "KAN-DAYOK",
            },
            {
              name: "KAN-SIPAT",
            },
            {
              name: "KAWASAN",
            },
            {
              name: "KULAY-KULAY",
            },
            {
              name: "LAKIT",
            },
            {
              name: "LOWER PATIBULAN",
            },
            {
              name: "LUNGGANG",
            },
            {
              name: "PARANG",
            },
            {
              name: "PUGAD MANAUL",
            },
            {
              name: "PUHAGAN",
            },
            {
              name: "SEIT HIGAD",
            },
            {
              name: "SEIT LAKE (POB.)",
            },
            {
              name: "SU-UH",
            },
            {
              name: "TABU MANUK",
            },
            {
              name: "TANDU-TANDU",
            },
            {
              name: "TAYUNGAN",
            },
            {
              name: "TINAH",
            },
            {
              name: "TUBIG GANTANG",
            },
            {
              name: "TUBIG JATI",
            },
            {
              name: "UPPER PATIBULAN",
            },
          ],
        },
        OMAR: {
          name: "OMAR",
          baranggays: [
            {
              name: "ANDALAN",
            },
            {
              name: "ANGILAN",
            },
            {
              name: "CAPUAL ISLAND",
            },
            {
              name: "HUWIT-HUWIT",
            },
            {
              name: "LAHING-LAHING",
            },
            {
              name: "NIANGKAAN",
            },
            {
              name: "SUCUBAN",
            },
            {
              name: "TANGKUAN",
            },
          ],
        },
        PANDAMI: {
          name: "PANDAMI",
          baranggays: [
            {
              name: "BALIGTANG",
            },
            {
              name: "BUD SIBAUD",
            },
            {
              name: "HAMBILAN",
            },
            {
              name: "KABBON",
            },
            {
              name: "LAHI",
            },
            {
              name: "LAPAK",
            },
            {
              name: "LAUD SIBAUD",
            },
            {
              name: "MALANTA",
            },
            {
              name: "MAMANOK",
            },
            {
              name: "NORTH MANUBUL",
            },
            {
              name: "PANDAMI",
            },
            {
              name: "PARIAN DAKULA",
            },
            {
              name: "SIBAUD PROPER",
            },
            {
              name: "SIGANGGANG",
            },
            {
              name: "SOUTH MANUBUL",
            },
            {
              name: "SUBA-SUBA",
            },
            {
              name: "TENGA MANUBUL",
            },
          ],
        },
        PANGATURAN: {
          name: "PANGATURAN",
          baranggays: [
            {
              name: "PANGATURAN",
            },
          ],
        },
        "PANGLIMA ESTINO (NEW PANAMAO)": {
          name: "PANGLIMA ESTINO (NEW PANAMAO)",
          baranggays: [
            {
              name: "GAGGUIL",
            },
            {
              name: "GATA-GATA",
            },
            {
              name: "JINGGAN",
            },
            {
              name: "KAMIH-PUNGUD",
            },
            {
              name: "LIHBUG KABAW",
            },
            {
              name: "LIKBAH",
            },
            {
              name: "LUBUK-LUBUK",
            },
            {
              name: "MARSADA",
            },
            {
              name: "PAIKSA",
            },
            {
              name: "PANDAKAN",
            },
            {
              name: "PUNAY (POB.)",
            },
            {
              name: "TIPTIPON",
            },
          ],
        },
        PANGUTARAN: {
          name: "PANGUTARAN",
          baranggays: [
            {
              name: "ALU BUNAH",
            },
            {
              name: "BANGKILAY",
            },
            {
              name: "KAWITAN",
            },
            {
              name: "KEHI NIOG",
            },
            {
              name: "LANTONG BABAG",
            },
            {
              name: "LUMAH DAPDAP",
            },
            {
              name: "PANDAN NIOG",
            },
            {
              name: "PANDUCAN",
            },
            {
              name: "PANITIKAN",
            },
            {
              name: "PATUTOL",
            },
            {
              name: "SE-IPANG",
            },
            {
              name: "SIMBAHAN (POB.)",
            },
            {
              name: "SUANG BUNAH",
            },
            {
              name: "TONGGASANG",
            },
            {
              name: "TUBIG NONOK",
            },
            {
              name: "TUBIG SALLANG",
            },
          ],
        },
        PARANG: {
          name: "PARANG",
          baranggays: [
            {
              name: "ALU LAYAG-LAYAG",
            },
            {
              name: "ALU PANGKOH",
            },
            {
              name: "BAGSAK",
            },
            {
              name: "BAWISAN",
            },
            {
              name: "BIID",
            },
            {
              name: "BUKID",
            },
            {
              name: "BULI BAWANG",
            },
            {
              name: "BUTON",
            },
            {
              name: "BUTON MAHABLO",
            },
            {
              name: "DANAPA",
            },
            {
              name: "DUYAN KABAO",
            },
            {
              name: "GIMBA LAGASAN",
            },
            {
              name: "KAHA",
            },
            {
              name: "KAHOY SINAH",
            },
            {
              name: "KANAWAY",
            },
            {
              name: "KUTAH SAIRAP",
            },
            {
              name: "LAGASAN HIGAD",
            },
            {
              name: "LANAO DAKULA",
            },
            {
              name: "LAUM BUWAHAN",
            },
            {
              name: "LAUM SUWAH",
            },
            {
              name: "LIANG",
            },
            {
              name: "LINUHO",
            },
            {
              name: "LIPUNOS",
            },
            {
              name: "LOWER SAMPUNAY",
            },
            {
              name: "LUMBAAN MAHABA",
            },
            {
              name: "LUNGAN GITONG",
            },
            {
              name: "LUPA ABU",
            },
            {
              name: "NONOKAN",
            },
            {
              name: "PARANG",
            },
            {
              name: "PAUGAN",
            },
            {
              name: "PAYUHAN",
            },
            {
              name: "PIYAHAN",
            },
            {
              name: "POBLACION (PARANG)",
            },
            {
              name: "SALDANG",
            },
            {
              name: "SAMPUNAY",
            },
            {
              name: "SILANGKAN",
            },
            {
              name: "TAINGTING",
            },
            {
              name: "TIKONG",
            },
            {
              name: "TUKAY",
            },
            {
              name: "TUMANGAS",
            },
            {
              name: "WANNI PIYANJIHAN",
            },
          ],
        },
        PATA: {
          name: "PATA",
          baranggays: [
            {
              name: "ANDALAN",
            },
            {
              name: "DAUNGDONG",
            },
            {
              name: "KAMAWI",
            },
            {
              name: "KANJARANG",
            },
            {
              name: "KAYAWAN (POB.)",
            },
            {
              name: "KIPUT",
            },
            {
              name: "LIKUD",
            },
            {
              name: "LUUK-TULAY",
            },
            {
              name: "NIOG-NIOG",
            },
            {
              name: "PATA",
            },
            {
              name: "PATIAN",
            },
            {
              name: "PISAK-PISAK",
            },
            {
              name: "SAIMBANGON",
            },
            {
              name: "SANGKAP",
            },
            {
              name: "TIMUDDAS",
            },
          ],
        },
        PATIKUL: {
          name: "PATIKUL",
          baranggays: [
            {
              name: "ANULING",
            },
            {
              name: "BAKONG",
            },
            {
              name: "BANGKAL",
            },
            {
              name: "BONBON",
            },
            {
              name: "BUHANGINAN (DARAYAN)",
            },
            {
              name: "BUNGKAUNG",
            },
            {
              name: "DANAG",
            },
            {
              name: "GANDASULI",
            },
            {
              name: "IGASAN",
            },
            {
              name: "KABBON TAKAS",
            },
            {
              name: "KADDAY MAMPALLAM",
            },
            {
              name: "KAN AGUE",
            },
            {
              name: "KAUNAYAN",
            },
            {
              name: "LANGHUB",
            },
            {
              name: "LATIH",
            },
            {
              name: "LIANG",
            },
            {
              name: "MALIGAY",
            },
            {
              name: "MAUBOH",
            },
            {
              name: "PANGDANON",
            },
            {
              name: "PANGLAYAHAN",
            },
            {
              name: "PANSUL",
            },
            {
              name: "PATIKUL",
            },
            {
              name: "PATIKUL HIGAD",
            },
            {
              name: "SANDAH",
            },
            {
              name: "TAGLIBI (POB.)",
            },
            {
              name: "TANDU-BAGUA",
            },
            {
              name: "TANUM",
            },
            {
              name: "TAUNG",
            },
            {
              name: "TIMPOK",
            },
            {
              name: "TUGAS",
            },
            {
              name: "UMANGAY",
            },
          ],
        },
        SIASI: {
          name: "SIASI",
          baranggays: [
            {
              name: "BAKUD",
            },
            {
              name: "BUAN",
            },
            {
              name: "BULANSING TARA",
            },
            {
              name: "BULIHKULLUL",
            },
            {
              name: "CAMPO ISLAM",
            },
            {
              name: "DUGGO",
            },
            {
              name: "DUHOL TARA",
            },
            {
              name: "EAST KUNGTAD",
            },
            {
              name: "EAST SISANGAT",
            },
            {
              name: "IPIL",
            },
            {
              name: "JAMBANGAN",
            },
            {
              name: "KABUBU",
            },
            {
              name: "KONG-KONG LAMINUSA",
            },
            {
              name: "KUD-KUD",
            },
            {
              name: "KUNGTAD WEST",
            },
            {
              name: "LATUNG",
            },
            {
              name: "LUUK LAMINUSA",
            },
            {
              name: "LUUK TARA",
            },
            {
              name: "MANTA",
            },
            {
              name: "MINAPAN",
            },
            {
              name: "NIPA-NIPA",
            },
            {
              name: "NORTH LAUD",
            },
            {
              name: "NORTH MANTA",
            },
            {
              name: "NORTH MUSU LAUD",
            },
            {
              name: "NORTH SILUMPAK",
            },
            {
              name: "PISLONG",
            },
            {
              name: "POBLACION (CAMPO BARO)",
            },
            {
              name: "PUNUNGAN",
            },
            {
              name: "PUUKAN LAMINUSA",
            },
            {
              name: "RATAG",
            },
            {
              name: "SABLAY",
            },
            {
              name: "SARUKOT",
            },
            {
              name: "SIASI",
            },
            {
              name: "SIBURI",
            },
            {
              name: "SINGKO",
            },
            {
              name: "SIOLAKAN",
            },
            {
              name: "SIOWING",
            },
            {
              name: "SIPANDING",
            },
            {
              name: "SISANGAT",
            },
            {
              name: "SIUNDOH",
            },
            {
              name: "SOUTH MUSU LAUD",
            },
            {
              name: "SOUTH SILUMPAK",
            },
            {
              name: "SOUTHWESTERN BULIKULLUL",
            },
            {
              name: "SUBAH BUAYA",
            },
            {
              name: "TAMPAKAN LAMINUSA",
            },
            {
              name: "TENGAH LAMINUSA",
            },
            {
              name: "TONG LAMINUSA",
            },
            {
              name: "TONG-TONG",
            },
            {
              name: "TONGLABAH",
            },
            {
              name: "TUBIG KUTAH",
            },
            {
              name: "TULLING",
            },
          ],
        },
        TALIPAO: {
          name: "TALIPAO",
          baranggays: [
            {
              name: "ANDALAN",
            },
            {
              name: "BAGSAK",
            },
            {
              name: "BANDANG",
            },
            {
              name: "BILAAN (POB.)",
            },
            {
              name: "BUD BUNGA",
            },
            {
              name: "BUNTOD",
            },
            {
              name: "BUROH",
            },
            {
              name: "DALIH",
            },
            {
              name: "GATA",
            },
            {
              name: "KABATUHAN BILAAN",
            },
            {
              name: "KABATUHAN TIIS",
            },
            {
              name: "KABUNGKOL",
            },
            {
              name: "KAGAY",
            },
            {
              name: "KAHAWA",
            },
            {
              name: "KANDAGA",
            },
            {
              name: "KANLIBOT",
            },
            {
              name: "KIUTAAN",
            },
            {
              name: "KUHAW",
            },
            {
              name: "KULAMBOH",
            },
            {
              name: "KUTTONG",
            },
            {
              name: "LAGTOH",
            },
            {
              name: "LAMBANAH",
            },
            {
              name: "LIBAN",
            },
            {
              name: "LIU-BUD PANTAO",
            },
            {
              name: "LOWER BINUANG",
            },
            {
              name: "LOWER KAMUNTAYAN",
            },
            {
              name: "LOWER LAUS",
            },
            {
              name: "LOWER SINUMAAN",
            },
            {
              name: "LOWER TALIPAO",
            },
            {
              name: "LUMBAYAO",
            },
            {
              name: "LUMPING PIGIH DAHO",
            },
            {
              name: "LUNGKIABAN",
            },
            {
              name: "MABAHAY",
            },
            {
              name: "MAHALA",
            },
            {
              name: "MAMPALLAM",
            },
            {
              name: "MARSADA",
            },
            {
              name: "MAUBOH",
            },
            {
              name: "MUNGIT-MUNGIT",
            },
            {
              name: "NIOG-SANGAHAN",
            },
            {
              name: "PANTAO",
            },
            {
              name: "SAMAK",
            },
            {
              name: "TALIPAO",
            },
            {
              name: "TALIPAO PROPER",
            },
            {
              name: "TAMPAKAN",
            },
            {
              name: "TIIS",
            },
            {
              name: "TINGGAH",
            },
            {
              name: "TUBOD",
            },
            {
              name: "TUYANG",
            },
            {
              name: "UPPER BINUANG",
            },
            {
              name: "UPPER KAMUNTAYAN",
            },
            {
              name: "UPPER LAUS",
            },
            {
              name: "UPPER SINUMAAN",
            },
            {
              name: "UPPER TALIPAO",
            },
          ],
        },
        TAPUL: {
          name: "TAPUL",
          baranggays: [
            {
              name: "ALU-KABINGAAN",
            },
            {
              name: "BANTING",
            },
            {
              name: "HAWAN",
            },
            {
              name: "KALANG (POB.)",
            },
            {
              name: "KAMAUNGGI",
            },
            {
              name: "KANAWAY",
            },
            {
              name: "KANMANGON",
            },
            {
              name: "KAUMPANG",
            },
            {
              name: "PAGATPAT",
            },
            {
              name: "PANGDAN",
            },
            {
              name: "PUOK",
            },
            {
              name: "SAYLI",
            },
            {
              name: "SUMAMBAT",
            },
            {
              name: "TANGKAPAAN",
            },
            {
              name: "TAPUL",
            },
            {
              name: "TULAKAN",
            },
          ],
        },
        TONGKIL: {
          name: "TONGKIL",
          baranggays: [
            {
              name: "BAKKAAN",
            },
            {
              name: "BANGALAW",
            },
            {
              name: "DANAO",
            },
            {
              name: "DUNGON",
            },
            {
              name: "KAHIKUKUK",
            },
            {
              name: "LUUK (POB.)",
            },
            {
              name: "NORTH PAAROL",
            },
            {
              name: "SIGUMBAL",
            },
            {
              name: "SOUTH PAAROL",
            },
            {
              name: "TABIALAN",
            },
            {
              name: "TAINGA-BAKKAO",
            },
            {
              name: "TAMBUN-BUN",
            },
            {
              name: "TATTALAN",
            },
            {
              name: "TINUTUNGAN",
            },
            {
              name: "TONGKIL",
            },
          ],
        },
      },
    },
    "SURIGAO DEL NORTE": {
      name: "SURIGAO DEL NORTE",
      region: "REGION 13",
      municipalities: {
        ALEGRIA: {
          name: "ALEGRIA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "ALIPAO",
            },
            {
              name: "ANAHAW",
            },
            {
              name: "BUDLINGIN",
            },
            {
              name: "CAMP EDUARD (GEOTINA)",
            },
            {
              name: "FERLDA",
            },
            {
              name: "GAMUTON",
            },
            {
              name: "JULIO OUANO (POB.)",
            },
            {
              name: "OMBONG",
            },
            {
              name: "POBLACION (ALEGRIA)",
            },
            {
              name: "PONGTUD",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO",
            },
          ],
        },
        BACUAG: {
          name: "BACUAG",
          baranggays: [
            {
              name: "BACUAG",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CAMBUAYON",
            },
            {
              name: "CAMPO",
            },
            {
              name: "DUGSANGON",
            },
            {
              name: "PAUTAO",
            },
            {
              name: "PAYAPAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNGTOD",
            },
            {
              name: "SANTO ROSARIO",
            },
          ],
        },
        BURGOS: {
          name: "BURGOS",
          baranggays: [
            {
              name: "BAYBAY",
            },
            {
              name: "BITAUG",
            },
            {
              name: "BURGOS",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "SAN MATEO",
            },
          ],
        },
        CLAVER: {
          name: "CLAVER",
          baranggays: [
            {
              name: "MAGALLANES",
            },
            {
              name: "PANATAO",
            },
            {
              name: "SAPA",
            },
            {
              name: "TAGANITO",
            },
            {
              name: "TAYAGA (POB. EAST)",
            },
            {
              name: "URBIZTONDO",
            },
            {
              name: "WANGKE",
            },
          ],
        },
        DAPA: {
          name: "DAPA",
          baranggays: [
            {
              name: "BAGAKAY",
            },
            {
              name: "BARANGAY 1 (POB.)",
            },
            {
              name: "BARANGAY 10 (POB.)",
            },
            {
              name: "BARANGAY 11 (POB.)",
            },
            {
              name: "BARANGAY 12 (POB.)",
            },
            {
              name: "BARANGAY 13 (POB.)",
            },
            {
              name: "BARANGAY 2 (POB.)",
            },
            {
              name: "BARANGAY 3 (POB.)",
            },
            {
              name: "BARANGAY 4 (POB.)",
            },
            {
              name: "BARANGAY 5 (POB.)",
            },
            {
              name: "BARANGAY 6 (POB.)",
            },
            {
              name: "BARANGAY 7 (POB.)",
            },
            {
              name: "BARANGAY 8 (POB.)",
            },
            {
              name: "BARANGAY 9 (POB.)",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABAWA",
            },
            {
              name: "CAMBAS-AC",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "CORREGIDOR",
            },
            {
              name: "DAGOHOY",
            },
            {
              name: "DAPA",
            },
            {
              name: "DON PAULINO",
            },
            {
              name: "JUBANG",
            },
            {
              name: "MONTSERRAT",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTA FELOMINA",
            },
            {
              name: "UNION",
            },
          ],
        },
        "DEL CARMEN": {
          name: "DEL CARMEN",
          baranggays: [
            {
              name: "ANTIPOLO",
            },
            {
              name: "BAGAKAY (ALBURO)",
            },
            {
              name: "BITOON",
            },
            {
              name: "CABUGAO",
            },
            {
              name: "CANCOHOY",
            },
            {
              name: "CAUB",
            },
            {
              name: "DEL CARMEN",
            },
            {
              name: "DEL CARMEN (POB.)",
            },
            {
              name: "DOMOYOG",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "HALIAN",
            },
            {
              name: "JAMOYAON",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LOBOGON",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAYAK",
            },
            {
              name: "TUBORAN",
            },
          ],
        },
        DINAGAT: {
          name: "DINAGAT",
          baranggays: [
            {
              name: "DINAGAT",
            },
          ],
        },
        "GENERAL LUNA": {
          name: "GENERAL LUNA",
          baranggays: [
            {
              name: "ANAJAWAN",
            },
            {
              name: "CABITOONAN",
            },
            {
              name: "CATANGNAN",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "CORAZON",
            },
            {
              name: "DAKU",
            },
            {
              name: "GENERAL LUNA",
            },
            {
              name: "LA JANUZA",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALINAO",
            },
            {
              name: "POBLACION I (PUROK I)",
            },
            {
              name: "POBLACION II (PUROK II)",
            },
            {
              name: "POBLACION III (PUROK III)",
            },
            {
              name: "POBLACION IV (PUROK IV)",
            },
            {
              name: "POBLACION V (PUROK V)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SUYANGAN",
            },
            {
              name: "TAWIN-TAWIN",
            },
          ],
        },
        GIGAQUIT: {
          name: "GIGAQUIT",
          baranggays: [
            {
              name: "ALAMBIQUE (POB.)",
            },
            {
              name: "ANIBONGAN",
            },
            {
              name: "CAM-BOAYON",
            },
            {
              name: "CAMAM-ONAN",
            },
            {
              name: "GIGAQUIT",
            },
            {
              name: "IPIL (POB.)",
            },
            {
              name: "LAHI",
            },
            {
              name: "MAHANUB",
            },
            {
              name: "PONIENTE",
            },
            {
              name: "SAN ANTONIO (BONOT)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SICO-SICO",
            },
            {
              name: "VILLAFLOR",
            },
            {
              name: "VILLAFRANCA",
            },
          ],
        },
        LIBJO: {
          name: "LIBJO",
          baranggays: [
            {
              name: "LIBJO",
            },
          ],
        },
        LORETO: {
          name: "LORETO",
          baranggays: [
            {
              name: "LORETO",
            },
          ],
        },
        MAINIT: {
          name: "MAINIT",
          baranggays: [
            {
              name: "BINGA",
            },
            {
              name: "BOBONA-ON",
            },
            {
              name: "CANTUGAS",
            },
            {
              name: "DAYANO",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGPAYANG",
            },
            {
              name: "MAGSAYSAY (POB.)",
            },
            {
              name: "MAINIT",
            },
            {
              name: "MANSAYAO",
            },
            {
              name: "MARAYAG",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "PACO",
            },
            {
              name: "QUEZON (POB.)",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SIANA",
            },
            {
              name: "SILOP",
            },
            {
              name: "TAGBUYAWAN",
            },
            {
              name: "TAPI-AN",
            },
            {
              name: "TOLINGON",
            },
          ],
        },
        MALIMONO: {
          name: "MALIMONO",
          baranggays: [
            {
              name: "BUNYASAN",
            },
            {
              name: "CAGTINAE",
            },
            {
              name: "CAN-AGA",
            },
            {
              name: "CANSAYONG",
            },
            {
              name: "CANTAPOY",
            },
            {
              name: "CAYAWAN",
            },
            {
              name: "DORO (BINOCARAN)",
            },
            {
              name: "HANAGDONG",
            },
            {
              name: "KARIHATAG",
            },
            {
              name: "MALIMONO",
            },
            {
              name: "MASGAD",
            },
            {
              name: "PILI",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "TINAGO",
            },
            {
              name: "VILLARIZA",
            },
          ],
        },
        MELGAR: {
          name: "MELGAR",
          baranggays: [
            {
              name: "MELGAR",
            },
          ],
        },
        PILAR: {
          name: "PILAR",
          baranggays: [
            {
              name: "ASINAN (POB.)",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "CENTRO (POB.)",
            },
            {
              name: "CONSOLACION",
            },
            {
              name: "DATU",
            },
            {
              name: "DAYAOHAY",
            },
            {
              name: "JABOY",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "PILAR",
            },
            {
              name: "PILARING (POB.)",
            },
            {
              name: "PUNTA (POB.)",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ROQUE",
            },
          ],
        },
        PLACER: {
          name: "PLACER",
          baranggays: [
            {
              name: "AMOSLOG",
            },
            {
              name: "ANISLAGAN",
            },
            {
              name: "BAD-AS",
            },
            {
              name: "BOYONGAN",
            },
            {
              name: "BUGAS-BUGAS",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "ELLAPERAL (NONOK)",
            },
            {
              name: "IPIL (POB.)",
            },
            {
              name: "LAKANDULA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MACALAYA",
            },
            {
              name: "MAGSAYSAY (POB.)",
            },
            {
              name: "MAGUPANGE",
            },
            {
              name: "PANANAY-AN",
            },
            {
              name: "PANHUTONGAN",
            },
            {
              name: "PLACER",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANI-SANI",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SUYOC",
            },
            {
              name: "TAGBONGABONG",
            },
          ],
        },
        "SAN BENITO": {
          name: "SAN BENITO",
          baranggays: [
            {
              name: "BONGDO",
            },
            {
              name: "MARIBOJOC",
            },
            {
              name: "NUEVO CAMPO",
            },
            {
              name: "SAN BENITO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "TALISAY (POB.)",
            },
          ],
        },
        "SAN FRANCISCO (ANAO-AON)": {
          name: "SAN FRANCISCO (ANAO-AON)",
          baranggays: [
            {
              name: "AMONTAY",
            },
            {
              name: "BALITE",
            },
            {
              name: "BANBANON",
            },
            {
              name: "DIAZ",
            },
            {
              name: "HONRADO",
            },
            {
              name: "JUBGAN",
            },
            {
              name: "LINONGGANAN",
            },
            {
              name: "MACOPA",
            },
            {
              name: "MAGTANGALE",
            },
            {
              name: "OSLAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FRANCISCO (ANAO-AON)",
            },
          ],
        },
        "SAN ISIDRO": {
          name: "SAN ISIDRO",
          baranggays: [
            {
              name: "BUHING CALIPAY",
            },
            {
              name: "DEL CARMEN (POB.)",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "MACAPAGAL",
            },
            {
              name: "PACIFICO",
            },
            {
              name: "PELAEZ",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTA PAZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAMBACAN",
            },
            {
              name: "TIGASAO",
            },
          ],
        },
        "SANTA MONICA": {
          name: "SANTA MONICA",
          baranggays: [
            {
              name: "SANTA MONICA",
            },
          ],
        },
        "SANTA MONICA (SAPAO)": {
          name: "SANTA MONICA (SAPAO)",
          baranggays: [
            {
              name: "ABAD SANTOS",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "BAILAN",
            },
            {
              name: "GARCIA",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABUHAY (POB.)",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "RIZAL",
            },
            {
              name: "T. ARLAN (POB.)",
            },
            {
              name: "TANGBO",
            },
          ],
        },
        SISON: {
          name: "SISON",
          baranggays: [
            {
              name: "BIYABID",
            },
            {
              name: "GACEPAN",
            },
            {
              name: "IMA",
            },
            {
              name: "LOWER PATAG",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAYAG",
            },
            {
              name: "POBLACION (SAN PEDRO)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SISON",
            },
            {
              name: "TAGBAYANI",
            },
            {
              name: "TINOGPAHAN",
            },
            {
              name: "UPPER PATAG",
            },
          ],
        },
        SOCORRO: {
          name: "SOCORRO",
          baranggays: [
            {
              name: "ALBINO TARUC",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "HELENE",
            },
            {
              name: "HONRADO",
            },
            {
              name: "NAVARRO (POB.)",
            },
            {
              name: "NUEVA ESTRELLA",
            },
            {
              name: "PAMOSAINGAN",
            },
            {
              name: "RIZAL (POB.)",
            },
            {
              name: "SALOG",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SERING",
            },
            {
              name: "SOCORRO",
            },
            {
              name: "SONGKOY",
            },
            {
              name: "SUDLON",
            },
          ],
        },
        "SURIGAO CITY": {
          name: "SURIGAO CITY",
          baranggays: [
            {
              name: "ALANG-ALANG",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "ANOMAR",
            },
            {
              name: "AURORA",
            },
            {
              name: "BALIBAYON",
            },
            {
              name: "BAYBAY",
            },
            {
              name: "BILABID",
            },
            {
              name: "BITAUGAN",
            },
            {
              name: "BONIFACIO",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CABONGBONGAN",
            },
            {
              name: "CAGNIOG",
            },
            {
              name: "CAGUTSAN",
            },
            {
              name: "CANLANIPA",
            },
            {
              name: "CANTIASAY",
            },
            {
              name: "CAPALAYAN",
            },
            {
              name: "CATADMAN",
            },
            {
              name: "DANAO",
            },
            {
              name: "DANAWAN",
            },
            {
              name: "DAY-ASAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "LIBUAC",
            },
            {
              name: "LIPATA",
            },
            {
              name: "LISONDRA",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MABUA",
            },
            {
              name: "MANYAGAO",
            },
            {
              name: "MAPAWA",
            },
            {
              name: "MAT-I",
            },
            {
              name: "NABAGO",
            },
            {
              name: "NONOC",
            },
            {
              name: "OROK",
            },
            {
              name: "POCTOY",
            },
            {
              name: "PUNTA BILAR",
            },
            {
              name: "QUEZON",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PEDRO (HANIGAD)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SERNA (BAD-ASAY)",
            },
            {
              name: "SIDLAKAN",
            },
            {
              name: "SILOP",
            },
            {
              name: "SUGBAY",
            },
            {
              name: "SUKAILANG",
            },
            {
              name: "SURIGAO CITY",
            },
            {
              name: "TAFT (POB.)",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TOGBONGON",
            },
            {
              name: "TRINIDAD",
            },
            {
              name: "WASHINGTON (POB.)",
            },
            {
              name: "ZARAGOZA",
            },
          ],
        },
        "TAGANA-AN": {
          name: "TAGANA-AN",
          baranggays: [
            {
              name: "AURORA (POB.)",
            },
            {
              name: "AZUCENA (POB.)",
            },
            {
              name: "BANBAN",
            },
            {
              name: "CAWILAN",
            },
            {
              name: "FABIO",
            },
            {
              name: "HIMAMAUG",
            },
            {
              name: "LAUREL",
            },
            {
              name: "LOWER LIBAS",
            },
            {
              name: "OPONG",
            },
            {
              name: "PATINO",
            },
            {
              name: "SAMPAGUITA (POB.)",
            },
            {
              name: "TAGANA-AN",
            },
            {
              name: "TALAVERA",
            },
            {
              name: "UNION",
            },
            {
              name: "UPPER LIBAS",
            },
          ],
        },
        TUBAJON: {
          name: "TUBAJON",
          baranggays: [
            {
              name: "TUBAJON",
            },
          ],
        },
        TUBOD: {
          name: "TUBOD",
          baranggays: [
            {
              name: "CAPAYAHAN",
            },
            {
              name: "CAWILAN",
            },
            {
              name: "DEL ROSARIO",
            },
            {
              name: "MARGA",
            },
            {
              name: "MOTORPOOL",
            },
            {
              name: "POBLACION (TUBOD)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "TIMAMANA",
            },
            {
              name: "TUBOD",
            },
          ],
        },
      },
    },
    "SURIGAO DEL SUR": {
      name: "SURIGAO DEL SUR",
      region: "REGION 13",
      municipalities: {
        BAROBO: {
          name: "BAROBO",
          baranggays: [
            {
              name: "AMAGA",
            },
            {
              name: "BAHI",
            },
            {
              name: "BAROBO",
            },
            {
              name: "CABACUNGAN",
            },
            {
              name: "CAMBAGANG",
            },
            {
              name: "CAUSWAGAN",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "DUGHAN",
            },
            {
              name: "GAMUT",
            },
            {
              name: "GUINHALINAN",
            },
            {
              name: "JAVIER",
            },
            {
              name: "KINAYAN",
            },
            {
              name: "MAMIS",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SUA",
            },
            {
              name: "SUDLON",
            },
            {
              name: "TAMBIS",
            },
            {
              name: "UNIDAD",
            },
            {
              name: "WAKAT",
            },
          ],
        },
        BAYABAS: {
          name: "BAYABAS",
          baranggays: [
            {
              name: "AMAG",
            },
            {
              name: "BALETE (POB.)",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "CABUGO",
            },
            {
              name: "CAGBAOTO",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "MAGOBAWOK",
            },
            {
              name: "PANAOSAWON",
            },
          ],
        },
        BISLIG: {
          name: "BISLIG",
          baranggays: [
            {
              name: "BISLIG",
            },
          ],
        },
        "BISLIG CITY": {
          name: "BISLIG CITY",
          baranggays: [
            {
              name: "BUCTO",
            },
            {
              name: "BURBOANAN",
            },
            {
              name: "CAGUYAO",
            },
            {
              name: "COLETO",
            },
            {
              name: "CUMAWAS",
            },
            {
              name: "KAHAYAG",
            },
            {
              name: "LABISMA",
            },
            {
              name: "LAWIGAN",
            },
            {
              name: "MAHARLIKA",
            },
            {
              name: "MANGAGOY (CITY DOWNTOWN)",
            },
            {
              name: "MONE",
            },
            {
              name: "PAMANLINAN",
            },
            {
              name: "PAMAYPAYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SAN ISIDRO (BAGNAN)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN ROQUE (CADANGLASAN)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SIBAROY",
            },
            {
              name: "TABON",
            },
            {
              name: "TUMANAN",
            },
          ],
        },
        CAGWAIT: {
          name: "CAGWAIT",
          baranggays: [
            {
              name: "ARAS-ASAN",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BITAUGAN EAST",
            },
            {
              name: "BITAUGAN WEST",
            },
            {
              name: "LA PURISIMA (PALHE)",
            },
            {
              name: "LACTUDAN",
            },
            {
              name: "MAT-E",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAWAGAN",
            },
            {
              name: "TUBO-TUBO",
            },
            {
              name: "UNIDAD",
            },
          ],
        },
        CANTILAN: {
          name: "CANTILAN",
          baranggays: [
            {
              name: "ANTAO",
            },
            {
              name: "BUGSUKAN",
            },
            {
              name: "BUNTALID",
            },
            {
              name: "CABANGAHAN",
            },
            {
              name: "CABAS-AN",
            },
            {
              name: "CALAGDAAN",
            },
            {
              name: "CANCAVAN",
            },
            {
              name: "CANTILAN",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CONSUELO",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GACUB",
            },
            {
              name: "GENERAL ISLAND",
            },
            {
              name: "HINAPUYAN",
            },
            {
              name: "LININTI-AN (POB.)",
            },
            {
              name: "LOBO",
            },
            {
              name: "MAGASANG",
            },
            {
              name: "MAGOSILOM (POB.)",
            },
            {
              name: "PAG-ANTAYAN",
            },
            {
              name: "PALASAO",
            },
            {
              name: "PARANG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUYAT",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "STA. CRUZ",
            },
            {
              name: "TAPI",
            },
            {
              name: "TIGABONG",
            },
          ],
        },
        CARRASCAL: {
          name: "CARRASCAL",
          baranggays: [
            {
              name: "ADLAY",
            },
            {
              name: "BABUYAN",
            },
            {
              name: "BACOLOD",
            },
            {
              name: "BAYBAY (POB.)",
            },
            {
              name: "BON-OT",
            },
            {
              name: "CAGLAYAG",
            },
            {
              name: "CARRASCAL",
            },
            {
              name: "DAHICAN",
            },
            {
              name: "DOYOS (POB.)",
            },
            {
              name: "EMBARCADERO (POB.)",
            },
            {
              name: "GAMUTON",
            },
            {
              name: "PANIKIAN",
            },
            {
              name: "PANTUKAN",
            },
            {
              name: "SACA (POB.)",
            },
            {
              name: "TAG-ANITO",
            },
          ],
        },
        CORTES: {
          name: "CORTES",
          baranggays: [
            {
              name: "BALIBADON",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CAPANDAN",
            },
            {
              name: "CORTES",
            },
            {
              name: "MABAHIN",
            },
            {
              name: "MADRELINO",
            },
            {
              name: "MANLICO",
            },
            {
              name: "MATHO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "TAG-ANONGAN",
            },
            {
              name: "TIGAO",
            },
            {
              name: "TUBORAN",
            },
            {
              name: "UBA",
            },
          ],
        },
        HINATUAN: {
          name: "HINATUAN",
          baranggays: [
            {
              name: "BACULIN",
            },
            {
              name: "BENIGNO AQUINO (ZONE I) POB.",
            },
            {
              name: "BIGAAN",
            },
            {
              name: "CAMBATONG",
            },
            {
              name: "CAMPA",
            },
            {
              name: "DUGMANON",
            },
            {
              name: "HARIP",
            },
            {
              name: "HINATUAN",
            },
            {
              name: "LA CASA (POB.)",
            },
            {
              name: "LOYOLA",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "PAGTIGUI-AN (BITOON)",
            },
            {
              name: "POCTO",
            },
            {
              name: "PORT LAMON",
            },
            {
              name: "ROXAS",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SASA",
            },
            {
              name: "TAGASAKA",
            },
            {
              name: "TAGBOBONGA",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TARUSAN",
            },
            {
              name: "TIDMAN",
            },
            {
              name: "TIWI",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE II STO. NIÑO (POB.)",
            },
            {
              name: "ZONE III MAHARLIKA (POB.)",
            },
          ],
        },
        LANUZA: {
          name: "LANUZA",
          baranggays: [
            {
              name: "AGSAM",
            },
            {
              name: "BOCAWE",
            },
            {
              name: "BUNGA",
            },
            {
              name: "GAMUTON",
            },
            {
              name: "HABAG",
            },
            {
              name: "LANUZA",
            },
            {
              name: "MAMPI",
            },
            {
              name: "NURCIA",
            },
            {
              name: "PAKWAN",
            },
            {
              name: "SIBAHAY",
            },
            {
              name: "ZONE I (POB.)",
            },
            {
              name: "ZONE II (POB.)",
            },
            {
              name: "ZONE III (POB.)",
            },
            {
              name: "ZONE IV (POB.)",
            },
          ],
        },
        LIANGA: {
          name: "LIANGA",
          baranggays: [
            {
              name: "ANIBONGAN",
            },
            {
              name: "BAN-AS",
            },
            {
              name: "BANAHAO",
            },
            {
              name: "BAUCAWE",
            },
            {
              name: "DIATAGON",
            },
            {
              name: "GANAYON",
            },
            {
              name: "LIANGA",
            },
            {
              name: "LIATIMCO",
            },
            {
              name: "MANYAYAY",
            },
            {
              name: "PAYASAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAINT CHRISTINE",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
          ],
        },
        LINGIG: {
          name: "LINGIG",
          baranggays: [
            {
              name: "ANIBONGAN",
            },
            {
              name: "BARCELONA",
            },
            {
              name: "BOGAK",
            },
            {
              name: "BONGAN",
            },
            {
              name: "HANDAMAYAN",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MANDUS",
            },
            {
              name: "MANSA-ILAO",
            },
            {
              name: "PAGTILA-AN",
            },
            {
              name: "PALO ALTO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RAJAH CABUNGSO-AN",
            },
            {
              name: "SABANG",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "TAGPOPORAN (TAGPUPURAN)",
            },
            {
              name: "UNION",
            },
            {
              name: "VALENCIA",
            },
          ],
        },
        MADRID: {
          name: "MADRID",
          baranggays: [
            {
              name: "BAGSAC",
            },
            {
              name: "BAYOGO",
            },
            {
              name: "LINIBONAN",
            },
            {
              name: "MADRID",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANGA",
            },
            {
              name: "PANAYOGON",
            },
            {
              name: "PATONG PATONG",
            },
            {
              name: "QUIRINO (POBLACION)",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SONGKIT",
            },
            {
              name: "UNION",
            },
          ],
        },
        MARIHATAG: {
          name: "MARIHATAG",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "AMONTAY",
            },
            {
              name: "ANTIPOLO",
            },
            {
              name: "AROROGAN",
            },
            {
              name: "BAYAN",
            },
            {
              name: "MAHABA",
            },
            {
              name: "MARARAG",
            },
            {
              name: "MARIHATAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTA CRUZ",
            },
          ],
        },
        "SAN AGUSTIN": {
          name: "SAN AGUSTIN",
          baranggays: [
            {
              name: "SANTO NIÑO",
            },
          ],
        },
        "SAN MIGUEL": {
          name: "SAN MIGUEL",
          baranggays: [
            {
              name: "SAN MIGUEL",
            },
          ],
        },
        TAGBINA: {
          name: "TAGBINA",
          baranggays: [
            {
              name: "BATUNAN",
            },
            {
              name: "CARPENITO",
            },
            {
              name: "DOÑA CARMEN",
            },
            {
              name: "HINAGDANAN",
            },
            {
              name: "KAHAYAGAN",
            },
            {
              name: "LAGO",
            },
            {
              name: "MAGLAMBING",
            },
            {
              name: "MAGLATAB",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALIXI",
            },
            {
              name: "MANAMBIA",
            },
            {
              name: "OSMEÑA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "QUEZON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SANTA JUANA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SAYON",
            },
            {
              name: "SORIANO",
            },
            {
              name: "TAGBINA",
            },
            {
              name: "TAGONGON",
            },
            {
              name: "TRINIDAD",
            },
            {
              name: "UGOBAN",
            },
            {
              name: "VILLAVERDE",
            },
          ],
        },
        TAGO: {
          name: "TAGO",
          baranggays: [
            {
              name: "ALBA",
            },
            {
              name: "ANAHAO BAG-O",
            },
            {
              name: "ANAHAO DAAN",
            },
            {
              name: "BADONG",
            },
            {
              name: "BAJAO",
            },
            {
              name: "BANGSUD",
            },
            {
              name: "CABANGAHAN",
            },
            {
              name: "CAGDAPAO",
            },
            {
              name: "CAMAGONG",
            },
            {
              name: "CARAS-AN",
            },
            {
              name: "CAYALE",
            },
            {
              name: "DAYO-AN",
            },
            {
              name: "GAMUT",
            },
            {
              name: "JUBANG",
            },
            {
              name: "KINABIGTASAN",
            },
            {
              name: "LAYOG",
            },
            {
              name: "LINDOY",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "PURISIMA (POB.)",
            },
            {
              name: "SUMO-SUMO",
            },
            {
              name: "TAGO",
            },
            {
              name: "UMBAY",
            },
            {
              name: "UNABAN",
            },
            {
              name: "UNIDOS",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
        TANDAG: {
          name: "TANDAG",
          baranggays: [
            {
              name: "TANDAG",
            },
          ],
        },
        "TANDAG CITY": {
          name: "TANDAG CITY",
          baranggays: [
            {
              name: "AWASIAN",
            },
            {
              name: "BAGONG LUNGSOD (POB.)",
            },
            {
              name: "BIOTO",
            },
            {
              name: "BONGTOD POB. (EAST WEST)",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "DAGOCDOC (POB.)",
            },
            {
              name: "MABUA",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAITUM",
            },
            {
              name: "MATICDUM",
            },
            {
              name: "PANDANON",
            },
            {
              name: "PANGI",
            },
            {
              name: "QUEZON",
            },
            {
              name: "ROSARIO",
            },
            {
              name: "SALVACION",
            },
            {
              name: "SAN AGUSTIN NORTE",
            },
            {
              name: "SAN AGUSTIN SUR",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TELAJE",
            },
          ],
        },
      },
    },
    TARLAC: {
      name: "TARLAC",
      region: "REGION 3",
      municipalities: {
        ANAO: {
          name: "ANAO",
          baranggays: [
            {
              name: "ANAO",
            },
            {
              name: "BAGUINDOC (BAGUINLOC)",
            },
            {
              name: "BANTOG",
            },
            {
              name: "CAMPOS",
            },
            {
              name: "CARMEN",
            },
            {
              name: "CASILI",
            },
            {
              name: "DON RAMON",
            },
            {
              name: "HERNANDO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN FRANCISCO EAST",
            },
            {
              name: "SAN FRANCISCO WEST",
            },
            {
              name: "SAN JOSE NORTH",
            },
            {
              name: "SAN JOSE SOUTH",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SINENSE",
            },
            {
              name: "SUAVERDEZ",
            },
          ],
        },
        BAMBAN: {
          name: "BAMBAN",
          baranggays: [
            {
              name: "ANUPUL",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "BANABA",
            },
            {
              name: "BANGCU",
            },
            {
              name: "CULUBASA",
            },
            {
              name: "DELA CRUZ",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MALONZO",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "VIRGEN DE LOS REMEDIOS (PACALCAL)",
            },
          ],
        },
        CAMILING: {
          name: "CAMILING",
          baranggays: [
            {
              name: "ANOLING 1ST",
            },
            {
              name: "ANOLING 2ND",
            },
            {
              name: "ANOLING 3RD",
            },
            {
              name: "BACABAC",
            },
            {
              name: "BACSAY",
            },
            {
              name: "BANCAY 1ST",
            },
            {
              name: "BANCAY 2ND",
            },
            {
              name: "BILAD",
            },
            {
              name: "BIRBIRA",
            },
            {
              name: "BOBON 1ST",
            },
            {
              name: "BOBON 2ND",
            },
            {
              name: "BOBON CAAROSIPAN",
            },
            {
              name: "CABANABAAN",
            },
            {
              name: "CACAMILINGAN NORTE",
            },
            {
              name: "CACAMILINGAN SUR",
            },
            {
              name: "CAMILING",
            },
            {
              name: "CANIAG",
            },
            {
              name: "CARAEL",
            },
            {
              name: "CAYAOAN",
            },
            {
              name: "CAYASAN",
            },
            {
              name: "FLORIDA",
            },
            {
              name: "LASONG",
            },
            {
              name: "LIBUEG",
            },
            {
              name: "MALACAMPA",
            },
            {
              name: "MANAKEM",
            },
            {
              name: "MANUPEG",
            },
            {
              name: "MARAWI",
            },
            {
              name: "MATUBOG",
            },
            {
              name: "NAGRAMBACAN",
            },
            {
              name: "NAGSERIALAN",
            },
            {
              name: "PALIMBO PROPER",
            },
            {
              name: "PALIMBO-CAAROSIPAN",
            },
            {
              name: "PAO 1ST",
            },
            {
              name: "PAO 2ND",
            },
            {
              name: "PAO 3RD",
            },
            {
              name: "PAPAAC",
            },
            {
              name: "PINDANGAN 1ST",
            },
            {
              name: "PINDANGAN 2ND",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION B",
            },
            {
              name: "POBLACION C",
            },
            {
              name: "POBLACION D",
            },
            {
              name: "POBLACION E",
            },
            {
              name: "POBLACION F",
            },
            {
              name: "POBLACION G",
            },
            {
              name: "POBLACION H",
            },
            {
              name: "POBLACION I",
            },
            {
              name: "POBLACION J",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SAWAT",
            },
            {
              name: "SINILIAN 1ST",
            },
            {
              name: "SINILIAN 2ND",
            },
            {
              name: "SINILIAN 3RD",
            },
            {
              name: "SINILIAN CACALIBOSOAN",
            },
            {
              name: "SINULATAN 1ST",
            },
            {
              name: "SINULATAN 2ND",
            },
            {
              name: "SURGUI 1ST",
            },
            {
              name: "SURGUI 2ND",
            },
            {
              name: "SURGUI 3RD",
            },
            {
              name: "TAMBUGAN",
            },
            {
              name: "TELBANG",
            },
            {
              name: "TUEC",
            },
          ],
        },
        CAPAS: {
          name: "CAPAS",
          baranggays: [
            {
              name: "ARANGUREN",
            },
            {
              name: "BUENO",
            },
            {
              name: "CAPAS",
            },
            {
              name: "CRISTO REY",
            },
            {
              name: "CUBCUB (POB.)",
            },
            {
              name: "CUTCUT 1ST",
            },
            {
              name: "CUTCUT 2ND",
            },
            {
              name: "DOLORES",
            },
            {
              name: "ESTRADA (CALINGCUAN)",
            },
            {
              name: "LAWY",
            },
            {
              name: "MANGA",
            },
            {
              name: "MANLAPIG",
            },
            {
              name: "MARUGLU",
            },
            {
              name: "O'DONNELL",
            },
            {
              name: "SANTA JULIANA",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTO DOMINGO 1ST",
            },
            {
              name: "SANTO DOMINGO 2ND",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "TALAGA",
            },
          ],
        },
        "CITY OF TARLAC": {
          name: "CITY OF TARLAC",
          baranggays: [
            {
              name: "AGUSO",
            },
            {
              name: "ALVINDIA SEGUNDO",
            },
            {
              name: "AMUCAO",
            },
            {
              name: "ARMENIA",
            },
            {
              name: "ASTURIAS",
            },
            {
              name: "ATIOC",
            },
            {
              name: "BALANTI",
            },
            {
              name: "BALETE",
            },
            {
              name: "BALIBAGO I",
            },
            {
              name: "BALIBAGO II",
            },
            {
              name: "BALINGCANAWAY",
            },
            {
              name: "BANABA",
            },
            {
              name: "BANTOG",
            },
            {
              name: "BARAS-BARAS",
            },
            {
              name: "BATANG-BATANG",
            },
            {
              name: "BINAUGANAN",
            },
            {
              name: "BORA",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BUHILIT (BUBULIT)",
            },
            {
              name: "BUROT",
            },
            {
              name: "CALINGCUAN",
            },
            {
              name: "CAPEHAN",
            },
            {
              name: "CARANGIAN",
            },
            {
              name: "CARE",
            },
            {
              name: "CENTRAL",
            },
            {
              name: "CITY OF TARLAC",
            },
            {
              name: "CULIPAT",
            },
            {
              name: "CUT-CUT I",
            },
            {
              name: "CUT-CUT II",
            },
            {
              name: "DALAYAP",
            },
            {
              name: "DELA PAZ",
            },
            {
              name: "DOLORES",
            },
            {
              name: "LAOANG",
            },
            {
              name: "LIGTASAN",
            },
            {
              name: "LOURDES",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MALIWALO",
            },
            {
              name: "MAPALACSIAO",
            },
            {
              name: "MAPALAD",
            },
            {
              name: "MATADERO",
            },
            {
              name: "MATATALAIB",
            },
            {
              name: "PARAISO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAPUNGAN",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JOSE DE URQUICO",
            },
            {
              name: "SAN JUAN DE MATA",
            },
            {
              name: "SAN LUIS",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SAN PASCUAL",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN SEBASTIAN",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (ALVINDIA PRIMERO)",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO DOMINGO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAPANG MARAGUL",
            },
            {
              name: "SAPANG TAGALOG",
            },
            {
              name: "SEPUNG CALZADA",
            },
            {
              name: "SINAIT",
            },
            {
              name: "SUIZO",
            },
            {
              name: "TARIJI",
            },
            {
              name: "TIBAG",
            },
            {
              name: "TIBAGAN",
            },
            {
              name: "TRINIDAD (TRINIDAD PRIMERO)",
            },
            {
              name: "UNGOT",
            },
            {
              name: "VILLA BACOLOR",
            },
          ],
        },
        CONCEPCION: {
          name: "CONCEPCION",
          baranggays: [
            {
              name: "ALFONSO",
            },
            {
              name: "BALUTU",
            },
            {
              name: "CAFE",
            },
            {
              name: "CALIUS GUECO",
            },
            {
              name: "CALULUAN",
            },
            {
              name: "CASTILLO",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CORAZON DE JESUS",
            },
            {
              name: "CULATINGAN",
            },
            {
              name: "DUNGAN",
            },
            {
              name: "DUTUNG-A-MATAS",
            },
            {
              name: "GREEN VILLAGE",
            },
            {
              name: "LILIBANGAN",
            },
            {
              name: "MABILOG",
            },
            {
              name: "MAGAO",
            },
            {
              name: "MALUPA",
            },
            {
              name: "MINANE",
            },
            {
              name: "PANALICSIAN (PANALICSICAN)",
            },
            {
              name: "PANDO",
            },
            {
              name: "PARANG",
            },
            {
              name: "PARULUNG",
            },
            {
              name: "PITABUNAN",
            },
            {
              name: "SAN AGUSTIN (MURCIA)",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO (ALMENDRAS)",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN JUAN (CASTRO)",
            },
            {
              name: "SAN MARTIN",
            },
            {
              name: "SAN NICOLAS BALAS",
            },
            {
              name: "SAN VICENTE (CALUIS/COBRA)",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTA ROSA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SANTO CRISTO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO (MAGUNTING)",
            },
            {
              name: "TALIMUNDUC MARIMLA",
            },
            {
              name: "TALIMUNDUC SAN MIGUEL",
            },
            {
              name: "TELABANCA",
            },
            {
              name: "TINANG",
            },
          ],
        },
        GERONA: {
          name: "GERONA",
          baranggays: [
            {
              name: "ABAGON",
            },
            {
              name: "AMACALAN",
            },
            {
              name: "APSAYAN",
            },
            {
              name: "AYSON",
            },
            {
              name: "BAWA",
            },
            {
              name: "BUENLAG",
            },
            {
              name: "BULARIT",
            },
            {
              name: "CALAYAAN",
            },
            {
              name: "CARBONEL",
            },
            {
              name: "CARDONA",
            },
            {
              name: "CATURAY",
            },
            {
              name: "DANZO",
            },
            {
              name: "DICOLOR",
            },
            {
              name: "DON BASILIO",
            },
            {
              name: "GERONA",
            },
            {
              name: "LUNA",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGASPAC",
            },
            {
              name: "MALAYEP",
            },
            {
              name: "MATAPITAP",
            },
            {
              name: "MATAYUMCAB",
            },
            {
              name: "NEW SALEM",
            },
            {
              name: "OLOYBUAYA",
            },
            {
              name: "PADAPADA",
            },
            {
              name: "PARSOLINGAN",
            },
            {
              name: "PINASLING (PINASUNG)",
            },
            {
              name: "PLASTADO",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "QUEZON",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SALAPUNGAN",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA LUCIA",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "SEMBRANO",
            },
            {
              name: "SINGAT",
            },
            {
              name: "SULIPA",
            },
            {
              name: "TAGUMBAO",
            },
            {
              name: "TANGCARAN",
            },
            {
              name: "VILLA PAZ",
            },
          ],
        },
        "LA PAZ": {
          name: "LA PAZ",
          baranggays: [
            {
              name: "BALANOY",
            },
            {
              name: "BANTOG-CARICUTAN",
            },
            {
              name: "CARAMUTAN",
            },
            {
              name: "CAUT",
            },
            {
              name: "COMILLAS",
            },
            {
              name: "DUMARAIS",
            },
            {
              name: "GUEVARRA",
            },
            {
              name: "KAPANIKIAN",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LA PURISIMA",
            },
            {
              name: "LARA",
            },
            {
              name: "LAUNGCUPANG",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "MACALONG",
            },
            {
              name: "MATAYUMTAYUM",
            },
            {
              name: "MAYANG",
            },
            {
              name: "MOTRICO",
            },
            {
              name: "PALUDPUD",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ISIDRO (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SIERRA",
            },
          ],
        },
        MAYANTOC: {
          name: "MAYANTOC",
          baranggays: [
            {
              name: "AMBALINGIT",
            },
            {
              name: "BAYBAYAOAS",
            },
            {
              name: "BIGBIGA",
            },
            {
              name: "BINBINACA",
            },
            {
              name: "CALABTANGAN",
            },
            {
              name: "CAOCAOAYAN",
            },
            {
              name: "CARABAOAN",
            },
            {
              name: "CUBCUB",
            },
            {
              name: "GAYONGGAYONG",
            },
            {
              name: "GOSSOOD",
            },
            {
              name: "LABNEY",
            },
            {
              name: "MAMONIT",
            },
            {
              name: "MANINIOG",
            },
            {
              name: "MAPANDAN",
            },
            {
              name: "MAYANTOC",
            },
            {
              name: "NAMBALAN",
            },
            {
              name: "PEDRO L. QUINES",
            },
            {
              name: "PITOMBAYOG",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "ROTROTTOOC",
            },
            {
              name: "SAN BARTOLOME",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TALDIAPAN",
            },
            {
              name: "TANGCARANG",
            },
          ],
        },
        MONCADA: {
          name: "MONCADA",
          baranggays: [
            {
              name: "ABLANG-SAPANG",
            },
            {
              name: "ARINGIN",
            },
            {
              name: "ATENCIO",
            },
            {
              name: "BANAOANG EAST",
            },
            {
              name: "BANAOANG WEST",
            },
            {
              name: "BAQUERO NORTE",
            },
            {
              name: "BAQUERO SUR",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CALAMAY",
            },
            {
              name: "CALAPAN",
            },
            {
              name: "CAMANGAAN EAST",
            },
            {
              name: "CAMANGAAN WEST",
            },
            {
              name: "CAMPOSANTO 1 - NORTE",
            },
            {
              name: "CAMPOSANTO 1 - SUR",
            },
            {
              name: "CAMPOSANTO 2",
            },
            {
              name: "CAPAOAYAN",
            },
            {
              name: "LAPSING",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALUAC",
            },
            {
              name: "MONCADA",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "POBLACION 4",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN JULIAN",
            },
            {
              name: "SAN LEON",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA LUCIA EAST",
            },
            {
              name: "SANTA LUCIA WEST",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTA MONICA",
            },
            {
              name: "TOLEGA NORTE",
            },
            {
              name: "TOLEGA SUR",
            },
            {
              name: "TUBECTUBANG",
            },
            {
              name: "VILLA",
            },
          ],
        },
        PANIQUI: {
          name: "PANIQUI",
          baranggays: [
            {
              name: "ABOGADO",
            },
            {
              name: "ACOCOLAO",
            },
            {
              name: "ADUAS",
            },
            {
              name: "APULID",
            },
            {
              name: "BALAOANG",
            },
            {
              name: "BARANG (BORANG)",
            },
            {
              name: "BRILLANTE",
            },
            {
              name: "CABAYAOASAN",
            },
            {
              name: "CANAN",
            },
            {
              name: "CARINO",
            },
            {
              name: "CAYANGA",
            },
            {
              name: "COLIBANGBANG",
            },
            {
              name: "CORAL",
            },
            {
              name: "DAPDAP",
            },
            {
              name: "ESTACION",
            },
            {
              name: "MABILANG",
            },
            {
              name: "MANAOIS",
            },
            {
              name: "MATALAPITAP",
            },
            {
              name: "NAGMISAAN",
            },
            {
              name: "NANCAMARINAN",
            },
            {
              name: "NIPACO",
            },
            {
              name: "PANIQUI",
            },
            {
              name: "PATALAN",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "RANG-AYAN",
            },
            {
              name: "SALUMAGUE",
            },
            {
              name: "SAMPUT",
            },
            {
              name: "SAN CARLOS",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN DE MILLA",
            },
            {
              name: "SANTA INES",
            },
            {
              name: "SINIGPIT",
            },
            {
              name: "TABLANG",
            },
            {
              name: "VENTENILLA",
            },
          ],
        },
        PURA: {
          name: "PURA",
          baranggays: [
            {
              name: "BALITE",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CADANGLAAN",
            },
            {
              name: "ESTIPONA",
            },
            {
              name: "LINAO",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MATINDEG",
            },
            {
              name: "MAUNGIB",
            },
            {
              name: "NAYA",
            },
            {
              name: "NILASIN 1ST",
            },
            {
              name: "NILASIN 2ND",
            },
            {
              name: "POBLACION 1",
            },
            {
              name: "POBLACION 2",
            },
            {
              name: "POBLACION 3",
            },
            {
              name: "POROC",
            },
            {
              name: "PURA",
            },
            {
              name: "SINGAT",
            },
          ],
        },
        RAMOS: {
          name: "RAMOS",
          baranggays: [
            {
              name: "CORAL-ILOCO",
            },
            {
              name: "GUITEB",
            },
            {
              name: "PANCE",
            },
            {
              name: "POBLACION CENTER",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "RAMOS",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN RAYMUNDO",
            },
            {
              name: "TOLEDO",
            },
          ],
        },
        "SAN CLEMENTE": {
          name: "SAN CLEMENTE",
          baranggays: [
            {
              name: "BALLOC",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "CASIPO",
            },
            {
              name: "CATAGUDINGAN",
            },
            {
              name: "DALDALAYAP",
            },
            {
              name: "DOCLONG 1",
            },
            {
              name: "DOCLONG 2",
            },
            {
              name: "MAASIN",
            },
            {
              name: "NAGSABARAN",
            },
            {
              name: "PIT-AO",
            },
            {
              name: "POBLACION NORTE",
            },
            {
              name: "POBLACION SUR",
            },
            {
              name: "SAN CLEMENTE",
            },
          ],
        },
        "SAN JOSE": {
          name: "SAN JOSE",
          baranggays: [
            {
              name: "BURGOS",
            },
            {
              name: "DAVID",
            },
            {
              name: "IBA",
            },
            {
              name: "LABNEY",
            },
            {
              name: "LAWACAMULAG",
            },
            {
              name: "LUBIGAN",
            },
            {
              name: "MAAMOT",
            },
            {
              name: "MABABANABA",
            },
            {
              name: "MORIONES",
            },
            {
              name: "PAO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN DE VALDEZ",
            },
            {
              name: "SULA",
            },
            {
              name: "VILLA AGLIPAY",
            },
          ],
        },
        "SAN MANUEL": {
          name: "SAN MANUEL",
          baranggays: [
            {
              name: "COLUBOT",
            },
            {
              name: "LANAT",
            },
            {
              name: "LEGASPI",
            },
            {
              name: "MATARANNOC",
            },
            {
              name: "PACPACO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALCEDO",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN FELIPE",
            },
            {
              name: "SAN JACINTO",
            },
            {
              name: "SAN MANUEL",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN NARCISO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA MARIA",
            },
          ],
        },
        "SANTA IGNACIA": {
          name: "SANTA IGNACIA",
          baranggays: [
            {
              name: "BALDIOS",
            },
            {
              name: "BOTBOTONES",
            },
            {
              name: "CAANAMONGAN",
            },
            {
              name: "CABARUAN",
            },
            {
              name: "CABUGBUGAN",
            },
            {
              name: "CADULDULAOAN",
            },
            {
              name: "CALIPAYAN",
            },
            {
              name: "MACAGUING",
            },
            {
              name: "NAMBALAN",
            },
            {
              name: "PADAPADA",
            },
            {
              name: "PILPILA",
            },
            {
              name: "PINPINAS",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "POBLACION WEST",
            },
            {
              name: "PUGO-CECILIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN SOTERO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA IGNACIA",
            },
            {
              name: "SANTA INES CENTRO",
            },
            {
              name: "SANTA INES EAST",
            },
            {
              name: "SANTA INES WEST",
            },
            {
              name: "TAGUIPORO",
            },
            {
              name: "TIMMAGUAB",
            },
            {
              name: "VARGAS",
            },
          ],
        },
        VICTORIA: {
          name: "VICTORIA",
          baranggays: [
            {
              name: "BACULONG",
            },
            {
              name: "BALAYANG",
            },
            {
              name: "BALBALOTO",
            },
            {
              name: "BANGAR",
            },
            {
              name: "BANTOG",
            },
            {
              name: "BATANGBATANG",
            },
            {
              name: "BULO",
            },
            {
              name: "CABULUAN",
            },
            {
              name: "CALIBUNGAN",
            },
            {
              name: "CANAREM",
            },
            {
              name: "CRUZ",
            },
            {
              name: "LALAPAC",
            },
            {
              name: "MALUID",
            },
            {
              name: "MANGOLAGO",
            },
            {
              name: "MASALASA",
            },
            {
              name: "PALACPALAC",
            },
            {
              name: "SAN ANDRES",
            },
            {
              name: "SAN FERNANDO (POB.)",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN GAVINO (POB.)",
            },
            {
              name: "SAN JACINTO",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTA LUCIA (POB.)",
            },
            {
              name: "VICTORIA",
            },
          ],
        },
      },
    },
    "TAWI-TAWI": {
      name: "TAWI-TAWI",
      region: "ARMM",
      municipalities: {
        BALIMBING: {
          name: "BALIMBING",
          baranggays: [
            {
              name: "BALIMBING",
            },
          ],
        },
        "BATU-BATU": {
          name: "BATU-BATU",
          baranggays: [
            {
              name: "BATU-BATU",
            },
          ],
        },
        BONGAO: {
          name: "BONGAO",
          baranggays: [
            {
              name: "BONGAO",
            },
            {
              name: "BONGAO POBLACION",
            },
            {
              name: "IPIL",
            },
            {
              name: "KAMAGONG",
            },
            {
              name: "KARUNGDONG",
            },
            {
              name: "LAGASAN",
            },
            {
              name: "LAKIT LAKIT",
            },
            {
              name: "LAMION",
            },
            {
              name: "LAPID LAPID",
            },
            {
              name: "LATO LATO",
            },
            {
              name: "LUUK PANDAN",
            },
            {
              name: "LUUK TULAY",
            },
            {
              name: "MALASSA",
            },
            {
              name: "MANDULAN",
            },
            {
              name: "MASANTONG",
            },
            {
              name: "MONTAY MONTAY",
            },
            {
              name: "NALIL",
            },
            {
              name: "PABABAG",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "PAGASINAN",
            },
            {
              name: "PAGATPAT",
            },
            {
              name: "PAHUT",
            },
            {
              name: "PAKIAS",
            },
            {
              name: "PANIONGAN",
            },
            {
              name: "PASIAGAN",
            },
            {
              name: "SANGA-SANGA",
            },
            {
              name: "SILUBOG",
            },
            {
              name: "SIMANDAGIT",
            },
            {
              name: "SUMANGAT",
            },
            {
              name: "TARAWAKAN",
            },
            {
              name: "TONGSINAH",
            },
            {
              name: "TUBIG BASAG",
            },
            {
              name: "TUBIG TANAH",
            },
            {
              name: "TUBIG-BOH",
            },
            {
              name: "TUBIG-MAMPALLAM",
            },
            {
              name: "UNGUS-UNGUS",
            },
          ],
        },
        "CAGAYAN DE TAWI-TAWI": {
          name: "CAGAYAN DE TAWI-TAWI",
          baranggays: [
            {
              name: "CAGAYAN DE TAWI-TAWI",
            },
          ],
        },
        LANGUYAN: {
          name: "LANGUYAN",
          baranggays: [
            {
              name: "ADNIN",
            },
            {
              name: "BAKAW-BAKAW",
            },
            {
              name: "BAKONG",
            },
            {
              name: "BAS-BAS PROPER",
            },
            {
              name: "BASLIKUD",
            },
            {
              name: "BASNUNUK",
            },
            {
              name: "DARUSSALAM",
            },
            {
              name: "JAKARTA (LOOKAN LATUAN)",
            },
            {
              name: "KALUPAG",
            },
            {
              name: "KINIKTAL",
            },
            {
              name: "LANGUYAN",
            },
            {
              name: "LANGUYAN PROPER (POB.)",
            },
            {
              name: "MARANG-MARANG",
            },
            {
              name: "MARANING",
            },
            {
              name: "PARANG PANTAY",
            },
            {
              name: "SIKULLIS",
            },
            {
              name: "SIMALAK",
            },
            {
              name: "TUBIG DAKULA",
            },
            {
              name: "TUHOG-TUHOG",
            },
            {
              name: "TUMAHUBONG",
            },
            {
              name: "TUMBAGAAN",
            },
          ],
        },
        "MAPUN (CAGAYAN DE TAWI-TAWI)": {
          name: "MAPUN (CAGAYAN DE TAWI-TAWI)",
          baranggays: [
            {
              name: "BOKI",
            },
            {
              name: "DUHUL BATU",
            },
            {
              name: "EROK-EROK",
            },
            {
              name: "GUPPAH",
            },
            {
              name: "KOMPANG",
            },
            {
              name: "LIYUBUD",
            },
            {
              name: "LUBBAK PARANG",
            },
            {
              name: "LUPA PULA (POB.)",
            },
            {
              name: "MAHALO",
            },
            {
              name: "PAWAN",
            },
            {
              name: "SAPA",
            },
            {
              name: "SIKUB",
            },
            {
              name: "TABULIAN",
            },
            {
              name: "TANDUAN",
            },
            {
              name: "UMUS MATAHA",
            },
          ],
        },
        "PANGLIMA SUGALA (BALIMBING)": {
          name: "PANGLIMA SUGALA (BALIMBING)",
          baranggays: [
            {
              name: "BALIMBING PROPER",
            },
            {
              name: "BATU-BATU (POB.)",
            },
            {
              name: "BAUNO GARING",
            },
            {
              name: "BELATAN HALU",
            },
            {
              name: "BUAN",
            },
            {
              name: "DUNGON",
            },
            {
              name: "KARAHA",
            },
            {
              name: "KULAPE",
            },
            {
              name: "LIYABURAN",
            },
            {
              name: "LUUK BUNTAL",
            },
            {
              name: "MAGSAGGAW",
            },
            {
              name: "MALACCA",
            },
            {
              name: "PARANGAN",
            },
            {
              name: "SUMANGDAY",
            },
            {
              name: "TABUNAN",
            },
            {
              name: "TUNDON",
            },
            {
              name: "TUNGBANGKAW",
            },
          ],
        },
        "SAPA-SAPA": {
          name: "SAPA-SAPA",
          baranggays: [
            {
              name: "BALDATAL ISLAM",
            },
            {
              name: "BUTUN",
            },
            {
              name: "DALO-DALO",
            },
            {
              name: "LAKIT-LAKIT",
            },
            {
              name: "LATUAN (SUASANG)",
            },
            {
              name: "LOOK NATUH",
            },
            {
              name: "LOOKAN BANARAN",
            },
            {
              name: "LOOKAN LATUAN",
            },
            {
              name: "MALANTA",
            },
            {
              name: "MANTABUAN TABUNAN",
            },
            {
              name: "NUNUK LIKUD SIKUBONG",
            },
            {
              name: "PALATE GADJAMINAH",
            },
            {
              name: "PAMASAN",
            },
            {
              name: "SAPA-SAPA",
            },
            {
              name: "SAPA-SAPA (POB.)",
            },
            {
              name: "SAPAAT",
            },
            {
              name: "SUKAH-SUKAH",
            },
            {
              name: "TABUNAN LIKUD SIKUBONG",
            },
            {
              name: "TANGNGAH (LALUNG SIKUBONG)",
            },
            {
              name: "TAPIAN BOHE NORTH",
            },
            {
              name: "TAPIAN BOHE SOUTH",
            },
            {
              name: "TONGGUSONG BANARAN",
            },
            {
              name: "TUP-TUP BANARAN",
            },
          ],
        },
        SIBUTU: {
          name: "SIBUTU",
          baranggays: [
            {
              name: "AMBULONG SAPAL",
            },
            {
              name: "DATU AMILHAMJA JAAFAR",
            },
            {
              name: "HADJI IMAM BIDIN",
            },
            {
              name: "HADJI MOHTAR SULAYMAN",
            },
            {
              name: "HADJI TAHA",
            },
            {
              name: "IMAM HADJI MOHAMMAD",
            },
            {
              name: "LIGAYAN",
            },
            {
              name: "NUNUKAN",
            },
            {
              name: "SHEIK MAKDUM",
            },
            {
              name: "SIBUTU (POB.)",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TANDU BANAK",
            },
            {
              name: "TAUNGOH",
            },
            {
              name: "TONGEHAT",
            },
            {
              name: "TONGSIBALO",
            },
            {
              name: "UNGUS-UNGUS",
            },
          ],
        },
        SIMUNUL: {
          name: "SIMUNUL",
          baranggays: [
            {
              name: "BAGID*",
            },
            {
              name: "BAKONG",
            },
            {
              name: "DOH-TONG",
            },
            {
              name: "LUUK DATAN",
            },
            {
              name: "MANUK MANGKAW",
            },
            {
              name: "MARUWA",
            },
            {
              name: "MONGKAY",
            },
            {
              name: "PAGASINAN",
            },
            {
              name: "PANGLIMA MASTUL",
            },
            {
              name: "SIMUNUL",
            },
            {
              name: "SUKAH-BULAN",
            },
            {
              name: "TAMPAKAN (POB.)",
            },
            {
              name: "TIMUNDON",
            },
            {
              name: "TONGGOSONG",
            },
            {
              name: "TUBIG INDANGAN",
            },
            {
              name: "UBOL",
            },
          ],
        },
        SITANGKAI: {
          name: "SITANGKAI",
          baranggays: [
            {
              name: "DATU BAGUINDA PUTIH",
            },
            {
              name: "IMAM SAPIE",
            },
            {
              name: "NORTH LARAP",
            },
            {
              name: "PANGLIMA ALARI",
            },
            {
              name: "SIPANGKOT",
            },
            {
              name: "SITANGKAI",
            },
            {
              name: "SITANGKAI POBLACION",
            },
            {
              name: "SOUTH LARAP (LARAP)",
            },
            {
              name: "TONGMAGENG",
            },
            {
              name: "TONGUSONG",
            },
          ],
        },
        "SOUTH UBIAN": {
          name: "SOUTH UBIAN",
          baranggays: [
            {
              name: "BABAGAN",
            },
            {
              name: "BENGKOL",
            },
            {
              name: "BINTAWLAN",
            },
            {
              name: "BOHE",
            },
            {
              name: "BUBUAN",
            },
            {
              name: "BUNAY BUNAY CENTER",
            },
            {
              name: "BUNAY BUNAY LOOKAN",
            },
            {
              name: "BUNAY BUNAY TONG",
            },
            {
              name: "EAST TALISAY",
            },
            {
              name: "LAHAD DAMPONG",
            },
            {
              name: "LAITAN",
            },
            {
              name: "LAMBI-LAMBIAN",
            },
            {
              name: "LAUD",
            },
            {
              name: "LIKUD DAMPONG",
            },
            {
              name: "LIKUD TABAWAN",
            },
            {
              name: "NUNUK",
            },
            {
              name: "NUSA",
            },
            {
              name: "NUSA-NUSA",
            },
            {
              name: "PAMPANG",
            },
            {
              name: "PUTAT",
            },
            {
              name: "SOLLOGAN",
            },
            {
              name: "SOUTH UBIAN",
            },
            {
              name: "TALISAY",
            },
            {
              name: "TAMPAKAN DAMPONG",
            },
            {
              name: "TANGNGAH",
            },
            {
              name: "TINDA-TINDAHAN",
            },
            {
              name: "TONG TAMPAKAN",
            },
            {
              name: "TUBIG DAYANG",
            },
            {
              name: "TUBIG DAYANG CENTER",
            },
            {
              name: "TUBIG DAYANG RIVERSIDE",
            },
            {
              name: "TUKKAI",
            },
            {
              name: "UNAS-UNAS",
            },
          ],
        },
        TANDUBAS: {
          name: "TANDUBAS",
          baranggays: [
            {
              name: "BALIUNGAN",
            },
            {
              name: "BALLAK",
            },
            {
              name: "BUTUN",
            },
            {
              name: "HIMBAH",
            },
            {
              name: "KAKOONG",
            },
            {
              name: "KALANG-KALANG",
            },
            {
              name: "KEPENG",
            },
            {
              name: "LAHAY-LAHAY",
            },
            {
              name: "NAUNGAN",
            },
            {
              name: "SALAMAT",
            },
            {
              name: "SALLANGAN",
            },
            {
              name: "SAPA",
            },
            {
              name: "SIBAKLOON",
            },
            {
              name: "SILANTUP",
            },
            {
              name: "TANDUBAS",
            },
            {
              name: "TANDUBATO",
            },
            {
              name: "TANGNGAH (TANGNGAH UNGUS MATATA)",
            },
            {
              name: "TAPIAN",
            },
            {
              name: "TAPIAN SUKAH",
            },
            {
              name: "TARUK",
            },
            {
              name: "TONGBANGKAW",
            },
          ],
        },
        TURTLE: {
          name: "TURTLE",
          baranggays: [
            {
              name: "TURTLE",
            },
          ],
        },
        "TURTLE ISLANDS": {
          name: "TURTLE ISLANDS",
          baranggays: [
            {
              name: "LIKUD BAKKAO",
            },
            {
              name: "TAGANAK POBLACION",
            },
          ],
        },
      },
    },
    ZAMBALES: {
      name: "ZAMBALES",
      region: "REGION 3",
      municipalities: {
        BOTOLAN: {
          name: "BOTOLAN",
          baranggays: [
            {
              name: "BANCAL",
            },
            {
              name: "BANGAN",
            },
            {
              name: "BATONLAPOC",
            },
            {
              name: "BELBEL",
            },
            {
              name: "BENEG",
            },
            {
              name: "BINUCLUTAN",
            },
            {
              name: "BOTOLAN",
            },
            {
              name: "CAPAYAWAN",
            },
            {
              name: "CARAEL",
            },
            {
              name: "DANACBUNGA",
            },
            {
              name: "MAGUISGUIS",
            },
            {
              name: "MALOMBOY",
            },
            {
              name: "MAMBOG",
            },
            {
              name: "MORAZA",
            },
            {
              name: "NACOLCOL",
            },
            {
              name: "OWAOG-NIBLOC",
            },
            {
              name: "PACO (POB.)",
            },
            {
              name: "PALIS",
            },
            {
              name: "PANAN",
            },
            {
              name: "PAREL",
            },
            {
              name: "PAUDPOD",
            },
            {
              name: "POONBATO",
            },
            {
              name: "PORAC",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "TAMPO (POB.)",
            },
            {
              name: "TAUGTOG",
            },
            {
              name: "VILLAR",
            },
          ],
        },
        CABANGAN: {
          name: "CABANGAN",
          baranggays: [
            {
              name: "ANONANG",
            },
            {
              name: "APO-APO",
            },
            {
              name: "AREW",
            },
            {
              name: "BANUAMBAYO (POB.)",
            },
            {
              name: "CABANGAN",
            },
            {
              name: "CADMANG-RESERVA",
            },
            {
              name: "CAMILING (CAMIING)",
            },
            {
              name: "CASABAAN",
            },
            {
              name: "DEL CARMEN (POB.)",
            },
            {
              name: "DOLORES (POB.)",
            },
            {
              name: "FELMIDA-DIAZ",
            },
            {
              name: "LAOAG",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "LONGOS",
            },
            {
              name: "MABANGLIT",
            },
            {
              name: "NEW SAN JUAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN RAFAEL",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TONDO",
            },
          ],
        },
        CANDELARIA: {
          name: "CANDELARIA",
          baranggays: [
            {
              name: "BABANCAL",
            },
            {
              name: "BINABALIAN",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "CATOL",
            },
            {
              name: "DAMPAY",
            },
            {
              name: "LAUIS",
            },
            {
              name: "LIBERTADOR",
            },
            {
              name: "MALABON (SAN ROQUE)",
            },
            {
              name: "MALIMANGA",
            },
            {
              name: "PAMIBIAN",
            },
            {
              name: "PANAYONAN",
            },
            {
              name: "PINAGREALAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SINABACAN",
            },
            {
              name: "TAPOSO",
            },
            {
              name: "UACON",
            },
            {
              name: "YAMOT",
            },
          ],
        },
        CASTILLEJOS: {
          name: "CASTILLEJOS",
          baranggays: [
            {
              name: "BALAYBAY",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CASTILLEJOS",
            },
            {
              name: "LOOC",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "NAGBAYAN",
            },
            {
              name: "NAGBUNGA",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN PABLO (POB.)",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA MARIA",
            },
          ],
        },
        IBA: {
          name: "IBA",
          baranggays: [
            {
              name: "AMUNGAN",
            },
            {
              name: "BANGANTALINGA",
            },
            {
              name: "DIRITA-BALOGUEN",
            },
            {
              name: "IBA",
            },
            {
              name: "LIPAY-DINGIN-PANIBUATAN",
            },
            {
              name: "PALANGINAN (PALANGUINAN-TAMBAK)",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "ZONE 1 POB. (LIBABA)",
            },
            {
              name: "ZONE 2 POB. (AYPA)",
            },
            {
              name: "ZONE 3 POB. (BOTLAY)",
            },
            {
              name: "ZONE 4 POB. (SAGAPAN)",
            },
            {
              name: "ZONE 5 POB. (BANO)",
            },
            {
              name: "ZONE 6 POB. (BAYTAN)",
            },
          ],
        },
        MASINLOC: {
          name: "MASINLOC",
          baranggays: [
            {
              name: "BALOGANON",
            },
            {
              name: "BAMBAN",
            },
            {
              name: "BANI",
            },
            {
              name: "COLLAT",
            },
            {
              name: "INHOBOL",
            },
            {
              name: "MASINLOC",
            },
            {
              name: "NORTH POBLACION",
            },
            {
              name: "SAN LORENZO",
            },
            {
              name: "SAN SALVADOR",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SOUTH POBLACION",
            },
            {
              name: "TALTAL",
            },
            {
              name: "TAPUAC",
            },
          ],
        },
        "OLONGAPO CITY": {
          name: "OLONGAPO CITY",
          baranggays: [
            {
              name: "ASINAN",
            },
            {
              name: "BANICAIN",
            },
            {
              name: "BARRETO",
            },
            {
              name: "EAST BAJAC-BAJAC",
            },
            {
              name: "EAST TAPINAC",
            },
            {
              name: "GORDON HEIGHTS",
            },
            {
              name: "KALAKLAN",
            },
            {
              name: "MABAYUAN",
            },
            {
              name: "NEW CABALAN",
            },
            {
              name: "NEW ILALIM",
            },
            {
              name: "NEW KABABAE",
            },
            {
              name: "NEW KALALAKE",
            },
            {
              name: "OLD CABALAN",
            },
            {
              name: "OLONGAPO CITY",
            },
            {
              name: "PAG-ASA",
            },
            {
              name: "SANTA RITA",
            },
            {
              name: "WEST BAJAC-BAJAC",
            },
            {
              name: "WEST TAPINAC",
            },
          ],
        },
        PALAUIG: {
          name: "PALAUIG",
          baranggays: [
            {
              name: "ALWA",
            },
            {
              name: "BATO",
            },
            {
              name: "BULAWEN",
            },
            {
              name: "CAUYAN",
            },
            {
              name: "EAST POBLACION",
            },
            {
              name: "GARRETA",
            },
            {
              name: "LIBABA",
            },
            {
              name: "LIOZON",
            },
            {
              name: "LIPAY",
            },
            {
              name: "LOCLOC",
            },
            {
              name: "MACARANG",
            },
            {
              name: "MAGALAWA",
            },
            {
              name: "PALAUIG",
            },
            {
              name: "PANGOLINGAN",
            },
            {
              name: "SALAZA",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TITION (SAN VICENTE)",
            },
            {
              name: "WEST POBLACION",
            },
          ],
        },
        "SAN ANTONIO": {
          name: "SAN ANTONIO",
          baranggays: [
            {
              name: "ANGELES",
            },
            {
              name: "ANTIPOLO (POB.)",
            },
            {
              name: "BURGOS (POB.)",
            },
            {
              name: "EAST DIRITA",
            },
            {
              name: "LUNA (POB.)",
            },
            {
              name: "PUNDAQUIT",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN GREGORIO (POB.)",
            },
            {
              name: "SAN JUAN (POB.)",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SANTIAGO",
            },
            {
              name: "WEST DIRITA",
            },
          ],
        },
        "SAN FELIPE": {
          name: "SAN FELIPE",
          baranggays: [
            {
              name: "ROSETE (POB.)",
            },
          ],
        },
        "SAN MARCELINO": {
          name: "SAN MARCELINO",
          baranggays: [
            {
              name: "CONSUELO SUR (POB.)",
            },
            {
              name: "LA PAZ (POB.)",
            },
          ],
        },
        "SAN NARCISO": {
          name: "SAN NARCISO",
          baranggays: [
            {
              name: "SAN PASCUAL (POB.)",
            },
            {
              name: "SAN RAFAEL (POB.)",
            },
          ],
        },
        "SANTA CRUZ": {
          name: "SANTA CRUZ",
          baranggays: [
            {
              name: "BABUYAN",
            },
            {
              name: "BANGCOL",
            },
            {
              name: "BAYTO",
            },
            {
              name: "BIAY",
            },
            {
              name: "BOLITOC",
            },
            {
              name: "BULAWON",
            },
            {
              name: "CANAYNAYAN",
            },
            {
              name: "GAMA",
            },
            {
              name: "GUINABON",
            },
            {
              name: "GUISGUIS",
            },
            {
              name: "LIPAY",
            },
            {
              name: "LOMBOY",
            },
            {
              name: "LUCAPON NORTH",
            },
            {
              name: "LUCAPON SOUTH",
            },
            {
              name: "MALABAGO",
            },
            {
              name: "NAULO",
            },
            {
              name: "PAGATPAT",
            },
            {
              name: "PAMONORAN",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "SABANG",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TABALONG",
            },
            {
              name: "TUBOTUBO NORTH",
            },
            {
              name: "TUBOTUBO SOUTH",
            },
          ],
        },
        SUBIC: {
          name: "SUBIC",
          baranggays: [
            {
              name: "ANINGWAY SACATIHAN",
            },
            {
              name: "ASINAN POBLACION",
            },
            {
              name: "ASINAN PROPER",
            },
            {
              name: "BARACA-CAMACHILE (POB.)",
            },
            {
              name: "BATIAWAN",
            },
            {
              name: "CALAPACUAN",
            },
            {
              name: "CALAPANDAYAN (POB.)",
            },
            {
              name: "CAWAG",
            },
            {
              name: "ILWAS (POB.)",
            },
            {
              name: "MANGAN-VACA",
            },
            {
              name: "MATAIN",
            },
            {
              name: "NAUGSOL",
            },
            {
              name: "PAMATAWAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "SUBIC",
            },
            {
              name: "WAWANDUE (POB.)",
            },
          ],
        },
      },
    },
    "ZAMBOANGA DEL NORTE": {
      name: "ZAMBOANGA DEL NORTE",
      region: "REGION 9",
      municipalities: {
        "BACUNGAN (LEON T. POSTIGO)": {
          name: "BACUNGAN (LEON T. POSTIGO)",
          baranggays: [
            {
              name: "BACUNGAN (LEON T. POSTIGO)",
            },
            {
              name: "BACUNGAN (POB.)",
            },
            {
              name: "BOGABONGAN",
            },
            {
              name: "DELUSOM",
            },
            {
              name: "MANGOP",
            },
            {
              name: "MANIL",
            },
            {
              name: "MAWAL",
            },
            {
              name: "MIDATAG",
            },
            {
              name: "MOROB",
            },
            {
              name: "NASIBAC",
            },
            {
              name: "RIZON",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SIPACONG",
            },
            {
              name: "TALINGA",
            },
            {
              name: "TINAPLAN",
            },
            {
              name: "TINIGUIBAN",
            },
            {
              name: "TINUYOP",
            },
            {
              name: "TIOGAN",
            },
            {
              name: "TITIK",
            },
          ],
        },
        BALIGUIAN: {
          name: "BALIGUIAN",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BALIGUIAN",
            },
            {
              name: "DIANGAS",
            },
            {
              name: "DICULOM",
            },
            {
              name: "GUIMOTAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KILALABAN",
            },
            {
              name: "LINAY",
            },
            {
              name: "LUMAY",
            },
            {
              name: "MALINAO",
            },
            {
              name: "MAMAD",
            },
            {
              name: "MAMAWAN",
            },
            {
              name: "MILIDAN",
            },
            {
              name: "NONOYAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TAMAO",
            },
            {
              name: "TAN-AWAN",
            },
          ],
        },
        "DAPITAN CITY": {
          name: "DAPITAN CITY",
          baranggays: [
            {
              name: "BAGTING (POB.)",
            },
            {
              name: "BANBANAN",
            },
            {
              name: "BANONONG (POB.)",
            },
            {
              name: "BARCELONA",
            },
            {
              name: "BAYLIMANGO",
            },
            {
              name: "BURGOS",
            },
            {
              name: "CANLUCANI",
            },
            {
              name: "CARANG",
            },
            {
              name: "CAWA-CAWA (POB.)",
            },
            {
              name: "DAMPALAN",
            },
            {
              name: "DAPITAN CITY",
            },
            {
              name: "DARO",
            },
            {
              name: "DAWO (POB.)",
            },
            {
              name: "DIWA-AN",
            },
            {
              name: "GUIMPUTLAN",
            },
            {
              name: "HILLTOP",
            },
            {
              name: "ILAYA",
            },
            {
              name: "KAUSWAGAN (TALISAY)",
            },
            {
              name: "LARAYAN",
            },
            {
              name: "LINABO (POB.)",
            },
            {
              name: "LIYANG",
            },
            {
              name: "MARIA CRISTINA",
            },
            {
              name: "MARIA URAY",
            },
            {
              name: "MASIDLAKON",
            },
            {
              name: "MATAGOBTOB POB. (TALISAY)",
            },
            {
              name: "NAPO",
            },
            {
              name: "OPAO",
            },
            {
              name: "ORO",
            },
            {
              name: "OWAON",
            },
            {
              name: "OYAN",
            },
            {
              name: "POLO",
            },
            {
              name: "POTOL (POB.)",
            },
            {
              name: "POTUNGAN",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN NICOLAS",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ (POB.)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SICAYAB BOCANA",
            },
            {
              name: "SIGAYAN",
            },
            {
              name: "SILINOG",
            },
            {
              name: "SINONOC",
            },
            {
              name: "SULANGON",
            },
            {
              name: "TAG-OLO",
            },
            {
              name: "TAGUILON",
            },
            {
              name: "TAMION",
            },
          ],
        },
        "DIPOLOG CITY": {
          name: "DIPOLOG CITY",
          baranggays: [
            {
              name: "BARRA (POB.)",
            },
            {
              name: "BIASONG (POB.)",
            },
            {
              name: "CENTRAL (POB.)",
            },
            {
              name: "COGON",
            },
            {
              name: "DICAYAS",
            },
            {
              name: "DIPOLOG CITY",
            },
            {
              name: "DIWAN",
            },
            {
              name: "ESTACA (POB.)",
            },
            {
              name: "GALAS",
            },
            {
              name: "GULAYON",
            },
            {
              name: "LUGDUNGAN",
            },
            {
              name: "MINAOG",
            },
            {
              name: "MIPUTAK (POB.)",
            },
            {
              name: "OLINGAN",
            },
            {
              name: "PUNTA",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANGKOL",
            },
            {
              name: "SANTA FILOMENA",
            },
            {
              name: "SANTA ISABEL",
            },
            {
              name: "SICAYAB",
            },
            {
              name: "SINAMAN",
            },
            {
              name: "TURNO",
            },
          ],
        },
        GODOD: {
          name: "GODOD",
          baranggays: [
            {
              name: "BALUNO",
            },
            {
              name: "BANUANGAN",
            },
            {
              name: "BUNAWAN",
            },
            {
              name: "DILUCOT",
            },
            {
              name: "DIPOPOR",
            },
            {
              name: "GODOD",
            },
            {
              name: "GUISAPONG",
            },
            {
              name: "LIMBONGA (LIMBOANGAN)",
            },
            {
              name: "LOMOGOM",
            },
            {
              name: "MAUSWAGON",
            },
            {
              name: "MIAMPIC",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RABA",
            },
            {
              name: "RAMBON",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SARAWAGAN",
            },
            {
              name: "SIANAN",
            },
            {
              name: "SIORAN",
            },
          ],
        },
        GUTALAC: {
          name: "GUTALAC",
          baranggays: [
            {
              name: "BACONG",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BANGANON",
            },
            {
              name: "BAYANIHAN",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "CANUPONG",
            },
            {
              name: "COCOB",
            },
            {
              name: "DATAGAN",
            },
            {
              name: "GUTALAC",
            },
            {
              name: "IMELDA",
            },
            {
              name: "IMMACULADA CONCEPCION",
            },
            {
              name: "LA LIBERTAD",
            },
            {
              name: "LOAY",
            },
            {
              name: "LOWER LUX",
            },
            {
              name: "LUX",
            },
            {
              name: "MALIAN",
            },
            {
              name: "MAMAWAN",
            },
            {
              name: "MAP",
            },
            {
              name: "MATUNOY",
            },
            {
              name: "NEW DAPITAN",
            },
            {
              name: "PANGANURAN",
            },
            {
              name: "PITAWE",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POBLACION (GUTALAC)",
            },
            {
              name: "SALVADOR",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAS",
            },
            {
              name: "SIBALIC",
            },
            {
              name: "TIPAN",
            },
            {
              name: "UPPER GUTALAC",
            },
          ],
        },
        "JOSE DALMAN (PONOT)": {
          name: "JOSE DALMAN (PONOT)",
          baranggays: [
            {
              name: "BALATAKAN",
            },
            {
              name: "BITOON",
            },
            {
              name: "DINASAN",
            },
            {
              name: "ILIHAN",
            },
            {
              name: "JOSE DALMAN (PONOT)",
            },
            {
              name: "LABAKID",
            },
            {
              name: "LIPAY",
            },
            {
              name: "LITALIP",
            },
            {
              name: "LOPERO",
            },
            {
              name: "LUMANPING",
            },
            {
              name: "MADALAG",
            },
            {
              name: "MANAWAN",
            },
            {
              name: "MARUPAY",
            },
            {
              name: "POBLACION (PONOT)",
            },
            {
              name: "SIGAMOK",
            },
            {
              name: "SIPAROK",
            },
            {
              name: "TABON",
            },
            {
              name: "TAMAROK",
            },
            {
              name: "TAMIL",
            },
          ],
        },
        KALAWIT: {
          name: "KALAWIT",
          baranggays: [
            {
              name: "BATAYAN",
            },
            {
              name: "BOTONG",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DANIEL MAING (DOMINOLOG)",
            },
            {
              name: "FATIMA (LACSUTAN)",
            },
            {
              name: "GATAS",
            },
            {
              name: "KALAWIT",
            },
            {
              name: "KALAWIT (POB.)",
            },
            {
              name: "MARCELO",
            },
            {
              name: "NEW CALAMBA",
            },
            {
              name: "PALALIAN",
            },
            {
              name: "PARAISO",
            },
            {
              name: "PIANON",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TUGOP",
            },
          ],
        },
        KATIPUNAN: {
          name: "KATIPUNAN",
          baranggays: [
            {
              name: "BALOK",
            },
            {
              name: "BARANGAY DOS (POB.)",
            },
            {
              name: "BARANGAY UNO (POB.)",
            },
            {
              name: "BASAGAN",
            },
            {
              name: "BINIRAY",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CARUPAY",
            },
            {
              name: "DAANGLUNGSOD",
            },
            {
              name: "DABIAK",
            },
            {
              name: "DR. JOSE RIZAL (LOWER MIAS)",
            },
            {
              name: "FIMAGAS",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LOYURAN",
            },
            {
              name: "MALASAY",
            },
            {
              name: "MALUGAS",
            },
            {
              name: "MATAM",
            },
            {
              name: "MIAS",
            },
            {
              name: "MIATAN",
            },
            {
              name: "NANGINAN",
            },
            {
              name: "NEW TAMBO",
            },
            {
              name: "PATIK",
            },
            {
              name: "SAN ANTONIO (LOOY)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANAO",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SERES",
            },
            {
              name: "SEROAN",
            },
            {
              name: "SINGATONG",
            },
            {
              name: "SINUYAK",
            },
            {
              name: "SITOG",
            },
            {
              name: "TUBURAN",
            },
          ],
        },
        "LA LIBERTAD": {
          name: "LA LIBERTAD",
          baranggays: [
            {
              name: "LA LIBERTAD",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LA VICTORIA",
            },
            {
              name: "MAUSWAGON",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "NEW ARGAO",
            },
            {
              name: "NEW BATAAN",
            },
            {
              name: "NEW CARCAR",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SINGARAN",
            },
          ],
        },
        LABASON: {
          name: "LABASON",
          baranggays: [
            {
              name: "ANTONINO (POB.)",
            },
            {
              name: "BALAS",
            },
            {
              name: "BOBONGAN",
            },
            {
              name: "DANSALAN",
            },
            {
              name: "GABU",
            },
            {
              name: "GIL SANCHEZ",
            },
            {
              name: "IMELDA",
            },
            {
              name: "IMMACULADA",
            },
            {
              name: "KIPIT",
            },
            {
              name: "LA UNION",
            },
            {
              name: "LABASON",
            },
            {
              name: "LAPATAN",
            },
            {
              name: "LAWAGAN",
            },
            {
              name: "LAWIGAN",
            },
            {
              name: "LOPOC (POB.)",
            },
            {
              name: "MALINTUBOAN",
            },
            {
              name: "NEW SALVACION",
            },
            {
              name: "OSUKAN",
            },
            {
              name: "PATAWAG",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "UBAY",
            },
          ],
        },
        LILOY: {
          name: "LILOY",
          baranggays: [
            {
              name: "BAYBAY (POB.)",
            },
            {
              name: "CABANGCALAN",
            },
            {
              name: "CANAAN",
            },
            {
              name: "CANDELARIA",
            },
            {
              name: "CAUSWAGAN",
            },
            {
              name: "COMMUNAL",
            },
            {
              name: "COMPRA",
            },
            {
              name: "DELA PAZ",
            },
            {
              name: "EL PARAISO",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GANASE",
            },
            {
              name: "GOAW",
            },
            {
              name: "GOIN",
            },
            {
              name: "KAYOK",
            },
            {
              name: "LA LIBERTAD (MAWAL)",
            },
            {
              name: "LAMAO",
            },
            {
              name: "LILOY",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAIGANG",
            },
            {
              name: "MALILA",
            },
            {
              name: "MAUSWAGON",
            },
            {
              name: "NEW BETHLEHEM",
            },
            {
              name: "OVERVIEW",
            },
            {
              name: "PANABANG",
            },
            {
              name: "PATAWAG",
            },
            {
              name: "PUNTA",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SILUCAP",
            },
            {
              name: "TAPICAN",
            },
            {
              name: "TIMAN",
            },
            {
              name: "VILLA CALIXTO SUDIACAL",
            },
            {
              name: "VILLA M. TEJERO",
            },
          ],
        },
        MANUKAN: {
          name: "MANUKAN",
          baranggays: [
            {
              name: "DIPANE",
            },
            {
              name: "DISAKAN",
            },
            {
              name: "DON JOSE AGUIRRE",
            },
            {
              name: "EAST POBLACION",
            },
            {
              name: "GUPOT",
            },
            {
              name: "LIBUTON",
            },
            {
              name: "LINAY",
            },
            {
              name: "LINGATONGAN",
            },
            {
              name: "LUPASANG",
            },
            {
              name: "MANUKAN",
            },
            {
              name: "MATE",
            },
            {
              name: "MESES",
            },
            {
              name: "PALARANAN",
            },
            {
              name: "PANGANDAO",
            },
            {
              name: "PATAGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNTA BLANCA",
            },
            {
              name: "SALUYONG",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SERONGAN",
            },
            {
              name: "SUISAYAN",
            },
            {
              name: "UPPER DISAKAN",
            },
            {
              name: "VILLARAMOS",
            },
          ],
        },
        MUTIA: {
          name: "MUTIA",
          baranggays: [
            {
              name: "ALVENDA",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "DILAND",
            },
            {
              name: "DIOLEN",
            },
            {
              name: "HEAD TIPAN",
            },
            {
              name: "MUTIA",
            },
            {
              name: "NEW CASUL",
            },
            {
              name: "NEW SIQUIJOR",
            },
            {
              name: "NEWLAND",
            },
            {
              name: "PASO RIO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTO TOMAS",
            },
            {
              name: "TINGLAN",
            },
            {
              name: "TOTONGON",
            },
            {
              name: "TUBAC",
            },
            {
              name: "UNIDOS",
            },
          ],
        },
        "PIÑAN (NEW PIÑAN)": {
          name: "PIÑAN (NEW PIÑAN)",
          baranggays: [
            {
              name: "ADANTE",
            },
            {
              name: "BACUYONG",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "CALICAN",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "DESIN",
            },
            {
              name: "DILAWA",
            },
            {
              name: "DIONUM",
            },
            {
              name: "LAPU-LAPU",
            },
            {
              name: "LOWER GUMAY",
            },
            {
              name: "LUZVILLA",
            },
            {
              name: "PIÑAN (NEW PIÑAN)",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SEGABE",
            },
            {
              name: "SIKITAN",
            },
            {
              name: "SILANO",
            },
            {
              name: "TERESITA",
            },
            {
              name: "TINAYTAYAN",
            },
            {
              name: "UBAY (DAAN TIPAN)",
            },
            {
              name: "UPPER GUMAY",
            },
            {
              name: "VILLARICO",
            },
          ],
        },
        POLANCO: {
          name: "POLANCO",
          baranggays: [
            {
              name: "ANASTACIO",
            },
            {
              name: "BANDERA",
            },
            {
              name: "BETHLEHEM",
            },
            {
              name: "DANGI",
            },
            {
              name: "DANSULLAN",
            },
            {
              name: "DE VENTA PERLA",
            },
            {
              name: "GUINLES",
            },
            {
              name: "ISIS",
            },
            {
              name: "LABRADOR (PRINDA)",
            },
            {
              name: "LAPAYANBAJA",
            },
            {
              name: "LETAPAN",
            },
            {
              name: "LINABO",
            },
            {
              name: "LINGASAD",
            },
            {
              name: "MACLEODES",
            },
            {
              name: "MAGANGON",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MILAD",
            },
            {
              name: "NEW LEBANGON",
            },
            {
              name: "NEW SICAYAB",
            },
            {
              name: "OBAY",
            },
            {
              name: "PIAN",
            },
            {
              name: "POBLACION NORTH",
            },
            {
              name: "POBLACION SOUTH",
            },
            {
              name: "POLANCO",
            },
            {
              name: "SAN ANTONIO (PAETAN)",
            },
            {
              name: "SAN MIGUEL (LOBOC)",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SANTO NIÑO (LANTOY)",
            },
            {
              name: "SIANIB",
            },
            {
              name: "SILAWE",
            },
            {
              name: "VILLAHERMOSA",
            },
          ],
        },
        "PRES. MANUEL A. ROXAS": {
          name: "PRES. MANUEL A. ROXAS",
          baranggays: [
            {
              name: "BALUBO",
            },
            {
              name: "BANBANAN",
            },
            {
              name: "CANIBONGAN",
            },
            {
              name: "CAPASE",
            },
            {
              name: "CAPE",
            },
            {
              name: "DENOMAN",
            },
            {
              name: "DOHINOB",
            },
            {
              name: "GALOKSO",
            },
            {
              name: "GUBAT",
            },
            {
              name: "IRASAN",
            },
            {
              name: "LABAKID",
            },
            {
              name: "LANGATIAN",
            },
            {
              name: "LIPAKAN",
            },
            {
              name: "MARUPAY",
            },
            {
              name: "MOLITON",
            },
            {
              name: "NABILID",
            },
            {
              name: "PANAMPALAY",
            },
            {
              name: "PANGOLOGON",
            },
            {
              name: "PIAO",
            },
            {
              name: "PIÑALAN",
            },
            {
              name: "PIÑAMAR",
            },
            {
              name: "PONGOLAN",
            },
            {
              name: "SALISIG",
            },
            {
              name: "SEBOD",
            },
            {
              name: "SIBATOG",
            },
            {
              name: "SITUBO",
            },
            {
              name: "TANAYAN",
            },
            {
              name: "TANTINGON",
            },
            {
              name: "UPPER IRASAN",
            },
            {
              name: "UPPER MINANG",
            },
            {
              name: "VILLAHERMOSO",
            },
          ],
        },
        RIZAL: {
          name: "RIZAL",
          baranggays: [
            {
              name: "BALUBOHAN",
            },
            {
              name: "BIRAYAN",
            },
            {
              name: "DAMASING",
            },
            {
              name: "EAST POBLACION",
            },
            {
              name: "LA ESPERANZA",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MABUNAO",
            },
            {
              name: "MITIMOS",
            },
            {
              name: "NANGCA",
            },
            {
              name: "NANGCAAN",
            },
            {
              name: "NAPILAN",
            },
            {
              name: "NASIPANG",
            },
            {
              name: "NEW DAPITAN",
            },
            {
              name: "NILABO",
            },
            {
              name: "NORTH MAPANG",
            },
            {
              name: "RIZAL",
            },
            {
              name: "RIZALINA",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SEBACA",
            },
            {
              name: "SIPAON",
            },
            {
              name: "SOUTH MAPANG",
            },
            {
              name: "TOLON",
            },
            {
              name: "WEST POBLACION",
            },
          ],
        },
        SALUG: {
          name: "SALUG",
          baranggays: [
            {
              name: "BACONG",
            },
            {
              name: "BALAKAN",
            },
            {
              name: "BINONI",
            },
            {
              name: "CALUCAP",
            },
            {
              name: "CANAWAN",
            },
            {
              name: "CARACOL",
            },
            {
              name: "DANAO",
            },
            {
              name: "DINOAN",
            },
            {
              name: "DIPOLOD",
            },
            {
              name: "FATIMA (POGAN)",
            },
            {
              name: "IPILAN",
            },
            {
              name: "LANAWAN",
            },
            {
              name: "LIGUAC",
            },
            {
              name: "LIPAKAN",
            },
            {
              name: "MUCAS",
            },
            {
              name: "PACUHAN",
            },
            {
              name: "POBLACION (SALUG)",
            },
            {
              name: "POBLACION EAST",
            },
            {
              name: "PUKAY",
            },
            {
              name: "RAMON MAGSAYSAY",
            },
            {
              name: "SALUG",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAMBALANG",
            },
            {
              name: "TAPALAN",
            },
          ],
        },
        "SERGIO OSMEÑA SR.": {
          name: "SERGIO OSMEÑA SR.",
          baranggays: [
            {
              name: "ANTONINO",
            },
            {
              name: "BAGONG BAGUIO",
            },
            {
              name: "BAGUMBAYAN",
            },
            {
              name: "BIAYON",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "DAMPALAN",
            },
            {
              name: "DANAO",
            },
            {
              name: "DON ELENO",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LABIRAY",
            },
            {
              name: "LIWANAG",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MACALIBRE",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MARAPONG",
            },
            {
              name: "NAZARETH",
            },
            {
              name: "NEBO",
            },
            {
              name: "NEW RIZAL",
            },
            {
              name: "NEW TANGUB",
            },
            {
              name: "NUEVAVISTA",
            },
            {
              name: "PEDAGAN",
            },
            {
              name: "PENACIO",
            },
            {
              name: "POBLACION ALTO",
            },
            {
              name: "POBLACION BAJO",
            },
            {
              name: "PRINCESA FRESHIA",
            },
            {
              name: "PRINCESA LAMAYA",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SERGIO OSMEÑA SR.",
            },
            {
              name: "SINAAD",
            },
            {
              name: "SINAI",
            },
            {
              name: "SITUBO",
            },
            {
              name: "TINAGO",
            },
            {
              name: "TININDUGAN",
            },
            {
              name: "TUBURAN",
            },
            {
              name: "VENUS",
            },
            {
              name: "WILBEN",
            },
          ],
        },
        SIAYAN: {
          name: "SIAYAN",
          baranggays: [
            {
              name: "BALOK",
            },
            {
              name: "BALUNOKAN",
            },
            {
              name: "DATAGAN",
            },
            {
              name: "DENOYAN",
            },
            {
              name: "DIONGAN",
            },
            {
              name: "DOMOGOK",
            },
            {
              name: "DUMPILAS",
            },
            {
              name: "GONAYEN",
            },
            {
              name: "GUIBO",
            },
            {
              name: "GUNYAN",
            },
            {
              name: "LITOLET",
            },
            {
              name: "MACASING",
            },
            {
              name: "MANGILAY",
            },
            {
              name: "MOYO",
            },
            {
              name: "MUÑOZ",
            },
            {
              name: "PANGE",
            },
            {
              name: "PARANGLUMBA (POB.)",
            },
            {
              name: "POLAYO",
            },
            {
              name: "SAYAW",
            },
            {
              name: "SERIAC",
            },
            {
              name: "SIAYAN",
            },
            {
              name: "SIAYAN PROPER (POB.)",
            },
            {
              name: "SUGUILON",
            },
          ],
        },
        SIBUCO: {
          name: "SIBUCO",
          baranggays: [
            {
              name: "ANONGAN",
            },
            {
              name: "BASAK",
            },
            {
              name: "BONGALAO",
            },
            {
              name: "CABBUNAN",
            },
            {
              name: "CAWIT-CAWIT",
            },
            {
              name: "CULAGUAN",
            },
            {
              name: "CUSIPAN",
            },
            {
              name: "DINULAN",
            },
            {
              name: "JATIAN",
            },
            {
              name: "KAMARANGAN",
            },
            {
              name: "LAKIKI",
            },
            {
              name: "LAMBAGOAN",
            },
            {
              name: "LIMPAPA",
            },
            {
              name: "LINGAYON",
            },
            {
              name: "LINTANGAN",
            },
            {
              name: "LITAWAN",
            },
            {
              name: "LUNDAY",
            },
            {
              name: "MALAYAL",
            },
            {
              name: "MANTIVO",
            },
            {
              name: "NALA (POB.)",
            },
            {
              name: "PANGANURAN",
            },
            {
              name: "PANGIAN",
            },
            {
              name: "PANIRAN",
            },
            {
              name: "PASILNAHUT",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULIRAN",
            },
            {
              name: "SANTO NIÑO (CULABOG)",
            },
            {
              name: "SIBUCO",
            },
            {
              name: "TANGARAK",
            },
          ],
        },
        SIBUTAD: {
          name: "SIBUTAD",
          baranggays: [
            {
              name: "BAGACAY",
            },
            {
              name: "CALILIC",
            },
            {
              name: "CALUBE",
            },
            {
              name: "DELAPA",
            },
            {
              name: "KANIM",
            },
            {
              name: "LIBAY",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MARAPONG",
            },
            {
              name: "MINLASAG",
            },
            {
              name: "OYAN",
            },
            {
              name: "PANGANURAN",
            },
            {
              name: "POBLACION (SIBUTAD)",
            },
            {
              name: "SAWANG",
            },
            {
              name: "SIBULOC",
            },
            {
              name: "SIBUTAD",
            },
            {
              name: "SINIPAY",
            },
            {
              name: "SIPALOC",
            },
          ],
        },
        SINDANGAN: {
          name: "SINDANGAN",
          baranggays: [
            {
              name: "BAGO",
            },
            {
              name: "BALOK",
            },
            {
              name: "BANTAYAN",
            },
            {
              name: "BATO",
            },
            {
              name: "BENIGNO AQUINO JR.",
            },
            {
              name: "BINUANGAN",
            },
            {
              name: "BITOON",
            },
            {
              name: "BUCANA",
            },
            {
              name: "CALATUNAN",
            },
            {
              name: "CALUAN",
            },
            {
              name: "CALUBIAN",
            },
            {
              name: "DAGOHOY",
            },
            {
              name: "DAPAON",
            },
            {
              name: "DATAGAN",
            },
            {
              name: "DATU TANGKILAN",
            },
            {
              name: "DICOYONG",
            },
            {
              name: "DISUD",
            },
            {
              name: "DON RICARDO MACIAS (DINOBOT)",
            },
            {
              name: "DOÑA JOSEFA",
            },
            {
              name: "DUMALOGDOG",
            },
            {
              name: "FATIMA",
            },
            {
              name: "GAMPIS",
            },
            {
              name: "GOLEO",
            },
            {
              name: "IMELDA",
            },
            {
              name: "INUMAN",
            },
            {
              name: "JOAQUIN MACIAS",
            },
            {
              name: "LA CONCEPCION",
            },
            {
              name: "LA ROCHE SAN MIGUEL",
            },
            {
              name: "LABAKID",
            },
            {
              name: "LAGAG",
            },
            {
              name: "LAPERO",
            },
            {
              name: "LAWIS",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MANDIH",
            },
            {
              name: "MARAS",
            },
            {
              name: "MAWAL",
            },
            {
              name: "MISOK",
            },
            {
              name: "MOTIBOT",
            },
            {
              name: "NATO",
            },
            {
              name: "NIPAAN",
            },
            {
              name: "PANGALALAN",
            },
            {
              name: "PIAO",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SIARE",
            },
            {
              name: "SINDANGAN",
            },
            {
              name: "TALINGA",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TINAPLAN",
            },
            {
              name: "TITIK",
            },
            {
              name: "UPPER INUMAN",
            },
            {
              name: "UPPER NIPAAN",
            },
          ],
        },
        SIOCON: {
          name: "SIOCON",
          baranggays: [
            {
              name: "ANDRES MICUBO JR. (BALILI)",
            },
            {
              name: "BALAGUNAN",
            },
            {
              name: "BUCANA",
            },
            {
              name: "BULACAN",
            },
            {
              name: "CANDIZ",
            },
            {
              name: "DATU SAILILA",
            },
            {
              name: "DIONISIO RICONALLA",
            },
            {
              name: "JOSE P. BRILLANTES, SR. (OLD LITUBAN)",
            },
            {
              name: "LATABON",
            },
            {
              name: "MAKIANG",
            },
            {
              name: "MALAMBUHANGIN",
            },
            {
              name: "MALIPOT",
            },
            {
              name: "MANAOL",
            },
            {
              name: "MATEO FRANCISCO",
            },
            {
              name: "MATIAG",
            },
            {
              name: "NEW LITUBAN",
            },
            {
              name: "PANGIAN",
            },
            {
              name: "PISAWAK",
            },
            {
              name: "POBLACION",
            },
            {
              name: "S. CABRAL",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SIAY",
            },
            {
              name: "SIOCON",
            },
            {
              name: "SUHAILE ARABI",
            },
            {
              name: "TABAYO",
            },
            {
              name: "TAGAYTAY",
            },
            {
              name: "TIBANGAO",
            },
          ],
        },
        SIRAWAI: {
          name: "SIRAWAI",
          baranggays: [
            {
              name: "BALATAKAN",
            },
            {
              name: "BALONKAN",
            },
            {
              name: "BALUBUAN",
            },
            {
              name: "BITUGAN",
            },
            {
              name: "BONGON",
            },
            {
              name: "CATUYAN",
            },
            {
              name: "CULASIAN",
            },
            {
              name: "DANGANON",
            },
            {
              name: "DOÑA CECILIA",
            },
            {
              name: "GUBAN",
            },
            {
              name: "LAGUNDI",
            },
            {
              name: "LIBUCON",
            },
            {
              name: "LUBOK",
            },
            {
              name: "MACUYON",
            },
            {
              name: "MINANGA",
            },
            {
              name: "MOTONG",
            },
            {
              name: "NAPULAN",
            },
            {
              name: "PANABUTAN",
            },
            {
              name: "PIACAN",
            },
            {
              name: "PIÑA",
            },
            {
              name: "PISA ITOM",
            },
            {
              name: "PISA PUTI",
            },
            {
              name: "PUGOS",
            },
            {
              name: "PULA BATO",
            },
            {
              name: "PULANG LUPA",
            },
            {
              name: "SAINT MARY (POB.)",
            },
            {
              name: "SAN NICOLAS (POB.)",
            },
            {
              name: "SAN ROQUE (POB.)",
            },
            {
              name: "SAN VICENTE (POB.)",
            },
            {
              name: "SIPAKIT",
            },
            {
              name: "SIPAWA",
            },
            {
              name: "SIRAWAI",
            },
            {
              name: "SIRAWAI PROPER (POB.)",
            },
            {
              name: "TALABIGA",
            },
            {
              name: "TAPANAYAN",
            },
          ],
        },
        TAMPILISAN: {
          name: "TAMPILISAN",
          baranggays: [
            {
              name: "BALACBAAN",
            },
            {
              name: "BANBANAN",
            },
            {
              name: "BARILI",
            },
            {
              name: "CABONG",
            },
            {
              name: "CAMUL",
            },
            {
              name: "FARMINGTON",
            },
            {
              name: "GALINGON",
            },
            {
              name: "LAWAAN",
            },
            {
              name: "LUMBAYAO",
            },
            {
              name: "MALILA-T",
            },
            {
              name: "MOLOS",
            },
            {
              name: "NEW DAPITAN",
            },
            {
              name: "POBLACION (TAMPILISAN)",
            },
            {
              name: "SANDAYONG",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SITUBO",
            },
            {
              name: "TAMPILISAN",
            },
            {
              name: "TILUBOG",
            },
            {
              name: "TININGGAAN",
            },
            {
              name: "TUBOD",
            },
            {
              name: "ZNAC",
            },
          ],
        },
      },
    },
    "ZAMBOANGA DEL SUR": {
      name: "ZAMBOANGA DEL SUR",
      region: "REGION 9",
      municipalities: {
        AURORA: {
          name: "AURORA",
          baranggays: [
            {
              name: "ACAD",
            },
            {
              name: "ALANG-ALANG",
            },
            {
              name: "ALEGRIA",
            },
            {
              name: "ANONANG",
            },
            {
              name: "AURORA",
            },
            {
              name: "BAGONG MANDAUE",
            },
            {
              name: "BAGONG MASLOG",
            },
            {
              name: "BAGONG OSLOB",
            },
            {
              name: "BAGONG PITOGO",
            },
            {
              name: "BAKI",
            },
            {
              name: "BALAS",
            },
            {
              name: "BALIDE",
            },
            {
              name: "BALINTAWAK",
            },
            {
              name: "BAYABAS",
            },
            {
              name: "BEMPOSA",
            },
            {
              name: "CABILINAN",
            },
            {
              name: "CAMPO UNO",
            },
            {
              name: "CEBONEG",
            },
            {
              name: "COMMONWEALTH",
            },
            {
              name: "GUBAAN",
            },
            {
              name: "INASAGAN",
            },
            {
              name: "INROAD",
            },
            {
              name: "KAHAYAGAN EAST (KATIPUNAN)",
            },
            {
              name: "KAHAYAGAN WEST",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LA PAZ (TINIBTIBAN)",
            },
            {
              name: "LA VICTORIA",
            },
            {
              name: "LANTUNGAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LINTUGOP",
            },
            {
              name: "LUBID",
            },
            {
              name: "MAGUIKAY",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MONTE ALEGRE",
            },
            {
              name: "MONTELA",
            },
            {
              name: "NAPO",
            },
            {
              name: "PANAGHIUSA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RESTHOUSE",
            },
            {
              name: "ROMARATE",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAPA LOBOC",
            },
            {
              name: "TAGULALO",
            },
            {
              name: "WATERFALL",
            },
          ],
        },
        BAYOG: {
          name: "BAYOG",
          baranggays: [
            {
              name: "BAKING",
            },
            {
              name: "BALUKBAHAN",
            },
            {
              name: "BALUMBUNAN",
            },
            {
              name: "BANTAL",
            },
            {
              name: "BAYOG",
            },
            {
              name: "BOBUAN",
            },
            {
              name: "CAMP BLESSING",
            },
            {
              name: "CANOAYAN",
            },
            {
              name: "CONACON",
            },
            {
              name: "DAGUM",
            },
            {
              name: "DAMIT",
            },
            {
              name: "DATAGAN",
            },
            {
              name: "DEPASE",
            },
            {
              name: "DEPILI",
            },
            {
              name: "DEPORE",
            },
            {
              name: "DEPOREHAN",
            },
            {
              name: "DIMALINAO",
            },
            {
              name: "KAHAYAGAN",
            },
            {
              name: "KANIPAAN",
            },
            {
              name: "LAMARE",
            },
            {
              name: "LIBA",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "MATUN-OG",
            },
            {
              name: "PANGI (SAN ISIDRO)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULANG BATO",
            },
            {
              name: "SALAWAGAN",
            },
            {
              name: "SIGACAD",
            },
            {
              name: "SUPON",
            },
          ],
        },
        DIMATALING: {
          name: "DIMATALING",
          baranggays: [
            {
              name: "BACAYAWAN",
            },
            {
              name: "BAHA",
            },
            {
              name: "BALANAGAN",
            },
            {
              name: "BALUNO",
            },
            {
              name: "BINUAY",
            },
            {
              name: "BUBURAY",
            },
            {
              name: "DIMATALING",
            },
            {
              name: "GRAP",
            },
            {
              name: "JOSEFINA",
            },
            {
              name: "KAGAWASAN",
            },
            {
              name: "LALAB",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "MAGAHIS",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALOAGAN",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SUGBAY UNO",
            },
            {
              name: "SUMBATO",
            },
            {
              name: "SUMPOT",
            },
            {
              name: "TINGGABULONG",
            },
            {
              name: "TINIGUANGAN",
            },
            {
              name: "TIPANGI",
            },
            {
              name: "UPPER LUDIONG",
            },
          ],
        },
        DINAS: {
          name: "DINAS",
          baranggays: [
            {
              name: "BACAWAN",
            },
            {
              name: "BENUATAN",
            },
            {
              name: "BERAY",
            },
            {
              name: "DINAS",
            },
            {
              name: "DON JOSE",
            },
            {
              name: "DONGOS",
            },
            {
              name: "EAST MIGPULAO",
            },
            {
              name: "GUINICOLALAY",
            },
            {
              name: "IGNACIO GARRATA (NEW MIRAPAO)",
            },
            {
              name: "KINACAP",
            },
            {
              name: "LEGARDA 1",
            },
            {
              name: "LEGARDA 2",
            },
            {
              name: "LEGARDA 3",
            },
            {
              name: "LOWER DIMAYA",
            },
            {
              name: "LUCOBAN",
            },
            {
              name: "LUDIONG",
            },
            {
              name: "NANGKA",
            },
            {
              name: "NIAN",
            },
            {
              name: "OLD MIRAPAO",
            },
            {
              name: "PISA-AN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PROPER DIMAYA",
            },
            {
              name: "SAGACAD",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SONGAYAN",
            },
            {
              name: "SUMPOTAN",
            },
            {
              name: "TARAKAN",
            },
            {
              name: "UPPER DIMAYA",
            },
            {
              name: "UPPER SIBUL",
            },
            {
              name: "WEST MIGPULAO",
            },
          ],
        },
        DUMALINAO: {
          name: "DUMALINAO",
          baranggays: [
            {
              name: "ANONANG",
            },
            {
              name: "BAG-ONG MISAMIS",
            },
            {
              name: "BAG-ONG SILAO",
            },
            {
              name: "BAGA",
            },
            {
              name: "BALOBOAN",
            },
            {
              name: "BANTA-AO",
            },
            {
              name: "BIBILIK",
            },
            {
              name: "CALINGAYAN",
            },
            {
              name: "CAMALIG",
            },
            {
              name: "CAMANGA",
            },
            {
              name: "CUATRO-CUATRO",
            },
            {
              name: "DUMALINAO",
            },
            {
              name: "LOCUBAN",
            },
            {
              name: "MALASIK",
            },
            {
              name: "MAMA (SAN JUAN)",
            },
            {
              name: "MATAB-ANG",
            },
            {
              name: "MECOLONG",
            },
            {
              name: "METOKONG",
            },
            {
              name: "MOTOSAWA",
            },
            {
              name: "PAG-ASA (POB.)",
            },
            {
              name: "PAGLAUM (POB.)",
            },
            {
              name: "PANTAD",
            },
            {
              name: "PINIGLIBANO",
            },
            {
              name: "REBOKON",
            },
            {
              name: "SAN AGUSTIN",
            },
            {
              name: "SIBUCAO",
            },
            {
              name: "SUMADAT",
            },
            {
              name: "TIKWAS",
            },
            {
              name: "TINA",
            },
            {
              name: "TUBO-PAIT",
            },
            {
              name: "UPPER DUMALINAO",
            },
          ],
        },
        DUMINGAG: {
          name: "DUMINGAG",
          baranggays: [
            {
              name: "BAG-ONG VALENCIA",
            },
            {
              name: "BAGONG KAUSWAGAN",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BUCAYAN",
            },
            {
              name: "CALUMANGGI",
            },
            {
              name: "CANIBONGAN",
            },
            {
              name: "CARIDAD",
            },
            {
              name: "DANLUGAN",
            },
            {
              name: "DAPIWAK",
            },
            {
              name: "DATU TOTOCAN",
            },
            {
              name: "DILUD",
            },
            {
              name: "DITULAN",
            },
            {
              name: "DULIAN",
            },
            {
              name: "DULOP",
            },
            {
              name: "DUMINGAG",
            },
            {
              name: "GUINTANANAN",
            },
            {
              name: "GUITRAN",
            },
            {
              name: "GUMPINGAN",
            },
            {
              name: "LA FORTUNA",
            },
            {
              name: "LABANGON",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LICABANG",
            },
            {
              name: "LIPAWAN",
            },
            {
              name: "LOWER LANDING",
            },
            {
              name: "LOWER TIMONAN",
            },
            {
              name: "MACASING",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALAGALAD",
            },
            {
              name: "MANLABAY",
            },
            {
              name: "MARALAG",
            },
            {
              name: "MARANGAN",
            },
            {
              name: "NEW BASAK",
            },
            {
              name: "SAAD",
            },
            {
              name: "SALVADOR",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PABLO (POB.)",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SENOTE",
            },
            {
              name: "SINONOK",
            },
            {
              name: "SUNOP",
            },
            {
              name: "TAGUN",
            },
            {
              name: "TAMURAYAN",
            },
            {
              name: "UPPER LANDING",
            },
            {
              name: "UPPER TIMONAN",
            },
          ],
        },
        GUIPOS: {
          name: "GUIPOS",
          baranggays: [
            {
              name: "BAGONG OROQUIETA",
            },
            {
              name: "BAGUITAN",
            },
            {
              name: "BALONGATING",
            },
            {
              name: "CANUNAN",
            },
            {
              name: "DACSOL",
            },
            {
              name: "DAGOHOY",
            },
            {
              name: "DALAPANG",
            },
            {
              name: "DATAGAN",
            },
            {
              name: "GUIPOS",
            },
            {
              name: "GULING",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "LINTUM",
            },
            {
              name: "LITAN",
            },
            {
              name: "MAGTING",
            },
            {
              name: "POBLACION (GUIPOS)",
            },
            {
              name: "REGLA",
            },
            {
              name: "SIKATUNA",
            },
            {
              name: "SINGCLOT",
            },
          ],
        },
        JOSEFINA: {
          name: "JOSEFINA",
          baranggays: [
            {
              name: "BOGO CALABAT",
            },
            {
              name: "DAWA (DIWA)",
            },
            {
              name: "EBARLE",
            },
            {
              name: "GUMAHAN (POB.)",
            },
            {
              name: "JOSEFINA",
            },
            {
              name: "LEONARDO",
            },
            {
              name: "LITAPAN",
            },
            {
              name: "LOWER BAGONG TUDELA",
            },
            {
              name: "MANSANAS",
            },
            {
              name: "MORADJI",
            },
            {
              name: "NEMEÑO",
            },
            {
              name: "NOPULAN",
            },
            {
              name: "SEBUKANG",
            },
            {
              name: "TAGAYTAY HILL",
            },
            {
              name: "UPPER BAGONG TUDELA (POB.)",
            },
          ],
        },
        KUMALARANG: {
          name: "KUMALARANG",
          baranggays: [
            {
              name: "BOGAYO",
            },
            {
              name: "BOLISONG",
            },
            {
              name: "BOYUGAN EAST",
            },
            {
              name: "BOYUGAN WEST",
            },
            {
              name: "BUALAN",
            },
            {
              name: "DIPLO",
            },
            {
              name: "GAWIL",
            },
            {
              name: "GUSOM",
            },
            {
              name: "KITAAN DAGAT",
            },
            {
              name: "KUMALARANG",
            },
            {
              name: "LANTAWAN",
            },
            {
              name: "LIMAMAWAN",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "PANGI",
            },
            {
              name: "PICANAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAGMANOK",
            },
            {
              name: "SECADE",
            },
            {
              name: "SUMINALUM",
            },
          ],
        },
        LABANGAN: {
          name: "LABANGAN",
          baranggays: [
            {
              name: "BAGALUPA",
            },
            {
              name: "BALIMBINGAN (WEST LUYA)",
            },
            {
              name: "BINAYAN",
            },
            {
              name: "BOKONG",
            },
            {
              name: "BULANIT",
            },
            {
              name: "COGONAN",
            },
            {
              name: "COMBO",
            },
            {
              name: "DALAPANG",
            },
            {
              name: "DIMASANGCA",
            },
            {
              name: "DIPAYA",
            },
            {
              name: "LABANGAN",
            },
            {
              name: "LANGAPOD",
            },
            {
              name: "LANTIAN",
            },
            {
              name: "LOWER CAMPO ISLAM (POB.)",
            },
            {
              name: "LOWER PULACAN",
            },
            {
              name: "LOWER SANG-AN",
            },
            {
              name: "NEW LABANGAN",
            },
            {
              name: "NOBORAN",
            },
            {
              name: "OLD LABANGAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "TAPODOC",
            },
            {
              name: "TAWAGAN NORTE",
            },
            {
              name: "UPPER CAMPO ISLAM (POB.)",
            },
            {
              name: "UPPER PULACAN",
            },
            {
              name: "UPPER SANG-AN",
            },
          ],
        },
        LAKEWOOD: {
          name: "LAKEWOOD",
          baranggays: [
            {
              name: "BAGONG KAHAYAG",
            },
            {
              name: "BAKING",
            },
            {
              name: "BISWANGAN",
            },
            {
              name: "BULULAWAN",
            },
            {
              name: "DAGUM",
            },
            {
              name: "GASA",
            },
            {
              name: "GATUB",
            },
            {
              name: "LAKEWOOD",
            },
            {
              name: "LUKUAN",
            },
            {
              name: "MATALANG",
            },
            {
              name: "POBLACION (LAKEWOOD)",
            },
            {
              name: "SAPANG PINOLES",
            },
            {
              name: "SEBUGUEY",
            },
            {
              name: "TIWALES",
            },
            {
              name: "TUBOD",
            },
          ],
        },
        LAPUYAN: {
          name: "LAPUYAN",
          baranggays: [
            {
              name: "BULAWAN",
            },
            {
              name: "CARPOC",
            },
            {
              name: "DANGANAN",
            },
            {
              name: "DANSAL",
            },
            {
              name: "DUMARA",
            },
            {
              name: "LAPUYAN",
            },
            {
              name: "LINOKMADALUM",
            },
            {
              name: "LUANAN",
            },
            {
              name: "LUBUSAN",
            },
            {
              name: "MAHALINGEB",
            },
            {
              name: "MANDEG",
            },
            {
              name: "MARALAG",
            },
            {
              name: "MARUING",
            },
            {
              name: "MOLUM",
            },
            {
              name: "PAMPANG",
            },
            {
              name: "PANTAD",
            },
            {
              name: "PINGALAY",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SALAMBUYAN",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAYOG",
            },
            {
              name: "TABON",
            },
            {
              name: "TALABAB",
            },
            {
              name: "TIGUHA",
            },
            {
              name: "TININGHALANG",
            },
            {
              name: "TIPASAN",
            },
            {
              name: "TUGAYA",
            },
          ],
        },
        MAHAYAG: {
          name: "MAHAYAG",
          baranggays: [
            {
              name: "BAG-ONG BALAMBAN",
            },
            {
              name: "BAG-ONG DALAGUETE",
            },
            {
              name: "BONIAO",
            },
            {
              name: "DELUSOM",
            },
            {
              name: "DIWAN",
            },
            {
              name: "GURIPAN",
            },
            {
              name: "KAANGAYAN",
            },
            {
              name: "KABUHI",
            },
            {
              name: "LOURMAH (LOWER MAHAYAG)",
            },
            {
              name: "LOWER SALUG DAKU",
            },
            {
              name: "LOWER SANTO NIÑO",
            },
            {
              name: "MAHAYAG",
            },
            {
              name: "MALUBO",
            },
            {
              name: "MANGUILES",
            },
            {
              name: "MARABANAN (BALANAN)",
            },
            {
              name: "PANAGAAN",
            },
            {
              name: "PARAISO",
            },
            {
              name: "PEDAGAN",
            },
            {
              name: "POBLACION (UPPER MAHAYAG)",
            },
            {
              name: "PUGWAN",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SICPAO",
            },
            {
              name: "TUBORAN",
            },
            {
              name: "TULAN",
            },
            {
              name: "TUMAPIC",
            },
            {
              name: "UPPER SALUG DAKU",
            },
            {
              name: "UPPER SANTO NIÑO",
            },
          ],
        },
        MARGOSATUBIG: {
          name: "MARGOSATUBIG",
          baranggays: [
            {
              name: "BALINTAWAK",
            },
            {
              name: "BULARONG",
            },
            {
              name: "DIGON",
            },
            {
              name: "GUINIMANAN",
            },
            {
              name: "IGAT ISLAND",
            },
            {
              name: "JOSEFINA",
            },
            {
              name: "KALIAN",
            },
            {
              name: "KOLOT",
            },
            {
              name: "LIMABATONG",
            },
            {
              name: "LIMAMAWAN",
            },
            {
              name: "LUMBOG",
            },
            {
              name: "MAGAHIS",
            },
            {
              name: "MARGOSATUBIG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAGUA",
            },
            {
              name: "TALANUSA",
            },
            {
              name: "TIGUIAN",
            },
            {
              name: "TULAPOC",
            },
          ],
        },
        MIDSALIP: {
          name: "MIDSALIP",
          baranggays: [
            {
              name: "BACAHAN",
            },
            {
              name: "BALONAI",
            },
            {
              name: "BIBILOP",
            },
            {
              name: "BULORON",
            },
            {
              name: "CABALORAN",
            },
            {
              name: "CANIPAY NORTE",
            },
            {
              name: "CANIPAY SUR",
            },
            {
              name: "CUMARON",
            },
            {
              name: "DAKAYAKAN",
            },
            {
              name: "DUELIC",
            },
            {
              name: "DUMALINAO",
            },
            {
              name: "ECUAN",
            },
            {
              name: "GOLICTOP",
            },
            {
              name: "GUINABOT",
            },
            {
              name: "GUITALOS",
            },
            {
              name: "GUMA",
            },
            {
              name: "KAHAYAGAN",
            },
            {
              name: "LICURO-AN",
            },
            {
              name: "LUMPUNID",
            },
            {
              name: "MATALANG",
            },
            {
              name: "MIDSALIP",
            },
            {
              name: "NEW KATIPUNAN",
            },
            {
              name: "NEW UNIDOS",
            },
            {
              name: "PALILI",
            },
            {
              name: "PAWAN",
            },
            {
              name: "PILI",
            },
            {
              name: "PISOMPONGAN",
            },
            {
              name: "PIWAN",
            },
            {
              name: "POBLACION A",
            },
            {
              name: "POBLACION B",
            },
            {
              name: "SIGAPOD",
            },
            {
              name: "TIMBABOY",
            },
            {
              name: "TULBONG",
            },
            {
              name: "TULUAN",
            },
          ],
        },
        MOLAVE: {
          name: "MOLAVE",
          baranggays: [
            {
              name: "ALICIA",
            },
            {
              name: "ARIOSA",
            },
            {
              name: "BAGONG ARGAO",
            },
            {
              name: "BAGONG GUTLANG",
            },
            {
              name: "BLANCIA",
            },
            {
              name: "BOGO CAPALARAN",
            },
            {
              name: "CULO",
            },
            {
              name: "DALAON",
            },
            {
              name: "DIPOLO",
            },
            {
              name: "DONTULAN",
            },
            {
              name: "GONOSAN",
            },
            {
              name: "LOWER DIMALINAO",
            },
            {
              name: "LOWER DIMOROK",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MADASIGON (POB.)",
            },
            {
              name: "MAKUGUIHON (POB.)",
            },
            {
              name: "MALOLOY-ON (POB.)",
            },
            {
              name: "MILIGAN",
            },
            {
              name: "MOLAVE",
            },
            {
              name: "PARASAN",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SILANGIT",
            },
            {
              name: "SIMATA",
            },
            {
              name: "SUDLON",
            },
            {
              name: "UPPER DIMOROK",
            },
          ],
        },
        "PAGADIAN CITY": {
          name: "PAGADIAN CITY",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "BALANGASAN (POB.)",
            },
            {
              name: "BALINTAWAK",
            },
            {
              name: "BALOYBOAN",
            },
            {
              name: "BANALE",
            },
            {
              name: "BOGO",
            },
            {
              name: "BOMBA",
            },
            {
              name: "BUENAVISTA",
            },
            {
              name: "BULATOK",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "DAMPALAN",
            },
            {
              name: "DANLUGAN",
            },
            {
              name: "DAO",
            },
            {
              name: "DATAGAN",
            },
            {
              name: "DEBOROK",
            },
            {
              name: "DITORAY",
            },
            {
              name: "DUMAGOC",
            },
            {
              name: "GATAS (POB.)",
            },
            {
              name: "GUBAC",
            },
            {
              name: "GUBANG",
            },
            {
              name: "KAGAWASAN",
            },
            {
              name: "KAHAYAGAN",
            },
            {
              name: "KALASAN",
            },
            {
              name: "KAWIT",
            },
            {
              name: "LA SUERTE",
            },
            {
              name: "LALA",
            },
            {
              name: "LAPIDIAN",
            },
            {
              name: "LENIENZA",
            },
            {
              name: "LIZON VALLEY",
            },
            {
              name: "LOURDES",
            },
            {
              name: "LOWER SIBATANG",
            },
            {
              name: "LUMAD",
            },
            {
              name: "LUMBIA",
            },
            {
              name: "MACASING",
            },
            {
              name: "MANGA",
            },
            {
              name: "MURICAY",
            },
            {
              name: "NAPOLAN",
            },
            {
              name: "PAGADIAN CITY",
            },
            {
              name: "PALPALAN",
            },
            {
              name: "PEDULONAN",
            },
            {
              name: "POLOYAGAN",
            },
            {
              name: "SAN FRANCISCO (POB.)",
            },
            {
              name: "SAN JOSE (POB.)",
            },
            {
              name: "SAN PEDRO (POB.)",
            },
            {
              name: "SANTA LUCIA (POB.)",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTIAGO (POB.)",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TAWAGAN SUR",
            },
            {
              name: "TIGUMA",
            },
            {
              name: "TUBURAN (POB.)",
            },
            {
              name: "TULANGAN",
            },
            {
              name: "TULAWAS",
            },
            {
              name: "UPPER SIBATANG",
            },
            {
              name: "WHITE BEACH",
            },
          ],
        },
        PITOGO: {
          name: "PITOGO",
          baranggays: [
            {
              name: "BALABAWAN",
            },
            {
              name: "BALONG-BALONG",
            },
            {
              name: "COLOJO",
            },
            {
              name: "LIASAN",
            },
            {
              name: "LIGUAC",
            },
            {
              name: "LIMBAYAN",
            },
            {
              name: "LOWER PANIKI-AN",
            },
            {
              name: "MATIN-AO",
            },
            {
              name: "PANUBIGAN",
            },
            {
              name: "PITOGO",
            },
            {
              name: "POBLACION (PITOGO)",
            },
            {
              name: "PUNTA FLECHA",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SUGBAY DOS",
            },
            {
              name: "TONGAO",
            },
            {
              name: "UPPER PANIKI-AN",
            },
          ],
        },
        "RAMON MAGSAYSAY (LIARGO)": {
          name: "RAMON MAGSAYSAY (LIARGO)",
          baranggays: [
            {
              name: "BAGONG OPON",
            },
            {
              name: "BAMBONG DAKU",
            },
            {
              name: "BAMBONG DIUT",
            },
            {
              name: "BOBONGAN",
            },
            {
              name: "CAMPO IV",
            },
            {
              name: "CAMPO V",
            },
            {
              name: "CANIANGAN",
            },
            {
              name: "DIPALUSAN",
            },
            {
              name: "EASTERN BOBONGAN",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "GAPASAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LOWER SAMBULAWAN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALATING",
            },
            {
              name: "PARADISE",
            },
            {
              name: "PASINGKALAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RAMON MAGSAYSAY (LIARGO)",
            },
            {
              name: "SAN FERNANDO",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SAPA ANDING",
            },
            {
              name: "SINAGUING",
            },
            {
              name: "SWITCH",
            },
            {
              name: "UPPER LAPERIAN",
            },
            {
              name: "WAKAT",
            },
          ],
        },
        "SAN MIGUEL": {
          name: "SAN MIGUEL",
          baranggays: [
            {
              name: "BETINAN",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CALUBE",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAO-AN",
            },
            {
              name: "DUMALIAN",
            },
            {
              name: "FATIMA",
            },
            {
              name: "LANGILAN",
            },
            {
              name: "LANTAWAN",
            },
            {
              name: "LAPERIAN",
            },
            {
              name: "LIBUGANAN",
            },
            {
              name: "LIMONAN",
            },
            {
              name: "MATI",
            },
            {
              name: "OCAPAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SAYOG",
            },
            {
              name: "TAPIAN",
            },
          ],
        },
        "SAN PABLO": {
          name: "SAN PABLO",
          baranggays: [
            {
              name: "BAG-ONG MISAMIS",
            },
            {
              name: "BUBUAL",
            },
            {
              name: "BUTON",
            },
            {
              name: "CULASIAN",
            },
            {
              name: "DAPLAYAN",
            },
            {
              name: "KALILANGAN",
            },
            {
              name: "KAPAMANOK",
            },
            {
              name: "KONDUM",
            },
            {
              name: "LUMBAYAO",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MARCOS VILLAGE",
            },
            {
              name: "MIASIN",
            },
            {
              name: "MOLANSONG",
            },
            {
              name: "PANTAD",
            },
            {
              name: "PAO",
            },
            {
              name: "PAYAG",
            },
            {
              name: "POBLACION (SAN PABLO)",
            },
            {
              name: "PONGAPONG",
            },
            {
              name: "SACBULAN",
            },
            {
              name: "SAGASAN",
            },
            {
              name: "SAN JUAN",
            },
            {
              name: "SAN PABLO",
            },
            {
              name: "SENIOR",
            },
            {
              name: "SONGGOY",
            },
            {
              name: "TANDUBUAY",
            },
            {
              name: "TANIAPAN",
            },
            {
              name: "TICALA ISLAND",
            },
            {
              name: "TUBO-PAIT",
            },
            {
              name: "VILLAKAPA",
            },
          ],
        },
        "SOMINOT (DON MARIANO MARCOS)": {
          name: "SOMINOT (DON MARIANO MARCOS)",
          baranggays: [
            {
              name: "BAG-ONG BAROY",
            },
            {
              name: "BAG-ONG OROQUIETA",
            },
            {
              name: "BARUBUHAN",
            },
            {
              name: "BULANAY",
            },
            {
              name: "DATAGAN",
            },
            {
              name: "EASTERN POBLACION",
            },
            {
              name: "LANTAWAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LUMANGOY",
            },
            {
              name: "NEW CARMEN",
            },
            {
              name: "PICTURAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIZAL",
            },
            {
              name: "SAN MIGUEL",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SAWA",
            },
            {
              name: "SOMINOT (DON MARIANO MARCOS)",
            },
            {
              name: "TUNGAWAN",
            },
            {
              name: "UPPER SICPAO",
            },
          ],
        },
        TABINA: {
          name: "TABINA",
          baranggays: [
            {
              name: "ABONG-ABONG",
            },
            {
              name: "BAGANIAN",
            },
            {
              name: "BAYA-BAYA",
            },
            {
              name: "CAPISAN",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "CULABAY",
            },
            {
              name: "DOÑA JOSEFINA",
            },
            {
              name: "LUMBIA",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALIM",
            },
            {
              name: "MANIKAAN",
            },
            {
              name: "NEW OROQUIETA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN FRANCISCO",
            },
            {
              name: "TABINA",
            },
            {
              name: "TULTOLAN",
            },
          ],
        },
        TAMBULIG: {
          name: "TAMBULIG",
          baranggays: [
            {
              name: "ALANG-ALANG",
            },
            {
              name: "ANGELES",
            },
            {
              name: "BAG-ONG KAUSWAGAN",
            },
            {
              name: "BAG-ONG TABOGON",
            },
            {
              name: "BALUGO",
            },
            {
              name: "CABGAN",
            },
            {
              name: "CALOLOT",
            },
            {
              name: "DIMALINAO",
            },
            {
              name: "FABIAN (BALUCOT)",
            },
            {
              name: "GABUNON",
            },
            {
              name: "HAPPY VALLEY (POB.)",
            },
            {
              name: "KAPALARAN",
            },
            {
              name: "LIBATO",
            },
            {
              name: "LIMAMAWAN",
            },
            {
              name: "LOWER LIASAN",
            },
            {
              name: "LOWER LODIONG (POB.)",
            },
            {
              name: "LOWER TIPARAK",
            },
            {
              name: "LOWER USOGAN",
            },
            {
              name: "MAYA-MAYA",
            },
            {
              name: "NEW VILLAGE (POB.)",
            },
            {
              name: "PELOCOBAN",
            },
            {
              name: "RIVERSIDE (POB.)",
            },
            {
              name: "SAGRADA FAMILIA",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SUMALIG",
            },
            {
              name: "TAMBULIG",
            },
            {
              name: "TULUAN",
            },
            {
              name: "TUNGAWAN",
            },
            {
              name: "UPPER LIASON",
            },
            {
              name: "UPPER LODIONG",
            },
            {
              name: "UPPER TIPARAK",
            },
          ],
        },
        TIGBAO: {
          name: "TIGBAO",
          baranggays: [
            {
              name: "BEGONG",
            },
            {
              name: "BUSOL",
            },
            {
              name: "CALUMA",
            },
            {
              name: "DIANA COUNTRYSIDE",
            },
            {
              name: "GUINLIN",
            },
            {
              name: "LACARAYAN",
            },
            {
              name: "LACUPAYAN",
            },
            {
              name: "LIBAYOY",
            },
            {
              name: "LIMAS",
            },
            {
              name: "LONGMOT",
            },
            {
              name: "MARAGANG",
            },
            {
              name: "MATE",
            },
            {
              name: "NANGAN-NANGAN",
            },
            {
              name: "NEW TUBURAN",
            },
            {
              name: "NILO",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TIGBAO",
            },
            {
              name: "TIMOLAN",
            },
            {
              name: "UPPER NILO",
            },
          ],
        },
        TUKURAN: {
          name: "TUKURAN",
          baranggays: [
            {
              name: "ALINDAHAW",
            },
            {
              name: "BACLAY",
            },
            {
              name: "BALIMBINGAN",
            },
            {
              name: "BUENASUERTE",
            },
            {
              name: "CAMANGA",
            },
            {
              name: "CURVADA",
            },
            {
              name: "LAPERIAN",
            },
            {
              name: "LIBERTAD",
            },
            {
              name: "LOWER BAYAO",
            },
            {
              name: "LUY-A",
            },
            {
              name: "MANILAN",
            },
            {
              name: "MANLAYAG",
            },
            {
              name: "MILITAR",
            },
            {
              name: "NAVALAN",
            },
            {
              name: "PANDUMA SENIOR",
            },
            {
              name: "SAMBULAWAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN CARLOS (POB.)",
            },
            {
              name: "SANTO NIÑO (POB.)",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SUGOD",
            },
            {
              name: "TABUAN",
            },
            {
              name: "TAGULO",
            },
            {
              name: "TINOTUNGAN",
            },
            {
              name: "TUKURAN",
            },
            {
              name: "UPPER BAYAO",
            },
          ],
        },
        "VINCENZO A. SAGUN": {
          name: "VINCENZO A. SAGUN",
          baranggays: [
            {
              name: "AMBULON",
            },
            {
              name: "BUI-OS",
            },
            {
              name: "COGON",
            },
            {
              name: "DANAN",
            },
            {
              name: "KABATAN",
            },
            {
              name: "KAPATAGAN",
            },
            {
              name: "LIMASON",
            },
            {
              name: "LINOGUAYAN",
            },
            {
              name: "LUMBAL",
            },
            {
              name: "LUNIB",
            },
            {
              name: "MACULAY",
            },
            {
              name: "MARAYA",
            },
            {
              name: "SAGUCAN",
            },
            {
              name: "VINCENZO A. SAGUN",
            },
            {
              name: "WALING-WALING",
            },
          ],
        },
        "ZAMBOANGA CITY": {
          name: "ZAMBOANGA CITY",
          baranggays: [
            {
              name: "ARENA BLANCO",
            },
            {
              name: "AYALA",
            },
            {
              name: "BALIWASAN",
            },
            {
              name: "BALUNO",
            },
            {
              name: "BARANGAY ZONE I (POB.)",
            },
            {
              name: "BARANGAY ZONE II (POB.)",
            },
            {
              name: "BARANGAY ZONE III (POB.)",
            },
            {
              name: "BARANGAY ZONE IV (POB.)",
            },
            {
              name: "BOALAN",
            },
            {
              name: "BOLONG",
            },
            {
              name: "BUNGUIAO",
            },
            {
              name: "BUSAY (SACOL ISLAND)",
            },
            {
              name: "CABALUAY",
            },
            {
              name: "CABATANGAN",
            },
            {
              name: "CACAO",
            },
            {
              name: "CALABASA",
            },
            {
              name: "CALARIAN",
            },
            {
              name: "CAMINO NUEVO",
            },
            {
              name: "CAMPO ISLAM",
            },
            {
              name: "CANELAR",
            },
            {
              name: "CAPISAN",
            },
            {
              name: "CAWIT",
            },
            {
              name: "CULIANAN",
            },
            {
              name: "CURUAN",
            },
            {
              name: "DITA",
            },
            {
              name: "DIVISORIA",
            },
            {
              name: "DULIAN (UPPER BUNGUIAO)",
            },
            {
              name: "DULIAN (UPPER PASONANCA)",
            },
            {
              name: "GUISAO",
            },
            {
              name: "GUIWAN",
            },
            {
              name: "KASANYANGAN",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LABUAN",
            },
            {
              name: "LAMISAHAN",
            },
            {
              name: "LANDANG GUA",
            },
            {
              name: "LANDANG LAUM",
            },
            {
              name: "LANZONES",
            },
            {
              name: "LAPAKAN",
            },
            {
              name: "LATUAN (CURUAN)",
            },
            {
              name: "LICOMO",
            },
            {
              name: "LIMAONG",
            },
            {
              name: "LIMPAPA",
            },
            {
              name: "LUBIGAN",
            },
            {
              name: "LUMAYANG",
            },
            {
              name: "LUMBANGAN",
            },
            {
              name: "LUNZURAN",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MALAGUTAY",
            },
            {
              name: "MAMPANG",
            },
            {
              name: "MANALIPA",
            },
            {
              name: "MANGUSU",
            },
            {
              name: "MANICAHAN",
            },
            {
              name: "MARIKI",
            },
            {
              name: "MERCEDES",
            },
            {
              name: "MUTI",
            },
            {
              name: "PAMUCUTAN",
            },
            {
              name: "PANGAPUYAN",
            },
            {
              name: "PANUBIGAN",
            },
            {
              name: "PASILMANTA (SACOL ISLAND)",
            },
            {
              name: "PASOBOLONG",
            },
            {
              name: "PASONANCA",
            },
            {
              name: "PATALON",
            },
            {
              name: "PUTIK",
            },
            {
              name: "QUINIPUT",
            },
            {
              name: "RECODO",
            },
            {
              name: "RIO HONDO",
            },
            {
              name: "SALAAN",
            },
            {
              name: "SAN JOSE CAWA-CAWA",
            },
            {
              name: "SAN JOSE GUSU",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SANGALI",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "SANTA CATALINA",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SIBULAO (CARUAN)",
            },
            {
              name: "SINUBUNG",
            },
            {
              name: "SINUNOC",
            },
            {
              name: "TAGASILAY",
            },
            {
              name: "TAGUITI",
            },
            {
              name: "TALABAAN",
            },
            {
              name: "TALISAYAN",
            },
            {
              name: "TALON-TALON",
            },
            {
              name: "TALUKSANGAY",
            },
            {
              name: "TETUAN",
            },
            {
              name: "TICTAPUL",
            },
            {
              name: "TIGBALABAG",
            },
            {
              name: "TIGTABON",
            },
            {
              name: "TOLOSA",
            },
            {
              name: "TUGBUNGAN",
            },
            {
              name: "TULUNGATUNG",
            },
            {
              name: "TUMAGA",
            },
            {
              name: "TUMALUTAB",
            },
            {
              name: "TUMITUS",
            },
            {
              name: "VICTORIA",
            },
            {
              name: "VITALI",
            },
            {
              name: "ZAMBOANGA CITY",
            },
            {
              name: "ZAMBOWOOD",
            },
          ],
        },
      },
    },
    "ZAMBOANGA SIBUGAY": {
      name: "ZAMBOANGA SIBUGAY",
      region: "REGION 9",
      municipalities: {
        ALICIA: {
          name: "ALICIA",
          baranggays: [
            {
              name: "ALEGRIA",
            },
            {
              name: "ALICIA",
            },
            {
              name: "BAGONG BUHAY",
            },
            {
              name: "BELLA",
            },
            {
              name: "CALADES",
            },
            {
              name: "CONCEPCION",
            },
            {
              name: "DAWA-DAWA",
            },
            {
              name: "GULAYON",
            },
            {
              name: "ILISAN",
            },
            {
              name: "KAPATAGAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "KAWAYAN",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LAMBUYOGAN",
            },
            {
              name: "LAPIRAWAN",
            },
            {
              name: "LITAYON",
            },
            {
              name: "LUTIMAN",
            },
            {
              name: "MILAGROSA (BALUNO)",
            },
            {
              name: "NAGA-NAGA",
            },
            {
              name: "PANDAN-PANDAN",
            },
            {
              name: "PAYONGAN",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "TALAPTAP",
            },
            {
              name: "TAMPALAN",
            },
            {
              name: "TANDIONG MUSLIM",
            },
            {
              name: "TIMBANG-TIMBANG",
            },
          ],
        },
        BUUG: {
          name: "BUUG",
          baranggays: [
            {
              name: "AGUTAYAN",
            },
            {
              name: "BAGONG BORBON",
            },
            {
              name: "BASALEM",
            },
            {
              name: "BAWANG",
            },
            {
              name: "BLISS",
            },
            {
              name: "BULAAN",
            },
            {
              name: "BUUG",
            },
            {
              name: "COMPOSTELA",
            },
            {
              name: "DANLUGAN",
            },
            {
              name: "DATU PANAS",
            },
            {
              name: "DEL MONTE",
            },
            {
              name: "GUINTULOAN",
            },
            {
              name: "GUITOM",
            },
            {
              name: "GUMINTA",
            },
            {
              name: "LABRADOR",
            },
            {
              name: "LANTAWAN",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MAGANAY",
            },
            {
              name: "MANLIN",
            },
            {
              name: "MUYO",
            },
            {
              name: "PAMINTAYAN",
            },
            {
              name: "PLING",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PULOG",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "TALAIRAN",
            },
            {
              name: "TALAMIMI",
            },
            {
              name: "VILLACASTOR (GALIT)",
            },
          ],
        },
        DIPLAHAN: {
          name: "DIPLAHAN",
          baranggays: [
            {
              name: "BALANGAO",
            },
            {
              name: "BUTONG",
            },
            {
              name: "DIPLAHAN",
            },
            {
              name: "DITAY",
            },
            {
              name: "GAULAN",
            },
            {
              name: "GOLING",
            },
            {
              name: "GUINOMAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LINDANG",
            },
            {
              name: "LOBING",
            },
            {
              name: "LUOP",
            },
            {
              name: "MANANGON",
            },
            {
              name: "MEJO",
            },
            {
              name: "NATAN",
            },
            {
              name: "PARADISE",
            },
            {
              name: "PILAR",
            },
            {
              name: "POBLACION (DIPLAHAN)",
            },
            {
              name: "SAMPOLI A",
            },
            {
              name: "SAMPOLI B",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SONGCUYA",
            },
            {
              name: "TINONGTONGAN",
            },
            {
              name: "TUNO",
            },
          ],
        },
        IMELDA: {
          name: "IMELDA",
          baranggays: [
            {
              name: "BALUGO",
            },
            {
              name: "BALUNGISAN",
            },
            {
              name: "BALUYAN",
            },
            {
              name: "CANA-AN",
            },
            {
              name: "DUMPOC",
            },
            {
              name: "GANDIANGAN",
            },
            {
              name: "IMELDA",
            },
            {
              name: "ISRAEL (BALIAN ISRAEL)",
            },
            {
              name: "LA VICTORIA",
            },
            {
              name: "LITTLE BAGUIO",
            },
            {
              name: "LOWER BALURAN",
            },
            {
              name: "LUMBOG",
            },
            {
              name: "LUMPANAC",
            },
            {
              name: "MALI LITTLE BAGUIO",
            },
            {
              name: "POBLACION (SANTA FE)",
            },
            {
              name: "PULAWAN (MT. VIEW)",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA BARBARA",
            },
            {
              name: "UPPER BALURAN",
            },
          ],
        },
        IPIL: {
          name: "IPIL",
          baranggays: [
            {
              name: "BACALAN",
            },
            {
              name: "BANGKEROHAN",
            },
            {
              name: "BULU-AN",
            },
            {
              name: "CAPARAN",
            },
            {
              name: "DOMANDAN",
            },
            {
              name: "DON ANDRES",
            },
            {
              name: "DOÑA JOSEFA",
            },
            {
              name: "GUITUAN",
            },
            {
              name: "IPIL",
            },
            {
              name: "IPIL HEIGHTS",
            },
            {
              name: "LABI",
            },
            {
              name: "LOGAN",
            },
            {
              name: "LOWER IPIL HEIGHTS",
            },
            {
              name: "LOWER TAWAY",
            },
            {
              name: "LUMBIA",
            },
            {
              name: "MAASIN",
            },
            {
              name: "MAGDAUP",
            },
            {
              name: "MAKILAS",
            },
            {
              name: "PANGI",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SANITO",
            },
            {
              name: "SUCLEMA",
            },
            {
              name: "TAWAY",
            },
            {
              name: "TENAN",
            },
            {
              name: "TIAYON",
            },
            {
              name: "TIMALANG",
            },
            {
              name: "TOMITOM",
            },
            {
              name: "UPPER PANGI",
            },
            {
              name: "VETERAN'S VILLAGE (RUIZ)",
            },
          ],
        },
        KABASALAN: {
          name: "KABASALAN",
          baranggays: [
            {
              name: "BANKER",
            },
            {
              name: "BOLO BATALLION",
            },
            {
              name: "BUAYAN",
            },
            {
              name: "CAINGLET",
            },
            {
              name: "CALAPAN",
            },
            {
              name: "CALUBIHAN",
            },
            {
              name: "CONCEPCION (BALUNGIS)",
            },
            {
              name: "DIAMPAK",
            },
            {
              name: "DIPALA",
            },
            {
              name: "GACBUSAN",
            },
            {
              name: "GOODYEAR",
            },
            {
              name: "KABASALAN",
            },
            {
              name: "LACNAPAN",
            },
            {
              name: "LITTLE BAGUIO",
            },
            {
              name: "LUMBAYAO",
            },
            {
              name: "NAZARETH",
            },
            {
              name: "PALINTA",
            },
            {
              name: "PEÑARANDA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "RIVERSIDE",
            },
            {
              name: "SANGHANAN",
            },
            {
              name: "SANTA CRUZ",
            },
            {
              name: "SAYAO",
            },
            {
              name: "SHIOLAN",
            },
            {
              name: "SIMBOL",
            },
            {
              name: "SININAN",
            },
            {
              name: "TAMIN",
            },
            {
              name: "TAMPILISAN",
            },
            {
              name: "TIGBANGAGAN",
            },
            {
              name: "TIMUAY DANDA (MANGAHAS)",
            },
          ],
        },
        MABUHAY: {
          name: "MABUHAY",
          baranggays: [
            {
              name: "ABUNDA",
            },
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BANGKAW-BANGKAW",
            },
            {
              name: "CALIRAN",
            },
            {
              name: "CATIPAN",
            },
            {
              name: "KAUSWAGAN",
            },
            {
              name: "LIGAYA",
            },
            {
              name: "LOOC-BARLAK",
            },
            {
              name: "MABUHAY",
            },
            {
              name: "MALINAO",
            },
            {
              name: "PAMANSAAN",
            },
            {
              name: "PINALIM (SAN ROQUE)",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PUNAWAN",
            },
            {
              name: "SANTO NIÑO (CALIRAN)",
            },
            {
              name: "SAWA",
            },
            {
              name: "SIOTON",
            },
            {
              name: "TAGUISIAN",
            },
            {
              name: "TANDU-COMOT (KATIPUNAN)",
            },
          ],
        },
        MALANGAS: {
          name: "MALANGAS",
          baranggays: [
            {
              name: "BACAO",
            },
            {
              name: "BASAK-BAWANG",
            },
            {
              name: "BONTONG",
            },
            {
              name: "CAMANGA",
            },
            {
              name: "CANDIIS",
            },
            {
              name: "CATITUAN",
            },
            {
              name: "DANSULAO",
            },
            {
              name: "DEL PILAR",
            },
            {
              name: "GUILAWA",
            },
            {
              name: "KIGAY",
            },
            {
              name: "LA DICHA",
            },
            {
              name: "LIPACAN",
            },
            {
              name: "LOGPOND",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALANGAS",
            },
            {
              name: "MALUNGON",
            },
            {
              name: "MULOM",
            },
            {
              name: "OVERLAND",
            },
            {
              name: "PALALIAN",
            },
            {
              name: "PAYAG",
            },
            {
              name: "POBLACION",
            },
            {
              name: "REBOCON",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SINUSAYAN",
            },
            {
              name: "TACKLING",
            },
            {
              name: "TIGABON",
            },
          ],
        },
        NAGA: {
          name: "NAGA",
          baranggays: [
            {
              name: "AGUINALDO",
            },
            {
              name: "BAGA",
            },
            {
              name: "BALUNO",
            },
            {
              name: "BANGKAW-BANGKAW",
            },
            {
              name: "CABONG",
            },
            {
              name: "CROSSING STA. CLARA",
            },
            {
              name: "GUBAWANG",
            },
            {
              name: "GUINTOLOAN",
            },
            {
              name: "KALIANTANA",
            },
            {
              name: "LA PAZ",
            },
            {
              name: "LOWER SULITAN",
            },
            {
              name: "MAMAGON",
            },
            {
              name: "MARSOLO",
            },
            {
              name: "NAGA",
            },
            {
              name: "POBLACION",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANDAYONG",
            },
            {
              name: "SANTA CLARA",
            },
            {
              name: "SULO",
            },
            {
              name: "TAMBANAN",
            },
            {
              name: "TAYTAY MANUBO",
            },
            {
              name: "TILUBOG",
            },
            {
              name: "TIPAN",
            },
            {
              name: "UPPER SULITAN",
            },
          ],
        },
        OLUTANGA: {
          name: "OLUTANGA",
          baranggays: [
            {
              name: "BATERIA",
            },
            {
              name: "CALAIS (KALINES)",
            },
            {
              name: "ESPERANZA",
            },
            {
              name: "FAMA",
            },
            {
              name: "GALAS",
            },
            {
              name: "GANDAAN",
            },
            {
              name: "KAHAYAGAN",
            },
            {
              name: "LOOC SAPI",
            },
            {
              name: "MATIM",
            },
            {
              name: "NOQUE",
            },
            {
              name: "OLUTANGA",
            },
            {
              name: "PULO LAUM",
            },
            {
              name: "PULO MABAO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTA MARIA",
            },
            {
              name: "SOLAR (POB.)",
            },
            {
              name: "TAMBANAN",
            },
            {
              name: "VILLACORTE",
            },
            {
              name: "VILLAGONZALO",
            },
          ],
        },
        PAYAO: {
          name: "PAYAO",
          baranggays: [
            {
              name: "BALIAN",
            },
            {
              name: "BALOGO",
            },
            {
              name: "BALUNGISAN",
            },
            {
              name: "BINANGONAN",
            },
            {
              name: "BULACAN",
            },
            {
              name: "BULAWAN",
            },
            {
              name: "CALAPE",
            },
            {
              name: "DALAMA",
            },
            {
              name: "FATIMA (SILAL)",
            },
            {
              name: "GUINTOLAN",
            },
            {
              name: "GUIWAN",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KIMA",
            },
            {
              name: "KULASIAN",
            },
            {
              name: "KULISAP",
            },
            {
              name: "LA FORTUNA",
            },
            {
              name: "LABATAN",
            },
            {
              name: "MAYABO (SANTA MARIA)",
            },
            {
              name: "MINUNDAS (SANTO. NIÑO)",
            },
            {
              name: "MOUNTAIN VIEW (PULUAN)",
            },
            {
              name: "NANAN",
            },
            {
              name: "PAYAO",
            },
            {
              name: "POBLACION (PAYAO)",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN ROQUE",
            },
            {
              name: "SAN VICENTE (BINANGONAN)",
            },
            {
              name: "SILAL",
            },
            {
              name: "SUMILONG",
            },
            {
              name: "TALAPTAP",
            },
            {
              name: "UPPER SUMILONG",
            },
          ],
        },
        "ROSELLER LIM": {
          name: "ROSELLER LIM",
          baranggays: [
            {
              name: "ALI ALSREE",
            },
            {
              name: "BALANSAG",
            },
            {
              name: "CALULA",
            },
            {
              name: "CASACON",
            },
            {
              name: "DON PERFECTO",
            },
            {
              name: "GANGO",
            },
            {
              name: "KATIPUNAN",
            },
            {
              name: "KULAMBUGAN",
            },
            {
              name: "MABINI",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MALUBAL",
            },
            {
              name: "NEW ANTIQUE",
            },
            {
              name: "NEW SAGAY",
            },
            {
              name: "PALMERA",
            },
            {
              name: "PRES. ROXAS",
            },
            {
              name: "REMEDIOS",
            },
            {
              name: "ROSELLER LIM",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN FERNANDINO",
            },
            {
              name: "SAN JOSE",
            },
            {
              name: "SANTO ROSARIO",
            },
            {
              name: "SIAWANG",
            },
            {
              name: "SILINGAN",
            },
            {
              name: "SURABAY",
            },
            {
              name: "TARUC",
            },
            {
              name: "TILASAN",
            },
            {
              name: "TUPILAC",
            },
          ],
        },
        SIAY: {
          name: "SIAY",
          baranggays: [
            {
              name: "BAGONG SILANG",
            },
            {
              name: "BALAGON",
            },
            {
              name: "BALINGASAN",
            },
            {
              name: "BALUCANAN",
            },
            {
              name: "BATAAN",
            },
            {
              name: "BATU",
            },
            {
              name: "BUYOGAN",
            },
            {
              name: "CAMANGA",
            },
            {
              name: "COLORAN",
            },
            {
              name: "KIMOS (KIMA)",
            },
            {
              name: "LABASAN",
            },
            {
              name: "LAGTING",
            },
            {
              name: "LAIH",
            },
            {
              name: "LOGPOND",
            },
            {
              name: "MAGSAYSAY",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MALIGAYA",
            },
            {
              name: "MANIHA",
            },
            {
              name: "MINSULAO",
            },
            {
              name: "MIRANGAN",
            },
            {
              name: "MONCHING",
            },
            {
              name: "PARUK",
            },
            {
              name: "POBLACION",
            },
            {
              name: "PRINCESA SUMAMA",
            },
            {
              name: "SALINDING",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SIAY",
            },
            {
              name: "SIBUGUEY",
            },
            {
              name: "SILOH",
            },
            {
              name: "VILLAGRACIA",
            },
          ],
        },
        TALUSAN: {
          name: "TALUSAN",
          baranggays: [
            {
              name: "AURORA",
            },
            {
              name: "BAGANIPAY",
            },
            {
              name: "BOLINGAN",
            },
            {
              name: "BUALAN",
            },
            {
              name: "CAWILAN",
            },
            {
              name: "FLORIDA",
            },
            {
              name: "KASIGPITAN",
            },
            {
              name: "LAPARAY",
            },
            {
              name: "MAHAYAHAY",
            },
            {
              name: "MOALBOAL",
            },
            {
              name: "POBLACION (TALUSAN)",
            },
            {
              name: "SAGAY",
            },
            {
              name: "SAMONTE",
            },
            {
              name: "TALUSAN",
            },
            {
              name: "TUBURAN",
            },
          ],
        },
        TITAY: {
          name: "TITAY",
          baranggays: [
            {
              name: "ACHASOL",
            },
            {
              name: "AZUSANO",
            },
            {
              name: "BANGCO",
            },
            {
              name: "CAMANGA",
            },
            {
              name: "CULASIAN",
            },
            {
              name: "DALANGIN",
            },
            {
              name: "DALANGIN MUSLIM",
            },
            {
              name: "DALISAY",
            },
            {
              name: "GOMOTOC",
            },
            {
              name: "IMELDA (UPPER CAMANGA)",
            },
            {
              name: "KIPIT",
            },
            {
              name: "KITABOG",
            },
            {
              name: "LA LIBERTAD",
            },
            {
              name: "LONGILOG",
            },
            {
              name: "MABINI",
            },
            {
              name: "MALAGANDIS",
            },
            {
              name: "MATE",
            },
            {
              name: "MOALBOAL",
            },
            {
              name: "NAMNAMA",
            },
            {
              name: "NEW CANAAN",
            },
            {
              name: "PALOMOC",
            },
            {
              name: "POBLACION (TITAY)",
            },
            {
              name: "POBLACION MUSLIM",
            },
            {
              name: "PULIDAN",
            },
            {
              name: "SAN ANTONIO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SANTA FE",
            },
            {
              name: "SUPIT",
            },
            {
              name: "TITAY",
            },
            {
              name: "TUGOP",
            },
            {
              name: "TUGOP MUSLIM",
            },
          ],
        },
        TUNGAWAN: {
          name: "TUNGAWAN",
          baranggays: [
            {
              name: "BALURAN",
            },
            {
              name: "BATUNGAN",
            },
            {
              name: "CAYAMCAM",
            },
            {
              name: "DATU TUMANGGONG",
            },
            {
              name: "GAYCON",
            },
            {
              name: "LANGON",
            },
            {
              name: "LIBERTAD (POB.)",
            },
            {
              name: "LINGUISAN",
            },
            {
              name: "LITTLE MARGOS",
            },
            {
              name: "LOBOC",
            },
            {
              name: "LOOC-LABUAN",
            },
            {
              name: "LOWER TUNGAWAN",
            },
            {
              name: "MALUNGON",
            },
            {
              name: "MASAO",
            },
            {
              name: "SAN ISIDRO",
            },
            {
              name: "SAN PEDRO",
            },
            {
              name: "SAN VICENTE",
            },
            {
              name: "SANTO NIÑO",
            },
            {
              name: "SISAY",
            },
            {
              name: "TAGLIBAS",
            },
            {
              name: "TIGBANUANG",
            },
            {
              name: "TIGBUCAY",
            },
            {
              name: "TIGPALAY",
            },
            {
              name: "TIMBABAUAN",
            },
            {
              name: "TUNGAWAN",
            },
            {
              name: "UPPER TUNGAWAN",
            },
          ],
        },
      },
    },
  },
};
