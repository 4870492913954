import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { matchSorter } from "match-sorter";
import { Button, Dialog, DialogContent, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useApolloClient } from "@apollo/client";
import numeral from "numeral";
import AddressSelection from "./AddressSelection";

const AddressSearch = (props) => {
  const { saveAddress, setShowAddressDialog, registry } = props;
  const [addressError, setAddressError] = useState();
  const [address1, setAddress1] = useState(registry.address1);
  const [address2, setAddress2] = useState(registry.address2);
  const [city, setCity] = useState(registry.city);
  const [province, setProvince] = useState(registry.province);
  const [showAddressSelection, setShowAddressSelection] = useState(false);

  const doSaveAddress = async () => {
    setShowAddressDialog(false);
    await saveAddress({ deliveryAddress: address1, address1, address2, city, province });
  };

  const setAddress = async (props) => {
    const { newCity, newProvince, newAddress2 } = props;
    setAddress2(newAddress2);
    setCity(newCity);
    setProvince(newProvince);
  };

  //   const handleSubmit = async (event) => {
  //     setAddressError(null);
  //     const addressName = event.target.innerText;
  //     setDeliveryAddress(addressName);
  //     const selected = searchOptions.filter((el) => el.address === addressName);
  //     if (selected.length > 0) {
  //       const placeId = selected[0].placeId;
  //       const { data, loading } = await client.query({
  //         query: CHECK_DELIVERY_ADDRESS,
  //         variables: {
  //           placeId,
  //           company: company,
  //         },
  //       });

  //       if (!loading) {
  //         const { checkDeliveryAddress } = data;
  //         setIsValid(checkDeliveryAddress.success);
  //         if (checkDeliveryAddress.success) {
  //           const { branch, distance, deliveryFee } = checkDeliveryAddress;
  //           setBranch(branch);
  //           setDistance(distance);
  //           setDeliveryFee(deliveryFee);
  //         } else {
  //           setAddressError(
  //             "Sorry, delivery address is beyond servicable area.  Either choose another address or select branch pickup"
  //           );
  //         }
  //       }
  //     }
  //   };

  //   const handleSearchKey = async (event) => {
  //     const name = event.target.value;
  //     if (event.target.value.length > 5) {
  //       const { data, loading } = await client.query({
  //         query: GET_PLACES_BY_NAME,
  //         variables: {
  //           name,
  //         },
  //       });

  //       if (!loading) {
  //         const { getPlacesByName } = data;
  //         if (getPlacesByName) {
  //           setSearchOptions(getPlacesByName);
  //         }
  //       }
  //     }
  //   };

  return (
    <>
      <DialogContent dividers>
        <h2>Change Delivery Address</h2>
        <TextField
          label="Address Details"
          placeholder="House No / Street / Unit No / Builing"
          style={{ width: "100%" }}
          size="small"
          variant="outlined"
          value={address1}
          onChange={(evt) => setAddress1(evt.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Delivery Address"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={`${address2}, ${city}, ${province}`}
          InputProps={{
            readOnly: true,
          }}
          onClick={() => {
            setShowAddressSelection(true);
          }}
          //onChange={(event) => handleSearchKey(event)}
        />

        {addressError && <p style={{ color: "red" }}>{addressError}</p>}
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={() => doSaveAddress()}>
          Save
        </Button>
      </DialogContent>

      <Dialog open={showAddressSelection} onClose={() => setShowAddressSelection(false)} fullWidth maxWidth="md">
        <AddressSelection
          setAddress={setAddress}
          setShowAddressDialog={setShowAddressSelection}
          barangay={address2}
          city={city}
          province={province}
        />
      </Dialog>
    </>
  );
};

export default AddressSearch;
