import React from "react";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";

const PublicLayout = (props) => {
  const { Component } = props;
  return (
    <Container component="main" maxWidth="lg">
      <Box>
        <Component />
      </Box>
    </Container>
  );
};

export default PublicLayout;
