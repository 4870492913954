import React from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import format from "date-fns/format";
import { camelToPhrase } from "../../core/utils/stringUtils";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tableIndex: {
    fontWeight: "bold",
  },
});

const DataRec = (props) => {
  const { label, value } = props;
  let dispValue = value;
  let dispLabel = label;

  console.log(value);

  if (label === "isPickup" || label === "isPublic" || label === "discountClaimed") {
    dispValue = value ? "Yes" : "No";
  } else if (label === "subType") {
    dispLabel = "Event Type";
  } else if (label.endsWith("Date")) {
    dispValue = format(new Date(value), "MMM dd, yyyy");
  }

  return (
    <>
      <strong>{camelToPhrase(dispLabel)}:</strong> {dispValue}
    </>
  );
};

const History = (props) => {
  const { history } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell align="center" className={classes.tableIndex}>
                DATE
              </TableCell>
              <TableCell align="center" className={classes.tableIndex}>
                USER
              </TableCell>
              <TableCell align="center" className={classes.tableIndex}>
                DESCRIPTION
              </TableCell>
              <TableCell align="center" className={classes.tableIndex}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history &&
              history.map((h, i) => (
                <TableRow key={i} className={classes.root}>
                  <TableCell>{format(new Date(h.actionDate), "MMM dd yyyy")}</TableCell>
                  <TableCell>{h.user}</TableCell>
                  <TableCell>{h.description}</TableCell>
                  <TableCell>
                    {h.data && (
                      <>
                        {Object.keys(JSON.parse(h.data)).map((k, i) => (
                          <p key={k}>
                            <DataRec key={`_${i}`} label={k} value={JSON.parse(h.data)[k]} />
                          </p>
                        ))}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default History;
