import { Box,  Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { DisplaySkuBarcode, getEventTypeObject } from "../../core/utils/stringUtils";
import format from "date-fns/format";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export const BarcodeDialog = (props) => {
  const { showBarcode, setShowBarcode, subType, wishlistCode, eventDate } = props;
  const classes = useStyles();

  return (
    <Dialog open={showBarcode} onClose={() => setShowBarcode(false)} className={classes.root} fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Event Barcode</Box>
          <Box>
            <IconButton onClick={() => setShowBarcode(false)} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <br />
        <div style={{ fontSize: 18 }}>Event Code: {wishlistCode}</div>
        <DisplaySkuBarcode
          value={`266${getEventTypeObject(subType).code}${wishlistCode}`}
          width={3}
          fontSize={20}
          height={100}
        />
        <br />
        <p>Guest</p>
        <br />
        <div style={{ width: "100%", textAlign: "left" }}>
          Valid Until {format(new Date(eventDate), "MMMM dd, yyyy")}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const BarcodeDialogOnline = (props) => {
  const { showBarcodeOnline, setShowBarcodeOnline, guestBarcode, wishlistCode, eventDate } = props;
  const classes = useStyles();

  return (
    <Dialog open={showBarcodeOnline} onClose={() => setShowBarcodeOnline(false)} className={classes.root} fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Guest Barcode</Box>
          <Box>
            <IconButton onClick={() => setShowBarcodeOnline(false)} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <br />
        <div style={{ fontSize: 18 }}>Event Code: {wishlistCode}</div>
        <DisplaySkuBarcode
          value={guestBarcode}
          width={3}
          fontSize={20}
          height={100}
        />
        <br />
        <p>Guest</p>
        <br />
        <div style={{ width: "100%", textAlign: "left" }}>
          Valid Until {format(new Date(eventDate), "MMMM dd, yyyy")}
        </div>
      </DialogContent>
    </Dialog>
  );
};
