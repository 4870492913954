import React, { createContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [snackBar, setSnackBar] = useState({ open: false, message: "", severity: "success" });
  const [showProgress, setShowProgress] = useState(false);

  const handleClose = () => {
    setSnackBar({ open: false, message: "", severity: snackBar.severity });
  };

  return (
    <AppContext.Provider
      value={{
        showSnackBar: ({ open, message, severity }) => {
          setSnackBar({ open, message, severity });
        },
        showProgressIndicator: (value) => {
          setShowProgress(value);
        },
      }}
    >
      {children}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={snackBar.severity} sx={{ width: "100%" }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
