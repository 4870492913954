import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import privateRoutes from "../routes/privateRoutes";
import publicRoutes from "../routes/publicRoutes";
import PrivateLayout from "./PrivateLayout";
import PublicLayout from "./PublicLayout";

const MasterLayout = () => {
  return (
    <BrowserRouter>
      <Routes>
        {privateRoutes.map(({ Component, path }) => {
          return <Route path={path} key={path} element={<PrivateLayout Component={Component} />} />;
        })}
        {publicRoutes.map(({ Component, path }) => {
          return <Route path={path} key={path} element={<PublicLayout Component={Component} />} />;
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default MasterLayout;
