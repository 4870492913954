const APP_NAME = "Gift Registry Console";
const APP_ID = "63b3a85d86b7fbeba6ee1cff";
const COMPANY_ID = "63b3a85c86b7fbeba6ee1cfb";
const URL = window.location.host.split(":")[0];

const API_TOKEN = process.env.REACT_APP_INTERNAL_API_TOKEN;
const AUTH_URL = "https://auth-sat.smomni.com";
const GRAPH_URL = process.env.REACT_APP_GRAPHQL_URL;
const STATIC_IMAGES_URL = process.env.REACT_APP_IMAGES_URL;
const STATIC_IMAGES_S3_BUCKET = process.env.REACT_APP_IMAGES_S3_BUCKET;
const STATIC_IMAGES_S3_PATH = process.env.REACT_APP_IMAGES_S3_BUCKET_PATH;
const ENV = process.env.REACT_APP_ENV;
const EDITOR_KEY = "yk63exqmeebbxsh9liab633n1spoyjhxhts5tz26e5c0rxl6";
const ALLOWED_ROLES = [
  "admin",
  "super_admin",
  "store operations",
  "treasury",
  "accounting",
  "operations head",
  "gift registry staff",
  "branch accounting main",
  "branch accounting",
  "customer_service",
];

// console.log("+++++++++++++++++++++++++++++++");
// console.log("URL:", URL);
// console.log("GRAPH :", GRAPH_URL);
// console.log("API TOKEN:", API_TOKEN);
// console.log("STATIC IMAGE URL:", STATIC_IMAGES_URL);
// console.log("STATIC IMAGE BUCKET :", STATIC_IMAGES_S3_BUCKET);
// console.log("STATIC IMAGE BUCKET PATH :", STATIC_IMAGES_S3_PATH);
// console.log("ENV :", ENV);
// console.log("+++++++++++++++++++++++++++++++");

export const EVENT_SUB_TYPE = [
  { type: "babyshower", label: "Baby Shower" },
  { type: "baptism", label: "Baptism" },
  { type: "birthday_adult", label: "Birthday-Adult" },
  { type: "birthday_men", label: "Birthday-Men" },
  { type: "birthday_women", label: "Birthday-Women" },
  { type: "birthday_kid", label: "Birthday-Kids" },
  { type: "birthday_debut", label: "Birthday-Debut" },
  { type: "wedding", label: "Wedding" },
  { type: "anniversary", label: "Anniversary" },
  { type: "housewarming", label: "House Warming" },
  { type: "others", label: "Others" },
  { type: "Baby Shower", label: "Baby Shower" },
  { type: "Birthday", label: "Birthday" },
  { type: "Baptismal", label: "Baptismal" },
  { type: "Personalized", label: "Personalized" },
];

export const REGISTRY_STATUS = [
  { label: "New", value: "NEW" },
  { label: "Partly Picked", value: "PART_PICKED" },
  { label: "Pick Complete", value: "PICK_COMPLETE" },
  { label: "Packed", value: "PACKED" },
  { label: "For Customer Pickup", value: "PICKUP" },
  { label: "Pickup Complete", value: "PICKUP_COMPLETE" },
  { label: "Shipped", value: "SHIPPED" },
  { label: "Delivered", value: "DELIVERED" },
  { label: "Closed", value: "CLOSED" },
  { label: "Cancelled", value: "CANCELLED" },
  //TK STATUS
  { label: "Pending", value: "TO_PAY" },
  { label: "Confirmed", value: "CONFIRMED" },
  { label: "To Receive", value: "TO_RECEIVE" },
  { label: "Completed", value: "COMPLETED" },
  { label: "For Refund", value: "FOR_REFUND" },
  { label: "Refunded", value: "REFUNDED" },
  { label: "Voided", value: "VOIDED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Return", value: "RETURN" },
];

export const PAYMENT_TYPE = {
  1: "Credit Card",
  3: "GCASH",
  18: "DP-ONLINE",
  38: "GRAB",
  19: "DP-BANK",
  20: "DP-NON",
  101: "PAY-IN-STORE",
};

export const getStatusLabel = (value) => {
  const status = REGISTRY_STATUS.filter((el) => el.value === value);
  if (status && status.length > 0) {
    return status[0].label;
  }
  return value;
};

export { APP_NAME, APP_ID, COMPANY_ID, URL, GRAPH_URL, ENV, API_TOKEN, STATIC_IMAGES_URL, EDITOR_KEY, ALLOWED_ROLES, AUTH_URL };
