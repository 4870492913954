import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Switch } from "@mui/material";
import Compressor from "compressorjs";
import { toBase64 } from "../../core/utils/common";
// import { UPLOAD_SKU_IMAGE, UPLOAD_WISHLIST_IMAGE } from '../../sku/api/mutations';
import { useMutation } from "@apollo/client";

const useStyles = makeStyles({
  image: {
    width: "100%",
    height: "auto",
  },
  contentRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  column: {
    flex: "50%",
  },
  yesLabel: {
    fontSize: 11,
    color: "green",
    fontWeight: "bold",
  },
  noLabel: {
    fontSize: 11,
    color: "#0071e3",
    fontWeight: "bold",
  },
});

const UploadImage = (props) => {
  const { uploadDialogOpen, setUploadDialogOpen, uploadItem, setWishlistItems, wishlistItems } = props;
  const [isForWishlist, setIsForWishlist] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  //   const [doUploadSkuImage] = useMutation(UPLOAD_SKU_IMAGE);
  //   const [doUploadWishlistItemImage] = useMutation(UPLOAD_WISHLIST_IMAGE);

  const resetWishlistItems = ({ primaryImage, imageUrl }) => {
    // console.log({ primaryImage, imageUrl });
    const wishlistItemsClone = [];
    wishlistItems.forEach((i) => {
      let item = { ...i };
      if (item.skuId === uploadItem.skuId) {
        if (imageUrl) item.imageUrl = imageUrl;
        if (primaryImage) item.primaryImage = primaryImage;
      }
      wishlistItemsClone.push(item);
    });
    setWishlistItems(wishlistItemsClone);
  };

  const handleUpload = async (event) => {
    let file = event.target.files[0];
    if (file.size > 1000000) {
      const compressed = await new Promise((resolve, reject) => {
        new Compressor(file, {
          success: resolve,
          error: reject,
        });
      });
      file = compressed;
    }

    const base64 = await toBase64(file);
    setIsLoading(true);

    // if (!isForWishlist || uploadItem.primaryImage) {
    //   const { loading, data } = await doUploadSkuImage({
    //     variables: {
    //       skuId: uploadItem.skuId,
    //       base64,
    //     },
    //   });

    //   if (!loading) {
    //     const { imageUrl } = data.uploadImage;
    //     resetWishlistItems({ primaryImage: imageUrl });
    //     setUploadDialogOpen(false);
    //     setIsLoading(false);
    //   }
    // } else {
    //   const { loading, data } = await doUploadWishlistItemImage({
    //     variables: {
    //       wishlistItemId: uploadItem._id,
    //       base64,
    //     },
    //   });

    //   if (!loading) {
    //     const { url } = data.uploadCustomerWishlistItemImage;
    //     resetWishlistItems({ imageUrl: url });
    //     setUploadDialogOpen(false);
    //     setIsLoading(false);
    //   }
    // }
  };

  return (
    <div>
      {uploadItem ? (
        <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>Upload Product Image</DialogTitle>
          <DialogContent dividers>
            <div className={classes.contentRoot}>
              <div className={classes.column}>
                {" "}
                <img
                  src={uploadItem.primaryImage || uploadItem.imageUrl || "images/no-image.jpg"}
                  className={classes.image}
                  alt="primary"
                />
              </div>
              <div className={classes.column}>
                {!uploadItem.primaryImage && (
                  <>
                    <p>
                      {" "}
                      Apply this image to this event only <br />
                      <Switch
                        name="isForWishlist"
                        defaultValue={isForWishlist}
                        onChange={() => setIsForWishlist(!isForWishlist)}
                        label="xxxx"
                      />{" "}
                      <br />
                      {isForWishlist ? (
                        <span className={classes.yesLabel}>Yes, this is for this event only</span>
                      ) : (
                        <span className={classes.noLabel}>No, set this as default image for all Events</span>
                      )}
                    </p>
                    <p>{isForWishlist && ""}</p>
                  </>
                )}
              </div>
            </div>
            <div className={classes.contentRoot}>
              <input
                style={{ display: "none" }}
                id="primaryImage"
                multiple
                type="file"
                onChange={(event) => handleUpload(event)}
                accept="image/*;capture=camera"
              />
              <label htmlFor="primaryImage">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button size="small" component="div" variant="contained">
                    Upload Image
                  </Button>
                )}
              </label>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default UploadImage;
