import { gql } from "@apollo/client";

export const SEARCH_GIFT_WRAP = gql`
  query searchGiftWrap(
    $searchInput: GiftWrapSearchInput
    $cursor: Int
    $limit: Int
    $orderBy: String
    $order: String
  ) {
    searchGiftWrap(
      searchInput: $searchInput
      cursor: $cursor
      limit: $limit
      orderBy: $orderBy
      order: $order
    )
  }
`;
export const GET_GIFT_WRAP_BY_ID = gql`
  query getGiftWrapById($_id: String) {
    getGiftWrapById(_id: $_id)
  }
`;
