import styled from "@emotion/styled";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import AppVersionModal from "./components/appVersionModal";
import { useState } from "react";
import { APP_REMOTE_CONFIG_QUERY } from "./api/queries";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_APP_REMOTE_CONFIG_MUTATION } from "./api/mutation";

const DetailsContainter = styled.div`
  display: flex;
  padding: 24px 24px;
  align-items: center;
`;

const DetailRow = styled.div`
  padding: 5px 0px;
`;

const DetailHead = styled.span`
  font-weight: 600;
`;

const Logo = styled.img`
  display: block;
  width: 100%;
`;

const AppVersion = () => {
  const [open, setOpen] = useState(false);
  const [saveVersion] = useMutation(UPDATE_APP_REMOTE_CONFIG_MUTATION);
  const [initValue, setInitValue] = useState({});

  const onSave = async ({ platform, version, versionCode, force, isMaintenance }) => {
    await saveVersion({
      variables: {
        platform: platform,
        version: version,
        versionCode: parseInt(versionCode),
        force: force,
        isMaintenance: isMaintenance,
      },
      refetchQueries: [APP_REMOTE_CONFIG_QUERY],
    });
    if (platform == "ANDROID") {
      await refetchAndroid();
    } else {
      await refetchIos();
    }
    setOpen(true);
    setOpen(false);
  };

  const {
    data: androidData,
    loading: androidLoading,
    refetch: refetchAndroid,
  } = useQuery(APP_REMOTE_CONFIG_QUERY, {
    variables: {
      platform: "ANDROID",
    },
    fetchPolicy: "network-only",
  });

  const {
    data: iosData,
    loading: iosLoading,
    refetch: refetchIos,
  } = useQuery(APP_REMOTE_CONFIG_QUERY, {
    variables: {
      platform: "IOS",
    },
    fetchPolicy: "network-only",
  });

  const onOpen = ({ platform, version, versionCode, force, isMaintenance }) => {
    setInitValue({
      platform: platform,
      version: version,
      versionCode: versionCode,
      force: force,
      isMaintenance: isMaintenance,
    });
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <h2>App Version</h2>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} lg={6}>
          <h3 style={{ padding: "0 24px" }}>Android</h3>
          {androidLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "24px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <DetailsContainter>
              <div style={{ width: "60%" }}>
                <DetailRow>
                  <DetailHead>Version:</DetailHead> {androidData?.appRemoteConfig?.version || "Not Set"}
                </DetailRow>
                <DetailRow>
                  <DetailHead>Version Code:</DetailHead> {androidData?.appRemoteConfig?.versionCode || "Not Set"}
                </DetailRow>
                <DetailRow>
                  <DetailHead>Forced Update:</DetailHead> {androidData?.appRemoteConfig?.force ? "ENABLED" : "DISABLED"}
                </DetailRow>
                <DetailRow>
                  <DetailHead>Is Maintenance?:</DetailHead> {androidData?.appRemoteConfig?.isMaintenance ? "YES" : "NO"}
                </DetailRow>
                <DetailRow>
                  <Button
                    // autoFocus
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      onOpen({
                        platform: androidData?.appRemoteConfig?.platform || "ANDROID",
                        version: androidData?.appRemoteConfig?.version || "1.0.0",
                        versionCode: androidData?.appRemoteConfig?.versionCode || "1",
                        force: androidData?.appRemoteConfig?.force || true,
                        isMaintenance: androidData?.appRemoteConfig?.isMaintenance
                          ? iosData?.appRemoteConfig?.isMaintenance
                          : false,
                      })
                    }
                  >
                    Update Android Version
                  </Button>
                </DetailRow>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  maxWidth: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "48px",
                }}
              >
                <Logo src="/static/android.png" />
              </div>
            </DetailsContainter>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <h3 style={{ padding: "0 24px" }}>IOS</h3>
          {iosLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "24px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <DetailsContainter>
              <div style={{ width: "60%" }}>
                <DetailRow>
                  <DetailHead>Version:</DetailHead> {iosData?.appRemoteConfig?.version || "Not set"}
                </DetailRow>
                <DetailRow>
                  <DetailHead>Version Code:</DetailHead> {iosData?.appRemoteConfig?.versionCode || "Not set"}
                </DetailRow>
                <DetailRow>
                  <DetailHead>Forced Update:</DetailHead> {iosData?.appRemoteConfig?.force ? "ENABLED" : "DISABLED"}
                </DetailRow>
                <DetailRow>
                  <DetailHead>Is Maintenance?:</DetailHead> {iosData?.appRemoteConfig?.isMaintenance ? "YES" : "NO"}
                </DetailRow>
                <DetailRow>
                  <Button
                    // autoFocus
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      onOpen({
                        platform: iosData?.appRemoteConfig?.platform || "IOS",
                        version: iosData?.appRemoteConfig?.version || "1.0.0",
                        versionCode: iosData?.appRemoteConfig?.versionCode || "1",
                        force: iosData?.appRemoteConfig?.force ? iosData?.appRemoteConfig?.force : true,
                        isMaintenance: iosData?.appRemoteConfig?.isMaintenance
                          ? iosData?.appRemoteConfig?.isMaintenance
                          : false,
                      })
                    }
                  >
                    Update IOS Version
                  </Button>
                </DetailRow>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  maxWidth: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "48px",
                }}
              >
                <Logo src="/static/ios.png" />
              </div>
            </DetailsContainter>
          )}
        </Grid>
      </Grid>
      <AppVersionModal open={open} onClose={onClose} initValue={initValue} onSave={onSave} />
    </Box>
  );
};

export default AppVersion;
