import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgress, Card, Chip, Grid, IconButton, Dialog } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import format from "date-fns/format";
import { deepOrange, green } from "@mui/material/colors";
import { DisplaySkuBarcode, getEventTypeObject } from "../../core/utils/stringUtils";
import { BarcodeDialog, BarcodeDialogOnline } from "./BarcodeDialog";
import numeral from "numeral";
import { Edit } from "@mui/icons-material";
import BranchSelection from "./BranchSelection";
// import { useMutation } from "@apollo/client";
// import { UPDATE_WISHLIST_BRANCH } from "../api/mutations";
import { getStatusLabel } from "../../../constants";
import AddressSearch from "./AddressSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  label: {
    fontWeight: "bold",
  },
  card: {
    padding: 15,
    marginBottom: 15,
  },
  confirmed: {
    color: green[800],
  },
  pending: {
    color: deepOrange[800],
  },
  btnResend: {
    fontSize: 12,
    padding: 0,
    margin: 0,
  },
  images: {
    width: "50%",
    height: "auto",
  },
  centered: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
}));

const Misc = (props) => {
  const { registry, saveAddress, disabled, saveBranch } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showBarcode, setShowBarcode] = useState(false);
  const [showBarcodeOnline, setShowBarcodeOnline] = useState(false);
  const [showBranchSelect, setShowBranchSelect] = useState(false);
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  // const [doUpdateWishlistBranch] = useMutation(UPDATE_WISHLIST_BRANCH);

  const openBranchSelection = () => {
    setShowBranchSelect(true);
  };

  const handleBranchChange = async (evt) => {
    const { value } = evt.target;
    await saveBranch({
      branch: value.id,
    });

    setShowBranchSelect(false);
  };

  return (
    <div className={classes.root}>
      {isLoading && <CircularProgress className={classes.centered} />}
      <Card className={classes.card} elevation={1}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            { registry.guestBarcode ?  <div>Guest Barcode</div>  : '' }
            {
              registry.guestBarcode ? 
                <span style={{ cursor: "pointer", marginRight: 100 }} onClick={() => setShowBarcodeOnline(true)}>
                  <DisplaySkuBarcode
                    label={"Guest Barcode"}
                    value={`${registry.guestBarcode}`}
                  />
                </span> : ''
            }
          </Grid>
          <Grid item sm={6} style={{ textAlign: "right" }}>
            <div>Event Barcode</div>
            <span style={{ cursor: "pointer" }} onClick={() => setShowBarcode(true)}>
              <DisplaySkuBarcode
                label={"Event Barcode"}
                value={`266${getEventTypeObject(registry.subType).code ?? "04"}${registry.wishlistCode}`}
              />
            </span>
          </Grid>
          <Grid item sm={4} className={classes.label}>
            Event Code
          </Grid>
          <Grid item sm={8}>
            {registry.wishlistCode}
          </Grid>
          <Grid item sm={4} className={classes.label}>
            Created
          </Grid>
          <Grid item sm={8}>
            {format(new Date(registry.createdDate), "MMM dd, yyyy")}
          </Grid>
          {registry.isPickup && (
            <>
              <Grid item sm={4} className={classes.label}>
                Branch
              </Grid>
              <Grid item sm={8}>
                {showBranchSelect ? (
                  <BranchSelection
                    handleChange={handleBranchChange}
                    defaultValue={registry.branch}
                    setShowBranchSelect={setShowBranchSelect}
                  />
                ) : (
                  <>
                    {registry.assignedBranch && registry.assignedBranch.name.replace("", "")} &nbsp;
                    {registry.isPickup && !disabled && (
                      <IconButton size="small" onClick={openBranchSelection}>
                        <Edit fontSize="small" style={{ fontSize: 15 }} color="secondary" />
                      </IconButton>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
          <Grid item sm={4} className={classes.label}>
            Status
          </Grid>
          <Grid item sm={8}>
            {getStatusLabel(registry.status)}
          </Grid>
          <Grid item sm={4} className={classes.label}>
            Email
          </Grid>
          <Grid item sm={8}>
            {registry.eventEmail || ""}
          </Grid>
          <Grid item sm={4} className={classes.label}>
            Contact
          </Grid>
          <Grid item sm={8}>
            {registry.eventContact || ""}
          </Grid>
          {/* <Grid item sm={4} className={classes.label}>
            Branch
          </Grid>
          <Grid item sm={8}>
            {showBranchSelect ? (
              <BranchSelection
                handleChange={handleBranchChange}
                defaultValue={registry.branch}
                setShowBranchSelect={setShowBranchSelect}
              />
            ) : (
              <>
                {registry.assignedBranch.name.replace("The SM Store", "")} &nbsp;
                {registry.isPickup && !disabled && (
                  <IconButton size="small" onClick={openBranchSelection}>
                    <Edit fontSize="small" style={{ fontSize: 15 }} color="secondary" />
                  </IconButton>
                )}
              </>
            )}
          </Grid> */}
          <Grid item sm={4} className={classes.label}>
            {registry.isPickup ? "Pickup Date" : "Delivery Date"}
          </Grid>
          <Grid item sm={8}>
            {format(new Date(registry.deliveryDate), "MMM dd, yyyy")}
          </Grid>
          {!registry.isPickup && (
            <>
              <Grid item sm={4} className={classes.label}>
                Delivery Address
              </Grid>
              <Grid item sm={7}>
                {registry.address1}, {registry.address2}, {registry.city}, {registry.province}
              </Grid>
              <Grid item sm={1}>
                {!disabled && (
                  <IconButton size="small" onClick={() => setShowAddressDialog(true)}>
                    <Edit fontSize="small" style={{ fontSize: 15 }} color="secondary" />
                  </IconButton>
                )}
              </Grid>
              {/* <Grid item sm={4} className={classes.label}>
                Delivery Address
              </Grid>
              <Grid item sm={8}>
                {registry.city}
              </Grid> */}
            </>
          )}
          <Grid item sm={4} className={classes.label}>
            Event Image
          </Grid>
          <Grid item sm={8}>
            {registry.images && registry.images.length > 0 && (
              <img src={registry.images[0].imageUrl} className={classes.images} />
            )}
          </Grid>
        </Grid>
      </Card>
      {registry.customer && (
        <Card className={classes.card} elevation={1}>
          <h3>Customer</h3>
          <Grid container spacing={1}>
            <Grid item sm={4} className={classes.label}>
              First Name
            </Grid>
            <Grid item sm={8}>
              {registry.customer.firstName}
            </Grid>
            <Grid item sm={4} className={classes.label}>
              Last Name
            </Grid>
            <Grid item sm={8}>
              {registry.customer.lastName}
            </Grid>
            <Grid item sm={4} className={classes.label}>
              Email
            </Grid>
            <Grid item sm={8}>
              {registry.customer.email || ""}
            </Grid>
            {/* <Grid item sm={4} className={classes.label}>
              Mobile
            </Grid>
            <Grid item sm={8}>
              {registry.customer.mobile || ""}
            </Grid> */}
          </Grid>
        </Card>
      )}
      <Card className={classes.card} elevation={1}>
        <h3>Guests</h3>
        <Grid container spacing={1} className={classes.confirmed}>
          <Grid item sm={12}>
            {registry.peers.map((peer) => (
              <Chip
                key={peer._id}
                icon={peer.hasRsvd ? <DoneIcon /> : <NotInterestedIcon />}
                label={`${peer.firstName || ""} ${peer.lastName || ""}`}
                color={peer.hasAccepted ? "primary" : "secondary"}
                style={{ margin: 2 }}
              />
            ))}
          </Grid>
        </Grid>
      </Card>
      <BarcodeDialog
        showBarcode={showBarcode}
        setShowBarcode={setShowBarcode}
        subType={registry.subType}
        wishlistCode={registry.wishlistCode}
        eventDate={registry.eventDate}
      />

      <BarcodeDialogOnline
        showBarcodeOnline={showBarcodeOnline}
        setShowBarcodeOnline={setShowBarcodeOnline}
        guestBarcode={registry.guestBarcode}
        wishlistCode={registry.wishlistCode}
        eventDate={registry.eventDate}
      />
      <Dialog
        open={showAddressDialog}
        onClose={() => setShowAddressDialog(false)}
        className={classes.root}
        fullWidth
        maxWidth="md"
      >
        <AddressSearch saveAddress={saveAddress} setShowAddressDialog={setShowAddressDialog} registry={registry} />
      </Dialog>
      <Dialog
        open={showAddressDialog}
        onClose={() => setShowAddressDialog(false)}
        className={classes.root}
        fullWidth
        maxWidth="md"
      >
        <AddressSearch saveAddress={saveAddress} setShowAddressDialog={setShowAddressDialog} registry={registry} />
      </Dialog>
    </div>
  );
};

export default Misc;
