import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
// import { CREATE_ORDER } from "../../order/api/mutations"
import { useMutation } from "@apollo/client";
import { CREATE_ORDER } from "../api/mutations";

const app = localStorage.getItem("OMNI_SELECTED_APP");
const OrderDialog = (props) => {
  let {
    createOrderDialog,
    setCreateOrderDialog,
    wishlist,
    displayMessage,
    fetchAndResetRegData,
    offlineItems,
    wishlistItems,
    doRecomputeDialog,
    setOrderItems,
    subType,
    initialDiscount
  } = props;
  const [wishlistItemsClone, setWishlistItemsClone] = useState([]);
  const initOrder = {
    wishlist,
    subType,
    app,
    isPickup: true,
    isCelebrant: false,
    // email: null,
    firstName: null,
    lastName: null,
    //mobile: null,
    discount: 0,
    total: 0,
    offlineItems,
  };
  const [order, setOrder] = useState(initOrder);

  const [doCreateOrder] = useMutation(CREATE_ORDER);
  const [totalDiscount, setTotalDiscount] = useState(initialDiscount);

  const customerInfoUpdated = (evt) => {
    const { name, value } = evt.target;
    const updatedOrder = { ...order };
    updatedOrder[name] = value;
    setOrder(updatedOrder);
  };

  const quantityUpdated = (evt) => {
    const { name, value } = evt.target;
    const updatedOrder = { ...order };
    const orderIndex = order.offlineItems.findIndex((el) => el._id === name);
    if (orderIndex !== -1) {
      const { _id, primaryImage, skuCode, commercialName, maxQty, currentPrice, referencePrice } =
        updatedOrder.offlineItems[orderIndex];

      let discount = referencePrice === 0 ? parseFloat(currentPrice) * 0.10 : referencePrice > parseFloat(currentPrice) ? 0 : parseFloat(currentPrice) * 0.10
      updatedOrder.offlineItems[orderIndex] = {
        _id,
        primaryImage,
        skuCode,
        commercialName,
        maxQty,
        quantity: value,
        currentPrice,
        amount: value * (currentPrice - discount),
        referencePrice,
        discount: discount * value
      };
      doRecompute(updatedOrder);
    }
  };

  const priceUpdated = (evt) => {
    const { name, value } = evt.target;
    const updatedOrder = { ...order };
    const orderIndex = order.offlineItems.findIndex((el) => el._id === name);

    if (orderIndex !== -1) {
      const { _id, primaryImage, skuCode, commercialName, maxQty, quantity, referencePrice } = updatedOrder.offlineItems[orderIndex];
      let discount = referencePrice === 0 ? parseFloat(value) * 0.10 : referencePrice > parseFloat(value) ? 0 : parseFloat(value) * 0.10
      updatedOrder.offlineItems[orderIndex] = {
        _id,
        primaryImage,
        skuCode,
        commercialName,
        maxQty,
        quantity,
        currentPrice: parseFloat(value),
        amount: quantity * (value - discount),
        referencePrice, 
        discount: discount * quantity
      };
      doRecompute(updatedOrder);
    }
  };

  const doSelectItem = async (evt) => {
    const { value } = evt.target;
    const wishlistIndex = wishlistItemsClone.findIndex((el) => el._id === value);
    if (wishlistIndex !== -1) {
      const updatedOrder = { ...order };
      const wishlistItem = wishlistItemsClone[wishlistIndex];
      wishlistItemsClone.splice(wishlistIndex, 1);
      setWishlistItemsClone(wishlistItemsClone);

      const { skuCode, commercialName, reservations, quantity, primaryImage, _id, currentPrice, referencePrice } = wishlistItem;
      let maxQty = quantity;
      reservations.map((reservation) => {
        maxQty -= reservation.quantity;
      });
      let discount = referencePrice === 0 ? parseFloat(currentPrice) * 0.10 : referencePrice > parseFloat(currentPrice) ? 0 : parseFloat(currentPrice) * 0.10
      const indexPos = updatedOrder.offlineItems.length - 1;
      if (wishlistItemsClone.length === 0) {
        updatedOrder.offlineItems[updatedOrder.offlineItems.length - 1] = {
          _id,
          primaryImage,
          skuCode,
          commercialName,
          maxQty,
          quantity: 1,
          currentPrice,
          amount: 1 * (currentPrice - discount),
          referencePrice,
          discount: discount * quantity
        };
      } else {
        updatedOrder.offlineItems.splice(indexPos, 0, {
          _id,
          primaryImage,
          skuCode,
          commercialName,
          maxQty,
          quantity: 1,
          currentPrice,
          amount: 1 * (currentPrice - discount),
          referencePrice,
          discount: discount * quantity
        });
      }
      doRecompute(updatedOrder);
    }
  };

  const submitOrder = async () => {
    setCreateOrderDialog(false);
    const { data, loading } = await doCreateOrder({
      variables: {
        order,
      },
    });
    if (!loading) {
      setOrder(initOrder);
      setOrderItems([]);
      let { createOrder } = data;
      createOrder = JSON.parse(createOrder);
      displayMessage(`Offline Order created with reference # ${createOrder.referenceNo}`);
      await fetchAndResetRegData();
    }
  };

  const doRecompute = (updatedOrder) => {
    let total = 0;
    let totalDiscount = 0;
    let _order = updatedOrder || order;
    _order.offlineItems.map((u) => {
      if (u.skuCode) {
        total += u.amount;
        totalDiscount += u?.discount
      }
    });
    total -= _order.discount;
    _order.total = parseFloat(total);
    setOrder(_order);
    setTotalDiscount(totalDiscount);
  };

  const discountChanged = (evt) => {
    const { value } = evt.target;
    const updatedOrder = { ...order };
    updatedOrder.discount = parseFloat(value);
    doRecompute(updatedOrder);
  };

  const deliveryOptionChanged = (event) => {
    const { checked } = event.target;
    const updatedOrder = { ...order };
    updatedOrder.isPickup = !checked;
    setOrder(updatedOrder);
  };

  const celebrantOptionChanged = (event) => {
    const { checked } = event.target;
    const updatedOrder = { ...order };
    updatedOrder.isCelebrant = checked;
    setOrder(updatedOrder);
  };

  useEffect(() => {
    doRecompute();
  }, [doRecomputeDialog]);

  return (
    <Dialog open={createOrderDialog} onClose={() => setCreateOrderDialog(false)} fullWidth maxWidth="lg">
      <DialogTitle>Create In Store Order</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              label="Guest First Name"
              name="firstName"
              required
              defaultValue={order.firstName}
              fullWidth
              onChange={customerInfoUpdated}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              label="Guest Last Name"
              name="lastName"
              required
              defaultValue={order.lastName}
              fullWidth
              onChange={customerInfoUpdated}
            />
          </Grid>
          <Grid item sm={6}>
            <Checkbox onChange={deliveryOptionChanged} checked={!order.isPickup} /> For Delivery
          </Grid>
          <Grid item sm={6}>
            <Checkbox onChange={celebrantOptionChanged} checked={order.isCelebrant} /> Is Buyer Celebrant
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20, marginBottom: 10 }} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">SKU</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Wants</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Reg/Discounted Price</TableCell>
                <TableCell align="center">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.offlineItems.map((sku, i) => {
                return (
                  <TableRow key={`diag-${i}`}>
                    <TableCell width={100}>
                      <img src={sku.primaryImage} style={{ width: "100%", height: "auto" }} />
                    </TableCell>
                    <TableCell>
                      {sku.skuCode ? (
                        sku.skuCode
                      ) : (
                        <Select
                          variant="outlined"
                          label="Sku Code"
                          name="skuCode"
                          onChange={(evt) => doSelectItem(evt)}
                          value={sku.skuCode || ""}
                        >
                          <MenuItem key={-1} value="">
                            -
                          </MenuItem>
                          {wishlistItemsClone.map((wishlistItem, i) => (
                            <MenuItem key={`mItem-${i}`} value={wishlistItem._id}>
                              {wishlistItem.skuCode}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </TableCell>
                    <TableCell>{sku.commercialName || sku.productName}</TableCell>
                    <TableCell align="center">{sku.maxQty}</TableCell>
                    <TableCell width={50}>
                      {sku.skuCode && (
                        <Box width={50}>
                          {sku.maxQty > 0 ? (
                            <Select
                              variant="outlined"
                              label="Sku Code"
                              name={sku._id}
                              onChange={(evt) => quantityUpdated(evt)}
                              value={sku.quantity || 1}
                            >
                              {Array.from({ length: sku.maxQty }, (_, i) => {
                                i++;
                                return (
                                  <MenuItem key={i} value={i}>
                                    {i}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          ) : (
                            0
                          )}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      {sku.skuCode && (
                        <Box width={150}>
                          <TextField
                            disabled
                            type="number"
                            variant="outlined"
                            name={sku._id}
                            required
                            defaultValue={sku.currentPrice - sku.discount}
                            fullWidth
                            onChange={(evt) => priceUpdated(evt)}
                          />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      {sku.skuCode && (
                        <Box width={150}>
                          <TextField
                            type="number"
                            disabled
                            variant="outlined"
                            name="amount"
                            value={sku.amount}
                            fullWidth
                          />
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan="6" align="right">
                  Discount
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={totalDiscount}
                    onChange={discountChanged}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="6" align="right">
                  Total
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    variant="outlined"
                    disabled
                    fullWidth
                    key={order.total}
                    value={order.total}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <DialogActions>
          <Button onClick={() => setCreateOrderDialog(false)} variant="contained" color="secondary">
            CANCEL
          </Button>
          <Button
            onClick={submitOrder}
            variant="contained"
            color="primary"
            disabled={order.total <= 0 || !order.firstName || !order.lastName}
          >
            CONFIRM
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default OrderDialog;
