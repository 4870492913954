import { gql } from "@apollo/client";
import { wishlistFileds } from "./queries";

export const DOWNLOAD_REGISTRY_REPORT = gql`
  mutation downloadRegistryReport($searchParam: SearchRegistryParam) {
    downloadRegistryReport(searchParam: $searchParam)
  }
`;

export const UPDATE_REGISTRY_STATUS = gql`
    mutation updateRegistryStatus( $wishlistId:String, $status:String ){
        updateRegistryStatus( wishlistId:$wishlistId, status:$status ){
            ${wishlistFileds}
        }
    }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($order: OrderInput) {
    createOrder(order: $order)
  }
`;

export const CANCEL_OFFLINE_ORDER = gql`
  mutation cancellOfflineOrder($id: String) {
    cancellOfflineOrder(id: $id)
  }
`;

export const UPDATE_REGISTRY = gql`
    mutation updateWishlist( $wishlist:UpdateWishlistInput ){
        updateWishlist( wishlist:$wishlist ){
            ${wishlistFileds}
        }
    }
`;

export const DELETE_WISHLIST_ITEM = gql`
  mutation deleteWishlistItem($wishlistId: String, $wishListItemId: String, $owner: String) {
    deleteWishlistItem(wishlistId: $wishlistId, wishListItemId: $wishListItemId, owner: $owner)
  }
`;

export const UPDATE_SHOPIFY_CHECKOUT_STATUS = gql`
    mutation updateShopifyCheckoutStatus( $shopifyCheckoutId:String, $status: String ){
      updateShopifyCheckoutStatus( shopifyCheckoutId:$shopifyCheckoutId, status: $status )
    }
`;

export const UPDATE_OFFLINE_ORDER_STATUS = gql`
    mutation updateOfflineOrderStatus( $id:String, $status: String ){
      updateOfflineOrderStatus( id:$id, status: $status )
    }
`;
