import { gql } from "@apollo/client";

export const APP_REMOTE_CONFIG_QUERY = gql`
  query appRemoteConfigQuery($platform: String) {
    appRemoteConfig(platform: $platform) {
      _id
      appId
      version
      versionCode
      force
      platform
      serviceMessage
      isMaintenance
    }
  }
`;
