import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Switch,
  TextField,
} from "@mui/material";

const AppVersionModal = (props) => {
  const { open, onClose, initValue, onSave } = props;
  const { platform, version, versionCode, force, isMaintenance } = initValue;
  const [forceEnabled, setForceEnabled] = useState(null);
  const [maintenanceEnabled, setMaintenanceEnabled] = useState(null);
  const [values, setValues] = useState({});

  const onCloseClick = () => {
    onClose();
    setForceEnabled(null);
    setMaintenanceEnabled(null);
    setValues({});
  };

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Dialog open={open} onClose={onCloseClick} fullWidth maxWidth="md">
      <DialogTitle>Update {platform} Version</DialogTitle>
      <DialogContent
        style={{
          margin: "10px",
          padding: "10px",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Version"
                name="version"
                variant="outlined"
                defaultValue={version || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Version Code"
                name="versionCode"
                variant="outlined"
                defaultValue={versionCode || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            Enable Forced Update?
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <FormControl>
              {/* <TextField
                fullWidth
                label="Forced Update"
                name="force"
                variant="outlined"
                defaultValue={force || ""}
                //onChange={changeConfig}
              /> */}
              <Switch
                checked={forceEnabled != null ? forceEnabled : force}
                onChange={() => setForceEnabled(!!!(forceEnabled != null ? forceEnabled : force))}
                //   classes={{
                //     switchBase: classes.switchBase,
                //     checked: classes.switchChecked,
                //     icon: classes.switchIcon,
                //     iconChecked: classes.switchIconChecked,
                //     bar: classes.switchBar
                //   }}
                inputProps={{ name: "force" }}
              />
            </FormControl>
            <div>{(forceEnabled != null ? forceEnabled : force) ? "Enabled" : "Disabled"}</div>
          </Grid>
          <Grid item xs={12}>
            Is Maintenance?
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <FormControl>
              {/* <TextField
                fullWidth
                label="Forced Update"
                name="force"
                variant="outlined"
                defaultValue={force || ""}
                //onChange={changeConfig}
              /> */}
              <Switch
                checked={maintenanceEnabled != null ? maintenanceEnabled : isMaintenance}
                onChange={() =>
                  setMaintenanceEnabled(!!!(maintenanceEnabled != null ? maintenanceEnabled : isMaintenance))
                }
                //   classes={{
                //     switchBase: classes.switchBase,
                //     checked: classes.switchChecked,
                //     icon: classes.switchIcon,
                //     iconChecked: classes.switchIconChecked,
                //     bar: classes.switchBar
                //   }}
                inputProps={{ name: "isMaintenance" }}
              />
            </FormControl>
            <div>{(maintenanceEnabled != null ? maintenanceEnabled : isMaintenance) ? "Yes" : "No"}</div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            onSave({
              platform,
              version: values.version || version,
              versionCode: values.versionCode || versionCode,
              force: forceEnabled != null ? forceEnabled : force,
              isMaintenance: maintenanceEnabled != null ? maintenanceEnabled : isMaintenance,
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppVersionModal;
