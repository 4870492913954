import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ApolloProvider } from "@apollo/client";
import client from "./modules/core/utils/connection";
import MasterLayout from "./modules/core/layouts/MasterLayout";
import AppContextProvider from "./modules/core/AppContext";

import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <AppContextProvider>
          <MasterLayout />
        </AppContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
