import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { matchSorter } from "match-sorter";
import { Button, Dialog, DialogContent, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useApolloClient } from "@apollo/client";
import numeral from "numeral";
import { PROVINCE_LIST } from "../../core/utils/province-list";

const AddressSelection = (props) => {
  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue);
  const { city, barangay, province, setAddress, setShowAddressDialog } = props;
  const [newBarangay, setBarangay] = useState(barangay);
  const [newCity, setCity] = useState(city);
  const [newProvince, setProvince] = useState(province);
  const [baranggayOptions, setBaranggayOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);

  const setNewProvince = (event) => {
    setProvince(event.target.innerText);
    setBarangay("");
    setCity("");
    setCityOptions([]);
    setBaranggayOptions([]);
  };

  const setNewCity = (event) => {
    setBarangay("");
    setCity(event.target.innerText);
    setBaranggayOptions([]);
  };

  const setNewBarangay = (event) => {
    setBarangay(event.target.innerText);
  };

  useEffect(() => {
    if (provinceOptions.length == 0 && PROVINCE_LIST != null) {
      let listOfProvinces = Object.keys(PROVINCE_LIST.PROVINCE_MAP);
      let newProvinceOptions = [];
      newProvinceOptions = listOfProvinces.map((provinceName, index) => {
        return provinceName;
      });
      setProvinceOptions(newProvinceOptions);
    }
  }, [provinceOptions, setProvinceOptions]);

  useEffect(() => {
    if (newProvince && cityOptions.length == 0 && PROVINCE_LIST != null) {
      let listOfCities = Object.keys(PROVINCE_LIST.PROVINCE_MAP[newProvince].municipalities);
      let newCityOptions = [];
      newCityOptions = listOfCities.map((cityName, index) => {
        return cityName;
      });
      setCityOptions(newCityOptions);
    }
  }, [newProvince, cityOptions, setCityOptions]);

  useEffect(() => {
    if (newCity && baranggayOptions.length == 0 && PROVINCE_LIST != null) {
      let listOfBaranggay = PROVINCE_LIST.PROVINCE_MAP[newProvince].municipalities[newCity].baranggays;
      let newBaranggayOptions = [];
      newBaranggayOptions = listOfBaranggay.map((brgyObj, index) => {
        return brgyObj.name;
      });
      setBaranggayOptions(newBaranggayOptions);
    }
  }, [newCity, baranggayOptions, setBaranggayOptions]);

  return (
    <DialogContent dividers>
      <h2>Change Address</h2>
      <Grid container spacing={1}>
        <Grid item sm={4}>
          <Autocomplete
            onChange={(event) => setNewProvince(event)}
            filterOptions={filterOptions}
            value={newProvince}
            freeSolo
            options={provinceOptions.map((option) => option)}
            renderInput={(params) => (
              <TextField
                label="Province"
                {...params}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                //onChange={(event) => handleSearchKey(event)}
              />
            )}
          />
        </Grid>
        <Grid item sm={4}>
          <Autocomplete
            onChange={(event) => setNewCity(event)}
            filterOptions={filterOptions}
            value={newCity}
            freeSolo
            options={cityOptions.map((option) => option)}
            renderInput={(params) => (
              <TextField
                label="City"
                {...params}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                //onChange={(event) => handleSearchKey(event)}
              />
            )}
          />
        </Grid>
        <Grid item sm={4}>
          <Autocomplete
            onChange={(event) => setNewBarangay(event)}
            filterOptions={filterOptions}
            value={newBarangay}
            freeSolo
            options={baranggayOptions.map((option) => option)}
            renderInput={(params) => (
              <TextField
                label="Barangay"
                {...params}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                //onChange={(event) => handleSearchKey(event)}
              />
            )}
          />
        </Grid>
      </Grid>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setAddress({
            newAddress2: newBarangay,
            newCity: newCity,
            newProvince: newProvince,
          });
          setShowAddressDialog(false);
        }}
      >
        Save
      </Button>
    </DialogContent>
  );
};

export default AddressSelection;
