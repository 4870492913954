import { gql } from "@apollo/client";

export const GET_BRANCHES = gql`
  query getBranches($showActiveOnly: Boolean, $keyword: String, $offset: Int, $limit: Int) {
    getBranches(showActiveOnly: $showActiveOnly, keyword: $keyword, offset: $offset, limit: $limit)
  }
`;

export const GET_BRANCH_BY_ID = gql`
  query getBranchById($branchId: String) {
    getBranchById(branchId: $branchId) {
      _id
      name
      storeCode
      fbUrl
      companyId
      description
      address1
      address2
      province
      barangay
      city
      postalCode
      isPickup
      isDelivery
      lng
      lat
      phone
      email
      imageUrl
      status
    }
  }
`;

export const GET_BRANCHES_BY_ID = gql`
  query getBranchesById($branchId: [String]) {
    getBranchesById(branchId: $branchId) {
      _id
      name
      storeCode
      fbUrl
      companyId
      description
      address1
      address2
      province
      barangay
      city
      postalCode
      isPickup
      isDelivery
      lng
      lat
      phone
      email
      imageUrl
      status
    }
  }
`;
